import { Component, Input, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-fullsize-info',
    host: { 'class': 'o-gv' },
    template: `
  <div class="c-fullsize-info {{ state }}">
    <fa-icon *ngIf="icon" [icon]="icon" size="6x"></fa-icon>
    <mat-spinner *ngIf="isLoading" class="spinner" color="primary" [diameter]="60" [strokeWidth]="3"></mat-spinner>
    <h3>{{ title }}</h3>
    <p><ng-content></ng-content></p>
  </div>
  `,
    styleUrls: ['./fullsize-info.component.scss'],
    standalone: true,
    imports: [NgIf, FontAwesomeModule, MatProgressSpinnerModule]
})
export class FullsizeInfoComponent implements OnInit {

  @Input() state: string
  @Input() icon: string[]
  @Input() title: string
  @Input() isLoading: boolean


  constructor() { }

  ngOnInit() { }

}
