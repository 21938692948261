import { Certificate } from 'pkijs'
import { CertificateIssuer, CertificateSubject } from '../defaults'
import { createRsaKeyPair } from '../key/create-rsa-key-pair'
import { addKeyAndSign } from './add-key-and-sign'
import { addCertificateExtensions } from './extensions'
import { initCertificate } from './init-certificate'

/**
 * create new X.509 certificate to hold the users public key
 */
export async function createCertificate(
  issuer: CertificateIssuer,
  subject: CertificateSubject,
  hashAlgorithm: string,
): Promise<{
  certificate: Certificate
  privateKey: CryptoKey
  publicKey: CryptoKey
}> {
  const keyPair = await createRsaKeyPair().toPromise()
  const certInit = await initCertificate(issuer, subject).toPromise()
  const certWithExtensions = await addCertificateExtensions(
    certInit,
    subject.sat,
  ).toPromise()
  const certificate = await addKeyAndSign(
    certWithExtensions,
    keyPair,
    hashAlgorithm,
  ).toPromise()

  return {
    certificate,
    privateKey: keyPair.privateKey,
    publicKey: keyPair.publicKey,
  }
}
