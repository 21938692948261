import { ArztType } from "@lib/entities/ArztType.entity"

/**
 * Socket UserRole
 */
export enum SocketUserRole {
  Arzt = 'arzt',
  Patient = 'patient',
  Tool = 'tool', // iphone
  Spezialist = 'specialist',
  Admin = 'admin'
}

/**
 * Socket Status
 */

export enum SocketUserStatus {
  Joined = 'joined',
  NotJoined = 'notJoined',
}

export enum SocketArztStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum SocketArztPortalStatus {
  Active = 'active',
  Inactive = 'inactive',
}

/**
 * Socket UserData
 */
export interface SocketPatientUserData {
  readonly session: string
  // waitingSince: Date
  // insuranceType: InsuranceType
}
export interface SocketArztUserData {
  readonly session?: string
  readonly arztType: ArztType
}

