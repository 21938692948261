import { InputType } from '@arzt-direkt/wfa-definitions'
import { QuestionTextModel } from 'survey-core'

export function setWfaInputType(
  inputType: InputType,
  question: QuestionTextModel,
): void {
  question['wfaInputType'] =
    inputType === 'custom-number' ? 'number' : undefined
}
