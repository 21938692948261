<div class="booking-date">
  <!-- message to be displayed in case there are no openings -->
  <div class="no-openings" *ngIf="!openingsExist">
    <div class="error">
      <!-- title -->
      <div class="title">
        {{ errorTitle }}
      </div>
      <span></span><img [src]="failureAssetUrl" alt='error'>
    </div>
  </div>
  <!-- the form:
    only visible if there are openings.
  using css instead of *ngIf to make sure calendar is defined otherwise there are errors. -->

  <form [formGroup]="bookingDateMultiFormGroup" [ngClass]="openingsExist ? '' : 'form-hide'">
    <!-- calendar (desktop only) -->

    <mat-stepper #chainStepper style="width:100%" (selectionChange)="onStepChange($event)">
      <mat-step *ngFor="let app of appointmentChain; let appIdx=index; let appIdx2=index; let appIdx3=index">
        <div class="booking-date-container">
          <div class="calendar">
            <mat-calendar #calendar [minDate]="calendarMinDate" [maxDate]="calendarMaxDate"
              [(selected)]="calendarSelectedDates[appIdx]" (selectedChange)="onCalendarSelectDate($event)"
              (monthSelected)="onCalendarSelectMonth($event)" [headerComponent]="calendarHeaderComponent">
            </mat-calendar>

            <!-- <mat-calendar [(selected)]="calendarSelectedDate">
            </mat-calendar> -->
          </div>
          <!-- appointment mini-summary, doctor, day (mobile only) & openings -->
          <div class="column-right">
            <!-- icon + mini-summary -->
            <div class="complete-mini-summary">
              <div class="icon-and-mini-summary">
                <mat-icon class="icon">event_available</mat-icon>
                <!-- mini summary -->
                <div class="mini-summary" *ngIf="appointmentTypeName">


                  <span class="appointment-type-name">
                    {{ ((bookingService.automaticSeriesSelection$ | async) === false ? app?.name : appointmentTypeName)
                    |
                    i18nString }}
                  </span>
                  <div class="chain-nav">
                    <!-- <button mat-icon-button matStepperPrevious><mat-icon>navigate_before</mat-icon></button>
                    <span>{{ 'Termin ' + (appIdx+1) + ' / 3'}}</span>
                    <button mat-icon-button matStepperNext><mat-icon>navigate_next</mat-icon></button> -->
                    <span *ngIf="(bookingService.automaticSeriesSelection$ | async) === false"
                      class="series-index-badge">{{
                      (appIdx+1) }}</span>
                  </div>
                </div>
              </div>
              <div *ngIf="bsFilterActive && bsSelected" class="icon-and-mini-summary" style="padding-top:0">
                <mat-icon class="icon">location_on</mat-icon>
                <div class="mini-summary">
                  <span class="bs-name">{{bsSelected?.name}}</span>
                </div>
              </div>
            </div>
            <!-- doctors -->
            <div class="doctors" *ngIf="allowDoctorChoice" formArrayName="doctors">
              <mat-form-field appearance="outline">
                <mat-label>{{handlerName}}</mat-label>
                <!-- <div *ngIf="!doctors.value[appIdx]" class="doctor-any">
                  {{'OTK.BOOKING-DATE.ANY' | translate}}
                </div> -->
                <mat-select [formControlName]="appIdx">
                  <!-- selected result -->
                  <!-- (notice the ".value" here in contrast to the mat-option-doctor below) -->
                  <mat-select-trigger class="doctor">
                    <div class="doctor">
                      <div class="doctor-photo">
                        <div class="doctor-badge">
                          <span class="doctor-letter">{{ doctors.value?.[appIdx]?.representingLetter || 'A'}}</span>
                        </div>
                      </div>
                      <div class="doctor-text">
                        {{doctors.value?.[appIdx]?.fullName || 'Alle'}}
                      </div>
                    </div>

                    <div class="doctor" *ngIf="!doctors.value[appIdx]">
                      Alle
                    </div>
                  </mat-select-trigger>
                  <!-- no filter ("beliebig") -->
                  <mat-option label="Beliebig" [value]="null">
                    <div class="doctor">
                      <div class="doctor-text">
                        <div class="doctor-text__fullname">
                          {{'OTK.BOOKING-DATE.ANY' | translate }}
                        </div>
                      </div>
                    </div>
                  </mat-option>
                  <!-- drop down options -->
                  <mat-option *ngFor="let doctor of (doctorSelectionArray | async)[appIdx]" [value]="doctor">
                    <div class="doctor">
                      <div class="doctor-photo">
                        <img *ngIf="doctor.photo" src="{{ doctor.photo }}" class="doctor-photo--custom"
                          alt="doctorPhoto">
                        <div *ngIf="!doctor.photo" class="doctor-photo--default">
                          {{ doctor.representingLetter }}
                        </div>
                      </div>
                      <div class="doctor-text">
                        <div class="doctor-text__fullname">
                          {{doctor.fullName}}
                        </div>
                        <div class="doctor-text__description" *ngIf="doctor.description">
                          {{doctor.description}}
                        </div>
                      </div>
                    </div>
                  </mat-option>
                </mat-select>
                <!-- reset button ("x") -->
              </mat-form-field>
            </div>
            <!-- date (mobile-only) -->
            <!-- known issue:
        user typing date manually leads to format change,
        see https://github.com/angular/components/issues/14291
       -->
            <div class="day" formArrayName="days">

              <mat-form-field appearance="outline" class="date">
                <mat-label>{{'OTK.STEPPER.TITLES.APPOINTMENT-DATE' | translate }}</mat-label>
                <input matInput [matDatepicker]="picker_appIdx2"
                  [placeholder]="'OTK.BOOKING-DATE.MAT-DATEPICKER-PLACEHOLDER' | translate " autocomplete="off"
                  [min]="calendarMinDate" [max]="calendarMaxDate" [matDatepickerFilter]="mobileCalFilters[appIdx]"
                  [formControlName]="appIdx">
                <mat-datepicker-toggle matSuffix [for]="picker_appIdx2"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker_appIdx2
                  [calendarHeaderComponent]="calendarHeaderComponent"></mat-datepicker>
                <mat-error *ngIf="dayError">{{dayError}}</mat-error>
              </mat-form-field>

            </div>





            <!-- opening -->
            <div *ngIf="dayString" class="opening-text" [ngClass]="allowDoctorChoice ? '' : 'no-doctor-style'">
              {{ 'OTK.BOOKING-DATE.OPENINGS-TEXT' | translate }} {{ days.value?.[appIdx] | date:'dd.MM.y' }}
            </div>
            <div class="openings" formArrayName="openings">
              <mat-button-toggle-group [formControlName]="appIdx" required name="openings"
                [class.only-time]="usedSlotStyle === slotStyle.nothing">
                <mat-button-toggle class="mat-button-toggle"
                  *ngFor="let openingFiltered of openingsFilteredMulti[appIdx]; let idx = index"
                  [value]="openingFiltered" [ngSwitch]="usedSlotStyle">
                  <!-- doctors and betriebstaette -->
                  <div class="button-content" *ngSwitchCase="slotStyle.both">
                    <!-- <mat-icon class="icon-big">schedule</mat-icon> {{ 'OTK.STEPPER.OPENING-ITEM-TEMPLATE' |                                                                        translate:{bookingTime: openingFiltered.displayStringTime} }} -->
                    <div class="button-parts-container">
                      <mat-icon class="icon-abs">schedule</mat-icon>
                      <span class="subtext">{{
                        openingFiltered.start |
                        date:'HH:mm':timezone }} {{ 'OTK.BOOKING-DATE.UHR' | translate }}</span>

                    </div>
                    <div class="button-parts-container">
                      <mat-icon class="icon-abs">person</mat-icon><span class="subtext">{{ (doctorsArray |
                        async)[openingFiltered.kdSetKey]?.length < 26 ? (doctorsArray | async)[openingFiltered.kdSetKey]
                          : ((doctorsArray | async)[openingFiltered.kdSetKey] | slice:0:25) + '...' }}</span>
                    </div>
                    <div class="button-parts-container">
                      <mat-icon class="icon-abs">location_on</mat-icon>
                      <div class="subtext-wrap">{{ (bsArray |
                        async)[openingFiltered.kdSetKey]?.length < 50 ? (bsArray | async)[openingFiltered.kdSetKey] :
                          ((bsArray | async)[openingFiltered.kdSetKey] | slice:0:49) + '...' }}</div>
                      </div>
                    </div>


                    <!-- doctors  -->
                    <div class="button-content" *ngSwitchCase="slotStyle.onlyDoctor">
                      <div class="button-parts-container">
                        <mat-icon class="icon-abs">schedule</mat-icon>
                        <span class="subtext">{{
                          openingFiltered.start |
                          date:'HH:mm':timezone }} {{ 'OTK.BOOKING-DATE.UHR' | translate }}</span>
                      </div>
                      <div class="button-parts-container">
                        <mat-icon class="icon-abs">person</mat-icon><span class="subtext">{{ (doctorsArray |
                          async)[openingFiltered.kdSetKey]?.length < 26 ? (doctorsArray |
                            async)[openingFiltered.kdSetKey] : ((doctorsArray | async)[openingFiltered.kdSetKey] |
                            slice:0:25) + '...' }}</span>
                      </div>
                    </div>

                    <!-- betriebsstaette  -->
                    <div class="button-content" *ngSwitchCase="slotStyle.onlyBs">
                      <div class="button-parts-container">
                        <mat-icon class="icon-abs">schedule</mat-icon>
                        <span class="subtext">{{
                          openingFiltered.start |
                          date:'HH:mm':timezone }} {{ 'OTK.BOOKING-DATE.UHR' | translate }}</span>
                      </div>
                      <div class="button-parts-container">
                        <mat-icon class="icon-abs">location_on</mat-icon>
                        <!-- <span class="subtext">{{ (bsArray |
                        async)[openingFiltered.kdSetKey]?.length < 26 ? (bsArray | async)[openingFiltered.kdSetKey] :
                          ((bsArray | async)[openingFiltered.kdSetKey] | slice:0:25) + '...' }}</span> -->
                        <div class="subtext-wrap">{{ (bsArray |
                          async)[openingFiltered.kdSetKey]?.length < 50 ? (bsArray | async)[openingFiltered.kdSetKey] :
                            ((bsArray | async)[openingFiltered.kdSetKey] | slice:0:49) + '...' }}</div>
                        </div>
                      </div>

                      <!-- only time -->
                      <div class="button-content" *ngSwitchDefault>
                        <div class="button-parts-container">
                          <mat-icon class="icon-abs">schedule</mat-icon>
                          <span class="subtext">{{
                            openingFiltered.start |
                            date:'HH:mm':timezone }} {{ 'OTK.BOOKING-DATE.UHR' | translate }}</span>
                        </div>
                      </div>
                </mat-button-toggle>

                <!-- <mat-button-toggle class="empty">b</mat-button-toggle>
                <mat-button-toggle class="empty">b</mat-button-toggle>
                <mat-button-toggle class="empty">b</mat-button-toggle>
                <mat-button-toggle class="empty">b</mat-button-toggle>
                <mat-button-toggle class="empty">b</mat-button-toggle>
                <mat-button-toggle class="empty">b</mat-button-toggle>
                <mat-button-toggle class="empty">b</mat-button-toggle>
                <mat-button-toggle class="empty">b</mat-button-toggle>
                <mat-button-toggle class="empty">b</mat-button-toggle> -->
              </mat-button-toggle-group>
              <div [class.o-vh]="!showNoSlotWarning[appIdx]">
                <p>
                  {{ 'OTK.BOOKING-DATE-MULTI.ERROR.GENERIC' | translate }}
                </p>
              </div>
            </div>

          </div>

        </div>
      </mat-step>
    </mat-stepper>



  </form>
</div>