import { AdLoggerService } from '@a-d/logging/ad-logger.service';
import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import dayjs from 'dayjs';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CookiesService, EssentialCookieNames, LSERROR } from '../dsgvo/cookies.service';
import { InstanceForm } from '../entities/InstanceForm.entity';
import { InstanceService } from '../instance/instance.service';



@Injectable({
  providedIn: 'root'
})
export class FormHelpers implements OnDestroy {

  private unsubscribe$ = new Subject()

  constructor(
    private adLoggerService: AdLoggerService,
    private instanceService: InstanceService,
    private cookiesService: CookiesService,
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }


  /* Trimming Data */

  public trimObject(obj) {
    // Source: https://stackoverflow.com/a/33511005/1381666
    if (obj === null || (!Array.isArray(obj) && typeof obj !== 'object')) {
      return obj;
    }
    return Object.keys(obj).reduce((acc, key) => {
      if (typeof obj[key] === 'string') {
        acc[key.trim()] = obj[key].trim();
      } else if (obj[key] instanceof Date) {
        acc[key.trim()] = obj[key];
      } else {
        acc[key.trim()] = this.trimObject(obj[key]);
      }
      return acc;
    }, Array.isArray(obj) ? [] : {});
  }

  public trimmedRawValue(formGroup: UntypedFormArray | UntypedFormGroup) {
    return this.trimObject(formGroup.getRawValue())
  }

  public trimmedValue(formGroup: UntypedFormArray | UntypedFormGroup, enabledOnly: boolean = true) {
    const formValue = formGroup.value

    if (enabledOnly) {
      for (const key of Object.keys(formValue)) {
        const control = formGroup.get(key)
        if (control?.disabled) {
          delete formValue[key];
        }
      }
    }

    return this.trimObject(formValue)
  }


  /* LocalStorage */

  public assignValueToControl(formControl: UntypedFormControl, value: any, validOnly: boolean, skipIfValidAndTouched: boolean = false) {
    if (!formControl) return

    if (skipIfValidAndTouched && formControl.touched && formControl.valid) {
      return
    }

    const oldValue = formControl.value

    // formControl.setValue(value)
    formControl.patchValue(value)

    if (validOnly && formControl.invalid) {
      formControl.setValue(oldValue)
    }
  }

  public setToLocalStorage(formGroup: UntypedFormGroup, id: string, excludeControls?: string[], maxDaysAge?: number, validOnly?: boolean, dontOverwrite?: boolean, explicitAllowance?: boolean) {
    if (this.cookiesService.get(EssentialCookieNames.isFormCachingAllowed) !== 'true' && !explicitAllowance)
      return
    // Prefix ID with instance-identifier
    if (this.instanceService.activeInstance) {
      id = `${this.instanceService.activeInstance.identifier.toUpperCase()}_${id}`
    }
    if (dontOverwrite && localStorage.getItem(id)) return

    let data = {}

    if (maxDaysAge && !data['VALID_UNTIL']) {
      const validUntil = dayjs().add(maxDaysAge, 'day')
      data['VALID_UNTIL'] = validUntil.toISOString()
    }

    for (let name in formGroup.controls) {
      if (excludeControls && excludeControls.includes(name)) continue

      const control = formGroup.controls[name]
      if (validOnly && control.invalid) continue

      if (!control.value && control.value !== false) continue

      data[name] = control.value
    }

    data = JSON.stringify(data)
    localStorage.setItem(id, <string>data);
  }

  public getFromLocalStorage(formGroup: UntypedFormGroup, id: string, excludeControls?: string[], maxDaysAge?: number, validOnly?: boolean, parseDate?: string) {
    // Prefix ID with instance-identifier
    if (this.instanceService.activeInstance) {
      id = `${this.instanceService.activeInstance.identifier.toUpperCase()}_${id}`
    }

    let data = localStorage.getItem(id)
    if (!data) return

    try {
      data = JSON.parse(data)

      // Check if Max-Age is exceeded
      const validUntil = dayjs(data['VALID_UNTIL'])
      if (data['VALID_UNTIL'] && validUntil.isValid() && validUntil.isBefore(dayjs())) {
        localStorage.removeItem(id)
        return
      }

      // Assign items to each accordingcontrol
      for (let name in <any>data) {
        if (!(name in formGroup.controls)) continue
        if (excludeControls && excludeControls.includes(name)) continue
        if (parseDate && parseDate === name) data[name] = new Date(data[name])

        const control = <UntypedFormControl>formGroup.controls[name]
        //without the distinction for zip, if zip format doesn't match the default country (from instance.contact)
        //it is marked invalid and is not set (even if it matches the country saved in the local storage)
        this.assignValueToControl(control, data[name], name === 'zip' ? false : validOnly)
      }
    } catch (e) {
      console.log(`Couldn't parse LocalStorage data for formGroup '${id}'`)
      this.adLoggerService.error(e)
    }
  }

  public clearFromLocalStorage(id: string) {
    // Prefix ID with instance-identifier
    if (this.instanceService.activeInstance) {
      id = `${this.instanceService.activeInstance.identifier.toUpperCase()}_${id}`
    }

    // Remove from localStorage
    localStorage.removeItem(id)
  }

  public syncWithLocalStorage(formGroup: UntypedFormGroup, id: string, formUnsubscribe$: Observable<any>, excludeControls?: string[], maxDaysAge?: number, validOnly: boolean = true, saveWithoutInstance: boolean = false) {
    if (!this.instanceService.activeInstance && !saveWithoutInstance) return

    this.getFromLocalStorage(formGroup, id, excludeControls, maxDaysAge, validOnly)

    formGroup.valueChanges.pipe(
      debounceTime(1000),
      takeUntil(formUnsubscribe$),
      takeUntil(this.unsubscribe$)
    ).subscribe((_) => {
      this.setToLocalStorage(formGroup, id, excludeControls, maxDaysAge, validOnly)
    })
  }


  public syncBookingPersonalWithLocalStorage(formGroup: UntypedFormGroup, id: string, formUnsubscribe$: Observable<any>) {
    if (!this.instanceService.activeInstance) return
    //check if localStorage access is allowed
    try { localStorage.key } catch { console.log(LSERROR); return }

    this.getFromLocalStorage(formGroup, id, undefined, undefined, undefined, 'birthDate')

    return formGroup.valueChanges.pipe(
      debounceTime(1000),
      takeUntil(formUnsubscribe$),
      takeUntil(this.unsubscribe$)
    ).subscribe((_) => {
      this.setToLocalStorage(formGroup, id, undefined, undefined, undefined, undefined)
    })
  }



  public syncActiveAnamneseFormWithLocalStorage(instanceForm: InstanceForm, formGroup: UntypedFormGroup, formUnsubscribe$: Observable<any>, suffix?: string, excludeControls?: string[], maxDaysAge?: number, validOnly: boolean = true) {
    if (!instanceForm || !instanceForm.anamneseFormIdentifier) return

    // Create form-id suffixed with form-identifier
    let id = 'FORM_ANAMNESE_' + instanceForm.anamneseFormIdentifier.toUpperCase()
    if (suffix) id += suffix.toUpperCase()
    this.syncWithLocalStorage(formGroup, id, formUnsubscribe$, excludeControls, maxDaysAge, validOnly)
  }

  public syncActivePersonalFormsWithLocalStorage(instanceForm: InstanceForm, personalFormGroup: UntypedFormGroup, insuranceFormGroup: UntypedFormGroup, formUnsubscribe$: Observable<any>, suffix?: string, excludeControls?: string[], maxDaysAge?: number, validOnly: boolean = true) {
    if (!instanceForm || !instanceForm.personalFormIdentifier) return

    // Create form-id suffixed with form-identifier
    let id = 'FORM_PERSONAL_' + instanceForm.personalFormIdentifier.toUpperCase()
    if (suffix) id += suffix.toUpperCase()
    this.syncWithLocalStorage(personalFormGroup, id, formUnsubscribe$, excludeControls, maxDaysAge, validOnly)

    if (insuranceFormGroup) {
      id = 'FORM_INSURANCE_' + instanceForm.personalFormIdentifier.toUpperCase()
      if (suffix) id += suffix.toUpperCase()
      this.syncWithLocalStorage(insuranceFormGroup, id, formUnsubscribe$, null, maxDaysAge, validOnly)
    }
  }

  /*
     Returns an array of invalid control/group names, or a zero-length array if
     no invalid controls/groups where found
  */
  public static findInvalidControls(formToInvestigate: UntypedFormGroup | UntypedFormArray): string[] {
    var invalidControls: string[] = [];
    let recursiveFunc = (form: UntypedFormGroup | UntypedFormArray) => {
      if (form?.controls === null || form?.controls === undefined) return []
      Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        if (control.invalid) invalidControls.push(field);
        if (control instanceof UntypedFormGroup) {
          recursiveFunc(control);
        } else if (control instanceof UntypedFormArray) {
          recursiveFunc(control);
        }
      });
    }
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }

  /* Validation for form groups/arrays with dynamically generated forms */
  public static valid(formToInvestigate: UntypedFormGroup | UntypedFormArray): boolean {
    return this.findInvalidControls(formToInvestigate).length === 0
  }
}
