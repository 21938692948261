<div class="appointment-type">
  <!-- appointment type name -->
  <div class="name">
    <mat-icon *ngIf="appointmentType.onlineConsultation"
      style="align-self:center; transform: scale(1.3); opacity:0.7; margin-bottom:-0.4rem; margin-right:0.4rem;"
      matTooltip="{{'OTK.BOOKING-TYPE.VSS-APPOINTMENT-TOOLTIP' | translate }}">
      videocam
    </mat-icon>
    {{name}}
  </div>
  <!-- appointment type description -->
  <div class="description" [innerHTML]="descriptionSanitized">
  </div>
  <!-- duration -->
  <div *ngIf="appointmentType.showDuration && !appointmentType.isDisabled" class="duration">
    <span>{{ 'OTK.BOOKING-TYPE.DURATION' | translate }}</span><br />
    <mat-icon>access_time</mat-icon>
    <span>{{appointmentType.durationSearch ? appointmentType.durationSearch : appointmentType.duration}} min</span>
  </div>
  <!-- price for appointment type -->
  <div *ngIf="!(appointmentType.isDisabled && !appointmentType.skipOpeningSelection) && appointmentType?.adPayActive"
    class="price">
    <span>{{ 'OTK.BOOKING-TYPE.PAY' | translate }}</span><br />
    <span class="highlight">{{ appointmentType?.amount?.value / 100 | currency: appointmentType?.amount?.currency :
      'symbol' : '1.2-2'
      }}</span>
  </div>
  <!-- no openings warning -->
  <div *ngIf="appointmentType.isDisabled && !appointmentType.skipOpeningSelection" class="disabled">
    <span>{{'OTK.BOOKING-TYPE.NO-AVAILABLE-APPOINTMENTS' | translate}}</span>
    <mat-icon>warning</mat-icon>
  </div>
</div>