import { HttpHeaders } from '@angular/common/http'

export const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  responseType: 'json' as const,
}

export const formDataHttpOptions = {
  headers: new HttpHeaders({
    key: 'Content-Type',
    value: 'multipart/form-data',
  }),
  responseType: 'json' as const,
}
