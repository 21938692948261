import { ZipValidatorService } from '@a-d/forms/zip-validator.service'
import { WfaFormValidatorsService } from '@a-d/wfr/wfa/forms/form-validators'
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms'
import { ADValidators } from '../validators/a-d-validators';

export interface AddressFormGroupTemplate {
  street?: UntypedFormControl
  streetNumber?: UntypedFormControl
  address_1?: UntypedFormControl
  address_2: UntypedFormControl
  zip: UntypedFormControl
  city: UntypedFormControl
  country: UntypedFormControl
  countryCode?: UntypedFormControl
}
export type AddressFormGroup = FormGroup<AddressFormGroupTemplate>

/**
 * Creates `address` FormGroup
 */
export function createAddressFormGroup(
  seperateStreetNameAndNumber: boolean,
  countrySubFormName?: string,
  defaultCountry: string = 'Deutschland',
  requireValidators: boolean = true,
  useDynamicallyRequiredValidator: boolean = false,
  useCountryCode: boolean = false
): AddressFormGroup {
  const requiredV = useDynamicallyRequiredValidator
    ? WfaFormValidatorsService.dynamicallyRequired
    : Validators.required
  const validators = requireValidators ? [ADValidators.noSpecialCharacters, requiredV] : [ADValidators.noSpecialCharacters]
  const zipValidators = requireValidators
    ? [
      requiredV,
      ZipValidatorService.getZipValidator(
        defaultCountry,
        countrySubFormName,
        useCountryCode
      ),
    ]
    : [
      ZipValidatorService.getZipValidator(
        defaultCountry,
        countrySubFormName,
        useCountryCode,
        true
      ),
    ]
  const group = new UntypedFormGroup({
    ...(seperateStreetNameAndNumber
      ? {
        street: new UntypedFormControl(null, [...validators, ADValidators.streetNameDoesNotContainHouseNumber]),
        streetNumber: new UntypedFormControl(null, validators),
      }
      : {
        address_1: new UntypedFormControl(null, validators),
      }),
    address_2: new UntypedFormControl(null, ADValidators.noSpecialCharacters),
    zip: new UntypedFormControl(null, zipValidators),
    city: new UntypedFormControl(null, validators),
    country: new UntypedFormControl(null, validators),
  })
  if (useCountryCode) {
    group.addControl('countryCode', new UntypedFormControl(null, validators))
  }
  return group
}
