import { InstanceDebug } from './../entities/Instance.entity'
import { ActiveInstance } from './../entities/ActiveInstance.entity'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  activeInstance: {
    debug: InstanceDebug
  } | undefined
}
