export enum StatReportType {
  CandidatePair = 'candidate-pair',
  LocalCandidate = 'local-candidate',
  RemoteCandidate = 'remote-candidate'
}

export enum CandidateState {
  Succeeded = 'succeeded',
  Waiting = 'waiting',
  InProgress = 'in-progress'
}

export enum CandidateType {
  Host = 'host',
  Relay = 'relay'
}

export enum DatagramProtocol {
  TCP = 'tcp',
  UDP = 'udp'
}

export interface StatReport {
  type: StatReportType
  id: string
  timestamp: Date
}

export interface StatReportCandidatePair extends StatReport {
  transportId: string
  localCandidateId: string
  remoteCandidateId: string
  state: CandidateState
  priority: number
  nominated: boolean
  writable: boolean
  bytesSent: number
  bytesReceived: number
  totalRoundTripTime: number
  currentRoundTripTime: number
  availableOutgoingBitrate: number
  requestsReceived: number
  requestsSent: number
  responsesReceived: number
  responsesSent: number
  consentRequestsSent: number
}

export interface StatReportCandidate extends StatReport {
  transportId: string
  isRemote: boolean
  networkType: string // wifi
  ip: string
  address: string
  port: number
  protocol: DatagramProtocol
  relayProtocol?: DatagramProtocol
  candidateType: CandidateType
  priority: number
}
