export const example = {
  type: 'file',
  title: {
    de: 'Bitte laden Sie Ihre Dateien hoch.',
    en: 'Please upload your files.',
  },
  name: 'file-upload-question',
  storeDataAsText: false,
  waitForUpload: true,
  allowMultiple: true,
  sourceType: 'file-camera',
}
