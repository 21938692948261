<mat-card
  class="o-wrapper-base"
  [ngClass]="{
    'is-embedded-in-ios-tomedo': isEmbeddedIn === 'iOsTomedo',
    'is-embedded-in-arzt-direkt': isEmbeddedIn === 'arztDirekt',
    'is-embedded-in-ad-stepper': isEmbeddedIn === 'adStepper',
  }"
>
  <mat-card-header>
    <wfa-viewer-header></wfa-viewer-header>
  </mat-card-header>
  <mat-card-content
    *ngIf="form$"
    [ngClass]="{ 'is-embedded-in-ad-stepper': isEmbeddedIn === 'adStepper' }"
  >
    <survey [model]="surveyModel"></survey>
  </mat-card-content>
  <mat-card-actions *ngIf="completed">
    <button mat-button class="btn-primary" (click)="exportPdf()">
      {{ 'PDF' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
