import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms'
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatOptionModule } from '@angular/material/core'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import {
  PraxisKennung,
  WfaForm,
  WfaFormId,
  WfaFormInfo,
} from '@arzt-direkt/wfa-definitions'
import { Id } from '@arzt-direkt/wfa-definitions'
import { PraxisDetails } from '@arzt-direkt/wfa-definitions'
import { UnlockMode } from '@arzt-direkt/wfa-definitions'
import { LocString } from '@arzt-direkt/wfa-definitions'
import { GetWfaFormRouteResponse200 } from '@arzt-direkt/wfa-definitions'
import { unlockModeDefault, unlockModeDict } from '@arzt-direkt/wfa-definitions'
import { id2String } from '@arzt-direkt/wfa-definitions'
import {
  zollsoftPublicLibrary,
  zollsoftStandardLibrary,
} from '@arzt-direkt/wfa-definitions'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import { notNullish, nullish } from '@arzt-direkt/wfa-generic-utils'
import { getLastElement } from '@arzt-direkt/wfa-generic-utils'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { RxLet } from '@rx-angular/template/let'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { filter, map, startWith, tap } from 'rxjs/operators'

import { I18nPipe } from '../../../utility/i18n.pipe'
import { updateInfos, ZsSupportService } from '../zs-support.service'
import { ZsSupportCommsService } from '../zs-support-comms.service'
import { mongoIdToLocaleString } from './../../../utility/mongo-id-to-locale-string'
import { controlIncludesStringFromArray } from './../../../utility/validators/control-includes-string-from-array'
import { clearInputValue } from './../utils'
import {
  filterByKennungSubstring,
  filterByWfaFormIdSubstring,
  sortAlphabetically,
  sortFormInfosAlphabetically,
} from './../utils'
import { maybeImportWfaForm } from './maybe-import-wfa-form'
import { submitUnlock, sumbitStandardForms } from './submit-unlock'

export interface WfaFormVersionInfo {
  wfaFormVersionId: Id
  title: LocString
}

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-unlock-form',
  templateUrl: `./unlock-form.component.html`,
  styleUrls: [
    `./unlock-form.component.scss`,
    '../support-text-button.scss',
    '../mat-card.scss',
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    I18nPipe,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    ReactiveFormsModule,
    RxLet,
    TranslateModule,
  ],
  providers: [I18nPipe],
})
export class WfaUnlockFormComponent implements OnInit {
  readonly clearInputValue = clearInputValue
  readonly faXmark = faXmark
  readonly id2String = id2String
  readonly mongoIdToLocaleString = mongoIdToLocaleString
  readonly unlockModeDict = unlockModeDict

  // praxis-select
  // can't figure out how to type the argument correctly
  praxisControl = new FormControl(
    zollsoftPublicLibrary.identifier as unknown as any,
  )
  filteredPraxisDetails$ = new BehaviorSubject<PraxisDetails[]>([])
  selectedKennung: PraxisKennung = zollsoftPublicLibrary.identifier

  // form-info-select
  formInfoControl = new FormControl()
  allFormInfos$ = new BehaviorSubject<WfaFormInfo[]>([])
  filteredFormInfos$ = new BehaviorSubject<WfaFormInfo[]>([])
  selectedWfaFormId: Id | undefined

  // form-version-select
  formVersionControl = new FormControl()
  allFormVersionInfos$ = new BehaviorSubject<WfaFormVersionInfo[]>([])
  selectedWfaFormVersionId: Id | undefined

  // new-wfa-form-id
  newWfaFormIdControl = new FormControl('', [Validators.required])
  unlockModeControl = new FormControl<UnlockMode>(unlockModeDefault)
  uploadedJson: Maybe<WfaForm>

  ngOnInit(): void {
    // praxis-select
    const filteredDetails = combineLatest([
      this.comms.loadAllPraxisDetails(),
      this.praxisControl.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(filterByKennungSubstring),
      map(sortAlphabetically),
      map((fd) => [zollsoftPublicLibrary, zollsoftStandardLibrary, ...fd]),
      untilDestroyed(this),
    )
    filteredDetails.subscribe(this.filteredPraxisDetails$)

    // form-info-select
    const filteredFormInfos = combineLatest([
      this.allFormInfos$ as Observable<WfaFormInfo[]>,
      this.formInfoControl.valueChanges.pipe(
        startWith(''),
      ) as Observable<WfaFormId>,
    ]).pipe(
      map(filterByWfaFormIdSubstring),
      map((wfi) => sortFormInfosAlphabetically(wfi, this.i18nPipe)),
      untilDestroyed(this),
    )
    filteredFormInfos.subscribe(this.filteredFormInfos$)

    // preload standard formInfos
    this.comms
      .getFormInfos(zollsoftPublicLibrary._id)
      .pipe(untilDestroyed(this))
      .subscribe((fi: WfaFormInfo[]) => {
        this.allFormInfos$.next(fi)
      })

    // control must know the latest formInfo's praxis kennungen to check for duplicates
    this.zsSupportService.formInfos$
      .pipe(untilDestroyed(this))
      .subscribe((fiList: WfaFormInfo[]) => {
        this.newWfaFormIdControl.setValidators([
          Validators.required,
          controlIncludesStringFromArray(fiList.map((fi) => fi.wfaFormId)),
        ])
        this.newWfaFormIdControl.updateValueAndValidity()
      })
  }

  constructor(
    private comms: ZsSupportCommsService,
    private i18nPipe: I18nPipe,
    private translate: TranslateService,
    public matDialog: MatDialog,
    public zsSupportService: ZsSupportService,
  ) {}

  onKennungSelect(event: MatAutocompleteSelectedEvent) {
    this.selectedKennung = event.option.value
    const selectedId = this.filteredPraxisDetails$.value.find(
      (pd) => pd.identifier === this.selectedKennung,
    )?._id

    if (nullish(selectedId)) return

    this.comms
      .getFormInfos(selectedId)
      .pipe(untilDestroyed(this))
      .subscribe((fi: WfaFormInfo[]) => {
        this.allFormInfos$.next(fi)
      })
  }

  ufImportJson(event: any) {
    const praxisId = this.zsSupportService.praxisDetails$?.value?._id

    const callbackAfterUpload = (maybeWfaForm: Maybe<WfaForm>) => {
      if (nullish(maybeWfaForm)) return

      console.log(
        `[ufImportJson]: imported json with wfaFormId ${maybeWfaForm.wfaFormId}`,
      )
      this.newWfaFormIdControl.setValue(maybeWfaForm.wfaFormId)
      this.uploadedJson = maybeWfaForm
    }

    let maybeWfaForm: Maybe<WfaForm>
    maybeImportWfaForm(
      event,
      this.matDialog,
      praxisId,
      maybeWfaForm,
      callbackAfterUpload,
    )
  }

  onFormInfoSelect(event: MatAutocompleteSelectedEvent) {
    this.selectedWfaFormId = event.option.value
    const praxisId = this.allFormInfos$.value.find(
      (fi) => fi.wfaFormId === this.selectedWfaFormId,
    )?.praxisId

    this.newWfaFormIdControl.setValue(id2String(this.selectedWfaFormId) ?? null)
    this.newWfaFormIdControl.setValidators([
      Validators.required,
      controlIncludesStringFromArray(
        this.zsSupportService.formInfos$.value.map((fi) => fi.wfaFormId),
      ),
    ])
    this.newWfaFormIdControl.updateValueAndValidity()

    this.comms
      .getForm(praxisId, id2String(this.selectedWfaFormId))
      .pipe(
        filter(notNullish),
        map(extractFormVersionInfos),
        filter(notNullish),
        untilDestroyed(this),
      )
      .subscribe((fvi: WfaFormVersionInfo[]) => {
        this.allFormVersionInfos$.next(fvi)
        const lastElement = getLastElement(fvi)
        if (lastElement !== undefined) {
          this.formVersionControl.setValue(lastElement.wfaFormVersionId)
        }
        if (fvi.length < 2) {
          this.formVersionControl.disable()
        } else {
          this.formVersionControl.enable()
        }
      })
  }

  onFormVersionSelect(event: MatAutocompleteSelectedEvent) {
    this.selectedWfaFormVersionId = event.option.value
  }

  ufSubmitUnlock() {
    const isUnlockFromMongoDb =
      this.unlockModeControl.value === unlockModeDict.fromMongoDb

    const params = isUnlockFromMongoDb
      ? {
          praxisId: this.zsSupportService.praxisDetails$?.value?._id,
          wfaFormVersionId: this.formVersionControl.value,
          newWfaFormId: this.newWfaFormIdControl.value,
        }
      : {
          wfaForm: {
            ...this.uploadedJson,
            wfaFormId: this.newWfaFormIdControl.value,
          },
        }

    submitUnlock(params, this.comms)
      .pipe(
        tap(() => {
          this.newWfaFormIdControl.setValue('')
          this.uploadedJson = undefined
          this.unlockModeControl.setValue(unlockModeDict.fromMongoDb)
          this.unlockModeControl.updateValueAndValidity()
        }),
        untilDestroyed(this),
      )
      .subscribe(updateInfos(this.zsSupportService))
  }

  publishStandardForms() {
    const praxisId = this.zsSupportService.praxisDetails$?.value?._id

    sumbitStandardForms(praxisId, this.comms)
      .pipe(untilDestroyed(this))
      .subscribe(updateInfos(this.zsSupportService))
  }
}

function extractFormVersionInfos(
  response: GetWfaFormRouteResponse200,
): Maybe<WfaFormVersionInfo[]> {
  if (!Array.isArray(response?.queryResult)) return null
  return response?.queryResult.map((f: WfaForm) => ({
    wfaFormVersionId: f.wfaFormVersionId,
    title: f.title,
  }))
}
