import Joi from 'joi'

export const nonHslaAttributes = [
  'modeColor',
  'mainValue',
  'mainSaturation',
  'mainLigtness',
  'primaryColorValues',
] as const

export const NonHslaAttributesJoi = Joi.string()
  .valid(...nonHslaAttributes)
  .meta({
    className: 'NonHslaAttributes',
  })
  .example('modeColor')
