import Joi from 'joi'

import { LocStringJoi } from '../loc-string.joi'
import { BaseQuestionJoi } from './base-question.joi'
import { example } from './examples/wfa-boolean-question.joi.example'

// https://surveyjs.io/form-library/documentation/api-reference/boolean-question-model
export const WfaBooleanQuestionJoi = BaseQuestionJoi.keys({
  labelTrue: LocStringJoi.optional(),
  labelFalse: LocStringJoi.optional(),
  type: Joi.string().valid('boolean').required(),
  swapOrder: Joi.boolean().optional(),
})
  .meta({
    className: 'WfaBooleanQuestion',
  })
  .example(example)
