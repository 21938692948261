import { Theming, WfaInstanceSettings } from 'shared-assets/vendor/ad-colors/src';
import { BaseLanguage } from "./I18N.entity";


/**
 * All Instance-Settings (e.g. smtp, papp,..)
 */
export interface InstanceSettings {
  readonly general: InstanceGeneral
  readonly smtp: InstanceSmtp
  readonly papp: InstancePapp
  readonly legal: InstanceLegal
}


export interface InstanceGeneral {
  readonly encryptionMode: InstanceEncryptionMode
  readonly arztPraxisMode: InstanceArztPraxisMode
  readonly waitingListModules: InstanceWaitingListModules[]
  readonly patientDocumentModules: InstancePatientDocumentModules[]
  readonly plannedAvailabilityEnabled: boolean
  readonly plannedAvailabilitySumArztTypes: boolean
  readonly plannedAvailabilityShowAsOnline: boolean
  readonly plannedAvailabilityShowDoctors: boolean
  readonly isFacharztPortalDisabled: boolean
  readonly closeUnoccupiedWaitingRoom: boolean
  readonly splitFormsByInsurance: boolean
  readonly theming: Theming
  readonly konsilShowInWaitroom: boolean
  readonly hideCookieDialog?: boolean
  readonly wfaInstanceSettings?: WfaInstanceSettings
  internationalization: InstanceSettingsInternationalization
  readonly useDefaultVssBackground?: boolean
}

export interface InstanceSettingsInternationalization {
  // baseLanguageDefault = default language used by the instance's patients
  baseLanguageDefault: BaseLanguage
  // baseLanguagesActive = languages the instance's patients can choose from.
  // The default language is displayed at the top of the list followed by this list (order is important)
  // Language selection is only visible if size>1.
  baseLanguagesActive: BaseLanguage[]
}


export interface InstanceSmtp {
  readonly service?: string
  readonly host: string
  readonly port: number
  readonly user: string
  readonly password: string
  readonly from: string
  readonly replyTo?: string
  readonly senderName?: string
}


export interface InstancePapp {
  readonly pappIsRequired: boolean
  readonly localPappPassword: string
  readonly localPappIdentifier: string
  readonly privateKey?: string
  readonly tomedoPappIdentifier: string
  readonly tomedoPappLogin: string
  readonly certificate?: string
  readonly coupled: boolean
}


export interface InstanceLegal {
  readonly customImprintText: string
  readonly consentForm: {
    readonly isEnabled: boolean
    readonly title: string
    readonly contentHtml: string
  }
  readonly privacyContact: {
    readonly name: string
    readonly company: string
    readonly address_1: string
    readonly address_2: string
    readonly zip: string
    readonly city: string
    readonly country: string
    readonly email: string
    readonly phone: string
  }
}


/**
 * Helper-Types
 */

/**
 * there are 2 different decryption modes which can be set in the instance configuration panel.
 * "arzt-only" decrypts only patient data that the particular Arzt treated?
 * "instance-wide" decrypts all patients?
 * I'm not sure about the differences, but anyways, Max said "arzt-only" is actually
 * a legacy feature, so simply always use "instance-wide".
 */
export enum InstanceEncryptionMode {
  ArztOnly = 'arzt-only',
  InstanceWide = 'instance-wide',
}

export enum InstanceArztPraxisMode {
  UserDefined = 'user-defined',
  InstanceDefined = 'instance-defined',
  // AllowBoth = 'allow-both',
}

export enum InstanceWaitingListModules {
  AllPatients = 'all-patients',
  NextPatient = 'next-patient',
}

export enum InstancePatientDocumentModules {
  Atteste = 'atteste',
  Rezepte = 'rezepte',
}

