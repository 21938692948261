import { nullish } from '@arzt-direkt/wfa-generic-utils'
import { get } from '@arzt-direkt/wfa-generic-utils'

import { WfaForm } from './../interfaces-autogen-from-joi/wfa-form'
import { WfaQuestion } from './../interfaces-autogen-from-joi/wfa-question/wfa-question'
import { SurveyJsElement, SurveyJsItem, WfaPage } from './viewer.interfaces'

interface QuestionWithPath {
  question: WfaQuestion
  keyPath: string[]
}

type MaybeWithPath<T extends boolean> = T extends true
  ? QuestionWithPath
  : WfaQuestion

export function getQuestions<T extends boolean = false>(
  wfaForm: Pick<WfaForm, 'pages'>,
  returnKeyPath = false as T,
): MaybeWithPath<T>[] {
  const wfaPages: WfaPage[] = get(wfaForm, 'pages') ?? []
  if (wfaPages.length === 0) return []

  return wfaPages.flatMap((page, pageIndex) =>
    getPageQuestions<T>(page, returnKeyPath, ['pages', pageIndex.toString()]),
  )
}

function getPageQuestions<T extends boolean>(
  wfaPage: WfaPage,
  returnKeyPath: T,
  basePath: string[] = [],
): MaybeWithPath<T>[] {
  return getSurveyItemQuestions<T>(wfaPage, returnKeyPath, basePath)
}

export function getSurveyItemQuestions<T extends boolean>(
  surveyItem: SurveyJsItem,
  returnKeyPath: T,
  basePath: string[] = [],
): MaybeWithPath<T>[] {
  const elements: SurveyJsElement[] = get(surveyItem, 'elements')
  if (nullish(elements)) return []

  return elements.flatMap((element, index) => {
    const currentPath = [...basePath, 'elements', index.toString()]
    if (elementIsPanel(element)) {
      return getSurveyItemQuestions<T>(element, returnKeyPath, currentPath)
    }
    return returnKeyPath ? { question: element, keyPath: currentPath } : element
  })
}

export function elementIsPanel(element: SurveyJsElement): boolean {
  return element['type'] === 'panel'
}
