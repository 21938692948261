import { InstanceService } from '@a-d/instance/instance.service';
import { NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import slugify from '@sindresorhus/slugify';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UsefulComponent } from '../../misc/useful.component';
import { ADValidators } from '../validators/a-d-validators';


@Component({
  selector: 'app-slug-field-component',
  host: { 'class': 'c-form__row' },
  template: `
  <mat-form-field [formGroup]="formGroup" appearance="outline">
    <mat-label>{{label}}</mat-label>

    <input matInput [formControlName]="name" spellcheck="false" [required]="required">

    <mat-hint *ngIf="hint" align="start">{{ hint }}</mat-hint>
    <mat-hint *ngIf="maxLength" align="end">
      <span [class.isWarning]="slug.errors && (slug.errors['maxlength'] || slug.errors['minlength'])">
        {{ (slug.value || '').length}} / {{ maxLength }}
      </span>
    </mat-hint>

    <mat-error *ngIf="slug.errors && slug.errors['slugInvalid']">Wert ungültig</mat-error>
    <mat-error *ngIf="slug.errors && slug.errors['pattern']">Wert bereits vergeben oder unzulässig</mat-error>
    <mat-error *ngIf="slug.errors && slug.errors['identifierDuplicate']">Kennung bereits vergeben</mat-error>


    <button mat-icon-button *ngIf="helpTooltip" matSuffix [matTooltip]="helpTooltip">
      <!--<fa-icon [icon]="['far', 'question-circle']" style="color: rgba(0, 0, 0, 0.54);"></fa-icon>-->
      <mat-icon style="color: rgba(0, 0, 0, 0.54);">info_outline</mat-icon>
    </button>

  </mat-form-field>
  `,
  standalone: true,
  imports: [MatFormFieldModule, FormsModule, ReactiveFormsModule, MatInputModule, NgIf, MatButtonModule, MatTooltipModule, FontAwesomeModule, MatIconModule]
})
export class SlugFieldComponent extends UsefulComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup
  @Input() name: string
  @Input() label: string
  @Input() maxLength: number
  @Input() minLength: number
  @Input() blacklist: string[]
  @Input() required: boolean
  @Input() hint: string
  @Input() helpTooltip: string
  @Input() uniqueIdentifierCheck: boolean = false

  public get slug(): AbstractControl { return this.formGroup.get(this.name) }

  constructor(
    private cd: ChangeDetectorRef,
    private instanceService: InstanceService
  ) {
    super()
  }


  ngOnInit() {
    super.ngOnInit()

    this.initValidators()

    // Transforms the input-value to a valid slug periodically
    this.slug.valueChanges.pipe(
      debounceTime(1000),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (!this.slug.touched) this.slug.markAsTouched()
      const validSlug = slugify(this.slug.value || '')
      if (this.slug.value === validSlug) return
      this.slug.setValue(validSlug, { emitEvent: false })
    })

  }

  ngOnChanges() {
    this.initValidators()
  }


  /**
   * Initializes Slug-Validation
   */
  private initValidators() {
    // Build Blacklist-Pattern if given
    let blacklistPattern
    if (this.blacklist && this.blacklist.length) {
      blacklistPattern = `^(?!(${this.blacklist.join('|')})$).*$`
    }

    // Set validators
    this.slug.setValidators([
      ADValidators.slug,
      ...(this.required ? [Validators.required] : []),
      ...(this.maxLength ? [Validators.maxLength(this.maxLength)] : []),
      ...(this.minLength ? [Validators.minLength(this.minLength)] : []),
      ...(blacklistPattern ? [Validators.pattern(blacklistPattern)] : []),
    ])
    if (this.uniqueIdentifierCheck) this.slug.addAsyncValidators(ADValidators.uniqueIdentifier(this.instanceService))

    this.cd.detectChanges()
  }

}
