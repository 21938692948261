import { Injectable } from '@angular/core';
import { js2xml } from 'xml-js';
import {
  PappAttachment, PappHandshakeRequest, PappHandshakeResponse, PappMessageAcknowledgement, PappMessageHeader
} from '../../../entities/Papp.entity';
import { PappHandshakeResult, PappSessionAsset, PappSessionCompleted } from './../../../entities/Papp.entity';
import { PatientSessionTomedoDataToEncrypt } from './../../../entities/PatientSessionCreation.entity';

@Injectable({
  providedIn: 'root'
})
export class XmlToJsonService {
  private ROOT_MESSAGE_HEADER = 'MessageHeader';
  private ROOT_MESSAGE_ACKNOWLEDGE = 'MessageAcknowledgement';
  private ROOT_MESSAGE_ATTACHMENT = 'Attachments';
  private ROOT_HANDSHAKE_REQUEST = 'PatientHandshakeRequest';
  private ROOT_HANDSHAKE_RESPONSE = 'PatientHandshakeResponse';
  private ROOT_HANDSHAKE_RESULT = 'PatientHandshakeResult';
  private ROOT_WAITROOM_PATIENT = 'PatientData';
  private ROOT_WAITROOM_ASSET = 'PatientAsset';

  constructor() { }

  public buildMessageHeader(header: PappMessageHeader): string {
    return this.buildXml(header, this.ROOT_MESSAGE_HEADER);
  }

  public buildMessageAcknowledgement(acknowledgement: PappMessageAcknowledgement) {
    return this.buildXml(acknowledgement, this.ROOT_MESSAGE_ACKNOWLEDGE);
  }

  public buildAttachment(attachments: PappAttachment[]) {
    return this.buildXml(attachments, this.ROOT_MESSAGE_ATTACHMENT);
  }

  public buildHandshakeRequest(request: PappHandshakeRequest) {
    return this.buildXml(request, this.ROOT_HANDSHAKE_REQUEST);
  }

  public buildHandshakeResponse(response: PappHandshakeResponse) {
    return this.buildXml(response, this.ROOT_HANDSHAKE_RESPONSE);
  }

  public buildHandshakeResult(result: PappHandshakeResult) {
    return this.buildXml(result, this.ROOT_HANDSHAKE_RESULT);
  }

  public buildPatientData(data: PatientSessionTomedoDataToEncrypt | PappSessionCompleted) {
    return this.buildXml(data, this.ROOT_WAITROOM_PATIENT);
  }

  public buildPatientAsset(data: PappSessionAsset) {
    return this.buildXml(data, this.ROOT_WAITROOM_ASSET);
  }

  private buildXml(jsonData: any, rootName: string) {
    const jsonWrapper = {
      [rootName]: jsonData
    };
    return js2xml(jsonWrapper, { compact: true, ignoreComment: true, spaces: 4 });
  }

  /**
   * Returns a xml2js JSON-Format to create xml-tags like:
   * `<diseaseSelection title="Symptomauswahl" index="0">Halsschmerzen</diseaseSelection>`
   *
   * Documentation of format: https://github.com/Leonidas-from-XIV/node-xml2js
   */
  public formatXmlObjectWithTitles<T>(data: T, titles: { [s: string]: string }, addTypes?: boolean): T {
    const newData = Object.assign({}, data)

    Object.entries(newData).forEach(([key, value], index) => {
      const title = titles?.[key]

      // Determine type
      let type: 'boolean' | 'date' | 'gender';
      if (addTypes) {
        if (typeof value === 'boolean') type = 'boolean'
        if (typeof value === 'string' && /^\d{4}[./-]\d{2}[./-]\d{2}$/.test(value)) type = 'date'
        if (key === 'gender') type = 'gender'
      }

      // Eventually transform value
      if (value && Array.isArray(value)) {
        value = value.join(', ')
      } else {
        value = value ? value : ''
      }
      newData[key] = {
        _: value,
        $: {
          index,
          ...(title ? { title } : null),
          ...(type ? { type } : null),
        },
      }
    })

    return newData
  }
}
