export function applyToValues<Key extends string, In, Out>(
  func: (entry: In) => Out,
  obj: Record<Key, In>
): Record<Key, Out> {
  const newEntries = (Object.keys(obj) as Key[]).map((key: Key) => [
    key,
    func(obj[key]),
  ])
  return Object.fromEntries(newEntries) as Record<Key, Out>
}
