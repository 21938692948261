import { Component, Input, OnInit } from '@angular/core';
import { HomeWhitelabelSectionInterface } from '../home-whitelabel-section.interface';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-whitelabel-section-medikonsil-direkt',
    templateUrl: './whitelabel-section-medikonsil-direkt.component.html',
    styleUrls: ['./whitelabel-section-medikonsil-direkt.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, TranslateModule]
})
export class WhitelabelSectionMedikonsilDirektComponent implements OnInit, HomeWhitelabelSectionInterface {

  @Input() mode: 'patient' | 'arzt'

  // public title = 'WHITELABEL-SECTION-MEDIKONSIL-DIREKT.TITLE'
  public subtitle = 'WHITELABEL-SECTION-MEDIKONSIL-DIREKT.SUBTITLE'
  public imageUrl = 'assets/whitelabeling/medikonsil-direkt/medikonsil-direkt--tagline--colored@2x.png'

  constructor() { }

  ngOnInit(): void {
  }

}
