import { PropertyGridDict } from '@arzt-direkt/wfa-definitions'

/**
 * All grid properties currently provided by SurveyJS.
 * For custom properties see `webforms-angular/src/app/shared-surveyjs/property-customization/custom-properties`.
 */
export const allGridProperties: PropertyGridDict = {
  survey: {
    general: {
      name: 'off',
      title: 'on',
      showTitle: 'off',
      description: 'on',
      locale: 'off',
      mode: 'off',
      cookieName: 'off',
      widthMode: 'off',
      width: 'off',
    },
    logo: {
      logo: 'off',
      logoWidth: 'off',
      logoHeight: 'off',
      logoFit: 'off',
    },
    navigation: {
      showNavigationButtons: 'off',
      showPrevButton: 'off',
      showProgressBar: 'off',
      showTOC: 'off',
      tocLocation: 'off',
      goNextPageAutomatic: 'off',
      allowCompleteSurveyAutomatic: 'off',
      pagePrevText: 'off',
      pageNextText: 'off',
      completeText: 'off',
      previewText: 'off',
      editText: 'off',
      firstPageIsStarted: 'off',
      questionsOnPageMode: 'off',
      showPreviewBeforeComplete: 'off',
    },
    question: {
      focusFirstQuestionAutomatic: 'off',
      questionsOrder: 'off',
      showQuestionNumbers: 'off',
      questionStartIndex: 'off',
      questionTitleLocation: 'off',
      questionDescriptionLocation: 'off',
      questionErrorLocation: 'off',
      maxTextLength: 'off',
      maxOthersLength: 'off',
      autoGrowComment: 'off',
      allowResizeComment: 'off',
      requiredText: 'off',
      questionTitlePattern: 'off',
    },
    page: {
      showPageTitles: 'off',
      showPageNumbers: 'off',
    },
    logic: {
      calculatedValues: 'off',
      triggers: 'off',
    },
    data: {
      sendResultOnPageNext: 'off',
      storeOthersAsComment: 'off',
      clearInvisibleValues: 'off',
      textUpdateMode: 'off',
    },
    validation: {
      focusOnFirstError: 'off',
      checkErrorsMode: 'off',
    },
    showOnCompleted: {
      completedHtml: 'off',
      completedBeforeHtml: 'off',
      completedHtmlOnCondition: 'off',
      loadingHtml: 'off',
      navigateToUrl: 'off',
      navigateToUrlOnCondition: 'off',
      showCompletedPage: 'off',
    },
    timer: {
      maxTimeToFinish: 'off',
      maxTimeToFinishPage: 'off',
      showTimerPanel: 'off',
      showTimerPanelMode: 'off',
    },
  },
  page: {
    general: {
      name: 'off',
      title: 'on',
      description: 'on',
      visible: 'off',
      readOnly: 'off',
      maxTimeToFinish: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      requiredIf: 'off',
    },
    layout: {
      questionTitleLocation: 'off',
      questionsOrder: 'off',
      questionErrorLocation: 'off',
      navigationButtonsVisibility: 'off',
    },
  },
  /**
   * The following question types are basic question types released for medical practices.
   * If you switch on properties that were previously switched off, please communicate with the tomedo server and the AD app.
   */
  text: {
    general: {
      name: 'off',
      title: 'on',
      description: 'on',
      visible: 'off',
      isRequired: 'on',
      readOnly: 'off',
      inputType: 'on',
      autocomplete: 'off',
      placeholder: 'off',
      /* inputType text */
      dataList: 'off',
      /* inputType date, datetime-local, month, number, range, time, week */
      min: 'on',
      max: 'on',
      /* inputType number, range */
      step: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
      requiredIf: 'on',
      /* inputType date, datetime-local, month, number, range, time, week */
      minValueExpression: 'off',
      maxValueExpression: 'off',
    },
    inputMaskSettings: {
      maskType: 'off',
      maskSettings: 'off',
    },
    layout: {
      state: 'off',
      width: 'off',
      maxWidth: 'off',
      minWidth: 'off',
      startWithNewLine: 'on',
      indent: 'off',
      titleLocation: 'off',
      descriptionLocation: 'off',
      hideNumber: 'off',
      errorLocation: 'off',
      size: 'off',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'off',
      valueName: 'off',
      defaultValue: 'off',
      correctAnswer: 'off',
      clearIfInvisible: 'off',
      textUpdateMode: 'off',
    },
    validation: {
      requiredErrorText: 'off',
      validators: 'on',
      minErrorText: 'off',
      maxErrorText: 'off',
      maxLength: 'off',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
  comment: {
    general: {
      name: 'off',
      visible: 'off',
      title: 'on',
      description: 'on',
      isRequired: 'on',
      readOnly: 'off',
      placeholder: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
      requiredIf: 'on',
    },
    layout: {
      state: 'off',
      width: 'off',
      minWidth: 'off',
      maxWidth: 'off',
      startWithNewLine: 'on',
      indent: 'off',
      titleLocation: 'off',
      descriptionLocation: 'off',
      hideNumber: 'off',
      errorLocation: 'off',
      rows: 'off',
      autoGrow: 'off',
      allowResize: 'off',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'off',
      valueName: 'off',
      defaultValue: 'off',
      correctAnswer: 'off',
      clearIfInvisible: 'off',
      textUpdateMode: 'off',
    },
    validation: {
      requiredErrorText: 'off',
      validators: 'on',
      maxLength: 'off',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
  radiogroup: {
    general: {
      name: 'off',
      title: 'on',
      description: 'on',
      visible: 'off',
      isRequired: 'on',
      readOnly: 'off',
      showCommentArea: 'off',
      commentPlaceholder: 'off',
    },
    choices: {
      choices: 'on',
      choicesFromQuestion: 'off',
      choicesFromQuestionMode: 'off',
      choicesOrder: 'off',
      showOtherItem: 'off',
      otherPlaceholder: 'off',
      otherText: 'off',
      separateSpecialChoices: 'off',
      showNoneItem: 'off',
      noneText: 'off',
      showClearButton: 'off',
    },
    choicesByUrl: {
      choicesByUrl: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
      requiredIf: 'on',
      hideIfChoicesEmpty: 'off',
      choicesVisibleIf: 'off',
      choicesEnableIf: 'off',
    },
    layout: {
      state: 'off',
      width: 'off',
      maxWidth: 'off',
      minWidth: 'off',
      startWithNewLine: 'on',
      indent: 'off',
      titleLocation: 'off',
      descriptionLocation: 'off',
      hideNumber: 'off',
      errorLocation: 'off',
      colCount: 'on',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'off',
      valueName: 'off',
      defaultValue: 'off',
      correctAnswer: 'off',
      clearIfInvisible: 'off',
    },
    validation: {
      requiredErrorText: 'off',
      validators: 'on',
      otherErrorText: 'off',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
  checkbox: {
    general: {
      name: 'off',
      title: 'on',
      description: 'on',
      visible: 'off',
      isRequired: 'on',
      readOnly: 'off',
      showCommentArea: 'off',
      commentPlaceholder: 'off',
    },
    choices: {
      choices: 'on',
      choicesFromQuestion: 'off',
      choicesFromQuestionMode: 'off',
      choicesOrder: 'off',
      separateSpecialChoices: 'off',
      showOtherItem: 'off',
      showNoneItem: 'off',
      otherPlaceholder: 'off',
      noneText: 'off',
      otherText: 'off',
      showSelectAllItem: 'off',
      maxSelectedChoices: 'off',
      minSelectedChoices: 'off',
      selectAllText: 'off',
    },
    choicesByUrl: {
      choicesByUrl: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
      requiredIf: 'on',
      hideIfChoicesEmpty: 'off',
      choicesVisibleIf: 'off',
      choicesEnableIf: 'off',
    },
    layout: {
      state: 'off',
      width: 'off',
      maxWidth: 'off',
      minWidth: 'off',
      startWithNewLine: 'on',
      indent: 'off',
      titleLocation: 'off',
      descriptionLocation: 'off',
      hideNumber: 'off',
      errorLocation: 'off',
      colCount: 'on',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'off',
      valueName: 'off',
      defaultValue: 'off',
      correctAnswer: 'off',
      clearIfInvisible: 'off',
      valuePropertyName: 'off',
    },
    validation: {
      requiredErrorText: 'off',
      validators: 'on',
      otherErrorText: 'off',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
  dropdown: {
    general: {
      name: 'off',
      title: 'on',
      description: 'on',
      visible: 'off',
      isRequired: 'on',
      readOnly: 'off',
      showCommentArea: 'off',
      commentPlaceholder: 'off',
      autocomplete: 'off',
      placeholder: 'off',
    },
    choices: {
      choices: 'on',
      choicesFromQuestion: 'off',
      choicesFromQuestionMode: 'off',
      choicesOrder: 'off',
      showOtherItem: 'off',
      otherPlaceholder: 'off',
      otherText: 'off',
      separateSpecialChoices: 'off',
      showNoneItem: 'off',
      noneText: 'off',
      allowClear: 'off',
      choicesMin: 'off',
      choicesMax: 'off',
      choicesStep: 'off',
      searchMode: 'off',
    },
    choicesByUrl: {
      choicesByUrl: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
      requiredIf: 'on',
      hideIfChoicesEmpty: 'off',
      choicesVisibleIf: 'off',
      choicesEnableIf: 'off',
    },
    layout: {
      state: 'off',
      width: 'off',
      maxWidth: 'off',
      minWidth: 'off',
      startWithNewLine: 'on',
      indent: 'off',
      titleLocation: 'off',
      descriptionLocation: 'off',
      hideNumber: 'off',
      errorLocation: 'off',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'off',
      valueName: 'off',
      defaultValue: 'off',
      correctAnswer: 'off',
      clearIfInvisible: 'off',
    },
    validation: {
      requiredErrorText: 'off',
      validators: 'on',
      otherErrorText: 'off',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
  tagbox: {
    general: {
      name: 'off',
      title: 'on',
      description: 'on',
      visible: 'off',
      isRequired: 'on',
      readOnly: 'off',
      showCommentArea: 'off',
      commentPlaceholder: 'off',
      closeOnSelect: 'off',
      placeholder: 'off',
    },
    choices: {
      choices: 'on',
      choicesFromQuestion: 'off',
      choicesFromQuestionMode: 'off',
      choicesOrder: 'off',
      separateSpecialChoices: 'off',
      showOtherItem: 'off',
      showNoneItem: 'off',
      otherPlaceholder: 'off',
      noneText: 'off',
      otherText: 'off',
      showSelectAllItem: 'off',
      maxSelectedChoices: 'off',
      minSelectedChoices: 'off',
      selectAllText: 'off',
      allowClear: 'off',
      searchEnabled: 'off',
      hideSelectedItems: 'off',
      searchMode: 'off',
    },
    choicesByUrl: {
      choicesByUrl: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
      requiredIf: 'on',
      hideIfChoicesEmpty: 'off',
      choicesVisibleIf: 'off',
      choicesEnableIf: 'off',
    },
    layout: {
      state: 'off',
      width: 'off',
      maxWidth: 'off',
      minWidth: 'off',
      startWithNewLine: 'on',
      indent: 'off',
      titleLocation: 'off',
      descriptionLocation: 'off',
      hideNumber: 'off',
      errorLocation: 'off',
      colCount: 'off',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'off',
      valueName: 'off',
      defaultValue: 'off',
      correctAnswer: 'off',
      clearIfInvisible: 'off',
      valuePropertyName: 'off',
    },
    validation: {
      requiredErrorText: 'off',
      validators: 'on',
      otherErrorText: 'off',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
  html: {
    general: {
      name: 'off',
      visible: 'off',
      html: 'on',
    },
    logic: {
      visibleIf: 'on',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
    },
    layout: {
      minWidth: 'off',
      maxWidth: 'off',
      startWithNewLine: 'on',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
  signaturepad: {
    general: {
      name: 'off',
      visible: 'off',
      title: 'on',
      description: 'on',
      isRequired: 'on',
      readOnly: 'off',
      signatureWidth: 'off',
      signatureHeight: 'off',
      signatureAutoScaleEnabled: 'off',
      penMinWidth: 'off',
      penMaxWidth: 'off',
      allowClear: 'off',
      showPlaceholder: 'off',
      placeholder: 'on',
      backgroundImage: 'on',
      penColor: 'off',
      backgroundColor: 'off',
      dataFormat: 'off',
      storeDataAsText: 'off',
      waitForUpload: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
      requiredIf: 'on',
    },
    layout: {
      state: 'off',
      width: 'off',
      minWidth: 'off',
      maxWidth: 'off',
      startWithNewLine: 'on',
      indent: 'off',
      titleLocation: 'off',
      descriptionLocation: 'off',
      hideNumber: 'off',
      errorLocation: 'off',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'off',
      valueName: 'off',
      clearIfInvisible: 'off',
    },
    validation: {
      requiredErrorText: 'off',
      validators: 'off',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
  /**
   * The following question types are not part of the basic types released for medical practices. All their properties are currently switched on (except for `name`)!
   * If one of these question types is released for medical practices, you need to consider which properties are to be switched off.
   * Communicate newly released question types and their properties to the tomedo server and the AD app.
   */
  boolean: {
    general: {
      name: 'off',
      visible: 'on',
      title: 'on',
      description: 'on',
      isRequired: 'on',
      readOnly: 'on',
      showCommentArea: 'on',
      commentPlaceholder: 'on',
      labelTrue: 'on',
      labelFalse: 'on',
      swapOrder: 'on',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'on',
      resetValueIf: 'on',
      setValueIf: 'on',
      setValueExpression: 'on',
      defaultValueExpression: 'on',
      requiredIf: 'on',
    },
    layout: {
      state: 'on',
      width: 'on',
      minWidth: 'on',
      maxWidth: 'on',
      startWithNewLine: 'on',
      indent: 'on',
      titleLocation: 'on',
      descriptionLocation: 'on',
      hideNumber: 'on',
      errorLocation: 'on',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'on',
      valueName: 'on',
      defaultValue: 'on',
      correctAnswer: 'on',
      clearIfInvisible: 'on',
      valueTrue: 'on',
      valueFalse: 'on',
    },
    validation: {
      requiredErrorText: 'on',
      validators: 'on',
    },
    others: {
      readonlyRenderAs: 'on',
    },
  },
  expression: {
    general: {
      name: 'on',
      visible: 'on',
      title: 'on',
      description: 'on',
      expression: 'on',
      format: 'on',
      displayStyle: 'on',
      maximumFractionDigits: 'on',
      minimumFractionDigits: 'on',
      useGrouping: 'on',
    },
    logic: {
      visibleIf: 'on',
      resetValueIf: 'on',
      setValueIf: 'on',
      setValueExpression: 'on',
    },
    layout: {
      state: 'on',
      width: 'on',
      minWidth: 'on',
      maxWidth: 'on',
      startWithNewLine: 'on',
      indent: 'on',
      titleLocation: 'on',
      descriptionLocation: 'on',
      hideNumber: 'on',
      errorLocation: 'on',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'on',
      valueName: 'on',
      clearIfInvisible: 'on',
      precision: 'on',
    },
    validation: {
      validators: 'on',
    },
    others: {
      readonlyRenderAs: 'on',
    },
  },
  image: {
    general: {
      name: 'off',
      visible: 'on',
      imageLink: 'on',
      altText: 'on',
      contentMode: 'on',
      imageFit: 'on',
      imageHeight: 'on',
      imageWidth: 'on',
    },
    logic: {
      visibleIf: 'on',
      resetValueIf: 'on',
      setValueIf: 'on',
      setValueExpression: 'on',
      defaultValueExpression: 'on',
    },
    layout: {
      state: 'on',
      width: 'on',
      minWidth: 'on',
      maxWidth: 'on',
      startWithNewLine: 'on',
      indent: 'on',
      descriptionLocation: 'on',
      hideNumber: 'on',
      errorLocation: 'on',
    },
    others: {
      readonlyRenderAs: 'on',
    },
  },
  imagepicker: {
    general: {
      name: 'off',
      visible: 'on',
      title: 'on',
      description: 'on',
      isRequired: 'on',
      readOnly: 'on',
      showCommentArea: 'on',
      commentPlaceholder: 'on',
      contentMode: 'on',
      imageFit: 'on',
      imageHeight: 'on',
      imageWidth: 'on',
      minImageWidth: 'on',
      minImageHeight: 'on',
      maxImageWidth: 'on',
      maxImageHeight: 'on',
      showLabel: 'on',
      multiSelect: 'on',
    },
    choices: {
      choices: 'on',
      choicesFromQuestion: 'on',
      choicesFromQuestionMode: 'on',
      choicesOrder: 'on',
    },
    choicesByUrl: {
      choicesByUrl: 'on',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'on',
      resetValueIf: 'on',
      setValueIf: 'on',
      setValueExpression: 'on',
      defaultValueExpression: 'on',
      requiredIf: 'on',
      hideIfChoicesEmpty: 'on',
      choicesVisibleIf: 'on',
      choicesEnableIf: 'on',
    },
    layout: {
      state: 'on',
      width: 'on',
      minWidth: 'on',
      maxWidth: 'on',
      startWithNewLine: 'on',
      indent: 'on',
      titleLocation: 'on',
      descriptionLocation: 'on',
      hideNumber: 'on',
      errorLocation: 'on',
      colCount: 'on',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'on',
      valueName: 'on',
      defaultValue: 'on',
      correctAnswer: 'on',
      clearIfInvisible: 'on',
    },
    validation: {
      requiredErrorText: 'on',
      validators: 'on',
    },
    others: {
      readonlyRenderAs: 'on',
    },
  },
  matrix: {
    general: {
      name: 'off',
      visible: 'on',
      title: 'on',
      description: 'on',
      isRequired: 'on',
      readOnly: 'on',
      showCommentArea: 'on',
      commentPlaceholder: 'on',
    },
    columns: {
      columns: 'on',
    },
    rows: {
      rows: 'on',
      rowsOrder: 'on',
      hideIfRowsEmpty: 'on',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'on',
      resetValueIf: 'on',
      setValueIf: 'on',
      setValueExpression: 'on',
      defaultValueExpression: 'on',
      requiredIf: 'on',
      columnsVisibleIf: 'on',
      rowsVisibleIf: 'on',
    },
    layout: {
      state: 'on',
      width: 'on',
      minWidth: 'on',
      maxWidth: 'on',
      startWithNewLine: 'on',
      indent: 'on',
      titleLocation: 'on',
      descriptionLocation: 'on',
      hideNumber: 'on',
      errorLocation: 'on',
      columnMinWidth: 'on',
      showHeader: 'on',
      verticalAlign: 'on',
      alternateRows: 'on',
      rowTitleWidth: 'on',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'on',
      valueName: 'on',
      defaultValue: 'on',
      correctAnswer: 'on',
      clearIfInvisible: 'on',
    },
    validation: {
      requiredErrorText: 'on',
      validators: 'on',
      isAllRowRequired: 'on',
      eachRowUnique: 'on',
    },
    cells: {
      cells: 'on',
    },
    others: {
      readonlyRenderAs: 'on',
    },
  },
  matrixdropdown: {
    general: {
      name: 'off',
      visible: 'on',
      title: 'on',
      description: 'on',
      isRequired: 'on',
      readOnly: 'on',
      showCommentArea: 'on',
      commentPlaceholder: 'on',
      detailPanelMode: 'on',
      placeholder: 'on',
      cellType: 'on',
      totalText: 'on',
    },
    columns: {
      columns: 'on',
    },
    rows: {
      rows: 'on',
      hideIfRowsEmpty: 'on',
    },
    choices: {
      choices: 'on',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'on',
      resetValueIf: 'on',
      setValueIf: 'on',
      setValueExpression: 'on',
      defaultValueExpression: 'on',
      requiredIf: 'on',
      columnsVisibleIf: 'on',
      rowsVisibleIf: 'on',
    },
    layout: {
      state: 'on',
      width: 'on',
      minWidth: 'on',
      maxWidth: 'on',
      startWithNewLine: 'on',
      indent: 'on',
      titleLocation: 'on',
      descriptionLocation: 'on',
      hideNumber: 'on',
      errorLocation: 'on',
      columnMinWidth: 'on',
      showHeader: 'on',
      verticalAlign: 'on',
      alternateRows: 'on',
      transposeData: 'on',
      cellErrorLocation: 'on',
      columnColCount: 'on',
      rowTitleWidth: 'on',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'on',
      valueName: 'on',
      defaultValue: 'on',
      correctAnswer: 'on',
      clearIfInvisible: 'on',
    },
    validation: {
      requiredErrorText: 'on',
      validators: 'on',
      keyDuplicationError: 'on',
    },
    others: {
      readonlyRenderAs: 'on',
    },
  },
  matrixdynamic: {
    general: {
      name: 'off',
      visible: 'on',
      title: 'on',
      description: 'on',
      isRequired: 'on',
      readOnly: 'on',
      showCommentArea: 'on',
      commentPlaceholder: 'on',
      detailPanelMode: 'on',
      placeholder: 'on',
      cellType: 'on',
      allowAddRows: 'on',
      allowRemoveRows: 'on',
      allowRowsDragAndDrop: 'on',
      rowCount: 'on',
      minRowCount: 'on',
      maxRowCount: 'on',
      confirmDelete: 'on',
      confirmDeleteText: 'on',
      addRowLocation: 'on',
      addRowText: 'on',
      removeRowText: 'on',
      detailPanelShowOnAdding: 'on',
    },
    columns: {
      columns: 'on',
      hideColumnsIfEmpty: 'on',
      emptyRowsText: 'on',
    },
    choices: {
      choices: 'on',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'on',
      resetValueIf: 'on',
      setValueIf: 'on',
      setValueExpression: 'on',
      defaultValueExpression: 'on',
      requiredIf: 'on',
      bindings: 'on',
      columnsVisibleIf: 'on',
    },
    layout: {
      state: 'on',
      width: 'on',
      minWidth: 'on',
      maxWidth: 'on',
      startWithNewLine: 'on',
      indent: 'on',
      titleLocation: 'on',
      descriptionLocation: 'on',
      hideNumber: 'on',
      errorLocation: 'on',
      columnMinWidth: 'on',
      showHeader: 'on',
      verticalAlign: 'on',
      alternateRows: 'on',
      transposeData: 'on',
      cellErrorLocation: 'on',
      columnColCount: 'on',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'on',
      valueName: 'on',
      defaultValue: 'on',
      correctAnswer: 'on',
      clearIfInvisible: 'on',
      defaultRowValue: 'on',
      defaultValueFromLastRow: 'on',
    },
    validation: {
      requiredErrorText: 'on',
      validators: 'on',
      keyDuplicationError: 'on',
      keyName: 'on',
    },
    others: {
      readonlyRenderAs: 'on',
    },
  },
  multipletext: {
    general: {
      name: 'off',
      visible: 'on',
      title: 'on',
      description: 'on',
      isRequired: 'on',
      readOnly: 'on',
    },
    items: {
      items: 'on',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'on',
      resetValueIf: 'on',
      setValueIf: 'on',
      setValueExpression: 'on',
      defaultValueExpression: 'on',
      requiredIf: 'on',
    },
    layout: {
      state: 'on',
      width: 'on',
      minWidth: 'on',
      maxWidth: 'on',
      startWithNewLine: 'on',
      indent: 'on',
      titleLocation: 'on',
      descriptionLocation: 'on',
      hideNumber: 'on',
      errorLocation: 'on',
      itemSize: 'on',
      colCount: 'on',
      itemTitleWidth: 'on',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'on',
      valueName: 'on',
      defaultValue: 'on',
      correctAnswer: 'on',
      clearIfInvisible: 'on',
    },
    validation: {
      requiredErrorText: 'on',
      validators: 'on',
    },
    others: {
      readonlyRenderAs: 'on',
    },
  },
  panel: {
    general: {
      name: 'off',
      visible: 'on',
      readOnly: 'on',
      title: 'on',
      description: 'on',
      isRequired: 'on',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'on',
      requiredIf: 'on',
    },
    layout: {
      questionTitleLocation: 'on',
      questionsOrder: 'on',
      questionErrorLocation: 'on',
      state: 'on',
      startWithNewLine: 'on',
      width: 'on',
      minWidth: 'on',
      maxWidth: 'on',
      innerIndent: 'on',
    },
    numbering: {
      showNumber: 'on',
      showQuestionNumbers: 'on',
      questionStartIndex: 'on',
    },
    validation: {
      requiredErrorText: 'on',
    },
  },
  paneldynamic: {
    general: {
      name: 'off',
      visible: 'on',
      title: 'on',
      description: 'on',
      isRequired: 'on',
      readOnly: 'on',
      showCommentArea: 'on',
      commentPlaceholder: 'on',
      templateTitle: 'on',
      templateDescription: 'on',
      noEntriesText: 'on',
      allowAddPanel: 'on',
      allowRemovePanel: 'on',
      panelCount: 'on',
      minPanelCount: 'on',
      maxPanelCount: 'on',
      confirmDelete: 'on',
      confirmDeleteText: 'on',
      panelAddText: 'on',
      panelRemoveText: 'on',
      panelPrevText: 'on',
      panelNextText: 'on',
      panelsState: 'on',
      showQuestionNumbers: 'on',
      showRangeInProgress: 'on',
      renderMode: 'on',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'on',
      resetValueIf: 'on',
      setValueIf: 'on',
      setValueExpression: 'on',
      defaultValueExpression: 'on',
      requiredIf: 'on',
      bindings: 'on',
      templateVisibleIf: 'on',
    },
    layout: {
      state: 'on',
      width: 'on',
      minWidth: 'on',
      maxWidth: 'on',
      startWithNewLine: 'on',
      indent: 'on',
      titleLocation: 'on',
      descriptionLocation: 'on',
      hideNumber: 'on',
      errorLocation: 'on',
      newPanelPosition: 'on',
      templateTitleLocation: 'on',
      templateErrorLocation: 'on',
      panelRemoveButtonLocation: 'on',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'on',
      valueName: 'on',
      defaultValue: 'on',
      correctAnswer: 'on',
      clearIfInvisible: 'on',
      defaultValueFromLastPanel: 'on',
      templateErrorLocation: 'on',
    },
    validation: {
      requiredErrorText: 'on',
      validators: 'on',
      keyName: 'on',
      keyDuplicationError: 'on',
    },
    others: {
      readonlyRenderAs: 'on',
    },
  },
  ranking: {
    general: {
      name: 'off',
      visible: 'on',
      title: 'on',
      description: 'on',
      isRequired: 'on',
      readOnly: 'on',
      showCommentArea: 'on',
      commentPlaceholder: 'on',
      selectToRankEnabled: 'on',
      selectToRankEmptyRankedAreaText: 'on',
      selectToRankEmptyUnrankedAreaText: 'on',
    },
    choices: {
      choices: 'on',
      choicesFromQuestion: 'on',
      choicesFromQuestionMode: 'on',
      choicesOrder: 'on',
    },
    choicesByUrl: {
      choicesByUrl: 'on',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'on',
      resetValueIf: 'on',
      setValueIf: 'on',
      setValueExpression: 'on',
      defaultValueExpression: 'on',
      requiredIf: 'on',
      hideIfChoicesEmpty: 'on',
      choicesVisibleIf: 'on',
      choicesEnableIf: 'on',
    },
    layout: {
      state: 'on',
      width: 'on',
      minWidth: 'on',
      maxWidth: 'on',
      startWithNewLine: 'on',
      indent: 'on',
      titleLocation: 'on',
      descriptionLocation: 'on',
      hideNumber: 'on',
      errorLocation: 'on',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'on',
      valueName: 'on',
      defaultValue: 'on',
      correctAnswer: 'on',
      clearIfInvisible: 'on',
      valuePropertyName: 'on',
    },
    validation: {
      requiredErrorText: 'on',
      validators: 'on',
    },
    others: {
      readonlyRenderAs: 'on',
    },
  },
  rating: {
    general: {
      name: 'off',
      visible: 'on',
      title: 'on',
      description: 'on',
      isRequired: 'on',
      readOnly: 'on',
      showCommentArea: 'on',
      commentPlaceholder: 'on',
    },
    rateValues: {
      rateType: 'on',
      autoGenerate: 'on',
      rateCount: 'on',
      rateValues: 'on',
      rateMax: 'on',
      rateMin: 'on',
      rateStep: 'on',
      minRateDescription: 'on',
      maxRateDescription: 'on',
      rateDescriptionLocation: 'on',
      displayMode: 'on',
      displayRateDescriptionsAsExtremeItems: 'on',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'on',
      resetValueIf: 'on',
      setValueIf: 'on',
      setValueExpression: 'on',
      defaultValueExpression: 'on',
      requiredIf: 'on',
    },
    layout: {
      state: 'on',
      width: 'on',
      minWidth: 'on',
      maxWidth: 'on',
      startWithNewLine: 'on',
      indent: 'on',
      titleLocation: 'on',
      descriptionLocation: 'on',
      hideNumber: 'on',
      errorLocation: 'on',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'on',
      valueName: 'on',
      defaultValue: 'on',
      correctAnswer: 'on',
      clearIfInvisible: 'on',
    },
    validation: {
      requiredErrorText: 'on',
      validators: 'on',
    },
    others: {
      readonlyRenderAs: 'on',
    },
  },
  /**
   * This question type is also not yet released for medical practices.
   * However, the file upload is based on the specifications from arzt-direkt.
   * To ensure that the file upload is handled consistently everywhere, only certain properties may be adjusted by the user.
   * For this reason, certain properties are already switched off here.
   */
  file: {
    general: {
      name: 'off',
      visible: 'off',
      title: 'on',
      description: 'on',
      isRequired: 'on',
      readOnly: 'off',
      showCommentArea: 'off',
      commentPlaceholder: 'off',
      showPreview: 'off',
      allowMultiple: 'off',
      allowImagesPreview: 'off',
      imageHeight: 'off',
      imageWidth: 'off',
      acceptedTypes: 'off',
      storeDataAsText: 'off',
      waitForUpload: 'off',
      maxSize: 'off',
      needConfirmRemoveFile: 'off',
      sourceType: 'on',
      fileOrPhotoPlaceholder: 'off',
      photoPlaceholder: 'off',
      filePlaceholder: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
      requiredIf: 'on',
    },
    layout: {
      state: 'off',
      width: 'off',
      minWidth: 'off',
      maxWidth: 'off',
      startWithNewLine: 'on',
      indent: 'off',
      titleLocation: 'off',
      descriptionLocation: 'off',
      hideNumber: 'off',
      errorLocation: 'off',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'off',
      valueName: 'off',
      clearIfInvisible: 'off',
    },
    validation: {
      requiredErrorText: 'off',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
  /**
   * Contains properties applied to `choicesByUrl` (radiogroup, checkbox, dropdown, tagbox, imagepicker, ranking)
   */
  choicesByUrl: {
    url: 'on',
    path: 'on',
    valueName: 'on',
    titleName: 'on',
    allowEmptyResponse: 'on',
  },
  /**
   * Contains properties that are applied to
   * `choices` (radiogroup, checkbox, dropdown, tagbox, imagepicker, matrixdropdown, matrixdynamic, ranking)
   * `rows` (matrix, matrixdropdown),
   * `columns` (matrix)
   * `rateValues` (rating)
   */
  itemvalue: {
    value: 'off',
    text: 'on',
    visibleIf: 'off',
    enableIf: 'off',
  },
  /**
   * Contains properties that are applied to
   * `columns` (matrixdropdown, matrixdynamic)
   */
  matrixdropdowncolumn: {
    general: {
      name: 'on',
      title: 'on',
      cellType: 'on',
      isRequired: 'on',
      isUnique: 'on',
      readOnly: 'on',
      minWidth: 'on',
      width: 'on',
      visible: 'on',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'on',
      requiredIf: 'on',
      resetValueIf: 'on',
      setValueIf: 'on',
      setValueExpression: 'on',
      defaultValueExpression: 'on',
    },
    totals: {
      totalType: 'on',
      totalExpression: 'on',
      totalFormat: 'on',
      totalDisplayStyle: 'on',
      totalCurrency: 'on',
    },
  },
  /**
   * Contains properties that are applied to
   * `items` (multipletext)
   */
  multipletextitem: {
    name: 'on',
    isRequired: 'on',
    placeholder: 'on',
    inputType: 'on',
    title: 'on',
    maxLength: 'on',
    size: 'on',
    requiredErrorText: 'on',
    minValueExpression: 'on',
    maxValueExpression: 'on',
    validators: 'on',
  },
  /**
   * The following question types are custom question types.
   * They are released for medical practices and are particularly important for writing fields back into patient files in tomedo® (Rückschrieb).
   */
  'academic-title': {
    general: {
      name: 'off',
      title: 'on',
      description: 'on',
      visible: 'off',
      isRequired: 'on',
      readOnly: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
      requiredIf: 'on',
    },
    layout: {
      state: 'off',
      width: 'off',
      minWidth: 'off',
      maxWidth: 'off',
      startWithNewLine: 'on',
      indent: 'off',
      titleLocation: 'off',
      descriptionLocation: 'off',
      hideNumber: 'off',
      errorLocation: 'off',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'off',
      valueName: 'off',
      defaultValue: 'off',
      correctAnswer: 'off',
      clearIfInvisible: 'off',
    },
    validation: {
      requiredErrorText: 'off',
      validators: 'off',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
  birthdate: {
    general: {
      name: 'off',
      title: 'on',
      description: 'on',
      visible: 'off',
      isRequired: 'on',
      readOnly: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
      requiredIf: 'on',
    },
    layout: {
      state: 'off',
      width: 'off',
      minWidth: 'off',
      maxWidth: 'off',
      startWithNewLine: 'on',
      indent: 'off',
      titleLocation: 'off',
      descriptionLocation: 'off',
      hideNumber: 'off',
      errorLocation: 'off',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'off',
      valueName: 'off',
      defaultValue: 'off',
      correctAnswer: 'off',
      clearIfInvisible: 'off',
    },
    validation: {
      requiredErrorText: 'off',
      validators: 'off',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
  contact: {
    general: {
      name: 'off',
      title: 'on',
      description: 'on',
      visible: 'off',
      isRequired: 'on',
      readOnly: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
      requiredIf: 'on',
    },
    layout: {
      state: 'off',
      width: 'off',
      minWidth: 'off',
      maxWidth: 'off',
      startWithNewLine: 'on',
      indent: 'off',
      titleLocation: 'off',
      descriptionLocation: 'off',
      hideNumber: 'off',
      errorLocation: 'off',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'off',
      valueName: 'off',
      defaultValue: 'off',
      correctAnswer: 'off',
      clearIfInvisible: 'off',
    },
    validation: {
      requiredErrorText: 'off',
      validators: 'off',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
  country: {
    general: {
      name: 'off',
      title: 'on',
      description: 'on',
      visible: 'off',
      isRequired: 'on',
      readOnly: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
      requiredIf: 'on',
    },
    layout: {
      state: 'off',
      width: 'off',
      minWidth: 'off',
      maxWidth: 'off',
      startWithNewLine: 'on',
      indent: 'off',
      titleLocation: 'off',
      descriptionLocation: 'off',
      hideNumber: 'off',
      errorLocation: 'off',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'off',
      valueName: 'off',
      defaultValue: 'off',
      correctAnswer: 'off',
      clearIfInvisible: 'off',
    },
    validation: {
      requiredErrorText: 'off',
      validators: 'off',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
  gender: {
    general: {
      name: 'off',
      title: 'on',
      description: 'on',
      visible: 'off',
      isRequired: 'on',
      readOnly: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
      requiredIf: 'on',
    },
    layout: {
      state: 'off',
      width: 'off',
      minWidth: 'off',
      maxWidth: 'off',
      startWithNewLine: 'on',
      indent: 'off',
      titleLocation: 'off',
      descriptionLocation: 'off',
      hideNumber: 'off',
      errorLocation: 'off',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'off',
      valueName: 'off',
      defaultValue: 'off',
      correctAnswer: 'off',
      clearIfInvisible: 'off',
    },
    validation: {
      requiredErrorText: 'off',
      validators: 'off',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
  'sending-information': {
    general: {
      name: 'off',
      title: 'on',
      description: 'on',
      visible: 'off',
      isRequired: 'on',
      readOnly: 'off',
    },
    logic: {
      visibleIf: 'on',
      enableIf: 'off',
      resetValueIf: 'off',
      setValueIf: 'off',
      setValueExpression: 'off',
      defaultValueExpression: 'off',
      requiredIf: 'on',
    },
    layout: {
      state: 'off',
      width: 'off',
      minWidth: 'off',
      maxWidth: 'off',
      startWithNewLine: 'on',
      indent: 'off',
      titleLocation: 'off',
      descriptionLocation: 'off',
      hideNumber: 'off',
      errorLocation: 'off',
    },
    data: {
      useDisplayValuesInDynamicTexts: 'off',
      valueName: 'off',
      defaultValue: 'off',
      correctAnswer: 'off',
      clearIfInvisible: 'off',
    },
    validation: {
      requiredErrorText: 'off',
      validators: 'off',
    },
    others: {
      readonlyRenderAs: 'off',
    },
  },
}
