import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import {
  faCheck,
  faCopy,
  faPenToSquare,
  faThumbTack,
  faThumbTackSlash,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'app-action-buttons',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    FontAwesomeModule,
    TranslateModule,
  ],
  templateUrl: './action-buttons.component.html',
})
export class ActionButtonsComponent {
  @Input() showPublish = false
  @Input() showPermalink = false
  @Input() permalink = ''
  @Output() onPublish = new EventEmitter<void>()
  @Output() onCopy = new EventEmitter<void>()
  @Output() onEdit = new EventEmitter<void>()
  @Output() onDelete = new EventEmitter<void>()
  @Output() onPermalink = new EventEmitter<void>()

  readonly faCheck = faCheck
  readonly faCopy = faCopy
  readonly faPenToSquare = faPenToSquare
  readonly faTrash = faTrash
  readonly faThumbTack = faThumbTack
  readonly faThumbTackSlash = faThumbTackSlash
}
