<form class="c-form c-form--fullHeight c-form--largerSpacing" [formGroup]="formGroup">

  <mat-form-field attr.data-selenium-id="field-varizen-eltern" appearance="outline">
    <mat-label [innerHTML]="'ANAMNESE-FORM.VARIZEN-HISTORY' | translate"></mat-label>
    <mat-select formControlName="familyHistory">
      <mat-option><em [innerHTML]="'GLOBAL.NO-ENTRY' | translate"></em></mat-option>
      <mat-option value="Nein">{{'GLOBAL.NO' | translate}}</mat-option>
      <mat-option value="Ein Elternteil">{{'ANAMNESE-FORM.VARIZEN-ONE-PARENT' | translate}}</mat-option>
      <mat-option value="Beide Eltern">{{'ANAMNESE-FORM.VARIZEN-BOTH-PARENTS' | translate}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field attr.data-selenium-id="field-varizen" appearance="outline">
    <mat-label [innerHTML]="'ANAMNESE-FORM.VARIZEN-CURRENT' | translate"></mat-label>
    <mat-select formControlName="personalHistory" required>
      <mat-option value="Ja">{{'GLOBAL.YES' | translate}}</mat-option>
      <mat-option value="Nein">{{'GLOBAL.NO' | translate}}</mat-option>
    </mat-select>
  </mat-form-field>

  <app-selection-field-component *ngIf="showPersonalHistorySelection" [formGroup]="formGroup"
    [options]="settings.personalHistoryOptions" [selectionRequired]="false" [descriptionHideUnlessSonstiges]="true"
    [descriptionRequiredIfSonstiges]="false" selectionName="personalHistorySelection"
    descriptionName="personalHistoryDescription" [selectionPlaceholder]="'ANAMNESE-FORM.VARIZEN-FINDINGS' | translate"
    [descriptionPlaceholder]="'ANAMNESE-FORM.VARIZEN-FINDINGS-PLACEHOLDER' | translate">
  </app-selection-field-component>

  <app-selection-field-component [formGroup]="formGroup" [options]="settings.symptomOptions"
    [descriptionHideUnlessSonstiges]="true" [descriptionRequiredIfSonstiges]="false" selectionName="symptomSelection"
    descriptionName="symptomDescription" [selectionPlaceholder]="'ANAMNESE-FORM.VARIZEN-COMPLAINS' | translate"
    [descriptionPlaceholder]="'ANAMNESE-FORM.VARIZEN-COMPLAINS-PLACEHOLDER' | translate">
  </app-selection-field-component>

  <mat-form-field attr.data-selenium-id="field-varizen-seite" appearance="outline">
    <mat-label>
      <span class="hide--onVerySmall" [innerHTML]="'ANAMNESE-FORM.VARIZEN-COMPLAINS-SIDE' | translate"></span>
      <span class="show--onVerySmall" [innerHTML]="'ANAMNESE-FORM.VARIZEN-COMPLAINS-SIDE-SMALL' | translate"></span>
    </mat-label>
    <mat-select formControlName="symptomSide" required>
      <mat-option value="Links">{{'GLOBAL.LEFT' | translate}}</mat-option>
      <mat-option value="Rechts">{{'GLOBAL.RIGHT' | translate}}</mat-option>
      <mat-option value="Beide Seiten">{{'ANAMNESE-FORM.VARIZEN-BOTH-SIDES' | translate}}</mat-option>
    </mat-select>
  </mat-form-field>

</form>