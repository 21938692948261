import {
  TomedoVersion,
  visibilityDict,
  WfaKey,
  wfaKeys,
} from '@arzt-direkt/wfa-definitions'
import { notNullish } from '@arzt-direkt/wfa-generic-utils'
import { Serializer } from 'survey-core'

import { getAllGridProperties } from './all-grid-properties/get-all-grid-properties'
import { getChangedProperties } from './get-changed-properties'
import { PropertyState } from './property-state'

type SurveyElementName = string

export function applyChangedProperties(tv?: TomedoVersion): void {
  const changedProperties = getChangedProperties(getAllGridProperties(tv))

  Object.entries(changedProperties).forEach(([elementName, propertyStates]) => {
    applyChangedElementProperties(elementName, propertyStates)
  })
}

function applyChangedElementProperties(
  elementName: SurveyElementName,
  propertyStates: PropertyState[],
): void {
  propertyStates.forEach((propertyState) => {
    const [key, visibility] = Object.entries(propertyState)[0]
    const property = Serializer.getProperty(elementName, key)

    if (notNullish(property) && visibility === visibilityDict.off)
      property.visible = false
    if (
      notNullish(property) &&
      visibility === visibilityDict.readOnlyIfWfaKey
    ) {
      property.enableIf = (obj: { wfaKey: WfaKey }) =>
        !wfaKeys.includes(obj.wfaKey)
    }
  })
}
