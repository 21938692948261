import { Injectable } from '@angular/core'
import { WfaForm } from '@arzt-direkt/wfa-definitions'
import { ComponentStore } from '@ngrx/component-store'

import { assign } from '../utility/assign'
import { EditorState, initialEditorState } from './editor.state'

@Injectable()
export class EditorStore extends ComponentStore<EditorState> {
  constructor() {
    super(initialEditorState)
  }

  readonly json$ = this.select(({ editorEnv }) => editorEnv.json as WfaForm)
  readonly locale$ = this.select(({ editorEnv }) => editorEnv.locale)
  readonly licenseTier$ = this.select(({ editorEnv }) => editorEnv.licenseTier)

  readonly setLocale = this.updater(assign('editorEnv.locale'))
  readonly setEnv = this.updater(assign('editorEnv'))
  readonly setForm = this.updater(assign('form'))
  readonly setFormIsValid = this.updater(assign('formIsValid'))
}
