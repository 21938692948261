import { OpKontrolleAnamneseFormSettings } from '@a-d/instance-form/anamnese/op-kontrolle/op-kontrolle-anamnese-form.component';
import { InstanceFormSettingsInterface } from '@a-d/instance-form/instance-form-component.interface';
import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InstanceSettingsFormsService } from '../../instance-settings-forms.service';
import { ChipListFieldComponent } from '../../../../../forms/fields/chip-list-field.component';


@Component({
    selector: 'app-op-kontrolle-anamnese-form-settings',
    templateUrl: './op-kontrolle-anamnese-form-settings.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        ChipListFieldComponent,
    ],
})
export class OpKontrolleAnamneseFormSettingsComponent implements InstanceFormSettingsInterface {

  @Input() defaultSettings: OpKontrolleAnamneseFormSettings
  @Input() settings: OpKontrolleAnamneseFormSettings


  /**
   * Form-Group
   */
  public formGroup = new UntypedFormGroup({
    opOptions: new UntypedFormControl([], [Validators.required]),
    surgeonOptions: new UntypedFormControl([], []),
  })

  public get opOptions(): AbstractControl { return this.formGroup.get('opOptions') }
  public get surgeonOptions(): AbstractControl { return this.formGroup.get('surgeonOptions') }


  constructor(
    private instanceSettingsFormsService: InstanceSettingsFormsService,
  ) { }

  onInit() { }


  /**
   * Gathers and returns merged settings-data.
   */
  public getMergedData(completeSettings?: boolean): any {
    return this.instanceSettingsFormsService.getMergedData(this, completeSettings)
  }

}
