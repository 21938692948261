import { idRsaPub } from '../assets/fake-ssh-credentials/id-rsa.pub.wrapper'
import { zollsoftForms } from '../assets/json/zollsoft/_zollsoft-forms.barrel'
import humanReadable from './human-readable.joi.example.json'
import { wfaFormResponse } from './wfa-form-response.example'

export const wfaFormWithResponse = {
  formIsValid: true,
  formResponse: wfaFormResponse,
  form: zollsoftForms.personalData,
  humanReadable: humanReadable,
  certificate: idRsaPub,
}
