<div class="example-header">
  <!-- go to the first (today's) month -->
  <button mat-icon-button class="example-double-arrow" (click)="goToFirstMonth()" [disabled]="isGoToFirstMonthDisabled">
    <span>≪</span>
  </button>
  <!-- go to previous month -->
  <button mat-icon-button (click)="goToPreviousMonth()" [disabled]="isGoToPreviousMonthDisabled">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <!-- label - for instance "August 2022" -->
  <span class="example-header-label">{{periodLabel}}</span>
  <!-- go to next month -->
  <button mat-icon-button (click)="goToNextMonth()" [disabled]="isGoToNextMonthDisabled">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>