import { ICreatorOptions } from 'survey-creator-core'

import previewTheme from '../../shared-surveyjs/themes/theme.editor.preview.json'

export const defaultCreatorOptions: ICreatorOptions = {
  isAutoSave: true,
  maxNestedPanels: 0,
  showDefaultLanguageInPreviewTab: 'all',
  showSaveButton: true,
  showHeaderInEmptySurvey: true,
  showTitlesInExpressions: true,
  showSimulatorInPreviewTab: false,
  showObjectTitles: true,
  theme: previewTheme,
}
