import Joi from 'joi'

import { IdJoi } from '../id.joi'
import { PraxisIdJoi } from '../praxis-id.joi'
import { AnyWfaFormJoi } from '../wfa-form.joi'
import { WfaFormIdJoi } from '../wfa-form-id.joi'
import {
  CopyDynamicWfaFormFormParam,
  CopyDynamicWfaFormParams,
  CopyDynamicWfaFormPreferredParams,
} from './../../interfaces-autogen-from-joi/wfb/copy-dynamic-wfa-form-params'

export const CopyDynamicWfaFormPreferredParamsJoi: Joi.ObjectSchema<CopyDynamicWfaFormPreferredParams> =
  Joi.object({
    praxisId: PraxisIdJoi.required(),
    wfaFormVersionId: IdJoi.required(),
    newWfaFormId: WfaFormIdJoi.required(),
  })
    .description(
      'Used to creates a copy of the form with wfaFormVersionId for praxis praxisId with wfaFormId newWfaFormId',
    )
    .meta({
      className: 'CopyDynamicWfaFormPreferredParams',
    })
    .example({
      praxisId: '65683e1c17a3b10786c413b5',
      wfaFormVersionId: '65683e1c17a3b10786c413b6',
      newWfaFormId: 'my-anamneseformular-allgemein',
    })

export const CopyDynamicWfaFormFormParamJoi: Joi.ObjectSchema<CopyDynamicWfaFormFormParam> =
  Joi.object()
    .keys({
      wfaForm: AnyWfaFormJoi.required(),
    })
    .description(
      'Used to upsert WfaForm, updating its id and wfaFormVersionId.',
    )
    .meta({
      className: 'CopyDynamicWfaFormFormParam',
    })

export const CopyDynamicWfaFormParamsJoi: Joi.AlternativesSchema<CopyDynamicWfaFormParams> =
  Joi.alternatives(
    CopyDynamicWfaFormPreferredParamsJoi.required(),
    CopyDynamicWfaFormFormParamJoi.required(),
  ).meta({
    className: 'CopyDynamicWfaFormParams',
  })
