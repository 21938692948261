import { BookingStep, I18NString_MISSING_VALUE } from '@a-d/entities/Booking.entity';
import { NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription, delay, merge, mergeMap, of } from 'rxjs';
import { LanguageService } from '../../i18n/language.service';
import { BookingService } from '../booking.service';

@UntilDestroy()
@Component({
  selector: 'app-booking-info',
  templateUrl: './booking-info.component.html',
  styleUrls: ['./booking-info.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule],
})
export class BookingInfoComponent implements OnInit {
  public globalInformation: SafeHtml
  subscriptionSetGlobalInformation: Subscription
  subscriptionMagicFill: Subscription
  public imageSource = `${environment.url}/assets/images/instance/vss-info.svg`

  constructor(
    private bookingService: BookingService,
    private languageService: LanguageService,
    private domSanitizer: DomSanitizer,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.setGlobalInformationSubscribe()
    this.magicFillSubscribe()
  }

  setGlobalInformationSubscribe() {
    if (this.subscriptionSetGlobalInformation)
      this.subscriptionSetGlobalInformation.unsubscribe()
    this.subscriptionSetGlobalInformation = merge(this.bookingService.isBookingInitialized$, this.languageService.baseLanguageChangeSubject$)
      .subscribe(() => {
        if (this.bookingService.isBookingInitialized$.value) this.setGlobalInformation()
      })
  }

  private setGlobalInformation() {
    const globalInformationUnsanitized: string = this.languageService.i18NStringToString(this.bookingService.otkUser.globalInformation)
    if (globalInformationUnsanitized && globalInformationUnsanitized !== I18NString_MISSING_VALUE && this.bookingService.otkUser.showGlobalInformation)
      this.globalInformation = this.domSanitizer.bypassSecurityTrustHtml(globalInformationUnsanitized)
    else
      this.bookingService.stepper.next()
  }


  // perform magic fill (fill the fields with preset values)
  magicFillSubscribe() {
    if (this.subscriptionMagicFill)
      this.subscriptionMagicFill.unsubscribe()
    this.subscriptionMagicFill = this.bookingService.magicFill$
      .pipe(untilDestroyed(this))
      .subscribe((magicFill: string) => magicFill === BookingStep.bookingInfo ? this.magicFill() : null)
  }

  magicFill() {
    of(null)
      .pipe(untilDestroyed(this),
        mergeMap(() => {
          this.bookingService.stepper.next()
          return of(null)
        }),
        delay(500),
        mergeMap(() => {
          this.changeDetectorRef.detectChanges()
          // if preliminary is active and if preliminary were not successfully given as parameters,
          // propagate magic fill to it. Otherwise, booking.service.setStepSubscription()
          // will have already set arePrestepsCompleted$ to true, making preliminary vanish,
          // so that it will not propagate magic fill to bs.
          // In this case, if bs is active, propagate magic fill to it, otherwise to type.

          if ((this.bookingService.isIkActive && !this.bookingService.isKnown.value)
            || (this.bookingService.isItActive && !this.bookingService.insuranceType.value))
            this.bookingService.magicFill$.next(BookingStep.bookingPreliminary)
          else if (this.bookingService.isBsActive)
            this.bookingService.magicFill$.next(BookingStep.bookingBs)
          else
            this.bookingService.magicFill$.next(BookingStep.bookingType)
          return of(null)
        })
      ).subscribe()
  }
}
