import { SurveyCreatorModel } from 'survey-creator-core'

import { addCustomEditorProperties } from '../../../shared-surveyjs/property-customization/custom-properties/add-custom-properties'
import { customizeSurveyjsProperties } from '../../../shared-surveyjs/property-customization/surveyjs-properties/customize-surveyjs-properties'
import { handlePropertyGridOnElementChange } from './handle-property-grid-on-element-change'
import { hideSwitchedOffProperties } from './hide-properties/hide-switched-off-properties'
import { addHtmlEditorToPropertyGrid } from './html-editor/add-html-editor-to-property-grid'
import { updatePropertiesOnQuestionChange } from './update-properties-on-question-change'
import { updatePropertiesOnValueChange } from './update-properties-on-value-change/update-properties-on-value-change'
import { validatePropertiesOnValueChange } from './validate-properties-on-value-change'

export function customizePropertyGrid(creator: SurveyCreatorModel): void {
  hideSwitchedOffProperties()
  addCustomEditorProperties()
  customizeSurveyjsProperties()
  updatePropertiesOnValueChange(creator)
  updatePropertiesOnQuestionChange(creator)
  validatePropertiesOnValueChange(creator)
  handlePropertyGridOnElementChange(creator)
  addHtmlEditorToPropertyGrid(creator)
}
