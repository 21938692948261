import 'survey-core/survey.i18n'

import { Component, OnInit } from '@angular/core'
import { MatCardModule } from '@angular/material/card'
import { SurveyModule } from 'survey-angular-ui'
import { Model } from 'survey-core'

import form from './viewer-from-link-error.json'

@Component({
  standalone: true,
  selector: 'app-viewer-from-link-error',
  templateUrl: './viewer-from-link-error.component.html',
  styleUrls: [
    './viewer-from-link-error.component.scss',
    '../../../scss/mat-card.scss',
  ],
  imports: [MatCardModule, SurveyModule],
})
export class ViewerFromLinkErrorComponent implements OnInit {
  survey: Model = new Model(form)

  ngOnInit() {
    this.survey.showNavigationButtons = false
  }
}
