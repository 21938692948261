import { InstanceGeneral } from '@a-d/entities/InstanceSettings.entity';
import { SocketService } from '@a-d/socket/socket.service';
import { ColorService } from '@a-d/theming/service/color.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import mongoose from 'mongoose';
import { NGXLogger } from 'ngx-logger';
import { arrayBufferToString, fromBase64, stringToArrayBuffer, toBase64 } from 'pvutils';
import { BehaviorSubject, from, Observable, of, Subject, throwError } from 'rxjs';
import { first, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { Theming } from 'shared-assets/vendor/ad-colors/src';

import { ActiveInstance } from '../entities/ActiveInstance.entity';
import {
  Dialect, Instance, InstanceActivationStatus,
  InstanceAssets, InstanceModule, InstanceRegistration, InstanceSecurity,
  InstanceStatus, InstanceStatusUpdateRequest,
  InstanceStatusUpdateResponse, InstanceTemplate,
  InstanceUpdate, InstanceUserCertEntry, InstanceUserResponse
} from '../entities/Instance.entity';
import { InstanceSettingsInternationalization } from '../entities/InstanceSettings.entity';
import { UserRegistration } from '../entities/User.entity';
import { CryptoService } from './../crypto/crypto.service';
import { UserDataService } from './../crypto/userdata.service';
import { InstanceCreateResponse, InstanceFullRegistration, InstanceTemplateRegistration, InstanceUserKeyEntry } from './../entities/Instance.entity';
import { User } from './../entities/User.entity';
import { DebugService } from './../logging/debug.service';



@Injectable({
  providedIn: 'root'
})
export class InstanceService implements OnDestroy {

  private unsubscribe$ = new Subject()

  constructor(
    private debugService: DebugService,
    private logger: NGXLogger,
    private socketService: SocketService,
    public colorService: ColorService,
    public cryptoService: CryptoService,
    public dataService: UserDataService,
    public http: HttpClient,
  ) { }


  /**
   * Currently active instance (determined by url)
   */
  private _activeInstance: ActiveInstance
  public get activeInstance(): ActiveInstance { return this._activeInstance }

  public activeInstanceChanged$ = new Subject<ActiveInstance>()

  private activeInstanceFetch$: Observable<ActiveInstance>

  public get activeInstanceIsAdmin(): boolean { return this.activeInstance && this.activeInstance.identifier === 'admin' }

  private _customInstance: Instance
  public get customInstance(): Instance { return this._customInstance }
  public set customInstance(instance: Instance) {
    this._customInstance = instance;
  }

  private activeCurrency = 'EUR'
  public activeCurrencyChanged$ = new BehaviorSubject<string>(this.activeCurrency)

  private updateCurrency(instance: ActiveInstance) {
    this.activeCurrency = (instance?.contact?.country === 'Schweiz') ? 'CHF' : 'EUR';
    this.activeCurrencyChanged$.next(this.activeCurrency);
  }

  updateActiveInstanceFrontendOnly(updates: Partial<ActiveInstance>) {
    if (Object.keys(updates).length !== 0) {
      if (!this._activeInstance) this._activeInstance = updates as ActiveInstance
      else this._activeInstance = Object.assign(this._activeInstance, updates)
      this.activeInstanceChanged$.next(this._activeInstance);
      this.debugService.activeInstance = { debug: this._activeInstance.debug }
      this.updateCurrency(this._activeInstance)
      this.applyTheming()
    }
  }

  resetActiveInstance() {
    this._activeInstance = undefined;
    this.activeInstanceChanged$.next(undefined)
    this.resetTheming()
  }

  applyTheming() {
    if (this.activeInstance.identifier !== 'admin') this.colorService.applyTheming(this.getTheming())
  }

  resetTheming() {
    this.colorService.applyThemingVariables(false);
  }

  /**
   * Returns the UserActivationStatus of the given user-status.
   */
  public getActivationStatus(status: InstanceStatus, isTemplate?: boolean): InstanceActivationStatus {
    if (isTemplate) return InstanceActivationStatus.Template
    switch (status) {
      case InstanceStatus.Registered: return InstanceActivationStatus.Deaktiviert
      case InstanceStatus.Disabled: return InstanceActivationStatus.Deaktiviert
      case InstanceStatus.Blocked: return InstanceActivationStatus.Deaktiviert
      case InstanceStatus.Enabled: return InstanceActivationStatus.Aktiviert
      default: return null
    }
  }

  public getRouteToWaitroom(): string {
    const path = !(this.activeInstance && this.activeInstance.settings && this.activeInstance.settings.general) || this.activeInstance.settings.general.konsilShowInWaitroom ?
      "/dashboard/warteliste" : "/dashboard/konsilwarteliste"
    return this.prependIdentifier(path)
  }


  /**
   * Prepends an `/:instanceIdentifier/` url-segment to the given path.
   */
  public prependIdentifier(path: string) {
    if (!this.activeInstance) {
      return path
    }

    path = path.replace(/^\//, '')
    return `/${this.activeInstance.identifier}/${path}`
  }

  /**
   * Fetches and sets ActiveInstance for given Identifier. (PUBLIC-ACCESS)
   * IMPORTANT: Only needs to be used by the AuthGuards, in all other parts of the app just 'authService.user' can be used.
   */
  public setInstanceByIdentifier(identifier: string): Observable<ActiveInstance> {
    this.resetTheming();
    if (this.activeInstance && this.activeInstance.identifier === identifier) {
      if (this.activeInstance.identifier !== 'admin') this.applyTheming()
      return of(this.activeInstance)
    } else if (!this.activeInstanceFetch$) {
      this.activeInstanceFetch$ = this.fetchInstance({ identifier }).pipe(
        tap((instance) => {
          this.activeInstanceFetch$ = null
          this.updateActiveInstanceFrontendOnly(instance)
          this.socketService.initAndConnect(identifier)
          return this.activeInstance
        }),
      )
    }
    return this.activeInstanceFetch$
  }

  /**
   * Fetches and Instance for given Identifier or ID. (PUBLIC-ACCESS)
   * IMPORTANT: Does not set Instance as active
   */
  public fetchInstance(data: { identifier?: string, _id?: string }): Observable<ActiveInstance> {
    return this.http.post<ActiveInstance>(`${environment.url}/api/instance/get`, data).pipe(
      first(),
      map((response: any) => {
        if (!response || response.status !== 200 || !response.instance) {
          throw { name: 'NoInstanceReceived', error: "Received empty response or no instance.", raw: response }
        }
        return response.instance as ActiveInstance
      }),
    )
  }


  /**
   * Queries Instances with the given params.
   */
  public query(params: any): Observable<Instance[]> {
    return this.http.get<Instance[]>('/api/rest/Instance', { params })
      .pipe(first())
  }



  /**
   * Queries Archived Instances with the given params.
   */
  public queryArchived(params: any): Observable<Instance[]> {
    return this.http.get<Instance[]>('/api/rest/InstanceArchive', { params })
      .pipe(first())
  }


  /**
   * Queries all Instances.
   */
  public queryAll(params?: any): Observable<Instance[]> {
    const mergedParams = Object.assign({
      sort: 'createdAt',
      select: ['_id', 'modules', 'createdAt', 'email', 'status', 'identifier', 'name', 'shortName', 'contact', 'isTomedoUser', 'customerId', 'billing.prices', 'billing.freeTierUntil', 'history', 'seo']
    }, params)

    return this.query(mergedParams)
  }

  /**
  * Queries Instance with given ID.
  */
  public queryById(instanceId: string, archived = false, extraParams: object = {}): Observable<Instance> {
    this.logger.log('Query Instance by Instance-ID: ' + instanceId);

    if (!mongoose.Types.ObjectId.isValid(instanceId)) {
      throw { name: "InvalidInstanceIDError", error: "Given ID is no valid Object ID." }
    }

    const params = {
      query: JSON.stringify(archived
        ? { instanceId: instanceId }
        : { _id: instanceId }
      ),
      limit: 1,
      select: archived
        ? ['-instance.assets']
        : ['-assets'],
      ...extraParams
    }

    const query$ = archived ? this.queryArchived.bind(this) : this.query.bind(this)
    return query$(params).pipe(
      map((instances: Instance[]) => {
        if (!instances || !instances.length) {
          throw { name: "InstanceNotFoundError", error: "Couldn't find instance with given ID", raw: instances }
        } else {
          return instances[0]
        }
      })
    )
  }


  /**
  * Queries Instance with given ID.
  */
  public queryByCustomerId(customerId: number, archived = false, extraParams: object = {}): Observable<Instance> {
    customerId = parseInt((customerId as any))
    if (isNaN(customerId)) return throwError({ name: "InstanceNotFoundError" })

    this.logger.log('Query Instance by Customer-ID: ' + customerId);
    const params = {
      query: JSON.stringify(archived
        ? { 'instance.customerId': customerId }
        : { customerId }
      ),
      limit: 1,
      select: archived
        ? ['-instance.assets']
        : ['-assets'],
      ...extraParams
    }

    const query$ = archived ? this.queryArchived.bind(this) : this.query.bind(this)
    return query$(params).pipe(
      map((instances: Instance[]) => {
        this.logger.log({ instances })
        if (!instances || !instances.length) {
          throw { name: "InstanceNotFoundError", error: "Couldn't find instance with given Customer-ID", raw: instances }
        } else {
          return instances[0]
        }
      })
    )
  }

  /**
   * Fetches  Instance Templates with given Id. if customer true returns first template with given customerId
   */
  public queryTemplateById(id: string | number, customer = false): Observable<InstanceTemplate> {
    const params = {
      query: JSON.stringify(customer ? { customerId: id } : { _id: id }),
      limit: 1,
    }
    return this.http.get<InstanceTemplate[]>('api/rest/InstanceTemplate', { params }).pipe(
      first(),
      map((instanceTemplates) => {
        this.logger.log({ instanceTemplates })
        if (!instanceTemplates || !instanceTemplates.length) {
          throw { name: "InstanceTemplateNotFoundError", error: "Couldn't find instance template with given ID", raw: instanceTemplates }
        } else {
          return instanceTemplates[0]
        }
      })
    )
  }

  /**
   * Fetches all Instance Templates
   */
  public queryAllTemplates(): Observable<InstanceTemplate[]> {
    const params = {
      sort: 'createdAt',
      select: ['_id', 'modules', 'createdAt', 'email', 'isTomedoUser', 'customerId', 'billing.prices', 'billing.freeTierUntil', 'history']
    }

    return this.http.get<InstanceTemplate[]>('api/rest/InstanceTemplate', { params }).pipe(first())
  }


  /**
 * Updates Instance-Template-Data
 */
  public updateTemplateData(templateId: string, updates: any): Observable<InstanceTemplate> {
    const data = {
      _id: templateId,
      ...updates
    }
    this.logger.log('Updating Instance Template..', data);
    return this.http.put<InstanceTemplate>('/api/instance-template/update', { data }).pipe(
      map((response: any) => {
        if (!response || response.status !== 200 || !response.instance) {
          throw { error: 'Error while updating instance data', raw: response }
        }
        return response.instance
      }),
      first()
    )
  }


  /**
   * Updates Instance-Data
   */
  public updateData(instanceId: string, updates: any): Observable<Instance> {
    const data = {
      _id: instanceId,
      ...updates
    }
    this.logger.log('Updating Instance..', data);
    return this.http.put<InstanceUpdate>('/api/instance/update', { data }).pipe(
      map((response: any) => {
        if (!response || response.status !== 200 || !response.instance) {
          throw { error: 'Error while updating instance data', raw: response }
        }
        return response.instance
      }),
      first()
    )
  }


  /**
   * Updates Instance-Status
   */
  public changeStatus(data: InstanceStatusUpdateRequest): Observable<InstanceStatusUpdateResponse> {
    if (!data) return throwError('No data given')

    this.logger.log('Updating Instance-Status..', data);
    return this.http.post('/api/instance/changeStatus', { data }).pipe(
      map((response: InstanceStatusUpdateResponse) => {
        if (!response || response.status !== 200 || !response.data) {
          throw response
        } else {
          return response
        }
      }),
      first()
    )
  }


  /**
   * Downloading Instance-Assets by performing an empty update and returning its response
   */
  public downloadAssets(instanceId: string): Observable<InstanceAssets> {
    return this.uploadAssets(instanceId, {})
  }


  /**
   * Uploading Instance-Assets (esp. Logo-Images) to the server
   */
  public uploadAssets(instanceId: string, assets: InstanceAssets): Observable<InstanceAssets> {
    return this.http.post('/api/instance/uploadAssets', {
      _id: instanceId,
      assets
    }).pipe(
      map((response: any) => {
        if (!response || response.status !== 200 || !response.assets) {
          throw response
        } else {
          return response.assets
        }
      }),
      first()
    )
  }

  /**
   * Load all user certificates associated with the logged in instance
   */
  public loadUsers(): Observable<InstanceUserResponse> {
    return this.http.get<InstanceUserResponse>('/api/instance/users');
  }

  public updateInstancePrivateKey(users: InstanceUserCertEntry[]): Observable<InstanceUserKeyEntry[]> {
    const privateKeyBase64 = toBase64(arrayBufferToString(this.dataService.privateKeyBuffer));
    return from(users).pipe(
      mergeMap((data) => this.encryptKeyForUser(data._id, data.certificate, privateKeyBase64)),
      toArray()
    );
  }

  public bulkUpdateKeys(userKeys: InstanceUserKeyEntry[]) {
    const body = {
      userKeys: userKeys
    };
    return this.http.post('/api/instance/bulkUpdate', body);
  }

  /**
   * Encrypt the instance private key with the users certificate
   */
  private encryptKeyForUser(_id: string, certificate: string, privateKeyBase64: string): Observable<InstanceUserKeyEntry> {
    return new Observable((observer) => {
      if (!certificate || !privateKeyBase64) {
        observer.error({ message: 'Missing data', certificate: certificate, privateKey: privateKeyBase64 });
        observer.complete();
      }
      this.cryptoService.encrypt(stringToArrayBuffer(fromBase64(certificate)), privateKeyBase64)
        .subscribe((encryptedKey) => {
          const keyObj = {
            _id: _id,
            encryptedKey: encryptedKey
          }
          observer.next(keyObj);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        })
    });
  }


  /**
   * Create new instance object
   * @param instance instance to be registered
   */
  public create(instance: InstanceRegistration) {
    return this.http.post<Instance>('/api/instance/create', instance);
  }

  /**
   * Create a new instance template object
   * @param instance necessary minimum template init data
   */
  public createTemplate(instance: InstanceTemplateRegistration) {
    return this.http.post<InstanceTemplate>('/api/instance-template/create', instance);
  }

  /**
   * Create new instance and set all security settings
   * @param registration instance general settings and contact information
   * @param security instance keystore and certificate
   * @param instanceTemplate initial account settings
   * @param verifyPassword verification code
   */
  public initialize(registration: InstanceFullRegistration, security: InstanceSecurity,
    instanceTemplate: string, verifyPassword: string, link: string) {
    return this.http.post<InstanceCreateResponse>('/api/instance-template/initialize',
      { registration, security, instanceTemplate, verifyPassword, link });
  }

  /**
   * Create an initial user account for 1-account setups
   * @param user full qualified user data
   * @param instanceId associated instance identification number
   * @param templateId original instance template id
   * @param verifyPassword verification code
   */
  public initialUserRegistration(user: UserRegistration, instanceId: string, templateId: string, verifyPassword: string, link: string) {
    this.logger.log('my instance id', instanceId)
    return this.http.post<User>('/api/instance-template/userRegistration', { user, instanceId, templateId, verifyPassword, link });
  }

  /**
   * Load instance template for specified link
   * @param link url query parameter
   */
  public getTemplate(link: string): Observable<InstanceTemplate> {
    return this.http.post<InstanceTemplate>('/api/instance-template/load', { link })
  }


  /**
   * Resend verification email to the instance owner
   * @param instanceId id of instance entry
   */
  public resendInstanceVerification(instanceId: string): Observable<any> {
    const body = {
      instanceId: instanceId
    };
    return this.http.post('/api/instance/resendVerification', body)
  }

  /**
   * Resend verification email to the owner of the instance which is to be created by instance template
   * @param instanceId id of instance entry
   */
  public resendInstanceTemplateVerification(templateId: string): Observable<any> {
    const body = {
      templateId
    };
    return this.http.post('/api/instance-template/resendVerification', body)
  }


  /**
   * Set initial crypto keys and password for the instance
   * @param securityData password and keystore information for the instance
   */
  public initSecurity(securityData: InstanceSecurity): Observable<any> {
    return this.http.put('/api/instance/initialize', securityData);
  }


  /**
   * Load the public instance certificate
   * @param identifier instance url identifier
   */
  public getCertificate(identifier: string) {
    this.logger.log(`Loading public certificate of '${identifier}'..`)
    const params = new HttpParams().set('identifier', identifier);
    return this.http.get<any>('/api/instance/certificate', { params });
  }


  /**
   * Returns true if active instance-dialect is not for ärzte (e.g. mitarbeiter/apotheker)
   */
  public isNonArztDialect(instance: ActiveInstance | Instance = this.activeInstance): boolean {
    return !!instance?.dialect && instance?.dialect !== Dialect.Deaktiviert && instance?.dialect !== Dialect.Arzt && instance?.dialect !== Dialect.Behandler
  }

  /**
 * Returns true if active instance-dialect is for heilpraktiker
 */
  public isHeilpraktikerDialect(instance: ActiveInstance | Instance = this.activeInstance): boolean {
    return instance?.dialect === Dialect.Heilpraktiker
  }

  /**
   * Returns true if active instance-dialect is not medical (e.g. mitarbeiter/kanzLaw)
   */
  public isNonMedicalDialect(instance: ActiveInstance | Instance = this.activeInstance): boolean {
    return instance?.dialect && (instance?.dialect === Dialect.Mitarbeiter || instance?.dialect === Dialect.KanzLaw)
  }

  /**
   * Reset instancefetch in case instance not found
   */
  public resetInstanceFetch() {
    this.activeInstanceFetch$ = null
  }


  /**
   * Returns true if given instance has completely set up tomedo-papp settings
   * Note: does NOT check if papp is correctly configured, only if its configured at all.
   * This means that functions which use if(pappCouplingIsConfigured) before doing
   * their thing might still fail in their execution
   */
  public pappCouplingIsConfigured(instance: ActiveInstance | Instance = this.activeInstance): boolean {
    return !!instance.settings?.papp?.privateKey
  }

  public getTheming(): Theming {
    return this.activeInstance?.settings?.general?.theming
      ? this.activeInstance.settings.general.theming
      : this.colorService.defaultTheming
  }

  public setTheming(theming: Theming, useDefaultVssBackground?: boolean): Observable<Instance> {
    let backgroundSetting = {}
    // Check negated values to interpret null and false as equal
    if (!this.activeInstance.settings.general.useDefaultVssBackground !== !useDefaultVssBackground) backgroundSetting = { useDefaultVssBackground }
    const generalSettings: InstanceGeneral = {
      ...this.activeInstance.settings.general,
      theming,
      ...backgroundSetting
    }
    const updates = {
      settings: { general: generalSettings }
    }
    return this.updateData(this.activeInstance._id, updates)
  }

  public setInternationalization(internationalization: InstanceSettingsInternationalization): Observable<Instance> {
    const generalSettings: InstanceGeneral = {
      ...this.activeInstance.settings.general,
      internationalization
    }
    const updates = {
      settings: { general: generalSettings }
    }
    return this.updateData(this.activeInstance._id, updates)
  }



  /**
   * Reset Instance-SMTP
   */
  public resetSMTP(instanceId: string): Observable<Instance> {

    return this.http.post<InstanceUpdate>('/api/instance/reset-smtp', { instanceId }).pipe(
      map((response: any) => {
        if (!response || response.status !== 200 || !response.instance) {
          throw { error: 'Error while resetting smtp', raw: response }
        }
        return response.instance
      }),
      first()
    )
  }

  /**
   * Activate adPay module
   */

  public activateAdPay(instanceId: string): Observable<InstanceModule[]> {
    return this.http.post<{ status: number, message: string, modules?: InstanceModule[], error?: string }>('api/adyen/activateAdPay', { instanceId }).pipe(
      map((response) => {
        if (!response || response.status !== 200 || !response.modules) {
          throw { error: 'Error while activating adPay', raw: response }
        }
        this.updateActiveInstanceFrontendOnly({ modules: response.modules })
        return response.modules
      }),
      first()
    )
  }


  /**
   * Checks if instance identifier is unique (for validator)
   */
  public checkIdentifierForUniqueness(identifier: string): Observable<boolean | { status: number, message: string }> {
    return this.http.post<boolean | { status: number, message: string }>('api/instance-template/identifier-available', { identifier })
  }

  /**
   * Returns papp account for pairing app <> tomedo
   */
  public getPairingAccount() {
    const pappAccounts = this.activeInstance?.pappAccounts
    const pairingAccount = pappAccounts?.find((account) => !!account.qrContent)
    return pairingAccount
  }


  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }
}
