import 'survey-creator-core/survey-creator-core.i18n'

import { CommonModule } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { WfaForm } from '@arzt-direkt/wfa-definitions'
import { wfaVersion } from '@arzt-direkt/wfa-definitions'
import {
  surveyjsArTsLicense,
  updateMetadata,
} from '@arzt-direkt/wfa-definitions'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
  BehaviorSubject,
  distinctUntilKeyChanged,
  first,
  map,
  Observable,
  skip,
} from 'rxjs'
import { setLicenseKey } from 'survey-core'
import { SurveyCreatorModule } from 'survey-creator-angular'
import { SurveyCreatorModel } from 'survey-creator-core'

import { WfaEnvService } from '../../environments/wfa-env.service'
import { createCreator } from './create-creator'
import { HtmlEditorComponent } from './customization/property-grid-customization/html-editor/html-editor-component/html-editor.component'
import { customizeAfterFormLoadToolboxActions } from './customization/toolbox-customization/customize-toolbox-actions/customize-toolbox-actions'
import { wfaMergeLocaleWithDefault } from './customization/translation-customization/wfa-merge-locale-with-default'
import { EditorDataService } from './editor.data-service'
import { StaticEditorEnv } from './editor.env'
import { STATIC_EDITOR_ENV } from './editor.env'
import { EditorEnv } from './editor.env'
import { EditorStore } from './editor.store'
import { Routing } from './interfaces'
import { SetCreatorParams } from './set-creator-params'

setLicenseKey(surveyjsArTsLicense.key)

@UntilDestroy()
@Component({
  selector: 'wfa-editor',
  standalone: true,
  imports: [CommonModule, SurveyCreatorModule, MatDialogModule],
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [EditorStore, EditorDataService],
})
export class EditorComponent implements OnInit {
  @Input() editorEnv$ = new Observable<EditorEnv>()
  @Input() requestWfaForm$ = new BehaviorSubject<boolean>(true)
  @Output() emitWfaForm: EventEmitter<WfaForm | object> = new EventEmitter<
    WfaForm | object
  >()

  static declaration = [HtmlEditorComponent]
  creator: Maybe<SurveyCreatorModel> = undefined
  wfaVersion = wfaVersion
  tomedoVersion = this.wfaEnvService.getInstance().tomedoVersion

  constructor(
    @Inject(STATIC_EDITOR_ENV) readonly staticEditorEnv: StaticEditorEnv,
    private cd: ChangeDetectorRef,
    private editorStore: EditorStore,
    private editorDataService: EditorDataService,
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private router: Router,
    private wfaEnvService: WfaEnvService,
  ) {}

  ngOnInit() {
    this.editorEnv$
      .pipe(
        first(),
        map((editorEnv) => ({ editorEnv })),
        untilDestroyed(this),
      )
      .subscribe((scp) => {
        console.debug(
          `[EditorComponent/ngOnInit]: subscribe editorEnv$ with `,
          scp,
        )
        const routing: Routing = {
          httpClient: this.httpClient,
          router: this.router,
          staticEditorEnv: this.staticEditorEnv,
          wfaEnvService: this.wfaEnvService,
        }

        this.creator = createCreator(
          this.editorStore,
          scp,
          this.dialog,
          this.editorDataService,
          routing,
        )
      })

    this.editorEnv$
      .pipe(untilDestroyed(this))
      .subscribe(this.editorStore.setEnv)

    this.subToRerenderCreatorOnLicenseTierChange(this.creator)
    this.subToUpdateCreatorOnLocaleChange()
    this.subToUpdateCreatorOnJsonChange()

    this.requestWfaForm$.pipe(untilDestroyed(this)).subscribe(() => {
      this.emitWfaForm.emit(this?.creator?.JSON ?? {})
    })
  }

  rerender = (scp: SetCreatorParams) => {
    this.creator = undefined
    this.cd.detectChanges()
    this.creator = createCreator(
      this.editorStore,
      scp,
      this.dialog,
      this.editorDataService,
      {
        httpClient: this.httpClient,
        router: this.router,
        staticEditorEnv: this.staticEditorEnv,
        wfaEnvService: this.wfaEnvService,
      },
    )
  }

  subToRerenderCreatorOnLicenseTierChange = (
    creator: Maybe<SurveyCreatorModel>,
  ) => {
    this.editorEnv$
      .pipe(
        skip(1),
        distinctUntilKeyChanged('licenseTier'),
        map((editorEnv) => ({ editorEnv, json: creator?.JSON })),
        untilDestroyed(this),
      )
      .subscribe(this.rerender)
  }

  subToUpdateCreatorOnLocaleChange = () => {
    this.editorStore.locale$.pipe(untilDestroyed(this)).subscribe((locale) => {
      if (this.creator) this.creator.locale = locale
    })
  }

  subToUpdateCreatorOnJsonChange = () => {
    this.editorEnv$
      .pipe(
        distinctUntilKeyChanged('json'),
        map((editorEnv) => editorEnv.json),
        untilDestroyed(this),
      )
      .subscribe((json: unknown) => {
        if (this.creator) {
          this.creator.JSON = updateMetadata(json as Partial<WfaForm>)
          wfaMergeLocaleWithDefault(this.creator)
          customizeAfterFormLoadToolboxActions(this.creator)
        }
      })
  }
}
