import { Documentation } from './Documentation.entity';
import { Asset, PatientSessionState } from './PatientSession.entity';
export interface PappRegistration {
  readonly login: string
  readonly mail: string
  readonly company: string
  readonly phoneNumber: string
  readonly faxNumber: string
  readonly address: PappAddress
}

export interface PappAddress {
  readonly line1: string
  readonly line2: string
  readonly city: string
  readonly plz: string
}

export interface PappHref {
  readonly href: string
}

export interface PappLink {
  readonly self: PappHref
}

export interface PappMessagesContainer {
  readonly _embedded: PappEmbeddedMessages
}

export interface PappEmbeddedMessages {
  readonly messages: PappMessage[]
}

export interface PappMessage {
  readonly schemaVersion: number
  readonly receiverIdentifier: string
  readonly header: string
  readonly body: string
  readonly expirationTime: Date
  readonly attachmentIds: string[]
  readonly isEncrypted: boolean
  readonly isPushable: boolean
  readonly createdAt: Date
  readonly updatedAt: Date
  readonly identifier: string
  readonly _links: PappLink
}

export interface PappMessageResponse {
  readonly message: string
  readonly status: number
  readonly messages: PappMessagesContainer
}

export interface PappAsset {
  readonly createdAt: string
  readonly header: string
  readonly identifier: string
  readonly name: string
  readonly updatedAt: string
}

export interface PappAssetResponse {
  readonly message: string,
  readonly status: number,
  readonly data: PappAsset
}

export enum PappTypeValue {
  patientStatus = 'de.papp.model.content.WaitRoomPatientStatus',
  patientInfo = 'de.papp.model.content.WaitRoomPatientInfo',
  patientAsset = 'de.papp.model.content.WaitRoomPatientAsset',
  handshakeRequest = 'de.papp.model.content.PatientHandshakeRequest',
  handshakeResponse = 'de.papp.model.content.PatientHandshakeResponse',
  handshakeResult = 'de.papp.model.content.PatientHandshakeResult',
  messageAcknowledgement = 'de.papp.model.content.MessageAcknowledgement',
  vaccinationCard = 'de.papp.model.content.VaccinationCard',
  textMessage = 'de.papp.model.content.TextMessage'
}

export enum PappAssetUsage {
  anamnese = 'anamnese',
  receipt = 'receipt',
  info = 'info'
}

export interface PappMetaEntry {
  readonly key: string
  readonly value: string
}

export interface PappMetaData {
  readonly entry: PappMetaEntry
}

export interface PappMessageHeader {
  readonly senderId: string
  readonly metadata: PappMetaData
}

export interface PappMessageAcknowledgement {
  readonly messageId: string
  readonly code: number
  readonly details?: string
}

export interface PappAttachment {
  readonly metadata: PappMetaData
  readonly content: string
}

export interface PappHandshakeRequest {
  readonly couplingId: string
  readonly firstname: string
  readonly lastname: string
  readonly birthday: string
}

export interface PappHandshakeResponse {
  readonly couplingId: string
  readonly tan: string
}

export enum PappHandshakeResultTypes {
  accepted = 'ACCEPTED',
  denied = 'DECLINED'
}

export interface PappHandshakeResult {
  readonly type: PappHandshakeResultTypes
}

export interface PappSessionCompleted {
  readonly sessionId: string
  readonly sessionCreatedAt?: Date
  readonly sessionState: PatientSessionState
  readonly sessionDoctorUrl?: string
  readonly sessionDoctorEmail?: string
  readonly sessionDocumentation?: Documentation
  assets?: Asset[]
  readonly instanceId: string
  readonly instanceIdentifier: string
  readonly instanceUrl: string
}

export interface PappSessionAsset {
  readonly sessionId: string
  readonly usage: PappAssetUsage
}

export interface SendPappMessageBody {
  identifier: string,
  assets?: string,
  header: string,
  message: string
}
