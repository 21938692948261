import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoggerModule } from 'ngx-logger';
import { SharedModule } from '../shared.module';
import { MainHeaderComponent } from './header/main-header.component';
import { LogoComponent } from './logo/logo.component';

@NgModule({
    imports: [
        CommonModule,
        LoggerModule,
        SharedModule,
        MainHeaderComponent,
        LogoComponent,
    ],
    exports: [
        MainHeaderComponent,
        LogoComponent
    ]
})
export class ThemingModule { }
