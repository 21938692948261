import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { Injectable, OnDestroy } from "@angular/core";
import { NotificationService } from "@lib/notifications/notification.service";
import dayjs from "dayjs";
import { Observable, of, Subject } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { SocketUserRole } from "../../../entities/Socket.entity";
import { ChannelData, WebRTCConnection, WebRTCConnectionType } from "../../../entities/WebRTC.entity";
import { WebRTCChannelDataType, WebRTCChatMessage, WebRTCChatSyncMessage } from "../../../entities/WebRTCMessage.entity";
import { StorageService } from "../../../storage/storage.service";

@Injectable({ providedIn: 'root' })
export class ChatService implements OnDestroy {
  readonly MAXIMUM_MESSAGE_LENGTH: number = 50
  public receivedMessage$ = new Subject<WebRTCChatMessage>()
  public shareToolMessage$ = new Subject<WebRTCChatMessage>()
  public webRTCChatMessagesChanged$ = new Subject<WebRTCChatMessage>()
  public webRTCChatMessages: WebRTCChatMessage[] = []
  private role: SocketUserRole // if patient, store chat in local storage
  private readonly toolWebRTCConnectionTypes = [
    WebRTCConnectionType.DocTools,
    WebRTCConnectionType.PatientTool,
    WebRTCConnectionType.SpecialistTools,
  ]

  public receivedMessage(): Observable<WebRTCChatMessage> {
    return this.receivedMessage$.asObservable();
  }

  constructor(
    private adLoggerService: AdLoggerService,
    private notificationService: NotificationService,
    private storageService: StorageService,
  ) { }

  public setRole(role: SocketUserRole) {
    this.role = role
  }

  /**
   * loads the chat from local storage.
   * Should only be called by the Patient. */
  public loadWebRTCChatMessages(): Observable<boolean> {
    return new Observable((observer) => {
      this.storageService.getWebRTCChatMessages()
        .subscribe((webRTCChatMessages: WebRTCChatMessage[]) => {
          if (webRTCChatMessages) {
            this.webRTCChatMessages = webRTCChatMessages
            this.receivedMessage$.next(this.getLatestWebRTCChatMessage())
          } else {
            this.webRTCChatMessages = []
          }
          console.log("loadWebRTCChatMessages: this.webRTCChatMessages = ", this.webRTCChatMessages)
          observer.next(true)
          observer.complete()
        })
    })
  }

  public getLatestWebRTCChatMessage(): WebRTCChatMessage {
    const numOfWebRTCChatMessages = Object.keys(this.webRTCChatMessages).length
    return this.webRTCChatMessages[numOfWebRTCChatMessages - 1]
  }

  public webRTCChatMessageToString(webRTCChatMessage: WebRTCChatMessage): string {
    let message: string = webRTCChatMessage.message
    if (webRTCChatMessage.message.length > this.MAXIMUM_MESSAGE_LENGTH) {
      message = webRTCChatMessage.message.substring(0, this.MAXIMUM_MESSAGE_LENGTH) + '…'
    }
    return `[${dayjs().format('HH:mm:ss')}] ${webRTCChatMessage.username}: ${message}`
  }

  public getWebRTCChatMessagesAsStrings(): Observable<string[]> {
    return new Observable((observer) => {
      this.storageService.getWebRTCChatMessages()
        .subscribe((webRTCChatMessages: WebRTCChatMessage[]) => {
          // only the patient has the webRTCChatMessages in local storage.
          // for the Arzt and the Specialist get them from the service.
          if (!webRTCChatMessages) {
            webRTCChatMessages = this.webRTCChatMessages
          }
          if (!webRTCChatMessages) {
            observer.next([])
            observer.complete()
            return
          }
          const webRTCChatMessagesAsStrings: string[] = []
          const webRTCChatMessagesKeys: string[] = Object.keys(webRTCChatMessages)
          const numOfWebRTCChatMessages: number = webRTCChatMessagesKeys.length
          for (let i = 0; i < numOfWebRTCChatMessages; i++) {
            const webRTCChatMessage: WebRTCChatMessage
              = webRTCChatMessages[webRTCChatMessagesKeys[i]]
            webRTCChatMessagesAsStrings.push(
              this.webRTCChatMessageToString(webRTCChatMessage)
            )
          }
          observer.next(webRTCChatMessagesAsStrings)
          observer.complete()
        })
    })
  }

  private areWebRTCMessagesIdentical(webRTCChatMessage1: WebRTCChatMessage, webRTCChatMessage2: WebRTCChatMessage): boolean {
    const result: boolean =
      ((webRTCChatMessage1.createdAt === webRTCChatMessage2.createdAt)
        && (webRTCChatMessage1.message === webRTCChatMessage2.message)
        && (webRTCChatMessage1.role === webRTCChatMessage2.role)
        && (webRTCChatMessage1.type === webRTCChatMessage2.type)
        && (webRTCChatMessage1.username === webRTCChatMessage2.username))
      ||
      ((webRTCChatMessage1.type === WebRTCChannelDataType.File)
        && (webRTCChatMessage2.type === WebRTCChannelDataType.File)
        && (webRTCChatMessage1.fileCreatedAt === webRTCChatMessage2.fileCreatedAt))
    // console.log("areWebRTCMessagesIdentical: result = ", result)
    return result
  }

  private doesWebRTCChatMessageExist(webRTCChatMessage: WebRTCChatMessage): Observable<boolean> {
    return new Observable((observer) => {
      this.storageService.getWebRTCChatMessages()
        .pipe(
          mergeMap((webRTCChatMessages: WebRTCChatMessage[]) => {
            // only the Patient saves the chat in local storage.
            // for the Arzt and Spezialist it is obtained from the service.
            if (!webRTCChatMessages || this.role !== SocketUserRole.Patient) webRTCChatMessages = this.webRTCChatMessages
            if (!webRTCChatMessages) {
              return of(false)
            } else {
              let doesWebRTCChatMessageExist = false
              const webRTCMessagesKeys: string[] = Object.keys(webRTCChatMessages)
              const numOfWebRTCMessages = webRTCMessagesKeys.length
              console.log("-----")
              console.log("doesWebRTCChatMessageExist: numOfWebRTCMessages = ", numOfWebRTCMessages)
              console.log(console.log(JSON.parse(JSON.stringify(webRTCChatMessage))))
              for (let i = 0; i < numOfWebRTCMessages; i++) {
                if (this.areWebRTCMessagesIdentical(
                  webRTCChatMessage,
                  webRTCChatMessages[webRTCMessagesKeys[i]]))
                  doesWebRTCChatMessageExist = true
              }
              // console.log("doesWebRTCChatMessageExist: doesWebRTCChatMessageExist = ", doesWebRTCChatMessageExist)
              return of(doesWebRTCChatMessageExist)
            }
          })
        ).subscribe((doesWebRTCChatMessageExist: boolean) => {
          observer.next(doesWebRTCChatMessageExist)
          observer.complete()
        })
    })
  }

  /**
   * updates the chat with a new message, either incoming or self-written.
   * for the patient it is also saved in local storage.
   */
  public updateChat(webRTCChatMessage: WebRTCChatMessage
  ): Observable<boolean> {
    return new Observable((observer) => {
      of(null)
        .pipe(
          mergeMap(() =>
            this.doesWebRTCChatMessageExist(webRTCChatMessage)
          )
        ).subscribe((doesWebRTCChatMessageExist: boolean) => {
          if (doesWebRTCChatMessageExist) {
            console.log("updateChat: webRTCChatMessage = ", webRTCChatMessage, " already exists")
            observer.next(false)
            observer.complete()
          } else {
            console.log("updateChat: webRTCChatMessage = ", webRTCChatMessage, " does not exist, adding")
            this.webRTCChatMessages.push(webRTCChatMessage)
            //here messages aded
            this.webRTCChatMessagesChanged$.next(webRTCChatMessage)
            if (this.role === SocketUserRole.Arzt && webRTCChatMessage.role === SocketUserRole.Tool) {
              this.shareToolMessage$.next(webRTCChatMessage);
              observer.next(true);
              observer.complete();
              return;
            } else if (this.role === SocketUserRole.Patient) {
              this.storageService.addWebRTCChatMessage(webRTCChatMessage)
                .subscribe(() => {
                  observer.next(true)
                  observer.complete()
                })
            } else {
              observer.next(true)
              observer.complete()
            }
          }
        })
    })
  }

  public syncChat(webRTCConnection: WebRTCConnection) {
    const messageData: WebRTCChatSyncMessage = {
      type: WebRTCChannelDataType.ChatSync,
      createdAt: dayjs().toISOString(),
      chat: this.webRTCChatMessages,
    }
    console.log("syncChat: messageData = ", messageData)
    this.sendMessageToPeers([webRTCConnection], messageData)
  }

  public sendMessageToPeers(
    webRTCConnections: WebRTCConnection[],
    message: object,
    toTool = false
  ) {
    const toolIndex = this.toolWebRTCConnectionTypes.findIndex(
      (type) => type === webRTCConnections[0].type
    );
    webRTCConnections.forEach((webRTCConnection: WebRTCConnection) => {
      console.log("sendMessageToPeers webRTCConnection = ", webRTCConnection)
      console.log("sendMessageToPeers message = ", message)
      if (!toTool && webRTCConnection.type && toolIndex === -1) {
        this.sendChannelMessage(webRTCConnection, message)
      } else if (toTool && webRTCConnection.type && toolIndex !== -1) {
        console.log('SENDING TO TOOL')
        this.sendChannelMessage(webRTCConnection, message);
      }
    });
  }

  private sendChannelMessage(webRTCConnection: WebRTCConnection, message: object) {
    try {
      console.log('sendChannelMessage: webRTCConnection = ', webRTCConnection)
      console.log('sendChannelMessage: message = ', message)

      const channelsData = webRTCConnection.dataChannels
      const channelData = channelsData.find((channelData: ChannelData) => channelData.rtcDataChannel.label === 'data')
      if (!channelData) {
        return console.log('sendChannelMessage: No data channel')
      }
      channelData.rtcDataChannel.send(JSON.stringify(message))
    } catch (error) {
      this.adLoggerService.error('sendChannelMessage: Fehler beim senden der Nachricht', error)
      this.notificationService.displayNotification(
        'Fehler beim Senden der Nachricht'
      )
    }
  }

  /**
   * is called recursively to manage chat update
  */
  public receivedChatUpdate(webRTCChatMessages: WebRTCChatMessage[], i: number = 0, j: number = -1) {
    //j is index of last non file message
    const numOfWebRTCMessages: number = Object.keys(webRTCChatMessages).length
    //last message
    if (i === numOfWebRTCMessages && j != -1) {
      // console.log('receivedChatUpdate: last message: ', webRTCChatMessages[i - 1])
      this.receivedMessage$.next(webRTCChatMessages[j])
      return
    }
    else if (i === numOfWebRTCMessages) return //update contains no non file message
    const webRTCChatMessage: WebRTCChatMessage = webRTCChatMessages[i]
    console.log("receivedChatUpdate: nachrichten:", JSON.stringify(webRTCChatMessages))
    // only updateds for non file message
    if (webRTCChatMessage.type !== WebRTCChannelDataType.File) {
      j = i
      console.log("updateChat: update chat with ", webRTCChatMessages.length)
      this.updateChat(webRTCChatMessage)
        .subscribe((updatedChat: boolean) => {
          // console.log("receivedChatUpdate: i = ", i)
          this.receivedChatUpdate(webRTCChatMessages, i + 1, j)
        })
    } else { //is file message continue with next
      this.receivedChatUpdate(webRTCChatMessages, i + 1, j)
    }
  }

  /* sample input:
   * 0: "[11:22:57]System: Sie sind dem Raum 615ac7cdce4b23375836a026 beigetreten"
   */
  public stringToWebRTCChatMessage(chatMessageAsString: string): WebRTCChatMessage {
    const createdAt: string = chatMessageAsString.substring(1, 9)
    const role: SocketUserRole = chatMessageAsString.substring(10).includes("Patient")
      ? SocketUserRole.Patient
      : SocketUserRole.Arzt
    const username: string = role
    const message: string = chatMessageAsString.split(':')[3]
    const type: WebRTCChannelDataType = WebRTCChannelDataType.Message
    const webRTCChatMessage: WebRTCChatMessage = {
      message,
      role,
      username,
      createdAt,
      type
    }
    return webRTCChatMessage
  }

  /**
   * removes the webRTCChatMessages from "this".
   * Also removes them from local storage for the patient.
   */
  public clear(): Observable<any> {
    return new Observable((observer) => {
      this.storageService.removeWebRTCChatMessages() // only does something for the Patient
        .subscribe(() => {
          this.webRTCChatMessages = []
          observer.next(true)
          observer.complete()
        })
    })
  }

  ngOnDestroy(): void {
    this.webRTCChatMessagesChanged$.complete();
    this.receivedMessage$.complete()
    this.shareToolMessage$.complete()
  }
}
