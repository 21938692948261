import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'
import { Model } from 'survey-core'

import { assign } from '../utility/assign'
import { initialViewerState, ViewerState } from './viewer.state'

@Injectable()
export class ViewerStore extends ComponentStore<ViewerState> {
  constructor() {
    super(initialViewerState)
  }

  readonly locale$ = this.select(({ env }) => env.locale)
  readonly env$ = this.select(({ env }) => env)
  readonly form$ = this.select(({ form }) => form)
  readonly formResponse$ = this.select(({ formResponse }) => formResponse)

  readonly emitResponseData$ = (surveyModel: Model) =>
    this.select(({ form, formResponse, latestResponseOrigin }) => {
      const formIsValid = surveyModel.validate(false)
      return {
        form,
        formResponse,
        formIsValid,
        origin: latestResponseOrigin,
      }
    })

  readonly setLocale = this.updater(assign('env.locale'))
  readonly setEnv = this.updater(assign('env'))
  readonly setForm = this.updater(assign('form'))
  readonly setFormResponse = this.updater(assign('formResponse'))
  readonly setLatestResponseOrigin = this.updater(
    assign('latestResponseOrigin'),
  )
}
