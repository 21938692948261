import { Operator } from '../types/operator'

// Overload signatures for different numbers of functions
export function flow<T1, T2>(f1: (x: T1) => T2): (x: T1) => T2
export function flow<T1, T2, T3>(
  f1: (x: T1) => T2,
  f2: (x: T2) => T3,
): (x: T1) => T3
export function flow<T1, T2, T3, T4>(
  f1: (x: T1) => T2,
  f2: (x: T2) => T3,
  f3: (x: T3) => T4,
): (x: T1) => T4
export function flow<T>(...functions: Operator<T>[]): Operator<T> {
  return (initialValue: T) =>
    functions.reduce((result, fn) => fn(result), initialValue)
}
