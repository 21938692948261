import { COUNTRIES_DB_DE } from '@a-d/forms/fields/country-field-i18n';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry
} from 'postcode-validator';

@Injectable({
  providedIn: 'root',
})
export class ZipValidatorService {
  static getZipValidator(defaultCountry: string, countrySubFormName?: string, useCountryCode?: boolean, emptyCountryNoError?: boolean, countryControlName?: string): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const errorValue = { value: control.value };
      if (!control.value) return null; // 'required' is a separate validator

      // if the country value is "hidden" in a subform, retrieve it through the subform
      // otherwise, the country value is directly accessible from the root form
      const formWithCountryField = countrySubFormName ? control.root?.get(countrySubFormName) : control.root
      let countryField: AbstractControl;
      if (countryControlName) {
        countryField = formWithCountryField?.get(countryControlName);
      } else if (!useCountryCode) {
        countryField = formWithCountryField?.get('country');
      } else {
        countryField = formWithCountryField?.get('countryCode');
      }

      if (emptyCountryNoError && !countryField?.value) return null

      const country = countryField?.value ?? (!useCountryCode ? defaultCountry : ZipValidatorService.countryConverter(defaultCountry));
      const alpha2Code = !useCountryCode ? ZipValidatorService.countryConverter(country) : country;
      const isCodeValid = postcodeValidatorExistsForCountry(alpha2Code);
      const validCode = isCodeValid ? alpha2Code : 'DE';

      const isValid = postcodeValidator(control.value, validCode);
      if (!isValid) return { invalidPlz: errorValue };

      return null;
    };
  }

  static countryConverter(countryName?: string): string {
    if (countryName == null) return 'DE';
    const candidates = COUNTRIES_DB_DE.filter((c) => c.name === countryName);
    if (candidates.length === 1) return candidates[0].alpha2Code;
    return 'DE';
  }

  constructor() { }
}
