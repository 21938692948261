import { arrayBufferToString, stringToArrayBuffer, toBase64 } from 'pvutils'
import { Observable, from } from 'rxjs'
import { envelopData } from './envelop-data'
import {
  arrayBufferToBase64,
  messageToArrayBuffer,
} from '../convert'

export async function encryptBuffer(
  certificateBuffer: ArrayBuffer,
  msgBuffer: ArrayBuffer,
  encryptAlgorithm: Algorithm,
): Promise<string> {
  const encryptedBuffer = await envelopData(
    certificateBuffer,
    msgBuffer,
    encryptAlgorithm,
  ).toPromise()

  const encryptMsgBase64 = await toBase64(arrayBufferToString(encryptedBuffer))
  return encryptMsgBase64
}

/**
 * encrypt a message with the target users public key
 * @param certificateBuffer array buffer of the personal certificate
 * @param message data to encrypt
 */
export function encrypt(
  certificateBuffer: ArrayBuffer,
  message: string,
  encryptAlgorithm: Algorithm,
  useCustomUmlautEncoding: boolean = false,
): Observable<string> {
  return new Observable((observer) => {
    const msgBuffer = useCustomUmlautEncoding
      ? messageToArrayBuffer(message)
      : stringToArrayBuffer(message)
    from(
      encryptBuffer(certificateBuffer, msgBuffer, encryptAlgorithm),
    ).subscribe(
      (encryptMsgBase64) => {
        observer.next(encryptMsgBase64)
        observer.complete()
      },
      (error) => {
        observer.error(error)
      },
    )
  })
}
