import Joi from 'joi'

export const wfaFormIdRegex = /^[a-z0-9][a-z0-9.-]*[a-z0-9]$/

export const RelaxedWfaFormIdJoi: Joi.StringSchema = Joi.string()
  .meta({
    className: 'RelaxedWfaFormId',
  })
  .example('Sähr äöÉÜßige I%D_')

export const WfaFormIdJoi: Joi.StringSchema = Joi.string()
  .pattern(wfaFormIdRegex)
  .description(
    ` * A human-readable survey identifier.
 *
 * This identifier is not necessarily unique in the context of surveys:
 *  - Different praxis-instances can have different surveys with the same wfaFormId.
 *  - A single praxis can have different surveys with the same wfaFormId for different versions or updates of the survey.
 * The wfaFormId must start with a lowercase letter and can be followed by lowercase letters, numbers, dots, or hyphens.
 * @typedef {string} WfaFormId`,
  )
  .meta({
    className: 'WfaFormId',
  })
  .example('personal-data')
