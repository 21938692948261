import { Injectable } from "@angular/core";
import { INGXLoggerConfig, INGXLoggerMetadata, NGXLoggerWriterService } from "ngx-logger";

/**
 * Custom {@link NGXLoggerWriterService} that replaces a leading './' in the
 * path to the file with 'webpack:///'. This fixes the behaviour in the
 * browser console to allow clicking the link to jump to location in the
 * source code.
 */
@Injectable()
export class ADNGXLoggerWriterService extends NGXLoggerWriterService {

  static readonly PATH_START = /^\.\//
  static readonly WEBPACK_PREFIX = 'webpack:///'
  

  protected override getFileDetailsToWrite(metadata: INGXLoggerMetadata, config: INGXLoggerConfig): string {
    if (config.disableFileDetails) {
      return '';
    }
    const { fileName, lineNumber, columnNumber } = metadata;
    const webPackFileName = fileName.replace(ADNGXLoggerWriterService.PATH_START, ADNGXLoggerWriterService.WEBPACK_PREFIX);
    return `[${webPackFileName}:${lineNumber}:${columnNumber}]`;
  }
}