import { MatDialog } from '@angular/material/dialog'
import { Model, settings, surveyLocalization } from 'survey-core'

import { customizeFileUploadQuestion } from '../../shared-surveyjs/file-upload-question-customization/customize-file-upload-question'
import { addCustomViewerProperties } from '../../shared-surveyjs/property-customization/custom-properties/add-custom-properties'
import { customizeSurveyjsProperties } from '../../shared-surveyjs/property-customization/surveyjs-properties/customize-surveyjs-properties'
import { addCustomQuestionTypes } from '../../shared-surveyjs/question-type-customization/add-custom-question-types'
import { customizeTranslations } from '../../shared-surveyjs/translation-customization/customize-translations'
import { addInJsonValidators } from '../../shared-surveyjs/validation-customization/in-json-validation/add-in-json-validators'
import { addOnEventValidators } from '../../shared-surveyjs/validation-customization/on-event-validation/add-on-event-validators'
import { customizePdfClasses } from '../surveyjs-pdf-export/custom-pdf-classes/customize-pdf-classes'
import { initialViewerState } from '../viewer.state'
import { setQuestionConfiguration } from './set-question-configuration'

export function setSurveyConfiguration(
  surveyModel: Model,
  dialog: MatDialog,
): void {
  settings.defaultLocaleName = initialViewerState.env.locale
  settings.lazyRender = { enabled: true, firstBatchSize: 10 }
  surveyLocalization.defaultLocale = initialViewerState.env.locale

  addCustomQuestionTypes()
  addCustomViewerProperties()
  addInJsonValidators()
  addOnEventValidators(surveyModel)
  customizeFileUploadQuestion(surveyModel, dialog, initialViewerState.env)
  customizePdfClasses()
  customizeSurveyjsProperties()
  customizeTranslations()
  setQuestionConfiguration(surveyModel)
}
