import Joi from 'joi'

import { WfaQuestionChoiceJoi } from '../wfb/wfa-question-choice.joi'
import { WfaQuestionValidatorJoi } from '../wfb/wfa-question-validator.joi'
import { BaseQuestionJoi } from './base-question.joi'
import { example } from './examples/wfa-radiogroup-question.joi.example'

export const WfaRadiogroupQuestionJoi = BaseQuestionJoi.keys({
  type: Joi.string().valid('radiogroup').required(),
  validators: Joi.array().items(WfaQuestionValidatorJoi),
  colCount: Joi.number().valid(0, 1, 2, 3, 4, 5),
  choices: Joi.array().items(WfaQuestionChoiceJoi),
})
  .meta({
    className: 'WfaRadiogroupQuestion',
  })
  .example(example)
