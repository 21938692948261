import { MatDialog } from '@angular/material/dialog'
import { SurveyCreatorModel } from 'survey-creator-core'

import { LimitDialogComponent } from './limit-dialog/limit-dialog.component'

export function handleLimitedQuestionTypes(
  creator: SurveyCreatorModel,
  dialog: MatDialog,
) {
  creator.onQuestionAdded.add((_, options) => {
    const question = options.question
    const questionType = question.getType()
    const questionTypesWithLimits = ['file']
    const questionTypeIsLimited = questionTypesWithLimits.includes(questionType)
    if (!questionTypeIsLimited) return

    const questions = creator.survey.getAllQuestions()
    const questionsOfLimitedType = questions.filter(
      (q) => q.getType() === questionType,
    )
    const questionTypeAlreadyExists = questionsOfLimitedType.some(
      (q) => q !== question,
    )

    if (questionTypeAlreadyExists) {
      question.delete()
      dialog.open(LimitDialogComponent, {
        autoFocus: false,
        data: {
          locale: creator.locale,
        },
      })
    }
  })
}
