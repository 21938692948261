import { LeanCoreState, CoreState } from './single-or-multilink.state'
import { WfaFormWithResponse as Fwr } from '@arzt-direkt/wfa-definitions'
import { Maybe, nullish, get, notNullish } from '@arzt-direkt/wfa-generic-utils'

export function getResponseId(x: unknown) {
  const candidates = [
    get(x, 'fwr.formResponse.responseId'),
    get(x, 'formResponse.responseId'),
    get(x, 'responseId'),
  ].filter(notNullish)

  if (candidates.length === 1) return candidates[0]
  return undefined
}

type MinWFR = {
  formResponse: {
    responseId: Fwr['formResponse']['responseId']
  }
}
export function getDefaultActiveResponseId(fwrs: MinWFR[]): Maybe<string> {
  return fwrs.length === 0
    ? undefined
    : fwrs[0].formResponse.responseId.toString()
}

export function getIndexByResponseId(s: LeanCoreState): Maybe<number> {
  if (nullish(s.fwrs) || nullish(s.activeResponseId)) return null
  return s.fwrs.findIndex((f) => getResponseId(f) === s.activeResponseId)
}

export function getNextResponseId(s: LeanCoreState): Maybe<string> {
  if (nullish(s.fwrs) || nullish(s.activeResponseId)) return null
  const ind = getIndexByResponseId(s)
  if (nullish(ind) || ind + 1 === s.fwrs.length) return null
  return getResponseId(s.fwrs[ind + 1])
}
