<ng-container *ngIf="formGroup && currentCountry">
  <mat-form-field [formGroup]="formGroup" appearance="outline">
    <mat-label>{{ subDivisionName }}</mat-label>
    <mat-select [formControlName]="name">
      <mat-option *ngFor="let state of filteredStates" [value]="state.subdivisionCode">
        {{ state.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

</ng-container>