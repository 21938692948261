import { Injectable } from "@angular/core"
import { MatBottomSheet } from "@angular/material/bottom-sheet"
import { StorageService } from "../storage/storage.service"
import { Cookie, CookiesService, EssentialCookieNames, LSERROR } from "./cookies.service"

@Injectable({
  providedIn: 'root'
})
export class DsgvoService {

  constructor
    (
      public _dsgvoBottomSheet: MatBottomSheet,
      private storageService: StorageService,
      private cookiesService: CookiesService,
  ) {
    this.subscribeToCookieChanges()
  }

  private subscribeToCookieChanges() {
    this.cookiesService.cookieChanged$
      .subscribe((cookie: Cookie) => {
        // console.log("cookie changed: ", cookie)
        switch (cookie.cookieName) {
          case EssentialCookieNames.areSupportCookiesAllowed:
            if (this.cookiesService.get(EssentialCookieNames.areSupportCookiesAllowed) === 'false') {
              // console.log("areSupportCookiesAllowed set to false: remove all support cookies")
              this.cookiesService.removeAllSupportCookies()
            }
            break;
          case EssentialCookieNames.isFormCachingAllowed:
            if (this.cookiesService.get(EssentialCookieNames.isFormCachingAllowed) === 'false') {
              // console.log("isFormCachingAllowed set to false: clear storages")
              this.clearStorages()
            }
            break;
        }
      })
  }

  public acceptAllDsgvoPreferences() {
    this.cookiesService.set(EssentialCookieNames.areSupportCookiesAllowed, 'true')
    this.cookiesService.set(EssentialCookieNames.isFormCachingAllowed, 'true')
    this.closeDsgvoBottomSheet()
  }

  public resetAllDsgvoPreferences() {
    this.cookiesService.removeAllCookies()
  }

  public closeDsgvoBottomSheet() {
    this.cookiesService.set(EssentialCookieNames.areDsgvoPreferencesSubmitted, 'true')
    this.cookiesService.removeAllSupportCookies()
    this.clearStorages()
    this._dsgvoBottomSheet.dismiss()
  }

  private clearStorages() {
    try { localStorage.clear() } catch { console.log(LSERROR) }
    this.storageService.clearAll().subscribe()
  }
}
