import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class MatPaginatorIntlDe extends MatPaginatorIntl {

  itemsPerPageLabel = this.translate.instant("PAGINATOR.ITEMS-PER-PAGE-LABEL")
  firstPageLabel = this.translate.instant("PAGINATOR.FIRST-PAGE-LABEL")
  previousPageLabel = this.translate.instant("PAGINATOR.PREVIOUS-PAGE-LABEL")
  nextPageLabel = this.translate.instant("PAGINATOR.NEXT-PAGE-LABEL")
  lastPageLabel = this.translate.instant("PAGINATOR.LAST-PAGE-LABEL")

  constructor(private translate: TranslateService) { super() }

  getRangeLabel = function (page: number, pageSize: number, length: number) {
    if (length === 0 || pageSize === 0) {
      return '0 ' + this.translate.instant("PAGINATOR.OF") + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' '
      + this.translate.instant("PAGINATOR.OF") + ' '
      + length;
  };
}
