<form class="c-form c-form--fullHeight c-form--largerSpacing" [formGroup]="formGroup">
  <!-- G4: Impfungen - Nebenwirkungen -->
  <div class="c-form__row" #target>
    <app-radio-button-select attr.data-selenium-id="radio-impfnebenwirkung" [formGroup]="formGroup"
      [options]="settings.sideEffects.options" [selectionGetter]="accessControl('sideEffects')"
      [selectionPlaceholder]="settings.sideEffects.title | i18nString" selectionName="sideEffects">
    </app-radio-button-select>
  </div>

  <div class="secondary-info-wrapper">
    <div class="c-form__row" *ngIf="visible['customSideEffect']" #target>
      <mat-form-field attr.data-selenium-id="field-impfnebenwirkung" appearance="outline">
        <mat-label>{{
          settings.customSideEffect.title | i18nString
          }}</mat-label>
        <textarea matInput cdkTextareaAutosize formControlName="customSideEffect"
          [required]="required['customSideEffect']" mat-autosize="true" cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"></textarea>
      </mat-form-field>
    </div>
  </div>

  <!-- G5: Impfungen - Nebenwirkungen -->
  <div class="c-form__row" #target>
    <app-radio-button-select attr.data-selenium-id="radio-allergien" [formGroup]="formGroup"
      [options]="settings.allergies.options" [selectionGetter]="accessControl('allergies')"
      [selectionPlaceholder]="settings.allergies.title | i18nString" selectionName="allergies">
    </app-radio-button-select>
  </div>

  <div class="secondary-info-wrapper">
    <div class="c-form__row" *ngIf="visible['allergySelect']" #target>
      <app-selection-field-component [formGroup]="formGroup" [multiple]="true"
        [options]="settings.allergySelect.options" [selectionGetter]="accessControl('allergySelect')"
        [selectionPlaceholder]="settings.allergySelect.title | i18nString"
        [selectionRequired]="required['allergySelect']" [sonstigesOption]="false" selectionName="allergySelect">
      </app-selection-field-component>
    </div>

    <div class="c-form__row" *ngIf="visible['customAllergy']" #target>
      <mat-form-field attr.data-selenium-id="field-medikamente-allergene" appearance="outline">
        <mat-label>{{ settings.customAllergy.title | i18nString }}</mat-label>
        <textarea matInput cdkTextareaAutosize formControlName="customAllergy" [required]="required['customAllergy']"
          mat-autosize="true" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
      </mat-form-field>
    </div>

    <!-- G6: Allergie: Beschwerden und Anfall -->
    <span *ngIf="visible['allergyDetails']" style="margin: 0pt">
      <div class="c-form__row">
        <mat-label>{{ settings.allergyDetails.title | i18nString }}</mat-label>
      </div>
      <div class="c-form__row" #target>
        <mat-form-field attr.data-selenium-id="field-allergieanfall" appearance="outline">
          <mat-label>{{
            settings.allergyDetails.shortTitle | i18nString
            }}</mat-label>
          <textarea matInput cdkTextareaAutosize formControlName="allergyDetails"
            [required]="required['allergyDetails']" mat-autosize="true" cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>
      </div>
    </span>
  </div>

  <!-- G7: Chronische Erkrankungen -->
  <div class="c-form__row" #target>
    <app-radio-button-select attr.data-selenium-id="radio-chronisch" [formGroup]="formGroup"
      [options]="settings.chronicDiseases.options" [selectionGetter]="accessControl('chronicDiseases')"
      [selectionPlaceholder]="settings.chronicDiseases.title | i18nString"
      [hint]="settings.chronicDiseases.hint | i18nString" selectionName="chronicDiseases"></app-radio-button-select>
  </div>

  <div class="secondary-info-wrapper">
    <div class="c-form__row" *ngIf="visible['customChronicDisease']" #target>
      <mat-form-field attr.data-selenium-id="field-chronisch" appearance="outline">
        <mat-label>{{
          settings.customChronicDisease.title | i18nString
          }}</mat-label>
        <textarea matInput cdkTextareaAutosize formControlName="customChronicDisease"
          [required]="required['customChronicDisease']" mat-autosize="true" cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"></textarea>
      </mat-form-field>
    </div>
  </div>

  <!-- G8: Medication and Treatments -->
  <div class="c-form__row" #target>
    <app-radio-button-select attr.data-selenium-id="radio-medikamente" [formGroup]="formGroup"
      [options]="settings.medications.options" [selectionGetter]="accessControl('medications')"
      [selectionPlaceholder]="settings.medications.title | i18nString" [hint]="settings.medications.hint | i18nString"
      selectionName="medications"></app-radio-button-select>
  </div>

  <div class="secondary-info-wrapper">
    <div class="c-form__row" *ngIf="visible['customMedication']" #target>
      <mat-form-field attr.data-selenium-id="field-medikamente" appearance="outline">
        <mat-label>{{
          settings.customMedication.title | i18nString
          }}</mat-label>
        <textarea matInput cdkTextareaAutosize formControlName="customMedication"
          [required]="required['customMedication']" mat-autosize="true" cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"></textarea>
      </mat-form-field>
    </div>
  </div>

  <!-- T1: Freitextfeld -->
  <div class="c-form__row" #target>
    <mat-form-field attr.data-selenium-id="field-zusatzinfo" appearance="outline">
      <mat-label>{{ settings.goOther.title | i18nString }}</mat-label>
      <textarea matInput cdkTextareaAutosize formControlName="goOther" [required]="false" mat-autosize="true"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
  </div>
</form>