import { Component, Input } from '@angular/core'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'

@Component({
  standalone: true,
  selector: 'wfa-plain-message',
  templateUrl: './plain-message.component.html',
  styleUrls: ['./plain-message.component.scss'],
})
export class PlainMessageComponent {
  @Input() message: Maybe<string> = 'Unspecified internal error'
}
