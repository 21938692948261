import { DocumentsService } from '@a-d/documents/documents.service';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { fromEvent, interval, Subject } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { DialogComponent } from '../../../../lib/src/lib/dialog/dialog.component';
import { DsgvoPreferencesDialogComponent } from '../dsgvo/dsgvo-preferences-dialog.component';
import { LegalDialogData } from '../entities/Legal.entity';
import { ScrollToTopComponent } from '../misc/scroll-to-top/scroll-to-top.component';
export interface TOCItem {
  title: string,
  headingEl: HTMLElement
}
@Component({
  selector: 'app-legal-dialog',
  templateUrl: './legal-dialog.component.html',
  styleUrls: ['./legal-dialog.component.scss'],
  standalone: true,
  imports: [DialogComponent, MatDialogModule, NgIf, NgTemplateOutlet, MatButtonModule, NgFor, ScrollToTopComponent, FontAwesomeModule, TranslateModule]
})

@UntilDestroy()
export class LegalDialogComponent implements OnInit {

  @ViewChild('contentEl', { static: true }) contentEl: ElementRef
  @ViewChild('dialogContent', { static: true }) dialogContent: ElementRef

  private unsubscribe$ = new Subject()
  public isLoading: boolean
  public hasScrolled: boolean
  public toc: TOCItem[] = null
  public docName: string
  public title: string
  public shortTitle: string
  public subtitle: string
  public documentHTML: string
  public showScrollToTopButton: boolean = false
  private readonly DONT_SHOW_SCROLL_TO_TOP_FOR: string[]
    = [this.translate.instant('DOCUMENTS.MENU-GLOBAL.IMPRINT'),
    this.translate.instant('DOCUMENTS.INSURANCE-CARD')]

  constructor(
    public dialogRef: MatDialogRef<LegalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LegalDialogData,
    public documentsService: DocumentsService,
    private translate: TranslateService,
    private dialog: MatDialog,

  ) { }

  ngOnInit() {
    this.retrieveDocumentAndToc()
    if (!this.data.hideToc) {
      this.initScrollListener()
    }
  }

  /*
   * retrieve the HTML document and its TOC if available.
   * note: DocumentsComponent and LegalDialog have similar functions
   * of the same name. Maybe there is an aesthetic way to unify them?
  */
  retrieveDocumentAndToc() {
    // const instanceIdentifier = this.data.activeInstance.identifier
    this.docName = this.data.dataFile.split('.')[0] // remove ".html"
    this.isLoading = true
    this.documentsService.retrieveDocument(
      this.data.activeBaseLang,
      this.docName,
      this.data.activeInstance ? this.data.activeInstance.identifier : null)
      .pipe(untilDestroyed(this))
      .subscribe((documentHTML) => {
        this.documentHTML = documentHTML
        setTimeout(() => {
          if (documentHTML) {
            this.documentsService.generateDynamicToc(this.contentEl)
            this.toc = this.documentsService.toc
            this.title = this.documentsService.title
            this.shortTitle = this.documentsService.shortTitle
            this.subtitle = this.documentsService.subtitle
            this.showScrollToTopButton = !this.DONT_SHOW_SCROLL_TO_TOP_FOR.includes(this.title)
          }
          this.scrollToHeading()
          this.isLoading = false
        }, 150)
      })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }

  scrollToTop() {
    this.dialogContent.nativeElement.scrollTop = 0
  }

  /**
  * Scrolls to given heading.
  */
  private scrollToHeading() {
    if (!this.data.scrollToHeading || typeof this.data.scrollToHeading !== 'string') return
    const tocItem = this.toc.find((item) => {
      if (!item.title || typeof item.title !== 'string') return false
      return item.title.toLowerCase().trim() === this.data.scrollToHeading.toLowerCase().trim()
    })
    if (!tocItem) {
      console.warn(`Couldn't find heading with title '${this.data.scrollToHeading}'.`)
    } else {
      console.log(`Scrolling to heading with title '${this.data.scrollToHeading}'..`)
      tocItem.headingEl.scrollIntoView()
    }
  }

  /**
  * Helper to query a given selector within a given element.
  */
  private queryElements(element: HTMLElement, selector: string): HTMLElement[] {
    const elements: HTMLElement[] = Array.from(element.querySelectorAll(selector))
    if (!elements || elements.length <= 0) {
      return [];
    }
    return elements
  }

  /**
  * Listen to the 'scroll' event and set a flag if scrolling has surpassed a given offset
  */
  public initScrollListener(offset: number = 100) {
    const scrollEl = this.dialogContent.nativeElement
    fromEvent(scrollEl, 'scroll').pipe(
      debounce((_) => interval(100))
    ).subscribe(() => {
      this.hasScrolled = scrollEl.scrollTop > offset
    });
  }

  public openDsgvoPreferencesDialog() {
    this.dialog.open(DsgvoPreferencesDialogComponent, {
      width: '35rem',
    })
  }
}
