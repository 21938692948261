import { WfaForm, WfaFormResponse } from '@arzt-direkt/wfa-definitions'
import {
  generateObjectId,
  Optional,
  WfaMessage,
} from '@arzt-direkt/wfa-generic-utils'

type MinWfaForm = Optional<
  Pick<WfaForm, 'wfaFormId' | 'wfaFormVersionId' | 'praxisId'>,
  'praxisId'
>

export function createWfaFormResponse(
  wfaForm: MinWfaForm,
): WfaMessage | WfaFormResponse {
  const { wfaFormId, wfaFormVersionId, praxisId } = wfaForm
  return {
    responseId: generateObjectId(),
    wfaFormId,
    wfaFormVersionId,
    praxisId: praxisId ?? '000000000000000000000000',
    surveyjsResponse: {},
  }
}
