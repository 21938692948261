<ng-container *ngIf="hasPayByBank">

  <mat-accordion class="headers-align">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title class="panel-header">
          {{ 'OTK.BOOKING-PAY.CREDIT-CARD' |
          translate }}
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <p>{{ 'OTK.BOOKING-PAY.CREDIT-PAYMENT' |
        translate }}</p>
      <div id="dropin-container"></div>
      <div *ngIf="hasError" class="retry-button">
        <button mat-button color="primary" (click)="retryPayment()">
          <span>
            {{ 'OTK.BOOKING-PAY.PAYMENT-RETRY' |
            translate }}
          </span>
        </button>
      </div>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="isDemo" [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title class="panel-header">
          {{ 'OTK.BOOKING-PAY.PAY-BY-BANK' |
          translate }}
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>{{ 'OTK.BOOKING-PAY.PAY-BY-BANK-HEADER' |
        translate }}</p>
      <h3>{{ 'OTK.BOOKING-PAY.PAY-BY-BANK-SUB-HEADER' |
        translate }}</h3>
      <mat-list>
        <mat-list-item>1. {{ 'OTK.BOOKING-PAY.PAY-BY-BANK-POINT-ONE' |
          translate }} </mat-list-item>
        <mat-list-item>2. {{ 'OTK.BOOKING-PAY.PAY-BY-BANK-POINT-TWO' |
          translate }} </mat-list-item>
        <mat-list-item>3. {{ 'OTK.BOOKING-PAY.PAY-BY-BANK-POINT-THREE' |
          translate }} </mat-list-item>
      </mat-list>
      <h3>{{ 'OTK.BOOKING-PAY.PAY-BY-BANK-INFO' |
        translate }}</h3>
      <p>{{ 'OTK.BOOKING-PAY.PAY-BY-BANK-INFO-TEXT' |
        translate }}</p>
      <div class="retry-button">
        <button mat-button color="primary" (click)="payWithOpenBanking()">
          <span>{{ 'OTK.BOOKING-PAY.PAY-BY-BANK-PAY' |
            translate }}</span>
        </button>
      </div>
    </mat-expansion-panel>

  </mat-accordion>

</ng-container>

<ng-container *ngIf="!hasPayByBank">
  <div id="dropin-container"></div>
  <div *ngIf="hasError" class="retry-button">
    <button mat-button color="primary" (click)="retryPayment()">
      <span>{{ 'OTK.BOOKING-PAY.PAYMENT-RETRY' |
        translate }}</span>
    </button>
  </div>
</ng-container>