import { AfterRenderQuestionEvent, SurveyModel } from 'survey-core'

/**
 * @param options comes from SurveyJs and is mutated
 */
export function setQuestionToReadOnly(
  sender: SurveyModel,
  options: AfterRenderQuestionEvent,
): void {
  options.question.readOnly = true
}
