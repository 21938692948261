import { StreetmapComponent } from '@a-d/streetmap/streetmap.component';
import { NgFor, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Injectable, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscriber, Subscription, catchError, delay, mergeMap, of } from 'rxjs';
import { Betriebsstaette, BookingStep } from '../../entities/Booking.entity';
import { StreetmapComponent as StreetmapComponent_1 } from '../../streetmap/streetmap.component';
import { BookingStandaloneService } from '../booking-standalone.service';
import { BookingService } from '../booking.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-booking-bs',
  templateUrl: './booking-bs.component.html',
  styleUrls: ['./booking-bs.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, MatButtonToggleModule, MatIconModule, NgFor, StreetmapComponent_1, TranslateModule]
})
export class BookingBsComponent implements OnInit, AfterViewInit {

  get bookingBsFormGroup(): FormGroup { return this.bookingService.bookingBsFormGroup }
  get bs(): AbstractControl<Betriebsstaette> { return this.bookingBsFormGroup.get('bs') }
  betriebsstaetten: Betriebsstaette | Betriebsstaette[]
  isBetriebsstaettenArray = false // needed for an *ngIf in the .html which supresses an error in case a single bs was provided as param
  doesParamExist: boolean
  @ViewChildren('maps') allMaps: QueryList<StreetmapComponent>
  @ViewChild('form') form: ElementRef
  isServerError: boolean
  subscriptionInitBookingType: Subscription
  subscriptionMagicFill: Subscription
  isStepActive = false // starts as false to prevent bs from being visible for a second even if disabled
  isZwCodeCorrect = false
  public failureAssetUrl = `${environment.url}/assets/otk/booking/failure.svg`

  constructor(
    private activatedRoute: ActivatedRoute,
    public bookingService: BookingService,
    private changeDetectorRef: ChangeDetectorRef,
    private bookingStandaloneService: BookingStandaloneService
  ) { }
  ngAfterViewInit(): void {
    this.setScrollableNativeElement()
  }

  ngOnInit(): void {
    this.isZwCodeCorrect = this.bookingService.isZwCodeCorrect
    this.bookingService.isBookingInitialized$
      .pipe(
        untilDestroyed(this),
        mergeMap((isBookingInitialized: boolean) =>
          isBookingInitialized ? of(null).pipe(
            mergeMap(() => this.initBookingTypeSubscribe()),
            mergeMap(() => this.setStepActive()),
            mergeMap(() => this.magicFillSubscription()),
            mergeMap(() => this.handleParams()),
            mergeMap(() => {
              if (this.betriebsstaetten instanceof Array) {
                this.betriebsstaetten.sort((a, b) => {
                  const aPos = a.displayPosition;
                  const bPos = b.displayPosition;
                  if (!aPos && !bPos) return 0;
                  if (!aPos) return 1;
                  if (!bPos) return -1;
                  return aPos - bPos;
                })
                if (this.betriebsstaetten.length === 1) {
                  this.isStepActive = false;
                  this.bookingService.autoForward[BookingStep.bookingBs] = false;
                  this.bs.setValue(this.betriebsstaetten[0]);
                  this.bookingService.stepper.selected.completed = true;
                  this.bookingService.showBs$.next(false);
                }
              }
              return of(null);
            }),
            catchError((error) => this.bookingService.setError(error)),
          ) : of(null)
        )
      ).subscribe()
  }

  setScrollableNativeElement() {
    if (this.isStepActive && !this.bookingService.isStandalone && this.form?.nativeElement)
      this.bookingService.scrollableNativeElement[BookingStep.bookingBs] = this.form.nativeElement
  }

  setStepActive(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this.isStepActive = this.bookingService.isBsActive
      if (!this.isStepActive) {
        // the next line prevents bookingService.setStepSubscription() from performing this.stepper.next(),
        //   which would have (in combination with showBs$.next(false)) skipped booking-type straight to booking-date
        this.bookingService.autoForward[BookingStep.bookingBs] = false
        this.bs.setValue(this.bookingService.bsAll)
        this.bookingService.showBs$.next(false)
      }
      subscriber.next()
      subscriber.complete()
    })
  }

  initBookingTypeSubscribe(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      if (this.subscriptionInitBookingType)
        this.subscriptionInitBookingType.unsubscribe()
      this.subscriptionInitBookingType = this.bookingService.bookingBsFormGroup.valueChanges
        .pipe(
          untilDestroyed(this),
          mergeMap(() => {
            if (this.bookingService.bookingBsFormGroup.valid) { // to avoid init when resetting
              this.bookingService.stepper.selected.completed = true
              return this.bookingService.initBookingType()
            }
            else
              return of(null)
          }
          ))
        .subscribe(() =>
          this.changeDetectorRef.detectChanges()
        )
      subscriber.next()
      subscriber.complete()
    })
  }

  // if no bs was given as get parameter:
  //    nothing happens until the user selects a bs and thus triggers initBookingType by the valueChanges subcription above
  // if one bs is given as get parameter:
  //    initBookingType is called by the valueChanges subscription, triggered by setValue below
  // if multiple bs are given as parameter:
  //    the "bsAll" option is hidden (otherwise one can use it to access appointments from hidden bs)
  //    and initBookingType is not called automatically, rather it is called through the valueChanges subscription
  //    when a bs is chosen by the user.
  handleParams(): Observable<void> {
    return this.activatedRoute.queryParams
      .pipe(untilDestroyed(this),
        mergeMap((params: Params) => {
          this.betriebsstaetten = this.bookingService.betriebsstaetten
          this.isBetriebsstaettenArray = Array.isArray(this.betriebsstaetten)

          //only bs for appointmenttype params
          if (params?.oat && this.isBetriebsstaettenArray && this.bookingService.isBsActive) {
            const oats = params.oat.split(',')
            const associatedBs = this.betriebsstaetten.filter(bs => oats.some((appTId: string) => !bs.excludedAppointmentTypeIds.includes(appTId)))
            if (associatedBs.length)
              this.betriebsstaetten = associatedBs
            else
              return this.bookingService.setError(new Error("booking-bs: handleParams"))
          }

          if (this.bookingService.isStandalone) {
            const bsIds = this.bookingStandaloneService.betriebsstaetten;
            if (!bsIds || bsIds.length === 0) { return of(null); }
            this.doesParamExist = true;
            this.betriebsstaetten = this.betriebsstaetten.filter((bs: Betriebsstaette) => bsIds.includes(bs._id))
            if (this.betriebsstaetten.length === 0) { return of(null); }
            this.isBetriebsstaettenArray = this.betriebsstaetten.length > 1
            if (!this.isBetriebsstaettenArray) {
              this.bookingService.autoForward[BookingStep.bookingBs] = false
              this.bs.setValue(this.betriebsstaetten[0])
              this.bookingService.stepper.selected.completed = true
              this.bookingService.showBs$.next(false)
            }
            return of(null);
          }

          if (!params?.bsid)
            return of(null)

          this.doesParamExist = true
          this.betriebsstaetten = params.bsid.includes(',')
            ? this.betriebsstaetten.filter((bs: Betriebsstaette) => params.bsid.split(',').includes(bs._id))
            : this.betriebsstaetten.find((bs: Betriebsstaette) => params.bsid === bs._id)
          this.isBetriebsstaettenArray = Array.isArray(this.betriebsstaetten)
          if (this.isBetriebsstaettenArray) {
            this.betriebsstaetten = this.bookingService.betriebsstaetten
            this.isBetriebsstaettenArray = Array.isArray(this.betriebsstaetten)
            //only bs for appointmenttype params
            if (params?.oat && this.isBetriebsstaettenArray && this.bookingService.isBsActive) {
              const oats = params.oat.split(',')
              const associatedBs = this.betriebsstaetten.filter(bs => oats.some((appTId: string) => !bs.excludedAppointmentTypeIds.includes(appTId)))
              if (associatedBs.length)
                this.betriebsstaetten = associatedBs
              else
                return this.bookingService.setError(new Error("booking-bs: handleParams"))
            }
            return of(null)
          }
          else if (this.betriebsstaetten) {
            // the next line prevents bookingService.setStepSubscription() from performing this.stepper.next(),
            //   which would have (in combination with showBs$.next(false)) skipped booking-type straight to booking-date
            this.bookingService.autoForward[BookingStep.bookingBs] = false
            this.bs.setValue(this.betriebsstaetten as Betriebsstaette)
            this.bookingService.stepper.selected.completed = true
            this.bookingService.showBs$.next(false)
            return of(null)
          }
          else {
            // return this.bookingService.setError(new Error("booking-bs: handleParams"))
            this.betriebsstaetten = this.bookingService.betriebsstaetten
            this.isBetriebsstaettenArray = Array.isArray(this.betriebsstaetten)
            //only bs for appointmenttype params
            if (params?.oat && this.isBetriebsstaettenArray && this.bookingService.isBsActive) {
              const oats = params.oat.split(',')
              const associatedBs = this.betriebsstaetten.filter(bs => oats.some((appTId: string) => !bs.excludedAppointmentTypeIds.includes(appTId)))
              if (associatedBs.length)
                this.betriebsstaetten = associatedBs
              else
                return this.bookingService.setError(new Error("booking-bs: handleParams"))
            }
            return of(null)
          }
        })
      )
  }

  // perform magic fill (fill the fields with preset values)
  magicFillSubscription(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      if (this.subscriptionMagicFill)
        this.subscriptionMagicFill.unsubscribe()
      this.subscriptionMagicFill = this.bookingService.magicFill$
        .pipe(untilDestroyed(this))
        .subscribe((magicFill: string) =>
          magicFill === BookingStep.bookingBs ? this.magicFill() : null)
      subscriber.next()
      subscriber.complete()
    })
  }

  magicFill() {
    of(null)
      .pipe(untilDestroyed(this),
        mergeMap(() => {
          if (this.isStepActive)
            this.bs.setValue(this.betriebsstaetten[0])
          this.bookingService.stepper.next()
          return of(null)
        }),
        delay(500),
        mergeMap(() => {
          this.changeDetectorRef.detectChanges()
          // if type was already given as a GET parameter, booking-type is already destroyed by now (?)
          // because of the ngIf in booking-stepper, so we need to propagate to bookingDate directly
          if (this.bookingService.showType$.value)
            this.bookingService.magicFill$.next(BookingStep.bookingType)
          else
            this.bookingService.magicFill$.next(BookingStep.bookingDate)
          return of(null)
        })
      ).subscribe()
  }

  redrawMaps() {
    this.allMaps.forEach((x: StreetmapComponent) => x.recalcsize())
    this.changeDetectorRef.detectChanges()
    this.allMaps.forEach((x: StreetmapComponent) => x.repaintMap())
  }
}


