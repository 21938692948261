export const example = {
  type: 'tagbox',
  name: 'Frage7',
  title: {
    de: 'Welche Beschwerden haben Sie an den Augen?',
  },
  choices: [
    {
      value: 'Item1',
      text: {
        de: 'Schmerzen',
      },
    },
    {
      value: 'Item2',
      text: {
        de: 'Jucken',
      },
    },
    {
      value: 'Item3',
      text: {
        de: 'Tränen',
      },
    },
    {
      value: 'Item4',
      text: {
        de: 'Blitze',
      },
    },
    {
      value: 'Item5',
      text: {
        de: 'schwarze Punkte ',
      },
    },
    {
      value: 'Item6',
      text: {
        de: 'Doppelbilder',
      },
    },
    {
      value: 'Item7',
      text: {
        de: 'Schielen',
      },
    },
    {
      value: 'Item8',
      text: {
        de: 'Andere Beschwerden',
      },
    },
  ],
  shortTitle: 'Beschwerden an Augen',
}
