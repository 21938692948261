import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NotificationService } from '@lib/notifications/notification.service';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, Subject, forkJoin, of } from 'rxjs';
import { first, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Documentation } from '../entities/Documentation.entity';
import { InstanceEncryptionMode } from '../entities/InstanceSettings.entity';
import { PappSessionCompleted } from '../entities/Papp.entity';
import { Asset, PatientSession, PatientSessionConnectionStatistics, PatientSessionError, PatientSessionMessage, PatientSessionPaymentStatus, PatientSessionState, PatientSessionStateElement } from '../entities/PatientSession.entity';
import { PatientSessionCreationResponse, PatientSessionDataEncrypted } from '../entities/PatientSessionCreation.entity';
import { FormHelpers } from '../forms/form-helpers.service';
import { InsuranceService } from '../insurance/insurance.service';
import { ConsoleService } from '../logging/console.service';
import { SocketService } from '../socket/socket.service';
import { StorageService } from '../storage/storage.service';
import { AssetService } from '../web-rtc/asset.service';
import { environment } from './../../environments/environment';
import { UtilityService } from './../crypto/utility.service';
import { PappService } from './../dashboard/instance-settings/papp/papp.service';
import { InstanceService } from './../instance/instance.service';
import { PatientSessionDecryptionService } from './patient-session-decryption.service';


@Injectable({
  providedIn: 'root'
})
export class PatientSessionService implements OnDestroy {

  private unsubscribe$ = new Subject()
  public skipDocumenting$ = new BehaviorSubject<boolean>(false)

  /* Current Users Active Session */

  activeSession$: BehaviorSubject<PatientSession | null> = new BehaviorSubject<PatientSession | null>(null);
  activeState$: BehaviorSubject<PatientSessionState | null> = new BehaviorSubject<PatientSessionState | null>(null);
  doesDoctorHaveActiveSession$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(
    private adLoggerService: AdLoggerService,
    public http: HttpClient,
    public notificationService: NotificationService,
    public formHelpers: FormHelpers,
    public authService: AuthService,
    public socketService: SocketService,
    private insuranceService: InsuranceService,
    private storageService: StorageService,
    private pappService: PappService,
    private instanceService: InstanceService,
    private utilityService: UtilityService,
    private patientSessionDecryptionService: PatientSessionDecryptionService,
    private assetService: AssetService,
    private consoleService: ConsoleService,
  ) {
    // Initially load active session for user
    this.adjustSessionOnUserChange()

    // Update active session if user changed
    this.authService.userUpdated$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.adjustSessionOnUserChange()
    })
  }

  private adjustSessionOnUserChange() {
    if (this.authService.user && this.authService.user.isArzt) {
      this.queryUsersActiveSession().pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (session: PatientSession | null | undefined) => {
          if (session) {
            this.socketService.sendArztActiveSessionUpdate(session._id);
            this.setActiveSession(session).pipe(takeUntil(this.unsubscribe$)).subscribe()
          } else this.deleteActiveSession(true).pipe(takeUntil(this.unsubscribe$)).subscribe()
        },
        error: (error) => {
          console.warn(error)
          this.deleteActiveSession(true).pipe(takeUntil(this.unsubscribe$)).subscribe()
        }
      })
    } else this.deleteActiveSession(true).pipe(takeUntil(this.unsubscribe$)).subscribe()
  }

  /**
   * in most parts of the app, this.activeSession$.value is used -
   * this function is used for initialization purposes only:
   * first tries to return the "this" variable, or if it doesn't exist
   * (for instance due to refresh) it loads it from local storage and returns that.
   */
  public getActiveSession(): Observable<PatientSession> {
    if (this.activeSession$.value) {
      this.consoleService.logDemo("getActiveSession")
      return of(this.activeSession$.value)
    }
    return this.storageService.getPatientSession()
      .pipe(
        mergeMap((patientSession: PatientSession) => {
          if (!patientSession) {
            console.warn("getActiveSession: no session found (in the service or in local storage)")
            return of(null)
          }
          this.consoleService.logDemo("getActiveSession: this._activeSession was nullish. Setting it from local storage.")
          return this.setActiveSession(patientSession).pipe(
            mergeMap(() => this.getActiveSession())
          )
        })
      )
  }

  /**
   * sets not only "this" but also local storage. If we edit the app and ensure this
   * is the only way the session gets changed we will ensure synchronicity between
   * the two (and make the code much more readable, in my (Tom's) opinion)
   * note: only updates the front end.
   */
  public setActiveSession(patientSession: PatientSession): Observable<boolean> {
    this.consoleService.logDemo("setActiveSession")
    this.consoleService.logDemo("setActiveSession: patientSession.state = ", patientSession.state)
    // in first step, patient creates a session without state (only form-data)
    if (patientSession.state && patientSession.state !== this.activeSession$.value?.state) this.activeState$.next(patientSession.state)
    this.doesDoctorHaveActiveSession$.next(this.getDoesDoctorHaveActiveSession(patientSession));
    this.activeSession$.next(patientSession);
    return this.storageService.setPatientSession(patientSession)
  }

  private getDoesDoctorHaveActiveSession(patientSession: PatientSession | null | undefined): boolean {
    if (patientSession && patientSession.isCouncil) false
    if (!patientSession || !patientSession.state || !patientSession.stateHistory)
      return false
    let doesDoctorHaveActiveSession: boolean = (patientSession.state === PatientSessionState.Active || patientSession.state === PatientSessionState.Picked)
    for (let i = 0; i < Object.keys(patientSession.stateHistory).length; i++) {
      if (patientSession.stateHistory[i].state === PatientSessionState.Active)
        doesDoctorHaveActiveSession = true
    }
    if (patientSession.state === PatientSessionState.Halted || patientSession.state === PatientSessionState.Completed)
      doesDoctorHaveActiveSession = false
    return doesDoctorHaveActiveSession
  }

  /**
   * deletes the active session from both the service and the local storage.
   * todo: replace all instances of "this.patientSessionService.activeSession = null"
   * (and this.session = null and others) in the code with subscriptions to this function
   */
  public deleteActiveSession(isArzt?: boolean): Observable<any> {
    this.consoleService.logDemo("deleteActiveSession")
    this.activeSession$.next(null);
    this.activeState$.next(null);
    this.doesDoctorHaveActiveSession$.next(false);
    if (isArzt) this.socketService.sendArztActiveSessionUpdate(null)
    return this.storageService.removePatientSession()
  }

  /**
   * updates the start time. Used at the start of the VSS.
   * Here we need to be careful: if there is a session in localstorage which already has a consultationStart, 
   * then we assume that the session has been refreshed and latter is the correct one 
   */
  public updateConsultationStart(consultationStart: string): Observable<boolean> {
    const patientSession = this.activeSession$.value;
    return (patientSession ? of(patientSession) : this.getActiveSession()).pipe(
      mergeMap((session) => {
        if (!session.documentation || !session.documentation.consultationStart)
          session.documentation = {
            authenticated: true,
            consultationStart: consultationStart
          }
        return this.setActiveSession(session)
      })
    )

    // patientSession.documentation.consultationStart = consultationStart



  }

  /**
   * updates the documentation part of the patient session with a copy
   * of the given documentation
   * note: only updates the front end.
   */
  public updateDocumentation(documentation: Documentation): Observable<boolean> {
    const patientSession = this.activeSession$.value;
    patientSession.documentation = documentation
    return this.setActiveSession(patientSession)

  }

  /**
   * updates the connectionStatistics part of the patient session
   * note: only updates the front end.
   */
  public updateConnectionStatistics(connectionStatistics: PatientSessionConnectionStatistics): Observable<boolean> {
    const patientSession = this.activeSession$.value;
    patientSession.connectionStatistics = connectionStatistics
    return this.setActiveSession(patientSession)
  }

  /**
   * updates the '[_: string]' portion of Assets from an Asset array
   * ignores NamedAsset and duplicates
   * note: only updates the front end.
   */
  public setAssetsFromAssetsArray(assetsArray: Asset[]): Observable<boolean> {
    const patientSession = this.activeSession$.value;
    // if this method is called by the specialist, activeSession is only present in the indexedDB
    return (patientSession ? of(patientSession) : this.storageService.getPatientSession()).pipe(
      mergeMap((session: PatientSession) => {
        if (session) {
          session.assets = this.assetService.assetsArrayToAssets(assetsArray);
          return this.setActiveSession(session)
        }
        return of(true)
      })
    )
  }

  /**
   * updates the encrypted assets and data (front end only)
   */
  public updateEncryptedDataAndAssets(patientSessionDataEncrypted: PatientSessionDataEncrypted): Observable<boolean> {
    const patientSession = this.activeSession$.value;
    patientSession.encryptedAssets = patientSessionDataEncrypted.encryptedAssets
    patientSession.encryptedData = patientSessionDataEncrypted.encryptedData;
    return this.setActiveSession(patientSession)
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }

  /**
   * Insert new session with non-encrypted data. (PUBLIC-ACCESS)
   */
  public insert(dataNotToEncrypt: any, dataEncrypted: PatientSessionDataEncrypted): Observable<PatientSessionCreationResponse> {
    this.consoleService.logDemo("insert: dataNotToEncrypt = ", dataNotToEncrypt)
    const data = {
      ...dataNotToEncrypt,
      ...dataEncrypted,
    }

    return this.http.post('/api/session/insert', data).pipe(
      first(),
      map((response: PatientSessionCreationResponse) => {
        if (!response || response.status !== 200 || !response.sessionId || !response.sessionState || !response.sessionCreatedAt) {
          throw { name: 'NoSessionReceived', raw: response, error: "Received empty response or no sessionId." }
        }

        return response
      }),
    )
  }

  /**
   * Returns PatientSessionShallow for given ID. (PUBLIC-ACCESS)
   */
  // public fetchState(id: string): Observable<PatientSessionShallow> {
  public fetchState(id: string): Observable<PatientSession> {
    return new Observable((observer: any) => {
      this.http.post('/api/session/state', { id: id })
        .pipe(
          map((response: any) => {
            if (!response || response.status !== 200 || !response.session) {
              throw { name: 'NoSessionReceived', raw: response, error: "Received empty response or no session." }
            } else {
              return response.session
            }
          })
        ).subscribe({
          next: (result) => { observer.next(result) },
          error: (error) => { observer.error(error) }
        })
    })
  }

  /**
   * Sets session-state to 'cancelled'. (PUBLIC-ACCESS)
   */
  public cancel(session: PatientSession, sessionError?: PatientSessionError): Observable<PatientSessionStateElement> {
    return new Observable((observer: any) => {
      const activeInstance = this.instanceService.activeInstance;
      const instanceUrl = `${environment.url}/${activeInstance.identifier}`;
      const pappSessionCompleted: PappSessionCompleted = {
        sessionId: session._id,
        sessionCreatedAt: session.createdAt,
        sessionState: PatientSessionState.Cancelled,
        instanceId: activeInstance._id,
        instanceIdentifier: activeInstance.identifier,
        instanceUrl
      };
      this.http.post('/api/session/cancel', { id: session._id, sessionError })
        .pipe(
          map((response: any) => {
            if (!response) throw { error: "Received empty response." }
            if (response.status !== 200) throw { raw: response, error: "Couldn't cancel session" }
            if (!response.stateElement) throw { raw: response, error: "Received no state-element." }
            return response.stateElement
          }),
          tap((stateElement: PatientSessionStateElement) => {
            // Send State-Update Notification
            this.socketService.sendSessionStateUpdate(session, stateElement)

            // Leave Waiting-Room & clear session-data
            this.socketService.reconnect()
            this.storageService.removePatientSession()
          })
        )
        .subscribe({
          next: (result) => {
            this.pappService.sendSessionCompleted(pappSessionCompleted).subscribe(() => { }, (error) => {
              this.adLoggerService.error(error);
            });
            observer.next(result);
          },
          error: (error) => { observer.error(error) }
        })
    })
  }


  /**
   * Re-Sets state to 'waiting' after session was in state 'halted'. (PUBLIC-ACCESS)
   */
  public reWait(session: PatientSession): Observable<PatientSessionStateElement> {
    return new Observable((observer: any) => {
      this.http.post('/api/session/re-wait', { id: session._id })
        .pipe(
          map((response: any) => {
            if (!response) throw { error: "Received empty response." }
            if (response.status !== 200) throw { raw: response, error: "Couldn't set session-state to 'waiting'" }
            if (!response.stateElement) throw { raw: response, error: "Received no state-element." }
            return response.stateElement
          }),
          tap((stateElement: PatientSessionStateElement) => {
            // Send State-Update Notification
            this.socketService.sendSessionStateUpdate(session, stateElement)
          })
        )
        .subscribe({
          next: (result) => { observer.next(result) },
          error: (error) => { observer.error(error) }
        })
    })
  }

  /**
   * patches the session and also saves it in the front end.
   * Note: all fields which are confidential information cannot be patch
   * using this function, rather they must be encrypted and then patched.
   * See "PatientSession.model.js" to see what can be directly patched
   * and what needs to be encrypted.
   * Note: because this updates the front end, this actually deletes
   * all confidential fields from the front end
   */
  public patchSession(sessionId: string, updates: any, params?: any): Observable<PatientSession> {
    this.consoleService.logDemo("patchSession: sessionId = ", sessionId)
    this.consoleService.logDemo("patchSession: updates = ", updates)

    if (params)
      this.consoleService.logDemo("patchSession: params = ", params)
    if (!sessionId) {
      console.warn("patchSession: Couldn't apply session-updates - No sessionId was given. sessionId = ", sessionId)
      return
    }
    if (!updates || !Object.keys(updates).length) {
      console.warn("patchSession: Couldn't apply session-updates - No updates were given. updates = ", updates)
      return
    }
    const url = `/api/rest/PatientSession/${sessionId}`
    return this.http.patch<PatientSession>(url, updates, { params }).pipe(
      first(),
      mergeMap((patchedSession: PatientSession) => {
        if (!patchedSession)
          throw { name: 'NoSessionReceived', error: "Received empty session" }
        this.consoleService.logDemo('patchSession: patchedSession = ', patchedSession)
        this.consoleService.logDemo('patchSession: patchedSession.stateHistory = ', patchedSession.stateHistory)
        this.consoleService.logDemo('patchSession: patchedSession.state = ', patchedSession.state)
        return of(patchedSession)
      }),
      // update "this" and local storage
      mergeMap((patchedSession: PatientSession) => this.setActiveSession(patchedSession)),
      mergeMap(() => of(this.activeSession$.value)
      )
    )
  }


  /**
   * Queries the Count of PatientSessions with the given params.
   */
  public countQuery(params: any, includeArchived: boolean = false): Observable<number> {
    return forkJoin([
      this.http.get<number>('/api/rest/PatientSession/count', { params }),
      includeArchived ? this.http.get<number>('/api/rest/PatientSessionArchive/count', { params }) : of({ count: 0 })
    ]).pipe(
      map(([response, responseArchived]: [any, any]) => {
        if (!response || !responseArchived || (!response.count && response.count !== 0) || (!responseArchived.count && responseArchived.count !== 0)) {
          throw { response, responseArchived }
        }
        return response.count + responseArchived.count;
      }),
      first()
    )
  }


  /**
   * Queries PatientSessions with the given params.
   */
  public query(params: any): Observable<PatientSession[]> {
    return this.http.get<PatientSession[]>('/api/rest/PatientSession', { params })
      .pipe(first())
  }


  /**
   * Queries archived PatientSessions (PatientSessionArchive) with the given params.
   */
  public queryArchived(params: any): Observable<PatientSession[]> {
    return this.http.get<PatientSession[]>('/api/rest/PatientSessionArchive', { params })
      .pipe(first())
  }


  /**
   * Fetches Waiting PatientSessions with certain criteria from the server.
   *
   * @param filterIds Only query for sessions with IDs in this given array
   * @param limit Limit fetched sessions amount
   * @param hideRejected If true, hide sessions which are already rejected by the doctor
   * @param hideNotTreatable If true, hide sessions which are not allowed to be treated by current user (e.g. Privatarzt can't treat GKV-Patients.)
   */
  public queryWaiting(limit: number, filterIds: string[] = null, hideRejected: boolean = true, hideNotTreatable: boolean = true, extraParams: object = {}): Observable<PatientSession[]> {
    const query = { state: PatientSessionState.Waiting }
    console.log('WUER WAITING!!!!', filterIds)
    // Exclude already rejected sessions
    if (hideRejected) query['rejections.doctor'] = {
      $ne: this.authService.user?._id
    }

    // Exclude not treatable patients
    if (hideNotTreatable) query['insurance.insuranceType'] = {
      $in: this.insuranceService.allInsuranceTypesTreatedByActiveUser()
    }

    // Only include `filterIds` if given
    if (filterIds && !filterIds.length) {
      return of([])
    }
    if (filterIds) {
      query['_id'] = { "$in": filterIds }
    }

    const request = {
      query: JSON.stringify(query),
      sort: 'createdAt',
      ...extraParams
    }

    // Add limit if given
    if (limit) {
      request['limit'] = limit
    }

    return this.query(request)
  }

  private queryUsersActiveSession(extraParams: object = {}): Observable<PatientSession> {
    return this.query({
      query: JSON.stringify({
        doctor: this.authService.user?._id,
        state: { $in: [PatientSessionState.Picked, PatientSessionState.Pending, PatientSessionState.Active, PatientSessionState.Documenting] }
      }),
      limit: 1,
      ...extraParams
    }).pipe(
      map((sessions: PatientSession[]) => (sessions && sessions.length) ? sessions[0] : null),
      first()
    )
  }


  /**
   * Fetch session with given ID.
   */
  public querySessionById(sessionId: string, archived: boolean = false, extraParams: Object = {}): Observable<PatientSession> {
    const params = {
      query: JSON.stringify({
        _id: sessionId
      }),
      limit: 1,
      ...extraParams
    }

    const query$ = archived ? this.queryArchived.bind(this) : this.query.bind(this)
    return query$(params).pipe(
      map((sessions: PatientSession[]) => {
        this.consoleService.logDemo("querySessionById: sessions = ", sessions)
        if (!sessions || !sessions.length) {
          throw { name: "SessionNotFoundError", error: "Couldn't find session with given ID", raw: sessions }
        } else {
          return sessions[0]
        }
      })
    )
  }


  /**
   * Returns Payment-Status of given session
   */
  public getPaymentStatus(session: PatientSession): PatientSessionPaymentStatus {
    if (!session || !session.payment || !session.payment.paypal || !session.payment.paypal.orderId || !session.payment.paypal.authorizationId) {
      return PatientSessionPaymentStatus.NoPayment
    } else if (session.payment.paypal.refunded) {
      return PatientSessionPaymentStatus.Refunded
    } else if (session.payment.paypal.voided) {
      return PatientSessionPaymentStatus.Voided
    } else if (session.payment.paypal.captured) {
      return PatientSessionPaymentStatus.Captured
    } else {
      return PatientSessionPaymentStatus.Authorized
    }
  }


  /**
   * Fetches, Decrypts and Downloads Session-Bill as PDF
   */
  public downloadSessionBill(session: PatientSession) {
    const params = {
      query: JSON.stringify({
        _id: session._id,
        'bill': { $exists: true },
      }),
      select: ['_id', 'instance', 'bill'],
      populate: JSON.stringify({
        path: 'bill',
        select: ['encryptedPdf']
      }),
      limit: 1,
    }

    const encryptionMode = session?.instance?.settings?.general?.encryptionMode ? session.instance.settings.general.encryptionMode : InstanceEncryptionMode.InstanceWide
    const isArchived = !!(<any>session).archivedAt
    const query$ = isArchived ? this.queryArchived.bind(this) : this.query.bind(this)
    query$(params).pipe(
      map((sessions: PatientSession[]) => {
        if (!sessions || !sessions.length) {
          throw { name: "SessionNotFoundError", error: "Couldn't find bill of given session", raw: sessions }
        } else {
          return sessions[0]
        }
      }),
      mergeMap((patientSession: PatientSession) => {
        this.consoleService.logDemo("FETCHED SESSION: ", { patientSession })
        return this.patientSessionDecryptionService.decryptBill(patientSession, encryptionMode)
      })
    ).subscribe((sessionBill: string) => {
      const sessionDate = dayjs(session.createdAt).format('YYYY-MM-DD');
      const filename = `rechnung_${session.person.fname}_${session.person.lname}_${sessionDate}.pdf`;
      this.utilityService.downloadBase64File(sessionBill[0], filename, 'application/pdf');
    })
  }

  /**
   * toggles skip documenting used if user does not want to document session
   * then the loading screen is shown in active patient component while
   * assets are encrypted and emails are sent
   */
  public toggleSkipDocumenting(value?: boolean) {
    const nextValue = value ? value : !this.skipDocumenting$.getValue()
    console.log("toogleSkipDocumenting to not show documenting component", nextValue)
    this.skipDocumenting$.next(nextValue)
  }

  addNewStateElement(newStateElement: PatientSessionStateElement): Observable<boolean> {
    const session = this.activeSession$.value;
    if (session.state !== newStateElement.state) this.activeState$.next(newStateElement.state);
    if (!session.stateHistory) session.stateHistory = [newStateElement]
    else session.stateHistory.unshift(newStateElement);
    session.state = newStateElement.state;
    this.activeSession$.next(session);
    return this.storageService.setPatientSession(session)
  }

  /**
   * adds decrypted messages to session in front end
   */
  addMessagesToPatientSession(messages: PatientSessionMessage[]): Observable<boolean> {
    const session = this.activeSession$.value
    session.messages = messages
    return this.setActiveSession(session)
  }


}
