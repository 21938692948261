<lib-dialog attr.data-selenium-id="dialog-legal" [isLoading]="isLoading" [noDialogActions]="!data.showActions" #dialog>

  <!-- Title -->
  <div mat-dialog-title [class.isHidden]="isLoading">
    <h1 *ngIf="title" [class.hide--onVerySmall]="shortTitle">
      {{ title | translate }}
      <p *ngIf="subtitle">{{ subtitle }}</p>
    </h1>
    <h1 *ngIf="shortTitle" class="show--onVerySmall">
      {{ shortTitle | translate }}
    </h1>
    <ng-template [ngTemplateOutlet]="dialog.closeButtonRef"></ng-template>
  </div>

  <!-- TOC & Content -->
  <div mat-dialog-content [class.isHidden]="isLoading" #dialogContent>
    <!-- cookie preferences button -->
    <div mat-dialog-actions class="legal-cookies-button" *ngIf="docName == 'datenschutz'">
      <button mat-flat-button class="cookie-reset" color="primary" (click)="openDsgvoPreferencesDialog()"
        [disabled]="isLoading">{{
        'DSGVO.CHANGE-COOKIE-SETTINGS' |
        translate}}
      </button>
    </div>

    <ol class="c-toc" *ngIf="!data.hideToc && toc && toc.length">
      <h2>{{'GLOBAL.CONTENTS' | translate}}</h2>
      <li *ngFor="let item of toc" (click)="item.headingEl.scrollIntoView()">
        {{ item.title }}
      </li>
    </ol>
    <div [innerHTML]="documentHTML" #contentEl></div>
    <div *ngIf="showScrollToTopButton" style="float:right">
      <app-scroll-to-top [componentType]="'dialog'"></app-scroll-to-top>
    </div>
  </div>


  <!-- Actions -->
  <div mat-dialog-actions *ngIf="data.showActions || docName == 'datenschutz'">
    <!-- change cookie preferences (only for 'datenschutz') -->
    <ng-container *ngIf="data.showActions">
      <ng-container *ngIf="docName !== 'datenschutz'">
        <button attr.data-selenium-id="button-ablehnen" mat-flat-button [mat-dialog-close]="false"
          [disabled]="isLoading">{{'LEGAL-DIALOG.DECLINE' |
          translate}}</button>
        <button attr.data-selenium-id="button-akzeptieren" mat-flat-button color="primary" class="hide--onVerySmall"
          [mat-dialog-close]="true" [disabled]="isLoading">{{
          'LEGAL-DIALOG.ACCEPT'
          |
          translate:{value: (shortTitle ? shortTitle:title) | translate} }}</button>
        <button attr.data-selenium-id="button-akzeptieren-small" mat-flat-button color="primary"
          class="show--onVerySmall" [mat-dialog-close]="true" [disabled]="isLoading">{{
          'LEGAL-DIALOG.ACCEPT'
          |
          translate:{value: (shortTitle ? shortTitle:'') | translate} }}</button>
      </ng-container>
      <ng-container *ngIf="docName === 'datenschutz'">
        <button attr.data-selenium-id="button-weiter" mat-flat-button color="primary" [mat-dialog-close]="true"
          [disabled]="isLoading">{{'LEGAL-DIALOG.CONTINUE' |
          translate}}</button>
      </ng-container>
      <button attr.data-selenium-id="button-inhalt" mat-flat-button class="toc-button" [class.isShown]="hasScrolled"
        (click)="dialogContent.scrollTop = 0" [disabled]="isLoading">
        <fa-icon [icon]="['far', 'list']"></fa-icon>
        <span> {{'LEGAL-DIALOG.CONTENT' | translate}}</span>
      </button>
    </ng-container>
  </div>
</lib-dialog>