import { DisplayOption } from '@arzt-direkt/wfa-definitions'
import { Model } from 'survey-core'

import { setQuestionToReadOnly } from './set-question-to-readOnly'
import { setSummaryMode } from './set-summary-mode'

/**
 * @param surveyModel its property `mode` is mutated
 */
export function setDisplayOption(
  surveyModel: Model,
  displayOption: DisplayOption,
): void {
  undoPreviousDisplayOptions(surveyModel)
  if (displayOption === 'editable') surveyModel.mode = 'edit'
  if (displayOption === 'readonly') surveyModel.mode = 'display'
  if (displayOption === 'summary') setSummaryMode(surveyModel)
}

/**
 * @param surveyModel is mutated by clearing `titleActions` and removing question rendering modifications
 */
function undoPreviousDisplayOptions(surveyModel: Model): void {
  surveyModel.questionsOnPageMode = 'standard'
  surveyModel.onGetQuestionTitleActions.add((surveyModel, options) => {
    options.titleActions = []
  })
  surveyModel.onAfterRenderQuestion.remove(setQuestionToReadOnly)
}
