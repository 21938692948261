// note:
//  there are 3 definitions of `I18NString`:
//    src/app/entities/Booking.entity.ts
//    server_appointment/src/types/i18n.types.ts
//    src/app/entities/I18N.entity.ts
//  the first two are synchronized through `shared-assets/vendor/arzt-direkt`
//  the third should be deleted and replaced by the first?

export enum BaseLanguage {
  English = 'en',
  German = 'de',
  French = 'fr',
  Italian = 'it',
  Spanish = 'es'
}

export type I18NDictionary<T> = {
  [key in BaseLanguage]?: T
}

export type I18NString = Record<BaseLanguage, string>

export const I18NString_MISSING_VALUE: string = "MULTILINGUAL-FIELD.NO-TRANSLATION"
export const I18NString_MISSING_REDIRECT_LINK: string = "MULTILINGUAL-FIELD.NO-REDIRECT-LINK"

export const i18NString_MISSING_VALUES: I18NString = {
  de: I18NString_MISSING_VALUE,
  en: I18NString_MISSING_VALUE,
  fr: I18NString_MISSING_VALUE,
  it: I18NString_MISSING_VALUE,
  es: I18NString_MISSING_VALUE
}

export const I18NSTRING_EMPTY: I18NString = { de: '', en: '', fr: '', it: '', es: '' }
