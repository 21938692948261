import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class DatauriHelpersService {

  public readonly dataUriRegExp = /^(data:)([\w\/\+]+);(charset=[\w-]+|base64).*,(.*)/gi


  constructor() { }


  /**
   * Returns [<media type>] of the given Data-URI string.
   * data:[<media type>][;base64],<data>
   */
  public getDataUriMediaType(dataUri: string): string {
    if (!dataUri || typeof dataUri !== 'string') return null

    var mime = dataUri.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (!mime || !mime.length) return null

    return mime[1]
  }


  /**
   * Returns <data> of the given Data-URI string.
   * data:[<media type>][;base64],<data>
   */
  public getDataUriData(dataUri: string): string {
    if (!dataUri || typeof dataUri !== 'string') return null
    if (!dataUri.includes(',')) return null

    const [_,  dataUriData] = dataUri.trim().split(',')

    return dataUriData
  }


  /**
   * Returns concatenated Data-URI string from given [<media type>] and <data>.
   * data:[<media type>][;base64],<data>
   */
  public createDataUri(mediaType: string, data: string): string {
    if (!mediaType || !data) return null

    return `data:${mediaType};base64,${data}`
  }


  /**
   * Returns the byte-size of a given dataUri
   */
  public getDataUriByteSize(dataUri: string): number {
    const data = this.getDataUriData(dataUri)
    try {
      return atob(data).length
    } catch (error) {
      return null
    }
  }


  /**
   * Converts given bytes into a pretty file-size string with appropriate unit.
   * Source: https://gist.github.com/lanqy/5193417#gistcomment-1977921
   */
  public formatByteSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`, 10)
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
  }

}
