import { VSSService } from '@a-d/dashboard/active-patient/vss.service';
import { LogoChangeMessageService } from '@a-d/misc/logo-change-message.service';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { InstanceService } from '../instance/instance.service';
import { AnnouncementComponent } from '../misc/announcement/announcement.component';
import { MainHeaderComponent } from '../theming/header/main-header.component';
import { LogoComponent } from '../theming/logo/logo.component';
import { ColorService } from '../theming/service/color.service';
import { PageSizeService } from '../theming/service/page-size.service';
@UntilDestroy()
@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
  standalone: true,
  imports: [NgIf, AnnouncementComponent, NgClass, MainHeaderComponent, LogoComponent]
})
export class WrapperComponent implements OnInit {

  @Input('fixedHeight') fixedHeight: boolean = true
  @Input('logoLink') logoLink: string = "/"
  @Input('hideHeader') hideHeader: boolean = false
  @Input('hideAnnouncement') hideAnnouncement: boolean = false
  @Input('headerType') headerType: string
  @Input() headerOverflow: boolean = true
  @Input() logoNavigatesOutside: boolean = false;

  @ViewChild('mainHeader', { read: ElementRef, static: false }) mainHeader: ElementRef;

  public logoRectangleUrl: string
  public logoSquareUrl: string
  private subscription: Subscription

  constructor(
    public router: Router,
    private instanceService: InstanceService,
    public pageSizeService: PageSizeService,
    private logoChangeMessageService: LogoChangeMessageService,
    private cd: ChangeDetectorRef,
    public colorService: ColorService,
    public vssService: VSSService
  ) { }

  ngOnInit() {
    this.subscription = this.logoChangeMessageService.currentMessage
      .subscribe((msg) => {
        if (msg === "color") {
          console.log("reset header color")
          this.cd.detectChanges()
        }
      })
    this.initLogos()

    this.vssService.recalcHeaderHeight$.pipe(untilDestroyed(this)).subscribe((completed) => {
      if (completed && this.mainHeader?.nativeElement) {
        setTimeout(() => {
          this.pageSizeService.setStyleVar('--headerHeight', this.mainHeader.nativeElement.offsetHeight);
        }, 50);
      }
    })
  }

  ngOnChanges() {
    this.initLogos()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.pageSizeService.setStyleVar('--headerHeight', this.mainHeader.nativeElement.offsetHeight);

    }, 200);
  }

  navigateLogo() {
    if (this.logoNavigatesOutside) location.href = this.logoLink
    else this.router.navigateByUrl(this.logoLink)
  }

  private initLogos() {
    if (!this.hideHeader) {
      const instanceId = this.instanceService.activeInstance?._id
      if (instanceId) {
        this.logoRectangleUrl = `${environment.url}/api/instance/asset/${instanceId}/logoImageRectangle`
        this.logoSquareUrl = `${environment.url}/api/instance/asset/${instanceId}/logoImageSquare`
      } else {
        this.logoRectangleUrl = `${environment.url}/assets/brand/arzt-direkt--white.svg`
        this.logoSquareUrl = `${environment.url}/assets/brand/arzt-direkt--white--small.svg`
      }
    }
  }


}
