import { PdfHeader, PdfTable } from '@a-d/entities/DataForPdfCreation.entity';
import { LanguageService } from '@a-d/i18n/language.service';
import { isLegacyCustomForm } from '@a-d/wfr/wfa/types/legacy-custom-form';
import { isWfaFormWithResponse } from '@a-d/wfr/wfa/types/wfa-form-with-response';
import { convertFormResponseToHumanReadable, getFormTitle, SurveyjsResponse } from '@a-d/wfr/wfa/wfa-for-ad-frontend-link';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import slugify from '@sindresorhus/slugify';
import dayjs from 'dayjs';
import { Insurance, InsuranceBesType, InsuranceType } from 'lib';
import { NGXLogger } from 'ngx-logger';
import { Columns, Img, PdfMakeWrapper, Table, Toc, TocItem, Txt, Ul } from 'pdfmake-wrapper';
import pdfFonts from "pdfmake/build/vfs_fonts"; // pdfmake default fonts
import { InstanceService } from 'projects/arzt-direkt/src/app/instance/instance.service';
import { catchError, from, mergeMap, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserService } from '../dashboard/user/user.service';
import { InstanceForm, PrivatePaymentHandler } from '../entities/InstanceForm.entity';
import { InstancePatientDocumentModules } from '../entities/InstanceSettings.entity';
import { Asset, Assets, PatientSession, PatientSessionState, PatientSessionStateElement, PickedStateData } from '../entities/PatientSession.entity';
import { User } from '../entities/User.entity';
import { PatientSessionFormattersService } from '../formatters/patient-session-formatters.service';
import { UserFormattersService } from '../formatters/user-formatters';
import { I18NStringPipe } from '../i18n/i18n.pipe';
import { AnamneseForm, AnamneseFormsService } from '../instance-form/anamnese/anamnese-forms.service';
import { InstanceFormPaymentService } from '../instance-form/payment/instance-form-payment.service';
import { InsuranceService } from '../insurance/insurance.service';
import { PatientSessionStatesService } from '../patient-session/patient-session-states.service';
import { PatientSessionService } from '../patient-session/patient-session.service';
import { Documentation } from './../entities/Documentation.entity';
import { Person } from './../entities/Person.entity';



export enum TableCellDisplayType {
  List = 'list',
  Text = 'text',
  // Image = 'image',
}


@Injectable({
  providedIn: 'root',
})
export class PdfService {
  private PATIENTDATA_TITLE = 'arzt-direkt Export';

  // person headers
  private PERSON_NAME_TITLE = 'Titel';
  private PERSON_FIRST_NAME = 'Vorname';
  private PERSON_LAST_NAME = 'Nachname';
  private PERSON_NAME_AFFIX = 'Namenszusatz';
  private PERSON_GENDER = 'Geschlecht';
  private PERSON_BIRTHDATE = 'Geburtstag';
  private PERSON_AGE = 'Alter';
  private PERSON_EMAIL = 'Email';
  private PERSON_TELEPHONE = 'Telefon';
  private PERSON_ZIP = 'PLZ';
  private PERSON_CITY = 'Ort';
  private PERSON_ADDRESS = 'Straße und Hausnummer';
  private PERSON_ADDRESS_2 = 'Adresszusatz';
  private PERSON_DOCTOR = 'Hausarzt';
  private PERSON_JOB = 'Beruf';

  // doc headers
  private DOCTOR_NAME_TITLE = 'Titel';
  private DOCTOR_FIRST_NAME = 'Vorname';
  private DOCTOR_LAST_NAME = 'Nachname';
  private DOCTOR_NAME_AFFIX = 'Namenszusatz';
  private DOCTOR_EMAIL = 'Email';
  private DOCTOR_WEBSITE = "Website"
  private DOCTOR_TELEPHONE = 'Telefon';
  private DOCTOR_ZIP = 'PLZ';
  private DOCTOR_CITY = 'Ort';
  private DOCTOR_ADDRESS_1 = 'Straße und Hausnummer';
  private DOCTOR_ADDRESS_2 = 'Adresszusatz';
  private DOCTOR_COUNTRY = 'Land';


  // insurance headers
  private INSURANCE_TYPE = 'Art der Versicherung';
  private INSURANCE_NAME = 'Krankenkasse';
  private INSURANCE_NUMBER = 'Kassennummer (IK)';
  private BARRACKS_NUMBER = 'Kasernennummer';
  private INSURANT_STATUS = 'Versichertenstatus';
  private INSURANT_NUMBER = 'Versichertennummer';
  private PERSONAL_ID = 'Personenkennziffer (PK)';
  private BES_TYPE = 'Besonderer Versichertenstatus';
  private EXPIRATION_DATE = 'Ablaufdatum Gesundheitskarte';

  // documentation headers
  private ATTEST_DOCTOR_NOTES = 'Private Notizen';
  private ATTEST_APPROVED_AT = 'Attest bewilligt am';
  private ATTEST_START_DATE = 'Arbeitsunfähig seit';
  private ATTEST_END_DATE = 'Arbeitsunfähig bis';
  private ATTEST_DURATION = 'Attest-Dauer';
  private ATTEST_SENT = 'Attest versendet';
  private ATTEST_SENT_AT = 'Attest versendet am';

  constructor(
    private insuranceService: InsuranceService,
    private anamneseFormsService: AnamneseFormsService,
    private patientSessionFormatter: PatientSessionFormattersService,
    private userFormatter: UserFormattersService,
    private patientSessionStatesService: PatientSessionStatesService,
    private instanceFormPaymentService: InstanceFormPaymentService,
    private patientSessionService: PatientSessionService,
    private userService: UserService,
    private instanceService: InstanceService,
    private i18nStringPipe: I18NStringPipe,
    private http: HttpClient,
    private logger: NGXLogger,
    private languageService: LanguageService
  ) { }

  private getDoctorByIdQuery(id: string): Observable<{ status: number, user: User }> {
    return this.http.post('/api/portal/userById', { id: id }, { headers: { 'x-app-id': environment.appId, 'x-api-key': environment.apiKey } }).pipe(
      catchError((error) => of({ status: error.status, user: undefined }))
    ) as Observable<{ status: number, user: User }>;
  }

  public exportPatientSession(session: PatientSession, downloadPdf: boolean, includeImages: boolean = false): Observable<any> {
    return new Observable((observer) => {
      let getDoctor: Observable<User | undefined>;
      // depending on from where this funtion is called, session.doctor and session.instance may be different
      if (session.doctor?.arzt && session.instance) {  // doctor is appended as User
        const newDoc: User = { arzt: session.doctor.arzt, instance: session.instance } as User;
        getDoctor = of(newDoc)
      }
      else if (session.doctor && typeof (session.doctor) === "string") { // doctor is appended "by ID"
        getDoctor = this.getDoctorByIdQuery(session.doctor).pipe(mergeMap(((result: { status: number, user: User | undefined }) => of(result.user))))
      } else {  // try to find doctorID in stateHistory
        const doctorId: string | undefined = session.stateHistory.map((state: PatientSessionStateElement) => state.userId).find((id: string | undefined) => !!id);
        if (doctorId) getDoctor = this.getDoctorByIdQuery(doctorId).pipe(mergeMap(((result: { status: number, user: User | undefined }) => of(result.user))))
        else { // no chance to get doctor-user-Info - get it from BE
          getDoctor = this.patientSessionService.querySessionById(session._id).pipe(
            mergeMap((fetchedSession: PatientSession) => {
              if (typeof (fetchedSession.doctor) === "string") return this.getDoctorByIdQuery(fetchedSession.doctor)
              return of({ status: 200, user: undefined })
            }),
            mergeMap(((result: { status: number, user: User | undefined }) => of(result.user)))
          )
        }
      }
      getDoctor.subscribe((user: User | undefined) => {
        const doctor: User | undefined = user;
        const anamnese = session.anamnese
        const documentation: Documentation = session.documentation
        const insurance: Insurance = session.insurance
        const person: Person = session.person
        const form: InstanceForm = session.form
        // console.log('exportPatientSession: session = ', session)
        // console.log('exportPatientSession: session.documentation = ', session.documentation)

        // const { doctor, anamnese, documentation, insurance, person, form } = session

        PdfMakeWrapper.setFonts(pdfFonts);
        const pdf: PdfMakeWrapper = new PdfMakeWrapper();
        const fullNameDoctor = doctor ? this.userFormatter.fullName(doctor).trim() : undefined;
        const fullNamePatient = this.patientSessionFormatter.fullName(person).trim()
        const filenameBase = slugify(fullNamePatient) || session._id
        const filename = `${filenameBase}_${dayjs().format('YY-MM-DD_HH-mm')}.pdf`
        this.setPdfInfo(pdf, this.PATIENTDATA_TITLE, fullNameDoctor, fullNamePatient);
        this.patientDataStyles(pdf);
        const dateFormatted = dayjs().format('DD.MM.YYYY HH:mm:ss') + ' Uhr'
        pdf.add(new Columns([
          new Txt(this.PATIENTDATA_TITLE).style('title').end,
          new Txt(dateFormatted).alignment('right').style('title').end
        ]).end)
        pdf.add(new Txt(fullNamePatient).style('header').margin([0, 17, 0, -15]).end);

        pdf.add(pdf.ln(2));

        this.addSession(pdf, session, 'archivedAt' in session);
        this.addAttestDocumentation(pdf, documentation || ({} as Documentation));
        if (doctor) this.addDoctor(pdf, doctor);
        this.addPatient(pdf, person, insurance);
        const anamneseForm = this.anamneseFormsService.getAccordingAnamneseForm(form);
        this.addAnamnese(pdf, form, anamneseForm, anamnese)

        const addImages$ = includeImages
          ? from(this.addAssetImages(pdf, session.assets))
          : of(null)

        addImages$.subscribe(() => {
          if (downloadPdf) {
            pdf.create().download(filename);
            observer.next();
            observer.complete();
            return;
          } else {
            pdf.create().getBase64((base64) => {
              observer.next('data:application/pdf;base64,' + base64);
              observer.complete();
              return;
            });
          }
        })
      })

    });
  }

  public exportFileWithTwoColumnTables(header: PdfHeader, data: PdfTable[]): Observable<any> {
    return new Observable((observer) => {
      // settings & metadata
      PdfMakeWrapper.setFonts(pdfFonts);
      const pdf: PdfMakeWrapper = new PdfMakeWrapper();
      this.setPdfInfo(pdf, header.pdfTitle, header.pdfAuthor, header.pdfSubject);
      this.patientDataStyles(pdf);

      // header
      pdf.add(new Columns([
        new Txt(header.lHeader).style('title').end,
        new Txt(header.rHeader).alignment('right').style('title').end
      ]).end)
      pdf.add(new Txt(header.title).style('header').margin([0, 17, 0, -15]).end);
      pdf.add(pdf.ln(2));

      if (header?.warning !== null && header?.warning !== undefined) {
        pdf.add(new Txt(header.warning).style('italics').end);
        pdf.add(pdf.ln(2));
      }

      // body
      data.forEach(pdfTable => {
        const table = new Map()
        pdfTable.content.forEach(row => {
          table.set(row[0], row[1])
        })
        this.addTable(pdf, [pdfTable.lTitle, pdfTable.rTitle], table);
        pdf.add(pdf.ln(2));
      })

      pdf.create().download(header.filename);
      observer.next();
      observer.complete();
      return;
    })
  }

  //////////////////////////////////////////////////////////////////////////////
  // private ///////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  private setPdfInfo(pdf: PdfMakeWrapper, title: string, author: string, subject: string): void {
    pdf.info({
      title: title,
      author: author,
      subject: subject
    });
  }

  private addSession(pdf: PdfMakeWrapper, session: PatientSession, isArchive: boolean) {
    this.addSubheader(pdf, isArchive ? 'Sitzungsarchiv' : 'Sitzung')
    pdf.add(pdf.ln(1));
    const sessionMap = new Map();

    if (session.tomedoPatientId) {
      sessionMap.set('tomedo Patienten-ID', {
        text: session.tomedoPatientId,
        link: 'tomedo:///patient#' + session.tomedoPatientId
      })
    }

    sessionMap.set("Sitzungs-ID", session._id)
    const activeSession = this.instanceService.activeInstance;
    let identifier = session.instance?.identifier;
    if (!identifier && activeSession && activeSession.identifier) {
      identifier = activeSession.identifier;
    }
    const sessionUrl = session['url'] ? session['url'] : `${environment.url}/${identifier}/dashboard/behandlungen/details/${session._id}`;
    sessionMap.set("URL", sessionUrl)

    sessionMap.set("Sitzung angelegt am", dayjs(session.createdAt).format('DD.MM.YYYY, HH:mm') + ' Uhr')
    const documentation = session.documentation;
    if (documentation?.consultationStart) {
      sessionMap.set('Sitzungsbeginn', `${documentation.consultationStart} Uhr`)
    }
    if (documentation?.consultationEnd) {
      sessionMap.set('Sitzungsende', `${documentation.consultationEnd} Uhr`)
    }

    const archivedAt = isArchive ? (<any>session).archivedAt : this.patientSessionStatesService.scheduledArchivingDate(session)
    const archivedAtTitle = isArchive ? 'Archiviert am' : 'Archivierung geplant am'
    const archivedAtValue = isArchive ? dayjs(archivedAt).format('DD.MM.YYYY, HH:mm') + " Uhr" : dayjs(archivedAt).format('DD.MM.YYYY')
    sessionMap.set(archivedAtTitle, archivedAtValue)

    const paymentIsRequired = session.payment?.isRequired
    sessionMap.set('Bezahlpflichtig', paymentIsRequired ? 'Ja' : 'Nein')

    if (paymentIsRequired && !isArchive) {
      const paymentStatus = this.patientSessionService.getPaymentStatus(session)
      const praxisName = this.userService.getPraxis(session.doctor, session.instance)?.name ??
        (this.patientSessionStatesService.latestStateElement(session, PatientSessionState.Picked)?.data as PickedStateData)?.praxisName;
      const paymentHandler = session.form.privatePayment.handler
      const paymentHandlerFormatted = {
        [PrivatePaymentHandler.ArztDirekt]: 'arzt-direkt',
        [PrivatePaymentHandler.External]: praxisName,
      }[paymentHandler]

      sessionMap.set('Zahlungsabwickler', paymentHandlerFormatted)

      if (paymentHandler === PrivatePaymentHandler.ArztDirekt) {
        sessionMap.set('Bezahlstatus', paymentStatus)
        sessionMap.set('Order-ID', session?.payment?.paypal?.orderId)
        sessionMap.set('Authorization-ID', session?.payment?.paypal?.authorizationId)
        sessionMap.set('Payer-ID', session?.payment?.paypal?.payerId)
      }
    }

    this.addTable(pdf, ['Kategorie', 'Daten'], sessionMap);
    pdf.add(pdf.ln(1));
  }

  private addDoctor(pdf: PdfMakeWrapper, user: User) {
    const arzt = user.arzt;
    this.addSubheader(pdf, 'Arzt');
    pdf.add(pdf.ln(1));
    const arztMap = new Map();
    if (arzt.nameTitle) arztMap.set(this.DOCTOR_NAME_TITLE, arzt.nameTitle);
    if (arzt.fname) arztMap.set(this.DOCTOR_FIRST_NAME, arzt.fname);
    if (arzt.lname) arztMap.set(this.DOCTOR_LAST_NAME, arzt.lname);
    const contact = user.instance.contact;
    if (contact) {
      if (contact.country) arztMap.set(this.DOCTOR_COUNTRY, contact.country)
      if (contact.zip) arztMap.set(this.DOCTOR_ZIP, contact.zip);
      if (contact.city) arztMap.set(this.DOCTOR_CITY, contact.city);
      if (contact.address_1) arztMap.set(this.DOCTOR_ADDRESS_1, contact.address_1);
      if (contact.address_2) arztMap.set(this.DOCTOR_ADDRESS_2, contact.address_2);

      if (contact.email) arztMap.set(this.DOCTOR_EMAIL, contact.email);
      if (contact.phone) arztMap.set(this.DOCTOR_TELEPHONE, contact.phone);
      if (contact.website) arztMap.set(this.DOCTOR_WEBSITE, contact.website);
    }
    this.addTable(pdf, ['Kategorie', 'Daten'], arztMap);
    pdf.add(pdf.ln(1));
  }

  private addPatient(pdf: PdfMakeWrapper, patient: Person, insurance: Insurance) {
    this.addSubheader(pdf, 'Patient')
    pdf.add(pdf.ln(1));
    const patientMap = new Map();

    if (patient.nameTitle) patientMap.set(this.PERSON_NAME_TITLE, patient.nameTitle)
    if (patient.fname) patientMap.set(this.PERSON_FIRST_NAME, patient.fname);
    if (patient.lname) patientMap.set(this.PERSON_LAST_NAME, patient.lname);
    if (patient.nameAffix) patientMap.set(this.PERSON_NAME_AFFIX, patient.nameAffix)
    patientMap.set(this.PERSON_GENDER, patient.gender);
    if (patient.birthDate) patientMap.set(this.PERSON_BIRTHDATE, dayjs(patient.birthDate).format('DD.MM.YYYY'));
    patientMap.set(this.PERSON_AGE, patient.age + ' Jahre');
    if (patient.email) patientMap.set(this.PERSON_EMAIL, patient.email);
    if (patient.phone) patientMap.set(this.PERSON_TELEPHONE, patient.phone);
    if (patient.zip) patientMap.set(this.PERSON_ZIP, patient.zip);
    if (patient.city) patientMap.set(this.PERSON_CITY, patient.city);
    if (patient.street || patient.streetNumber) patientMap.set(this.PERSON_ADDRESS, patient.street + ' ' + patient.streetNumber);
    if (patient.address_2) patientMap.set(this.PERSON_ADDRESS_2, patient.address_2);
    if (patient.hausarzt) patientMap.set(this.PERSON_DOCTOR, patient.hausarzt);
    if (patient.beruf) patientMap.set(this.PERSON_JOB, patient.beruf);

    patientMap.set(this.INSURANCE_TYPE, this.insuranceService.mediumNameFormatted(insurance.insuranceType));
    if (insurance.insuranceName) patientMap.set(this.INSURANCE_NAME, insurance.insuranceName);
    if (insurance.insuranceNumber && insurance.insuranceType === InsuranceType.BES && insurance.besType === InsuranceBesType.bw) { patientMap.set(this.BARRACKS_NUMBER, insurance.insuranceNumber); }
    else if (insurance.insuranceNumber) { patientMap.set(this.INSURANCE_NUMBER, insurance.insuranceNumber); }
    if (insurance.insurantNumber && insurance.insuranceType === InsuranceType.BES && insurance.besType === InsuranceBesType.bw) { patientMap.set(this.PERSONAL_ID, insurance.insurantNumber); }
    else if (insurance.insurantNumber) { patientMap.set(this.INSURANT_NUMBER, insurance.insurantNumber); }
    if (insurance.insurantStatus) patientMap.set(this.INSURANT_STATUS, this.insuranceService.insurantStatusFormatted(insurance.insurantStatus));
    if (insurance.besType) patientMap.set(this.BES_TYPE, insurance.besType);
    if (insurance.expirationDate) patientMap.set(this.EXPIRATION_DATE, dayjs(insurance.expirationDate).format('DD.MM.YYYY'));
    if (insurance.noExpirationDate) patientMap.set(this.EXPIRATION_DATE, "Auf der Karte ist kein Ablaufdatum angegeben.");

    this.addTable(pdf, ['Kategorie', 'Daten'], patientMap);
    pdf.add(pdf.ln(1));
  }

  private addAnamnese(pdf: PdfMakeWrapper, form: InstanceForm, anamneseForm: AnamneseForm, anamnese: any): void {
    this.addSubheader(pdf, 'Anamnese')
    pdf.add(pdf.ln(1));
    const anamneseMap = new Map();

    let humanReadableResponse: SurveyjsResponse;
    let wfaFormTitle: string;
    if (isWfaFormWithResponse(anamnese) === true) {
      const {
        form,
        formResponse,
        patientLang
      } = anamnese as unknown as any // 2024-03-28 @SaBi add patientLang to wfaFormWithResponseJoi ?
      const locale = patientLang == null ? this.languageService.activeBaseLang : patientLang
      humanReadableResponse = convertFormResponseToHumanReadable(form, formResponse.surveyjsResponse, locale)
      wfaFormTitle = getFormTitle(form, locale)
    }

    let formularFormatted = wfaFormTitle ?? `${this.i18nStringPipe.transform(form.title)}`
    if (form.identifier && !isLegacyCustomForm(form.anamneseFormIdentifier)) formularFormatted += ` (${form.identifier})`
    anamneseMap.set("Formular", formularFormatted);

    const anamneseResponse = humanReadableResponse == null ? anamnese : humanReadableResponse
    for (let key in anamneseResponse) {
      const value = anamneseResponse[key]
      const displayType = this.getDisplayType(value)
      const title = (anamneseForm && anamneseForm.fieldTitles[key]) ? anamneseForm.fieldTitles[key] : key

      if (displayType === TableCellDisplayType.Text) {
        anamneseMap.set(title, value)

      } else if (displayType === TableCellDisplayType.List) {
        anamneseMap.set(title, value.join(', '))
      }
    }

    this.addTable(pdf, ['Kategorie', 'Daten'], anamneseMap);
    pdf.add(pdf.ln(1));
  }

  private addAttestDocumentation(pdf: PdfMakeWrapper, documentation: Documentation) {
    console.log("addAttestDocumentation: documentation = ", documentation)
    const chatExists = documentation.chat?.length
    const notesExist = documentation.notes
    const showAttestData = (this.instanceService.activeInstance.settings.general?.patientDocumentModules || []).includes(InstancePatientDocumentModules.Atteste)
    if (!chatExists && !showAttestData && !notesExist) return

    this.addSubheader(pdf, 'Dokumentation')
    pdf.add(pdf.ln(1));
    const documentationMap = new Map();
    // Doctor Notes
    if (notesExist) {
      console.log("addAttestDocumentation: notesExist")
      documentationMap.set(this.ATTEST_DOCTOR_NOTES, documentation.notes);
    }

    // Chat
    if (chatExists) {
      console.log("addAttestDocumentation: chatExists")
      const chat = documentation.chat.join('\n');
      documentationMap.set('Chat-Verlauf', chat)
    }

    // Attest-Data
    if (showAttestData) {
      const attestApproved = documentation.attest?.approved
      // documentationMap.set("Attest bewilligt", attestApproved ? 'Ja' : 'Nein');
      // if (attestApproved) {
      //   documentationMap.set(this.ATTEST_APPROVED_AT, dayjs(documentation.attest.approvedAt).format('DD.MM.YYYY, HH:mm:ss') + ' Uhr');
      //   documentationMap.set(this.ATTEST_START_DATE, dayjs(documentation.attest.startDate).format('DD.MM.YYYY'));
      //   documentationMap.set(this.ATTEST_END_DATE, dayjs(documentation.attest.endDate).format('DD.MM.YYYY'));
      //   documentationMap.set(this.ATTEST_DURATION, this.patientSessionFormatter.attestDurationFormatted(documentation.attest.durationDays));
      //   documentationMap.set(this.ATTEST_SENT, documentation.attest.sent ? 'Ja' : 'Nein');
      //   if (documentation.attest.sentAt) documentationMap.set(this.ATTEST_SENT_AT, dayjs(documentation.attest.sentAt).format('DD.MM.YYYY, HH:mm') + ' Uhr');
      // }
    }

    this.addTable(pdf, ['Kategorie', 'Daten'], documentationMap);
    pdf.add(pdf.ln(1));
  }


  private async addAssetImages(pdf: PdfMakeWrapper, assets: Assets) {
    if (!assets) return

    // Determine image-assets
    const assetImages: Asset[] = []
    for (const key in assets) {
      const asset = assets[key]
      const assetType = (asset?.meta?.type || '')
      if (!assetType.startsWith('image') || !asset?.dataUri) continue;
      assetImages.push(asset)
    }
    if (!assetImages?.length) return

    // Convert image-assets to pdf-images and add to document
    for (const asset of assetImages) {
      await this.addAssetImage(pdf, asset, true)
    }
  }


  private async addAssetImage(pdf: PdfMakeWrapper, asset: Asset, includeMetadata: boolean) {
    if (!asset) return

    const assetImage = await new Img(asset.dataUri).fit([550, 450]).alignment('center').build()
    if (!assetImage) return

    this.addSubheader(pdf, 'Anhang', true)
    pdf.add(pdf.ln(1))

    if (includeMetadata && asset.meta) {
      const metadataMap = new Map()

      if (asset.meta.name) metadataMap.set("Name", asset.meta.name);
      if (asset.meta.description) metadataMap.set("Beschreibung", asset.meta.description);

      if (metadataMap.size) {
        this.addTable(pdf, ['Kategorie', 'Daten'], metadataMap, 0)
        pdf.add(pdf.ln(4))
      }
    }

    pdf.add(assetImage)
  }


  private addTableOfContent(pdf: PdfMakeWrapper) {
    pdf.add(
      new Toc(
        new Txt('INDEX').bold().end
      ).textStyle({ italics: true }).end
    );
    pdf.add(
      new TocItem(
        new Txt('Second page').pageBreak('before').end
      ).tocStyle({ color: 'blue' }).end
    );
  }

  private addUnorderedList(pdf: PdfMakeWrapper, items: string[], listTitle: string): void {
    pdf.add(new Txt(listTitle).style('subsubheader').end);
    pdf.add(new Ul(items).type('circle').end);
  }

  private addSubheader(pdf: PdfMakeWrapper, title: string, pageBreakBefore?: boolean) {
    if (pageBreakBefore) {
      pdf.add(new Txt(title).style('subheader').margin([0, 10, 0, 0]).pageBreak('before').end);
    } else {
      pdf.add(new Txt(title).style('subheader').margin([0, 10, 0, 0]).end);
    }
  }

  private addTable(pdf: PdfMakeWrapper, columns: string[], rowData: Map<string, string>, headerRows: number = 1) {
    pdf.add(
      new Table(
        this.fillTable(columns, rowData)
      ).headerRows(headerRows).widths([175, '*']).layout('lightHorizontalLines').end
    )
  }

  private getDisplayType(val: any): TableCellDisplayType {
    const isString = (val) => typeof val === 'string'
    const isNumber = (val) => typeof val === 'number'
    const isArray = (val) => Array.isArray(val)

    if (!val) return null
    else if (isArray(val) && val.length) return TableCellDisplayType.List
    else if (isString(val) && val.length) return TableCellDisplayType.Text
    else return TableCellDisplayType.Text
  }

  private fillTable(columns: string[], rowData: Map<string, string>) {
    const content = [];
    if (columns) content.push(this.addTableHeader(columns));
    rowData.forEach((value, key) => {
      const isLink = value && typeof value == 'string' && (value.startsWith('http://') || value.startsWith('https://'))
      const valueTxt = isLink
        ? new Txt(value).style('text').link(value).decoration('underline').alignment('left')
        : new Txt(value).style('text')

      content.push([new Txt(key).fontSize(12).end, valueTxt.end]);
    });
    return content;
  }

  private addTableHeader(columns: string[]) {
    const headers = [];
    columns.forEach((columnKey) => {
      const header = new Txt(columnKey).bold().end;
      headers.push(header);
    })
    return headers;
  }

  private patientDataStyles(pdf: PdfMakeWrapper) {
    pdf.styles({
      title: {
        fontSize: 11,
        color: 'gray'
      },
      header: {
        bold: true,
        fontSize: 28,
        alignment: 'center'
      },
      subheader: {
        bold: true,
        fontSize: 20,
        alignment: 'left',
      },
      subsubheader: {
        bold: true,
        fontSize: 18,
        alignment: 'left'
      },
      text: {
        bold: false,
        fontSize: 12,
        alignment: 'justify'
      },
      italics: {
        italics: true,
        fontSize: 12,
        alignment: 'justify'
      }
    })
  }
}
