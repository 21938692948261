import { FormFieldsModule } from '@a-d/forms/form-fields.module';
import { SharedModule } from '@a-d/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslateModule } from '@ngx-translate/core';
import { GoComponent } from './go/go.component';
import { ReiseNGoComponent } from './reise-n-go/reise-n-go.component';
import { DestinationComponent } from './reise/destination/destination.component';
import { ReiseComponent } from './reise/reise.component';


@NgModule({
    imports: [
    CommonModule,
    FormsModule,
    FormFieldsModule,
    MatStepperModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    ReiseComponent,
    DestinationComponent,
    GoComponent,
    ReiseNGoComponent,
],
    exports: [ReiseComponent, GoComponent, ReiseNGoComponent],
})
export class ZrmModule { }
