import { LoadableLinkType } from './single-or-multilink.state'
import { catchError } from 'rxjs/operators'
import { switchMap, of } from 'rxjs'
import {
  BooleanResponseInfo,
  LinkType,
  SidebarEntry,
} from './single-or-multilink.types'
import { SingleOrMultilinkApiService } from './api/single-or-multilink.api.service'
import { getVorbefuellungResponse } from './../multilink-viewer/get-vorbefuellung-response'
import { Assigner } from './../../../utility/assign'
import {
  getResponseId,
  getIndexByResponseId,
  getNextResponseId,
  getDefaultActiveResponseId,
} from './response-id.utils'
import {
  selectResponseIds,
  updateFormValidity,
  selectIcon,
} from './select-icon'
import { NotificationService } from './../../../utility-components/notification/notification.service'
import { nullish, get, set, notNullish } from '@arzt-direkt/wfa-generic-utils'
import { assign } from './../../../utility/assign'
import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'

import {
  initialSingleOrMultilinkState,
  SingleOrMultilinkState as State,
} from './single-or-multilink.state'
import {
  WfaFormWithResponse as Fwr,
  WfaFormResponse,
  Id,
  AnyWfaForm,
} from '@arzt-direkt/wfa-definitions'
import {
  firstValueFrom,
  tap,
  map,
  distinctUntilChanged,
  withLatestFrom,
  filter,
  take,
  Observable,
} from 'rxjs'
import {
  notNullishMultilinkState,
  getMultilinkState,
} from './not-nullish-multilink-state'

/**
 * Manages state for single/multilink form responses
 */
@Injectable({
  providedIn: 'root',
})
export class SingleOrMultilinkStore extends ComponentStore<State> {
  constructor(
    public ns: NotificationService,
    private singleOrMultilinkApiService: SingleOrMultilinkApiService,
  ) {
    super(initialSingleOrMultilinkState)
  }

  /*****************************************************************************
   * Selectors
   *****************************************************************************/

  // Core state selectors
  readonly activeResponseId$ = this.select((s) => s.activeResponseId).pipe(
    distinctUntilChanged(),
    filter(notNullish),
  )
  readonly env$ = this.select((s) => s.env)
  readonly fwrs$ = this.select((s) => s.fwrs).pipe(filter(notNullish))
  readonly linkType$ = this.select((s) => s.linkType)
  readonly valid$ = this.select((s) => s.valid)
  readonly visited$ = this.select((s) => s.visited)
  readonly requestResponse$ = this.select((s) => s.requestResponseCounter > 0)

  // Form selectors
  readonly fwr$ = this.select((s) => ({
    rerenderCounter: s.rerenderCounter,
    fwr: s.fwrs?.find((fwr) => getResponseId(fwr) === s.activeResponseId),
  })).pipe(
    distinctUntilChanged((p, c) => p.rerenderCounter === c.rerenderCounter),
    map(({ fwr }) => fwr),
    filter(notNullish),
    take(5000),
  )
  readonly form$ = this.fwr$.pipe(map((s) => s.form))
  readonly formResponse$ = this.fwr$.pipe(map((fwr) => fwr.formResponse))
  readonly formLocales$ = this.fwr$.pipe(
    map((fwr) => Object.keys(fwr.form.title)),
  )

  // Navigation and UI selectors
  readonly fwrIndex$ = this.select(getMultilinkState).pipe(
    filter(notNullishMultilinkState),
    map(getIndexByResponseId),
    distinctUntilChanged(),
  )
  readonly nextFwr$ = this.fwrIndex$.pipe(
    filter(notNullish),
    withLatestFrom(this.fwrs$),
    map(([fwrIndex, fwrs]) => get(fwrs, `${fwrIndex + 1}`)),
  )
  readonly isMultilink$ = this.fwrs$.pipe(
    map((fwrs) => fwrs.length > 1),
    distinctUntilChanged(),
  )
  readonly titlesAndIds$ = this.select(({ fwrs }) =>
    fwrs?.map((fwr) => ({
      title: fwr.form.title,
      responseId: fwr.formResponse.responseId.toString(),
    })),
  ).pipe(filter(notNullish))
  readonly fwrsIds$ = this.select(selectResponseIds).pipe(filter(notNullish))
  readonly validIcons$ = this.activeResponseId$.pipe(
    withLatestFrom(this.valid$, this.fwrsIds$, this.visited$),
    map(selectIcon),
  )
  readonly sidebarEntries$: Observable<SidebarEntry[]> =
    this.activeResponseId$.pipe(
      withLatestFrom(this.validIcons$, this.titlesAndIds$),
      map(([_, icons, titlesAndIds]) =>
        titlesAndIds.map((val, ind) => ({ ...val, icon: icons[ind] })),
      ),
    )

  /*****************************************************************************
   * Updaters
   *****************************************************************************/
  // Direct state access updaters
  readonly setActiveResponseId = this.updater(
    assignAndRerenderFwr('activeResponseId'),
  )
  readonly setEnv = this.updater(assign('env'))
  readonly setFwrs = this.updater((state: State, fwrs: Fwr[]) => {
    const activeResponseId = getDefaultActiveResponseId(fwrs)
    const rerenderCounter = state.rerenderCounter + 1
    return { ...state, fwrs, activeResponseId, rerenderCounter }
  })
  readonly setLinkType = this.updater(assign('linkType'))
  readonly setValid = this.updater(updateFormValidity)
  readonly setVisited = this.updater(
    (state: State, { responseId, visited }: BooleanResponseInfo<'visited'>) => {
      const newVisited = { ...state.visited, [responseId.toString()]: visited }
      return { ...state, visited: newVisited }
    },
  )

  // Current form updaters
  readonly setFwr = this.updater((state: State, fwr: Fwr) => {
    const activeResponseId = getResponseId(fwr)
    const nextState = { ...state, activeResponseId }
    const index = getIndexByResponseId(nextState)
    set(nextState, `fwrs.${index}`, fwr)
    return nextState
  })
  readonly setForm = this.updater(assignFwr('form'))
  readonly setFormResponse = this.updater(assignFwr('formResponse'))
  readonly setSurveyjsResponse = this.updater(
    assignFwr('formResponse.surveyjsResponse'),
  )

  readonly setFwrsResponse = this.updater(
    (state: State, formResponse: WfaFormResponse) => {
      const index = getIndexByResponseId(state)
      if (nullish(index)) {
        this.ns.error('WFA.MULTILINK-VIEWER.SET-FWRS-RESPONSE-ERROR')
        return state
      }
      set(state, `fwrs.${index}.formResponse`, formResponse)
      return state
    },
  )

  // Navigation and UI updaters
  readonly goToNextFwr = this.updater((state: State) => {
    if (nullish(state.fwrs)) {
      this.ns.error('WFA.MULTILINK-VIEWER.SELECT-NEXT-590')
      return state
    }

    const activeResponseId = getNextResponseId(state)
    if (nullish(activeResponseId)) {
      this.ns.error('WFA.MULTILINK-VIEWER.SELECT-NEXT-FWR-ERROR')
      return state
    }

    set(state, 'activeResponseId', activeResponseId)
    const index = getIndexByResponseId(state)
    if (nullish(index)) {
      this.ns.error('WFA.MULTILINK-VIEWER.SELECT-NEXT-590')
      return state
    }

    const fwr = state.fwrs[index]
    const vorbefuellungResponse = getVorbefuellungResponse(fwr, state.fwrs)
    set(fwr, 'formResponse.surveyjsResponse', vorbefuellungResponse)
    return {
      ...state,
      rerenderCounter: state.rerenderCounter + 1,
    }
  })

  // Load and submit forms
  loadData(routeParams: Record<string, string>) {
    return this.singleOrMultilinkApiService.loadLinkData(routeParams).pipe(
      filter(notNullish),
      tap((linkData) => {
        this.setFwrs(linkData.fwrs)
        this.setLinkType(linkData.type)
      }),
      catchError((err) => {
        this.ns.error([
          `WFA.NOTIFICATION.MULTILINK-VIEWER.LOAD-FORM-ERROR`,
          JSON.stringify(err, null, 2),
        ])
        console.error(`[loadData]: HTTP request error:`, err)
        this.setFwrs([])
        this.setLinkType(<LoadableLinkType>'loadError')
        return of(null)
      }),
    )
  }

  submitFormResponse() {
    return of(null).pipe(
      withLatestFrom(this.fwrs$, this.linkType$),
      filter(([_, _fwrs, type]) => type !== 'loading'),
      switchMap(([_, fwrs, type]) =>
        this.singleOrMultilinkApiService.submitLinkData({
          fwrs,
          type: <LinkType>type,
        }),
      ),
    )
  }
}

function assignFwr(path: string): Assigner<State> {
  return (state: State, value: unknown) => {
    const index = getIndexByResponseId(state)
    set(state, `fwrs.${index}.${path}`, value)
    return { ...state, rerenderCounter: state.rerenderCounter + 1 }
  }
}

function assignAndRerenderFwr(path: string): Assigner<State> {
  return (state: State, value: unknown) => {
    set(state, `${path}`, value)
    return { ...state, rerenderCounter: state.rerenderCounter + 1 }
  }
}
