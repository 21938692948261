import Joi from 'joi'

import { BaseQuestionJoi } from './base-question.joi'
import { example } from './examples/wfa-academic-title-question.joi.example'

export const WfaAcademicTitleQuestionJoi = BaseQuestionJoi.keys({
  type: Joi.string().valid('academic-title').required(),
})
  .meta({
    className: 'WfaAcademicTitleQuestion',
  })
  .example(JSON.stringify(example, null, 2))
