import { BookingResult, MessageToApp } from '@a-d/entities/Booking.entity';
import { LanguageService } from '@a-d/i18n/language.service';
import { PdfService } from '@a-d/misc/pdf.service';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from "@angular/router";
import { environment } from '@env/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilityService } from '../../crypto/utility.service';
import { InstanceService } from '../../instance/instance.service';
import { WrapperOTKComponent } from '../../wrapper-otk/wrapper-otk.component';
import { BookingService } from "../booking.service";
@Component({
  selector: 'app-booking-result',
  templateUrl: './booking-result.component.html',
  styleUrls: ['./booking-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    WrapperOTKComponent,
    MatButtonModule,
    NgIf,
    FontAwesomeModule,
    TranslateModule,
  ],
})

export class BookingResultComponent implements OnInit {
  public unsubscribe$ = new Subject()
  public failureAssetUrl = `${environment.url}/assets/otk/booking/failure.svg`
  public successAssetUrl = `${environment.url}/assets/otk/booking/success.svg`
  public isStandaloneResult = false
  instanceName: string
  instanceShortName: string
  bookingResultTitle: string
  exportInProgress: boolean = false
  faSpinner = faSpinner
  seriesMsg = false
  bookingResult: BookingResult
  BookingResultSuccess: BookingResult = BookingResult.SUCCESS
  bookingResultFailure: BookingResult = BookingResult.FAILURE

  constructor(
    private instanceService: InstanceService,
    private router: Router,
    public bookingService: BookingService,
    private utilityService: UtilityService,
    private pdfService: PdfService,
    private translate: TranslateService,
    private languageService: LanguageService,
    private cd: ChangeDetectorRef
  ) { }

  routeToBookingPage() {
    const newUrl = this.router.url.split("/")[0] + "/" + this.instanceService.activeInstance.identifier + "/booking"
    this.router.navigateByUrl(newUrl)
  }

  ngOnInit() {
    this.isStandaloneResult = this.bookingService.isStandalone;
    this.bookingResult = this.bookingService.bookingResult
    // if the user got here without filling the form first, reroute to the first step.
    if (!this.bookingResult)
      this.routeToBookingPage()
    this.instanceName = this.instanceService.activeInstance.name
    this.instanceShortName = this.instanceService.activeInstance.shortName

    this.seriesMsg = this.bookingService.seriesMode$.getValue() && !this.bookingService.automaticSeriesSelection$.getValue()
    if (this.bookingResult === BookingResult.FAILURE)
      this.bookingService.cancelReservation(true).subscribe()
    this.languageChangeSubscribe()
  }

  routeToInstancePage() {
    const instanceWebsite = this.instanceService.activeInstance.contact.website;
    if (instanceWebsite) {
      const externalLink = this.utilityService.isExternalUrl(instanceWebsite) ? instanceWebsite : `https://${instanceWebsite}`;
      window.open(externalLink, '_blank');
    } else {
      const newUrl = this.router.url.split("/")[0] + "/" + this.instanceService.activeInstance.identifier
      this.router.navigateByUrl(newUrl)
    }
  }

  // It seems like this method is not called anywhere and was replaced by navigateOnBookingSuccess() in BookingService
  // Can be deleted after 2024-08-08
  // IF otkuser redirectionafterbookingurl is not empty string redirect after 2 seconds
  // checkForRedirect() {
  //   const redUrl = this.bookingService.otkUser?.redirectionAfterBookingUrl ?? "";
  //   console.log("cheching redirect", redUrl)
  //   window.location.href = redUrl
  // }

  private languageChangeSubscribe() {
    this.languageService.baseLanguageChangeSubject$.pipe(takeUntil(this.unsubscribe$)).subscribe((baseLanguage: any) => {
      setTimeout(() => {
        this.bookingResultTitle = this.translate.instant('OTK.STEPPER.PREP-BOOKING') + ' ' + this.instanceName
        this.cd.detectChanges()
      })
    });
  }

  messageToAppFinish() {
    this.bookingService.sendMessageToApp(MessageToApp.EXIT)
  }
}
