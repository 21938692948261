import { ActiveInstance } from '@a-d/entities/ActiveInstance.entity';
import { OTKFieldConfig } from '@a-d/entities/Calendar.entity';
import { ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import dayjs from 'dayjs';
import { of } from 'rxjs';

function setMagicFillValues(
  formGroup: UntypedFormGroup,
  cd: ChangeDetectorRef,
  customFields: OTKFieldConfig[],
  activeInstance: ActiveInstance
) {
  formGroup.get('fname').setValue('Patienti');
  formGroup.get('lname').setValue('Patientos');
  formGroup.get('gender')?.setValue('D');
  formGroup.get('birthDate')?.setValue(dayjs().subtract(20, 'years').toDate());
  formGroup.get('email').setValue(activeInstance.email)
  formGroup.get('emailConfirm').setValue(activeInstance.email)
  formGroup.get('phone')?.setValue('012345678901');
  formGroup.get('city')?.setValue('Berlin');
  formGroup.get('zip')?.setValue('10115');
  formGroup.get('street')?.setValue('Buchungstraße');
  formGroup.get('streetNumber')?.setValue('123');
  formGroup.get('country')?.setValue('Deutschland');
  formGroup.get('customerId')?.setValue('99');
  formGroup.get('insuranceName')?.setValue('Techniker Krankenkasse');
  formGroup.get('insuranceNumber')?.setValue('TK12345678');
  formGroup.get('info')?.setValue('Lange, aber notwendige Erläuterung');
  formGroup.get('confirmTreated')?.setValue(true);
  formGroup.get('confirmCorrect')?.setValue(true);
  formGroup.get('checkbox3')?.setValue(true);
  formGroup.get('checkbox4')?.setValue(true);
  formGroup.get('checkbox5')?.setValue(true);

  customFields.forEach((config) => {
    formGroup.get(config.identifier)?.setValue('test value');
  });
  cd.detectChanges();
  return of(null);
}

export { setMagicFillValues };
