import { Question } from 'survey-core'

import { QUESTION_TYPE } from './question-type'

export class HtmlEditorModel extends Question {
  override getType() {
    return QUESTION_TYPE
  }
  public get height(): string {
    return this.getPropertyValue('height')
  }
  public set height(val: string) {
    this.setPropertyValue('height', val)
  }
}
