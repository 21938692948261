import Joi from 'joi'

import { HumanReadableJoi } from './human-readable.joi'
import { MostRelaxedWfaFormJoi } from './wfa-form.joi'
import { WfaFormResponseJoi } from './wfa-form-response.joi'
import { wfaFormWithResponse } from './wfa-form-with-response.example'

export const WfaFormWithResponseJoi = Joi.object({
  formIsValid: Joi.boolean().optional(),
  formResponse: WfaFormResponseJoi.required(),
  form: MostRelaxedWfaFormJoi.required(),
  humanReadable: HumanReadableJoi.optional(),
  certificate: Joi.string()
    .optional()
    .description(
      "AD Instance (praxis)'s public key that can be used to encrypt form response.",
    ),
})
  .meta({
    className: 'WfaFormWithResponse',
  })
  .example(wfaFormWithResponse)
