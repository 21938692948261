<app-wrapper-otk [title]="'OTK.BOOKING-EDIT.APPOINTMENT-MANAGEMENT'"
  [shortTitle]="'OTK.BOOKING-EDIT.APPOINTMENT-MANAGEMENT-SHORT'" [bookingEdit]="true" [isLoading]="isLoading">

  <div class="booking-edit--content">
    <div class="booking-summary" [class.o-vh]="isLoading">

      <div class="summary-and-actions">

        <div class="summary" style="height: auto">
          {{ summaryText | translate }}
        </div>
        <hr>
        <div *ngIf="statusError" class="errorbox">
          <mat-icon>error</mat-icon>
          <span>{{ statusError | translate }}</span>
        </div>
      </div>
    </div>
  </div>


  <button attr.data-selenium-id="button-cancel-desktop" cancelButton mat-flat-button class="cancelButton hide--onSmall"
    [disabled]="isLoading || !canCancel" (click)="cancelAppointment()">
    <mat-icon class="button-icon">cancel</mat-icon> {{ 'OTK.BOOKING-EDIT.BUTTONS.CANCEL' | translate }}
  </button>

  <button attr.data-selenium-id="button-cancel-mobile" cancelButton mat-flat-button
    class="cancelButtonMobile show--onSmall" [disabled]="isLoading || !canCancel" (click)="cancelAppointment()">
    <mat-icon>cancel</mat-icon> {{ 'OTK.BOOKING-EDIT.BUTTONS.CANCEL-MOBILE' | translate }}
  </button>

</app-wrapper-otk>