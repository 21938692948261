import { PaymentResultCode } from '@a-d/entities/AdPay.entity';
import { InstanceService } from '@a-d/instance/instance.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookingPaymentService {

  constructor(
    private translateService: TranslateService,
    private instanceService: InstanceService,
    private httpClient: HttpClient
  ) { }

  public loadDeferredPayment(accessCode: string) {
    const instanceId = this.instanceService.activeInstance._id;
    const payDeferredBody = {
      instanceId: instanceId,
      accessCode: accessCode
    }
    return this.httpClient.post(`${environment.otkUrl}/api/adp/payLater`, payDeferredBody)
      .pipe(
        map((response: any) => response.paymentDeferred)
      );
  }

  public setDeferredDropinStatus(dropin: any, resultCode: PaymentResultCode) {
    if (resultCode !== PaymentResultCode.authorised) {
      this.handlePaymentError({ authorise: { resultCode: resultCode } }, dropin);
      return;
    }
    dropin.setStatus('success', { message: this.translateService.instant('OTK.BOOKING-PAY-DELAY.PAYMENT-SUCCESS') });

  }

  public handlePaymentError(response: any, dropin: any) {
    let error = ''
    const resultCode = response?.authorise?.resultCode;
    if (!resultCode) {
      return dropin.setStatus('error', { message: this.translateService.instant(error) });
    }
    // this.translateService.instant('OTK.NOTIFICATIONS.APPOINTMENT-CANCELED-ERROR')
    switch (resultCode) {
      case PaymentResultCode.refused:
        error = 'PAYMENT-ERROR.REFUSED'
        break;
      case PaymentResultCode.cancelled:
        error = 'PAYMENT-ERROR.CANCELLED'
        break;
      case PaymentResultCode.error:
        error = 'PAYMENT-ERROR.ERROR'
        break;
      default:
        break;
    }
    console.error('Failed to complete transaction', response)
    dropin.setStatus('error', { message: this.translateService.instant(error) });
  }
}
