import { Injectable } from '@angular/core'
import {
  defaultAdColors,
  hslaAdColors,
  themingDefault,
  AdColors,
  Theming,
  getHslaString,
  getHslaStringFromHueAndSla,
  setDocumentStyles,
} from 'shared-assets/vendor/ad-colors/src'

const dac = defaultAdColors()
const hac = hslaAdColors()
const hacDark = hslaAdColors('dark')

@Injectable({
  providedIn: 'root',
})
export class ColorService implements AdColors {
  lightHeader: boolean = false
  largeLogo: boolean = false
  defaultTheming = { ...themingDefault }

  // instantiated in the constructor
  backgroundColorTertiary
  buttonColor
  buttonTextColor
  buttonTextOnColor
  errorColor
  infoColor
  mainLigtness
  mainSaturation
  mainValue
  modeColor
  primaryBackColor
  primaryCardColor
  primaryColor
  primaryColorValues
  primaryLineColor
  primaryTextColor
  primaryTextOnColor
  secondaryBackColor
  secondaryCardColor
  secondaryLineColor
  secondaryTextColor
  secondaryTextOnColor
  shadowColor
  successColor
  warningColor

  constructor() {
    Object.assign(this, dac)
  }

  public setTheme(
    colorPrimary: number = hacDark.primaryColor.h,
    colorButton: number = hacDark.buttonColor.h,
    colorSaturation: number = hacDark.primaryColor.s,
    colorLightnes: number = hacDark.primaryColor.l,
    buttonSaturation: number = hacDark.buttonColor.s,
    buttonLightness: number = hacDark.buttonColor.l,
  ) {
    this.mainValue = colorPrimary.toString()
    this.mainSaturation = colorSaturation.toString()
    this.mainLigtness = colorLightnes.toString()
    this.primaryColor = getHslaString({
      h: colorPrimary,
      s: colorSaturation,
      l: colorLightnes,
      a: hacDark.primaryColor.a,
    })
    this.primaryColorValues = `${colorPrimary},${colorSaturation}%,${colorLightnes}%`
    ;[
      'primaryBackColor',
      'secondaryBackColor',
      'secondaryCardColor',
      'secondaryTextColor',
      'secondaryTextOnColor',
      'secondaryLineColor',
      'shadowColor',
      'buttonColor',
      'buttonTextColor',
    ].forEach((key) => {
      this[key] = getHslaStringFromHueAndSla(colorPrimary, hacDark[key])
    })
    ;['buttonColor', 'buttonTextColor'].forEach((key) => {
      this[key] = getHslaString({
        h: colorButton,
        s: buttonSaturation,
        l: buttonLightness,
        a: hacDark[key].a,
      })
    })
  }

  private resetTheming() {
    Object.assign(this, dac)
  }

  applyTheming(theming: Theming) {
    this.applyThemingVariables(
      true,
      theming.colorPrimary,
      theming.colorButton,
      theming.saturationPrimary,
      theming.lightPrimary,
      theming.saturationButton,
      theming.lightButton,
      theming.isHeaderWhite,
      theming.isLogoLarge,
    )
  }

  public applyThemingVariables(
    instanceId: any,
    mainColor: number = hac.primaryColor.h,
    buttonColor: number = hac.buttonColor.h,
    mainSaturation: number = hac.primaryColor.s,
    mainLigtness: number = hac.primaryColor.l,
    buttonSaturation: number = hac.buttonColor.s,
    buttonLightness: number = hac.buttonColor.l,
    isWhiteHeader: boolean = false,
    isLargeLogo: boolean = false,
  ) {
    if (instanceId) {
      this.setTheme(
        mainColor,
        buttonColor,
        mainSaturation,
        mainLigtness,
        buttonSaturation,
        buttonLightness,
      )
      this.lightHeader = isWhiteHeader
      this.largeLogo = isLargeLogo
    } else {
      this.lightHeader = false
      this.largeLogo = false
      this.resetTheming()
    }

    setDocumentStyles(document, this)
  }

  convertHex(hexValue: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexValue)

    let r = parseInt(result[1], 16)
    let g = parseInt(result[2], 16)
    let b = parseInt(result[3], 16)

    ;(r /= 255), (g /= 255), (b /= 255)
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b)
    let h,
      s,
      l = (max + min) / 2

    if (max == min) {
      h = s = 0 // achromatic
    } else {
      const d = max - min
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0)
          break
        case g:
          h = (b - r) / d + 2
          break
        case b:
          h = (r - g) / d + 4
          break
      }
      h /= 6
    }

    s = s * 100
    s = Math.round(s)
    l = l * 100
    l = Math.round(l)
    h = Math.round(360 * h)
    return [h, s, l]
  }

  // analogue to logoService (server_appointment/src/services/logo.service.ts)
  public hslToHex(h: number, s = 90, l = 40) {
    l /= 100
    const a = (s * Math.min(l, 1 - l)) / 100
    const f = (n: any) => {
      const k = (n + h / 30) % 12
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0') // convert to Hex and prefix "0" if needed
    }
    return `#${f(0)}${f(8)}${f(4)}`
  }
}
