import { Injectable } from '@angular/core';
import { goForm } from './go/go.form';
import { reiseNGoForm } from './reise-n-go/reise-n-go.form';
import { reiseForm } from './reise/reise.form';
import { ZrmModule } from './zrm.module';

@Injectable({
  providedIn: ZrmModule,
})
export class ZrmFormsService {
  static readonly go = goForm;
  static readonly reise = reiseForm;
  static readonly reiseNGo = reiseNGoForm;

  constructor() { }
}
