import {
  ExtractedParams,
  PermaLinkParams,
  SingleOrMultilinkParams,
} from '../single-or-multilink.types'

function buildSingleOrMultilinkUrl(
  p: SingleOrMultilinkParams,
  serverBaseUrl: string,
): string {
  return `${serverBaseUrl}/getWfaFormWithResponse?responseId=${p.responseId}`
}

function buildPermalinkUrl(p: PermaLinkParams, serverBaseUrl: string): string {
  const urlParams = [
    `instanceIdentifier=${p.instanceIdentifier}`,
    `wfaFormId=${p.wfaFormId}`,
    `loadCertificate=true`,
  ].join(`&`)
  return `${serverBaseUrl}/getWfaForm?${urlParams}`
}

const buildUrlDict = {
  permalink: buildPermalinkUrl,
  singleOrMultilink: buildSingleOrMultilinkUrl,
} as const

export function buildUrl(
  params: ExtractedParams,
  serverBaseUrl: string,
): string {
  return buildUrlDict[params.type](params as any, serverBaseUrl)
}
