import { EventEmitter, Input, OnDestroy, Output, Directive } from '@angular/core';
import { Subject } from 'rxjs';


@Directive()
export class UsefulComponent implements OnDestroy {

  /**
   * Loading Indicator
   */
  private _isLoading: boolean
  public get isLoading(): boolean { return this._isLoading }
  @Input() public set isLoading(isLoading: boolean) {
    Promise.resolve().then(() => this._isLoading = isLoading)
    this.isLoadingChange.emit(isLoading)
  }
  @Output() isLoadingChange = new EventEmitter<boolean>(true)


  /**
   * Load-Error Indicator
   */
  private _isLoadingError: boolean
  public get isLoadingError(): boolean { return this._isLoadingError }
  @Input() public set isLoadingError(isLoadingError: boolean) {
    // this._isLoadingError = isLoadingError
    Promise.resolve().then(() => this._isLoadingError = isLoadingError)
    this.isLoadingErrorChange.emit(isLoadingError)
  }
  @Output() isLoadingErrorChange = new EventEmitter<boolean>(true)


  /**
   * Unsubscribing Subject
   */
  public unsubscribe$ = new Subject()


  constructor() { }

  ngOnInit() { }

  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }

}
