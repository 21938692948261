import Joi from 'joi'

import { IsEmbeddedIn } from './../interfaces-autogen-from-joi/is-embedded-in'
import { TargetBuild } from './../interfaces-autogen-from-joi/target-build'

export const isEmbeddedIns = [
  'arztDirekt',
  'iOsTomedo',
  'macOsTomedo',
  'adStepper',
] as const

export const IsEmbeddedInJoi = Joi.string()
  .valid(...isEmbeddedIns)
  .meta({
    className: 'IsEmbeddedIn',
  })

const conversionDict: Record<TargetBuild, IsEmbeddedIn> = {
  indes: 'arztDirekt',
  adis: 'arztDirekt',
  lotos: 'iOsTomedo',
}

export function getIsEmbeddedIn(targetBuild: TargetBuild): IsEmbeddedIn {
  return conversionDict[targetBuild]
}
