import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'kbToMb',
    pure: false,
    standalone: true
})
export class KbToMbPipe implements PipeTransform {
  transform(kb: number): any {
    return (kb / 1024 / 1024).toPrecision(2)
  }
}
