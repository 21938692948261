import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { CameraSnapshotComponent } from './camera-snapshot.component';

@NgModule({
    imports: [
        SharedModule,
        CameraSnapshotComponent,
    ],
    exports: [
        CameraSnapshotComponent,
    ]
})
export class CameraSnapshotModule { }
