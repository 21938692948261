import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const SHARED_MODULES = [
  CommonModule,
  FormsModule,
  FontAwesomeModule,
  // Angular Material
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatDividerModule,
  MatMenuModule,
  MatIconModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatTooltipModule,
]
@NgModule({
  imports: [
    ...SHARED_MODULES,
  ],

  exports: [
    ...SHARED_MODULES,
  ],

  providers: [
  ]
})
export class SharedModule { }
