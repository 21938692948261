
/**
 * contains entities required for otk-configuration
 * does not contain entities required for the otk frontend booking, these are in Booking.entity.ts
 */

import { FormControl, FormGroup } from "@angular/forms";
import {
  FieldSettings, I18NString,
  OTKFieldConfig
} from "../vendor-link/arzt-direkt";
import { Dialect } from "./Instance.entity";
export type {
  FieldSettings, I18NString,
  OTKFieldConfig
} from "../vendor-link/arzt-direkt";

// OtkSlot.model.ts IKalenderData
export interface CalendarData {
  kalenderId: string
  lokalitaetId?: string
  arztName?: string
  lokalitaet?: string
}

export interface ResourceCalendar {
  date: Date
  index: number
}

export interface Timezone {
  name: string  // timezone identifier string
  utc: number   // hour difference to utc
  dst: number   // utc hour difference during daylight saving times
}

//Bs Email settings
export interface BetriebsstaetteEmailSettings {      // send email to doctors office on appointment event
  noticeAddress: string         // address to send email information to
  replyToAddress?: string       // email reply to header for patient requests
}

// OtkEmailSettings.model.ts IOtkEmailSettings
// und!! OtkUser.model.ts IUserEmailSettings
export interface EmailSettings {
  sendNotice: boolean           // send email to doctors office on appointment event
  noticeAddress: string         // address to send email information to
  replyToAddress?: string       // email reply to header for patient requests
  hostErrorMessageUnrecognized: boolean // flag if host should receive emails upon unknown email address
  hostErrorMessageUnavailable: boolean // flag if host should receive emails upon failed booking requests
  hostErrorMessageRejected: boolean // flag if host should receive emails when user email was rejected
  cancelled: EmailDefinitions   // appointment cancellation mails
  confirmed: EmailDefinitions   // appointment booked mails
  moved: EmailDefinitions       // appointment date change mails
  requested: EmailDefinitions   // appointment request mails
  reminder: EmailDefinitions   // appointment reminder mails
  useCalenderMail?: boolean     // special zollsoft mode to send booking mails to associated calender email adresses
}

export interface EmailDefinitions {
  clientContent: I18NString  // client email content
  clientSend: boolean       // send client email
  clientFormat: EmailFormat
  hostContent: string       // host email content
  hostSend: boolean         // send email to host
  hostFormat: EmailFormat
  updateSend: boolean       // inform host about tomedo changes
  embedContent: boolean     // embed client custom html in template
}

export enum EmailFormat {
  TEMPLATE = 'template',
  CUSTOM = 'custom'
}

export interface ContactSettings {
  name: I18NString
  description: I18NString
  issues: string[]
  basic: OTKFieldConfig[]
  custom: OTKFieldConfig[]
}
// OtkDisplaySettings.model.ts IOtkDisplaySettings
export interface DisplaySettings {
  showCategories: boolean     // enable additional appointment category display
  showUnavailable: boolean    // display currently unavailable appointments
  showInsuranceTypes: boolean // display insurance type dialogue
}

// OtkUser.model.ts BookingMode
export enum BookingMode {
  Free = 'free',
  Account = 'account',
  Zuweiser = 'zuweiser'
}

export enum PatientTargetType {
  New = 'new',            // appointment is only for new patients
  Known = 'known',         // appointment is only for know patients
  Both = 'both',           // appointment is for all patients
}

// OtkInsuranceMode.model.ts IInsuranceSettings
export interface InsuranceSettings {
  show: boolean                   // patient may choose this insurance type
  quarterLimit: number            // maximum number of appointments a single patient can book in 1 quarter
  unavailableMessage: I18NString   // Specified warning text when no appointment slots are available
}

export interface InsuranceSettingsForm {
  show: FormControl<boolean>
  quarterLimit: FormControl<number>
  unavailableMessage: FormControl<I18NString>
}

export const createInsuranceSetttingsForm = (unavailableMessage: I18NString): FormGroup => new FormGroup<InsuranceSettingsForm>({
  show: new FormControl<boolean>(true),
  quarterLimit: new FormControl<number>(0),
  unavailableMessage: new FormControl<I18NString>(unavailableMessage),
})

// OtkInsuranceModel.model.ts IInsuranceMode
export interface InsuranceMode {
  gkv: InsuranceSettings
  pkv: InsuranceSettings
  hzv: InsuranceSettings
  sz: InsuranceSettings
  bg: InsuranceSettings
}

export interface InsuranceModeForm {
  gkv: FormGroup<InsuranceSettingsForm>
  pkv: FormGroup<InsuranceSettingsForm>
  hzv: FormGroup<InsuranceSettingsForm>
  sz: FormGroup<InsuranceSettingsForm>
  bg: FormGroup<InsuranceSettingsForm>
}

export const createInsuranceModeForm = (unavailableMessage: I18NString): FormGroup => new FormGroup<InsuranceModeForm>({
  gkv: createInsuranceSetttingsForm(unavailableMessage),
  pkv: createInsuranceSetttingsForm(unavailableMessage),
  hzv: createInsuranceSetttingsForm(unavailableMessage),
  sz: createInsuranceSetttingsForm(unavailableMessage),
  bg: createInsuranceSetttingsForm(unavailableMessage)
})

export enum DayOfWeekToNumber {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6
}

export enum DayOfWeek {
  SUNDAY = 'Sunday',
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday'
}

// OtkUser.model.ts IOtkUserRegistration
export interface IOtkUserRegistration {
  instance: string
  emailSettings: EmailSettings
  fieldSettings: FieldSettings
  displaySettings: DisplaySettings
  bookingMode: BookingMode
  password: string
  active: boolean
  insurance: InsuranceMode
  insuranceFilterActive: boolean
  timezone: string
  syncpolicy: number
  rerouteUrl: string
  redirectionAfterBookingActive?: boolean
  redirectionAfterBookingUrls?: I18NString
  firstDayOfWeek: DayOfWeek
  globalInformation?: I18NString
  showGlobalInformation?: boolean
}
// OtkUser.model.ts IUserIpEntry
export interface IUserIpEntry {
  ip: string
  updatedAt: Date
}

export enum IOtkBookingStrategy {
  CONFIRM = 'confirm',            // the booking needs to be manually confirmed, until which the booking is pending. The final state is confirmed/rejected/unavailable
  AUTOCONFIRM = 'autoconfirm',    // the booking is automatically confirmed/unvailable
  ALWAYSBOOK = 'alwaysbook',      // the booking is always confirmed regardless of availability (not recommended)
}

// OtkUser.model.ts IGlobalSettings
export interface IGlobalSettings {
  allowDoctorChoiceDefault: boolean                // allow choosing individual doctor before appointment time choice
  allowOnlyKnownEmailDefault: boolean              // allow only bookings where email address is known by tomedo
  cancellationWindowDefault: number                // timewindow in hours in which the appointment can be cancelled
  showDoctorsDefault: boolean                      // display doctor names
  strategyDefault: IOtkBookingStrategy             // booking strategy (e.g. 'autoconfirm')
  reservationTimeDefault: number                   // reservation time in minutes
  allowClientCancellationDefault: boolean          // allow client to cancel a booked appointment
  allowClientMoveDefault: boolean                  // allow client to move a booked appointment
  showDurationDefault: boolean                     // display duration for each appointment slot
  showIfNoOpeningsDefault: boolean                 // show in booking-type even if no openings?
  selectableIfNoOpeningsDefault: boolean           // allow selecting in booking-type if showIfNoOpenings && no openings?
  textNoOpeningsDefault: I18NString                // shown if (selected in booking-type || reached via parametrization) && no openings
  dialectDefault: Dialect                          // default dialect to be used for the arzt/behandler/... in booking-date
  patientTargetDefault: PatientTargetType          // otk default appointment target to: new,known,both
  ageRestriction: boolean                          // kinderarzt mode - enable to filter appointment types by patient age
  incognitoMode: boolean                           // remove all personal information in email correspondence
  storeId?: string                                 // default ad.Pay store for ecommerce
}

// OtkUser.model.ts IInsuranceSettings
export interface IInsuranceSettings {
  show: boolean
  quarterLimit: number
  unavailableMessage: string
}

export enum ILimitIntervalType {
  DAY = 'day',          // limit interval in days
  QUARTER = 'quarter'   // limit interval in quarters
}
export interface IOTKBookingLimit {
  limitCount?: number                     // maximum number of appointments a user is allowed to book
  limitLength?: number                    // limit time length in quarters
  limitInterval?: number                  // minimum time between all appointments
  limitIntervalType?: ILimitIntervalType  // interval time in days or quarters
}

export interface OTKBookingLimitForm {
  limitCount: FormControl<number | null>,
  limitLength: FormControl<number | null>,
  limitInterval: FormControl<number | null>,
  limitIntervalType: FormControl<ILimitIntervalType | null>
}

const createOTKBookingLimitForm = (): FormGroup => new FormGroup<OTKBookingLimitForm>({
  limitCount: new FormControl<number | null>(0),
  limitLength: new FormControl<number | null>(0),
  limitInterval: new FormControl<number | null>(0),
  limitIntervalType: new FormControl<ILimitIntervalType | null>(ILimitIntervalType.DAY)
})

export interface IOTKBookingLimitSettings {
  general: IOTKBookingLimit
  gkv: IOTKBookingLimit
  pkv: IOTKBookingLimit
  hzv: IOTKBookingLimit
  sz: IOTKBookingLimit
  bg: IOTKBookingLimit
}

export interface OTKBookingLimitSettingsForm {
  general: FormGroup<OTKBookingLimitForm>,
  gkv: FormGroup<OTKBookingLimitForm>,
  pkv: FormGroup<OTKBookingLimitForm>,
  hzv: FormGroup<OTKBookingLimitForm>,
  sz: FormGroup<OTKBookingLimitForm>,
  bg: FormGroup<OTKBookingLimitForm>
}

export const createOTKBookingLimitSettingsForm = (): FormGroup => new FormGroup<OTKBookingLimitSettingsForm>({
  general: createOTKBookingLimitForm(),
  gkv: createOTKBookingLimitForm(),
  pkv: createOTKBookingLimitForm(),
  hzv: createOTKBookingLimitForm(),
  sz: createOTKBookingLimitForm(),
  bg: createOTKBookingLimitForm()
})

export interface ILink {
  _id: string,
  name: string,
  url: string,
  createdAt: Date,
  updatedAt: Date,
  changedAt?: Date[] // (deprecated)
}

// OtkUser.model.ts IOtkUser
export interface OTKUser extends IOtkUserRegistration {
  _id: string
  lastsync: Date
  globalSettings: IGlobalSettings                     // global appointment type defaults (can be overridden by individual appointment types)
  textNoOpenings: I18NString                          // message shown in booking-type when no openings are available
  bookingLimitSettings: IOTKBookingLimitSettings
  betriebsstaettenFilter?: boolean
  knownPatientFilter?: boolean
  contactForm?: boolean
  contactSettings?: ContactSettings
  zollsoftMode?: boolean
  security?: {
    tomedoCertificate?: string
  },
  links?: ILink[]
  verifyPassword(password: string): Promise<boolean>
  setPassword(password: string): Promise<OTKUser>
}

export const weekDaysShort = ["WEEKDAYS-SHORT.SUNDAY", "WEEKDAYS-SHORT.MONDAY", "WEEKDAYS-SHORT.TUESDAY", "WEEKDAYS-SHORT.WEDNESDAY", "WEEKDAYS-SHORT.THURSDAY", "WEEKDAYS-SHORT.FRIDAY", "WEEKDAYS-SHORT.SATURDAY"]
