import { Pipe, PipeTransform } from '@angular/core';
/**
 * get translation string for day of time in timezone
 * its implemented in this way because we dont want to use dayjs timzone import in the pipe
 * and there is only getDay() of Date in local time.
 * We could also use locale in date pipe but we would need to register big locale files for fr and it
 */

@Pipe({
    name: 'weekday',
    standalone: true,
})
export class WeekdayPipe implements PipeTransform {
  transform(dateIsoString: string, timezone?: string, locale?: string): string {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', ...(timezone ? { timeZone: timezone } : {}) }
    const dayOfWeek = (new Date(dateIsoString)).toLocaleDateString('en', options).toUpperCase()
    return `WEEKDAYS-SHORT.${dayOfWeek}`
  }
}
