import Joi from 'joi'

import { CoreLocale } from './../interfaces-autogen-from-joi/core-locale'

export const coreLocales = ['en', 'de', 'fr', 'it', 'es'] as const

export function getCoreLocales(): string[] {
  return structuredClone(coreLocales) as unknown as string[]
}

export const CoreLocaleJoi = Joi.string()
  .valid(...coreLocales)
  .meta({
    className: 'CoreLocale',
  })

export function isCoreLocale(x: string): x is CoreLocale {
  return coreLocales.includes(x as CoreLocale)
}
