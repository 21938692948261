/**
 * This file was automatically generated by generate-shared-types.ts
 * Do not edit this file, instead modify:
 * ../../untyped-joi/object-id/object-id.untyped-joi.ts
 */
import {
  AlternativesSchema
} from "joi";
import {
  ObjectId
} from "../../interfaces/object-id";
import {
  ObjectIdUntypedJoi
} from "../../untyped-joi/object-id";

export const ObjectIdJoi = ObjectIdUntypedJoi as AlternativesSchema<ObjectId>;
