import { questionTypeTitles as customQuestionTypeTitles } from '../../../../shared-surveyjs/question-type-customization/custom-question-types/custom-question-types'

export const toolboxStrings = {
  ...customQuestionTypeTitles,
  checkbox: {
    de: 'Checkboxen',
    en: 'Checkboxes',
    es: 'Checkboxes',
    fr: 'Checkboxes',
    it: 'Checkboxes',
  },
  comment: {
    de: 'Langer Text',
    en: 'Long text',
    es: 'Texto largo',
    fr: 'Texte long',
    it: 'Testo lungo',
  },
  radiogroup: {
    de: 'Radiobuttons',
    en: 'Radiobuttons',
    es: 'Radiobuttons',
    fr: 'Radiobuttons',
    it: 'Radiobuttons',
  },
  tagbox: {
    de: 'Multi-Select Dropdown',
    en: 'Multi-Select Dropdown',
    es: 'Multi-Select Dropdown',
    fr: 'Multi-Select Dropdown',
    it: 'Multi-Select Dropdown',
  },
}
