import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Pipe({
  name: 'translateWithPrefix',
  standalone: true,
})
export class TranslateWithPrefix implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string, prefix: string): string {
    const candidate = this.translateService.instant(`${prefix}.${value}`)
    if (candidate.includes(prefix)) return value
    return candidate
  }
}
