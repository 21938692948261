import { BitString, BmpString } from "asn1js"
import {
  AltName,
  BasicConstraints,
  Certificate,
  Extension,
  GeneralName,
} from "pkijs"
import { Observable } from "rxjs"
import { defaults } from '../defaults'

/**
 * add standard extensions to the certificate
 * @param certificate X.509 user certificate
 */
export function addCertificateExtensions(
  certificate: Certificate,
  subjectSat: string = defaults.certificate.subject.sat,
): Observable<Certificate> {
  return new Observable((observer) => {
    certificate.extensions = []
    const basicConstraints = createBasicConstraintsExtension(true, 3)
    const keyUsage = createKeyUsageExtension()
    const subjectAlternativeName =
      createSubjectAlternativeNameExtension(subjectSat)
    if (basicConstraints && keyUsage && subjectAlternativeName) {
      certificate.extensions.push(basicConstraints)
      certificate.extensions.push(keyUsage)
      certificate.extensions.push(subjectAlternativeName)
      observer.next(certificate)
      observer.complete()
    } else {
      observer.error("failed to create certificate extensions")
    }
  })
}

/**
 * add basic constraints to the certificate
 * @param ca ability of the key to verify certificate signatures
 * @param pathLength maximum depth of certification paths
 */
export function createBasicConstraintsExtension(
  ca: boolean,
  pathLength: number,
): Extension {
  const constraints = new BasicConstraints({
    cA: ca,
    pathLenConstraint: pathLength,
  })
  return new Extension({
    extnID: "2.5.29.19",
    critical: true,
    extnValue: constraints.toSchema().toBER(false),
    parsedValue: constraints,
  })
}

/**
 * defines the usage restriction for the embedded key
 */
export function createKeyUsageExtension(): Extension {
  const bitArray = new ArrayBuffer(1)
  const bitView = new Uint8Array(bitArray)

  // tslint:disable-next-line:no-bitwise
  bitView[0] = bitView[0] | 0x02 // crl sign flag
  // tslint:disable-next-line:no-bitwise
  bitView[0] = bitView[0] | 0x04 // key cert sign flag

  const keyUsage = new BitString({ valueHex: bitArray })
  return new Extension({
    extnID: "2.5.29.15",
    critical: false,
    extnValue: keyUsage.toBER(false),
    parsedValue: keyUsage,
  })
}

/**
 * certificate extension for alternative host names
 */
export function createSubjectAlternativeNameExtension(
  subjectSat: string,
): Extension {
  const altName = new AltName()
  const generalName = new GeneralName()
  generalName.type = 1
  generalName.value = subjectSat
  altName.altNames.push(generalName)

  const satValue = new BmpString({ value: subjectSat })
  return new Extension({
    extnID: "2.5.29.17",
    critical: false,
    extnValue: altName.toSchema().toBER(false),
    parsedValue: satValue,
  })
}
