import Joi from 'joi'

export const HslaColorJoi = Joi.object()
  .keys({
    h: Joi.number().required(),
    s: Joi.number().required(),
    l: Joi.number().required(),
    a: Joi.number().required(),
  })
  .meta({
    className: 'HslaColor',
  })
