import { InstanceBillingCountryKey } from './Instance.entity';

export enum CountryCodes {
  name = 'name',
  alpha2code = 'alpha2Code',
  alpha3code = 'alpha3Code'
}

export interface Announcement {
  announcementTextDe: string
  announcementTextEn: string
  announcementUrl?: string
  showAnnouncement: boolean
}
export interface GlobalSetting {
  announcement: Announcement
}

export interface GlobalVssBilling {
  monthlyPrice: Number
  perSessionPrice: Number
  updatedAt?: Date
}

export interface GlobalOtkBilling {
  monthlyPrice: Number
  perBookingPrice: Number
  updatedAt?: Date
}

export interface GlobalBillingCountry {
  vss: GlobalVssBilling
  otk: GlobalOtkBilling
}

export interface GlobalBilling {
  [InstanceBillingCountryKey.germany]: GlobalBillingCountry
  [InstanceBillingCountryKey.austria]: GlobalBillingCountry
  [InstanceBillingCountryKey.switzerland]: GlobalBillingCountry
}