import { Observable, of } from 'rxjs'

export type EncryptString = (
  certificate: string,
  data: string,
) => Observable<string>

export const defaultEncryptString: EncryptString = (
  _: string,
  data: string,
) => {
  return of(data)
}
