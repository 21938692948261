/**
 * Returns the last element of an array, or undefined if the array is empty.
 *
 * @template T - The type of elements in the array
 * @param {T[]} array - The array to get the last element from
 * @returns {T | undefined} The last element of the array, or undefined if the array is empty
 * @example
 * last([1, 2, 3]); // 3
 * last([]); // undefined
 * last(['a', 'b']); // 'b'
 */
export function last<T>(array: T[]): T | undefined {
  return array[array.length - 1]
}
