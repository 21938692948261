import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { AuthService } from '@a-d/auth/auth.service';
import { ActiveInstance } from '@a-d/entities/ActiveInstance.entity';
import { AccountHolder, AdPayConfig, AdPayInstanceRole, AdyenLegalEntity, AssociationType, CountryName, EntityAssociation, LegalEntityType } from '@a-d/entities/AdPay.entity';
import { InstanceService } from '@a-d/instance/instance.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import dayjs from 'dayjs';
import { Observable, Subject, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdPayGeneralService {
  //public adPayConfig: AdPayConfig
  private _adPayConfig: AdPayConfig
  public get adPayConfig(): AdPayConfig { return this._adPayConfig }
  public set adPayConfig(config: AdPayConfig) {
    this._adPayConfig = config
    //this.adPayConfigSubject$.next(this._adPayConfig)
  }
  public adPayConfigSubject$ = new Subject<AdPayConfig>()
  public adPayConfigArray: AdPayConfig[] = []
  public instanceRole: AdPayInstanceRole = AdPayInstanceRole.main
  public legalEntityMain: any
  public legalEntitySub: any[] = []
  private instance: ActiveInstance

  public readonly ADYEN_CLIENT_KEY = environment.adyenClient

  constructor(
    private adLoggerService: AdLoggerService,
    private http: HttpClient,
    private instanceService: InstanceService,
    private router: Router,
    private authService: AuthService
  ) {
    this.instance = this.instanceService.activeInstance
    dayjs.locale('de')
  }

  public loadAdPayConfig(instance: string, makeNew = false): Observable<any> {
    return this.http.post('/api/adyen/loadAdPayConfig', {
      instance: instance,
      makeNew: makeNew
    }).pipe(
      map((response: { message: string, status: number, config: AdPayConfig[] }) => {
        console.log('my response', response)
        this.adPayConfigArray = response.config
        if (!response.config.at(-1)?.legalEntityMain) { //return latest adPay if first registration step is not yet finished
          this.adPayConfig = response.config.at(-1)
          this.instanceRole = this.adPayConfig.instance === this.instance._id ? AdPayInstanceRole.main : AdPayInstanceRole.associated
          return response.config.at(-1)
        } else {
          this.adPayConfig = response.config[0];
          this.instanceRole = this.adPayConfig.instance === this.instance._id ? AdPayInstanceRole.main : AdPayInstanceRole.associated
          return response.config[0]
        }
      })
    )
  }

  public getAssociatedIdentifiers(instance: string): Observable<any> {
    return this.http.post('/api/adyen/getAssociatedIdentifiers', {
      instanceId: instance,
    })
  }

  public loadLegalEntities(instance: string, mainEntityId: AdyenLegalEntity, subEntityIds: AdyenLegalEntity[]): Observable<any> {
    console.log('begin loading legal entities')
    return this.http.post('/api/adyen/loadLegalEntities', {
      instance: instance,
      mainEntityId: mainEntityId._id,
      subEntityIds: subEntityIds
    }).pipe(
      map((response: any) => {
        console.log('my legal entities', response.entities)
        this.legalEntityMain = response?.entities?.legalEntityMain
        if (response?.entities?.legalEntitiesSub) {
          this.legalEntitySub = response?.entities?.legalEntitySub;
        }
        return response.entities;
      })
    )
  }

  /**
   * load correct legal entity for legal entity type
   * sole proprietor need the associated individuals id
   * organizations use the organizations id
   * @returns legal entity used for onboarding
   */
  public getOnboardingLegalEntity(): AdyenLegalEntity {
    if (!this.adPayConfig) { return; }
    const mainEntity = this.adPayConfig.legalEntityMain
    if (mainEntity?.type === LegalEntityType.organization) {
      return mainEntity;
    }
    console.log('have to check the subs')
    const subEntities = this.adPayConfig.legalEntitySub;
    if (!subEntities) { return; }
    for (let i = 0; i < subEntities.length; i++) {
      const entity = subEntities[i];
      if (entity.entityAssociations && this.hasMatchingAssociation(entity.entityAssociations, AssociationType.soleProprietorship)) {
        console.log('found matching entity', entity)
        return entity;
      }
      if (i === subEntities.length - 1) { return }
    }
  }

  public syncLegalEntities(): Observable<any> {
    console.log('begin syncing legal entities')
    const instanceId: string = this.instance._id
    const mainEntity: AdyenLegalEntity = this._adPayConfig.legalEntityMain
    const subEntities: AdyenLegalEntity[] = this._adPayConfig.legalEntitySub
    const subEntityAdyenIds = []
    subEntities.forEach((entity) => subEntityAdyenIds.push(entity.id))
    return this.http.post('/api/adyen/syncLegalEntities', {
      instanceId: instanceId,
      mainEntityAdyenId: mainEntity.id,
      subEntityAdyenIds: subEntityAdyenIds
    }).pipe(
      map((response: any) => {
        console.log('my legal entities', response.entities)
        this.legalEntityMain = response?.entities?.legalEntityMain
        this._adPayConfig.legalEntityMain = response?.entities?.legalEntityMain
        if (response?.entities?.legalEntitySub) {
          this.legalEntitySub = response?.entities?.legalEntitySub;
          this._adPayConfig.legalEntitySub = response?.entities?.legalEntitySub
        }
        //this.adPayConfigSubject$.next(this._adPayConfig)
        return response.entities;
      })
    )
  }

  public loadAccountHolder(): AccountHolder[] | null {
    if (!this.adPayConfig) { return; }
    return this.adPayConfig.accountHolder;
  }

  public loadAccountHolderFromAdyen() {
    if (!this._adPayConfig) { return of(null); }
    const accountHolder = this._adPayConfig.accountHolder
    const accountHolderId = accountHolder[0].id
    if (!accountHolderId) { return of(accountHolder); }
    return this.http.get('/api/adyen/accountHolder/' + accountHolderId).pipe(
      map((response: any) => {
        console.log('my account holder', response.accountHolder)
        this._adPayConfig.accountHolder[0] = response.accountHolder
        return response.accountHolder;
      })
    )
  }

  private hasMatchingAssociation(entityAssociations: EntityAssociation[], associationType: AssociationType) {
    if (!entityAssociations) { return; }
    for (let i = 0; i < entityAssociations.length; i++) {
      const association = entityAssociations[i];
      if (association.type === associationType) {
        return true
      }
      if (i === entityAssociations.length - 1) {
        return false;
      }
    }
  }

  public loadOnboardingLink(legalEntityId: string) {
    const data = {
      instanceId: this.instance._id,
      legalEntityId: legalEntityId,
      redirectLink: `${environment.url}${this.router.url}`
    }
    return this.http.post('/api/adyen/onboarding', data)
  }

  /**
   * add or remove an existing control from a form group
   * @param mainGroup basic legal entity form group
   * @param fieldName form group abstract control to change
   * @param targetGroup new abstract control
   * @returns status message
   */
  public addOrRemoveGroup(mainGroup: FormGroup, fieldName: string, targetGroup: FormGroup): string {
    if (!mainGroup) { return 'missing'; }
    const subGroup = mainGroup.get(fieldName);
    if (subGroup) {
      mainGroup.removeControl(fieldName);
      return 'removed';
    }
    mainGroup.addControl(fieldName, targetGroup);
    return 'added';
  }


  public completeOnboarding() {
    const data = {
      adPayId: this._adPayConfig._id,
      instanceId: this.instance._id
    }
    return this.http.post('/api/adyen/completeOnboarding', data)
  }

  public createContract() {
    /*const mainLegalEntity = this._adPayConfig.legalEntityMain
    const mainLegalEntityName = mainLegalEntity.type === LegalEntityType.organization ? mainLegalEntity.organization.legalName : mainLegalEntity.soleProprietorship.name
    const subLegalEntities = this._adPayConfig.legalEntitySub
    let name
    if (mainLegalEntity.type === LegalEntityType.organization) {
      let subLegalEntityId
      mainLegalEntity.entityAssociations.forEach((association) => {
        if (association.type === AssociationType.signatory) subLegalEntityId = association.legalEntityId
      })
      subLegalEntities.forEach((entity) => {
        if (entity.id === subLegalEntityId) {
          name = entity.individual.name
        }
      })
    } else {
      name = subLegalEntities[0].individual.name
    }
    const subLegalEntityName = name.infix ? name.firstName + ' ' + name.infix + ' ' + name.lastName : name.firstName + ' ' + name.lastName
    const addressObj = mainLegalEntity.type === LegalEntityType.organization ? mainLegalEntity.organization.registeredAddress : mainLegalEntity.soleProprietorship.registeredAddress
    const addressLine1 = addressObj.street
    const addressLine2 = addressObj.postalCode + ' ' + addressObj.city*/
    const isSwitzerland = this.instance.contact.country === CountryName.CH
    const customerId = this.authService.user.customerId
    const date = dayjs().format('D. MMMM YYYY')
    if (!customerId || !date) {
      this.adLoggerService.error("Contract data is missing!")
      return of(null)
    }
    const data = {
      /*mainLegalEntityName,
      subLegalEntityName,
      addressLine1,
      addressLine2,*/
      customerId,
      date,
      isSwitzerland
    }
    return this.http.post('/api/adyen/createContract', data)
  }

  public saveContractAccepted() {
    const data = {
      adPayId: this._adPayConfig._id,
      instanceId: this.instance._id,
      date: dayjs().toDate()
    }
    return this.http.post('/api/adyen/saveContractAccepted', data)
  }


  public associateInstanceWithAdPay(adPayId: string, customerId: number, instanceIdentifier: string) {
    const data = {
      adPayId,
      mainInstanceId: this.instance._id,
      customerId,
      instanceIdentifier
    }
    return this.http.post('/api/adyen/associateInstance', data)
  }
}
