import { SurveyModel, TextMarkdownEvent } from 'survey-core'

export function applyHtml(_: SurveyModel, options: TextMarkdownEvent): void {
  let str = options.text
  if (str.indexOf('<p>') === 0) {
    // Remove root paragraphs <p></p>
    str = str.substring(3)
    str = str.substring(0, str.length - 4)
  }
  // Set HTML markup to render
  options.html = str
}
