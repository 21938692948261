import { CommonModule } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { Router } from '@angular/router'
import { setDocumentStyles } from '@arzt-direkt/colors'
import { LoadingViewMode } from '@arzt-direkt/wfa-definitions'
import { loadingViewModeDict } from '@arzt-direkt/wfa-definitions'
import { nullish } from '@arzt-direkt/wfa-generic-utils'
import { UntilDestroy } from '@ngneat/until-destroy'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { BehaviorSubject } from 'rxjs'

import { WfaEnvService } from '../../../environments/wfa-env.service'
import { EditorComponent } from '../../editor/editor.component'
import {
  AdisEditorEnv,
  adisEditorEnv,
  EditorEnv,
} from '../../editor/editor.env'
import { STATIC_EDITOR_ENV } from './../../editor/editor.env'
import { AreYouSureComponent } from './../../utility-components/are-you-sure.component'
import { MatInputPopupComponent } from './../../utility-components/mat-input-popup.component'

@UntilDestroy()
@Component({
  selector: 'wfa-adis-editor',
  standalone: true,
  imports: [
    AreYouSureComponent,
    CommonModule,
    EditorComponent,
    MatInputPopupComponent,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './adis-editor.component.html',
  styleUrls: ['./adis-editor.component.scss'],
  providers: [{ provide: STATIC_EDITOR_ENV, useValue: adisEditorEnv }],
})
export class AdisEditorComponent implements OnInit {
  readonly loadingViewModeDict = loadingViewModeDict
  editorEnv$: BehaviorSubject<EditorEnv>

  requestWfaFormDraft$ = new BehaviorSubject<boolean>(true)
  viewMode: LoadingViewMode = loadingViewModeDict['loading']

  constructor(
    @Inject(STATIC_EDITOR_ENV) readonly staticEditorEnv: AdisEditorEnv,
    private router: Router,
    private translate: TranslateService,
    private wfaEnvService: WfaEnvService,
  ) {
    this.editorEnv$ = this.wfaEnvService.editorEnv$

    const navigation = this.router.getCurrentNavigation()
    if (navigation?.extras.state) {
      const wfaFormDraft =
        navigation.extras.state['adisEditorInput']?.wfaFormDraft

      if (nullish(wfaFormDraft)) {
        this.viewMode = 'error'
      }

      this.viewMode = 'loaded'

      this.editorEnv$.next({
        ...this.editorEnv$.value,
        json: {
          ...wfaFormDraft,
          _id: wfaFormDraft.wfaFormVersionId,
          status: 'live',
        },
      })
    }
  }

  saveWfaFormDraft(_event: any) {
    console.log('mock save wfa form draft')
  }

  ngOnInit(): void {
    setDocumentStyles(document)
    this.editorEnv$.next({
      ...this.editorEnv$.value,
    })
  }
}
