import { AdLoggerService } from '@a-d/logging/ad-logger.service'
/**
*
* Example Usage:
*
* # Counter
* const now = dayjs()
* <app-counter [autostart]="true" [mode]="forwards" [startTime]="now"></app-counter>
*
* # Countdown
* const later = dayjs().add(1, 'minute')
* <app-counter [autostart]="true" [mode]="backwards" [stopTime]="later"></app-counter>
*
*/


import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { interval, Subject, Subscription } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';


@Component({
    selector: 'app-counter',
    template: `{{ timeFormatted }}`,
    standalone: true,
})
export class CounterComponent implements OnInit, OnDestroy {

  // Forwards-Mode (Counter)
  @Input() forwards: boolean
  @Input() startTime: Date
  public elapsedSeconds: number
  public counterHasStarted: boolean
  @Output() counterStarted = new EventEmitter()


  // Backwards-Mode (Countdown)
  @Input() backwards: boolean
  @Input() stopTime: Date
  public remainingSeconds: number
  public countdownHasFinished: boolean
  @Output() countdownFinished = new EventEmitter()

  // Autostart Counter
  @Input() autostart: boolean = false

  // If given counter/countdown pauses at that time
  @Input() pausedTime: Date

  // Change-Emitter
  @Output() changed = new EventEmitter()

  public timeFormatted: string

  @Input() updateInterval: number = 1000 / 2
  public timeInterval$: Subscription
  private unsubscribe$ = new Subject()


  constructor(
    private adLoggerService: AdLoggerService,
  ) {
    dayjs.extend(utc)
  }

  ngOnInit() {
    if (this.autostart) {
      this.start()
    }
  }

  ngOnDestroy() {
    this.stop()
  }


  /**
  * Initializes the Counter / Countdown
  */
  public start() {
    if (this.forwards == this.backwards) {
      this.adLoggerService.error("Couldn't start counter as no mode or both modes are set.")
      return
    }
    if (this.forwards && (!this.startTime || !dayjs(this.startTime).isValid())) {
      this.adLoggerService.error("Couldn't start counter as mode is 'forwards' but no start-time is provided.")
      return
    }
    if (this.backwards && (!this.stopTime || !dayjs(this.stopTime).isValid())) {
      this.adLoggerService.error("Couldn't start counter as mode is 'forwards' but no stop-time is provided.")
      return
    }

    // Start Interval
    this.unsubscribe$ = new Subject()
    this.timeInterval$ = interval(this.updateInterval).pipe(
      startWith(0),
      takeUntil(this.unsubscribe$)
    ).subscribe(_ => {
      this.updateTime()
    })
  }


  /**
  * Stops the Counter / Countdown
  */
  public stop() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }


  /**
  * Updates `timeFormatted` of the Counter / Countdown
  */
  private updateTime() {
    const pausedTimeGiven = this.pausedTime && dayjs.utc(this.pausedTime).isValid()
    const now = pausedTimeGiven ? dayjs.utc(this.pausedTime) : dayjs()

    if (this.forwards) {
      this.updateTimeForwards(now)
    } else if (this.backwards) {
      this.updateTimeBackwards(now)
    }
  }


  /**
  * Updates Time Forwards (Counter)
  */
  private updateTimeForwards(now) {
    // Start-Time from which the counter gets increased
    const startTime = dayjs.utc(this.startTime)
    const counterHasStarted = now.isAfter(startTime)

    // Emit initial Counter-Start
    if (!this.counterHasStarted && counterHasStarted) {
      this.counterStarted.emit()
      this.counterHasStarted = true
    }
    if (!this.counterHasStarted) {
      this.timeFormatted = '0:00'
      this.elapsedSeconds = 0
      return
    }

    const elapsedTime = dayjs.utc(now.valueOf() - startTime.valueOf())
    const format = elapsedTime.hour() ? 'H:mm:ss' : 'm:ss'
    this.timeFormatted = elapsedTime.format(format)

    const oldElapsedSeconds = this.elapsedSeconds
    this.elapsedSeconds = now.diff(startTime, 'second')

    // Emit value-change if changed
    if (oldElapsedSeconds !== this.elapsedSeconds) {
      this.changed.emit(this.elapsedSeconds)
    }
  }


  /**
  * Updates Time Backwards (Countdown)
  */
  private updateTimeBackwards(now) {
    // Stop-Time until which the countdown gets decreased
    const stopTime = dayjs.utc(this.stopTime)
    const countdownHasFinished = now.isAfter(stopTime)

    // Emit initial Coundown-Finish
    if (!this.countdownHasFinished && countdownHasFinished) {
      this.countdownFinished.emit()
      this.countdownHasFinished = true
    }
    if (this.countdownHasFinished) {
      this.timeFormatted = '0:00'
      this.remainingSeconds = 0
      return
    }

    const remainingTime = dayjs.utc(stopTime.valueOf() - now.valueOf())
    const format = remainingTime.hour() ? 'H:mm:ss' : 'm:ss'
    this.timeFormatted = remainingTime.format(format)

    const oldRemainingSeconds = this.remainingSeconds
    this.remainingSeconds = stopTime.diff(now, 'second')

    // Emit value-change if changed
    if (oldRemainingSeconds !== this.remainingSeconds) {
      this.changed.emit(this.remainingSeconds)
    }
  }

}
