import { SurveyjsPropertyInfo } from '@arzt-direkt/wfa-definitions'

export const title: SurveyjsPropertyInfo = {
  name: 'title',
  surveyElements: ['survey'],
  customPropertySettings: [
    {
      settingName: 'isRequired',
      settingValue: true,
    },
  ],
}
