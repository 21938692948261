
export enum LegalDataFiles {
  AGB = 'agb.html',
  // Selbstzahler = 'agb.html#Behandlung als Selbstzahler',
  Datenschutz = 'datenschutz.html',
  Impressum = 'impressum.html',
  Support = 'support.html',
  Kundensupport = 'customer-support.html',
  Changelog = 'changelog.html',
  ChangelogOtk = 'changelog_otk.html',
  Zeitplan = 'zeitplan.html',
  Zahlungshinweise = 'zahlungshinweise.html',
  Gesundheitskarte = 'gesundheitskarte.html',
  GesundheitskarteAblauf = 'gesundheitskarte-ablauf.html',
  Einverstaendniserklaerung = 'einverstaendniserklaerung.html'
}

export interface LegalDialogData {
  readonly documentType: 'instance' | 'global',
  readonly activeInstance: any,
  readonly activeBaseLang: string,
  readonly dataFile: LegalDataFiles,
  readonly showActions: boolean,
  readonly hideToc?: boolean,
  readonly scrollToHeading?: string
}
