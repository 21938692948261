<div
  class="scrollable-sidebar"
  *rxLet="store.sidebarEntries$; let sidebarEntries"
>
  <button
    *ngIf="isMobileView && !isScrolledToStart"
    class="scrollable-sidebar__arrow scrollable-sidebar__arrow--prev"
    (click)="scrollSidebar('left')"
    aria-label="Scroll previous"
  >
    <mat-icon>chevron_left</mat-icon>
  </button>

  <nav
    #sidebarNav
    class="scrollable-sidebar__nav"
    *rxLet="store.activeResponseId$; let activeResponseId"
  >
    <ul #sidebarList>
      @for (item of sidebarEntries; track item.responseId; let index = $index) {
        <li class="scrollable-sidebar__item">
          <a
            mat-menu-item
            [class.is-active]="activeResponseId === item.responseId"
            (click)="store.setActiveResponseId(item.responseId)"
          >
            <span>{{ item.title | i18n }}</span>
            <span class="sidebar-icon-wrapper">
              <mat-icon
                [class.warning-icon]="item.icon === 'warning'"
                [class.done-icon]="item.icon === 'done'"
                >{{ item.icon }}</mat-icon
              >
            </span>
          </a>
        </li>
      }
    </ul>
  </nav>

  <button
    *ngIf="isMobileView && !isScrolledToEnd"
    class="scrollable-sidebar__arrow scrollable-sidebar__arrow--next"
    (click)="scrollSidebar('right')"
    aria-label="Scroll next"
  >
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
