<!-- Actions-Bar -->
<div class="c-dialog__actions-bar">
  <ng-content select="[actions-bar]"></ng-content>
</div>

<!-- Dialog -->
<div class="c-dialog__inner" [class.c-dialog__inner--noPadding]="noPadding" [class.c-dialog__inner--isError]="isError"
  [class.c-dialog__inner--hasTabGroup]="hasTabGroup" [class.c-dialog__inner--hideTitle]="hideTitle"
  [class.c-dialog__inner--hasActionsBar]="hasActionsBar" [class.c-dialog__inner--noDialogActions]="noDialogActions"
  [class.c-dialog__inner--isTransparent]="isTransparent" [class.c-dialog__inner--hasFixedHeight]="!!height"
  [style.height]="height" [style.background]="background">

  <!-- Progress-Bar -->
  <mat-progress-bar [class.isShown]="isLoading" mode="indeterminate" color="primary"></mat-progress-bar>

  <!-- Close-Button Template -->
  <ng-template #CloseButtonRef>
    <button attr.data-selenium-id="button-schliessen" *ngIf="!hideClose" mat-icon-button class="c-dialog__close-button"
      [mat-dialog-close] [matTooltip]="'GLOBAL.CLOSE' | translate">
      <mat-icon>close</mat-icon>
    </button>
  </ng-template>

  <!-- Title, Content & Actions -->
  <ng-content></ng-content>

</div>