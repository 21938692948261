import { Injectable } from "@angular/core";

@Injectable({

  providedIn: 'root'
})
export class UnicodeHelpersService {

  constructor() { }

  /**
   * JSON.parse can fail when the string contains valid Unicode control-characters.
   * Therefore this method can be used to remove them before parsing.
   */
  public removeControlCharacters(value: string): string {
    if (!value) return value
    return value.replace(/[\u0000-\u001F\u007F-\u009F]/g, "")
  }

}


