import { CustomAnamneseFormSettings } from '@a-d/instance-form/anamnese/custom/custom-anamnese-form.component';
import { InstanceFormSettingsInterface } from '@a-d/instance-form/instance-form-component.interface';
import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsefulComponent } from '../../../../../misc/useful.component';
import { InstanceSettingsFormsService } from '../../instance-settings-forms.service';
import { MatButtonModule } from '@angular/material/button';
import { CustomAnamneseFormSettingsItemComponent } from './custom-anamnese-form-settings-item.component';
import { NgFor } from '@angular/common';


@Component({
    selector: 'app-custom-anamnese-form-settings',
    templateUrl: './custom-anamnese-form-settings.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        CustomAnamneseFormSettingsItemComponent,
        MatButtonModule,
    ],
})
export class CustomAnamneseFormSettingsComponent extends UsefulComponent implements InstanceFormSettingsInterface {

  @Input() defaultSettings: CustomAnamneseFormSettings
  @Input() settings: CustomAnamneseFormSettings


  /**
   * Form-Group
   */
  public formGroup = new UntypedFormGroup({
    customFields: new UntypedFormArray([], [Validators.required, Validators.min(1)]),
  })
  public get customFields(): UntypedFormArray { return this.formGroup.get('customFields') as UntypedFormArray }


  constructor(
    private instanceSettingsFormsService: InstanceSettingsFormsService,
  ) {
    super()
  }

  onInit() {
    this.initFields()
  }


  /**
   * Returns a new FormGroup-instance for a field-item
   */
  private createNewFieldFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      title: new UntypedFormControl(null, [Validators.required]),
      type: new UntypedFormControl('textarea', [Validators.required]),
      isRequired: new UntypedFormControl(false, []),
      options: new UntypedFormControl({}, []),
    })
  }


  /**
   * Initializes `customFields` form-groups
   */
  private initFields() {
    this.customFields.clear()
    if (!this.settings || !this.settings.customFields || !this.settings.customFields.length) return

    // Patch with initially fetched instance-values
    for (let field of this.settings.customFields) {
      const fieldFormGroup = this.createNewFieldFormGroup()
      fieldFormGroup.patchValue(field)
      this.customFields.push(fieldFormGroup)
    }
  }


  /**
   * Creates and adds a new field form-group.
   */
  public addField() {
    const newField = this.createNewFieldFormGroup()
    this.customFields.push(newField)
  }


  /**
   * Removes the given field form-group.
   */
  public removeField(index: number) {
    if (index < 0 || index >= this.customFields.controls.length) return
    this.customFields.removeAt(index)
  }


  /**
   * Gathers and returns merged settings-data.
   */
  public getMergedData(completeSettings?: boolean): any {
    return this.instanceSettingsFormsService.getMergedData(this, completeSettings)
  }

}
