import { Maybe, notNullish, nullish } from '@arzt-direkt/wfa-generic-utils'

import { Id } from './../interfaces-autogen-from-joi/id'

export function id2String(id: Maybe<Id>): string | undefined {
  if (nullish(id)) {
    console.info(`[id2String]: nullish input.`)
    return undefined
  }

  if (id === undefined) {
    return undefined
  }

  const { $oid } = id as { $oid: string }
  if (notNullish($oid) && typeof $oid === 'string') return $oid

  if (notNullish(id.toString)) {
    return id.toString()
  }

  if (typeof id === 'string') return id
  console.error(`[id2String]: could not convert ${id} to string.`)
  return ''
}
