export * from './short-title'

import { surveyElementsWithInstance } from './instance'
import { surveyElementsWithPraxisId } from './praxis-id'
import { surveyElementsWithShortTitle } from './short-title'
import { surveyElementsWithWfaFormId } from './wfa-form-id'
import { surveyElementsWithWfaFormVersionId } from './wfa-form-version-id'
import { surveyElementsWithWfaInputType } from './wfa-input-type'
import { surveyElementsWithWfaVersion } from './wfa-version'

export const sharedProperties = [
  surveyElementsWithInstance,
  surveyElementsWithPraxisId,
  surveyElementsWithShortTitle,
  surveyElementsWithWfaFormId,
  surveyElementsWithWfaInputType,
  surveyElementsWithWfaFormVersionId,
  surveyElementsWithWfaVersion,
]
