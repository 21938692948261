import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatCardModule } from '@angular/material/card'
import { MatMenuModule } from '@angular/material/menu'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faFilePdf, faGlobe, faInfo } from '@fortawesome/free-solid-svg-icons'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { RxLet } from '@rx-angular/template/let'
import { notNullish } from '@arzt-direkt/wfa-generic-utils'
import { filter } from 'rxjs'

import {
  wfaVersion,
  coreLocales,
  CoreLocale,
} from '@arzt-direkt/wfa-definitions'
import { ViewerStore } from '../viewer.store'
import { VersionComponent } from './../../utility-components/version.component'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-viewer-header',
  templateUrl: './viewer-header.component.html',
  styleUrls: ['./viewer-header.component.scss', '../../../scss/mat-card.scss'],
  providers: [],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatCardModule,
    MatMenuModule,
    MatTooltipModule,
    RxLet,
    TranslateModule,
    VersionComponent,
  ],
})
export class ViewerHeaderComponent implements OnInit {
  readonly faGlobe = faGlobe
  readonly faFilePdf = faFilePdf
  readonly faInfo = faInfo
  readonly wfaVersion = wfaVersion
  locales: string[] = [...coreLocales]
  localeControl = new FormControl<CoreLocale>('de')

  constructor(
    private readonly translateService: TranslateService,
    public readonly viewerStore: ViewerStore,
  ) {}

  changeLanguage = (lang: string) => {
    this.translateService.use(lang)
    this.viewerStore.setLocale(lang)
  }

  ngOnInit() {
    this.viewerStore.form$
      .pipe(filter(notNullish), untilDestroyed(this))
      .subscribe((form) => {
        const formLangs = Object.keys(form.title)
        const uniqueLocales = [...new Set([...coreLocales, ...formLangs])]
        this.locales = this.sortLocalesByTranslation(uniqueLocales)
      })
  }

  // private
  private sortLocalesByTranslation(locales: string[]): string[] {
    return locales.sort((a, b) => {
      const translationA = this.translateService.instant(`WFA.LANGUAGES.${a}`)
      const translationB = this.translateService.instant(`WFA.LANGUAGES.${b}`)
      return translationA.localeCompare(translationB)
    })
  }
}
