import { Component, Inject } from "@angular/core";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { MatIconModule } from "@angular/material/icon";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
    selector: 'app-booking-snackbar',
    templateUrl: './booking-snackbar.component.html',
    styleUrls: ['./booking-snackbar.component.scss'],
    standalone: true,
    imports: [FontAwesomeModule, MatIconModule]
})
export class BookingSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public message: any,
    private snackBarRef: MatSnackBarRef<BookingSnackbarComponent>
  ) {
  }

  close() {
    this.snackBarRef.dismiss()
  }
}
