import { WfaForm } from '../../../interfaces-autogen-from-joi/wfa-form'
import allgemeineAnamnese from './allgemeine-anamnese.json'
import blank from './blank.json'
import otcAufnahmebogen from './otc-aufnahmebogen-personal-data.json'
import otcMrt from './otc-mrt.json'
import personalData from './personal-data.json'
import standardAnamnese02 from './standard-anamnese-02.json'
import standardAnamneseGynaekologie from './standard-anamnese-gynaekologie.json'
import standardDatenschutzformular02 from './standard-datenschutzformular-02.json'
import standardKontaktdaten02 from './standard-kontaktdaten-02.json'
import werMachtAngaben from './wer-macht-angaben.json'

export const zollsoftForms = {
  allgemeineAnamnese: allgemeineAnamnese as WfaForm,
  blank: blank as WfaForm,
  personalData: personalData as WfaForm,
  standardAnamnese02: standardAnamnese02 as WfaForm,
  standardAnamneseGynaekologie: standardAnamneseGynaekologie as WfaForm,
  standardDatenschutzformular02: standardDatenschutzformular02 as WfaForm,
  standardKontaktdaten02: standardKontaktdaten02 as WfaForm,
  otcAufnahmebogen: otcAufnahmebogen as WfaForm,
  otcMrt: otcMrt as WfaForm,
  werMachtAngaben: werMachtAngaben as WfaForm,
} as const
