<mat-card>
  <mat-card-title data-selenium-id="mat-card-title-@praxis-select/title">{{
    'WFA.PRAXIS-SELECT.TITLE' | translate
  }}</mat-card-title>
  <mat-card-content>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'WFA.PRAXIS-SELECT.SELECT-LABEL' | translate }}</mat-label>
      <input
        type="text"
        matInput
        [formControl]="praxisControl"
        [matAutocomplete]="auto"
        data-selenium-id="input-@praxis-select/praxis-control"
      />
      @if (praxisControl.value) {
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearInputValue(praxisControl)"
        >
          <mat-icon>close</mat-icon>
        </button>
      }

      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="onSelect($event)"
      >
        <mat-option
          *ngFor="let option of filteredPraxisDetails$ | async"
          [value]="option.identifier"
          data-selenium-id="mat-option-@praxis-select/option"
        >
          {{ option.identifier }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div
      *ngIf="selectedKennung"
      data-selenium-id="div-@praxis-select/selected-praxis"
    >
      {{ 'WFA.PRAXIS-SELECT.SELECTED' | translate }}: {{ selectedKennung }}
    </div>
  </mat-card-content>
</mat-card>
