<form class="c-form c-form--fullHeight" [formGroup]="formGroup">

  <div class="c-form__row" *ngFor="let field of customFields.controls; let idx = index">
    <app-custom-anamnese-form-settings-item [formGroup]="field" (remove)="removeField(idx)">
    </app-custom-anamnese-form-settings-item>
  </div>

</form>

<!-- Add-Button -->
<div class="c-button-group">
  <button mat-flat-button (click)="addField()" style="width: 100%;"
    [color]="!!customFields.controls.length ? '' : 'primary'">
    Feld hinzufügen
  </button>
</div>