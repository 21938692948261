import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LegalDataFiles } from "../entities/Legal.entity";
import { LegalService } from "../legal/legal.service";
import { DsgvoPreferencesDialogComponent } from "./dsgvo-preferences-dialog.component";
import { DsgvoService } from "./dsgvo.service";
import { TranslateModule } from "@ngx-translate/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: 'app-dsgvo',
    templateUrl: './dsgvo.component.html',
    styleUrls: ['./dsgvo.component.scss'],
    standalone: true,
    imports: [MatButtonModule, FontAwesomeModule, TranslateModule]
})
export class DsgvoComponent {

  constructor(
    private dsgvoService: DsgvoService,
    private dialog: MatDialog,
    private legalService: LegalService,
  ) { }

  public openDatenschutz() {
    this.legalService.openDialog(LegalDataFiles.Datenschutz, 'global')
  }

  public acceptAllDsgvoPreferences() {
    this.dsgvoService.acceptAllDsgvoPreferences()
  }

  public openDsgvoPreferencesDialog() {
    this.dialog.open(DsgvoPreferencesDialogComponent, {
      width: '35rem',
    })
  }
}
