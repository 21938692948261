import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

export enum ConsoleMessageType {
  Log = "log",
  Warn = "warn",
  Error = "error"
}

@Injectable({
  providedIn: 'root'
})
export class ConsoleService {

  private isDemo = environment.demoMode

  constructor(
    private adLoggerService: AdLoggerService,
  ) { }

  // /**
  //  * prints a message to the console.
  //  * If in demo mode, a more detailed message "messageDemo" is printed
  //  * preceded by the name of the calling function,
  //  * otherwise "messageProd" is printed (if given).
  //  * If no type is given, prints a console.log.
  //  */
  // print(messageDemo: string, messageProd?: string, type?: ConsoleMessageType) {
  //   if (!this.isDemo && !messageProd)
  //     return
  //   const message: string = this.isDemo
  //     ? print.caller.name + ": " + messageDemo
  //     : messageProd
  //   switch (type) {
  //     case ConsoleMessageType.Log:
  //       console.log(message)
  //       break;
  //     case ConsoleMessageType.Warn:
  //       console.warn(message)
  //       break;
  //     case ConsoleMessageType.Error:
  //       this.adLoggerService.error(message)
  //       break;
  //     default:
  //       console.log(message)
  //       break;
  //   }

  /**
   * prints a console.log including the caller name.
   * demo mode only.
   */
  logDemo(message: any, ...optionalParams: any[]) {
    if (this.isDemo)
      // console.log(print.caller.name + ": ", message, ...optionalParams)
      console.log(message, ...optionalParams)
  }
  /**
   * prints a console.warn including the caller name.
   * demo mode only.
   */
  warnDemo(message: any, ...optionalParams: any[]) {
    if (this.isDemo)
      // console.warn(print.caller.name + ": ", message, ...optionalParams)
      console.warn(message, ...optionalParams)
  }

}
