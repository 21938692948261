import { ActivatedRouteSnapshot } from "@angular/router";
import type { Link } from "@a-d/dashboard/otkembeddings/links/link"
import type { AppointmentType } from "@a-d/entities/Booking.entity"


export class RouteUtil {

  /**
   * Get the {@link AppointmentType} ids specified in the route ('oat=')
   * @see {@link Link}
   * 
   * @returns
   * An array of ids (strings) or an empty array if no 'oat' in route.
   */
  static getAppointmentTypeIds(route: ActivatedRouteSnapshot): string[] {
    const commaSeparatedIds: string | undefined = route.queryParams["oat"];
    if (!commaSeparatedIds) return [];
    const appTypeIds = commaSeparatedIds.split(',');
    return appTypeIds;
  }
}