import { ActiveInstance } from '@a-d/entities/ActiveInstance.entity';
import { InstanceForm } from '@a-d/entities/InstanceForm.entity';
import { FormHelpers } from '@a-d/forms/form-helpers.service';
import { AnamneseForm } from '@a-d/instance-form/anamnese/anamnese-forms.service';
import { InstanceFormInterface } from '@a-d/instance-form/instance-form-component.interface';
import { InstanceFormService } from '@a-d/instance-form/instance-form.service';
import { InstanceService } from '@a-d/instance/instance.service';
import { UsefulComponent } from '@a-d/misc/useful.component';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { environment } from '@env/environment';
import { GoSettings } from './../go/go-settings.entity';
import { GoComponent } from './../go/go.component';
import { ReiseSettings } from './../reise/reise-settings.entity';
import { ReiseComponent } from './../reise/reise.component';
import {
  ReiseNGoComponentData,
  ReiseNGoSettings
} from './reise-n-go-settings.entity';
import { I18NStringPipe } from '../../../../i18n/i18n.pipe';
import { GoComponent as GoComponent_1 } from '../go/go.component';
import { ReiseComponent as ReiseComponent_1 } from '../reise/reise.component';
import { MatStepperModule } from '@angular/material/stepper';

@Component({
    selector: 'app-reise-n-go',
    templateUrl: './reise-n-go.component.html',
    styleUrls: ['./reise-n-go.component.scss'],
    standalone: true,
    imports: [
        MatStepperModule,
        ReiseComponent_1,
        GoComponent_1,
        I18NStringPipe,
    ],
})
export class ReiseNGoComponent
  extends UsefulComponent
  implements AfterViewInit, OnDestroy, OnInit, InstanceFormInterface {
  @Input() anamneseForm: AnamneseForm;
  @Input() settings: ReiseNGoSettings;
  @ViewChild('reise') reiseComponent: ReiseComponent | undefined;
  @ViewChild('go') goComponent: GoComponent | undefined;
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  firstCompleted: boolean = false;
  secondCompleted: boolean = false;

  get goSettings() {
    return this.settings as GoSettings;
  }

  get reiseSettings() {
    return this.settings as ReiseSettings;
  }

  constructor(
    private formHelpers: FormHelpers,
    private instanceFormService: InstanceFormService,
    private instanceService: InstanceService
  ) {
    super();
    this.formHelpers.syncActiveAnamneseFormWithLocalStorage(
      this.activeInstanceForm,
      this.formGroup,
      this.unsubscribe$
    );
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe((v) => {
      this.firstCompleted = FormHelpers.valid(this.reiseComponent.formGroup)
        ? true
        : false;
      this.secondCompleted = FormHelpers.valid(this.goComponent.formGroup)
        ? true
        : false;
    });
  }

  ngAfterViewInit(): void {
    [
      this.reiseComponent.formGroup.controls,
      this.goComponent.formGroup.controls,
    ].forEach((controls) => this.addControls(controls));
  }

  ngOnDestroy(): void { }

  // Current Instance & Instance-Form Helpers //////////////////////////////////
  get activeInstance(): ActiveInstance {
    return this.instanceService.activeInstance;
  }
  get activeInstanceForm(): InstanceForm {
    return this.instanceFormService.activeInstanceForm;
  }
  readonly isDemoMode = environment.demoMode;

  // InstanceFormInterface /////////////////////////////////////////////////////
  getData(): ReiseNGoComponentData {
    const reiseData = this.reiseComponent.getData();
    const goData = this.goComponent.getData();
    return Object.assign({}, reiseData, goData);
  }

  magicFill() {
    this.reiseComponent.magicFill();
    this.goComponent.magicFill();
  }

  // private ///////////////////////////////////////////////////////////////////
  addControls(controls: { [key: string]: AbstractControl }) {
    Object.entries(controls).forEach((pair) => {
      const [controlName, control] = pair;
      this.formGroup.addControl(controlName, control);
    });
  }
}
