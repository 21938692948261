import { CustomPropertyInfo } from '@arzt-direkt/wfa-definitions'

export const password: CustomPropertyInfo = {
  name: 'password',
  type: 'string',
  displayName: {
    de: 'Passwort',
    en: 'Password',
    es: 'Contraseña',
    fr: 'Mot de passe',
    it: 'Password',
  },
  category: 'general',
  isSerializable: true,
  dependsOn: ['inputType'],
  visibleIndex: 8,
  visibleIf: (question: { inputType: string }) => {
    return question.inputType === 'password'
  },
}

const surveyElements = ['text']

export const surveyElementsWithPassword = surveyElements.reduce(
  (acc, item) => {
    acc[item] = password
    return acc
  },
  {} as Record<string, CustomPropertyInfo>,
)
