export const example = {
  type: 'checkbox',
  name: 'question41',
  title: {
    de: 'Haben Sie eine der folgenden Nebenerkrankungen?',
  },
  choices: [
    {
      value: 'Item1',
      text: {
        de: 'Diabetes mellitus',
      },
    },
    {
      value: 'Item2',
      text: {
        de: 'Herzschrittmacher/Herzpass',
      },
    },
    {
      value: 'Item3',
      text: {
        de: 'keine',
      },
    },
  ],
  validators: [
    {
      type: 'expression',
      text: 'Eingabe ungültig',
      expression:
        "{question41} = ['Item3'] or {question41} notcontains 'Item3'",
    },
  ],
  shortTitle: 'Nebenerkrankungen?',
}
