import { Injectable, OnDestroy } from '@angular/core';
import { Certificate } from 'pkijs';
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { filter, first, map, mergeMap, takeUntil, timeout } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { CryptoService, UserDataService } from '../crypto';
import { AttestDocumentation, Documentation } from '../entities/Documentation.entity';
import { InstanceEncryptionMode } from '../entities/InstanceSettings.entity';
import { PatientSession } from '../entities/PatientSession.entity';
import { PatientSessionDataUnencrypted } from '../entities/PatientSessionCreation.entity';
import { UnicodeHelpersService } from '../misc/unicode-helpers.service';
import { CryptoWorkerService } from './../crypto/crypto.worker.service';


@Injectable({
  providedIn: 'root'
})
export class PatientSessionDecryptionService implements OnDestroy {

  private unsubscribe$ = new Subject()

  constructor(
    private authService: AuthService,
    private cryptoService: CryptoService,
    private userDataService: UserDataService,
    private cryptoWorkerService: CryptoWorkerService,
    private unicodeHelpers: UnicodeHelpersService,
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }


  /**
   * Decrypts the `encryptedData` of the given session and assigns it onto its belonging attributes.
   */
  public decryptSession(session: PatientSession, encryptionMode: InstanceEncryptionMode, authenticated?: boolean): Observable<PatientSession> {
    // console.log("decryptSession: session = ", session, " encryptionMode = ", encryptionMode)
    if (!this.authService.cryptoStartedInitializing) {
      return throwError({ name: "CryptoNotInitialized", error: "Crypto-Module didn't start initializing" })
    }
    if (!session) {
      return throwError({ name: "NoEncryptedDataError", error: "Couldn't decrypt session as it either doesn't exist or has no 'encryptedData' attached." })
    }
    if (!encryptionMode) {
      return throwError({ name: "NoEncryptionModeGiven", error: "Couldn't decrypt session as no 'encryptionMode' was given." })
    }

    // Ensure Crypto-Module is initialized
    return this.authService.cryptoIsInitialized$.pipe(
      filter(Boolean),
      first(),
      timeout(3000),

      // Start Decryption
      mergeMap(_ => {
        const { privateKeyBuffer, certificate } = this.getKeyAndCertificate(encryptionMode)
        if (!privateKeyBuffer || !certificate) {
          throw { name: "CryptoNotInitialized", error: "Either privateKeyBuffer or certificate are not existing.", privateKeyBuffer, certificate }
        }
        const encryptedData = session.encryptedData
        const encryptedAssets = session.encryptedAssets
        const encryptedSessionBill = session.bill?.encryptedPdf
        if (!encryptedData && !encryptedAssets && !encryptedSessionBill) {
          return of('', '', '')
        }
        return forkJoin([
          ...(encryptedData ? [this.cryptoWorkerService.decryptEnvelopedData(privateKeyBuffer, certificate, encryptedData, true)] : []),
          ...(encryptedAssets ? [this.cryptoWorkerService.decryptEnvelopedData(privateKeyBuffer, certificate, encryptedAssets, true)] : []),
          ...(encryptedSessionBill ? [this.cryptoWorkerService.decryptEnvelopedData(privateKeyBuffer, certificate, encryptedSessionBill, true)] : []),
        ])
      }),

      // tap(([decryptedDataString, decryptedAssetsString, decryptedSessionBillString]: [string, string, string]) => {
      //   console.log({ decryptedDataString })
      //   console.log({ decryptedAssetsString })
      //   console.log({ decryptedSessionBillString })
      // }),

      // Parse Decrypted-Data-String to JSON
      map(([decryptedDataString, decryptedAssetsString, decryptedSessionBillString]: [string, string, string]) => (
        (decryptedDataString || decryptedAssetsString) ? {
          ...(decryptedDataString ? JSON.parse(this.unicodeHelpers.removeControlCharacters(decryptedDataString)) : null),
          ...(decryptedAssetsString ? JSON.parse(this.unicodeHelpers.removeControlCharacters(decryptedAssetsString)) : null),
          ...(decryptedSessionBillString ? { bill: { pdf: decryptedSessionBillString } } : null),
        } : { ...session })),
      // Assign Decrypted-Data to Session
      map((decryptedData: PatientSessionDataUnencrypted) => ({
        ...session,
        person: decryptedData.person,
        insurance: decryptedData.insurance,
        anamnese: decryptedData.anamnese,
        assets: decryptedData.assets,
        documentation: decryptedData.documentation
          ? this.handleAttest(decryptedData.documentation, session?.documentation?.attest)
          : { authenticated: typeof (authenticated) === "undefined" ? false : authenticated },
        ...(session.bill && session.bill.encryptedPdf
          ? {
            bill: {
              ...session.bill,
              ...decryptedData.bill,
            }
          }
          : null),
      })),

      takeUntil(this.unsubscribe$)
    )
  }

  private handleAttest(documentation: Documentation, attestData: AttestDocumentation) {
    if (!attestData) { return documentation }
    documentation.attest = attestData
    return documentation
  }


  public decryptBill(session: PatientSession, encryptionMode: InstanceEncryptionMode): Observable<string[]> {
    if (!this.authService.cryptoStartedInitializing) {
      return throwError({ name: "CryptoNotInitialized", error: "Crypto-Module didn't start initializing" })
    }
    if (!session || !session.encryptedData && !session.encryptedAssets && !session?.bill?.encryptedPdf) {
      return throwError({ name: "NoEncryptedDataError", error: "Couldn't decrypt session as it either doesn't exist or has no 'encryptedData' attached." })
    }
    if (!encryptionMode) {
      return throwError({ name: "NoEncryptionModeGiven", error: "Couldn't decrypt session as no 'encryptionMode' was given." })
    }

    // Ensure Crypto-Module is initialized
    return this.authService.cryptoIsInitialized$.pipe(
      filter(Boolean),
      first(),
      timeout(3000),

      // Start Decryption
      mergeMap(_ => {
        const { privateKeyBuffer, certificate } = this.getKeyAndCertificate(encryptionMode)
        if (!privateKeyBuffer || !certificate) {
          throw { name: "CryptoNotInitialized", error: "Either privateKeyBuffer or certificate are not existing.", privateKeyBuffer, certificate }
        }
        const encryptedSessionBill = session.bill && session.bill.encryptedPdf

        return forkJoin([
          encryptedSessionBill ? this.cryptoService.decrypt(privateKeyBuffer, certificate, encryptedSessionBill, true) : '',
        ])
      }),
      takeUntil(this.unsubscribe$)
    )
  }


  /**
   * Determines the correct key and certificate for the current user and given `encryptionMode`
   */
  private getKeyAndCertificate(encryptionMode: InstanceEncryptionMode): { privateKeyBuffer: ArrayBuffer, certificate: Certificate } {
    let privateKeyBuffer = this.userDataService.privateKeyBuffer
    let certificate = this.userDataService.certificate

    if (this.authService.user?.isArzt && encryptionMode === InstanceEncryptionMode.InstanceWide) {
      privateKeyBuffer = this.userDataService.instancePrivateKeyBuffer
      certificate = this.userDataService.instanceCertificate
    }

    return { privateKeyBuffer, certificate }
  }

}
