import { PraxisId, SurveyjsResponse } from '@arzt-direkt/wfa-definitions'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import { nullish } from '@arzt-direkt/wfa-generic-utils'
import { cloneDeep, set } from '@arzt-direkt/wfa-generic-utils'
import { map, Observable, of } from 'rxjs'

import { AdisWfaEnv } from '../../../environments/wfa-env'

interface MinFormResponseData {
  formResponse: {
    surveyjsResponse?: Maybe<SurveyjsResponse>
    praxisId: PraxisId
  }
}

export function encryptFormResponseData<T extends MinFormResponseData>(
  formResponseData: T,
  certBase64: Maybe<string>,
  wfaEnv: Pick<AdisWfaEnv, 'encryptString'>,
): Observable<T> {
  const unencrypted = formResponseData.formResponse.surveyjsResponse ?? {}
  if (Object.keys(unencrypted).length === 0) return of(formResponseData)

  if (nullish(certBase64)) {
    const errorMsg =
      '[WFA] encryptFormResponseData: could not find certificate to encrypt patient data'
    console.error(errorMsg)
    return of(
      set<T>(cloneDeep(formResponseData), 'formResponse.surveyjsResponse', {
        __wfaErrorMsg__: errorMsg,
      }),
    )
  }

  return wfaEnv.encryptString(certBase64, JSON.stringify(unencrypted)).pipe(
    map((encryptedSurveyjsResponse) =>
      set<T>(cloneDeep(formResponseData), 'formResponse.surveyjsResponse', {
        encryptedSurveyjsResponse,
      }),
    ),
  )
}
