import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

/**
 * A custom Angular pipe for safely transforming URLs into trusted resource URLs.
 *
 * **Warning:** Never pass user-generated data directly to this pipe. Always ensure that
 * the input URL is trusted and comes from a trusted source to prevent security risks.
 *
 * @example
 * To use the `safe` pipe in an Angular template:
 *
 * ```html
 * <iframe [src]="unsafeUserGeneratedUrl | safe"></iframe>
 * ```
 *
 * @param {string} url - The URL string to be transformed into a trusted resource URL.
 * @returns {SafeResourceUrl} A trusted resource URL that can be safely used in Angular templates.
 *
 * @see [Angular Security Guide](https://angular.io/guide/security)
 * @see [Angular DomSanitizer](https://angular.io/api/platform-browser/DomSanitizer)
 * @see [Medium Explanation](https://ctrlintelligence.medium.com/angular-error-unsafe-value-used-in-a-resource-url-context-73838e97b082)
 *
 * @ngModule CommonModule
 */
@Pipe({
  name: 'safe',
  standalone: true,
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
}
