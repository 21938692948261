export function getMessage(x: string | object): string {
  if (typeof x === 'string') return x
  if (typeof (x as Error)?.message === 'string') return (x as Error).message
  return JSON.stringify(x, null, 2)
}

export function plainError(x: string | Error, _calledFrom?: string, data?: unknown) {
  const calledFrom = `[${_calledFrom}]: ` ?? ''
  console.log(`${calledFrom}Error: ${getMessage(x)}`, data)
}

export function plainLog(x: string, _calledFrom?: string, data?: unknown) {
  const calledFrom = `[${_calledFrom}]: ` ?? ''
  console.log(`${calledFrom}: ${x}`, data)
}
