import { CommonModule } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Router } from '@angular/router'
import { ViewerLoadingState } from '@arzt-direkt/wfa-definitions'
import { FormResponseData } from '@arzt-direkt/wfa-definitions'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import { notNullish, nullish } from '@arzt-direkt/wfa-generic-utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject, filter, switchMap, tap, withLatestFrom } from 'rxjs'

import { logWfaEnv } from '../../../environments/log-wfa-env'
import { WfaEnvService } from '../../../environments/wfa-env.service'
import { UserMessageComponent } from '../../user-message/user-message.component'
import submitting from '../../user-message/viewer-from-link-submitting.json'
import successfullySubmitted from '../../user-message/viewer-from-link-successfully-submitted.json'
import { checkFormResponseOrigin } from '../../utility/check-form-response-origin'
import { submitFormResponseData } from '../../utility/submit-form-response-data'
import { ViewerComponent } from '../../viewer/viewer.component'
import { ViewerFromLinkStore } from './viewer-from-link.store'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-viewer-from-link',
  templateUrl: './viewer-from-link.component.html',
  styleUrls: [
    './viewer-from-link.component.scss',
    '../../viewer/viewer-parent.scss',
  ],
  imports: [CommonModule, ViewerComponent, UserMessageComponent],
  providers: [ViewerFromLinkStore],
})
export class ViewerFromLinkComponent implements OnInit {
  envState$ = this.viewerFromLinkStore.env$
  form$ = this.viewerFromLinkStore.form$
  formResponse$ = this.viewerFromLinkStore.formResponse$
  emitFormResponseData$ = new BehaviorSubject<Maybe<FormResponseData>>(null)
  status: ViewerLoadingState = 'survey'
  submittingJson = submitting
  submittedJson = successfullySubmitted
  certBase64: Maybe<string> = null // to encrypt patient response
  hasCompletedHtml = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private router: Router,
    private viewerFromLinkStore: ViewerFromLinkStore,
    private wfaEnv: WfaEnvService,
  ) {}

  ngOnInit(): void {
    this.wfaEnv.logger.info(
      `[WFA] ViewerFromLinkComponent: ngOnInit called with wfaEnv ${logWfaEnv(
        this.wfaEnv,
      )}`,
    )

    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe((data) => {
      const form = data['routeResolver']?.form
      if (nullish(form)) {
        console.error('[ViewerFromLinkComonent/ngOnInit]: could not load form')
        return
      }

      if (notNullish(form)) {
        this.viewerFromLinkStore.setForm(form)
        this.hasCompletedHtml = notNullish(form.completedHtml)
      }

      const formResponse = data['routeResolver'].formResponse
      if (notNullish(formResponse))
        this.viewerFromLinkStore.setFormResponse(formResponse)

      this.certBase64 = data['routeResolver'].certificate
    })

    this.emitFormResponseData$
      .pipe(
        filter(notNullish),
        filter((formResponseData) =>
          checkFormResponseOrigin(formResponseData, ['completeButton']),
        ),
        tap(() => {
          if (!this.hasCompletedHtml) {
            this.status = 'submitting'
          }
        }),
        withLatestFrom(this.form$),
        switchMap(([formResponseData, form]) =>
          submitFormResponseData(
            form,
            formResponseData as FormResponseData,
            this.httpClient,
            this.wfaEnv,
            this.router,
            this.certBase64,
          ),
        ),
        tap(() => {
          if (!this.hasCompletedHtml) {
            this.status = 'submitted'
          }
        }),
      )
      .subscribe()
  }
}
