import { AbstractControl, Validators } from "@angular/forms";
import { dynamicallyRequired } from "./form-validators";

/**
 * @description
 * Checks validity for control that may have the `dynamicallyRequired`
 * validator.
 */
function isDynamicallyValid(control: AbstractControl): boolean {
  if (
    control.hasValidator(dynamicallyRequired) &&
    [undefined, null, ""].includes(control.value)
  )
    return false;

  return control.valid;
}

function makeDynamicallyRequired(
  control: AbstractControl,
  isCheckbox: boolean = false
): void {
  control.addValidators(dynamicallyRequired);
  if (isCheckbox === true) control.addValidators(Validators.requiredTrue);
}

function removeDynamicallyRequired(
  control: AbstractControl,
  isCheckbox: boolean = false
): void {
  control.removeValidators(dynamicallyRequired);
  if (isCheckbox === true) control.removeValidators(Validators.requiredTrue);
}

const WfaFormControlService = {
  isDynamicallyValid,
  makeDynamicallyRequired,
  removeDynamicallyRequired,
};

export {
  WfaFormControlService,
  isDynamicallyValid,
  makeDynamicallyRequired,
  removeDynamicallyRequired,
};
