import { Certificate } from 'pkijs'
import { arrayBufferToString, toBase64 } from 'pvutils'
import { Observable } from 'rxjs'
import { fromBER } from 'asn1js'

/**
 * get buffer representation of the certificate
 * @param certificate X.509 user certificate holding public key
 *
 * legacy function, wrapped to return observable, simpler sync variant below
 */
export function certificateToBuffer(
  certificate: Certificate,
): Observable<ArrayBuffer> {
  return new Observable((observer) => {
    const buffer = certificate.toSchema(true).toBER(false)
    if (buffer) {
      observer.next(buffer)
      observer.complete()
    } else {
      observer.error('certificate could not be converted to buffer')
    }
  })
}

/**
 * legacy function, wrapped to return observable, simpler sync variant below
 */
export function certificateToBase64(
  certificate: Certificate,
): Observable<string> {
  return new Observable((observer) => {
    certificateToBuffer(certificate).subscribe(
      (certBuffer) => {
        observer.next(toBase64(arrayBufferToString(certBuffer)))
        observer.complete()
      },
      (error) => {
        observer.error(error)
        observer.complete()
      },
    )
  })
}

export function certToArrayBuffer(certificate: Certificate): ArrayBuffer {
  return certificate.toSchema(true).toBER(false)
}

export function certToBase64(certificate: Certificate): string {
  return toBase64(arrayBufferToString(certToArrayBuffer(certificate)))
}

export function base64ToCert(cBase64: string): Certificate {
  const cBuffer = Buffer.from(cBase64, 'base64')
  const cDer = cBuffer.toString('binary')
  const asn1 = fromBER(Buffer.from(cDer, 'binary'))
  if (asn1.offset === -1) {
    throw new Error('[base64ToCert]: Incorrect encoded ASN.1 data')
  }
  return new Certificate({ schema: asn1.result })
}

export function arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
  return btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)))
}

/**
 * Use for conversion of keys, certificates, etc (things with ascii characters)
 *
 * Seems to coincide with stringToArrayBuffer from pvUtils
 */
export function stringToArrayBuffer(str: string): ArrayBuffer {
  const buffer = new ArrayBuffer(str.length)
  const bufferView = new Uint8Array(buffer)
  for (let i = 0; i < str.length; i++) {
    bufferView[i] = str.charCodeAt(i)
  }
  return buffer
}

/**
 * Use for message conversion (things with non-ascii characters)
 */
export function messageToArrayBuffer(
  str: string,
): ArrayBuffer {
  console.debug(`[messageToArrayBuffer]: doing umlaut-friendly encoding of '${str}'...`)
  const encoder = new TextEncoder()
  return encoder.encode(str).buffer
}

export function base64ToArrayBuffer(base64: string): ArrayBuffer {
  const binaryString = atob(base64)

  const uint8Array = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i)
  }

  return uint8Array.buffer
}

export async function base64ToPrivateKey(
  privateKeyBase64: string,
): Promise<CryptoKey> {
  const binaryDerString = atob(privateKeyBase64)
  const binaryDer = stringToArrayBuffer(binaryDerString)

  const privateKey = await crypto.subtle.importKey(
    'pkcs8',
    binaryDer,
    { name: 'RSASSA-PKCS1-v1_5', hash: { name: 'SHA-256' } },
    true,
    ['sign'],
  )
  return privateKey
}

export async function base64ToPublicKey(base64: string): Promise<CryptoKey> {
  const publicKeyArrayBuffer = Uint8Array.from(atob(base64), (c) =>
    c.charCodeAt(0),
  ).buffer
  const publicKey = await crypto.subtle.importKey(
    'spki',
    publicKeyArrayBuffer,
    {
      name: 'RSASSA-PKCS1-v1_5',
      hash: { name: 'SHA-256' },
    },
    true,
    ['verify'],
  )
  return publicKey
}

export async function privateKeyToBase64(
  privateKey: CryptoKey,
): Promise<string> {
  const privateKeyArrayBuffer = await crypto.subtle.exportKey(
    'pkcs8',
    privateKey,
  )
  const privateKeyBase64 = arrayBufferToBase64(privateKeyArrayBuffer)
  return privateKeyBase64
}

export async function publicKeyToBase64(publicKey: CryptoKey): Promise<string> {
  const publicKeyArrayBuffer = await crypto.subtle.exportKey('spki', publicKey)
  const publicKeyBase64 = arrayBufferToBase64(publicKeyArrayBuffer)
  return publicKeyBase64
}

export function stringToPem(
  key: string,
  pemType: 'PRIVATE' | 'PUBLIC',
): string {
  return `-----BEGIN ${pemType} KEY-----\n${key}\n-----END ${pemType} KEY-----`
}
