import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { MatAccordion, MatExpansionModule } from "@angular/material/expansion";
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UntilDestroy } from "@ngneat/until-destroy";
import { CookiesService, EssentialCookieName, EssentialCookieNames } from './cookies.service';
import { DsgvoService } from "./dsgvo.service";
import { SanitizeHtmlPipe } from "../misc/sanitize.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgTemplateOutlet } from "@angular/common";
import { DialogComponent } from "../../../../lib/src/lib/dialog/dialog.component";


@UntilDestroy()
@Component({
    selector: 'app-dsgvo-preferences-dialog',
    templateUrl: './dsgvo-preferences-dialog.component.html',
    styleUrls: ['./dsgvo-preferences-dialog.component.scss'],
    standalone: true,
    imports: [DialogComponent, MatDialogModule, NgTemplateOutlet, MatExpansionModule, MatSlideToggleModule, FontAwesomeModule, MatButtonModule, TranslateModule, SanitizeHtmlPipe]
})
export class DsgvoPreferencesDialogComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  public isFormCachingAllowed: boolean // just for the html, the real value is in a cookie
  public areSupportCookiesAllowed: boolean // just for the html, the real value is in a cookie

  public EssentialCookieNames = EssentialCookieNames

  constructor(
    public dialogRef: MatDialogRef<DsgvoPreferencesDialogComponent>,
    private dsgvoService: DsgvoService,
    private cookiesService: CookiesService
  ) { }

  ngOnInit() {
    this.isFormCachingAllowed = this.cookiesService.get(EssentialCookieNames.isFormCachingAllowed) === 'true' ? true : false
    this.areSupportCookiesAllowed = this.cookiesService.get(EssentialCookieNames.areSupportCookiesAllowed) === 'true' ? true : false
  }

  public setEssentialCookie(essentialCookieName: EssentialCookieName, event: MatSlideToggleChange) {
    this.cookiesService.set(essentialCookieName, String(event.checked))
  }

  public acceptAllDsgvoPreferences() {
    this.dsgvoService.acceptAllDsgvoPreferences()
  }

  public closeDsgvoBottomSheet() {
    this.dsgvoService.closeDsgvoBottomSheet()
  }
}
