import {
  AdColors,
  HslaAdColors,
  NonHslaAdColors,
} from '../../interfaces/theming/ad-colors'
import { HslaAttributes } from '../../interfaces/theming/hsla-attributes'
import { HslaColor } from '../../interfaces/theming/hsla-color'
import { getHslaString } from '../../../theming/color-utils'
import { applyToValues } from '../../../utils/apply-to-values'

const lightness = {
  light: 54,
  dark: 40,
}

const primaryLightColor: HslaColor = { h: 206, s: 90, l: lightness.light, a: 1 } // for admin
const primaryDarkColor: HslaColor = { ...primaryLightColor, l: lightness.dark } // for users
const primaryTextOnColor: HslaColor = { h: 0, s: 100, l: 100, a: 1 }

type LType = 'light' | 'dark'
export function primaryColor(lType: LType = 'light'): HslaColor {
  if (lType === 'dark') return primaryDarkColor
  return primaryLightColor
}

export function nonHslaAdColors(lType: LType = 'light'): NonHslaAdColors {
  return {
    modeColor: 'white',
    mainValue: primaryColor(lType).h.toString(),
    mainSaturation: primaryColor(lType).s.toString(),
    mainLigtness: primaryColor(lType).l.toString(),
    primaryColorValues: `${primaryColor(lType).h},${primaryColor(lType).s}%,${primaryColor(lType).l}%`,
  }
}

export function hslaAdColors(lType: LType = 'light'): HslaAdColors {
  const pc = primaryColor(lType)
  const l = (lType === 'dark') ? lightness.dark : lightness.light
  const s = (lType === 'dark') ? 24 : 100
  return {
    primaryColor: pc,
    primaryBackColor: { h: 240, s, l: 98, a: 1 },
    secondaryBackColor: { h: 207, s: 35, l: 94, a: 1 },
    primaryCardColor: { h: 0, s: 0, l: 100, a: 1 },
    secondaryCardColor: { h: 240, s: 100, l: 98, a: 1 },
    primaryTextColor: { h: 0, s: 0, l: 20, a: 1 },
    secondaryTextColor: { h: 209, s: 20, l: 56, a: 1 },
    primaryTextOnColor,
    secondaryTextOnColor: { h: 209, s: 70, l: 95, a: 1 },
    primaryLineColor: { h: 0, s: 0, l: 74, a: 1 },
    secondaryLineColor: { h: 207, s: 35, l: 94, a: 1 },
    shadowColor: { h: 214, s: 80, l: 46, a: 0.16 },
    buttonColor: { h: 206, s: 90, l, a: 1 },
    buttonTextColor: { h: 206, s: 90, l, a: 1 },
    buttonTextOnColor: { h: 0, s: 100, l: 100, a: 1 },
    errorColor: { h: 0, s: 68, l: 63, a: 1 },
    successColor: { h: 145, s: 65, l: 48, a: 1 },
    warningColor: { h: 32, s: 100, l: 58, a: 1 },
    infoColor: { h: 203, s: 100, l: 65, a: 1 },
    backgroundColorTertiary: { h: 240, s: 1, l: 99, a: 1 },
  }
}

export function defaultAdColors(lType: LType = 'light'): AdColors {
  return Object.assign(
    {},
    nonHslaAdColors(lType),
    applyToValues<HslaAttributes, HslaColor, string>(getHslaString, hslaAdColors(lType)),
  ) as AdColors
}
