export type Locale = 'en' | 'de' | 'fr' | 'it' | 'es'

type LocString = Partial<Record<Locale, string>>

interface ResponseOptionObject {
  value: string
  text: string | LocString
}

export type ResponseOption = ResponseOptionObject | string

export type WfaPage = Record<string, any>

export type SurveyJsElement = Record<string, any>

export type SurveyJsQuestion = any

export type SurveyJsItem = WfaPage | SurveyJsElement

export const QUESTION_TYPES_MULTI_SELECT = ['tagbox', 'checkbox']
export const QUESTION_TYPES_SINGLE_SELECT = ['dropdown', 'radiogroup']
export const QUESTION_TYPES_SELECT = [
  ...QUESTION_TYPES_MULTI_SELECT,
  ...QUESTION_TYPES_SINGLE_SELECT,
]
