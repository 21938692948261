<ng-container *rxLet="viewerStore.env$; let env">
  <header
    class="c-home-nav c-home-nav--isShown o-wrapper-base"
    *ngIf="env.isEmbeddedIn !== 'adStepper'"
  >
    <div class="menu-wrapper top-level-width">
      <div class="menu-icon-wrapper">
        <wfa-version></wfa-version>
      </div>
      <div class="menu-icon-wrapper">
        <button
          class="menu-btn"
          [matMenuTriggerFor]="langMenu"
          matTooltip="{{ 'Language' | translate }}"
        >
          <fa-icon [icon]="faGlobe" [fixedWidth]="true"></fa-icon>
          <!-- <span [class.o-vh]="true">{{ 'Language' | translate }}</span> -->
        </button>
        <mat-menu #langMenu="matMenu">
          <ng-container *ngFor="let lang of locales; let i = index">
            <button
              mat-menu-item
              (click)="changeLanguage(lang)"
              [style.fontWeight]="
                lang === localeControl.value ? '600' : 'normal'
              "
            >
              {{ 'WFA.LANGUAGES.' + lang | translate }}
            </button>
          </ng-container>
        </mat-menu>
      </div>
    </div>
  </header>
</ng-container>
