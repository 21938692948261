import { InstanceService } from "@a-d/instance/instance.service";
import { AbstractControl, AsyncValidatorFn, FormControl, UntypedFormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import dayjs from "dayjs";
// in folder validators:
import { streetNameDoesNotContainHouseNumberValidator } from './validator-functions/address.validators';
import { datesRelationValidator } from "./validator-functions/date.validators";
import { notPatternValidator } from "./validator-functions/general.validators";
import { uniqueIdentifierValidator } from "./validator-functions/instance.validators";
import {
  doNotAllowCharactersValidator,
  genderValidator,
  i18nUrlValidator,
  matchStringOfControlValidator,
  noLeadingOrTrailingWhitespaceValidator,
  noSpecialCharactersValidator,
  onlyAllowCharactersValidator
} from './validator-functions/misc-string.validators';
import { slugValidator } from "./validator-functions/slug.validators";


/**
 * Class containing custom validators for Arzt-Direkt.
 * 
 * This is the preferred location for access to validators, if you are
 * implementing a new validator check the readme in the folder of ADValidators.
 */
export class ADValidators {


  /**
   * Check if the current date is 'after' or 'before' the other date.
   * @param relation `'after'` - the date has to be the same or after otherDate
   * `'before'` - the date has to be the same or before otherDate
   * @param required is the field required (default = true)
   * @returns A validator function that returns an error map with either the
   * `dateIsNotAfterOrSame` property (relation = 'after'), or
   * the `dateIsNotBeforeOrSame` property (relation = 'before') if the date
   * relation requirement is not met; otherwise `null`
   */
  static datesRelation(
    relation: 'after'|'before', otherDate: dayjs.Dayjs, required: boolean = true
  ): ValidatorFn {
    return datesRelationValidator(relation, otherDate, required);
  }


  /**
   * @description
   * Validator that requires the field to not contain any of the specified
   * characters.
   * 
   * @usageNotes
   * ```typescript
   * const control = new FormControl('This is number #42', ADValidators.doNotAllowCharacters('#0-9'));
   * console.log(control.errors); // {notAllowedCharacters: {patternFound: '#42'}}
   * 
   * const controlB = new FormControl('This is xxnumber #42', ADValidators.doNotAllowCharacters('x0-9'));
   * console.log(controlB.errors); // {notAllowedCharacters: {patternFound: 'xx'}}
   * ```
   * 
   * @param {string} characters the characters that are not allowed to occur.
   * Special characters have to be escaped.
   * 
   * @returns A validator function that returns an error map with the 
   * `notAllowedCharacters` property if the validation fails, otherwise `null`.
   * 
   * @see {@link ADValidators.notPattern}
   */
  static doNotAllowCharacters(characters: string): ValidatorFn {
    return doNotAllowCharactersValidator(characters);
  }


  /**
   * Checks if the field value is one of M, W, D.
   */
  static gender(): ValidatorFn {
    return genderValidator();
  }

  /**
  * Checks if the entries of an i18n-String are valid URLs
  */
  static i18nUrl(): ValidatorFn {
    return i18nUrlValidator();
  }

  /**
   * @description
   * Validator that requires the string in the current FormControl to match the
   * string in another one.
   * 
   * @param {FormControl} otherControl the control against which the string of 
   * this control will be validated.
   * 
   * @returns A validator function that returns an error map with the `mismatch`
   * property if the validation fails, otherwise `null`.
   */
  static matchStringOfControl(
    otherControl: FormControl<string>
  ): ValidatorFn {
    return matchStringOfControlValidator(otherControl);
  }

  /**
   * @description
   * Validator that requires the control field not to start or end with whitespace.
   * 
   * @returns A validator function that returns an error map with the
   * `leadingOrTrailingWhitespace` property if the validation fails,
   * otherwise `null`.
   */
  static noLeadingOrTrailingWhitespace(): ValidatorFn {
    return noLeadingOrTrailingWhitespaceValidator();
  }


  /**
   * Validator that requires the control to not contain any special characters,
   * symbols or emojis.
   * Allowed are: whitespace, letters, numbers
   * 
   * @returns A validator function that returns an error map with the 
   * `specialCharacters` property if the validation fails, otherwise `null`.
   * 
   * @see {@link ADValidators.notPattern}
   */
  static noSpecialCharacters(control: AbstractControl): ValidationErrors|null {
    return noSpecialCharactersValidator(control);
  }


  /**
   * @description
   * Validator that requires the control's value to NOT match a regex pattern. 
   * This is basically the opposite behavior of {@link Validators.pattern}.
   * 
   * @usageNotes
   * ```typescript
   * const control = new FormControl('Should this be in here', ADValidators.notPattern('\\s+this\\s+'));
   * 
   * console.log(control.errors); // {pattern: {patternFound: ' this '}}
   * ```
   * @param pattern A regular expression or a string.
   * (unlike Validators.pattern a string is taken as is - no '^' and '$' added)
   * 
   * @returns A validator function that returns an error map with the
   * `pattern` property if the validation check fails, otherwise `null`.
   * The 'patternFound' contains the (offending) matching string.
   * 
   * @see {@link Validators.pattern}
   */
  static notPattern(pattern: string|RegExp): ValidatorFn {
    return notPatternValidator(pattern);
  }


  /**
   * Only allow the provided characters in the form field
   * 
   * @returns A validator function that returns an error map with the
   * `pattern` property if the validation check fails, otherwise `null`.
   * 
   * @see {@link Validators.pattern}
   */
  static onlyAllowCharacters(characters: string): ValidatorFn {
    return onlyAllowCharactersValidator(characters);
  }


  /**
   * Slug validation
   * https://developer.mozilla.org/en-US/docs/Glossary/Slug
   */
  static slug(control: UntypedFormControl): ValidationErrors|null {
    return slugValidator(control);
  }


  /**
   * @description
   * Validator to prohibit the control's value ending in a (valid, german) house number.
   * 
   * @usageNotes
   * ```typescript
   * const control = new FormControl('Schönstraße 27a', ADValidators.streetNameDoesNotContainHouseNumber);
   * 
   * console.log(control.errors); // {houseNumber: {patternFound: '27a'}}
   * ```
   * 
   * @returns An error map with the `houseNumber` property
   * if the validation check fails, `null` otherwise.
   */
  static streetNameDoesNotContainHouseNumber(control: AbstractControl): ValidationErrors|null {
    return streetNameDoesNotContainHouseNumberValidator(control);
  }


  /**
   * @description
   * Validator to ensure that the instance identifier is unique.
   * 
   * @usageNotes
   * ```typescript
   * const control = new FormControl('test-instanz', ADValidators.identifierDuplicate);
   * 
   * console.log(control.errors); // {identifierDuplicate: true}
   * ```
   * 
   * @returns An async validator function that returns an error map with the
   * `identifierDuplicate` property if the validation check fails, otherwise `null`.
   */

  static uniqueIdentifier(instanceService: InstanceService): AsyncValidatorFn {
    return uniqueIdentifierValidator(instanceService)
  }
}

