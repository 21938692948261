import { NgModule } from '@angular/core';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons';
import { faList as farList } from '@fortawesome/pro-regular-svg-icons';
import { faCircle as fasCircle } from '@fortawesome/pro-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserSupportDialogComponent } from '../../../arzt-direkt/src/app/misc/browser-support/browser-support-dialog.component';
import { ActiveIndicatorComponent } from './active-indicator/active-indicator.component';
import { DialogComponent } from './dialog/dialog.component';
import { LibComponent } from './lib.component';
import { SharedModule } from './shared.module';


@NgModule({
    imports: [
        SharedModule,
        TranslateModule,
        LibComponent,
        DialogComponent,
        BrowserSupportDialogComponent,
        ActiveIndicatorComponent,
    ],
    exports: [
        LibComponent,
        DialogComponent,
        BrowserSupportDialogComponent,
        ActiveIndicatorComponent,
        TranslateModule,
    ]
})
export class LibModule {
  constructor() {
    // Registering FontAwesome-Icons (https://github.com/FortAwesome/angular-fontawesome)
    const faIcons = [
      farList, fasCircle, falTimes,
    ]
    library.add(...faIcons)
  }
}
