import { getHslaString } from '../../../theming/color-utils'
import { applyToValues } from '../../../utils/apply-to-values'
import {
  AdColors, HslaAdColors,
  HslaAttributes,
  HslaColor,
  NonHslaAdColors
} from '../../interfaces'

const primaryColor = { h: 350, s: 75, l: 49, a: 1 }
// const primaryTextOnColor = { h: 0, s: 100, l: 100, a: 1 }

export const nonHslaTomedoColors: NonHslaAdColors = {
  modeColor: 'white',
  mainValue: primaryColor.h.toString(),
  mainSaturation: primaryColor.s.toString(),
  mainLigtness: primaryColor.l.toString(),
  primaryColorValues: `${primaryColor.h}, ${primaryColor.s}%, ${primaryColor.l}%`,
}

export const hslaTomedoColors: HslaAdColors = {
  primaryColor,
  primaryBackColor: { h: (primaryColor.h + 120) % 360, s: 50, l: 90, a: 1 }, // Complementary color
  secondaryBackColor: { h: (primaryColor.h + 60) % 360, s: 75, l: 75, a: 1 }, // Analogous color
  primaryCardColor: { h: primaryColor.h, s: primaryColor.s, l: 95, a: 1 }, // Lightened version
  secondaryCardColor: { h: (primaryColor.h + 180) % 360, s: 50, l: 95, a: 1 }, // Opposite hue
  primaryTextColor: { h: primaryColor.h, s: primaryColor.s, l: 20, a: 1 }, // Darkened version
  secondaryTextColor: { h: (primaryColor.h + 180) % 360, s: 75, l: 45, a: 1 }, // Analogous color darkened
  primaryTextOnColor: { h: 0, s: 100, l: 100, a: 1 }, // White
  secondaryTextOnColor: { h: (primaryColor.h + 180) % 360, s: 70, l: 90, a: 1 }, // Analogous color lightened
  primaryLineColor: { h: primaryColor.h, s: primaryColor.s, l: 74, a: 1 }, // Similar to primary text color
  secondaryLineColor: { h: (primaryColor.h + 180) % 360, s: 50, l: 80, a: 1 }, // Analogous color slightly lightened
  shadowColor: { h: (primaryColor.h + 180) % 360, s: 80, l: 46, a: 0.16 }, // Analogous color with adjusted saturation
  buttonColor: { h: primaryColor.h, s: primaryColor.s, l: 49, a: 1 }, // Same as primary color
  buttonTextColor: { h: 0, s: 0, l: 100, a: 1 }, // White
  buttonTextOnColor: { h: primaryColor.h, s: primaryColor.s, l: 95, a: 1 }, // Lightened version of primary color
  errorColor: { h: 0, s: 68, l: 63, a: 1 }, // Reddish color
  successColor: { h: (primaryColor.h + 120) % 360, s: 50, l: 70, a: 1 }, // Complementary color slightly darkened
  warningColor: { h: (primaryColor.h + 60) % 360, s: 75, l: 60, a: 1 }, // Analogous color slightly darkened
  infoColor: { h: (primaryColor.h + 180) % 360, s: 100, l: 65, a: 1 }, // Opposite hue with adjusted saturation and lightness
  backgroundColorTertiary: { h: (primaryColor.h + 60) % 360, s: 10, l: 98, a: 1 }, // Analogous color with adjusted saturation and lightness
} as const

export const defaultTomedoColors = Object.assign(
  {},
  nonHslaTomedoColors,
  applyToValues<HslaAttributes, HslaColor, string>(getHslaString, hslaTomedoColors)
) as AdColors
