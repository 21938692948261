import { CustomPropertyInfo } from './../../../../interfaces-autogen-from-joi/custom-property-info'

const wfaInputType: CustomPropertyInfo = {
  name: 'wfaInputType',
  type: 'string',
  visible: false,
}

const surveyElements = ['text']

export const surveyElementsWithWfaInputType = surveyElements.reduce(
  (acc, item) => {
    acc[item] = wfaInputType
    return acc
  },
  {} as Record<string, CustomPropertyInfo>,
)
