import { BookingOpening, ConditionalAge, TimeComparator } from '@a-d/entities/Booking.entity';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class DateHelperService {

  constructor() { }

  /** deprecated ToMa 2024-04-15 */
  public convertOpeningsToDates(openings: BookingOpening[]): BookingOpening[] {
    return openings;
    // changed date from dayjs to Date in BookingOpening
    /* if (!openings) { return []; }
    const convertedOpenings = openings.map((opening: BookingOpening) =>
      opening = {
        ...opening,
        date: dayjs(opening.date)
      }
    );
    return convertedOpenings; */
  }

  public compareWithBoundary(date: dayjs.Dayjs, boundary: dayjs.Dayjs, isAfter: boolean) {
    if (isAfter) {
      return date.isAfter(boundary)
    }
    return date.isBefore(boundary);
  }

  public checkBoundaryCondition(birthDate: Date, ageConditions: ConditionalAge): boolean {
    if (!ageConditions) { return true; }
    const firstCondition = ageConditions.condition[0];
    const birthDateDJS = dayjs(birthDate);
    if (!firstCondition) { return true; }
    const firstBoundaryDate = birthDateDJS.add(firstCondition.timeSpan, firstCondition.timeUnit);
    const secondCondition = ageConditions.condition[1];
    if (!secondCondition) {
      return this.compareWithBoundary(dayjs(), firstBoundaryDate, firstCondition.timeComparator === TimeComparator.GREATER_THAN)
    }
    const secondBoundaryDate = birthDateDJS.add(secondCondition.timeSpan, secondCondition.timeUnit);
    return (this.compareWithBoundary(dayjs(), firstBoundaryDate, firstCondition.timeComparator === TimeComparator.GREATER_THAN)
      && this.compareWithBoundary(dayjs(), secondBoundaryDate, secondCondition.timeComparator === TimeComparator.GREATER_THAN))
  }
}
