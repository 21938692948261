import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { fromEvent, interval, Subject } from 'rxjs';
import { takeUntil, throttle } from 'rxjs/operators';
import { NotificationService } from '../../../../lib/src/lib/notifications/notification.service';
import { DsgvoService } from '../dsgvo/dsgvo.service';
import { InstanceService } from '../instance/instance.service';
import { StorageService } from '../storage/storage.service';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-dev-bar',
    template: `
    <a *ngIf="instanceService.activeInstance" routerLink="/">Start</a>
    <a *ngIf="instanceService.activeInstance" routerLink="/dashboard">Dashboard</a>
    <a (click)="clearStorages()">Clear Storages</a>
    <a (click)="loadAdminUser()">Load Admin</a>
    <a (click)="acceptCookies()">Accept Cookies</a>
    <!-- <a *ngIf="instanceService.activeInstance" (click)="replicateSampleData()">Replicate Data</a> -->
    <!-- <a (click)="loadSampleData()">Load Sample-DB</a> -->
  `,
    styleUrls: ['./dev-bar.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink]
})
export class DevBarComponent implements OnInit {

  private unsubscribe$ = new Subject()


  constructor(
    public instanceService: InstanceService,
    private http: HttpClient,
    private hostEl: ElementRef,
    private storageService: StorageService,
    private router: Router,
    private notificationService: NotificationService,
    private dsgvoService: DsgvoService,
  ) { }

  ngOnInit() {
    const shownClass = 'isShown';

    fromEvent(window, 'mousemove').pipe(
      throttle(() => interval(150)),
      takeUntil(this.unsubscribe$)
    ).subscribe((event: MouseEvent) => {
      if (event.x <= 42) this.hostEl.nativeElement.classList.add(shownClass)
      else this.hostEl.nativeElement.classList.remove(shownClass)
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }


  public clearStorages() {
    localStorage.clear()
    this.storageService.clearAll().subscribe()
    this.dsgvoService.resetAllDsgvoPreferences()
    window.location.reload()
  }

  // public loadSampleData() {
  //   this.http.get('/api/demo/load-sample-data').subscribe(() => {
  //     console.log('Cleared Database & Loaded Sample Data');
  //     this.clearStorages();
  //   }, (error) => { console.warn(error) })
  // }

  public replicateSampleData() {
    const data = {
      instanceId: this.instanceService.activeInstance._id,
      replicationFactor: 1,  // 1 will double all sessions
    }
    this.http.post('/api/demo/replicate-sample-data', data).subscribe(() => {
      this.notificationService.displayNotification("Successfully replicated data")
    }, (error) => {
      console.warn(error)
      this.notificationService.displayNotification("Error while replicating data")
    })
  }

  public loadAdminUser() {
    this.http.get('/api/demo/load-admin-user').subscribe(() => {
      this.notificationService.displayNotification("Inserted admin-user into database")
      this.clearStorages();
      this.router.navigateByUrl('/admin')
    }, (error) => {
      console.warn(error)
      this.notificationService.displayNotification("Error while inserting admin-user into database")
    })
  }

  public acceptCookies() {
    this.dsgvoService.acceptAllDsgvoPreferences()
  }

}
