import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralUtilityService {

  constructor() {}

  public getKeyByValue(obj: object, value: any, subPath?: string[]) {
    return Object.keys(obj).find((key) => {
      let path = [key];
      if (subPath) {
        path = path.concat(subPath);
      }
      const property = this.getNestedProperty(path, obj);
      return (property === value);
    })
  }

  public getNestedProperty(path: string[], obj: object) {
    return path.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, obj);
  }

  public jsonSafeParse(data: any): Observable<any> {
    return new Observable((observer) => {
      try {
        observer.next(JSON.parse(data));
        observer.complete();
      } catch (error) {
        observer.error(error);
        observer.complete();
      }
    })
  }
}
