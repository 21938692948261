import { GoSettings } from './go-settings.entity';

export const goSettings: GoSettings = {
  sideEffects: {
    title: {
      de: 'Sind bei Impfungen in der Vergangenheit unerwünschte Nebenwirkungen oder Probleme aufgetreten?',
      en: 'Did past vaccinations cause undesirable side effects or problems?',
    },
    shortTitle: {
      de: 'Nebenwirkungen bei vergangenen Impfungen',
      en: 'Side effects of past vaccinations',
    },
    options: {
      de: ['ja', 'nein'],
      en: ['yes', 'no'],
    },
  },
  customSideEffect: {
    title: {
      de: 'Bitte beschreiben Sie die aufgetretenen Nebenwirkungen',
      en: 'Please describe the occured side effects',
    },
    shortTitle: {
      de: 'Nebenwirkungen (detailliert)',
      en: 'Side effects (elaborated)',
    },
  },
  allergies: {
    title: {
      de: 'Haben Sie Allergien (z.B. auf Medikamente, frühere Impfungen, Hühnereiweiss, Nahrungsmittel)?',
      en: 'Do you have any allergies (e.g. to medication, former vaccinations, poultry protein, consumables)?',
    },
    shortTitle: {
      de: 'Allergien',
      en: 'Allergies',
    },
    options: {
      de: ['ja', 'nein'],
      en: ['yes', 'no'],
    },
  },
  allergySelect: {
    title: {
      de: 'Allergien',
      en: 'allergies',
    },
    shortTitle: {
      de: 'Welche Allergien',
      en: 'Which allergies',
    },
    options: {
      de: [
        'Bienen-/Wespenstiche',
        'Heuschnupfen',
        'Hühnereiweiß oder Eier',
        'Kontrastmittel',
        'Medikamente (z.B. Antibiotika)',
        'Sonstige Allergene',
      ],
      en: [
        'Bee or wasp stings',
        'Chicken protein or eggs',
        'Contrast agents',
        'Hay fever',
        'Medicaments (e.g. antibiotics)',
        'Other allergens',
      ],
    },
  },
  customAllergy: {
    title: {
      de: 'Medikamente/Sonstige Allergene',
      en: 'Medicaments/Other allergens',
    },
    shortTitle: {
      de: 'Sonstige Allergene',
      en: 'Other allergens',
    },
  },
  allergyDetails: {
    title: {
      de: 'Was für Beschwerden sind bei dem Allergieanfall aufgetreten? In welchem zeitlichen Abstand (sofort oder innerhalb Stunden / Tage / Wochen) sind die Beschwerden aufgetreten?',
      en: 'What were your symptoms during an allergic reaction? What was the duration (immediate/days/weeks/months) between the allergen contact and the onset of symptoms?',
    },
    shortTitle: {
      de: 'Allergieanfall (detalliert)',
      en: 'Allergy onset (elaborated)',
    },
  },
  chronicDiseases: {
    title: {
      de: 'Leiden Sie an oder hatten Sie eine chronische Erkrankung?',
      en: 'Are you currently suffering or did you have a chronic disease?',
    },
    shortTitle: {
      de: 'Chronische Erkrankungen',
      en: 'Chronic diseases',
    },
    options: {
      de: ['ja', 'nein, ich leide an keiner Erkrankung'],
      en: ['yes', 'no, I do not have any chronic diseases'],
    },
    hint: {
      de: 'Beispiele: Asthma, Bluthochdruck, Herzerkrankung, Blutgerinnungsstörung, Thrombose, Lungenerkrankung, Diabetes, Entzündliche Darmerkrankung (z.B. Morbus Crohn, Chronische Colitis), Nierenfunktionsstörung, Lebererkrankung, Epilepsie, Verletzung oder Entfernung der Milz, Organtransplantationen, Krebserkrankung, HIV/AIDS, Erkrankung/Entfernung des Thymus, Erkrankungen des Immunsystems (z.B. Multiple Sklerose, chronische Arthritis, Psoriasis), Psychische Erkrankung/Depression, Gelbsucht (Hepatitis) oder andere.',
      en: "Examples: Asthma, Hypertension, Heart Disease, Bleeding Disorder, Thrombosis, Lung Disease, Diabetes, Inflammatory Bowel Disease (e.g. Crohn's Disease, Chronic Colitis), Renal Dysfunction, Liver Disease, Epilepsy, Injury or Removal of the Spleen, Organ Transplants, Cancer, HIV/AIDS, Disease/Removal of the thymus, diseases of the immune system (e.g. multiple sclerosis, chronic arthritis, psoriasis), mental illness/depression, jaundice (hepatitis) or others.",
    },
  },
  customChronicDisease: {
    title: {
      de: 'Chronische Erkrankung',
      en: 'Chronic disease',
    },
    shortTitle: {
      de: 'Chronische Erkrankungen (detailliert)',
      en: 'Chronic diseases (elaborated)',
    },
  },
  medications: {
    title: {
      de: 'Nehmen Sie zur Zeit Medikamente ein oder stehen Sie unter einer der folgenden genannten Therapien?',
      en: 'Are you currently taking medication or are you currently undergoing any of the following treatments?',
    },
    shortTitle: {
      de: 'Zurzeit eingenommene Medikamente',
      en: 'Currently taken medication',
    },
    options: {
      de: ['ja', 'nein, ich nehme keine Medikamente regelmäßig ein'],
      en: ['yes', 'no, I do not take any medication'],
    },
    hint: {
      de: 'Beispiele: Medikamente, die das Immunsystem unterdrücken (z.B. Kortison), Blutdrucksenker, Blutverdünner, Antibiotika, antivirale Medikamente, Antidepressiva, Hormone (z.B. Pille), Chemotherapie, Strahlentherapie, Desensibilisierungstherapie bei Allergien, Blutprodukte, Antikörper oder andere.',
      en: 'Examples: Drugs that suppress the immune system (e.g. cortisone), antihypertensives, blood thinners, antibiotics, antiviral drugs, antidepressants, hormones (e.g. contraception pills), chemotherapy, radiotherapy, allergy desensitization therapy, blood products, antibodies or others.',
    },
  },
  customMedication: {
    title: {
      de: 'Medikamente/Therapien',
      en: 'Medication/Treatment',
    },
    shortTitle: {
      de: 'Medikamente (detailliert)',
      en: 'Medication (elaborated)',
    },
  },
  goOther: {
    title: {
      de: 'Zusätzliche Information',
      en: 'Additional information',
    },
  },
};
