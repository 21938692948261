import { InstanceWhitelabel } from 'vendor/arzt-direkt/shared/schemas/interfaces/instance/instance-whitelabel';
import { instanceWhitelabelDict } from 'vendor/arzt-direkt/shared/schemas/untyped-joi/instance/instance-whitelabel-dict';
import { I18NString } from './I18N.entity';
import { InstanceForm } from './InstanceForm.entity';
import { InstanceEncryptionMode, InstanceSettings } from './InstanceSettings.entity';
import { PraxisContact } from './Praxis.entity';



export interface Instance {
  readonly createdAt: Date
  readonly _id: string
  readonly roles: string[]
  readonly status: InstanceStatus
  readonly publicStatusReason?: string
  readonly identifier: string
  readonly customerId: number
  readonly isTomedoUser: boolean
  readonly localWebRTC?: boolean
  readonly testInstance?: boolean
  readonly email: string
  readonly name: string
  readonly shortName: string
  readonly bsnr?: string
  readonly nbsnr?: string
  readonly whitelabelType?: InstanceWhitelabel
  readonly dialect?: Dialect

  readonly contact: PraxisContact
  readonly assets: InstanceAssets
  readonly settings: InstanceSettings
  readonly security: InstanceSecurity
  readonly forms: InstanceForm[]

  readonly billing: InstanceBilling
  readonly latestChangelogRead: Date
  readonly questionnaire?: InstanceQuestionnaire

  readonly isArzt?: boolean
  readonly isAdmin?: boolean
  readonly isInstance?: boolean
  readonly hasNoRole?: boolean
  readonly modules: InstanceModule[]
  readonly keywords?: string[]
  readonly tomedoVersion?: string
  readonly history?: InstanceHistory[]
  readonly seo?: SEOSettings
  readonly statusMessage?: InstanceStatusMessage
  readonly pappAccounts?: InstancePappAccount[]
  readonly acceptedAGB?: boolean
}

export interface InstanceStatusMessage {
  message: I18NString | undefined,
  showUntil: string | undefined, //Date as ISO string
  storedMessages: I18NString[],
  createdAt: string //Date as ISO string
}

export interface InstancePappAccount {
  pappId: string,
  name: string,
  qrContent?: string
}

export type PappInstanceStatusMessageUpdateType = "message" | "showUntil" | "delete" | "messageAndShowUntil" | undefined | null;

export interface PappInstanceStatusMessage {
  updateType: PappInstanceStatusMessageUpdateType,
  message: string | undefined | null,
  showUntil: string | undefined | null,
  sessionId: string | undefined | null,
  createdAt: string | undefined | null,
  instanceId: string
}

export interface SEOSettings {
  title: string
  subtitle: string
  keywords: string[]
  specializations: string[]
  aboutUs: string
  contact: string
  updatedAt: Date
}

export interface InstanceCreateResponse {
  readonly instance: Instance
  readonly message: string
  readonly status: number
}

export interface InstanceTemplate {
  readonly createdAt: Date
  readonly _id: string
  readonly customerId: number
  readonly isTomedoUser: boolean
  readonly localWebRTC: boolean
  readonly whitelabelType?: InstanceWhitelabel
  readonly email: string
  readonly dialect?: Dialect
  readonly encryptionMode: InstanceEncryptionMode
  readonly billing: InstanceBilling
  readonly modules: string[]
  readonly history?: InstanceHistory[],
  readonly invisibleInPortal?: boolean
}

export interface InstanceUpdate extends Instance {
  readonly password?: string
}

export enum InstanceModule {
  ADPay = 'adp',
  OTK = 'otk',
  VSS = 'vss',
  WFA = 'wfa',
}

/**
 * Instance-Status
 */
export enum InstanceStatus {
  Registered = 'registered',
  Disabled = 'disabled',
  Enabled = 'enabled',
  Blocked = 'blocked',
}

export enum InstanceActivationStatus {
  Aktiviert = 'aktiviert',
  Deaktiviert = 'deaktiviert',
  Template = 'template'
}

export enum InstanceBillingCountry {
  switzerland = 'Schweiz',
  germany = 'Deutschland',
  austria = "Österreich"
}

export enum InstanceBillingCountryKey {
  switzerland = 'switzerland',
  germany = 'germany',
  austria = 'austria'
}

export enum BillingTypeVss {
  perSessionPrice = 'perSessionPrice',
  monthlyPrice = 'monthlyPrice'
}

export enum BillingTypeOtk {
  perBookingPrice = 'perBookingPrice',
  monthlyPrice = 'monthlyPrice'
}

export enum PriceType {
  default = 'default',
  custom = 'custom'
}

/**
 * Instance-Security
 */
export interface InstanceSecurity {
  readonly passwordTemp: string
  readonly password: string
  readonly link: string
  readonly downloadedCryptoKeys: boolean
  readonly keystore: string
  readonly keystoreSalt: string
  certificate: string
  readonly renewCryptoKeys: boolean
}

export interface InstanceDebug {
  activateAdLogger?: boolean
}

/**
 * Initial instance information
 */
export interface InstanceRegistration {
  readonly contact: PraxisContact
  readonly identifier: string
  readonly email: string
  readonly name: string
  readonly shortName: string
  readonly billing?: Partial<InstanceBilling>
  readonly settings?: Partial<InstanceSettings>
  readonly forms?: InstanceForm[]
  readonly bsnr: string
  readonly nbsnr: string
}

export interface InstanceFullRegistration {
  readonly contact: PraxisContact
  readonly identifier: string
  readonly email: string
  readonly name: string
  readonly shortName: string
  readonly bsnr: string
  readonly nbsnr: string
  readonly customerId: string
  readonly isTomedoUser: boolean
  readonly localWebRTC: boolean
  readonly whitelabelType?: InstanceWhitelabel
  readonly dialect?: Dialect
  readonly encryptionMode: InstanceEncryptionMode
  readonly billing: InstanceBilling
  readonly settings: object
}

/**
 * instance template information
 */
export interface InstanceTemplateRegistration {
  readonly customerId: number
  readonly isTomedoUser: boolean
  readonly localWebRTC: boolean
  readonly whitelabelType: InstanceWhitelabel
  readonly email: string
  readonly dialect: Dialect
  readonly encryptionMode: InstanceEncryptionMode
  readonly billing: InstanceBilling
}

/**
 * Instance-Status Update
 */
export interface InstanceStatusUpdateRequest {
  readonly _id: string,
  readonly status: InstanceStatus
  readonly statusReason?: string
  readonly statusReasonIsPublic?: boolean
}
export interface InstanceStatusUpdateResponse {
  readonly status: number,
  readonly data?: {
    readonly status: InstanceStatus
    readonly publicStatusReason?: string
  }
}

export interface InstanceUserCertEntry {
  readonly _id: string
  readonly certificate: string
}

export interface InstanceUserKeyEntry {
  readonly _id: string
  readonly encryptedKey: string
}

export interface InstanceUserResponse {
  readonly message: string
  readonly status: number
  readonly users: InstanceUserCertEntry[]
}


/**
 * Instance-Assets
 */
export interface InstanceAssets {
  readonly logoImageSquare?: InstanceAsset
  readonly logoImageRectangle?: InstanceAsset
  emailImageRectangle?: InstanceAsset
  vssBackgroundImage?: InstanceAsset
}

export interface InstanceAsset {
  readonly data: string,
  readonly contentType: string,
}

export const InstanceWhitelabelType = instanceWhitelabelDict


/**
 * Dialects
 * IMPORTANT: 
 *  make sure the definitions for Dialect in
 *    1. projects/arzt-direkt/src/app/entities/instance.entity.ts
 *    2. server/types/instance.types.js
 *    3. server_appointment/src/types/instance.types.ts
 *  are synchronized!
 */
export enum Dialect {
  Deaktiviert = 'deaktiviert',
  // vss and otk
  Mitarbeiter = 'mitarbeiter',
  Heilpraktiker = 'heilpraktiker',
  // only vss
  Apotheker = 'apotheker',
  // only otk
  Arzt = 'arzt',
  Behandler = 'behandler',
  Ansprechpartner = 'ansprechpartner',
  KanzLaw = 'kanzlaw'
}


/*
 * Instance-Billing
 */
export interface InstanceBilling {
  readonly isActive?: boolean
  readonly billingCountry?: InstanceBillingCountryKey
  readonly prices?: {
    readonly monthlyPerArzt: number
    readonly perSession: number
    readonly billingType?: BillingTypeVss
    readonly priceType?: PriceType
    readonly updatedAt?: Date
    readonly discountUntil?: Date
  }
  readonly otk?: {
    readonly billingType?: BillingTypeOtk
    readonly priceType?: PriceType
    readonly updatedAt?: Date
    readonly isActive?: boolean
    readonly userCount?: number
    readonly monthlyPrice?: number
    readonly perBookingPrice?: number
    readonly discountUntil?: Date
  }
}

/**
 * History
 */

export enum InstanceHistoryEvents {
  activated = 'activated',
  deactivated = 'deactivated',
  //changed = 'changed',
}

export interface InstanceHistory {
  readonly date: Date
  readonly eventIn: string
  readonly type: InstanceModule | InstanceWhitelabel
  readonly event: InstanceHistoryEvents
}


/**
 * Questionnaire
 */

export interface InstanceQuestionnaire {
  readonly latestDialogRead: Date
  readonly dialogReadCounter: number
  readonly latestQuestionnaireSent: Date
}
