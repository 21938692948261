import Joi from 'joi'

export const surveyjsInputTypes = ['text', 'date', 'password']

export const customInputTypes = [
  'custom-number',
  'custom-phone-number',
  'custom-email',
  'custom-insurant-number',
  'custom-insurance-number',
]

export const inputTypes = [...surveyjsInputTypes, ...customInputTypes] as const

export const InputTypeJoi = Joi.string()
  .valid(...inputTypes)
  .meta({
    className: 'InputType',
  })
