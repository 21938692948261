import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Observable, of } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { PatientSession, PatientSessionState, PatientSessionStateElement } from '../entities/PatientSession.entity';
import { PatientSessionShallow } from '../entities/PatientSessionShallow.entity';
import { PatientSessionService } from './patient-session.service';


@Injectable({
  providedIn: 'root'
})
export class PatientSessionStatesService {

  constructor(
    private patientSessionService: PatientSessionService,
    private authService: AuthService,
  ) { }


  /**
   * Returns the latest (newest) state-element from the sessions state-history.
   * If `state` is given it filters out the latest state-element with that state.
   */
  public latestStateElement(session: PatientSession | PatientSessionShallow, state?: PatientSessionState): PatientSessionStateElement {
    if (!session?.stateHistory?.length) return null
    const stateElement = session.stateHistory.find((el: PatientSessionStateElement) => {
      return !state || el.state === state
    })
    return stateElement
  }

  /**
   * Returns the prognosed date where the seesion will get archived / anonymized by the server.
   * Please note that if the session is already archived this date will be returned.
   */
  public scheduledArchivingDate(session: PatientSession): Date {
    const archivedAt = (<any>session).archivedAt
    if (archivedAt) return dayjs(archivedAt).toDate()
    return dayjs(session.createdAt).add(14, 'day').toDate()
  }



  /**
   * Helper to get a basic PatientSessionStateElement
   */
  private createStateElement(state: PatientSessionState): PatientSessionStateElement {
    const date = new Date()
    const user = this.authService.user
    const patientSessionStateElement: PatientSessionStateElement =
    {
      date,
      state,
      ...(user ? { userId: user._id } : {}),
    }
    console.log("createStateElement: patientSessionStateElement = ", patientSessionStateElement)
    return patientSessionStateElement
  }

  /**
   * All States according to Lifecycle
   * https://whimsical.co/4u8qfXHh7aLNjPHjWtwgjk
   */


  /**
   * the patient session is set by the user to active when the vss starts,
   * including on the backend. However the local patient session of the doctor
   * is not updated and still has the status pending. This causes the doctor to leave
   * the VSS upon refresh. To prevent this, the doctor's session state is also
   * updated to active, but only on the front end.
   */
  public setActiveFrontEndOnly(): Observable<boolean> {
    const stateElement: PatientSessionStateElement = this.createStateElement(PatientSessionState.Active)
    const patientSession = this.patientSessionService.activeSession$.value;
    if (patientSession.state === PatientSessionState.Pending) {
      const stateHistory = Object.values(patientSession.stateHistory)
      stateHistory.unshift(stateElement)
      patientSession.state = stateElement.state
      patientSession.stateHistory = stateHistory
      return this.patientSessionService.setActiveSession(patientSession) 
    }   
    console.log("setActiveFrontEndOnly: did not set the state to active because it was not set previously to pending")
    return of(null)
  }

}
