import { KeyFormats } from '@a-d/entities/CertValues.entity';
import { Injectable } from '@angular/core';
import { toBase64 } from 'pvutils';
import { Observable, tap } from 'rxjs';
import { TextEncoder } from 'text-encoding-shim';
import { UtilityService } from './utility.service';
import { defaultRsaKeyParameters, createRsaKeyPair } from './lib/key/create-rsa-key-pair'

@Injectable({
  providedIn: 'root'
})
export class KeyService {
  // maybe switch to newer RSA signing algorithm?
  // private rsaKeyParameters = {
  //   name: 'RSA-PSS',
  //   modulusLength: 4096,
  //   publicExponent: new Uint8Array([1, 0, 1]),
  //   hash: 'SHA-256',
  // };
  private rsaKeyParameters = defaultRsaKeyParameters;

  // elliptic curve encryption
  private ecdsaParameters = {
    name: 'ECDSA',            // algorithm name
    namedCurve: 'P-521'       // type of curve to use
  }
  private _publicKey: CryptoKey;
  private _privateKey: CryptoKey;

  constructor(
    private utilityService: UtilityService
  ) { }

  /**
   * make new RSA crypto key pair
   */
  public createRSAKeyPair(): Observable<CryptoKeyPair> {
    return createRsaKeyPair(this.rsaKeyParameters).pipe(
      tap((keyPair: CryptoKeyPair) => {
        this._publicKey = keyPair.publicKey;
        this._privateKey = keyPair.privateKey;
      })
    )
  }

  /**
   * make new elliptic kurve key pair
   */
  public createECDSAKeyPair(): Observable<CryptoKeyPair> {
    return new Observable((observer) => {
      crypto.subtle.generateKey(this.ecdsaParameters, true, ['sign', 'verify'])
        .then((keyPair: CryptoKeyPair) => {
          this._publicKey = keyPair.publicKey;
          this._privateKey = keyPair.privateKey;
          observer.next(keyPair);
          observer.complete();
        },
          (error) => {
            observer.error(error);
          })
    })
  }

  /**
   * export key as array buffer
   * @param key rsa crypto key
   * @param format pkcs8 for private / spki for public key
   */
  public exportRSAKey(key: CryptoKey, format: KeyFormats): Observable<ArrayBuffer> {
    return new Observable((observer) => {
      crypto.subtle.exportKey(format, key)
        .then((keyBuffer: ArrayBuffer) => {
          observer.next(keyBuffer);
          observer.complete();
        },
          (error) => {
            observer.error(error);
          })
    });
  }

  /**
   * import key from array buffer
   * @param keyBuffer key data buffer
   * @param format pkcs8 for private / spki for public key
   */
  public importRSAKey(keyBuffer: ArrayBuffer, format: KeyFormats): Observable<CryptoKey> {
    return new Observable((observer) => {
      crypto.subtle.importKey(format, keyBuffer, this.rsaKeyParameters, true, ['decrypt'])
        .then((key) => {
          observer.next(key);
          observer.complete();
        },
          (error) => {
            observer.error(error);
          })
    });
  }

  /**
   * create pem file from crypto key buffer
   * @param keyBuffer key data
   * @param keyType public or private key
   */
  public createKeyPem(keyBuffer: ArrayBuffer, keyType: string): Observable<string> {
    return new Observable((observer) => {
      const keyData = String.fromCharCode.apply(null, new Uint8Array(keyBuffer));
      let pemString = '-----BEGIN ' + keyType.toUpperCase() + '-----\r\n';
      pemString = `${pemString}${this.utilityService.formatPEM(toBase64(keyData))}`;
      pemString = `${pemString}\r\n-----END ${keyType.toUpperCase()}-----\r\n`;
      observer.next(pemString);
      observer.complete();
    });
  }

  /**
   * use pbkdf2 to stretch standard user password for increased security
   * @param password weak password to be stretched
   * @param iterations number of hashing rounds
   * @param saltBuffer randomly generated salt
   * @param hashAlgorithm hashing algorithm to use
   */
  public deriveKeyFromPassword(password: string, iterations: number, saltBuffer: Uint8Array, hashAlgorithm: string): Observable<string[]> {
    return new Observable((observer) => {
      // const saltBuffer = crypto.getRandomValues(new Uint8Array(saltLength));
      const encoder = new TextEncoder();
      const passPhrase = encoder.encode(password);

      crypto.subtle.importKey(
        'raw',                        // key format
        passPhrase,                   // key data
        'PBKDF2',                     // hash algorithm
        false,                        // can the key be extracted
        ['deriveBits', 'deriveKey']   // key usage
      )
        .then((pbkdf2Key) => this.derive(pbkdf2Key, saltBuffer, iterations, hashAlgorithm))
        .then((webKey) => crypto.subtle.exportKey('raw', webKey))
        .then((keyBuffer) => {
          const pbkdfPassword = this.utilityService.bufferToHex(keyBuffer);
          const salt = this.utilityService.bufferToHex(saltBuffer);
          const data = [];
          data.push(pbkdfPassword);
          data.push(salt);
          observer.next(data);
          observer.complete();
        },
          (error) => {
            observer.error(error);
            observer.complete();
          });
    });
  }

  /**
   * stretch password key to increase difficulty of brute force attacks
   * @param key key from simple password
   * @param saltbuffer randomly generated salt bits
   * @param iterations number of hashing rounds
   * @param hashAlgorithm hashing algorithm to use
   */
  private derive(key: CryptoKey, saltbuffer: Uint8Array, iterations: number, hashAlgorithm: string) {
    return crypto.subtle.deriveKey(
      {
        'name': 'PBKDF2',
        'salt': saltbuffer,
        'iterations': iterations,
        'hash': hashAlgorithm
      },
      key,                                    // key data
      { 'name': 'AES-CBC', 'length': 256 },    // cypther suite
      true,                                   // key extractable
      ['encrypt', 'decrypt']                  // key usage
    );
  }

  get publicKey(): CryptoKey {
    return this._publicKey;
  }

  set publicKey(key: CryptoKey) {
    this._publicKey = key;
  }

  get privateKey(): CryptoKey {
    return this._privateKey;
  }

  set privateKey(key: CryptoKey) {
    this._privateKey = key;
  }
}
