<!-- Address Search-Field -->
<div class="c-form__row" [class.o-vh]="disabled" *ngIf="!addressSearchDisabled">

  <mat-form-field [class.mat-form-field-invalid]="(anyAddressFieldIsTouched || stepInteracted) && !fullAddressIsValid"
    appearance="outline">

    <mat-label>
      <fa-icon [icon]="['far', 'search']" style="margin-right: .25rem"></fa-icon>
      {{ 'ADDRESS-SEARCH-FIELD.SEARCH' | translate:{value: customAddressLabel} }} <span *ngIf="!showAddressInputs"
        [class.isError]="!fullAddressIsValid">*</span>
    </mat-label>

    <fa-icon matSuffix *ngIf="fullAddressIsValid" [icon]="['far', 'check']" class="isSuccess"></fa-icon>

    <mat-hint style="margin-bottom: 1.25rem; margin-top: .2rem;">
      <button attr.data-selenium-id="button-show-address-inputs-true" class="c-custom-address-button"
        [class.c-custom-address-button--moreProminent]="(anyAddressFieldIsTouched || stepInteracted) && !fullAddressIsValid"
        (click)="showAddressInputs=true;" *ngIf="!showAddressInputs">
        <strong>{{'ADDRESS-SEARCH-FIELD.SEARCH-HINT' | translate}}</strong>
        <fa-icon [icon]="['fas', 'arrow-alt-circle-right']" style="opacity: .5; margin-left: .25rem;"></fa-icon>
      </button>
    </mat-hint>

  </mat-form-field>

</div>


<!-- Custom Address-Inputs -->
<ng-container [formGroup]="formGroup">

  <div [class.o-vh]="!showAddressInputs"
    [ngClass]="{ 'c-form__info-banner c-info-banner normal' : !disabled && !addressSearchDisabled, 'c-form__row__item--stacked' : disabled || addressSearchDisabled }">

    <!-- Show Address_2 Button Template -->
    <ng-template #showAddress_2Button>
      <button attr.data-selenium-id="button-show-address-2-true" mat-icon-button
        *ngIf="!!address_2 && !disabled && !showAddress_2" (click)="showAddress_2 = true; $event.stopPropagation();"
        matTooltip="{{'ADDRESS-SEARCH-FIELD.ADDITIONAL-ADDRESS' | translate }}">
        <fa-icon [icon]="faPlus" style="color: rgba(0, 0, 0, 0.54);"></fa-icon>
      </button>
    </ng-template>

    <!-- Street-Name & -Number -->
    <div class="c-form__row" *ngIf="seperateStreetNameAndNumber">

      <mat-form-field attr.data-selenium-id="field-strasse"
        [class.c-form__row__item--small]="!disabled && !addressSearchDisabled" appearance="outline">
        <mat-label>
          {{'ADDRESS-SEARCH-FIELD.STREET' | translate }}<span *ngIf="isDynamicallyRequired" class="isError">*</span>
        </mat-label>
        <input attr.data-selenium-id="input-street-name" matInput [formControlName]="streetName" spellcheck="false"
          name="street" autocomplete="street-address" [required]="isRequired">
        <mat-error *ngIf="formGroup.get('street')?.hasError('specialCharacters')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.EMOJIS' | translate">
        </mat-error>
        <mat-error *ngIf="formGroup.get('street')?.hasError('houseNumber')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.HOUSE-NUMBER' | translate ">
        </mat-error>
      </mat-form-field>

      <mat-form-field attr.data-selenium-id="field-hausnummer"
        [class.c-form__row__item--verySmall]="!disabled && !addressSearchDisabled" appearance="outline">
        <mat-label>
          {{'ADDRESS-SEARCH-FIELD.HOUSE-NUMBER' | translate }}<span *ngIf="isDynamicallyRequired"
            class="isError">*</span>
        </mat-label>
        <input attr.data-selenium-id="input-street-number-name" matInput [formControlName]="streetNumberName"
          spellcheck="false" name="streetNumber" [required]="isRequired">
        <mat-error *ngIf="formGroup.get('streetNumber')?.hasError('specialCharacters')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.EMOJIS' | translate">
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Address_1 -->
    <div class="c-form__row" *ngIf="!seperateStreetNameAndNumber">

      <mat-form-field attr.data-selenium-id="field-strasse-hausnummer" appearance="outline">
        <mat-label>
          {{'ADDRESS-SEARCH-FIELD.STREET-AND-NUMBER' | translate }}<span *ngIf="isDynamicallyRequired"
            class="isError">*</span>
        </mat-label>
        <input attr.data-selenium-id="input-street-address" matInput placeholder="Straße und Hausnummer"
          [formControlName]="address_1Name" spellcheck="false" autocomplete="street-address" [required]="isRequired">
        <mat-error *ngIf="formGroup.get('address_1')?.hasError('specialCharacters')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.EMOJIS' | translate">
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="showAddress_2Name" class="c-form__row">
      <mat-form-field attr.data-selenium-id="field-adresszusatz" appearance="outline">
        <mat-label>{{'ADDRESS-SEARCH-FIELD.ADDITIONAL-ADDRESS' | translate }}</mat-label>
        <input attr.data-selenium-id="input-address-2-name" matInput [formControlName]="address_2Name"
          spellcheck="false" autocomplete="chrome-off" #address_2_Input>
        <mat-error *ngIf="formGroup.get('address_2')?.hasError('specialCharacters')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.EMOJIS' | translate">
        </mat-error>
      </mat-form-field>
    </div>

    <div class="c-form__row">
      <mat-form-field attr.data-selenium-id="field-plz"
        [class.c-form__row__item--verySmall]="!disabled && !addressSearchDisabled" appearance="outline">
        <mat-label>{{'ADDRESS-SEARCH-FIELD.POSTCODE' | translate }}<span *ngIf="isDynamicallyRequired"
            class="isError">*</span>
        </mat-label>
        <input attr.data-selenium-id="input-postal-code" matInput autocomplete="postal-code" name="zip"
          spellcheck="false" [formControlName]="zipName" [required]="isRequired" />
        <mat-error *ngIf="zip.errors?.invalidPlz">
          {{ 'ADDRESS-SEARCH-FIELD.INVALID-ZIP' | translate }}
          <ng-container *ngIf="!country.invalid && country.value">({{ country.value }})</ng-container>
        </mat-error>
      </mat-form-field>

      <mat-form-field attr.data-selenium-id="field-ort"
        [class.c-form__row__item--small]="!disabled && !addressSearchDisabled" appearance="outline">
        <mat-label>{{'ADDRESS-SEARCH-FIELD.CITY' | translate }}<span *ngIf="isDynamicallyRequired"
            class="isError">*</span>
        </mat-label>
        <input attr.data-selenium-id="input-city" matInput autocomplete="address-level2" name="city" spellcheck="false"
          [formControlName]="cityName" [required]="isRequired" />
        <mat-error *ngIf="formGroup.get('city')?.hasError('specialCharacters')"
          [innerHTML]="'OTK.BOOKING-PERSONAL.ERRORS.EMOJIS' | translate">
        </mat-error>
      </mat-form-field>
    </div>

    <div class="c-form__row">
      <app-country-field-custom #countryField [formGroup]="formGroup" [name]="countryName" autocomplete="country"
        [useDynamicallyRequiredValidator]="useDynamicallyRequiredValidator" [onlyAllowLangCodes]="onlyAllowLangCodes"
        [required]="requireCountry" [returnValue]="returnValue">
      </app-country-field-custom>

      <ng-container *ngIf="showStateSelection && country.value">
        <app-state-field class="c-form__row" [formGroup]="formGroup" [name]="stateName">
        </app-state-field>
      </ng-container>
    </div>

  </div>
</ng-container>