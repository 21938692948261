import { WfaFormControlService } from "@a-d/wfr/wfa/forms/form-control";
import { WfaFormGroupService } from "@a-d/wfr/wfa/forms/form-group";
import { WfaFormValidatorsService } from "@a-d/wfr/wfa/forms/form-validators";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { LoggerModule } from "ngx-logger";
import { IconsModule } from "../../icons.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    IconsModule,
    LoggerModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDividerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
    ScrollingModule,
  ],
  exports: [],
  providers: [],
})
export class WfaModule { }

export default {
  WfaFormControlService,
  WfaFormGroupService,
  WfaFormValidatorsService,
  WfaModule
};

