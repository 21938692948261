import { isValidInsuranceNumber } from './is-valid-insurance-number'
import { isValidInsurantNumber } from './is-valid-insurant-number'
import { isValidNumber } from './is-valid-number'
import { isValidPhoneNumber } from './is-valid-phone-number'

export const inJsonValidators = {
  isValidNumber,
  isValidPhoneNumber,
  isValidInsurantNumber,
  isValidInsuranceNumber,
}
