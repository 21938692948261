import { isPlainObject } from './is-plain-object'

export type Predicate<T> = (value: T, path: string) => boolean
export function omitBy<T extends object>(
  obj: T,
  predicate: Predicate<any>,
  parentPath = '',
): Partial<T> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const currentPath = parentPath ? `${parentPath}.${key}` : key

    if (predicate(value, currentPath)) {
      return acc
    }

    if (isPlainObject(value)) {
      const processed = omitBy(value as object, predicate, currentPath)
      acc[key as keyof T] = processed as T[keyof T]
    } else if (Array.isArray(value)) {
      const processed = value
        .map((item, index) =>
          isPlainObject(item)
            ? omitBy(item, predicate, `${currentPath}[${index}]`)
            : predicate(item, `${currentPath}[${index}]`)
              ? undefined
              : item,
        )
        .filter((item): item is NonNullable<typeof item> => item !== undefined)

      if (processed.length > 0) {
        acc[key as keyof T] = processed as T[keyof T]
      }
    } else {
      acc[key as keyof T] = value
    }

    return acc
  }, {} as Partial<T>)
}
