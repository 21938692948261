import { UntypedFormControl } from '@angular/forms';

function sameValueConstructor<T>(
  keys: readonly string[],
  defaultValue: any
): T {
  return Object.assign(
    {},
    ...keys.map((k) => {
      return { [k]: defaultValue };
    })
  ) as T;
}

const parentKeys = [
  'sideEffects',
  'allergies',
  'chronicDiseases',
  'medications',
] as const;
export type ParentKey = typeof parentKeys[number];

const allergySelectKeys = ['allergySelect'] as const;
export type AllergySelectKey = typeof allergySelectKeys[number];
export type ControlWithOptions = ParentKey | AllergySelectKey;

const childKeys = [
  allergySelectKeys[0],
  'customSideEffect',
  'customAllergy',
  'customChronicDisease',
  'customMedication',
] as const;
export type ChildKey = typeof childKeys[number];

const goKeys = [...parentKeys, ...childKeys, 'goOther'];
type GoKey = typeof goKeys[number];
export type GoFormGroup = {
  [K in typeof goKeys[number]]: UntypedFormControl;
};

export type ControlAndValue = [GoKey, string | string[]];

export type ChildFlag = {
  [K in typeof childKeys[number]]: boolean;
};
export const newChildFlag = () =>
  sameValueConstructor<ChildFlag>(childKeys, false);

export type VisibilityCondition = {
  parent: ParentKey;
  child: ChildKey;
  parentValuesThatShowChild: string[];
  canShowChild: boolean;
};

export type RawVisibilityCondition = [ParentKey, ChildKey, number[], boolean];
