import { WfaForm } from '@arzt-direkt/wfa-definitions'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'

import { EditorEnv, initialEditorEnv } from './editor.env'

export interface EditorState extends Record<string, unknown> {
  editorEnv: EditorEnv
  form: Maybe<WfaForm>
  formIsValid?: boolean
}

export const initialEditorState: EditorState = {
  editorEnv: initialEditorEnv,
  form: undefined,
}
