import { HttpClient } from '@angular/common/http'
import { inject } from '@angular/core'
import { Router } from '@angular/router'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { WfaFormWithResponse } from '@arzt-direkt/wfa-definitions'
import {
  validateWfaForm,
  WfaFormWithResponseJoi,
} from '@arzt-direkt/wfa-definitions'
import { notNullish } from '@arzt-direkt/wfa-generic-utils'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import { validate } from '@arzt-direkt/wfa-generic-utils'
import { isWfaMessage } from '@arzt-direkt/wfa-generic-utils'
import { map, Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { getDisplayableErrorRoute } from '../../../app/user-message/displayable-errors'
import { WfaEnvService } from '../../../environments/wfa-env.service'
import { getWfaServerBaseUrl } from './../../../environments/get-wfa-url'

export function ViewerFromLinkResolver(
  route: ActivatedRouteSnapshot,
  _: RouterStateSnapshot,
): Observable<Maybe<WfaFormWithResponse>> {
  const httpClient = inject(HttpClient)
  const router = inject(Router)
  const wfaEnv = inject(WfaEnvService)
  const { responseId } = route.params

  const serverBaseUrl = getWfaServerBaseUrl(wfaEnv)

  const getFormUrl = `${serverBaseUrl}/getWfaFormWithResponse`
  const urlParams = `?responseId=${responseId}`
  const url = `${getFormUrl}${urlParams}`

  return httpClient
    .get(url)
    .pipe(
      map((res) => {
        const formWithResponse = validate<WfaFormWithResponse>(
          WfaFormWithResponseJoi,
          (res as { queryResult: unknown }).queryResult,
        )

        if (isWfaMessage(formWithResponse)) {
          wfaEnv.logger.error(
            `[ViewerFromLinkResolver]: invalid formWithResponse ${formWithResponse.message} for formWithResponse`,
            (res as { queryResult: unknown }).queryResult,
          )
          return null
        }

        const fwr = formWithResponse as unknown as WfaFormWithResponse

        const maybeForm = fwr?.form
        const form = validateWfaForm(maybeForm)
        if (isWfaMessage(form)) {
          wfaEnv.logger.error(
            `[ViewerFromLinkResolver]: invalid form. ${form.message} for form`,
            maybeForm,
          )
          return null
        }

        const formResponse = fwr?.formResponse
        const certificate = fwr?.certificate

        if (
          notNullish(formResponse?.submittedAt) &&
          formResponse.submittedAt > 0
        ) {
          router.navigate([
            `${wfaEnv.frontendUrlSubdirectory}/user-message/alreadySubmitted`,
          ])
          return null
        }

        return { form, formResponse, wfaEnv, certificate }
      }),
      catchError((error) => {
        router.navigate([
          `${wfaEnv.frontendUrlSubdirectory}/user-message/`,
          getDisplayableErrorRoute('q5706', error?.error?.message),
        ])
        wfaEnv.logger.error(`getWfaFormWithResponse: `, error?.message)
        return of(null)
      }),
    )
    .pipe(
      catchError((error) => {
        wfaEnv.logger.error('ViewerFromLinkResolver: ', error?.message)
        return of(null)
      }),
    )
}
