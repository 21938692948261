import { CheckboxResponse } from '@a-d/entities/Booking.entity';
import { OTKFieldConfig } from '@a-d/entities/Calendar.entity';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { BaseLanguage } from '../../../entities/I18N.entity';

@Injectable({
  providedIn: 'root',
})
export class CheckboxService {
  static getValue(
    control: AbstractControl,
    config: OTKFieldConfig,
    logger: NGXLogger,
    baseLanguage: BaseLanguage
  ): CheckboxResponse | null {
    if (control && config?.display === true) {
      if (![null, undefined, true, false].includes(control.value)) {
        logger.error(`Expected boolean, got ${control.value}. This is a bug.`);
      }
      const booleanValue = control.value === true;
      return {
        identifier: config.identifier,
        required: config.required,
        description: config?.description?.[baseLanguage]
          ? config.description[baseLanguage]
          : (config?.description?.['de']
            ? config.description['de']
            : ''),
        value: booleanValue,
      };
    }
    return null;
  }

  constructor() { }
}
