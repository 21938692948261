import { MatDialog } from '@angular/material/dialog'
import { SurveyCreatorModel } from 'survey-creator-core'

import { Routing } from '../interfaces'
import { customizeLocalization } from './customize-localization'
import { customizePreview } from './customize-preview'
import { customizePropertyGrid } from './property-grid-customization/customize-property-grid'
import { customizeToolbox } from './toolbox-customization/customize-toolbox'
import { customizeTranslations } from './translation-customization/customize-translations'

export function customize(
  creator: SurveyCreatorModel,
  dialog: MatDialog,
  routing: Routing,
): void {
  customizeLocalization()
  customizeTranslations()
  customizePropertyGrid(
    creator,
    routing.wfaEnvService.getInstance()?.tomedoVersion,
  )
  customizeToolbox(creator, dialog, routing)
  customizePreview(creator, dialog)
}
