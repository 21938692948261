import Joi from 'joi'

export const displayOptions = ['editable', 'readonly', 'summary'] as const

export const DisplayOptionJoi = Joi.string()
  .valid(...displayOptions)
  .description(
    `Display options for the viewer.
 * - editable: Form can be edited by the patient.
 * - readonly: Form cannot be edited.
 * - summary: Similar to readonly, but all questions are displayed on a single page.
`,
  )
  .meta({
    className: 'DisplayOption',
  })
