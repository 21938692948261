import { UntypedFormControl, ValidationErrors } from "@angular/forms";
/**
 * @description
 * Dynamic 'required' validator.
 *
 * If a control with `Validators.required` is dynamically added
 * to a formGroup, then it is automatically marked as 'INVALID'
 * even if it is pristine and untouched. Then:
 *  - logically, it is correct (as an input is expected);
 *  - visually, it is incorrect (the control is marked red,
 * but the user has done nothing wrong yet).
 *
 * This validator replaces `Validators.required`.
 * If the control is pristine, untouched, and has an empty value,
 * it is marked 'VALID', otherwise 'INVALID'. Then:
 *  - logically, it is incorrect (as an input is still expected);
 *  - vilually, it is correct (the control is not marked red).
 *
 * To check for logical correctness, use the `isDynamicallyValid`
 * function.
 */
function dynamicallyRequired(control: UntypedFormControl): ValidationErrors {
  if (control.pristine === true && control.untouched === true) return null;
  if ([null, undefined, ""].includes(control.value))
    return { dynamicallyRequired: true };

  return null;
}

const WfaFormValidatorsService = { dynamicallyRequired };
export { WfaFormValidatorsService, dynamicallyRequired };
