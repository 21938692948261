import Joi from 'joi'

import { WfaFormStatus } from './../interfaces-autogen-from-joi/wfa-form-status'

export const wfaFormStatusDict: Record<string, WfaFormStatus> = {
  live: 'live',
  draft: 'draft',
  archived: 'archived',
  disabled: 'disabled',
} as const

export const wfaFormStatuses = Object.values(wfaFormStatusDict)
export const wfaFormStatusDefault = wfaFormStatusDict['archived']

export const WfaFormStatusJoi = Joi.string()
  .valid(...wfaFormStatuses)
  .default(wfaFormStatusDefault)
  .description(
    `Status of a WfaForm determines what can doctors and patients do with it.
     live : Can be used to create links. Can not be edited
     draft : Can not be used to create links. Can be edited.
     archived : For old / unused forms. Can not be used to create links. Can not be edited.
     disabled : For cancelled accounts. Is completely hidden from view.`,
  )
  .meta({
    className: 'WfaFormStatus',
  })
  .example('live')
