import { dynamicallyRequired } from "@a-d/wfr/wfa/forms/form-validators";
import { Validators } from "@angular/forms";
import { ADValidators } from "@a-d/forms/validators/a-d-validators";

const forbiddenCharactersName: string = `±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=,0123456789$`
const phoneNumberPattern = Validators.pattern('[+]?[0-9 ]*');

const nameValidators = [
  dynamicallyRequired,
  ADValidators.noLeadingOrTrailingWhitespace,
  ADValidators.doNotAllowCharacters(forbiddenCharactersName),
  ADValidators.noSpecialCharacters,
  Validators.maxLength(40)
];
const defaultValidators = (charLimit: number = 200) => [
  Validators.maxLength(charLimit),
  ADValidators.noSpecialCharacters
];
const phoneValidator = [
  phoneNumberPattern,
  Validators.maxLength(20)
];
const birthDateStart = new Date(1990, 0, 1);
const stdCharLimit: number = 200;

export default {
  nameValidators,
  defaultValidators,
  phoneValidator,
  birthDateStart,
  stdCharLimit,
};
