import { Component, OnInit } from '@angular/core';
import { LegalDataFiles } from '../entities/Legal.entity';
import { LegalService } from '../legal/legal.service';
import { TranslateModule } from '@ngx-translate/core';
import { FullsizeInfoComponent } from './fullsize-info.component';

@Component({
    selector: 'app-loading-error-fullsize-info',
    host: { 'class': 'o-gv' },
    template: `
      <app-fullsize-info [icon]="['fal', 'signal-slash']" state="error" title="Verbindungsfehler">
    
        {{'LOADING-ERROR-FULLSIZE-INFO.ERR-MSG-1' | translate}} <a (click)="reload()">{{'LOADING-ERROR-FULLSIZE-INFO.ERR-MSG-2' | translate}}</a>.
        {{'LOADING-ERROR-FULLSIZE-INFO.ERR-MSG-3' | translate}} 
        <a href="https://arzt-direkt.de/handbuch/problemloesungen/#Loeschen_des_Browser-Seiten-Cache" target="_blank">{{'LOADING-ERROR-FULLSIZE-INFO.ERR-MSG-4' | translate}}</a>
         {{'LOADING-ERROR-FULLSIZE-INFO.ERR-MSG-5' | translate}} <a (click)="legalService.openDialog(LegalFiles.Support)">{{'LOADING-ERROR-FULLSIZE-INFO.ERR-MSG-6' | translate}}</a>.
      </app-fullsize-info>
      `,
    standalone: true,
    imports: [FullsizeInfoComponent, TranslateModule],
})
export class LoadingErrorFullsizeInfoComponent implements OnInit {

  public LegalFiles = LegalDataFiles


  constructor(
    public legalService: LegalService,
  ) { }

  ngOnInit() { }


  /**
   * Reload-Helpers
   */
  public reload() {
    window.location.reload()
  }

}
