import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-name-title-field',
  host: { 'class': 'c-form__row__item' },
  template: `
  <mat-form-field attr.data-selenium-id="field-titel" [formGroup]="formGroup" style="width: 100%" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <mat-select [formControlName]="name" [required]="required" >
      <mat-option *ngIf="!required" data-selenium-id="option-kein-titel" [value]=null><em>{{'USER-DATA-ARZT.none' | translate}}</em></mat-option>
      <mat-option *ngFor="let item of options; let i = index" [value]="item" attr.data-selenium-id="option-titel-{{i}}">{{ item }}</mat-option>
    </mat-select>

  </mat-form-field>
  `,
  standalone: true,
  imports: [MatFormFieldModule, FormsModule, ReactiveFormsModule, MatSelectModule, NgIf, MatOptionModule, NgFor, TranslateModule]
})
export class NameTitleFieldComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup
  @Input() name: string
  @Input() label: string
  @Input() required: boolean
  @Input() options = ['Dr.', 'Prof.', 'Prof. Dr.', 'Priv. Doz.']

  public get title(): AbstractControl { return this.formGroup.get(this.name) }


  constructor() { }

  ngOnInit() {
    if (this.required) {
      this.title.setValidators([Validators.required])
      this.title.updateValueAndValidity()
    }
  }

}
