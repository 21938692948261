import { i18n } from '@arzt-direkt/wfa-definitions'
import { CoreLocale } from '@arzt-direkt/wfa-definitions'
import { TranslateService } from '@ngx-translate/core'

const defaultLang = 'de'

export function translateMatDialogContent(
  translate: TranslateService,
  locale?: CoreLocale,
) {
  const lang = locale ?? defaultLang
  const translation = i18n[lang] ?? i18n[defaultLang]
  translate.currentLang = lang
  translate.setTranslation(lang, translation, true)
}
