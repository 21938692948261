import { HttpClient } from '@angular/common/http'
import { Injectable } from "@angular/core"
import { MatBottomSheetConfig } from '@angular/material/bottom-sheet'
import { MatDialogRef } from "@angular/material/dialog"
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router"
import { environment } from "../../environments/environment"
import { CookiesService, EssentialCookieNames } from "./cookies.service"
import { DsgvoComponent } from "./dsgvo.component"
import { DsgvoService } from "./dsgvo.service"

@Injectable({
  providedIn: 'root'
})
export class DsgvoGuard implements CanActivate {

  public dsvgoDialogRef: MatDialogRef<DsgvoComponent>

  constructor(
    private dsgvoService: DsgvoService,
    private cookiesService: CookiesService,
    private httpClient: HttpClient
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const isDemoMode = environment.demoMode
    if (!isDemoMode && this.cookiesService.get(EssentialCookieNames.areDsgvoPreferencesSubmitted) !== 'true') {
      if (window.location !== window.parent.location) {
        this.httpClient.post(`${environment.url}/api/instance/get-hide-cookie-dialog`, { identifier: route.params.instanceIdentifier })
          .subscribe({
            next: (response: any) => {
              if (!response || !response.hideCookieDialog)
                this.checkOpenCookieDialog()
            }
          })
      }
      else {
        this.checkOpenCookieDialog()
      }

      return true
    }
  }

  checkOpenCookieDialog() {
    if (this.cookiesService.get(EssentialCookieNames.areDsgvoPreferencesSubmitted) !== 'true') {
      this.openDsgvoBottomSheet()
    }
  }

  openDsgvoBottomSheet(): void {
    const matBottomSheetConfig: MatBottomSheetConfig = {
      panelClass: 'c-bottom-sheet',
      backdropClass: 'c-bottom-sheet-backdrop',
      autoFocus: true,
      closeOnNavigation: false,
      disableClose: true,
      hasBackdrop: true,
      restoreFocus: true
    }
    this.dsgvoService._dsgvoBottomSheet.open(DsgvoComponent, matBottomSheetConfig);
  }
}
