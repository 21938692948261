import { ActiveInstance } from "@a-d/entities/ActiveInstance.entity";
import {
  FieldSettings,
  OTKFieldConfig as OtkFieldConfig
} from "@a-d/entities/Calendar.entity";
import { Instance } from "@a-d/entities/Instance.entity";
import { UntypedFormGroup, ValidatorFn } from "@angular/forms";
import { keyBy } from "./construct-configs";
import {
  createDefaultPersonalFormGroup,
  PersonalFormGroup
} from "./default-personal-form-group";
import { MaybeBirthDateControlNeeded } from "./entities";
import {
  addBasicFieldControls,
  maybeAddAddressFormControl,
  maybeAddFormControl
} from "./maybe-add-control";

import vld from "./validators";
import { ADValidators } from "@a-d/forms/validators/a-d-validators";

export function createPersonalFormGroup(
  activeInstance: ActiveInstance | Instance,
  basicFields: Record<string, OtkFieldConfig>,
  customFields: OtkFieldConfig[],
  maybeBirthDateControlNeeded?: MaybeBirthDateControlNeeded
): PersonalFormGroup {
  const formGroup = createDefaultPersonalFormGroup();
  addBasicFieldControls(
    formGroup,
    basicFields,
    activeInstance.contact.country,
    maybeBirthDateControlNeeded
  );

  customFields.forEach((customField) => {
    const charLimit = customField?.limit;
    maybeAddFormControl(
      customField,
      formGroup,
      [ADValidators.noLeadingOrTrailingWhitespace, ...vld.defaultValidators(charLimit)]
    );
  });

  return formGroup;
}

export function populateContactFormGroup(
  activeInstance: ActiveInstance,
  formGroup: UntypedFormGroup,
  fieldSettings: FieldSettings
): { basic: Record<string, OtkFieldConfig>; custom: OtkFieldConfig[] } {
  const basic = keyBy(fieldSettings.basic, "identifier");
  const custom =
    fieldSettings.custom && fieldSettings.custom.length > 0
      ? fieldSettings.custom
      : [];

  const maybeControls: Array<[string, ValidatorFn[]]> = [
    ["info", vld.defaultValidators(basic.info?.limit)],
    ["phone", vld.phoneValidator],
    ["customerId", vld.defaultValidators(basic.customerId?.limit)],
    ["issue", vld.defaultValidators(basic.issue?.limit)],
  ];

  maybeControls.map(([key, validators]) => {
    maybeAddFormControl(basic[key], formGroup, validators);
  });

  maybeAddAddressFormControl(
    basic.address,
    formGroup,
    activeInstance.contact.country
  );

  fieldSettings.custom.forEach((customField) => {
    const charLimit = customField?.limit;
    maybeAddFormControl(
      customField,
      formGroup,
      vld.defaultValidators(charLimit)
    );
  });
  return { basic, custom };
}
