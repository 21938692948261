import Joi from 'joi'

import { DescriptionLocation } from './../../../interfaces-autogen-from-joi/wfa-question/base-question-props/description-location'

export const descriptionLocationDict: Record<string, DescriptionLocation> = {
  default: 'default',
  underTitle: 'underTitle',
  underInput: 'underInput',
} as const

export const descriptionLocations = Object.values(descriptionLocationDict)

export const DescriptionLocationJoi = Joi.string()
  .valid(...descriptionLocations)
  .meta({
    className: 'DescriptionLocation',
  })
  .example('default')
