/**
 * Decodes malformed array responses from AD-App multiselect/tagbox components.
 *
 * Handles string-encoded arrays like:
 * Input:  "['option-1', 'option-2']"
 * Output: ['option-1', 'option-2']
 *
 * @throws Logs warning if parsing fails
 * @returns Original input if not parseable as array
 */
export function decodeResponseIfAppProblemAdi3470<T>(
  x: string,
): string | string[]
export function decodeResponseIfAppProblemAdi3470<T>(x: T): T
export function decodeResponseIfAppProblemAdi3470<T>(
  x: T | string,
): T | string | string[] {
  try {
    if (typeof x !== 'string') return x
    if (!x.startsWith(`['`) && !x.startsWith(`["`)) return x
    if (!x.endsWith(`']`) && !x.endsWith(`"]`)) return x
    const withoutSingleQuotes = x.replace(/'/g, '"')
    const response = JSON.parse(withoutSingleQuotes) as unknown as string[]
    if (Array.isArray(response) && response.length > 0) return response
    return x
  } catch (e) {
    console.warn(
      [
        `[decodeResponseIfAppProblemAdi3470]:`,
        `Unexpected input ${x}. If this value can occur as a tagbox or multiselect`,
        `answer, this function needs to be fixed.`,
      ].join(''),
    )
    return x
  }
}
