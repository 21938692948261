import { InstanceService } from './../../instance/instance.service'
import { NgModule, inject } from '@angular/core'
import { NGXLogger } from 'ngx-logger'
import { CryptoWorkerService } from '../../crypto/crypto.worker.service'
import {
  AdisWfaEnvRoutingModule,
  DemoAdisWfaEnvRoutingModule,
} from './adis-wfa-env-routing.module'
import { AdisWfaEnvService } from './adis-wfa-env.service'
import { NotificationService, WfaEnvService } from './wfa-for-ad-frontend-link'

export const AdisWfaEnvServiceProvider = {
  provide: WfaEnvService,
  useFactory: () => {
    const instanceService = inject(InstanceService)
    const logger = inject(NGXLogger)
    const notification = inject(NotificationService)
    const cryptoWorkerService = inject(CryptoWorkerService)
    logger.debug('[adis-wfa]: injecting AdisWfaEnvService.')
    return new AdisWfaEnvService(
      logger,
      notification,
      cryptoWorkerService,
      instanceService,
    )
  },
}

/**
 * AdisWfaEnv Service Provider Module
 *
 * This module provides the AdisWfaEnvServiceProvider without routing.
 * It should be used whenever wfa components are used not on the top
 * level of the routing, but when inside arzt-direkt components,
 * like for instance/.../routes, as, for example, in
 * instance-settings-forms.component.ts.
 *
 * @module AdisWfaEnvSPModule
 */
@NgModule({
  providers: [AdisWfaEnvServiceProvider],
})
export class AdisWfaEnvSPModule {}

/**
 * AdisWfaEnv Module
 *
 * This module provides the AdisWfaEnvService and Adis Routing.
 * It should be used whenever wfa components are used on the top
 * level of the routing, like for webforms/.../routes, as in
 * app-routing.module.ts.
 *
 * @module AdisWfaEnvModule
 */
@NgModule({
  imports: [AdisWfaEnvRoutingModule],
})
export class AdisWfaEnvModule {}

@NgModule({
  imports: [DemoAdisWfaEnvRoutingModule],
  providers: [AdisWfaEnvServiceProvider],
})
export class DemoAdisWfaEnvModule {}
