export const surveyjsResponseJoiExample = {
  $wfaPatientVorname$: 'Max',
  $wfaPatientNachname$: 'Mustermann',
  $wfaPatientTitel$: 'Dr.',
  $wfaPatientGeschlecht$: 'W',
  $wfaPatientGeburtstag$: '2023-10-04',
  $wfaPatientEmail$: 'a@d.te',
  $wfaPatientTelefonPrivat$: '123',
  $wfaPatientTelefonMobil$: '456',
  $wfaPatientStrasseHausnummer$: 'Musterstr. 33',
  $wfaPatientPlz$: '10700',
  $wfaPatientOrt$: 'Berlin',
  $wfaPatientLand$: 'DEU',
  $wfaPatientKrankenkasse$: 'TK',
  $wfaPatientVersicherungsnummer$: '101575519',
}
