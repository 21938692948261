import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { DayOfWeek } from "@a-d/entities/Calendar.entity"
import { BaseLanguage } from '@a-d/entities/I18N.entity'
import { Dialect } from "@a-d/entities/Instance.entity"
import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common"
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core'
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms'
import { MatButtonModule } from "@angular/material/button"
import { MatButtonToggleModule } from "@angular/material/button-toggle"
import { DateAdapter, MatOptionModule } from '@angular/material/core'
import { MatCalendar, MatCalendarCellCssClasses, MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatSelectModule } from "@angular/material/select"
import { environment } from "@env/environment"
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import dayjs from 'dayjs'
import { Observable, Subscriber, Subscription, merge, of } from 'rxjs'
import { delay, mergeMap } from 'rxjs/operators'
import { AppointmentType, Betriebsstaette, BookingOpening, BookingStep, I18NString, OtkDoctor, TomKalenderDaten } from '../../entities/Booking.entity'
import { LanguageService } from '../../i18n/language.service'
import { BookingService } from '../booking.service'
import { CustomDateAdapter } from './calendar-custom-date-adapter/calendar-custom-date-adapter.component'
import { CalendarHeaderComponent } from './calendar-header/calendar-header.component'

enum SlotStyle { onlyDoctor, onlyBs, both, nothing }
const LEFTALIGNSLOTS = 20
const LEFTALIGNSLOTS_DOCTORS = 18

@UntilDestroy()
@Component({
  selector: 'app-booking-date',
  templateUrl: './booking-date.component.html',
  styleUrls: ['./booking-date.component.scss', '../../../styles.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, MatDatepickerModule, MatIconModule, MatFormFieldModule, MatSelectModule, MatOptionModule, NgFor, MatButtonModule, MatInputModule, MatButtonToggleModule, NgSwitch, NgSwitchCase, NgSwitchDefault, TranslateModule]
})
export class BookingDateComponent implements OnInit, AfterViewInit {
  @ViewChild('calendar') calendar: MatCalendar<Date> // missing official documentation, see https://onthecode.co.uk/angular-material-calendar-component/
  @ViewChild('form', { static: true }) form: ElementRef
  openingsFiltered: BookingOpening[] = [] // sorted by date
  // onlyOneVisibleDoctor: if after doctor filtering (after selecting the day) there is only one
  // visible doctor, don't show "beliebig" or the "x" button, disable doctor selection and select that one doctor.
  onlyOneVisibleDoctor: boolean
  subsriptionInitBookingDate: Subscription
  subscriptionFilterDay: Subscription
  subscriptionFilterDoctor: Subscription
  subscriptionCalendarSelection: Subscription
  subscriptionMagicFill: Subscription
  calendarMinDate: Date
  calendarMaxDate: Date
  calendarSelectedDate: Date
  calendarMonthArrowButtons // contains references to the arrow buttons for switching the month in the desktop version of the calendar
  calendarHeaderComponent = CalendarHeaderComponent
  dayError: string
  showDoctors: boolean // should probably actually be called: showDoctorsInOpeningStrings
  allowDoctorChoice: boolean // should probably actually be called: showDoctorMenu
  doctorPhoto: string
  displayLimit: number
  urgentAppointment: boolean
  appointmentTypeName: I18NString
  slotStyle = SlotStyle
  usedSlotStyle = SlotStyle.nothing
  maxBsStringSize = 0
  leftAlignSlots: boolean = false
  leftAlignSlotsDoctors: boolean = false
  handlerName: string
  forerunTime: number = 0

  bsFilterActive: boolean = false
  bsSelected: Betriebsstaette = null

  openingBsStrings: string[] = [] // bs infos for the openings

  public failureAssetUrl = `${environment.url}/assets/otk/booking/failure.svg`
  public errorTitle = this.translateService.instant('OTK.BOOKING-DATE.TITLE-ERROR')

  get bookingDateFormGroup(): UntypedFormGroup { return this.bookingService.bookingDateFormGroup }
  get doctor(): AbstractControl { return this.bookingDateFormGroup.get('doctor') }
  get day(): AbstractControl { return this.bookingDateFormGroup.get('day') }
  get opening(): AbstractControl { return this.bookingDateFormGroup.get('opening') }
  /** doctorsVisible && doctorsInvisible:
  * doctors without a Nutzer get filtered out in backend.
  * the doctors received in frontend are divided into doctorsVisible and doctorsInvisible.
  * doctorsVisible are doctors with titel, vorname or nachname.
  * these doctors should be displayed in the doctors dropdown menu (if allowDoctorChoice),
  * be shown in the opening strings (if showDoctors) and have their openings displayed to the user.
  * doctorsInvisible are doctors with titel===vorname===nachname===''.
  * these doctors should not be displayed in the doctors dropdown menu or in the opening strings,
  * (because there is nothing to display) but their openings should still be displayed to the user.
  * There is no "invisible doctors" filter - The user can only filter according to visible doctors,
  * such that when filtering according to any doctor, all openings offered only by invisible doctors
  * will be filtered out
  */

  get doctorsVisible(): OtkDoctor[] {
    const doctorsSortedByLastName = this.bookingService.doctorsVisible
      .sort((a, b) => a.fullName.split(" ").slice(-1)[0]
        .localeCompare(b.fullName.split(" ").slice(-1)[0]))
    return doctorsSortedByLastName
  }
  get doctorsInvisible(): OtkDoctor[] { return this.bookingService.doctorsInvisible }
  get openings(): BookingOpening[] { return this.bookingService.openings }
  // openingsExist:
  // it is possible to select appointment types with no openings, either if
  // 1. appointmentType.selectableIfNoOpenings === true
  // 2. the appointment type was reached via parametrization
  // in this case the appropriate message should be shown to the user
  openingsExist: boolean = true // start with true otherwise the error message shows for a moment in the beginning
  textNoOpenings: I18NString = { de: '', en: '', fr: '', it: '', es: '' }// message to show if !openingsExist
  dayString: string
  openingsDisplayStringsSubscription: Subscription
  baseLanguageSubscription: Subscription
  baseLanguage: BaseLanguage

  constructor(
    private adLoggerService: AdLoggerService,
    public bookingService: BookingService,
    private cd: ChangeDetectorRef,
    private renderer2: Renderer2,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private calendarCustomDateAdapter: DateAdapter<Date>,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.openingsDisplayStringsSubscribe()
    this.initBookingDateSubscribe()
    this.filterSubscribe()
    this.baseLanguageSubscribe()
    this.setCalendarDayOfWeekAndLang()
    this.setScrollableNativeElement()
  }

  // updates the language of the name of the month in mat-datepicker and mat-calendar
  // and the language of the appointment type name
  baseLanguageSubscribe() {
    if (this.baseLanguageSubscription)
      this.baseLanguageSubscription.unsubscribe()
    this.baseLanguageSubscription = merge(
      this.languageService.baseLanguageChangeSubject$, // for language button clicks
      this.languageService.languageChangeSubject$) // for appointment type changes and their associated dialect changes
      .pipe(untilDestroyed(this)
      ).subscribe(() => {
        const appointmentType: AppointmentType = this.bookingService.appointmentType.value as AppointmentType
        this.baseLanguage = this.languageService.activeBaseLang
        if (appointmentType) {
          this.appointmentTypeName = appointmentType.name
          this.handlerName = this.translateService.instant('OTK.HANDLER-SINGULAR')
        }
        this.calendarCustomDateAdapter.setLocale(this.languageService.activeBaseLang);
        this.translateErrorMessage(this.baseLanguage)
      }
      )
  }

  // sets the language and first day of the week of the calendar.
  // I couldn't get this to work as an internal function of calendar-custom-date-adapter
  // because it requires usage of the language service. For that I need to inject it into the constructor
  // and I couldn't, not even after getting the "super" call right.
  setCalendarDayOfWeekAndLang() {
    (this.calendarCustomDateAdapter as CustomDateAdapter).firstDayOfWeek = this.bookingService.otkUser?.firstDayOfWeek
      ? this.bookingService.otkUser.firstDayOfWeek
      : DayOfWeek.MONDAY
    this.calendarCustomDateAdapter.setLocale(this.languageService.activeBaseLang)
  }

  setScrollableNativeElement() {
    this.bookingService.scrollableNativeElement[BookingStep.bookingDate] = this.form.nativeElement
  }

  openingsDisplayStringsSubscribe() {
    if (this.openingsDisplayStringsSubscription)
      this.openingsDisplayStringsSubscription.unsubscribe()
    this.openingsDisplayStringsSubscription = merge(
      this.opening.valueChanges,
      this.translateService.onLangChange,
    )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.bookingService.updateMiniSummaryStrings()
      })
  }

  ngAfterViewInit() {
    this.setCalendar(true)
    //trigger recalc header height in case no booking start booking start is skipped
    this.bookingService.recalcHeaderHeight$.next(true)
  }

  /**
   * called only on desktop mode in 2 cases:
   * on ngAfterViewInit and whenever a new appointment type is selected.
   * 1. if running for the first time, set listers for the calendar month arrow buttons,
   * otherwise they won't trigger the month selection change function
   * 2. uses some dirty hack of setting calendarMinDate, setting the dateFilter
   * and setting calendarMinDate again in order to force re-rendering of the calendar
   * otherwise dateFilter does not render correctly until the month changes.
   */
  setCalendar(cd: boolean = false) {
    if (!this.calendarMonthArrowButtons) {
      this.calendarMonthArrowButtons = document
        .querySelectorAll('.mat-calendar-previous-button, .mat-calendar-next-button')
      Array.from(this.calendarMonthArrowButtons).forEach(button => {
        this.renderer2.listen(button, 'click', () =>
          this.onCalendarSelectMonth(this.calendar.activeDate)
        )
      })
    }
    this.calendarMinDate = new Date()
    this.calendarMaxDate = dayjs(this.calendarMinDate).add(this.bookingService.MAXIMAL_ALLOWED_BOOKING_IN_ADVANCE_DAYS, 'days').toDate()
    setTimeout(() => {
      this.calendar.dateFilter = this.dateFilter
      this.calendar.updateTodaysDate()
      if (cd) this.cd.detectChanges()
    }
      , 1000)
  }
  /**
    * dirty solution alert: "calendarMinDate" is set twice with a "delay of 0"
    * in order to force re - rendering of the calendar element, which also calls
    * this.getCalendarDateClass() again.
    * Dont need this anymore probably
    */
  rerenderCalendar() {
    this.calendarMinDate = new Date()
    setTimeout(() => this.calendarMinDate = null, 0)
  }

  /**
   * called when a date is selected using the desktop version of the calendar.
   * Note that this is not called by default when the patient clicks on the arrows,
   * see https://onthecode.co.uk/angular-material-calendar-component/
   */
  onCalendarSelectDate(event) {
    this.day.setValue(event)
    this.bookingService.updateMiniSummaryStrings()
    this.setDayString()
    //this.rerenderCalendar()
  }

  /**
   * "Freie Termine für den DD.MM.YYYY"
   */
  setDayString() {
    this.dayString = this.day.value
      ? dayjs((this.day.value as Date).toISOString()).format("DD.MM.YYYY")
      : undefined
  }

  /**
   * called when the month is changed using the desktop version of the calendar.
   * Reset the day, to prevent the case where the day from the previous month is still selected.
   */
  onCalendarSelectMonth(event) {
    this.day.reset()
    this.opening.reset()
    this.bookingService.updateMiniSummaryStrings()
    this.setDayString()
    //this.rerenderCalendar()
    this.cd.detectChanges() // otherwise the openings won't disappear
  }

  /**
   * initially as well as upon changes to booking type (the previous step),
   * get the doctors and openings for that booking type,
   * display the booking type name, select the first date,
   * select the doctor if theres only one, and perform magic fill
   * if requested.
   * Note that it is important to end subscriptions before setting them again
   * otherwise they will be set double here.
   */
  initBookingDateSubscribe() {
    if (this.subsriptionInitBookingDate)
      this.subsriptionInitBookingDate.unsubscribe()
    this.subsriptionInitBookingDate =
      this.bookingService.initBookingDate$
        .pipe(
          untilDestroyed(this),
          mergeMap(() => {
            this.onlyOneVisibleDoctor = Object.keys(this.bookingService.doctorsVisible).length === 1
            this.displayLimit = (this.bookingService.appointmentType.value as AppointmentType).displayLimit
            this.urgentAppointment = (this.bookingService.appointmentType.value as AppointmentType).urgentAppointment
            this.showDoctors = this.bookingService.showDoctors
            this.bsFilterActive = !!this.bookingService.otkUser?.betriebsstaettenFilter
            this.bsSelected = this.bookingService.bs.value !== this.bookingService.bsAll ? this.bookingService.bs.value : null
            this.forerunTime = (this.bookingService.appointmentType.value as AppointmentType)?.forerunTime || 0
            this.usedSlotStyle = this.chooseSlotStyle(this.showDoctors, this.bsFilterActive && !this.bsSelected)
            this.allowDoctorChoice = (this.bookingService.appointmentType.value as AppointmentType).allowDoctorChoice
            this.openingsExist = Object.keys(this.openings).length !== 0
            const baseLanguage: BaseLanguage = this.languageService.activeBaseLang
            if (!this.openingsExist) {
              this.textNoOpenings = (this.bookingService.appointmentType.value as AppointmentType).textNoOpenings
                ? (this.bookingService.appointmentType.value as AppointmentType).textNoOpenings
                : null
              this.translateErrorMessage(baseLanguage);
            }
            const appointmentType: AppointmentType = this.bookingService.appointmentType.value as AppointmentType
            if (appointmentType) {
              this.appointmentTypeName = appointmentType.name
              const dialectAppointmentType: Dialect = appointmentType.dialect
                ? appointmentType.dialect
                : this.bookingService.dialectDefault
              this.languageService.setActiveDialect(dialectAppointmentType)
              this.handlerName = this.translateService.instant('OTK.HANDLER-SINGULAR')
            }
            return of(null)
          }),
          mergeMap(() => this.openingsExist ? this.selectFirstDay() : of(null)),
          mergeMap(() => this.magicFillSubscribe()),
        ).subscribe(() => {
          this.setCalendar()
          this.cd.detectChanges()
          this.bookingDateFormGroup.updateValueAndValidity()
        })
  }

  public chooseSlotStyle(showDocs, showBs) {
    if (showDocs && showBs) return SlotStyle.both
    if (showDocs) return SlotStyle.onlyDoctor
    if (showBs) return SlotStyle.onlyBs
    return SlotStyle.nothing
  }

  private translateErrorMessage(baseLanguage: BaseLanguage) {
    const baseTranslation = this.textNoOpenings[this.languageService.DEFAULT_BASE_LANG];
    if (!this.textNoOpenings) {
      this.errorTitle = this.translateService.instant('OTK.BOOKING-DATE.TITLE-ERROR');
      return;
    }
    switch (this.textNoOpenings[baseLanguage]) {
      case '':
      case this.bookingService.I18NString_MISSING_VALUE:
        if (baseTranslation && baseTranslation !== this.bookingService.I18NString_MISSING_VALUE) {
          this.errorTitle = baseTranslation;
          return
        }
        this.errorTitle = this.translateService.instant('OTK.BOOKING-DATE.TITLE-ERROR');
        return
      default:
        this.errorTitle = this.textNoOpenings[baseLanguage];
    }
  }

  /**
   * filter doctor, day and opening as follows:
   * doctor filtering:
   *  already "pre-filtered" twice:
   *    1. In backend to include only active doctors of the instance offering the selected appointment type
   *    2. In frontend after the request finishes to only contain those with names != '' (doctorsVisible).
   *  other than that, the doctor list stays constant. The doctors list does not only contain doctors offering
   *    appointments on the selected day but it should contain all doctors offering appointments.
   *    Days where the selected doctor does not offer appointments should then be grayed out.
   * Day filtering:
   *  initially autoselected to be the first avaialble day elsewhere.
   *  !doctor => filter to days for which any visible or invisible doctor has at least one opening.
   *  doctor => filter to days for which the selected doctor has at least one opening.
   *  The day filtering does not happen upon doctor selection but upon
   *    datepicker opening.
   *  The day is not only filtered but also the first valid day
   *    is then automatically selected.
   * opening:
   *  !day => filter to an empty list.
   *  day && !doctor => filter to openings available for that day.
   *  day && doctor  => filter to openings available for that day
   *    offered by a doctors list containing that doctor.
   *
   * As mentioned, the days are filtered in a separate function (dateFilter()),
   * such that the filter flow in this function is simplified to:
   * day selected =>
   *  filterOpenings
   * doctor reset/selected =>
   *  filterOpenings
   *  !openingsFiltered => selectFirstDay
   *    (=>filterOpenings due to #1)
   *
   * note that "doctor" only triggers a "valueChanges" when it is manually selected by the user, because we use
   * this everywhere else:
   * this.bookingService.bookingDateFormGroup.patchValue({ doctor:newValue },{ onlySelf: true, emitEvent: false })}
   * See https://stackoverflow.com/questions/45241103/patchvalue-with-emitevent-false-triggers-valuechanges-on-angular-4-formgrou
   */
  filterSubscribe() {
    if (this.subscriptionFilterDay)
      this.subscriptionFilterDay.unsubscribe()
    this.subscriptionFilterDay =
      // day selected => filter the openings
      this.day.valueChanges
        .pipe(untilDestroyed(this),
          mergeMap(() => this.filterOpenings()),
          mergeMap(() => {
            this.setDayString()
            this.setDayError()
            return of(null)
          })
        ).subscribe()
    if (this.day.value) // because the first call is with the value ''
      this.setDayString()
    //* doctor selected =>
    //  * filterOpenings
    //  * !openingsFiltered => selectFirstDay
    //  * (=> filterOpenings due to #1)
    if (this.subscriptionFilterDoctor)
      this.subscriptionFilterDoctor.unsubscribe()
    this.subscriptionFilterDoctor =
      this.doctor.valueChanges
        .pipe(untilDestroyed(this),
          mergeMap(() => {
            this.doctorPhoto = (this.doctor.value as OtkDoctor)?.photo
            return of(null)
          }),
          mergeMap(() =>
            this.filterOpenings()
          ),
          mergeMap(() => {
            if (Object.keys(this.openingsFiltered).length === 0)
              return this.selectFirstDay()
            return of(null)
          }),
          mergeMap(() => {
            this.calendar.updateTodaysDate()
            return of(null)
          })
        ).subscribe()
  }

  setDayError() {
    if (this.day.touched && this.day.errors?.dateInvalid)
      this.dayError = this.translateService.instant('OTK.STEPPER.DATE-FORMAT-HINT')
    else if (this.day.errors?.dateIsInThePast)
      this.dayError = this.translateService.instant('OTK.STEPPER.DATE-FUTURE-HINT')
    else if (this.day.errors?.matDatepickerFilter)
      this.dayError = this.translateService.instant('OTK.STEPPER.DATE-AVAILABILITY-HINT')
    else if (this.day.errors?.getDateMoreThanGivenDaysInTheFutureValidator)
      this.dayError = this.translateService.instant('OTK.BOOKING-DATE.ERROR.DAY.TOO-FAR-IN-THE-FUTURE')
    else
      this.dayError = ''
  }

  // perform magic fill (fill the fields with preset values)
  magicFillSubscribe(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      if (this.subscriptionMagicFill)
        this.subscriptionMagicFill.unsubscribe()
      this.subscriptionMagicFill = this.bookingService.magicFill$
        .pipe(untilDestroyed(this))
        .subscribe((magicFill: string) => magicFill === BookingStep.bookingDate ? this.magicFill() : null)
      subscriber.next()
      subscriber.complete()
    })
  }

  /**
   * selects the first day for which there are openings
   */
  selectFirstDay(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      const firstOpening: BookingOpening = this.openings
        .find((opening: BookingOpening) =>
          this.dateFilter(opening.date))
      if (firstOpening === undefined) {
        this.adLoggerService.error("selectFirstDay: selected doctor has no openings")
        this.bookingService.bookingDateFormGroup.patchValue(
          { doctor: undefined },
          { onlySelf: true, emitEvent: false })
        this.day.reset()
        this.setDayString()
        subscriber.next(false)
        subscriber.complete()
        return
      }
      const firstDay: Date = dayjs(firstOpening.date).startOf("day").toDate() // convert to start of day in date format
      this.calendarSelectedDate = firstDay
      this.calendar.activeDate = firstDay
      this.cd.detectChanges()
      this.day.setValue(firstDay)
      this.setDayString()
      subscriber.next(true)
      subscriber.complete()
    })
  }



  /**
   * Filters the openings according to what is selected by the user:
   *  !day, !doctor ==> none.
   *  !day, doctor ==> none.
   *  day, !doctor ==> openings available for that day.
   *  day, doctor ==> openings available for that day offered
   *    by a doctors list containing that doctor.
   */
  filterOpenings(): Observable<boolean> {
    return new Observable((subscriber: Subscriber<any>) => {
      this.openingsFiltered = []
      // for some reason the doctors.valueChange activates once the
      // doctors list is populated or so and this calls this function before
      // openings is defined
      // if no day is selected, filter to an empty list.
      if (!this.day.value) {
        this.opening.reset()
        this.bookingService.updateMiniSummaryStrings()
        return
      }
      // a day is selected. Filter to openings available for that day.
      this.openingsFiltered = this.openings
        .filter((bookingOpening: BookingOpening) =>
          dayjs(bookingOpening.date).add(this.forerunTime, 'minute').isSame(dayjs(this.day.value), 'day'))
      // if a doctor was selected filter further to openings offered by a doctors list containing that doctor.
      if (this.doctor.value)
        this.openingsFiltered = this.openingsFiltered
          .filter((bookingOpening: BookingOpening) =>
            // booking offered by the selected doctor
            bookingOpening.kdSet
              .map((tomKalendarDaten: TomKalenderDaten) =>
                tomKalendarDaten.kid)
              .some(x => (this.doctor.value as OtkDoctor).kids.includes(x))
          )
      // if this.displayLimit is defined and not 0, uniformly filter the openings to the desired limit
      if (this.openingsFiltered && this.openingsFiltered.length > 0) {
        if (this.urgentAppointment) {
          this.openingsFiltered = [this.openingsFiltered[0]];
        } else if (!!this.displayLimit) {
          this.openingsFiltered = this.bookingService.filterEquidistantEntries(0, this.openingsFiltered.length - 1, this.displayLimit, this.openingsFiltered);
        }
      }

      // hijack filter to create bsInformation
      // To Do: attach to openings when getting from backend
      // or attach directly from database
      if (this.bookingService.otkUser.betriebsstaettenFilter && this.bookingService.bs.value === this.bookingService.bsAll)
        this.createBsStrings(this.openingsFiltered)

      //Check string lenght doctors
      if (this.usedSlotStyle === SlotStyle.onlyDoctor)
        this.leftAlignSlotsDoctors = this.openingsFiltered.some((opening: BookingOpening) => opening.displayStringNames.length > LEFTALIGNSLOTS_DOCTORS)

      if (!this.openingsFiltered.includes(this.opening.value))
        this.opening.setValue(undefined, { emitEvent: false })
      subscriber.next()
      subscriber.complete()
    })
  }


  // includeBsString
  public createBsStrings(openings: BookingOpening[]) {
    this.openingBsStrings = []
    this.maxBsStringSize = 0
    if (!openings || openings.length === 0) return
    for (let i = 0; i < openings.length; i++) {
      const locs = openings[i].kdSet.map(x => x.lid)
      const bs = this.bookingService?.betriebsstaetten ? this.bookingService.betriebsstaetten.find((x: Betriebsstaette) => locs.some(y => x.localityIdents.includes(y))) : null
      this.openingBsStrings.push(bs?.name ?? "")
      if (bs?.name.length > this.maxBsStringSize) this.maxBsStringSize = bs?.name.length
    }
    this.leftAlignSlots = this.maxBsStringSize > LEFTALIGNSLOTS
  }



  /**
   * filter the available calendar dates (days) to:
   * 1. Days not in the past
   * 2. if(doctor) days for which the selected doctor has at least one opening.
   */
  public dateFilter = (date: Date): boolean => {
    // filter out past dates
    if (dayjs(date).isBefore(dayjs().startOf('day')))
      return false
    // if booking openings not yet calculated, enable all dates
    if (!this.openings)
      return true
    // if no doctor was selected don't filter according to a specific doctor.
    // Filter to days for which any doctor has at least one opening.
    // see https://zollsoft.atlassian.net/browse/ADI-1210
    if (!this.doctor.value)
      return this.openings
        .some((bookingOpening: BookingOpening) =>
          dayjs(bookingOpening.date).add(this.forerunTime, 'minute')
            .isSame(dayjs(date), "day"))
    // a doctor was selected. Filter to days for which the selected doctor
    // has at least one opening on that day.
    return this.openings
      .filter((bookingOpening: BookingOpening) =>
        // the opening is offered by the selected doctor
        bookingOpening.kdSet
          .map((tomKalendarDaten: TomKalenderDaten) =>
            tomKalendarDaten.kid)
          .some(x => (this.doctor.value as OtkDoctor).kids.includes(x))
      )
      // the booking is offered on the day in question
      .some((bookingOpening: BookingOpening) =>
        dayjs(bookingOpening.date).add(this.forerunTime, 'minute')
          .isSame(dayjs(date), "day"))
  }

  public magicFill() {
    return of(null)
      .pipe(untilDestroyed(this),
        // note: an event is emitted by doctor.setValue, to simulate a manual selection of the doctor by the user
        mergeMap(() => of(this.doctor.setValue(this.doctorsVisible[0]))),
        mergeMap(() => this.selectFirstDay()),
        mergeMap(() => of(this.opening.setValue(this.openingsFiltered[0]))),
        mergeMap(() => {
          this.bookingDateFormGroup.updateValueAndValidity()
          this.changeDetectorRef.detectChanges()
          return of(null)
        }),
        delay(500),
        mergeMap(() => {
          !!(this.bookingService.appointmentType.value as AppointmentType).formIdentifier // does anamnese form exist?
            ? this.bookingService.magicFill$.next(BookingStep.bookingAnamnese)
            : this.bookingService.magicFill$.next(BookingStep.bookingPersonal)
          return of(null)
        })
      ).subscribe()
  }

  getCalendarDateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      if (!this.day.value)
        return ''
      const areDatesEqual: boolean = date.getFullYear() === (this.day.value as Date).getFullYear()
        && date.getMonth() === (this.day.value as Date).getMonth()
        && date.getDate() === (this.day.value as Date).getDate()
      return areDatesEqual
        ? 'date-highlighted'
        : ''
    }
  }

  // called when clicking on the "x"
  resetDoctor($event: MouseEvent) {
    this.doctor.setValue(undefined)
    // prevent the doctor selection menu from opening up again automatically
    // right after clicking the x button
    $event.stopPropagation()
  }
}

