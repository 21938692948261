<app-wrapper-otk [title]="bookingResultTitle" [shortTitle]="instanceShortName" [headerType]="'header-has-2-rows'"
  [headerOverflow]="false">
  <div buttonsEmbeddedSlot>
    <button attr.data-selenium-id="button-message-to-app-finish" (click)="messageToAppFinish()" mat-flat-button>
      {{ 'OTK.RESULT.SUCCESS.EXIT' | translate }}
    </button>
  </div>
  <!-- success -->
  <ng-container *ngIf="bookingResult===BookingResultSuccess">
    <div class="text-booking-result text-booking-result--success">
      <span>
        <fa-icon [icon]="['fas', 'circle-info']"></fa-icon>
      </span>
      {{ (seriesMsg ? 'OTK.RESULT.SUCCESS.MSG-SERIES' : 'OTK.RESULT.SUCCESS.MSG') | translate }}
    </div>
    <div class="image-container">
      <img [src]="successAssetUrl" alt="" />
    </div>
    <div class="buttons-wrapper">
      <ng-container *ngIf="!bookingService.isEmbedded && !isStandaloneResult">
        <button attr.data-selenium-id="button-route-to-instance-page" (click)="routeToInstancePage()" mat-flat-button>
          {{ 'OTK.RESULT.BACK' | translate }}
        </button>
      </ng-container>
    </div>
  </ng-container>
  <!-- failure -->
  <ng-container *ngIf="bookingResult===bookingResultFailure">
    <div class="text-booking-result text-booking-result--failure">
      <span>
        <fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
      </span>
      {{ (seriesMsg ? 'OTK.RESULT.FAILURE.TITLE-SERIES' : 'OTK.RESULT.FAILURE.TITLE') | translate }}
    </div>
    <div class="image-container">
      <img [src]="failureAssetUrl" alt="" />
    </div>
    <div class="message--failure">
      {{ 'OTK.RESULT.FAILURE.MSG' | translate }}
    </div>
    <div class="buttons-wrapper">
      <button attr.data-selenium-id="button-route-to-booking-page" (click)="routeToBookingPage()" color="primary"
        mat-flat-button>
        {{ 'OTK.RESULT.FAILURE.TRY-AGAIN' | translate }}
      </button>
      <ng-container *ngIf="!bookingService.isEmbedded && !isStandaloneResult">
        <button attr.data-selenium-id="button-route-to-instance-page" (click)="routeToInstancePage()" mat-flat-button>
          {{ 'OTK.RESULT.BACK' | translate }}
        </button>
      </ng-container>
    </div>
  </ng-container>
</app-wrapper-otk>