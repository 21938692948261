export const example = {
  type: 'radiogroup',
  name: 'Frage9',
  title: {
    de: 'Sind Sie Brillenträger/in? ',
  },
  choices: [
    {
      value: 'Item1',
      text: {
        de: 'Ja',
      },
    },
    {
      value: 'Item2',
      text: {
        de: 'Nein',
      },
    },
  ],
  colCount: 0,
  shortTitle: 'Brillenträger/in',
}
