import { BookingOpening, ConditionalAge, TimeComparator } from '@a-d/entities/Booking.entity';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { DateHelperService } from './date-helper.service';

@Injectable({
  providedIn: 'root'
})
export class OpeningService {

  constructor(
    private dateHelperService: DateHelperService
  ) { }

  /**
   * convert all opening iso date strings to full date objects and sort the entries
   * @param openings free tomedo appointment slots
   * @returns all openings sorted by date in ascending order
   */
  public convertAndSortOpenings(openings: BookingOpening[]): BookingOpening[] {
    openings = this.dateHelperService.convertOpeningsToDates(openings);
    return this.sortByDate(openings);
  }

  public filterAndProcessOpenings(openings: BookingOpening[], ageConditions: ConditionalAge, birthDate: Date) {
    const convertedOpenings = this.dateHelperService.convertOpeningsToDates(openings);
    if (!ageConditions || !birthDate || ageConditions?.show === false || ageConditions?.condition?.length === 0) {
      return this.sortByDate(convertedOpenings);
    }
    const firstCondition = ageConditions.condition[0]
    const firstBoundaryDate = dayjs(birthDate).add(firstCondition.timeSpan, firstCondition.timeUnit);

    const secondCondition = ageConditions.condition[1]
    if (!secondCondition) {
      const filteredOpenings = convertedOpenings.filter((opening: any) => this.dateHelperService.compareWithBoundary(dayjs(opening.date), firstBoundaryDate, firstCondition.timeComparator === TimeComparator.GREATER_THAN));
      return this.sortByDate(filteredOpenings);
    }
    const secondBoundaryDate = dayjs(birthDate).add(secondCondition.timeSpan, secondCondition.timeUnit);
    const filteredRangeOpenings = convertedOpenings.filter((opening: any) => {
      return (this.dateHelperService.compareWithBoundary(dayjs(opening.date), firstBoundaryDate, firstCondition.timeComparator === TimeComparator.GREATER_THAN)
        && this.dateHelperService.compareWithBoundary(dayjs(opening.date), secondBoundaryDate, secondCondition.timeComparator === TimeComparator.GREATER_THAN))
    });
    return this.sortByDate(filteredRangeOpenings);
  }

  public sortByDate(openings: BookingOpening[]): BookingOpening[] {
    if (!openings || openings.length === 0) { return []; }
    openings.sort((firstOpening, secondOpening) => {
      const dateA = dayjs(firstOpening.date);
      const dateB = dayjs(secondOpening.date);
      return dateA.diff(dateB)
    })
    return openings
  }
}
