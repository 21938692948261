<form class="c-form c-form--fullHeight c-form--largerSpacing" [formGroup]="formGroup">

  <div class="c-form__row">
    <h4 class="c-form__row__item--subheading-alt">
      {{'WHITELABEL-SECTION-BEREITSCHAFTSDIENST.ANAMNESE-FORM.PROBLEMS-QUESTION' | translate}}
    </h4>
  </div>


  <div class="c-form__row">

    <!-- Akute Beschwerden und Fallnummer -->

    <mat-form-field appearance="outline">
      <mat-label>Akute Beschwerden <span *ngIf="problemsQuestion.value.length >= 1">({{
          problemsQuestion.value.length}}/300 Zeichen)</span></mat-label>
      <textarea matInput cdkTextareaAutosize formControlName="problemsQuestion" mat-autosize=true cdkAutosizeMinRows=5
        maxlength="300" cdkAutosizeMaxRows=6></textarea>
    </mat-form-field>

  </div>


  <div class="c-form__row">
    <h4 class="c-form__row__item--subheading-alt">
      {{'WHITELABEL-SECTION-BEREITSCHAFTSDIENST.ANAMNESE-FORM.CASE-NUMBER' | translate}}
    </h4>
  </div>



  <div class="c-form__row">
    <mat-form-field appearance="outline">
      <mat-label> Fallnummer <span *ngIf="caseNumber.value.length >= 1">({{ caseNumber.value.length }}/13
          Zeichen)</span>
      </mat-label>
      <input matInput formControlName="caseNumber" mat-autosize=true cdkAutosizeMinRows=1 maxlength="13">
      <mat-hint *ngIf="!caseNumber.valid && caseNumber.value.length >=9 "><span class="isError">Eingabe muss in dem
          Format "JJJJ/MM/Nummer" erfolgen.</span>
      </mat-hint>
    </mat-form-field>
  </div>


</form>