import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstanceSocket extends Socket {

  private currentIdentifier:string|undefined;

  constructor() {
    super({
      url: `${environment.url}`,
      options: {
        autoConnect: false,
        transports: ['websocket', 'polling'],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
        withCredentials: true
      }
    })
  }

  /**
   * Initializes & updates socket url depending on active instance
   */
  initSocketUrlAndNamespace(identifier:string|undefined) {
    this.currentIdentifier = identifier?? ''
    this.ioSocket.nsp = `/${this.currentIdentifier}`
  }


  /**
   * Disconnect & connect to socket to reset socket-user data.
   */
  public reconnect(): any {
    console.log('RECONNECT')
    super.disconnect()
    this.initSocketUrlAndNamespace(this.currentIdentifier)
    super.connect()
    console.log('RECONNECTED')
  }
}
