export const propertyValues = {
  camera: {
    de: 'Fotos mit der Kamera aufnehmen',
    en: 'Take photos with the camera',
    es: 'Hacer fotos con la cámara',
    fr: 'Prendre des photos avec la caméra',
    it: 'Scattare foto con la fotocamera',
  },
  file: {
    de: 'Dateien vom Gerät hochladen',
    en: 'Upload files from Device',
    es: 'Cargar archivos desde el dispositivo',
    fr: `Télécharger des fichiers depuis l'appareil`,
    it: 'Caricare i file dal dispositivo',
  },
  'file-camera': {
    de: 'Beides zulassen',
    en: 'Allow both',
    es: 'Permitir ambos',
    fr: `Permettre les deux`,
    it: 'Consentire entrambi',
  },
  imageFit: {
    none: {
      de: '/',
      en: '/',
      es: '/',
      fr: '/',
      it: '/',
    },
    contain: {
      de: 'Anpassen',
      en: 'Contain',
      es: 'Contener',
      fr: 'Contenir',
      it: 'Contenere',
    },
    cover: {
      de: 'Abdecken',
      en: 'Cover',
      es: 'Cubrir',
      fr: 'Couvrir',
      it: 'Coprire',
    },
    fill: {
      de: 'Ausfüllen',
      en: 'Fill',
      es: 'Llenar',
      fr: 'Remplir',
      it: 'Riempire',
    },
  },
  inputType: {
    'custom-email': {
      de: 'E-Mail',
      en: 'E-mail',
      es: 'E-mail',
      fr: 'E-mail',
      it: 'E-mail',
    },
    'custom-insurance-number': {
      de: 'Versicherungsnummer (Kassenummer)',
      en: 'Insurance number',
      es: 'Número de seguro',
      fr: `Numéro d'assurance`,
      it: 'Numero di assicurazione',
    },
    'custom-insurant-number': {
      de: 'Versichertennummer',
      en: 'Insurant number',
      es: 'Número de persona asegurada',
      fr: `Numéro d'assuré(e)`,
      it: 'Numero di persona assicurata',
    },
    'custom-number': {
      de: 'Zahl',
      en: 'Number',
      es: 'Número',
      fr: 'Nombre',
      it: 'Numero',
    },
    'custom-phone-number': {
      de: 'Telefonnummer',
      en: 'Phone number',
      es: 'Número de teléfono',
      fr: 'Numéro de téléphone',
      it: 'Numero di telefono',
    },
  },
  logoFit: {
    contain: {
      de: 'Anpassen',
      en: 'Contain',
      es: 'Contener',
      fr: 'Contenir',
      it: 'Contenere',
    },
    cover: {
      de: 'Abdecken',
      en: 'Cover',
      es: 'Cubrir',
      fr: 'Couvrir',
      it: 'Coprire',
    },
    fill: {
      de: 'Ausfüllen',
      en: 'Fill',
      es: 'Llenar',
      fr: 'Remplir',
      it: 'Riempire',
    },
  },
}
