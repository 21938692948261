import { Component, Input, OnInit } from '@angular/core';
import { HomeWhitelabelSectionInterface } from '../home-whitelabel-section.interface';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-whitelabel-section-mein-rezept-online',
    templateUrl: './whitelabel-section-mein-rezept-online.component.html',
    styleUrls: ['./whitelabel-section-mein-rezept-online.component.scss'],
    standalone: true,
    imports: [NgIf, MatButtonModule, MatIconModule, TranslateModule]
})
export class WhitelabelSectionMeinRezeptOnlineComponent implements OnInit, HomeWhitelabelSectionInterface {

  @Input() mode: 'patient' | 'arzt'

  public title = 'WHITELABEL-SECTION-MEIN-REZEPT-ONLINE.TITLE'
  public subtitle = 'WHITELABEL-SECTION-MEIN-REZEPT-ONLINE.SUBTITLE'

  constructor() { }

  ngOnInit(): void {
  }

}
