import { OtkUserService } from '@a-d/shared-services/otkuser.service'
import { OtkUserGuard } from '@a-d/shared-services/otkuser-guard'
import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router'
import { of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'

import { InstanceStatus } from '../entities/Instance.entity'
import { RerouteService } from '../reroute.service'
import { InstanceModule } from './../entities/Instance.entity'
import { InstanceService } from './instance.service'

/**
 * All Guards make use of the prefixed route-segment `:instanceIdentifier` to gather an instance.
 */

@Injectable({
  providedIn: 'root',
})
export class InstanceExistsGuard implements CanActivate {
  constructor(
    public router: Router,
    public rerouteService: RerouteService,
    public instanceService: InstanceService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    if (!route.params || !route.params.instanceIdentifier) return false

    return this.instanceService
      .setInstanceByIdentifier(route.params.instanceIdentifier)
      .pipe(
        map((_) => true),
        catchError(() => of(this.router.parseUrl(''))),
      )
  }
}

@Injectable({
  providedIn: 'root',
})
export class InstanceEnabledGuard implements CanActivate {
  constructor(
    public router: Router,
    public rerouteService: RerouteService,
    public instanceService: InstanceService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    if (!route.params || !route.params.instanceIdentifier) return false

    return this.instanceService
      .setInstanceByIdentifier(route.params.instanceIdentifier)
      .pipe(
        mergeMap((instance) => {
          const isEnabled =
            instance &&
            instance.status &&
            instance.status.includes(InstanceStatus.Enabled)
          if (isEnabled) return of(true)

          // Return root of instance as fallback-url
          const url = this.instanceService.prependIdentifier('')
          return of(this.router.parseUrl(url))
        }),
        catchError(() => of(false)),
      )
  }
}

@Injectable({
  providedIn: 'root',
})
export class RemoveInstanceGuard implements CanActivate {
  constructor(
    public router: Router,
    public rerouteService: RerouteService,
    public instanceService: InstanceService,
  ) {}

  canActivate() {
    this.instanceService.resetActiveInstance()
    return true
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomInstanceGuard implements CanActivate {
  constructor(
    public router: Router,
    public rerouteService: RerouteService,
    public instanceService: InstanceService,
    public otkUserService: OtkUserService,
    public otkUserGuard: OtkUserGuard,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // sets customInstance and otkUser for instanceId in route (used in admin dashboard)
    if (
      !this.instanceService.activeInstanceIsAdmin ||
      !route.params ||
      !route.params.instanceId
    )
      return of(false)
    const instanceId = route.params.instanceId

    if (this.instanceService.customInstance?._id === instanceId) {
      if (
        this.otkUserService.otkUser?.instance === instanceId ||
        !this.instanceService.customInstance?.modules?.includes(
          InstanceModule.OTK,
        )
      )
        return of(true)
      return this.otkUserGuard.canActivate(route, state)
    }

    return this.instanceService.queryById(route.params.instanceId).pipe(
      switchMap((instance) => {
        if (!instance) return of(false)
        this.instanceService.customInstance = instance
        if (
          this.otkUserService.otkUser?.instance === instanceId ||
          !instance.modules?.includes(InstanceModule.OTK)
        )
          return of(true)
        return this.otkUserGuard.canActivate(route, state)
      }),
      catchError(() => of(this.router.parseUrl(''))),
    )
  }
}
