import Joi from 'joi'

import { BaseQuestionWithoutShortTitleJoi } from './base-question.joi'
import { ImageJoi } from './base-question-props/image.joi'
import { example } from './examples/wfa-signaturepad-question.joi.example'

export const WfaSignaturepadQuestionJoi = BaseQuestionWithoutShortTitleJoi.keys(
  {
    backgroundImage: ImageJoi.optional(),
    type: Joi.string().valid('signaturepad').required(),
  },
)
  .meta({
    className: 'WfaSignaturepadQuestion',
  })
  .example(example)
