/**
 * from @link https://blog.devgenius.io/how-to-deep-merge-javascript-objects-12a7235f5573 (with modifications)
 * Limitations: cannot handle circular references (causes infinity loop)
 */
export function mergeDeep(
  target: Record<string, any>,
  ...sources: Record<string, any>[]
): Record<string, any> {
  if (sources.length === 0) return target
  const source = sources.shift()
  if (!isObject(source)) return mergeDeep(target, ...sources)

  for (const key in source) {
    if (isObject(source[key]) && target[key]) {
      mergeDeep(target[key], source[key])
    }
    if (isObject(source[key]) && !target[key]) {
      Object.assign(target, { [key]: {} })
      mergeDeep(target[key], source[key])
    }
    if (!isObject(source[key]) && source[key]) {
      Object.assign(target, { [key]: source[key] })
    }
  }

  return mergeDeep(target, ...sources)
}

function isObject(item: any): boolean {
  return item && typeof item === 'object' && !Array.isArray(item)
}
