import Joi from 'joi'
import { ObjectIdJoi } from '../../joi/object-id';


export const OatCoreUntypedJoi = Joi.object()
  .keys({
    _id: ObjectIdJoi,
    instance: ObjectIdJoi.required(),
  })
  .meta({
    className: 'OatCore',
  })
  .example({
    _id: '661f89cc8e82668a5dde7773',
    instance: '661f89d29fd041f20403c9af',
  })
