import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { fromBER } from 'asn1js'
import { Certificate, ContentInfo, EnvelopedData } from 'pkijs'
import { arrayBufferToString, fromBase64, stringToArrayBuffer } from 'pvutils'
import {
  arrayBufferToBase64,
  base64ToArrayBuffer,
    certToBase64,
    base64ToCert
} from '../convert'
import { areArrayBuffersEqual } from '../are-array-buffers-equal'
import { Observable } from 'rxjs'

/**
 * decrypt encrypted data with the users private key
 * @param recipientPrivateKey array buffer of the users private key
 * @param recipientCertificate the users personal certificate
 * @param encryptedData base64 encoded encrypted data
 * @param decode remove base64 encoding
 */
export function decrypt(
  recipientPrivateKey: ArrayBuffer,
  recipientCertificate: Certificate,
  encryptedData: string,
  decode = false,
  show = false,
): Observable<string> {
  return new Observable((observer) => {
    const rpkBase64 = arrayBufferToBase64(recipientPrivateKey)
    const cBase64 = certToBase64(recipientCertificate)
    console.debug('[decrypt] rpkBase64', rpkBase64)
    console.debug('[decrypt] cBase64', cBase64)
    console.debug('[decrypt] encryptedData', encryptedData)

    let encryptedBuffer: ArrayBuffer
    if (decode) {
      encryptedBuffer = stringToArrayBuffer(fromBase64(encryptedData))
    } else {
      encryptedBuffer = stringToArrayBuffer(encryptedData)
    }
    const asn1 = fromBER(encryptedBuffer)
    const cmsContent = new ContentInfo({ schema: asn1.result })
    const envelopedData = new EnvelopedData({ schema: cmsContent.content })
    envelopedData
      .decrypt(0, {
        recipientCertificate: recipientCertificate,
        recipientPrivateKey: recipientPrivateKey,
      })
      .then(
        (decryptedBuffer) => {
          const decrypted = arrayBufferToString(decryptedBuffer)
          if (!decrypted) {
            observer.error('Error while decrypting data.')
          } else {
            if (show) {
              console.log('DEC')
              console.log(decrypted)
            }
            observer.next(decrypted)
            observer.complete()
          }
        },
        (error) => {
          AdLoggerService.plain.error(error, 'lib/crypto/decrypt')
          observer.error('Error while decrypting data.')
        },
      )
  })
}
