export const example = {
  type: 'country',
  name: '$wfaPatientLand$',
  startWithNewLine: false,
  title: {
    de: 'Land',
  },
  isRequired: true,
  shortTitle: 'Land',
}
