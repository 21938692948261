import { AdLoggerService } from '@a-d/logging/ad-logger.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { distance } from 'fastest-levenshtein';
import { Arzt } from '../entities/Arzt.entity';
import { FacharztAusbildung, FacharztSchwerpunkt, MedicalField, WeiterbildungsOrdnung, ZusatzBezeichnung, ZusatzWeiterbildung } from '../entities/Facharzt.entity';
import { I18NString } from '../entities/I18N.entity';
import { GetFacharztAusbildungenResponse, GetMedicalFieldsResponse } from '../entities/Portal.entity';
import { I18NStringPipe } from '../i18n/i18n.pipe';
import defaultFacharztAusbildungenJson from './json/default-facharzt-ausbildungen.json';
import defaultMedicalFieldsJson from './json/default-medical-fields.json';

@Injectable({
  providedIn: 'root',
})
export class FacharztService {

  constructor(
    private adLoggerService: AdLoggerService,
    private http: HttpClient,
  ) {
    // default values instantly
    this.updateFacharztAusbildungenWithResponse(defaultFacharztAusbildungenJson as GetFacharztAusbildungenResponse);
    this.updateMedicalFieldsWithResponse(defaultMedicalFieldsJson as GetMedicalFieldsResponse);

    // update from remote, when possible
    this.updateFacharztAusbildungen();
    this.updateMedicalFields();
  }

  private getMedicalFieldsUrl = '/api/portal/medical-fields';
  private getFacharztAusbildungenUrl = '/api/portal/facharzt-ausbildungen';

  public facharztAusbildungenById = new Map<string, FacharztAusbildung>();
  public facharztSchwerpunkteById = new Map<string, FacharztSchwerpunkt>();
  public facharztZusatzWeiterbildungenById = new Map<string, ZusatzWeiterbildung>();
  public facharztZusatzBezeichnungenById = new Map<string, ZusatzBezeichnung>();
  public medicalFieldsById = new Map<string, MedicalField>();

  public get facharztAusbildungen() { return Array.from(this.facharztAusbildungenById.values()); };
  public get facharztSchwerpunkte() { return Array.from(this.facharztSchwerpunkteById.values()); };
  public get facharztZusatzWeiterbildungen() { return Array.from(this.facharztZusatzWeiterbildungenById.values()); };
  public get facharztZusatzBezeichnungen() { return Array.from(this.facharztZusatzBezeichnungenById.values()); };
  public get medicalFields() { return Array.from(this.medicalFieldsById.values()); };

  public standaloneFacharztIDs = ['0', 'ALT_0.1', 'ALT_0.2', 'fa-2018-psychologischer-psychotherapeut', 'fa-2018-kinder-jugend-psychotherapeut', '37', '38', '39']
  public get standaloneFacharztNames() { return this.standaloneFacharztIDs.map((id) => this.getFacharztAusbildungById(id)?.name) }


  public updateMedicalFieldsWithResponse(response: GetMedicalFieldsResponse) {
    this.medicalFieldsById = new Map((response.medicalFields ?? []).map((e) => [e.id, e]));
  }

  public facharztNameList() {
    const ausbildungen = [...this.facharztAusbildungenById.values()];
    const aubildungNames = ausbildungen.map((item) => item.name);
    const schwerpunkte = [...this.facharztSchwerpunkteById.values()];
    const schwerpunkteNames = schwerpunkte.map((item) => item.name);
    const zusatz = [...this.facharztZusatzWeiterbildungenById.values()];
    const zusatzNames = zusatz.map((item) => item.name);
    const medfields = [...this.medicalFieldsById.values()];
    const medfieldsNames = medfields.map((item) => item.name);
    const zusatzBezeichnungen = [...this.facharztZusatzBezeichnungenById.values()];
    const zusatzBezeichnungenNames = zusatzBezeichnungen.map((item) => item.name);
    return [...new Set([...aubildungNames, ...schwerpunkteNames, ...zusatzNames, ...medfieldsNames, ...zusatzBezeichnungenNames])];
  }

  public updateFacharztAusbildungenWithResponse(response: GetFacharztAusbildungenResponse) {
    this.facharztAusbildungenById = new Map((response.facharztAusbildungen ?? []).map((e) => [e.id, e]));
    this.facharztSchwerpunkteById = new Map((response.facharztSchwerpunkte ?? []).map((e) => [e.id, e]));
    this.facharztZusatzWeiterbildungenById = new Map((response.facharztZusatzWeiterbildungen ?? []).map((e) => [e.id, e]));
    this.facharztZusatzBezeichnungenById = new Map((response.facharztZusatzBezeichnungen ?? []).map((e) => [e.id, e]));
  }

  public async updateMedicalFields() {
    try {
      const response = await this.http.get<GetMedicalFieldsResponse>(this.getMedicalFieldsUrl).toPromise();
      this.updateMedicalFieldsWithResponse(response);
    } catch (e) {
      this.adLoggerService.error("Error while updating Medical Fields!", e);
    }
  }

  public async updateFacharztAusbildungen() {
    try {
      const response = await this.http.get<GetFacharztAusbildungenResponse>(this.getFacharztAusbildungenUrl).toPromise();
      this.updateFacharztAusbildungenWithResponse(response);
    } catch (e) {
      this.adLoggerService.error("Error while updating Facharzt Ausbildungen!", e);
    }
  }

  /**
  * get a facharzt ausbildung by id
  */
  public getFacharztAusbildungById(id: string): FacharztAusbildung {
    return this.facharztAusbildungenById.get(id);
  }

  /**
   * returns whether a facharzt ausbildungs id exists.
   */
  public existsFacharztAusbildungId(id: string): boolean {
    return this.facharztAusbildungenById.has(id);
  }

  /**
   * get a facharzt schwerpunkt by id
   */
  public getFacharztSchwerpunktById(id: string): FacharztSchwerpunkt {
    return this.facharztSchwerpunkteById.get(id);
  }

  /**
   * returns whether a facharzt schwerpunkt id exists.
   */
  public existsFacharztSchwerpunktId(id: string): boolean {
    return this.facharztSchwerpunkteById.has(id);
  }

  /**
   * returns applicable schwerpunkte for a facharzt ausbildungs id.
   */
  public getSchwerpunkteForFacharztAusbildungId(id: string): FacharztSchwerpunkt[] {
    return this.facharztSchwerpunkte.filter((s) => s.requiredFacharzt === id);
  }

  /**
   * get a zusatz weiterbildung by id
   */
  public getZusatzWeiterbildungById(id: string): ZusatzWeiterbildung {
    return this.facharztZusatzWeiterbildungenById.get(id);
  }

  /**
   * returns whether a zusatz weiterbilungs id exists
   */
  public existsZusatzWeiterbildungId(id: string): boolean {
    return this.facharztZusatzWeiterbildungenById.has(id);
  }

  /**
   * get a medical field by id
   */
  public getMedicalFieldById(id: string): MedicalField {
    return this.medicalFieldsById.get(id);
  }

  /**
   * returns whether a medical field id exists
   */
  public existsMedicalFieldId(id: string): boolean {
    return this.medicalFieldsById.has(id);
  }

  /**
   * get a zusatz bezeichnung by id
   */
  public getZusatzBezeichnungById(id: string): ZusatzBezeichnung {
    return this.facharztZusatzBezeichnungenById.get(id);
  }

  /**
   * returns whether a zusatz bezeichnung id exists
   */
  public existsZusatzBezeichnungId(id: string): boolean {
    return this.facharztZusatzBezeichnungenById.has(id);
  }


  /**
   * returns FacharztAusbildung for the given arzt
   */
  public getFacharztForArzt(arzt: Arzt): FacharztAusbildung {
    if (!arzt?.facharzt) return null;
    return this.getFacharztAusbildungById(arzt.facharzt);
  }
  public getSecondFacharztForArzt(arzt: Arzt): FacharztAusbildung {
    if (!arzt?.facharzt_2) return null;
    return this.getFacharztAusbildungById(arzt.facharzt_2);
  }

  /**
   * returns all FacharztSchwerpunkte of given arzt.
   */
  public getFacharztSchwerpunkteForArzt(arzt: Arzt): FacharztSchwerpunkt[] {
    if (!arzt?.facharztSchwerpunkte?.length) return [];
    return arzt.facharztSchwerpunkte.map((id) => this.getFacharztSchwerpunktById(id));
  }
  public getSecondFacharztSchwerpunkteForArzt(arzt: Arzt): FacharztSchwerpunkt[] {
    if (!arzt?.facharztSchwerpunkte_2?.length) return [];
    return arzt.facharztSchwerpunkte_2.map((id) => this.getFacharztSchwerpunktById(id));
  }

  /**
   * returns all ZusatzWeiterbildungen of given arzt
   */
  public getZusatzWeiterbildungenForArzt(arzt: Arzt): ZusatzWeiterbildung[] {
    if (!arzt?.facharztZusatzWeiterbildungen?.length) return [];
    return arzt.facharztZusatzWeiterbildungen.map((id) => this.getZusatzWeiterbildungById(id));
  }

  /**
   * returns all medical fields for all educations
   * (facharzt, schwerpunkt, zusatzweiterbildung) of the
   * given arzt
   */
  public getMedicalFieldsForArzt(arzt: Arzt): MedicalField[] {
    const fieldIdsAccumulated = [
      ...(this.getFacharztForArzt(arzt)?.fields ?? []),
      ...(this.getFacharztSchwerpunkteForArzt(arzt)?.map((s) => s?.fields ?? [])?.reduce((a, b) => a.concat(b), []) ?? []),
      ...(this.getZusatzWeiterbildungenForArzt(arzt)?.map((s) => s?.fields ?? [])?.reduce((a, b) => a.concat(b), []) ?? []),
    ];
    const fieldIdsAccumulatedUnique = Array.from(new Set(fieldIdsAccumulated));
    return fieldIdsAccumulatedUnique.map((f) => this.getMedicalFieldById(f));
  }

  /**
   * returns validator which checks if value of given control is valid facharzt-id.
   */
  public getFacharztIdValidator(isRequired: boolean): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const errorValue = { value: control.value }

      // null value
      if (!control.value) {
        if (isRequired) {
          return { 'idEmpty': errorValue };
        } else {
          return null;
        }
      }

      // id does not exist
      if (!this.existsFacharztAusbildungId(control.value)) {
        return { 'idInvalid': errorValue };
      }

      return null;
    }
  }

  /**
   * returns validator which checks if value of given control is valid ZusatzWeiterbildung-id.
   */
  public getZusatzWeiterbildungIdValidator(isRequired: boolean): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const errorValue = { value: control.value }

      // null value
      if (!control.value) {
        if (isRequired) {
          return { 'idEmpty': errorValue };
        } else {
          return null;
        }
      }

      // id does not exist
      if (!this.existsZusatzWeiterbildungId(control.value)) {
        return { 'idInvalid': errorValue };
      }

      return null;
    }
  }

  /**
   * returns validator which checks if value of given control is valid medical-field-id.
   */
  public getMedicalFieldIdValidator(isRequired: boolean): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const errorValue = { value: control.value }

      // null value
      if (!control.value) {
        if (isRequired) {
          return { 'idEmpty': errorValue };
        } else {
          return null;
        }
      }

      // id does not exist
      if (!this.existsMedicalFieldId(control.value)) {
        return { 'idInvalid': errorValue };
      }

      return null;
    }
  }

  /**
   * returns an i18n string as name for the given weiterbildungsordnung
   */
  public getWboName(wbo: WeiterbildungsOrdnung): I18NString {
    if (!wbo) return undefined;
    switch (wbo) {
      case WeiterbildungsOrdnung.UNDEFINED:
        return undefined;
      case WeiterbildungsOrdnung.WBO_NONE:
        return undefined;
      case WeiterbildungsOrdnung.WBO_1992:
        return { de: "Weiterbildungsordnung 1992", en: "CPE regulations 1992" };
      case WeiterbildungsOrdnung.WBO_2003:
        return { de: "Weiterbildungsordnung 2003", en: "CPE regulations 2003" };
      case WeiterbildungsOrdnung.WBO_2018:
        return { de: "Weiterbildungsordnung 2018", en: "CPE regulations 2018" };
    }
  }


  /**
   * find a facharzt ausbildung by name
   */
  public findFacharztAusbildungByName(name: string, i18nStringPipe: I18NStringPipe) {
    const findRes = this.facharztAusbildungen.find((facharzt) => {
      return name === (i18nStringPipe.transform(facharzt.name) ?? "");
    });
    return findRes;
  }

  /**
   * find a ZusatzWeiterbildung by name
   */
  public findZusatzWeiterbildungByName(name: string, i18nStringPipe: I18NStringPipe) {
    const findRes = this.facharztZusatzWeiterbildungen.find((wb) => {
      return name === (i18nStringPipe.transform(wb.name) ?? "");
    });
    return findRes;
  }

  /**
   * find a medical field by name
   */
  public findMedicalFieldByName(name: string, i18nStringPipe: I18NStringPipe) {
    const findRes = this.medicalFields.find((field) => {
      return name === (i18nStringPipe.transform(field.name) ?? "");
    });
    return findRes;
  }

  /**
   * find a Zusatzbezeichnung by name
   */
  public findZusatzBezeichnungByName(name: string, i18nStringPipe: I18NStringPipe) {
    const findRes = this.facharztZusatzBezeichnungen.find((zb) => {
      return name === (i18nStringPipe.transform(zb.name) ?? "");
    });
    return findRes;
  }

  /**
   * searches and returns appropiate facharzt ausbildungen for the given search term.
   */
  public searchForFacharztAusbildungen(searchTerm: string, i18nStringPipe: I18NStringPipe): FacharztAusbildung[] {
    const searchRegex = new RegExp(searchTerm, "i");
    return this.facharztAusbildungen.filter((facharzt) => {
      return facharzt.id === searchTerm ||
        (facharzt.name && i18nStringPipe.transform(facharzt.name).match(searchRegex)) ||
        (facharzt.name && distance(i18nStringPipe.transform(facharzt.name), searchTerm) < 4);
    });
  }

  /**
   * searches and returns appropiate ZusatzWeiterbildungen for the given search term.
   */
  public searchForZusatzWeiterbildungen(searchTerm: string, i18nStringPipe: I18NStringPipe): ZusatzWeiterbildung[] {
    const searchRegex = new RegExp(searchTerm, "i");
    return this.facharztZusatzWeiterbildungen.filter((wb) => {
      return wb.id === searchTerm ||
        (wb.name && i18nStringPipe.transform(wb.name).match(searchRegex)) ||
        (wb.name && distance(i18nStringPipe.transform(wb.name), searchTerm) < 4);
    });
  }

  /**
   * searches and returns appropiate medical fields for the given search term.
   */
  public searchForMedicalFields(searchTerm: string, i18nStringPipe: I18NStringPipe): MedicalField[] {
    const searchRegex = new RegExp(searchTerm, "i");
    return this.medicalFields.filter((field) => {
      return field.id === searchTerm ||
        (field.name && i18nStringPipe.transform(field.name).match(searchRegex)) ||
        (field.tags?.some((t) => t.match(searchRegex)) ?? false);
    });
  }

  /**
   * searches and returns appropiate Schwerpunkte for the given search term.
   */
  public searchForFacharztSchwerpunkte(searchTerm: string, i18nStringPipe: I18NStringPipe): FacharztSchwerpunkt[] {
    const searchRegex = new RegExp(searchTerm, "i");
    return this.facharztSchwerpunkte.filter((sp) => {
      return sp.id === searchTerm ||
        (sp.name && i18nStringPipe.transform(sp.name).match(searchRegex)) ||
        (sp.name && distance(i18nStringPipe.transform(sp.name), searchTerm) < 4);
    });
  }

  /**
   * searches and returns appropiate ZusatzBezeichnungen for the given search term.
   */
  public searchForZusatzBezeichnungen(searchTerm: string, i18nStringPipe: I18NStringPipe): ZusatzBezeichnung[] {
    const searchRegex = new RegExp(searchTerm, "i");
    return this.facharztZusatzBezeichnungen.filter((zb) => {
      return zb.id === searchTerm ||
        (zb.name && i18nStringPipe.transform(zb.name).match(searchRegex)) ||
        (zb.name && distance(i18nStringPipe.transform(zb.name), searchTerm) < 4);
    });
  }
}
