import { WfaEnvService } from './../../../../environments/wfa-env.service'
import { SingleOrMultilinkStore } from './../single-or-multilink/single-or-multilink.store'
import { getWfaServerBaseUrl } from './../../../../environments/get-wfa-url'
import { Route } from '@angular/router'
import { MultilinkViewerComponent } from './multilink-viewer.component'
import { Component } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { AnyWfaForm } from '@arzt-direkt/wfa-definitions'
import {
  allStaticJsonForms,
  Id,
  indesNid,
  json,
  responseIdDict,
  staticIdDict,
} from '@arzt-direkt/wfa-definitions'
import { firstValueFrom, map, BehaviorSubject } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { WfaFormWithResponse } from '@arzt-direkt/wfa-definitions'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-test-multilink-viewer',
  template: ` <wfa-multilink-viewer></wfa-multilink-viewer> `,
  imports: [MultilinkViewerComponent],
})
export class TestMultilinkViewerComponent {
  constructor(
    private httpClient: HttpClient,
    private store: SingleOrMultilinkStore,
    private wfaEnv: WfaEnvService,
  ) {}

  ngOnInit() {
    const serverBaseUrl = getWfaServerBaseUrl(this.wfaEnv)
    const url = `${serverBaseUrl}/getWfaFormWithResponse?responseId=${responseIdDict.betaGroup}`
    this.httpClient
      .get(url)
      .pipe(
        map((q: any) => q.queryResult),
        untilDestroyed(this),
      )
      .subscribe((fwrs: WfaFormWithResponse[]) => {
        this.store.setFwrs(fwrs)
      })
  }
}

export const testMultilinkViewerRoute: Route = {
  path: 'test-multilink-viewer',
  component: TestMultilinkViewerComponent,
}
