import { RxLet } from '@rx-angular/template/let'
import { LanguagePickerService } from './language-picker.service'
import { TranslateWithPrefix } from './../../utility/translate-with-prefix.pipe'
import { CommonModule } from '@angular/common'
import { Output, EventEmitter, Component, OnInit, Input } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { MatCardModule } from '@angular/material/card'
import { MatMenuModule } from '@angular/material/menu'
import { MatTooltipModule } from '@angular/material/tooltip'
import { TranslateModule, TranslateService } from '@ngx-translate/core'

import {
  getCoreLocales,
  coreLocales,
  CoreLocale,
} from '@arzt-direkt/wfa-definitions'
import { nullish, removeDuplicates } from '@arzt-direkt/wfa-generic-utils'
import { Observable } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    TranslateModule,
    TranslateWithPrefix,
    RxLet,
  ],
})
export class LanguagePickerComponent implements OnInit {
  localeControl = new FormControl<CoreLocale>('de')

  constructor(public languagePicker: LanguagePickerService) {}

  changeLanguage = (lang: string) => {
    this.languagePicker.update(lang)
  }

  ngOnInit() {
    const browserLocale = navigator.language.split('-')[0] // returns "en" from "en-US"
    if (this.languagePicker.locales$?.value.includes(browserLocale)) {
      this.localeControl.setValue(<CoreLocale>browserLocale)
    }
  }
}
