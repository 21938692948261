import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { NgFor } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-chip-list-field-component',
  host: { 'class': 'c-form__row' },
  template: `
  <mat-form-field [formGroup]="formGroup" appearance="outline">

    <mat-chip-grid #chipList>
      <mat-chip-row *ngFor="let item of list.value" [removable]="true" (removed)="remove(item)">
        {{ item }}
        <fa-icon matChipRemove [icon]="['fas', 'times-circle']"></fa-icon>
      </mat-chip-row>
      <input [placeholder]="placeholder" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">
    </mat-chip-grid>

  </mat-form-field>
  `,
  standalone: true,
  imports: [MatFormFieldModule, FormsModule, ReactiveFormsModule, MatChipsModule, NgFor, FontAwesomeModule]
})
export class ChipListFieldComponent implements OnInit {

  private unsubscribe$ = new Subject()
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input() formGroup: UntypedFormGroup
  @Input() ngModel: any[]
  @Input() name: string
  @Input() placeholder: string
  @Input() required: boolean

  public get list(): AbstractControl { return this.formGroup.get(this.name) }


  constructor(
    private cd: ChangeDetectorRef,
  ) { }


  ngOnInit() {
    // Set validators
    this.list.setValidators([
      ...(this.required ? [Validators.required] : []),
    ])

    this.cd.detectChanges()
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }


  /**
   * Adds an item to the array
   */
  public add(event: MatChipInputEvent) {
    const newValue = event.value ? event.value.trim() : null
    const newValueExists = (this.list.value || []).includes(newValue)

    // Add item
    if (this.list.value && newValue && !newValueExists) {
      this.list.value.push(newValue)
      this.list.updateValueAndValidity({ emitEvent: true })
    } else if (!this.list.value && newValue && !newValueExists) {
      this.list.setValue([newValue])
    }

    // Reset the input value
    if (event.input) event.input.value = ''

    // Set Control manually to pristine
    this.list.markAsDirty()
  }


  /**
   * Removes the given item from the array
   */
  remove(item: string) {
    if (!this.list.value) return

    const index = this.list.value.indexOf(item)
    if (index >= 0) {
      this.list.value.splice(index, 1)
      if (!this.list.value.length) this.list.setValue(null)
      else this.list.updateValueAndValidity({ emitEvent: true })
    }

    // Set Control manually to pristine
    this.list.markAsDirty()
  }

}
