import { FormGetter } from '@a-d/entities/FormGetter.entity';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18NArray } from '../../entities/I18N.entity';
import { UsefulComponent } from '../../misc/useful.component';
import { I18NArrayPipe } from '../../i18n/i18n.pipe';
import { MatRadioModule } from '@angular/material/radio';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-radio-button-select',
    host: { class: 'c-form__row__item--stacked' },
    templateUrl: './radio-button-select.component.html',
    styleUrls: ['./radio-button-select.component.scss'],
    animations: [
        trigger('toggleClick', [
            state('false', style({
                height: '1.3em',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            })),
            state('true', style({
                height: '*',
                whiteSpace: 'normal',
            })),
            transition('true => false', animate('200ms linear')),
            transition('false => true', animate('200ms linear')),
        ]),
    ],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        NgIf,
        NgClass,
        MatRadioModule,
        NgFor,
        I18NArrayPipe,
    ],
})
export class RadioButtonSelectComponent
  extends UsefulComponent
  implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() hint: string = undefined;
  @Input() options: I18NArray = [];
  @Input() required: boolean = true;
  @Input() selectionGetter: FormGetter;
  @Input() selectionName: string;
  @Input() selectionPlaceholder: string;
  expandHint: boolean = false;

  // Form-Controls /////////////////////////////////////////////////////////////
  public get selection(): AbstractControl {
    if (this.selectionGetter !== undefined) return this.selectionGetter();
    else return this.formGroup.get(this.selectionName);
  }

  constructor() {
    super();
  }

  ngOnInit(): void { }
}
