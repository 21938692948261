import { ViewerEnvState } from '@arzt-direkt/wfa-definitions'
import { WfaForm } from '@arzt-direkt/wfa-definitions'
import { WfaFormResponse } from '@arzt-direkt/wfa-definitions'
import { initialViewerEnvStateDict } from '@arzt-direkt/wfa-definitions'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'

export interface PermalinkState extends Record<string, unknown> {
  env: ViewerEnvState
  form: Maybe<WfaForm>
  formResponse: Maybe<WfaFormResponse>
}

export const initalPermalinkViewerState: PermalinkState = {
  env: initialViewerEnvStateDict['arztDirekt'],
  form: undefined,
  formResponse: undefined,
}
