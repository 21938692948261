import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject, Subject } from 'rxjs'

import {
  MatInputPopupComponent,
  MatInputPopupUserInput,
} from './../../utility-components/mat-input-popup.component'

@UntilDestroy()
@Component({
  selector: 'app-test-mat-input-popup',
  standalone: true,
  template: `
    <div>
      <h3>Current Input Value: {{ params.matInputControl.value }}</h3>
      <button mat-button (click)="showPopup()">Edit Input</button>
    </div>
    <wfa-mat-input-popup [params]="params"> </wfa-mat-input-popup>
  `,
  styles: [
    `
      div {
        text-align: center;
        margin: 20px;
      }
    `,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputPopupComponent,
  ],
})
export class TestMatInputPopupComponent {
  params = {
    matInputControl: new FormControl(''),
    show$: new BehaviorSubject<boolean>(false),
    userInput$: new Subject<MatInputPopupUserInput>(),
    matInputPopupI18n: {
      editInput: 'WFA.MAT-INPUT-POPUP.EDIT-WFA-FORM-ID.EDIT-INPUT',
      label: 'WFA.MAT-INPUT-POPUP.EDIT-WFA-FORM-ID.LABEL',
      save: 'WFA.MAT-INPUT-POPUP.EDIT-WFA-FORM-ID.SAVE',
      cancel: 'WFA.MAT-INPUT-POPUP.EDIT-WFA-FORM-ID.CANCEL',
      invalidInput: 'WFA.MAT-INPUT-POPUP.EDIT-WFA-FORM-ID.INVALID-INPUT',
    },
  }
  inputValue = ''

  showPopup() {
    this.params.show$.next(true)
  }

  ngOnInit() {
    this.params.userInput$
      .pipe(untilDestroyed(this))
      .subscribe((userInput: MatInputPopupUserInput) => {
        if (userInput === 'save') {
          console.log('TestMatInputPopupComponent: save called')
        }
      })
  }
}
