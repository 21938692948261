import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { dummyPersonalDataWfaFormResponse } from '@arzt-direkt/wfa-definitions'
import {
  isSubmitStatusToTomedo,
  json,
  surveyjsResponseJoiExample,
  WfaForm,
  wrapFormToWebviewInputData,
} from '@arzt-direkt/wfa-definitions'

import { getWfaFrontendBaseUrl } from './../../../environments/get-wfa-url'

const { testing, zollsoftForms } = json

import { WfaEnvService } from '../../../environments/wfa-env.service'
import { next } from './next'
import { SafePipe } from './safe.pipe'

const forms = Object.values(zollsoftForms)
forms.push(testing.questionMix)

@Component({
  selector: 'app-test-webview-viewer',
  standalone: true,
  imports: [CommonModule, SafePipe],
  templateUrl: './test-webview-viewer.component.html',
  styleUrls: ['./test-webview-viewer.component.scss'],
})
export class TestWebviewViewerComponent implements AfterViewInit {
  @ViewChild('twvIframe', { static: false }) twvIframe!: ElementRef
  url: string
  currentFormNo = 0

  constructor(
    private router: Router,
    private wfaEnv: WfaEnvService,
  ) {
    const webformsRootUrl = getWfaFrontendBaseUrl(this.wfaEnv)
    this.url = `${webformsRootUrl}/webview-viewer`
    console.log(`[testWebviewViewer/constructor]: set this.url to ${this.url}`)
  }

  ngAfterViewInit() {
    window.addEventListener('message', (event: MessageEvent) => {
      if (iframeOnInitComplete(event)) this.postNewForm()
      if (isSubmitStatusToTomedo(event.data))
        console.log('webview-viewer: Rendering complete')
    })
  }

  postNewForm() {
    const data = wrapFormToWebviewInputData(
      forms[this.currentFormNo] as unknown as WfaForm,
      {
        ...dummyPersonalDataWfaFormResponse,
        surveyjsResponse: surveyjsResponseJoiExample,
      },
      'formFromTomedo',
    )

    this.twvIframe.nativeElement.contentWindow.postMessage(data, '*')
    this.currentFormNo = next(this.currentFormNo, forms.length)
  }

  postPdfExport() {
    const data = { action: 'exportPDF' }
    this.twvIframe.nativeElement.contentWindow.postMessage(data, '*')
  }

  postCompleteForm() {
    const data = { action: 'completeForm' }
    this.twvIframe.nativeElement.contentWindow.postMessage(data, '*')
  }

  postStatusRequested() {
    const data = { action: 'statusRequestedByTomedo' }
    this.twvIframe.nativeElement.contentWindow.postMessage(data, '*')
  }
}

function iframeOnInitComplete(event: MessageEvent): boolean {
  return event.data?.action === 'webview-viewer: OnInit complete'
}
