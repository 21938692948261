import { AdLoggerService } from '@a-d/logging/ad-logger.service';
import { AbilityService } from '@a-d/rights-management/ability.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@lib/notifications/notification.service';
import { Certificate } from 'pkijs';
import { arrayBufferToString, fromBase64, stringToArrayBuffer } from 'pvutils';
import { BehaviorSubject, Observable, Subject, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CertificateService, KeyService, KeystoreService, UserDataService, UtilityService } from '../crypto';
import { CookiesService } from '../dsgvo/cookies.service';
import { InstanceEncryptionMode, InstanceWaitingListModules } from '../entities/InstanceSettings.entity';
import { User, UserStatus } from '../entities/User.entity';
import { InstanceService } from '../instance/instance.service';
import { RerouteService } from '../reroute.service';
import { SocketService } from '../socket/socket.service';
import { CryptoService } from './../crypto/crypto.service';
import { Instance } from './../entities/Instance.entity';
import { StorageService, vssBackgroundSettingsType } from './../storage/storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public readonly passwordConfig = {
    iterations: 100000,
    hashAlgorithm: 'SHA-256'
  }

  public cryptoStartedInitializing = false
  public cryptoIsInitialized$ = new BehaviorSubject<boolean>(null);

  public userStartedFetching = false
  public userUpdated$ = new Subject<any>();
  private _managerEmail: string;
  private _managerPassword: string;

  passwordForOtk: string;

  public isSwitchingAccountMode$ = new BehaviorSubject<boolean>(null);

  public get email(): string { return this._managerEmail; }
  public set email(email: string) { this._managerEmail = email; }

  public get password(): string { return this._managerPassword; }
  public set password(password: string) { this._managerPassword = password; }

  public get user(): any { return (<any>window).user }
  public set user(user: any) {
    if (user) {
      // Update Role Helpers
      user.isArzt = user.roles && user.roles.includes('arzt') && user.status === UserStatus.Freigeschaltet
      user.isAdmin = user.roles && user.roles.includes('admin') && user.status === UserStatus.Freigeschaltet
      user.isManager = user.roles && user.roles.includes('management')
      user.isInstance = user.roles && user.roles.includes('instance')
      user.hasNoRole = !user.isArzt && !user.isAdmin && !user.isInstance

      // Save/Delete User on 'window'
      console.log(`Setting User '${user.email}':`, user);
      (<any>window).user = user;
      this.abilityService.initializeUserAbilities(user.abilities)
    } else if ((<any>window).user) {
      console.log(`Removing User..`)
      delete (<any>window).user;
      this.abilityService.resetAbilities();
    }



    // Notify about User-Update
    this.userUpdated$.next(user);
  }

  public get arztPhoto(): string {
    if (!this.user?.arzt) return null
    return this.user.arzt.photo
  }

  constructor(
    private adLoggerService: AdLoggerService,
    public http: HttpClient,
    private instanceService: InstanceService,
    private certificateService: CertificateService,
    private cookieService: CookiesService,
    private keystoreService: KeystoreService,
    private keyService: KeyService,
    private dataService: UserDataService,
    private utilityService: UtilityService,
    private storageService: StorageService,
    private router: Router,
    private notificationService: NotificationService,
    private socketService: SocketService,
    private rerouteService: RerouteService,
    private cryptoService: CryptoService,
    private abilityService: AbilityService
  ) { }


  /**
   * A helper for HTTP-Requests which return a new user (e.g. fetchUser, login, register)
   */
  public userReponseHandler(response: any) {
    if (response && response.user) {
      this.user = response.user
      if (response.token) this.cookieService.temporaryAuthCookie = response.token;
      if (response.csrf) this.cookieService.csrfToken = response.csrf;
      if (this.user.roles && this.user.roles.includes('instance')) {
        return response.user as Instance
      }
      return response.user as User
    } else {
      this.user = null
      throw { name: "ReceivedNoUser", raw: response }
    }
  }


  /**
   * Returns locally saved user object or fetches it if not existing.
   * IMPORTANT: Only needs to be used by the AuthGuards, in all other parts of the app just 'authService.user' can be used.
   */
  public getUser(): Observable<any> {
    if (!this.instanceService.activeInstance) {
      console.warn("Couldn't get user (login) as no active instance is set.")
      return of(null)
    }

    if (this.user || this.userStartedFetching) {
      return of(this.user)

    } else {
      const instanceId = this.instanceService.activeInstance._id
      return this.http.post<any>('/api/auth/user', { instanceId }).pipe(
        catchError((_) => of(null)),
        map((response) => this.userReponseHandler(response)),
      )
    }
  }


  /**
   * Call Token-Refreh Route
   */


  /**
   * Clear all local- and user-data (e.g. useful for switching account-mode)
   */
  private clearData() {
    return this.storageService.clearAll().pipe(
      tap((_) => {
        this.dataService.resetData();
        this.cryptoStartedInitializing = false;
        this.cryptoIsInitialized$.next(null);
        this.socketService.disjoin()
        this.rerouteService.resetUrls()
        this.user = null
      })
    )
  }


  /**
   * Logs in with the given credentials and does some Initializations afterwards.
   */
  public login(email: string, password: string, ignoreUser = false): Observable<User | Instance> {
    if (!this.instanceService.activeInstance) {
      console.warn("Couldn't login as no active instance was found.")
      return
    }
    this.passwordForOtk = password;
    console.log("Logging in..")
    const instanceId = this.instanceService.activeInstance._id
    return this.http.post<any>('/api/auth/login', { email, password, instanceId, ignoreUser }).pipe(
      map((response) => this.userReponseHandler(response)),
      mergeMap((user) => {
        if (user.isArzt || user.isInstance) {
          if (!this.socketService.isConnected) this.socketService.reconnect()
          return this.initCrypto(user, password)
        }
        return of(null)
      }),
      map((_) => this.user)
    )
  }


  /**
   * If user has 'management'-role, this function switches between the instance-view and user-/arzt-view.
 */
  public switchAccountMode(): Observable<any> {
    this.isSwitchingAccountMode$.next(true)
    const ignoreUser = this.user?.isArzt ? true : false

    let vssBackgroundSettingsArray = []
    this.storageService.getAllVSSBackgroundSettings()
      .subscribe((settingsArray: { key: string, value: vssBackgroundSettingsType }[]) => vssBackgroundSettingsArray = settingsArray)

    if (environment.demoMode && (!this.password || !this.email)) {
      this.isSwitchingAccountMode$.next(false)
      return throwError(() => "Demo-Modus: Fehler beim Account-Wechsel, da Login-Daten nicht mehr gespeichert. Neu einloggen.")
    }

    return this.clearData().pipe(
      mergeMap(() => this.login(this.email, this.password, ignoreUser)),
      tap(() => {
        if (vssBackgroundSettingsArray.length != 0) {
          vssBackgroundSettingsArray.map(({ key, value }) => this.storageService.setVSSBackgroundSettings(value, undefined, key).pipe(take(1)).subscribe())
        }
        let rerouteUrl = this.getUsersDefaultDashboardPage()
        rerouteUrl = this.instanceService.prependIdentifier(rerouteUrl)
        this.router.navigateByUrl(rerouteUrl)
        this.socketService.reconnect()
        this.isSwitchingAccountMode$.next(false)
      }),
    )
  }


  /**
   * Signs out (Removes Cookie and deletes user from 'window'-object)
   */
  public logout(): void {
    console.log("Logging out..")
    this.http.post<any>('/api/auth/logout', {}).subscribe({
      next: (response) => {
        console.log(response)

        let vssBackgroundSettingsArray = []
        this.storageService.getAllVSSBackgroundSettings()
          .subscribe((settingsArray: { key: string, value: vssBackgroundSettingsType }[]) => vssBackgroundSettingsArray = settingsArray)

        this.storageService.clearAll()
          .subscribe({
            next: () => {
              if (vssBackgroundSettingsArray.length != 0) {
                vssBackgroundSettingsArray.map(({ key, value }) => this.storageService.setVSSBackgroundSettings(value, undefined, key).pipe(take(1)).subscribe())
              }
              this.dataService.resetData();
              this.cryptoStartedInitializing = false;
              this.cryptoIsInitialized$.next(null);
              this.socketService.disjoin()
              this.rerouteService.resetUrls()
              this.user = null
              this.cookieService.removeTokens();
              this.router.navigateByUrl(this.instanceService.prependIdentifier('auth/login'))
            }, error: (error) => {
              this.adLoggerService.error(error);
              this.router.navigateByUrl(this.instanceService.prependIdentifier('auth/login'))
            }
          });

      }, error: (error) => {
        this.adLoggerService.error(error);
      }
    })
  }

  public checkLoginData(loginData: any): Observable<boolean> {
    return new Observable((observer) => {
      this.http.post<any>('/api/auth/checkLogin', loginData).subscribe((verificationResult) => {
        observer.next(verificationResult.verified);
        observer.complete();
      }, (error) => {
        observer.error(error);
        observer.complete();
      })
    })
  }

  public removeUser(): Observable<any> {
    return new Observable((observer) => {
      this.http.post<any>('/api/auth/logout', {})
        .subscribe({
          next: (response) => {
            let vssBackgroundSettingsArray = []
            this.storageService.getAllVSSBackgroundSettings()
              .subscribe((settingsArray: { key: string, value: vssBackgroundSettingsType }[]) => vssBackgroundSettingsArray = settingsArray)
            this.storageService.clearAll()
              .subscribe({
                next: () => {
                  if (vssBackgroundSettingsArray.length != 0) {
                    vssBackgroundSettingsArray.map(({ key, value }) => this.storageService.setVSSBackgroundSettings(value, undefined, key).pipe(take(1)).subscribe())
                  }
                  this.dataService.resetData();
                  this.cryptoStartedInitializing = false;
                  this.cryptoIsInitialized$.next(null);
                  this.socketService.disjoin()
                  this.rerouteService.resetUrls()
                  this.user = null
                  observer.next()
                  observer.complete()
                }, error: (error) => {
                  this.adLoggerService.error(error);
                  observer.error(error);
                  observer.complete();
                }
              });
          }, error: (error) => {
            this.adLoggerService.error(error)
            observer.error(error);
            observer.complete();
          }
        });
    })
  }


  /**
   * Initializes Crypto-Module
   * Dennis explains why this function depends on isDemo:
   * Crypto wird für Demo-Zwecke zwischengespeichert (passiert im stable nicht bzw. wäre zu unsicher), d.h. nach jeder Seitenaktualisierung muss das Passwort nicht erneuet eingegeben werden, was es leichter zu testen lässt.
   */
  public initCrypto(user: any, password: string): Observable<any> {
    this.cryptoStartedInitializing = true

    if (user && user.isManager) {
      console.log('saving user login credentials for manager accounts')
      this.email = user.email;
      this.password = password;
    }

    console.log('Initializing Crypto..', user)
    return new Observable((observer) => {
      let keystoreBuffer: ArrayBuffer;
      let certificateBuffer: ArrayBuffer;
      let saltBuffer: Uint8Array;
      if (user.isInstance) {
        keystoreBuffer = stringToArrayBuffer(fromBase64(user.security.keystore));
        certificateBuffer = stringToArrayBuffer(fromBase64(user.security.certificate));
        saltBuffer = this.utilityService.hexToBuffer(user.security.keystoreSalt);
      } else {
        keystoreBuffer = stringToArrayBuffer(fromBase64(user.keystore));
        certificateBuffer = stringToArrayBuffer(fromBase64(user.certificate));
        saltBuffer = this.utilityService.hexToBuffer(user.keystoreSalt);
      }

      this.keyService.deriveKeyFromPassword(password, this.passwordConfig.iterations, saltBuffer, this.passwordConfig.hashAlgorithm)
        .pipe(
          mergeMap((passSalt) => this.keystoreService.parseKeystore(keystoreBuffer, passSalt[0]))
        )
        .subscribe((pkcs12) => {
          this.dataService.keystore = pkcs12;
          forkJoin([
            this.keystoreService.loadPrivateKeyBuffer(pkcs12),
            this.certificateService.bufferToCertificate(certificateBuffer)
          ])
            .subscribe(([keyBuffer, certificate]) => {
              this.dataService.privateKeyBuffer = keyBuffer;
              if (environment.demoMode) {
                const privateKeybuffer = arrayBufferToString(keyBuffer);
                this.storageService.setPrivateKeyBuffer(privateKeybuffer).subscribe((saved) => {
                  if (saved) {
                    console.log('Saved Private-Key-Buffer');
                  } else {
                    this.adLoggerService.error('Couldn\'t save Private-Key-Buffer.');
                  }
                });
              }
              this.dataService.certificate = certificate;
              const encryptionModeIsArztOnly = !this.instanceService.activeInstance || !this.instanceService.activeInstance.settings || !this.instanceService.activeInstance.settings.general || this.instanceService.activeInstance.settings.general.encryptionMode !== InstanceEncryptionMode.InstanceWide
              if (!user.isInstance && !encryptionModeIsArztOnly) {
                console.log('start loading instance crypto', user)
                this.loadInstanceCrypto(user.instanceKey)
                  .subscribe(() => {
                    console.log("Successfully initialized Crypto-Module.")
                    observer.next();
                    observer.complete();
                    this.cryptoIsInitialized$.next(true);
                  }, (error) => {
                    error = { raw: error, name: 'CryptoInitializationError', userMessage: 'Fehler beim Laden der Instanz Kryptographie' };
                    observer.error(error);
                    this.cryptoIsInitialized$.error(error);
                    this.notificationService.displayNotification("Fehler beim Initialisieren des Nutzers.")
                  });

              } else {
                console.log("Successfully initialized Crypto-Module.")
                observer.next();
                observer.complete();
                this.cryptoIsInitialized$.next(true);
              }
            }, (error) => {
              error = { raw: error, name: 'CryptoInitializationError', userMessage: 'Fehler beim Initialisieren des Krypto-Moduls' }
              observer.error(error);
              this.cryptoIsInitialized$.error(error);
              this.notificationService.displayNotification("Fehler beim Initialisieren des Nutzers.")
            });
        },
          (error) => {
            error = { raw: error, name: 'CryptoInitializationError', userMessage: 'Fehler beim Initialisieren des Krypto-Moduls' }
            observer.error(error);
          });
    });
  }

  private loadInstanceCrypto(instanceKey: string): Observable<any> {
    return new Observable((observer) => {
      if (!instanceKey) {
        observer.error('Missing instance decryption key');
        observer.complete();
        return;
      }
      forkJoin([
        this.loadInstanceCertificate(),
        this.cryptoService.decrypt(this.dataService.privateKeyBuffer, this.dataService.certificate, instanceKey, true)
      ]).subscribe(([instanceCertificate, instancePrivateKey]) => {
        this.dataService.instanceCertificate = instanceCertificate;
        this.dataService.instancePrivateKeyBuffer = stringToArrayBuffer(fromBase64(instancePrivateKey));
        observer.next();
        observer.complete();
      }, (error) => {
        this.adLoggerService.error(error)
        observer.error(error);
        observer.complete();
      });
    })
  }

  private loadInstanceCertificate(): Observable<Certificate> {
    return new Observable((observer) => {
      this.instanceService.getCertificate(this.instanceService.activeInstance.identifier)
        .pipe(
          mergeMap((certResponse) => this.certificateService.bufferToCertificate(stringToArrayBuffer(fromBase64(certResponse.certificate))))
        )
        .subscribe((certificate) => {
          observer.next(certificate);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
    })
  }


  /**
   * Re-Initializes Crypto-Module
   * NOTE: Only used in Development-Environment when key is stored
   */
  public reloadCrypto(): void {
    this.cryptoStartedInitializing = true

    const user = (<any>window).user;
    const isArztWithCert = user && user.isArzt && user.certificate
    const isInstanceWithCert = user && user.isInstance && user.security && user.security.certificate

    if (isArztWithCert || isInstanceWithCert) {
      const certificate = isArztWithCert ? user.certificate : user.security.certificate
      const certificateBuffer = stringToArrayBuffer(fromBase64(certificate));
      forkJoin([
        this.storageService.getPrivateKeyBuffer(),
        this.certificateService.bufferToCertificate(certificateBuffer)
      ]).pipe(
        map((result) => {
          if (result[0] && result[1]) return result
          else throw { raw: result, name: "PrivateKeyInitializationError" }
        })
      ).subscribe((result) => {
        this.dataService.privateKeyBuffer = stringToArrayBuffer(result[0]);
        this.dataService.certificate = result[1];
        const encryptionModeIsArztOnly = !this.instanceService.activeInstance || !this.instanceService.activeInstance.settings || !this.instanceService.activeInstance.settings.general || this.instanceService.activeInstance.settings.general.encryptionMode !== InstanceEncryptionMode.InstanceWide
        if (encryptionModeIsArztOnly || isInstanceWithCert) {
          this.cryptoIsInitialized$.next(true);
        } else {
          this.loadInstanceCrypto(user.instanceKey)
            .subscribe(() => {
              this.cryptoIsInitialized$.next(true);
            }, (error) => {
              this.adLoggerService.error(error);
              this.cryptoIsInitialized$.error({ raw: error, userMessage: 'Fehler beim Initialisieren des Krypto-Moduls' });
              this.logout();
            });
        }

      }, (error) => {
        this.adLoggerService.error(error);
        this.cryptoIsInitialized$.error({ raw: error, userMessage: 'Fehler beim Initialisieren des Krypto-Moduls' });
        this.logout();
      });
    }
  }


  /**
   * Returns the according Dashboard Starting-Page for the current user
   */
  public getUsersDefaultDashboardPage(): string {
    if (!this.user) {
      console.warn("Can't return dashboard-default page as no user exists.", this.user)
      return '/auth/logout'
    }

    if (!this.user.isInstance && this.user.status !== UserStatus.Freigeschaltet) {
      return '/auth/pending'

    } else if (this.user.isAdmin) {
      return '/dashboard/instanzen#aktiviert'

    } else if (this.user.isInstance) {
      return '/dashboard/instanz#allgemein'

    } else if (this.user.isArzt && this.instanceService.activeInstance && this.instanceService.activeInstance.settings && this.instanceService.activeInstance.settings.general && this.instanceService.activeInstance.settings.general.waitingListModules && this.instanceService.activeInstance.settings.general.waitingListModules.length && this.instanceService.activeInstance.settings.general.waitingListModules.includes(InstanceWaitingListModules.AllPatients)) {
      return '/dashboard/warteliste'

      /*} else if (this.user.isArzt && this.instanceService.activeInstance && this.instanceService.activeInstance.settings && this.instanceService.activeInstance.settings.general && this.instanceService.activeInstance.settings.general.waitingListModules && this.instanceService.activeInstance.settings.general.waitingListModules.length && this.instanceService.activeInstance.settings.general.waitingListModules.includes(InstanceWaitingListModules.NextPatient)) {
        return '/dashboard/nächster'*/

    } else if (this.user.isArzt) {
      return '/dashboard/behandlungen'
    }

    return '/auth/logout'
  }

}
