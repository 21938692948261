import { BookingStep } from '@a-d/entities/Booking.entity'
import { NgClass, NgIf } from '@angular/common'
import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Observable, Subscriber, Subscription, of } from 'rxjs'
import { delay, mergeMap, startWith, tap } from 'rxjs/operators'
import { BookingService } from '../booking.service'

@UntilDestroy()
@Component({
  selector: 'app-booking-anamnese',
  templateUrl: './booking-anamnese.component.html',
  styleUrls: ['./booking-anamnese.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass]
})
export class BookingAnamneseComponent implements OnInit, AfterViewInit {
  @Input() isHeaderOnly: boolean
  @ViewChild('anamneseFormHost', { read: ViewContainerRef }) anamneseFormHost: ViewContainerRef
  get anamneseFormComponent() { return this.bookingService.anamneseFormComponent }
  instructions: string
  isMobile: boolean
  subscriptionAppointmentTypeValueChanges
  subscriptionMagicFill: Subscription
  constructor(
    private bookingService: BookingService,
  ) { }

  ngOnInit() {
    this.isMobile = this.bookingService.isMobile()
  }

  ngAfterViewInit() {
    this.subscribeAppointmentTypeValueChanges()
  }

  subscribeAppointmentTypeValueChanges() {
    if (this.subscriptionAppointmentTypeValueChanges)
      this.subscriptionAppointmentTypeValueChanges.unsubscribe()
    this.subscriptionAppointmentTypeValueChanges = this.bookingService.appointmentType.valueChanges
      .pipe(
        startWith(true),
        mergeMap(() => {
          const anamneseExists = this.bookingService.doesAnamneseFormExist()
          if (anamneseExists && this.bookingService.appointmentType.value && !this.isHeaderOnly)
            return of(null).pipe(
              mergeMap(() => this.bookingService.createAnamneseFormComponent(this.anamneseFormHost)),
              mergeMap(() => this.magicFillSubscription()),
            )
          else
            return of(null)
        }),
        tap(() => this.instructions = this.bookingService.anamneseFormInstructions)
      ).subscribe()
  }

  // perform magic fill (fill the fields with preset values)
  magicFillSubscription(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      if (this.subscriptionMagicFill)
        this.subscriptionMagicFill.unsubscribe()
      this.subscriptionMagicFill = this.bookingService.magicFill$
        .pipe(untilDestroyed(this))
        .subscribe((magicFill: string) =>
          magicFill === BookingStep.bookingAnamnese ? this.magicFill() : null
        )
      subscriber.next()
      subscriber.complete()
    })
  }

  magicFill() {
    of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          this.anamneseFormComponent.magicFill()
          this.anamneseFormComponent.formGroup.updateValueAndValidity({ onlySelf: false, emitEvent: true })
          return of(null)
        }),
        delay(500),
        mergeMap(() => {
          this.bookingService.stepper.next()
          this.bookingService.magicFill$.next(BookingStep.bookingPersonal)
          return of(null)
        })
      ).subscribe()
  }
}
