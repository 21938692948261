<div *ngIf="isPreviewMode || showAnnouncement" class="announcement">
  <span>
    <fa-icon [icon]="['fas','megaphone']"></fa-icon>
  </span>
  <ng-container *ngIf="announcementUrl">
    <a href='{{announcementUrl}}'>{{ announcementText}}</a>
  </ng-container>
  <ng-container *ngIf="!announcementUrl">
    {{ announcementText}}
  </ng-container>
</div>
