import { BookingOpening, OtkDoctor } from '@a-d/entities/Booking.entity'
import { LanguageService } from '@a-d/i18n/language.service'
import { InstanceService } from '@a-d/instance/instance.service'


import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'

import { environment } from '@env/environment'
import { UntilDestroy } from '@ngneat/until-destroy'
import { TranslateModule } from '@ngx-translate/core'
import { mergeMap, Observable, of, Subscriber } from 'rxjs'
import { BookingService } from '../booking.service'

interface MessageErrorKeyValuePair {
  key: string
  value: string
}
@UntilDestroy()
@Component({
  selector: 'app-booking-error',
  templateUrl: './booking-error.component.html',
  styleUrls: ['./booking-error.component.scss'],
  standalone: true,
  imports: [TranslateModule]
})
export class BookingErrorComponent implements OnInit {
  messageError: MessageErrorKeyValuePair[]

  constructor(
    private bookingService: BookingService,
    private languageService: LanguageService,
    private instanceService: InstanceService,
    private httpClient: HttpClient
  ) { }

  ngOnInit() {
    of(null).pipe(
      mergeMap(() => this.setMessageError()),
      // mergeMap(() => this.sendEmail())
    ).subscribe()
  }

  setMessageError(): Observable<any> {
    return new Observable<any>((subscriber: Subscriber<any>) => {
      const personalFormGroupValues: any = this.bookingService.personalFormComponent.formGroup.value
      const personalFormValuesZipped: MessageErrorKeyValuePair[] = Object.keys(personalFormGroupValues) // returns keys of object {fname: 'tom', lname: ...}, for instance fname,
        .filter((key: string) => personalFormGroupValues[key]) // filter out empty strings
        .map((key: string) => {
          return {
            key, value:
              typeof personalFormGroupValues[key] === 'string'
                ? personalFormGroupValues[key]
                : personalFormGroupValues[key].toString() // for instance convert privacyPolicy's boolean value to string
          }
        })
      const separator: string = ',\n'
      this.messageError = []
      // general
      this.messageError.push({ key: 'isInApp', value: String(this.bookingService.isInApp) })
      this.messageError.push({ key: 'Instance id', value: this.instanceService.activeInstance._id })
      this.messageError.push({ key: 'Instance identifier', value: this.instanceService.activeInstance.identifier })
      if (this.bookingService.error.name)
        this.messageError.push({ key: 'Uhrzeit', value: new Date().toISOString() })
      this.messageError.push({ key: 'Error message name', value: this.bookingService.error.name })
      if (this.bookingService.error.message)
        this.messageError.push({ key: 'Error message description', value: this.bookingService.error.message })
      // booking-preliminary
      if (this.bookingService.isKnown.value === true)
        this.messageError.push({ key: 'Bestandspatient', value: this.bookingService.isKnown.value.toString() })
      if (this.bookingService.insuranceType.value)
        this.messageError.push({ key: 'Versicherungsart', value: this.bookingService.insuranceType.value })
      if (this.bookingService.birthDate.value)
        this.messageError.push({ key: 'Geburtsdatum', value: this.bookingService.birthDate.value.toISOString() })
      // booking-betriebsstätte
      if (this.bookingService.bs.value?._id)
        this.messageError.push({ key: 'Betriebsstätte id', value: this.bookingService.bs.value._id })
      // booking-type
      if (this.bookingService.appointmentType.value) {
        this.messageError.push({ key: 'Terminart id', value: this.bookingService.appointmentType.value._id })
        this.messageError.push({ key: 'Terminart Name de', value: this.bookingService.appointmentType.value.name.de })
      }
      // booking-date      
      const otkDoctor: OtkDoctor = this.bookingService.doctor.value as OtkDoctor
      if (otkDoctor?.ident)
        this.messageError.push({ key: 'Arzt ident', value: otkDoctor.ident })
      // if ((this.bookingService.opening.value as BookingOpening)?.date?.toISOString())
      //   this.messageError.push({ key: 'Opening date', value: (this.bookingService.opening.value as BookingOpening).date.toISOString() })
      if ((this.bookingService.opening.value as BookingOpening)?.displayStringTime)
        this.messageError.push({ key: 'Opening displayed string', value: (this.bookingService.opening.value as BookingOpening).displayStringTime })
      if ((this.bookingService.opening.value as BookingOpening)?.displayStringNames)
        this.messageError.push({ key: 'Opening doctors', value: (this.bookingService.opening.value as BookingOpening).displayStringNames })
      // booking-personal
      this.messageError = this.messageError.concat(personalFormValuesZipped)
      // appointment
      if (this.bookingService.otkAppointment)
        this.messageError.push({ key: 'Appointment', value: JSON.stringify(this.bookingService.otkAppointment) })
      subscriber.next()
      subscriber.complete()
    })
  }

  sendEmail(): Observable<any> {
    const params = {
      instanceId: this.instanceService.activeInstance._id,
      messageError: this.messageError,
      locale: this.languageService.activeBaseLang
    };
    return this.httpClient.post(`${environment.otkUrl}/api/email/send-error-report`, params)
  }

}

