import { WfaForm } from '../../../interfaces-autogen-from-joi/wfa-form'
import adi3110 from './bugs/adi-3110.json'
import adi3232 from './bugs/adi-3232.json'
import adi3265 from './bugs/adi-3265.json'
import adi3265s2 from './bugs/adi-3265-s2.json'
import adi3265s5 from './bugs/adi-3265-s5.json'
import adi3319 from './bugs/adi-3319.json'
import adi3325 from './bugs/adi-3325.json'
import adi3325x from './bugs/adi-3325-x.json'
import sup72129 from './bugs/sup-72129.json'
import depraecatedWfaFoerm from './depraecated-wfa-foerm.json'
import dummyForm from './dummy-form-01.json'
import extendedQuestionMix from './extended-question-mix.json'
import fallbackLanguage from './fallback-language.json'
import fileUpload from './file-upload.json'
import multiPage from './multi-page.json'
import multiRow from './multi-row.json'
import multilinkA1 from './multilink/multilink-a1.json'
import multilinkA2 from './multilink/multilink-a2.json'
import multilinkA3 from './multilink/multilink-a3.json'
import multilinkA4 from './multilink/multilink-a4.json'
import questionMix from './question-mix.json'
import questionMixWithPassword from './question-mix-with-password.json'
import questionMixWithWfaKeys from './question-mix-with-wfa-keys.json'
import requiredOnCondition from './required-on-condition.json'
import signaturepad from './signaturepad.json'
import validators from './validators.json'
import visibleIfOnPageLevel from './visible-if-on-page-level.json'
import visibleOnCondition from './visible-on-condition.json'

export const testing = {
  multilinkA1: multilinkA1 as unknown as WfaForm,
  multilinkA2: multilinkA2 as unknown as WfaForm,
  multilinkA3: multilinkA3 as unknown as WfaForm,
  multilinkA4: multilinkA4 as unknown as WfaForm,
  adi3110: adi3110 as unknown as WfaForm,
  adi3232: adi3232 as unknown as WfaForm,
  adi3265: adi3265 as unknown as WfaForm,
  adi3265s2: adi3265s2 as unknown as WfaForm,
  adi3265s5: adi3265s5 as unknown as WfaForm,
  adi3319: adi3319 as unknown as WfaForm,
  adi3325: adi3325 as unknown as WfaForm,
  adi3325x: adi3325x as unknown as WfaForm,
  depraecatedWfaFoerm: depraecatedWfaFoerm as unknown as WfaForm,
  dummyForm: dummyForm as WfaForm,
  extendedQuestionMix: extendedQuestionMix as WfaForm,
  fallbackLanguage: fallbackLanguage as WfaForm,
  fileUpload: fileUpload as WfaForm,
  multiPage: multiPage as WfaForm,
  multiRow: multiRow as WfaForm,
  questionMix: questionMix as WfaForm,
  questionMixWithPassword: questionMixWithPassword as WfaForm,
  questionMixWithWfaKeys: questionMixWithWfaKeys as WfaForm,
  requiredOnCondition: requiredOnCondition as WfaForm,
  sup72129: sup72129 as WfaForm,
  signaturepad: signaturepad as WfaForm,
  validators: validators as WfaForm,
  visibleIfOnPageLevel: visibleIfOnPageLevel as WfaForm,
  visibleOnCondition: visibleOnCondition as WfaForm,
} as const
