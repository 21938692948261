import { safeStructuredClone } from '@arzt-direkt/wfa-generic-utils'
import { allGridProperties as tsv148 } from './tsv-leq-148'

/**
 * Settings for tsv149 were originally different from 148,
 * but on closer inspection it was possible to keep them
 * equal to 148. This code is kept here as reference how
 * to change these properties dependent on tomedo version.
 */
export const allGridProperties = safeStructuredClone(tsv148)
