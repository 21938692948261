import {
  Maybe,
  newWfaMessage,
  nullish,
  validate,
  WfaMessage,
} from '@arzt-direkt/wfa-generic-utils'
import Joi from 'joi'

import { WfaForm } from './../interfaces-autogen-from-joi/wfa-form'
import { wfaFormJoiDict } from './../joi/wfa-form.joi'
import { selectVersion } from './select-version'

export function validateWfaForm(form: Maybe<unknown>): WfaForm | WfaMessage {
  if (nullish(form))
    return newWfaMessage('[validateWfaForm]: no message provided')
  const wfaVersion = (form as WfaForm).wfaVersion ?? 'v1.5.0'
  const wfaFormJoi =
    selectVersion(wfaVersion, wfaFormJoiDict) ??
    (wfaFormJoiDict['v1.5.0'] as unknown as Joi.ObjectSchema<WfaForm>)
  return validate(wfaFormJoi, form) as unknown as WfaForm | WfaMessage
}
