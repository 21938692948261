import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { SocketUserRole } from '@a-d/entities/Socket.entity';
import { PatientSessionService } from '@a-d/patient-session/patient-session.service';
import { SocketService } from '@a-d/socket/socket.service';
import { Injectable } from '@angular/core';
//import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Bowser from 'bowser';
import { Subject } from 'rxjs';
import { CandidateState, CandidateType, StatReport, StatReportCandidate, StatReportCandidatePair, StatReportType } from '../entities/peerConnectionStats.entity';
import { WebRTCConnectionType, WebRTCStatisticData } from '../entities/WebRTC.entity';
import { GeneralUtilityService } from '../misc/general-utility.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class PeerConnectionStatsService {

  public iceCandidateTypeStatus$ = new Subject<{ connection: WebRTCConnectionType, candidate: CandidateType }>()

  private localCandidateTypes: string[] = []
  private remoteCandidateTypes: string[] = []

  private browserUsed: string = null

  private jitterAudio: WebRTCStatisticData = { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
  private jitterVideo: WebRTCStatisticData = { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
  private remoteJitterAudio: WebRTCStatisticData = { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
  private remoteJitterVideo: WebRTCStatisticData = { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
  private rttAudio: WebRTCStatisticData = { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
  private rttVideo: WebRTCStatisticData = { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
  private averageRtt: WebRTCStatisticData = { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }

  private byteExchangeLast: { bytesSent: number, bytesReceived: number, timestamp: number } = { bytesSent: 0, bytesReceived: 0, timestamp: 0 }
  private bitrateIn: WebRTCStatisticData = { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
  private bitrateOut: WebRTCStatisticData = { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }

  private packetExchangeLastAudio: { packetsReceived: number, packetsLost: number, timestamp: number } = { packetsReceived: 0, packetsLost: 0, timestamp: 0 }
  private packetExchangeLastVideo: { packetsReceived: number, packetsLost: number, timestamp: number } = { packetsReceived: 0, packetsLost: 0, timestamp: 0 }
  private packetLossAudio: WebRTCStatisticData = { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
  private packetLossVideo: WebRTCStatisticData = { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }

  constructor(
    private adLoggerService: AdLoggerService,
    private utilityService: GeneralUtilityService,
    private patientSessionService: PatientSessionService,
    private socketService: SocketService
  ) { }

  public updateActiveIceCandidateType(pc: RTCPeerConnection, connection: WebRTCConnectionType): void {
    pc.getStats()
      .then((stats: RTCStatsReport) => {
        this.analyzeCandidateReports(stats, connection);
      })
      .catch((error) => {
        this.adLoggerService.error(error);
      });
  }

  public analyzeCandidateReports(stats: RTCStatsReport, connection: WebRTCConnectionType) {
    stats.forEach((report: StatReport) => {
      if (report && report.type === StatReportType.CandidatePair) {
        const candidatePairReport = <StatReportCandidatePair>report;
        if (candidatePairReport.nominated && candidatePairReport.state === CandidateState.Succeeded) {
          this.analyzeActiveIceCandidateReports(
            candidatePairReport.localCandidateId, candidatePairReport.remoteCandidateId, stats, connection);
        }
      }
    })
  }

  public analyzeActiveIceCandidateReports(localIceCandidateId: string, remoteIceCandidateId: string,
    stats: RTCStatsReport, connection: WebRTCConnectionType) {
    stats.forEach((report: StatReport) => {
      switch (report.type) {
        case StatReportType.LocalCandidate:
        case StatReportType.RemoteCandidate:
          if (!(report.id === localIceCandidateId || report.id === remoteIceCandidateId)) { break; }
          this.updateIceConnectionType(report, connection);
          break;
        default: break;
      }
    })
  }

  public updateIceConnectionType(report: StatReport, connection: WebRTCConnectionType): void {
    if (!report || !report['candidateType']) { return; }
    const candidateReport = <StatReportCandidate>report;
    this.iceCandidateTypeStatus$.next({
      connection,
      candidate: candidateReport.candidateType
    });
  }


  public createWebRTCStatistics(connection: RTCPeerConnection, userRole: SocketUserRole, sessionId: string) {
    //if (!environment.demoMode) return
    if (userRole != 'arzt' && userRole != 'patient') return;
    //console.log("CONNECTION STATS:", this.patientSessionService.activeSession$.value.connectionStatistics)
    if (userRole === 'arzt') this.initData();
    const browserName = Bowser.getParser(window.navigator.userAgent).getBrowserName() || "-";
    const browserVersion = Bowser.getParser(window.navigator.userAgent).getBrowserVersion() ? ' ' + Bowser.getParser(window.navigator.userAgent).getBrowserVersion() : "";
    const platformType = Bowser.getParser(window.navigator.userAgent).getPlatformType() ? ' (' + Bowser.getParser(window.navigator.userAgent).getPlatformType() + ')' : "";
    this.browserUsed = browserName + browserVersion + platformType;
    if (userRole === 'arzt') {
      let times = 0
      var statisticInterval = setInterval(() => {
        if (connection.connectionState === "closed") {
          clearInterval(statisticInterval);
          return
        };
        connection.getStats(null).then((stats) => {
          let localCandidateId = null
          let remoteCandidateId = null

          stats.forEach((report) => {
            // inbound-rtp
            if (report.type === "inbound-rtp" && report.kind === "audio") {
              if (report['jitter'] || report['jitter'] === 0) {
                this.setStatisticData(this.jitterAudio, report['jitter'])
              }
              if ((report['packetsLost'] || report['packetsLost'] === 0) && (report['packetsReceived'] || report['packetsReceived'] === 0)) {
                if (this.packetExchangeLastAudio.timestamp != 0 && this.packetExchangeLastAudio.packetsReceived < report['packetsReceived']) {
                  const receivedSinceLast = report['packetsReceived'] - this.packetExchangeLastAudio.packetsReceived
                  const lostSinceLast = report['packetsLost'] - this.packetExchangeLastAudio.packetsLost > 0 ? (report['packetsLost'] - this.packetExchangeLastAudio.packetsLost) : 0
                  const packetLoss = lostSinceLast / (lostSinceLast + receivedSinceLast)
                  this.setStatisticData(this.packetLossAudio, packetLoss)
                }
                this.packetExchangeLastAudio = { packetsReceived: report['packetsReceived'], packetsLost: report['packetsLost'], timestamp: report['timestamp'] }
              }
            }
            if (report.type === "inbound-rtp" && report.kind === "video") {
              if (report['jitter'] || report['jitter'] === 0) {
                this.setStatisticData(this.jitterVideo, report['jitter'])
              }
              if ((report['packetsLost'] || report['packetsLost'] === 0) && (report['packetsReceived'] || report['packetsReceived'] === 0)) {
                if (this.packetExchangeLastVideo.timestamp != 0 && this.packetExchangeLastVideo.packetsReceived < report['packetsReceived']) {
                  const receivedSinceLast = report['packetsReceived'] - this.packetExchangeLastVideo.packetsReceived
                  const lostSinceLast = report['packetsLost'] - this.packetExchangeLastVideo.packetsLost > 0 ? (report['packetsLost'] - this.packetExchangeLastVideo.packetsLost) : 0
                  const packetLoss = lostSinceLast / (lostSinceLast + receivedSinceLast)
                  this.setStatisticData(this.packetLossVideo, packetLoss)
                }
                this.packetExchangeLastVideo = { packetsReceived: report['packetsReceived'], packetsLost: report['packetsLost'], timestamp: report['timestamp'] }
              }
            }

            // remote-inbound-rtp
            if (report.type === "remote-inbound-rtp" && report.kind === "audio") {
              if (report['jitter']) {
                this.setStatisticData(this.remoteJitterAudio, report['jitter'])
              }
              if (report['roundTripTime']) {
                this.setStatisticData(this.rttAudio, report['roundTripTime'])
              }
            }
            if (report.type === "remote-inbound-rtp" && report.kind === "video") {
              if (report['jitter']) {
                this.setStatisticData(this.remoteJitterVideo, report['jitter'])
              }
              if (report['roundTripTime']) {
                this.setStatisticData(this.rttVideo, report['roundTripTime'])
              }
            }

            // candidate-pair
            if (report.type === "candidate-pair" && report.nominated && report.state === CandidateState.Succeeded) {
              if (report['totalRoundTripTime'] && report['responsesReceived']) {
                let averageRtt = report['totalRoundTripTime'] / report['responsesReceived']
                this.setStatisticData(this.averageRtt, averageRtt)
              }
              remoteCandidateId = report.remoteCandidateId
              localCandidateId = report.localCandidateId
              if (report['bytesSent'] && report['bytesReceived']) {
                if (this.byteExchangeLast.timestamp != 0 && this.byteExchangeLast.bytesSent < report['bytesSent'] && this.byteExchangeLast.bytesReceived < report['bytesReceived']) {
                  const bytesOut = report['bytesSent'] - this.byteExchangeLast.bytesSent
                  const bytesIn = report['bytesReceived'] - this.byteExchangeLast.bytesReceived
                  let bitrateIn = (bytesIn * 8 / (report['timestamp'] - this.byteExchangeLast.timestamp)) / 1000 //Mbit/s
                  let bitrateOut = (bytesOut * 8 / (report['timestamp'] - this.byteExchangeLast.timestamp)) / 1000 //Mbit/s
                  this.setStatisticData(this.bitrateIn, bitrateIn)
                  this.setStatisticData(this.bitrateOut, bitrateOut)
                }
                this.byteExchangeLast.timestamp = report['timestamp']
                this.byteExchangeLast.bytesReceived = report['bytesReceived']
                this.byteExchangeLast.bytesSent = report['bytesSent']
              }
            }
            if (report.type === "local-candidate") {
              if (report['id'] === localCandidateId && !this.localCandidateTypes.includes(report.candidateType)) {
                this.localCandidateTypes.push(report.candidateType)
              }
            }
            if (report.type === "remote-candidate") {
              if (report['id'] === remoteCandidateId && !this.remoteCandidateTypes.includes(report.candidateType)) {
                this.remoteCandidateTypes.push(report.candidateType)
              }
            }
          }
          );
        });
        // saves every 15 seconds in front-end/local storage and every 30 seconds in data base
        times += 1
        if (times === 3) {
          this.saveWebRTCStatistics(sessionId, userRole, false)
        }
        if (times === 6) {
          this.saveWebRTCStatistics(sessionId, userRole, true)
          times = 0
        }
      }, 5000);
    }
  }

  private initData() {
    const connectionStatistics = this.patientSessionService.activeSession$.value?.connectionStatistics
    this.browserUsed = connectionStatistics?.browserDoctor || null
    this.localCandidateTypes = connectionStatistics?.candidateTypeDoctor || []
    this.remoteCandidateTypes = connectionStatistics?.candidateTypePatient || []
    this.jitterAudio = connectionStatistics?.jitterAudioDoctor || { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
    this.jitterVideo = connectionStatistics?.jitterVideoDoctor || { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
    this.remoteJitterAudio = connectionStatistics?.jitterAudioPatient || { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
    this.remoteJitterVideo = connectionStatistics?.jitterVideoPatient || { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
    this.rttAudio = connectionStatistics?.rttAudio || { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
    this.rttVideo = connectionStatistics?.rttVideo || { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
    this.averageRtt = connectionStatistics?.averageRtt || { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
    this.bitrateIn = connectionStatistics?.bitrateIn || { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
    this.bitrateOut = connectionStatistics?.bitrateOut || { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
    this.packetLossAudio = connectionStatistics?.packetLossAudio || { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
    this.packetLossVideo = connectionStatistics?.packetLossVideo || { totalSum: 0, max: undefined, min: undefined, valueCount: 0 }
  }

  private setStatisticData(statisticData: WebRTCStatisticData, reportData: number) {
    statisticData.totalSum += reportData
    if (!(statisticData.max > reportData)) statisticData.max = reportData
    if (!(statisticData.min < reportData)) statisticData.min = reportData
    statisticData.valueCount += 1
  }

  public saveWebRTCStatistics(sessionId: string, userRole: SocketUserRole, saveInDatabase: boolean = false) {
    //if (!environment.demoMode) return
    if (userRole != 'arzt' && userRole != 'patient') return;
    let connectionStatistics;
    if (userRole === 'arzt') {
      connectionStatistics = {
        browserDoctor: this.browserUsed,
        candidateTypeDoctor: this.localCandidateTypes,
        candidateTypePatient: this.remoteCandidateTypes,
        jitterAudioDoctor: this.jitterAudio,
        jitterVideoDoctor: this.jitterVideo,
        jitterAudioPatient: this.remoteJitterAudio,
        jitterVideoPatient: this.remoteJitterVideo,
        rttAudio: this.rttAudio,
        rttVideo: this.rttVideo,
        averageRtt: this.averageRtt,
        bitrateIn: this.bitrateIn,
        bitrateOut: this.bitrateOut,
        packetLossAudio: this.packetLossAudio,
        packetLossVideo: this.packetLossVideo
      }
    };
    if (userRole === 'patient') {
      connectionStatistics = {
        browserPatient: this.browserUsed
      }
    };
    if (saveInDatabase) this.socketService.sendSessionConnectionStatisticsUpdate(sessionId, connectionStatistics);
    this.patientSessionService.updateConnectionStatistics(connectionStatistics).pipe(untilDestroyed(this)).subscribe() //Update activeSession 'this' and localStorage
  }

}
