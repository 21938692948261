import { MatDialog } from '@angular/material/dialog'
import { ViewerEnvState } from '@arzt-direkt/wfa-definitions'
import { take } from 'rxjs'
import { ClearFilesEvent } from 'survey-core'

import { DeletionDialogComponent } from './deletion-dialog/deletion-dialog.component'

export function handleFileDeletion(
  options: ClearFilesEvent,
  dialog: MatDialog,
  env?: ViewerEnvState,
) {
  const dialogRef = dialog.open(DeletionDialogComponent, {
    autoFocus: false,
    data: {
      fileName: options.fileName,
      locale: env?.locale,
      isEmbeddedIn: env?.isEmbeddedIn,
    },
  })

  dialogRef
    .afterClosed()
    .pipe(take(1))
    .subscribe((result) => {
      if (result === 'delete') options.callback('success', options.value)
    })
}
