import { ActiveInstance } from '@a-d/entities/ActiveInstance.entity';
import { InstanceForm } from '@a-d/entities/InstanceForm.entity';
import { FormHelpers } from '@a-d/forms/form-helpers.service';
import { InstanceService } from '@a-d/instance/instance.service';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import dayjs from 'dayjs';
import { environment } from '../../../../environments/environment';
import FormValidators from '../../../forms/form-validators.service';
import { UsefulComponent } from '../../../misc/useful.component';
import { InstanceFormInterface } from '../../instance-form-component.interface';
import { InstanceFormService } from '../../instance-form.service';
import { AnamneseForm } from '../anamnese-forms.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectionFieldComponent } from '../../../forms/fields/selection-field.component';
import { InfoBannerComponent } from '../../../wrapper/info-banner.component';
import { ADValidators } from '@a-d/forms/validators/a-d-validators';


export interface OpKontrolleAnamneseFormSettings {
  readonly opOptions: string[]
  readonly surgeonOptions: string[]
}


@Component({
    selector: 'app-op-kontrolle-anamnese-form',
    templateUrl: './op-kontrolle-anamnese-form.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        InfoBannerComponent,
        SelectionFieldComponent,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        NgIf,
        TranslateModule,
    ],
})
export class OpKontrolleAnamneseFormComponent extends UsefulComponent implements OnInit, InstanceFormInterface {

  @Input() anamneseForm: AnamneseForm
  @Input() settings: OpKontrolleAnamneseFormSettings


  /**
   * Form-Group
   */
  public MIN_DATE_FILTER = FormValidators.getMinimumAgeDateFilter(0)
  public formGroup = new UntypedFormGroup({
    opSelection: new UntypedFormControl(null),
    opDescription: new UntypedFormControl(null),
    // opSide: new FormControl(null),
    opDate: new UntypedFormControl(null, [ADValidators.datesRelation('before', dayjs(), false)]),
    surgeonSelection: new UntypedFormControl(null),
  })

  public get opSelection(): AbstractControl { return this.formGroup.get('opSelection') }
  public get opDescription(): AbstractControl { return this.formGroup.get('opDescription') }
  // public get opSide(): AbstractControl { return this.formGroup.get('opSide') }
  public get opDate(): AbstractControl { return this.formGroup.get('opDate') }
  public get surgeonSelection(): AbstractControl { return this.formGroup.get('surgeonSelection') }


  /**
    * Current Instance & Instance-Form Helpers
   */
  public get activeInstance(): ActiveInstance { return this.instanceService.activeInstance }
  public get activeInstanceForm(): InstanceForm { return this.instanceFormService.activeInstanceForm }
  public readonly isDemoMode = environment.demoMode


  constructor(
    private instanceService: InstanceService,
    private instanceFormService: InstanceFormService,
    private formHelpers: FormHelpers,
    private cd: ChangeDetectorRef,
  ) {
    super()
    this.formHelpers.syncActiveAnamneseFormWithLocalStorage(this.activeInstanceForm, this.formGroup, this.unsubscribe$)
  }

  // ---------------------//
  // InstanceFormInterface //
  // ---------------------//

  public getData(): any {
    return this.formHelpers.trimmedRawValue(this.formGroup)
  }

  public getTomedoData(): any {
    const formData = this.formHelpers.trimmedRawValue(this.formGroup)

    return {
      ...formData,
      opDate: formData['opDate'] ? dayjs(formData['opDate']).format('DD.MM.YYYY') : null,
    }
  }

  public magicFill() {
    if (!this.isDemoMode) return

    const randomSelection = this.settings.opOptions[Math.floor(Math.random() * this.settings.opOptions.length)]
    this.opSelection.setValue(randomSelection)

    this.cd.detectChanges()
  }

}
