<form class="c-form c-form--fullHeight c-form--largerSpacing" [formGroup]="formGroup">

  <app-selection-field-component attr.data-selenium-id="selection-field-beschwerden" [formGroup]="formGroup"
    [options]="settings.diseaseOptions" selectionName="diseaseSelection" descriptionName="diseaseDescription"
    [selectionPlaceholder]="'ANAMNESE-FORM.COMPLAIN-TITLE' | translate"
    [descriptionPlaceholder]="'ANAMNESE-FORM.COMPLAIN-TEXT' | translate"
    [sonstigesOptionUndertitle]="'GLOBAL.DESCRIBE-OTHER'|translate" [sonstigesOption]="'GLOBAL.OTHER'|translate">
  </app-selection-field-component>


  <mat-form-field attr.data-selenium-id="field-verlauf" appearance="outline">
    <mat-label>{{'ANAMNESE-FORM.COMPLAIN-HISTORY-TITLE' | translate}}</mat-label>
    <textarea matInput cdkTextareaAutosize formControlName="diseaseHistory" mat-autosize=true cdkAutosizeMinRows=1
      cdkAutosizeMaxRows=5></textarea>
    <mat-hint>{{'ANAMNESE-FORM.COMPLAIN-HISTORY-TEXT' | translate}}</mat-hint>
  </mat-form-field>


  <div class="c-form__row" *ngIf="settings.showPreExistingDiseases || settings.showPreExistingAllergies">

    <mat-form-field attr.data-selenium-id="field-vorerkrankungen" *ngIf="settings.showPreExistingDiseases"
      appearance="outline">
      <mat-label>{{'ANAMNESE-FORM.HISTORY-TITLE' | translate}}</mat-label>
      <textarea matInput cdkTextareaAutosize formControlName="preExistingDiseases" mat-autosize=true
        cdkAutosizeMinRows=1 cdkAutosizeMaxRows=2></textarea>
      <mat-hint>{{'ANAMNESE-FORM.HISTORY-TEXT' | translate}}</mat-hint>
    </mat-form-field>

    <mat-form-field attr.data-selenium-id="field-allergien" *ngIf="settings.showPreExistingAllergies"
      appearance="outline">
      <mat-label>{{'ANAMNESE-FORM.ALLERGIES-TITLE' | translate}}</mat-label>
      <textarea matInput cdkTextareaAutosize formControlName="preExistingAllergies" mat-autosize=true
        cdkAutosizeMinRows=1 cdkAutosizeMaxRows=2></textarea>
      <mat-hint>{{'ANAMNESE-FORM.ALLERGIES-TEXT' | translate}}</mat-hint>
    </mat-form-field>

  </div>


  <mat-form-field attr.data-selenium-id="field-medikation" *ngIf="settings.showMedication" appearance="outline">
    <mat-label>{{'ANAMNESE-FORM.MEDICATION-TITLE' | translate}}</mat-label>
    <textarea matInput cdkTextareaAutosize formControlName="medication" mat-autosize=true cdkAutosizeMinRows=1
      cdkAutosizeMaxRows=3></textarea>
    <mat-hint>{{'ANAMNESE-FORM.MEDICATION-TEXT' | translate}}</mat-hint>
  </mat-form-field>

</form>