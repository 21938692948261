import { WfaFormResponse } from './../interfaces-autogen-from-joi/wfa-form-response'
import { surveyjsResponseJoiExample } from './surveyjs-response.joi.example'

export const wfaFormResponse: WfaFormResponse = {
  linkToCompleteSurveyInArztDirekt:
    'http://localhost:4200/wfa/editFormResponse&praxisId=651161879124139e2489b1e2&wfaFormId=personal-data&responseId=653a2dc8e0240b44e8f084b1',
  origin: 'missing',
  praxisId: '651161879124139e2489b1e2',
  responseId: '653a2dc8e0240b44e8f084b1',
  submittedAt: undefined,
  wfaFormId: 'personal-data',
  wfaFormVersionId: '6516d06ced8700dbf2c55180',
  surveyjsResponse: surveyjsResponseJoiExample,
}

export const dummyPersonalDataWfaFormResponse: WfaFormResponse = {
  responseId: '6517143d15cf05768196c2f6',
  wfaFormId: 'personal-data',
  wfaFormVersionId: '6516cfa361b7627268a81a0c',
  praxisId: '651161879124139e2489b1e2',
  surveyjsResponse: {},
  isDebugEntry: true,
}
