import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NotificationService } from '@lib/notifications/notification.service';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { fromEvent, merge, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, startWith, takeUntil } from 'rxjs/operators';
import { SocketUserRole } from '../../entities/Socket.entity';
import { Origin } from '../../entities/WebRTC.entity';
import { DatauriHelpersService } from '../../misc/datauri-helpers.service';
import { ImageHelpersService, ImageScaleMode } from '../../misc/image-helpers.service';
import { MimetypeHelpersService } from '../../misc/mimetype-helpers.service';
import { UsefulComponent } from '../../misc/useful.component';
import { SocketFormattersService } from '../../socket/socket-formatters.service';
import FormValidators from '../form-validators.service';
import { Asset, AssetMeta } from './../../entities/PatientSession.entity';
import { AssetUploadFieldWebcamDialogComponent } from './asset-upload-field-webcam-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';


export enum AssetUploadFieldFormat {
  DataUri = 'DataUri',
  Asset = 'Asset',
  AssetArray = 'AssetArray',
}

export interface AssetUploadFieldSettings {
  readonly format?: AssetUploadFieldFormat
  readonly required?: boolean
  readonly mediaTypes?: string[]
  readonly allowWebcamPhoto?: boolean
  readonly allowSVGAsXML?: boolean // If true allow upload of dompurify sanitized xml files, beware of potential xss, but should be unlikely. If false svg is rendered as png
  readonly allowPng?: boolean
  readonly askBeforeDeletion?: boolean
  readonly placeholderIcon?: string
  readonly placeholderIconSize?: string
  readonly hidePlaceholder?: boolean
  readonly multiple?: boolean
  limit?: number
  readonly hideReuploadActions?: boolean
  readonly imageClass?: string
  readonly maxMegaByteSize?: number
  readonly imageMaxSize?: [number, number]
  readonly imageMinSize?: [number, number]
  readonly imageScaleSize?: [number, number]
  readonly imageDoUpscale?: boolean
  readonly imageScaleMode?: ImageScaleMode
  readonly senderRole?: SocketUserRole
  readonly capturedRole?: SocketUserRole
}


@Component({
    selector: 'app-asset-upload-field',
    templateUrl: './asset-upload-field.component.html',
    styleUrls: ['./asset-upload-field.component.scss'],
    host: { 'class': 'c-form__row' },
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        MatButtonModule,
        MatIconModule,
        FontAwesomeModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        TranslateModule,
    ],
})
export class AssetUploadFieldComponent extends UsefulComponent implements OnInit, OnDestroy {

  //@Output() uploadCompleteEvent = new EventEmitter();
  @Input() formGroup: UntypedFormGroup
  @Input() name: string
  @Input() control: AbstractControl
  @Input() disabled: boolean
  @Input() settings: AssetUploadFieldSettings
  @Input() otk: boolean = false
  @Output() changesMade = new EventEmitter();



  private webcamDialogRef: MatDialogRef<AssetUploadFieldWebcamDialogComponent>
  public assetDataUriSanitized: SafeUrl

  public Format = AssetUploadFieldFormat
  public readonly defaultSettings: AssetUploadFieldSettings = {
    format: AssetUploadFieldFormat.DataUri,
    mediaTypes: ['*'],
    maxMegaByteSize: 8,
    placeholderIcon: 'expand',
    placeholderIconSize: '5x',
    imageClass: 'c-asset-image',
    imageDoUpscale: false,
    imageScaleMode: ImageScaleMode.Contain,
  }

  public get asset(): AbstractControl {
    return this.control || this.formGroup.get(this.name)
  }

  public get acceptedMediaTypes(): string { return this.settings.mediaTypes.join(',') }


  constructor(
    private adLoggerService: AdLoggerService,
    public sanitizer: DomSanitizer,
    private datauriHelpersService: DatauriHelpersService,
    private imageHelpers: ImageHelpersService,
    private cd: ChangeDetectorRef,
    private formValidators: FormValidators,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private socketFormattersService: SocketFormattersService,
    private mimeTypeHelpersService: MimetypeHelpersService
  ) {
    super()
  }

  ngOnInit() {
    super.ngOnInit()

    // Merge settings
    this.settings = { ...this.defaultSettings, ...this.settings }

    // Apply appropriate validators
    Promise.resolve().then(() => {
      this.asset.setValidators([
        this.formValidators.getDataUriValidator(this.settings.required, this.settings.mediaTypes),
        ...(this.settings.required ? [Validators.required] : [])
      ])
      this.asset.setAsyncValidators((this.settings.imageMinSize || this.settings.imageMaxSize)
        ? this.formValidators.getAsyncImageSizeValidator(this.settings.imageMinSize, this.settings.imageMaxSize, false)
        : null
      )
      this.asset.updateValueAndValidity()
    })

    // Updates local sanitized copy of `assetDataUri` for preview
    this.updateSanitizedAsset()
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    if (this.webcamDialogRef) { this.webcamDialogRef.close() }
  }


  /**
   * Updates local sanitized copy of `assetDataUri` for preview
   */
  private updateSanitizedAsset() {
    this.asset.valueChanges.pipe(
      startWith(0),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      const dataUri = this.settings.format === AssetUploadFieldFormat.Asset
        ? this.asset?.value?.dataUri
        : this.asset.value
      this.assetDataUriSanitized = dataUri
        ? this.sanitizer.bypassSecurityTrustUrl(dataUri)
        : null
    })
  }


  /**
   * Called whenever files-array changes
   */
  public onFilesChange(files: FileList) {
    if (!files || !files.length) { return }

    const limit = this.settings.multiple ? this.settings.limit : 1
    const fileUploads$ = Array.from(files)
      .slice(0, limit)
      .filter(Boolean)
      .map((file) => this.uploadFile(file))

    if (!fileUploads$.length) { return }

    this.isLoading = true
    let uploadCount = 0
    merge(...fileUploads$).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (asset: Asset) => {
        if (asset) {
          let newValue = this.assetsToFieldFormat(asset)
          if (this.settings.format === AssetUploadFieldFormat.AssetArray) {
            newValue = (this.asset.value || []).concat(newValue)
          }
          this.asset.setValue(newValue)
        }

        uploadCount++
        if (uploadCount >= fileUploads$.length) { this.isLoading = false }
        this.cd.markForCheck()
      },
      (error) => {
        this.adLoggerService.error(error)
        this.isLoading = false
        this.cd.markForCheck()
      }
    )

  }


  /**
   * Uploads and converts a single file
   */
  private uploadFile(file: File): Observable<Asset> {
    if (!file) {
      this.notificationService.displayNotification('Fehler beim Upload')
      return of(null)
    }

    // Ensure file-type is allowed
    const allTypesAllowed = this.acceptedMediaTypes.trim() === '*'
    const givenTypeAllowed = this.settings.mediaTypes.includes(file.type)
    if (!file.type || (!allTypesAllowed && !givenTypeAllowed)) {
      alert(`Sie dürfen die Datei ${file.name} vom Typ ${file.type} nicht hochladen.`)
      return of(null)
    }

    const fileIsImage = this.mimeTypeHelpersService.IMAGE_MIME_TYPES.includes(file.type)
    const fileIsSVG = file.type === 'image/svg+xml'
    let name: string = file.name
    let type: string = file.type
    const size: number = file.size
    const origin: Origin = Origin.Upload

    // Ensure file is not too large
    if (this.settings.maxMegaByteSize && size > (1024 * 1024 * this.settings.maxMegaByteSize)) {
      const maxSizeFormatted = this.datauriHelpersService.formatByteSize(1024 * 1024 * this.settings.maxMegaByteSize)
      const sizeFormatted = this.datauriHelpersService.formatByteSize(size)
      alert(`Sie dürfem maximal ${maxSizeFormatted} hochladen. Die Datei ${name} ist ${sizeFormatted} groß.`)
      return throwError('File too large')
    }

    const reader = new FileReader()
    if (!fileIsSVG || !this.settings.allowSVGAsXML) {

      reader.readAsDataURL(file)
      return fromEvent(reader, 'load').pipe(
        map((_) => {
          if (reader.error) { throw reader.error } else { return reader.result as string }
        }),
        mergeMap((dataUri: string) => {
          if (fileIsImage) {
            // Falls nicht jpg dann entweder jpg oder png je nachdem ob png erlaubt ist (default nein nur bei logo upload ja)
            if (type !== 'image/jpeg') { type = this.settings.allowPng ? 'image/png' : 'image/jpeg' }
            return this.imageHelpers.scaleBase64Image(dataUri, this.settings.imageScaleSize, this.settings.imageScaleMode, this.settings.imageDoUpscale, this.settings.allowPng, type)
          } else {
            return of(dataUri)
          }
        }),
        catchError((error) => {
          this.adLoggerService.error(error)
          this.notificationService.displayNotification('Fehler beim Upload')
          return of(null)
        }),
        map((dataUri: string) => {
          if (!dataUri) { return null }

          // Format filename & -type
          const baseName = `${dayjs().format('DD-MM-YY_HH-mm-ss-SSS')}_Upload_Patient`
          if (fileIsImage) {
            name = `${baseName}.${type.split('/')[1]}`
          } else {
            const extension = /(?:\.([^.]+))?$/.exec(name)[1]
            name = `${baseName}.${extension}`
          }

          // Recalculate Size if scaled image
          // there is actually a formula to retrieve the byte size of base64 encoded string from string length
          // toDo: calc new size with formula
          const finalSize = fileIsImage ? atob(dataUri.split(',')[1]).length : size

          const senderRole: SocketUserRole = this.settings.senderRole ? this.settings.senderRole : null
          const capturedRole: SocketUserRole = this.settings.capturedRole ? this.settings.capturedRole : null
          const meta: AssetMeta = {
            name,
            createdAt: dayjs().toISOString(),
            type,
            size: finalSize,
            origin,
            senderRole,
            capturedRole,
            description: this.socketFormattersService.makeFileDescription(origin, senderRole, capturedRole)
          }
          const asset: Asset = {
            dataUri,
            meta
          }
          this.changesMade.emit(true)
          return asset
        }),
      )
    } else {
      reader.readAsText(file)
      return fromEvent(reader, 'load').pipe(
        map((_) => {
          if (reader.error) { throw reader.error } else { return reader.result as string }
        }),
        catchError((error) => {
          this.adLoggerService.error(error)
          this.notificationService.displayNotification('Fehler beim Upload')
          return of(null)
        }),
        map((SVGXMLcontent: string) => {
          if (!SVGXMLcontent) { return null }

          // Format filename & -type
          const baseName = `${dayjs().format('DD-MM-YY_HH-mm-ss-SSS')}_Upload_Patient`
          const extension = /(?:\.([^.]+))?$/.exec(name)[1]
          name = `${baseName}.${extension}`

          // sanitizing SVG + XML + base64 encoding + make DataUri
          console.log('sanitizing xml..')
          const dataUri = this.datauriHelpersService.createDataUri('image/svg+xml', btoa(DOMPurify.sanitize(SVGXMLcontent)))

          // asset metadata
          const senderRole: SocketUserRole = this.settings.senderRole ? this.settings.senderRole : null
          const capturedRole: SocketUserRole = this.settings.capturedRole ? this.settings.capturedRole : null
          const meta: AssetMeta = {
            name,
            createdAt: dayjs().toISOString(),
            type,
            size,
            origin,
            senderRole,
            capturedRole,
            description: this.socketFormattersService.makeFileDescription(origin, senderRole, capturedRole)
          }
          const asset: Asset = {
            dataUri,
            meta
          }
          this.changesMade.emit(true)
          return asset
        }),
      )
    }
  }


  /**
   * Formats the given asset(s) into the actual format of the asset-field
   */
  private assetsToFieldFormat(asset: Asset): string | Asset | Asset[] {
    switch (this.settings.format) {
      case AssetUploadFieldFormat.DataUri:
        return asset?.dataUri ? asset.dataUri : null
      case AssetUploadFieldFormat.Asset:
      case AssetUploadFieldFormat.AssetArray:
        return asset
      default: return null
    }
  }


  /**
   * Opens dialog for taking a webcam-photo.
   */
  public openWebcamPhotoDialog() {
    this.webcamDialogRef = this.dialog.open(AssetUploadFieldWebcamDialogComponent, {
      width: '45rem',
      backdropClass: 'c-dialog-dark-backdrop',
      disableClose: true,
      data: null,
    })
    this.webcamDialogRef.afterClosed().pipe(
      filter((dataUri: string) => !!dataUri),
      mergeMap((dataUri: string) => {
        this.isLoading = true
        return this.imageHelpers.scaleBase64Image(dataUri, this.settings.imageScaleSize, this.settings.imageScaleMode, this.settings.imageDoUpscale)
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe((dataUri: string) => {
      const name = `${dayjs().format('DD-MM-YY_HH-mm-ss-SSS')}_Vorab-Foto.jpg`
      const type = 'image/jpeg'
      const size = new Blob([dataUri]).size
      const origin: Origin = Origin.Snapshot
      const senderRole: SocketUserRole = this.settings.senderRole ? this.settings.senderRole : null
      const capturedRole: SocketUserRole = this.settings.capturedRole ? this.settings.capturedRole : null
      const description: string = this.socketFormattersService.makeFileDescription(origin, senderRole, capturedRole)
      const meta: AssetMeta = {
        name,
        createdAt: dayjs().toISOString(),
        type,
        size,
        origin,
        senderRole,
        capturedRole,
        description
      }

      const asset: Asset = {
        dataUri,
        meta
      }
      const newValue = this.assetsToFieldFormat(asset)
      this.asset.setValue(newValue)
      this.changesMade.emit(true)
      this.isLoading = false
    })
  }

  public deleteAsset() {
    if (this.settings.askBeforeDeletion && !confirm('Wollen Sie dieses Logo/Bild wirklich löschen?')) { return }
    this.asset.setValue(null)
  }

}
