/* from https://youmightnotneed.com/lodash */

// Overload signatures
type F1<T1, R> = (x: T1) => R
type F2<T1, T2, R> = (x: T1, y: T2) => R
type F3<T1, T2, T3, R> = (x: T1, y: T2, z: T3) => R
type F2M1<T1, T2, R> = (x: T1) => F1<T2, R>

type F3M1<T1, T2, T3, R> = (x: T1, y: T2) => F1<T3, R>

export function curry<T1, T2, R>(func: F2<T1, T2, R>): F2M1<T1, T2, R>
export function curry<T1, T2, T3, R>(
  func: F3<T1, T2, T3, R>,
): F3M1<T1, T2, T3, R>

export function curry(func: any): any {
  // define the number of expected arguments
  const expectedArgs = func.length
  const curried = (...args: any) => {
    // if enough arugments has been passed return the
    // result of the function execution, otherwise
    // continue adding arguments to the list
    return args.length >= expectedArgs
      ? func(...args)
      : (...args2: any) => curried(...args.concat(args2))
  }
  return curried
}
