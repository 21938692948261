import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { adisRoutes, allRoutes } from '@a-d/wfr/wfa/wfa-for-ad-frontend-link'


@NgModule({
  imports: [RouterModule.forChild(adisRoutes)],
  exports: [RouterModule],
})
export class AdisWfaEnvRoutingModule { }

@NgModule({
  imports: [RouterModule.forChild(allRoutes)],
  exports: [RouterModule],
})
export class DemoAdisWfaEnvRoutingModule { }
