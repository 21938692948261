import { ComputedUpdater, Model, IAction } from 'survey-core'
import { SingleOrMultilinkStore } from '../../adis/viewer-from-link/single-or-multilink/single-or-multilink.store'
import { notNullish, nullish } from '@arzt-direkt/wfa-generic-utils'
import { FormResponseOrigin } from '@arzt-direkt/wfa-definitions'
import { of, catchError } from 'rxjs'

const NEXT_SURVEY_ACTION_ID = 'sv-nav-multilink-next-survey'

export function addCustomNavigationButtonCompleteForMultilink(
  survey: Model,
  store: SingleOrMultilinkStore,
  isLastSurvey: boolean,
) {
  // do not show the default complete button
  survey.showCompleteButton = false

  const maybeNextSurveyAction = getNextSurveyAction(survey)
  const nextSurveyAction: IAction = nullish(maybeNextSurveyAction)
    ? survey.addNavigationItem({
        id: NEXT_SURVEY_ACTION_ID,
        // cf https://surveyjs.answerdesk.io/ticket/details/t20000/issue-with-hiding-navigation-buttons-based-on-question-value-not-updating-on-2nd-page
        visible: new ComputedUpdater(() => survey.isLastPage),
        // enabled: new ComputedUpdater(() => survey.validate(false)),
        visibleIndex: 51,
        locTitleName: 'multilinkNextSurvey',
        action: nextFormAction(survey, store),
      })
    : maybeNextSurveyAction

  if (isLastSurvey) {
    nextSurveyAction.locTitleName = 'multilinkSubmitSurvey'
    nextSurveyAction.action = () => {
      store
        .submitFormResponse()
        .pipe(
          catchError((error) => {
            const printError = [
              'WFA.NOTIFICATION.MULTILINK-VIEWER.SUBMIT-FORM-ERROR',
              JSON.stringify(error),
              JSON.stringify(survey.toJSON()),
            ]
            store.ns.error(printError)
            console.error(printError.join(''))
            return of(null)
          }),
        )
        .subscribe((data) => {
          if (notNullish(data)) {
            survey.completeLastPage()
          }
        })
    }
  } else {
    nextSurveyAction.action = nextFormAction(survey, store)
    nextSurveyAction.locTitleName = 'multilinkNextSurvey'
  }
}

function getNextSurveyAction(survey: Model) {
  return survey.navigationBar.actions.find(
    (a) => a.id === NEXT_SURVEY_ACTION_ID,
  )
}

function nextFormAction(survey: Model, store: SingleOrMultilinkStore) {
  return () => {
    // check validity without UI changes
    if (survey.validate(false)) {
      // if valid, proceed
      store.goToNextFwr()
    } else {
      // if invalid, show the errors in the UI
      survey.validate()
    }
  }
}
