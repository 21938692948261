import { WfaFormValidatorsService } from '@a-d/wfr/wfa/forms/form-validators';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from '@env/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { CountryFieldCustomComponent } from './country-field-custom.component';
import {
  AddressFormGroup,
  createAddressFormGroup as createAddressFormGroup1
} from './create-address-form-group';
import { StateFieldComponent } from './state-field/state-field.component';

@UntilDestroy()
@Component({
  selector: 'app-address-search-field',
  host: { class: 'c-form__row__item--stacked' },
  templateUrl: './address-search-field.component.html',
  styleUrls: ['./address-search-field.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    MatButtonModule,
    MatTooltipModule,
    MatInputModule,
    CountryFieldCustomComponent,
    TranslateModule,
    StateFieldComponent
  ],
})
export class AddressSearchFieldComponent implements OnInit {

  public get zip(): AbstractControl {
    return this.formGroup.get(this.zipName);
  }
  public get city(): AbstractControl {
    return this.formGroup.get(this.cityName);
  }
  public get country(): AbstractControl {
    return this.formGroup.get(this.countryName);
  }
  public get street(): AbstractControl {
    return this.formGroup.get(this.streetName);
  }
  public get streetNumber(): AbstractControl {
    return this.formGroup.get(this.streetNumberName);
  }
  public get address_1(): AbstractControl {
    return this.formGroup.get(this.address_1Name);
  }
  public get address_2(): AbstractControl {
    return this.address_2Name && this.formGroup.get(this.address_2Name);
  }

  public get anyAddressFieldIsTouched(): boolean {
    return (
      this.city.touched ||
      this.zip.touched ||
      this.country.touched ||
      (this.seperateStreetNameAndNumber &&
        (this.street.touched || this.streetNumber.touched)) ||
      (!this.seperateStreetNameAndNumber && this.address_1.touched) ||
      this.addressSearchTouched
    );
  }
  public get fullAddressIsValid(): boolean {
    return (
      this.city.valid &&
      this.zip.valid &&
      this.country.valid &&
      (!this.seperateStreetNameAndNumber ||
        (this.street.valid && this.streetNumber.valid)) &&
      (this.seperateStreetNameAndNumber || this.address_1.valid)
    );
  }
  public get fullAddressFormatted(): string {
    const address_1 = this.seperateStreetNameAndNumber
      ? `${this.street.value || ''} ${this.streetNumber.value || ''}`.trim()
      : (this.address_1.value || '').trim();
    const cityAndZip = `${this.zip.value || ''} ${this.city.value || ''
      }`.trim();
    const country = (this.country.value || '').trim();

    if (address_1 && cityAndZip && country) {
      return `${address_1}, ${cityAndZip}, ${country}`;
    } else if (address_1 && cityAndZip) { return `${address_1}, ${cityAndZip}`; } else if (address_1 && country) { return `${address_1}, ${country}`; } else if (cityAndZip && country) { return `${cityAndZip}, ${country}`; } else if (address_1) { return address_1; } else if (cityAndZip) { return cityAndZip; } else if (country) { return country; } else { return ''; }
  }

  constructor(private cd: ChangeDetectorRef) { }
  faPlus = faPlus;
  // @ViewChild('addressSearchInput', { read: ElementRef, static: true }) addressSearchInput: ElementRef<HTMLInputElement>

  // IMPORTANT: EINE ZERTIFIFIZIERUNGS-BEDINGUNG VERBIETET ES UNS, GOOGLE IN IRGEND EINER ART & WEISE ZU NUTZEN.
  //            DESWEGEN IST DIE ADRESS-SUCHE (BIS ZUM FINDEN EINER NEUEN, DEUTSCHEN ADRESS-API) DEAKTIVIERT.
  public readonly addressSearchDisabled = true;

  @Input() formGroup: UntypedFormGroup;
  @Input() seperateStreetNameAndNumber: boolean;
  @Input() disabled: boolean;
  @Input() customAddressLabel = 'Adresse';
  @Input() onlyAllowLangCodes: string[];
  @Input() alwaysShowAddressInputs: boolean;
  @Input() stepInteracted: boolean;
  @Input() requireCountry = true;
  @Input() useDynamicallyRequiredValidator = false;
  @Input() returnValue = 'name' //for country (CountryCodes)
  @Input() showStateSelection = false

  @Input() custom = false;
  @Input() zipName = 'zip';
  @Input() cityName = 'city';
  @Input() stateName = 'stateOrProvince';
  @Input() countryName = 'country';
  @Input() streetName = 'street';
  @Input() streetNumberName = 'streetNumber';
  @Input() address_1Name = 'address_1';
  @Input() address_2Name = 'address_2';
  @Input() showAddress_2Name = true // set to false for the otk to hide the field until it is added to otkappointment & tomedo

  // @ViewChild('countryField') countryField: CountryFieldComponent
  @ViewChild('countryField') countryField: CountryFieldCustomComponent

  readonly requiredV = Validators.required
  readonly dynamicallyRequiredV = WfaFormValidatorsService.dynamicallyRequired
  isRequired = false
  isDynamicallyRequired = false

  public addressSearchTouched = false;
  public showAddressInputs = false;
  public showAddress_2 = false;

  /**
   * Creates `address` FormGroup
   */
  public static createAddressFormGroup(
    seperateStreetNameAndNumber: boolean,
    countrySubFormName?: string,
    defaultCountry: string = 'Deutschland',
    requireValidators: boolean = true,
    useDynamicallyRequiredValidator: boolean = false,
    useCountryCode: boolean = false
  ): AddressFormGroup {
    return createAddressFormGroup1(
      seperateStreetNameAndNumber,
      countrySubFormName,
      defaultCountry,
      requireValidators,
      useDynamicallyRequiredValidator,
      useCountryCode
    )
  }

  ngOnInit(): void {
    // Show manual address-fields if form is disabled or they should be always shown
    if (
      this.disabled ||
      this.alwaysShowAddressInputs ||
      this.addressSearchDisabled
    ) {
      this.showAddressInputs = true;
    }

    // Show certain hidden fields if they were value exists already (e.g. cached)
    if (this.address_2?.value) { this.showAddress_2 = true; }

    // re-validate zip upon country change. This solves the issue of an
    // "ungültige Postleitzahl" error message still being displayed even though
    // the country was changed to a country where the given zip is correct.
    this.country.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.zip.updateValueAndValidity();
      this.zip.markAsTouched()
    });

    // Update search-input depending on address-field values
    if (!this.addressSearchDisabled) {
      merge(
        this.zip.valueChanges,
        this.city.valueChanges,
        this.country.valueChanges,
        ...(this.address_2 ? [this.address_2.valueChanges] : []),
        ...(this.seperateStreetNameAndNumber
          ? [this.street.valueChanges, this.streetNumber.valueChanges]
          : [this.address_1.valueChanges])
      )
        .pipe(startWith(0), untilDestroyed(this))
        .subscribe(() => {
          // this.addressSearchInput.nativeElement.value = this.fullAddressIsValid ? this.fullAddressFormatted : ''
        });
    }

    // use zip control because it is always defined
    this.isRequired = this.formGroup.get(this.zipName)?.hasValidator(this.requiredV)
    this.isDynamicallyRequired =
      this.formGroup.get(this.zipName)?.hasValidator(this.dynamicallyRequiredV);
    this.cd.detectChanges()
  }



  /**
   * DEBUG-ONLY: Automatic Form-Filling
   */
  public magicFill() {
    if (!environment.demoMode) { return; }

    this.zip.setValue('07743');
    this.city.setValue('Jena');
    this.country.setValue('Deutschland');
    this.formGroup.get("countryCode")?.setValue("DE")
    if (this.seperateStreetNameAndNumber) {
      this.street.setValue('Carl-Zeiss-Str.');
      this.streetNumber.setValue('42');
    } else {
      this.address_1.setValue('Carl-Zeiss-Str. 42');
    }
  }
}
