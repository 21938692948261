import { PraxisGeodata } from '@a-d/entities/Praxis.entity';
import { NgStyle } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import Leaflet from 'leaflet';

@Component({
  selector: 'app-streetmap-overlay',
  templateUrl: './streetmap-overlay.component.html',
  styleUrls: ['./streetmap-overlay.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule, 
    MatIconModule, 
    MatTooltipModule,
    NgStyle,
    TranslateModule
  ]
})
export class StreetmapOverlayComponent implements OnInit, AfterViewInit {

  constructor(
    private dialogRef: MatDialogRef<StreetmapOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) private overlayData: { geodata: PraxisGeodata, header: TemplateRef<any>, data: any },
    private _elementRef: ElementRef<any>
  ) { }

  private map: Leaflet.Map;
  mapStyle: { height: string } = { height: '100%' };
  @ViewChild('header', { read: ViewContainerRef }) header: ViewContainerRef | undefined;


  ngOnInit(): void {
    setTimeout(() => {
      if (this.overlayData.header) {
        this.header.createEmbeddedView(this.overlayData.header, { data: this.overlayData.data, onCancelClick: this.onCancelClick.bind(this) }); // putting this into ngAfterViewInit causes ExpressionChangedAfterItHasBeenCheckedError
      }
    }, 0)
  }

  ngAfterViewInit(): void {
    setTimeout(() => { // wait for this._elementRef.nativeElement to be ready...
      /* if (this.overlayData.header) {
        this.mapStyle = { height: 'calc(100% - ' + this._elementRef.nativeElement.firstChild.clientHeight + 'px)' }
      } */

      const options: Leaflet.MapOptions = {
        zoom: 17,
        minZoom: 15,
        maxZoom: 19,
        center: Leaflet.latLng(this.overlayData.geodata.lat, this.overlayData.geodata.lon),
        attributionControl: false,
      };
      this.map = Leaflet.map('map', options);
      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        minZoom: 15,
      }).addTo(this.map);
      Leaflet.marker([this.overlayData.geodata.lat, this.overlayData.geodata.lon], {
        icon: Leaflet.icon({
          iconSize: [25, 41],
          iconAnchor: [10, 30],
          iconUrl: `${environment.url}/assets/images/leaflet/marker-icon.png`,
          shadowUrl: `${environment.url}/assets/images/leaflet/marker-shadow.png`,
          popupAnchor: [12.5, 0],
        }),
      }
      ).addTo(this.map);
    }, 0)
  }

  onCancelClick() {
    this.map.off();
    this.map.remove();
    this.dialogRef.close();
  }

}
