import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UsefulComponent } from '../../misc/useful.component';
import FormValidators from '../form-validators.service';


@Component({
  selector: 'app-password-field',
  host: { 'class': 'c-form__row' },
  template: `
  <mat-form-field attr.data-selenium-id="field-passwort" [formGroup]="formGroup" [appearance]="appearance">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput [formControlName]="name" [type]="showPasswordValue ? 'text' : 'password'" [required]="required"
    spellcheck="false" [autocomplete]="autocomplete || 'new-password'" (keydown.enter)="$event.preventDefault();" (keydown.shift.enter)="$event.preventDefault();">

    <mat-error *ngIf="password.errors && password.errors['passwordLengthEight']" style="font-weight: 600;">
      {{'PASSWORD-FIELD.ERROR-TOO-SHORT'| translate}}
    </mat-error>
    <mat-error *ngIf="password.errors && password.errors['passwordNoLowerLetter']" style="font-weight: 600;">
      {{'PASSWORD-FIELD.ERROR-NO-LOWER-LETTER'| translate}}
    </mat-error>
    <mat-error *ngIf="password.errors && password.errors['passwordNoUpperLetter']" style="font-weight: 600;">
      {{'PASSWORD-FIELD.ERROR-NO-UPPER-LETTER'| translate}}
    </mat-error>
    <mat-error *ngIf="password.errors && password.errors['passwordNoDigit']" style="font-weight: 600;">
      {{'PASSWORD-FIELD.ERROR-NO-DIGIT'| translate}}
    </mat-error>
    <mat-error *ngIf="password.errors && password.errors['passwordNoSpecialChar']" style="font-weight: 600;">
      {{'PASSWORD-FIELD.ERROR-NO-SPECIAL-CHAR'| translate}}
    </mat-error>
    <mat-error *ngIf="password.errors && password.errors['passwordWhitespace']" style="font-weight: 600;">
      {{'PASSWORD-FIELD.ERROR-WHITESPACE'| translate}}
    </mat-error>
    <mat-error *ngIf="password.errors && password.errors['fieldsDontMatch']" style="font-weight: 600;">
      {{'PASSWORD-FIELD.ERROR-DONT-MATCH'| translate}}
    </mat-error>
    <mat-error *ngIf="password.errors && password.errors['fieldsMatch']" style="font-weight: 600;">
      {{'PASSWORD-FIELD.ERROR-MATCH'| translate}}
    </mat-error>

    <button attr.data-selenium-id="button-passwort-zeigen" type="button" mat-icon-button matSuffix *ngIf="showable" (click)="showPasswordValue = !showPasswordValue; $event.stopPropagation();" [matTooltip]="showPasswordValue ? this.translate.instant('PASSWORD-FIELD.HIDE') : this.translate.instant('PASSWORD-FIELD.SHOW')" tabindex="-1">
      <fa-icon [icon]="['far', showPasswordValue ? 'eye' : 'eye-slash' ]" style="color: rgba(0, 0, 0, 0.54);"></fa-icon>
    </button>

  </mat-form-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    MatTooltipModule,
    FontAwesomeModule,
    TranslateModule,
  ],
})
export class PasswordFieldComponent extends UsefulComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup
  @Input() name: string = "password"
  @Input() required: boolean = true
  @Input() placeholder: string = "Passwort"
  @Input() autocomplete: string
  @Input() strengthValidation: boolean
  @Input() matchField: string
  @Input() notMatchField: string
  @Input() showable: boolean = true
  @Input() appearance: String = 'outline'

  public showPasswordValue: boolean = false

  public get password(): AbstractControl { return this.formGroup.get(this.name || 'password') }


  constructor(
    private cd: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    super()
  }

  ngOnInit() {
    this.initValidation()
  }

  ngOnChanges() {
    this.initValidation()
    this.cd.detectChanges()
  }


  /**
   * Initializes validation of password-field and keeps updated
   */
  private initValidation() {
    const passwordValidators = [
      ...(this.strengthValidation ? [FormValidators.getPasswordStrengthValidator(this.required)] : []),
      ...(!this.strengthValidation && this.required ? [Validators.required] : []),
      ...(this.matchField ? [FormValidators.getFieldsMatchValidator(this.matchField)] : []),
      ...(this.notMatchField ? [FormValidators.getFieldsMatchValidator(this.notMatchField, false)] : []),
    ]
    this.password.setValidators(passwordValidators)
    this.password.updateValueAndValidity()
  }

}
