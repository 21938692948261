import { cloneDeep, set } from '@arzt-direkt/wfa-generic-utils'

export type Assigner<State> = (state: State, value: unknown) => State

export function assign<State extends Record<string, unknown>>(
  path: string,
): Assigner<State> {
  return (object: State, value: unknown) =>
    set(cloneDeep(object), path, value) as State
}
