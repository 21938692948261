<form class="c-form c-form--fullHeight" [formGroup]="formGroup">

  <mat-form-field attr.data-selenium-id="field-haut-art" *ngIf="!this.lesionIsPreselected" appearance="outline">
    <mat-label>{{'ANAMNESE-FORM.SKIN-COMPLAIN-TYPE-TITLE' | translate}}</mat-label>
    <mat-select formControlName="hautproblemIsLesion" required>
      <mat-option [value]="hautproblemIsLesionValue">{{'ANAMNESE-FORM.SKIN-COMPLAIN-TYPE-MOLE' | translate}}
      </mat-option>
      <mat-option [value]="hautproblemOtherHautproblemValue">{{'ANAMNESE-FORM.SKIN-COMPLAIN-TYPE-OTHER' | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field attr.data-selenium-id="field-leberfleck-durchmesser" *ngIf="isLesion" appearance="outline">
    <mat-label>
      <span class="hide--onVerySmall">{{'ANAMNESE-FORM.SKIN-MOLE-SIZE-TITLE' | translate}}</span>
      <span class="show--onVerySmall">{{'ANAMNESE-FORM.SKIN-MOLE-SIZE-MEASUREMENTS' | translate}}</span>
    </mat-label>
    <input matInput type="number" formControlName="hautproblemLesionDiameter" spellcheck="false" min="1" max="100">
    <mat-error *ngIf="hautproblemLesionDiameter.errors">{{'ANAMNESE-FORM.SKIN-MOLE-SIZE-MEASUREMENTS-ERROR' |
      translate}}</mat-error>
  </mat-form-field>

  <mat-form-field attr.data-selenium-id="field-leberfleck-aenderung" *ngIf="isLesion" appearance="outline">
    <mat-label>
      <span class="hide--onVerySmall">{{'ANAMNESE-FORM.SKIN-MOLE-CHANGES-TITLE' | translate}}</span>
      <span class="show--onVerySmall">{{'ANAMNESE-FORM.SKIN-MOLE-CHANGES-TEXT' | translate}}</span>
    </mat-label>
    <mat-select formControlName="hautproblemLesionMutations" multiple>
      <mat-select-trigger>{{ (hautproblemLesionMutations.value || []).join(', ') }}</mat-select-trigger>
      <mat-option value="Größe">{{'ANAMNESE-FORM.SKIN-MOLE-CHANGES-OPTION-SIZE' | translate}}</mat-option>
      <mat-option value="Farbe">{{'ANAMNESE-FORM.SKIN-MOLE-CHANGES-OPTION-COLOR' | translate}}</mat-option>
      <mat-option value="Form">{{'ANAMNESE-FORM.SKIN-MOLE-CHANGES-OPTION-SHAPE' | translate}}</mat-option>
    </mat-select>
  </mat-form-field>

  <app-selection-field-component *ngIf="isOtherHautproblem" [formGroup]="formGroup"
    [options]="settings.hautproblemOptions" selectionName="hautproblemSelection"
    descriptionName="hautproblemDescription" [selectionPlaceholder]="'ANAMNESE-FORM.SKIN-COMPLAIN-TITLE' | translate"
    [descriptionPlaceholder]="'ANAMNESE-FORM.COMPLAIN-TEXT' | translate">
  </app-selection-field-component>

  <mat-form-field attr.data-selenium-id="field-haut-wo" appearance="outline">
    <mat-label>{{'ANAMNESE-FORM.SKIN-COMPLAIN-DETAILS-TEXT' | translate}}</mat-label>
    <input matInput formControlName="hautproblemLocalization" spellcheck="false">
  </mat-form-field>

  <mat-form-field attr.data-selenium-id="field-haut-seit" appearance="outline">
    <mat-label>
      <span class="hide--onVerySmall">{{'ANAMNESE-FORM.SKIN-COMPLAIN-HISTORY-TITLE' | translate}}</span>
      <span class="show--onVerySmall">{{'ANAMNESE-FORM.COMPLAIN-HISTORY-QUESTION-TEXT' | translate}}</span>
    </mat-label>
    <textarea matInput cdkTextareaAutosize formControlName="hautproblemHistory" mat-autosize=true cdkAutosizeMinRows=1
      cdkAutosizeMaxRows=5></textarea>
  </mat-form-field>

  <mat-form-field attr.data-selenium-id="field-haut-behandlung" appearance="outline">
    <mat-label>
      <span class="hide--onVerySmall">{{'ANAMNESE-FORM.SKIN-PREVIOUS-TREATMENT' | translate}}</span>
      <span class="show--onVerySmall">{{'ANAMNESE-FORM.SKIN-PREVIOUS-TREATMENT-DETAILS' | translate}}</span>
    </mat-label>
    <mat-select formControlName="hautproblemPreTreated">
      <mat-option value="Ja">{{'GLOBAL.YES' | translate}}</mat-option>
      <mat-option value="Nein">{{'GLOBAL.NO' | translate}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field attr.data-selenium-id="field-haut-beschreibung" *ngIf="hautproblemPreTreated.value === 'Ja'"
    appearance="outline">
    <mat-label>{{'ANAMNESE-FORM.SKIN-PREVIOUS-TREATMENT-DETAILS-DESCRIPTION' | translate}}</mat-label>
    <textarea matInput cdkTextareaAutosize formControlName="hautproblemPreTreatmentDescription" mat-autosize=true
      cdkAutosizeMinRows=1 cdkAutosizeMaxRows=5></textarea>
  </mat-form-field>

</form>