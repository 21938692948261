import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit
} from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { BehaviorSubject, Subscription, merge, of } from 'rxjs';
import { delay, mergeMap, startWith } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BookingStep } from '../entities/Booking.entity';
import { InstanceService } from '../instance/instance.service';
import { WrapperOTKComponent } from '../wrapper-otk/wrapper-otk.component';
import { BookingErrorComponent } from './booking-error/booking-error.component';
import { BookingReservationComponent } from './booking-reservation/booking-reservation.component';
import { BookingSeriesService } from './booking-series.service';
import { BookingStepperComponent } from './booking-stepper/booking-stepper.component';
import { BookingService } from './booking.service';


@UntilDestroy()
@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [WrapperOTKComponent, BookingReservationComponent, BookingStepperComponent, BookingErrorComponent, NgIf, MatButtonModule, FontAwesomeModule, AsyncPipe, TranslateModule]
})
export class BookingComponent implements OnInit {

  public activeInstance = this.instanceService.activeInstance
  public currentStep: BookingStep
  public Step = BookingStep
  showBackwardButton$: BehaviorSubject<boolean> = this.bookingService.showBackwardButton$
  showForwardButton$: BehaviorSubject<boolean> = this.bookingService.showForwardButton$
  isNextButtonDisabled$: BehaviorSubject<boolean> = this.bookingService.isNextButtonDisabled$
  showBookButton$: BehaviorSubject<boolean> = this.bookingService.showBookButton$
  showExportButton$: BehaviorSubject<boolean> = this.bookingService.showExportButton$
  bookingChangesSubscription: Subscription
  titleSubscription: Subscription
  title: string
  isInDemoMode: boolean
  isPaidAppointment = false
  isLoading: boolean = true
  constructor(
    private instanceService: InstanceService,
    public bookingService: BookingService,
    private bookingSeriesService: BookingSeriesService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.isInDemoMode = environment.demoMode
    this.bookingService.isBookingInitialized$
      .pipe(untilDestroyed(this))
      .subscribe((isBookingInitialized: boolean) => {
        if (isBookingInitialized) {
          this.isLoading = false
          this.loadingSubscription()
          this.titleSubscribe()
          this.bookingChangesSubscribe()
          this.paidAppointmentSubscribe()
        }
      })
  }

  public paidAppointmentSubscribe() {
    this.bookingService.paymentMode$.pipe(untilDestroyed(this)).subscribe((isPaid) => { this.isPaidAppointment = isPaid })
  }

  // causes the loading bar to appear whenever loading
  public loadingSubscription() {
    this.bookingService.isLoading
      .pipe(
        untilDestroyed(this),
        mergeMap((isLoading: boolean) => {
          this.isLoading = isLoading
          return of(null)
        }),
        // the delay(500) is needed to solve a bug I didn't fully understand where
        // loading is successfully set to false, but the view does not update
        // so the website appears as if its loading indefinitely until the user
        // does something. For instance this happens when the user selects another
        // opening or when the magic button is used.
        delay(500),
        mergeMap(() => {
          this.changeDetectorRef.markForCheck()
          this.changeDetectorRef.detectChanges()
          return of(null)
        })
      ).subscribe()
  }

  // updates the titles as the stepper is navigated
  titleSubscribe() {
    if (this.titleSubscription)
      this.titleSubscription.unsubscribe()
    this.titleSubscription = merge(this.bookingService.stepper.selectionChange, this.translateService.onLangChange, this.bookingService.arePrestepsCompleted$)
      .pipe(
        startWith(null),
        untilDestroyed(this),
        delay(300),
        mergeMap(() => this.bookingService.setTabTitles())
      ).subscribe(() => {
        if (this.bookingService.stepper.selected) {
          const currentBookingStepLabel: string = this.bookingService.stepper.selected.label
          const bookingStep: BookingStep = Object.keys(this.bookingService.tabTitles)
            .find((key) => currentBookingStepLabel === this.bookingService.tabTitles[key]) as BookingStep
          const bookingStepEdited: string = bookingStep.split('_').join('-')
          const zwOrDefTitle = this.bookingService.isZwCodeCorrect && bookingStep === BookingStep.bookingPersonal ? 'TITLE-ZW' : 'TITLE'
          this.title = this.translateService.instant('OTK.' + bookingStepEdited + '.' + zwOrDefTitle)
        }
        this.changeDetectorRef.detectChanges()
        this.changeDetectorRef.detectChanges()
      })
  }

  public magicFill() {
    const bookingStep: BookingStep = (Object.keys(this.bookingService.tabTitles) as BookingStep[])
      .find((step: BookingStep) =>
        this.bookingService.tabTitles[step] === this.bookingService.stepper.selected.label)
    this.bookingService.magicFill$.next(bookingStep)
  }

  public shortenReservationTimer() {
    this.bookingService.reservation.dateExpiry = dayjs().add(1, 'seconds').toDate()
  }

  /**
* if any of the forms change their value or if the step changes,
* recalculate which buttons to show based on the current step
* and whether they are valid.
*/
  private bookingChangesSubscribe() {
    if (this.bookingChangesSubscription)
      this.bookingChangesSubscription.unsubscribe()
    this.bookingChangesSubscription =
      merge(this.bookingService.stepper.selectionChange,
        this.bookingService.bookingValueChanges$,
        this.bookingService.arePrestepsCompleted$)
        .pipe(
          untilDestroyed(this),
          startWith(null),
          // without this delay+detect changes,
          // stepper.selected is always the previous step, not the current one
          delay(300),
          mergeMap(() => {
            this.changeDetectorRef.detectChanges()
            return of(null)
          })
        )
        .subscribe(() => {
          this.changeDetectorRef.detectChanges()
          // set all button settings to false
          this.showBackwardButton$.next(false)
          this.showForwardButton$.next(true)
          this.isNextButtonDisabled$.next(true)
          this.showBookButton$.next(false)
          this.showExportButton$.next(false)
          const bookingStep: BookingStep = (Object.keys(this.bookingService.tabTitles) as BookingStep[])
            .find((bookingStep: BookingStep) =>
              this.bookingService.tabTitles[bookingStep] === this.bookingService.stepper.selected.label)
          this.currentStep = bookingStep
          switch (bookingStep) {
            case BookingStep.bookingInfo:
              this.isNextButtonDisabled$.next(false)
              break;
            case BookingStep.bookingPreliminary:
              this.isNextButtonDisabled$.next(!this.bookingService.bookingPreliminaryFormGroup.valid);
              break;
            case BookingStep.bookingBs:
              this.isNextButtonDisabled$.next(!this.bookingService.bookingBsFormGroup.valid);
              break;
            case BookingStep.bookingType:
              this.showBackwardButton$.next(this.bookingService.showBs$.value)
              this.isNextButtonDisabled$.next(!this.bookingService.bookingTypeFormGroup.valid);
              break;
            case BookingStep.bookingDateMulti:
              this.showForwardButton$.next(true) //this.bookingService.showType$.value //!this.bookingService.bookingDateFormGroup.valid;
              this.showBackwardButton$.next(true)
              this.isNextButtonDisabled$.next(!(this.bookingService.openingsMultiControl as UntypedFormArray).at(0)?.valid)
              break;
            case BookingStep.bookingDate:
              this.showBackwardButton$.next(this.bookingService.showType$.value)
              this.isNextButtonDisabled$.next(!this.bookingService.bookingDateFormGroup.valid)
              break;
            case BookingStep.bookingAnamnese:
              this.showBackwardButton$.next(true)
              this.isNextButtonDisabled$.next(!this.bookingService.isAnamneseFormValid);
              break;
            case BookingStep.bookingPersonal:
              this.showBackwardButton$.next(true)
              this.showForwardButton$.next(true)
              this.isNextButtonDisabled$.next(false); //!this.bookingService.personalFormComponent.isFormGroupValid();
              break;
            case BookingStep.bookingSummary:
              this.showBackwardButton$.next(true)
              if (this.isPaidAppointment) {
                this.showForwardButton$.next(true)
                this.isNextButtonDisabled$.next(false);
                break;
              }
              this.showForwardButton$.next(false)
              this.showBookButton$.next(true)
              break;
            case BookingStep.bookingPayment:
              this.showBackwardButton$.next(true)
              this.showForwardButton$.next(false)
            // default:
            //   this.showForwardButton = true //prevents occasional disappearing of start button in booking-start
          }
          this.bookingService.messageHeightToIframeHost()
          // Dont need this because of async pipes
          // this.changeDetectorRef.detectChanges() 
        })
  }

  onButtonForward() {
    // if at step preliminary (last prestep)
    //  1. next arePrestepsCompleted$, which removes the presteps from the stepper and adds to the steps to the stepper.
    //  2. reset the selected index of the stepper to 0, which is now Betriebstätte (was Info before),
    //    otherwise Betriebstätte is skipped and we go directly to Type
    // actually, seeing how preliminary is set to autoforward=true, these two lines are already executed automatically as soon
    // as preliminary is valid, such that they are not needed here. So I comment them out, but I leave the whole explanation here
    // in case we sometime decide to set the autoforwarding of preliminary to false.
    // if (this.bookingService.stepper.selected.label == this.bookingService.tabTitles[BookingStep.bookingPreliminary]) {
    //   this.bookingService.arePrestepsCompleted$.next(true)
    //   this.bookingService.stepper.selectedIndex = 0
    // }
    // else
    if (this.currentStep === BookingStep.bookingPersonal && this.bookingService.personalFormComponent.handleNextButton()) return
    if (this.currentStep === BookingStep.bookingDateMulti && this.bookingSeriesService.next()) return
    this.bookingService.stepper.selected.completed = true
    this.bookingService.stepper.next()
  }


  onButtonBackward() {
    if (this.currentStep === BookingStep.bookingDateMulti && this.bookingSeriesService.previous()) return
    this.bookingService.stepper.previous()
  }

  refresh() {
    window.location.reload()
  }

}

