import { ElementFactory, Serializer } from 'survey-core'

import { HtmlEditorModel } from './html-editor-model'
import { QUESTION_TYPE } from './question-type'

export function registerHtmlEditorModel(): void {
  registerModelInElementFactory()
  addMetaData()
}

/**
 * Register the html editor model in `ElementFactory`
 */
function registerModelInElementFactory(): void {
  ElementFactory.Instance.registerElement(QUESTION_TYPE, (name) => {
    return new HtmlEditorModel(name)
  })
}

/**
 * Add question type metadata
 */
function addMetaData(): void {
  Serializer.addClass(
    QUESTION_TYPE,
    [{ name: 'height', default: '200px', category: 'layout' }],
    () => {
      return new HtmlEditorModel('')
    },
    'html',
  )
}
