import { FormResponseOrigin } from '@arzt-direkt/wfa-definitions'
import { ViewerEnvState } from '@arzt-direkt/wfa-definitions'
import { WfaForm } from '@arzt-direkt/wfa-definitions'
import { WfaFormResponse } from '@arzt-direkt/wfa-definitions'
import { defaultViewerEnvState } from '@arzt-direkt/wfa-definitions'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'

export interface ViewerState extends Record<string, unknown> {
  env: ViewerEnvState
  form: Maybe<WfaForm>
  formResponse: Maybe<WfaFormResponse>
  latestResponseOrigin: Maybe<FormResponseOrigin>
}

export const initialViewerState: ViewerState = {
  env: defaultViewerEnvState,
  form: undefined,
  formResponse: undefined,
  latestResponseOrigin: undefined,
}
