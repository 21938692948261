import { IsEmbeddedIn } from './../interfaces-autogen-from-joi/is-embedded-in'
import { ViewerEnvState } from './../interfaces-autogen-from-joi/viewer-env-state'

export const defaultViewerEnvState: ViewerEnvState = {
  locale: 'de',
  isEmbeddedIn: 'arztDirekt',
  displayOption: 'editable',
}

const state = defaultViewerEnvState

export const initialViewerEnvStateDict: Record<IsEmbeddedIn, ViewerEnvState> = {
  arztDirekt: { ...state, isEmbeddedIn: 'arztDirekt' },
  iOsTomedo: { ...state, isEmbeddedIn: 'iOsTomedo' },
  macOsTomedo: { ...state, isEmbeddedIn: 'macOsTomedo' },
  adStepper: { ...state, isEmbeddedIn: 'adStepper' },
  adMultilinkViewer: {
    ...state,
    isEmbeddedIn: 'adMultilinkViewer',
  },
  adPermalinkViewer: {
    ...state,
    isEmbeddedIn: 'adPermalinkViewer',
  },
}
