export const propertyTitles = {
  altText: {
    de: 'Alternativtext',
    en: 'Alternative text',
    es: 'Texto alternativo',
    fr: 'Texte de remplacement',
    it: 'Testo alternativo',
  },
  imageLink: {
    de: 'Bild (aus URL oder Datei)',
    en: 'Image (from URL or file)',
    es: 'Imagen (de URL o archivo)',
    fr: `Image (de l'URL ou du fichier)`,
    it: 'Immagine (da URL o file)',
  },
  logo: {
    de: 'Logo (aus URL oder Datei)',
    en: 'Logo (from URL or file)',
    es: 'Logotipo (de URL o archivo)',
    fr: `Logo (de l'URL ou du fichier)`,
    it: 'Logo (da URL o file)',
  },
}
