import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { KeyFormats } from '@a-d/entities/CertValues.entity';
import { Injectable } from '@angular/core';
import { BitString, BmpString, fromBER, OctetString } from 'asn1js';
import { Attribute, AuthenticatedSafe, CertBag, Certificate, getRandomValues, PFX, PKCS8ShroudedKeyBag, PrivateKeyInfo, SafeBag, SafeContents } from 'pkijs';
import { arrayBufferToString, fromBase64, stringToArrayBuffer, toBase64 } from 'pvutils';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { KeyService } from './key.service';
import { UserDataService } from './userdata.service';

@Injectable({
  providedIn: 'root'
})
export class KeystoreService {

  constructor(
    private adLoggerService: AdLoggerService,
    private keyService: KeyService,
    private userDataService: UserDataService
  ) { }

  /**
   * base64 representation of keystore for easy saving
   * @param pkcs12 the keystore file
   */
  public encodeKeystore(pkcs12: PFX): string {
    const buffer = pkcs12.toSchema().toBER(false);
    return toBase64(arrayBufferToString(buffer));
  }

  /**
   * load the keystore from an array buffer
   * @param keystoreBuffer array buffer of the keystore
   * @param password keystore password
   */
  public parseKeystore(keystoreBuffer: ArrayBuffer, password: string): Observable<PFX> {
    return new Observable((observer) => {
      const passwordBuffer = stringToArrayBuffer(password);
      console.log('pass', password)
      console.log('passbuff', passwordBuffer)
      const asn1 = fromBER(keystoreBuffer);
      const pkcs12 = new PFX({ schema: asn1.result });
      console.log('before parse safe', pkcs12)
      this.parseSafe(pkcs12, passwordBuffer)
        .pipe(
          mergeMap(() => this.parseSafeContents(pkcs12)),
          mergeMap(() => this.parsePKCS8Bag(pkcs12, passwordBuffer)),
        )
        .subscribe(() => {
          observer.next(pkcs12);
          observer.complete();
        },
          (error) => {
            this.adLoggerService.error('my error', error)
            observer.error(error);
          })
    })
  }

  /**
   * decrypt the authenticated safe
   * @param pkcs12 pkcs12 keystore
   * @param passwordBuffer arraybuffer of the keystore password
   */
  private parseSafe(pkcs12: PFX, passwordBuffer: ArrayBuffer): Observable<unknown> {
    console.log('before parse safe', pkcs12.authSafe.content)
    console.log('before parse safe', pkcs12.authSafe.content instanceof OctetString === false)
    return from(pkcs12.parseInternalValues({
      password: passwordBuffer,
      checkIntegrity: false
    }));
  }

  /**
   * decrypt the safe contents
   * @param pkcs12 pkcs12 keystore
   */
  private parseSafeContents(pkcs12: PFX): Observable<unknown> {
    console.log('safe content?')
    return from(pkcs12.parsedValue.authenticatedSafe.parseInternalValues({
      safeContents: [
        {
          // empty because we don't use privacy mode
        }
      ]
    }));
  }

  /**
   * decrypt the private key container
   * @param pkcs12 pkcs12 keystore
   * @param passwordBuffer array buffer of the keystore password
   */
  private parsePKCS8Bag(pkcs12: PFX, passwordBuffer: ArrayBuffer): Observable<unknown> {
    return from(
      pkcs12.parsedValue.authenticatedSafe.parsedValue.safeContents[0].value.safeBags[0].bagValue.parseInternalValues({
        password: passwordBuffer
      }));
  }

  /**
   * extract the private key arraybuffer from the keystore
   * @param pkcs12 keystore file
   */
  public loadPrivateKeyBuffer(pkcs12: PFX): Observable<ArrayBuffer> {
    return new Observable((observer) => {
      const pkcs8Bag = pkcs12.parsedValue.authenticatedSafe.parsedValue.safeContents[0].value.safeBags[0];
      if (pkcs8Bag) {
        const keybuffer = pkcs8Bag.bagValue.parsedValue.toSchema().toBER(false);
        observer.next(keybuffer);
        observer.complete();
      } else {
        observer.error('Dieser keystore enthält keinen private key.');
      }
    })
  }

  /**
   * extract the certificate arraybuffer from the keystore
   * @param pkcs12 keystore file
   */
  public loadCertificateBuffer(pkcs12: PFX): Observable<ArrayBuffer> {
    return new Observable((observer) => {
      const certBag = pkcs12.parsedValue.authenticatedSafe.parsedValue.safeContents[0].value.safeBags[1];
      if (certBag) {
        const certificateBuffer = certBag.bagValue.parsedValue.toSchema().toBER(false);
        observer.next(certificateBuffer);
        observer.complete();
      } else {
        observer.error('Dieser keystore enthält kein Zertifikat.');
      }
    });
  }

  /**
   * create a new pkcs12 keystore to hold the keys for the asymmetric encryption
   * @param password keystore password
   * @param certificate user certificate
   * @param privateKey RSA private key counterpart
   */
  public createPKCS12Keystore(password: string, certificate: Certificate, privateKey: CryptoKey): Observable<ArrayBuffer> {
    return new Observable((observer) => {
      const passwordBuffer = stringToArrayBuffer(password);
      this.keyService.exportRSAKey(privateKey, KeyFormats.pkcs8)
        .pipe(
          mergeMap((privateKeyBuffer) => this.initKeystore(privateKeyBuffer, certificate)),
          mergeMap((pkcs12) => this.encryptKeystore(pkcs12, passwordBuffer)),
        )
        .subscribe((keystoreBuffer) => {
          observer.next(keystoreBuffer);
          observer.complete();
        },
          (error) => {
            observer.error(error);
          })
    })
  }

  /**
   * create a new pkcs12 keystore to hold the keys for the asymmetric encryption
   * @param password derived PBKDF2 keystore password
   * @param certificate user certificate
   * @param privateKeyBuffer RSA private key buffer
   */
  public createPKCS12KeystoreFromBuffer(password: string, certificate: Certificate, privateKeyBuffer: ArrayBuffer): Observable<ArrayBuffer> {
    return new Observable((observer) => {
      const passwordBuffer = stringToArrayBuffer(password);
      this.initKeystore(privateKeyBuffer, certificate)
        .pipe(
          mergeMap((pkcs12) => this.encryptKeystore(pkcs12, passwordBuffer))
        )
        .subscribe((keystoreBuffer) => {
          observer.next(keystoreBuffer);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
    })
  }

  /**
   * encrypt the keystore so it is only accessable with the password
   * @param pkcs12 unencrypted keystore
   * @param passwordBuffer buffer of the keystore password
   */
  private encryptKeystore(pkcs12: PFX, passwordBuffer: ArrayBuffer): Observable<ArrayBuffer> {
    return new Observable((observer) => {
      this.encryptShroudedBag(pkcs12, passwordBuffer)
        .pipe(
          mergeMap(() => this.encryptSafeInternalValues(pkcs12)),
          mergeMap(() => this.encryptInternalValues(pkcs12, passwordBuffer)),
        )
        .subscribe(() => {
          console.log('Finished Keystore-Encryption');
          const keystoreBuffer = pkcs12.toSchema().toBER(false);
          observer.next(keystoreBuffer);
          observer.complete();
        },
          (error) => {
            observer.error(error);
          })
    })
  }

  /**
   * encrypt the shrouded bag with AES
   * @param pkcs12 keystore file
   * @param passwordBuffer array buffer of the keystore password
   */
  private encryptShroudedBag(pkcs12: PFX, passwordBuffer: ArrayBuffer): Observable<any> {
    return from(
      pkcs12.parsedValue.authenticatedSafe.parsedValue.safeContents[0].value.safeBags[0].bagValue.makeInternalValues({
        password: passwordBuffer,
        contentEncryptionAlgorithm: {
          name: 'AES-CBC',
          length: 128
        },
        hmacHashAlgorithm: 'SHA-1',
        iterationCount: 100000
      }))
  }

  private encryptSafeInternalValues(pkcs12: PFX): Observable<any> {
    return from(
      pkcs12.parsedValue.authenticatedSafe.makeInternalValues({
        safeContents: [
          {
            // can enable for additional privacy protection
          }
        ]
      })
    )
  }

  /**
   * secure the internal values + password with pbkdf2
   * @param pkcs12 keystore file
   * @param passwordBuffer array buffer of the keystore password
   */
  private encryptInternalValues(pkcs12: PFX, passwordBuffer: ArrayBuffer): Observable<unknown> {
    return from(
      pkcs12.makeInternalValues({
        password: passwordBuffer,
        iterations: 3000,   // increase count for more security
        pbkdf2HashAlgorithm: 'SHA-256',
        hmacHashAlgorithm: 'SHA-256'
      })
    )
  }

  /**
   * initialize the keystore with the encryption/decryption keys
   * @param keyBuffer buffer of the private key
   * @param certificate personal certificate
   */
  private initKeystore(keyBuffer: ArrayBuffer, certificate: Certificate): Observable<PFX> {
    return new Observable((observer) => {
      const pkcs8Info = this.makePrivateKeyInfo(keyBuffer);
      const pkcs12 = this.makePkcs12(certificate, pkcs8Info);
      if (pkcs12) {
        this.userDataService.keystore = pkcs12;
        observer.next(pkcs12);
        observer.complete();
      }
    })
  }

  /**
   * generate the general keystore structure
   * @param certificate personal certificate
   * @param privateKeyInfo asn1 key representation
   */
  private makePkcs12(certificate: Certificate, privateKeyInfo: PrivateKeyInfo): PFX {
    const pkcs12 = new PFX({
      parsedValue: {
        integrityMode: 0,
        authenticatedSafe: new AuthenticatedSafe({
          parsedValue: {
            safeContents: [
              {
                privacyMode: 0,     // no password for entries
                value: new SafeContents({
                  safeBags: [
                    this.makePrivateKeyBag(privateKeyInfo),
                    this.makeCertBag(certificate)
                  ]
                })
              }
            ]
          }
        })
      }
    });
    return pkcs12;
  }

  /**
   * container for holding private key data
   * @param pkcs8 private key data
   */
  private makePrivateKeyBag(pkcs8: PrivateKeyInfo): SafeBag {
    const keyLocalIdBuffer = new ArrayBuffer(4);
    const keyLocalIdView = new Uint8Array(keyLocalIdBuffer);
    getRandomValues(keyLocalIdView);

    const keyBag = new SafeBag({
      bagId: '1.2.840.113549.1.12.10.1.2',
      bagValue: new PKCS8ShroudedKeyBag({
        parsedValue: pkcs8
      }),
      bagAttributes: [
        new Attribute({
          type: '1.2.840.113549.1.9.20',  // friendlyName: key entry name
          values: [
            new BmpString({ value: 'privateKey' })
          ]
        }),
        new Attribute({
          type: '1.2.840.113549.1.9.21',  // localKeyId
          values: [
            new OctetString({ valueHex: keyLocalIdBuffer })
          ]
        }),
        new Attribute({
          type: '1.3.6.1.4.1.311.17.1', // pkcs12KeyProviderNameAttr
          values: [
            new BmpString({ value: 'http://www.pkijs.org' })
          ]
        })
      ]
    });
    return keyBag;
  }

  /**
   * container for holding certificate data
   * @param certificate personal certificate
   */
  private makeCertBag(certificate: Certificate): SafeBag {
    const certLocalIdBuffer = new ArrayBuffer(4);
    const certLocalIdView = new Uint8Array(certLocalIdBuffer);

    getRandomValues(certLocalIdView);
    const certificateBase64 = toBase64(arrayBufferToString(certificate.toSchema(true).toBER(false)));
    const asn1 = fromBER(stringToArrayBuffer(fromBase64(certificateBase64)));
    const certSimpl = new Certificate({ schema: asn1.result });

    const certBag = new SafeBag({
      bagId: '1.2.840.113549.1.12.10.1.3',
      bagValue: new CertBag({
        parsedValue: certSimpl
      }),
      bagAttributes: [
        new Attribute({
          type: '1.2.840.113549.1.9.20',  // alias for cert entry
          values: [
            new BmpString({ value: 'personalCertificate' })
          ]
        }),
        new Attribute({
          type: '1.2.840.113549.1.9.21',  // local key Id
          values: [
            new OctetString({ valueHex: certLocalIdBuffer })
          ]
        }),
        new Attribute({
          type: '1.3.6.1.4.1.311.17.1',
          values: [
            new BmpString({ value: 'http://www.pkijs.org' })
          ]
        })
      ]
    });
    return certBag;
  }

  /**
   * convert private key to it's asn1 representation
   * @param privateKeyBase64 base64 representation of the private key
   */
  private makePrivateKeyInfo(privateKeyBuffer: ArrayBuffer): PrivateKeyInfo {
    const bitArray = new ArrayBuffer(1);
    const bitView = new Uint8Array(bitArray);

    // tslint:disable-next-line:no-bitwise
    bitView[0] = bitView[0] | 0x80;
    const keyUsage = new BitString({
      valueHex: bitArray,
      unusedBits: 7
    });
    const asn1 = fromBER(privateKeyBuffer);
    const pkcs8Simpl = new PrivateKeyInfo({ schema: asn1.result });
    pkcs8Simpl.attributes = [
      new Attribute({
        type: '2.5.29.15',
        values: [
          keyUsage
        ]
      })
    ];
    return pkcs8Simpl;
  }
}
