<form class="c-form c-form--fullHeight" [formGroup]="formGroup">

  <!-- <app-chip-list-field-component [formGroup]="formGroup" name="diseaseOptions"
    placeholder="Auswahlmöglichkeiten Beschwerden *" [required]="true"></app-chip-list-field-component> -->
  <app-multilingual-chip-list-field-component [formGroup]="formGroup" [formAttribute]="diseaseOptions" [required]="true"
    placeholder="Auswahlmöglichkeiten Beschwerden *">
  </app-multilingual-chip-list-field-component>

  <div class="c-form__row c-form__row--checkboxes">
    <div class="c-form__row__item--stacked">

      <mat-checkbox formControlName="showPreExistingDiseases" color="primary">
        Textfeld <em>Vorerkrankungen</em> anzeigen
      </mat-checkbox>

      <mat-checkbox formControlName="showPreExistingAllergies" color="primary">
        Textfeld <em>Allergien</em> anzeigen
      </mat-checkbox>

      <mat-checkbox formControlName="showMedication" color="primary">
        Textfeld <em>Medikation</em> anzeigen
      </mat-checkbox>

    </div>
  </div>

</form>