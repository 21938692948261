import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'app-action-buttons',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
  ],
  templateUrl: './action-buttons.component.html',
  styleUrl: './action-buttons.component.scss',
})
export class ActionButtonsComponent {
  @Input() showPublish = false
  @Input() showPermalink = false
  @Input() permalink = ''
  @Output() onPublish = new EventEmitter<void>()
  @Output() onCopy = new EventEmitter<void>()
  @Output() onEdit = new EventEmitter<void>()
  @Output() onDelete = new EventEmitter<void>()
  @Output() onPermalink = new EventEmitter<void>()
}
