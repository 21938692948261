import { Injectable } from '@angular/core';
import { fromBase64, stringToArrayBuffer } from 'pvutils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  /**
   * enforce pem format (64 character line limit) for base64 certificate
   * @param pemString base64 certificate
   */
  public formatPEM(pemString: string): string {
    const stringLength = pemString.length;
    let formattedPEM = ``;
    for (let i = 0, count = 0; i < stringLength; i++, count++) {
      if (count > 63) {
        formattedPEM = `${formattedPEM}\n`;
        count = 0;
      }
      formattedPEM = `${formattedPEM}${pemString[i]}`;
    }
    return formattedPEM;
  }

  /**
   * remove pem headers and convert it to array buffer
   * @param pem base64 structure with specific headers
   * @param regex regular expression to identify pem headers
   */
  public pemToBuffer(pem: string, regex: RegExp): Observable<ArrayBuffer> {
    return new Observable((observer) => {
      const encoded = pem.replace(regex, ''); // remove pem header/footer
      const lineRemoveReg = new RegExp(/^\s*$(?:\r\n?|\n)/gm);
      const encodedTrimmed = encoded.replace(lineRemoveReg, '')
      // console.log('original', pem)
      // console.log('trimmed encoded', encodedTrimmed)
      const buffer = stringToArrayBuffer(fromBase64(encodedTrimmed));
      // console.log('pkijs', buffer)
      const raw = new Buffer(encodedTrimmed, 'base64').toString('binary');
      // console.log('raw', raw)
      if (buffer.byteLength > 0) {
        observer.next(buffer);
        observer.complete();
      } else {
        observer.error('empty pem buffer');
        observer.complete();
      }
    })
  }

  /**
   * check if the users browser supports web crypto
   */
  public hasCrypto(): boolean {
    if (window.crypto && window.crypto.subtle) {
      return true;
    } else {
      return false;
    }
  }

  public Utf8ArrayToString(array) {
    const len = array.length;
    let c;
    let char2, char3;

    let out = '';
    let i = 0;
    while (i < len) {
      c = array[i++];
      // tslint:disable-next-line:no-bitwise
      switch (c >> 4) {
        case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
          out += String.fromCharCode(c);
          break;
        case 12: case 13:
          char2 = array[i++];
          // tslint:disable-next-line:no-bitwise
          out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
          break;
        case 14:
          char2 = array[i++];
          char3 = array[i++];
          // tslint:disable-next-line:no-bitwise
          out += String.fromCharCode(((c & 0x0F) << 12) | ((char2 & 0x3F) << 6) | ((char3 & 0x3F) << 0));
          break;
      }
    }
    return out;
  }


  public utfToUint(message: string): Uint8Array {
    let i = 0;
    const bytes = new Uint8Array(message.length * 4);
    for (let ci = 0; ci !== message.length; ci++) {
      let c = message.charCodeAt(ci);
      if (c < 128) {
        bytes[i++] = c;
        continue;
      }
      if (c < 2048) {
        bytes[i++] = c >> 6 | 192;
      } else {
        if (c > 0xd7ff && c < 0xdc00) {
          if (++ci >= message.length) {
            throw new Error('UTF-8 encode: incomplete surrogate pair');
          }
          const c2 = message.charCodeAt(ci);
          if (c2 < 0xdc00 || c2 > 0xdfff) {
            throw new Error('UTF-8 encode: second surrogate character 0x' + c2.toString(16) + ' at index ' + ci + ' out of range');
          }
          c = 0x10000 + ((c & 0x03ff) << 10) + (c2 & 0x03ff);
          bytes[i++] = c >> 18 | 240;
          bytes[i++] = c >> 12 & 63 | 128;
        } else bytes[i++] = c >> 12 | 224;
        bytes[i++] = c >> 6 & 63 | 128;
      }
      bytes[i++] = c & 63 | 128;
    }
    return bytes.subarray(0, i);
  }

  public uintToArrayBuffer(uintArray: Uint8Array): ArrayBuffer {
    const resultBuffer = new ArrayBuffer(uintArray.length);
    const newUint = new Uint8Array(resultBuffer);
    for (let i = 0; i < uintArray.length; i++) {
      newUint[i] = uintArray[i];
    }
    return resultBuffer;
  }

  public bufferToHex(buffer: any): string {
    return Array
      .from(new Uint8Array(buffer))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  }

  public hexToBuffer(hexString: string): Uint8Array {
    if (hexString.length % 2 !== 0) {
      throw new Error('malformed hex string');
    }
    return new Uint8Array(
      hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
  }

  private async base64ToBlob(base64Url: string) {
    const res = await fetch(base64Url);
    return await res.blob();
  }

  public async downloadBase64File(base64Data: string, filename: string, type: string) {
    const fileBlob = await this.base64ToBlob(base64Data);
    const url = window.URL.createObjectURL(fileBlob);
    const downloadAnchor = document.createElement('a');
    document.body.appendChild(downloadAnchor);
    downloadAnchor.setAttribute('style', 'display: none')
    downloadAnchor.href = url;
    downloadAnchor.download = filename;
    downloadAnchor.click();
    window.URL.revokeObjectURL(url);
    downloadAnchor.remove();
  }


  /**
   * Deeply clones all given objects (via JSON) and merge/assign them in the given order.
   */
  public cloneAndMergeObjects(...objects) {
    let mergedObject = {}
    for (const obj of objects) {
      Object.assign(
        mergedObject,
        JSON.parse(JSON.stringify(obj))
      )
    }
    return mergedObject
  }

  public isExternalUrl(url: string): boolean {
    return /^http(?:s)?:\/{2}\S+$/.test(url);
  }
}
