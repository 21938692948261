import { Injectable } from '@angular/core';
import {
  Constructed,
  ObjectIdentifier,
  OctetString, UTCTime,
  fromBER
} from 'asn1js';
import {
  Attribute, Certificate, CertificateSet,
  ContentInfo, EncapsulatedContentInfo, EnvelopedData,
  IssuerAndSerialNumber, OriginatorInfo,
  SignedAndUnsignedAttributes,
  SignedData, SignerInfo
} from 'pkijs';
import { arrayBufferToString, fromBase64, stringToArrayBuffer, toBase64 } from 'pvutils';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { defaults } from './lib/defaults'
import { envelopData } from './lib/crypto/envelop-data'
import { encrypt as _encrypt } from './lib/crypto/encrypt'
import { decrypt as _decrypt } from './lib/crypto/decrypt'

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  private encryptAlgorithm = defaults.crypto.encryptAlgorithm;

  constructor(

  ) { }

  /**
   * encrypt a message with the target users public key
   * @param certificateBuffer array buffer of the personal certificate
   * @param message data to encrypt
   */
  public encrypt(
    certificateBuffer: ArrayBuffer,
    message: string,
    useCustomUmlautEncoding: boolean = false
  ): Observable<string> {
    return _encrypt(certificateBuffer, message, this.encryptAlgorithm, useCustomUmlautEncoding)
  }

  public encryptSigned(certificateBuffer: ArrayBuffer, signedMessage: ArrayBuffer, encode = true): Observable<any> {
    return new Observable((observer) => {
      envelopData(certificateBuffer, signedMessage, this.encryptAlgorithm).subscribe((encryptedData) => {
        if (encode) {
          observer.next(toBase64(arrayBufferToString(encryptedData)));
          observer.complete();
        } else {
          observer.next(encryptedData);
          observer.complete();
        }
      }, (error) => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public signAndEncrypt(certificate: Certificate, certificateBuffer: ArrayBuffer, messageBuffer: ArrayBuffer, privateKeyBuffer: ArrayBuffer, encode?: boolean): Observable<any> {
    return new Observable((observer) => {
      from(this.cmsSigned(certificate, messageBuffer, privateKeyBuffer))
        .pipe(
          mergeMap((signedBuffer) => this.encryptSigned(certificateBuffer, signedBuffer, encode))
        )
        .subscribe((encrypted) => {
          observer.next(encrypted);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
    })
  }

  public decrypt(
    recipientPrivateKey: ArrayBuffer,
    recipientCertificate: Certificate,
    encryptedData: string,
    decode = false,
    show = false,
  ): Observable<string> {
    return _decrypt(
      recipientPrivateKey,
      recipientCertificate,
      encryptedData,
      decode,
      show,
    )
  }


  public async cmsSigned(certificate: Certificate, dataBuffer: ArrayBuffer, privateKeyBuffer: ArrayBuffer): Promise<ArrayBuffer> {
    const signedData = new SignedData({
      version: 1,
      encapContentInfo: new EncapsulatedContentInfo({
        eContentType: '1.2.840.113549.1.7.1'  // data content type
      }),
      signerInfos: [
        new SignerInfo({
          version: 1,
          sid: new IssuerAndSerialNumber({
            issuer: certificate.issuer,
            serialNumber: certificate.serialNumber
          })
        })
      ],
      certificates: [certificate]
    });
    // add signer attributes
    signedData.signerInfos[0].signedAttrs = new SignedAndUnsignedAttributes({
      type: 0,
      attributes: this.addSignerAttributes()
    });
    const contentInfo = new EncapsulatedContentInfo({
      eContent: new OctetString({ valueHex: dataBuffer })
    });
    signedData.encapContentInfo.eContent = contentInfo.eContent;
    const algo = {
      name: 'RSASSA-PKCS1-v1_5',
      modulusLength: 4096,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: 'SHA-256'
    };
    const key = await crypto.subtle.importKey('pkcs8', privateKeyBuffer, algo, true, ['sign'])
    signedData.sign(key, 0, 'SHA-512');
    const signedSchema = signedData.toSchema(true);
    const cmsContentSimp = new ContentInfo({
      contentType: '1.2.840.113549.1.7.2',
      content: signedSchema
    });
    const finalSignedSchema = cmsContentSimp.toSchema();

    finalSignedSchema.lenBlock.isIndefiniteForm = true;

    const block1 = finalSignedSchema.valueBlock.value[1] as Constructed;
    block1.lenBlock.isIndefiniteForm = true;

    const block2 = block1.valueBlock.value[0] as Constructed;
    block2.lenBlock.isIndefiniteForm = true;

    const block3 = block2.valueBlock.value[2] as Constructed;
    block3.lenBlock.isIndefiniteForm = true;
    block3.valueBlock.value[1].lenBlock.isIndefiniteForm = true;
    ((block3.valueBlock.value[1] as Constructed).valueBlock.value[0] as Constructed).lenBlock.isIndefiniteForm = true;
    return finalSignedSchema.toBER(false);
  }

  private addSignerAttributes(messageDigest?: ArrayBuffer) {
    const extensions = [];
    // content type: data
    extensions.push(new Attribute({
      type: '1.2.840.113549.1.9.3',
      values: [
        new ObjectIdentifier({ value: '1.2.840.113549.1.7.1' })
      ]
    }));
    // signing time
    extensions.push(new Attribute({
      type: '1.2.840.113549.1.9.3',
      values: [
        new UTCTime({ valueDate: new Date() })
      ]
    }));
    // message digest
    if (messageDigest) {
      extensions.push(new Attribute({
        type: '1.2.840.113549.1.9.4',
        values: [
          new OctetString({ valueHex: messageDigest })
        ]
      }));
    }
    return extensions;
  }
}
