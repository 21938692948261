import { SingleOrMultilinkState as State } from './single-or-multilink.state'
import { nullish, MakeAttributeRequired } from '@arzt-direkt/wfa-generic-utils'
import { WfaFormWithResponse } from '@arzt-direkt/wfa-definitions'

type MinState = Pick<State, 'activeResponseId' | 'fwrs'>
export type RequiredMinState = {
  activeResponseId: string
  fwrs: WfaFormWithResponse[]
}

export function getMultilinkState({ activeResponseId, fwrs }: State): MinState {
  return { activeResponseId, fwrs }
}

export function notNullishMultilinkState(s: MinState): s is RequiredMinState {
  if (nullish(s.activeResponseId)) return false
  if (nullish(s.fwrs)) return false
  return true
}
