import { IsEmbeddedIn } from '@arzt-direkt/wfa-definitions'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import { nullish } from '@arzt-direkt/wfa-generic-utils'
import { Model } from 'survey-core'

import adStepperTheme from '../../shared-surveyjs/themes/theme.viewer.adStepper.json'
import adTheme from '../../shared-surveyjs/themes/theme.viewer.arzt-direkt.json'
import tomedoTheme from '../../shared-surveyjs/themes/theme.viewer.tomedo.json'

type Theme = Record<string, unknown>

export function applySurveyTheme(
  surveyModel: Model,
  isEmbeddedIn: IsEmbeddedIn,
): void {
  const theme = lookUpTheme(isEmbeddedIn)
  if (nullish(theme)) return
  surveyModel.applyTheme(theme)
}

const themeDictionary: Record<IsEmbeddedIn, Theme> = {
  arztDirekt: adTheme,
  adMultilinkViewer: adTheme,
  adPermalinkViewer: adTheme,
  adStepper: adStepperTheme,
  iOsTomedo: tomedoTheme,
  macOsTomedo: tomedoTheme,
}

function lookUpTheme(isEmbeddedIn: IsEmbeddedIn): Maybe<Theme> {
  return themeDictionary[isEmbeddedIn]
}
