export interface CertValues {
  readonly country?: string
  readonly commonName?: string
  readonly organization?: string
  readonly state?: string
  readonly city?: string
  readonly address?: string
  readonly postalCode?: string
  readonly sat?: string
}

export enum KeyFormats {
  pkcs8 = 'pkcs8',
  raw = 'raw',
  spki = 'spki'
}
