<ng-container [formGroup]="formGroup">
  <mat-label class="c-form__row">{{ selectionPlaceholder }}</mat-label>
  <div class="expandable-hint" *ngIf="hint !== undefined" (click)="expandHint = !expandHint"
    [@toggleClick]="expandHint">
    <mat-hint>
      {{ hint }}
    </mat-hint>
    <span [ngClass]="expandHint ? 'rotate' : ''" class="mat-select-arrow"></span>
  </div>
  <div class="c-form__row">
    <mat-radio-group attr.data-selenium-id="radio-auswahl" class="c-radio-group" [formControlName]="selectionName"
      [required]="true" aria-label="Select an option" color="primary">
      <mat-radio-button *ngFor="let item of options | i18nArray" [value]="item">
        {{ item }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</ng-container>
