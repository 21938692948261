import { SurveyjsPropertyInfo } from '@arzt-direkt/wfa-definitions'
import { notNullish, nullish } from '@arzt-direkt/wfa-generic-utils'
import { JsonObjectProperty, Serializer } from 'survey-core'

import { surveyjsProperties } from './properties/_surveyjs-properties.barrel'

export function customizeSurveyjsProperties() {
  surveyjsProperties.forEach((property) => {
    customizeSurveyjsProperty(property)
  })
}

function customizeSurveyjsProperty(property: SurveyjsPropertyInfo) {
  property.surveyElements.forEach((element) => {
    const prop = Serializer.getProperty(element, property.name)
    if (nullish(prop)) return
    customizePropertySettings(property, prop)
  })
}

function customizePropertySettings(
  property: SurveyjsPropertyInfo,
  prop: JsonObjectProperty,
) {
  property.customPropertySettings.forEach((setting) => {
    if (notNullish(setting.settingName))
      prop[setting.settingName] = setting.settingValue
    if (notNullish(setting.settingSetterFunc))
      prop[setting.settingSetterFunc](setting.settingValue)
  })
}
