import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { GenderString } from '@a-d/entities/Person.entity'
import { PrintableArrayRow } from '@a-d/entities/Printable.entity'
import { I18NArrayPipe } from '@a-d/i18n/i18n.pipe'
import { ReiseService } from '@a-d/instance-form/anamnese/zrm/reise/reise.service'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { InsuranceType } from 'lib'
import { isCustomFieldResponse } from '../entities/Booking.entity'

@Injectable({
  providedIn: 'root',
})
export class PrepareForDisplayService {
  prepareForPrint(
    data: Object,
    dateParser: (x: Date) => string,
  ): PrintableArrayRow<string>[] {
    const notEmpty = (v: any) => ![undefined, null, ''].includes(v)
    return Object.entries(data)
      .filter((entry) => notEmpty(entry[1]))
      .map((entry) => {
        const [key, value] = entry
        const valueFormatter = this.getValueFormatter(key, dateParser)
        return {
          name: this.keyFormatter(key, value),
          values: valueFormatter(value),
        }
      })
      .filter((printableRow) => notEmpty(printableRow.values))
      .filter((printableRow) => {
        const emptyValue =
          printableRow.values.length === 1 && printableRow.values[0] === ''
        return !emptyValue
      })
  }

  getValueFormatter(
    key: string,
    dateParser: (x: Date) => string,
  ): (value: any) => string[] | null {
    if (key === 'gender') return (v: GenderString) => [`${GenderString[v]}`]
    if (['insurance', 'insuranceType'].includes(key))
      return (v) =>
        v.insuranceType === InsuranceType.None ? null : v.insuranceType
    if (key === 'checkboxes') return (v) => null
    if (key === 'destinations')
      return (v) => [
        ReiseService.printableDestinations(v, (d) => dateParser(d)),
      ]
    if (key.includes('Date') || key.includes('date'))
      return (v) => [dateParser(v)]
    return (v) => this.defaultFormatter(v)
  }

  defaultFormatter(value: any): string[] {
    if (value.constructor.name === 'Array') return value
    if (value.constructor.name === 'String') return [value]
    if (value.constructor.name === 'Number') return [`${value}`]
    if (value.constructor.name === 'Boolean') {
      const yes = this.translate.instant(`GLOBAL.YES`)
      const no = this.translate.instant(`GLOBAL.NO`)
      return value ? [yes] : [no]
    }
    if (isCustomFieldResponse(value)) {
      return [value.value]
    }
    this.adLoggerService.error(`Formatter for value ${value} is not defined.`)
    return []
  }

  /**
   * Translate key under certain conditions
   *
   * The key is not translated if value has type CustomFieldResponse
   * The key is not translated if no translation with prefix
   * 'OTK.BOOKING-PERSONAL.' is found
   */
  keyFormatter(key: string, value: any): string {
    if (isCustomFieldResponse(value)) {
      return key
    }
    const prefix = 'OTK.BOOKING-PERSONAL.'
    const maybeTranslated = this.translate.instant(prefix + key)
    const translationFound = maybeTranslated !== prefix + key
    const result = translationFound ? maybeTranslated : key
    return result
  }

  constructor(
    private adLoggerService: AdLoggerService,
    private translate: TranslateService,
    private i18nPipe: I18NArrayPipe,
  ) {}
}
