import FormValidators from '@a-d/forms/form-validators.service';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { Observable, Subject, Subscriber, Subscription, of } from 'rxjs';
import { catchError, delay, mergeMap } from 'rxjs/operators';
import { BookingStep, PatientInsuranceType } from '../../entities/Booking.entity';
import validators from '../booking-personal/validators';
import { BookingStandaloneService } from '../booking-standalone.service';
import { BookingService } from '../booking.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-booking-preliminary',
  templateUrl: './booking-preliminary.component.html',
  styleUrls: ['./booking-preliminary.component.scss', '../../../styles.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, MatButtonToggleModule, NgFor, NgClass, MatIconModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, TranslateModule]
})
export class BookingPreliminaryComponent implements OnInit {
  readonly MIN_AGE_FILTER = FormValidators.getMinimumAgeDateFilter(0);
  readonly MIN_BIRTH_DATE = dayjs().subtract(150, 'years').toDate()
  readonly birthDateStart = validators.birthDateStart;
  public failureAssetUrl = `${environment.url}/assets/otk/booking/failure.svg`

  get bookingPreliminaryFormGroup(): FormGroup { return this.bookingService.bookingPreliminaryFormGroup }
  isServerError: boolean
  params: Params
  isStepActive: boolean
  isZwCodeCorrect: boolean = false
  subscriptionMagicFill: Subscription

  // query parameter - ik 
  get isKnown(): AbstractControl<boolean> { return this.bookingService.bookingPreliminaryFormGroup.get('isKnown') }
  isIkActive: boolean = false;
  doIkParamExist: boolean = false;
  isIkParamValid: boolean = false;

  // query parameter - it
  get insuranceType(): AbstractControl<PatientInsuranceType> { return this.bookingService.bookingPreliminaryFormGroup.get('insuranceType') }
  insuranceTypes: PatientInsuranceType[]
  isItActive: boolean
  doItParamExistSingle: boolean
  doItParamExistMultiple: boolean // were multiple it's given as parameters
  isItParamValid: boolean

  //birthDate
  get birthDate(): AbstractControl<Date> { return this.bookingService.bookingPreliminaryFormGroup.get('birthDate') }
  isBirthdateActive = false

  constructor(
    private bookingService: BookingService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private bookingStandaloneService: BookingStandaloneService
  ) { }

  ngOnInit(): void {
    this.isZwCodeCorrect = this.bookingService.isZwCodeCorrect
    this.bookingService.isBookingInitialized$
      .pipe(
        untilDestroyed(this),
        mergeMap((isBookingInitialized: boolean) =>
          isBookingInitialized ? of(null).pipe(
            mergeMap(() => this.setIsActive()),
            mergeMap(() => this.populateInsuranceTypes()),
            mergeMap(() => this.magicFillSubscription()),
            mergeMap(() => this.handleParams())
          ) : of(null)
        ),
        catchError((error) => this.bookingService.setError(error)),
      ).subscribe()
  }

  setIsActive(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      this.isIkActive = this.bookingService.isIkActive
      this.isItActive = this.bookingService.isItActive
      this.isBirthdateActive = this.bookingService.isBirthdateActive
      this.isStepActive = this.isIkActive || this.isItActive
      subscriber.next()
      subscriber.complete()
    })
  }

  handleParams(): Observable<boolean> {
    const finished = new Subject<boolean>();

    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(
      (params) => {
        let isKnown: boolean | undefined = undefined;
        let parameterInsurances: PatientInsuranceType[] | undefined = undefined;

        if (this.bookingService.isStandalone) {
          // get parameters from service
          isKnown = this.bookingStandaloneService.known_patient;
          parameterInsurances = this.bookingStandaloneService.insuranceTypes;
        } else {
          // use parameters from route
          const ik = params['ik']
          isKnown = (ik != null) ? (ik === 'true') : undefined;
          parameterInsurances = params['it']?.split(',')
        }

        this.checkIsKnown(isKnown);
        this.checkInsurances(parameterInsurances);

        finished.next(true);
      }
    )

    return finished.asObservable();
  }

  private checkIsKnown(isKnown: boolean | undefined): void {
    // no query parameters
    if (isKnown == undefined) {
      this.doIkParamExist = false;
      this.isIkParamValid = true;
    // query parameters for 'ik'
    } else {
      this.doIkParamExist = true;
      this.isIkParamValid = true;
      this.isKnown.setValue(isKnown);
    }
  }

  private checkInsurances(insurances: PatientInsuranceType[]) {
    if (!insurances || insurances.length === 0) { return; }
    this.doItParamExistSingle = insurances.length === 1;
    this.doItParamExistMultiple = insurances.length > 1;
    this.isItParamValid = insurances
      .every((it: PatientInsuranceType) =>
        Object.values(PatientInsuranceType).includes(it)
        && this.insuranceTypes.includes(it)
      )
    if (this.isItParamValid) {
      this.insuranceTypes = insurances;
      if (this.doItParamExistSingle)
        this.insuranceType.setValue(insurances[0])
    }

  }

  // perform magic fill (fill the fields with preset values)
  magicFillSubscription(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      if (this.subscriptionMagicFill)
        this.subscriptionMagicFill.unsubscribe()
      this.subscriptionMagicFill = this.bookingService.magicFill$
        .pipe(untilDestroyed(this))
        .subscribe((magicFill: string) => magicFill === BookingStep.bookingPreliminary ? this.magicFill() : null)
      subscriber.next()
      subscriber.complete()
    })
  }

  populateInsuranceTypes(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      this.insuranceTypes = []
      for (const patientInsuranceType in this.bookingService.otkUser.insurance) {
        if (this.bookingService.otkUser.insurance[patientInsuranceType].show) {
          this.insuranceTypes.push(patientInsuranceType as PatientInsuranceType)
        }
      }
      subscriber.next()
      subscriber.complete()
    })
  }

  magicFill() {
    of(null)
      .pipe(untilDestroyed(this),
        mergeMap(() => {
          if (this.isIkActive && !this.bookingService.isKnown.value) // the second condition avoids overriding GET parameters
            this.bookingService.isKnown.setValue(true)
          if (this.isItActive && !this.bookingService.insuranceType.value) // the second condition avoids overriding GET parameters
            this.bookingService.insuranceType.setValue(PatientInsuranceType.GKV)
          if (this.isBirthdateActive && !this.bookingService.birthDate.value) // not sure whether the second condition does anything as not sure whether birthday can or will be able to be set in the future as a GET parameter
            this.bookingService.birthDate.setValue(dayjs().subtract(10, 'years').toDate())
          this.bookingService.arePrestepsCompleted$.next(true)
          return of(null)
        }),
        mergeMap(() => {
          this.bookingService.stepper.selectedIndex = 0
          this.changeDetectorRef.detectChanges()
          return of(null)
        }),
        delay(500),
        mergeMap(() => {
          this.bookingService.showBs$.value
            ? this.bookingService.magicFill$.next(BookingStep.bookingBs)
            : this.bookingService.magicFill$.next(BookingStep.bookingType)
          return of(null)
        })
      ).subscribe()
  }

  // it
  insuranceTypeOrder = ((patientInsuranceType1: PatientInsuranceType,
    patientInsuranceType2: PatientInsuranceType) =>
    Object.keys(PatientInsuranceType).indexOf(patientInsuranceType1) >
    Object.keys(PatientInsuranceType).indexOf(patientInsuranceType2)
  )

}


