// Validators that deal with instance fields: identifier,...

import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { InstanceService } from "@a-d/instance/instance.service";
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { catchError, map, Observable, of, switchMap, timer } from "rxjs";

/**
 * Returns a ValidationError if the instance identifier already exists in the data base.
 * 
 * @remark
 * Do NOT use this function directly for code outside of `validators/`,
 * use `ADValidators.uniqueIdentifier` instead.
 */
export function uniqueIdentifierValidator(instanceService: InstanceService): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return timer(700).pipe(
      switchMap(() =>
        instanceService.checkIdentifierForUniqueness(control.value)
          .pipe(map((result: boolean | { status: number, message: string }) => result ? null : { identifierDuplicate: true }),
            catchError((error) => { AdLoggerService.plain.error(error, "uniqueIdentifierValidatorIdentifier"); return of(null) }))
      )
    );
  };
}
