import { DebugService } from './debug.service'
import { Injectable } from '@angular/core'
import { NGXLogger } from 'ngx-logger'
import { getMessage, plainError, plainLog } from './ad-logger.utils'

@Injectable({
  providedIn: 'root',
})
export class AdLoggerService {
  static plain = {
    log: plainLog,
    error: plainError,
  }

  log = (x: unknown, ...y: unknown[]) => this.ngxLogger.log(x, y)
  error(error: Partial<Error> | string | object, _calledFrom?: string) {
    const calledFrom = `[${_calledFrom}]: ` ?? ''
    const hostedFromTestserver =
      window.location.hostname.includes('test') ||
      window.location.hostname.includes('localhost') ||
      window.location.hostname.includes('127.0.0.1')
    const debugActivated =
      this.debugService.activeInstance.debug?.activateAdLogger
    if (hostedFromTestserver || debugActivated) {
      return this.ngxLogger.error(calledFrom, error)
    }
    return this.ngxLogger.log(calledFrom, getMessage(error))
  }

  constructor(
    private ngxLogger: NGXLogger,
    private debugService: DebugService,
  ) {}
}
