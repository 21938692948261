import { MatDialog } from '@angular/material/dialog'
import { SurveyCreatorModel } from 'survey-creator-core'

import { Routing } from '../../interfaces'
import { customizeCategories } from './customize-categories/customize-categories'
import { customizeDefaults } from './customize-defaults/customize-defaults'
import { customizeToolboxActions } from './customize-toolbox-actions/customize-toolbox-actions'
import { handleLimitedQuestionTypes } from './handle-limited-question-types/handle-limited-question-types'

export function customizeToolbox(
  creator: SurveyCreatorModel,
  dialog: MatDialog,
  routing: Routing,
) {
  customizeToolboxActions(creator, routing)
  customizeCategories(creator)
  customizeDefaults(creator)
  handleLimitedQuestionTypes(creator, dialog)
}
