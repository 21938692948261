import Joi from 'joi'

import { BaseQuestionJoi } from './base-question.joi'
import { example } from './examples/wfa-contact-question.joi.example'

export const WfaContactQuestionJoi = BaseQuestionJoi.keys({
  type: Joi.string().valid('contact').required(),
})
  .meta({
    className: 'WfaContactQuestion',
  })
  .example(example)
