import Joi from 'joi'

import { zollsoftForms } from '../../assets/json/zollsoft/_zollsoft-forms.barrel'
import version from '../../version.json'
import { CoreLocaleJoi } from '../core-locale.joi'
import { IdJoi } from '../id.joi'
import { LocStringJoi } from '../loc-string.joi'
import { PraxisIdJoi } from '../praxis-id.joi'
import { wfaFormStatusDefault, WfaFormStatusJoi } from '../wfa-form-status.joi'
import { WfaPageJoi } from '../wfa-page.joi'
import { staticIdDict } from './../../assets/praxis-id.dict'
import { WfaFormDraft_v2_7_6 } from './../../interfaces-autogen-from-joi/wfa-form-versions/wfa-form-v2-7-6'
import { WfaForm_v2_7_6 } from './../../interfaces-autogen-from-joi/wfa-form-versions/wfa-form-v2-7-6'
import { RelaxedWfaFormIdJoi } from './../wfa-form-id.joi'

export const wfaForm_v2_7_6Default = {
  praxisId: staticIdDict.staticZsLib,
  instance: staticIdDict.staticZsLib,
  status: wfaFormStatusDefault,
  wfaVersion: version.wfaVersion,
}

export const WfaFormDraft_v2_7_6Joi: Joi.ObjectSchema<WfaFormDraft_v2_7_6> =
  Joi.object()
    .keys({
      description: LocStringJoi.optional(),
      instance: PraxisIdJoi.required().valid(Joi.ref('praxisId')),
      locale: CoreLocaleJoi.optional().description(
        'Currently necessary for legacy arzt-direkt settings.',
      ),
      praxisId: PraxisIdJoi.required(),
      pages: Joi.array().items(WfaPageJoi),
      title: LocStringJoi.required(),
      wfaFormId: RelaxedWfaFormIdJoi.required(),
      wfaFormVersionId: IdJoi.required(),
      wfaVersion: Joi.string().required(),
      _id: IdJoi.valid(Joi.ref('wfaFormVersionId')).optional(),
    })
    .description(
      'Wenn der Arzt eine Formularvorlage verändern möchte, dann wird nicht die Formularvorlage selbst geändert, sondern es wird eine Kopie erstellt. Diese Kopie wird in einer separaten mongo collection geschpeichert (wfaformdrafts), damit man auf sie für produktivzwecke nicht zufällig zugreifen kann.',
    )
    .meta({
      className: 'WfaFormDraft_v2_7_6',
    })
    .example(zollsoftForms.personalData)

export const WfaForm_v2_7_6Joi = WfaFormDraft_v2_7_6Joi.append({
  status: WfaFormStatusJoi.required(),
})
  .unknown(true)
  .meta({
    className: 'WfaForm_v2_7_6',
  })
  .example(
    zollsoftForms.personalData,
  ) as unknown as Joi.ObjectSchema<WfaForm_v2_7_6>
