<!-- booking-reservation -->
<!-- the "&& timeLeft" is for fixing "NaN" that appears in the first second or so -->
<div *ngIf="(bookingService.reservation || bookingSeriesService.reservation?.length) && timeLeft" class="reservation"
  [ngClass]="timeLeft>alertThreshold ? 'reservation--success':'reservation--warning'">
  <span>
    <fa-icon [icon]="['fal','info-circle']"></fa-icon>
  </span>
  {{ ((bookingService.seriesMode$ | async) ? 'OTK.STEPPER.RESERVED-MESSAGE-SERIES' : 'OTK.STEPPER.RESERVED-MESSAGE') |
  translate}} {{ timeLeft | secondsToDoubleDigitsMinutes }} {{'OTK.STEPPER.MINUTES' |
  translate}}
</div>