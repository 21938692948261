import { inject, NgModule } from '@angular/core'
import { Injectable } from '@angular/core'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { TargetBuild } from '@arzt-direkt/wfa-definitions'

import { NotificationService } from './../app/utility-components/notification/notification.service'
import { indesWfaEnv, WfaEnv } from './wfa-env'

@Injectable({
  providedIn: null,
})
export class WfaEnvService implements WfaEnv {
  readonly demoMode = indesWfaEnv.demoMode
  readonly editorEnv$ = indesWfaEnv.editorEnv$
  readonly encryptString = indesWfaEnv.encryptString
  readonly frontendUrl = indesWfaEnv.frontendUrl
  readonly frontendUrlSubdirectory = indesWfaEnv.frontendUrlSubdirectory
  readonly logger = indesWfaEnv.logger
  readonly production = indesWfaEnv.production
  readonly serverUrl = indesWfaEnv.serverUrl
  readonly serverUrlSubdirectory = indesWfaEnv.serverUrlSubdirectory
  readonly targetBuild: TargetBuild = indesWfaEnv.targetBuild
  readonly getInstance = () => indesWfaEnv.getInstance()

  constructor(public notification: NotificationService) {}
}

export const WfaEnvServiceProvider = {
  provide: WfaEnvService,
  useFactory: () => {
    const notification = inject(NotificationService)
    return new WfaEnvService(notification)
  },
}

/**
 * WfaEnv Service Provider Module
 *
 * @module WfaEnvSPModule
 */
@NgModule({
  imports: [MatSnackBarModule], // for notifications
  providers: [WfaEnvServiceProvider],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class WfaEnvSPModule {}
