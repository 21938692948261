import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { InstanceService } from "@a-d/instance/instance.service";
import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { ArztType } from "@lib/entities/ArztType.entity";
import { InsuranceType } from "@lib/entities/Insurance.entity";
import { OnlineDoctors } from "@lib/entities/OnlineDoctors.entity";
import { BehaviorSubject, Observable, Subject, interval, of } from "rxjs";
import {
  catchError,
  first,
  map,
  mergeMap,
  startWith,
  takeUntil
} from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class OnlineDoctorsService implements OnDestroy {
  private onlineDoctors: OnlineDoctors = {
    [ArztType.Kassenarzt]: 0,
    [ArztType.Privatarzt]: 0,
    sum: 0,
    userIds: [],
  };
  private onlineDoctorsInterval$;
  private unsubscribe$ = new Subject();
  private stop$ = new Subject();

  public onlineDoctors$ = new BehaviorSubject<OnlineDoctors>(
    this.onlineDoctors
  );

  constructor(
    private adLoggerService: AdLoggerService,
    private http: HttpClient,
    private instanceService: InstanceService
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  /**
   * @returns all available insurance-types
   */
  public allInsurances(): InsuranceType[] {
    return Object.keys(InsuranceType).map((key) => InsuranceType[key]);
  }

  /**
   * @returns all available arzt-types
   */
  public allArztTypes(): ArztType[] {
    return Object.keys(ArztType).map((key) => ArztType[key]);
  }

  /**
   * @returns `true`, if the given arzt-type is permitted to treat patients
   * with the given insurance-type.
   */
  public isTreatedByArztType(arztType: ArztType, insuranceType: InsuranceType) {
    if (
      [InsuranceType.PKV, InsuranceType.SZ, InsuranceType.None].includes(
        insuranceType
      )
    )
      return true;
    else return arztType === ArztType.Kassenarzt;
  }

  /**
   * @returns all arzt types who can treat the specified
   * insurance type
   */
  public allArztTypesTreatingInsuranceType(
    insuranceType: InsuranceType
  ): ArztType[] {
    return this.allArztTypes().filter((arztType) =>
      this.isTreatedByArztType(arztType, insuranceType)
    );
  }

  /**
   * @returns `true`, if any doctor is online
   */
  public isDoctorOnline(onlineDoctors?: OnlineDoctors): boolean {
    return this.allArztTypes()
      .map((arztType) => this.isDoctorOnlineOfType(arztType, onlineDoctors))
      .some((isOn) => isOn);
  }

  /**
   * @returns `true`, if a doctor of specified arzt-type is online
   */
  public isDoctorOnlineOfType(
    arztType: ArztType,
    onlineDoctors?: OnlineDoctors
  ): boolean {
    return ((onlineDoctors ?? this.onlineDoctors$.value)[arztType] ?? 0) > 0;
  }

  /**
   * @returns `true`, if a doctor is online who is permitted to treat
   * patients with the given insurance-type.
   */
  public isDoctorOnlineToTreatInsuranceType(
    insuranceType: InsuranceType,
    onlineDoctors?: OnlineDoctors
  ) {
    if (!insuranceType) return this.isDoctorOnline(onlineDoctors);
    return this.allArztTypesTreatingInsuranceType(
      insuranceType
    ).some((arztType) => this.isDoctorOnlineOfType(arztType, onlineDoctors));
  }

  /**
   * determines whether a doctor is online to process a form with
   * the specified insurance types.
   * @returns `true`, if any doctor is online who can process
   * any of the specified insurance types, or if not specified,
   * if any doctor is online.
   */
  public isDoctorOnlineToProcessFormInsuranceTypes(
    insuranceTypes: InsuranceType[],
    onlineDoctors?: OnlineDoctors
  ) {
    // insurance type not specified -> is any doctor online?
    if (!insuranceTypes) {
      return this.isDoctorOnline(onlineDoctors);
    }

    // insurance type specified -> is any doctor online to treat
    // any of these types?
    if (insuranceTypes) {
      return insuranceTypes.some(
        (insuranceType) => this
          .isDoctorOnlineToTreatInsuranceType(insuranceType, onlineDoctors)
      )
    }

    // return default false
    return false;
  }

  /**
   * determine fetch url.
   * @returns the fetch url
   */
  private get fetchUrl() {
    const baseUrl = environment.url
    const instanceIdentifier = this.instanceService.activeInstance.identifier;
    const url = `${baseUrl}/api/landing/${instanceIdentifier}/online-doctors`;
    return url;
  }

  /**
   * Periodically request amount of online doctors.
   */
  public startFetchInterval() {
    if (this.onlineDoctorsInterval$) return;
    console.log("Start Online-Doctors Fetching-Interval..");

    // Determine Fetch-URL
    const url = this.fetchUrl;

    this.stop$ = new Subject();
    this.onlineDoctorsInterval$ = interval(30 * 1000)
      .pipe(
        startWith(0),
        mergeMap((_) => this.http.get(url).pipe(catchError((_) => of(null)))),
        takeUntil(this.stop$),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((response: any) => {
        if (!response || response.status !== 200 || !response.onlineDoctors) {
          this.adLoggerService.error("Error while fetching online-doctors.", response);
          return;
        }

        const onlineDoctors = response.onlineDoctors;
        const didChange =
          JSON.stringify(this.onlineDoctors) !== JSON.stringify(onlineDoctors);
        if (didChange) this.adjustOnlineDoctors(onlineDoctors)
          
        
      });
  }

  private adjustOnlineDoctors(onlineDoctors: any) {
    console.log("Updated Online-Doctors:", onlineDoctors);
    this.onlineDoctors = onlineDoctors;
    this.instanceService.updateActiveInstanceFrontendOnly({onlineDoctors: onlineDoctors})
    this.onlineDoctors$.next(onlineDoctors);
  }

  /**
   * Stop Fetching amount of online doctors.
   */
  public stopFetchInterval() {
    if (this.onlineDoctorsInterval$) {
      console.log("Stopped Online-Doctors Fetching-Interval");
      this.stop$.next(null);
      this.stop$.complete();
      this.onlineDoctorsInterval$ = null;
      this.stop$ = new Subject();
    }
  }

  /**
   * make a one time request for an info about how many
   * doctors are online (for an {@link OnlineDoctors} object).
   * @param emitEvent if this should update internal values
   * and emit an event on {@link OnlineDoctorsService.onlineDoctors$}
   */
  public makeOneTimeRequest(): Observable<OnlineDoctors | null> {
    const url = this.fetchUrl;
    return this.http.get(url).pipe(
      catchError((_) => of(null)), // catch errors from http get
      first(), // take first result
      catchError((_) => of(null)), // catch EmptyError (when there is no first element)
      map((response: any) => {
        // check response status
        if (!response || response.status !== 200 || !response.onlineDoctors) {
          this.adLoggerService.error("Error while fetching online-doctors.", response);
          return null;
        }

        const onlineDoctors = response.onlineDoctors;
        const didChange =
          JSON.stringify(this.onlineDoctors) !== JSON.stringify(onlineDoctors);
        if (didChange) this.adjustOnlineDoctors(onlineDoctors)

        // return response
        return onlineDoctors;
      })
    );
  }
}
