<form class="c-form c-form--fullHeight c-form--largerSpacing" [formGroup]="formGroup">

  <app-info-banner *ngIf="!requiredFieldExists" [stickToTitle]="false">
    {{'ANAMNESE-FORM.NON-REQUIRED-FIELDS-TEXT' | translate}}
  </app-info-banner>

  <ng-container *ngFor="let field of formFields">

    <div class="c-form__row">
      <mat-form-field attr.data-selenium-id="field-custom" appearance="outline">
        <!-- TODO (Dan) 🧑‍💻 - add i18nString pipe to the field.title  and field.name bellow -->
        <mat-label>{{ field.title }}</mat-label>
        <textarea matInput cdkTextareaAutosize [formControlName]="field.name" mat-autosize=true cdkAutosizeMinRows=1
          cdkAutosizeMaxRows=5></textarea>
      </mat-form-field>
    </div>

  </ng-container>

</form>