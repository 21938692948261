import { goForm } from './../go/go.form';
import { reiseForm } from './../reise/reise.form';
import { ReiseNGoSettings } from './reise-n-go-settings.entity';
import { ReiseNGoComponent } from './reise-n-go.component';

const reiseNGoSettings = Object.assign(
  {
    goStep: {
      de: 'Gesundheitsfragebogen',
      en: 'Health survey',
    },
    reiseStep: {
      de: 'Reisefragebogen',
      en: 'Travel survey',
    },
  },
  reiseForm.defaultSettings,
  goForm.defaultSettings
);
const reiseNGoFieldTitles = Object.assign(
  {},
  reiseForm.fieldTitles,
  goForm.fieldTitles
);

export const reiseNGoForm = {
  name: 'ZRM R+GO',
  identifier: 'zrm-reise-n-go',
  component: ReiseNGoComponent,
  defaultMedicalSpecializationId: '1',
  defaultSettings: reiseNGoSettings as unknown as ReiseNGoSettings,
  fieldTitles: reiseNGoFieldTitles,
};
