import Joi from 'joi'
import { instanceWhitelabelDict } from './instance-whitelabel-dict'

export const InstanceWhitelabelKeyUntypedJoi = Joi.string()
  .valid(...Object.keys(instanceWhitelabelDict))
  .meta({
    className: 'InstanceWhitelabelKey',
  })
  .example('KLAW')

export const InstanceWhitelabelUntypedJoi = Joi.string()
  .valid(...Object.values(instanceWhitelabelDict))
  .meta({
    className: 'InstanceWhitelabel',
  })
  .example('kanzlaw')
