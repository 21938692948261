import { AdLoggerService } from '@a-d/logging/ad-logger.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { ActiveInstance } from '../../entities/ActiveInstance.entity';
import { Instance } from '../../entities/Instance.entity';
import { InstanceArztPraxisMode } from '../../entities/InstanceSettings.entity';
import { PasswordRecovery } from '../../entities/PasswordRecovery.entity';
import { Praxis } from '../../entities/Praxis.entity';
import { User, UserActivationStatus, UserSettings, UserStatus, UserUpdate } from '../../entities/User.entity';
import { InstanceService } from '../../instance/instance.service';


@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {

  private unsubscribe$ = new Subject()


  constructor(
    private adLoggerService: AdLoggerService,
    private http: HttpClient,
    private instanceService: InstanceService,
    private authService: AuthService
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }


  /**
   * Returns the UserActivationStatus of the given user-status.
   */
  public getActivationStatus(status: UserStatus): UserActivationStatus {
    switch (status) {
      case UserStatus.Registriert: return UserActivationStatus.Ausstehend
      case UserStatus.Verifiziert: return UserActivationStatus.Ausstehend
      case UserStatus.Wiederhergestellt: return UserActivationStatus.Ausstehend
      case UserStatus.Gesperrt: return UserActivationStatus.Ausstehend
      case UserStatus.Freigeschaltet: return UserActivationStatus.Aktiviert
      case UserStatus.Abgelehnt: return UserActivationStatus.Deaktiviert
      default: return null
    }
  }


  /**
   * Returns Praxis either from User or Instance depending on `arztPraxisMode`
   */
  public getPraxis(user: User, instance: Instance | ActiveInstance = this.instanceService.activeInstance): Praxis {
    if (instance?.identifier === 'admin') {
      instance = user.instance
    }
    if (!user?.praxis && (!instance || typeof instance === 'string')) {
      this.adLoggerService.error("Neither user nor instance-praxis are defined.")
      return null
    }

    const praxisIsInstanceDefined = instance?.settings?.general?.arztPraxisMode === InstanceArztPraxisMode.InstanceDefined
    if (praxisIsInstanceDefined || !user?.praxis) {
      return {
        name: instance.name,
        shortName: instance.shortName,
        bsnr: instance.bsnr,
        nbsnr: instance.nbsnr,
        contact: instance.contact,
      }

    } else {
      return user?.praxis
    }
  }


  /**
   * Changes the UserStatus of the given user.
   */
  public changeAccountStatus(user: User, status: string, reason: string, encryptedKey: string): Observable<any> {
    if (!user) {
      return throwError("Couldn't update status as no user was given")
    }

    const data = {
      id: user._id,
      instanceId: this.instanceService.activeInstance._id,
      status,
      currentStatus: user.status,
      encryptedKey: encryptedKey
    }
    if (reason) {
      data['reason'] = reason
    }

    console.log(`Changing status of user '${data.id}' to '${data.status}'..`, data)
    return this.http.post('/api/user/account', data).pipe(
      map((response: any) => {
        if (!response || response.status !== 200) {
          throw { error: "Error while changing user-status.", raw: response }
        }
        return response
      }),
      first()
    )
  }


  /**
   * Applies the given user-update.
   */
  public updateData(userId: string, userUpdate: UserUpdate | any): Observable<User> {
    const data = {
      _id: userId,
      ...userUpdate
    }

    if (!userUpdate) {
      return throwError('No update given');
    }
    console.log('Updating user..');
    return this.http.put<UserUpdate>('/api/user/account', { data }).pipe(
      map((response: any) => {
        if (!response || response.status !== 200 || !response.user) {
          throw { error: 'Error while updating user data', raw: response }
        }
        return response.user
      }),
      first()
    )
  }

  public updateKumoUsers(instanceId: string) {
    return this.http.post('/api/kumo/instanceUpdate', { instanceId: instanceId }).pipe(
      map((response: any) => {
        if (!response || response.status !== 200) {
          throw { error: 'Error updating Kumo instance data', raw: response }
        }
        return response
      }),
      first()
    )
  }


  /**
   * Updates the .pem-file download status of the given `userId`.
   */
  public updatePemDownloadStatus(userId: string, downloadStatus: boolean, isInstance = false) {
    const updateData = {
      data: {
        _id: userId,
        downloadedCryptoKeys: downloadStatus,
        isInstance: isInstance
      }
    };
    return this.http.put<any>('/api/user/account', updateData).pipe(
      map((response: any) => {
        if (!response || response.status !== 200) {
          throw { error: 'Error while updating user data', raw: response }
        }
        return response
      }),
      first()
    )
  }

  /**
   * Updates the AGB accepted status of the given `userId`.
   */
  public updateAGBAcceptedStatus(userId: string, acceptedStatus: boolean, isInstance = false) {
    const updateData = {
      data: {
        _id: userId,
        acceptedAGB: acceptedStatus,
        isInstance: isInstance
      }
    };
    return this.http.put<any>('/api/user/account', updateData).pipe(
      map((response: any) => {
        if (!response || response.status !== 200) {
          throw { error: 'Error while updating user data', raw: response }
        }
        return response
      }),
      first()
    )
  }


  /**
   * Sends a password-recovery link to the given user.
   */
  public sendPasswordRecoveryLink(email: string, identifier: string) {
    const body = {
      email: email,
      identifier: identifier
    }
    return this.http.post<any>('/api/user/recovery', body).pipe(
      map((response: any) => {
        if (!response || response.status !== 200) {
          throw { error: 'Error while sending recovery link', raw: response }
        }
        return response
      }),
      first()
    )
  }


  /**
   * Recovers password by applying the given user-update.
   */
  public recoverPassword(userUpdate: PasswordRecovery) {
    return this.http.put<any>('/api/user/recovery', userUpdate).pipe(
      map((response: any) => {
        if (!response || response.status !== 200) {
          throw { error: 'Error while resetting password', raw: response }
        }
        return response
      }),
      first()
    )
  }


  /**
   * save user settings
   */
  public saveUserSettings(settingsObj: Object, userId: User['_id'] = this.authService.user?._id): Observable<any> {
    const updateData: { settings: UserSettings } = {
      settings: {
        ...this.authService.user?.settings,
        ...settingsObj
      }
    }
    return this.updateData(userId, updateData).pipe(
      // Update user-object
      tap((updatedUser) => this.authService.user = updatedUser)
    )
  }

  /**
   * save user billing status
   */
  public saveUserBillingStatus(isRegistered: boolean, userId: User['_id'], archiveId?: string | undefined): Observable<any> {
    const conditionalArchiveId = archiveId ? { archiveId: archiveId } : {}
    const updateData = {
      registeredForMonthlyBilling: isRegistered,
      ...conditionalArchiveId
    }
    return this.updateData(userId, updateData)
  }

}
