<form class="c-form c-form--fullHeight c-form--largerSpacing" [formGroup]="formGroup">
  <div class="destination-wrapper" *ngFor="let destination of destinations; index as destinationId">
    <app-destination [destinationControl]="destination" [settings]="settings"></app-destination>

    <button mat-mini-fab color="warn" class="remove-button" (click)="reiseService.removeDestination(destinationId)"
      *ngIf="destinations.length > 1">
      <mat-icon aria-label="Remove destination">remove</mat-icon>
    </button>
  </div>

  <div class="add-button">
    <button attr.data-selenium-id="button-plus" mat-mini-fab color="success" (click)="reiseService.addDestination()">
      <mat-icon aria-label="Add another destination">add</mat-icon>
    </button>
  </div>

  <div class="c-form__row" #target>
    <app-selection-field-component [descriptionRequiredIfSonstiges]="false" [formGroup]="formGroup" [multiple]="false"
      [noOption]="false" [options]="settings.tripStyle.options" [selectionGetter]="accessControl('tripStyle')"
      [selectionPlaceholder]="settings.tripStyle.title | i18nString" [selectionPretendRequired]="true"
      [selectionRequired]="false" [sonstigesOption]="false" selectionName="tripStyle">
    </app-selection-field-component>
  </div>

  <div class="c-form__row" #target>
    <app-selection-field-component [descriptionGetter]="accessControl('customPurpose')"
      [descriptionHideUnlessSonstiges]="true" [descriptionPlaceholder]="'GLOBAL.OTHER' | translate"
      [formGroup]="formGroup" [multiple]="false" [noOption]="false" [options]="settings.purpose.options"
      [selectionGetter]="accessControl('purpose')" [selectionPlaceholder]="settings.purpose.title | i18nString"
      [selectionPretendRequired]="true" [selectionRequired]="false"
      [sonstigesOption]="settings.purpose.customOption.title | i18nString" [sonstigesOptionUndertitle]="
        settings.purpose.customOption.subtitle | i18nString
      " [descriptionPlaceholder]="
        settings.purpose.customOption.subtitle | i18nString
      " descriptionName="customPurpose" selectionName="purpose">
    </app-selection-field-component>
  </div>

  <div class="c-form__row" #target>
    <app-selection-field-component [descriptionGetter]="accessControl('customActivities')"
      [descriptionHideUnlessSonstiges]="true" [descriptionPlaceholder]="'GLOBAL.OTHER' | translate"
      [formGroup]="formGroup" [multiple]="true" [noOption]="false" [options]="settings.activities.options"
      [selectionGetter]="accessControl('activities')" [selectionPlaceholder]="settings.activities.title | i18nString"
      [selectionRequired]="false" [sonstigesOption]="settings.activities.customOption.title | i18nString"
      [sonstigesOptionUndertitle]="
        settings.activities.customOption.subtitle | i18nString
      " [descriptionPlaceholder]="
        settings.activities.customOption.subtitle | i18nString
      " descriptionName="customActivities" selectionName="activities">
    </app-selection-field-component>
  </div>

  <div class="c-form__row" #target>
    <mat-form-field attr.data-selenium-id="field-zusatzinfo-reise" appearance="outline">
      <mat-label>{{ settings.other.title | i18nString }}</mat-label>
      <textarea matInput cdkTextareaAutosize formControlName="other" [required]="false" mat-autosize="true"
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
  </div>
</form>