<ng-container *rxLet="store.linkType$; let linkType">
  @switch (linkType) {
    @case ('loading') {
      <wfa-user-message [surveyjsJson]="loadingJson"> </wfa-user-message>
    }
    @case ('multi') {
      <wfa-multilink-viewer></wfa-multilink-viewer>
    }
    @case ('single') {
      <wfa-viewer-from-link></wfa-viewer-from-link>
    }
    @case ('perma') {
      <wfa-viewer-from-link></wfa-viewer-from-link>
    }
    @default {
      <wfa-user-message [surveyjsJson]="errorJson"> </wfa-user-message>
    }
  }
</ng-container>
