import { generateFormResponse } from './../../viewer/utility/generate-form-response/generate-form-response'
import { SingleOrMultilinkStore } from './../../adis/viewer-from-link/single-or-multilink/single-or-multilink.store'
import { CommonModule } from '@angular/common'
import { Output, EventEmitter, Component, OnInit, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

import {
  WfaForm,
  WfaFormResponse,
  WfaFormWithResponse,
} from '@arzt-direkt/wfa-definitions'
import { nullish, notNullish, Maybe } from '@arzt-direkt/wfa-generic-utils'
import {
  Observable,
  map,
  filter,
  BehaviorSubject,
  of,
  withLatestFrom,
  take,
} from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-magic-fill',
  templateUrl: './magic-fill.component.html',
  styleUrls: ['./magic-fill.component.scss'],
  imports: [CommonModule, MatIconModule],
})
export class MagicFillComponent implements OnInit {
  constructor(private store: SingleOrMultilinkStore) {}

  ngOnInit() {}
  magicFill() {
    of(null)
      .pipe(
        withLatestFrom(this.store.fwr$),
        map(([_, fwr]) => fwr),
        filter(notNullish),
        take(1),
      )
      .subscribe((fwr) => {
        const surveyjsResponse = generateFormResponse(fwr.form)
        const formResponse = { ...fwr.formResponse, surveyjsResponse }
        this.store.setFormResponse(formResponse)
      })
  }
}
