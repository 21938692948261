import { InstanceForm } from '@a-d/entities/InstanceForm.entity';
import { FormHelpers } from '@a-d/forms/form-helpers.service';
import { InstanceService } from '@a-d/instance/instance.service';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ActiveInstance } from '../../../entities/ActiveInstance.entity';
import FormValidators from '../../../forms/form-validators.service';
import { UsefulComponent } from '../../../misc/useful.component';
import { InstanceFormInterface } from '../../instance-form-component.interface';
import { InstanceFormService } from '../../instance-form.service';
import { AnamneseForm } from '../anamnese-forms.service';
import { TranslateModule } from '@ngx-translate/core';
import { SelectionFieldComponent } from '../../../forms/fields/selection-field.component';
import { NgIf } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


export interface VarizenAnamneseFormSettings {
  readonly symptomOptions: string[]
  readonly personalHistoryOptions: string[]
}


@Component({
    selector: 'app-varizen-anamnese-form',
    templateUrl: './varizen-anamnese-form.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        NgIf,
        SelectionFieldComponent,
        TranslateModule,
    ],
})
export class VarizenAnamneseFormComponent extends UsefulComponent implements OnInit, InstanceFormInterface {

  @Input() anamneseForm: AnamneseForm
  @Input() settings: VarizenAnamneseFormSettings


  /**
   * Form-Group
   */
  public MIN_DATE_FILTER = FormValidators.getMinimumAgeDateFilter(0)
  public formGroup = new UntypedFormGroup({
    familyHistory: new UntypedFormControl(null),
    personalHistory: new UntypedFormControl(null, [Validators.required]),
    personalHistorySelection: new UntypedFormControl([]),
    personalHistoryDescription: new UntypedFormControl(null),
    symptomSelection: new UntypedFormControl([], [Validators.required]),
    symptomDescription: new UntypedFormControl(null),
    symptomSide: new UntypedFormControl(null, [Validators.required]),
  })

  public get familyHistory(): AbstractControl { return this.formGroup.get('familyHistory') }
  public get personalHistory(): AbstractControl { return this.formGroup.get('personalHistory') }
  public get personalHistorySelection(): AbstractControl { return this.formGroup.get('personalHistorySelection') }
  public get personalHistoryDescription(): AbstractControl { return this.formGroup.get('personalHistoryDescription') }
  public get symptomSelection(): AbstractControl { return this.formGroup.get('symptomSelection') }
  public get symptomDescription(): AbstractControl { return this.formGroup.get('symptomDescription') }
  public get symptomSide(): AbstractControl { return this.formGroup.get('symptomSide') }

  public get showPersonalHistorySelection(): boolean {
    return this.personalHistory.value === "Ja" && this.settings.personalHistoryOptions && !!this.settings.personalHistoryOptions.length
  }


  /**
    * Current Instance & Instance-Form Helpers
   */
  public get activeInstance(): ActiveInstance { return this.instanceService.activeInstance }
  public get activeInstanceForm(): InstanceForm { return this.instanceFormService.activeInstanceForm }
  public readonly isDemoMode = environment.demoMode


  constructor(
    private instanceService: InstanceService,
    private instanceFormService: InstanceFormService,
    private formHelpers: FormHelpers,
    private cd: ChangeDetectorRef,
  ) {
    super()
    this.formHelpers.syncActiveAnamneseFormWithLocalStorage(this.activeInstanceForm, this.formGroup, this.unsubscribe$)

    // Reset selection & description if ´personalHistory` is 'Nein'
    this.personalHistory.valueChanges.pipe(
      startWith(0),
      filter((_) => this.personalHistory.value === 'Nein'),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.personalHistorySelection.setValue([])
      this.personalHistoryDescription.setValue(null)
    })
  }



  // ---------------------//
  // InstanceFormInterface //
  // ---------------------//

  public getData(): any {
    return this.formHelpers.trimmedRawValue(this.formGroup)
  }

  public magicFill() {
    if (!this.isDemoMode) return

    const randomSelection = this.settings.symptomOptions[Math.floor(Math.random() * this.settings.symptomOptions.length)]
    this.symptomSelection.setValue([randomSelection])
    this.personalHistory.setValue("Nein")
    this.symptomSide.setValue("Links")

    this.cd.detectChanges()
  }

}
