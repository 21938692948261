import { BookingService } from "@a-d/booking/booking.service";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from "@angular/core";
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatCalendar } from "@angular/material/datepicker";
import dayjs from "dayjs";
import { Subject } from "rxjs";
import { startWith, takeUntil } from "rxjs/operators";
import { LanguageService } from '../../../i18n/language.service';
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

// custom header (arrows and chosen date) for mat-calendar in booking-date
@Component({
    selector: 'app-calendar-header',
    styleUrls: ['calendar-header.component.scss'],
    templateUrl: 'calendar-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, MatIconModule],
})
export class CalendarHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>()
  periodLabel: string // z.B. August 2022
  isGoToFirstMonthDisabled: boolean
  isGoToPreviousMonthDisabled: boolean
  isGoToNextMonthDisabled: boolean

  /**
   * note:
   * regarding startWith:
   * 1. it is not really deprecated https://stackoverflow.com/questions/56571406/is-the-startwith-operator-in-rxjs-really-deprecated
   * 2. it is required for mat-datepicker to display periodLabel right from the start
   * (not for mat-calendar)
   */
  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, // VSC says its not used. Filthy lie. It is used to define the mat-datepicker format
    private languageService: LanguageService,
    cdr: ChangeDetectorRef,
    private bookingService: BookingService) {
    _calendar.stateChanges
      .pipe(
        takeUntil(this._destroyed),
        startWith(null))
      .subscribe(() => {
        this.updateButtonDisablers()
        this.setPeriodLabel()
        cdr.markForCheck()
      })
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  setPeriodLabel() {
    this.periodLabel = (this._calendar.activeDate as unknown as Date)
      .toLocaleString(this.languageService.activeBaseLang,
        { year: 'numeric', month: 'long' }
      )
  }

  updateButtonDisablers() {
    const activeDate: dayjs.Dayjs = dayjs(this._calendar.activeDate as unknown as Date)
    const today: dayjs.Dayjs = dayjs()
    const monthDifference: number = activeDate.year() * 12 + activeDate.month() - today.year() * 12 - today.month() // somehow this doesn't work: activeDate.diff(dayjs(), 'month') + 1
    this.isGoToFirstMonthDisabled = monthDifference === 0
    this.isGoToPreviousMonthDisabled = !today.isBefore(activeDate)
    this.isGoToNextMonthDisabled = !today.add(this.bookingService.MAXIMAL_ALLOWED_BOOKING_IN_ADVANCE_DAYS, 'days')
      .subtract(1, 'month').isAfter(activeDate)
  }

  goToFirstMonth() {
    const activeDate: dayjs.Dayjs = dayjs(this._calendar.activeDate as unknown as Date)
    const today: dayjs.Dayjs = dayjs()
    const monthDifference: number = activeDate.year() * 12 + activeDate.month() - today.year() * 12 - today.month() // somehow this doesn't work: activeDate.diff(dayjs(), 'month') + 1
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -monthDifference)
  }

  goToPreviousMonth() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
  }

  goToNextMonth() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
  }
}
