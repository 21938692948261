import { ElementRef } from '@angular/core';
import { Asset, AssetMeta } from './PatientSession.entity';
import { SocketUserRole } from './Socket.entity';
import { AdLoggerService } from '@a-d/logging/ad-logger.service'

export interface AudioPlayerOptions {
  channels: number          // number of audio channels, default: 2 -> stereo
  sampleRate: number        // number of discrete samples, default: 44100 Hz
  codec: WebRTCAudioCodec   // typed array form, default: Float32 array buffer
  flushTime: number         //
}

export enum WebRTCConnectionType {
  DocPatient = 'docPatient',
  DocSpecialist = 'docSpecialist',
  DocTools = 'docTools',
  PatientTool = 'PatientTool',
  PatientSpecialist = 'patientSpecialist',
  SpecialistTools = 'specialistTools'
}

export interface WebRTCConnection {
  readonly type?: WebRTCConnectionType
  peerConnection?: RTCPeerConnection
  makingOffer: boolean
  isSettingRemoteAnswerPending?: boolean
  peerId?: string
  peerRole?: SocketUserRole
  iceCandidates: RTCIceCandidate[]
  remoteElement?: ElementRef
  readonly remoteStream?: MediaStream
  dataChannels?: ChannelData[]
}

export interface ChannelData {
  rtcDataChannelState?: RTCDataChannelState
  readonly rtcDataChannel?: RTCDataChannel
}

export enum RTCDataChannelLabels {
  Data = 'data'
}

export enum Origin {
  Upload = 'upload', // Asset was uploaded by user outside vss
  Snapshot = 'snapshot', // Asset was recorded by user outside vss
  Sprechstunde = 'vss', // Asset was uploaded or recording during vss
}
export interface WebRTCTransferData {
  docPatient?: PartialData
  docSpecialist?: PartialData
  docTools?: PartialData
  PatientTool?: PartialData
  patientSpecialist?: PartialData
  specialistTools?: PartialData
}
export interface PartialData {
  readonly meta: AssetMeta
  buffer: any
  transferred: number
}

export enum WebRTCAudioCodec {
  Int8 = 'int8',          // max Val = 128
  Int16 = 'int16',        // max Val = 32768
  Int32 = 'int32',        // max Val = 2147483648
  Float32 = 'float32'     // max Val = 1 (32 bit float)
}

export interface WebRTCFile extends Asset {
  sendError?: string
  syncError?: string
  selected?: boolean
  readonly connectionType?: WebRTCConnectionType
}
export interface WebRTCReceivedFile {
  filename: string
  sender: string
  createdAt: string
  senderRole?: SocketUserRole
  connectionType: WebRTCConnectionType
}

export const ToolWebRTCConnectionTypes = [WebRTCConnectionType.DocTools, WebRTCConnectionType.PatientTool, WebRTCConnectionType.SpecialistTools];
export const SpecialistTypes = [WebRTCConnectionType.DocSpecialist, WebRTCConnectionType.PatientSpecialist];

export const getConnectionType = (userRole: SocketUserRole, peerRole: SocketUserRole) => {
  switch (userRole) {
    case SocketUserRole.Arzt:
      if (peerRole === SocketUserRole.Patient) { return WebRTCConnectionType.DocPatient; }
      if (peerRole === SocketUserRole.Tool) { return WebRTCConnectionType.DocTools; }
      if (peerRole === SocketUserRole.Spezialist) { return WebRTCConnectionType.DocSpecialist; }
      break;
    case SocketUserRole.Patient:
      if (peerRole === SocketUserRole.Arzt) { return WebRTCConnectionType.DocPatient; }
      if (peerRole === SocketUserRole.Tool) { return WebRTCConnectionType.PatientTool; }
      if (peerRole === SocketUserRole.Spezialist) { return WebRTCConnectionType.PatientSpecialist; }
      break;
    case SocketUserRole.Spezialist:
      if (peerRole === SocketUserRole.Arzt) { return WebRTCConnectionType.DocSpecialist; }
      if (peerRole === SocketUserRole.Patient) { return WebRTCConnectionType.PatientSpecialist; }
      if (peerRole === SocketUserRole.Tool) { return WebRTCConnectionType.SpecialistTools; }
      break;
    case SocketUserRole.Tool:
      if (peerRole === SocketUserRole.Arzt) { return WebRTCConnectionType.DocTools; }
      if (peerRole === SocketUserRole.Patient) { return WebRTCConnectionType.PatientTool; }
      if (peerRole === SocketUserRole.Spezialist) { return WebRTCConnectionType.SpecialistTools; }
      break;
    default:
      AdLoggerService.plain.error('Unknown user role', 'WebRTC.entity/getConnectionType');
      return;
  }
}

export type ToggleStreamType = 'camera' | 'microphone' | 'screen' | 'blur'
export type StreamEffect = 'none' | 'blur' | 'color' | 'remove'
export type ToggleElementType =
  | 'messages'
  | 'files'
  | 'notes'
  | 'tool'
  | 'remoteSecond'
export enum RemoteStreamRole {
  First = 'remoteFirstStreamFillMode',
  Second = 'remoteSecondStreamFillMode',
  Tool = 'toolStreamFillMode'
}

export interface WebRTCStatisticData {
  totalSum: number
  max: number
  min: number
  valueCount: number
}
