import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup
} from "@angular/forms";
import { isDynamicallyValid as isDynamicallyValidFC } from "./form-control";

interface NamedControl {
  key: string;
  control: AbstractControl;
}

/**
 * @description
 * Returns a (flat) array of all (primitive) controls (no groups or arrays)
 * of a (possibly nested) FormGroup
 */
function collectPrimitiveControls(
  formGroup: UntypedFormGroup | UntypedFormArray
): NamedControl[] {
  let allControls: NamedControl[] = [];
  let recursiveFunc = (form: UntypedFormGroup | UntypedFormArray) => {
    if (form?.controls === null || form?.controls === undefined) return [];
    Object.entries(form.controls).forEach(([key, control]) => {
      const isFormGroup = control instanceof UntypedFormGroup;
      const isFormArray = control instanceof UntypedFormArray;
      if (isFormGroup || isFormArray) recursiveFunc(control);
      else allControls.push({ key, control });
    });
  };
  recursiveFunc(formGroup);
  return allControls;
}

/**
 * @description
 * Check whether all WfaFormControl's of the group are `valid` or `dynamicallyRequired`
 *
 * For form groups with controls that are not dynamically required, the output is the
 * same as for `formGroup.valid`
 *
 * @see [FormValidatorsService#dynamicallyRequired] (./wfa-form-control.service)
 */
function isDynamicallyValid(
  formGroup: UntypedFormGroup | UntypedFormArray
): boolean {
  let allControls = collectPrimitiveControls(formGroup).map((n) => n.control);
  return allControls.every((c) => isDynamicallyValidFC(c));
}

/**
 * @description
 * FormGroup.instance.updateValueAndValidity does not work correctly
 * for dynamically added controls. Use this function instead
 */
function updateValueAndValidity(
  formGroup: UntypedFormGroup | UntypedFormArray,
  options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }
): void {
  let allControls = collectPrimitiveControls(formGroup).map((n) => n.control);
  allControls.forEach((c) => c.updateValueAndValidity(options));
}

const WfaFormGroupService = {
  collectPrimitiveControls,
  isDynamicallyValid,
  updateValueAndValidity,
};

export {
  WfaFormGroupService,
  collectPrimitiveControls,
  isDynamicallyValid,
  updateValueAndValidity,
};
