import { CameraSnapshotComponent } from '@a-d/camera/camera-snapshot.component';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CameraSnapshotComponent as CameraSnapshotComponent_1 } from '../../camera/camera-snapshot.component';
import { NgTemplateOutlet, NgIf } from '@angular/common';
import { DialogComponent } from '../../../../../lib/src/lib/dialog/dialog.component';


@Component({
    selector: 'app-asset-upload-field-webcam-dialog',
    templateUrl: './asset-upload-field-webcam-dialog.component.html',
    styleUrls: ['./asset-upload-field-webcam-dialog.component.scss'],
    standalone: true,
    imports: [DialogComponent, MatDialogModule, NgTemplateOutlet, CameraSnapshotComponent_1, NgIf, MatButtonModule, MatIconModule, TranslateModule]
})
export class AssetUploadFieldWebcamDialogComponent implements OnInit {

  // public isLoading: boolean

  @ViewChild('camera') camera: CameraSnapshotComponent;

  constructor(
    public dialogRef: MatDialogRef<AssetUploadFieldWebcamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public photoDataUrl: string
  ) { }

  ngOnInit() { }

  @HostListener('document:keydown', ['$event']) handleKeydownEvent(event: KeyboardEvent) {
    event.preventDefault();
    if (event.key === "Enter") {
      if (this.photoDataUrl) this.dialogRef.close(this.photoDataUrl)
    }
  }

  @HostListener('document:keyup', ['$event']) handleKeyupEvent(event: KeyboardEvent) {
    if (event.code === "Space") {
      event.preventDefault();
      if (!this.photoDataUrl) {
        if (this.camera && this.camera.isPlaying) this.camera.takeSnapshot();
      } else {
        this.photoDataUrl = null
        this.camera.deleteSnapshot()
      }
    }
  }

}
