import { Injectable, NgModule } from '@angular/core'
import { NGXLogger } from 'ngx-logger'
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs'
import { environment } from '../../../environments/environment'
import { CryptoWorkerService } from '../../crypto/crypto.worker.service'
import { Asset } from '../../entities/PatientSession.entity'
import { AdisWfaEnv, EditorEnv, initialEditorEnv } from './wfa-for-ad-frontend-link'
import { latin1ToUtf8 } from './utility/latin-1-to-utf-8'
import { toBase64 } from 'pvutils';

@Injectable({
  providedIn: 'root'
})
export class AdisWfaEnvService implements AdisWfaEnv {
  readonly demoMode = environment.demoMode
  readonly editorEnv$ = new BehaviorSubject<EditorEnv>(initialEditorEnv)
  readonly frontendUrl = environment.url
  readonly frontendUrlSubdirectory = '/webforms'
  readonly production = environment.production
  readonly serverUrl = environment.otkUrl
  readonly serverUrlSubdirectory = '/api/wfb/app'
  readonly targetBuild = 'adis'
  public encryptString(certificate: string, data: string): Observable<string> {
    const useCustomUmlautEncoding = true

    return this.cryptoWorkerService
      .encryptAsset(certificate, data as unknown as Asset, useCustomUmlautEncoding)
      .pipe(
        map((v) => {
          this.logger.log(`[WFA] AdisWfaEnvService.encryptString: data, certificate, encrypted`, data, certificate, v)
          return v as unknown as string
        }),
        catchError((e) => {
          this.logger.error(`[WFA] AdisWfaEnvService.encryptString: encryption error`, e)
          return of(data)
        })
      )
  }

  constructor(
    public logger: NGXLogger,
    private cryptoWorkerService: CryptoWorkerService
  ) {}
}
