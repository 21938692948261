import { Injectable } from '@angular/core';
import { Certificate, PFX } from 'pkijs';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  private personalCertificate: Certificate;
  private personalCertificateBuffer: ArrayBuffer;
  private _instanceCertificate: Certificate;
  private _instancePrivateKeyBuffer: ArrayBuffer;
  private _privateKey: CryptoKey;
  private _publicKey: CryptoKey;
  private _privateKeyBuffer: ArrayBuffer;
  private _keystore: PFX;

  constructor(private utilityService: UtilityService) { }

  get certificate(): Certificate {
    return this.personalCertificate;
  }

  set certificate(cert) {
    this.personalCertificate = cert;
    this.personalCertificateBuffer = cert.toSchema(true).toBER(false);
  }

  get instanceCertificate(): Certificate {
    return this._instanceCertificate;
  }

  set instanceCertificate(cert: Certificate) {
    this._instanceCertificate = cert;
  }

  get instancePrivateKeyBuffer(): ArrayBuffer {
    return this._instancePrivateKeyBuffer;
  }

  set instancePrivateKeyBuffer(keyBuffer: ArrayBuffer) {
    this._instancePrivateKeyBuffer = keyBuffer;
  }

  get certificateBuffer(): ArrayBuffer {
    return this.personalCertificateBuffer;
  }

  set certificateBuffer(buffer: ArrayBuffer) {
    this.personalCertificateBuffer = buffer;
  }

  get privateKey(): CryptoKey {
    return this._privateKey;
  }

  set privateKey(key: CryptoKey) {
    if (this.utilityService.hasCrypto) {
      crypto.subtle.exportKey('pkcs8', key)
        .then((buffer) => {
          this._privateKeyBuffer = buffer;
        });
    }
    this._privateKey = key;
  }

  get publicKey(): CryptoKey {
    return this._publicKey;
  }

  set publicKey(key: CryptoKey) {
    this._publicKey = key;
  }

  get privateKeyBuffer(): ArrayBuffer {
    return this._privateKeyBuffer;
  }

  set privateKeyBuffer(buffer: ArrayBuffer) {
    this._privateKeyBuffer = buffer;
  }

  get keystore(): PFX {
    return this._keystore;
  }

  set keystore(store: PFX) {
    this._keystore = store;
  }

  public resetData() {
    this._keystore = null;
    this._privateKey = null;
    this._privateKeyBuffer = null;
    this._publicKey = null;
    // this.personalCertificate = null;
    // this.personalCertificateBuffer = null;
  }
}
