import { VarizenAnamneseFormSettings } from '@a-d/instance-form/anamnese/varizen/varizen-anamnese-form.component';
import { InstanceFormSettingsInterface } from '@a-d/instance-form/instance-form-component.interface';
import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InstanceSettingsFormsService } from '../../instance-settings-forms.service';
import { ChipListFieldComponent } from '../../../../../forms/fields/chip-list-field.component';


@Component({
    selector: 'app-varizen-anamnese-form-settings',
    templateUrl: './varizen-anamnese-form-settings.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        ChipListFieldComponent,
    ],
})
export class VarizenAnamneseFormSettingsComponent implements InstanceFormSettingsInterface {

  @Input() defaultSettings: VarizenAnamneseFormSettings
  @Input() settings: VarizenAnamneseFormSettings


  /**
   * Form-Group
   */
  public formGroup = new UntypedFormGroup({
    symptomOptions: new UntypedFormControl([], [Validators.required]),
    personalHistoryOptions: new UntypedFormControl([]),
  })

  public get symptomOptions(): AbstractControl { return this.formGroup.get('symptomOptions') }
  public get personalHistoryOptions(): AbstractControl { return this.formGroup.get('personalHistoryOptions') }


  constructor(
    private instanceSettingsFormsService: InstanceSettingsFormsService,
  ) { }

  onInit() { }


  /**
   * Gathers and returns merged settings-data.
   */
  public getMergedData(completeSettings?: boolean): any {
    return this.instanceSettingsFormsService.getMergedData(this, completeSettings)
  }

}
