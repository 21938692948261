import WfaModule from '@a-d/wfr/wfa/wfa.module';
import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { startWith } from 'rxjs/operators';
import { COUNTRIES_DB_DE } from './country-field-i18n';

/**
 * Country interface ISO 3166
 */
export interface Country {
  name: string;
  readonly alpha2Code: string;
  readonly alpha3Code: string;
  readonly numericCode: string;
}

/**
 * @deprecated is obsolete - just use CountryFieldCustomComponent
 */
@UntilDestroy()
@Component({
  selector: 'app-country-field',
  host: { 'class': 'c-form__row' },
  template: `
  <mat-form-field attr.data-selenium-id="field-land" [formGroup]="formGroup" appearance="outline">
    <mat-label>
      {{'ADDRESS-SEARCH-FIELD.COUNTRY' | translate }}<span *ngIf="isDynamicallyRequired" class="isError">*</span>
    </mat-label>
    <input attr.data-selenium-id="input-name" matInput spellcheck="false" autocomplete="chrome-off"
      [formControlName]="name"
      [required]="isRequired"
      [matAutocomplete]="countryAutocomplete">
    <mat-error *ngIf="formGroup.get(name).errors?.noCountryFound">{{ 'ADDRESS-SEARCH-FIELD.INVALID-COUNTRY' | translate }}</mat-error>
    <mat-error *ngIf="formGroup.get(name).errors?.countryNotAllowed">Erlaubte Länder: {{ onlyAllowedCountryNamesFormatted }}</mat-error>

    <mat-autocomplete #countryAutocomplete="matAutocomplete">
        <mat-option attr.data-selenium-id="mat-option-{{i}}" *ngFor="let country of filteredCountries; let i = index" [value]="country?.name">
          <small>{{country?.name}} - {{country?.alpha3Code}}</small>
        </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  `,
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatInputModule,
    MatAutocompleteModule,
    NgFor,
    MatOptionModule,
    TranslateModule,
  ],
})
export class CountryFieldComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup
  @Input() name = "country"
  @Input() required: boolean
  @Input() onlyAllowLangCodes: string[]  // `alpha2Code` country-codes (see ./country-field-i18n.ts)
  @Input() useDynamicallyRequiredValidator: boolean = false;
  readonly requiredV = Validators.required
  readonly dynamicallyRequiredV = WfaModule.WfaFormValidatorsService.dynamicallyRequired
  isRequired: boolean = false
  isDynamicallyRequired: boolean = false

  public filteredCountries: Country[] = []
  public onlyAllowedCountryNamesFormatted: string

  constructor(
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

    // Init Validator
    this.initAllowedLangCodes()

    this.isRequired = this.required && this.useDynamicallyRequiredValidator !== true
    this.isDynamicallyRequired = this.required && this.useDynamicallyRequiredValidator === true
    let countryValidators = [this.countryValidator.bind(this)]
    if (this.isRequired) countryValidators.push(this.requiredV)
    if (this.isDynamicallyRequired) countryValidators.push(this.dynamicallyRequiredV)

    this.formGroup.get(this.name).setValidators(countryValidators)
    this.cd.detectChanges()

    // Filter countries based on input
    this.formGroup.get(this.name).valueChanges.pipe(
      startWith(this.formGroup.get(this.name).value),
      untilDestroyed(this)
    ).subscribe((value) => {
      this.filterCountries(value)
    })
  }

  private initAllowedLangCodes() {
    if (this.onlyAllowLangCodes?.length) {
      this.onlyAllowedCountryNamesFormatted = COUNTRIES_DB_DE
        .filter((c) => this.onlyAllowLangCodes.includes(c.alpha2Code))
        .map((c) => c.name)
        .join(', ')
    }
  }

  private filterCountries(value: string) {
    const valueRegex = new RegExp(value, "i")
    this.filteredCountries = COUNTRIES_DB_DE
      .filter((c) => {
        return !this.onlyAllowLangCodes?.length
          || this.onlyAllowLangCodes.includes(c.alpha2Code)
      })
      .filter((c) => {
        return c.name.match(valueRegex)
          || c.alpha2Code.match(valueRegex)
          || c.alpha3Code.match(valueRegex)
      })
  }

  private countryValidator(control: UntypedFormControl): ValidationErrors {
    const errorValue = { value: control.value }
    if (!control.value && this.required) return null

    const emptyControlValue = (control.value === null || control.value === undefined || control.value === '')
    if (emptyControlValue && !this.required) return null

    const country = COUNTRIES_DB_DE.filter((c) => c.name === control.value)[0]
    if (!country) return { 'noCountryFound': errorValue }

    const countryIsAllowed = !this.onlyAllowLangCodes?.length
      || this.onlyAllowLangCodes.includes(country.alpha2Code)
    if (!countryIsAllowed) return { 'countryNotAllowed': errorValue }

    return null
  }

}
