import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class NumberHelpersService {

  constructor() { }


  /**
   * Rounds a given decimal to a fixed amound of decimal places.
   * See "Dealing with float precision in JavaScript": https://stackoverflow.com/questions/11695618/dealing-with-float-precision-in-javascript
   */
  public toFixed(number: number | string, decimalPlaces: number): number {
    const roundFactor = 10 ** Math.max(decimalPlaces, 0)
    const roundedNumber = Math.round(parseFloat(<any>number || 0) * roundFactor) / roundFactor
    return parseFloat(roundedNumber.toFixed(2))
  }

}
