import {
  getQuestions,
  HumanReadable,
  SurveyjsResponse,
  WfaForm,
  WfaQuestion,
} from '@arzt-direkt/wfa-definitions'
import { Optional } from '@arzt-direkt/wfa-generic-utils'

import { Locale, QUESTION_TYPES_SELECT, ResponseOption } from '../../types'
import { initialViewerState } from '../../viewer.state'

interface WithUnsanitizedChoices { choices?: ResponseOption[] }

const fallbackLocale = initialViewerState.env.locale

export function convertFormResponseToHumanReadable(
  wfaForm: WfaForm,
  surveyjsResponse: SurveyjsResponse,
  locale: Locale = fallbackLocale,
): HumanReadable {
  const wfaQuestions = getQuestions(wfaForm)
  const convertedFormResponse: SurveyjsResponse = {}

  Object.entries(surveyjsResponse).forEach(([questionId, questionResponse]) => {
    const wfaQuestion = getQuestionByIdentifier(wfaQuestions, questionId)
    if (wfaQuestion === undefined) return
    const convertedQuestionId = convertQuestionIdentifier(wfaQuestion, locale)
    const convertedResponse =
      isSelectQuestion(wfaQuestion) === true
        ? convertResponseOfSelectQuestion(
            wfaQuestion as WithUnsanitizedChoices,
            questionResponse as string | string[],
            locale,
          )
        : questionResponse
    Object.assign(convertedFormResponse, {
      [convertedQuestionId]: convertedResponse,
    })
  })
  return convertedFormResponse
}

export function getQuestionByIdentifier<T extends Pick<WfaQuestion, 'name'>>(
  wfaQuestions: T[],
  questionIdentifier: string,
): T | undefined {
  return wfaQuestions.find((question) => question.name === questionIdentifier)
}

export function convertQuestionIdentifier(
  wfaQuestion: Optional<Pick<WfaQuestion, 'name' | 'type' | 'title'>, 'title'>,
  locale: Locale,
): string {
  if (wfaQuestion['title'] == undefined) return wfaQuestion.name
  return (
    (wfaQuestion['title'][locale] as unknown as string) ??
    (wfaQuestion['title'][fallbackLocale] as unknown as string)
  )
}

export function isSelectQuestion(
  wfaQuestion: Pick<WfaQuestion, 'type'>,
): boolean {
  const questionType = wfaQuestion.type
  if (QUESTION_TYPES_SELECT.includes(questionType)) return true
  return false
}

export function convertResponseOfSelectQuestion(
  wfaQuestion: WithUnsanitizedChoices,
  questionResponse: string | string[],
  locale: Locale,
): string | string[] | undefined {
  const responseOptions: ResponseOption[] = wfaQuestion['choices'] ?? []
  if (typeof questionResponse === 'string')
    return convertResponseOption(responseOptions, questionResponse, locale)
  return questionResponse
    .map((response: string) =>
      convertResponseOption(responseOptions, response, locale),
    )
    .filter((option) => option != undefined) as string[]
}

export function convertResponseOption(
  responseOptions: ResponseOption[],
  questionResponse: string,
  locale: Locale,
): string | undefined {
  const responseOption = responseOptions.find((option) => {
    if (typeof option === 'string') return option === questionResponse
    if (
      typeof option === 'object' &&
      option !== null &&
      Object.keys(option).includes('value')
    )
      return option.value === questionResponse
    return false
  })
  if (responseOption == undefined || typeof responseOption === 'string')
    return responseOption as string
  if (typeof responseOption.text === 'string') return responseOption.text
  return responseOption.text[locale] ?? responseOption.text[fallbackLocale]
}
