import { AdColors } from '../schemas/interfaces/theming/ad-colors'
import { defaultAdColors } from '../schemas/joi/theming/ad-colors.default'

const dac = defaultAdColors()

export function setDocumentStyles(bork: any, adColors?: AdColors): void {
  if (document === undefined || document?.documentElement === undefined) {
    console.error("[setDocumentStyles]: 'document' not found.")
    return
  }
  const _adColors = adColors ?? dac
  Object.entries(_adColors)
    .filter(([key, _value]) => Object.keys(dac).includes(key))
    .forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value)
    })
}
