<div class="otk-snackbar">
  <div class="icon-and-message">
    <div class="icon">
      <fa-icon [icon]="['fas','exclamation-triangle']" class="fa-lg"></fa-icon>
    </div>
    <div class="message">
      {{ message }}
    </div>
  </div>
  <div class="actions">
    <span attr.data-selenium-id="span-close" (click)="close()">
      <mat-icon>close</mat-icon>
    </span>
  </div>
</div>