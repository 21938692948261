import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import { InstanceService } from 'projects/arzt-direkt/src/app/instance/instance.service';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { CryptoWorkerService } from '../../crypto/crypto.worker.service';
import { Documentation } from '../../entities/Documentation.entity';
import { Dialect } from '../../entities/Instance.entity';
import { InstanceEncryptionMode } from '../../entities/InstanceSettings.entity';
import { SocketUserRole } from '../../entities/Socket.entity';
import { WebRTCConnection, WebRTCFile } from '../../entities/WebRTC.entity';
import { ConsoleService } from '../../logging/console.service';
import { ImageScaleMode } from '../../misc/image-helpers.service';
import { DataTransferService } from '../../web-rtc/dataTransfer.service';
import { SignalingService } from '../../web-rtc/signaling.service';
import { ChatService } from '../../web-rtc/videochat/chat/chat.service';
import { certificateToBase64 } from '../../crypto/lib/convert';
import { UserDataService } from './../../crypto/userdata.service';
import { PendingStateVssData } from './../../entities/PatientSession.entity';
import { PatientSessionDataUnencrypted } from './../../entities/PatientSessionCreation.entity';
import { PatientSessionService } from './../../patient-session/patient-session.service';
import { StorageService } from './../../storage/storage.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})

export class VSSService implements OnDestroy {
  private unsubscribe$ = new Subject()
  private instanceCertificate: string
  public SPRECHSTUNDE_INFO: string = 'arztDirektWebRTC'
  public sessionId: string
  public remoteFirstStreamFillMode$: BehaviorSubject<ImageScaleMode> = new BehaviorSubject<ImageScaleMode>(ImageScaleMode.Contain)
  public remoteSecondStreamFillMode$: BehaviorSubject<ImageScaleMode> = new BehaviorSubject<ImageScaleMode>(ImageScaleMode.Contain)
  public remoteToolStreamFillMode$: BehaviorSubject<ImageScaleMode> = new BehaviorSubject<ImageScaleMode>(ImageScaleMode.Contain)
  public recalcHeaderHeight$ = new Subject<boolean>()

  constructor(
    private adLoggerService: AdLoggerService,
    private sessionService: PatientSessionService,
    private instanceService: InstanceService,
    private userDataService: UserDataService,
    private storageService: StorageService,
    private cryptoWorkerService: CryptoWorkerService,
    private dataTransferService: DataTransferService,
    private chatService: ChatService,
    private patientSessionService: PatientSessionService,
    private signalingService: SignalingService,
    private consoleService: ConsoleService,
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }

  /**
 * start a videosprechstunde
 * @param sessionId databank id of the p2p video session
 */
  public initVSSServices(sessionId: string, userRole: SocketUserRole): Observable<any> {
    console.log(" ***************** ")
    console.log(" *** VSS start *** ")
    console.log(" ***************** ")
    return new Observable((observer) => {
      this.sessionId = sessionId
      const sessionStart = `${dayjs().format('HH:mm:ss')}`;
      (userRole===SocketUserRole.Spezialist? of(null) : this.patientSessionService.updateConsultationStart(sessionStart))
        .pipe(
          mergeMap(() => {
            return of(this.dataTransferService.setRole(userRole))
          }
          ),
          mergeMap(() =>
            of(this.chatService.setRole(userRole))
          ),
          // patient only: load the WebRTCFiles from storage (in case of refresh/reconnect)
          mergeMap(() =>
            userRole === SocketUserRole.Patient
              ? this.dataTransferService.addWebRTCFilesFromLocalStorage()
              : of(null)
          ),
          // patient only: add the pre-uploaded WebRTCFiles to the session
          mergeMap(() =>
            userRole === SocketUserRole.Patient
              ? this.dataTransferService.addWebRTCFilesFromSession()
              : of(null)
          ),
          // patient only: load the chat from local storage
          mergeMap(() =>
            userRole === SocketUserRole.Patient
              ? this.chatService.loadWebRTCChatMessages()
              : of(null)
          ),
        )
        .subscribe({
          next: () => {observer.next(); observer.complete()}, 
          error: (error) => {
            this.adLoggerService.error("initVSSServices: error = ", error)
            observer.next()
            observer.complete()
          }
        })
    })
  }

  /**
   * updates the patient session at the end of the vss with the assets, chat and notes.
   * Called by all three participants so they can export pdf and zip.
   * For the Arzt it is also important to encrypt and patch the session.
   */
  public updateSessionAtEndOfVSS(): Observable<boolean> {
    let currentSession = this.patientSessionService.activeSession$.value;
    return of(null)
      .pipe(
        mergeMap(() => {
          const webRTCFiles: WebRTCFile[] = this.dataTransferService.webRTCFiles
          if (currentSession?.assets?.KVKPhoto) webRTCFiles.push(currentSession.assets.KVKPhoto)
          if (currentSession?.assets?.authPhoto) webRTCFiles.push(currentSession.assets.authPhoto)
          return this.patientSessionService.setAssetsFromAssetsArray(webRTCFiles)
        }),
        // update documentation
        mergeMap(() => forkJoin([this.storageService.getNotes(), this.chatService.getWebRTCChatMessagesAsStrings()])),
        mergeMap(([notes, webRTCChatMessagesAsStrings]) => {
          this.consoleService.logDemo("updateSessionAtEndOfVSS: notes = ", notes)
          this.consoleService.logDemo("updateSessionAtEndOfVSS: webRTCChatMessagesAsString = ", webRTCChatMessagesAsStrings)
          if (!notes)
            notes = ""
          const updatedDocumentation: Documentation = {
            ...this.patientSessionService.activeSession$.value.documentation,
            notes,
            chat: webRTCChatMessagesAsStrings,
            consultationEnd: dayjs().format('HH:mm:ss'),
          }
          return this.patientSessionService.updateDocumentation(updatedDocumentation)
        })
      )
  }

  public buildVSSUrl(vssData: PendingStateVssData, token: string, doctorId: string): string {
    if (!vssData || !token || !doctorId) return null
    let urlParams = new HttpParams()
      .set('doc', doctorId)
      .set('token', token)
    if (this.instanceService.activeInstance?.dialect === Dialect.Mitarbeiter) {
      urlParams = urlParams.set('dialect', 'mitarbeiter')
    }
    return `${vssData.vssUrl}/#/room/${vssData.vssRoom}?${urlParams.toString()}`;
  }



  public updateEncryptedDataAndAssets(): Observable<any> {
    const patientSession = this.patientSessionService.activeSession$.value;
    return this.loadUserCertificate()
      .pipe(
        mergeMap((userCert) => {
          const dataToEncrypt: PatientSessionDataUnencrypted = {
            person: patientSession.person,
            insurance: patientSession.insurance,
            anamnese: patientSession.anamnese,
            assets: patientSession.assets,
            documentation: patientSession.documentation
          }
          this.consoleService.logDemo("updateEncryptedDataAndAssets: patientSession = ", patientSession)
          const encryptedAssets = (patientSession.assets && Object.keys(patientSession.assets || {}).length > 0) ? '' : patientSession.encryptedAssets
          return this.cryptoWorkerService.encryptSession(userCert, dataToEncrypt, encryptedAssets)
        }),
        mergeMap((encryptedData) => this.sessionService.updateEncryptedDataAndAssets(encryptedData)),
        // maybe missing a patch call here like: mergeMap((encryptedData) => this.sessionService.patchSession({})),
      )
  }

  public loadUserCertificate(): Observable<string> {
    return new Observable((observer) => {
      if (this.instanceCertificate) {
        observer.next(this.instanceCertificate);
        observer.complete();
        return;
      }
      const instance = this.instanceService.activeInstance;
      if (!instance || !instance.settings || !instance.settings.general) {
        observer.error('incomplete instance settings');
        observer.complete();
        return;
      }
      const encryptMode = instance.settings.general.encryptionMode;
      if (encryptMode !== InstanceEncryptionMode.InstanceWide && encryptMode !== InstanceEncryptionMode.ArztOnly) {
        observer.error('unknown encryption mode');
        observer.complete();
        return;
      }
      const isInstanceWide = (encryptMode === InstanceEncryptionMode.InstanceWide);
      const certificate = isInstanceWide ? this.userDataService.instanceCertificate : this.userDataService.certificate;
      certificateToBase64(certificate).subscribe((certBase64) => {
        if (!certBase64) {
          observer.error('failed to load base64 certificate');
          observer.complete();
          return;
        }
        observer.next(certBase64);
        observer.complete();
      }, (error) => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  /**
 * triggers magic which causes the participants to automatically send
 * pre-made assets, chat messages and notes for testing
 * called by the doctor (only in demo mode)
 */
  public magic() {
    const webRTCConnections: WebRTCConnection[] = this.signalingService.getRunningDataConnections()
    this.dataTransferService.magic(webRTCConnections)
  }

  /*
   * clear the VSS data to prevent the case where users transfer information
   * from a previous session to a new one with a different patient.
   * This is called:
   * for the patient & specialist: at the end of the vss.
   * Note that their patientSession is not removed as they need it to save pdf/zip.
   * for the doctor: when selecting a patient and at the end of the vss.
   * note that the doctor's patientSession is indeed removed at the end of the VSS,
   * but not by this function.
   * Note that the webRTCFiles and chat are only stored in local storage for the Patient,
   * but here they are being "cleared" for all 3 participants anyways for simplicity.
   */
  public clearVSSData(): Observable<boolean> {
    return of(null)
      .pipe(
        tap(() => this.consoleService.logDemo("clearVSSData")),
        mergeMap(() => this.chatService.clear()),
        mergeMap(() => this.storageService.removeWebRTCFiles()),
        mergeMap(() => this.dataTransferService.clear()),
        mergeMap(() => this.storageService.removeNotes()),
        mergeMap(() => this.storageService.removeVSSSettings()),
      )
  }
}
