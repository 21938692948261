import { CouncilSessionStorage } from '@a-d/entities/CouncilSession.entity';
import { Injectable } from '@angular/core';
import { Color } from '@tensorflow-models/body-segmentation/dist/shared/calculators/interfaces/common_interfaces';
import dayjs from 'dayjs';
import { NgForage } from 'ngforage';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ITestData } from '../devices-tests/devices-tests-dialog.component';
import { AssetMeta, PatientSession } from '../entities/PatientSession.entity';
import { WebRTCFile } from '../entities/WebRTC.entity';
import { WebRTCChatMessage } from '../entities/WebRTCMessage.entity';
import { ConsoleService } from '../logging/console.service';
import { AssetService } from '../web-rtc/asset.service';

/** StorageServiceKeyTypes Definitions */
export enum StorageServiceKeyTypes {
  PrivateKeybuffer = 'PRIVATE_KEY_BUFFER', // used in authService.initCrypto,reloadCrypto
  PatientSession = 'PATIENT_SESSION', // only updated at the end of the VSS
  CouncilSession = 'COUNCIL_SESSION',
  VSSToken = 'VSS_TOKEN',
  WebRTCChatMessages = "WEB_RTC_CHAT_MESSAGES", //  Saved only for the patient.
  Notes = "NOTES", // VSS private notes. Saved uniquely for each participant.
  WebRTCFiles = 'WEB_RTC_FILES', // VSS assets. Saved only for the patient.
  HelperRead = 'HELPER_READ',
  DevicesTestsResults = "DEVICE_TEST_RESULTS",
  BookingPersonalFormCaching = "BOOKING_PERSONAL_FORM_CACHING",
  VSSBackgroundSettings = 'VSS_BACKGROUND_SETTINGS',
  VSSSettings = 'VSS_SETTINGS'
}

export interface vssBackgroundSettingsType {
  type: string,
  number: number,
  customColor?: [Color, string],
  customImage?: string | ArrayBuffer
}
export interface vssSettingsType {
  shareCameraStream: boolean,
  shareMicrophoneStream: boolean
}
@Injectable({
  providedIn: 'root',
})

export class StorageService {
  // private keystoreKey = 'keystore';

  constructor(
    private ngf: NgForage,
    private assetService: AssetService,
    private consoleService: ConsoleService
  ) { }

  private isKeyValid(key: StorageServiceKeyTypes): boolean {
    if (!<any>Object.values(StorageServiceKeyTypes).includes(key))
      console.warn("isKeyValid: false")
    return <any>Object.values(StorageServiceKeyTypes).includes(key)
  }

  /** GET **/

  private getItem(key: StorageServiceKeyTypes): Observable<any> {
    if (!this.isKeyValid(key))
      return
    return new Observable((observer) => {
      this.ngf
        .getItem(key)
        .then((item) => {
          observer.next(item)
          observer.complete()
        })
        .catch((error) => {
          observer.error(error);
        })
    })
  }

  public getPrivateKeyBuffer(): Observable<string> {
    return this.getItem(StorageServiceKeyTypes.PrivateKeybuffer)
  }

  public getPatientSession(): Observable<PatientSession> {
    return this.getItem(StorageServiceKeyTypes.PatientSession)
  }

  public getCouncilSession(): Observable<CouncilSessionStorage> {
    return this.getItem(StorageServiceKeyTypes.CouncilSession)
  }

  public getVSSToken(): Observable<string> {
    return this.getItem(StorageServiceKeyTypes.VSSToken)
  }

  public getWebRTCChatMessages(): Observable<WebRTCChatMessage[]> {
    return this.getItem(StorageServiceKeyTypes.WebRTCChatMessages)
  }

  public getNotes(): Observable<string> {
    return this.getItem(StorageServiceKeyTypes.Notes)
  }

  public getWebRTCFiles(): Observable<WebRTCFile[]> {
    return this.getItem(StorageServiceKeyTypes.WebRTCFiles)
  }

  public getHelperRead(): Observable<string> {
    return this.getItem(StorageServiceKeyTypes.HelperRead)
  }

  public getDevicesTestsResults(): Observable<ITestData> {
    return this.getItem(StorageServiceKeyTypes.DevicesTestsResults)
  }

  public getBookingPersonalFormCaching(instanceIdentifier: string): Observable<boolean> {
    return this.getInstanceSpecificItem(StorageServiceKeyTypes.BookingPersonalFormCaching, instanceIdentifier)
  }

  // gets VSS background settings for patient (without ID) or doctor with given ID
  public getVSSBackgroundSettings(ID?: string): Observable<any> {
    if (ID) {
      return new Observable((observer) => {
        this.ngf
          .getItem(`${StorageServiceKeyTypes.VSSBackgroundSettings}-${ID}`)
          .then((item) => {
            observer.next(item)
            observer.complete()
          })
          .catch((error) => {
            observer.error(error);
          })
      })
    }
    return this.getItem(StorageServiceKeyTypes.VSSBackgroundSettings)
  }
  // gets array of all VSS background settings to keep them when storage is cleared
  public getAllVSSBackgroundSettings(): Observable<{ key: string, value: vssBackgroundSettingsType }[]> {
    let vssBackgroundSettingsArray = []
    return new Observable((observer) => {
      this.ngf
        .iterate((value, key) => {
          if (key.includes(StorageServiceKeyTypes.VSSBackgroundSettings)) {
            vssBackgroundSettingsArray.push({ key, value })
          }
        })
        .then(() => {
          observer.next(vssBackgroundSettingsArray)
          observer.complete()
        })
        .catch((error) => {
          observer.error(error);
        })
    })
  }

  public getVSSSettings(): Observable<vssSettingsType> {
    return this.getItem(StorageServiceKeyTypes.VSSSettings)
  }

  /** SET **/

  private setItem(key: StorageServiceKeyTypes, value: any): Observable<boolean> {
    if (!this.isKeyValid(key))
      return
    return new Observable((observer) => {
      this.ngf
        .setItem(key, value)
        .then(() => {
          observer.next(true)
          observer.complete()
        })
        .catch((error) => {
          observer.error(error)
        })
    })
  }

  public setPrivateKeyBuffer(privateKeyBuffer: string): Observable<boolean> {
    return this.setItem(StorageServiceKeyTypes.PrivateKeybuffer, privateKeyBuffer)
  }

  public setPatientSession(patientSession: PatientSession): Observable<boolean> {
    return this.setItem(StorageServiceKeyTypes.PatientSession, patientSession)
  }

  public setCouncilSession(councilSession: CouncilSessionStorage): Observable<boolean> {
    return this.setItem(StorageServiceKeyTypes.CouncilSession, councilSession)
  }

  public setVSSToken(vssToken: string): Observable<boolean> {
    return this.setItem(StorageServiceKeyTypes.VSSToken, vssToken)
  }

  public setWebRTCChatMessages(webRTCChatMessages: WebRTCChatMessage[]): Observable<boolean> {
    return this.setItem(StorageServiceKeyTypes.WebRTCChatMessages, webRTCChatMessages)
  }

  public setNotes(notes: string): Observable<boolean> {
    return this.setItem(StorageServiceKeyTypes.Notes, notes)
  }

  public setWebRTCFiles(webRTCFiles: WebRTCFile[]): Observable<boolean> {
    return this.setItem(StorageServiceKeyTypes.WebRTCFiles, webRTCFiles)
  }

  public addWebRTCFiles(webRTCFilesToAdd: WebRTCFile[]): Observable<any> {
    return this.getWebRTCFiles()
      .pipe(
        mergeMap((webRTCFiles: WebRTCFile[]) => {
          if (!webRTCFiles)
            webRTCFiles = []
          webRTCFilesToAdd.forEach((webRTCFileToAdd: WebRTCFile) => {
            const webRTCFileCopy: WebRTCFile = Object.assign({}, webRTCFileToAdd)
            let i: number = -1
            if (Object.keys(webRTCFiles).length !== 0)
              i = webRTCFiles.findIndex((savedWebRTCFile) =>
                this.assetService.areAssetsIdentical(savedWebRTCFile.meta, webRTCFileCopy.meta))
            if (i === -1) {
              webRTCFiles.push(webRTCFileCopy)
            }
          })
          this.consoleService.logDemo('addWebRTCFiles: webRTCFiles = ', webRTCFiles)
          return this.setWebRTCFiles(webRTCFiles)
        })
      )
  }

  public addWebRTCChatMessage(webRTCChatMessage: WebRTCChatMessage): Observable<boolean> {
    return of(null)
      .pipe(
        mergeMap(() => this.getWebRTCChatMessages()),
        mergeMap((webRTCChatMessages: WebRTCChatMessage[]) => {
          if (!webRTCChatMessages)
            webRTCChatMessages = []
          webRTCChatMessages.push(webRTCChatMessage)
          this.consoleService.logDemo("addWebRTCChatMessage: webRTCChatMessages = ", webRTCChatMessages)
          return this.setWebRTCChatMessages(webRTCChatMessages)
        })
      )
  }

  public setHelperRead(value: string): Observable<boolean> {
    return this.setItem(StorageServiceKeyTypes.HelperRead, value)
  }

  public setDevicesTestsResults(value: ITestData): Observable<boolean> {
    const validUntil = dayjs().add(1, 'day')
    value['VALID_UNTIL'] = validUntil.toISOString()
    return this.setItem(StorageServiceKeyTypes.DevicesTestsResults, value)
  }

  public setBookingPersonalFormCaching(value: boolean, instanceIdentifier: string): Observable<boolean> {
    return this.setInstanceSpecificItem(StorageServiceKeyTypes.BookingPersonalFormCaching, instanceIdentifier, value)
  }

  // sets VSS background settings for patient (without ID), for doctor with given ID
  // or sets background settings again (with key) (called after storage was cleared)
  public setVSSBackgroundSettings(settings: vssBackgroundSettingsType, ID?: string, key?: string): Observable<boolean> {
    if (ID) {
      return new Observable((observer) => {
        this.ngf
          .setItem(`${StorageServiceKeyTypes.VSSBackgroundSettings}-${ID}`, settings)
          .then(() => {
            observer.next(true)
            observer.complete()
          })
          .catch((error) => {
            observer.error(error)
          })
      })
    }
    if (key) {
      return new Observable((observer) => {
        this.ngf
          .setItem(key, settings)
          .then(() => {
            observer.next(true)
            observer.complete()
          })
          .catch((error) => {
            observer.error(error)
          })
      })
    }
    return this.setItem(StorageServiceKeyTypes.VSSBackgroundSettings, settings)
  }

  public setVSSSettings(shareCameraStream: boolean, shareMicrophoneStream: boolean): Observable<boolean> {
    return this.setItem(StorageServiceKeyTypes.VSSSettings, { shareCameraStream, shareMicrophoneStream })
  }


  /** REMOVE **/

  public removePatientSession(): Observable<any> {
    return this.removeItem(StorageServiceKeyTypes.PatientSession)
  }

  public removeCouncilSession(): Observable<any> {
    return this.removeItem(StorageServiceKeyTypes.CouncilSession)
  }

  public removeVSSToken(): Observable<any> {
    return this.removeItem(StorageServiceKeyTypes.VSSToken)
  }

  public removeWebRTCChatMessages(): Observable<any> {
    return this.removeItem(StorageServiceKeyTypes.WebRTCChatMessages)
  }

  public removeNotes(): Observable<any> {
    this.consoleService.logDemo("removeNotes")
    return this.removeItem(StorageServiceKeyTypes.Notes)
  }

  public removeWebRTCFiles(): Observable<any> {
    return this.removeItem(StorageServiceKeyTypes.WebRTCFiles)
  }

  // removes VSS background settings for patient (without ID) or doctor with given ID
  public removeVSSBackgroundSettings(ID?: string): Observable<any> {
    if (ID) {
      return new Observable((observer) => {
        this.ngf
          .removeItem(`${StorageServiceKeyTypes.VSSBackgroundSettings}-${ID}`)
          .then(() => {
            this.consoleService.logDemo('removeItem: key = ', `${StorageServiceKeyTypes.VSSBackgroundSettings}-${ID}`)
            observer.next(true)
            observer.complete()
          })
          .catch((error) => {
            observer.error(error)
          })
      })
    }
    return this.removeItem(StorageServiceKeyTypes.VSSBackgroundSettings)
  }

  public removeVSSSettings(): Observable<any> {
    return this.removeItem(StorageServiceKeyTypes.VSSSettings)
  }

  private removeItem(key: StorageServiceKeyTypes): Observable<any> {
    if (!this.isKeyValid(key))
      return
    return new Observable((observer) => {
      this.ngf
        .removeItem(key)
        .then(() => {
          this.consoleService.logDemo('removeItem: key = ', key)
          observer.next()
          observer.complete()
        })
        .catch((error) => {
          observer.error(error)
        })
    })
  }

  public clearAll(): Observable<boolean> {
    return new Observable((observer) => {
      this.ngf
        .clear()
        .then(() => {
          observer.next(true)
          observer.complete()
        })
        .catch((error) => {
          observer.error(error)
          observer.complete()
        })
    })
  }

  public clearSharedData(): Observable<boolean> {
    return new Observable((observer) => {
      of(null).pipe(
        mergeMap(() => this.removePatientSession()),
        mergeMap(() => this.removeWebRTCFiles())
      ).subscribe(() => {
        observer.next(true)
        observer.complete()
      }),
        (error) => {
          console.warn("clearSharedData: error = ", error)
          observer.error(error)
          observer.complete()
        }
    })
  }


  //instanceSpecificStorage
  //get key prefixed with instance identifier, we could also do this with optional parameter in getItem
  private getInstanceSpecificItem(key: StorageServiceKeyTypes, instanceIdentifier: string): Observable<any> {
    if (!this.isKeyValid(key))
      return
    return new Observable((observer) => {
      this.ngf
        .getItem(`${instanceIdentifier.toUpperCase()}-${key}`)
        .then((item) => {
          observer.next(item)
          observer.complete()
        })
        .catch((error) => {
          observer.error(error);
        })
    })
  }

  private setInstanceSpecificItem(key: StorageServiceKeyTypes, instanceIdentifier: string, value: any): Observable<boolean> {
    if (!this.isKeyValid(key))
      return
    return new Observable((observer) => {
      this.ngf
        .setItem(`${instanceIdentifier.toUpperCase()}-${key}`, value)
        .then(() => {
          observer.next(true)
          observer.complete()
        })
        .catch((error) => {
          observer.error(error)
        })
    })
  }


  public deleteWebRTCFile(webRTCFileMeta: AssetMeta): Observable<boolean> {
    return of(null).pipe(
      mergeMap(() => this.getWebRTCFiles()),
      mergeMap((webRTCFiles: WebRTCFile[]) => {
        const fileIndex: number = webRTCFiles.findIndex(
          (savedWebRTCFile: WebRTCFile) =>
            this.assetService.areAssetsIdentical(savedWebRTCFile.meta, webRTCFileMeta)
        )
        if (fileIndex === -1) {
          console.warn('deleteWebRTCFile: No webRTCFile to delete')
          return of(false)
        }
        webRTCFiles.splice(fileIndex, 1)
        this.consoleService.logDemo('deleteWebRTCFile: webRTCFiles = ', webRTCFiles)
        return of(webRTCFiles)
      }),
      mergeMap((webRTCFiles: WebRTCFile[]) =>
        webRTCFiles
          ? this.setWebRTCFiles(webRTCFiles)
          : of(false))
    )
  }

  public deleteFileMessage(webRTCFileMeta: AssetMeta): Observable<boolean> {
    return of(null).pipe(
      mergeMap(() => this.getWebRTCChatMessages()),
      mergeMap((webRTCChatMessages: WebRTCChatMessage[]) => {
        const fileIndex: number = webRTCChatMessages.findIndex(
          (savedWebRTCChatMessage: WebRTCChatMessage) =>
            savedWebRTCChatMessage.type === 'file' && savedWebRTCChatMessage.fileCreatedAt === webRTCFileMeta.createdAt
        )
        if (fileIndex === -1) {
          console.warn('deleteFileMessage: No webRTCFileMessage to delete')
          return of(false)
        }
        webRTCChatMessages.splice(fileIndex, 1)
        this.consoleService.logDemo('deleteFileMessage: webRTCChatMessages = ', webRTCChatMessages)
        return of(webRTCChatMessages)
      }),
      mergeMap((webRTCChatMessages: WebRTCChatMessage[]) =>
        webRTCChatMessages
          ? this.setWebRTCChatMessages(webRTCChatMessages)
          : of(false))
    )
  }
}
