import { HslaColor } from '../schemas/interfaces/theming/hsla-color'

export function getHslaString({ h, s, l, a }: HslaColor): string {
  return `hsla(${h},${s}%,${l}%,${a})`
}

type Sla = {s: number, l: number, a: number}
export function getHslaStringFromHueAndSla(h: number, sla: Sla) {
    const {s,l,a} = sla
    return `hsla(${h},${s}%,${l}%,${a})`
}
