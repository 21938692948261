import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { Injectable, OnDestroy } from '@angular/core';
import { NotificationService } from '@lib/notifications/notification.service';
import dayjs from 'dayjs';
import { Observable, of, Subject } from 'rxjs';
import { catchError, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { CryptoWorkerService } from '../crypto/crypto.worker.service';
import { PappService } from '../dashboard/instance-settings/papp/papp.service';
import { PatientEmailService } from '../email/patient-email.service';
import { InstanceForm, InstanceFormTarget } from '../entities/InstanceForm.entity';
import { InstanceEncryptionMode } from '../entities/InstanceSettings.entity';
import { PatientEmailType } from '../entities/PatientEmail.entity';
import { PatientSession, PatientSessionError, PatientSessionErrorType } from '../entities/PatientSession.entity';
import { PatientSessionCreationResponse, PatientSessionDataEncrypted, PatientSessionDataUnencrypted, PatientSessionTomedoDataToEncrypt } from '../entities/PatientSessionCreation.entity';
import { InstanceService } from '../instance/instance.service';
import { OnlineDoctorsService } from '../online-doctors/online-doctors.service';
import { PatientSessionEncryptionService } from '../patient-session/patient-session-encryption.service';
import { PatientSessionService } from '../patient-session/patient-session.service';
import { SoundService } from '../sounds/sound.service';
import { StorageService } from '../storage/storage.service';


@Injectable({
  providedIn: 'root'
})
export class InstanceFormService implements OnDestroy {

  public activeInstanceForm: InstanceForm
  private unsubscribe$ = new Subject()

  public get pappIsRequired(): boolean {
    return !!this.instanceService?.activeInstance?.settings?.papp?.pappIsRequired
  }


  constructor(
    private adLoggerService: AdLoggerService,
    private patientEmailService: PatientEmailService,
    private notificationService: NotificationService,
    private patientSessionService: PatientSessionService,
    private storageService: StorageService,
    private instanceService: InstanceService,
    private onlineDoctorsService: OnlineDoctorsService,
    private soundService: SoundService,
    private pappService: PappService,
    private encryptionService: PatientSessionEncryptionService,
    private cryptoWorkerService: CryptoWorkerService
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }


  /**
   * Creates a new PatientSession encrypting and sending data.
   */
  public createPatientSession(dataToEncrypt: PatientSessionDataUnencrypted, dataNotToEncrypt: object, dataTomedoToEncrypt: PatientSessionTomedoDataToEncrypt, instanceForm: InstanceForm): Observable<PatientSessionCreationResponse> {
    // Store `dataToEncrypt` locally in browser for later encryption
    const storageItem: any = Object.assign({}, dataToEncrypt);
    const newInstanceForm: InstanceForm = {
      title: instanceForm.title,
      anamneseFormIdentifier: instanceForm.anamneseFormIdentifier,
      privatePayment: instanceForm.privatePayment,
    }
    storageItem.form = newInstanceForm
    // TODO: savePatientSession receives here an "any" instead of a "PatientSession"
    console.log('createPatientSession: STORAGE ITEM', storageItem)
    return this.storageService.setPatientSession(storageItem).pipe(

      // If encryption-mode is `praxis-wide` get praxis-certificate, encrypt `dataToEncrypt`
      // (and insert it as a second parameter into `patientSessionService.insert`)
      mergeMap((_) => this.encryptSessionData(dataToEncrypt)),

      // Create new PatientSession with `dataNotToEncrypt`
      mergeMap((dataEncrypted) => this.patientSessionService.insert(dataNotToEncrypt, dataEncrypted)),

      // Get Papp-Key and encrypt `dataTomedoToEncrypt`
      mergeMap((creationResponse) => this.pappService.sendPatientData(dataTomedoToEncrypt, creationResponse)),

      // Error-Handling
      catchError((error) => {
        const isPappError = error && error.name === 'PappError' && error.creationResponse
        if (isPappError && this.pappIsRequired) {
          // Invalidate Sessions and push Papp-SessionError
          const session = { _id: error.creationResponse.sessionId } as PatientSession
          const sessionError: PatientSessionError = {
            type: PatientSessionErrorType.PappError,
            date: dayjs().toDate(),
            data: error.error,
          }
          this.patientSessionService.cancel(session, sessionError).subscribe()

        } else if (isPappError && !this.pappIsRequired) {
          // Continue to Waiting-Room even if Papp failed
          return of(error.creationResponse)
        }

        throw error
      }),

      // Send Patient-Mail and housekeeping
      tap(({ sessionId }: PatientSessionCreationResponse) => {
        let targetMailAdresse = dataToEncrypt.person.email
        if (this.activeInstanceForm?.target === InstanceFormTarget.Arzt) { targetMailAdresse = dataToEncrypt.anamnese.arztEmail }
        const instanceIdentifier = this.instanceService.activeInstance.identifier
        this.sendPatientJoinedMail(sessionId, targetMailAdresse, instanceIdentifier)
        this.onlineDoctorsService.stopFetchInterval()
        this.soundService.playBlankSound();
      }),
      takeUntil(this.unsubscribe$)
    )
  }

  /**
   * Sends `SessionJoined`-Mail to Patient-Email
   */
  public sendPatientJoinedMail(sessionId: string, email: string, instanceIdentifier: string) {
    console.log(`Sending 'SessionJoined' patient-email to '${email}'..`)

    const session = <PatientSession><any>{
      _id: sessionId,
      person: { email }
    }
    this.patientEmailService.sendPatientEmail(PatientEmailType.SessionJoined, session, instanceIdentifier).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      () => {
        console.log(`Successfully sent 'SessionJoined' email to patient ('${email}')`)

      }, (error) => {
        this.adLoggerService.error(error)
        this.notificationService.displayNotification(`Fehler beim Senden der Bestätigungsmail an '${email}'`, {
          duration: 5000
        })
      }
    )
  }

  /**
   * Encrypts session data with either arzt- or instance-key
   */
  private encryptSessionData(data: PatientSessionDataUnencrypted): Observable<PatientSessionDataEncrypted> {
    return new Observable((observer) => {
      const instance = this.instanceService.activeInstance;
      if (!instance || !instance.settings || !instance.settings.general || instance.settings.general.encryptionMode !== InstanceEncryptionMode.InstanceWide) {
        observer.next(null);
        observer.complete();
        return;
      }
      this.instanceService.getCertificate(instance.identifier)
        .pipe(
          mergeMap((certificateResponse) => this.cryptoWorkerService.encryptSession(certificateResponse.certificate, data))
        )
        .subscribe((encryptedData) => {
          observer.next(encryptedData);
          observer.complete();
        }, (error) => {
          this.adLoggerService.error(error);
          observer.error(error);
          observer.complete();
        });
    })
  }
}
