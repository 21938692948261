import { Injectable } from '@angular/core'
import { TargetBuild } from '@arzt-direkt/wfa-definitions'

import { indesWfaEnv, WfaEnv } from './wfa-env'

@Injectable({
  providedIn: null,
})
export class WfaEnvService implements WfaEnv {
  readonly demoMode = indesWfaEnv.demoMode
  readonly editorEnv$ = indesWfaEnv.editorEnv$
  readonly encryptString = indesWfaEnv.encryptString
  readonly frontendUrl = indesWfaEnv.frontendUrl
  readonly frontendUrlSubdirectory = indesWfaEnv.frontendUrlSubdirectory
  readonly logger = indesWfaEnv.logger
  readonly production = indesWfaEnv.production
  readonly serverUrl = indesWfaEnv.serverUrl
  readonly serverUrlSubdirectory = indesWfaEnv.serverUrlSubdirectory
  readonly targetBuild: TargetBuild = indesWfaEnv.targetBuild
}
