import { IsEmbeddedIn } from '@arzt-direkt/wfa-definitions'
import { Model } from 'survey-core'

const cssBase = {
  text: { controlDisabled: '' },
  checkbox: { itemDisabled: '' },
  radiogroup: { itemDisabled: '' },
  comment: { controlDisabled: '' },
}

const cssHideCompleteButton = {
  navigation: { complete: 'd-none' },
}

/**
 * @param surveyModel its property `css` is mutated
 */
export function addCustomSurveyStyling(
  surveyModel: Model,
  isEmbeddedIn: IsEmbeddedIn,
): void {
  surveyModel.css =
    isEmbeddedIn === 'arztDirekt'
      ? cssBase
      : { ...cssBase, ...cssHideCompleteButton }
}
