import { KeyFormats } from '@a-d/entities/CertValues.entity';
import { AdLoggerService } from '@a-d/logging/ad-logger.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Certificate } from 'pkijs';
import { arrayBufferToString, fromBase64, stringToArrayBuffer, toBase64 } from 'pvutils';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, mergeMap, tap, timeout, toArray } from 'rxjs/operators';
import { CertificateService } from '../../../crypto/certificate.service';
import { KeyService } from '../../../crypto/key.service';
import { certificateToBase64 } from '../../../crypto/lib/convert';
import { PappRegistration, SendPappMessageBody } from '../../../entities/Papp.entity';
import { PatientSessionCreationResponse, PatientSessionTomedoDataToEncrypt } from '../../../entities/PatientSessionCreation.entity';
import { DatauriHelpersService } from '../../../misc/datauri-helpers.service';
import { CryptoService } from './../../../crypto/crypto.service';
import { UtilityService } from './../../../crypto/utility.service';
import { PappAssetResponse, PappAssetUsage, PappMessageHeader, PappSessionCompleted, PappTypeValue } from './../../../entities/Papp.entity';
import { Asset } from './../../../entities/PatientSession.entity';
import { InstanceService } from './../../../instance/instance.service';
import { XmlToJsonService } from './xmlToJson.service';

@Injectable({
  providedIn: 'root'
})
export class PappService {
  constructor(
    private adLoggerService: AdLoggerService,
    private http: HttpClient,
    private keyService: KeyService,
    private certificateService: CertificateService,
    private instanceService: InstanceService,
    private xmlToJsonService: XmlToJsonService,
    private cryptoService: CryptoService,
    private datauriHelperService: DatauriHelpersService,
    private utilityService: UtilityService
  ) { }

  /**
   * Create a new papp account with the instance data
   * @param data full papp account information
   */
  public register(data: PappRegistration) {
    const body = {
      account: data,
    }
    return this.generatePappCryptoKeys(body)
      .pipe(
        mergeMap((pappData) => this.http.post<any>('/api/papp/account', pappData))
      )
  }

  /**
   * Check if account has been registered at the papp server
   * @param instanceId id of the instance
   */
  public isRegistered(instanceId: string): Observable<boolean> {
    return this.http.post('/api/papp/registered', { id: instanceId })
      .pipe(mergeMap(({ registered }: { registered: boolean }) => of(registered)));
  }

  /**
   * Check if papp account exists and add it to the instance
   * @param instanceId id of the instance
   * @param tomedoPappLogin login name for tomedo papp account
   */
  public addTomedoPapp(instanceId: string, tomedoPappLogin: string) {
    const data = {
      id: instanceId,
      login: tomedoPappLogin
    };
    return this.http.post<any>('/api/papp/addReceiver', data);
  }

  /**
   * Load certificate for with instance associated tomedo papp account
   * @param identifier instance identifier
   */
  private getTomedoCertificate(identifier: string): Observable<any> {
    const params = new HttpParams().set('identifier', identifier);

    // return this.http.get('/api/papp/certificate', { params: params });
    const result = this.http.get('/api/papp/certificate', { params: params });
    console.log("getTomedoCertificate: result = ", result)
    return result
  }

  /**
   * 
   * @param {string} instanceIdentifier 
   * @param {string[]} encryptedData 
   * @returns Observable<SendMessageBody|null> (returns null in case of success, body otherwise)
   */
  private sendMessage(instanceIdentifier: string, encryptedData: string[]): Observable<SendPappMessageBody | null> {
    const body: SendPappMessageBody = {
      identifier: instanceIdentifier,
      assets: encryptedData[0],
      header: encryptedData[1],
      message: encryptedData[2]
    };
    console.log("sendMessage: body = ", body)
    return of(null).pipe(
      mergeMap(() => this.http.post('/api/papp-messaging/waitingRoom', body)),
      timeout(10000),
      mergeMap(() => of(null)),
      catchError((error) => {
        console.error(error)
        return of(body)
      })
    )
  }

  /**
   * upload papp asset as seperate message
   * @param assetBuffer signed and encrypted file array buffer
   * @param header signed and encrypted asset header
   * @param instanceIdentifier Id of session instance
   */
  private uploadAsset(assetBuffer: string, header: string, instanceIdentifier: string): Observable<PappAssetResponse> {
    const body = {
      identifier: instanceIdentifier,
      asset: assetBuffer,
      header: header
    };
    return this.http.post<PappAssetResponse>('/api/papp-messaging/asset', body);
  }

  private createMessageHeader(senderId: string, key: string, value: string): PappMessageHeader {
    return {
      senderId: senderId,
      metadata: {
        entry: {
          key: key,
          value: value
        }
      }
    };
  }

  public sendPatientData(dataTomedoToEncrypt: PatientSessionTomedoDataToEncrypt, creationResponse: PatientSessionCreationResponse): Observable<any> {
    return new Observable((observer) => {
      // Attach session-data from response to tomedo-data
      const { sessionId, sessionState, sessionCreatedAt } = creationResponse
      const sessionDoctorUrl = dataTomedoToEncrypt.isCouncil ?
        (this.instanceService.activeInstance?.settings?.general?.konsilShowInWaitroom ?
          `${dataTomedoToEncrypt.instanceUrl}/dashboard/warteliste?opencouncil=${sessionId}` :
          `${dataTomedoToEncrypt.instanceUrl}/dashboard/konsilwarteliste?opencouncil=${sessionId}`
        ) :
        `${dataTomedoToEncrypt.instanceUrl}/dashboard/aufrufen/${sessionId}`
      const data = {
        sessionId,
        sessionState,
        sessionCreatedAt,
        sessionDoctorUrl,
        ...dataTomedoToEncrypt,
      }

      // Gather Papp Private-Key
      const privateKey = this.instanceService?.activeInstance?.settings?.papp?.privateKey
      if (!privateKey) {
        observer.error({ name: "PappError", creationResponse, message: "Missing private key to sign message" });
        observer.complete();
        return;
      }

      console.log('Final PatientSession Tomedo-Data:', data)
      this.getTomedoCertificate(data.instanceIdentifier)
        .pipe(
          mergeMap((certificateResponse) => { return this.makeEncryptedMessage(data, certificateResponse, privateKey) }),
          mergeMap((encryptedData) => { return this.sendMessage(data.instanceIdentifier, encryptedData) }),
        )
        .subscribe({
          next: () => {
            observer.next(creationResponse);
            observer.complete();
          },
          error: (error) => {
            observer.error({ name: "PappError", creationResponse, raw: error });
            observer.complete();
          }
        });
    });
  }

  private uploadEncryptedAssets(assets: Asset[], certificate: Certificate, certBuffer: ArrayBuffer, identifier: string, privateKeyBuffer: ArrayBuffer, instanceId: string): Observable<string[]> {
    if (!assets || assets.length <= 0) {
      return of([]);
    }

    return new Observable((observer) => {
      from(assets).pipe(
        mergeMap((asset) => this.sendAsset(asset, identifier, certificate, certBuffer, privateKeyBuffer, instanceId)),
        toArray()
      ).subscribe({
        next: (sentAssets) => {
          observer.next(sentAssets)
          observer.complete()
        },
        error: (error) => {
          this.adLoggerService.error(error)
          observer.next([])
          observer.complete()
        }
      })
    })
  }

  private sendAsset(asset: Asset, identifier: string, cert: Certificate, certBuffer: ArrayBuffer, privateKeyBuffer: ArrayBuffer, instanceId: string): Observable<string> {
    return new Observable((observer) => {
      const header = this.createMessageHeader(identifier, 'filename', asset.meta.name);
      const headerBuffer = stringToArrayBuffer(this.xmlToJsonService.buildMessageHeader(header));
      const trimmedUri = this.datauriHelperService.getDataUriData(asset.dataUri);
      const assetBuffer = stringToArrayBuffer(fromBase64(trimmedUri));
      // const dataBuffer = datauri(asset.dataUri);
      forkJoin([
        this.cryptoService.signAndEncrypt(cert, certBuffer, headerBuffer, privateKeyBuffer),
        this.cryptoService.signAndEncrypt(cert, certBuffer, assetBuffer, privateKeyBuffer)
      ])
        .pipe(
          mergeMap(([encryptedHeader, encryptedAsset]) => this.uploadAsset(encryptedAsset, encryptedHeader, instanceId))
        )
        .subscribe({
          next: (response) => {
            const uploadedAsset = response.data;
            observer.next(uploadedAsset.identifier);
            observer.complete();
          },
          error: (error) => {
            observer.error({ name: 'SendAssetError', error });
            observer.complete();
          }
        })
    })
  }

  public sendSessionCompleted(sessionData: PappSessionCompleted): Observable<any> {
    return new Observable((observer) => {
      // Gather Papp Private-Key
      const privateKey = this.instanceService?.activeInstance?.settings?.papp?.privateKey
      if (!privateKey) {
        observer.next();
        observer.complete();
        return;
      }
      this.getTomedoCertificate(sessionData.instanceIdentifier)
        .pipe(
          tap(() => console.log("sendSessionCompleted: sessionData = ", sessionData)),
          mergeMap((certResponse) => this.makeEncryptedMessage(sessionData, certResponse, privateKey)),
          mergeMap((encryptedData) => this.sendMessage(sessionData.instanceIdentifier, encryptedData)),
          catchError((error) => {
            console.warn("sendSessionCompleted: error. Maybe papp is not correctly configured? error message = ", error)
            return of(null)
          })
        )
        .subscribe({
          next: () => {
            observer.next();
            observer.complete();
          },
          error: (error) => {
            observer.error(error);
            observer.complete();
          }
        });
    });
  }

  public completeAndSendPdf(sessionData: PappSessionCompleted): Observable<[SendPappMessageBody | null, SendPappMessageBody | null]> {
    return new Observable((observer) => {
      const privateKey = this.instanceService?.activeInstance?.settings?.papp?.privateKey
      if (!privateKey) {
        observer.error({ name: 'PappError', message: 'Missing private key to sign message' });
        observer.complete();
        return;
      }
      this.getTomedoCertificate(sessionData.instanceIdentifier)
        .pipe(
          mergeMap((certResponse) => this.completeVssSession(certResponse, privateKey, sessionData))
        )
        .subscribe({
          next: (responses) => {
            observer.next(responses);
            observer.complete();
          },
          error: (error) => {
            observer.error(error);
            observer.complete();
          }
        })
    })
  }

  private completeVssSession(certResponse: any, privateKey: string, sessionData: PappSessionCompleted): Observable<[SendPappMessageBody | null, SendPappMessageBody | null]> {
    return forkJoin([
      this.createMessage(certResponse, privateKey, sessionData, PappTypeValue.patientAsset),
      this.createMessage(certResponse, privateKey, sessionData, PappTypeValue.patientStatus)
    ]);
  }

  private createMessage(certificateResponse: any, privateKey: string, sessionData: PappSessionCompleted, messageType: PappTypeValue) {
    return new Observable<SendPappMessageBody | null>((observer) => {
      this.makeEncryptedMessage(sessionData, certificateResponse, privateKey, messageType)
        .pipe(
          mergeMap((encryptedData) => this.sendMessage(sessionData.instanceIdentifier, encryptedData))
        )
        .subscribe({
          next: (bodyOrNull) => {
            observer.next(bodyOrNull);
            observer.complete();
          },
          error: (error) => {
            observer.error(error);
            observer.complete();
          }
        })
    })
  }

  // tslint:disable-next-line:max-line-length
  private makeEncryptedMessage(patientData: PatientSessionTomedoDataToEncrypt | PappSessionCompleted, certResponse: any, privateKeyBase64: string, headerType = PappTypeValue.patientStatus): Observable<any> {
    return new Observable((observer) => {
      const sessionInstance = patientData.instanceIdentifier;
      const pappIdentifier = certResponse.identifier;
      const privateKeyBuffer = stringToArrayBuffer(fromBase64(privateKeyBase64));
      const tomedoCertBase64 = certResponse.certificate;
      const tomedoCertBuffer = stringToArrayBuffer(fromBase64(tomedoCertBase64));
      if (!tomedoCertBuffer || !pappIdentifier) {
        observer.error('Failed to retrieve certificate');
        observer.complete();
        return;
      }
      const messageData = this.createMessageData(patientData, pappIdentifier, headerType);
      this.certificateService.bufferToCertificate(tomedoCertBuffer).pipe(
        mergeMap((tomedoCert) => forkJoin([
          this.uploadEncryptedAssets(messageData.assets, tomedoCert, tomedoCertBuffer, pappIdentifier, privateKeyBuffer, sessionInstance),
          this.cryptoService.signAndEncrypt(tomedoCert, tomedoCertBuffer, messageData.headerBuffer, privateKeyBuffer),
          this.cryptoService.signAndEncrypt(tomedoCert, tomedoCertBuffer, messageData.messageBuffer, privateKeyBuffer)
        ]))
      ).subscribe({
        next: (data) => {
          observer.next(data);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        }
      })
    })
  }

  // tslint:disable-next-line:max-line-length
  private createMessageData(patientData: PatientSessionTomedoDataToEncrypt | PappSessionCompleted, pappIdentifier: string, headerType: string) {
    let assets = [];
    if (patientData['assets']) {
      assets = (patientData as PappSessionCompleted).assets;
      delete (patientData as PappSessionCompleted).assets;
    }
    if (patientData['anamnese']) {
      assets = (patientData as PatientSessionTomedoDataToEncrypt).anamnese['assets'];
      delete (patientData as PatientSessionTomedoDataToEncrypt).anamnese['assets'];
    }
    const header = this.createMessageHeader(pappIdentifier, 'type', headerType);
    const headerBuffer = stringToArrayBuffer(this.xmlToJsonService.buildMessageHeader(header));
    let message = '';
    switch (headerType) {
      case PappTypeValue.patientStatus:
        if (patientData['sessionDocumentation'] && (patientData as PappSessionCompleted).sessionDocumentation.vssInfoPdf) {
          delete (patientData as PappSessionCompleted).sessionDocumentation.vssInfoPdf;
        }
        message = this.xmlToJsonService.buildPatientData(patientData);
        break;
      case PappTypeValue.patientAsset:
        if (patientData['sessionDocumentation'] && (patientData as PappSessionCompleted).sessionDocumentation.vssInfoPdf) {
          const date = dayjs().format('YYYY-MM-DD');
          const assetMeta = {
            name: `vss_info_${date}`,
            type: 'application/pdf'
          };
          const infoAsset = {
            dataUri: (patientData as PappSessionCompleted).sessionDocumentation.vssInfoPdf,
            meta: assetMeta
          }
          assets.push(infoAsset);
          delete (patientData as PappSessionCompleted).sessionDocumentation.vssInfoPdf;
        }
        const patAsset = {
          sessionId: patientData.sessionId,
          usage: PappAssetUsage.info
        };
        message = this.xmlToJsonService.buildPatientAsset(patAsset);
        break;
    }
    const messageBuffer = this.utilityService.uintToArrayBuffer(this.utilityService.utfToUint(message));
    console.log("Papp XML-Message:", message);
    return {
      assets,
      headerBuffer,
      messageBuffer
    };
  }

  private generatePappCryptoKeys(body: any): Observable<any> {
    return new Observable((observer) => {
      this.keyService.createRSAKeyPair()
        .pipe(
          mergeMap((keyPair) => this.certificateService.createCertificateWithKeys(keyPair))
        )
        .subscribe({
          next: (cryptoData) => {
            const privateKey = cryptoData.keys.privateKey
            forkJoin([
              this.privateKeyToBase64(privateKey),
              certificateToBase64(cryptoData.certificate)
            ])
              .subscribe({
                next: ([privateKeyBase64, certificateBase64]) => {
                  body.privateKey = privateKeyBase64;
                  body.certificate = certificateBase64;
                  observer.next(body);
                  observer.complete();
                },
                error: (error) => {
                  observer.error(error);
                  observer.complete();
                }
              })
          },
          error: (error) => {
            observer.error(error);
            observer.complete();
          }
        })
    });
  }

  private privateKeyToBase64(privateKey: CryptoKey): Observable<string> {
    return new Observable((observer) => {
      this.keyService.exportRSAKey(privateKey, KeyFormats.pkcs8)
        .subscribe({
          next: (privateKeyBuffer) => {
            if (privateKeyBuffer) {
              observer.next(toBase64(arrayBufferToString(privateKeyBuffer)));
            } else {
              observer.error('Got no private key buffer');
            }
            observer.complete();
          },
          error: (error) => {
            observer.error(error);
            observer.complete();
          }
        })
    })
  }

  public decryptPappMessage(privateKeyBase64: string, certificateBase64: string, header: string, body: string) {
    const pappPrivateKeyBuffer = stringToArrayBuffer(fromBase64(privateKeyBase64));
    const certBuffer = stringToArrayBuffer(fromBase64(certificateBase64));
    this.certificateService.bufferToCertificate(certBuffer)
      .subscribe((certificate) => {
        forkJoin([
          this.cryptoService.decrypt(pappPrivateKeyBuffer, certificate, header, true),
          this.cryptoService.decrypt(pappPrivateKeyBuffer, certificate, body, true)
        ])
          .subscribe({
            next: ([decryptedHeader, decryptedBody]) => {
              console.log('decrypted successfully')
              console.log(decryptedHeader)
              console.log(decryptedBody)
            },
            error: (error) => {
              this.adLoggerService.error(error)
            }
          });
      })
  }
}
