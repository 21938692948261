<div class="outer-overlay-container">
  <ng-container #header></ng-container>
  <div class="overlay-map" id="map" [ngStyle]="mapStyle"></div>
  <div class="button-container">
    <button mat-icon-button (click)="onCancelClick()" 
            [matTooltip]="'GLOBAL.CLOSE' | translate"
            attr.data-selenium-id="button-map-overlay-close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
