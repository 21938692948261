import { Injectable, Renderer2, RendererFactory2 } from "@angular/core"
import { UntilDestroy } from "@ngneat/until-destroy"
import dayjs from "dayjs"
import { Observable } from "rxjs"
import { Asset, AssetMeta, Assets, NamedAssetDescription } from "../entities/PatientSession.entity"
import { SocketUserRole } from "../entities/Socket.entity"
import { Origin, WebRTCFile } from "../entities/WebRTC.entity"
import { SocketFormattersService } from "../socket/socket-formatters.service"

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AssetService {
  private renderer: Renderer2
  private virtualCanvas: HTMLCanvasElement

  constructor(
    private rendererFactory: RendererFactory2,
    private socketFormattersService: SocketFormattersService
  ) { }

  /**
 * returns true if the asset is a authentication or kvk photo
 */
  public isAssetNamed(asset: Asset): boolean {
    return (this.isAssetAuthPhoto(asset) || this.isAssetKVKPhoto(asset))
  }

  /**
 * returns true if the asset is a authentication or kvk photo
 */
  public isAssetAuthPhoto(asset: Asset): boolean {
    return (asset.meta.description === NamedAssetDescription.AuthPhoto)
  }

  /**
 * returns true if the asset is a authentication or kvk photo
 */
  public isAssetKVKPhoto(asset: Asset): boolean {
    return (asset.meta.description === NamedAssetDescription.KVKPhoto)
  }

  /**
 * checks whether two given assets are identical.
 * "identical" assets uploaded at different times do not count as identical.
 */
  public areAssetsIdentical(assetMeta1: AssetMeta, assetMeta2: AssetMeta): boolean {
    if (assetMeta1.name !== assetMeta2.name)
      return false
    // "identical" assets uploaded at different times do not count as identical.
    if (assetMeta1.createdAt !== assetMeta2.createdAt)
      return false
    if (assetMeta1.type !== assetMeta2.type)
      return false
    if (assetMeta1.size !== assetMeta2.size)
      return false
    if (assetMeta1.origin !== assetMeta2.origin)
      return false
    if ((assetMeta1.senderRole || assetMeta2.senderRole) && assetMeta1.senderRole !== assetMeta2.senderRole)
      return false
    if ((assetMeta1.capturedRole || assetMeta2.capturedRole) && assetMeta1.capturedRole !== assetMeta2.capturedRole)
      return false
    if (assetMeta1.description !== assetMeta2.description)
      return false
    return true
  }

  public doesAssetExist(asset: Asset, assets: Asset[]): Observable<boolean> {
    return new Observable((observer) => {
      const assetKeys: string[] = Object.keys(assets)
      const numOfAssets = assetKeys.length
      for (let i = 0; i < numOfAssets; i++) {
        if (this.areAssetsIdentical(asset.meta, assets[assetKeys[i]].meta)) {
          console.log("doesAssetExist: asset = ", asset, "assets[assetKeys[i]].meta = ", assets[assetKeys[i]].meta)
          console.log("doesAssetExist: yes")
          observer.next(true)
          observer.complete()
          return
        }
      }
      console.log("doesAssetExist: asset = ", asset, "assets = ", assets)
      console.log("doesAssetExist: no")
      observer.next(false)
      observer.complete()
    })
  }

  public makeFileName(file: File, origin: Origin, extension: string, time?: dayjs.Dayjs, senderRole?: SocketUserRole, capturedRole?: SocketUserRole): string {
    const generatedFilename = this.socketFormattersService.makeFileName(
      origin,
      extension,
      time,
      senderRole,
      capturedRole
    )
    const useGeneratedFilename =
      (origin === Origin.Snapshot) || (!file.name)
    const filename = useGeneratedFilename ? generatedFilename : file.name;
    return filename
  }

  private makeAssetMeta(file: File, origin: Origin, senderRole?: SocketUserRole,
    capturedRole?: SocketUserRole, name?: string, description?: string
  ): AssetMeta {
    const extension = /(?:\.([^.]+))?$/.exec(file.name)[1];
    const time = dayjs()
    if (!name)
      name = this.makeFileName(file, origin, extension, time, senderRole, capturedRole)
    if (!description)
      description = this.socketFormattersService.makeFileDescription(origin, senderRole, capturedRole)
    let assetMeta: AssetMeta
    if (capturedRole) {
      assetMeta = {
        name,
        createdAt: dayjs().toISOString(),
        type: file.type,
        size: file.size,
        description,
        origin,
        senderRole,
        capturedRole
      }
    } else {
      assetMeta = {
        name,
        createdAt: dayjs().toISOString(),
        type: file.type,
        size: file.size,
        description,
        origin,
        senderRole,
      }
    }
    return assetMeta
  }

  /**
   * doctor online receives files in a different format, namely:
   * name: "dogWhite.jpeg"
   * origin: "Upload"
  * type: "image/jpeg"
  * url: "data:ima...
  * fileReader.readAsDataUrl(file) returns an error in this case so we need
  * a separate function to read those files
   */
  public DoctorOnlineFileToAsset(doctorOnlineFile: any, origin: Origin, senderRole: SocketUserRole, capturedRole: SocketUserRole) {
    // make meta
    // const extension = /(?:\.([^.]+))?$/.exec(doctorOnlineFile.name)[1];
    // const time = dayjs()
    const description = this.socketFormattersService.makeFileDescription(origin, senderRole, capturedRole)
    const dataUriContent: string = doctorOnlineFile.url.split(',')[1]
    // https://stackoverflow.com/questions/53228948/how-to-get-image-file-size-from-base-64-string-in-javascript
    const size: number = (doctorOnlineFile.url as string).endsWith("==")
      ? (dataUriContent.length * (3 / 4)) - 2
      : (dataUriContent.length * (3 / 4)) - 1
    let meta: AssetMeta = {
      name: doctorOnlineFile.name,
      createdAt: dayjs().toISOString(),
      type: doctorOnlineFile.type,
      size: size,
      description,
      origin,
      senderRole,
      capturedRole
    }
    // make asset
    const asset: Asset = {
      dataUri: doctorOnlineFile.url as string,
      meta,
    }
    console.log("DoctorOnlineFileToAsset: asset = ", asset)
    return asset
  }

  public fileToAsset(file: File, origin: Origin, senderRole?: SocketUserRole, capturedRole?: SocketUserRole, name?: string, description?: string): Observable<WebRTCFile> {
    console.log("fileToAsset: file = ", file)
    return new Observable((observer) => {
      const meta: AssetMeta = this.makeAssetMeta(file, origin, senderRole, capturedRole, name, description)
      const fileReader: FileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        const asset: Asset = {
          dataUri: <string>fileReader.result,
          meta,
        }
        // console.log('fileToAsset: asset = ', asset)
        observer.next(asset)
        observer.complete()
      }
    })
  }

  /**
   * used to convert the kvk photo, taken at a lower quality, to an asset
   * for the vss.
   */
  public HTMLImageElementToAsset(htmlImageElement: HTMLImageElement, mimeType: string, origin: Origin, senderRole: SocketUserRole, name: string, description: string, capturedRole?: SocketUserRole): Observable<WebRTCFile> {
    return new Observable((observer) => {
      if (!this.virtualCanvas) {
        this.renderer = this.rendererFactory.createRenderer(null, null)
        this.virtualCanvas = this.renderer.createElement('canvas')
      }
      const width = htmlImageElement.naturalWidth
      const height = htmlImageElement.naturalHeight
      this.virtualCanvas.width = width
      this.virtualCanvas.height = height
      // console.log("HTMLImageElementToAsset: this.virtualCanvas.width = ", this.virtualCanvas.width)
      // console.log("HTMLImageElementToAsset: this.virtualCanvas.height = ", this.virtualCanvas.height)
      this.virtualCanvas.getContext('2d', { alpha: false }).drawImage(htmlImageElement, 0, 0, width, height)
      const quality: number = 0.7
      const imageUri: string = this.virtualCanvas.toDataURL(mimeType, quality)
      this.imageUrlToAsset(imageUri, mimeType, origin, senderRole, name, capturedRole, description)
        .subscribe((asset: Asset) => {
          observer.next(asset)
          observer.complete()
        })
      // this.virtualCanvas.toBlob((imageBlob: Blob) => {
      //   const extension: string = mimeType.split('/')[1];
      //   const time: dayjs.Dayjs = dayjs()
      //   if (!name)
      //     name = this.socketFormattersService.makeFileName(origin, extension, time, senderRole, capturedRole, description)
      //   const imageFile: File = new File([imageBlob], name, { type: mimeType })
      //   this.fileToAsset(imageFile, origin, senderRole, capturedRole, name, description)
      //     .subscribe((asset: Asset) => {
      //       observer.next(asset)
      //       observer.complete()
      //     })
      // })
    })
  }

  public imageUrlToAsset(imageUrl: string, mimeType: string, origin: Origin, senderRole: SocketUserRole, name: string, capturedRole?: SocketUserRole, description?: string): Observable<WebRTCFile> {
    return new Observable((observer) => {
      let arr = imageUrl.split(',')
      const bstr: string = atob(arr[1])
      let remainingCharacters: number = bstr.length
      let u8arr: Uint8Array = new Uint8Array(remainingCharacters)
      while (remainingCharacters--) {
        u8arr[remainingCharacters] = bstr.charCodeAt(remainingCharacters)
      }
      const filePropertyBag: FilePropertyBag = {
        type: mimeType
      }
      const file: File = new File([u8arr], name, filePropertyBag)
      this.fileToAsset(file, origin, senderRole, capturedRole, name, description)
        .subscribe((asset: Asset) => {
          observer.next(asset)
          observer.complete()
        })
    })
  }

  private dataUrlToBlob(dataUri: string, mimetype: string): Blob {
    const bytestring = atob(dataUri.split(',')[1]);
    const fileBuffer = new ArrayBuffer(bytestring.length);
    const uBuffer = new Uint8Array(fileBuffer);
    for (let i = 0; i < bytestring.length; i++) {
      uBuffer[i] = bytestring.charCodeAt(i);
    }
    return new Blob([fileBuffer], { type: mimetype });
  }

  public fileFromAsset(asset: Asset): File {
    const mimetype = asset.meta.type
    const fileBlob = this.dataUrlToBlob(asset.dataUri, mimetype)
    const filePropertyBag: FilePropertyBag = {
      type: mimetype
    }
    return new File([fileBlob], asset.meta.name, filePropertyBag)
  }

  public assetsToAssetsArray(assets: Assets): Asset[] {
    let assetsArray: Asset[] = []
    const assetsKeys: string[] = Object.keys(assets)
    const numOfAssets: number = assetsKeys.length
    for (let i: number = 0; i < numOfAssets; i++)
      assetsArray.push(assets[assetsKeys[i]])
    return assetsArray
  }

  public assetsArrayToAssets(assetsArray: Asset[]): Assets {
    let assets: Assets = {}
    const numOfAssets: number = Object.keys(assetsArray).length
    let j: number = 0
    for (let i: number = 0; i < numOfAssets; i++) {
      const asset: Asset = assetsArray[i]
      if (this.isAssetAuthPhoto(asset))
        assets.authPhoto = asset
      else if (this.isAssetKVKPhoto(asset))
        assets.KVKPhoto = asset
      else {
        assets[j] = asset
        j += 1
      }
    }
    return assets
  }

  private assetToWebRTCFile(asset: Asset): WebRTCFile {
    const webRTCFile: WebRTCFile = asset
    return webRTCFile
  }

  public assetsArrayToWebRTCFiles(assets: Asset[]): WebRTCFile[] {
    let webRTCFiles: WebRTCFile[] = []
    for (let i: number = 0; i < Object.keys(assets).length; i++) {
      webRTCFiles.push(this.assetToWebRTCFile(assets[i]))
    }
    return webRTCFiles
  }

  private webRTCFileToAsset(webRTCFile: WebRTCFile): Asset {
    const asset: Asset = {
      dataUri: webRTCFile.dataUri,
      meta: webRTCFile.meta,
    }
    console.log("webRTCFileToAsset: asset = ", asset)
    return asset
  }

  public webRTCFilesToAssets(webRTCFiles: WebRTCFile[]): Asset[] {
    console.log("webRTCFilesToAssets: webRTCFiles = ", webRTCFiles)
    const assets: Asset[] = []
    webRTCFiles.forEach((webRTCFile: WebRTCFile) => {
      assets.push(this.webRTCFileToAsset(webRTCFile))
    })
    return assets
  }

  public getSizeOfAssets(assets: Asset[]): number {
    const keys: string[] = Object.keys(assets)
    const numOfAssets: number = keys.length
    let sizeOfAssets: number = 0
    for (let i = 0; i < numOfAssets; i++) {
      sizeOfAssets += assets[i].meta.size
    }
    // console.log("getSizeOfAssets: sizeOfAssets = ", sizeOfAssets)
    return sizeOfAssets
  }

  /** before the patient enters the VSS, their auth photo and pre-loaded assets
   * are transferred to tomedo. After the VSS, this function is used to filter
   * out all assets shared during the VSS to upload them is well.
   * Note that the KVKPhoto is not uploaded at all at the moment, neither at the
   * start nor at the end of the VSS.
   */
  public filterAssetsArrayToVSSAssets(assets: Assets): Observable<Asset[]> {
    return new Observable((observer) => {
      const allAssets: Asset[] = this.assetsToAssetsArray(assets)
      // console.log("filterAssetsArrayToVSSAssets: allAssets = ", allAssets)
      const filteredAssets: Asset[] = allAssets.filter(
        (asset: Asset) =>
          asset.meta.origin === Origin.Sprechstunde
      )
      // console.log("filterAssetsArrayToVSSAssets: filteredAssets = ", filteredAssets)
      // console.log("filterAssetsArrayToVSSAssets: filteredAssets[0] = ", filteredAssets[0])
      // console.log("filterAssetsArrayToVSSAssets: filteredAssets[0].meta.name = ", filteredAssets[0].meta.name)
      observer.next(filteredAssets)
      observer.complete()
    })
  }
}
