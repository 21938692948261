export const editorStrings = {
  // lg stands for 'logic tab'
  lg: {
    empty_tab: {
      de: 'Erstellen Sie eine Regel, um den Fluss des Formulars anzupassen.',
      en: 'Create a rule to customize the flow of the form.',
      es: 'Crea una regla para personalizar el flujo de la forma.',
      fr: 'Créez une règle pour personnaliser le déroulement du formulaire.',
      it: 'Crea una regola per personalizzare il percorso del formulario.',
    },
  },
  surveyPlaceHolder: {
    de: 'Das Formular enthält keine Fragen. Ziehen Sie ein Element aus der linken Seitenleiste hier her oder drücken Sie den Button unten.',
    en: 'The form does not contain any questions. Drag an element from the left sidebar here or press the button below.',
    es: 'El formulario no contiene preguntas. Arrastre aquí un elemento de la barra lateral izquierda o pulse el botón de abajo.',
    fr: 'Le formulaire ne contient pas de questions. Faites glisser un élément de la barre latérale gauche ici ou appuyez sur le bouton ci-dessous.',
    it: 'Il modulo non contiene domande. Trascinare qui un elemento dalla barra laterale sinistra o premere il pulsante sottostante.',
  },
  testSurvey: {
    de: 'Vorschau',
    en: 'Preview',
    es: 'Vista previa del formulario',
    fr: 'Aperçu du formulaire',
    it: 'Anteprima della forma',
  },
  saveSurvey: {
    de: 'Formularvorlage speichern',
    en: 'Save form',
    es: 'Guardar la encuesta',
    fr: 'Sauvegarder le sondage',
    it: 'Salva sondaggio',
  },
  saveSurveyTooltip: {
    de: 'Formularvorlage speichern. Falls inaktiv, wurde die aktuelle Version von der Formularvorlage bereits automatisch gespeichert.',
    en: 'Save form template. If inactive, the form template has already been saved automatically.',
    es: 'Guardar la encuesta',
    fr: 'Sauvegarder le sondage',
    it: 'Salva sondaggio',
  },
}
