<!-- <div class="logo">
  <picture>
    <source type="image/png" srcset="
          assets/whitelabeling/medikonsil-direkt/home/medikonsil-direkt--with-logo--colored@400w.png 400w,
          assets/whitelabeling/medikonsil-direkt/home/medikonsil-direkt--with-logo--colored@800w.png 800w,"
      sizes=" (max-width: 25rem) 100vw, 25rem">
    <img src="assets/whitelabeling/medikonsil-direkt/home/medikonsil-direkt--with-logo--colored@400w.png"
      alt="medikonsil-direkt.de">
  </picture>
</div> -->


<!-- <div class="text">
  <p>
    {{'WHITELABEL-SECTION-MEDIKONSIL-DIREKT.TEXT' | translate}}
  </p>
</div> -->


<div class="c-button-group" style="justify-content: center; margin-top: 2rem;">
  <a mat-flat-button color="primary" href="https://medikonsil-direkt.de" target="_blank" style="background: #2A4E7C;">
    <span style="font-weight: 600;">{{'WHITELABEL-SECTION-MEDIKONSIL-DIREKT.LEARN-MORE' | translate}} </span>
    <mat-icon iconPositionEnd>open_in_new</mat-icon>
  </a>
</div>