interface ImageSettings {
  scaleSize: [number, number]
  quality: number
  saveType: string
}

export interface FileUploadSettings {
  image: ImageSettings
  maxMegaByteSize: number
  mediaTypes: string[]
  limit: number
}

/**
 * These settings for file upload are currently based on the settings from VSS in arzt-direkt.
 */
export const settings: FileUploadSettings = {
  image: {
    scaleSize: [2000, 2000],
    quality: 0.7,
    saveType: 'image/jpeg',
  },
  maxMegaByteSize: 8,
  limit: 3,
  mediaTypes: [
    'application/pdf',
    'application/x-tar',
    'application/x-bzip2',
    'application/gzip',
    'application/x-7z-compressed',
    'application/x-rar-compressed',
    'application/zip',
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/bmp',
    'image/svg+xml',
    'image/webp',
    'audio/aac',
    'audio/mpeg',
    'audio/ogg',
    'audio/opus',
    'audio/wav',
    'audio/webm',
    'audio/3gpp',
    'audio/3gpp2',
    'video/x-msvideo',
    'video/mpeg',
    'video/ogg',
    'video/mp4',
    'video/mp2t',
    'video/webm',
    'video/3gpp',
    'video/3gpp2',
    'text/plain',
    'application/rtf',
    'application/vnd.oasis.opendocument.text',
    'text/comma-separated-values',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.presentation',
    'application/msword',
    'application/vnd.ms-word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.sheet',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/msexcel',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/mspowerpoint',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  ],
}
