<form class="c-form c-form--fullHeight" [formGroup]="formGroup">

  <app-selection-field-component [formGroup]="formGroup" [options]="settings.coronaSymptomOptions" [noOption]="false"
    [selectionRequired]="false" [descriptionRequiredIfSonstiges]="false" [descriptionHideUnlessSonstiges]="true"
    selectionName="coronaSymptomsSelection" descriptionName="coronaSymptomsDescription"
    [selectionPlaceholder]="'ANAMNESE-FORM.COVID-COMPLAIN-TITLE' | translate"
    [descriptionPlaceholder]="'ANAMNESE-FORM.COVID-COMPLAIN-TEXT' | translate">
  </app-selection-field-component>


  <mat-form-field attr.data-selenium-id="field-seit-wann" appearance="outline">
    <mat-label>
      <span class="hide--onVerySmall">{{'ANAMNESE-FORM.COVID-COMPLAIN-HISTORY-TITLE' | translate}}</span>
      <span class="show--onVerySmall">{{'ANAMNESE-FORM.COMPLAIN-HISTORY-QUESTION-TEXT' | translate}}</span>
    </mat-label>
    <textarea matInput cdkTextareaAutosize formControlName="coronaSymptomsHistory" mat-autosize=true
      cdkAutosizeMinRows=1 cdkAutosizeMaxRows=5></textarea>
  </mat-form-field>


  <div class="c-form__row">
    <h4 class="c-form__row__item--subheading-alt">
      {{'ANAMNESE-FORM.COVID-QUESTION-LUNG' | translate}}
    </h4>
  </div>

  <div class="c-form__row" #target>
    <mat-radio-group attr.data-selenium-id="radio-lungenerkrankung" class="c-radio-group"
      formControlName="coronaLungDisease" color="primary">
      <div>
        <mat-radio-button value="Ja">{{'GLOBAL.YES' | translate}}</mat-radio-button>
        <mat-radio-button value="Nein">{{'GLOBAL.NO' | translate}}</mat-radio-button>
      </div>
    </mat-radio-group>
  </div>


  <div class="c-form__row">
    <h4 class="c-form__row__item--subheading-alt">
      {{'ANAMNESE-FORM.COVID-QUESTION-CONTACT' | translate}}<br>
    </h4>
  </div>

  <div class="c-form__row" #target>
    <mat-radio-group attr.data-selenium-id="radio-kontakt" class="c-radio-group"
      formControlName="coronaInfectedPersonContact" color="primary">
      <div>
        <mat-radio-button value="Ja">{{'GLOBAL.YES' | translate}}</mat-radio-button>
        <mat-radio-button value="Nein">{{'GLOBAL.NO' | translate}}</mat-radio-button>
      </div>
    </mat-radio-group>
  </div>

  <div class="c-info-banner c-info-banner--hinweisListe normal">
    <details>
      <summary>
        <h4>{{'ANAMNESE-FORM.COVID-QUESTION-CONTACT-TEXT' | translate}}</h4>
      </summary>

      <small style="margin-bottom: .5rem;"
        [innerHTML]="'ANAMNESE-FORM.COVID-QUESTION-CONTACT-HINT' | translate | sanitizeHtml">
      </small>
    </details>
  </div>

</form>