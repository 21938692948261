import Joi from 'joi'

import { LoadingViewMode } from './../interfaces-autogen-from-joi/loading-view-mode'

export const loadingViewModeDict: Record<string, LoadingViewMode> = {
  loading: 'loading',
  loaded: 'loaded',
  error: 'error',
} as const

export const loadingViewModes = Object.values(loadingViewModeDict)
export const loadingViewModeDefault = loadingViewModeDict['loading']

export const LoadingViewModeJoi = Joi.string()
  .valid(...loadingViewModes)
  .default(loadingViewModeDefault)
  .meta({
    className: 'LoadingViewMode',
  })
  .example('loading')
