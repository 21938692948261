// Validators that deal with address fields: street, house number, ...

import { AbstractControl, ValidationErrors } from "@angular/forms";
import { notPatternValidator } from "./general.validators";

/**
 * Regex pattern that matches a valid german house number:
 * simple number: '14'
 * number and letter: '123a', '123A'
 * fraction: '3/8', '1/2', '56 1/2'
 * range: '11-13', '11 - 13', '11/13', '11 / 13'
 */
const validHouseNumberPattern: string = '\\d+(\\s?[a-zA-Z]|\\s\\d+)?' + // number and possible letter or '17 1' part of e.g. '17 1/2'
                                        '(\\s*[-/]\\s*\\d+(\\s?[a-zA-Z])?)?'; // range with second number

/**
 * Returns a ValidationError if the string does end with a house number.
 * 
 * @remark
 * Do NOT use this function directly for code outside of `validators/`,
 * use `ADValidators.streetNameDoesNotContainHouseNumber` instead.
 */                                        
export function streetNameDoesNotContainHouseNumberValidator(control: AbstractControl): ValidationErrors|null {
  const validator = notPatternValidator('\\b' + validHouseNumberPattern + '\\s*$');
  const result = validator(control);

  if (!result) {
    return null;
  }
  return {'houseNumber': result['pattern']};
}


