import { MatDialog } from '@angular/material/dialog'
import { ViewerEnvState } from '@arzt-direkt/wfa-definitions'
import { Model } from 'survey-core'

import { customizeFileUploadQuestion } from '../../shared-surveyjs/file-upload-question-customization/customize-file-upload-question'
import { setDisplayOption } from '../surveyjs-display-options/set-display-option'
import { applySurveyTheme } from '../surveyjs-styling/apply-survey-theme'
import { addCustomSurveyStyling } from '../surveyjs-styling/surveyjs-styling'

export function updateSurveyModelWithEnvState(
  surveyModel: Model,
  envState: ViewerEnvState,
  dialog: MatDialog,
): void {
  const { isEmbeddedIn, displayOption } = envState
  addCustomSurveyStyling(surveyModel, isEmbeddedIn)
  setDisplayOption(surveyModel, displayOption)
  applySurveyTheme(surveyModel, isEmbeddedIn)
  customizeFileUploadQuestion(surveyModel, dialog, envState)

  const showCompleteButton = [
    'arztDirekt',
    'adStepper',
    'adPermalinkViewer',
  ].includes(isEmbeddedIn)
  surveyModel.showCompleteButton = showCompleteButton
}
