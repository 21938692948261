import { Integer } from "asn1js"
import { Certificate } from "pkijs"
import { setTypesAndValues } from "./set-types-and-values"
import { Observable } from "rxjs"
import { defaults, CertificateIssuer, CertificateSubject } from "../defaults"

/**
 * set main certificate information fields
 */
export function initCertificate(
  _issuer: CertificateIssuer = defaults.certificate.issuer,
  _subject: CertificateSubject = defaults.certificate.subject,
): Observable<Certificate> {
  return new Observable((observer) => {
    const certificate = new Certificate()
    // TODO: add user info to cert
    certificate.version = 2
    certificate.serialNumber = new Integer({ value: 1 })
    certificate.issuer.typesAndValues = setTypesAndValues(
      certificate.issuer.typesAndValues,
      _issuer,
    )
    certificate.subject.typesAndValues = setTypesAndValues(
      certificate.subject.typesAndValues,
      _subject,
    )

    let notBefore = new Date()
    notBefore.setHours(0, 0, 0, 0)
    certificate.notBefore.value = notBefore

    let notAfter = new Date()
    notAfter.setFullYear(new Date().getFullYear() + 20)
    notAfter.setHours(0, 0, 0, 0)
    certificate.notAfter.value = notAfter

    observer.next(certificate)
    observer.complete()
  })
}
