
// construct a type that contains all methods of a class as optional properties
// with a boolean value
type Methods<Class> = {
  [Property in keyof Class as Class[Property] extends Function ? Property : never]?: boolean
}


/**
 * Methods available for {@link Console}
 */
export type ConsoleMethods = Methods<Console>;


/**
 * Class that contains helper functions for `main.ts`.
 */
export class MainFunctions {

  /**
   * Disable certain console methods for the production build.
   * 
   * @usageNotes
   * e.g. to disable `console.log`, `console.info` and `console.debug`:
   * 
   * ```typescript
   * const disabledMethods: ConsoleMethods = {
   *    debug: true,
   *    info: true,
   *    log: true
   * }
   * disableConsoleMethodsInProduction(disabledMethods)
   * ```
   * 
   */
  public static disableConsoleMethodsInProduction(methods: ConsoleMethods) {
    const adjustedConsole = this.disableConsoleMethods(globalThis.console, methods);
    globalThis.console = adjustedConsole;
  }


  /**
   * Return a console with the specified methods set to void functions.
   * @param methods that will be disabled if the corresponding value is `true`
   */
  private static disableConsoleMethods(console: Console, methods: ConsoleMethods): Console {
    let newConsole = Object.assign({}, console);
    let adjustedMethods = {};

    for (const method of Object.entries(methods)) {
      // if value for method name is 'true' disable it
      if (method[1]) {
        adjustedMethods[method[0]] = function (): void { };
      }
    }

    Object.assign(newConsole, adjustedMethods)
    return newConsole;
  }
}
