<div class="booking-summary" #bookingSummary id="otk-scrollable-content--booking-summary">
  <!-- booking invalid -->
  <div *ngIf="!isBookingValid" class="title">
    {{'OTK.STEPPER.BOOKING-INVALID-MESSAGE' | translate}}
  </div>

  <!-- contact content -->
  <div *ngIf="isContactValid" class="summary-and-actions">
    <div class="summary">
      <div class="opening-and-type">
        <div class="opening">
        </div>
        <div>
          {{ contactFormName | i18nString: contactNameFallback | translate}}
        </div>
      </div>

      <hr>

      <!-- localities bs - just show selected bs data? -->
      <ng-container *ngIf="betriebsstaettenFilter && bsDisplayed && !isZollsoftMode">
        <div class=" summary-block">
          <div class="category-title">
            {{'OTK.STEPPER.LOCATION' | translate}}
          </div>
          <div class="locality" [class.light-apearence]="colorService.lightHeader">
            <div class="instance-logo">
              <zs-logo></zs-logo>
            </div>
            <div class="instance-details text-field-value">
              <div class="instance-name">{{ bsDisplayed.name }}</div>
              <div class="instance-address">{{ bsDisplayed.contact.address_1 }} <br /> {{ bsDisplayed.contact.zip }} {{
                bsDisplayed.contact.city }}</div>
              <a [href]="bsEmailHref">{{ bsEmail }}</a>
              <div>
                {{ bsDisplayed.contact?.phone ? bsDisplayed.contact.phone : instancePhone }}
              </div>
            </div>
          </div>
          <!-- map -->
          <div class="streetmap-container">
            <app-streetmap #map [geodata]="bsDisplayed.contact.geodata" [pxwidth]="300" [pxheight]="100">
            </app-streetmap>
          </div>
        </div>

        <div class="summary-block" *ngIf="localities && !isLocalitiesEmpty">
          <div class="category-title">
            {{ 'OTK.STEPPER.SUBLOCATION' | translate}}
          </div>
          <div *ngFor="let locality of localities" class="locality" [class.light-apearence]="colorService.lightHeader">
            <div class="instance-logo">
              <zs-logo></zs-logo>
            </div>
            <div class="instance-details text-field-value">
              <div class="instance-name">{{ locality.bezeichnung }}</div>
              <a [href]="emailString">{{ otkEmail }}</a>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- localities instance -->
      <ng-container *ngIf="(!betriebsstaettenFilter && !isZollsoftMode) || (!bsDisplayed && !isZollsoftMode)">
        <div class="summary-block" *ngIf="localities && !isLocalitiesEmpty">
          <div class="category-title">
            {{ 'OTK.STEPPER.LOCATION' | translate }}
          </div>
          <div *ngFor="let locality of localities" class="locality" [class.light-apearence]="colorService.lightHeader">
            <div class="instance-logo">
              <zs-logo></zs-logo>
            </div>
            <div class="instance-details text-field-value">
              <div class="instance-name">{{ locality.bezeichnung }}</div>
              <a [href]="emailString">{{ otkEmail }}</a>
              <div>
                {{ instancePhone }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- personal details -->
      <div class="summary-block">
        <div class="category-title">
          {{'OTK.BOOKING-SUMMARY.PERSONAL-FORM' | translate}}
        </div>
        <div class="personal-grid">
          <ng-container *ngFor="let formItem of personal">
            <div class="text-field-name">
              {{ formItem.name }}:
            </div>
            <div class="text-field-value" *ngFor="let value of formItem.values">
              {{ value }}
            </div>
          </ng-container>
          <!-- insurance -->
          <!-- (visually looks like a continuation of 'personal details') -->
          <ng-container *ngIf="insuranceType">
            <span class="text-field-name">
              {{ 'OTK.BOOKING-SUMMARY.INSURANCE' | translate }}:
            </span>
            <span class="text-field-value desktop-only">
              {{
              ('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.LONG-NAME-WITH-ABBREVIATION-FORMATTED.'+ (insuranceType |
              uppercase)) |
              translate
              }}
            </span>
            <span class="text-field-value mobile-only">
              {{
              ('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.SHORT-NAME-FORMATTED-WITH-ABBREVATION.'+ (insuranceType |
              uppercase)) |
              translate
              }}
            </span>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- book button -->
    <div class="button-book-container">
      <button attr.data-selenium-id="button-send-contact-data" mat-flat-button color="primary"
        (click)="sendContactData()" [disabled]="bookingService.currentlyLoading">
        {{'OTK.STEPPER.BOOK-CONTACT-BUTTON' | translate}}
      </button>
    </div>
  </div>










  <!-- content -->
  <div *ngIf="isBookingValid" class="summary-and-actions">
    <div class="summary">

      <!-- booking-summary content -->
      <div class="opening-and-type" *ngIf="(bookingService.seriesMode$ | async) === false">
        <div class="category-title">
          {{'OTK.BOOKING-SUMMARY.SELECTED-APPOINTMENT' | translate }}
        </div>
        <div class="opening">
          <span *ngIf="bookingService.openingDateString">
            {{ bookingService.openingDateString }}&nbsp;
          </span>
          <span *ngIf="bookingService.openingTimeString">
            {{ bookingService.openingTimeString }}
            {{'OTK.BOOKING-DATE.UHR' | translate}}
          </span>
        </div>
        <div>
          {{ appointmentTypeName }}
        </div>
        <div *ngIf="isPaid && paymentAmount" class="opening">
          <span>
            {{ 'OTK.BOOKING-TYPE.PAY' | translate }}:
            {{ paymentAmount?.value / 100 | currency: paymentAmount?.currency :'symbol' : '1.2-2'}}
          </span>
        </div>
      </div>

      <!-- booking-summary content For Appointment Series  -->
      <div class="opening-and-type" *ngIf="(bookingService.seriesMode$ | async)">

        <!-- normal selection -->
        <ng-container *ngIf="(bookingService.automaticSeriesSelection$ | async) === false">

          <div class="app-multi-container">
            <div class="app-multi-item-container"
              *ngFor="let app of bookingService.appointmentSeriesChain; let idx=index">
              <!-- <span class="nr-badge">{{idx + 1}}</span> -->

              <div class="app-multi-item">
                <div class="heading-line">
                  <!-- <span class="nr-badge">{{idx + 1}}</span>  -->
                  {{'OTK.BOOKING-SUMMARY.SELECTED-APPOINTMENT-SERIES' | translate }} {{idx + 1}}: {{app.name |
                  i18nString}}
                </div>
                <div class="opening-multi">
                  <mat-icon>today</mat-icon><span class="opening-multi-date">{{ openingsFormArray.value?.[idx]?.start |
                    weekday:timezone:baseLanguage | translate}}. {{
                    openingsFormArray.value?.[idx]?.start | date:'dd.MM.y, HH:mm':timezone}} {{ 'OTK.BOOKING-DATE.UHR' |
                    translate }}</span>
                  <mat-icon>person</mat-icon><span>{{ (doctorArray | async)[openingsFormArray.value?.[idx]?.kdSetKey]
                    }}</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isPaid && paymentAmount" class="opening">
            <span>
              {{ 'OTK.BOOKING-TYPE.PAY' | translate }}:
              {{ paymentAmount?.value / 100 | currency: paymentAmount?.currency :'symbol' : '1.2-2'}}
            </span>
          </div>
        </ng-container>

        <!-- automatic selection -->
        <ng-container *ngIf="(bookingService.automaticSeriesSelection$ | async)">
          <div class="category-title">
            {{'OTK.BOOKING-SUMMARY.SELECTED-APPOINTMENT' | translate }}
          </div>
          <div *ngIf="(bookingService.seriesMode$ | async)" class="app-multi-container">
            <div class="app-multi-item-container">
              <div class="app-multi-item">
                <div class="heading-line">
                  {{bookingService.appointmentType?.value?.name | i18nString}}
                </div>
                <div class="opening-multi">
                  <mat-icon>today</mat-icon><span class="opening-multi-date">{{ openingsFormArray.value?.[0]?.start |
                    weekday:timezone:baseLanguage | translate}}. {{
                    openingsFormArray.value?.[0]?.start | date:'dd.MM.y, HH:mm':timezone}} {{ 'OTK.BOOKING-DATE.UHR' |
                    translate }}</span>
                  <mat-icon>person</mat-icon><span>{{ (doctorArray | async)[openingsFormArray.value?.[0]?.kdSetKey]
                    }}</span>
                </div>
              </div>
            </div>

          </div>
          <div *ngIf="isPaid && paymentAmount" class="opening">
            <span>
              {{ 'OTK.BOOKING-TYPE.PAY' | translate }}:
              {{ paymentAmount?.value / 100 | currency: paymentAmount?.currency :'symbol' : '1.2-2'}}
            </span>
          </div>
        </ng-container>
      </div>



      <hr>

      <!-- appointment type info -->
      <div *ngIf="showInfo && info | i18nString:''" class="info" style="margin-bottom:1rem;">
        <mat-icon>info</mat-icon>
        <span class="infoHtml" [innerHtml]="info | i18nString:''"></span>
      </div>

      <!-- Error Banner Betriebstaetten -->
      <div *ngIf="isOpeningConnectedToMultipleBs && !isOnlineConsultation" class="error" style="margin-bottom:1rem;">
        <mat-icon>info</mat-icon>
        <span>{{'OTK.BOOKING-SUMMARY.ERROR-BS-MULTIPLE' | translate}}</span>
      </div>

      <div *ngIf="isOpeningNotConnectedToAnyBs && !isOnlineConsultation" class="error" style="margin-bottom:1rem;">
        <mat-icon>info</mat-icon>
        <span>{{'OTK.BOOKING-SUMMARY.ERROR-BS-NOT-FOUND' | translate}}</span>
      </div>

      <!-- doctors -->
      <div *ngIf="showDoctors && !isDoctorsBookedEmpty && ((bookingService.seriesMode$ | async) === false) "
        class="summary-block">
        <div class="category-title">
          <span *ngIf="onlyOneDoctor">{{'OTK.HANDLER-SINGULAR' | translate}}</span>
          <span *ngIf="!onlyOneDoctor">{{'OTK.HANDLER-PLURAL' | translate}}</span>
        </div>
        <ng-container *ngFor="let doctor of doctorsBooked">
          <div class="doctor">
            <div class="doctor-photo">
              <img *ngIf="doctor.photo" src="{{ doctor.photo }}" alt="photo of doctor" class="doctor-photo--custom">
              <div class="doctor-photo--default" *ngIf="!doctor.photo">
                {{ doctor.representingLetter }}
              </div>
            </div>
            <div class="doctor-text">
              <div class="doctor-text__fullname" *ngIf="doctor.fullName">
                {{doctor.fullName}}
              </div>
              <div class="doctor-text__fullname" *ngIf="!doctor.fullName">
                ?
              </div>
              <div class="doctor-text__description" *ngIf="doctor.description">
                {{doctor.description}}
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- anamnese -->
      <div class="summary-block" *ngIf="!!this.bookingService.appointmentType.value?.formIdentifier">
        <div class="category-title">
          {{'OTK.BOOKING-SUMMARY.QUESTIONNAIRE' | translate}}
        </div>
        <!-- "Fragebogen ausgefüllt: Allgemeine Beschwerden" -->
        <ng-container>
          <div class="title--section">
            <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
            {{'OTK.STEPPER.ANAMNESE-LABEL' | translate}} {{ anamneseFormTitle | translate}}
          </div>
        </ng-container>
      </div>
      <!-- personal details -->
      <div class="summary-block">
        <div class="category-title">
          {{'OTK.BOOKING-SUMMARY.PERSONAL-FORM' | translate}}
        </div>
        <div class="personal-grid">
          <ng-container *ngFor="let formItem of personal">
            <div class="text-field-name">
              {{ formItem.name }}:
            </div>
            <div class="text-field-value" *ngFor="let value of formItem.values">
              {{ value }}
            </div>
          </ng-container>
          <!-- insurance -->
          <!-- (visually looks like a continuation of 'personal details') -->
          <ng-container *ngIf="insuranceType">

            <span class="text-field-name">
              {{ 'OTK.BOOKING-SUMMARY.INSURANCE' | translate }}:
            </span>
            <span class="text-field-value desktop-only">
              {{
              ('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.LONG-NAME-WITH-ABBREVIATION-FORMATTED.'+ (insuranceType |
              uppercase)) |
              translate
              }}
            </span>
            <span class="text-field-value mobile-only">
              {{
              ('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.SHORT-NAME-FORMATTED-WITH-ABBREVATION.'+ (insuranceType |
              uppercase)) |
              translate
              }}
            </span>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- book button -->
    <div class="button-book-container">
      <!--Asset Upload -->
      <div *ngIf="showFileUpload" class="summary-block" style="width:95%">
        <div class="category-title">
          {{'OTK.BOOKING-SUMMARY.FILE-ATTACHMENT' | translate}}
        </div>

        <div class="file-list" [class.o-vh]="!assets.value?.length">
          <div class="file-item" *ngFor="let asset of assets.value; let idx = index">
            <mat-icon class="file-icon">{{ fileIconDict[asset?.meta?.type || 'unknown'] || 'insert_drive_file'
              }}</mat-icon>
            <div class="file-description">
              <span class="description-text">{{ asset.meta.description.length > 28 ? (asset.meta.description |
                slice:0:28)+'...' :
                ( asset.meta.description || ('OTK.BOOKING-SUMMARY.NO-DESCRIPTION' | translate) ) }}</span>
              <span class="description-subtext">{{ asset.meta.name }}</span>
            </div>

            <mat-icon *ngIf="(bookingService.fileEncrypting[idx] | async) === false"
              class="encryption-prepare right-align"
              matTooltip="Ihre Datei wird bei Buchung verschlüsselt bevor sie an den Server gesendet wird">lock_open</mat-icon>
            <mat-spinner
              *ngIf="(bookingService.fileEncrypting[idx] | async) && ((bookingService.fileEncryptionDone[idx] | async) === false)"
              color="primary" class="right-align" [diameter]="20"></mat-spinner>
            <mat-icon *ngIf="(bookingService.fileEncryptionDone[idx] | async)"
              class="success-encryption right-align">lock</mat-icon>
          </div>
        </div>
        <p *ngIf="!assets.value?.length" class="summary-item-description">Keine Dateien angehängt</p>
      </div>

      <div class="summary-block" *ngIf="waitinglistActivated" style="width:95%">
        <div class="category-title">
          {{'OTK.BOOKING-SUMMARY.WAITINGLIST' | translate}}
        </div>
        <mat-checkbox attr.data-selenium-id="mat-checkbox-subscribe-waiting-list-control"
          [formControl]="subscribeWaitinglistControl" style="margin-right: 10px">
          <span [innerHTML]="'OTK.BOOKING-SUMMARY.NOTICE-CHECKBOX' | translate"></span>
          <mat-icon class="notification_icon">notifications_active
          </mat-icon>
        </mat-checkbox>
      </div>

      <!-- localities bs filter -->
      <ng-container *ngIf="betriebsstaettenFilter && bsDisplayed && !isOnlineConsultation && !isZollsoftMode">
        <div class="summary-block" style="width:95%">
          <div class="category-title">
            {{'OTK.STEPPER.LOCATION' | translate}}
          </div>
          <div class="locality" [class.light-apearence]="colorService.lightHeader">
            <div class="instance-details text-field-value">
              <div class="instance-name">{{ bsDisplayed.name }}</div>
              <div class="instance-address">{{ bsDisplayed.contact.address_1 }} <br /> {{ bsDisplayed.contact.zip }} {{
                bsDisplayed.contact.city }}</div>
              <a [href]="bsEmailHref">{{ bsEmail }}</a>
              <div>
                {{ bsDisplayed.contact?.phone ? bsDisplayed.contact.phone : instancePhone }}
              </div>
            </div>
            <div class="instance-logo">
              <zs-logo></zs-logo>
            </div>
          </div>
          <!-- map -->
          <div class="streetmap-container">
            <app-streetmap #map [geodata]="bsDisplayed.contact.geodata" [pxwidth]="300" [pxheight]="100">
            </app-streetmap>
          </div>
        </div>

        <div class="summary-block" *ngIf="localities && !isLocalitiesEmpty && !isOnlineConsultation">
          <div class="category-title">
            {{ 'OTK.STEPPER.SUBLOCATION' | translate}}
          </div>
          <div *ngFor="let locality of localities" class="locality" [class.light-apearence]="colorService.lightHeader">
            <div class="instance-logo">
              <zs-logo></zs-logo>
            </div>
            <div class="instance-details text-field-value">
              <div class="instance-name">{{ locality.bezeichnung }}</div>
              <a [href]="emailString">{{ otkEmail }}</a>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- localities no bs filter only instance -->
      <ng-container *ngIf="!betriebsstaettenFilter && !isOnlineConsultation && !isZollsoftMode">
        <div class=" summary-block" style="width:95%">
          <div class="category-title">
            {{'OTK.STEPPER.LOCATION' | translate}}
          </div>
          <div class="locality" [class.light-apearence]="colorService.lightHeader">
            <div class="instance-logo">
              <zs-logo></zs-logo>
            </div>
            <div class="instance-details text-field-value">
              <div class="instance-name">{{ instanceName }}</div>
              <div class="instance-address">{{ instanceStreetNumber }} <br /> {{ instanceZip }} {{
                instanceCity }}</div>
              <a attr.data-selenium-id="a-email-string" [href]="emailString">{{ otkEmail }}</a>
              <div *ngIf="instancePhone">
                {{ instancePhone }}
              </div>
            </div>
          </div>
          <!-- map -->
          <div class="streetmap-container" *ngIf="!noInstanceMap">
            <app-streetmap #instanceMap [geodata]="instanceGeodata" [pxwidth]="300" [pxheight]="100">
            </app-streetmap>
          </div>
        </div>

        <div class="summary-block" style="width:95%" *ngIf="localities && !isLocalitiesEmpty">
          <div class="category-title">
            {{ 'OTK.STEPPER.SUBLOCATION' | translate }}
          </div>
          <div *ngFor="let locality of localities" class="locality" [class.light-apearence]="colorService.lightHeader">
            <div class="instance-logo">
              <zs-logo></zs-logo>
            </div>
            <div class="instance-details text-field-value">
              <div class="instance-name">{{ locality.bezeichnung }}</div>
              <a attr.data-selenium-id="a-email-string" [href]="emailString">{{ otkEmail }}</a>
              <div>
                {{ instancePhone }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>