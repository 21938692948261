import { SurveyjsPropertyInfo } from '@arzt-direkt/wfa-definitions'

export const inputType: SurveyjsPropertyInfo = {
  name: 'inputType',
  surveyElements: ['text'],
  customPropertySettings: [
    {
      settingSetterFunc: 'setChoices',
      settingValue: [
        'text',
        'date',
        'custom-number',
        'custom-phone-number',
        'custom-email',
        'custom-insurant-number',
        'custom-insurance-number',
      ],
      /* // for ADI-2775
        settingValue: [
          'text', 
          'date', 
          'custom-number', 
          'custom-phone-number', 
          'custom-email',
          'custom-insurant-number',
          'custom-insurance-number',
          'password'
        ], 
      */
    },
  ],
}
