import { Injectable } from '@angular/core';
import { AppointmentType } from '../entities/Booking.entity';
import { InstanceForm } from '../entities/InstanceForm.entity';

@Injectable({
  providedIn: 'root'
})
export class AnamneseFormCheckerService {
  static getAnamneseId(currentType: AppointmentType): string | null {
    const anamneseId = currentType?.formIdentifier
    return ([null, undefined].includes(anamneseId)) ? null : anamneseId
  }

  static hasAnamneseId(currentType: AppointmentType): boolean {
    const anamneseId = AnamneseFormCheckerService.getAnamneseId(currentType)
    return (anamneseId === null) ? false : true
  }

  /**
   * check if requested anamnese form matches one in preset list
   *
   * sic: returns true if there no anamnese form is requested
   *
   * This is a rudimentary check and may need to be extended if other
   * uncaught bugs pop up. The bugs manifest themselves as an empty
   * anamnese step in booking-service.
   */
  static isAnamneseConfigNonexistentOrValid(currentType: AppointmentType, validForms: InstanceForm[]): boolean {
    const hasAnamneseId = AnamneseFormCheckerService.hasAnamneseId(currentType)
    if (!hasAnamneseId) return true

    // check whether anamnese form is in preset list
    // and that the form in the preset list is valid
    const anamneseId = AnamneseFormCheckerService.getAnamneseId(currentType)
    const instanceForm = validForms.find((form) => form.identifier === anamneseId)
    if ([null, undefined].includes(instanceForm)) return false
    if ([null, undefined].includes(instanceForm?.anamneseFormIdentifier)) return false

    return true
  }

  /**
   * checks that an anamnese form preset matching currentType exists
   *
   * Does not check whether the preset is valid.
   * This function is useful in case preset validation is
   * not yet possible, for example, if currentType is not yet
   * defined.
   */
  static getInstanceForm(
    currentType: AppointmentType, validForms: InstanceForm[]
  ): InstanceForm | null {
    const hasAnamneseId = AnamneseFormCheckerService.hasAnamneseId(currentType)
    if (!hasAnamneseId) return null

    const anamneseId = AnamneseFormCheckerService.getAnamneseId(currentType)
    const instanceForm = validForms.find((form) => form.identifier === anamneseId)
    if ([null, undefined].includes(instanceForm)) return null

    return instanceForm
  }

  constructor() { }

}
