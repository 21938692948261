import { CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatOptionModule } from '@angular/material/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { PraxisId, PraxisKennung } from '@arzt-direkt/wfa-definitions'
import { PraxisDetails } from '@arzt-direkt/wfa-definitions'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import { notNullish, nullish } from '@arzt-direkt/wfa-generic-utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { map, startWith, tap } from 'rxjs/operators'

import { clearInputValue } from './utils'
import { filterByKennungSubstring, sortAlphabetically } from './utils'
import { updateInfos, ZsSupportService } from './zs-support.service'
import { ZsSupportCommsService } from './zs-support-comms.service'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-praxis-select',
  templateUrl: './praxis-select.component.html',
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatAutocompleteModule,
    MatOptionModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  styleUrls: ['./mat-card.scss'],
})
export class PraxisSelectComponent implements OnInit {
  @Input() praxisId: PraxisId | undefined

  readonly clearInputValue = clearInputValue

  praxisControl = new FormControl()
  selectedKennung: PraxisKennung | undefined
  filteredPraxisDetails$ = new BehaviorSubject<PraxisDetails[]>([])

  constructor(
    private translate: TranslateService,
    private comms: ZsSupportCommsService,
    private zsSupportService: ZsSupportService,
  ) {}

  ngOnInit() {
    const praxisDetails$ = this.comms.loadAllPraxisDetails()
    const filteredDetails = combineLatest([
      praxisDetails$,
      this.praxisControl.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(filterByKennungSubstring),
      map(sortAlphabetically),
      untilDestroyed(this),
    )

    filteredDetails.subscribe(this.filteredPraxisDetails$)
    praxisDetails$.subscribe((pd) => {
      this.selectIfNotNullish(this.praxisControl, this.praxisId, pd)
    })
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.selectedKennung = event.option.value
    const selectedDetails = this.filteredPraxisDetails$.value.find(
      (pd) => pd.identifier === this.selectedKennung,
    )

    if (selectedDetails) {
      this.zsSupportService.praxisDetails$.next(selectedDetails)
      this.zsSupportService.showSpinner$.next(true)
      this.comms
        .getFormAndDraftInfos(selectedDetails._id)
        .pipe(
          tap(() => {
            this.zsSupportService.showSpinner$.next(false)
          }),
          untilDestroyed(this),
        )
        .subscribe(updateInfos(this.zsSupportService))
    }
  }

  selectIfNotNullish(
    praxisControl: FormControl,
    praxisId: Maybe<PraxisId>,
    praxisDetails: PraxisDetails[],
  ) {
    const currentKennung = praxisControl.value
    if (notNullish(currentKennung) && currentKennung !== '') return
    if (nullish(praxisId)) return

    const candidate = praxisDetails.find((pd) => pd._id === praxisId)
    if (nullish(candidate)) return

    praxisControl.setValue(candidate.identifier)
    this.zsSupportService.praxisDetails$.next(candidate)
    this.zsSupportService.showSpinner$.next(true)
    this.comms
      .getFormAndDraftInfos(candidate._id)
      .pipe(
        tap(() => {
          this.zsSupportService.showSpinner$.next(false)
        }),
        untilDestroyed(this),
      )
      .subscribe(updateInfos(this.zsSupportService))
  }
}
