import { validateBasicConfig } from "@a-d/dashboard/instance-settings/appointment-calendar/appointment-field-settings/validate-basic-config";
import { OTKFieldConfig } from "@a-d/entities/Calendar.entity";
import { defaultBasicConfig } from "@a-d/dashboard/instance-settings/appointment-calendar/appointment-field-settings/default-settings"
import { overwriteConfigKeyWithDefault } from "@a-d/dashboard/instance-settings/appointment-calendar/appointment-field-settings/apply-user-settings-to-field-config"

export function overrideEntries<T extends { identifier: string }>(
  originalArr: T[] | null,
  overrideArr?: T[] | null
): T[] {
  if (originalArr == null && overrideArr == null) return null
  if (originalArr == null) return overrideArr;
  if (overrideArr == null) return originalArr;

  const overridenArr = originalArr.map((originalConfig) => {
    const overridenConfig = overrideArr.find(
      (c) => c.identifier === originalConfig.identifier
    );
    return overridenConfig ?? originalConfig;
  });

  const originalArrIds = originalArr.map( config => config.identifier)
  const missingConfigs = overrideArr.filter( config => !originalArrIds.includes(config.identifier))
  return [...overridenArr, ...missingConfigs]
}

export function constructBasicConfig(
  userConfig: OTKFieldConfig[] | null,
  overrideConfig?: OTKFieldConfig[]
): Record<string, OTKFieldConfig> {
  const forSpecificAppointmentType = overrideEntries(userConfig, overrideConfig);
  const validated = validateBasicConfig(forSpecificAppointmentType)
  const overrideInfoLimit =
    overwriteConfigKeyWithDefault("info", "limit")({userBS: validated, defaultBS: defaultBasicConfig})
  const basicFields = keyBy(overrideInfoLimit, "identifier");
  return basicFields
}

// bootleg analog of _.keyBy
export function keyBy<T>(array: T[], key: string): Record<string, T> {
  return (array || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});
}
