import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable, of } from 'rxjs'

import { getDisplayableErrorAsSurveyjs } from '../../app/user-message/displayable-errors'

export function FallbackRouteResolver(
  activatedRouteSnapshot: ActivatedRouteSnapshot,
  _routerStateSnapshot: RouterStateSnapshot,
): Observable<object> {
  const displayableError = getDisplayableErrorAsSurveyjs(
    'q5708',
    activatedRouteSnapshot.url,
  )

  return of({ errorCode: 'q5708', displayableError })
}
