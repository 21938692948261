import Joi from 'joi'

import { LocStringJoi } from '../loc-string.joi'
import { PrimitiveJoi } from '../primitive.joi'
import { BaseQuestionJoi } from './base-question.joi'
import { wfaMatrixQuestionExample } from './examples/wfa-matrix-question.joi.example'

export const WfaMatrixColumnJoi = Joi.alternatives()
  .try(
    PrimitiveJoi,
    Joi.object().keys({
      value: Joi.string().required(),
      text: LocStringJoi.required(),
    }),
  )
  .meta({
    className: 'WfaMatrixColumn',
  })

export const WfaMatrixRowJoi = Joi.alternatives()
  .try(
    PrimitiveJoi,
    Joi.object().keys({
      text: LocStringJoi,
      value: PrimitiveJoi,
    }),
  )
  .meta({
    className: 'WfaMatrixRow',
  })

// https://surveyjs.io/form-library/documentation/api-reference/matrix-table-question-model
export const WfaMatrixQuestionJoi = BaseQuestionJoi.keys({
  alternateRows: Joi.boolean().optional(),
  cellComponent: Joi.string().optional(),
  columnMinWidth: Joi.string().optional(),
  columnsVisibleIf: Joi.string().optional(),
  columns: Joi.array().items(WfaMatrixColumnJoi).optional(),
  eachRowUnique: Joi.boolean().optional(),
  hideIfRowsEmpty: Joi.boolean().optional(),
  isAllRowRequired: Joi.boolean().optional(),
  rows: Joi.array().items(WfaMatrixRowJoi).optional(),
  rowsOrder: Joi.string().valid('initial', 'random').optional(),
  rowsVisibleIf: Joi.string().optional(),
  showHeader: Joi.boolean().optional(),
  type: Joi.string().valid('matrix').required(),
  verticalAlign: Joi.string().valid('top', 'middle').optional(),
})
  .meta({
    className: 'WfaMatrixQuestion',
  })
  .example(JSON.stringify(wfaMatrixQuestionExample, null, 2))
