import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, Input, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { MatStep, MatStepper, MatStepperModule } from '@angular/material/stepper';
import { environment } from '@env/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { Subscription, merge } from 'rxjs';
import { delay } from 'rxjs/operators';
import { BookingStep, TabTitles } from '../../entities/Booking.entity';
import { LanguageService } from '../../i18n/language.service';
import { InstanceService } from '../../instance/instance.service';
import { BookingAnamneseComponent } from '../booking-anamnese/booking-anamnese.component';
import { BookingBsComponent } from '../booking-bs/booking-bs.component';
import { BookingDateMultiComponent } from '../booking-date-multi/booking-date-multi.component';
import { BookingDateComponent } from '../booking-date/booking-date.component';
import { BookingInfoComponent } from '../booking-info/booking-info.component';
import { BookingPaymentComponent } from '../booking-payment/booking-payment.component';
import { BookingPersonalComponent } from '../booking-personal/booking-personal.component';
import { BookingPreliminaryComponent } from '../booking-preliminary/booking-preliminary.component';
import { BookingSeriesService } from '../booking-series.service';
import { BookingSummaryComponent } from '../booking-summary/booking-summary.component';
import { BookingTypeComponent } from '../booking-type/booking-type.component';
import { BookingService } from '../booking.service';


@UntilDestroy()
@Component({
  selector: 'app-booking-stepper',
  templateUrl: './booking-stepper.component.html',
  styleUrls: ['./booking-stepper.component.scss'],
  standalone: true,
  imports: [
    MatStepperModule,
    NgClass,
    FontAwesomeModule,
    NgIf,
    BookingInfoComponent,
    BookingPreliminaryComponent,
    BookingBsComponent,
    BookingTypeComponent,
    BookingDateMultiComponent,
    BookingDateComponent,
    BookingAnamneseComponent,
    BookingPersonalComponent,
    BookingPaymentComponent,
    BookingSummaryComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class BookingStepperComponent implements OnInit, AfterViewInit, OnDestroy {
  public tabNames: []
  public BookingStep = BookingStep
  private onLangChangeSubscription: Subscription
  // booking-anamnese only initializes the form if !isHeaderOnly.
  // otherwise the header part of the stepper initializes a form
  // which is hidden to the user and is thus not interactive,
  // preventing the "next" button from becoming active.
  @Input() isHeaderOnly: boolean
  @ViewChild('stepper') stepper: MatStepper
  @ViewChild('bookingDateMultiStep') bookingDateMultiStep: MatStep
  @ViewChild('personalFormComponent') personalFormComponent: BookingPersonalComponent
  selectedIndex: number
  public activeInstance = this.instanceService.activeInstance
  showBookingAnamnese = false
  showType = true // hide booking-type if received appointment type as a get parameter
  showBs = true
  showPayment = false
  isBsGivenAsParameterSubscription: Subscription
  showTypeSub: Subscription
  // initialize tabTitles with empty string to prevent throwing an undefined error
  // as these are first defined after the booking service finishes initialization
  tabTitles: TabTitles
  isContactFormSelected = false
  arePrestepsCompleted: boolean
  seriesMode = this.bookingService.seriesMode$
  automaticSeriesSelection = this.bookingService.automaticSeriesSelection$
  chainIndex = this.bookingSeriesService.chainIndex$

  constructor(
    private adLoggerService: AdLoggerService,
    private cd: ChangeDetectorRef,
    private instanceService: InstanceService,
    public bookingService: BookingService,
    public bookingSeriesService: BookingSeriesService,
    private translateService: TranslateService,
    public languageService: LanguageService
  ) { }

  setTabTitles() {
    this.tabTitles = {
      [BookingStep.bookingInfo]: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-INFO'),
      [BookingStep.bookingPreliminary]: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-PRELIMINARY'),
      [BookingStep.bookingBs]: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-BS'),
      [BookingStep.bookingType]: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-TYPE'),
      [BookingStep.bookingDateMulti]: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-DATE-MULTI'),
      [BookingStep.bookingDate]: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-DATE'),
      [BookingStep.bookingAnamnese]: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-ANAMNESE'),
      [BookingStep.bookingPersonal]: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-PERSONAL'),
      [BookingStep.bookingSummary]: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-SUMMARY'),
      [BookingStep.bookingPayment]: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-PAYMENT'),
    }
  }

  onLangChangeSubscribe() {
    this.onLangChangeSubscription = this.translateService.onLangChange
      .subscribe(() => this.setTabTitles())
  }

  isPayedAppointmentSubscribe() {
    this.bookingService.paymentMode$.pipe(
      untilDestroyed(this)
    ).subscribe((isPaid: boolean) => {
      this.showPayment = isPaid;
    })
  }


  ngOnInit(): void {
    this.setTabTitles()
    this.onLangChangeSubscribe()
    this.isContactFormSubscribe()
    this.showTypeSubscribe()
    this.isBsGivenAsParameterSubscribe()
    this.subscribeArePrestepsCompleted()
    this.isPayedAppointmentSubscribe()
  }

  isContactFormSubscribe() {
    this.bookingService.isContactFormSelected$
      .pipe(
        untilDestroyed(this)
      ).subscribe({
        next: () => this.isContactFormSelected = this.bookingService.isContactFormSelected$.value,
        error: (error) => { this.adLoggerService.error(error) }
      })
  }

  showTypeSubscribe() {
    this.bookingService.showType$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.showType = this.bookingService.showType$.value)
  }

  isBsGivenAsParameterSubscribe() {
    if (this.isBsGivenAsParameterSubscription)
      this.isBsGivenAsParameterSubscription.unsubscribe()
    this.isBsGivenAsParameterSubscription = this.bookingService.showBs$
      .pipe(untilDestroyed(this))
      .subscribe(() =>
        this.showBs = this.bookingService.showBs$.value
      )
  }

  subscribeArePrestepsCompleted() {
    this.bookingService.arePrestepsCompleted$.subscribe(() => {
      this.arePrestepsCompleted = this.bookingService.arePrestepsCompleted$.value
    })
  }

  ngOnDestroy(): void {
    this.onLangChangeSubscription.unsubscribe()
  }

  ngAfterViewInit() {
    this.syncStepperWithService()
  }

  /**
   * there are two copies of app-booking-stepper in booking.component.html:
   * one for the content (with the header hidden) and one for the header (with the content hidden)
   * The first one calling this function sets the stepper variable of the service to its stepper.
   * The second one calling this function sets its own stepper to that set in the service.
   * This way there is only one stepper variable and both are synced.
   * The first one calling this function should be the content one, in order to activate the valueChanges
   * subjects of the personal form.
   * This is achieved by placing the content stepper above the header stepper in booking.component.html.
   * It should be tested whether the order can be reversed due to race conditions.
   */
  syncStepperWithService() {
    // the content-only version of the stepper
    if (!this.isHeaderOnly) {
      this.bookingService.stepper = this.stepper
      this.bookingService.personalFormComponent = this.personalFormComponent
      this.stepper.selectionChange
        .pipe(untilDestroyed(this))
        .subscribe((event: any) => {
          this.bookingService.stepperStepIndex = event.selectedIndex
        })
      this.bookingService.init().subscribe()
      this.bookingSeriesService.setReservationSubscription()
    }
    // the header-only version of the stepper
    else {
      // change the header's index upon changes to the content's index
      this.bookingService.stepper.selectionChange
        .pipe(untilDestroyed(this),
          // without this delay+detect changes,
          // stepper.selectedIndex is always the previous step, not the current one
          delay(100)
        ).subscribe(() => {
          if (this.stepper.steps.length) {
            this.stepper.selectedIndex = this.bookingService.stepperStepIndex
            if (this.stepper.selectedIndex < this.bookingService.stepperStepIndex) {
              this.stepper.selected.completed = true
              this.stepper.next()
            }
            if (this.stepper.selectedIndex > this.bookingService.stepperStepIndex)
              this.stepper.previous()
            this.cd.detectChanges()
          }
        })
      // change the content's index upon changes to the header's index
      this.stepper.selectionChange
        .pipe(untilDestroyed(this),
          // without this delay+detect changes,
          // stepper.selectedIndex is always the previous step, not the current one
          delay(100)
        ).subscribe(() => {
          this.bookingService.stepper.selectedIndex = this.stepper.selectedIndex
          if (this.stepper.selected === this.bookingDateMultiStep) this.bookingService.multiStepSelected$.next(true)
          else this.bookingService.multiStepSelected$.next(false)
        })
      // upon selection of a new bs, set the first header stepper step to completed
      // and the rest to uncompleted. Otherwise it is possible to click on uncompleted steps (such as personal
      // form) without selecting a new opening. This is a minor bug as it is only allows clicking,
      // not navigating.
      // note: mat-stepper has a bug (or a decision I don't understand) : when hovering over the current
      // step (not over the completed ones or the ones not yet available) the cursor is default
      // instead of pointer, even though one can click on it to go to that step.
      // I observed this in the old design as well, back when there was just one stepper.
      this.bookingService.bs.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.stepper.steps.forEach((step: MatStep, index: number) =>
            step.completed = index === 0 ? true : false)
        })
    }

    // showBookingAnamanese and tab titles subscriptions
    this.showBookingAnamneseSubscribe()
    this.tabTitlesSubscribe()
  }

  showBookingAnamneseSubscribe() {
    merge(this.stepper.selectionChange, this.bookingService.bookingTypeFormGroup.valueChanges)
      .pipe(untilDestroyed(this),
        // without this delay+detect changes,
        // stepper.selectedIndex is always the previous step, not the current one
        delay(100)
      ).subscribe(() => {
        this.showBookingAnamnese = this.bookingService.doesAnamneseFormExist()
      })
  }

  tabTitlesSubscribe() {
    this.bookingService.isBookingInitialized$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe((isBookingInitialized: boolean) => {
        if (isBookingInitialized)
          this.tabTitles = this.bookingService.tabTitles
      })
  }

  // used to show/hide the magic fill button
  public isInDemoMode() {
    return environment.demoMode
  }

  public shortenReservationTimer() {
    this.bookingService.reservation.dateExpiry = dayjs().add(1, 'seconds').toDate()
  }
}
