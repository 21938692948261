import { interval, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AudioPlayerOptions, WebRTCAudioCodec } from '../../entities/WebRTC.entity';
export class AudioPlayer {
  private options: AudioPlayerOptions
  private samples: Float32Array
  private startTime: number
  private unsubscribe$ = new Subject()

  // audio elements
  private audioContext: AudioContext  // general audio controller
  private gainNode: GainNode          // volume control

  private defaultOptions: AudioPlayerOptions = {
    channels: 2,
    flushTime: 500,
    sampleRate: 44100,
    codec: WebRTCAudioCodec.Float32
  }

  constructor(options?: AudioPlayerOptions) {
    this.init(options);
  }

  public init(options?: AudioPlayerOptions): void {
    this.options = Object.assign({}, this.defaultOptions, options);
    this.samples = new Float32Array();
    this.createContext();
    interval(this.options.flushTime).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => this.flush());
  }

  public volume(volume: number) {
    this.gainNode.gain.value = volume;
  }

  public async suspend() {
    await this.audioContext.suspend();
  }

  public async resume() {
    await this.audioContext.resume();
  }

  public destroy(): Observable<any> {
    return new Observable((observer) => {
      this.unsubscribe$.next(true);
      if (!this.audioContext) {
        observer.next();
        observer.complete();
      }
      // finish last interval first
      this.samples = null;
      this.audioContext.close().then(() => {
        this.audioContext = null;
        observer.next();
        observer.complete();
      });
    })
  }

  public createContext(): void {
    this.audioContext = new AudioContext();
    this.audioContext.resume(); // iOS/Safari fix to start context
    this.startGain();
    this.startTime = this.audioContext.currentTime;
  }

  public startGain(startVolume = 10): void {
    this.gainNode = this.audioContext.createGain();
    this.gainNode.gain.value = startVolume;
    this.gainNode.connect(this.audioContext.destination);
  }

  public feedBuffer(data: any): void {
    if (!this.audioContext) { return; }
    data = new Float32Array(data);
    if (!this.samples) {
      this.samples = new Float32Array();
    }
    const sampleLength = this.samples ? this.samples.length : 0;
    const tmp = new Float32Array(sampleLength + data.length);
    tmp.set(this.samples, 0);
    tmp.set(data, sampleLength);
    this.samples = tmp;
  }

  public flush() {
    if (!this.samples || this.samples.length < 2) { return; }
    const bufferSource = this.audioContext.createBufferSource();
    const length = this.samples.length / this.options.channels;
    const audioBuffer = this.audioContext.createBuffer(this.options.channels, length, this.options.sampleRate);

    for (let channel = 0; channel < this.options.channels; channel++) {
      const audioData = audioBuffer.getChannelData(channel);
      let offset = channel;
      let decrement = 50;
      for (let i = 0; i < length; i++) {
        audioData[i] = this.samples[offset];
        // fade in
        if (i < 50) {
          audioData[i] = (audioData[i] * i) / 50;
        }
        // fade out
        if (i >= (length - 51)) {
          audioData[i] = (audioData[i] * decrement--) / 50
        }
        offset += this.options.channels;
      }
    }

    // sync time with audio context
    this.startTime = Math.max(this.audioContext.currentTime, this.startTime);
    console.log('start vs current ' + this.startTime + ' vs ' + this.audioContext.currentTime + ' duration: ' + audioBuffer.duration);

    bufferSource.buffer = audioBuffer;
    bufferSource.connect(this.gainNode);
    bufferSource.start(this.startTime);
    this.startTime += audioBuffer.duration;
    this.samples = new Float32Array();
  }
}
