import { LinkData } from './parse-raw-data'
import { submitResponse } from './submit-response'
import { PermalinkMissingError } from './../../permalink-missing-error'
import { getDisplayableErrorRoute } from './../../../../user-message/displayable-errors'
import { WfaEnvService } from './../../../../../environments/wfa-env.service'
import { httpOptions } from './../../../../utility/http-params'
import { getWfaServerBaseUrl } from './../../../../../environments/get-wfa-url'
import { parseRawData } from './parse-raw-data'
import { ActivatedRoute, Router } from '@angular/router'

import { buildUrl } from './build-url'
import { extractRouteParams } from './extract-route-params'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  AnyWfaForm,
  Id,
  PraxisId,
  WfaForm,
  WfaFormDraftInfo,
  WfaFormId,
  WfaFormInfo,
  WfaFormStatus,
} from '@arzt-direkt/wfa-definitions'
import { PraxisDetails } from '@arzt-direkt/wfa-definitions'
import { GetWfaFormRouteResponse200 } from '@arzt-direkt/wfa-definitions'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import { nullish } from '@arzt-direkt/wfa-generic-utils'
import { Observable, of } from 'rxjs'
import { take, map, switchMap, withLatestFrom } from 'rxjs/operators'
import { catchError } from 'rxjs/operators'

export interface FormAndDraftInfos {
  formInfos: WfaFormInfo[]
  draftInfos: WfaFormDraftInfo[]
}
export const emptyInfos = { formInfos: [], draftInfos: [] }

@Injectable({
  providedIn: 'root',
})
export class SingleOrMultilinkApiService {
  private readonly baseUrl = `${this.wfaEnv.frontendUrlSubdirectory}/user-message/`

  loadLinkData(
    routeParams: Record<string, string>,
  ): Observable<Maybe<LinkData>> {
    const params = extractRouteParams(routeParams)
    const url = buildUrl(params, getWfaServerBaseUrl(this.wfaEnv))
    return this.httpClient.get(url).pipe(
      map(parseRawData),
      switchMap((ld: LinkData) => {
        if (ld.alreadySubmitted) {
          this.router.navigate([this.alreadySubmittedRoute()])
          return of(null)
        }

        return of(ld)
      }),
      catchError((error) => {
        if (error instanceof PermalinkMissingError) {
          this.handlePermalinkMissingError()
        } else {
          this.handleFallbackError(JSON.stringify(error, null, 2) ?? '')
        }
        return of(null)
      }),
    )
  }

  submitLinkData(linkData: Pick<LinkData, 'fwrs' | 'type'>) {
    console.log(
      'catbug submitting data',
      Object.keys(linkData.fwrs[0].formResponse),
      JSON.stringify(linkData.fwrs[0].formResponse, null, 2),
    )

    return submitResponse(linkData, this.httpClient, this.wfaEnv, this.router)
  }

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private wfaEnv: WfaEnvService,
  ) {}

  private alreadySubmittedRoute(): string {
    return `${this.wfaEnv.frontendUrlSubdirectory}/user-message/alreadySubmitted`
  }

  private errorRoute(message: string): string[] {
    const errorUrl = getDisplayableErrorRoute('q5706', message)
    return [this.baseUrl, errorUrl]
  }

  private handlePermalinkMissingError() {
    this.wfaEnv.logger.error('[ViewerFromLinkResolver]: permalink missing')
    this.router.navigate([
      `${this.wfaEnv.frontendUrlSubdirectory}/user-message/`,
      getDisplayableErrorRoute('q5708'),
    ])
  }

  private handleFallbackError(message: string) {
    this.wfaEnv.logger.error(
      '[ViewerFromLinkResolver]: fallback error',
      message,
    )
    this.router.navigate(this.errorRoute(message))
  }
}
