import { LinkType } from './single-or-multilink.types'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import {
  initialViewerEnvStateDict,
  ViewerEnvState,
  WfaFormWithResponse,
  IsEmbeddedIn,
} from '@arzt-direkt/wfa-definitions'

export type LoadableLinkType = LinkType | 'loading' | 'loadError'

export interface SingleOrMultilinkState extends Record<string, unknown> {
  env: ViewerEnvState
  activeResponseId: Maybe<string> // responseId of the currently edited form
  fwrs: Maybe<WfaFormWithResponse[]>
  valid: Record<string, Maybe<boolean>>
  visited: Record<string, Maybe<boolean>>
  linkType: LoadableLinkType
  // this counter is used to manually trigger re-render of currently selected fwr
  // some properties of the current form (like validity of certain questions)
  // can be changed even when activeResponseId and fwrs remain the same.
  // the attribute `rerenderCounter` is a simple and efficient tool to
  // do this / act as a manual change detection trigger
  rerenderCounter: number
  // the corresponding observable can be used to trigger viewer
  // to emit the latest form response
  requestResponseCounter: number
}

export const initialSingleOrMultilinkState: SingleOrMultilinkState = {
  env: initialViewerEnvStateDict['arztDirekt'],
  activeResponseId: undefined,
  fwrs: undefined,
  valid: {},
  visited: {},
  linkType: 'loading',
  rerenderCounter: 0,
  requestResponseCounter: 0,
}

type State = SingleOrMultilinkState
export type CoreState = {
  fwrs: State['fwrs']
  activeResponseId: State['activeResponseId']
}
export type LeanCoreState = {
  fwrs: Maybe<
    Array<{
      formResponse: {
        responseId: WfaFormWithResponse['formResponse']['responseId']
      }
    }>
  >
  activeResponseId: State['activeResponseId']
}

export const linkEmbeddedDict: Record<LoadableLinkType, IsEmbeddedIn> = {
  single: 'arztDirekt',
  loading: 'arztDirekt',
  loadError: 'arztDirekt',
  multi: 'adMultilinkViewer',
  perma: 'adPermalinkViewer',
}
