import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NavigationStart, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private unsubscribe$ = new Subject()

  public readonly DEFAULT_SNACKBAR_OPTIONS = {
    duration: 5000,
    panelClass: ['c-snackbar'],
    verticalPosition: <MatSnackBarVerticalPosition>'top',
    horizontalPosition: <MatSnackBarHorizontalPosition>'center'
  }

  private previousWindowTitle: string
  private previousWindowTitleNotifications: string[] = []


  constructor(
    private router: Router,
    public snackBar: MatSnackBar
  ) {
    // Cleanup Favicon- & Title-Notifications on Router-Navigation
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      takeUntil(this.unsubscribe$)
    ).subscribe((_) => {
      this.restoreWindowTitle()
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }


  /**
  * Show a little notification-bar at the top of the page which contains the given message.
  */
  public displayNotification(message: string, config: MatSnackBarConfig = {}, action?: string): Observable<void> {
    const mergedConfig = Object.assign({}, this.DEFAULT_SNACKBAR_OPTIONS, config)
    const snackBarRef = this.snackBar.open(message, action, mergedConfig)
    return action ? snackBarRef.onAction() : of(null)
  }


  /**
  * Updates the document title with the given messages and stores the old title.
  */
  public displayWindowTitleNotification(message: string) {
    // Store window title if it is no previous notification
    const wasNotification = this.previousWindowTitleNotifications.includes(document.title)
    if (!wasNotification) this.previousWindowTitle = document.title

    document.title = message
    this.previousWindowTitleNotifications.push(message)
  }


  /**
  * Restores the window title before `displayWindowTitleNotification` was called.
  */
  public restoreWindowTitle() {
    if (this.previousWindowTitle) {
      document.title = this.previousWindowTitle
      this.previousWindowTitle = null
    }
  }

}
