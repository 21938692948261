import { flow } from '@arzt-direkt/wfa-generic-utils'

import { LicenseTier } from '../../types/license-tier'
import { customizeTopBar } from './customize-top-bar'
import { defaultCreatorOptions } from './default-creator-options'
import { customizeQuestionTypes } from './toolbox-customization/customize-question-types'

export function combineCreatorOptions(licenseTier: LicenseTier) {
  const customize = flow(
    customizeTopBar(licenseTier),
    customizeQuestionTypes(licenseTier),
  )

  return customize(defaultCreatorOptions)
}
