import { ICreatorOptions } from 'survey-creator-core'

import { LicenseOptionsRecord, LicenseTier } from '../../types/license-tier'
import { Operator } from '../../types/operator'
import { customizeCreatorOptions } from './customize-creator-options'

const basic = {
  showDesignerTab: true,
  showPreviewTab: true,
  showLogicTab: true,
  showJSONEditorTab: false,
  showTranslationTab: true,
}

const extended = {
  showDesignerTab: true,
  showPreviewTab: true,
  showLogicTab: true,
  showJSONEditorTab: false,
  showTranslationTab: true,
}

const devEverything = {
  showDesignerTab: true,
  showPreviewTab: true,
  showThemeTab: true,
  showLogicTab: true,
  showJSONEditorTab: true,
  showTranslationTab: true,
}

const topBarOptionsRecord: LicenseOptionsRecord = {
  basic,
  extended,
  devEverything,
}

export function customizeTopBar(
  licenseTier: LicenseTier,
): Operator<ICreatorOptions> {
  return (c: ICreatorOptions) =>
    customizeCreatorOptions(licenseTier, topBarOptionsRecord, c)
}
