<div class="overlay-button">
  <button (click)="showOverlay = !showOverlay">Settings</button>
</div>

<div class="overlay" *ngIf="showOverlay">
  <div>
    <h1>Testing AD Form Editor</h1>
  </div>

  <div>
    <label> Locale: </label>
    <select [formControl]="localeControl">
      <option *ngFor="let locale of locales" [ngValue]="locale">
        {{ locale }}
      </option>
    </select>
  </div>

  <div>
    <label> License Tier: </label>
    <select [formControl]="licenseTierControl">
      <option *ngFor="let licenseTier of licenseTiers" [ngValue]="licenseTier">
        {{ licenseTier }}
      </option>
    </select>
  </div>
</div>

<wfa-editor [editorEnv$]="editorEnv$"></wfa-editor>
