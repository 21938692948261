import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { LegalDataFiles, LegalDialogData } from '../entities/Legal.entity';
import { LanguageService } from '../i18n/language.service';
import { InstanceService } from '../instance/instance.service';
import { LegalDialogComponent } from './legal-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class LegalService implements OnDestroy {

  public legalDialogRef: MatDialogRef<LegalDialogComponent>
  private unsubscribe$ = new Subject()
  public get allLegalFileNames(): string[] {
    return Object.keys(LegalDataFiles)
  }
  public get allLegalFiles(): LegalDataFiles[] {
    return Object.keys(LegalDataFiles).map(key => LegalDataFiles[key])
  }

  constructor(
    private dialog: MatDialog,
    private instanceService: InstanceService,
    private languageService: LanguageService,
  ) { }

  ngOnDestroy() {
    this.closeDialog()
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }

  /**
  * Opens the LegalDialog with the given content/type
  */
  public openDialog(document: LegalDataFiles, documentType: 'instance' | 'global' = 'instance', showActions = false, scrollToHeading?: string, width?: string): MatDialogRef<LegalDialogComponent> {
    // Check for occurence of '#' which indicated to scroll to the given heading
    let dataFile = document
    if (document.indexOf('#') >= 0) {
      dataFile = document.split('#')[0] as LegalDataFiles
      scrollToHeading = document.split('#')[1]
    }

    // Hide TOC for certain document-types
    const hideToc = [
      LegalDataFiles.Support,
      LegalDataFiles.Kundensupport,
      LegalDataFiles.Changelog,
      LegalDataFiles.ChangelogOtk,
      LegalDataFiles.Zahlungshinweise,
      LegalDataFiles.Zeitplan,
      LegalDataFiles.Impressum,
      LegalDataFiles.Gesundheitskarte,
      LegalDataFiles.GesundheitskarteAblauf
    ].includes(document)

    const dialogData: LegalDialogData = {
      documentType,
      activeInstance: this.instanceService.activeInstance,
      activeBaseLang: this.languageService.activeBaseLang,
      dataFile: dataFile,
      showActions: showActions,
      hideToc: hideToc,
      scrollToHeading: scrollToHeading,
    }

    this.legalDialogRef = this.dialog.open(LegalDialogComponent, {
      width: width || '45rem',
      backdropClass: 'c-dialog-transparent-backdrop',
      data: dialogData
    })
    return this.legalDialogRef
  }

  /**
  * Closes the LegalDialog if opened
  */
  public closeDialog() {
    if (this.legalDialogRef) {
      this.legalDialogRef.close()
    }
  }
}
