

export enum PlannedAvailabilityDay {
  Mo = 'monday',
  Di = 'tuesday',
  Mi = 'wednesday',
  Do = 'thursday',
  Fr = 'friday',
  Sa = 'saturday',
  So = 'sunday',
}

export enum PlannedAbsenceMonth {
  Jan = 'january',
  Feb = 'february',
  Mar = 'march',
  Apr = 'april',
  May = 'may',
  Jun = 'june',
  Jul = 'july',
  Aug = 'august',
  Sep = 'september',
  Oct = 'october',
  Nov = 'november',
  Dec = 'december'
}

export enum PlannedAvailabilityType {
  pa = 'pa',
  ka = 'ka',
  sum = 'sum',
}

export interface PlannedAvailability {
  readonly [PlannedAvailabilityType.pa]?: PlannedAvailabilityUnit
  readonly [PlannedAvailabilityType.ka]?: PlannedAvailabilityUnit
  readonly [PlannedAvailabilityType.sum]?: PlannedAvailabilityUnit
}

export interface PlannedAvailabilityUnit {
  readonly [PlannedAvailabilityDay.Mo]?: string
  readonly [PlannedAvailabilityDay.Di]?: string
  readonly [PlannedAvailabilityDay.Mi]?: string
  readonly [PlannedAvailabilityDay.Do]?: string
  readonly [PlannedAvailabilityDay.Fr]?: string
  readonly [PlannedAvailabilityDay.Sa]?: string
  readonly [PlannedAvailabilityDay.So]?: string
}

export interface PlannedAbsenceUnit {
  readonly [PlannedAbsenceMonth.Jan]?: string
  readonly [PlannedAbsenceMonth.Feb]?: string
  readonly [PlannedAbsenceMonth.Mar]?: string
  readonly [PlannedAbsenceMonth.Apr]?: string
  readonly [PlannedAbsenceMonth.May]?: string
  readonly [PlannedAbsenceMonth.Jun]?: string
  readonly [PlannedAbsenceMonth.Jul]?: string
  readonly [PlannedAbsenceMonth.Aug]?: string
  readonly [PlannedAbsenceMonth.Sep]?: string
  readonly [PlannedAbsenceMonth.Oct]?: string
  readonly [PlannedAbsenceMonth.Nov]?: string
  readonly [PlannedAbsenceMonth.Dec]?: string
}

export interface PlannedAbsenceWeek {
  [PlannedAvailabilityDay.Mo]?: boolean
  [PlannedAvailabilityDay.Di]?: boolean
  [PlannedAvailabilityDay.Mi]?: boolean
  [PlannedAvailabilityDay.Do]?: boolean
  [PlannedAvailabilityDay.Fr]?: boolean
  [PlannedAvailabilityDay.Sa]?: boolean
  [PlannedAvailabilityDay.So]?: boolean
}