import { Maybe, nullish } from '@arzt-direkt/wfa-generic-utils'
import Joi from 'joi'

import { SurveyjsResponse } from '../interfaces-autogen-from-joi'
import { surveyjsResponseJoiExample } from './surveyjs-response.joi.example'
import { wfaKeys } from './wfa-key.joi'

// Predefined set of allowed keys
const allowedKeys = wfaKeys

// allowed values
const allowedValuesJoi = Joi.alternatives(
  Joi.string(),
  Joi.array().items(Joi.string()),
)

// Define Joi schema
export const SurveyjsResponseJoi = Joi.object()
  .pattern(Joi.string().valid(...allowedKeys), allowedValuesJoi)
  .unknown(true)
  .meta({
    className: 'SurveyjsResponse',
  })
  .example(surveyjsResponseJoiExample)

export function hasUnencryptedResponse(
  x: Maybe<SurveyjsResponse>,
): x is Record<string, unknown> {
  if (typeof x === 'string' || nullish(x) || typeof x !== 'object') {
    return false
  }

  return Object.entries(x).some(([_key, value]) => {
    return (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      !(Array.isArray(value) && value.length === 0)
    )
  })
}
