import {
  CoreLocale,
  InputType,
  LocString,
  WfaKey,
  WfaQuestionType,
} from '@arzt-direkt/wfa-definitions'
import { PropertyChoice } from '@arzt-direkt/wfa-definitions'
import { wfaKeys } from '@arzt-direkt/wfa-definitions'
import { removeKeys } from '@arzt-direkt/wfa-definitions'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import { nullish } from '@arzt-direkt/wfa-generic-utils'

import { initialEditorState } from '../../../editor.state'
import qtd from './question-type-dictionary.json'
import std from './short-title-dictionary.json'

interface QuestionTypeDictionary {
  wfaKey: WfaKey
  questionType: WfaQuestionType
  inputType?: string
}

const questionTypeDictionary = qtd as unknown as QuestionTypeDictionary[]

// certain keys are hidden from view until they are
// supported in tomedo, see https://zollsoft.atlassian.net/browse/ADI-3198
const shortTitleDictionary = removeKeys(std, [
  '$wfaPatientKrankenkasse$',
  '$wfaPatientVersicherungsnummer$',
  '$wfaPatientVersichertennummer$',
  '$wfaPatientGewicht$',
  '$wfaPatientGroesse$',
  '$wfaPatientArbeitgeber$',
]) as unknown as Partial<Record<WfaKey, LocString>>

const fallbackLocale = initialEditorState.editorEnv.locale

export const wfaKeyChoices: PropertyChoice[] = Object.entries(
  shortTitleDictionary,
).map((entry) => {
  const [key, value] = entry
  return {
    value: key,
    text: value,
  }
})

export function lookUpLocalizedShortTitleFromWfaKey(
  wfaKey: WfaKey,
  locale: CoreLocale,
): Maybe<string> {
  const shortTitleLocString = shortTitleDictionary[wfaKey]
  if (nullish(shortTitleLocString)) return undefined
  return shortTitleLocString[locale] ?? shortTitleLocString[fallbackLocale]
}

export function wfaKey2QuestionType(wfaKey: WfaKey): Maybe<WfaQuestionType> {
  const entry = questionTypeDictionary.find((e) => e['wfaKey'] === wfaKey)
  if (nullish(entry)) return undefined
  return entry.questionType
}

export function wfaKey2TextInputType(wfaKey: WfaKey): Maybe<InputType> {
  const entry = questionTypeDictionary.find((e) => e['wfaKey'] === wfaKey)
  if (nullish(entry)) return undefined
  return entry.inputType as InputType
}

export function questionType2WfaKey(
  questionType: WfaQuestionType,
): Maybe<WfaKey> {
  const entries = questionTypeDictionary.filter(
    (e) => e['questionType'] === questionType,
  )
  if (entries.length !== 1) return undefined
  return entries[0].wfaKey
}

export function isWfaKey(x: unknown): x is WfaKey {
  if (nullish(x)) return false
  return typeof x === 'string' && wfaKeys.includes(x as WfaKey)
}
