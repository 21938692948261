import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { NotificationService } from "@lib/notifications/notification.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import dayjs from "dayjs";
import { Subscription, interval, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { BookingSeriesService } from "../booking-series.service";
import { BookingService } from "../booking.service";
import { SecondsToDoubleDigitsMinutesPipe } from "./SecondsToDoubleDigitsMinutes.pipe";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgIf, NgClass, AsyncPipe } from "@angular/common";

@UntilDestroy()
@Component({
    selector: 'app-booking-reservation',
    templateUrl: './booking-reservation.component.html',
    styleUrls: ['./booking-reservation.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, FontAwesomeModule, AsyncPipe, TranslateModule, SecondsToDoubleDigitsMinutesPipe]
})
export class BookingReservationComponent implements OnInit {
  timeLeft: number // remaining time until the reservation expires in seconds
  alertThreshold: number // below this threshold, the background color changes from success to warning
  isBookingReserved: boolean = false
  demoMode: boolean
  subscriptionSetInterval: Subscription
  seriesReservation$ = this.bookingSeriesService.reservation

  constructor(
    public bookingService: BookingService,
    public bookingSeriesService: BookingSeriesService,
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.alertThreshold = this.bookingService.ALERT_THRESHOLD
    this.setsubscriptionSetInterval()
  }

  /**
   * every second:
   * If a reservation exists, calculate the time left.
   * If the time elapses, expire the reservation and hide the reservation component.
   */
  setsubscriptionSetInterval() {
    if (this.subscriptionSetInterval)
      this.subscriptionSetInterval.unsubscribe()
    this.subscriptionSetInterval = interval(1000)
      .pipe(
        untilDestroyed(this),
        map(() => {
          if (this.bookingService.reservation)
            return dayjs(this.bookingService.reservation.dateExpiry).diff(dayjs(), "seconds")
          else if (this.bookingSeriesService.reservation?.length)
            return dayjs(this.bookingSeriesService.reservation[0].dateExpiry).diff(dayjs(), "seconds")
          else return 0
        }),
        mergeMap((timeLeft: number) => {
          if (!this.bookingService.reservation && !this.bookingSeriesService.reservation?.length)
            return of(null)
          // sometimes the counter goes to negative values. Maybe its because of "=== 0" so changed it to "<= 0"
          if (timeLeft <= 0) {
            this.notificationService.displayNotification(this.translate.instant('OTK.STEPPER.EXPIRED-NOTIFICATION'))
            return this.bookingService.seriesMode$.getValue() ? this.bookingSeriesService.cancelReservationFrontend() : this.bookingService.cancelReservation(true)
          }
          this.timeLeft = timeLeft
          this.cd.detectChanges()
          return of(null)
        })
      ).subscribe()
  }

}
