import Joi from 'joi'

import { inputTypes } from '../input-type.joi'
import { WfaQuestionValidatorJoi } from '../wfb/wfa-question-validator.joi'
import { BaseQuestionJoi } from './base-question.joi'
import { example } from './examples/wfa-text-question.joi.example'
import { wfaInputTypeDict } from './wfa-input-type.joi'

export const WfaTextQuestionJoi = BaseQuestionJoi.keys({
  type: Joi.string().valid('text').required(),
  inputType: Joi.string()
    .valid(...inputTypes)
    .optional(),
  wfaInputType: Joi.when('inputType', {
    is: 'custom-number',
    then: Joi.string().valid(wfaInputTypeDict.number).required(),
    otherwise: Joi.forbidden(),
  }),
  validators: Joi.array().items(WfaQuestionValidatorJoi),
  defaultValue: Joi.when('inputType', {
    is: 'date',
    then: Joi.string().valid('=today()'),
  }),
  defaultValueExpression: Joi.when('inputType', {
    is: 'date',
    then: Joi.string().valid('today()'),
  }),
})
  .meta({
    className: 'WfaTextQuestion',
  })
  .example(example)
