<lib-dialog [isLoading]="isLoading" #dialog>

  <div mat-dialog-title>
    <h1>
      AGB und Datenschutz
    </h1>
  </div>

  <div mat-dialog-content>
    <p>
      Sehr geehrte arzt-direkt-Nutzer:innen,
    </p>
    <p>
      im Rahmen der Zertifizierung, aber auch der Einführung neuer Produkte, wie der neuen Patientenformulare oder
      arzt-direkt.Pay, haben wir unsere Datenschutzhinweise und Allgemeinen Geschäftsbedingungen angepasst.
    </p>
    <p>
      Bitte nehmen Sie diese zur Kenntnis bzw. akzeptieren Sie diese.
    </p>
    <p>
      Hier finden Sie unsere Datenschutzhinweise (
      <a class="clickable" [href]="'https://arzt-direkt.de/datenschutz/'" target="_blank">Link</a>
      |
      <a class="clickable" (click)="openPrivacy()">Anzeigen</a>
      ) und die Allgemeinen
      Geschäftsbedingungen (
      <a class="clickable" [href]="'https://arzt-direkt.de/agb/'" target="_blank">Link</a>
      |
      <a class="clickable" (click)="openAGB()">Anzeigen</a>
      ).
    </p>
    <p>
      Bei Rückfragen kontaktieren Sie gerne <a class="clickable" href="mailto:arzt-direkt@zollsoft.org">
        <span style="white-space: nowrap">arzt-direkt&#64;zollsoft.org</span>
      </a>.
    </p>
    <p>
      Mit freundlichen Grüßen
    </p>
    <p>
      Ihr arzt-direkt Team
    </p>
    <div class="checkbox-wrapper">
      <mat-checkbox [formControl]="agbCheckbox" color="primary" required></mat-checkbox>
      <p>
        Ich habe die Allgemeinen Geschäftsbedingungen gelesen und akzeptiere diese.
      </p>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="saveAGBAcceptance()" [disabled]="isLoading || !agbCheckbox.value">
      <span>Weiter</span>
    </button>
    <button mat-flat-button routerLink="/auth/logout" [mat-dialog-close] [disabled]="isLoading">Ausloggen</button>
  </div>

</lib-dialog>