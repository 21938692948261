import { InstanceService } from '@a-d/instance/instance.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { BaseLanguage, I18NArray, I18NString } from '../../entities/I18N.entity';
import { LanguageService } from '../../i18n/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgFor, UpperCasePipe, KeyValuePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-multilingual-chip-list-field-component',
    host: { 'class': 'c-form__row' },
    templateUrl: './multilingual-chip-list-field.component.html',
    styleUrls: ['./multilingual-field.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, FormsModule, ReactiveFormsModule, MatChipsModule, NgIf, NgFor, MatIconModule, MatAutocompleteModule, MatButtonModule, MatTooltipModule, FontAwesomeModule, MatOptionModule, MatProgressSpinnerModule, UpperCasePipe, KeyValuePipe, TranslateModule]
})
export class MultilingualChipListFieldComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup
  @Input() formAttribute: AbstractControl
  @Input() placeholder: string
  @Input() required: boolean

  constructor(
    private languageService: LanguageService,
    private instanceService: InstanceService,
  ) {
    this.textInputField?.valueChanges.subscribe(
      newValue => {
        this.showTranslations = false
        this.isLoading = false
        this.updateI18nValues(newValue)
        this.formAttribute.markAsDirty()
      }
    )
  }

  public originalLanguage = this.languageService.DEFAULT_BASE_LANG
  public activeLanguage = this.languageService.activeBaseLang
  public acceptedLanguages = this.languageService.ALL_BASE_LANGAUGES
  public targetLanguages = this.acceptedLanguages.filter(lang => lang !== this.activeLanguage)

  public instanceLanguages = this.instanceService.activeInstance?.settings?.general?.internationalization?.baseLanguagesActive

  public textInputField = new UntypedFormControl()
  public i18nValues: I18NArray = {};
  public showTranslations = false
  public isLoading = false
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('auto') matAutocomplete: MatAutocomplete
  @ViewChild('inputField') inputField: ElementRef<HTMLInputElement>

  ngOnInit(): void {
    // Set validators
    this.formAttribute.setValidators([
      ...(this.required ? [Validators.required] : []),
    ])

    // hide translations by default
    this.showTranslations = false

    // show value form DB on load
    if (this.formAttribute.value?.length) this.i18nValues[this.activeLanguage] = this.formAttribute.value
    else if (this.formAttribute.value && this.containsLanguage(this.formAttribute.value)) this.i18nValues = this.formAttribute.value

    // updates `i18nValues` and then turns loading off loading spinner
    // this.translationSubs = this.googleTranslate.translatedArrayObject$.subscribe(
    //   v => {
    //     this.updateI18nValues(v)
    //     this.isLoading = false
    //   }
    // )
  }

  /**
   * Adds an item to the array
   */
  public add(event: MatChipInputEvent) {
    this.showTranslations = false
    const newValue = event.value ? event.value.trim() : null
    const newValueExists = (this.i18nValues[this.activeLanguage] || []).includes(newValue)

    // Add item
    if (this.formAttribute.value && newValue && !newValueExists) {
      Array.isArray(this.i18nValues[this.activeLanguage])
        ? this.i18nValues[this.activeLanguage].push(newValue)
        : this.i18nValues[this.activeLanguage] = [newValue]
      this.updateFormAttribute(this.i18nValues[this.activeLanguage])
      this.formAttribute.updateValueAndValidity({ emitEvent: true })
    } else if (!this.formAttribute.value && newValue && !newValueExists) {
      this.updateI18nValues(newValue)
    }

    this.setIsDisabled(newValue)
    // Reset the input value
    if (event.input) event.input.value = ''

    // Set Control manually to pristine
    this.formAttribute.markAsDirty()
  }

  /**
  * Checks if the TranslationObject is a language object
  */
  private containsLanguage(TranslationObject: I18NArray): boolean {
    if (!TranslationObject) return false
    return !!this.acceptedLanguages.filter(lang => Object.keys(TranslationObject).includes(lang)).length
  }

  /**
   * Removes the given item
   */
  // for values saved on form
  public clear(lang: BaseLanguage) {
    this.i18nValues[lang] = ''//'MULTILINGUAL-FIELD.NO-TRANSLATION'
    this.updateFormAttribute(this.i18nValues)
    if (lang === this.activeLanguage) this.textInputField.setValue('')
    this.setIsDisabled(this.textInputField.value)
  }
  // for individual chips in the `activeLanguage`
  public remove(item: string) {
    if (!this.i18nValues[this.activeLanguage]) return
    const index = this.i18nValues[this.activeLanguage].indexOf(item)
    // checks if `i18nValues` already contains item and updates `formAttribute` accordingly
    if (index >= 0) {
      this.i18nValues[this.activeLanguage].splice(index, 1)
      if (Object.keys(this.i18nValues).length > 1) this.updateFormAttribute(this.i18nValues)
      else this.updateFormAttribute(this.i18nValues[this.activeLanguage], false)
      this.formAttribute.updateValueAndValidity({ emitEvent: true })
    }

    this.setIsDisabled(this.i18nValues[this.activeLanguage].length)
    this.formAttribute.markAsDirty()
  }

  /*
   * Helper functions for converting strings to upper-/title-case
   */
  private capitalize(str) {
    return str.charAt(0).toUpperCase() + str.substring(1, str.length).toLowerCase();
  }
  private titleCase(str) {
    return str.replace(/[^\ \/\-\_]+/g, this.capitalize);
  }

  /**
   * Formats the chips to a comma-separated string
   */
  public formatChipsArray(chipsArray: string[]) {
    if (!chipsArray?.length) return ''
    return chipsArray
      .map((word) => this.titleCase(word))
      .join(', ')
  }

  /**
   * Calculates if a button should be disabled
   */
  public isDisabled = false
  public setIsDisabled(condition: any) {
    if (!condition) this.isDisabled = true
    else this.isDisabled = false
  }

  /**
   * Checks if the active value `i18nValue` key is not a string, in order to avoid loading non-existing chips
   */
  public isArray(): boolean {
    return typeof (this.i18nValues[this.activeLanguage]) !== 'string'
  }

  /**
   * Opens box and set's default values if none are present
   */
  public toggleTranslationBox() {
    this.inputField.nativeElement.focus() // makes sure matAutocomplete opens upon button press
    if (this.showTranslations) return this.showTranslations = false
    this.targetLanguages.forEach(lang => {
      if (!this.i18nValues[lang]) this.i18nValues[lang] = ''//'MULTILINGUAL-FIELD.NO-TRANSLATION'
    })
    this.i18nValues[this.activeLanguage] = (this.i18nValues[this.activeLanguage]?.length && this.i18nValues[this.activeLanguage] !== ''/*'MULTILINGUAL-FIELD.NO-TRANSLATION'*/) ? this.i18nValues[this.activeLanguage] : ''//'MULTILINGUAL-FIELD.NO-TRANSLATION'
    this.showTranslations = true
    this.formAttribute.markAsDirty()
  }

  /**
  * Updates `activeLanguage` and `textInputField`, plus closes TranslationBox
  */
  setLanguage(lang: BaseLanguage) {
    if (lang === this.activeLanguage) return
    this.originalLanguage = this.activeLanguage
    this.activeLanguage = lang
    if (typeof (this.i18nValues[this.activeLanguage]) === 'string') this.i18nValues[this.activeLanguage] = []
    this.showTranslations = false
    this.inputField.nativeElement.value = ''
  }

  /**
   * Updates FormAttribute by formatting an `I18NString` value
   */
  public updateFormAttribute(v: I18NArray, blockEmptyValues = true) {
    if (!v && blockEmptyValues) return
    const oldValues = this.formAttribute.value
    let newValues = {}

    // set evaluate the value of `v` and update `newValues` accordingly
    if (!oldValues && !!(v as string[]).length) return this.formAttribute.setValue(v)
    if ((oldValues as string[])?.length && !!(v as string[]).length && this.originalLanguage === this.activeLanguage) newValues = v
    if ((oldValues as string[])?.length && !!(v as string[]).length && this.originalLanguage !== this.activeLanguage) newValues[this.originalLanguage] = oldValues
    if (this.containsLanguage(oldValues) && !!(v as string[]).length) newValues = oldValues
    if (!!(v as string[])?.length && this.originalLanguage !== this.activeLanguage) newValues[this.activeLanguage] = v
    else if (this.containsLanguage(v)) {
      for (const key in v as object) {
        if (v[key] && v[key] !== ''/*'MULTILINGUAL-FIELD.NO-TRANSLATION'*/) newValues[key] = v[key]
      }
    }

    // if the object only has one attribute, save just it's string
    newValues = (Object.keys(newValues).length > 1) ? newValues : newValues[Object.keys(newValues)[0]]
    this.inputField.nativeElement.value = ''
    this.formAttribute.setValue(newValues)
  }

  /**
   * Update translation values shown to the user
   */
  private updateI18nValues(newValues: I18NString) {
    if (newValues && typeof (newValues) === 'string' && typeof (this.i18nValues[this.activeLanguage]) !== 'string') {
      this.i18nValues[this.activeLanguage] ? this.i18nValues[this.activeLanguage].push(newValues) : this.i18nValues[this.activeLanguage] = [newValues]
    }
    else if (newValues && typeof (newValues) === 'string' && typeof (this.i18nValues[this.activeLanguage]) === 'string') {
      const originalValue = (this.i18nValues[this.activeLanguage] === ''/*'MULTILINGUAL-FIELD.NO-TRANSLATION'*/) ? '' : this.i18nValues[this.activeLanguage]
      this.i18nValues[this.activeLanguage] = [...originalValue, newValues]
    }
    else {
      for (const key in newValues as object) {
        if (newValues[key] !== this.i18nValues[key]) {
          let updatedValue = newValues[key].includes(',') ? newValues[key].split(',') : [newValues[key]]
          updatedValue = updatedValue.map((word) => this.titleCase((word || '').trim()))
          this.i18nValues[key] = updatedValue
        }
      }
    }
    this.updateFormAttribute(this.i18nValues)
  }

  /**
   * Starts loading spinner and passes input value to googleTranslate
   */
  public updateTranslation() {
    // this.isLoading = true
    // this.googleTranslate.translateText(this.formAttribute.value[this.activeLanguage]?.toString() ?? this.formAttribute.value?.toString(), true)
  }


}
