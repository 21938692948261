import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function controlIncludesStringFromArray(
  forbiddenArray: string[],
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const errorValue = { value: control.value }
    if (!control.value) return null // 'required' is a separate validator

    if (forbiddenArray.includes(control.value))
      return { forbiddenValue: errorValue }

    return null
  }
}
