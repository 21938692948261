import { Arzt } from './Arzt.entity';
import { Instance } from './Instance.entity';
import { Praxis } from './Praxis.entity';

export enum UserRole {
  Arzt = 'arzt',
  Instance = 'instance',
  Admin = 'admin'
}
export enum UserStatus {
  Registriert = 'registered',
  Verifiziert = 'verified',
  Freigeschaltet = 'accepted',
  Abgelehnt = 'denied',
  Wiederhergestellt = 'recovery',
  Gesperrt = 'blocked'
}

export enum UserActivationStatus {
  Ausstehend = 'ausstehend',
  Aktiviert = 'aktiviert',
  Deaktiviert = 'deaktiviert',
}

export interface UserRegistration {
  readonly email: string
  readonly password: string
  readonly arzt: Arzt
  readonly praxis?: Praxis

  readonly instanceIdentifier: string
  certificate?: string
  keystore?: string
  keystoreSalt?: string
  instanceKey?: string
}

export interface UserSecurity {
  readonly keystore: string
  readonly keystoreSalt: string
  readonly certificate?: string
}

export interface UserSettings {
  notificationVolume: number,
  waitRoomFilter?: WaitRoomFilter,
}

export interface UserUpdate extends User {
  readonly password?: string
  security?: UserSecurity
  readonly settings?: UserSettings
}

export interface WaitRoomFilter {
  formIds?: string[];
}

export interface UserQuestionnaire {
  readonly latestDialogRead: Date
  readonly dialogReadCounter: Number
  readonly latestQuestionnaireSent: Date
}

export interface UserStatusHistoryElement {
  readonly date: Date
  readonly status: UserStatus
}

export interface User {
  readonly _id: string
  readonly instance: Instance
  readonly email: string
  readonly createdAt: Date
  readonly roles: string[]
  // TODO readonly roles: UserRole[]
  status: UserStatus
  statusHistory?: UserStatusHistoryElement[]
  downloadedCryptoKeys: boolean
  acceptedAGB: boolean
  readonly certificate: string
  keystore: string
  keystoreSalt: string
  readonly vssId: string
  readonly arzt: Arzt
  readonly praxis?: Praxis

  readonly settings?: UserSettings;

  readonly latestChangelogRead: Date
  readonly questionnaire?: UserQuestionnaire

  readonly isArzt?: boolean
  readonly isAdmin?: boolean
  readonly isManager?: boolean
  readonly isInstance?: boolean
  readonly hasNoRole?: boolean

  readonly archiveId?: string
  readonly instanceKey?: string

  readonly registeredForMonthlyBilling?: boolean
}


