import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faArchive as falArchive, faArrowToBottom as falArrowToBottom,
  faBusinessTime as falBusinessTime,
  faCalendarCheck as falCalendarCheck, faCalendarLinesPen as falCalendarLinesPen, faCheckCircle as falCheckCircle, faCheckSquare as falCheckSquare, faClipboardCheck as falClipboardCheck, faCreditCard as falCreditCard,
  faDoorOpen as falDoorOpen,
  faEllipsisV as falEllipsisV, faEnvelopeOpenText as falEnvelopeOpenText, faExpand as falExpand,
  faFaceDisappointed as falFaceDisappointed, faFileAlt as falFileAlt, faFileArchive as falFileArchive, faFileAudio as falFileAudio, faFileCheck as falFileCheck, faFileExcel as falFileExcel, faFileImage as falFileImage, faFilePdf as falFilePdf, faFilePowerpoint as falFilePowerpoint, faFileSlash as falFileSlash, faFileSpreadsheet as falFileSpreadsheet, faFileTimes as falFileTimes, faFileVideo as falFileVideo, faFileWord as falFileWord,
  faHandshake as falHandshake,
  faInboxOut as falInboxOut, faInfoCircle as falInfoCircle,
  faLayerGroup as falLayerGroup, faLocationSlash as falLocationSlash, faLockAlt as falLockAlt,
  faMemoCircleCheck as falMemoCircleCheck,
  faPhone as falPhone, faPiggyBank as falPiggyBank,
  faShieldCheck as falShieldCheck, faSignal as falSignal, faSignalSlash as falSignalSlash,
  faTasks as falTasks, faTimes as falTimes, faTimesCircle as falTimesCircle, faTimesOctagon as falTimesOctagon,
  faUnlink as falUnlink, faUser as falUser, faUserCheck as falUserCheck, faUserClock as falUserClock, faUserCog as falUserCog, faUserEdit as falUserEdit, faUserHeadset as falUserHeadset, faUserMd as falUserMd, faUserSlash as falUserSlash,
  faUserTimes as falUserTimes,
  faUsersMedical as falUsersMedical,
  faVideoSlash as falVideoSlash,
  faWallet as falWallet, faWebcam as falWebcam, faWebcamSlash as falWebcamSlash, faWindow as falWindow
} from '@fortawesome/pro-light-svg-icons';
import {
  faAddressCard as farAddressCard, faAngleDown as farAngleDown, faAngleLeft as farAngleLeft, faAngleRight as farAngleRight, faArchive as farArchive, faArrowAltCircleRight as farArrowAltCircleRight, faArrowFromBottom as farArrowFromBottom, faArrowFromRight as farArrowFromRight, faArrowToBottom as farArrowToBottom, faArrowToLeft as farArrowToLeft,
  faBalanceScale as farBalanceScale, faBars as farBars, faBook as farBook, faBriefcaseMedical as farBriefcaseMedical,
  faCalendar as farCalendar, faCalendarAlt as farCalendarAlt, faCalendarCheck as farCalendarCheck, faCamera as farCamera, faChartLine as farChartLine, faChartPie as farChartPie, faCheck as farCheck, faCheckSquare as farCheckSquare, faChevronLeft as farChevronLeft, faClipboardList as farClipboardList, faClipboardUser as farClipboardUser, faClock as farClock, faCog as farCog, faCogs as farCogs, faCommentsDollar as farCommentsDollar, faCompress as farCompress, faCopy as farCopy,
  faDotCircle as farDotCircle,
  faEdit as farEdit, faEllipsisV as farEllipsisV, faEnvelope as farEnvelope, faExpand as farExpand, faExternalLink as farExternalLink, faEye as farEye, faEyeSlash as farEyeSlash,
  faFaceThinking as farFaceThinking, faFileAlt as farFileAlt, faFileArchive as farFileArchive, faFileExport as farFileExport, faFileInvoice as farFileInvoice, faFileInvoiceDollar as farFileInvoiceDollar, faFileMedical as farFileMedical, faFilePdf as farFilePdf, faFilePlus as farFilePlus,
  faGenderless as farGenderless, faGlobe as farGlobe, faGripLines as farGripLines, faGripLinesVertical as farGripLinesVertical,
  faInboxOut as farInboxOut,
  faKey as farKey,
  faLayerGroup as farLayerGroup, faLayerPlus as farLayerPlus, faLightbulb as farLightbulb, faLightbulbOn as farLightbulbOn, faLink as farLink, faList as farList, faListAlt as farListAlt, faLockAlt as farLockAlt,
  faMagic as farMagic, faMars as farMars, faMicrophone as farMicrophone, faMinus as farMinus, faMobileAlt as farMobileAlt, faMoneyCheck as farMoneyCheck,
  faPaperPlane as farPaperPlane, faPhone as farPhone, faPlayCircle as farPlayCircle, faPlus as farPlus, faPlusCircle as farPlusCircle,
  faQuestionCircle as farQuestionCircle,
  faRedoAlt as farRedoAlt,
  faSearch as farSearch, faShare as farShare, faShieldAlt as farShieldAlt, faShieldCheck as farShieldCheck, faSpinnerThird as farSpinnerThird, faSquare as farSquare,
  faTimes as farTimes, faTransgender as farTransgender, faTrashAlt as farTrashAlt,
  faUnlink as farUnlink, faUser as farUser, faUserAltSlash as farUserAltSlash, faUserChart as farUserChart, faUserCheck as farUserCheck, faUserCircle as farUserCircle, faUserCog as farUserCog, faUserEdit as farUserEdit, faUserFriends as farUserFriends, faUserPlus as farUserPlus,
  faUserSlash as farUserSlash, faUserTimes as farUserTimes,
  faUsers as farUsers, faUsersCog as farUsersCog,
  faVenus as farVenus, faVideo as farVideo, faVideoPlus as farVideoPlus,
  faWebcam as farWebcam, faWindow as farWindow, faWindowRestore as farWindowRestore
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAddressCard as fasAddressCard, faAngleLeft as fasAngleLeft, faAngleRight as fasAngleRight, faArrowAltCircleLeft as fasArrowAltCircleLeft, faArrowAltCircleRight as fasArrowAltCircleRight, faArrowAltCircleUp as fasArrowAltCircleUp, faArrowDown as fasArrowDown, faArrowFromBottom as fasArrowFromBottom,
  faBlockQuestion as fasBlockQuestion, faBook as fasBook, faBooks as fasBooks, faBuilding as fasBuilding,
  faCalendarDay as fasCalendarDay, faCalendarEdit as fasCalendarEdit, faCamera as fasCamera, faCheck as fasCheck, faCheckCircle as fasCheckCircle, faCheckDouble as fasCheckDouble, faChevronDoubleUp as fasChevronDoubleUp, faCircle as fasCircle, faCircleInfo as fasCircleInfo, faClock as fasClock, faCog as fasCog, faCogs as fasCogs, faColumns as fasColumns, faCommentsAlt as fasCommentsAlt, faCompress as fasCompress, faCompressArrowsAlt as fasCompressArrowsAlt, faCookieBite as fasCookieBite,
  faDiceOne as fasDiceOne, faDollarSign as fasDollarSign, faDollarSquare as fasDollarSquare,
  faEarthEurope as fasEarthEurope, faEllipsisV as fasEllipsisV, faEnvelope as fasEnvelope, faExchange as fasExchange, faExclamationCircle as fasExclamationCircle, faExclamationTriangle as fasExclamationTriangle, faExpand as fasExpand, faExternalLink as fasExternalLink,
  faFiles as fasFiles,
  faHammer as fasHammer, faHeartCrack as fasHeartCrack, faHomeAlt as fasHomeAlt, faIdCard as fasIdCard,
  faKey as fasKey,
  faLaptop as fasLaptop, faLightbulbOn as fasLightbulbOn, faLink as fasLink, faLinkHorizontal as fasLinkHorizontal, faLockAlt as fasLockAlt,
  faMapMarkerAlt as fasMapMarkerAlt, faMegaphone as fasMegaphone, faMemoCircleCheck as fasMemoCircleCheck, faMicrophone as fasMicrophone, faMicrophoneAlt as fasMicrophoneAlt, faMinusCircle as fasMinusCircle, faMusic as fasMusic,
  faPaintRoller as fasPaintRoller, faPaperPlane as fasPaperPlane, faPause as fasPause, faPencil as fasPencil, faPhone as fasPhone, faPizza as fasPizza, faPlay as fasPlay, faPlayCircle as fasPlayCircle, faPlug as fasPlug, faPlusCircle as fasPlusCircle, faPoo as fasPoo, faPowerOff as fasPowerOff,
  faQuestionCircle as fasQuestionCircle,
  faReceipt as fasReceipt, faRedo as fasRedo, faRedoAlt as fasRedoAlt,
  faSackDollar as fasSackDollar, faSave as fasSave, faShareAlt as fasShareAlt, faShieldCheck as fasShieldCheck, faSlidersH as fasSlidersH, faSlidersHSquare as fasSlidersHSquare, faSquareQuestion as fasSquareQuestion, faSquareUser as fasSquareUser, faStepBackward as fasStepBackward, faStopwatch as fasStopwatch, faSyncAlt as fasSyncAlt,
  faTimesCircle as fasTimesCircle, faTrash as fasTrash, faTrashAlt as fasTrashAlt, faTv as fasTv,
  faUnlink as fasUnlink, faUpload as fasUpload, faUser as fasUser, faUserCog as fasUserCog, faUserFriends as fasUserFriends, faUserHeadset as fasUserHeadset, faUserMd as fasUserMd, faUserMinus as fasUserMinus,
  faUserSecret as fasUserSecret, faUserSlash as fasUserSlash, faUserTimes as fasUserTimes,
  faUsers as fasUsers,
  faVideo as fasVideo, faVideoPlus as fasVideoPlus, faVideoSlash as fasVideoSlash, faVolume as fasVolume, faVolumeMute as fasVolumeMute, faVolumeUp as fasVolumeUp,
  faWebcam as fasWebcam, faWindow as fasWindow, faWindowRestore as fasWindowRestore
} from '@fortawesome/pro-solid-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule]
})
export class IconsModule {
  constructor(library: FaIconLibrary) {
    // Registering FontAwesome-Icons (https://github.com/FortAwesome/angular-fontawesome)
    library.addIcons(...[

      falArchive, falArrowToBottom,
      falBusinessTime,
      falCalendarCheck, falCalendarLinesPen, falCheckCircle, falClipboardCheck, falCreditCard, falCheckSquare,
      falDoorOpen,
      falEllipsisV, falEnvelopeOpenText, falExpand,
      falFaceDisappointed, falFileCheck, falFileAlt, falFileImage, falFileVideo, falFileAudio, falFilePdf, falFileArchive, falFileWord, falFileExcel, falFilePowerpoint, falFileSlash, falFileSpreadsheet, falFileTimes,
      falHandshake,
      falInboxOut, falInfoCircle,
      falLayerGroup, falLocationSlash, falLockAlt,
      falMemoCircleCheck,
      falPhone, falPiggyBank,
      falSignal, falSignalSlash,
      falTasks, falTimes, falTimesCircle, falTimesOctagon,
      falShieldCheck,
      falUnlink, falUser, falUserCheck, falUsersMedical, falUserMd, falUserSlash, falUserTimes, falUserClock, falUserEdit, falUserHeadset, falUserCog,
      falVideoSlash,
      falWallet, falWebcam, falWebcamSlash, falWindow,

      farAddressCard, farAngleLeft, farAngleRight, farAngleDown, farArchive, farArrowAltCircleRight, farArrowFromBottom, farArrowToBottom, farArrowFromRight, farArrowToLeft,
      farBalanceScale, farBars, farBook, farBriefcaseMedical,
      farCamera, farCalendar, farCalendarAlt, farCalendarCheck, farCheck, farChartLine, farChartPie, farUserChart, farCheckSquare, farChevronLeft, farClock, farCog, farCogs, farCommentsDollar, farCompress, farCopy, farClipboardUser, farClipboardList,
      farDotCircle,
      farEdit, farEllipsisV, farEnvelope, farExpand, farExternalLink, farEye, farEyeSlash,
      farFaceThinking, farFileAlt, farFileExport, farFileArchive, farFileInvoice, farFileInvoiceDollar, farFileMedical, farFilePdf, farFilePlus,
      farGenderless, farGripLinesVertical, farGripLines, farGlobe,
      farInboxOut,
      farKey,
      farLayerGroup, farLayerPlus, farLightbulb, farLightbulbOn, farLink, farList, farListAlt, farLockAlt,
      farMagic, farMars, farMicrophone, farMinus, farMobileAlt, farMoneyCheck,
      farRedoAlt,
      farPaperPlane, farPhone, farPlayCircle, farPlus, farPlusCircle,
      farQuestionCircle,
      farSearch, farShare, farShieldAlt, farShieldCheck, farSpinnerThird, farSquare,
      farTransgender, farTrashAlt, farTimes,
      farUserTimes, farUnlink, farUser, farUserAltSlash, farUserCircle, farUserCheck, farUserPlus, farUserCog, farUserEdit, farUserFriends, farUsers, farUsersCog, farUserSlash, fasUserMinus,
      farVenus, farVideo, farVideoPlus,
      farWebcam, farWindow, farWindowRestore,

      fasAddressCard, fasArrowAltCircleRight, fasArrowAltCircleLeft, fasArrowAltCircleUp, fasArrowDown, fasArrowFromBottom, fasAngleRight, fasAngleLeft,
      fasBlockQuestion, fasBook, fasBooks, fasBuilding,
      fasCalendarEdit, fasCalendarDay, fasCamera, fasCheck, fasCheckDouble, fasCheckCircle, fasChevronDoubleUp, fasCircle, fasCircleInfo, fasClock, fasCog, fasCogs, fasColumns, fasCommentsAlt, fasCompress, fasCompressArrowsAlt, fasCookieBite,
      fasDiceOne, fasDollarSign, fasDollarSquare,
      fasEarthEurope, fasEnvelope, fasEllipsisV, fasExchange, fasExclamationCircle, fasExclamationTriangle, fasExternalLink, fasExpand,
      fasFiles,
      fasHammer, fasHeartCrack, fasHomeAlt,
      fasIdCard,
      fasKey,
      fasLaptop, fasLightbulbOn, fasLightbulbOn, fasLink, fasLinkHorizontal, fasLockAlt,
      fasMapMarkerAlt, fasMegaphone, fasMemoCircleCheck, fasMicrophone, fasMicrophoneAlt, fasMinusCircle, fasMusic,
      fasPaintRoller, fasPaperPlane, fasPause, fasPencil, fasPhone, fasPizza, fasPlay, fasPlayCircle, fasPlug, fasPlusCircle, fasPoo, fasPowerOff,
      fasQuestionCircle,
      fasReceipt, fasRedo, fasRedoAlt,
      fasSackDollar, fasSave, fasShareAlt, fasShieldCheck, fasSlidersH, fasSlidersHSquare, fasStepBackward, fasStopwatch, fasSquareQuestion, fasSquareUser, fasSyncAlt,
      fasTimesCircle, fasTrash, fasTrashAlt, fasTv,
      fasUnlink, fasUser, fasUserCog, fasUserFriends, fasUserMd, fasUsers, fasUserHeadset, fasUpload, fasUserSecret, fasUserSlash, fasUserTimes,
      fasVideo, fasVideoPlus, fasVideoSlash, fasVolumeUp, fasVolumeMute, fasVolume,
      fasWebcam, fasWindow, fasWindowRestore,

    ])
  }
}
