
import { DayOfWeek, DayOfWeekToNumber } from "@a-d/entities/Calendar.entity";
import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";

// defines the birthdate format
export const customDateFormats = {
  parse: {
    dateInput: "DD.MM.YYYY",
  },
  display: {
    dateInput: "DD.MM.YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "DD.MM.YYYY",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

// a custom class for mat-calendar and mat-datepicker.
// determines the language, starting day of the week and the date formatting
// of both of the manual and the automatic selection of the birthday to DD.MM.YYYY
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

  FIRST_DAY_OF_THE_WEEK_DEFAULT: DayOfWeek = DayOfWeek.MONDAY
  public firstDayOfWeek: DayOfWeek = this.FIRST_DAY_OF_THE_WEEK_DEFAULT

  getFirstDayOfWeek(): number {
    return DayOfWeekToNumber[this.firstDayOfWeek.toUpperCase()]
  }

  // format dates
  private convertToDoubleDigit(n: number) {
    return ("00" + n).slice(-2);
  }
  parse(value: string | number): Date | null {
    if (typeof value === "string" && value.indexOf(".") > -1) {
      const str = value.split(".");
      return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
  format(date: Date): string {
    return (
      this.convertToDoubleDigit(date.getDate()) +
      "." +
      this.convertToDoubleDigit(date.getMonth() + 1) +
      "." +
      date.getFullYear()
    );
  }
}
