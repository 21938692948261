import { ScrollableSidebarIcon } from './../single-or-multilink/single-or-multilink.types'
import { ScrollableSidebarComponent } from './../../../utility-components/scrollable-sidebar/scrollable-sidebar.component'
import { setDocumentStyles } from '@arzt-direkt/colors'
import { NotificationService } from './../../../utility-components/notification/notification.service'
import { SingleOrMultilinkStore } from './../single-or-multilink/single-or-multilink.store'
import { RxLet } from '@rx-angular/template/let'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { ViewerFromLinkComponent } from './../viewer-from-link.component'
import { I18nPipe } from './../../../utility/i18n.pipe'
import { MatIconModule } from '@angular/material/icon'
import { ViewChild, Input, Component } from '@angular/core'
import {
  WfaFormWithResponse as FWR,
  getQuestions,
  wfaKeys,
} from '@arzt-direkt/wfa-definitions'
import {
  notNullish,
  nullish,
  Maybe,
  get,
  matchesProperty,
  set,
  omit,
} from '@arzt-direkt/wfa-generic-utils'
import { CommonModule } from '@angular/common'
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { first, take, distinctUntilChanged, distinct } from 'rxjs'
import { filter, firstValueFrom } from 'rxjs'
import { of, withLatestFrom } from 'rxjs'
import { WfaKey } from '@arzt-direkt/wfa-definitions'
import { createArrayIntersection } from '@arzt-direkt/wfa-generic-utils'
import { getVorbefuellungResponse } from './get-vorbefuellung-response'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-multilink-viewer',
  imports: [
    CommonModule,
    I18nPipe,
    MatIconModule,
    MatMenuModule,
    RxLet,
    ScrollableSidebarComponent,
    TranslateModule,
    ViewerFromLinkComponent,
  ],
  styleUrl: './multilink-viewer.component.scss',
  templateUrl: './multilink-viewer.component.html',
})
export class MultilinkViewerComponent {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined

  constructor(
    public notificationService: NotificationService,
    public store: SingleOrMultilinkStore,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    setDocumentStyles(document)
    this.store.fwrs$
      .pipe(filter(notNullish), first(), untilDestroyed(this))
      .subscribe((fwrs: Maybe<FWR[]>) => {
        const defaultForm = get(fwrs, '0')
        this.store.setFwr(defaultForm)
      })
  }

  selectForm(fwr: FWR): void {
    of(fwr) //
      .pipe(withLatestFrom(this.store.fwrs$), take(1), untilDestroyed(this))
      .subscribe(([selectedFWR, fwrs]) => {
        if (nullish(fwrs)) return

        const vorbefuellungResponse = getVorbefuellungResponse(
          selectedFWR,
          fwrs,
        )
        this.store.setSurveyjsResponse(vorbefuellungResponse)
      })
  }
}
