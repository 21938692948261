import { ActiveInstance } from '@a-d/entities/ActiveInstance.entity';
import { InstanceForm } from '@a-d/entities/InstanceForm.entity';
import { FormHelpers } from '@a-d/forms/form-helpers.service';
import { InstanceService } from '@a-d/instance/instance.service';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '@env/environment';
import { UsefulComponent } from '../../../misc/useful.component';
import { InstanceFormInterface } from '../../instance-form-component.interface';
import { InstanceFormService } from '../../instance-form.service';
import { AnamneseForm } from '../anamnese-forms.service';
import { SanitizeHtmlPipe } from '../../../misc/sanitize.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatRadioModule } from '@angular/material/radio';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectionFieldComponent } from '../../../forms/fields/selection-field.component';


export interface CoronaAnamneseFormSettings {
  readonly coronaSymptomOptions: string[]
}


@Component({
    selector: 'app-corona-anamnese-form',
    templateUrl: './corona-anamnese-form.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        SelectionFieldComponent,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        MatRadioModule,
        TranslateModule,
        SanitizeHtmlPipe,
    ],
})
export class CoronaAnamneseFormComponent extends UsefulComponent implements OnInit, InstanceFormInterface {

  @Input() anamneseForm: AnamneseForm
  @Input() settings: CoronaAnamneseFormSettings


  /**
   * Form-Group
   */
  public formGroup = new UntypedFormGroup({
    coronaSymptomsSelection: new UntypedFormControl([], []),
    coronaSymptomsDescription: new UntypedFormControl('', []),
    coronaSymptomsHistory: new UntypedFormControl('', []),
    coronaLungDisease: new UntypedFormControl('', []),
    coronaInfectedPersonContact: new UntypedFormControl('', []),
  })

  public get coronaSymptomsSelection(): AbstractControl { return this.formGroup.get('coronaSymptomsSelection') }
  public get coronaSymptomsDescription(): AbstractControl { return this.formGroup.get('coronaSymptomsDescription') }
  public get coronaSymptomsHistory(): AbstractControl { return this.formGroup.get('coronaSymptomsHistory') }
  public get coronaLungDisease(): AbstractControl { return this.formGroup.get('coronaLungDisease') }
  public get coronaInfectedPersonContact(): AbstractControl { return this.formGroup.get('coronaInfectedPersonContact') }


  /**
    * Current Instance & Instance-Form Helpers
   */
  public get activeInstance(): ActiveInstance { return this.instanceService.activeInstance }
  public get activeInstanceForm(): InstanceForm { return this.instanceFormService.activeInstanceForm }
  public readonly isDemoMode = environment.demoMode


  constructor(
    private instanceService: InstanceService,
    private instanceFormService: InstanceFormService,
    private formHelpers: FormHelpers,
    private cd: ChangeDetectorRef,
  ) {
    super()
    this.formHelpers.syncActiveAnamneseFormWithLocalStorage(this.activeInstanceForm, this.formGroup, this.unsubscribe$)
  }

  ngOnInit() {
    super.ngOnInit()
  }

  onInit() {
    console.log(this.settings)
  }



  // ---------------------//
  // InstanceFormInterface //
  // ---------------------//


  public getData(): any {
    return this.formHelpers.trimmedRawValue(this.formGroup)
  }

  public magicFill() {
    if (!this.isDemoMode) return

    // TODO

    this.cd.detectChanges()
  }

}
