import { BmpString } from "asn1js"
import { AttributeTypeAndValue } from "pkijs"
import { CertValues } from '../../../entities/CertValues.entity';

/**
 * set attributes and values for certificate issuer or subject
 * @param typesAndValues target array to hold standard attributes
 * @param values target attribute values
 */
export function setTypesAndValues(
  typesAndValues: AttributeTypeAndValue[],
  values: CertValues,
): AttributeTypeAndValue[] {
  typesAndValues.push(
    new AttributeTypeAndValue({
      type: "2.5.4.6", // country
      value: new BmpString({ value: values.country }),
    }),
  )
  typesAndValues.push(
    new AttributeTypeAndValue({
      type: "2.5.4.3", // common name
      value: new BmpString({ value: values.commonName }),
    }),
  )
  typesAndValues.push(
    new AttributeTypeAndValue({
      type: "2.5.4.10", // organization
      value: new BmpString({ value: values.organization }),
    }),
  )
  typesAndValues.push(
    new AttributeTypeAndValue({
      type: "2.5.4.7", // city locality
      value: new BmpString({ value: values.city }),
    }),
  )
  typesAndValues.push(
    new AttributeTypeAndValue({
      type: "2.5.4.9", // address
      value: new BmpString({ value: values.address }),
    }),
  )
  typesAndValues.push(
    new AttributeTypeAndValue({
      type: "2.5.4.17", // postal code
      value: new BmpString({ value: values.postalCode }),
    }),
  )
  return typesAndValues
}
