import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidationHelpersService {

  constructor() { }


  /**
   * Sets given validators on the given field(s) and optionally also enables the field.
   */
  public setValidators(fields: AbstractControl | AbstractControl[], validators: ValidatorFn | ValidatorFn[], alsoEnable: boolean = false, emitEvents: boolean = false) {
    if (!validators) validators = []
    if (!Array.isArray(fields)) fields = [fields]

    for (const field of fields) {
      field.setValidators(validators)
      if (alsoEnable) {
        field.enable({ emitEvent: emitEvents })
      }
    }
  }


  /**
   * Clears the validators on the given field(s) and optionally also disables the field.
   */
  public clearValidators(fields: AbstractControl | AbstractControl[], alsoDisable: boolean = false, emitEvents: boolean = false) {
    if (!Array.isArray(fields)) fields = [fields]

    for (const field of fields) {
      field.clearValidators()
      if (alsoDisable) {
        field.disable({ emitEvent: emitEvents })
      }
    }
  }


  /**
   * Sets given validators on given field(s) depending on given condition.
   * And optionally also sets the status (enabled/disabled) on those fields.
   */
  public setValidatorsIf(fields: AbstractControl | AbstractControl[], condition: boolean, trueValidators: ValidatorFn | ValidatorFn[], falseValidators: ValidatorFn | ValidatorFn[] = [], alsoSetStatus: boolean = false, emitEvents: boolean = false) {
    if (!trueValidators) trueValidators = []
    if (!falseValidators) falseValidators = []
    if (!Array.isArray(fields)) fields = [fields]

    for (const field of fields) {
      field.setValidators(!!condition ? trueValidators : falseValidators)
    }
    if (alsoSetStatus) {
      this.setStatusIf(fields, !!condition, emitEvents)
    }
  }


  /**
   * Sets status (enabled/disabled) on given field(s) depending on given condition.
   */
  public setStatusIf(fields: AbstractControl | AbstractControl[], condition: boolean, emitEvents: boolean = false) {
    if (!Array.isArray(fields)) fields = [fields]

    for (const field of fields) {
      if (!!condition) {
        field.enable({ emitEvent: emitEvents })
      } else {
        field.disable({ emitEvent: emitEvents })
      }
    }
  }


  /**
   * Updates value and validity of given field(s).
   */
  public updateValueAndValidity(fields: AbstractControl | AbstractControl[], emitEvents: boolean = false) {
    if (!Array.isArray(fields)) fields = [fields]

    for (const field of fields) {
      field.updateValueAndValidity({ emitEvent: emitEvents })
    }
  }

}
