import { nullish } from '@arzt-direkt/wfa-generic-utils'

export function mergeObjects(
  ...objects: Record<string, unknown>[]
): Record<string, unknown> {
  const result: Record<string, unknown> = {}
  objects.forEach((obj) => addObjectToResult(obj, result))
  return result
}

function addObjectToResult(
  obj: Record<string, unknown>,
  result: Record<string, unknown>,
) {
  Object.entries(obj).forEach(([key, value]) => {
    if (nullish(result[key])) result[key] = []
    ;(result[key] as unknown[]).push(value)
  })
}
