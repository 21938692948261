import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AnimationClassHelperService } from '@lib/misc/animation-class-helper.service';
import { interval, of } from 'rxjs';
import { mergeMap, startWith, takeUntil } from 'rxjs/operators';
import 'webrtc-adapter';
import { SocketUserRole } from '../entities/Socket.entity';
import { Origin } from '../entities/WebRTC.entity';
import { LanguageService } from '../i18n/language.service';
import { BrowserSupportService } from '../misc/browser-support/browser-support.service';
import { ImageHelpersService, ImageScaleMode } from '../misc/image-helpers.service';
import { UsefulComponent } from '../misc/useful.component';
import { DataTransferService } from '../web-rtc/dataTransfer.service';
import { Snapshot, SnapshotService } from '../web-rtc/snapshot.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-camera-snapshot',
    templateUrl: './camera-snapshot.component.html',
    styleUrls: ['./camera-snapshot.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatMenuModule,
        MatIconModule,
        NgFor,
        MatProgressSpinnerModule,
        FontAwesomeModule,
        MatButtonModule,
        TranslateModule,
    ],
})
export class CameraSnapshotComponent extends UsefulComponent implements OnInit, OnDestroy {

  @ViewChild('shutterOverlayEl', { static: true, read: ElementRef }) shutterOverlayEl: ElementRef
  @ViewChild('deletedSnapshotEl', { static: true, read: ElementRef }) deletedSnapshotEl: ElementRef
  @ViewChild('videoEl', { static: true, read: ElementRef }) videoEl: ElementRef
  @ViewChild('canvasEl', { static: true, read: ElementRef }) canvasEl: ElementRef

  public video: HTMLVideoElement
  public canvas: HTMLCanvasElement

  @Input() snapshotType: string = 'image/jpeg'
  @Input() snapshotQuality: number = .7
  @Input() snapshot: string
  @Output() snapshotChange = new EventEmitter<string>()

  @Input() isInTakeSnapshot: boolean = false;
  @Input() rescaleImage: boolean = false
  @Input() imageScaleSize: [number, number] = [1280, 1280]
  @Input() imageScaleMode: ImageScaleMode = ImageScaleMode.Contain
  @Input() imageDoUpscale: boolean = false
  @Input() overlayImagePath: string

  public browserIsSupported = true
  public isPlaying = false
  public isError = false
  public mediaDevices: MediaDeviceInfo[] = []
  public mediaDeviceLabels: { [_: string]: string } = {}

  private readonly BASE_MEDIA_CONSTRAINTS: MediaStreamConstraints = {
    audio: false,
    video: {
      width: { min: 320, ideal: 1280 },
      height: { min: 240, ideal: 720 },
      aspectRatio: { ideal: 16 / 9 },
      frameRate: { max: 30 },
      facingMode: 'user',
    }
  }

  constructor(
    private adLoggerService: AdLoggerService,
    private animationClassHelperService: AnimationClassHelperService,
    private browserSupportService: BrowserSupportService,
    private imageHelpers: ImageHelpersService,
    private snapshotService: SnapshotService,
    private languageService: LanguageService,
    private dataTransferService: DataTransferService,
  ) {
    super()
  }

  ngOnInit() {
    super.ngOnInit()
    // Check for browser-support
    this.browserIsSupported = 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices
    if (!this.browserIsSupported) return

    // Get video- and canvas-elements
    if (this.videoEl) this.video = this.videoEl.nativeElement
    if (this.canvasEl) this.canvas = this.canvasEl.nativeElement
    if (!this.video || !this.canvas) return

    // Gather devices periodically
    interval(500).pipe(
      startWith(0),
      takeUntil(this.unsubscribe$)
    ).subscribe((_) => {
      this.updateMediaDevices()
    })

    // Start stream (without preferred device, first)
    if (!this.snapshot) {
      this.startStream()
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    this.stopStream()
  }

  ngOnChanges(changes: SimpleChanges) {
    const previousSnapshot = changes?.['snapshot']?.previousValue
    const currentSnapshot = changes?.['snapshot']?.currentValue
    if (previousSnapshot && !currentSnapshot) {
      this.animateDeletedSnapshot(previousSnapshot)
      if (!this.isPlaying) {
        this.startStream()
      }
    }
  }

  /**
   * Gets media-devices with video-abilities
   */
  private updateMediaDevices() {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      this.mediaDevices = (devices || [])
        .filter((device) => {
          return device && device.deviceId && (device.kind || '').includes('video')
        })
      this.mediaDeviceLabels = this.mediaDevices.reduce((prev, curr) => ({
        ...prev,
        [curr.deviceId]: (curr.label || 'Kamera').replace(/ *\([^)]*\) */g, "")
      }), {})
    })
  }

  /**
   * securely start the camera stream
   */
  public startStream(preferredDevice?: MediaDeviceInfo) {
    // console.log("constraints: ", navigator.mediaDevices.getSupportedConstraints())

    if (this.isPlaying) this.stopStream()
    if (!navigator.mediaDevices) {
      this.isError = true
      return
    }

    // Build constraints depeneding on given `preferredDevice`
    const constraints = Object.assign({}, this.BASE_MEDIA_CONSTRAINTS)
    if (preferredDevice && preferredDevice.deviceId) {
      constraints.video['deviceId'] = {
        exact: preferredDevice.deviceId,
      }
    }

    this.isError = false
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      if (!this.video) {
        this.adLoggerService.error('Video-Element does not exist')
        return
      }

      this.video.onloadedmetadata = (_) => {
        this.video.controls = false
        this.video.play().then(() => {
          console.log('Video-Stream started')
          this.isPlaying = true
          window.dispatchEvent(new Event('resize'))  // Fixing Safari Viewport-Height Issue
        })
      }

      try {
        this.video.srcObject = stream;
      } catch (error) {
        this.adLoggerService.error('Media-Stream Error: ' + error)
        this.video.src = URL.createObjectURL(stream as any)
      }
    })
      .catch(error => {
        this.adLoggerService.error("Error while getting media-devices:", error)
        this.isError = true
      })
  }

  /**
   * stop video tracks and remove the stream from the video source
   */
  public stopStream() {
    if (this.video && this.video.srcObject) {
      const stream = <MediaStream>this.video.srcObject
      const tracks = stream.getTracks()
      for (const track of tracks) track.stop()
      this.video.srcObject = null
    }

    this.isPlaying = false
  }

  /**
   * capture a single video frame as a base64 data string
   */
  public takeSnapshot() {
    if (!this.video || !this.video.srcObject) return
    const startTime = new Date().getTime();
    const doPerformRescale = this.rescaleImage && (this.canvas.width > this.imageScaleSize[0] || this.canvas.height > this.imageScaleSize[1] || this.imageDoUpscale)
    this.isInTakeSnapshot = true;
    this.video.pause()
    const origin: Origin = Origin.Snapshot
    const senderRole: SocketUserRole = null
    const capturedRole: SocketUserRole = SocketUserRole.Patient

    this.snapshotService.takeSnapshot(this.video, origin, senderRole, capturedRole).pipe(mergeMap(
      (snapshot: Snapshot) => {
        if (doPerformRescale) {
          this.imageHelpers.scaleBase64Image(snapshot.imageUri, this.imageScaleSize,
            this.imageScaleMode, this.imageDoUpscale)
            .subscribe(
              (imageUri: string) => {
                const upscaledSnapshot: Snapshot = {
                  ...snapshot,
                  imageUri,
                }
                return of(upscaledSnapshot)
              })
        }
        else return of(snapshot)
      })
    )
      .subscribe((snapshot: Snapshot) => {
        this.snapshot = snapshot.imageUri
        this.isInTakeSnapshot = false;
        this.snapshotChange.next(this.snapshot)
        this.animationClassHelperService.add(this.shutterOverlayEl, 'shutter-overlay--performAnimation')
        const endTime = new Date().getTime();
        console.log("takeSnapshot took a snapshot  ", snapshot, " in " + String(endTime - startTime) + "ms")

      })
  }

  /**
   * Fades deleted snapshot out to bottom.
   */
  private animateDeletedSnapshot(deletedSnapshot: string) {
    const nativeElement = this.deletedSnapshotEl.nativeElement as HTMLImageElement
    nativeElement.src = deletedSnapshot
    this.animationClassHelperService.add(this.deletedSnapshotEl, 'deleted-snapshot--performAnimation', () => {
      nativeElement.src = ''
    })
  }

  /**
   * Opens a custom duckduckgo search for setting up camera with browser/os.
   */
  public openCameraHelpPage() {
    const browserEncoded = encodeURI(this.browserSupportService.browser.getBrowserName())
    const osEncoded = encodeURI(this.browserSupportService.osName)
    let searchTerm: string
    switch (this.languageService.activeBaseLang) {
      // instead of spaces, use '+'
      case 'en':
        searchTerm = 'Camera+Permissions'
        break
      default:
        searchTerm = 'Kamera+Berechtigungen'
    }
    console.log(searchTerm, this.languageService.activeBaseLang)
    const url = `https://duckduckgo.com/?q=${searchTerm}+${browserEncoded}+${osEncoded}`
    window.open(url, '_blank')
  }

  public deleteSnapshot() {
    this.video.play()
    this.snapshot = null
    this.video.hidden = false
    console.log("camera-snapshot.component deleteSnapshot")
  }
}
