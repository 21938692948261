import countriesDe from './json/countries.de.json';
import countriesEn from './json/countries.en.json';
import { ReiseSettings } from './reise-settings.entity';
import { ReiseComponent } from './reise.component';

export const reiseForm = {
  name: 'ZRM Reisefragebogen',
  identifier: 'zrm-reisefragebogen',
  component: ReiseComponent,
  defaultMedicalSpecializationId: '1',
  defaultSettings: {
    destinations: {
      shortTitle: {
        de: 'Reiseziel',
        en: 'Destination',
      },
    },
    wohinSelection: {
      title: {
        de: 'Wohin reisen Sie?',
        en: 'What is your travel destination?',
      },
      options: {
        de: countriesDe as string[],
        en: countriesEn as string[],
      },
    },
    travelDates: {
      title: {
        de: 'Reise von',
        en: 'Trip start',
      },
      title2: {
        de: 'bis',
        en: 'end',
      },
      startPlaceholder: {
        de: 'Von',
        en: 'Start date',
      },
      endPlaceholder: {
        de: 'Bis (optional)',
        en: 'End date (optional)',
      },
      error: {
        required: {
          de: 'Bitte Startdatum eingeben',
          en: 'Please provide the starting date',
        },
        future: {
          de: 'Das Startdatum muss in der Zukunft liegen',
          en: 'Start date has to be in the future',
        },
        end: {
          de: 'Das Enddatum muss nach dem Startdatum liegen',
          en: 'End date has to be after the start date',
        },
      },
    },
    duration: {
      title: {
        de: 'Reisedauer',
        en: 'Travel duration',
      },
    },
    arrivalDescription: {
      title: {
        de: 'Wie erfolgt die Anreise?',
        en: 'Arrival details',
      },
      placeholder: {
        de: 'Z.B. Transit über Arusha nach Sansibar',
        en: 'E.g. transit via Arusha to Zanzibar',
      },
      error: {
        tooLong: {
          de: 'Zeichenlimit überschritten (max. 240 Zeichen)',
          en: 'Allowed entry length exceeded (max. 240 characters)',
        },
      },
    },
    addButton: {
      de: 'Weiteres Reiseziel hinzufügen',
      en: 'Add another destination',
    },
    tripStyle: {
      title: {
        de: 'Hauptreisestil',
        en: 'Travel class',
      },
      options: {
        de: [
          'Low-Budget / Rucksack / Tramper',
          'Mittelklasse',
          'Luxus (4-5 Sterne)',
        ],
        en: [
          'Low-budget / Backpacking / Tramper',
          'Middle class',
          'Luxury (4-5 star)',
        ],
      },
    },
    purpose: {
      title: {
        de: 'Hauptreisezweck',
        en: 'Main travel purpose',
      },
      options: {
        de: [
          'Tourismus',
          'Geschäftsreise',
          'Besuch von Bekannten oder Familie',
          'Studium',
          'Humanitäre Hilfe (Flüchtlingscamps, andere)',
          'Beruflicher Kontakt mit lokaler Bevölkerung (z.B. Arbeit / Praktikum in der Schule)',
        ],
        en: [
          'Tourism',
          'Business',
          'Visiting friends or family',
          'Study',
          'Humanitarian aid (refugee camps, other)',
          'Work involving contact with local population (school practica etc.)',
        ],
      },
      customOption: {
        title: {
          de: 'Sonstiger Reisezweck',
          en: 'Other travel purpose',
        },
        subtitle: {
          de: 'Bitte beschreiben Sie Ihren Reisezweck',
          en: 'Please describe the purpose of your travel',
        },
      },
    },
    activities: {
      title: {
        de: 'Reiseaktivitäten',
        en: 'Travel activities',
      },
      options: {
        de: [
          'Autofahren',
          'Backpacking',
          'Kontakt zu Tieren',
          'Kreuzfahrt',
          'Motorradfahren',
          'Pilgerreise',
          'Reise in ländliche/abgelegene Regionen, Gebiete mit schlechter Infrastruktur',
          'Reise ins Hochgebirge',
          'Safari',
          'Strandferien',
          'Städtereise',
          'Tauchen',
          'Trekking',
          'Velofahren',
          'Zelten',
        ],
        en: [
          'Backpacking',
          'Beach vacation',
          'Biking',
          'Camping',
          'Car trip',
          'Cruise',
          'Motorbike trip',
          'Mountaineering',
          'Pilgrimage',
          'Remote destination travel, travel to regions with bad infrastructure',
          'Safari',
          'Scuba diving',
          'Trekking',
          'Visiting cities',
          'Working with animals',
        ],
      },
      customOption: {
        title: {
          de: 'Sonstige Reiseaktivität',
          en: 'Other travel activity',
        },
        subtitle: {
          de: 'Bitte beschreiben Sie Ihre Reiseaktivität',
          en: 'Please describe your travel activities',
        },
      },
    },
    other: {
      title: {
        de: 'Zusätzliche Information',
        en: 'Additional information',
      },
    },
  } as unknown as ReiseSettings,
  fieldTitles: {
    destinations: 'Reiseziele',
    tripStyle: 'Reisestil',
    purpose: 'Reisezweck',
    activities: 'Reiseaktivitäten',
    other: 'Sonstiges',
  },
};
