import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'

import { assign } from '../../utility/assign'
import {
  initialViewerFromLinkState,
  ViewerFromLinkState,
} from './viewer-from-link.state'

@Injectable()
export class ViewerFromLinkStore extends ComponentStore<ViewerFromLinkState> {
  constructor() {
    super(initialViewerFromLinkState)
  }

  readonly env$ = this.select(({ env }) => env)
  readonly form$ = this.select(({ form }) => form)
  readonly formResponse$ = this.select(({ formResponse }) => formResponse)

  readonly setEnv = this.updater(assign('env'))
  readonly setForm = this.updater(assign('form'))
  readonly setFormResponse = this.updater(assign('formResponse'))
}
