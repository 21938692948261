import { MatDialog } from '@angular/material/dialog'
import {
  PraxisId,
  updateMetadata,
  validateWfaForm,
  WfaForm,
} from '@arzt-direkt/wfa-definitions'
import { isWfaMessage, Maybe, nullish } from '@arzt-direkt/wfa-generic-utils'

import { openUnlockFormUploadDialog } from './upload-error.component'

/**
 * mutates wfaForm
 */
export function maybeImportWfaForm(
  event: any,
  matDialog: MatDialog,
  praxisId: Maybe<PraxisId>,
  wfaForm: Maybe<WfaForm>,
  callback: (_wfaForm: Maybe<WfaForm>) => void, // perform after successfull import
) {
  if (nullish(praxisId)) {
    console.error('[importJson]: praxisId is undefined')
    return undefined
  }

  const file: File = event?.target?.files[0]
  const reader = new FileReader()

  reader.onload = (_e: any) => {
    if (typeof reader.result === 'string') {
      const withoutMetadata = {
        ...JSON.parse(reader.result),
        praxisId,
        instance: praxisId,
      }
      const maybeForm = updateMetadata(withoutMetadata)
      const form = validateWfaForm(maybeForm)
      if (isWfaMessage(form)) {
        openUnlockFormUploadDialog(matDialog, form)
        console.debug(
          `[importJson]: could not validate uploaded form, got error: ${form.message}`,
          maybeForm,
        )
        wfaForm = undefined
        return
      }

      const { permalink: _, ...withoutPermalink } = form as WfaForm
      wfaForm = withoutPermalink
      callback(wfaForm)
      return
    }
    wfaForm = undefined
    return
  }

  reader.readAsText(file)
}
