import { Injectable, OnDestroy } from '@angular/core';
import { fromBase64, stringToArrayBuffer } from 'pvutils';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CryptoService } from '../crypto/crypto.service';
import { PatientSessionDataEncrypted, PatientSessionDataUnencrypted } from '../entities/PatientSessionCreation.entity';


@Injectable({
  providedIn: 'root'
})
export class PatientSessionEncryptionService implements OnDestroy {

  private unsubscribe$ = new Subject()

  constructor(
    private cryptoService: CryptoService,
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }


  /**
   * Encrypts the given data with the given certificate and encrypts assets separately.
   */
  public encryptSession(certBase64: string, data: PatientSessionDataUnencrypted): Observable<PatientSessionDataEncrypted> {
    if (!certBase64) throw { name: 'NoCertificateForEncryption' }
    const certBuffer = stringToArrayBuffer(fromBase64(certBase64))
    // Encrypt assets separately
    const assets = {
      assets: data.assets
    }
    delete (<any>data).assets

    return forkJoin([
      this.cryptoService.encrypt(certBuffer, JSON.stringify(data)),
      this.cryptoService.encrypt(certBuffer, JSON.stringify(assets)),
    ]).pipe(
      map(([encryptedData, encryptedAssets]: [string, string]) => ({
        encryptedData,
        encryptedAssets
      })),
      takeUntil(this.unsubscribe$)
    )
  }

}
