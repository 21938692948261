import { I18NString, I18NString_MISSING_VALUE } from '@a-d/entities/Booking.entity';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { CookiesService, SupportCookieNames } from '../dsgvo/cookies.service';
import { ActiveInstance } from '../entities/ActiveInstance.entity';
import { I18NHtmlString } from '../entities/Booking.entity';
import { BaseLanguage } from '../entities/I18N.entity';
import { Dialect, Instance } from '../entities/Instance.entity';
import { InstanceService } from '../instance/instance.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public readonly ALL_BASE_LANGAUGES: BaseLanguage[] = Object.keys(BaseLanguage).map(key => BaseLanguage[key])
  public readonly DEFAULT_BASE_LANG: BaseLanguage = BaseLanguage.German
  public readonly DEFAULT_BASE_LANGUAGES: BaseLanguage[] = [BaseLanguage.German, BaseLanguage.English]

  private _activeBaseLang: BaseLanguage
  public get activeBaseLang(): BaseLanguage { return this._activeBaseLang }

  private _activeDialect: Dialect
  public get activeDialect(): Dialect { return this._activeDialect }

  private _activeLang: string
  public get activeLang(): string { return this._activeLang }
  public shopperLocale = 'de_DE'

  public languageChangeSubject$ = new BehaviorSubject<string>(this.activeLang) // z.B. "de--mitarbeiter"
  public shopperLocaleChangeSubject$ = new BehaviorSubject<string>(this.shopperLocale) // z.B. "de--mitarbeiter"
  public baseLanguageChangeSubject$ = new BehaviorSubject<BaseLanguage>(this.activeBaseLang) // z.B. "de"

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private instanceService: InstanceService,
    private cookiesService: CookiesService,
    private domSanitizer: DomSanitizer
  ) {
    // Set default language
    this.translateService.setDefaultLang(this.DEFAULT_BASE_LANG)

    // Update language when logged-in user changes
    this.authService.userUpdated$.pipe(
      startWith(0),
      untilDestroyed(this),
    ).subscribe(() => {
      if (!!this.authService.user) {
        // Check if user is logged in and use his/her langauge if yes
        const userLanguage = this.authService.user?.settings?.language || this.DEFAULT_BASE_LANG
        this.changeBaseLanguage(userLanguage)

      } else {
        // Determine active base language with priority:
        // stored user-language > chosen/cached language > default browser language > default app language
        const cachedBaseLang: BaseLanguage = this.cookiesService.get(SupportCookieNames.language) === BaseLanguage.English ? BaseLanguage.English : BaseLanguage.German
        const defaultBrowserLang = this.translateService.getBrowserLang() === BaseLanguage.English ? BaseLanguage.English : BaseLanguage.German
        this.changeBaseLanguage(cachedBaseLang || defaultBrowserLang)
      }
    })

    // Update language when instance changes
    this.instanceService.activeInstanceChanged$.pipe(
      startWith(0),
      untilDestroyed(this),
    ).subscribe(() => {
      if (!this.authService.user?.isInstance && !this.authService.user?.isArzt) this.setLanguageForInstance(this.instanceService.activeInstance) // dont set Language if user is instance // or if user is doctor (changes instance e.g. for status message)
    })
  }


  public setLanguageForInstance(instance: Instance | ActiveInstance) {
    if (instance?.settings?.general?.internationalization?.baseLanguageDefault)
      this._activeBaseLang = instance.settings.general.internationalization?.baseLanguageDefault
    this._activeDialect = instance?.dialect || null
    this.updateActiveLang()
  }

  public setActiveDialect(dialect: Dialect) {
    this._activeDialect = dialect
    this.updateActiveLang()
  }

  public changeBaseLanguage(baseLang: BaseLanguage) {
    if (!this.ALL_BASE_LANGAUGES.includes(baseLang)) { return }
    this._activeBaseLang = baseLang || this.DEFAULT_BASE_LANG
    this.updateActiveLang()
    this.baseLanguageChangeSubject$.next(this._activeBaseLang)
    this.updateShopperLocale(this._activeBaseLang);

    // Cache value as a cookie if `doCache` is true
    this.cookiesService.set(SupportCookieNames.language, this._activeBaseLang)
  }


  private updateActiveLang() {
    const newActiveLang = !!this._activeDialect
      ? `${this._activeBaseLang}--${this._activeDialect}`
      : this._activeBaseLang

    if (newActiveLang === this._activeLang) return

    this._activeLang = newActiveLang

    this.translateService.setDefaultLang(this._activeBaseLang)
    this.translateService.use(this._activeLang)
    this.languageChangeSubject$.next(newActiveLang)
  }

  private updateShopperLocale(language: BaseLanguage) {
    switch (language) {
      case BaseLanguage.English:
        this.shopperLocale = 'en_US';
        break;
      case BaseLanguage.German:
        this.shopperLocale = 'de_DE';
        break;
      case BaseLanguage.French:
        this.shopperLocale = 'fr_FR';
        break;
      case BaseLanguage.Italian:
        this.shopperLocale = 'it_IT';
        break;
      case BaseLanguage.Spanish:
        this.shopperLocale = 'es_ES';
        break;
    }
    this.shopperLocaleChangeSubject$.next(this.shopperLocale);
  }

  /**
   * used for displaying I18NStrings in frontend.
   * Returns the string contained in i18NString for the active language
   * or for the default active language as fallback
   * or a default "No translation available" string as fallback
   */
  i18NStringToString(i18NString: I18NString) {
    const noTranslationAvailable: string = I18NString_MISSING_VALUE
    if (!i18NString)
      return noTranslationAvailable
    if (i18NString[this.activeBaseLang] && i18NString[this.activeBaseLang] !== I18NString_MISSING_VALUE)
      return i18NString[this.activeBaseLang]
    if (i18NString[this.DEFAULT_BASE_LANG] && i18NString[this.DEFAULT_BASE_LANG] !== I18NString_MISSING_VALUE)
      return i18NString[this.DEFAULT_BASE_LANG]
    return noTranslationAvailable
  }


  i18NStringSafeHtml(i18NString: I18NString): I18NHtmlString {
    Object.keys(i18NString).forEach((key) => i18NString[key] = this.domSanitizer.bypassSecurityTrustHtml(i18NString[key]))
    return i18NString
  }

}
