import Joi from 'joi'

/**
 * This is a manual attempt to emulate mongoose.Types.ObjectId, which is the same
 * as bson's ObjectID specified in arzt-direkt/node_modules/bson/src/objectid.ts.
 * 
 * Since the bson definition of ObjectId is rather involved, this schema is rather
 * limited.  As far as tsc is concerned, the resulting Id ts type seems to be
 * completely compatible with mongoose.Types.ObjectId.
 */
export const ObjectIdUntypedJoi = Joi.alternatives(
  Joi.string().length(24).hex(),
  Joi.object().keys({
    id: Joi.any(),
    _bsontype: Joi.allow('ObjectID'),
  }),
)
  .description(
    'The object id used in Mongoose and MongoDB, it emulates mongoose.Types.ObjectId \n' +
          'and hence bson\'s ObjectID. This is a common topic for confusion,\n' +
          'see e.g.: https://stackoverflow.com/questions/46046119'
  )
  .meta({ className: 'ObjectId', _mongoose: { type: 'ObjectId' } })