import { ActiveInstance } from '@a-d/entities/ActiveInstance.entity';
import { InstanceForm } from '@a-d/entities/InstanceForm.entity';
import { FormHelpers } from '@a-d/forms/form-helpers.service';
import { I18NArrayPipe } from '@a-d/i18n/i18n.pipe';
import { AnamneseForm } from '@a-d/instance-form/anamnese/anamnese-forms.service';
import { InstanceFormInterface } from '@a-d/instance-form/instance-form-component.interface';
import { InstanceFormService } from '@a-d/instance-form/instance-form.service';
import { InstanceService } from '@a-d/instance/instance.service';
import { UsefulComponent } from '@a-d/misc/useful.component';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { GoComponentData, GoSettings } from './go-settings.entity';
import { GoService } from './go.service';
import {
  ChildFlag,
  ChildKey,
  ControlAndValue,
  ControlWithOptions,
  newChildFlag,
  ParentKey,
  RawVisibilityCondition,
  VisibilityCondition
} from './go.types';
import { I18NStringPipe } from '../../../../i18n/i18n.pipe';
import { SelectionFieldComponent } from '../../../../forms/fields/selection-field.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';
import { RadioButtonSelectComponent } from '../../../../forms/fields/radio-button-select.component';

@Component({
    selector: 'app-go',
    templateUrl: './go.component.html',
    styleUrls: ['./go.component.scss'],
    providers: [GoService],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        RadioButtonSelectComponent,
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        SelectionFieldComponent,
        I18NStringPipe,
    ],
})
export class GoComponent
  extends UsefulComponent
  implements OnDestroy, OnInit, InstanceFormInterface {
  @Input() anamneseForm: AnamneseForm;
  @Input() settings: GoSettings;
  subscriptions: Subscription[];
  formGroup = new UntypedFormGroup(this.goService.formGroup);
  visible: ChildFlag = newChildFlag();
  required: ChildFlag = newChildFlag();
  visibilityConditions: VisibilityCondition[] = [];

  accessControl(controlName: string) {
    return () => this.formGroup.get(controlName);
  }

  constructor(
    private cd: ChangeDetectorRef,
    private formHelpers: FormHelpers,
    private goService: GoService,
    private i18nPipe: I18NArrayPipe,
    private instanceFormService: InstanceFormService,
    private instanceService: InstanceService
  ) {
    super();
    this.formHelpers.syncActiveAnamneseFormWithLocalStorage(
      this.activeInstanceForm,
      this.formGroup,
      this.unsubscribe$
    );
  }

  ngOnInit(): void {
    this.visibilityConditions = GoService.createVisibilityConditions(
      this.settings,
      [
        ['sideEffects', 'customSideEffect', [0], true],
        ['allergies', 'allergySelect', [0], true],
        ['allergies', 'customAllergy', [0], false],
        ['allergySelect', 'customAllergy', [4, 5], true],
        ['allergies', 'allergyDetails', [0], true],
        ['chronicDiseases', 'customChronicDisease', [0], true],
        ['medications', 'customMedication', [0], true],
      ] as unknown as RawVisibilityCondition[]
    );

    this.subscriptions = this.visibilityConditions.map(
      ({ parent, child, parentValuesThatShowChild, canShowChild }) => {
        return this.formGroup
          .get(parent)
          .valueChanges.subscribe((_newValue) => {
            this.syncChildWithParent(
              parent,
              child,
              parentValuesThatShowChild,
              canShowChild
            );
          });
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  // Current Instance & Instance-Form Helpers //////////////////////////////////
  get activeInstance(): ActiveInstance {
    return this.instanceService.activeInstance;
  }
  get activeInstanceForm(): InstanceForm {
    return this.instanceFormService.activeInstanceForm;
  }
  readonly isDemoMode = environment.demoMode;

  // InstanceFormInterface /////////////////////////////////////////////////////
  getData(): GoComponentData {
    return this.formHelpers.trimmedRawValue(this.formGroup);
  }

  magicFill() {
    const immediatelyVisible: ControlAndValue[] = [
      ['sideEffects', this.i18('sideEffects', 0)],
      ['allergies', this.i18('allergies', 0)],
      ['chronicDiseases', this.i18('chronicDiseases', 0)],
      ['medications', this.i18('medications', 1)],
    ];
    const laterVisible: ControlAndValue[] = [
      ['customSideEffect', 'Rote Flecken überall am Körper'],
      ['allergyDetails', 'Nach einer Woche'],
      [
        'allergySelect',
        [this.i18('allergySelect', 1), this.i18('allergySelect', 4)],
      ],
      ['customChronicDisease', 'Schlaflosigkeit'],
    ];
    const lastVisible: ControlAndValue[] = [['customAllergy', 'Ibuprofen']];
    const timeout = 100;

    immediatelyVisible.forEach(this.fillEntry);
    setTimeout(() => {
      laterVisible.forEach(this.fillEntry);
    }, timeout);
    setTimeout(() => {
      lastVisible.forEach(this.fillEntry);
    }, 2 * timeout);
  }

  // private ///////////////////////////////////////////////////////////////////
  private arraysHaveCommonElements(a1: string[], a2: string[]): boolean {
    return a2.filter((v) => a1.includes(v)).length > 0;
  }

  private hasValue(controlName: string, allowedValues: string[]): boolean {
    const controlValue: string | string[] =
      this.formGroup.get(controlName).value;
    if (controlValue === null || controlValue === undefined) return false;
    if (typeof controlValue === 'string')
      return allowedValues.includes(controlValue);
    return this.arraysHaveCommonElements(controlValue, allowedValues);
  }

  private reset(controlName: ChildKey) {
    this.formGroup.get(controlName).reset('');
    this.formGroup.get(controlName).setValidators([]);
    this.cd.detectChanges();
  }

  private turnOn = (flag: ChildFlag, key: ChildKey) =>
    Object.assign({}, flag, { [key]: true });
  private turnOff = (flag: ChildFlag, key: ChildKey) =>
    Object.assign({}, flag, { [key]: false });

  private showRequireResetValue(child: ChildKey) {
    this.required = this.turnOn(this.required, child);
    this.cd.detectChanges();
    this.visible = this.turnOn(this.visible, child);
    this.reset(child);
  }

  private hideUnrequireResetValue(child: ChildKey) {
    this.visible = this.turnOff(this.visible, child);
    this.cd.detectChanges();
    this.required = this.turnOff(this.required, child);
    this.reset(child);
  }

  private syncChildWithParent(
    parent: ParentKey,
    child: ChildKey,
    parentValuesThatShowChild: string[],
    canShowChild: boolean = true
  ) {
    const parentWantsChildToShow = this.hasValue(
      parent,
      parentValuesThatShowChild
    );
    if (!parentWantsChildToShow) this.hideUnrequireResetValue(child);
    if (parentWantsChildToShow && canShowChild)
      this.showRequireResetValue(child);
  }

  private i18(c: ControlWithOptions, ind: number) {
    return this.i18nPipe.transform(this.settings[c].options)[ind];
  }

  private fillEntry = (pair: [string, string | string[]]): void => {
    const [controlName, newValue] = pair;
    const control = this.accessControl(controlName)();
    control.setValue(newValue);
    this.cd.detectChanges();
  };
}
