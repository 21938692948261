import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import dayjs from 'dayjs';

export const ISO_REGEX = /^(\d{4})-(\d{2})-(\d{2})(?:T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|(?:(?:\+|-)\d{2}:\d{2}))?)?$/;
export const CUSTOM_DMY_REGEX = /^(\d{2}).(\d{2}).(\d{4})$/;

@Component({
    selector: 'app-datepicker-custom',
    templateUrl: './datepicker-custom.component.html',
    styleUrls: ['./datepicker-custom.component.scss'],
    standalone: true,
    imports: [MatDatepickerModule]
})
export class DatepickerCustomComponent implements OnInit {
  @Input() control?: AbstractControl | null
  @Input() minDate = dayjs().subtract(100, 'years').toDate()

  constructor() { }

  ngOnInit(): void {
  }

  public dateChangeHandler(event: MatDatepickerInputEvent<Date>): void {
    if (!event.target.value) { return; }
    this.updateControl(event.target.value)
  }

  public dateInputHandler(event: MatDatepickerInputEvent<Date>): void {
    if (!event.target.value) { return; }
    this.updateControl(event.target.value)
  }

  private updateControl(updatedDate: Date): void {
    this.control?.setValue(this.convertToDDMMYYYYFromISODate(updatedDate?.toISOString())) // maybe convert?
    this.control?.markAsTouched();
    this.control?.markAsDirty();
  }

  public convertToISODateFromDDMMYYYY(value: string): string {
    const found = value.match(CUSTOM_DMY_REGEX);
    if (!found || found[0] !== value) { return value; }
    return new Date(Number(found[3]), Number(found[2]) - 1, Number(found[1])).toISOString();
  }

  public convertToDDMMYYYYFromISODate(value: string): string | null {
    if (ISO_REGEX.test(value)) {
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        date.setMinutes(date.getMinutes() + 540);
        return date?.toISOString().replace(ISO_REGEX, '$3.$2.$1')
      }
    }
    return value;
  }
}
