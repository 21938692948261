const Joi = require('joi')
import { themingDefault } from './theming.default'

export const ThemingJoi = Joi.object({
  colorPrimary: Joi.number(),
  saturationPrimary: Joi.number(),
  lightPrimary: Joi.number(),
  colorButton: Joi.number(),
  saturationButton: Joi.number(),
  lightButton: Joi.number(),
  isHeaderWhite: Joi.boolean(),
  isLogoLarge: Joi.boolean(),
})
  .meta({
    className: 'Theming',
  })
  .example(themingDefault)
