import { InstanceService } from '@a-d/instance/instance.service';
import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { BaseLanguage } from '../entities/I18N.entity';
import { LanguageService } from './language.service';

@Component({
  selector: 'app-language-toggle',
  templateUrl: './language-toggle.component.html',
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    MatIconModule,
    NgFor,
    TranslateModule,
  ],
})
export class LanguageToggleComponent implements OnInit {
  baseLanguagesActive: BaseLanguage[]
  isVisible: boolean

  constructor(
    public languageService: LanguageService,
    public instanceService: InstanceService
  ) { }

  ngOnInit(): void {
    const instanceLangs = this.instanceService.activeInstance?.settings?.general?.internationalization?.baseLanguagesActive
    const standardLangs = [BaseLanguage.English, BaseLanguage.French, BaseLanguage.German, BaseLanguage.Italian]
    this.baseLanguagesActive = instanceLangs ?? standardLangs
    this.isVisible = Object.keys(this.baseLanguagesActive).length > 1
  }
}
