import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { WfaFormDraft } from '@arzt-direkt/wfa-definitions'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import { nullish } from '@arzt-direkt/wfa-generic-utils'
import { Observable, of } from 'rxjs'

import { WfaEnvService } from '../../environments/wfa-env.service'
import { httpOptions } from './../utility/http-params'

@Injectable({
  providedIn: null,
})
export class EditorDataService {
  constructor(
    private httpClient: HttpClient,
    private wfaEnvService: WfaEnvService,
  ) {}

  private getUrl(path: string): string {
    if (this.wfaEnvService === undefined) return ''
    return `${this.wfaEnvService.serverUrl}${this.wfaEnvService.serverUrlSubdirectory}/${path}`
  }

  public wfaUpdateWfaFormDraft(
    wfaFormDraft: Maybe<WfaFormDraft>,
    _attachmentIds: string[] = [],
  ): Observable<any> {
    if (nullish(wfaFormDraft)) {
      this.wfaEnvService.logger.error(
        `[wfaUpdateWfaFormDraft]: could not update nullish wfaFormDraft`,
      )
      return of(null)
    }

    const url = this.getUrl(`wfaFormDraft/update`)
    const body = {
      wfaForm: wfaFormDraft,
    }

    return this.httpClient.post(url, body, httpOptions)

    /* adi-3220
    // const url = this.getUrl(`wfaFormDraft/update`)
    // const lean = convertFormToLean(wfaFormDraft, attachmentIds)
    // const formData = convertLeanToFormData(lean)

    // return this.httpClient.post(url, formData, formDataHttpOptions)
    */
  }
}
