import Joi from 'joi'

export const unlockModeDict = {
  fromMongoDb: 'fromMongoDb',
  uploadedByUser: 'uploadedByUser',
} as const

export const unlockModes = Object.values(unlockModeDict)
export const unlockModeDefault = unlockModeDict.fromMongoDb

export const UnlockModeJoi = Joi.string()
  .valid(...unlockModes)
  .default(unlockModeDefault)
  .description(
    `To unlock a form for a user, one can either use an already existing form in the database, or the user can upload a new form.`,
  )
  .meta({
    className: 'UnlockMode',
  })
  .example('fromMongoDb')
