import { AllgemeinAnamneseFormSettings } from '@a-d/instance-form/anamnese/allgemein/allgemein-anamnese-form.component';
import { InstanceFormSettingsInterface } from '@a-d/instance-form/instance-form-component.interface';
import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InstanceSettingsFormsService } from '../../instance-settings-forms.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MultilingualChipListFieldComponent } from '../../../../../forms/fields/multilingual-chip-list-field.component';


@Component({
    selector: 'app-allgemein-anamnese-form-settings',
    templateUrl: './allgemein-anamnese-form-settings.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MultilingualChipListFieldComponent,
        MatCheckboxModule,
    ],
})
export class AllgemeinAnamneseFormSettingsComponent implements InstanceFormSettingsInterface {

  @Input() defaultSettings: AllgemeinAnamneseFormSettings
  @Input() settings: AllgemeinAnamneseFormSettings


  /**
   * Form-Group
   */
  public formGroup = new UntypedFormGroup({
    diseaseOptions: new UntypedFormControl(null, [Validators.required]),
    showPreExistingDiseases: new UntypedFormControl(false, []),
    showPreExistingAllergies: new UntypedFormControl(false, []),
    showMedication: new UntypedFormControl(false, []),
  })

  public get diseaseOptions(): AbstractControl { return this.formGroup.get('diseaseOptions') }
  public get showPreExistingDiseases(): AbstractControl { return this.formGroup.get('showPreExistingDiseases') }
  public get showPreExistingAllergies(): AbstractControl { return this.formGroup.get('showPreExistingAllergies') }
  public get showMedication(): AbstractControl { return this.formGroup.get('showMedication') }


  constructor(
    private instanceSettingsFormsService: InstanceSettingsFormsService,
  ) { }

  onInit() { }

  /**
   * Gathers and returns merged settings-data.
   */
  public getMergedData(completeSettings?: boolean): any {
    return this.instanceSettingsFormsService.getMergedData(this, completeSettings)
  }

}
