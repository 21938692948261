import { ICreatorOptions } from 'survey-creator-core'

import { LicenseOptionsRecord, LicenseTier } from '../../types/license-tier'

export function customizeCreatorOptions(
  licenseTier: LicenseTier,
  record: LicenseOptionsRecord,
  creatorOptions: ICreatorOptions,
): ICreatorOptions {
  const customOptions = record[licenseTier] ?? record['basic']
  return { ...creatorOptions, ...customOptions }
}
