import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { LogoChangeMessageService } from '../../misc/logo-change-message.service';
import { ColorService } from '../service/color.service';
import { LogoService } from '../service/logo.service';
import { PageSizeService } from '../service/page-size.service';
@Component({
    selector: 'zs-main-header',
    template: `<header [className]="'main-header ' + headerType + (headerOverflow ? '' : ' c-header-no-overflow')">
    <ng-content></ng-content>
  </header>`,
    styleUrls: ['./main-header.component.scss'],
    standalone: true
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  @Input('headerType') headerType: string
  @Input() headerOverflow: boolean = true
  private subscription: Subscription

  @ViewChild('mainHeader') mainHeader: ElementRef;
  constructor(

    public colorService: ColorService,
    public pageSizeService: PageSizeService,
    private logoChangeMessageService: LogoChangeMessageService,
    private cd: ChangeDetectorRef,
    private logoService: LogoService
  ) { }

  ngOnInit() {
    this.subscription = this.logoChangeMessageService.currentMessage
      .subscribe((msg) => {
        if (msg === "color") {
          console.log("reset primary color in header")
          this.cd.detectChanges()
        }
      })

    this.logoService.setPaddingMain(this.colorService.largeLogo);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

}
