import {
  CategoryDict,
  PropertyDict,
  PropertyGridDict,
  PropertyName,
  SurveyElementName,
} from '@arzt-direkt/wfa-definitions'

export function getSwitchedOffProperties(
  gridProperties: PropertyGridDict,
): Record<SurveyElementName, PropertyName[]> {
  const switchOffDict = {}

  Object.entries(gridProperties).forEach(([surveyElementName, dict]) => {
    const elementHasCategories = hasElementCategories(
      dict as CategoryDict | PropertyDict,
    )
    const switchedOffElementProperties = elementHasCategories
      ? getSwitchedOffPropertiesFromCategoryDict(dict as CategoryDict)
      : getSwitchedOffPropertiesFromPropertyDict(dict as PropertyDict)
    if (switchedOffElementProperties.length > 0)
      Object.assign(switchOffDict, {
        [surveyElementName]: switchedOffElementProperties,
      })
  })

  return switchOffDict as Record<SurveyElementName, PropertyName[]>
}

function hasElementCategories(dict: CategoryDict | PropertyDict): boolean {
  const visibility = ['on', 'off']
  const dictValues = Object.values(dict)
  if (dictValues.length === 0) return false
  if (typeof dictValues[0] === 'string' && visibility.includes(dictValues[0]))
    return false
  return true
}

function getSwitchedOffPropertiesFromCategoryDict(
  categoryDict: CategoryDict,
): PropertyName[] {
  return Object.values(categoryDict)
    .map((propertyDict) =>
      getSwitchedOffPropertiesFromPropertyDict(propertyDict as PropertyDict),
    )
    .flat()
}

function getSwitchedOffPropertiesFromPropertyDict(
  propertyDict: PropertyDict,
): PropertyName[] {
  return Object.entries(propertyDict)
    .filter(([_, visibility]) => visibility === 'off')
    .map(([property]) => property) as PropertyName[]
}
