import { Maybe } from '../maybe'
import { nullish } from '../nullish'

/* from https://youmightnotneed.com/lodash */

export function get(
  obj: Maybe<Record<string, any>>,
  path: string | string[],
  defValue?: any,
): any {
  if (!path) return undefined
  if (nullish(obj)) return undefined

  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
  if (pathArray == null) return undefined

  const result = pathArray.reduce(
    (prevObj, key) => prevObj && prevObj[key],
    obj,
  )
  return result === undefined && defValue ? defValue : result
}
