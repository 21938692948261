import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgTemplateOutlet } from '@angular/common';


@Component({
    selector: 'app-info-banner',
    host: { '[class.c-card__title-stick]': 'stickToTitle' },
    templateUrl: './info-banner.component.html',
    standalone: true,
    imports: [
        NgIf,
        FontAwesomeModule,
        RouterLink,
        MatTooltipModule,
        NgTemplateOutlet,
    ],
})
export class InfoBannerComponent implements OnInit {

  @Input('state') state: string = 'normal'
  @Input('icon') icon: string[]
  @Input('title') title: string
  @Input('stickToTitle') stickToTitle: boolean = true

  @Input('externalLink') externalLink: string
  @Input('routerLink') routerLink: string
  @Input('showLink') showLink: boolean
  @Input('linkTooltip') linkTooltip: string
  @Output('linkClick') linkClick = new EventEmitter()

  @Input('closable') closable: boolean = false

  @Input('centering') centering: string

  public get centeringClass(): string {
    switch (this.centering) {
      case 'all': return 'c-info-banner--centeringAll'
      case 'title': return 'c-info-banner--centeringTitle'
      default: return ''
    }
  }

  public get hasLink(): boolean {
    return this.showLink && (!!this.routerLink || !!this.externalLink)
  }


  constructor(
    public element: ElementRef
  ) { }

  ngOnInit() { }


  /**
   * Removes the Banner from the DOM
   */
  close() {
    this.element.nativeElement.parentNode.removeChild(this.element.nativeElement)
  }
}
