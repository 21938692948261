/**
 * Class containing all(?) defined Unicode blocks
 * see: https://en.wikipedia.org/wiki/Unicode_block
 * 
 * Data parsed from https://www.unicode.org/Public/UNIDATA/Blocks.txt
 * - whitespace removed from block names
 * - '-' removed from block names
 */
export class UnicodeBlocks {
  /**
   * Join several code blocks into one regex.
   * @usageNotes
   * ```typescript
   * const allLatin: RegExp = UnicodeBlocks.join(
   *    UnicodeBlocks.BasicLatin, UnicodeBlocks.LatinOneSupplement,
   *    UnicodeBlocks.LatinExtendedA, UnicodeBlocks.LatinExtendedB
   * )
   * ```
   */
  static join(...regexes: RegExp[]): RegExp {
    return new RegExp(regexes.map(regex => regex.source).join("|"), "u");
  }
  static BasicLatin = RegExp("[\u{0000}-\u{007F}]", "u");
  static LatinOneSupplement = RegExp("[\u{0080}-\u{00FF}]", "u");
  static LatinExtendedA = RegExp("[\u{0100}-\u{017F}]", "u");
  static LatinExtendedB = RegExp("[\u{0180}-\u{024F}]", "u");
  static IPAExtensions = RegExp("[\u{0250}-\u{02AF}]", "u");
  static SpacingModifierLetters = RegExp("[\u{02B0}-\u{02FF}]", "u");
  static CombiningDiacriticalMarks = RegExp("[\u{0300}-\u{036F}]", "u");
  static GreekandCoptic = RegExp("[\u{0370}-\u{03FF}]", "u");
  static Cyrillic = RegExp("[\u{0400}-\u{04FF}]", "u");
  static CyrillicSupplement = RegExp("[\u{0500}-\u{052F}]", "u");
  static Armenian = RegExp("[\u{0530}-\u{058F}]", "u");
  static Hebrew = RegExp("[\u{0590}-\u{05FF}]", "u");
  static Arabic = RegExp("[\u{0600}-\u{06FF}]", "u");
  static Syriac = RegExp("[\u{0700}-\u{074F}]", "u");
  static ArabicSupplement = RegExp("[\u{0750}-\u{077F}]", "u");
  static Thaana = RegExp("[\u{0780}-\u{07BF}]", "u");
  static NKo = RegExp("[\u{07C0}-\u{07FF}]", "u");
  static Samaritan = RegExp("[\u{0800}-\u{083F}]", "u");
  static Mandaic = RegExp("[\u{0840}-\u{085F}]", "u");
  static SyriacSupplement = RegExp("[\u{0860}-\u{086F}]", "u");
  static ArabicExtendedB = RegExp("[\u{0870}-\u{089F}]", "u");
  static ArabicExtendedA = RegExp("[\u{08A0}-\u{08FF}]", "u");
  static Devanagari = RegExp("[\u{0900}-\u{097F}]", "u");
  static Bengali = RegExp("[\u{0980}-\u{09FF}]", "u");
  static Gurmukhi = RegExp("[\u{0A00}-\u{0A7F}]", "u");
  static Gujarati = RegExp("[\u{0A80}-\u{0AFF}]", "u");
  static Oriya = RegExp("[\u{0B00}-\u{0B7F}]", "u");
  static Tamil = RegExp("[\u{0B80}-\u{0BFF}]", "u");
  static Telugu = RegExp("[\u{0C00}-\u{0C7F}]", "u");
  static Kannada = RegExp("[\u{0C80}-\u{0CFF}]", "u");
  static Malayalam = RegExp("[\u{0D00}-\u{0D7F}]", "u");
  static Sinhala = RegExp("[\u{0D80}-\u{0DFF}]", "u");
  static Thai = RegExp("[\u{0E00}-\u{0E7F}]", "u");
  static Lao = RegExp("[\u{0E80}-\u{0EFF}]", "u");
  static Tibetan = RegExp("[\u{0F00}-\u{0FFF}]", "u");
  static Cherokee = RegExp("[\u{13A0}-\u{13FF}]", "u");
  static UnifiedCanadianAboriginalSyllabics = RegExp("[\u{1400}-\u{167F}]", "u");
  static Ogham = RegExp("[\u{1680}-\u{169F}]", "u");
  static Runic = RegExp("[\u{16A0}-\u{16FF}]", "u");
  static Tagalog = RegExp("[\u{1700}-\u{171F}]", "u");
  static Hanunoo = RegExp("[\u{1720}-\u{173F}]", "u");
  static Buhid = RegExp("[\u{1740}-\u{175F}]", "u");
  static Tagbanwa = RegExp("[\u{1760}-\u{177F}]", "u");
  static Khmer = RegExp("[\u{1780}-\u{17FF}]", "u");
  static Mongolian = RegExp("[\u{1800}-\u{18AF}]", "u");
  static UnifiedCanadianAboriginalSyllabicsExtended = RegExp("[\u{18B0}-\u{18FF}]", "u");
  static Limbu = RegExp("[\u{1900}-\u{194F}]", "u");
  static TaiLe = RegExp("[\u{1950}-\u{197F}]", "u");
  static NewTaiLue = RegExp("[\u{1980}-\u{19DF}]", "u");
  static KhmerSymbols = RegExp("[\u{19E0}-\u{19FF}]", "u");
  static Buginese = RegExp("[\u{1A00}-\u{1A1F}]", "u");
  static TaiTham = RegExp("[\u{1A20}-\u{1AAF}]", "u");
  static CombiningDiacriticalMarksExtended = RegExp("[\u{1AB0}-\u{1AFF}]", "u");
  static Balinese = RegExp("[\u{1B00}-\u{1B7F}]", "u");
  static Sundanese = RegExp("[\u{1B80}-\u{1BBF}]", "u");
  static Batak = RegExp("[\u{1BC0}-\u{1BFF}]", "u");
  static Lepcha = RegExp("[\u{1C00}-\u{1C4F}]", "u");
  static OlChiki = RegExp("[\u{1C50}-\u{1C7F}]", "u");
  static CyrillicExtendedC = RegExp("[\u{1C80}-\u{1C8F}]", "u");
  static GeorgianExtended = RegExp("[\u{1C90}-\u{1CBF}]", "u");
  static SundaneseSupplement = RegExp("[\u{1CC0}-\u{1CCF}]", "u");
  static VedicExtensions = RegExp("[\u{1CD0}-\u{1CFF}]", "u");
  static PhoneticExtensions = RegExp("[\u{1D00}-\u{1D7F}]", "u");
  static PhoneticExtensionsSupplement = RegExp("[\u{1D80}-\u{1DBF}]", "u");
  static CombiningDiacriticalMarksSupplement = RegExp("[\u{1DC0}-\u{1DFF}]", "u");
  static LatinExtendedAdditional = RegExp("[\u{1E00}-\u{1EFF}]", "u");
  static GreekExtended = RegExp("[\u{1F00}-\u{1FFF}]", "u");
  static GeneralPunctuation = RegExp("[\u{2000}-\u{206F}]", "u");
  static SuperscriptsandSubscripts = RegExp("[\u{2070}-\u{209F}]", "u");
  static CurrencySymbols = RegExp("[\u{20A0}-\u{20CF}]", "u");
  static CombiningDiacriticalMarksforSymbols = RegExp("[\u{20D0}-\u{20FF}]", "u");
  static LetterlikeSymbols = RegExp("[\u{2100}-\u{214F}]", "u");
  static NumberForms = RegExp("[\u{2150}-\u{218F}]", "u");
  static Arrows = RegExp("[\u{2190}-\u{21FF}]", "u");
  static MathematicalOperators = RegExp("[\u{2200}-\u{22FF}]", "u");
  static MiscellaneousTechnical = RegExp("[\u{2300}-\u{23FF}]", "u");
  static ControlPictures = RegExp("[\u{2400}-\u{243F}]", "u");
  static OpticalCharacterRecognition = RegExp("[\u{2440}-\u{245F}]", "u");
  static EnclosedAlphanumerics = RegExp("[\u{2460}-\u{24FF}]", "u");
  static BoxDrawing = RegExp("[\u{2500}-\u{257F}]", "u");
  static BlockElements = RegExp("[\u{2580}-\u{259F}]", "u");
  static GeometricShapes = RegExp("[\u{25A0}-\u{25FF}]", "u");
  static MiscellaneousSymbols = RegExp("[\u{2600}-\u{26FF}]", "u");
  static Dingbats = RegExp("[\u{2700}-\u{27BF}]", "u");
  static MiscellaneousMathematicalSymbolsA = RegExp("[\u{27C0}-\u{27EF}]", "u");
  static SupplementalArrowsA = RegExp("[\u{27F0}-\u{27FF}]", "u");
  static BraillePatterns = RegExp("[\u{2800}-\u{28FF}]", "u");
  static SupplementalArrowsB = RegExp("[\u{2900}-\u{297F}]", "u");
  static MiscellaneousMathematicalSymbolsB = RegExp("[\u{2980}-\u{29FF}]", "u");
  static SupplementalMathematicalOperators = RegExp("[\u{2A00}-\u{2AFF}]", "u");
  static MiscellaneousSymbolsandArrows = RegExp("[\u{2B00}-\u{2BFF}]", "u");
  static Glagolitic = RegExp("[\u{2C00}-\u{2C5F}]", "u");
  static LatinExtendedC = RegExp("[\u{2C60}-\u{2C7F}]", "u");
  static Coptic = RegExp("[\u{2C80}-\u{2CFF}]", "u");
  static GeorgianSupplement = RegExp("[\u{2D00}-\u{2D2F}]", "u");
  static Tifinagh = RegExp("[\u{2D30}-\u{2D7F}]", "u");
  static EthiopicExtended = RegExp("[\u{2D80}-\u{2DDF}]", "u");
  static CyrillicExtendedA = RegExp("[\u{2DE0}-\u{2DFF}]", "u");
  static SupplementalPunctuation = RegExp("[\u{2E00}-\u{2E7F}]", "u");
  static CJKRadicalsSupplement = RegExp("[\u{2E80}-\u{2EFF}]", "u");
  static KangxiRadicals = RegExp("[\u{2F00}-\u{2FDF}]", "u");
  static IdeographicDescriptionCharacters = RegExp("[\u{2FF0}-\u{2FFF}]", "u");
  static CJKSymbolsandPunctuation = RegExp("[\u{3000}-\u{303F}]", "u");
  static Hiragana = RegExp("[\u{3040}-\u{309F}]", "u");
  static Katakana = RegExp("[\u{30A0}-\u{30FF}]", "u");
  static Bopomofo = RegExp("[\u{3100}-\u{312F}]", "u");
  static HangulCompatibilityJamo = RegExp("[\u{3130}-\u{318F}]", "u");
  static Kanbun = RegExp("[\u{3190}-\u{319F}]", "u");
  static BopomofoExtended = RegExp("[\u{31A0}-\u{31BF}]", "u");
  static CJKStrokes = RegExp("[\u{31C0}-\u{31EF}]", "u");
  static KatakanaPhoneticExtensions = RegExp("[\u{31F0}-\u{31FF}]", "u");
  static EnclosedCJKLettersandMonths = RegExp("[\u{3200}-\u{32FF}]", "u");
  static CJKCompatibility = RegExp("[\u{3300}-\u{33FF}]", "u");
  static CJKUnifiedIdeographsExtensionA = RegExp("[\u{3400}-\u{4DBF}]", "u");
  static YijingHexagramSymbols = RegExp("[\u{4DC0}-\u{4DFF}]", "u");
  static CJKUnifiedIdeographs = RegExp("[\u{4E00}-\u{9FFF}]", "u");
  static YiSyllables = RegExp("[\u{A000}-\u{A48F}]", "u");
  static YiRadicals = RegExp("[\u{A490}-\u{A4CF}]", "u");
  static Lisu = RegExp("[\u{A4D0}-\u{A4FF}]", "u");
  static Vai = RegExp("[\u{A500}-\u{A63F}]", "u");
  static CyrillicExtendedB = RegExp("[\u{A640}-\u{A69F}]", "u");
  static Bamum = RegExp("[\u{A6A0}-\u{A6FF}]", "u");
  static ModifierToneLetters = RegExp("[\u{A700}-\u{A71F}]", "u");
  static LatinExtendedD = RegExp("[\u{A720}-\u{A7FF}]", "u");
  static SylotiNagri = RegExp("[\u{A800}-\u{A82F}]", "u");
  static CommonIndicNumberForms = RegExp("[\u{A830}-\u{A83F}]", "u");
  static Phagspa = RegExp("[\u{A840}-\u{A87F}]", "u");
  static Saurashtra = RegExp("[\u{A880}-\u{A8DF}]", "u");
  static DevanagariExtended = RegExp("[\u{A8E0}-\u{A8FF}]", "u");
  static KayahLi = RegExp("[\u{A900}-\u{A92F}]", "u");
  static Rejang = RegExp("[\u{A930}-\u{A95F}]", "u");
  static HangulJamoExtendedA = RegExp("[\u{A960}-\u{A97F}]", "u");
  static Javanese = RegExp("[\u{A980}-\u{A9DF}]", "u");
  static MyanmarExtendedB = RegExp("[\u{A9E0}-\u{A9FF}]", "u");
  static Cham = RegExp("[\u{AA00}-\u{AA5F}]", "u");
  static MyanmarExtendedA = RegExp("[\u{AA60}-\u{AA7F}]", "u");
  static TaiViet = RegExp("[\u{AA80}-\u{AADF}]", "u");
  static MeeteiMayekExtensions = RegExp("[\u{AAE0}-\u{AAFF}]", "u");
  static EthiopicExtendedA = RegExp("[\u{AB00}-\u{AB2F}]", "u");
  static LatinExtendedE = RegExp("[\u{AB30}-\u{AB6F}]", "u");
  static CherokeeSupplement = RegExp("[\u{AB70}-\u{ABBF}]", "u");
  static MeeteiMayek = RegExp("[\u{ABC0}-\u{ABFF}]", "u");
  static HangulSyllables = RegExp("[\u{AC00}-\u{D7AF}]", "u");
  static HangulJamoExtendedB = RegExp("[\u{D7B0}-\u{D7FF}]", "u");
  static HighSurrogates = RegExp("[\u{D800}-\u{DB7F}]", "u");
  static HighPrivateUseSurrogates = RegExp("[\u{DB80}-\u{DBFF}]", "u");
  static LowSurrogates = RegExp("[\u{DC00}-\u{DFFF}]", "u");
  static PrivateUseArea = RegExp("[\u{E000}-\u{F8FF}]", "u");
  static CJKCompatibilityIdeographs = RegExp("[\u{F900}-\u{FAFF}]", "u");
  static AlphabeticPresentationForms = RegExp("[\u{FB00}-\u{FB4F}]", "u");
  static ArabicPresentationFormsA = RegExp("[\u{FB50}-\u{FDFF}]", "u");
  static VariationSelectors = RegExp("[\u{FE00}-\u{FE0F}]", "u");
  static VerticalForms = RegExp("[\u{FE10}-\u{FE1F}]", "u");
  static CombiningHalfMarks = RegExp("[\u{FE20}-\u{FE2F}]", "u");
  static CJKCompatibilityForms = RegExp("[\u{FE30}-\u{FE4F}]", "u");
  static SmallFormVariants = RegExp("[\u{FE50}-\u{FE6F}]", "u");
  static ArabicPresentationFormsB = RegExp("[\u{FE70}-\u{FEFF}]", "u");
  static HalfwidthandFullwidthForms = RegExp("[\u{FF00}-\u{FFEF}]", "u");
  static Specials = RegExp("[\u{FFF0}-\u{FFFF}]", "u");
  static LinearBSyllabary = RegExp("[\u{10000}-\u{1007F}]", "u");
  static LinearBIdeograms = RegExp("[\u{10080}-\u{100FF}]", "u");
  static AegeanNumbers = RegExp("[\u{10100}-\u{1013F}]", "u");
  static AncientGreekNumbers = RegExp("[\u{10140}-\u{1018F}]", "u");
  static AncientSymbols = RegExp("[\u{10190}-\u{101CF}]", "u");
  static PhaistosDisc = RegExp("[\u{101D0}-\u{101FF}]", "u");
  static Lycian = RegExp("[\u{10280}-\u{1029F}]", "u");
  static Carian = RegExp("[\u{102A0}-\u{102DF}]", "u");
  static CopticEpactNumbers = RegExp("[\u{102E0}-\u{102FF}]", "u");
  static OldItalic = RegExp("[\u{10300}-\u{1032F}]", "u");
  static Gothic = RegExp("[\u{10330}-\u{1034F}]", "u");
  static OldPermic = RegExp("[\u{10350}-\u{1037F}]", "u");
  static Ugaritic = RegExp("[\u{10380}-\u{1039F}]", "u");
  static OldPersian = RegExp("[\u{103A0}-\u{103DF}]", "u");
  static Deseret = RegExp("[\u{10400}-\u{1044F}]", "u");
  static Shavian = RegExp("[\u{10450}-\u{1047F}]", "u");
  static Osmanya = RegExp("[\u{10480}-\u{104AF}]", "u");
  static Osage = RegExp("[\u{104B0}-\u{104FF}]", "u");
  static Elbasan = RegExp("[\u{10500}-\u{1052F}]", "u");
  static CaucasianAlbanian = RegExp("[\u{10530}-\u{1056F}]", "u");
  static Vithkuqi = RegExp("[\u{10570}-\u{105BF}]", "u");
  static LinearA = RegExp("[\u{10600}-\u{1077F}]", "u");
  static LatinExtendedF = RegExp("[\u{10780}-\u{107BF}]", "u");
  static CypriotSyllabary = RegExp("[\u{10800}-\u{1083F}]", "u");
  static ImperialAramaic = RegExp("[\u{10840}-\u{1085F}]", "u");
  static Palmyrene = RegExp("[\u{10860}-\u{1087F}]", "u");
  static Nabataean = RegExp("[\u{10880}-\u{108AF}]", "u");
  static Hatran = RegExp("[\u{108E0}-\u{108FF}]", "u");
  static Phoenician = RegExp("[\u{10900}-\u{1091F}]", "u");
  static Lydian = RegExp("[\u{10920}-\u{1093F}]", "u");
  static MeroiticHieroglyphs = RegExp("[\u{10980}-\u{1099F}]", "u");
  static MeroiticCursive = RegExp("[\u{109A0}-\u{109FF}]", "u");
  static Kharoshthi = RegExp("[\u{10A00}-\u{10A5F}]", "u");
  static OldSouthArabian = RegExp("[\u{10A60}-\u{10A7F}]", "u");
  static OldNorthArabian = RegExp("[\u{10A80}-\u{10A9F}]", "u");
  static Manichaean = RegExp("[\u{10AC0}-\u{10AFF}]", "u");
  static Avestan = RegExp("[\u{10B00}-\u{10B3F}]", "u");
  static InscriptionalParthian = RegExp("[\u{10B40}-\u{10B5F}]", "u");
  static InscriptionalPahlavi = RegExp("[\u{10B60}-\u{10B7F}]", "u");
  static PsalterPahlavi = RegExp("[\u{10B80}-\u{10BAF}]", "u");
  static OldTurkic = RegExp("[\u{10C00}-\u{10C4F}]", "u");
  static OldHungarian = RegExp("[\u{10C80}-\u{10CFF}]", "u");
  static HanifiRohingya = RegExp("[\u{10D00}-\u{10D3F}]", "u");
  static RumiNumeralSymbols = RegExp("[\u{10E60}-\u{10E7F}]", "u");
  static Yezidi = RegExp("[\u{10E80}-\u{10EBF}]", "u");
  static ArabicExtendedC = RegExp("[\u{10EC0}-\u{10EFF}]", "u");
  static OldSogdian = RegExp("[\u{10F00}-\u{10F2F}]", "u");
  static Sogdian = RegExp("[\u{10F30}-\u{10F6F}]", "u");
  static OldUyghur = RegExp("[\u{10F70}-\u{10FAF}]", "u");
  static Chorasmian = RegExp("[\u{10FB0}-\u{10FDF}]", "u");
  static Elymaic = RegExp("[\u{10FE0}-\u{10FFF}]", "u");
  static Brahmi = RegExp("[\u{11000}-\u{1107F}]", "u");
  static Kaithi = RegExp("[\u{11080}-\u{110CF}]", "u");
  static SoraSompeng = RegExp("[\u{110D0}-\u{110FF}]", "u");
  static Chakma = RegExp("[\u{11100}-\u{1114F}]", "u");
  static Mahajani = RegExp("[\u{11150}-\u{1117F}]", "u");
  static Sharada = RegExp("[\u{11180}-\u{111DF}]", "u");
  static SinhalaArchaicNumbers = RegExp("[\u{111E0}-\u{111FF}]", "u");
  static Khojki = RegExp("[\u{11200}-\u{1124F}]", "u");
  static Multani = RegExp("[\u{11280}-\u{112AF}]", "u");
  static Khudawadi = RegExp("[\u{112B0}-\u{112FF}]", "u");
  static Grantha = RegExp("[\u{11300}-\u{1137F}]", "u");
  static Newa = RegExp("[\u{11400}-\u{1147F}]", "u");
  static Tirhuta = RegExp("[\u{11480}-\u{114DF}]", "u");
  static Siddham = RegExp("[\u{11580}-\u{115FF}]", "u");
  static Modi = RegExp("[\u{11600}-\u{1165F}]", "u");
  static MongolianSupplement = RegExp("[\u{11660}-\u{1167F}]", "u");
  static Takri = RegExp("[\u{11680}-\u{116CF}]", "u");
  static Ahom = RegExp("[\u{11700}-\u{1174F}]", "u");
  static Dogra = RegExp("[\u{11800}-\u{1184F}]", "u");
  static WarangCiti = RegExp("[\u{118A0}-\u{118FF}]", "u");
  static DivesAkuru = RegExp("[\u{11900}-\u{1195F}]", "u");
  static Nandinagari = RegExp("[\u{119A0}-\u{119FF}]", "u");
  static ZanabazarSquare = RegExp("[\u{11A00}-\u{11A4F}]", "u");
  static Soyombo = RegExp("[\u{11A50}-\u{11AAF}]", "u");
  static UnifiedCanadianAboriginalSyllabicsExtendedA = RegExp("[\u{11AB0}-\u{11ABF}]", "u");
  static PauCinHau = RegExp("[\u{11AC0}-\u{11AFF}]", "u");
  static DevanagariExtendedA = RegExp("[\u{11B00}-\u{11B5F}]", "u");
  static Bhaiksuki = RegExp("[\u{11C00}-\u{11C6F}]", "u");
  static Marchen = RegExp("[\u{11C70}-\u{11CBF}]", "u");
  static MasaramGondi = RegExp("[\u{11D00}-\u{11D5F}]", "u");
  static GunjalaGondi = RegExp("[\u{11D60}-\u{11DAF}]", "u");
  static Makasar = RegExp("[\u{11EE0}-\u{11EFF}]", "u");
  static Kawi = RegExp("[\u{11F00}-\u{11F5F}]", "u");
  static LisuSupplement = RegExp("[\u{11FB0}-\u{11FBF}]", "u");
  static TamilSupplement = RegExp("[\u{11FC0}-\u{11FFF}]", "u");
  static Cuneiform = RegExp("[\u{12000}-\u{123FF}]", "u");
  static CuneiformNumbersandPunctuation = RegExp("[\u{12400}-\u{1247F}]", "u");
  static EarlyDynasticCuneiform = RegExp("[\u{12480}-\u{1254F}]", "u");
  static CyproMinoan = RegExp("[\u{12F90}-\u{12FFF}]", "u");
  static EgyptianHieroglyphs = RegExp("[\u{13000}-\u{1342F}]", "u");
  static EgyptianHieroglyphFormatControls = RegExp("[\u{13430}-\u{1345F}]", "u");
  static AnatolianHieroglyphs = RegExp("[\u{14400}-\u{1467F}]", "u");
  static BamumSupplement = RegExp("[\u{16800}-\u{16A3F}]", "u");
  static Mro = RegExp("[\u{16A40}-\u{16A6F}]", "u");
  static Tangsa = RegExp("[\u{16A70}-\u{16ACF}]", "u");
  static BassaVah = RegExp("[\u{16AD0}-\u{16AFF}]", "u");
  static PahawhHmong = RegExp("[\u{16B00}-\u{16B8F}]", "u");
  static Medefaidrin = RegExp("[\u{16E40}-\u{16E9F}]", "u");
  static Miao = RegExp("[\u{16F00}-\u{16F9F}]", "u");
  static IdeographicSymbolsandPunctuation = RegExp("[\u{16FE0}-\u{16FFF}]", "u");
  static Tangut = RegExp("[\u{17000}-\u{187FF}]", "u");
  static TangutComponents = RegExp("[\u{18800}-\u{18AFF}]", "u");
  static KhitanSmallScript = RegExp("[\u{18B00}-\u{18CFF}]", "u");
  static TangutSupplement = RegExp("[\u{18D00}-\u{18D7F}]", "u");
  static KanaExtendedB = RegExp("[\u{1AFF0}-\u{1AFFF}]", "u");
  static KanaSupplement = RegExp("[\u{1B000}-\u{1B0FF}]", "u");
  static KanaExtendedA = RegExp("[\u{1B100}-\u{1B12F}]", "u");
  static SmallKanaExtension = RegExp("[\u{1B130}-\u{1B16F}]", "u");
  static Nushu = RegExp("[\u{1B170}-\u{1B2FF}]", "u");
  static Duployan = RegExp("[\u{1BC00}-\u{1BC9F}]", "u");
  static ShorthandFormatControls = RegExp("[\u{1BCA0}-\u{1BCAF}]", "u");
  static ZnamennyMusicalNotation = RegExp("[\u{1CF00}-\u{1CFCF}]", "u");
  static ByzantineMusicalSymbols = RegExp("[\u{1D000}-\u{1D0FF}]", "u");
  static MusicalSymbols = RegExp("[\u{1D100}-\u{1D1FF}]", "u");
  static AncientGreekMusicalNotation = RegExp("[\u{1D200}-\u{1D24F}]", "u");
  static KaktovikNumerals = RegExp("[\u{1D2C0}-\u{1D2DF}]", "u");
  static MayanNumerals = RegExp("[\u{1D2E0}-\u{1D2FF}]", "u");
  static TaiXuanJingSymbols = RegExp("[\u{1D300}-\u{1D35F}]", "u");
  static CountingRodNumerals = RegExp("[\u{1D360}-\u{1D37F}]", "u");
  static MathematicalAlphanumericSymbols = RegExp("[\u{1D400}-\u{1D7FF}]", "u");
  static SuttonSignWriting = RegExp("[\u{1D800}-\u{1DAAF}]", "u");
  static LatinExtendedG = RegExp("[\u{1DF00}-\u{1DFFF}]", "u");
  static GlagoliticSupplement = RegExp("[\u{1E000}-\u{1E02F}]", "u");
  static CyrillicExtendedD = RegExp("[\u{1E030}-\u{1E08F}]", "u");
  static NyiakengPuachueHmong = RegExp("[\u{1E100}-\u{1E14F}]", "u");
  static Toto = RegExp("[\u{1E290}-\u{1E2BF}]", "u");
  static Wancho = RegExp("[\u{1E2C0}-\u{1E2FF}]", "u");
  static NagMundari = RegExp("[\u{1E4D0}-\u{1E4FF}]", "u");
  static EthiopicExtendedB = RegExp("[\u{1E7E0}-\u{1E7FF}]", "u");
  static MendeKikakui = RegExp("[\u{1E800}-\u{1E8DF}]", "u");
  static Adlam = RegExp("[\u{1E900}-\u{1E95F}]", "u");
  static IndicSiyaqNumbers = RegExp("[\u{1EC70}-\u{1ECBF}]", "u");
  static OttomanSiyaqNumbers = RegExp("[\u{1ED00}-\u{1ED4F}]", "u");
  static ArabicMathematicalAlphabeticSymbols = RegExp("[\u{1EE00}-\u{1EEFF}]", "u");
  static MahjongTiles = RegExp("[\u{1F000}-\u{1F02F}]", "u");
  static DominoTiles = RegExp("[\u{1F030}-\u{1F09F}]", "u");
  static PlayingCards = RegExp("[\u{1F0A0}-\u{1F0FF}]", "u");
  static EnclosedAlphanumericSupplement = RegExp("[\u{1F100}-\u{1F1FF}]", "u");
  static EnclosedIdeographicSupplement = RegExp("[\u{1F200}-\u{1F2FF}]", "u");
  static MiscellaneousSymbolsandPictographs = RegExp("[\u{1F300}-\u{1F5FF}]", "u");
  static Emoticons = RegExp("[\u{1F600}-\u{1F64F}]", "u");
  static OrnamentalDingbats = RegExp("[\u{1F650}-\u{1F67F}]", "u");
  static TransportandMapSymbols = RegExp("[\u{1F680}-\u{1F6FF}]", "u");
  static AlchemicalSymbols = RegExp("[\u{1F700}-\u{1F77F}]", "u");
  static GeometricShapesExtended = RegExp("[\u{1F780}-\u{1F7FF}]", "u");
  static SupplementalArrowsC = RegExp("[\u{1F800}-\u{1F8FF}]", "u");
  static SupplementalSymbolsandPictographs = RegExp("[\u{1F900}-\u{1F9FF}]", "u");
  static ChessSymbols = RegExp("[\u{1FA00}-\u{1FA6F}]", "u");
  static SymbolsandPictographsExtendedA = RegExp("[\u{1FA70}-\u{1FAFF}]", "u");
  static SymbolsforLegacyComputing = RegExp("[\u{1FB00}-\u{1FBFF}]", "u");
  static CJKUnifiedIdeographsExtensionB = RegExp("[\u{20000}-\u{2A6DF}]", "u");
  static CJKUnifiedIdeographsExtensionC = RegExp("[\u{2A700}-\u{2B73F}]", "u");
  static CJKUnifiedIdeographsExtensionD = RegExp("[\u{2B740}-\u{2B81F}]", "u");
  static CJKUnifiedIdeographsExtensionE = RegExp("[\u{2B820}-\u{2CEAF}]", "u");
  static CJKUnifiedIdeographsExtensionF = RegExp("[\u{2CEB0}-\u{2EBEF}]", "u");
  static CJKUnifiedIdeographsExtensionI = RegExp("[\u{2EBF0}-\u{2EE5F}]", "u");
  static CJKCompatibilityIdeographsSupplement = RegExp("[\u{2F800}-\u{2FA1F}]", "u");
  static CJKUnifiedIdeographsExtensionG = RegExp("[\u{30000}-\u{3134F}]", "u");
  static CJKUnifiedIdeographsExtensionH = RegExp("[\u{31350}-\u{323AF}]", "u");
  static Tags = RegExp("[\u{E0000}-\u{E007F}]", "u");
  static VariationSelectorsSupplement = RegExp("[\u{E0100}-\u{E01EF}]", "u");
}