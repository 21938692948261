import { responseIdDict } from './response-id.dict'

export const staticWfaFormVersionId = '664736def1cbb7cee8c18caa'
export const testingWfaFormVersionId = '664c9542968e7458af2fb3bd'
export const draftWfaFormVersionId = '665dad2f34085a1baf881a7e'
export const draft2WfaFormVersionId = '665dbbb19710eee4bf27b05a'
export const draft3WfaFormVersionId = '6666a7ef6fdac8d7920a532d'

export const praxisIdDict = {
  arseniy: '641ad49b0c82411c0e20b9aa',
  testInstanzBettina: '65b36c5be6c7e09ab425f658',
  chris: '65950e035b9c5c90a6538ba4',
  testInstanzHoney: '65d495f539d673640473af86',
  flutter: '614af2ef4e989f0012d3f76c',
  florian: '65683e1c17a3b10786c413b5',
  konstantin: '654e35756c8567b04391db24',
  praesiDbAllg: '6350ec2e66af99a03d94981d', // live
  testInstanzChristian: '65c223d2bc362e3806206ff2',
  testInstanzStaticBeta: '65ba0fa6a7d202b6faa62f2a',
  testinstanzPatientenformulare: '65ec46995cf0466c22f8d84f',
  toni: '616563cab7409a0011c22914',
} as const

export const praxisKennungDict = {
  arseniy: 'test-instanz-arseniy',
  testInstanzStaticBeta: 'test-instanz-static-beta',
} as const

export const livePraxisIdDict = {
  // alpha test instances
  bettinaLiveInstanz: '660168f39400eebe10c825a9', // zs-tester
  liveinstanzPatientenformulare: '65eb2f7a350c6072f88e1646',
  hausarztpraxisMusterstadtNeu: '63ff76badb5e3d683838072e', // "madita live"
  // other
  artemisHeppenheim: '66338ae9497f44e7d8192375', // vip-kunde
  arztDirektMichael: '61dd6bb700c413323a003499',
  hautpraxisJena: '5dd68e7a4057020010a4616a', // Dr. Christine Zollmann Jena
  hnoImMedicum: '5efd780bd781790018d3ed67', // beta-tester
  hnomedicMucallg: '634455ab62ffd2b9e82ad900', // vip-kunde
  jenaPraxisklinik: '5de525ce979708001131a874', // Dr. Ph. Zollmann & Koll Jena
  kaiserKolleginnen: '624a8f4607d4aa702e579f9a', // beta-tester
  kinderarztpraxisHerzenssache: '65b9f00fa9d98d81f4446869', // beta-tester
  liveinstanzChho: '62fa42fee61eb21a2d40a5d1', // Christian Honey
  praesiDbAllg: '6350ec2e66af99a03d94981d',
  testInstanzChristian2: '6618f6c868845d8f1837ee32', // christian meiners
  testInstanzChristian: '65c223d2bc362e3806206ff2', // christian meiners
  testTomedoAt: '65aa362d8e8841ef1a981943',
  testzollsoftDemoArbeitsmedizin: '648873df6b8cb922066894ec',
  zollsoftVifa: '6464b3a265996f8b5cbdd7fd',
  zollsoftTohi: '64425203b1a33e9d62248612', // toHi
  zollsoftverwaltung: '6475c77c2722b9043fbe350f',
  // static
  liveTemplateLib: '665065fb74f3e9d25996696d',
  liveZsLib: '661626ed81ab4aa586d2ef0f',
} as const

export const zollsoftStandardLibrary = {
  _id: livePraxisIdDict.liveZsLib,
  identifier: 'zollsoft-extended-library',
} as const

export const zollsoftPublicLibrary = {
  _id: livePraxisIdDict.liveTemplateLib,
  identifier: 'zollsoft-public-library',
} as const

export const livePraxisKennungDict = {
  // alpha test instances
  bettinaLiveInstanz: 'bettina-live-instanz',
  hausarztpraxisMusterstadtNeu: 'hausarztpraxis-musterstadt-neu', // "madita live"
} as const

export const staticIdDict = {
  arseniy: '66029e2b585291f8a399d447',
  nonExistent: '6602ae647dc9c480fd4c36f9',
  zollsoft: '667421f4925d3e4f1319bb96', // statische mit einer willkürlichen instanz
  staticZsLib: '66013b22cf10cef07176c492', // praxis für formulare in src/assets/json
} as const
export const staticKennungDict = {
  arseniy: 'indes-instance-arseniy',
  zollsoft: 'indes-instance-zollsoft',
} as const

// dictionaries used for different newman build variants
export interface NewmanInstanceDict {
  alpha: string // most used, static
  beta: string // for special test cases
  nonExistent: string // instance id that does not exist
  responseIdAlpha: string // for existing response id
  firstWfaFormVersionId: string // for existing wfaFormVersionId
  draftWfaFormVersionId: string
  draft2WfaFormVersionId: string
  draft3WfaFormVersionId: string
  wfaFormLength: number // number of available dynamic forms
}

export type NewmanKennungDict = {
  [k in keyof NewmanInstanceDict]?: string
}

export interface NewmanParams {
  nid: NewmanInstanceDict
  nkd?: NewmanKennungDict
}

export const indesNid = {
  alpha: staticIdDict.zollsoft,
  beta: staticIdDict.arseniy,
  nonExistent: staticIdDict.nonExistent,
  responseIdAlpha: responseIdDict.alpha,
  firstWfaFormVersionId: testingWfaFormVersionId,
  draftWfaFormVersionId,
  draft2WfaFormVersionId,
  draft3WfaFormVersionId,
  wfaFormLength: 7,
}

export const indesNkd = {
  alpha: staticKennungDict.zollsoft,
  beta: staticKennungDict.arseniy,
}

// local arzt-direkt newman instance dict
export const ladNid = {
  alpha: praxisIdDict.testInstanzStaticBeta,
  beta: praxisIdDict.arseniy,
  nonExistent: staticIdDict.nonExistent,
  responseIdAlpha: '6604154a63beefbd38de9cd1', // from arzt-direkt/server_appointment/src/services/db-generator/instances/test-instanz-arseniy/collections/wfaformresponses.json
  firstWfaFormVersionId: '6551f356d868f9ab2c7a1295', // random entry from test db
  draftWfaFormVersionId,
  draft2WfaFormVersionId,
  draft3WfaFormVersionId,
  wfaFormLength: 7,
}

// local arzt-direkt newman kennung dict
export const ladNkd = {
  alpha: praxisKennungDict.testInstanzStaticBeta,
  beta: praxisKennungDict.arseniy,
}

export const liveNid = {
  alpha: livePraxisIdDict.bettinaLiveInstanz,
  beta: livePraxisIdDict.hausarztpraxisMusterstadtNeu,
  nonExistent: staticIdDict.nonExistent,
  responseIdAlpha: '6603e06ae7fcfd3703e9b5bd', // some random entry from the live db
  firstWfaFormVersionId: '656efc58f13a9ed75c2a9dfb', // random entry from live db
  draftWfaFormVersionId: '665dadd8635a778f0544403e', // random entry from live db
  draft2WfaFormVersionId: '665dadd8635a778f0544403e',
  draft3WfaFormVersionId: '665dadd8635a778f0544403e',
  wfaFormLength: 7,
}

export const liveNkd = {
  alpha: livePraxisIdDict.bettinaLiveInstanz,
  beta: livePraxisIdDict.hausarztpraxisMusterstadtNeu,
}
