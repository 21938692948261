import { HttpClient } from '@angular/common/http'
import { inject } from '@angular/core'
import { Router } from '@angular/router'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { WfaFormResponse } from '@arzt-direkt/wfa-definitions'
import {
  validateWfaForm,
  WfaFormResponseJoi,
} from '@arzt-direkt/wfa-definitions'
import { validate } from '@arzt-direkt/wfa-generic-utils'
import { isWfaMessage } from '@arzt-direkt/wfa-generic-utils'
import { map, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { getDisplayableErrorRoute } from '../../../app/user-message/displayable-errors'
import { WfaEnvService } from '../../../environments/wfa-env.service'
import { getWfaServerBaseUrl } from './../../../environments/get-wfa-url'

export function PermalinkViewerResolver(
  route: ActivatedRouteSnapshot,
  _: RouterStateSnapshot,
) {
  const httpClient = inject(HttpClient)
  const router = inject(Router)
  const wfaEnv = inject(WfaEnvService)
  const { instanceIdentifier, wfaFormId } = route.params

  const serverBaseUrl = getWfaServerBaseUrl(wfaEnv)
  const urlParams = `instanceIdentifier=${instanceIdentifier}&wfaFormId=${wfaFormId}&loadCertificate=true`

  const url = `${serverBaseUrl}/getWfaForm?${urlParams}`

  return httpClient
    .get(url)
    .pipe(
      map((res: any) => {
        const form = validateWfaForm(res.queryResult)

        if (isWfaMessage(form)) {
          wfaEnv.logger.error(
            `[PermalinkViewerResolver]: invalid form ${form.message} for form`,
            (res as { queryResult: unknown }).queryResult,
          )
          return of(null)
        }

        if (!form.permalink) {
          router.navigate([
            `${wfaEnv.frontendUrlSubdirectory}/user-message/`,
            getDisplayableErrorRoute('q5708'),
          ])
          return of(null)
        }

        const placeholderId = '000000000000000000000000'
        const certificate = form.certificate

        const newFormResponse = {
          _id: placeholderId,
          responseId: placeholderId,
          wfaFormId: form.wfaFormId,
          wfaFormVersionId: form.wfaFormVersionId,
          praxisId: form.praxisId,
          surveyjsResponse: {},
          origin: 'missing',
          submittedAt: -1,
          certificate: certificate,
        }

        const formResponse = validate<WfaFormResponse>(
          WfaFormResponseJoi,
          newFormResponse,
        )

        if (isWfaMessage(formResponse)) {
          wfaEnv.logger.error(
            `[PermalinkViewerResolver]: invalid formResponse ${formResponse.message} for formResponse`,
            (res as { queryResult: unknown }).queryResult,
          )
          return of(null)
        }

        return { form, formResponse, wfaEnv, certificate }
      }),
      catchError((error) => {
        router.navigate([
          `${wfaEnv.frontendUrlSubdirectory}/user-message/`,
          getDisplayableErrorRoute('q5706', error?.error?.message),
        ])
        wfaEnv.logger.error(`getWfaForm: `, error?.message)
        return of(null)
      }),
    )
    .pipe(
      catchError((error) => {
        wfaEnv.logger.error('[PermalinkViewerResolver]: ', error?.message)
        return of(null)
      }),
    )
}
