import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ContactData } from '../entities/Booking.entity';

@Injectable({
  providedIn: 'root'
})
export class BookingContactService {

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
   * book new contact form request
   * @param instance active instance identifier
   * @param contactFormData full form data
   * @returns booking status
   */
  public book(instance: string, contactFormData: ContactData) {
    const requestData = {
      instance: instance,
      contactForm: contactFormData
    }
    return this.httpClient.post(`${environment.otkUrl}/api/contact/book`, requestData);
  }
}
