<div attr.data-selenium-id="div-asset-form-group" [formGroup]="assetFormGroup">

  <div attr.data-selenium-id="div-c-form-info-banner" class="c-form__info-banner c-info-banner normal"
    appDragNDropUpload (onFileDropped)="uploadFieldComponent.onFilesChange($event)"
    [disabled]="assets.controls.length >= MAX_AMOUNT_IMAGES">

    <div class="c-form__row">
      <div class="c-form__row__item--metaText">
        <em *ngIf="assets.controls.length < MAX_AMOUNT_IMAGES">
          {{ 'OTK.BOOKING-SUMMARY.UPLOAD-TEXT' | translate: {handler: ('OTK.HANDLER-SINGULAR' | translate)} }}
        </em>
        <em *ngIf="assets.controls.length >= MAX_AMOUNT_IMAGES">
          {{ 'INSTANCE-FORM-FILE-UPLOAD.UPLOAD-IMAGES-MAX-WARNING' | translate:{value: MAX_AMOUNT_IMAGES} }}
        </em>
      </div>
    </div>

    <div class="c-form__row">
      <app-asset-upload-field [formGroup]="assetFormGroup" [control]="newAsset"
        [disabled]="assets.controls.length >= MAX_AMOUNT_IMAGES" [settings]="uploadSettings" #uploadFieldComponent
        [otk]="true">
      </app-asset-upload-field>
    </div>

    <ng-container *ngFor="let asset of assets.controls; let idx = index">
      <div class="c-form__row">
        <hr>
      </div>

      <div attr.data-selenium-id="div-uploaded-files-row" class="c-uploaded-files-row">

        <div class="c-uploaded-files-row__thumb" (click)="downloadAsset(asset.value)">
          <div>
            <!-- if asset is an image, display its preview -->
            <img *ngIf="asset.get('isImage').value" [src]="asset.value.dataUri" [alt]="asset.value.meta.name">

            <!-- if asset is not an image, display its extension icon & name -->
            <ng-container *ngIf="!asset.get('isImage').value">
              <fa-icon [icon]="['fal', mimetypeHelpers.getFileIcon(asset.value.meta.type)]"></fa-icon>
              <p *ngIf="!asset.get('isImage').value">{{ asset.get('extension').value }}</p>
            </ng-container>
          </div>
        </div>

        <mat-form-field attr.data-selenium-id="mat-form-field-description" [formGroup]="asset.get('meta')"
          appearance="outline" class="description-form-field">
          <mat-label>{{ 'INSTANCE-FORM-FILE-UPLOAD.DESCRIPTION' | translate }}</mat-label>
          <textarea attr.data-selenium-id="textarea-description" matInput cdkTextareaAutosize
            formControlName="description" mat-autosize=true cdkAutosizeMinRows=1 cdkAutosizeMaxRows=3></textarea>
        </mat-form-field>

        <button attr.data-selenium-id="button-remove-asset-at" *ngIf="!(bookingService.fileEncrypting[idx] | async)"
          mat-icon-button class="c-uploaded-files-row__action" (click)="removeAssetAt(idx);">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>