import { Router } from '@angular/router'
import { WfaFormDraft } from '@arzt-direkt/wfa-definitions'
import { AdisEditorInput } from '@arzt-direkt/wfa-definitions'
import { getParentUrl } from '@arzt-direkt/wfa-definitions'

import { WfaEnvService } from '../../../environments/wfa-env.service'

export function navigateToAdisEditor(
  wfaFormDraft: WfaFormDraft,
  router: Router,
  _wfaEnvService: WfaEnvService,
) {
  const adisEditorInput: AdisEditorInput = {
    wfaFormDraft,
  }
  const url = `${getParentUrl(router.url)}/wfa/adis-editor`
  router.navigateByUrl(url, { state: { adisEditorInput } })
}
