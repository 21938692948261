import { SurveyjsPropertyInfo } from '@arzt-direkt/wfa-definitions'

export const showQuestionNumbers: SurveyjsPropertyInfo = {
  name: 'showQuestionNumbers',
  surveyElements: ['survey'],
  customPropertySettings: [
    {
      settingName: 'defaultValue',
      settingValue: 'off',
    },
  ],
}
