const mongoose = require('mongoose');
const Schema = mongoose.Schema;

export const WfaInstanceSettingsMS = new Schema({
  "isInternalZollsoftSupportInstance": {
    "default": false,
    "type": Boolean
  },
  "enableInternalTestEditor": {
    "default": false,
    "type": Boolean
  }
})
