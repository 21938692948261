import { HttpClient } from '@angular/common/http'
import { ElementRef, Injectable } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { environment } from '../../environments/environment'
import { InstanceService } from '../instance/instance.service'
import { TOCItem } from '../legal/legal-dialog.component'
import { BrowserSupportService } from '../misc/browser-support/browser-support.service'
@Injectable({
  providedIn: 'root',
})

@UntilDestroy()
export class DocumentsService {

  public readonly menuFileNamesGlobal = ['agb', 'impressum', 'datenschutz', 'support']
  public readonly menuFileNamesInstance = ['einverstaendniserklaerung']
  public readonly displayTocFor: string[] = ['agb', 'datenschutz', 'einverstaendniserklaerung']

  public toc: TOCItem[]
  public title: string
  public shortTitle: string
  public subtitle: string

  constructor(
    private http: HttpClient,
    private browserSupportService: BrowserSupportService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private instanceService: InstanceService,

  ) { }

  /**
   * Fetches the content of the given .html file in an HTTP-request
   */
  retrieveDocument(lang: string,
    docName: string, instanceIdentifier?: string)
    : BehaviorSubject<string> {
    let documentHTML: string = ''
    const subject$ = new BehaviorSubject<string>('')
    const instanceIdentifierForUrl = (instanceIdentifier && instanceIdentifier !== 'admin')
      ? (instanceIdentifier + '/')
      : ''
    // prevent the user from accessing instance-only documents when not in an instance
    if (this.menuFileNamesInstance.includes(docName) && !this.instanceService.activeInstance.settings.legal.consentForm.isEnabled)
      docName = 'invalid'
    // retrieve the document
    //if (lang !== 'de' && lang !== 'en') lang = 'de'
    const url: string =
      environment.url + '/api/docs/' + lang + '/' + instanceIdentifierForUrl
      + docName + ".html"
    this.http.get(url, { responseType: "text" })
      .pipe(
        untilDestroyed(this)
      )
      .subscribe((result) => {
        // replace placeholders in text and generate toc.
        result = result.replace(/\[\[\s?appRequirements\s?\]\]/g, this.browserSupportService.appRequirementsFormattedHtml)
        result = result.replace(/\[\[\s?browserNameEncoded\s?\]\]/g, encodeURIComponent(this.browserSupportService.browser.getBrowserName()))

        // Parse & Insert HTML
        // don't sanitize here but inside the component TODO
        documentHTML = <string>this.domSanitizer.bypassSecurityTrustHtml(result)
        subject$.next(documentHTML)
        subject$.complete()
      }, (error) => {
        console.warn(error)
        this.router.navigate(['./dokumente'])
        subject$.next('')
        subject$.complete()
      })
    return subject$
  }

  /**
  * Generates a dynamic outline based on the loaded HTML and it's heading-elements.
  */
  public generateDynamicToc(contentEl: ElementRef) {
    // Reloacte Title
    const titleEl: HTMLElement = contentEl.nativeElement.querySelector('h1')
    let subtitleEl: HTMLElement = null
    if (titleEl) {
      subtitleEl = titleEl.querySelector('p')
      if (subtitleEl) {
        this.subtitle = subtitleEl.textContent
        subtitleEl.remove()
      } else {
        this.subtitle = null
      }
      this.shortTitle = titleEl.dataset.short
      this.title = titleEl.textContent
      titleEl.remove()
    }

    // Create TOC-Items of h2-Elements
    this.toc = []
    const h2Elements = this.queryElements(contentEl.nativeElement, 'h2')
    for (let h2El of h2Elements) {
      this.toc.push({
        title: h2El.textContent,
        headingEl: h2El
      })
    }
  }

  /**
  * Helper to query a given selector within a given element.
  */
  private queryElements(element: HTMLElement, selector: string): HTMLElement[] {
    const elements: HTMLElement[] = Array.from(element.querySelectorAll(selector))
    if (!elements || elements.length <= 0) {
      return [];
    }
    return elements
  }
}
