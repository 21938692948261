import { Injectable } from "@angular/core";
import Cookies, { CookieAttributes } from 'js-cookie';
import { Subject } from "rxjs";

/* this should be the only place in the app directly using js-cookie.
  all others should use this service to manipulate cookies. */

/**
 * note: I wanted to have 2 enums here, one for each cookie type, and then
 * a third enum which is their union. However, I could not get the typecasting
 * in the functions to work right this way. I found this solution using iterable
 * union types. I find it interesting and elegant. Read about it here:
 * https://stackoverflow.com/questions/40275832/typescript-has-unions-so-are-enums-redundant
 * see also
 * https://stackoverflow.com/questions/55377365/what-does-keyof-typeof-mean-in-typescript
 */
export const LSERROR = "Error while accessing local storage. This is likely due to chrome incognito mode and iframe embedding"

export const EssentialCookieNames = {
  areDsgvoPreferencesSubmitted: "ARE_DSGVO_PREFERENCES_SUBMITTED",
  isFormCachingAllowed: "IS_FORM_CACHING_ALLOWED",
  areSupportCookiesAllowed: "ARE_SUPPORT_COOKIES_ALLOWED",
  refreshToken: "REFRESH_TOKEN", // managed by the backend
  authToken: "AUTH_TOKEN", // managed by the backend
  csrfToken: "x-csrf-token"
} as const
export type EssentialCookieName = typeof EssentialCookieNames[keyof typeof EssentialCookieNames]

export const SupportCookieNames = {
  hideBrowserSupportDialog: "HIDE_BROWSER_SUPPORT_DIALOG",
  language: "LANG",
  loggedInInstance: "LOGGED_IN_INSTANCE",
  loggedInAdmin: "LOGGED_IN_ADMIN",
} as const
type SupportCookieName = typeof SupportCookieNames[keyof typeof SupportCookieNames]

// cookies which should be set with samesite:none and secure:true so that they can be set
// cross-origin for iframe embedding
export const IframeCookieNames = {
  areDsgvoPreferencesSubmitted: "ARE_DSGVO_PREFERENCES_SUBMITTED",
  isFormCachingAllowed: "IS_FORM_CACHING_ALLOWED",
  hideBrowserSupportDialog: "HIDE_BROWSER_SUPPORT_DIALOG",
  language: "LANG",
  loggedInInstance: "LOGGED_IN_INSTANCE",
  loggedInAdmin: "LOGGED_IN_ADMIN",
} as const
type IframeCookieName = typeof IframeCookieNames[keyof typeof IframeCookieNames]

const CookieNames = {
  ...EssentialCookieNames,
  ...SupportCookieNames
} as const
type CookieName = typeof CookieNames[keyof typeof CookieNames]
export interface Cookie {
  cookieName: CookieName,
  value: string
}
@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  public cookieChanged$ = new Subject<Cookie>()

  temporaryAuthCookie: string | null = null;

  csrfToken: string | null = null

  private isSupportCookie(cookieName: CookieName): boolean {
    return Object.values(SupportCookieNames).includes(cookieName as SupportCookieName)
  }

  private isIframeCookie(cookieName: CookieName): boolean {
    return Object.values(IframeCookieNames).includes(cookieName as IframeCookieName)
  }

  private areSupportCookiesAllowed(): boolean {
    return this.get(EssentialCookieNames.areSupportCookiesAllowed) === 'true'
  }

  public set(cookieName: CookieName, value: string, attributes?: CookieAttributes) {
    if (this.isSupportCookie(cookieName) && !this.areSupportCookiesAllowed())
      return
    if (this.isIframeCookie(cookieName))
      attributes = { ...attributes, samesite: 'none', secure: true }
    Cookies.set(cookieName, value, attributes);
    const cookie: Cookie = { cookieName, value }
    this.cookieChanged$.next(cookie)
  }

  /**
   * note: for "boolean" cookies this still returns a string
   * ("true" or "false")
   */
  public get(cookieName: string): string {
    return Cookies.get(cookieName)
  }

  public remove(cookieName: CookieName) {
    Cookies.remove(cookieName)
  }

  private removeAllEssentialCookies() {
    for (const essentialCookieName of Object.values(EssentialCookieNames)) {
      this.remove(essentialCookieName)
    }
  }

  public removeAllSupportCookies() {
    for (const supportCookieName of Object.values(SupportCookieNames)) {
      this.remove(supportCookieName)
    }
  }

  public removeTokens() {
    this.remove(EssentialCookieNames.authToken)
    this.remove(EssentialCookieNames.refreshToken)
    this.remove(EssentialCookieNames.csrfToken)
  }

  public removeAllCookies() {
    this.removeAllEssentialCookies()
    this.removeAllSupportCookies()
    // by the way, the following would have also worked:
    // for (const cookieName of Object.values(CookieName)) {
    //   this.remove(cookieName)
    // }
  }
}
