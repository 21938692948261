import { Directive, EventEmitter, HostBinding, HostListener, Input, Output, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appDragNDropUpload]',
    standalone: true
})
export class DragNDropUploadDirective {

  @Input() disabled: boolean
  @Output() onFileDropped = new EventEmitter<any>();

  @HostBinding('class.dragover') private isDragover = false
  @HostBinding('class.drop') private isDrop = false

  @HostListener('dragover', ['$event']) onDragOver(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.disabled) return

    this.isDragover = true
    this.isDrop = false
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.disabled) return

    this.isDragover = false
    this.isDrop = false
  }

  @HostListener('drop', ['$event']) public ondrop(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.disabled) return

    this.isDragover = false
    this.isDrop = true

    let files = event?.dataTransfer?.files ? event.dataTransfer.files : []
    if (files.length > 0) {
      this.onFileDropped.emit(files)
    }
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.disabled) {
      this.isDragover = false
      this.isDrop = false
    }
  }

}
