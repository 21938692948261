@switch (viewMode) {
  @case ('loading') {
    <span>
      {{ 'WFA.ADIS-EDITOR.LOADING' | translate }}
    </span>
  }
  @case ('error') {
    <span>
      {{ 'WFA.ADIS-EDITOR.LOADING-FAILED' | translate }}
    </span>
  }
  @case ('loaded') {
    <app-are-you-sure
      [show$]="staticEditorEnv.deleteDraftModal.show$"
      [userInput$]="staticEditorEnv.deleteDraftModal.userInput$"
      data-selenium-id="component-@adis-editor/are-you-sure"
    ></app-are-you-sure>
    <wfa-editor
      [editorEnv$]="editorEnv$"
      [requestWfaForm$]="requestWfaFormDraft$"
      (emitWfaForm)="saveWfaFormDraft($event)"
    ></wfa-editor>
  }
}
