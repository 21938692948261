import { NgIf, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounce } from 'projects/lib/src/lib/misc/debounce';
import { interval } from 'rxjs';
import { filter, map, mergeMap, startWith } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { DevBarComponent } from './dev-bar/dev-bar.component';
import { LegalDataFiles } from './entities/Legal.entity';
import { InstanceService } from './instance/instance.service';
import { LegalService } from './legal/legal.service';
import { OverflowHelpersService } from './misc/overflow-helpers.service';
import { ColorService } from './theming/service/color.service';
import { PageSizeService } from './theming/service/page-size.service';
import { WhitelabelingService } from './whitelabeling/whitelabeling.service';


@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    RouterOutlet,
    NgIf,
    DevBarComponent,
  ],
})
export class AppComponent implements OnInit, AfterViewInit {

  public isDemo = environment.demoMode
  public LegalFiles = LegalDataFiles;

  public previewColorMain = 206;
  public previewColorButton = 206;

  constructor(
    public pageSizeService: PageSizeService,
    public legalService: LegalService,
    public colorService: ColorService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private instanceService: InstanceService,
    private overflowHelpersService: OverflowHelpersService,
    private whitelabelingService: WhitelabelingService,
  ) {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
    // Register SVG Icons
    this.registerSvgIcons()

    // Setup Viewport-Height Fix
    this.applyViewportUnitFix()

    // Setup Overflow-Scroll Indicator
    interval(200).pipe(
      startWith(0),
      untilDestroyed(this),
    ).subscribe((_) => {
      this.updateOverflowScrollIndicator()
    })
  }

  ngOnInit() {
    // Dynamic Window-Titles
    // (Source: https://ultimatecourses.com/blog/dynamic-page-titles-angular-2-router-events)
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
      untilDestroyed(this),
    ).subscribe((event) => {
      const instanceShortName = this.instanceService.activeInstance?.shortName
      const instanceWhitelabelType = this.instanceService.activeInstance?.whitelabelType
      const instanceWhitelabelNameFormatted = this.whitelabelingService.nameFormatted(instanceWhitelabelType)
      const brand = (instanceWhitelabelNameFormatted && instanceWhitelabelNameFormatted !== 'keines') ? instanceWhitelabelNameFormatted : 'arzt-direkt'

      if (event['title'] && !!instanceShortName) {
        this.titleService.setTitle(`${event['title']} – ${instanceShortName} | ${brand}`)

      } else if (!event['title'] && !!instanceShortName) {
        this.titleService.setTitle(`${instanceShortName} | ${brand}`)

      } else if (event['title'] && !instanceShortName) {
        this.titleService.setTitle(`${event['title']} | ${brand}`)
      }
    });
  }

  ngAfterViewInit(): void {
    this.viewportFix(window);
  }

  /**
   * Applies a common fix/hack to ensure 100vh does exactly match the
   * viewport-height in iOS and some other browsers.
   *
   * Source: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
   */
  @HostListener('window:orientationchange')
  @HostListener('window:resize', ['$event'])
  @debounce(100)
  public applyViewportUnitFix(event?) {
    if (!event || !event.target) { return; }
    this.viewportFix(event.target);
  }

  private viewportFix(element: any) {
    if (!element || !element.innerHeight) { return; }
    const vh = element.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.pageSizeService.checkIfMobile(element.innerWidth);
    this.pageSizeService.setSizes(element.innerWidth, element.innerHeight)
  }

  /**
   * Checks whether any visible card-content overflows
   * to either show/hide the scroll indicator.
   */
  private updateOverflowScrollIndicator() {
    const OVERFLOW_CLASS = 'c-card--hasBottomOverflow'
    const cardEls = Array.from(document.querySelectorAll('mat-card.c-card:not(.c-card--noScroll)'))
    cardEls.forEach((cardEl) => {
      const addClass = !this.overflowHelpersService.elementIsScrolledToEnd(cardEl)
      addClass ? cardEl.classList.add(OVERFLOW_CLASS) : cardEl.classList.remove(OVERFLOW_CLASS)
    })
  }


  /**
   * Registering custom svg-icons.
   */
  registerSvgIcons() {
    [
      'insurance/pkv',
      'insurance/gkv',
      'insurance/hzv',
      'insurance/bg',
      'insurance/sz',
      'patient/reverse_camera',
      // 'icon_name', …
    ].forEach((icon) => {
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.url}/assets/icons/${icon}.svg`))
    });
  }

}
