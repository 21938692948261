import { Operator } from '../operator'

// Overload signatures
type F1<T1, T2> = (x: T1) => T2

export function flow<T1, T2, T3>(f1: F1<T1, T2>, f2: F1<T2, T3>): F1<T1, T3>

export function flow<T>(...functions: Operator<T>[]): Operator<T> {
  return (initialValue: T) =>
    functions.reduce((result, fn) => fn(result), initialValue)
}
