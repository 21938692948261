import Joi from 'joi'

import example from './human-readable.joi.example.json'

export const HumanReadableJoi = Joi.object()
  .unknown()
  .meta({
    className: 'HumanReadable',
  })
  .example(example)
