import { Route } from '@angular/router'

import { AdisEditorComponent } from '../adis/adis-editor/adis-editor.component'
import { SupportEditorComponent } from '../adis/support-editor/support-editor.component'
import { TestEditorComponent } from '../test/test-editor/test-editor.component'
import { TestViewerComponent } from '../test/test-viewer/test-viewer.component'
import { TestWebviewViewerComponent } from '../test/test-webview-viewer/test-webview-viewer.component'
import { UserMessageComponent } from '../user-message/user-message.component'
import { TestNotificationComponent } from './../test/test-notification.component'
import { AboutComponent } from './../utility-components/about.component'
import { FallbackRouteResolver } from './fallback-route.resolver'

export const aboutRoute: Route = {
  path: 'about',
  component: AboutComponent,
}

export const adisEditorRoute: Route = {
  path: 'adis-editor',
  component: AdisEditorComponent,
}

export const fallbackRoute: Route = {
  path: '**',
  component: UserMessageComponent,
  resolve: {
    routeResolver: FallbackRouteResolver,
  },
}

export const supportEditorRoute: Route = {
  path: 'support-editor',
  component: SupportEditorComponent,
}

export const testEditorRoute: Route = {
  path: 'test-editor',
  component: TestEditorComponent,
}

export const testNotificationRoute: Route = {
  path: 'test-notification',
  component: TestNotificationComponent,
}

export const testViewerRoute: Route = {
  path: 'test-viewer',
  component: TestViewerComponent,
}

export const testWebviewViewerRoute: Route = {
  path: 'test-webview-viewer',
  component: TestWebviewViewerComponent,
}

export const userMessageRoute: Route = {
  path: 'user-message/:messageType',
  title: 'Patientenformulare | Hinweis',
  component: UserMessageComponent,
}
