import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { InstanceService } from './instance/instance.service';

@Injectable({
  providedIn: 'root'
})
export class RerouteService implements OnDestroy {

  public previousUrl: string
  public currentUrl: string
  private unsubscribe$ = new Subject()

  constructor(
    private router: Router,
    private instanceService: InstanceService
  ) {

    this.updateCurrentUrl(this.router.url)

    // Protocl Router-Events for potential Reroute after Login
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (event: NavigationEnd) => {
        // Reset URLs on Logout
        if (event.url.endsWith('/auth/logout')) {
          console.info('Resetting Reroute-URLs on Logout..')
          this.resetUrls()
          return
        }
        this.updateCurrentUrl(event.url)
      }
    )

    // Prefix URLs with current Instance-ID if not already
    this.router.events.pipe(
      filter((_) => !!this.instanceService.activeInstance),
      // tap((event) => { console.log(event) }),  // DEBUG: Logs all Navigation-Events
      filter((event) => event instanceof NavigationStart),
      takeUntil(this.unsubscribe$)
    ).subscribe((event: NavigationStart) => {
      const instanceIdentifier = this.instanceService.activeInstance.identifier
      const isInstanceUrl = event.url.toLowerCase().startsWith(instanceIdentifier) || event.url.toLowerCase().startsWith('/' + instanceIdentifier)  //case insensitivity for instance-identifiers
      const isGlobalUrl = [
        'demo',
        'dokumente',
        'home',
        'portal',
        'webforms',
      ].some((url) => event.url.startsWith(url) || event.url.startsWith(`/${url}`))

      if (!isInstanceUrl && !isGlobalUrl) {
        console.log(`Rerouted '${event.url}' under instance '/${instanceIdentifier}/...'.`)
        this.router.navigateByUrl(this.instanceService.prependIdentifier(event.url))
      }
    })
  }



  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }


  public updateCurrentUrl(url: string) {
    // Protocol only '/dashboard/..' and '/auth/..' routes and
    // also allow them to be prefixed by an :instanceId
    const protocolRegex = new RegExp('^/?([^/]+/)?(dashboard|auth).*$')
    if (protocolRegex.test(url)) {
      this.previousUrl = this.currentUrl
      this.currentUrl = url
    }
  }

  public resetUrls() {
    this.previousUrl = '';
    this.currentUrl = '';
  }

}
