import { ChangeDetectorRef, Component, Input, OnInit, RendererFactory2, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import dayjs from 'dayjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { InstanceFormFileUploadSettings } from '../../entities/InstanceForm.entity';
import { Asset, AssetMeta } from '../../entities/PatientSession.entity';
import { SocketUserRole } from '../../entities/Socket.entity';
import { Origin } from '../../entities/WebRTC.entity';
import { AssetUploadFieldComponent, AssetUploadFieldFormat, AssetUploadFieldSettings } from '../../forms/fields/asset-upload-field.component';
import { ImageHelpersService } from '../../misc/image-helpers.service';
import { MimetypeHelpersService } from '../../misc/mimetype-helpers.service';
import { UsefulComponent } from '../../misc/useful.component';
import { BookingService } from '../booking.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DragNDropUploadDirective } from '../../forms/directives/drag-n-drop-upload.directive';

@Component({
    selector: 'app-booking-file-upload',
    templateUrl: './booking-file-upload.component.html',
    styleUrls: ['./booking-file-upload.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        DragNDropUploadDirective,
        NgIf,
        AssetUploadFieldComponent,
        NgFor,
        FontAwesomeModule,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        MatButtonModule,
        MatIconModule,
        AsyncPipe,
        TranslateModule,
    ],
})
export class BookingFileUploadComponent extends UsefulComponent implements OnInit {

  @ViewChild('uploadFieldComponent', { static: true }) uploadFieldComponent: AssetUploadFieldComponent
  @Input() settings: InstanceFormFileUploadSettings

  public readonly isDemoMode = environment.demoMode

  public MAX_AMOUNT_IMAGES = this.bookingService?.MAX_ALLOWED_FILES ?? 3
  public get assetFormGroup(): UntypedFormGroup { return this.bookingService.assetFormGroup }
  public uploadSettings: AssetUploadFieldSettings = {
    format: AssetUploadFieldFormat.Asset,
    mediaTypes: this.mimetypeHelpers.ALLOWED_MIME_TYPES,
    imageClass: 'c-asset-small-image',
    allowWebcamPhoto: true,
    imageScaleSize: [2000, 2000],
    hidePlaceholder: true,
    multiple: true,
    limit: this.MAX_AMOUNT_IMAGES,
    senderRole: SocketUserRole.Patient,
    capturedRole: SocketUserRole.Patient,
  }

  public get assets(): UntypedFormArray { return this.assetFormGroup.get('assets') as UntypedFormArray }
  public get newAsset(): UntypedFormControl { return this.assetFormGroup.get('newAsset') as UntypedFormControl }


  constructor(
    public mimetypeHelpers: MimetypeHelpersService,
    private imageHelpers: ImageHelpersService,
    private cd: ChangeDetectorRef,
    private rendererFactory: RendererFactory2,
    private bookingService: BookingService
  ) {
    super()
  }

  ngOnInit() {
    super.ngOnInit()

    // Push new asset if `newAsset` values changes and reset it
    this.newAsset.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      const name = this.newAsset?.value?.meta?.['name'] ? this.newAsset.value.meta['name'] : ''
      const type = this.newAsset?.value?.meta?.['type'] ? this.newAsset.value.meta['type'] : ''
      if (!this.newAsset.value || !type || !name) return

      const newAssetFormGroup = this.createAssetFormGroup()
      newAssetFormGroup.patchValue(this.newAsset.value)
      newAssetFormGroup.get('isImage').setValue(this.mimetypeHelpers.IMAGE_MIME_TYPES.includes(type), { emitEvent: false })
      newAssetFormGroup.get('extension').setValue(this.getFileExt(name), { emitEvent: false })

      this.assets.push(newAssetFormGroup)
      this.newAsset.setValue(null, { emitEvent: false })
      console.log("ngOnInit sub this.assets = ", this.assets)
      this.cd.detectChanges()
    })

    // Update upload-limit if assets-array changed
    this.assets.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      const newLimit = this.MAX_AMOUNT_IMAGES - (this.assets.value || []).length
      this.uploadFieldComponent.settings.limit = Math.max(newLimit, 0)
    })
  }


  /**
   * Returns a new FormGroup-instance for a Asset-item
   */
  private createAssetFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dataUri: new UntypedFormControl('', [Validators.required]),
      meta: new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required]),
        createdAt: new UntypedFormControl('', [Validators.required]),
        type: new UntypedFormControl('', [Validators.required]),
        size: new UntypedFormControl('', [Validators.required]),
        origin: new UntypedFormControl('', []),
        senderRole: new UntypedFormControl('', []),
        capturedRole: new UntypedFormControl('', []),
        description: new UntypedFormControl('', []),
      }),

      // UI-Helpers
      isImage: new UntypedFormControl({ value: false, disabled: true }),
      extension: new UntypedFormControl({ value: '', disabled: true }),
    })
  }


  /**
   * Returns file-extension of given filename-string
   */
  private getFileExt(filename: string): string {
    return /(?:\.([^.]+))?$/.exec(filename)[1]
  }


  /**
   * Removes the asset at the given index
   */
  public removeAssetAt(index: number) {
    if (index < 0 || index >= this.assets.length) return
    this.assets.removeAt(index)
    this.cd.detectChanges()
  }


  /**
   * Returns all uploaded assets as an Asset-array.
   */
  public getAssets(): Asset[] {
    return this.assets.value
  }


  /**
   * Downloads given asset
   */
  public downloadAsset(asset: Asset) {
    const renderer = this.rendererFactory.createRenderer(null, null)
    const a = renderer.createElement("a")
    a.setAttribute("href", asset.dataUri)
    a.setAttribute("download", asset.meta.name)
    a.click()
    a.remove()
  }


  /**
   * DEBUG-ONLY: Automatic upload of one sample-image
   */
  public magicFill() {
    if (!this.isDemoMode) return

    this.imageHelpers.loadBase64Image('/assets/images/patient/samples/file-upload-sample.jpg', 'image/jpeg').pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((dataUri) => {
      const meta: AssetMeta = {
        name: 'testbild.jpg',
        createdAt: dayjs().toISOString(),
        type: 'image/jpeg',
        size: 38816,
        origin: Origin.Upload,
        senderRole: SocketUserRole.Patient,
        capturedRole: SocketUserRole.Patient,
        description: 'Donec ullamcorper nulla non metus auctor fringilla',
      }
      const asset: Asset = {
        dataUri,
        meta
      }
      this.newAsset.setValue(asset)
    })
  }
}
