/**
 * Calculates the next index in a circular manner.
 *
 * If the current index `n` is greater than or equal to the total length `length`,
 * it wraps around to 0.
 *
 * @param {number} n - The current index.
 * @param {number} length - The total number of elements in the circular structure.
 * @returns {number} The next index in a circular manner.
 *
 * @example
 * // Returns 1
 * const result1 = next(0, 5); // 0 + 1 % 5 = 1
 *
 * // Returns 3
 * const result2 = next(2, 10); // 2 + 1 % 10 = 3
 *
 * // Returns 0
 * const result3 = next(4, 5); // 4 + 1 % 5 = 0
 *
 * // Returns 0
 * const result4 = next(10, 10); // 10 + 1 % 10 = 0
 *
 * // Returns 0
 * const result5 = next(12, 10); // 12 + 1 % 10 = 2
 */
export function next(n: number, length: number): number {
  if (n >= length) return 0
  return (n + 1) % length
}
