// file for validators that have a general purpose and can't be attributed to a specific field category

import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";


/**
 * Validator that requires the control's value to NOT match the provided regex
 * pattern.
 * Note: Mostly 'inverting' the original code from {@link Validators.pattern},
 * however, while Validators.pattern explicitly matches against the whole string
 * by adding '^' and '$', notPatternValidator retains the regex as provided,
 * allowing to check for unwanted parts in the control's value.
 * 
 * @remark
 * Do NOT use this function directly for code outside of `validators/`,
 * use `ADValidators.notPattern` instead.
 */
export function notPatternValidator(pattern: string | RegExp): ValidatorFn {
  // for the original Angular function compare: 
  // https://github.com/angular/angular/blob/16.2.x/packages/forms/src/validators.ts#L533
  if (!pattern) return Validators.nullValidator;
  const regex = new RegExp(pattern); 

  return (control: AbstractControl): ValidationErrors|null => {
    if (control.value == null || (control.value as string).length === 0) {
      return null;  // don't validate empty values
    }
    const value: string = control.value;

    // String.prototype.match() returns an array which contains also information
    // about index:, input: and groups: defined in the regex - 
    // only keep the first matched string and return that in the ValidationErrors
    const matchAllFields: string[]|null = value.match(regex);
    const match = matchAllFields ? matchAllFields[0] : null;
    
    return regex.test(value) ? { 'pattern': { 'patternFound': match } } : null;
  };
}