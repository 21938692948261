export const customTranslations = {
  multilinkNextSurvey: {
    de: 'Nächstes Formular',
  },
  multilinkSubmitSurvey: {
    de: 'Alle ausgefüllten Formulare einreichen',
  },
  clearCaption: {
    de: 'Alle entfernen',
  },
  completingSurvey: {
    de: 'Ihre Angaben wurden erfolgreich übermittelt. Vielen Dank für das Ausfüllen des Formulars!',
    en: 'Your information has been successfully submitted. Thank you for completing the form!',
    es: 'Su información ha sido enviada con éxito. Gracias por completar el formulario!',
    fr: `Vos informations ont été soumises avec succès. Merci d'avoir rempli le formulaire !`,
    it: 'Le tue informazioni sono state inviate con successo. Grazie per aver compilato il modulo!',
  },
  emptyMessage: {
    de: 'Kein Eintrag mit diesem Suchbegriff vorhanden.',
    en: 'No entry with this search term available.',
    es: 'No hay ninguna entrada disponible con este término de búsqueda.',
    fr: `Il n'y a pas d'entrée avec ce terme de recherche.`,
    it: 'Non sono disponibili voci con questo termine di ricerca',
  },
  numericError: {
    de: '',
    en: '',
    es: '',
    fr: '',
    it: '',
  },
  ok: {
    de: 'Fortfahren',
    en: 'Continue',
    es: 'Continuar',
    fr: 'Continuer',
    it: 'Continua',
  },
  takePhotoCaption: {
    de: 'Foto aufnehmen',
  },
}
