<!-- Video and Snapshot-Canvas -->
<canvas #canvasEl></canvas>
<video #videoEl playsinline>
  {{'CAMERA-SNAPSHOT.NO-VIDEO' | translate}}
</video>

<!-- Overlay image -->
<div mat-flat-button class="overlayImage" *ngIf="overlayImagePath && !snapshot">
  <img src="{{overlayImagePath}}">
</div>
<!-- Device-List -->
<div attr.data-selenium-id="trigger-kamera-liste" mat-flat-button class="device-list-trigger"
  *ngIf="!snapshot && mediaDevices.length >= 1" [matMenuTriggerFor]="devicesMenu">
  <!--<img src="/assets/icons/videochat/reverse_camera.svg">-->
  <mat-icon>settings</mat-icon>
</div>
<mat-menu #devicesMenu="matMenu" yPosition="above">
  <button mat-menu-item *ngFor="let device of mediaDevices; let i = index" attr.data-selenium-id="button-geraet-{{i}}"
    (click)="startStream(device)">
    {{ mediaDeviceLabels[device.deviceId] | translate }}
  </button>
</mat-menu>

<!-- Loading-Screen -->
<mat-spinner *ngIf="!isPlaying && !isError && !snapshot" color="primary" [diameter]="50"></mat-spinner>
<p *ngIf="!isPlaying && !isError && !snapshot" class="loadingText">
  {{'CAMERA-SNAPSHOT.CAMERA-PERMISSION' | translate}}
</p>

<!-- Error-Screen -->
<div class="errorMessage" *ngIf="isError && !isPlaying && !snapshot">
  <fa-icon class="errorIcon" [icon]="['fal', 'webcam-slash']" size="3x"></fa-icon>
  <p>{{'CAMERA-SNAPSHOT.CAMERA-PERMISSION-ERROR' | translate}} <a
      (click)="openCameraHelpPage()">{{'CAMERA-SNAPSHOT.ERROR-SEARCH' | translate}}</a></p>
  <button mat-flat-button color="primary" (click)="startStream()">{{'GLOBAL.TRY-AGAIN' | translate}}</button>
</div>

<!-- Snapshot-Preview -->
<img *ngIf="snapshot" class="snapshot" [src]="snapshot">
<img class="deleted-snapshot" #deletedSnapshotEl>
<div class="shutter-overlay" #shutterOverlayEl></div>