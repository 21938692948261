<mat-form-field [formGroup]="formGroup" appearance="outline">
  <mat-label>{{ 'USER-DATA-ARZT.specialist' | translate}}</mat-label>
  <input type="text" matInput formControlName="facharzt" [matAutocomplete]="facharztAutocomplete">
  <mat-error *ngIf="facharzt.errors?.idInvalid">{{'USER-DATA-ARZT.no-valid-specialist' | translate}}</mat-error>

  <div matSuffix style="display:flex; align-items: center">
    <mat-icon style="color: rgba(0, 0, 0, 0.54); box-sizing: content-box;"
      [style.padding]="!facharzt.valid || !facharzt.value || showSecondFacharzt? '12px':'0'"
      matTooltip="{{ 'USER-DATA-ARZT.specialist-tooltip' | translate}}">
      info_outline</mat-icon>
    <button mat-icon-button *ngIf="facharzt.valid && facharzt.value && !showSecondFacharzt"
      (click)="$event.stopPropagation(); showSecondFacharzt = true"
      matTooltip="{{ 'USER-DATA-ARZT.further-specialist' | translate}}">
      <fa-icon [icon]="['far', 'plus']" style="color: rgba(0, 0, 0, 0.54);"></fa-icon>
    </button>
  </div>
  <mat-autocomplete #facharztAutocomplete="matAutocomplete" [displayWith]="getFacharztDisplayLabel.bind(this)"
    autoActiveFirstOption>

    <mat-option *ngFor="let fa of filteredFacharztOptions" value="{{ fa.id }}" class="mat-option--isMultiline">
      <span>{{fa.name | i18nString}}</span>
      <span style="opacity: .33">
        {{ getWboDisplayLabel(fa.verordnung) }}
      </span>

    </mat-option>

  </mat-autocomplete>
</mat-form-field>


<mat-form-field [formGroup]="formGroup" *ngIf="facharzt.valid && applicableFacharztSchwerpunkte?.length"
  appearance="outline">
  <mat-label>{{ 'USER-DATA-ARZT.focus' | translate}}</mat-label>
  <mat-select formControlName="facharztSchwerpunkte" multiple>

    <mat-option *ngFor="let item of applicableFacharztSchwerpunkte" value="{{ item.id }}">{{ item.name | i18nString }}
    </mat-option>

  </mat-select>
</mat-form-field>


<mat-form-field *ngIf="facharzt.value && showSecondFacharzt" [formGroup]="formGroup" appearance="outline">
  <mat-label>{{ 'USER-DATA-ARZT.further-specialist' | translate}}</mat-label>
  <input type="text" matInput formControlName="facharzt_2" [matAutocomplete]="secondFacharztAutocomplete">
  <mat-error *ngIf="facharzt_2.errors?.idInvalid">{{'USER-DATA-ARZT.no-valid-specialist' | translate}}</mat-error>
  <mat-autocomplete #secondFacharztAutocomplete="matAutocomplete" [displayWith]="getFacharztDisplayLabel.bind(this)"
    autoActiveFirstOption>
    <mat-option *ngFor="let fa of filteredSecondFacharztOptions" value="{{ fa.id }}" class="mat-option--isMultiline">
      <span>{{fa.name | i18nString}}</span>
      <span style="opacity: .33">
        {{ getWboDisplayLabel(fa.verordnung) }}
      </span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-form-field [formGroup]="formGroup" *ngIf="facharzt_2.valid && applicableSecondFacharztSchwerpunkte?.length"
  appearance="outline">
  <mat-label>{{ 'USER-DATA-ARZT.further-focus' | translate}}</mat-label>
  <mat-select formControlName="facharztSchwerpunkte_2" multiple>

    <mat-option *ngFor="let item of applicableSecondFacharztSchwerpunkte" value="{{ item.id }}">{{ item.name |
      i18nString }}
    </mat-option>

  </mat-select>
</mat-form-field>



<mat-form-field [formGroup]="formGroup" appearance="outline">
  <mat-label>{{ 'USER-DATA-ARZT.add-train' | translate}}</mat-label>
  <mat-chip-grid #zwbList aria-label="Auswahl Zusatzweiterbildungen" formControlName="facharztZusatzWeiterbildungen">
    <mat-chip-row *ngFor="let wb of facharztZusatzWeiterbildungen.value" [removable]="true" value="{{wb}}"
      (removed)="removeZusatzWeiterbildung(wb)">
      {{getZusatzWeiterbildungDisplayLabel(wb)}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>
    <input matInput style="align-self: center" placeholder="{{ 'USER-DATA-ARZT.add-train-add' | translate}}"
      #addZusatzWeiterbildungInput [formControl]="addZusatzWeiterbildungCtrl" [matChipInputFor]="zwbList"
      [matChipInputSeparatorKeyCodes]="chipListSeparatorKeyCodes" [matChipInputAddOnBlur]="false"
      (matChipInputTokenEnd)="addZusatzWeiterbildung($event)" [matAutocomplete]="zusatzWeiterbildungenAutocomplete">
  </mat-chip-grid>
  <mat-autocomplete #zusatzWeiterbildungenAutocomplete="matAutocomplete"
    [displayWith]="getZusatzWeiterbildungDisplayLabel.bind(this)" (optionSelected)="selectedZusatzWeiterbildung($event)"
    autoActiveFirstOption>
    <mat-option *ngFor="let wb of filteredZusatzWeiterbildungenOptions" value="{{ wb.id }}"
      class="mat-option--isMultiline">
      <span>{{wb.name | i18nString}}</span>
      <span *ngIf="getWboDisplayLabel(wb.verordnung)" style="opacity: .33">
        {{ getWboDisplayLabel(wb.verordnung) }}
      </span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>


<!--<mat-form-field [formGroup]="formGroup" *ngIf="facharzt.valid && facharzt.value && !isDemo" appearance="fill">
  <mat-label>{{ 'USER-DATA-ARZT.add-des' | translate}}</mat-label>
  <input matInput formControlName="facharztZusatz" spellcheck="false" name="facharztZusatz">
</mat-form-field>-->

<mat-form-field [formGroup]="formGroup" *ngIf="facharzt.valid && facharzt.value" appearance="outline">
  <mat-label>{{ 'USER-DATA-ARZT.add-des' | translate}}</mat-label>
  <input matInput formControlName="facharztZusatz" spellcheck="false" name="facharztZusatz"
    [matAutocomplete]="zusatzBezeichnungenAutocomplete" (change)="checkOnChange()"
    (click)="addCommaOnClick(); trigger.openPanel()" (blur)="checkOnChange()" #trigger="matAutocompleteTrigger">
  <mat-error *ngIf="facharztZusatz.errors?.listInvalid">{{ 'USER-DATA-ARZT.error-invalid-entries' | translate}}
  </mat-error>
  <button mat-icon-button matSuffix *ngIf="!showSuggestionField && showSuggestionOption"
    (click)="$event.stopPropagation(); showSuggestionField = true"
    matTooltip="{{ 'USER-DATA-ARZT.propose-add-des' | translate}}">
    <fa-icon [icon]="['far', 'plus']" style="color: rgba(0, 0, 0, 0.54)"></fa-icon>
  </button>

  <mat-icon matSuffix *ngIf="!showSuggestionOption" style="color: rgba(0, 0, 0, 0.54)"
    matTooltip="{{ 'USER-DATA-ARZT.proposal-profile-info' | translate}}">info_outline
  </mat-icon>

  <mat-autocomplete #zusatzBezeichnungenAutocomplete="matAutocomplete"
    [displayWith]="getZusatzBezeichnungDisplayLabel.bind(this)" (closed)="validateFacharztZusatz()"
    autoActiveFirstOption>
    <mat-option *ngFor="let zb of filteredZusatzBezeichnungenOptions"
      [value]="autocompleteZusatzBezeichnungenValue(zb.name)" class="mat-option--isMultiline">
      <span>{{zb.name | i18nString}}</span>
      <span style="opacity: .33"></span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>


<mat-form-field [formGroup]="formGroup" *ngIf="showSuggestionField" appearance="outline">
  <mat-label>{{ 'USER-DATA-ARZT.proposal-add-des' | translate}}</mat-label>
  <input matInput formControlName="facharztZusatzVorschlag" spellcheck="true" name="facharztZusatzVorschlag">

  <!--<fa-icon matSuffix [icon]="['far', 'circle-question']" style="color: rgba(0, 0, 0, 0.54); font-size: 20px "
    matTooltip="{{ 'USER-DATA-ARZT.proposal-review-info' | translate}}">
  </fa-icon>-->
  <mat-icon matSuffix style="color: rgba(0, 0, 0, 0.54)"
    matTooltip="{{ 'USER-DATA-ARZT.proposal-review-info' | translate}}">info_outline
  </mat-icon>

</mat-form-field>
<div *ngIf="showSuggestionField && facharztZusatzVorschlag.value && suggestionArray.length!==0">
  <p>{{ 'USER-DATA-ARZT.proposal-list-check' | translate}}</p>
  <div style="max-height: 256px; overflow: auto;">
    <mat-option *ngFor="let suggestion of suggestionArray" value="{{ suggestion.id }}" class="mat-option--isMultiline"
      (click)="setValuesToSelection(suggestion)" matTooltip="{{ 'USER-DATA-ARZT.add-to-profile' | translate}}">
      <span *ngIf="suggestion.ausbildungsType==='ZusatzWeiterbildung'" style="overflow-x:auto">{{
        'USER-DATA-ARZT.add-train-singular' | translate}}:
        {{suggestion.name| i18nString}}</span>
      <span *ngIf="suggestion.ausbildungsType==='ZusatzWeiterbildung'"
        style="opacity: .33">{{getWboDisplayLabel(suggestion.verordnung)}}</span>
      <span *ngIf="suggestion.ausbildungsType==='FacharztAusbildung'" style="overflow-x:auto">{{
        'USER-DATA-ARZT.specialist' | translate}}:
        {{suggestion.name| i18nString}}</span>
      <span *ngIf="suggestion.ausbildungsType==='FacharztAusbildung'"
        style="opacity: .33">{{getWboDisplayLabel(suggestion.verordnung)}}</span>
      <span *ngIf="suggestion.ausbildungsType==='FacharztSchwerpunkt'" style="overflow-x:auto">{{
        'USER-DATA-ARZT.focus-singular' | translate}}:
        {{suggestion.name| i18nString}}
      </span>
      <span *ngIf="suggestion.ausbildungsType==='FacharztSchwerpunkt'" style="opacity: .33">{{
        'USER-DATA-ARZT.for-specialist' | translate}}:
        {{getFacharztDisplayLabel(suggestion.requiredFacharzt)}}, {{getWboDisplayLabel(suggestion.verordnung)}}</span>
      <span *ngIf="suggestion.ausbildungsType==='ZusatzBezeichnung'" style="overflow-x:auto">{{
        'USER-DATA-ARZT.add-des-singular' | translate}}:
        {{suggestion.name| i18nString}}</span>
      <span *ngIf="suggestion.ausbildungsType==='ZusatzBezeichnung'"></span>
    </mat-option>
  </div>
</div>