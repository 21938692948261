import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatauriHelpersService } from '../misc/datauri-helpers.service';
import { SocketFormattersService } from '../socket/socket-formatters.service';

export enum ImageScaleMode {
  Contain = 'contain',
  Cover = 'cover',
}

@Injectable({
  providedIn: 'root'
})
export class ImageHelpersService implements OnDestroy {

  private unsubscribe$ = new Subject()
  private renderer: Renderer2
  private BASE_IMG_QUALITY = .7

  constructor(
    rendererFactory: RendererFactory2,
    private socketFormattersService: SocketFormattersService,
    private datauriHelpersService: DatauriHelpersService,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }

  /**
   * Creates and returns virtual canvas & 2d-context
   * Tom: I added { alpha: false } to make the app faster and the photos
   * lower size, and because as far as I know we don't use transparency.
   * If you need transparency you should remove that.
   * Marc: I modified createCanvas for optional transparency to use in image Upload
   * This should only have consequences for asset-upload-field-component, which uses scaleBase64 with transparency enabled
   */
  private createCanvas(w: number, h: number, background = 'white', useTransparency = false): [HTMLCanvasElement, CanvasRenderingContext2D] {
    const canvas = this.renderer.createElement('canvas')
    canvas.width = w
    canvas.height = h
    const ctx = !useTransparency ? canvas.getContext('2d', { alpha: false }) : canvas.getContext('2d', { alpha: true })
    ctx.fillStyle = !useTransparency ? background : 'transparent'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    return [canvas, ctx]
  }

  /**
   * Calculates new dimensions for the given `size` by covering the given `boxSize` and keeping its aspect-ratio.
   * If the ratio of `size` and `boxSize` differs, the returned sized must be partly cropped on one side.
   * @param size Original dimensions of the image.
   * @param boxSize Dimensions of the bounding box which should be covered by the image.
   * @param doUpscale If true and size is smaller than boxSize, dimensions are upscaled in case they are smaller.
   */
  public sizeToCoverBox(size: [number, number], boxSize: [number, number], doUpscale: boolean = false): [number, number] {
    const newSize: [number, number] = [size[0], size[1]]
    const ratio = size[0] / size[1]

    newSize[0] = ratio > 1 ? boxSize[1] * ratio : boxSize[0]
    newSize[1] = ratio > 1 ? boxSize[1] : boxSize[0] / ratio
    if (doUpscale && (newSize[0] < boxSize[0] || newSize[1] < boxSize[1])) {
      newSize[0] = ratio <= 1 ? boxSize[1] * ratio : boxSize[0]
      newSize[1] = ratio <= 1 ? boxSize[1] : boxSize[0] / ratio
    }

    if (!doUpscale && (newSize[0] > size[0] || newSize[1] > size[1])) {
      return size
    } else {
      return newSize
    }
  }

  /**
   * Calculates new dimensions for the given `size` by fitting (contain) the given `boxSize` and keeping its aspect-ratio.
   * @param size Original dimensions of the image.
   * @param boxSize Dimensions of the bounding box to fit the image in.
   * @param doUpscale If true and size is smaller than boxSize, dimensions are upscaled in case they are smaller.
   */
  public sizeContainInBox(size: [number, number], boxSize: [number, number], doUpscale: boolean = false): [number, number] {
    const newSize: [number, number] = [size[0], size[1]]
    const ratio = size[0] / size[1]
    newSize[0] = Math.min(boxSize[0], boxSize[1] * ratio)
    newSize[1] = Math.min(boxSize[1], boxSize[0] / ratio)

    if (!doUpscale && (newSize[0] > size[0] || newSize[1] > size[1])) {
      return size
    } else {
      return newSize
    }
  }

  /**
   * Loads image with given source as Base64-String
   */
  public loadBase64Image(src: string, contentType: string = 'image/jpeg'): Observable<string> {
    const result$ = new Subject<string>()
    const img = new Image()

    fromEvent(img, 'error').pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((error) => { result$.error(error) })

    fromEvent(img, 'load').pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      () => {
        const [canvas, ctx] = this.createCanvas(img.naturalWidth, img.naturalHeight)
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
        const base64Image = canvas.toDataURL(contentType, this.BASE_IMG_QUALITY)

        result$.next(base64Image)
        result$.complete()
      }, (error) => {
        result$.error(error)
      }
    )
    img.src = src
    return result$
  }

  public imagePathToFile(imagePath: string, fileName: string, mimetype: string) {
    return (fetch(imagePath)
      .then((result) => result.arrayBuffer())
      .then((buffer) => new File([buffer], fileName, { type: mimetype }))
    )

  }

  public makeImageUri(data: string, contentType: string) {
    return `data:${contentType};charset=utf-8;base64, ${data}`;
  }

  /**
   * Scales the given `base64Image` to the given `scaleSize` with the given scaling mode (contain or cover)
* and saves it in optimized quality. Upscaling is disabled by default.
* Transforms svg to jpg
*/
  public scaleBase64Image(base64Image: string,
    scaleSize?: [number, number],
    mode?: ImageScaleMode,
    doUpscale: boolean = false,
    useTransparency: boolean = false,
    saveType: string = 'image/jpeg',
    imageQuality: number = this.BASE_IMG_QUALITY): Observable<string> {
    const startTime = new Date().getTime();
    const result$ = new Subject<string>()
    const img = new Image()
    fromEvent(img, 'error').pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((error) => { result$.error(error) })
    fromEvent(img, 'load').pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      () => {
        if (!scaleSize || scaleSize[0] === 0 || scaleSize.length !== 2 || !mode) {
          scaleSize = [img.naturalWidth, img.naturalHeight]
          mode = ImageScaleMode.Contain
        }
        // Determine new image dimensions and coordinates depending on downscale-mode
        let canvasSize, newImageSize, imagePosition = [0, 0]
        if (mode === ImageScaleMode.Contain) {
          newImageSize = this.sizeContainInBox([img.naturalWidth, img.naturalHeight], scaleSize, doUpscale)
          canvasSize = newImageSize
        } else if (mode === ImageScaleMode.Cover) {
          newImageSize = this.sizeToCoverBox([img.naturalWidth, img.naturalHeight], scaleSize, doUpscale)
          canvasSize = [Math.min(scaleSize[0], newImageSize[0]), Math.min(scaleSize[1], newImageSize[1])]
          imagePosition[0] = (canvasSize[0] - newImageSize[0]) / 2
          imagePosition[1] = (canvasSize[1] - newImageSize[1]) / 2
        }
        // Draw on canvas and save in optimized quality
        const [canvas, ctx] = this.createCanvas(canvasSize[0], canvasSize[1], undefined, useTransparency)
        ctx.drawImage(img, imagePosition[0], imagePosition[1], newImageSize[0], newImageSize[1])
        // let imgData = ctx.getImageData(imagePosition[0], imagePosition[1], newImageSize[0], newImageSize[1]);
        const base64String = canvas.toDataURL(saveType, imageQuality)
        const endTime = new Date().getTime();
        console.log('scaleBase64Image rescaled image in ' + String(endTime - startTime) + 'ms from(W, H) = (', img.width, ' ', img.height, ') to (', newImageSize[0], ', ', newImageSize[1], ')' + ' saved as ' + saveType)
        result$.next(base64String)
        result$.complete()
      }, (error) => {
        result$.error(error)
      }
    )
    img.src = base64Image
    return result$
  }
}
