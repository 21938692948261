@if (showPublish) {
  <button
    matSuffix
    mat-icon-button
    aria-label="Publish"
    class="action-button"
    data-selenium-id="button-@action-buttons/publish"
    [matTooltip]="'WFA.ACTION-BUTTONS.PUBLISH' | translate"
    (click)="onPublish.emit()"
  >
    <fa-icon [icon]="faCheck"></fa-icon>
  </button>
}

@if (showPermalink) {
  <button
    matSuffix
    mat-icon-button
    aria-label="Permalink"
    class="action-button"
    [matTooltip]="
      (permalink
        ? 'WFA.ACTION-BUTTONS.PERMALINK-DISABLE'
        : 'WFA.ACTION-BUTTONS.PERMALINK-ENABLE'
      ) | translate
    "
    (click)="onPermalink.emit()"
    data-selenium-id="button-@action-buttons/permalink"
  >
    <fa-icon
      [icon]="permalink ? faThumbTack : faThumbTackSlash"
      [styles]="permalink ? { color: '#1D8BF1' } : { color: 'grey' }"
    ></fa-icon>
  </button>
}

<button
  matSuffix
  mat-icon-button
  aria-label="Edit"
  class="action-button"
  data-selenium-id="button-@action-buttons/edit"
  [matTooltip]="'WFA.ACTION-BUTTONS.EDIT' | translate"
  (click)="onEdit.emit()"
>
  <fa-icon [icon]="faPenToSquare"></fa-icon>
</button>

<button
  matSuffix
  mat-icon-button
  aria-label="Copy"
  class="action-button"
  data-selenium-id="button-@action-buttons/copy"
  [matTooltip]="'WFA.ACTION-BUTTONS.COPY' | translate"
  (click)="onCopy.emit()"
>
  <fa-icon [icon]="faCopy"></fa-icon>
</button>

<button
  matSuffix
  mat-icon-button
  aria-label="Delete"
  class="action-button"
  data-selenium-id="button-@action-buttons/delete"
  [matTooltip]="'WFA.ACTION-BUTTONS.DELETE' | translate"
  (click)="onDelete.emit()"
>
  <fa-icon [icon]="faTrash"></fa-icon>
</button>
