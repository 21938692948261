import { convertToWfaFormId, WfaForm } from '@arzt-direkt/wfa-definitions'
import { Maybe, nullish } from '@arzt-direkt/wfa-generic-utils'
import { getCurrentDateTime } from '@arzt-direkt/wfa-generic-utils'

export function getWfaFormId(
  wfaForm: Maybe<Partial<WfaForm>>,
  date: Date = new Date(),
): string {
  return (wfaForm as WfaForm)?.wfaFormId ?? getPristineWfaFormId(wfaForm, date)
}

function getPristineWfaFormId(
  wfaForm: Maybe<Partial<WfaForm>>,
  date: Date = new Date(),
): string {
  const dateTime = getCurrentDateTime(date)
  if (nullish(wfaForm)) {
    return `form-${dateTime}`
  }

  const title = wfaForm.title?.de ?? wfaForm.title ?? 'form'
  const description = wfaForm.description?.de ?? wfaForm.description ?? ''
  const candidateWfaFormId = convertToWfaFormId(`${title}-${description}`)
  if (candidateWfaFormId === 'form') {
    return `form-${dateTime}`
  }

  return candidateWfaFormId
}
