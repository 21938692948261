import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, Subject } from 'rxjs'

export type AreYouSureUserInput = 'yes' | 'no' | 'abort'
export interface AreYouSureParams {
  show$: BehaviorSubject<boolean>
  userInput$: Subject<AreYouSureUserInput>
}

@Component({
  selector: 'app-are-you-sure',
  standalone: true,
  template: `
    @if (show$ | async) {
      <div class="overlay" (click)="onAbort()">
        <div class="dialog" (click)="$event.stopPropagation()">
          <p>{{ 'WFA.ARE-YOU-SURE.ARE-YOU-SURE' | translate }}</p>
          <button mat-button color="primary" (click)="onYes()">
            {{ 'WFA.ARE-YOU-SURE.YES' | translate }}
          </button>
          <button mat-button color="warn" (click)="onNo()">
            {{ 'WFA.ARE-YOU-SURE.NO' | translate }}
          </button>
        </div>
      </div>
    }
  `,
  styleUrls: [`./overlay-dialog.scss`],
  imports: [CommonModule, MatDialogModule, MatButtonModule, TranslateModule],
})
export class AreYouSureComponent implements AreYouSureParams {
  @Input() show$ = new BehaviorSubject<boolean>(false)
  @Input() userInput$ = new Subject<AreYouSureUserInput>()

  onYes() {
    this.userInput$.next('yes')
    this.show$.next(false)
  }

  onNo() {
    this.userInput$.next('no')
    this.show$.next(false)
  }

  onAbort() {
    this.userInput$.next('abort')
    this.show$.next(false)
  }

  constructor(private translate: TranslateService) {}
}
