import { AdPayCheckoutService } from '@a-d/dashboard/ad-pay/ad-pay-checkout.service';
import { StoreTypes } from '@a-d/entities/AdPay.entity';
import { ContactSettings, EmailFormat, PatientTargetType } from '@a-d/entities/Calendar.entity';
import { BaseLanguage } from '@a-d/entities/I18N.entity';
import { Dialect } from '@a-d/entities/Instance.entity';
import { AnamneseFormCheckerService } from '@a-d/misc/anamnese-form-checker.service';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggle, MatButtonToggleGroup, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscriber, Subscription, of } from 'rxjs';
import { delay, mergeMap } from 'rxjs/operators';
import { AppointmentCategory, AppointmentType, AppointmentTypeEmailDefinition, AppointmentTypeEmailSettings, BookingStep, I18NSTRING_EMPTY, I18NString, OtkBookingStrategy } from '../../entities/Booking.entity';
import { IOTKBookingLimit, IOTKBookingLimitSettings } from '../../entities/Calendar.entity';
import { I18NStringPipe } from '../../i18n/i18n.pipe';
import { LanguageService } from '../../i18n/language.service';
import { InstanceService } from '../../instance/instance.service';
import { BookingStandaloneService } from '../booking-standalone.service';
import { BookingService } from '../booking.service';
import { AppointmentTypeComponent } from './appointment-type/appointment-type.component';
import { BookingTypeUtil } from './util-functions/booking-type-util';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-booking-type',
  templateUrl: './booking-type.component.html',
  styleUrls: ['./booking-type.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, MatButtonToggleModule, NgFor, NgClass, AppointmentTypeComponent, MatExpansionModule, TranslateModule, I18NStringPipe]
})
export class BookingTypeComponent implements OnInit, AfterViewInit {

  private _appointmentCategoriesNonGeneric: AppointmentCategory[] = [];
  private bookingServiceInit$ = this.bookingService.isBookingTypeInitialized$


  doesParamExistCategory = true // true to avoid displaying the generic category for a moment even if a param is given
  doesParamExistType: boolean
  isContactFormActive = false
  public failureAssetUrl = `${environment.url}/assets/otk/booking/failure.svg`
  contactFormName = this.translate.instant('OTK.BOOKING-TYPE.CONTACT-FORM-NAME')
  contactFormDescription: SafeHtml
  contactSettings: ContactSettings
  appointmentTypeContactSettings: AppointmentType // a dummy appointment type which contains the name and the description for the contact form.
  //get appointmentCategoriesNonGeneric(): AppointmentCategory[] { return this.bookingService.appointmentCategoriesNonGeneric }
  get appointmentCategoriesNonGeneric() {
    return this._appointmentCategoriesNonGeneric;
  }
  get appointmentCategoryNonGeneric(): AbstractControl<AppointmentCategory> { return this.bookingService.appointmentCategoryNonGeneric }
  get appointmentCategoryGeneric(): AppointmentCategory { return this.bookingService.appointmentCategoryGeneric }

  private _appointmentTypesOfSelectedAppointmentCategory$: BehaviorSubject<AppointmentType[]> = new BehaviorSubject([]);
  //appointmentTypesOfSelectedAppointmentCategory: AppointmentType[] = undefined
  public get appointmentTypesOfSelectedAppointmentCategory() {
    return this._appointmentTypesOfSelectedAppointmentCategory$.value
  }
  public set appointmentTypesOfSelectedAppointmentCategory(value: AppointmentType[]) {
    this._appointmentTypesOfSelectedAppointmentCategory$.next(value);
  }

  appointmentTypesOfGenericAppointmentCategory: AppointmentType[] = undefined
  doNonGenericAppointmentCategoriesExist: boolean
  doAppointmentTypesOfGenericAppointmentCategoryExist: boolean
  doAppointmentTypesExist = true // default true otherwise the error message is shown for a split second while loading
  showAppointments: boolean
  textNoOpenings: I18NString = I18NSTRING_EMPTY // initial value to prevent undefined error
  languageChangeSubscription: Subscription
  get bookingTypeFormGroup(): FormGroup { return this.bookingService.bookingTypeFormGroup }
  get appointmentType(): AbstractControl<AppointmentType> { return this.bookingService.appointmentType }
  baseLanguage: BaseLanguage // default value to prevent an error due to being not initialized
  subscriptionIsBookingTypeInitialized: Subscription
  baseLanguageSubscription: Subscription
  subscriptionAppointmentCategory: Subscription
  subscriptionAppointmentTypeUnselect: Subscription
  subscriptionMagicFill: Subscription
  isZwModeError: boolean

  areAllNonGenericCategoriesExpanded = false // Are panels expanded
  @ViewChild('appAccordion') accordion: MatAccordion
  @ViewChild('genericButtonToggleGroup') gBTGroup: MatButtonToggleGroup
  @ViewChild('form') form: ElementRef

  constructor(
    public bookingService: BookingService,
    private changeDetectorRef: ChangeDetectorRef,
    private instanceService: InstanceService,
    private activatedRoute: ActivatedRoute,
    public languageService: LanguageService,
    private translate: TranslateService,
    private bookingStandaloneService: BookingStandaloneService,
    private checkoutService: AdPayCheckoutService
  ) { }

  // isBookingTypeInitialized$ is initialized whenever bs is changed.
  // note that, as this may happen more than once, it is important to unsubscribe some of the subscriptions below
  // (such as magic fill) if they already exist before subscribing again
  ngOnInit(): void {

    this.bookingService.appointmentCategoriesNonGeneric$.pipe(
      untilDestroyed(this)
    ).subscribe(
      (categories) => {
        this._appointmentCategoriesNonGeneric = categories;
      }
    )

    // update categories when changed in booking service
    this.bookingServiceInit$.pipe(
      untilDestroyed(this)
    ).subscribe({
      next: (init) => {
        if (!init) return;
        this._appointmentCategoriesNonGeneric = this.filterNonGenericAppointmentCategories(
          this.bookingService.appointmentCategoriesNonGeneric
        )
      }
    })

    // other init
    this.subscribeIsBookingTypeInitialized()
  }

  ngAfterViewInit() {
    this.setScrollableNativeElement()
  }

  subscribeIsBookingTypeInitialized() {
    if (this.subscriptionIsBookingTypeInitialized)
      this.subscriptionIsBookingTypeInitialized.unsubscribe()
    this.subscriptionIsBookingTypeInitialized = this.bookingService.isBookingTypeInitialized$
      .pipe(
        mergeMap((isBookingTypeInitialized: boolean) =>
          isBookingTypeInitialized ? of(null).pipe(
            untilDestroyed(this),
            mergeMap(() => this.updateContactFields()),
            mergeMap(() => this.baseLanguageSubscribe()),
            mergeMap(() => this.bookingService.otkUser.insuranceFilterActive
              ? this.bookingService.filterCategoriesByInsurance()
              : of(null)
            ),
            mergeMap(() => this.setAppointmentTypesOfGenericAppointmentCategory()),
            mergeMap(() => this.setDoAppointmentTypesExist()),
            mergeMap(() => this.setTextNoOpenings()),
            mergeMap(() => this.appointmentCategorySubscribe()),
            mergeMap(() => this.appointmentTypeUnselectSubscribe()),
            mergeMap(() => this.handleParams()),
            mergeMap(() => this.magicFillSubscribe()),
          )
            : of(null)
        )
      ).subscribe()
  }

  updateContactFields(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      this.isContactFormActive = this.bookingService.otkUser?.contactForm
      this.contactSettings = this.bookingService.otkUser?.contactSettings
      if (this.isContactFormActive && this.contactSettings) {
        const stringPlaceholder: string = ''
        const i18NStringPlaceholder: I18NString = I18NSTRING_EMPTY
        const numberPlaceholder: number = 0
        const appointmentTypeEmailDefinition: AppointmentTypeEmailDefinition = {
          content: I18NSTRING_EMPTY,
          format: EmailFormat.TEMPLATE,
          embedContent: true,
          active: true,
          sendHostMessage: true
        }
        const appointmentTypeEmailSettings: AppointmentTypeEmailSettings = {
          cancelled: appointmentTypeEmailDefinition,
          confirmed: appointmentTypeEmailDefinition,
          moved: appointmentTypeEmailDefinition
        }
        const iOTKBookingLimitPlaceholder: IOTKBookingLimit = {}
        const iOTKBookingLimitSettings: IOTKBookingLimitSettings = {
          general: iOTKBookingLimitPlaceholder,
          gkv: iOTKBookingLimitPlaceholder,
          pkv: iOTKBookingLimitPlaceholder,
          hzv: iOTKBookingLimitPlaceholder,
          sz: iOTKBookingLimitPlaceholder,
          bg: iOTKBookingLimitPlaceholder
        }
        this.appointmentTypeContactSettings = {
          name: this.contactSettings.name,
          description: this.contactSettings.description,
          // all fields starting from here are just placeholder fields.
          // todo - migrate contactForm to be a property of appointmentType and not of OtkUser? And then this whole thing will look better.
          _id: stringPlaceholder,
          instance: stringPlaceholder,
          insurance: [stringPlaceholder],
          terminSucheIdent: stringPlaceholder,
          isEnabled: false,
          isZuweiser: false,
          active: true,
          emailSettings: appointmentTypeEmailSettings,
          duration: numberPlaceholder,
          allowDoctorChoice: true,
          allowOnlyKnownEmail: true,
          allowClientCancellation: true,
          allowClientMove: true,
          showDuration: true,
          cancellationWindow: numberPlaceholder,
          showDoctors: true,
          strategy: OtkBookingStrategy.ALWAYSBOOK,
          showIfNoOpenings: true,
          selectableIfNoOpenings: true,
          textNoOpenings: i18NStringPlaceholder,
          bookingLimitSettings: iOTKBookingLimitSettings,
          dialect: Dialect.Ansprechpartner,
          hasOpenings: true,
          isDisabled: true,
          onlineConsultation: false,
          patientTarget: PatientTargetType.Both,
          skipOpeningSelection: true
        }
      }
      subscriber.next()
      subscriber.complete()
    })
  }

  baseLanguageSubscribe(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      if (this.baseLanguageSubscription)
        this.baseLanguageSubscription.unsubscribe()
      this.baseLanguageSubscription = this.languageService.baseLanguageChangeSubject$
        .pipe(
          untilDestroyed(this),
          mergeMap(() => this.updateContactFields()))
        .subscribe(() =>
          this.baseLanguage = this.languageService.activeBaseLang
        )
      subscriber.next()
      subscriber.complete()
    })
  }

  /**
   * Filter the generic AppointmentCategory obtained from the booking service:
   *   - if query parameter 'oat=' given only include those AppointmentTypes
   *   - only include AppointmentTypes with a valid Anamnese
   *
   * SETS: this.appointmentTypesOfGenericAppointmentCategory
   */
  setAppointmentTypesOfGenericAppointmentCategory(): Observable<any> {
    const genericCategory = this.bookingService.appointmentCategoryGeneric;
    const allAppTypes = genericCategory?.appointmentTypes || [];

    // filter AppointmentTypes //
    // if appointment type ids are specified in 'oat' only use those 
    const idInRouteQuery = BookingTypeUtil.getAppointmentTypesInRouteQueryParams(
      allAppTypes, this.activatedRoute.snapshot
    )
    const validAnamnese = idInRouteQuery.filter(
      (appType) => this.verifyAnamneseFormValidity(appType)
    )

    this.appointmentTypesOfGenericAppointmentCategory = validAnamnese;

    return new Observable((subscriber: Subscriber<any>) => {
      subscriber.next()
      subscriber.complete()
    })
  }

  // perform magic fill (fill the fields with preset values)
  magicFillSubscribe(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      if (this.subscriptionMagicFill) {
        this.subscriptionMagicFill.unsubscribe()
      }
      this.subscriptionMagicFill = this.bookingService.magicFill$
        .pipe(untilDestroyed(this))
        .subscribe((magicFill: string) => {
          if (magicFill === BookingStep.bookingType)
            this.magicFill()
        })
      subscriber.next()
      subscriber.complete()
    })
  }

  // after selecting the booking category, filter the relevant booking types
  appointmentCategorySubscribe(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      if (this.subscriptionAppointmentCategory)
        this.subscriptionAppointmentCategory.unsubscribe()
      this.subscriptionAppointmentCategory = this.appointmentCategoryNonGeneric.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          if (this.appointmentCategoryNonGeneric.value) // only if not changed by the reset function
            this.appointmentTypesOfSelectedAppointmentCategory =
              (this.appointmentCategoryNonGeneric.value as AppointmentCategory).appointmentTypes
                .filter((appType) => this.verifyAnamneseFormValidity(appType))
        })
      subscriber.next()
      subscriber.complete()
    })
  }

  setDoAppointmentTypesExist(): Observable<boolean> {
    return new Observable((subscriber: Subscriber<boolean>) => {
      const doAppointmentTypesOfGenericAppointmentCategoryExist: boolean =
        this.appointmentTypesOfGenericAppointmentCategory
        && Object.keys(this.appointmentTypesOfGenericAppointmentCategory).length > 0
      const doAppointmentTypesOfNonGenericAppointmentCategoriesExist: boolean = this.appointmentCategoriesNonGeneric.some((appointmentCategory: AppointmentCategory) =>
        Object.keys(appointmentCategory.appointmentTypes).length > 0)
      this.doAppointmentTypesExist = doAppointmentTypesOfGenericAppointmentCategoryExist
        || doAppointmentTypesOfNonGenericAppointmentCategoriesExist
      subscriber.next()
      subscriber.complete()
    })
  }

  setTextNoOpenings(): Observable<boolean> {
    return new Observable((subscriber: Subscriber<any>) => {
      this.textNoOpenings = this.bookingService.insuranceType.value
        ? this.bookingService.otkUser.insurance[this.bookingService.insuranceType.value].unavailableMessage
        : this.bookingService.otkUser.textNoOpenings
      subscriber.next()
      subscriber.complete()
    })
  }

  /**
   * when a booking type in either the generic or one of the non-generic
   * booking categories is selected, unselect all booking types of the
   * other category type
   * without this, the gui will sometimes falsely display two selected booking
   * types, one in the generic category and one in one of the other ones
   */
  appointmentTypeUnselectSubscribe(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      if (this.subscriptionAppointmentTypeUnselect)
        this.subscriptionAppointmentTypeUnselect.unsubscribe()
      this.subscriptionAppointmentTypeUnselect = this.appointmentType.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.bookingService.isContactFormSelected$.next(false)
          const appointmentType: AppointmentType = this.appointmentType.value
          if (appointmentType.adPayActive) {
            this.checkoutService.updateActiveStore(StoreTypes.appointmentType, appointmentType?.storeId);
          }
          this.appointmentType.setValue(appointmentType, { onlySelf: true, emitEvent: false })
          this.bookingService.setRedirectionUrl()
        })
      subscriber.next()
      subscriber.complete()
    })
  }

  closeAllExpansionPanels() {
    this.accordion.closeAll()
  }

  /**
   * prefills the options
   */
  public magicFill() {
    of(null).
      pipe(untilDestroyed(this),
        mergeMap(() => {
          this.changeDetectorRef.detectChanges()
          return of(null)
        }),
        mergeMap(() => {
          if (this.appointmentCategoryGeneric?.appointmentTypes)
            this.appointmentType.setValue(this.appointmentCategoryGeneric.appointmentTypes[0])
          else
            this.appointmentType.setValue(this.appointmentCategoriesNonGeneric[0].appointmentTypes[0])
          this.bookingService.bookingTypeFormGroup.updateValueAndValidity()
          this.bookingService.bookingTypeFormGroup.updateValueAndValidity()
          // this.bookingService.stepper.next() // no need because of auto-forwarding
          this.changeDetectorRef.detectChanges()
          return of(null)
        }),
        delay(1000),
        mergeMap(() => {
          this.appointmentType.value.appointmentSeriesType
            ? this.bookingService.magicFill$.next(BookingStep.bookingDateMulti)
            : this.bookingService.magicFill$.next(BookingStep.bookingDate)
          return of(null)
        })
      ).subscribe()
  }

  private verifyAnamneseFormValidity(appType: AppointmentType): boolean {
    if ([null, undefined].includes(appType?.formIdentifier)) return true
    const instanceForm = AnamneseFormCheckerService.getInstanceForm(
      appType,
      this.instanceService.activeInstance.forms
    )
    if (instanceForm === null) return false
    return true
  }

  public onContactFormSelect() {
    this.bookingService.initContactFormMode()
  }

  handleParamCategory(): Observable<any> {
    return this.activatedRoute.queryParams
      .pipe(
        mergeMap((params: Params) => {
          if (this.bookingService.isStandalone) {
            if (!this.bookingStandaloneService.categories) { return of(null); }
            this.doesParamExistCategory = true;
            return this.filterCategoriesByParameter(this.bookingStandaloneService.categories)
          }
          this.doesParamExistCategory = !!params?.katid
          if (this.doesParamExistCategory) {
            return this.filterCategoriesByParameter((params.katid as string).split(','))
          }
          else
            return of(null)
        })
      )
  }

  private filterCategoriesByParameter(categories: string[]) {
    if (!categories || categories.length === 0) { return; }

    this.bookingService.appointmentCategoryGeneric = categories
      .some((catId) => this.bookingService.appointmentCategoryGeneric?._id === catId)
      ? this.bookingService.appointmentCategoryGeneric
      : null

    this.bookingService.appointmentCategoriesNonGeneric = this.bookingService.appointmentCategoriesNonGeneric
      ?.filter((ac) => {
        return categories.includes(ac._id)
      })

    if (this.bookingService.appointmentCategoriesNonGeneric?.length === 1) {
      this.areAllNonGenericCategoriesExpanded = true
    }

    // valid Anamnese form
    const appTypesValidAnamnese = this.bookingService.appointmentCategoryGeneric
      ? this.bookingService.appointmentCategoryGeneric.appointmentTypes
        .filter((appType) => this.verifyAnamneseFormValidity(appType))
      : undefined

    // when appointments specified in route query params only show those
    let appTypesInRouteQuery: AppointmentType[] = [];
    if (appTypesValidAnamnese != undefined) {
      appTypesInRouteQuery = BookingTypeUtil.getAppointmentTypesInRouteQueryParams(
        appTypesValidAnamnese, this.activatedRoute.snapshot
      )
    }

    this.appointmentTypesOfGenericAppointmentCategory = appTypesInRouteQuery;
    this.changeDetectorRef.detectChanges()
    return of(null)
  }

  private filterAppointmentTypesByParameter(oats: string[]) {

    if (!oats || oats.length === 0) { return of(null); }

    const filterAppointmentCategoryBasedOnTypeParam: (appointmentCategory: AppointmentCategory) => AppointmentCategory
      = (appointmentCategory: AppointmentCategory): AppointmentCategory => {
        const appointmentCategoryFiltered: AppointmentCategory = {
          ...appointmentCategory,
          appointmentTypeIds: appointmentCategory?.appointmentTypeIds
            .filter((ati: string) => oats.includes(ati)),
          appointmentTypes: appointmentCategory?.appointmentTypes
            .filter((at: AppointmentType) => oats.includes(at._id)),
        }
        return appointmentCategoryFiltered
      }
    this.bookingService.appointmentCategoryGeneric = filterAppointmentCategoryBasedOnTypeParam(this.bookingService.appointmentCategoryGeneric)
    if (!this.bookingService.appointmentCategoryGeneric?.appointmentTypeIds) {
      this.bookingService.appointmentCategoryGeneric = null;
    }
    let acsNonGenericFiltered: AppointmentCategory[] = []
    let acNonGenericFiltered: AppointmentCategory
    for (let i = 0; i < this.bookingService.appointmentCategoriesNonGeneric.length; i++) {

      acNonGenericFiltered = this.bookingService.appointmentCategoriesNonGeneric[i]
      acNonGenericFiltered = filterAppointmentCategoryBasedOnTypeParam(acNonGenericFiltered)
      if (acNonGenericFiltered.appointmentTypeIds.length !== 0)
        acsNonGenericFiltered.push(acNonGenericFiltered)
    }
    this.bookingService.appointmentCategoriesNonGeneric = acsNonGenericFiltered
    return of(null).pipe(
      mergeMap(() => this.setAppointmentTypesOfGenericAppointmentCategory()),
      mergeMap(() => this.setDoAppointmentTypesExist())
    )
  }

  private setAppointmentTypeByParameter(oat: string) {
    const appointmentTypeMatchedGeneric: AppointmentType = this.bookingService.appointmentCategoryGeneric?.appointmentTypes
      .find((appointmentType: AppointmentType) =>
        appointmentType._id === oat)
    const appointmentTypeMatchedNonGeneric: AppointmentType = this.bookingService.appointmentCategoriesNonGeneric
      .flatMap((appointmentCategory: AppointmentCategory) => appointmentCategory?.appointmentTypes)
      .find((appointmentType: AppointmentType) => appointmentType._id === oat)
    const appointmentTypeMatched: AppointmentType = !!appointmentTypeMatchedGeneric
      ? appointmentTypeMatchedGeneric : appointmentTypeMatchedNonGeneric
    if (this.doesParamExistType && !!appointmentTypeMatched) {
      this.bookingService.appointmentType.setValue(appointmentTypeMatched, { emitEvent: true })
      this.bookingService.showType$.next(false)
    }
    return of(null)
  }

  handleParamType() {
    return this.activatedRoute.queryParams
      .pipe(
        mergeMap((params: Params) => {
          if (this.bookingService.isStandalone && this.bookingStandaloneService.appointmentTypes) {
            this.doesParamExistType = true
            return this.filterAppointmentTypesByParameter(this.bookingStandaloneService.appointmentTypes);
          }
          this.doesParamExistType = !!params?.oat
          if (this.doesParamExistType && params.oat.split(',').length > 1) {
            return this.filterAppointmentTypesByParameter((params.oat as string).split(','));
          }
          else if (this.doesParamExistType) {
            return this.setAppointmentTypeByParameter(params.oat)
          }
          return of(null)
        })
      )
  }

  handleParams(): Observable<any> {
    return of(null)
      .pipe(
        mergeMap(() => {
          this.isZwModeError = this.bookingService.zuweiserCode && !this.bookingService.isZwCodeCorrect
          return of(null)
        }),
        mergeMap(() => this.handleParamCategory()),
        mergeMap(() => this.handleParamType()),
      )
  }

  closeCategory(cat: AppointmentCategory) {
    if (!this.appointmentType.value) return
    const genericIsSelected = (this.gBTGroup?.selected as MatButtonToggle)?.value?._id === this.appointmentType.value?._id
    if (cat?.appointmentTypeIds?.includes(this.appointmentType.value?._id) && !genericIsSelected)
      this.appointmentType.setValue(null)
  }

  setScrollableNativeElement() {
    this.bookingService.scrollableNativeElement[BookingStep.bookingType] = this.form.nativeElement
  }


  /**
   * Filter AppointmentCategories according to:
   *  - AppointmentTypes specified in query parameters ('oat=')
   */
  private filterNonGenericAppointmentCategories(
    categories: Iterable<AppointmentCategory>
  ): AppointmentCategory[] {

    let filteredCategories: AppointmentCategory[] = [];

    // if the route contains specified AppointmentTypes in the query ('oat='),
    // remove categories that don't contain any.
    for (const cat of categories) {
      const filteredCat = BookingTypeUtil.filterCategoryAccordingToRouteParams(
        cat, this.activatedRoute.snapshot
      )
      if (filteredCat) filteredCategories.push(filteredCat);
    }

    return filteredCategories;
  }
}
