<div class="text">
  <p>
    {{'WHITELABEL-SECTION-MEDIPOLIS.TEXT' | translate}}
  </p>
</div>


<div class="c-button-group" style="justify-content: center; margin-top: 2rem;">
  <a mat-flat-button color="primary" href="https://medipolis-apotheken.de" target="_blank" style="background: #BF363F;">
    <span style="font-weight: 600;">{{'WHITELABEL-SECTION-MEDIPOLIS.LEARN-MORE' | translate}} </span>
    <mat-icon iconPositionEnd>open_in_new</mat-icon>
  </a>
</div>