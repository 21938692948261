<form class="c-form c-form--fullHeight c-form--largerSpacing" [formGroup]="formGroup">

  <app-info-banner [stickToTitle]="false">
    {{'ANAMNESE-FORM.NON-REQUIRED-FIELDS-TEXT' | translate}}
  </app-info-banner>

  <app-selection-field-component [formGroup]="formGroup" [options]="settings.opOptions" [multiple]="false"
    [selectionRequired]="false" [descriptionRequiredIfSonstiges]="false" [descriptionHideUnlessSonstiges]="true"
    selectionName="opSelection" descriptionName="opDescription"
    [selectionPlaceholder]="'ANAMNESE-FORM.OP-QUESTION' | translate"
    [descriptionPlaceholder]="'ANAMNESE-FORM.OP-DESCRIPTION' | translate">
  </app-selection-field-component>

  <!-- <mat-form-field appearance="fill">
    <mat-label>
      <span class="hide--onVerySmall">Welche Seite wurde operiert?</span>
      <span class="show--onVerySmall">Seite</span>
    </mat-label>
    <mat-select formControlName="opSide">
      <mat-option><em>Nicht zutreffend</em></mat-option>
      <mat-option value="Links">Links</mat-option>
      <mat-option value="Rechts">Rechts</mat-option>
    </mat-select>
  </mat-form-field> -->

  <mat-form-field attr.data-selenium-id="field-op-wann" appearance="outline">
    <mat-label>
      <span class="hide--onVerySmall" [innerHTML]="'ANAMNESE-FORM.OP-TIME' | translate"></span>
      <span class="show--onVerySmall" [innerHTML]="'ANAMNESE-FORM.OP-DATE' | translate"></span>
    </mat-label>
    <input matInput [matDatepicker]="picker" [matDatepickerFilter]="MIN_DATE_FILTER"
      [placeholder]="'GLOBAL.DATE-PLACEHOLDER' | translate" formControlName="opDate" name="bday"
      autocomplete="chrome-off">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error
      *ngIf="opDate.errors && opDate.errors['matDatepickerParse'] && opDate.errors['matDatepickerParse']['text']"
      [innerHTML]="'GLOBAL.DATE-ERROR-INVALID' | translate"></mat-error>
    <mat-error *ngIf="opDate.errors && opDate.errors['datesInvalid']"
      [innerHTML]="'GLOBAL.DATE-ERROR-INVALID' | translate"></mat-error>
    <mat-error *ngIf="opDate.errors && opDate.errors['dateIsNotBeforeOrSame']"
      [innerHTML]="'GLOBAL.DATE-ERROR-PAST' | translate"></mat-error>
  </mat-form-field>

  <app-selection-field-component *ngIf="settings.surgeonOptions && settings.surgeonOptions.length"
    [formGroup]="formGroup" [options]="settings.surgeonOptions" [multiple]="false" [sonstigesOption]="false"
    [selectionRequired]="false" [descriptionRequiredIfSonstiges]="false" selectionName="surgeonSelection"
    [selectionPlaceholder]="'ANAMNESE-FORM.OP-DOCTOR' | translate">
  </app-selection-field-component>

</form>