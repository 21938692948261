import { last, Maybe } from '@arzt-direkt/wfa-generic-utils'
import { compareVersions } from 'compare-versions'

import { Version } from './../interfaces-autogen-from-joi/version'

type VersionDict<T> = {
  [key in Version]: T
}

export function selectVersion<T>(
  currentVersion: Version,
  versionDict: VersionDict<T>,
): Maybe<T> {
  const dictVersions = Object.keys(versionDict)
  if (dictVersions.length === 0) return undefined

  try {
    const sortedVersions = dictVersions.sort(compareVersions)
    const allowedVersions = sortedVersions.filter(
      (v) => compareVersions(v, currentVersion) < 1,
    )
    const selected = last(allowedVersions) ?? sortedVersions[0]
    return versionDict[selected]
  } catch (e) {
    return undefined
  }
}
