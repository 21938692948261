import { HautproblemAnamneseFormSettings } from '@a-d/instance-form/anamnese/hautproblem/hautproblem-anamnese-form.component';
import { InstanceFormSettingsInterface } from '@a-d/instance-form/instance-form-component.interface';
import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InstanceSettingsFormsService } from '../../instance-settings-forms.service';
import { ChipListFieldComponent } from '../../../../../forms/fields/chip-list-field.component';
import { NgIf } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@Component({
    selector: 'app-hautproblem-anamnese-form-settings',
    templateUrl: './hautproblem-anamnese-form-settings.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        NgIf,
        ChipListFieldComponent,
    ],
})
export class HautproblemAnamneseFormSettingsComponent implements InstanceFormSettingsInterface {

  @Input() defaultSettings: HautproblemAnamneseFormSettings
  @Input() settings: HautproblemAnamneseFormSettings


  /**
   * Form-Group
   */
  public formGroup = new UntypedFormGroup({
    preSelectIsLesion: new UntypedFormControl('', []),
    hautproblemOptions: new UntypedFormControl('', [Validators.required]),
  })

  public get preSelectIsLesion(): AbstractControl { return this.formGroup.get('preSelectIsLesion') }
  public get hautproblemOptions(): AbstractControl { return this.formGroup.get('hautproblemOptions') }


  constructor(
    private instanceSettingsFormsService: InstanceSettingsFormsService,
  ) { }

  onInit() { }


  /**
   * Gathers and returns merged settings-data.
   */
  public getMergedData(completeSettings?: boolean): any {
    return this.instanceSettingsFormsService.getMergedData(this, completeSettings)
  }

}
