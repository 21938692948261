import { IsEmbeddedIn } from '@arzt-direkt/wfa-definitions'
import { set } from '@arzt-direkt/wfa-generic-utils'

export function replaceSpecialCharacters(
  response: Record<string, unknown>,
  isEmbeddedIn: IsEmbeddedIn = 'arztDirekt',
): Record<string, unknown> {
  if (isEmbeddedIn !== 'arztDirekt') return response

  const _response = structuredClone(response)
  Object.entries(_response)
    .filter(([_, value]) => typeof value === 'string')
    .forEach(([key, value]) => {
      if (typeof value === 'string') {
        set(_response, key, value.replace(/"/g, "'"))
      }
    })
  return _response
}
