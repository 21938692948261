import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GoSettings } from './go-settings.entity';
import {
  ChildKey,
  ControlWithOptions,
  GoFormGroup,
  ParentKey,
  RawVisibilityCondition,
  VisibilityCondition
} from './go.types';

@Injectable({
  providedIn: null, // provided in go.component
})
export class GoService {
  formGroup: GoFormGroup = {
    sideEffects: new UntypedFormControl(''),
    customSideEffect: new UntypedFormControl(''),
    allergies: new UntypedFormControl(''),
    allergySelect: new UntypedFormControl(''),
    customAllergy: new UntypedFormControl(''),
    allergyDetails: new UntypedFormControl(''),
    chronicDiseases: new UntypedFormControl(''),
    customChronicDisease: new UntypedFormControl(''),
    medications: new UntypedFormControl(''),
    customMedication: new UntypedFormControl(''),
    goOther: new UntypedFormControl(''),
  };

  public static createVisibilityConditions(
    settings: GoSettings,
    conditions: RawVisibilityCondition[]
  ): VisibilityCondition[] {
    return conditions.map((tuple) => {
      const parent: ParentKey = tuple[0];
      const child: ChildKey = tuple[1];
      const indsOfParentValues: number[] = tuple[2];
      const canShowChild: boolean = tuple[3];
      const parentValues: string[] = this.getOptions(
        settings,
        parent,
        indsOfParentValues
      );
      return {
        parent: parent,
        child: child,
        parentValuesThatShowChild: parentValues,
        canShowChild: canShowChild,
      } as VisibilityCondition;
    });
  }

  public static getOption(
    settings: GoSettings,
    key: ControlWithOptions,
    ind: number
  ) {
    return [settings[key].options.de[ind], settings[key].options.en[ind]];
  }

  public static getOptions(
    settings: GoSettings,
    key: ControlWithOptions,
    inds: number[]
  ) {
    return [].concat(...inds.map((ind) => this.getOption(settings, key, ind)));
  }

  constructor() { }
}
