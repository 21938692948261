import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Howl } from 'howler';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';


export enum NotificationSounds {
  // Patient
  BecamePicked = 'became-picked',
  BecamePending = 'became-pending',
  BecameHalted = 'became-halted',
  CountdownTick = 'countdown-tick',
  CountdownLoudTick = 'countdown-loud-tick',

  // Doctor
  PatientWaiting = 'patient-waiting',
  PatientUpdated = 'patient-updated',
  LastPatientLeft = 'last-patient-left',
  PatientAccepted = 'patient-accepted',
  PatientNotAccepted = 'patient-not-accepted',

  // Both
  NoSound = 'no-sound',
}


@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SoundService {

  public readonly SOUND_TYPES = {
    [NotificationSounds.NoSound]: 'Silence',

    [NotificationSounds.BecamePicked]: 'Hey There',
    [NotificationSounds.BecamePending]: 'Super',
    [NotificationSounds.BecameHalted]: 'Release',
    [NotificationSounds.CountdownTick]: 'Old Tick',
    [NotificationSounds.CountdownLoudTick]: 'Mute Metal',

    [NotificationSounds.PatientWaiting]: 'Success',
    [NotificationSounds.PatientUpdated]: 'Click',
    [NotificationSounds.LastPatientLeft]: 'Popped',
    [NotificationSounds.PatientAccepted]: 'Super',
    [NotificationSounds.PatientNotAccepted]: 'Release',
  }

  private sounds = {}
  private loopingSounds = {}
  private userVolume = this.authService.user?.settings?.notificationVolume ?? 1


  constructor(private authService: AuthService) {
    // Preload Sounds by creating Howl-objects
    for (const name in this.SOUND_TYPES) {
      this.sounds[name] = new Howl({
        src: [`${environment.url}/assets/sounds/${this.SOUND_TYPES[name]}.mp3`]
      })
    }

    // Update `userVolume` and update running sounds
    this.authService.userUpdated$.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      const newUserVolume = this.authService.user?.settings?.notificationVolume ?? 1
      if (this.userVolume !== newUserVolume) {
        this.userVolume = newUserVolume

        const allSounds = Object.values(this.sounds).concat(Object.values(this.loopingSounds)) as Howl[]
        for (let sound of allSounds) {
          sound.volume(this.userVolume)
        }
      }
    })
  }


  /**
   * Plays the given sound with the given volume.
   * Sounds are muted in demo mode.
   */
  public playSound(name: NotificationSounds, volume: number = this.userVolume, loopTimes: number = null) {
    if (environment.demoMode)
      return
    this.stopAllSounds()

    if (!(name in this.sounds)) {
      console.warn(`Couldn't play sound, unknown notification '${name}'.`)
      return
    }
    const sound = this.sounds[name] as Howl
    sound.volume(volume)
    sound.stop()

    if (!loopTimes) {
      sound.play()

    } else {
      // Create looping version of sound if `loopTimes` is given.
      let counter = 0
      const loopingSound = new Howl({
        src: [`${environment.url}/assets/sounds/${this.SOUND_TYPES[name]}.mp3`],
        volume: volume,
        autoplay: true,
        loop: true,
        onend: () => {
          counter += 1
          if (counter >= loopTimes) {
            loopingSound.loop(false)
          }
        }
      })

      this.loopingSounds[name] = loopingSound
    }
  }


  /**
   * Stops all sounds (normal as well as looping ones).
   */
  public stopAllSounds() {
    const allSounds = Object.values(this.sounds).concat(Object.values(this.loopingSounds)) as Howl[]
    for (let sound of allSounds) {
      sound.loop(false)
      sound.stop()
    }
  }


  /**
   * Due to the strict Audio-Policy by Chrome it's useful to play
   * repeatedly blank sounds after an user-interaction to make sure
   * an actual notification-sound is actually played when needed.
   */
  public playBlankSound() {
    this.playSound(NotificationSounds.NoSound)
  }

}
