import { CoreLocale, InputType, WfaKey } from '@arzt-direkt/wfa-definitions'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import { notNullish, nullish } from '@arzt-direkt/wfa-generic-utils'
import { QuestionTextModel } from 'survey-core'
import {
  PropertyValueChangingEvent,
  SurveyCreatorModel,
} from 'survey-creator-core'

import { generateUniqueQuestionName } from '../generate-unique-question-name'
import {
  lookUpLocalizedShortTitleFromWfaKey,
  wfaKey2QuestionType,
  wfaKey2TextInputType,
} from '../wfa-key/wfa-key-utils'
import { setWfaInputType } from './set-wfa-input-type'
import { addValidator, removeValidator } from './validator-utils'

interface Question {
  name: string
  shortTitle: Maybe<string>
  title: Maybe<string>
  inputType: Maybe<InputType>
  wfaKey: Maybe<string>
  getType: () => string
}

export function updatePropertiesWithWfaKey(
  creator: SurveyCreatorModel,
  options: PropertyValueChangingEvent,
): void {
  const wfaKey = options.newValue as WfaKey
  const question = options.obj as unknown as Question

  updateQuestionType(creator, wfaKey)
  const questionWithNewType = creator.selectedElement as unknown as Question
  updateQuestionName(creator, wfaKey, questionWithNewType)
  updateQuestionTitleAndShortTitle(creator, wfaKey, questionWithNewType)
  updateQuestionInputType(wfaKey, question, questionWithNewType)
  setWfaKey(wfaKey, questionWithNewType)
  creator.validateSelectedElement()
}

function updateQuestionType(creator: SurveyCreatorModel, wfaKey: WfaKey): void {
  if (nullish(wfaKey)) return
  const questionType = wfaKey2QuestionType(wfaKey)
  if (nullish(questionType)) return
  creator.convertCurrentQuestion(questionType)
}

function updateQuestionName(
  creator: SurveyCreatorModel,
  wfaKey: WfaKey,
  question: Question,
): void {
  question.name = wfaKey ?? generateUniqueQuestionName(creator)
}

function updateQuestionTitleAndShortTitle(
  creator: SurveyCreatorModel,
  wfaKey: WfaKey,
  question: Question,
): void {
  if (nullish(wfaKey)) return

  const locale = creator.locale as CoreLocale
  const shortTitle = question.shortTitle
  const title = question.title
  const localizedShortTitle = lookUpLocalizedShortTitleFromWfaKey(
    wfaKey,
    locale,
  )

  if (nullish(shortTitle) || shortTitle.length === 0)
    question.shortTitle = localizedShortTitle
  if (title === wfaKey) question.title = localizedShortTitle
}

function updateQuestionInputType(
  wfaKey: WfaKey,
  oldQuestion: Question,
  newQuestion: Question,
): void {
  if (nullish(wfaKey)) return
  if (newQuestion.getType() !== 'text') return

  const inputType = wfaKey2TextInputType(wfaKey)
  if (nullish(inputType)) return

  newQuestion.inputType = inputType
  setWfaInputType(inputType, newQuestion as unknown as QuestionTextModel)
  updateQuestionValidatorsWithInputType(oldQuestion, newQuestion, inputType)
}

function updateQuestionValidatorsWithInputType(
  oldQuestion: Question,
  newQuestion: Question,
  inputType: InputType,
): void {
  const oldInputType = oldQuestion.inputType
  if (notNullish(oldInputType))
    removeValidator(oldInputType, newQuestion as unknown as QuestionTextModel)
  addValidator(inputType, newQuestion as unknown as QuestionTextModel)
}

/**
 * If the question type is changed, the WFA key must be set again as it is not adopted.
 */
function setWfaKey(wfaKey: WfaKey, question: Question): void {
  if (nullish(wfaKey)) return
  question['wfaKey'] = wfaKey
}
