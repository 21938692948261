export const example = {
  type: 'gender',
  name: '$wfaPatientGeschlecht$',
  startWithNewLine: false,
  title: {
    de: 'Geschlecht',
  },
  isRequired: true,
  shortTitle: 'Geschlecht',
}
