import { InstanceService } from '@a-d/instance/instance.service';
import { AdLoggerService } from '@a-d/logging/ad-logger.service';
import { NgIf } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { DialogComponent } from '../../../../../lib/src/lib/dialog/dialog.component';
import { NotificationService } from '../../../../../lib/src/lib/notifications/notification.service';
import { Instance } from '../../entities/Instance.entity';
import { User } from '../../entities/User.entity';
import { PasswordFieldComponent } from '../../forms/fields/password-field.component';
import { UsefulComponent } from '../../misc/useful.component';
import { AGBPrivacyDialogComponent } from '../agb-privacy-dialog/agb-privacy-dialog.component';
import { AuthService } from './../auth.service';

@UntilDestroy()
@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  standalone: true,
  imports: [DialogComponent, MatDialogModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, PasswordFieldComponent, MatButtonModule, NgIf, RouterLink]
})
export class PasswordDialogComponent extends UsefulComponent implements OnInit, OnDestroy {

  public formGroup = new UntypedFormGroup({
    email: new UntypedFormControl({ value: null, disabled: true }, [Validators.required]),
    password: new UntypedFormControl(null, [Validators.required]),
  })

  public get email(): AbstractControl { return this.formGroup.get('email') }
  public get password(): AbstractControl { return this.formGroup.get('password') }

  public user: User
  private logoutOnError: boolean

  public agbPrivacyDialogRef: MatDialogRef<AGBPrivacyDialogComponent>


  constructor(
    private adLoggerService: AdLoggerService,
    public dialogRef: MatDialogRef<PasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public authService: AuthService,
    public notificationService: NotificationService,
    public router: Router,
    private instanceService: InstanceService,
    private dialog: MatDialog
  ) {
    super()
  }

  ngOnInit() {
    super.ngOnInit()
    this.user = this.data.user;
    this.email.setValue(this.user.email)
    this.logoutOnError = this.data.logoutOnError;
    this.isLoading = false;
  }


  /**
   * Password entered
   */
  public submitForm() {
    const instanceId = this.instanceService.activeInstance?._id
    const loginData = {
      accountType: this.user.isArzt ? 'user' : 'instance',
      login: this.user.email,
      password: this.password.value,
      instanceId
    }
    this.isLoading = true;
    ((this.data.login ? this.authService.login(this.user.email, this.password.value) : this.authService.checkLoginData(loginData)) as Observable<any>).subscribe({
      next: (verified) => {
        this.isLoading = false;
        if (!verified) {
          this.handleVerifyFailure('Wrong password!');
          return;
        }

        if (!this.user.acceptedAGB) this.openAGBPrivacyDialog(this.user)

        if (!this.agbPrivacyDialogRef) {
          this.dialogRef.close(true);

          if (!this.data.suppressSuccessMsg) {
            this.notificationService.displayNotification('Erfolgreich authentifiziert');
          }
          return
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.handleVerifyFailure(error);
        return;
      }
    })

  }

  private handleVerifyFailure(error: string) {
    this.adLoggerService.error(error);
    this.notificationService.displayNotification('Authentifizierung fehlgeschlagen');
    if (this.logoutOnError) this.authService.logout()
    this.dialogRef.close(false)
  }

  /**
   * Opens a a dialog to accept AGB
   */
  private openAGBPrivacyDialog(user: User | Instance) {
    //if (environment.demoMode) return

    const isInstance: boolean = !!user?.roles?.includes('instance')
    const isArzt: boolean = !!user?.roles?.includes('arzt')
    if (!isInstance && !isArzt) return

    this.isLoading = false
    this.agbPrivacyDialogRef = this.dialog.open(AGBPrivacyDialogComponent, {
      data: { user },
      backdropClass: 'c-dialog-dark-backdrop',
      disableClose: true
    })

    this.agbPrivacyDialogRef.afterClosed().pipe(
      untilDestroyed(this)
    ).subscribe((accepted) => {
      this.dialogRef.close(true);

      if (!this.data.suppressSuccessMsg && accepted) {
        this.notificationService.displayNotification('Erfolgreich authentifiziert');
      }
      return
    })
  }

}
