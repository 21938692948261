import Joi from 'joi'

import { BaseQuestionJoi } from './base-question.joi'
import { example } from './examples/wfa-expression-question.joi.example'

// https://surveyjs.io/form-library/documentation/api-reference/expression-model
export const WfaExpressionQuestionJoi = BaseQuestionJoi.keys({
  type: Joi.string().valid('expression').required(),
  expression: Joi.string().optional(),
})
  .meta({
    className: 'WfaExpressionQuestion',
  })
  .example(example)
