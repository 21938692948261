import Joi from 'joi'

export const propertyNames = [
  'acceptedTypes',
  'addRowLocation',
  'addRowText',
  'allowAddPanel',
  'allowAddRows',
  'allowClear',
  'allowCompleteSurveyAutomatic',
  'allowEmptyResponse',
  'allowImagesPreview',
  'allowMultiple',
  'allowRemovePanel',
  'allowRemoveRows',
  'allowResize',
  'allowResizeComment',
  'allowRowsDragAndDrop',
  'altText',
  'alternateRows',
  'autoGenerate',
  'autoGrow',
  'autoGrowComment',
  'autocomplete',
  'backgroundColor',
  'backgroundImage',
  'bindings',
  'calculatedValues',
  'cellErrorLocation',
  'cellType',
  'cells',
  'checkErrorsMode',
  'choices',
  'choicesByUrl',
  'choicesEnableIf',
  'choicesFromQuestion',
  'choicesFromQuestionMode',
  'choicesMax',
  'choicesMin',
  'choicesOrder',
  'choicesStep',
  'choicesVisibleIf',
  'clearIfInvisible',
  'clearInvisibleValues',
  'closeOnSelect',
  'colCount',
  'columnColCount',
  'columnMinWidth',
  'columns',
  'columnsVisibleIf',
  'commentPlaceholder',
  'completeText',
  'completedBeforeHtml',
  'completedHtml',
  'completedHtmlOnCondition',
  'confirmDelete',
  'confirmDeleteText',
  'contentMode',
  'cookieName',
  'correctAnswer',
  'dataFormat',
  'dataList',
  'defaultRowValue',
  'defaultValue',
  'defaultValueExpression',
  'defaultValueFromLastPanel',
  'defaultValueFromLastRow',
  'description',
  'descriptionLocation',
  'detailPanelMode',
  'detailPanelShowOnAdding',
  'displayMode',
  'displayRateDescriptionsAsExtremeItems',
  'displayStyle',
  'eachRowUnique',
  'editText',
  'emptyRowsText',
  'enableIf',
  'errorLocation',
  'expression',
  'fileOrPhotoPlaceholder',
  'filePlaceholder',
  'firstPageIsStarted',
  'focusFirstQuestionAutomatic',
  'focusOnFirstError',
  'format',
  'goNextPageAutomatic',
  'hideColumnsIfEmpty',
  'hideIfChoicesEmpty',
  'hideIfRowsEmpty',
  'hideNumber',
  'hideSelectedItems',
  'html',
  'imageFit',
  'imageHeight',
  'imageLink',
  'imageLinkName',
  'imageWidth',
  'indent',
  'innerIndent',
  'inputType',
  'isAllRowRequired',
  'isRequired',
  'isUnique',
  'itemSize',
  'itemTitleWidth',
  'items',
  'keyDuplicationError',
  'keyName',
  'labelFalse',
  'labelTrue',
  'loadingHtml',
  'locale',
  'logo',
  'logoFit',
  'logoHeight',
  'logoWidth',
  'maskType',
  'maskSettings',
  'max',
  'maxErrorText',
  'maxImageHeight',
  'maxImageWidth',
  'maxLength',
  'maxOthersLength',
  'maxPanelCount',
  'maxRateDescription',
  'maxRowCount',
  'maxSelectedChoices',
  'maxSize',
  'maxTextLength',
  'maxTimeToFinish',
  'maxTimeToFinishPage',
  'maxValueExpression',
  'maxWidth',
  'maximumFractionDigits',
  'min',
  'minErrorText',
  'minImageHeight',
  'minImageWidth',
  'minPanelCount',
  'minRateDescription',
  'minRowCount',
  'minSelectedChoices',
  'minValueExpression',
  'minWidth',
  'minimumFractionDigits',
  'mode',
  'multiSelect',
  'name',
  'navigateToUrl',
  'navigateToUrlOnCondition',
  'navigationButtonsVisibility',
  'needConfirmRemoveFile',
  'newPanelPosition',
  'noEntriesText',
  'noneText',
  'otherErrorText',
  'otherPlaceholder',
  'otherText',
  'pageNextText',
  'pagePrevText',
  'panelAddText',
  'panelCount',
  'panelNextText',
  'panelPrevText',
  'panelRemoveButtonLocation',
  'panelRemoveText',
  'panelsState',
  'path',
  'penColor',
  'penMaxWidth',
  'penMinWidth',
  'photoPlaceholder',
  'placeholder',
  'precision',
  'previewText',
  'questionDescriptionLocation',
  'questionErrorLocation',
  'questionStartIndex',
  'questionTitleLocation',
  'questionTitlePattern',
  'questionsOnPageMode',
  'questionsOrder',
  'rateCount',
  'rateDescriptionLocation',
  'rateMax',
  'rateMin',
  'rateStep',
  'rateType',
  'rateValues',
  'readOnly',
  'readonlyRenderAs',
  'removeRowText',
  'renderMode',
  'requiredErrorText',
  'requiredIf',
  'requiredText',
  'resetValueIf',
  'rowCount',
  'rowTitleWidth',
  'rows',
  'rowsOrder',
  'rowsVisibleIf',
  'searchEnabled',
  'searchMode',
  'selectAllText',
  'selectToRankEmptyRankedAreaText',
  'selectToRankEmptyUnrankedAreaText',
  'selectToRankEnabled',
  'sendResultOnPageNext',
  'separateSpecialChoices',
  'setValueExpression',
  'setValueIf',
  'showClearButton',
  'showCommentArea',
  'showCompletedPage',
  'showHeader',
  'showLabel',
  'showNavigationButtons',
  'showNoneItem',
  'showNumber',
  'showOtherItem',
  'showPageNumbers',
  'showPageTitles',
  'showPlaceholder',
  'showPrevButton',
  'showPreview',
  'showPreviewBeforeComplete',
  'showProgressBar',
  'showQuestionNumbers',
  'showRangeInProgress',
  'showSelectAllItem',
  'showTOC',
  'showTimerPanel',
  'showTimerPanelMode',
  'showTitle',
  'signatureAutoScaleEnabled',
  'signatureHeight',
  'signatureWidth',
  'size',
  'sourceType',
  'startWithNewLine',
  'state',
  'step',
  'storeDataAsText',
  'storeOthersAsComment',
  'swapOrder',
  'templateDescription',
  'templateErrorLocation',
  'templateTitle',
  'templateTitleLocation',
  'templateVisibleIf',
  'text',
  'textUpdateMode',
  'title',
  'titleLocation',
  'titleName',
  'tocLocation',
  'totalDisplayStyle',
  'totalCurrency',
  'totalExpression',
  'totalFormat',
  'totalText',
  'totalType',
  'transposeData',
  'triggers',
  'url',
  'useDisplayValuesInDynamicTexts',
  'useGrouping',
  'validators',
  'value',
  'valueFalse',
  'valueName',
  'valuePropertyName',
  'valueTrue',
  'verticalAlign',
  'visible',
  'visibleIf',
  'waitForUpload',
  'width',
  'widthMode',
] as const

export const PropertyNameJoi = Joi.string()
  .valid(...propertyNames)
  .meta({
    className: 'PropertyName',
  })
  .example('title')
