import { InstanceService } from "@a-d/instance/instance.service";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ModuleGuard implements CanActivate {
  constructor(
    private instanceService: InstanceService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.instanceService.activeInstance.modules?.includes(route.data.module)) {
      this.router.navigateByUrl(this.instanceService.activeInstance.identifier)
      return of(false)
    }
    return of(true)
  }

}
