import { AppointmentCategory, AppointmentType } from "@a-d/entities/Booking.entity";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { RouteUtil } from "./route-util";


export class BookingTypeUtil {

  /**
   * Filter a list/set of {@link AppointmentType}s and return only those whose
   * `_id` matches the ids provided in the 'oat=' query argument of the
   * {@link ActivatedRoute}.
   * If the route does not include the query field 'oat' no filtering happens,
   * and the returned array will contain all passed appointment types.
   */
  static getAppointmentTypesInRouteQueryParams(
    appointmentTypes: Iterable<AppointmentType>,
    route: ActivatedRouteSnapshot
  ): AppointmentType[] {

    const appTypeIds = RouteUtil.getAppointmentTypeIds(route)
    const appTypes = Array.from(appointmentTypes);

    // if ids is an empty array [] 'oat' was not set as parameter
    // -> return all appointmentTypes
    if (!appTypeIds || appTypeIds.length === 0) return appTypes;

    const filteredAppTypes = appTypes.filter(
      (aT) => { return appTypeIds.includes(aT._id); }
    )
    return filteredAppTypes;
  }

  /**
   * Filter the {@link AppointmentType}s of an {@link AppointmentCategory} to
   * only include AppointmentTypes specified in the route query parameters ('oat=').
   * 
   * @returns the filtered AppointmentCategory or `null` if no AppointmentTypes
   * remain.
   */
  static filterCategoryAccordingToRouteParams(
    category: AppointmentCategory,
    route: ActivatedRouteSnapshot
  ): AppointmentCategory | null {
    const filteredCategory = Object.assign({}, category);
    const appTypeIds = RouteUtil.getAppointmentTypeIds(route);

    if (appTypeIds && appTypeIds.length > 0) {
      const catAppTypeIds = filteredCategory.appointmentTypeIds;
      const sharedIds = catAppTypeIds.filter(id => appTypeIds.includes(id))
      if (!sharedIds || sharedIds.length < 1) return null;
      // filter the actual AppointmentTypes as well
      const sharedAppTypes = filteredCategory.appointmentTypes.filter(
        (aT) => sharedIds.includes(aT._id)
      );
      filteredCategory.appointmentTypeIds = sharedIds;
      filteredCategory.appointmentTypes = sharedAppTypes;
    }

    return filteredCategory;
  }
}