import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of, map } from 'rxjs';
import de__ansprechpartner from '../../../../../i18n/de--ansprechpartner.json';
import de__apotheker from '../../../../../i18n/de--apotheker.json';
import de__arzt from '../../../../../i18n/de--arzt.json';
import de__behandler from '../../../../../i18n/de--behandler.json';
import de__heilpraktiker from '../../../../../i18n/de--heilpraktiker.json';
import de__kanzlaw from '../../../../../i18n/de--kanzlaw.json';
import de__mitarbeiter from '../../../../../i18n/de--mitarbeiter.json';
import de from '../../../../../i18n/de.json';
import en__ansprechpartner from '../../../../../i18n/en--ansprechpartner.json';
import en__apotheker from '../../../../../i18n/en--apotheker.json';
import en__arzt from '../../../../../i18n/en--arzt.json';
import en__behandler from '../../../../../i18n/en--behandler.json';
import en__heilpraktiker from '../../../../../i18n/en--heilpraktiker.json';
import en__kanzlaw from '../../../../../i18n/en--kanzlaw.json';
import en__mitarbeiter from '../../../../../i18n/en--mitarbeiter.json';
import en from '../../../../../i18n/en.json';
import es__ansprechpartner from '../../../../../i18n/es--ansprechpartner.json';
import es__apotheker from '../../../../../i18n/es--apotheker.json';
import es__arzt from '../../../../../i18n/es--arzt.json';
import es__behandler from '../../../../../i18n/es--behandler.json';
import es__heilpraktiker from '../../../../../i18n/es--heilpraktiker.json';
import es__kanzlaw from '../../../../../i18n/es--kanzlaw.json';
import es__mitarbeiter from '../../../../../i18n/es--mitarbeiter.json';
import es from '../../../../../i18n/es.json';
import fr__ansprechpartner from '../../../../../i18n/fr--ansprechpartner.json';
import fr__apotheker from '../../../../../i18n/fr--apotheker.json';
import fr__arzt from '../../../../../i18n/fr--arzt.json';
import fr__behandler from '../../../../../i18n/fr--behandler.json';
import fr__heilpraktiker from '../../../../../i18n/fr--heilpraktiker.json';
import fr__kanzlaw from '../../../../../i18n/fr--kanzlaw.json';
import fr__mitarbeiter from '../../../../../i18n/fr--mitarbeiter.json';
import fr from '../../../../../i18n/fr.json';
import it__ansprechpartner from '../../../../../i18n/it--ansprechpartner.json';
import it__apotheker from '../../../../../i18n/it--apotheker.json';
import it__arzt from '../../../../../i18n/it--arzt.json';
import it__behandler from '../../../../../i18n/it--behandler.json';
import it__heilpraktiker from '../../../../../i18n/it--heilpraktiker.json';
import it__kanzlaw from '../../../../../i18n/it--kanzlaw.json';
import it__mitarbeiter from '../../../../../i18n/it--mitarbeiter.json';
import it from '../../../../../i18n/it.json';
import { Dialect } from '../entities/Instance.entity';
import de_wfa from '../../../../../shared-assets/vendor/webforms-angular/src/assets/i18n/de.json'
import en_wfa from '../../../../../shared-assets/vendor/webforms-angular/src/assets/i18n/en.json'
import es_wfa from '../../../../../shared-assets/vendor/webforms-angular/src/assets/i18n/es.json'
import fr_wfa from '../../../../../shared-assets/vendor/webforms-angular/src/assets/i18n/fr.json'
import it_wfa from '../../../../../shared-assets/vendor/webforms-angular/src/assets/i18n/it.json'

@Injectable({
  providedIn: 'root'
})
export class DialectTranslationService implements TranslateLoader {

  public readonly ALL_DIALECTS = Object.keys(Dialect).map(key => Dialect[key])

  /**
   * Returns imported translation file for given language-identifier
   */
  // Should we have lazy loading of some kind ?
  // Maybe at least depending which activeBaseLanguages in instance settings
  // Or split it in chunks for vss/otk
  // note - the apotheker dialect only affects the vss and is only available in en and de, so it is commented out for all other languages.
  public getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case 'de': return of({...de_wfa, ...de})
      case 'de--ansprechpartner': return of(de__ansprechpartner)
      case 'de--apotheker': return of(de__apotheker)
      case 'de--arzt': return of(de__arzt)
      case 'de--behandler': return of(de__behandler)
      case 'de--deaktiviert': return of(de)
      case 'de--heilpraktiker': return of(de__heilpraktiker)
      case 'de--kanzlaw': return of(de__kanzlaw)
      case 'de--mitarbeiter': return of(de__mitarbeiter)

      case 'en': return of({ ...en_wfa, ...en })
      case 'en--ansprechpartner': return of(en__ansprechpartner)
      case 'en--apotheker': return of(en__apotheker)
      case 'en--arzt': return of(en__arzt)
      case 'en--behandler': return of(en__behandler)
      case 'en--deaktiviert': return of(en)
      case 'en--heilpraktiker': return of(en__heilpraktiker)
      case 'en--kanzlaw': return of(en__kanzlaw)
      case 'en--mitarbeiter': return of(en__mitarbeiter)

      case 'it': return of({...it_wfa, ...it})
      case 'it--ansprechpartner': return of(it__ansprechpartner)
      case 'it--apotheker': return of(it__apotheker)
      case 'it--arzt': return of(it__arzt)
      case 'it--behandler': return of(it__behandler)
      case 'it--deaktiviert': return of(it)
      case 'it--heilpraktiker': return of(it__heilpraktiker)
      case 'it--kanzlaw': return of(it__kanzlaw)
      case 'it--mitarbeiter': return of(it__mitarbeiter)

      case 'fr': return of({...fr_wfa, ...fr})
      case 'fr--ansprechpartner': return of(fr__ansprechpartner)
      case 'fr--apotheker': return of(fr__apotheker)
      case 'fr--arzt': return of(fr__arzt)
      case 'fr--behandler': return of(fr__behandler)
      case 'fr--deaktiviert': return of(fr)
      case 'fr--heilpraktiker': return of(fr__heilpraktiker)
      case 'fr--kanzlaw': return of(fr__kanzlaw)
      case 'fr--mitarbeiter': return of(fr__mitarbeiter)

      case 'es': return of({...es_wfa, ...es})
      case 'es--ansprechpartner': return of(es__ansprechpartner)
      case 'es--apotheker': return of(es__apotheker)
      case 'es--arzt': return of(es__arzt)
      case 'es--behandler': return of(es__behandler)
      case 'es--deaktiviert': return of(es)
      case 'es--heilpraktiker': return of(es__heilpraktiker)
      case 'es--kanzlaw': return of(es__kanzlaw)
      case 'es--mitarbeiter': return of(es__mitarbeiter)

      default: return of(de)
    }
  }


  /**
   * Returns formatted name of given dialect
   */
  public dialectNameFormatted(dialect: Dialect): string {
    switch (dialect) {
      case Dialect.Ansprechpartner: return "Ansprechpartner statt Arzt (OTK)"
      case Dialect.Apotheker: return "Apotheker statt Arzt (VSS)"
      case Dialect.Arzt: return "Arzt (OTK, in der VSS schon voreingestellt) "
      case Dialect.Behandler: return "Behandler statt Arzt (OTK)"
      case Dialect.Deaktiviert: return "Deaktiviert (Arzt für VSS und Behandler für OTK)"
      case Dialect.Heilpraktiker: return "Heilpraktiker statt Arzt (VSS & OTK)"
      case Dialect.KanzLaw: return "KanzLaw (alpha)"
      case Dialect.Mitarbeiter: return "Mitarbeiter statt Arzt (VSS & OTK) & Kunde statt Patient (VSS)"
      default: return dialect
    }
  }


  /**
   * Returns internal description of given dialect
   */
  public dialectInternalDescription(dialect: Dialect): string {
    switch (dialect) {
      case Dialect.Ansprechpartner: return 'Ansprechpartner statt Arzt (OTK)'
      case Dialect.Apotheker: return 'Medipolis Kooperation (VSS)'
      case Dialect.Arzt: return "Arzt (OTK, in der VSS schon voreingestellt) "
      case Dialect.Behandler: return "Behandler statt Arzt (OTK)"
      case Dialect.Heilpraktiker: return "Heilpraktiker statt Arzt (VSS & OTK)"
      case Dialect.KanzLaw: return "KanzLaw (alpha)"
      case Dialect.Mitarbeiter: return 'REHAaktiv Kooperation (VSS) + Mitarbeiter statt Arzt (OTK)'
      default: return ''
    }
  }


  /**
   * Returns file-url of default user avatar for given dialect (if existing)
   */
  public dialectUserDefaultAvatarUrl(dialect: Dialect): string {
    switch (dialect) {
      case Dialect.Mitarbeiter: return '/assets/avatars/mitarbeiter@500w.jpg'
      case Dialect.Apotheker: return '/assets/avatars/apotheker@500w.jpg'
      case Dialect.Heilpraktiker: return '/assets/avatars/heilpraktiker@500w.jpg'
      default: return '/assets/avatars/arzt@500w.jpg'
    }
  }

}
