import { ActiveInstance } from '@a-d/entities/ActiveInstance.entity';
import { InstanceForm } from '@a-d/entities/InstanceForm.entity';
import { FormHelpers } from '@a-d/forms/form-helpers.service';
import { I18NArrayPipe, I18NStringPipe } from '@a-d/i18n/i18n.pipe';
import { AnamneseForm } from '@a-d/instance-form/anamnese/anamnese-forms.service';
import { InstanceFormInterface } from '@a-d/instance-form/instance-form-component.interface';
import { InstanceFormService } from '@a-d/instance-form/instance-form.service';
import { InstanceService } from '@a-d/instance/instance.service';
import { UsefulComponent } from '@a-d/misc/useful.component';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import cloneDeep from 'lodash.clonedeep';
import { ReiseComponentData, ReiseSettings } from './reise-settings.entity';
import { ReiseService } from './reise.service';
import { I18NStringPipe as I18NStringPipe_1 } from '../../../../i18n/i18n.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectionFieldComponent } from '../../../../forms/fields/selection-field.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DestinationComponent } from './destination/destination.component';
import { NgFor, NgIf } from '@angular/common';
// import { pipe } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'app-reise',
    templateUrl: './reise.component.html',
    styleUrls: ['./reise.component.scss'],
    providers: [ReiseService],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        DestinationComponent,
        NgIf,
        MatButtonModule,
        MatIconModule,
        SelectionFieldComponent,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        TranslateModule,
        I18NStringPipe_1,
    ],
})
export class ReiseComponent
  extends UsefulComponent
  implements OnInit, InstanceFormInterface {
  @Input() anamneseForm: AnamneseForm;
  @Input() settings: ReiseSettings;
  formGroup: UntypedFormGroup;
  destinations: UntypedFormGroup[];

  accessControl(controlName: string) {
    return () => this.formGroup.get(controlName);
  }

  /**
   * Current Instance & Instance-Form Helpers
   */
  get activeInstance(): ActiveInstance {
    return this.instanceService.activeInstance;
  }
  get activeInstanceForm(): InstanceForm {
    return this.instanceFormService.activeInstanceForm;
  }
  readonly isDemoMode = environment.demoMode;

  constructor(
    private cd: ChangeDetectorRef,
    private formHelpers: FormHelpers,
    private i18nPipe: I18NArrayPipe,
    private i18nStringPipe: I18NStringPipe,
    private instanceFormService: InstanceFormService,
    private instanceService: InstanceService,
    public reiseService: ReiseService
  ) {
    super();
    this.formGroup = this.reiseService.formGroup;
    this.formHelpers.syncActiveAnamneseFormWithLocalStorage(
      this.activeInstanceForm,
      this.formGroup,
      this.unsubscribe$
    );
  }

  ngOnInit() {
    this.reiseService.destinations$
      .pipe(untilDestroyed(this))
      .subscribe((d) => {
        this.destinations = d;
      });
  }

  // InstanceFormInterface /////////////////////////////////////////////////////
  getData(): ReiseComponentData {
    let formData = this.formHelpers.trimmedRawValue(this.formGroup);
    // deeply nested option keys are translated rightaway
    formData = this.replaceKey(
      formData,
      'customPurpose',
      this.i18nStringPipe.transform(this.settings.purpose.customOption.title)
    );
    formData = this.replaceKey(
      formData,
      'customActivities',
      this.i18nStringPipe.transform(this.settings.activities.customOption.title)
    );
    const destinationsWithDuration = formData.destinations.map(
      (destination) => {
        return Object.assign({}, destination, {
          tripDurationInDays: ReiseService.dayDiff(
            destination.startDate,
            destination.endDate
          ),
        });
      }
    );
    const dataWithDuration = Object.assign({}, formData, {
      destinations: destinationsWithDuration,
    });
    return dataWithDuration;
  }

  magicFill() {
    const destinations = this.formGroup.get('destinations') as UntypedFormArray;

    const country0 = this.i18nPipe.transform(
      this.settings.wohinSelection.options
    )[0];
    destinations.at(0).get('country').setValue(country0);
    const startDate0 = new Date();
    startDate0.setDate(startDate0.getDate() + 7);
    destinations.at(0).get('startDate').setValue(startDate0);
    const endDate0 = new Date();
    endDate0.setDate(endDate0.getDate() + 22);
    destinations.at(0).get('endDate').setValue(endDate0);
    destinations
      .at(0)
      .get('arrivalDescription')
      .setValue('Via plane with a 2-week covid lockdown');

    if (destinations.length === 1) this.reiseService.addDestination();
    const country1 = this.i18nPipe.transform(
      this.settings.wohinSelection.options
    )[1];
    destinations.at(1).get('country').setValue(country1);
    const startDate1 = new Date();
    startDate1.setDate(startDate1.getDate() + 22);
    destinations.at(1).get('startDate').setValue(startDate1);

    const tripStyle = this.accessControl('tripStyle')();
    const style = this.i18nPipe.transform(this.settings.tripStyle.options)[0];
    tripStyle.setValue(style);

    const tripPurpose = this.accessControl('purpose')();
    const purpose = this.i18nPipe.transform(this.settings.purpose.options)[0];
    tripPurpose.setValue(purpose);

    const tripActivities = this.accessControl('activities')();
    const activity0 = this.i18nPipe.transform(
      this.settings.activities.options
    )[0];
    const activity1 = this.i18nPipe.transform(
      this.settings.activities.options
    )[1];
    tripActivities.setValue([activity0, activity1]);

    const tripOther = this.accessControl('other')();
    tripOther.setValue('Bitte bis Ende des Monats bearbeiten.');

    this.cd.detectChanges();
  }

  // rename `key` of `fIn` to `newKey`, keeping the value
  private replaceKey<T extends Object>(
    fIn: T,
    key: keyof T,
    newKey: string
  ): Object {
    return Object.assign(
      {},
      ...Object.entries(fIn).map(([key_, value_]) => {
        return key === key_
          ? { [newKey]: cloneDeep(value_) }
          : { [key_]: cloneDeep(value_) };
      })
    );
  }
}
