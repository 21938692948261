import Joi from 'joi'

export const formResponseOrigins = [
  'blur',
  'completeButton',
  'validateOnRender',
  'missing',
] as const

export const FormResponseOriginJoi = Joi.string()
  .valid(...formResponseOrigins)
  .meta({
    className: 'FormResponseOrigin',
  })
  .example('completeButton')
