import { Injectable } from '@angular/core';
import {
  BitString,
  BmpString, fromBER, Integer
} from 'asn1js';
import dayjs from 'dayjs';
import { AltName, AttributeTypeAndValue, BasicConstraints, Certificate, Extension, GeneralName } from 'pkijs';
import { arrayBufferToString, fromBase64, stringToArrayBuffer, toBase64 } from 'pvutils';
import { Observable, from, map } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { CertValues } from '../entities/CertValues.entity';
import { InstanceService } from '../instance/instance.service';
import { KeyService } from './key.service';
import { UserDataService } from './userdata.service';
import { UtilityService } from './utility.service';
import { defaults } from './lib/defaults'
import { initCertificate } from './lib/certificate/init-certificate'
import { addCertificateExtensions } from './lib/certificate/extensions'
import { addKeyAndSign } from './lib/certificate/add-key-and-sign'
import { createCertificate as _createCertificate } from './lib/certificate/create-certificate'

@Injectable({
  providedIn: 'root'
})
export class CertificateService {
  private hashAlgorithm = defaults.hashAlgorithm
  private signAlgorithm = defaults.signAlgorithm
  private issuer = defaults.certificate.issuer;

  private subject = {
    commonName: this.instanceService.activeInstance ? this.instanceService.activeInstance.name : 'demo',
    sat: `https://www.app.arzt-direkt.de/${this.instanceService.activeInstance ? this.instanceService.activeInstance.identifier : 'demo'}`,
    country: 'DE',
    ...(this.instanceService.activeInstance?.contact
      ? {
        organization: this.instanceService.activeInstance.contact.company,
        city: this.instanceService.activeInstance.contact.city,
        address: this.instanceService.activeInstance.contact.address_1,
        postalCode: this.instanceService.activeInstance.contact.zip,
      }
      : {
        organization: '',
        city: '',
        address: '',
        postalCode: '',
      })
  };

  constructor(
    private keyService: KeyService,
    private utilityService: UtilityService,
    private dataService: UserDataService,
    private instanceService: InstanceService,
  ) { }


  public decodeCert(pem: string) {
    // Load certificate in PEM encoding (base64 encoded DER)
    const b64 = pem.replace(/(-----(BEGIN|END) CERTIFICATE-----|[\n\r])/g, '')
    // Now that we have decoded the cert it's now in DER-encoding
    const der = Buffer.from(b64, 'base64');

    // And massage the cert into a BER encoded one
    const ber = new Uint8Array(der).buffer

    // And now Asn1js can decode things \o/
    const asn1 = fromBER(ber);

    return new Certificate({ schema: asn1.result });
  }



  /**
   * create certificate structure from an array buffer
   * @param certBuffer buffer containing certificate data
   */
  public bufferToCertificate(certBuffer: ArrayBuffer, update?: boolean): Observable<Certificate> {
    return new Observable((observer) => {
      if (certBuffer.byteLength === 0) {
        observer.error('Empty certificate buffer');
      } else {
        // Create Certificate
        const asn1 = fromBER(certBuffer);
        // console.log('ASN1', asn1)
        const certificate = new Certificate({ schema: asn1.result });
        if (update) {
          this.dataService.certificate = certificate;
        }
        observer.next(certificate);
        observer.complete();
      }
    });
  }

  /**
   * convert buffer to pem string format for easy export
   * @param certBuffer buffer containing certificate data
   */
  public writeCertPem(certBuffer: ArrayBuffer): Observable<string> {
    return new Observable((observer) => {
      const certData = arrayBufferToString(certBuffer);
      // const certData = String.fromCharCode.apply(null, new Uint8Array(certBuffer));
      const base64Cert = `${this.utilityService.formatPEM(toBase64(certData))}`;
      const certPem = `-----BEGIN CERTIFICATE-----\r\n${base64Cert}\n-----END CERTIFICATE-----\r\n`;
      observer.next(certPem);
      observer.complete();
    });
  }

  /**
   * convert base64 encoded certificate to pem file
   * @param certBase64 base64 encoded X.509 certificate
   */
  public base64ToCertPem(certBase64: string): Observable<string> {
    return new Observable((observer) => {
      const certBuffer = stringToArrayBuffer(fromBase64(certBase64));
      this.writeCertPem(certBuffer).subscribe((certPem) => {
        observer.next(certPem);
        observer.complete();
      })
    })
  }

  /**
   * create new X.509 certificate to hold the users public key
   */
  public createCertificate(): Observable<Certificate> {
    return from(
      _createCertificate(this.issuer, this.subject, this.hashAlgorithm),
    ).pipe(
      tap(({ certificate, privateKey, publicKey }) => {
        this.dataService.certificate = certificate
        this.dataService.privateKey = privateKey
        this.dataService.publicKey = publicKey
      }),
      map(({ certificate }) => certificate),
    )
  }

  public createCertificateWithKeys(keyPair: CryptoKeyPair): Observable<any> {
    return new Observable((observer) => {
      initCertificate(this.issuer, this.subject)
        .pipe(
          mergeMap((certificate) => addCertificateExtensions(certificate, this.subject.sat)),
          mergeMap((certificateExtensions) => addKeyAndSign(certificateExtensions, keyPair, this.hashAlgorithm)),
        )
        .subscribe((certificateSigned) => {
          const data = {
            certificate: certificateSigned,
            keys: keyPair
          }
          observer.next(data);
          observer.complete();
        }, (error) => {
          observer.error(error);
        })
    });
  }




}

