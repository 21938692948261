<ng-container [formGroup]="formGroup">

  <div class="c-form__row">

    <mat-form-field appearance="outline">
      <mat-label>Titel</mat-label>
      <input matInput formControlName="title" spellcheck="false" required>
      <mat-error *ngIf="title.errors && title.errors['notUnique']">Titel muss eindeutig sein</mat-error>
    </mat-form-field>

    <div style="flex-grow: 0; min-width: 0; margin-bottom: calc(1em + 1px); align-self: center">
      <button mat-icon-button (click)="remove.emit()" matTooltip="Feld entfernen" style="vertical-align: middle">
        <fa-icon [icon]="['far', 'trash-alt']" class="isError"></fa-icon>
      </button>
      <mat-checkbox formControlName="isRequired" style="padding-left: 1rem" color="primary">
        Pflichtfeld
      </mat-checkbox>
    </div>

  </div>

</ng-container>