import { Pipe, PipeTransform } from '@angular/core'
import { LocString } from '@arzt-direkt/wfa-definitions'
import { TranslateService } from '@ngx-translate/core'

@Pipe({
  name: 'i18n',
  standalone: true,
})
export class I18nPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: LocString): string {
    const currentLang =
      this.translateService.currentLang || this.translateService.defaultLang

    const result = value[currentLang] as unknown as string
    const fallback = value['de'] as unknown as string
    const fallback2 = Object.values(value)[0] as unknown as string
    return result || fallback || fallback2 || ''
  }
}
