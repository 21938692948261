import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { SocketUserRole } from '../entities/Socket.entity';
import { Origin } from '../entities/WebRTC.entity';

@Injectable({
  providedIn: 'root'
})
export class SocketFormattersService {

  constructor(
    private translate: TranslateService,
  ) { }

  /**
   * Returns a formatted version of the given socket user-role
   */
  public socketRoleFormatted(role: SocketUserRole): string {
    switch (role) {
      case SocketUserRole.Arzt: return this.translate.instant('Arzt')
      case SocketUserRole.Patient: return this.translate.instant('Patient')
      case SocketUserRole.Tool: return "iPhone"
      case SocketUserRole.Spezialist: return "Spezialist"
      default: return role
    }
  }

  /**
   * Creates a semantic description for the given file-metadata
   */
  public makeFileDescription(origin: Origin, senderRole?: SocketUserRole, capturedRole?: SocketUserRole): string {
    const senderRoleGiven = [SocketUserRole.Arzt, SocketUserRole.Patient, SocketUserRole.Tool, SocketUserRole.Spezialist].includes(senderRole)
    const capturedRoleGiven = [SocketUserRole.Arzt, SocketUserRole.Patient, SocketUserRole.Tool, SocketUserRole.Spezialist].includes(capturedRole)
    const senderRoleFormatted = this.socketRoleFormatted(senderRole)
    const capturedRoleFormatted = this.socketRoleFormatted(capturedRole)

    // Semantic Description
    let description: string
    if (origin === Origin.Upload && senderRoleGiven) {
      description = senderRoleFormatted + "-Upload"
    } else if (origin === Origin.Upload) {
      description = "Upload"
    } else if (origin === Origin.Snapshot && capturedRoleGiven) {
      description = "Aufnahme vom " + capturedRoleFormatted
    } else if (origin === Origin.Snapshot) {
      description = "Aufnahme"
    } else { // ==> origin === Origin.Snapshot = true
      description = "Datei"
    }

    return description
  }

  /**
   * Creates a semantic filename for the given file-metadata
   */
  public makeFileName(origin: Origin, extension: string, time?: dayjs.Dayjs, senderRole?: SocketUserRole, capturedRole?: SocketUserRole, description?: string): string {
    const timeFormatted = (time ? time : dayjs()).format('DD-MM-YY_HH-mm-ss-SSS')
    if (!description)
      description = this.makeFileDescription(origin, senderRole, capturedRole).split(' ').join('_')
    const fileName = `${timeFormatted}_${description}.${extension}`
    return fileName
  }
}
