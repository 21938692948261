import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { CountryCodes } from '@a-d/entities/Global-Setting.entity';
import WfaModule from '@a-d/wfr/wfa/wfa.module';
import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs/operators';
import { LanguageService } from '../../i18n/language.service';
import { COUNTRIES_DB } from './country-field-i18n';

/**
 * Country interface ISO 3166
 */
export interface Country {
  readonly name: string;
  readonly alpha2Code: string;
  readonly alpha3Code: string;
  readonly numericCode: string;
}


@UntilDestroy()
@Component({
  selector: 'app-country-field-custom',
  host: { 'class': 'c-form__row' },
  template: `
  <mat-form-field attr.data-selenium-id="field-land-custom" [formGroup]="formGroup" appearance="outline">
    <mat-label *ngIf="!customLabel">
      {{'ADDRESS-SEARCH-FIELD.COUNTRY' | translate }}<span *ngIf="isDynamicallyRequired" class="isError">*</span>
    </mat-label>
    <mat-label *ngIf="customLabel">
      {{customLabel}}<span *ngIf="isDynamicallyRequired" class="isError">*</span>
    </mat-label>
    <input attr.data-selenium-id="input-name" matInput spellcheck="false" autocomplete="chrome-off"
      [formControlName]="name"
      [required]="isRequired"
      [matAutocomplete]="countryAutocomplete">
      <mat-icon *ngIf="tooltip" matSuffix style="color: rgba(0, 0, 0, 0.54);" [matTooltip]="tooltip">help_outline</mat-icon>
    <mat-error *ngIf="formGroup.get(name).errors?.noCountryFound">{{ 'ADDRESS-SEARCH-FIELD.INVALID-COUNTRY' | translate }}</mat-error>
    <mat-error *ngIf="formGroup.get(name).errors?.countryNotAllowed">Erlaubte Länder: {{ onlyAllowedCountryNamesFormatted }}</mat-error>

    <mat-autocomplete #countryAutocomplete="matAutocomplete">
        <mat-option attr.data-selenium-id="mat-option-{{i}}"  *ngFor="let country of filteredCountries; let i = index" [value]="(returnValue === 'name') ? country?.name : ((returnValue === 'alpha2Code') ? country?.alpha2Code : country?.alpha3Code)">
          <small>{{country?.name }} - {{country?.alpha2Code}}</small>
        </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  `,
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatInputModule,
    MatAutocompleteModule,
    NgFor,
    MatOptionModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule
  ],
})
export class CountryFieldCustomComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup
  @Input() name = 'country'
  @Input() required: boolean
  @Input() returnValue: CountryCodes = CountryCodes.name
  @Input() onlyAllowLangCodes: string[]  // `alpha2Code` country-codes (see ./country-field-i18n.ts)
  @Input() useDynamicallyRequiredValidator = false;
  @Input() customLabel: string
  @Input() tooltip: string
  readonly requiredV = Validators.required
  readonly dynamicallyRequiredV = WfaModule.WfaFormValidatorsService.dynamicallyRequired
  isRequired = false
  isDynamicallyRequired = false
  translatedCountries: Country[] = []
  countryDataControl: AbstractControl

  public filteredCountries: Country[] = []
  public onlyAllowedCountryNamesFormatted: string

  constructor(
    private adLoggerService: AdLoggerService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    // hold country aplha2code information
    this.countryDataControl = this.formGroup.get('countryCode');
    // Init Validator
    this.updateCountryTranslation();
    this.initAllowedLangCodes()
    this.isRequired = this.required && this.useDynamicallyRequiredValidator !== true
    this.isDynamicallyRequired = this.required && this.useDynamicallyRequiredValidator === true
    const countryValidators = [this.countryValidator.bind(this)]
    if (this.isRequired) { countryValidators.push(this.requiredV) }
    if (this.isDynamicallyRequired) { countryValidators.push(this.dynamicallyRequiredV) }

    this.formGroup.get(this.name).setValidators(countryValidators)
    this.cd.detectChanges()

    // Filter countries based on input
    this.formGroup.get(this.name).valueChanges.pipe(
      startWith(this.formGroup.get(this.name).value),
      untilDestroyed(this)
    ).subscribe((value) => {
      this.filterCountries(value)
    })

    this.languageService.languageChangeSubject$.pipe(
      untilDestroyed(this)
    ).subscribe({
      next: () => this.updateCountryTranslation(),
      error: (error) => this.adLoggerService.error(error)
    })
  }

  public displayFunction(country?: Country): string | undefined {
    return (country[this.returnValue]) ? country[this.returnValue] : undefined;
  }

  private updateCountryTranslation() {
    this.translatedCountries = COUNTRIES_DB.map((country) => {
      const translatedCountry = Object.assign({}, country);
      translatedCountry.name = this.translate.instant(country.name)
      return translatedCountry;
    });
    this.filteredCountries = [];
    this.filterCountries(this.formGroup.get(this.name).value)
  }

  private initAllowedLangCodes() {
    if (this.onlyAllowLangCodes?.length) {
      this.onlyAllowedCountryNamesFormatted = this.translatedCountries
        .filter((c) => this.onlyAllowLangCodes.includes(c.alpha2Code))
        .map((c) => c.name)
        .join(', ')
    }
  }

  private filterCountries(value: string) {
    const valueRegex = new RegExp(value, 'i')
    this.filteredCountries = this.translatedCountries
      .filter((c) => {
        return !this.onlyAllowLangCodes?.length
          || this.onlyAllowLangCodes.includes(c.alpha2Code)
      })
      .filter((c) => {
        return c.name.match(valueRegex)
          || c.alpha2Code.match(valueRegex)
          || c.alpha3Code.match(valueRegex)
      })
  }

  private countryValidator(control: FormControl<string | null>): ValidationErrors {
    const errorValue = { value: control.value }
    if (!control.value && this.required) { return null }

    const emptyControlValue = (control.value === null || control.value === undefined || control.value === '')
    if (emptyControlValue && !this.required) {
      if (this.countryDataControl && this.countryDataControl.value !== null) this.countryDataControl.setValue(null)
      return null
    }
    let country = null;
    let validatorValue;
    const uppercaseValue = control?.value.toUpperCase();
    this.filterCountries(this.formGroup.get(this.name).value)

    switch (this.returnValue) {
      case CountryCodes.name:
        country = this.filteredCountries.filter((c) => c.name === control.value)[0]
        validatorValue = this.handleCountry(country, errorValue);
        return validatorValue ?? null;
      case CountryCodes.alpha2code:
        country = this.filteredCountries.filter((c) => c.alpha2Code === uppercaseValue)[0]
        validatorValue = this.handleCountry(country, errorValue);
        return validatorValue ?? null;
      case CountryCodes.alpha3code:
        country = this.filteredCountries.filter((c) => c.alpha3Code === uppercaseValue)[0]
        validatorValue = this.handleCountry(country, errorValue);
        return validatorValue ?? null;
      default:
        break;
    }
    return null;
  }

  private handleCountry(country: Country, errorValue: any) {
    if (!country) { return { 'noCountryFound': errorValue } }
    const countryIsAllowed = !this.onlyAllowLangCodes?.length
      || this.onlyAllowLangCodes.includes(country.alpha2Code)
    if (!countryIsAllowed) { return { 'countryNotAllowed': errorValue } }
    if (this.countryDataControl && this.countryDataControl.value !== country.alpha2Code) {
      this.countryDataControl.setValue(country.alpha2Code)
    }
  }

}
