import { CommonModule } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog'
import { CoreLocale } from '@arzt-direkt/wfa-definitions'
import { IsEmbeddedIn } from '@arzt-direkt/wfa-definitions'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { TranslateModule, TranslateService } from '@ngx-translate/core'

import { translateMatDialogContent } from '../../../../utility/translate-mat-dialog-content'

interface DialogData {
  fileName?: string
  locale?: CoreLocale
  isEmbeddedIn?: IsEmbeddedIn
}

@Component({
  selector: 'wfa-deletion-dialog',
  templateUrl: './deletion-dialog.component.html',
  styleUrls: [
    './deletion-dialog.component.scss',
    '../../../../../scss/button.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
  ],
})
export class DeletionDialogComponent {
  readonly faXmark = faXmark

  constructor(
    private dialogRef: MatDialogRef<DeletionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translate: TranslateService,
  ) {
    translateMatDialogContent(this.translate, this.data.locale)
  }

  continue() {
    this.dialogRef.close('delete')
  }
}
