import { QuestionTextModel } from 'survey-core'
import { PropertyValueChangingEvent } from 'survey-creator-core'

import { setWfaInputType } from './set-wfa-input-type'
import { addValidator, removeValidator } from './validator-utils'

export function updatePropertiesWithInputType(
  options: PropertyValueChangingEvent,
): void {
  const question = options.obj as QuestionTextModel
  const newInputType = options.newValue
  const oldInputType = options.value

  setWfaInputType(newInputType, question)
  addValidator(newInputType, question)
  removeValidator(oldInputType, question)
}
