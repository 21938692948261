<div class="c-dsgvo-bottom-sheet">

  <!-- Main-Content -->
  <div>
    <h1>
      {{ 'DSGVO.TITLE' | translate }}
    </h1>

    <p>
      {{ 'DSGVO.DESCRIPTION' | translate }}
    </p>

    <button class="c-dsgvo-bottom-sheet__privacy-button" (click)="openDatenschutz()">
      {{ 'DSGVO.PRIVACY-BUTTON' | translate }}
    </button>

    <div class="c-button-group c-button-group--noBottomMargin">
      <button mat-flat-button (click)="acceptAllDsgvoPreferences()" color="primary">
        <fa-icon [icon]="['fas', 'check-double']"></fa-icon>
        <span>
          {{ 'DSGVO.ACCEPT-ALL' | translate }}
        </span>
      </button>

      <button mat-flat-button (click)="openDsgvoPreferencesDialog()">
        {{ 'DSGVO.SETTINGS' | translate }}
      </button>
    </div>
  </div>

  <!-- Side-Image -->
  <img class="c-dsgvo-bottom-sheet__bg" src="/assets/images/privacy/privacy-1@2x.jpg" />

</div>