<div [ngSwitch]="showContentTitle">
  <wfa-plain-message
    [message]="showContentJson"
    *ngSwitchCase="'waitingForInput'"
  >
  </wfa-plain-message>
  <wfa-plain-message [message]="showContentJson" *ngSwitchCase="'loading'">
  </wfa-plain-message>
  <div *ngSwitchCase="'form'">
    <wfa-viewer
      [form$]="form$"
      [formResponse$]="formResponse$"
      [envState$]="envState$"
      (formResponseDataEmitter)="onFormResponseDataEmitted($event)"
      #wfaViewer
    ></wfa-viewer>
  </div>
  <wfa-plain-message [message]="showContentJson" *ngSwitchCase="'invalidForm'">
  </wfa-plain-message>
  <div *ngSwitchDefault>internal webview component error</div>
</div>
