import { CoreLocale } from '@arzt-direkt/wfa-definitions'
import { CustomError, SurveyModel, ValidateQuestionEvent } from 'survey-core'

import { defaultLocale } from '../../../default-locale'

const questionTypesWithExclusiveReponseOptions = [
  'contact',
  'sending-information',
]

export function validateExclusiveResponseOptions(
  surveyModel: SurveyModel,
  options: ValidateQuestionEvent,
) {
  const question = options.question
  const questionType = question.getType()
  if (!questionTypesWithExclusiveReponseOptions.includes(questionType)) return

  const response = options.value
  if (response.length > 1 && response.includes('none')) {
    const locale = surveyModel.locale as CoreLocale
    const errMessage = getLocalizedErrorMessage(locale)

    //display error in viewer
    const err = new CustomError(errMessage)
    question.errors.push(err)

    //display error in editor preview
    options.error = errMessage
  }
}

function getLocalizedErrorMessage(locale: CoreLocale): string {
  const errorMessageDict: Record<CoreLocale, string> = {
    de: 'Eingabe ungültig.',
    en: 'Input invalid.',
    fr: 'Saisie non valide.',
    es: 'Entrada inválida.',
    it: 'Ingresso non valido.',
  }
  return errorMessageDict[locale] ?? errorMessageDict[defaultLocale]
}
