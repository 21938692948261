export interface State {
  name: string            // translated name of the subdivision
  countryCode: string     // alpha2code of the country
  subdivisionCode: string // code for the country specific subdivision
}

export const STATES_DB: State[] =
  [
    // germany
    {
      name: 'ADDRESS-STATE.DE-BW',
      countryCode: 'DE',
      subdivisionCode: 'BW'
    },
    {
      name: 'ADDRESS-STATE.DE-BY',
      countryCode: 'DE',
      subdivisionCode: 'BY'
    },
    {
      name: 'ADDRESS-STATE.DE-BE',
      countryCode: 'DE',
      subdivisionCode: 'BE'
    },
    {
      name: 'ADDRESS-STATE.DE-BB',
      countryCode: 'DE',
      subdivisionCode: 'BB'
    },
    {
      name: 'ADDRESS-STATE.DE-HB',
      countryCode: 'DE',
      subdivisionCode: 'HB'
    },
    {
      name: 'ADDRESS-STATE.DE-HH',
      countryCode: 'DE',
      subdivisionCode: 'HH'
    },
    {
      name: 'ADDRESS-STATE.DE-HE',
      countryCode: 'DE',
      subdivisionCode: 'HE'
    },
    {
      name: 'ADDRESS-STATE.DE-MV',
      countryCode: 'DE',
      subdivisionCode: 'MV'
    },
    {
      name: 'ADDRESS-STATE.DE-NI',
      countryCode: 'DE',
      subdivisionCode: 'NI'
    },
    {
      name: 'ADDRESS-STATE.DE-NW',
      countryCode: 'DE',
      subdivisionCode: 'NW'
    },
    {
      name: 'ADDRESS-STATE.DE-RP',
      countryCode: 'DE',
      subdivisionCode: 'RP'
    },
    {
      name: 'ADDRESS-STATE.DE-SL',
      countryCode: 'DE',
      subdivisionCode: 'SL'
    },
    {
      name: 'ADDRESS-STATE.DE-SN',
      countryCode: 'DE',
      subdivisionCode: 'SN'
    },
    {
      name: 'ADDRESS-STATE.DE-ST',
      countryCode: 'DE',
      subdivisionCode: 'ST'
    },
    {
      name: 'ADDRESS-STATE.DE-SH',
      countryCode: 'DE',
      subdivisionCode: 'SH'
    },
    {
      name: 'ADDRESS-STATE.DE-TH',
      countryCode: 'DE',
      subdivisionCode: 'TH'
    },
    //------------
    // switzerland
    //------------
    {
      name: 'ADDRESS-STATE.CH-AG',
      countryCode: 'CH',
      subdivisionCode: 'AG'
    }, {
      name: 'ADDRESS-STATE.CH-AR',
      countryCode: 'CH',
      subdivisionCode: 'AR'
    }, {
      name: 'ADDRESS-STATE.CH-AI',
      countryCode: 'CH',
      subdivisionCode: 'AI'
    }, {
      name: 'ADDRESS-STATE.CH-BL',
      countryCode: 'CH',
      subdivisionCode: 'BL'
    }, {
      name: 'ADDRESS-STATE.CH-BS',
      countryCode: 'CH',
      subdivisionCode: 'BS'
    }, {
      name: 'ADDRESS-STATE.CH-BE',
      countryCode: 'CH',
      subdivisionCode: 'BE',
    }, {
      name: 'ADDRESS-STATE.CH-FR',
      countryCode: 'CH',
      subdivisionCode: 'FR'
    }, {
      name: 'ADDRESS-STATE.CH-GE',
      countryCode: 'CH',
      subdivisionCode: 'GE'
    }, {
      name: 'ADDRESS-STATE.CH-GL',
      countryCode: 'CH',
      subdivisionCode: 'GL'
    }, {
      name: 'ADDRESS-STATE.CH-GR',
      countryCode: 'CH',
      subdivisionCode: 'GR'
    }, {
      name: 'ADDRESS-STATE.CH-JU',
      countryCode: 'CH',
      subdivisionCode: 'JU'
    }, {
      name: 'ADDRESS-STATE.CH-LU',
      countryCode: 'CH',
      subdivisionCode: 'LU'
    }, {
      name: 'ADDRESS-STATE.CH-NE',
      countryCode: 'CH',
      subdivisionCode: 'NE'
    }, {
      name: 'ADDRESS-STATE.CH-NW',
      countryCode: 'CH',
      subdivisionCode: 'NW'
    }, {
      name: 'ADDRESS-STATE.CH-OW',
      countryCode: 'CH',
      subdivisionCode: 'OW'
    }, {
      name: 'ADDRESS-STATE.CH-SH',
      countryCode: 'CH',
      subdivisionCode: 'SH'
    }, {
      name: 'ADDRESS-STATE.CH-SZ',
      countryCode: 'CH',
      subdivisionCode: 'SZ'
    }, {
      name: 'ADDRESS-STATE.CH-SO',
      countryCode: 'CH',
      subdivisionCode: 'SO'
    }, {
      name: 'ADDRESS-STATE.CH-SG',
      countryCode: 'CH',
      subdivisionCode: 'SG'
    }, {
      name: 'ADDRESS-STATE.CH-TI',
      countryCode: 'CH',
      subdivisionCode: 'TI'
    }, {
      name: 'ADDRESS-STATE.CH-TG',
      countryCode: 'CH',
      subdivisionCode: 'TG'
    }, {
      name: 'ADDRESS-STATE.CH-UR',
      countryCode: 'CH',
      subdivisionCode: 'UR'
    }, {
      name: 'ADDRESS-STATE.CH-VD',
      countryCode: 'CH',
      subdivisionCode: 'VD'
    }, {
      name: 'ADDRESS-STATE.CH-VS',
      countryCode: 'CH',
      subdivisionCode: 'VS'
    }, {
      name: 'ADDRESS-STATE.CH-ZG',
      countryCode: 'CH',
      subdivisionCode: 'ZG'
    }, {
      name: 'ADDRESS-STATE.CH-ZH',
      countryCode: 'CH',
      subdivisionCode: 'ZH'
    },
    //--------
    // austria
    //--------
    {
      name: 'ADDRESS-STATE.AT-1',
      countryCode: 'AT',
      subdivisionCode: '1'
    }, {
      name: 'ADDRESS-STATE.AT-2',
      countryCode: 'AT',
      subdivisionCode: '2'
    }, {
      name: 'ADDRESS-STATE.AT-3',
      countryCode: 'AT',
      subdivisionCode: '3'
    }, {
      name: 'ADDRESS-STATE.AT-4',
      countryCode: 'AT',
      subdivisionCode: '4'
    }, {
      name: 'ADDRESS-STATE.AT-5',
      countryCode: 'AT',
      subdivisionCode: '5'
    }, {
      name: 'ADDRESS-STATE.AT-6',
      countryCode: 'AT',
      subdivisionCode: '6'
    }, {
      name: 'ADDRESS-STATE.AT-7',
      countryCode: 'AT',
      subdivisionCode: '7'
    }, {
      name: 'ADDRESS-STATE.AT-8',
      countryCode: 'AT',
      subdivisionCode: '8'
    }, {
      name: 'ADDRESS-STATE.AT-9',
      countryCode: 'AT',
      subdivisionCode: '9'
    }
  ]