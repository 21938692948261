import { SurveyjsPropertyInfo } from '@arzt-direkt/wfa-definitions'

export const searchMode: SurveyjsPropertyInfo = {
  name: 'searchMode',
  surveyElements: ['dropdown', 'tagbox'],
  customPropertySettings: [
    {
      settingName: 'defaultValue',
      settingValue: 'startsWith',
    },
  ],
}
