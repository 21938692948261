import { LanguagePickerService } from './../../utility-components/language-picker/language-picker.service'
import { submitResponse } from './single-or-multilink/api/submit-response'
import { LinkType } from './single-or-multilink/single-or-multilink.types'
import { SingleOrMultilinkState } from './single-or-multilink/single-or-multilink.state'
import { SingleOrMultilinkStore } from './single-or-multilink/single-or-multilink.store'
import { NotificationService } from './../../utility-components/notification/notification.service'
import { CommonModule } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import {
  ViewerLoadingState,
  WfaFormWithResponse as Fwr,
  hasUnencryptedResponse,
  WfaForm,
  FormResponseData,
} from '@arzt-direkt/wfa-definitions'
import { notNullish, nullish, Maybe } from '@arzt-direkt/wfa-generic-utils'
import {
  BehaviorSubject,
  filter,
  switchMap,
  map,
  tap,
  withLatestFrom,
  distinctUntilChanged,
  catchError,
  of,
} from 'rxjs'

import { logWfaEnv } from '../../../environments/log-wfa-env'
import { WfaEnvService } from '../../../environments/wfa-env.service'
import { UserMessageComponent } from '../../user-message/user-message.component'
import submitting from '../../user-message/viewer-from-link-submitting.json'
import successfullySubmitted from '../../user-message/viewer-from-link-successfully-submitted.json'
import { checkFormResponseOrigin } from '../../utility/check-form-response-origin'
import { ViewerComponent } from '../../viewer/viewer.component'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-viewer-from-link',
  templateUrl: './viewer-from-link.component.html',
  styleUrls: [
    './viewer-from-link.component.scss',
    '../../viewer/viewer-parent.scss',
  ],
  imports: [CommonModule, ViewerComponent, UserMessageComponent],
})
export class ViewerFromLinkComponent implements OnInit {
  envState$ = this.store.env$
  form$ = this.store.form$
  formResponse$ = this.store.formResponse$
  requestResponse$ = this.store.requestResponse$
  emitFormResponseData$ = new BehaviorSubject<Maybe<FormResponseData>>(null)
  status: ViewerLoadingState = 'survey'
  submittingJson = submitting
  submittedJson = successfullySubmitted
  certBase64: Maybe<string> = null // to encrypt patient response
  hasCompletedHtml = false

  constructor(
    private httpClient: HttpClient,
    private languagePicker: LanguagePickerService,
    private notificationService: NotificationService,
    private router: Router,
    private wfaEnv: WfaEnvService,
    private store: SingleOrMultilinkStore,
  ) {}

  ngOnInit(): void {
    this.wfaEnv.logger.info([
      `[ViewerFromLink/ngOnInit]: called with wfaEnv ${logWfaEnv(this.wfaEnv)}`,
    ])

    // for some reason if this subscribtion does not exist,
    // the viewerStore in viewer.component.ts does not
    // update its formResponse$ from this one correctly
    this.formResponse$.pipe(untilDestroyed(this)).subscribe()
    this.store.fwr$
      ?.pipe(untilDestroyed(this))
      .subscribe(({ form, certificate }: Fwr) => {
        this.hasCompletedHtml = notNullish(form['completedHtml'])
        this.certBase64 = certificate
        const locales = Object.keys(form.title)
        this.languagePicker.updateLocales(locales)
      })

    this.emitFormResponseData$
      .pipe(
        filter(notNullish),
        filter((data) =>
          hasUnencryptedResponse(data.formResponse.surveyjsResponse),
        ),
        withLatestFrom(this.store.isMultilink$),
        tap(([{ formResponse }, isMultilink]) => {
          if (isMultilink === true) {
            this.store.setFwrsResponse(formResponse)
          }
        }),
        map(([data]) => data),
        filter((formResponseData) =>
          checkFormResponseOrigin(formResponseData, ['completeButton']),
        ),
        tap(() => {
          if (!this.hasCompletedHtml) {
            this.status = 'submitting'
          }
        }),
        withLatestFrom(this.store.form$, this.store.linkType$),
        switchMap(([formResponseData, form, linkType]) => {
          if (linkType === 'loading' || linkType === 'loadError') {
            throw new Error(
              `[ViewerFromLinkComponent/emitFormResponseData$]: unknown linkType ${linkType}`,
            )
          }
          const fwrs = [
            {
              ...formResponseData,
              form,
            },
          ]
          console.log(
            'catbug submitting data',
            JSON.stringify(formResponseData.formResponse, null, 2),
            JSON.stringify(fwrs[0].formResponse, null, 2),
          )

          return this.submitFormResponseData(fwrs, linkType)
        }),
        tap(() => {
          if (!this.hasCompletedHtml) {
            this.status = 'submitted'
          }
        }),
        catchError((error) => {
          this.wfaEnv.logger.error(
            '[ViewerFromLinkComponent/emitFormResponseData$]: fallback error',
            error?.message,
          )
          return of(null)
        }),
      )
      .subscribe()
  }

  submitFormResponseData = (fwrs: Fwr[], type: LinkType) => {
    return submitResponse(
      { fwrs, type },
      this.httpClient,
      this.wfaEnv,
      this.router,
    )
  }
}
