import Joi from 'joi'

import { surveyjsResponseJoiExample } from './surveyjs-response.joi.example'
import { wfaKeys } from './wfa-key.joi'

// Predefined set of allowed keys
const allowedKeys = wfaKeys

// allowed values
const allowedValuesJoi = Joi.alternatives(
  Joi.string(),
  Joi.array().items(Joi.string()),
)

// Define Joi schema
export const SurveyjsResponseJoi = Joi.object()
  .pattern(Joi.string().valid(...allowedKeys), allowedValuesJoi)
  .unknown(true)
  .meta({
    className: 'SurveyjsResponse',
  })
  .example(surveyjsResponseJoiExample)
