import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { LanguageService } from '@a-d/i18n/language.service';
import { formatCurrency } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ArztType } from '@lib/entities/ArztType.entity';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../dashboard/user/user.service';
import { InstanceFormTarget } from '../entities/InstanceForm.entity';
import { PatientAttestSentEmailData, PatientCompletedEmailData, PatientEmailData, PatientEmailType, PatientJoinedEmailData } from '../entities/PatientEmail.entity';
import { PatientSession } from '../entities/PatientSession.entity';
import { User } from '../entities/User.entity';
import { PatientSessionFormattersService } from '../formatters/patient-session-formatters.service';
import { UserFormattersService } from '../formatters/user-formatters';
import { InstanceService } from '../instance/instance.service';
import { InsuranceService } from '../insurance/insurance.service';


@Injectable({
  providedIn: 'root'
})
export class PatientEmailService {

  // translation keys for dialects
  public readonly TRANSLATION_KEYS = [
    "PatientD",
    "Patienten",
    "ArztD",
    "privatarzt",
    "kassenarzt",
    "Praxis",
    "videosprechstunde",
    "Videosprechstunden",
  ]

  constructor(
    private adLoggerService: AdLoggerService,
    private http: HttpClient,
    private patientFormatter: PatientSessionFormattersService,
    private userFormatter: UserFormattersService,
    private authService: AuthService,
    private insuranceService: InsuranceService,
    private userService: UserService,
    private translate: TranslateService,
    private languageService: LanguageService,
    private instanceService: InstanceService,
    @Inject(LOCALE_ID) private locale: string
  ) { }


  /**
   * send an informational email to the patients email address
   * @param session patient-session containing all information
   * @param type type of email to send
   */
  public sendPatientEmail(type: PatientEmailType, session: PatientSession, instanceIdentifier: string): Observable<any> {
    if (!type || !session) {
      this.adLoggerService.error(`type: ${JSON.stringify(type, null, 2)}, session: ${JSON.stringify(session, null, 2)}`, `sendPatientEmail: Couldn't send Patient-Email as not sufficient data is given`)
      return
    }

    let doctor = session.doctor
    if (doctor && this.authService.user && <any>doctor === this.authService.user?._id) {
      doctor = this.authService.user
    }

    let targetMailAdresse = session.person.email
    console.log("sendPatientEmail: targetMailAdresse = ", targetMailAdresse)
    console.log("sendPatientEmail: session = ", session)
    if (session.form?.target === InstanceFormTarget.Arzt) { targetMailAdresse = session.anamnese.arztEmail }

    const emailData = this.gatherPatientEmailData(type, session, doctor, instanceIdentifier)

    const body = {
      to: targetMailAdresse,
      emailType: type,
      emailData: { ...emailData },
      localization: {
        preferredLocale: session.preferredLocale || this.languageService.activeBaseLang,
        dialect: this.instanceService.activeInstance.dialect,
        dialect_keys: this.TRANSLATION_KEYS,
      }
    };

    console.log("sendPatientEmail: Sending Patient E-Mail. body = ", body)
    return this.http.post('/api/email/send/patient', body)
  }


  /**
   * Gathers PatientEmailData for given PatientEmailType .
   */
  private gatherPatientEmailData(type: PatientEmailType, session: PatientSession, doctor: User, instanceIdentifier: string): PatientEmailData {
    switch (type) {
      case PatientEmailType.SessionJoined: return this.gatherPatientJoinedEmailData(session, doctor, instanceIdentifier)
      case PatientEmailType.SessionCompleted: return this.gatherPatientCompletedEmailData(session, doctor, instanceIdentifier)
      case PatientEmailType.AttestSent: return this.gatherPatientAttestSentEmailData(session, doctor, instanceIdentifier)
      default: return null
    }
  }

  private gatherPatientJoinedEmailData(session: PatientSession, doctor: User, instanceIdentifier: string): PatientJoinedEmailData {
    return {
      instanceIdentifier,
      sessionId: session._id,
    }
  }

  private gatherPatientCompletedEmailData(session: PatientSession, doctor: User, instanceIdentifier: string): PatientCompletedEmailData {
    console.log("gatherPatientCompletedEmailData")
    if (!session.documentation || !session.doctor) {
      this.adLoggerService.error(session, "gatherPatientCompletedEmailData")
      return null
    }

    return {
      instanceIdentifier,
      patientName: this.patientFormatter.fullName(session.person),
      patientAddress: this.patientFormatter.address(session),
      // insurance: this.patientFormatter.insuranceFormatted(session),
      insuranceType: this.insuranceService.longNameFormatted(session.insurance.insuranceType),
      sessionDate: dayjs(session.createdAt).format('DD.MM.YYYY'),
      attest: session.documentation.attest?.approved,
      attestPeriod: this.patientFormatter.attestPeriodFormatted(session.documentation.attest?.startDate, session.documentation.attest?.endDate, true),
      attestDurationDays: session.documentation.attest?.durationDays,
      arztName: this.userFormatter.fullName(doctor),
      arztType: doctor.arzt.arztType === ArztType.Kassenarzt ? 'kassenarzt' : 'privatarzt',//this.userFormatter.arztType(doctor.arzt.arztType),
      praxis: this.userService.getPraxis(doctor),
      instanceFormTitle: session.form.title,
      paymentIsRequired: session.payment.isRequired,
      paymentPrice: session.payment.price,
      paymentPriceFormatted: formatCurrency(session.payment.price, this.locale, '€'),
      paymentHandler: session.payment.handler,
    }
  }

  private gatherPatientAttestSentEmailData(session: PatientSession, doctor: User, instanceIdentifier: string): PatientAttestSentEmailData {
    return {
      instanceIdentifier,
      attestPeriod: this.patientFormatter.attestPeriodFormatted(session.documentation.attest?.startDate, session.documentation.attest?.endDate, true),
      attestDurationDays: session.documentation.attest?.durationDays,
      arztName: this.userFormatter.fullName(doctor),
      arztType: doctor.arzt.arztType === ArztType.Kassenarzt ? 'kassenarzt' : 'privatarzt',//this.userFormatter.arztType(doctor.arzt.arztType),
      praxis: this.userService.getPraxis(doctor),
    }
  }


}
