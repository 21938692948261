import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, Subscription } from 'rxjs';
import { ColorService } from './color.service';

@Injectable({
  providedIn: 'root'
})
export class LogoService {

  public logoUrl: string
  public windowWidth: number
  public currentRectangleLogoRandomString: string = null
  public currentSquareLogoRandomString: string = null

  public resizeObservable$: Observable<Event>
  public resizeSubscription$: Subscription
  private logoHeight: string
  private paddingTop: string
  private paddingBottomWide: string
  private paddingBottomNarrow: string
  private paddingTopSmall: string
  private paddingTopLarge: string
  constructor(
    private colorService: ColorService
  ) { }

  public initLogos(instanceId: string, windowWidth: number) {
    let defaultLogo = '/assets/brand/arzt-direkt--white.svg';
    let defaultSmallLogo = '/assets/brand/arzt-direkt--white--small.svg';

    if (this.colorService.lightHeader) {
      defaultLogo = '/assets/brand/arzt-direkt--blue.svg';
      defaultSmallLogo = '/assets/brand/arzt-direkt--blue--small.svg';
    }

    this.setLogoHeight(this.colorService.largeLogo, windowWidth);

    if (instanceId === 'admin') {
      if (windowWidth < 450) return this.logoUrl = defaultSmallLogo;
      return this.logoUrl = defaultLogo;
    }
    if (instanceId) {
      if (windowWidth < 450) return this.logoUrl = `${environment.url}/api/instance/asset/${instanceId}/logoImageSquare`;
      return this.logoUrl = `${environment.url}/api/instance/asset/${instanceId}/logoImageRectangle`;
    }
    if (windowWidth < 450) return this.logoUrl = defaultSmallLogo;
    return this.logoUrl = defaultLogo;
  }

  public setLogoHeight(largeLogo: boolean, windowWidth: number) {
    if (windowWidth < 375) {
      this.logoHeight = '2rem';
    }
    else {
      this.logoHeight = largeLogo ? '4rem' : '2rem';
    }
    document.documentElement.style.setProperty('--logoHeight', this.logoHeight);
  }

  public setPaddingMain(largeLogo: boolean) {
    this.paddingTop = largeLogo ? '1.5rem' : '0.5rem';
    document.documentElement.style.setProperty('--paddingTop', this.paddingTop);
    this.paddingBottomWide = largeLogo ? '1.5rem' : '0.5rem';
    document.documentElement.style.setProperty('--paddingBottomWide', this.paddingBottomWide);
    this.paddingBottomNarrow = largeLogo ? '0.5rem' : '0rem';
    document.documentElement.style.setProperty('--paddingBottomNarrow', this.paddingBottomNarrow);
  }

  public setPaddingHome(largeLogo: boolean) {
    this.paddingTopSmall = largeLogo ? '4rem' : '2.5rem';
    document.documentElement.style.setProperty('--paddingTopSmall', this.paddingTopSmall);
    this.paddingTopLarge = largeLogo ? '5rem' : '3.5rem';
    document.documentElement.style.setProperty('--paddingTopLarge', this.paddingTopLarge);
  }
}
