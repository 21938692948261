import { CustomPropertyInfo } from './../../../../interfaces-autogen-from-joi/custom-property-info'

const wfaFormVersionId: CustomPropertyInfo = {
  name: 'wfaFormVersionId',
  type: 'string',
  visible: false,
}

const surveyElements = ['survey']

export const surveyElementsWithWfaFormVersionId = surveyElements.reduce(
  (acc, item) => {
    acc[item] = wfaFormVersionId
    return acc
  },
  {} as Record<string, CustomPropertyInfo>,
)
