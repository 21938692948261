import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
    selector: 'lib-dialog',
    templateUrl: './dialog.component.html',
    standalone: true,
    imports: [
        MatProgressBarModule,
        NgIf,
        MatButtonModule,
        MatDialogModule,
        MatTooltipModule,
        MatIconModule,
        TranslateModule,
    ],
})
export class DialogComponent implements OnInit {

    @Input() isLoading: boolean
    @Input() noPadding: boolean
    @Input() isError: boolean
    @Input() hasTabGroup: boolean
    @Input() hasActionsBar: boolean
    @Input() isTransparent: boolean
    @Input() noDialogActions: boolean
    @Input() hideTitle: boolean
    @Input() hideClose: boolean = false
    @Input() background: string
    @Input() height: string

    @ViewChild('CloseButtonRef', { static: true }) closeButtonRef: TemplateRef<any>


    constructor() { }

    ngOnInit() { }

}
