export const example = {
  type: 'text',
  name: '$wfaPatientTelefonMobil$',
  startWithNewLine: false,
  title: {
    de: 'Telefon mobil',
  },
  inputType: 'custom-phone-number',
  shortTitle: 'Telefon mobil',
  validators: [
    {
      type: 'expression',
      text: {
        de: 'Ungültige Telefonnummer.',
      },
      expression: 'isValidPhoneNumber({$wfaPatientTelefonMobil$})',
    },
  ],
}
