import { terminKettenAppTest } from '@a-d/booking-shared/app-predicates';
import { AdPayCheckoutService } from '@a-d/dashboard/ad-pay/ad-pay-checkout.service';
import { StoreTypes } from '@a-d/entities/AdPay.entity';
import { Dialect } from '@a-d/entities/Instance.entity';
import { MobileAppVersion } from '@a-d/entities/MobileApp.entity';
import { LanguageService } from '@a-d/i18n/language.service';
import { AdLoggerService } from '@a-d/logging/ad-logger.service';
import { AnamneseFormCheckerService } from '@a-d/misc/anamnese-form-checker.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Type, ViewContainerRef } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { NotificationService } from '@lib/notifications/notification.service';
import { ICustomFieldResponse } from '@models/otk-appointment/CustomFieldResponse.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import WfaModule from '@wfr/wfa/wfa.module';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, Subject, Subscriber, Subscription, forkJoin, merge, of } from 'rxjs';
import { catchError, delay, filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { I18NString_MISSING_REDIRECT_LINK } from 'vendor/arzt-direkt';
import { environment } from '../../environments/environment';
import { BookingHelpersService } from '../booking-shared/booking-helpers.service';
import { AppointmentCategory, AppointmentSeriesTypeItem, AppointmentType, Betriebsstaette, BookedOverType, BookingOpening, BookingResult, BookingStep, I18NString, I18NString_MISSING_VALUE, MessageToApp, OtkAppointment, OtkAppointmentSeries, OtkAppointmentStatus, OtkAttachment, OtkDoctor, OtkEventType, OtkReservation, OtkReservationL, PatientInsuranceType, TomKalenderDaten, TomTerminLokalitaet } from '../entities/Booking.entity';
import { OTKUser, PatientTargetType } from '../entities/Calendar.entity';
import FormValidators from '../forms/form-validators.service';
import { AnamneseFormsService } from '../instance-form/anamnese/anamnese-forms.service';
import { InstanceFormInterface } from '../instance-form/instance-form-component.interface';
import { InstanceFormService } from '../instance-form/instance-form.service';
import { InstanceService } from '../instance/instance.service';
import { BrowserSupportService } from '../misc/browser-support/browser-support.service';
import { MultiSlots } from './booking-date-multi/multislots';
import { BookingPersonalComponent } from './booking-personal/booking-personal.component';
import { BookingSnackbarComponent } from './booking-snackbar/booking-snackbar.component';
import { OpeningService } from './opening.service';

const BRIDGE_TIME = 5

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class BookingService {

  /* general */
  error: Error
  MAXIMAL_ALLOWED_BOOKING_IN_ADVANCE_DAYS = 365 // allow users to book up to 1 year in advance (source: talk with Madita on the 2022.8.4)
  readonly MAX_ALLOWED_FILES = 3
  isEmbedded: boolean
  public isStandalone = false
  isInApp = false
  appVersion: MobileAppVersion
  isInIframe: boolean // used to determine whether to show the legal and language button in mat-card-actions
  tabTitles: Record<BookingStep, string>
  public isLoading = new Subject<boolean>()
  public currentlyLoading = false
  public isBookingInitialized$ = new BehaviorSubject<boolean>(false) // called after init() finished
  public magicFill$ = new BehaviorSubject<BookingStep>(null)
  public stepper: MatStepper
  public chainLength = 4
  public seriesMode$ = new BehaviorSubject<boolean>(false)
  public paymentMode$ = new BehaviorSubject<boolean>(false)
  public automaticSeriesSelection$ = new BehaviorSubject<boolean>(false)
  public multiStepSelected$ = new BehaviorSubject<boolean>(false)
  public isStandaloneComplete$ = new BehaviorSubject<boolean>(false)
  public bsDisplayed$ = new BehaviorSubject<Betriebsstaette>(null)
  autoForward: Record<BookingStep, boolean> // determines whether autoforwarding is active for the different steps
  // scrollableNativeElement holds the nativeElement of each step which should be scrolled up once that step is reached for the first time
  //  it is undefined for info and it-and-ik as these are not scrollable
  //  it is undefined for booking_date_multi as that is not yet implemented
  scrollableNativeElement: Record<BookingStep, any> = {
    BOOKING_INFO: undefined, // remains undefined throughout
    BOOKING_PRELIMINARY: undefined, // remains undefined throughout
    BOOKING_BS: undefined,
    BOOKING_TYPE: undefined,
    BOOKING_DATE: undefined,
    BOOKING_DATE_MULTI: undefined, // todo
    BOOKING_ANAMNESE: undefined,
    BOOKING_PERSONAL: undefined,
    BOOKING_SUMMARY: undefined,
    BOOKING_PAYMENT: undefined
  }

  public showBackwardButton$ = new BehaviorSubject<boolean>(false)
  public showForwardButton$ = new BehaviorSubject<boolean>(true)
  public isNextButtonDisabled$ = new BehaviorSubject<boolean>(true)
  public showBookButton$ = new BehaviorSubject<boolean>(false)
  public showExportButton$ = new BehaviorSubject<boolean>(false)
  public appointmentSeriesChain: AppointmentSeriesTypeItem[] = []
  public isBookingEdit = false
  public seriesReservations: OtkReservationL[][]

  stepSubscription: Subscription
  otkUser: OTKUser // the otk instance settings
  stepperStepIndex: number
  updateAppSubscription: Subscription
  I18NString_MISSING_VALUE: string = I18NString_MISSING_VALUE // string stored for instance in I18NString.en in case it was not translated yet
  languageChangeSubscription: Subscription
  subscriptionForceRefreshOnNav: Subscription
  public certBase64: string
  public recalcHeaderHeight$ = new Subject<boolean>()


  constructor(
    private adLoggerService: AdLoggerService,
    private anamneseFormsService: AnamneseFormsService,
    private httpClient: HttpClient,
    private instanceFormService: InstanceFormService,
    private instanceService: InstanceService,
    private notificationService: NotificationService,
    private router: Router,
    private translateService: TranslateService,
    private matSnackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private bookingHelpersService: BookingHelpersService,
    private browserSupportService: BrowserSupportService,
    private openingService: OpeningService,
    private checkoutService: AdPayCheckoutService
  ) {
    this.setIsEmbedded()
    this.isLoading.pipe(untilDestroyed(this)).subscribe(x => { this.currentlyLoading = x })
  }

  // embedded is true if in iframe or if set by get parameters (by the app) because I couldn't
  // get webview detection to work
  setIsEmbedded() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.isInIframe = window.location != window.parent.location
      const isEmbeddedFromGetParameter: boolean = params?.isEmbedded === 'true' // used by the app, which embeds using webview, because I couldn't figure out how to automatically detect webview
      this.isEmbedded = isEmbeddedFromGetParameter || this.isInIframe
      this.isInApp = isEmbeddedFromGetParameter
      if (params.appversion) {
        const [major, minor, patch] = params.appversion.split('-').map((x: string) => parseInt(x))
        if (major && minor) this.appVersion = { major, minor, patch: patch || 0 }
      }
    })
  }

  isMobile() {
    const isMobile: boolean = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i
      .test(navigator.userAgent))
    return isMobile
  }

  init(): Observable<any> {
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          this.isLoading.next(true)
          this.isBookingEdit = false
          return of(null)
        }),
        mergeMap(() => this.getLangParam()),
        mergeMap(() => this.checkZuweiserMode()),
        mergeMap(() => this.baseLanguageChangeSubscribe()),
        mergeMap(() => this.setTabTitles()),
        mergeMap(() => this.getBetriebsstaetten()),
        mergeMap(() => this.getOTKSettings()),
        mergeMap(() => this.setIsActive()),
        mergeMap(() => this.subscribeForceRefreshOnNav()),
        mergeMap(() => {
          //this.initBookingDateMultiForm()
          this.setRedirectionUrl()
          this.initializeAutoForward()
          this.setStepSubscription()
          return of(null)
        }),
        mergeMap(() => {
          this.isBookingInitialized$.next(true)
          this.isLoading.next(false)
          return of(null)
        }),
        // mergeMap(() => this.debugger())
      )
  }

  // (disabled) [ADI-2303]
  // the timeout is for the stepper to update (otherwise bookingStep is the previous step) and for the scrollable content to render
  /* scrollToView() {
    setTimeout(() => {
      if (this.isStandalone) { return; }
      const bookingStep: BookingStep = this.getCurrentBookingStep()
      if (bookingStep === BookingStep.bookingInfo || bookingStep === BookingStep.bookingPreliminary)
        return
      const nativeElementScrollable: any = this.scrollableNativeElement[bookingStep]
      const isSafari: boolean = this.browserSupportService.isSafari
      if (isSafari) { return }
      nativeElementScrollable?.scrollIntoView({ behavior: 'smooth' })
    }, 1000)
  } */

  // Usage of booking-date-component in dialog for booking edits by patients need
  // a slightly different initialization because there is no stepper and startcompleted does not get called
  initForBookingEdit(bsid: string = null): Observable<any> {
    this.arePrestepsCompleted$.next(true)
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          this.isLoading.next(true)
          this.isBookingEdit = true
          return of(null)
        }),
        mergeMap(() => this.getLangParam()),
        mergeMap(() => this.getBetriebsstaetten()),
        mergeMap(() => bsid ? this.setBetriebsstaette(bsid) : of(null)),
        mergeMap(() => this.getOTKSettings()),
        mergeMap(() => this.setIsActive()),
        mergeMap(() => this.setTabTitles()),
        mergeMap(() => {
          this.initializeAutoForward()
          this.isBookingInitialized$.next(true)
          this.isLoading.next(false)
          return this.initBookingType()
        })
      )
  }

  getLangParam(): Observable<any> {
    return this.activatedRoute.queryParams
      .pipe(untilDestroyed(this),
        mergeMap((params: Params) => {
          const langParam = params?.lang
          const activeLanguages = this.instanceService.activeInstance
            .settings.general.internationalization.baseLanguagesActive
          if (activeLanguages.includes(langParam))
            this.languageService.changeBaseLanguage(langParam)
          return of(null)
        })
      )
  }

  baseLanguageChangeSubscribe(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      if (this.languageChangeSubscription)
        this.languageChangeSubscription.unsubscribe()
      this.languageChangeSubscription = this.languageService.baseLanguageChangeSubject$
        .pipe(untilDestroyed(this))
        .subscribe()
      subscriber.next()
      subscriber.complete()
    })
  }

  getOTKSettings(): Observable<any> {
    const instanceId =
      this.instanceService.activeInstanceIsAdmin
        ? this.instanceService.customInstance._id
        : this.instanceService.activeInstance._id;
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          const httpParams: HttpParams = new HttpParams().set('instance', instanceId)
          return this.httpClient.get<OTKUser>(`${environment.otkUrl}/api/otkuser`, { params: httpParams })
        }),
        catchError((error) => this.setError(error)),
        first(),
        mergeMap((response: any) => {
          if (!response?.user)
            return this.setError(new Error('getOTKSettings'))
          this.otkUser = response?.user
          if (!this.otkUser?.globalSettings?.reservationTimeDefault)
            return this.setError(new Error('getOTKSettings'))
          this.checkoutService.updateActiveStore(StoreTypes.global, this.otkUser.globalSettings.storeId)
          this.globalReservationDurationS = this.otkUser.globalSettings.reservationTimeDefault * 60
          this.certBase64 = this.otkUser.security?.tomedoCertificate ?? ""
          return of(null)
        })
      )
  }

  setIsActive(): Observable<any> {
    this.isBsActive = (!!this.otkUser.betriebsstaettenFilter) && this.betriebsstaetten.length !== 0
    if (this.isBsActive) {
      this.bs.setValidators(Validators.required)
      this.bs.updateValueAndValidity() // otherwise valid even if null
    }
    this.isIkActive = !!this.otkUser.knownPatientFilter
    if (this.isIkActive) {
      this.isKnown.setValidators(Validators.required)
      this.isKnown.updateValueAndValidity() // otherwise valid even if null
    }
    this.isItActive = !!this.otkUser.insuranceFilterActive
    if (this.isItActive) {
      this.insuranceType.setValidators(Validators.required)
      this.insuranceType.updateValueAndValidity() // otherwise valid even if null
    }
    this.isBirthdateActive = !!this.otkUser?.globalSettings?.ageRestriction
    if (this.isBirthdateActive) {
      this.birthDate.setValidators(Validators.required)
      this.birthDate.updateValueAndValidity()
    }
    const infoString = this.languageService.i18NStringToString(this.otkUser?.globalInformation)
    if (!infoString || infoString === I18NString_MISSING_VALUE) {
      this.hasGlobalInformation = false;
    } else {
      this.hasGlobalInformation = true;
    }
    return of(null)
  }

  // refreshes when
  //  1. the back or forward browser buttons are clicked
  //  2. navigating to /booking from the instance main page
  //    (relevant when booking again after going back to the instance home page in booking/results)
  // Otherwise there are errors because the state isn't well defined (variables are not set because ngOnInit is not called and so on)
  subscribeForceRefreshOnNav(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      if (this.subscriptionForceRefreshOnNav)
        this.subscriptionForceRefreshOnNav.unsubscribe()
      this.subscriptionForceRefreshOnNav =
        this.router.events
          .pipe(
            filter(event => event instanceof NavigationEnd)
          ).subscribe((event: NavigationEnd) => {
            if ((event as unknown as NavigationStart).navigationTrigger === 'popstate' //back/forward buttons
              || (event.url.includes('booking') && !event.url.includes('result')) // navigating homepage --> booking
            )
              window.location.reload()
          })
      subscriber.next()
      subscriber.complete()
    })
  }

  // not in use, delete?
  messageHeightToIframeHost() {
    setTimeout(() => {
      const windowHeight: number = +document.documentElement.style.getPropertyValue('--windowHeight').replace('px', '')
      const headerHeight: number = +document.documentElement.style.getPropertyValue('--headerHeight').replace('px', '')
      const matCardActionsHeight: number = +document.documentElement.style.getPropertyValue('--matCardActionsHeight').replace('px', '')
      const height: number = windowHeight - headerHeight - matCardActionsHeight
      window.parent.postMessage(height, '*')
    }, 550)
  }

  setTabTitles(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      this.tabTitles = {
        BOOKING_INFO: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-INFO'),
        BOOKING_PRELIMINARY: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-PRELIMINARY'),
        BOOKING_BS: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-BS'),
        BOOKING_TYPE: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-TYPE'),
        BOOKING_DATE: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-DATE'),
        BOOKING_DATE_MULTI: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-DATE-MULTI'),
        BOOKING_ANAMNESE: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-ANAMNESE'),
        BOOKING_PERSONAL: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-PERSONAL'),
        BOOKING_SUMMARY: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-SUMMARY'),
        BOOKING_PAYMENT: this.translateService.instant('OTK.STEPPER.TITLES.APPOINTMENT-PAYMENT')
      }
      subscriber.next()
      subscriber.complete()
    })
  }

  /**
   * upon completion of the step for the first time, if autoForward
   * is set to true, forward the user to the next step.
  */
  initializeAutoForward() {
    // auto-forwarding from the mentioned step to the one in the line below it
    // (info, preliminary, bs and anamnese are optional steps)
    this.autoForward = {
      BOOKING_INFO: false,
      BOOKING_PRELIMINARY: true,
      BOOKING_BS: true,
      BOOKING_TYPE: true,
      BOOKING_DATE: true,
      BOOKING_DATE_MULTI: true,
      BOOKING_ANAMNESE: false,
      BOOKING_PERSONAL: false,
      BOOKING_SUMMARY: false, // doesn't really do anything
      BOOKING_PAYMENT: false
    }
  }

  /**
   * whenever any field in any step of the form is changed:
   * - update isBookingValid
   * - if the form in the last step the patient got to is now valid:
   *  - set it to completed, thus enabling navigation to the next step
   *  - autoforward to the next step if needed
   * note: both "this.stepper.selectionChange" and "delay(100)" are needed in order to make the "next" button
   *  work onLoad in booking-anamnese (and -personal?) in case they are initially valid due to caching
  */
  private setStepSubscription() {
    if (this.stepSubscription) {
      return
    }
    merge(this.bookingValueChanges$, this.stepper.selectionChange)
      .pipe(untilDestroyed(this),
        delay(100))
      .subscribe(() => {
        this.messageHeightToIframeHost()
        const anamneseExists = this.doesAnamneseFormExist()
        // bookingPreliminaryFormGroup is not taken into account
        //  because it is anyways impossible to reach booking-summary
        //  without filling it if it is enabled, and because it is
        //  not always enabled
        this.isBookingValid =
          this.bookingPreliminaryFormGroup.valid &&
          this.bookingBsFormGroup.valid
          && this.bookingTypeFormGroup.valid
          && this.bookingDateFormGroup.valid
          && (anamneseExists ? this.isAnamneseFormValid : true)
          && this.personalFormComponent?.formGroup.valid
        // if the current booking step was just completed, advance to the next one
        // and perform additional actions if needed
        let isValid: boolean
        if (!this.stepper?.selected) { return; } // prevent error on browser back button
        switch (this.stepper.selected.label) {
          case this.tabTitles.BOOKING_INFO:
            isValid = true;
            break;
          case this.tabTitles.BOOKING_PRELIMINARY:
            isValid = this.bookingPreliminaryFormGroup.valid;
            break;
          case this.tabTitles.BOOKING_BS:
            isValid = this.bookingBsFormGroup.valid;
            break;
          case this.tabTitles.BOOKING_TYPE:
            isValid = this.bookingTypeFormGroup.valid;
            break;
          case this.tabTitles.BOOKING_DATE:
            isValid = this.bookingDateFormGroup.valid;
            break;
          case this.tabTitles.BOOKING_ANAMNESE:
            isValid = this.isAnamneseFormValid;
            break;
          case this.tabTitles.BOOKING_PERSONAL:
            isValid = this.personalFormComponent.formGroup.valid
            break;
        }
        if (isValid) {
          this.stepper.selected.completed = true // enable navigation to the next step in the scroller
          // if this step is set to autoforwarding, forward the user to the next step.
          // also sets autoforwarding for this step to false so that this will only happen once
          // for each step
          const bookingStep: BookingStep = this.getCurrentBookingStep()
          if (this.autoForward[bookingStep] === true) {
            this.autoForward[bookingStep] = false
            // if this step is preliminary, the last prestep, don't next() but instead hide the presteps, show the steps and reset
            // the stepper to the first step
            if (bookingStep === BookingStep.bookingPreliminary) {
              this.arePrestepsCompleted$.next(true)
              this.stepper.selectedIndex = 0
            }

            // note:
            // although this.autoForward[BookingStep.bookingBs] is initially set to true, actually it is set to false in booking-bs just prior to
            //  triggering this function in the cases where the bs was not manually selected by the user, which are the cases where the filter isn't active
            //  or where the filter is active and a single bs was given as a parameter.
            //  the reason is that, for these 2 cases, we don't only complete bs but also hide it, which already "next"s the stepper to booking-type in a way.
            //  if we were to also next in addition, we would skip booking-type and go to booking-date.
            // so even though below it looks like this.stepper.next() happens for every step other than bookingItAndIt, actually it also does not always
            //  happen for bs.
            else
              this.stepper.next()
          }
          // Unfortunately this causes several unwanted behaviours, disable
          // for now [ADI-2303]
          //this.scrollToView()
        } else
          this.stepper.selected.completed = false
      })
  }

  getCurrentBookingStep(): BookingStep {
    const bookingStep: BookingStep = (Object.keys(this.tabTitles) as BookingStep[])
      .find((step: BookingStep) =>
        this.tabTitles[step] === this.stepper.selected.label)
    return bookingStep
  }

  /**
   * open a snackbar at the top. Used for displaying error messages when
   * backend routes fail
   * // TODO: remove demo mode guard
  */
  showServerErrorMessage() {
    if (!environment.demoMode)
      return
    let message: string
    const bookingStep: BookingStep = (Object.keys(this.tabTitles) as BookingStep[])
      .find((bookingStep: BookingStep) =>
        this.tabTitles[bookingStep] === this.stepper?.selected.label) // "stepper?" because throws an error when used from booking-edit because the stepper does not exist there
    if (this.stepper)
      switch (bookingStep) {
        case BookingStep.bookingDate:
          message = this.translateService.instant('OTK.BOOKING-DATE.SERVER-ERROR-MESSAGE')
          break;
        case BookingStep.bookingSummary:
          message = this.translateService.instant('OTK.BOOKING-SUMMARY.SERVER-ERROR-MESSAGE')
          break;
        default: //case this.tabTitles.BOOKING_TYPE:
          message = this.translateService.instant('OTK.BOOKING-TYPE.SERVER-ERROR-MESSAGE')
          break;
      }
    else
      message = this.translateService.instant('OTK.BOOKING-TYPE.SERVER-ERROR-MESSAGE') // for booking-start errors
    let matSnackBarconfig: MatSnackBarConfig = {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: message,
      panelClass: ['otk']
    }
    this.matSnackBar.openFromComponent(BookingSnackbarComponent, matSnackBarconfig)
  }

  /** Asset form group */
  public assetFormGroup = new UntypedFormGroup({
    assets: new UntypedFormArray([], []),
    newAsset: new UntypedFormControl(null, []),
  })
  get assets(): AbstractControl { return this.assetFormGroup.get('assets') }

  /** booking-info */
  hasGlobalInformation: boolean = false

  /** booking-preliminary */
  isIkActive: boolean = false
  isItActive: boolean = false
  isBirthdateActive: boolean = false

  public bookingPreliminaryFormGroup: FormGroup = new FormGroup({
    isKnown: new FormControl<boolean>(null), // Validators.required is added dynamically if isIkActive
    insuranceType: new FormControl<PatientInsuranceType>(null), // Validators.required is added dynamically if isItActive
    birthDate: new FormControl<Date>(null)
  })
  get isKnown(): AbstractControl<boolean> { return this.bookingPreliminaryFormGroup.get('isKnown') }
  get insuranceType(): AbstractControl<PatientInsuranceType> { return this.bookingPreliminaryFormGroup.get('insuranceType') }
  get birthDate(): AbstractControl<Date> { return this.bookingPreliminaryFormGroup.get('birthDate') }

  /** booking-bs */
  allBetriebsstaetten: Betriebsstaette[]
  betriebsstaetten: Betriebsstaette[] // allBetriebstaetten filtered with zero Localities and not active
  localitiesAll: string[]
  isBsActive: boolean = false
  showBs$: BehaviorSubject<boolean> = new BehaviorSubject(true)
  bsChangesSubscription: Subscription
  // bsDisplayed: the bs displayed in summary and sent with the appointment object.
  // It is not automatically the bs selected by the user (as one would expect)
  // see setDisplayedBs
  bsDisplayed: Betriebsstaette
  // a dummy bs which is picked when "Schnellstmöglicher Termin" is selected
  bsAll: Betriebsstaette = {
    _id: null,
    instance: null,
    betriebsstaetteIdent: null,
    tomBetriebsstaette: null,
    contact: null,
    name: 'all'
  }

  public bookingBsFormGroup: FormGroup = new FormGroup({
    bs: new FormControl<Betriebsstaette>(null)
  })
  get bs(): AbstractControl<Betriebsstaette> { return this.bookingBsFormGroup.get('bs') }
  public arePrestepsCompleted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  private getBetriebsstaetten(): Observable<boolean> {
    const httpParams: HttpParams = new HttpParams().set('instance', this.instanceService.activeInstance._id);
    return this.httpClient.get(`${environment.otkUrl}/api/betriebsstaetten`, { params: httpParams }).pipe(
      tap((response: any) => {
        this.allBetriebsstaetten = response.betriebsstaetten
        this.betriebsstaetten = response.betriebsstaetten.filter((x: Betriebsstaette) => x?.localityIdents?.length > 0 && x.active && (x.visible ?? true)) // filter Betriebstaetten with zero Localities and not active
        this.localitiesAll = this.betriebsstaetten.reduce((prev, current) => prev.concat(current?.localityIdents ?? []), [])
      })
    )
  }

  private setBetriebsstaette(bsid: string): Observable<boolean> {
    if (!this.allBetriebsstaetten) throw new Error("No betriebsstaette found")
    const bsToSet = this.allBetriebsstaetten.find(x => x._id === bsid)
    if (bsToSet) this.bs.setValue(bsToSet, { emitEvent: false })
    else throw new Error("No betriebsstaette found")
    return of(null)
  }

  /* booking-type */
  private _appointmentCategoryGeneric$ = new BehaviorSubject<AppointmentCategory>(null);
  private _appointmentCategoriesNonGeneric$ = new BehaviorSubject<AppointmentCategory[]>([]);
  isContactFormSelected$ = new BehaviorSubject<boolean>(false)
  category: string = "" // For category directLink
  public isBookingTypeInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  // Generic AppointmentCategory
  public get appointmentCategoryGeneric$(): Observable<AppointmentCategory> {
    return this._appointmentCategoryGeneric$.asObservable();
  }
  public get appointmentCategoryGeneric() {
    return this._appointmentCategoryGeneric$.value;
  }
  public set appointmentCategoryGeneric(value: AppointmentCategory) {
    this._appointmentCategoryGeneric$.next(value);
  }

  // Non-generic AppointmentCategory s
  public get appointmentCategoriesNonGeneric$(): Observable<AppointmentCategory[]> {
    return this._appointmentCategoriesNonGeneric$.asObservable();
  }
  public get appointmentCategoriesNonGeneric() {
    return this._appointmentCategoriesNonGeneric$.value;
  }
  public set appointmentCategoriesNonGeneric(value: AppointmentCategory[]) {
    this._appointmentCategoriesNonGeneric$.next(value);
  }

  public bookingTypeFormGroup = new FormGroup<{
    appointmentCategoryNonGeneric: FormControl<AppointmentCategory>
    appointmentType: FormControl<AppointmentType>
  }>({
    appointmentCategoryNonGeneric: new FormControl<AppointmentCategory>(null),
    appointmentType: new FormControl<AppointmentType>(null, Validators.required),
  })
  get appointmentCategoryNonGeneric(): FormControl<AppointmentCategory> {
    return this.bookingTypeFormGroup.controls.appointmentCategoryNonGeneric;
  }
  get appointmentType(): FormControl<AppointmentType> {
    return this.bookingTypeFormGroup.controls.appointmentType;
  }
  // reservation timer variables
  reservationInterval: Observable<number> // observer sending the amount of seconds left every second
  reservationIntervalDate: dayjs.Dayjs // date when the reservation was made
  globalReservationDurationS: number
  ALERT_THRESHOLD = 2 * 60 // show alert if reservation expires within 2 minutes
  showType$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true) // is appointment type given as a get parameter?
  public zuweiserCode: string
  public isZwCodeCorrect: boolean

  public initBookingType(): Observable<boolean> {
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          this.isLoading.next(true)
          return of(null)
        }),
        mergeMap(() => this.getCategories()),
        tap((value) => { console.debug('TAP', value) }),  // XXX
        mergeMap((bookingCategories: AppointmentCategory[]) => {
          if (!bookingCategories || bookingCategories?.length === 0) {
            return of(null) //this.setError(new Error('initBookingType: no bookingCategories'))
          }
          this.appointmentCategoriesNonGeneric = bookingCategories
            .filter((appointmentCategory) => !appointmentCategory.isGeneric)
          this.appointmentCategoryGeneric = bookingCategories
            .find((appointmentCategory) => appointmentCategory.isGeneric)
          return of(null)
        }),
        mergeMap(() => of(this.setAppointmentTypeChangeSubscription())),
        mergeMap(() => {
          this.isLoading.next(false)
          this.isBookingTypeInitialized$.next(true)
          return of(null)
        })
      )
  }

  /**
   * called after selecting an insurance type. Filters out all appointment types for which insurance
   * is not empty && insurance does not contain the selected insurance type.
   * also filters empty categories.
   */
  public filterCategoriesByInsurance(): Observable<any> {
    return new Observable((observer) => {
      if (this.appointmentCategoryGeneric?.appointmentTypes
        && Object.keys(this.appointmentCategoryGeneric.appointmentTypes).length !== 0)
        this.appointmentCategoryGeneric.appointmentTypes = this.appointmentCategoryGeneric.appointmentTypes
          .filter((appointmentType: AppointmentType) =>
            !appointmentType.insurance || Object.keys(appointmentType.insurance).length === 0
            || appointmentType.insurance.includes(this.insuranceType.value)
          )
      for (let i: number = Object.keys(this.appointmentCategoriesNonGeneric).length - 1; i >= 0; i--) {
        this.appointmentCategoriesNonGeneric[i].appointmentTypes = this.appointmentCategoriesNonGeneric[i].appointmentTypes
          .filter((appointmentType: AppointmentType) =>
            !appointmentType.insurance || Object.keys(appointmentType.insurance).length === 0
            || appointmentType.insurance.includes(this.insuranceType.value)
          )
        if (Object.keys(this.appointmentCategoriesNonGeneric[i].appointmentTypes).length === 0)
          this.appointmentCategoriesNonGeneric.splice(i, 1)
      }
      observer.next(true)
      observer.complete()
    })
  }


  checkZuweiserMode(): Observable<boolean> {
    return of(null).pipe(
      mergeMap(() =>
        this.activatedRoute.queryParams
      ),
      mergeMap((params: Params) => {
        if (this.isStandalone) { return of(null); }
        this.zuweiserCode = params?.zuweiserCode
        return of(null)
      }),
      // in case still using the old format (/booking/zuweiser/code instead of /booking?zuweiserCode=code),
      // the mergeMap above will find nothing, so we use the one below.
      // Todo sometime in the far future: Remove the mergeMap below? (And the legacy route)
      mergeMap(() => {
        this.zuweiserCode = this.zuweiserCode
          ? this.zuweiserCode
          : this.router.url.includes('zuweiser/')
            ? this.router.url.split('zuweiser/')[1]
            : null
        return of(null)
      }),
      mergeMap(() => {
        if (!this.zuweiserCode)
          return of(null)
        else
          return of(null).pipe(
            mergeMap(() =>
              this.httpClient.post<{ status: number, user: OTKUser }>(`${environment.otkUrl}/api/otkuser/zw`,
                { instanceId: this.instanceService.activeInstance._id, accessCode: this.zuweiserCode }).pipe(untilDestroyed(this))
            ),
            mergeMap(({ status, user }) => {
              this.isZwCodeCorrect = !!user
              return of(null)
            })
          )
      })
    )
  }

  /**
   * server_booking/src/routes/category.route.ts loadCategories
   * loads all booking categories belonging to the instance
   */
  public getCategories(): Observable<AppointmentCategory[]> {
    return of(null).pipe(untilDestroyed(this),
      mergeMap(() => {
        this.isLoading.next(true)
        return of(null)
      }),
      mergeMap(() => {
        let localityIds: string[] = this.isBsActive
          ? (this.bs.value === this.bsAll
            ? this.localitiesAll
            : this.bs.value.localityIdents)
          : []
        let catId = this.category // is empty string if not set by parameter
        const params = {
          birthDate: this.birthDate.value,
          localityIds,
          instance: this.instanceService.activeInstance._id,
          catId: catId,
          zwMode: this.isZwCodeCorrect,
          ...(this.insuranceType.value ? { insuranceType: this.insuranceType.value } : {}),
          ...(this.isZwCodeCorrect ? { zwCode: this.zuweiserCode } : {})
        }
        console.debug('PARAMS', params) // XXX
        return this.httpClient.post(`${environment.otkUrl}/api/appointment-category`, params)
      }),
      mergeMap((categoryResponse: any) => {
        this.isLoading.next(false)
        console.debug('rep CATEGORIES', categoryResponse) // XXX
        return !!categoryResponse?.categories
          ? this.filterBookingCategories(categoryResponse.categories as AppointmentCategory[])
          : this.setError(new Error('getBookingCategories: no categories'))
      }),
      catchError((error) => this.setError(error)),
    )
  }

  filterBookingCategories(categories: AppointmentCategory[]): Observable<AppointmentCategory[]> {
    console.debug('IN filterBookingCategories()', categories) // XXX

    return new Observable((subscriber) => {
      console.debug('IN Observable I'); // XXX

      if (!categories || categories.length === 0) {
        subscriber.next(categories)
        subscriber.complete()
        return
      }
      type Predicate = (type: AppointmentType) => boolean
      const excludedAppointmentTypeIds = (this.bs.value?.excludedAppointmentTypeIds ?? [])
      const predicateExcludedTypeIds: Predicate = (this.isBsActive && this.bs.value !== this.bsAll && excludedAppointmentTypeIds.length !== 0)
        ? ((type: AppointmentType) => !excludedAppointmentTypeIds.includes(type._id))
        : (() => true)
      const isKnownPatientP: Predicate = (type: AppointmentType) => {
        const result = this.isKnown.value
          ? type.patientTarget !== PatientTargetType.New
          : type.patientTarget !== PatientTargetType.Known
        console.debug('knownPatient', result)  // XXX
        return result;
      };
      const predicateIsKnownPatient: Predicate = (type) => {
        const result = this.otkUser?.knownPatientFilter
          ? isKnownPatientP(type)
          : true;
        console.debug('knownPatient (predicate)', result)  // XXX
        return result;
      };
      const isAcceptedInsuranceP: Predicate = (type: AppointmentType) => {
        const result = Object.keys(type.insurance).length === 0
          || type.insurance.includes(this.insuranceType.value);
        console.debug('insurance', result)  // XXX
        return result;
      };
      const predicateInsuranceType: Predicate = (type) => {
        const result = this.otkUser?.insuranceFilterActive
          ? isAcceptedInsuranceP(type)
          : true;
        console.debug('insurance (predicate)', result)  // XXX
        return result;
      };
      const predicateIsVisible: Predicate = (type: AppointmentType) => {
        const result = type.hasOpenings || type.showIfNoOpenings;
        console.debug('isVisible (predicate)', result)  // XXX
        return result;
      };
      const predicateNoAppointmentSeries: Predicate = (type: AppointmentType) => {
        const result = (this.isInApp && !terminKettenAppTest(this.appVersion))
          ? !type.appointmentSeriesType
          : true
        console.debug('appointmentSeries (predicate)', result)  // XXX
        return result;
      };
      const predicates: Predicate[] = [
        predicateExcludedTypeIds,
        predicateIsKnownPatient,
        predicateInsuranceType,
        predicateIsVisible,
        predicateNoAppointmentSeries
      ]

      let categoriesFiltered: AppointmentCategory[] = []

      for (let i = 0; i < categories.length; i++) {
        console.debug(`IN Observable II (loop) i=${i}`); // XXX
        const initialAppointmentTypes = categories[i].appointmentTypes;
        let filteredAppointmentTypes: AppointmentType[] = [];

        initialAppointmentTypes.forEach((appType) => {
          console.debug('forEach, appType', appType) // XXX
          const keep = predicates.every(p => p(appType))
          console.debug(`keep = ${keep}`)  // XXX
          if (keep) {
            filteredAppointmentTypes.push(appType);
          }
        })

        if (filteredAppointmentTypes.length > 0) {
          const currentCat: AppointmentCategory = {
            ...categories[i],
            appointmentTypes: filteredAppointmentTypes
          }
          categoriesFiltered.push(currentCat)
        }

        /* const typesFiltered: AppointmentType[] = categories[i].appointmentTypes
          .filter((type: AppointmentType) =>
            predicates.every((predicate: Predicate) => {
              console.debug('AppType: ', type) // XXX
              const keep = predicate(type)
              console.debug('keep?', keep);
              return keep
            }
            )) */

        /* if (typesFiltered?.length !== 0) {
          let categoryFiltered: AppointmentCategory = {
            ...categories[i],
            appointmentTypes: typesFiltered
          }
          categoriesFiltered.push(categoryFiltered)
        } */
        /* if (i === (categories.length - 1)) {
          subscriber.next(categoriesFiltered)
          subscriber.complete()
        } */
      }

      subscriber.next(categoriesFiltered);
      subscriber.complete();
    })
  }

  /**
   * called by booking-type if receiving a valid appointment type id as a paramter.
   * selects the appropriate appointment type and hides the booking-type step.
   */
  setAppTypeParam(appointmentType: AppointmentType) {
    this.autoForward[BookingStep.bookingType] = false
    this.appointmentType.setValue(appointmentType)
    this.showType$.next(false)
  }

  public initContactFormMode() {
    this.isContactFormSelected$.next(true)
    this.appointmentType.setValue(null, { emitEvent: false })
    this.stepper.selected.completed = true
    this.stepper.next()
  }

  /* booking date */

  bookingDateFormGroup = new UntypedFormGroup({
    opening: new UntypedFormControl('', Validators.required),
    doctor: new UntypedFormControl('', []), // only used as a filter. The doctor (actually doctors) used are in opening.
    day: new UntypedFormControl('',
      [Validators.required,
      FormValidators.dateValidator,
      FormValidators.pastDayValidator(),
      FormValidators.getDateMoreThanGivenDaysInTheFutureValidator(this.MAXIMAL_ALLOWED_BOOKING_IN_ADVANCE_DAYS)
      ]),
  })
  get day(): AbstractControl { return this.bookingDateFormGroup.get("day") }
  get doctor(): AbstractControl { return this.bookingDateFormGroup.get("doctor") }
  get opening(): AbstractControl { return this.bookingDateFormGroup.get("opening") }


  /* booking date Multi*/

  bookingDateMultiFormGroup = new UntypedFormGroup({
    openings: new UntypedFormArray([], Validators.required),
    doctors: new UntypedFormArray([], []), // only used as a filter. The doctor (actually doctors) used are in opening.
    days: new UntypedFormArray([], [Validators.required])
  })
  get daysMulti(): AbstractControl { return this.bookingDateMultiFormGroup.get("days") }
  get doctorsMulti(): AbstractControl { return this.bookingDateMultiFormGroup.get("doctor") }
  get openingsMultiControl(): AbstractControl { return this.bookingDateMultiFormGroup.get("openings") }


  //fills formarrays depening on appointmentseriestype
  initBookingDateMultiForm() {
    (this.bookingDateMultiFormGroup.get('openings') as UntypedFormArray).clear();
    (this.bookingDateMultiFormGroup.get('days') as UntypedFormArray).clear();
    (this.bookingDateMultiFormGroup.get('doctors') as UntypedFormArray).clear();
    for (let i = 0; i < this.chainLength; i++) {
      (this.bookingDateMultiFormGroup.get('openings') as UntypedFormArray).push(new UntypedFormControl(null));
      (this.bookingDateMultiFormGroup.get('doctors') as UntypedFormArray).push(new UntypedFormControl(null));
      (this.bookingDateMultiFormGroup.get('days') as UntypedFormArray).push(new UntypedFormControl(null, [FormValidators.dateValidator,
      FormValidators.pastDayValidator(),
      FormValidators.getDateMoreThanGivenDaysInTheFutureValidator(this.MAXIMAL_ALLOWED_BOOKING_IN_ADVANCE_DAYS)]));
    }
  }



  // doctorsVisible && doctorsInvisible:
  // doctors without a Nutzer get filtered out in backend.
  // the doctors received in frontend are divided into doctorsVisible and doctorsInvisible.
  // doctorsVisible are doctors with titel, vorname or nachname.
  // these doctors should be displayed in the doctors dropdown menu (if allowDoctorChoice),
  // be shown in the opening strings (if showDoctors) and have their openings displayed to the user.
  // doctorsInvisible are doctors with titel===vorname===nachname===''.
  // these doctors should not be displayed in the doctors dropdown menu or in the opening strings,
  // (because there is nothing to display) but their openings should still be displayed to the user.
  doctorsVisible: OtkDoctor[]
  doctorsInvisible: OtkDoctor[]
  openings: BookingOpening[]


  multiSlots = new MultiSlots(this.adLoggerService)


  //send resettrigger
  reset$: Subject<any> = new Subject<any>()

  initBookingDate$: Subject<any> = new Subject<any>()
  initBookingDateMulti$: Subject<any> = new Subject<any>()
  appointmentTypeChangeSubscription: Subscription
  reservation: OtkReservation
  isOpeningConnectedToMultipleBs: boolean
  isOpeningNotConnectedToAnyBs: boolean
  openingDateString: string
  openingTimeString: string
  showDoctors: boolean
  dialectDefault: Dialect = Dialect.Behandler // default dialect to be used in booking-date and booking-summary for the doctor/practitioner/...
  reservationSubscription: Subscription
  openingChangesSubscription: Subscription

  // determines which bs will be displayed in the summary and sent with the appointment in the following way:
  // all bs sharing at least one locality with the chosen opening are found.
  // if theres more than one:
  //  flag it so we can display a warning in the summary.
  //  use the first one.
  // if there is one:
  //  if a bs was manually selected by the user and its the same as the one found, use it
  //  if no bs was manually selected by the user, use the one found
  // if there is none:
  //  use none :P
  setDisplayedBs() {
    if (!this.opening.value) // if reset return
      return
    if (!this.isBsActive)
      return
    this.isOpeningNotConnectedToAnyBs = false
    this.isOpeningConnectedToMultipleBs = false
    const openingLids: string[] = (this.opening.value as BookingOpening).kdSet
      .map((kd: TomKalenderDaten) => kd.lid)
    const bsSharingLidsWithOpening: Betriebsstaette[] = this.betriebsstaetten.filter((bs: Betriebsstaette) =>
      openingLids.some(lid => bs?.localityIdents.includes(lid))
    )
    if (bsSharingLidsWithOpening.length > 1) {
      this.isOpeningConnectedToMultipleBs = true
      this.bsDisplayed = bsSharingLidsWithOpening[0]
    }
    else if (bsSharingLidsWithOpening.length === 1) {
      if (this.bs.value !== this.bsAll)
        this.bsDisplayed = this.bs.value._id === bsSharingLidsWithOpening[0]._id ? this.bs.value : undefined
      else
        this.bsDisplayed = bsSharingLidsWithOpening[0]
    }
    else { // bsSharingLidsWithOpening.length === 0
      this.isOpeningNotConnectedToAnyBs = true
      this.bsDisplayed = null
    }
    // look for custom store Id for used bs
    if (this.bsDisplayed) {
      this.checkoutService.updateActiveStore(StoreTypes.betriebsstaette, this.bsDisplayed.storeId);
    }
  }

  openingChangesSubscribe(): Observable<boolean> {
    return new Observable((subscriber: Subscriber<boolean>) => {
      if (this.openingChangesSubscription)
        this.openingChangesSubscription.unsubscribe()
      this.openingChangesSubscription = this.opening.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.setDisplayedBs()
        })
      subscriber.next()
      subscriber.complete()
    })
  }

  updateMiniSummaryStrings() {
    const activeBaseLang: string = this.languageService.activeBaseLang
    this.openingDateString = dayjs(this.opening.value?.date).add(this.appointmentType.value?.forerunTime || 0, 'minute').locale(activeBaseLang).format('dddd DD.MM.YYYY')
    this.openingTimeString = dayjs(this.opening.value?.date).add(this.appointmentType.value?.forerunTime || 0, 'minute').locale(activeBaseLang).format('HH:mm')
  }

  setAppointmentTypeChangeSubscription() {
    if (this.appointmentTypeChangeSubscription)
      return
    this.appointmentTypeChangeSubscription = this.appointmentType.valueChanges
      .pipe(untilDestroyed(this),
        mergeMap((app) => {
          if (app?.adPayActive) {
            this.paymentMode$.next(app.adPayActive)
            this.checkoutService.amount = app.amount;
          }
          if (!this.appointmentType.valid) {
            this.seriesMode$.next(false)
            return of(null)
          }
          else if (app?.appointmentSeriesType) {
            this.chainLength = app.appointmentSeriesType.items.length
            this.seriesMode$.next(true)
            this.appointmentSeriesChain = app.appointmentSeriesType.items
            this.automaticSeriesSelection$.next(!!app.appointmentSeriesType.automaticSelection && this.appointmentSeriesChain.every(x => x.mhd !== 2))
            return this.initBookingMultiDate()
          } else {
            this.seriesMode$.next(false)
            return this.initBookingDate()
          }
        })
      ).subscribe()
  }

  initBookingDate(): Observable<any> {
    return of(null)
      .pipe(untilDestroyed(this),
        mergeMap(() => {
          this.bookingDateFormGroup.reset()
          return of(null)
        }),
        mergeMap(() => this.openingChangesSubscribe()),
        mergeMap(() => forkJoin([this.getDoctors(), this.getOpenings()])),
        tap(() => this.filterDoctors()),
        mergeMap(() => of(this.setReservationSubscription())),
        mergeMap(() => {
          this.showDoctors = (this.appointmentType.value as AppointmentType)?.showDoctors
          this.initBookingDate$.next(null)
          return of(null)
        }),
      )
  }



  //fills formarrays depening on appointmentseriestype
  initBookingMultiDate(newFormArray: boolean = true): Observable<any> {
    this.resetMultiDateFormGroup(newFormArray)
    return forkJoin([this.getDoctors(), this.getReservations(), this.getOpeningsMulti()]).pipe(
      untilDestroyed(this),
      mergeMap(([_, reservations, openings]) => {
        if (reservations) this.seriesReservations = reservations
        else this.seriesReservations = undefined
        if (!openings) throw new Error('getOpenings')
        if (openings?.length !== this.chainLength) throw new Error('getOpenings')
        this.multiSlots.init(this.appointmentType.value.appointmentSeriesType, openings, this.isBsActive && !this.bs.value?._id, this.betriebsstaetten, this.seriesReservations, this.appointmentType.value?.displayOptions?.ageAtAppointmentTime, this.birthDate.value)
        //doctorArray
        this.multiSlots.makeDoctorArray(this.doctorsVisible)
        //bsArray
        this.multiSlots.makeBsArray(this.betriebsstaetten)
        this.isLoading.next(false)
        this.initBookingDateMulti$.next(null)
        return of(null)
      }))
  }

  resetMultiDateFormGroup(newFormArray: boolean = true) {
    if (newFormArray) {
      (this.bookingDateMultiFormGroup.get('openings') as UntypedFormArray).clear({ emitEvent: false });
      (this.bookingDateMultiFormGroup.get('days') as UntypedFormArray).clear({ emitEvent: false });
      (this.bookingDateMultiFormGroup.get('doctors') as UntypedFormArray).clear({ emitEvent: false });
      for (let i = 0; i < this.chainLength; i++) {
        (this.bookingDateMultiFormGroup.get('doctors') as UntypedFormArray).push(new UntypedFormControl(null), { emitEvent: false });
        (this.bookingDateMultiFormGroup.get('openings') as UntypedFormArray).push(new UntypedFormControl(null), { emitEvent: false });
        (this.bookingDateMultiFormGroup.get('days') as UntypedFormArray).push(new UntypedFormControl(null), { emitEvent: false });
      }
    } else {
      for (let i = 0; i < this.chainLength; i++) {
        (this.bookingDateMultiFormGroup.get('doctors') as UntypedFormArray).at(i).setValue(null, { emitEvent: false });
        (this.bookingDateMultiFormGroup.get('openings') as UntypedFormArray).at(i).setValue(null, { emitEvent: false });
        (this.bookingDateMultiFormGroup.get('days') as UntypedFormArray).at(i).setValue(null, { emitEvent: false });
      }
    }
  }





  public filterDoctors() {
    console.time("filterd")
    if (this.seriesMode$.getValue()) return
    const uniqueKids = [...new Set(this.openings.map((x: BookingOpening) => x.kdSet.map(y => y.kid)).flat())]
    this.doctorsVisible = this.doctorsVisible.filter((doc: OtkDoctor) => doc.kids.some(x => uniqueKids.includes(x)))
    this.doctorsInvisible = this.doctorsInvisible.filter((doc: OtkDoctor) => doc.kids.some(x => uniqueKids.includes(x)))
    console.timeEnd("filterd")
  }

  /** retrieves a list of the relevant doctors for this booking type
 * server_booking/src/routes/doctors.route.ts getDoctors
 */
  public getDoctors(): Observable<any> {
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          this.isLoading.next(true)
          return of(null)
        }),
        mergeMap(() => {
          const onlineConsultation = !!this.bookingTypeFormGroup.get("appointmentType").value?.onlineConsultation
          const params = {
            instance: this.instanceService.activeInstance._id,
            terminSucheIdent: this.bookingTypeFormGroup.get("appointmentType").value.terminSucheIdent,
            localityIds: (this.isBsActive && !onlineConsultation)
              ? (this.bs.value?.localityIdents
                ? this.bs.value.localityIdents
                : this.localitiesAll)
              : []
          }
          return this.httpClient.post(environment.otkUrl + "/api/doctor/load", params)
        }),
        catchError((error) => this.setError(error)),
        mergeMap((response: any) => {
          if (!response?.doctors)
            return this.setError(new Error('getDoctors'))
          // it is possible to receive doctors with fullName===''.
          // The openings of these doctors should still be available,
          // but the doctors themselves should not be displayed,
          // neither in the doctor drop down menu or in the opening string
          this.doctorsVisible = (response.doctors as OtkDoctor[])
            .filter((otkDoctor: OtkDoctor) => otkDoctor.fullName)
          this.doctorsInvisible = (response.doctors as OtkDoctor[])
            .filter((otkDoctor: OtkDoctor) => !otkDoctor.fullName)
          return of(null)
        }),
        mergeMap(() => {
          this.isLoading.next(false)
          return of(null)
        })
      )
  }

  /**
   * retrieves the list of all openings matching the instance and terminSucheIdent
   * uses server_booking/src/routes/opening.route.ts getOpenings
   * sorts the openings by date
   * tsIdent if is triggered manually in waitinglist-dialog.component
   */
  public getOpenings(tsIdent: string = "", fTime: number = 0): Observable<any> {
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          this.isLoading.next(true)
          return of(null)
        }),
        mergeMap(() => {
          const onlineConsultation = !!this.bookingTypeFormGroup.get("appointmentType").value?.onlineConsultation
          const terminSucheIdent: string = tsIdent ? tsIdent : this.bookingTypeFormGroup.get("appointmentType").value.terminSucheIdent
          const localityIds: string[] = (this.isBsActive && !onlineConsultation)
            ? (this.bs.value?.localityIdents
              ? this.bs.value.localityIdents
              : this.localitiesAll)
            : []
          const forerunTime = fTime || this.appointmentType.value?.forerunTime || 0
          const httpParams: HttpParams = new HttpParams()
            .set('localityIds', localityIds as unknown as string)
            .set('instance', this.instanceService.activeInstance._id)
            .set('terminSucheIdent', terminSucheIdent)
            .set('forerunTime', forerunTime)
          return this.httpClient.get(environment.otkUrl + '/api/opening', { params: httpParams })
        }),
        catchError((error) => this.setError(error)),
        mergeMap((response: any) => {
          if (!response?.openings)
            return this.setError(new Error('getOpenings'))
          this.openings = response?.openings as BookingOpening[]

          if (!this.isBirthdateActive || !this.appointmentType.value?.displayOptions?.ageAtAppointmentTime?.show) {
            this.openings = this.openingService.convertAndSortOpenings(this.openings)
            return of(null)
          }
          this.openings = this.openingService.filterAndProcessOpenings(this.openings, this.appointmentType.value?.displayOptions?.ageAtAppointmentTime, this.birthDate.value)
          // the dates are returned as strings even though they are defined
          // as Date in backend. Convert them to dayjs.
          // this.openings = this.openings
          //   .map((opening: BookingOpening) =>
          //     opening = {
          //       ...opening,
          //       date: dayjs(opening.date)
          //     })
          // sort the openings by date
          // this.openings = this.openings
          //   .sort((opening1, opening2) =>
          //     opening1.date.diff(opening2.date))
          return of(null)
        }),
        mergeMap(() => {
          this.isLoading.next(false)
          return of(null)
        })
      )
  }


  /**
   * retrieves openings for appointment Series
   * 
   */

  public getOpeningsMulti(tsIdent: string = ""): Observable<any> {
    this.isLoading.next(true)
    const mhdArray = this.appointmentSeriesChain?.map(item => item.mhd) || []
    const terminKetteSucheIdent: string = tsIdent ? tsIdent : this.appointmentType.value.terminKetteSucheIdent
    const localityIds: string[] = this.isBsActive ? (this.bs.value?.localityIdents || this.localitiesAll) : []
    const httpParams: HttpParams = new HttpParams()
      .set('instance', this.instanceService.activeInstance._id)
      .set('terminKetteSucheIdent', terminKetteSucheIdent)
      .set('localityIds', localityIds.join(','))
      .set('mhds', mhdArray.join(','))
    return this.httpClient.get(environment.otkUrl + '/api/opening/chain', { params: httpParams }).pipe(
      untilDestroyed(this),
      map((res: any) => res.openings))
  }


  public getReservations() {
    const terminSucheIdents = this.appointmentType.value.appointmentSeriesType.items.map(x => x.terminSucheIdent)
    const httpParams: HttpParams = new HttpParams()
      .set('terminSucheIdents', terminSucheIdents.join(','))
      .set('instance', this.instanceService.activeInstance._id)
    return this.httpClient.get(environment.otkUrl + '/api/reservation/get-for-idents', { params: httpParams }).pipe(
      untilDestroyed(this),
      map((res: any) => res.reservations?.map((resis: any) => resis.map((sres: any) => ({ ...sres, dateAppointment: new Date(sres.dateAppointment) })))))
  }


  /**
   * if the user selects an opening:
   *  if a reservation exists
   *    cancel it
   *  reserve
   */
  setReservationSubscription() {
    if (this.reservationSubscription)
      this.reservationSubscription.unsubscribe()
    this.reservationSubscription = this.opening.valueChanges
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          return this.reservation ? this.cancelReservation(false) : of(null) // "false" = cancel in backend only
        }),
        mergeMap(() =>
          this.opening.value ? this.reserve() : of(null))
      ).subscribe()
  }

  /**
   * Attempts to cancel an existing booking.
   * Called whenever the opening is changed (either directly or because
   * the appointment type was changed, which resets the opening)
   * if cancelFrontend is true, the appointment was cancelled because
   * it expired. In this case, don't just cancel it in backend but also
   * in frontend.
   */
  cancelReservation(cancelFrontend: boolean): Observable<any> {
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          this.isLoading.next(true)
          return of(null)
        }),
        mergeMap(() => {
          const query = {
            instance: this.instanceService.activeInstance._id,
            reservationId: this.reservation._id
          }
          return this.httpClient.post(environment.otkUrl + "/api/reservation/cancel", query)
        }),
        catchError((error) => this.setError(error)),
        mergeMap(() => {
          this.reservation = null // remove the reservation object
          // if the opening is not set, that means it was resettet because the
          // appointment type was changed. booking-date should then be set to
          // not completed.
          if (!this.opening.value && this.stepper?.steps?.get(1)) // second condition because undefined on browser back button click
            this.stepper.steps.get(1).completed = false
          return of(null)
        }),
        mergeMap(() =>
          cancelFrontend ? this.cancelReservationFrontend() : of(null)
        ),
        mergeMap(() => {
          this.updateMiniSummaryStrings()
          return of(null)
        }),
        mergeMap(() => {
          this.isLoading.next(false)
          return of(null)
        })
      )
  }

  /**
   * Called whenever one of the 3 fields relevant for reserving a booking is changed
   * && all three are valid.
   * Attempts to reserve the booking.
   * If succeeded, the patient can continue to fill out the form.
   * If failed, the patient needs to redo booking-date.
   */
  reserve(): Observable<any> {
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          this.isLoading.next(true)
          return of(null)
        }),
        mergeMap(() => {
          const query = {
            instance: this.instanceService.activeInstance._id,
            terminSucheIdent: (this.appointmentType.value as AppointmentType).terminSucheIdent,
            dateAppointment: (this.opening.value as BookingOpening).date,
            duration: (this.opening.value as BookingOpening).duration,
            dateExpiry: dayjs().add(this.globalReservationDurationS, "seconds").toDate(),
            doctorIds: (this.opening.value as BookingOpening).kdSet
              .map((kd) => kd.kid)
          }
          return this.httpClient.post(environment.otkUrl + "/api/reservation/reserve", query)
        }),
        catchError((error) => this.setError(error)),
        mergeMap((response: any) => {
          // if there is no response, the appointment has likely been reserved,
          // treat it as if it had been already booked by someone else.
          this.reservation = response?.reservation
          // cancelling a reservation by selecting a new appointment type
          // sets "Datum".completed = false, so here it is set to true
          if (!this.reservation) {
            this.notificationService.displayNotification(this.translateService.instant('OTK.NOTIFICATIONS.APPOINTMENT-RESERVED'))
            return this.cancelReservationFrontend()
          }
          if (this.stepper) {
            this.stepper.steps.get(1).completed = true
          }
          return of(null)
        }),
        mergeMap(() => {
          this.isLoading.next(false)
          return of(null)
        })
      )
  }

  /**
   * called either when the reservation failed in the backend or when the time
   * elapses. Resets the reservation object, booking date (doctors and openings)
   * and sends the user back to booking date
   */
  cancelReservationFrontend(): Observable<any> {
    this.stepper.selectedIndex = 1
    this.reservation = undefined
    this.stepper.steps.get(1).completed = false
    return this.initBookingDate()
  }


  stepperToBookingDateMulti() {
    const index = this.showBs$.getValue() ? 2 : 1
    this.stepper.selectedIndex = index
    this.stepper.steps.get(index).completed = false
  }

  /* booking anamnese */

  public anamneseFormComponent: InstanceFormInterface
  anamneseFormInstructions: string
  anamneseFormTitle: I18NString
  // we can't directly subscribe to the anamnese form because its created in runtime.
  // so we create new variables for "onChanges" and "valid" and attach them to it with a subscription.
  // anamneseFormChanges$ and isAnamneseFormValid are substitutes for anamneseFormComponent.anamneseFormHost.formGroup
  // valueChanges and valid, respectively, which cannot be used as they are created in runtime.
  anamneseFormSubscription: Subscription
  anamneseFormChanges$: Subject<boolean> = new Subject<boolean>()
  isAnamneseFormValid: boolean = false
  /** creates an instance of the custom, booking-type-specific anamnese form
   * to be shown after booking-date.
   * note 1: this is called on init and when a new appointment type with an anamnese form is selected.
   * this would have been called twice each time, because there are 2 booking-stepper and thus 2 booking-anamnese.
   * this would have caused the anamnese form shown to the user to be not interactive / decoupled from the
   * "next" button, because the one shown to the user would be that of the content-only booking-stepper,
   * while the one saved here would be that of the header-only booking-stepper.
   * To solve this, the header-only booking-stepper gets "isHeaderOnly"===true as input, which
   * prevents its booking-anamnese from calling this function.
   * note 2: I tried making this more aesthetic by making a single call to this function from booking-stepper,
   * but apparently formHost needs to be transferred directly from its component.
   */
  public createAnamneseFormComponent(formHost: ViewContainerRef): Observable<any> {
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          this.isLoading.next(true)
          return of(null)
        }),
        mergeMap(() => {
          const identifier = (this.appointmentType.value as AppointmentType).formIdentifier
          let instanceForm = this.instanceService.activeInstance.forms
            .find((form) => form.identifier === identifier)
          if (
            [null, undefined].includes(instanceForm)
            || [null, undefined].includes(instanceForm?.anamneseFormIdentifier)
            || this.anamneseFormsService.populateInstanceForm(instanceForm) !== 0
          ) {
            return of(null)
          }
          this.anamneseFormTitle = instanceForm.title as I18NString
          this.anamneseFormsService.populateInstanceForm(instanceForm)
          this.anamneseFormInstructions = instanceForm.instructions
          this.instanceFormService.activeInstanceForm = instanceForm
          formHost.clear()
          let component: Type<InstanceFormInterface>
          component = this.instanceFormService.activeInstanceForm.anamneseFormComponent
          const componentRef = formHost.createComponent(component)
          let formComponent
          formComponent = componentRef.instance
          formComponent.settings = this.instanceFormService.activeInstanceForm.anamneseFormSettings
          formComponent.step = this.stepper.steps.toArray()[3]

          if (formComponent.onInit)
            formComponent.onInit()
          this.anamneseFormComponent = formComponent
          this.updateFormSubscription(BookingStep.bookingAnamnese)
          return of(null)
        }),
        mergeMap(() => {
          this.isLoading.next(false)
          return of(null)
        })
      )
  }

  calculateRejected(checkBefore: dayjs.Dayjs) {
    if (this.openings.length === 0) return
    checkBefore = checkBefore.subtract(5, 'minutes')
    let rej: Date[] = []
    let currentTime: dayjs.Dayjs, append: boolean;
    currentTime = dayjs(this.openings[0].date)
    rej.push(currentTime.toDate())
    for (let i = 0; i < this.openings.length - 1; i++) {
      currentTime = dayjs(this.openings[i].date).add(this.openings[i].duration, 'minutes')
      if (dayjs(this.openings[i + 1].date).isAfter(checkBefore)) {
        break
      }
      append = currentTime.add(BRIDGE_TIME, 'minutes').isAfter(this.openings[i + 1].date)
      if (!append) {
        rej.push(currentTime.toDate())
        currentTime = dayjs(this.openings[i + 1].date)
        rej.push(currentTime.toDate())
      }
    }
    rej.push(currentTime.toDate())
    return rej
  }

  /* booking personal */

  public personalFormComponent: BookingPersonalComponent
  // we can't directly subscribe to the personal form because its created in runtime.
  // so we create a new variable for "onChanges" and attach it to it with a subscription.
  public personalFormChanges$: Subject<any> = new Subject<any>()
  personalFormSubscription: Subscription
  bookingStepPrevious: BookingStep // used to tell whether the user navigated from booking-personal and thus send a message to the app
  /* booking anamnese and booking personal - shared */

  updateFormSubscription(bookingStep: BookingStep) {
    switch (bookingStep) {
      case BookingStep.bookingAnamnese:
        if (this.anamneseFormSubscription)
          this.anamneseFormSubscription.unsubscribe()
        this.anamneseFormSubscription =
          this.anamneseFormComponent.formGroup.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => {
              this.isAnamneseFormValid =
                WfaModule.WfaFormGroupService.isDynamicallyValid(this.anamneseFormComponent.formGroup)
              this.anamneseFormChanges$.next(null)
            })
        break;
      case BookingStep.bookingPersonal:
        if (this.personalFormSubscription)
          this.personalFormSubscription.unsubscribe()
        this.personalFormSubscription =
          merge(this.personalFormComponent.formGroup.valueChanges)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
              this.personalFormChanges$.next(null)
              this.personalFormChanges$.next(null) // (?) otherwise cd ref is not triggered
            })
        break;
    }
  }


  // send the personal form data to the app upon step changes
  // from booking-personal to any other step,
  updateAppSubscribe(): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      if (this.updateAppSubscription)
        this.updateAppSubscription.unsubscribe()
      this.updateAppSubscription = this.stepper.selectionChange
        .pipe(
          untilDestroyed(this),
          delay(500) // otherwise the previous step is always this step
        ).subscribe(() => {
          if (this.bookingStepPrevious === BookingStep.bookingPersonal)
            (window as any).flutter_inappwebview
              ?.callHandler('getPersonalDataFromWebpage', this.personalFormComponent.formGroup.getRawValue());
          const bookingStepCurrent = (Object.keys(this.tabTitles) as BookingStep[])
            .find((bookingStep: BookingStep) =>
              this.tabTitles[bookingStep] === this.stepper.selected.label)
          this.bookingStepPrevious = bookingStepCurrent
          subscriber.next()
          subscriber.complete()
        })
    })
  }



  /* booking summary */
  private redUrl: string

  public fileEncrypting = Array.from(Array(this.MAX_ALLOWED_FILES), () => new BehaviorSubject<boolean>((false)))
  public fileEncryptionDone = Array.from(Array(this.MAX_ALLOWED_FILES), () => new BehaviorSubject<boolean>((false)))

  public subscribeWaitinglistControl = new FormControl<null | boolean>(false)
  otkAppointment: OtkAppointment
  initBookingSummary$: Subject<boolean> = new Subject<boolean>()
  bookingValueChanges$: Observable<any> = merge(
    this.bookingPreliminaryFormGroup.valueChanges,
    this.bookingBsFormGroup.valueChanges,
    this.bookingTypeFormGroup.valueChanges,
    this.bookingDateFormGroup.valueChanges,
    this.anamneseFormChanges$,
    this.personalFormChanges$)
  isBookingValid: boolean
  bookingResult: BookingResult
  messageToAppBook: MessageToApp = MessageToApp.BOOK
  // pdfSummary: { header: PdfHeader, tables: PdfTable[] } | undefined

  /**
   * assembles an otkAppointment object from the previous steps
   * TODO:
   *  eventType = ?
   *  strategy: get it from AppointmentType
   *  insurance.name: remove placeholder
   */
  public createOtkAppointment(): Observable<any> {
    let tomedoVersion = parseInt(this.instanceService.activeInstance?.tomedoVersion) // is NaN if null or undefined gt and lt compares always false
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          this.isLoading.next(true)
          return of(null)
        }),
        mergeMap(() => {
          const isZuweiserTermin: boolean = !!this.isZwCodeCorrect // "!!" because isZwCodeCorrect can be undefined and then no "isZuweiserTermin" field is added to the appintment object
          const appointmentType: AppointmentType = this.bookingTypeFormGroup.get("appointmentType").value as AppointmentType
          const bookingOpening: BookingOpening = this.bookingDateFormGroup.get("opening").value as BookingOpening
          let patientData = this.personalFormComponent.getPatientData(this.languageService.activeBaseLang, this.instanceService.activeInstance?.settings?.general?.internationalization?.baseLanguageDefault ?? this.languageService.DEFAULT_BASE_LANG)
          const agbAccepted = this.personalFormComponent.formGroup.get('agbAccepted')
          patientData.personal.agbAccepted = agbAccepted?.value
          delete patientData.personal.emailConfirm // frontend only
          // "name" was removed from customFieldResponses but is still used by tomedo.
          // so here we re-add it for tomedo. todo: tomedo should only use description and then we can remove name.
          const customFieldResponses: ICustomFieldResponse[] = patientData.customFieldResponses
            .map((iCustomFieldResponse: ICustomFieldResponse) => { return { ...iCustomFieldResponse, name: iCustomFieldResponse.description } })
          patientData = { ...patientData, customFieldResponses }
          if (this.otkUser.insuranceFilterActive && patientData.insurance) {
            patientData.insurance.type = this.insuranceType.value // patientInsurance
          }
          if (!(tomedoVersion > 115)) delete patientData.checkboxResponses  // prior to 116 checkbox responses go to info field which does not look nice in tomedo
          this.otkAppointment = {
            instance: this.instanceService.activeInstance._id,
            eventType: OtkEventType.OTKAPPOINTMENT,
            terminSucheIdent: appointmentType.terminSucheIdent,
            start: bookingOpening.date,
            end: dayjs(bookingOpening.date)
              .add(bookingOpening.duration, "minutes").toDate(),
            kdSet: bookingOpening.kdSet,
            accessCode: undefined,
            strategy: appointmentType.strategy,
            patientData,
            isPrimary: true,
            bookedOver: this.isInApp ? BookedOverType.APP : (this.isInIframe ? BookedOverType.IFRAME : BookedOverType.AD),
            referringDoctor: undefined,
            reservationId: this.reservation._id,
            schemaVersion: this.instanceService.activeInstance.tomedoVersion ? this.instanceService.activeInstance.tomedoVersion : environment.tomedoVersion.toString(),
            // until and including tomedo version 123, "betriebsstaette" was actually the bs id, not the bs itself.
            // starting from tomedo version 124, "betriebsstaette" is really the bs, not the id
            ...((this.bsDisplayed && this.isBsActive)
              ? (tomedoVersion >= 124
                ? { betriebsstaette: this.bsDisplayed }
                : { betriebsstaette: this.bsDisplayed._id })
              : {}),
            ...(appointmentType?.onlineConsultation ? { isOnlineConsultation: true } : {}),
            ...(appointmentType?.forerunTime ? { forerunTime: appointmentType.forerunTime } : {}),
            displayStringNames: (this.opening.value as BookingOpening).displayStringNames,
            name: (this.appointmentType.value as AppointmentType).name,
            description: (this.appointmentType.value as AppointmentType).description,
            dialect: (this.appointmentType.value as AppointmentType).dialect,
            isZuweiserTermin
          }
          return of(null)
        }),
        mergeMap(() => {
          this.isLoading.next(false)
          return of(null)
        })
      )
  }

  getPatientData() {
    let patientData = this.personalFormComponent.getPatientData(this.languageService.activeBaseLang, this.instanceService.activeInstance?.settings?.general?.internationalization?.baseLanguageDefault ?? this.languageService.DEFAULT_BASE_LANG)
    delete patientData.personal.emailConfirm // frontend only
    // "name" was removed from customFieldResponses but is still used by tomedo.
    // so here we re-add it for tomedo. todo: tomedo should only use description and then we can remove name.
    const customFieldResponses: ICustomFieldResponse[] = patientData.customFieldResponses
      .map((iCustomFieldResponse: ICustomFieldResponse) => { return { ...iCustomFieldResponse, name: iCustomFieldResponse.description } })
    patientData = { ...patientData, customFieldResponses }
    if (this.otkUser.insuranceFilterActive && patientData.insurance) {
      patientData.insurance.type = this.insuranceType.value // patientInsurance
    }
    return patientData
  }


  getBookedOverType(): BookedOverType {
    return this.isInApp ? BookedOverType.APP : (this.isInIframe ? BookedOverType.IFRAME : BookedOverType.AD)
  }


  getBsForLocalities(lids: string[]): Betriebsstaette {
    const bsSharingLidsWithOpening: Betriebsstaette[] = this.betriebsstaetten.filter((bs: Betriebsstaette) =>
      lids.some(lid => bs?.localityIdents.includes(lid))
    )
    return bsSharingLidsWithOpening[0] ?? null
  }

  /**
   * get the localities of the visible doctors of the selected opening.
   */
  getLocalities(): Observable<TomTerminLokalitaet[]> {
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          return of(null)
        }),
        mergeMap(() => {
          const localityIds: string[] = this.otkAppointment.kdSet
            .map((kd: TomKalenderDaten) => kd.lid)
            .filter((ident: string) =>
              this.doctorsVisible
                .map((otkDoctor: OtkDoctor) => otkDoctor.ident)
                .includes(ident))
          const httpParams: HttpParams = new HttpParams()
            .set('instance', this.instanceService.activeInstance._id)
            .set('localityIds', JSON.stringify(localityIds))
          return this.httpClient.get(environment.otkUrl + "/api/tomterminlokalitaet",
            { params: httpParams })

        }),
        catchError((error) => this.setError(error)),
        mergeMap((response: any) => {
          this.isLoading.next(false)
          return response?.localities ? of(response.localities) : this.setError(new Error('getLocalities'))
        }),
      )
  }

  book(payment = null) {
    this.isLoading.next(true)
    const subscribeWaitinglist: boolean = !!this.subscribeWaitinglistControl?.value
    of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          this.isLoading.next(true)
          return of(null)
        }),
        // todo: move the mergeMaps below to a separate function (or maybe to the function where this.otkAppointment is defined?)
        mergeMap(() => (this.assets.value?.length && this.certBase64) ? this.bookingHelpersService.encryptOtkAssets(this.assets.value, this.certBase64, this.fileEncrypting, this.fileEncryptionDone) : of(null)),
        mergeMap((otkAttachments: OtkAttachment[]) => {
          const waitinglistRequest = this.makeWaitinglistRequest(subscribeWaitinglist)
          // If vssAppointment attach link
          // todo: move to function
          const checkboxResponses = (this.otkAppointment.patientData.checkboxResponses) ? this.otkAppointment.patientData.checkboxResponses : [];
          const checkboxResponsesStripped = checkboxResponses.map((response) => {
            if (!response?.description) { return response }
            response.description = response?.description.replace(/<\/?[^>]+(>|$)/gi, '')
            return response;
          })
          const query = { otkAppointment: Object.assign(this.otkAppointment, waitinglistRequest), otkAttachments, ...(this.isIkActive ? { knownPatient: !!this.isKnown.value } : {}) }
          if (payment) {
            payment.paymentId ?
              (query['payment'] = payment) : (query['paymentDeferred'] = payment);
          }
          query.otkAppointment.patientData.checkboxResponses = checkboxResponsesStripped
          return this.httpClient.post(environment.otkUrl + "/api/appointment/book", query)
        }),
        catchError((error) => this.setError(error))
      )
      .subscribe((response) => {
        this.isLoading.next(false)
        this.bookingResult = response
          ? BookingResult.SUCCESS
          : BookingResult.FAILURE
        if (this.bookingResult === BookingResult.SUCCESS) {
          this.setRedirectionUrl();
          this.sendMessageToApp(this.messageToAppBook, (response as unknown as any).iOtkAppointmentUpdated as OtkAppointment);
          this.navigateOnBookingSuccess()
        }
        else
          this.setError(new Error('book failed'))
      })
  }

  sendMessageToApp(messageToApp: MessageToApp, otkAppointment?: OtkAppointment | OtkAppointmentSeries) {
    (window as any).flutter_inappwebview?.callHandler(messageToApp, otkAppointment)
  }

  public navigateOnBookingSuccess() {
    const newUrl = this.router.url.replace('booking', 'booking/result')
    if (this.bookingResult === BookingResult.SUCCESS && this.redUrl) { // If booking succes and set otkuser.redirectionAfterBookingUrl
      if (!this.isInIframe) window.location.href = this.redUrl
      else window.parent.location.href = this.redUrl
    } else {
      if (this.isStandalone) {
        this.isStandaloneComplete$.next(true);
        return
      }
      this.router.navigateByUrl(newUrl)
    }
  }

  public navigateContact() {
    this.bookingResult = BookingResult.SUCCESS;
    this.navigateOnBookingSuccess();
  }

  private makeWaitinglistRequest(subscribeWaitinglist: boolean) {
    if (!subscribeWaitinglist) return {}
    // If Betriebsstaette associated
    const bsFilterRequest = (this.otkAppointment.betriebsstaette && this.bsDisplayed?.betriebsstaetteIdent) ? { betriebsstaetten: [this.bsDisplayed.betriebsstaetteIdent] } : {}
    return { waitinglistRequest: { before: this.otkAppointment.start, rejected: this.calculateRejected(dayjs(this.otkAppointment.start)), ...bsFilterRequest } }
  }

  // sets the error variable, which causes booking.component to show the error component and button and hide everything else
  setError(error: Error): Observable<any> {
    console.error('[BookingService]: got error', error.message, error)
    return new Observable<any>((subscriber: Subscriber<any>) => {
      this.error = error
      subscriber.next()
      subscriber.complete()
    })
  }

  public moveAppointment(instance: string, accessCode: string, opening: BookingOpening,
    unsubscribeWaitinglist = false): Observable<any> {
    const appointmentUpdate = {
      opening,
      instance,
      accessCode,
      unsubscribeWaitinglist
    };
    return this.httpClient.post(`${environment.otkUrl}/api/appointment/move119`, appointmentUpdate)
      .pipe(
        untilDestroyed(this),
        catchError((error) => this.setError(error))
      );
  }

  // note: this does not return the moved appointment, but the entire response. fix?
  public moveAppointmentLegacy(instance: string, accessCode: string, opening: BookingOpening,
    unsubscribeWaitinglist = false): Observable<any> {
    const appointmentUpdate = {
      opening,
      instance,
      accessCode,
      unsubscribeWaitinglist
    };
    return this.httpClient.post(`${environment.otkUrl}/api/appointment/move`, appointmentUpdate)
      .pipe(
        untilDestroyed(this),
        catchError((error) => this.setError(error))
      );
  }

  public subscribeWaitingList(instance: string, accessCode: string): Observable<any> {
    return this.httpClient.post(`${environment.otkUrl}/api/waitinglist/subscribe`, { instance, accessCode }).pipe(
      untilDestroyed(this),
      catchError((error) => this.setError(error))
    );
  }

  public unsubscribeWaitlinglist(instance: string, accessCode: string): Observable<any> {
    const appointmentUpdate = {
      instance,
      accessCode,
    };
    return this.httpClient.post(`${environment.otkUrl}/api/waitinglist/unsubscribe`, appointmentUpdate)
      .pipe(
        untilDestroyed(this),
        catchError((error) => this.setError(error))
      );
  }

  public loadAppointmentByCode(accessCode: string, birthDate: string): Observable<any> {
    return this.httpClient.post(`${environment.otkUrl}/api/appointment/load`, { accessCode, birthDate })
      .pipe(
        untilDestroyed(this),
        catchError((error) => this.setError(error)),
        map((response: any) => response.appointment)
      );
  }

  public changeState(accessCode: string, instance: string, state: OtkAppointmentStatus): Observable<OtkAppointment> {
    return this.httpClient.post(`${environment.otkUrl}/api/appointment/state`, { accessCode, instance, state })
      .pipe(
        untilDestroyed(this),
        catchError((error) => this.setError(error)),
        map((categoryResponse: any) => categoryResponse.appointment)
      );
  }

  public cancelAppointment(accessCode: string, isIncognito: boolean) {
    let appVersion: string;
    if (this.appVersion) {
      appVersion = `${this.appVersion.major}.${this.appVersion.minor}.${this.appVersion.patch}`;
    }
    const inApp = this.isInApp;
    return this.httpClient.post(`${environment.otkUrl}/api/appointment/cancel`, { accessCode, isIncognito, inApp, appVersion })
      .pipe(
        untilDestroyed(this),
        catchError((error) => this.setError(error)),
        map((response: any) => response.appointment)
      )
  }

  public cancelAppointmentSeries(accessCode: string, instance: string) {
    let appVersion: string;
    if (this.appVersion) {
      appVersion = `${this.appVersion.major}.${this.appVersion.minor}.${this.appVersion.patch}`;
    }
    const inApp = this.isInApp;
    return this.httpClient.post(`${environment.otkUrl}/api/appointment/cancel-series`, { accessCode, instance, inApp, appVersion })
      .pipe(
        untilDestroyed(this),
        catchError((error) => this.setError(error))
      )
  }

  public filterEquidistantEntries(startValue: number, stopValue: number, cardinality: number, inputArray: any[]) {
    if (cardinality == 0 || cardinality >= inputArray.length) { return inputArray; }
    if (cardinality == 1) { return [inputArray[0]]; }
    const arr = [];
    const step = (stopValue - startValue) / (cardinality - 1);
    for (let i = 0; i < cardinality; i++) {
      const index = startValue + Math.round(step * i);
      arr.push(inputArray[index])
    }
    return arr;
  }

  public doesAnamneseFormExist(): boolean {
    const instanceForm = AnamneseFormCheckerService.getInstanceForm(
      this.appointmentType.value,
      this.instanceService.activeInstance.forms
    )
    if (instanceForm === null) return false
    if ([null, undefined].includes(instanceForm?.anamneseFormIdentifier))
      return false
    return true
  }

  private isValidUrl(url: string): boolean {
    return url !== '' && url !== I18NString_MISSING_REDIRECT_LINK;
  }

  public setRedirectionUrl() {
    this.activatedRoute.params.subscribe({
      next: (params) => {
        const appTypeUrl = this.appointmentType?.value?.redirectionAfterBookingActive ?
          this.appointmentType?.value?.redirectionAfterBookingUrls[this.languageService.activeBaseLang] : '';
        const globalUrl = this.otkUser?.redirectionAfterBookingActive ?
          this.otkUser?.redirectionAfterBookingUrls[this.languageService.activeBaseLang] : '';
        const url = this.isValidUrl(appTypeUrl) ? appTypeUrl : this.isValidUrl(globalUrl) ? globalUrl : '';
        this.redUrl = (params?.patientId) ? `${url}?patientId=${params.patientId}` : url
      },
      error: (error) => {
        this.adLoggerService.error(error);
        const appTypeUrl = this.appointmentType?.value?.redirectionAfterBookingActive ?
          this.appointmentType?.value?.redirectionAfterBookingUrls[this.languageService.activeBaseLang] : '';
        const globalUrl = this.otkUser?.redirectionAfterBookingActive ?
          this.otkUser?.redirectionAfterBookingUrls[this.languageService.activeBaseLang] : '';
        this.redUrl = this.isValidUrl(appTypeUrl) ? appTypeUrl : this.isValidUrl(globalUrl) ? globalUrl : '';
      }
    })
  }

  //In case of category directLink
  public setCategory(catId: string) {
    this.category = catId
  }

  public encryptOtkAssets() {
    return (this.assets.value?.length && this.certBase64) ? this.bookingHelpersService.encryptOtkAssets(this.assets.value, this.certBase64, this.fileEncrypting, this.fileEncryptionDone).pipe(untilDestroyed(this)) : of(null)
  }

  public resetFileUpload() {
    (this.assetFormGroup.get('assets') as UntypedFormArray).clear()
    this.assetFormGroup.reset()
    this.fileEncrypting.forEach(x => x.next(false))
    this.fileEncryptionDone.forEach(x => x.next(false))
  }

}
