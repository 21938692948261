<lib-dialog #dialog>

  <div mat-dialog-title>
    <h1>
      {{ 'DSGVO.DIALOG.TITLE' | translate }}
    </h1>
    <ng-template [ngTemplateOutlet]="dialog.closeButtonRef"></ng-template>
  </div>

  <div mat-dialog-content>
    <p>{{ 'DSGVO.DESCRIPTION' | translate }}</p>
    <p [innerHTML]="'DSGVO.REVOCATION' | translate | sanitizeHtml"></p>

    <!-- Privacy-Settings Accordion -->
    <mat-accordion class="c-privacy-settings-accordion" [multi]="false" [hideToggle]="true">

      <!-- Formulardatenübertragung in den Warteraum mit indexDB / Sicherung mit ngForage. -->
      <div class="c-privacy-settings-accordion__item">
        <mat-slide-toggle class="c-slide-toggle" [checked]="true" [disabled]="true"></mat-slide-toggle>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'DSGVO.DIALOG.FORMS-DATA-TRANSFER' | translate }}
              <fa-icon [icon]="['far', 'question-circle']" style="margin-left: .5rem; opacity: .75;"></fa-icon>
            </mat-panel-title>
            <mat-panel-description>
              {{ 'DSGVO.DIALOG.MANDATORY' | translate }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          {{ 'DSGVO.DIALOG.FORMS-DATA-TRANSFER-DESCRIPTION' | translate }}
        </mat-expansion-panel>
      </div>

      <!-- Formularcaching mit localStorage -->
      <div class="c-privacy-settings-accordion__item">
        <mat-slide-toggle color="primary" [checked]="isFormCachingAllowed"
          (change)="setEssentialCookie(EssentialCookieNames.isFormCachingAllowed,$event)">
        </mat-slide-toggle>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'DSGVO.DIALOG.FORMS-CACHING' | translate }}
              <fa-icon [icon]="['far', 'question-circle']" style="margin-left: .5rem; opacity: .75;"></fa-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>
          {{ 'DSGVO.DIALOG.FORMS-CACHING-DESCRIPTION' | translate }}
        </mat-expansion-panel>
      </div>

      <!-- Hilfscookies für die einfachere Nutzung  -->
      <div class="c-privacy-settings-accordion__item">
        <mat-slide-toggle color="primary" [checked]="areSupportCookiesAllowed"
          (change)="setEssentialCookie(EssentialCookieNames.areSupportCookiesAllowed, $event)">
        </mat-slide-toggle>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'DSGVO.DIALOG.SUPPORT-COOKIES' | translate }}
              <fa-icon [icon]="['far', 'question-circle']" style="margin-left: .5rem; opacity: .75;"></fa-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>
          {{ 'DSGVO.DIALOG.SUPPORT-COOKIES-DESCRIPTION' | translate }}
          <ul>
            <li>
              <div>
                {{ 'DSGVO.DIALOG.SUPPORT-COOKIES-DESCRIPTION-FUNCTIONAL-COOKIE' | translate }}
                <strong>{{ 'DSGVO.DIALOG.SUPPORT-COOKIES-DESCRIPTION-LI1-NAME' | translate }}</strong>
              </div>
              {{ 'DSGVO.DIALOG.SUPPORT-COOKIES-DESCRIPTION-LI1-DESCRIPTION' | translate }}
            </li>
            <li>
              <div>
                {{ 'DSGVO.DIALOG.SUPPORT-COOKIES-DESCRIPTION-FUNCTIONAL-COOKIE' | translate }}
                <strong>{{ 'DSGVO.DIALOG.SUPPORT-COOKIES-DESCRIPTION-LI2-NAME' | translate }}</strong>
              </div>
              {{ 'DSGVO.DIALOG.SUPPORT-COOKIES-DESCRIPTION-LI2-DESCRIPTION' | translate }}
            </li>
            <li>
              <div>
                {{ 'DSGVO.DIALOG.SUPPORT-COOKIES-DESCRIPTION-FUNCTIONAL-COOKIE' | translate }}
                <strong>{{ 'DSGVO.DIALOG.SUPPORT-COOKIES-DESCRIPTION-LI3-NAME' | translate }}</strong>
              </div>
              {{ 'DSGVO.DIALOG.SUPPORT-COOKIES-DESCRIPTION-LI3-DESCRIPTION' | translate }}
            </li>
          </ul>
        </mat-expansion-panel>
      </div>

    </mat-accordion>

  </div>

  <!-- buttons -->
  <div mat-dialog-actions>
    <!-- <button mat-flat-button [mat-dialog-close]>
      <fa-icon [fixedWidth]="true" [icon]="['far', 'chevron-left']"></fa-icon>
      <span>{{ 'DSGVO.DIALOG.BACK' | translate }}</span>
    </button> -->
    <button mat-flat-button [mat-dialog-close] color="primary" (click)="closeDsgvoBottomSheet()">
      <fa-icon [fixedWidth]="true" [icon]="['fas', 'check']"></fa-icon>
      <span>{{ 'DSGVO.DIALOG.ACCEPT' | translate }}</span>
    </button>
    <button mat-flat-button [mat-dialog-close] (click)="acceptAllDsgvoPreferences()">
      <fa-icon [fixedWidth]="true" [icon]="['fas', 'check-double']"></fa-icon>
      <span>{{ 'DSGVO.DIALOG.ACCEPT-ALL' | translate }}</span>
    </button>
  </div>

</lib-dialog>