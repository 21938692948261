import { coreLocales } from '@arzt-direkt/wfa-definitions'
import { mergeDeep, notNullish } from '@arzt-direkt/wfa-generic-utils'
import { surveyLocalization } from 'survey-core'

import { getLocalizedTranslations } from '../../utility/get-localized-translations'
import { customTranslations } from './custom-translations-for-viewer-and-editor-preview'

export function customizeTranslations(): void {
  coreLocales.forEach((locale) => {
    const localizedTranslations = getLocalizedTranslations(
      customTranslations,
      locale,
    )
    if (notNullish(localizedTranslations))
      surveyLocalization.locales[locale] = mergeDeep(
        surveyLocalization.locales[locale],
        localizedTranslations,
      )
  })
}
