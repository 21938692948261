<!-- Menu-Button -->
<ng-container *ngIf="menuItem && !asBar">
  <button id="button-open-legal-menu" mat-menu-item [matMenuTriggerFor]="legalMenu">
    <mat-icon>help_outline</mat-icon>
    <span>{{ title | translate }}</span>
  </button>
</ng-container>

<ng-container *ngIf="!menuItem && !asBar">
  <button mat-icon-button attr.data-selenium-id="button-hilfe" [matMenuTriggerFor]="legalMenu">
    <mat-icon>help_outline</mat-icon>
    <span [class.o-vh]="true">{{ title | translate }}</span>
  </button>
</ng-container>


<!-- Menu-Content -->
<mat-menu #legalMenu="matMenu">

  <ng-container *ngFor="let item of menuItems; let i = index">
    <a mat-menu-item (click)="item.action()" attr.data-selenium-id="anchor-legal-menu-auswahl-{{i}}">
      <mat-icon>{{item.icon}}</mat-icon>
      <span>{{ item.title | translate }}</span>
    </a>
    <mat-divider *ngIf="item.hasDivider && (viewMode!=='patientOTK')"></mat-divider>
  </ng-container>

</mat-menu>

<!-- Legal Menu as Bar-->
<div *ngIf=asBar class="legal-links">
  <ng-container *ngFor="let item of menuItems; let i = index">
    <a attr.data-selenium-id="anchor-legal-link-{{i}}" (click)="item.action()">{{
      item.title |
      translate }}</a>
  </ng-container>
</div>