import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { I18NString_MISSING_REDIRECT_LINK } from "vendor/arzt-direkt";
import { UnicodeBlocks } from "../unicode-blocks";
import { notPatternValidator } from "./general.validators";


/**
 * Validator that requires the control to not contain any of the characters in
 *  the provided string.
 * 
 * @remark
 * Do NOT use this function directly for code outside of `validators/`,
 * use `ADValidators.doNotAllowCharacters` instead.
 */
export function doNotAllowCharactersValidator(characters: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const validatorFn = notPatternValidator("[" + characters + "]+");
    const result = validatorFn(control);
    return result ? { 'notAllowedCharacters': result['pattern'] } : null;
  }
}


/**
 * Gender Validation
 * 
 * @remark
 * Do NOT use this function directly for code outside of `validators/`,
 * use `ADValidators.gender` instead.
 */
export function genderValidator(): ValidatorFn {
  return Validators.pattern('^(M|W|D)$')
}


/**
 * Match the string of another FormControl
 * 
 * @remark
 * Do NOT use this function directly for code outside of `validators/`,
 * use `ADValidators.matchStringOfOtherControl` instead.
 */

export function matchStringOfControlValidator(
  otherControl: FormControl<string>
): ValidatorFn {
  return (formControl: FormControl<string>): ValidationErrors =>
    formControl.value === otherControl.value
      ? null
      : { 'mismatch': formControl.value }
}


/**
 * No leading or trailing whitespace in control field.
 * 
 * @remark
 * Do NOT use this function directly for code outside of `validators/`,
 * use `ADValidators.noLeadingOrTrailingWhitespace` instead.
 */
export function noLeadingOrTrailingWhitespaceValidator(): ValidatorFn {
  return (formControl: FormControl<string>): ValidationErrors => {
    const regExp: RegExp = new RegExp('^\\s|\\s$')
    return regExp.test(formControl.value) ?
      { 'leadingOrTrailingWhitespace': formControl.value } : null
  }
}


// replaces getForbidEmojisValidator()
/**
 * Don't allow special characters
 * 
 * @see {@link UnicodeBlocks}
 * 
 * @remark
 * Do NOT use this function directly for code outside of `validators/`,
 * use `ADValidators.noSpecialCharacters` instead.
 */
export function noSpecialCharactersValidator(control: AbstractControl): ValidationErrors | null {
  const U = UnicodeBlocks
  const latinOneSupplementSymbols: RegExp = new RegExp('[\u{0080}-\u{00BF}\u{00D7}\u{00F7}]', 'u');
  const generalPunctuationExcludingWhitespace: RegExp = new RegExp('[\u{2011}-\u{206F}]', 'u');
  // order according to UnicodeBlocks and utf ranges, not names
  const specialCharacters: RegExp = U.join(
    latinOneSupplementSymbols,
    generalPunctuationExcludingWhitespace,
    U.SuperscriptsandSubscripts,
    U.CurrencySymbols,
    U.CombiningDiacriticalMarksforSymbols,
    U.LetterlikeSymbols,
    U.NumberForms,
    U.Arrows,
    U.MathematicalOperators,
    U.MiscellaneousTechnical,
    U.ControlPictures,
    U.OpticalCharacterRecognition,
    U.EnclosedAlphanumerics,
    U.BoxDrawing,
    U.BlockElements,
    U.GeometricShapes,
    U.MiscellaneousSymbols,
    U.Dingbats,
    U.MiscellaneousMathematicalSymbolsA,
    U.SupplementalArrowsA,
    U.BraillePatterns,
    U.SupplementalArrowsB,
    U.MiscellaneousMathematicalSymbolsB,
    U.SupplementalMathematicalOperators,
    U.MiscellaneousSymbolsandArrows,
    U.SupplementalPunctuation,
    U.IdeographicDescriptionCharacters,
    U.CJKSymbolsandPunctuation,
    U.EnclosedCJKLettersandMonths,
    U.HighSurrogates,
    U.HighPrivateUseSurrogates,
    U.LowSurrogates,
    U.PrivateUseArea,
    U.Specials,
    U.MusicalSymbols,
    U.MathematicalAlphanumericSymbols,
    U.MahjongTiles,
    U.DominoTiles,
    U.PlayingCards,
    U.EnclosedAlphanumericSupplement,
    U.EnclosedIdeographicSupplement,
    U.MiscellaneousSymbolsandPictographs,
    U.Emoticons,
    U.OrnamentalDingbats,
    U.TransportandMapSymbols,
    U.AlchemicalSymbols,
    U.GeometricShapesExtended,
    U.SupplementalArrowsC,
    U.SupplementalSymbolsandPictographs,
    U.ChessSymbols,
    U.SymbolsandPictographsExtendedA
  );

  const validatorFn = notPatternValidator(specialCharacters);
  const result = validatorFn(control);

  return result ? { 'specialCharacters': result['pattern'] } : null;
}


/**
 * Only allow specified characters in control field.
 * 
 * @remark
 * Do NOT use this function directly for code outside of `validators/`,
 * use `ADValidators.onlyAllowCharacters` instead.
 */
export function onlyAllowCharactersValidator(characters: string): ValidatorFn {
  return Validators.pattern('^[' + characters + ']*$');
}


/**
 * Allow only valid URLs in an i18n-String
 */

export function i18nUrlValidator(): ValidatorFn {
  const urlRegex = /^(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/;
  return (control: AbstractControl): { [key: string]: any } | null => {
    const invalidUrl: { [key: string]: boolean } = {};

    let isValid = true;

    for (const [lang, val] of Object.entries(control.value)) {
      const validUrl =
        val === '' ||
        val === I18NString_MISSING_REDIRECT_LINK ||
        (typeof val === 'string' && urlRegex.test(val));

      if (!validUrl) {
        invalidUrl[lang] = true;
        isValid = false;
      }
    }

    return isValid ? null : { 'invalidUrl': invalidUrl };
  };
}