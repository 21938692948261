import 'survey-core/survey.i18n'

import { Component, Input, OnInit } from '@angular/core'
import { MatCardModule } from '@angular/material/card'
import { ActivatedRoute } from '@angular/router'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import { notNullish, nullish } from '@arzt-direkt/wfa-generic-utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SurveyModule } from 'survey-angular-ui'
import { Model } from 'survey-core'

import { logWfaEnv } from '../../environments/log-wfa-env'
import { WfaEnvService } from '../../environments/wfa-env.service'
import genericError from './../adis/viewer-from-link-error/viewer-from-link-error.json'
import { getDisplayableErrorAsSurveyjs } from './displayable-errors'
import { decodeRouteParam } from './encode-route-param'
import alreadySubmitted from './viewer-from-link-already-submitted.json'

const messageTypeDict: Record<string, unknown> = {
  alreadySubmitted,
  genericError,
}

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-user-message',
  templateUrl: './user-message.component.html',
  styleUrls: ['./user-message.component.scss', '../../scss/mat-card.scss'],
  imports: [MatCardModule, SurveyModule],
})
export class UserMessageComponent implements OnInit {
  @Input() surveyjsJson: Maybe<Record<string, unknown>> = undefined
  @Input() messageCode: Maybe<string> = undefined
  survey = new Model()
  awaitingErrorForm = true

  constructor(
    private route: ActivatedRoute,
    private wfaEnv: WfaEnvService,
  ) {}

  ngOnInit() {
    this.wfaEnv.logger.info(
      `[WFA] UserMessageComponent: ngOnInit called with wfaEnv ${JSON.stringify(
        logWfaEnv(this.wfaEnv),
      )}`,
    )

    if (notNullish(this.surveyjsJson) && this.awaitingErrorForm) {
      this.survey.fromJSON(this.surveyjsJson)
      this.awaitingErrorForm = false
    }

    if (notNullish(this.messageCode) && this.awaitingErrorForm) {
      this.survey.fromJSON(getDisplayableErrorAsSurveyjs(this.messageCode))
      this.awaitingErrorForm = false
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.route.data.pipe(untilDestroyed(this)).subscribe((data: any) => {
      const errorCode = data['routeResolver']?.errorCode
      const displayableError = data['routeResolver']?.displayableError

      if (
        notNullish(errorCode) &&
        notNullish(displayableError) &&
        this.awaitingErrorForm
      ) {
        this.survey.fromJSON(displayableError)
        this.awaitingErrorForm = false
      }
    })

    if (nullish(this.surveyjsJson) && this.awaitingErrorForm) {
      const form = selectFormByMessageType(
        this.route.snapshot.params['messageType'],
      )

      this.survey.fromJSON(form)
      this.awaitingErrorForm = false
    }

    this.survey.showNavigationButtons = false
  }
}

function selectFormByMessageType(s: Maybe<string>): object {
  if (nullish(s)) return getDisplayableErrorAsSurveyjs('q5704')

  if (s.startsWith('dynamic')) {
    const params = s.split('~')
    if (![2, 3].includes(params.length))
      return getDisplayableErrorAsSurveyjs('q5705')

    if (params.length === 2) {
      const code = params[1]
      return getDisplayableErrorAsSurveyjs(code)
    }

    if (params.length === 3) {
      const code = params[1]
      const body = decodeRouteParam(params[2])
      return getDisplayableErrorAsSurveyjs(code, body)
    }
  }

  return (messageTypeDict[s] ?? genericError) as unknown as object
}
