export const example = {
  type: 'dropdown',
  name: 'Frage3',
  title: {
    de: 'Wie wurden Sie auf uns aufmerksam?',
  },
  choices: [
    {
      value: 'Item1',
      text: {
        de: 'Augenarzt ',
      },
    },
    {
      value: 'Item2',
      text: {
        de: 'Hausarzt',
      },
    },
    {
      value: 'Item3',
      text: {
        de: 'Apotheke',
      },
    },
    {
      value: 'Item4',
      text: {
        de: 'Optiker',
      },
    },
    {
      value: 'Item5',
      text: {
        de: 'Telefonbuch',
      },
    },
    {
      value: 'Item6',
      text: {
        de: 'Internet',
      },
    },
    {
      value: 'Item7',
      text: {
        de: 'durch Bekannte',
      },
    },
    {
      value: 'Item8',
      text: {
        de: 'auf Empfehlung',
      },
    },
    {
      value: 'Item9',
      text: {
        de: 'Sonstiges',
      },
    },
  ],
  shortTitle: 'Aufmerksam geworden durch',
}
