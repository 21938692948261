import { CommonModule } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { CoreLocale } from '@arzt-direkt/wfa-definitions'
import { TranslateModule, TranslateService } from '@ngx-translate/core'

import { translateMatDialogContent } from '../../../../../utility/translate-mat-dialog-content'

interface DialogData {
  locale?: CoreLocale
}

@Component({
  selector: 'wfa-limit-dialog',
  templateUrl: './limit-dialog.component.html',
  styleUrls: [
    './limit-dialog.component.scss',
    '../../../../../../scss/button.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    TranslateModule,
  ],
})
export class LimitDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<LimitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translate: TranslateService,
  ) {
    translateMatDialogContent(this.translate, this.data.locale)
  }

  continue() {
    this.dialogRef.close()
  }
}
