import { Injectable, Injector } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { isObservable, Observable, of } from "rxjs";
import { mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GuardsSynchronizierGuard implements CanActivate {
  constructor(
    private injector: Injector,
  ) { }

  /** allows chaining and execution of guards in a specific order.
   * At the moment only supports boolean and Observable<boolean>.
   * Could also support: UrlTree | Observable<UrlTree> | Promise<boolean> | Promise<UrlTree>
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    let canActivateTotal: Observable<boolean> = of(true)
    let guards = route.data.guards
    for (let i = 0; i < Object.keys(guards).length; i++) {
      let guard = this.injector.get<CanActivate>(guards[i])
      let canActivateSingle: boolean | Observable<boolean> = guard
        .canActivate(route, state) as boolean | Observable<boolean>
      if (isObservable(canActivateSingle))
        canActivateTotal = canActivateTotal.pipe(
          mergeMap((bool: boolean) => canActivateSingle as Observable<boolean>),
        )
      else
        canActivateTotal = canActivateTotal.pipe(
          mergeMap((bool: boolean) => of(canActivateSingle as boolean)),
        )
    }
    return canActivateTotal
  }

}
