import { defaults } from '../defaults'
import { Certificate } from 'pkijs'
import { Observable } from 'rxjs'

/**
 * add public key to the certificate and sign with the private key
 * @param certificate user certificate object
 * @param keyPair public and private keys
 */
export function addKeyAndSign(
  certificate: Certificate,
  keyPair: CryptoKeyPair,
  hashAlgorithm: string = defaults.hashAlgorithm,
): Observable<Certificate> {
  return new Observable((observer) => {
    if (keyPair) {
      certificate.subjectPublicKeyInfo.importKey(keyPair.publicKey)
      certificate.sign(keyPair.privateKey, hashAlgorithm).then(() => {
        observer.next(certificate)
        observer.complete()
      })
    } else {
      observer.error('No Key pair available')
    }
  })
}
