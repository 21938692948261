import { designerSurfaceStrings } from './designer-surface-strings'
import { editorStrings } from './editor-strings'
import { infoTexts } from './info-texts'
import { propertyTitles } from './property-titles'
import { propertyValues } from './property-values'
import { toolboxStrings } from './toolbox-strings'

/**
 * override translations provided by SurveyJS with custom ones and insert additional translations, e.g. info texts
 * for SurveyJS translations see {@link https://github.com/surveyjs/survey-creator/tree/master/packages/survey-creator-core/src/localization | SurveyJS localization dictionaries}
 */
export const customTranslations = {
  //other displayed strings in the editor, e.g. tab titles
  ed: {
    ...editorStrings,
  },
  //displayed property titles in property grid and placeholders in designer surface
  pe: {
    ...propertyTitles,
    ...designerSurfaceStrings,
  },
  //info texts in property grid
  pehelp: {
    ...infoTexts,
  },
  //property values in property grid
  pv: {
    ...propertyValues,
  },
  //toolbox strings
  qt: {
    ...toolboxStrings,
  },
}
