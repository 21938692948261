import { Type } from '@angular/core';
import { InsuranceType } from '@lib/entities/Insurance.entity';
import { InstanceFormInterface, InstanceFormSettingsInterface } from '../instance-form/instance-form-component.interface';
import { I18NString } from './I18N.entity';


/**
 * InstanceForm
 */
export interface InstanceForm {
  readonly identifier?: string,
  readonly status?: InstanceFormStatus,
  readonly type?: InstanceFormType,
  readonly target?: InstanceFormTarget,
  readonly title: I18NString,
  readonly description?: string,
  readonly instructions?: string,
  readonly medicalSpecializations?: string[],
  readonly insuranceTypes?: InsuranceType[],
  readonly privatePayment: InstanceFormPrivatePayment,
  readonly customPaymentLabels?: {
    readonly [InsuranceType.GKV]: string,
    readonly [InsuranceType.PKV]: string,
    readonly [InsuranceType.SZ]: string,
    readonly hideSZHint: boolean,
    readonly hideSZForms: boolean,
  },
  readonly anamneseFormIdentifier?: string,
  anamneseFormSettings?: any,

  readonly personalFormIdentifier?: string,
  personalFormSettings?: InstanceFormPersonalSettings,

  readonly fileUploadSettings?: InstanceFormFileUploadSettings,
  readonly checksSettings?: InstanceFormChecksSettings,

  // Populated on client-side
  anamneseFormComponent?: Type<InstanceFormInterface>,
  anamneseFormSettingsComponent?: Type<InstanceFormSettingsInterface>,
  anamneseFormFieldTitles?: { [s: string]: string },

  personalFormComponent?: Type<InstanceFormInterface>,
  personalFormSettingsComponent?: Type<InstanceFormSettingsInterface>,
  personalFormFieldTitles?: { [s: string]: string },
}

export enum InstanceFormType {
  Video = 'video',
  Contact = 'contact',
  Termin = 'termin',
}

export enum InstanceFormTarget {
  Arzt = 'arzt',
  Patient = 'patient',
}

export enum InstanceFormStatus {
  Public = 'public',
  Invisible = 'invisible',
  Disabled = 'disabled',
  Custom = 'custom',
}


/**
 * Various Instance-Form Settings
 */

export interface InstanceFormPersonalSettings {
  readonly additionalFields: InstanceFormPersonalAdditionalFields[]
  readonly preferredArztMode?: InstanceFormPersonalPreferredArztMode
  readonly preferredArztSelection?: string[]

  readonly retrieveInsuranceData: InstanceFormRetrieveInsuranceDataMode

  readonly mustConfirmPresence: boolean
  readonly mustConfirmQuarterPresence: boolean
  readonly mustConfirmData: boolean

  readonly patientNameInsteadOfName: boolean

  // Populated on client-side
  readonly preSetPatientIsNew?: boolean,
}

export enum InstanceFormPersonalAdditionalFields {
  PreferredArzt = 'preferredArzt',
  Hausarzt = 'hausarzt',
  Beruf = 'beruf',
  Phone = 'phone',
}

export enum InstanceFormPersonalPreferredArztMode {
  All = 'all',
  AllOnline = 'all-online',
  Selection = 'selection',
  SelectionOnline = 'selection-online',
}

export enum InstanceFormRetrieveInsuranceDataMode {
  EnabledComplete = 'enabled-complete',
  EnabledNeupatientenComplete = 'enabled-neupatienten-complete',
  EnabledStatusOnly = 'enabled-status-only',
  Disabled = 'disabled',
}

export interface InstanceFormFileUploadSettings {
  readonly isEnabled?: boolean,
}

export interface InstanceFormChecksSettings {
  readonly authPhotoMode?: InstanceFormAuthPhotoMode
  readonly deviceTestsMode?: InstanceFormDeviceTestsMode
}

export enum InstanceFormAuthPhotoMode {
  EnabledForAll = 'enabled-all',
  EnabledForNeupatienten = 'enabled-neupatienten',
  Disabled = 'disabled',
}

export enum InstanceFormDeviceTestsMode {
  Required = 'required',
  Optional = 'optional',
  Disabled = 'disabled',
}


/**
 * Instance-Form Payment-Settings
 */

export interface InstanceFormPrivatePayment {
  readonly isRequired: boolean,
  readonly handler?: PrivatePaymentHandler,
  readonly items?: PrivatePaymentItem[],

  // Populated on client-side (depending on applicable items)
  price?: number,
}

export enum PrivatePaymentHandler {
  ArztDirekt = 'arzt-direkt',
  External = 'external',
  Unknown = 'unknown'
}

export interface PrivatePaymentItem {
  readonly type: PrivatePaymentItemType,
  readonly price: number,
  readonly factor: number,
  readonly justification?: string,
  isApplicable?: boolean,
}

export enum PrivatePaymentItemType {
  Videosprechstunde = 'videosprechstunde',  // Nicht GOÄ-konforme Sprechstundengebühren
  Technikzuschlag = 'technikzuschlag',      // Nicht GOÄ-konforme Zusatzgebühr

  Allgemein_1 = 'allgemein_1',              // Beratung
  Allgemein_3 = 'allgemein_3',              // Eingehende, das gewöhnliche Maß übersteigende Beratung (nur einzeln)
  Allgemein_5 = 'allgemein_5',              // Symptombezogene Untersuchung

  // Zuschlag_A = 'zuschlag_a',             // Außerhalb der Sprechstunde erbrachte Leistungen
  Zuschlag_B = 'zuschlag_b',                // 20-22 Uhr und 6-8 Uhr
  Zuschlag_C = 'zuschlag_c',                // 22-6 Uhr
  Zuschlag_D = 'zuschlag_d',                // Samstage, Sonntage, Feiertage
  Zuschlag_K1 = 'zuschlag_k1',              // Kinder unter 4 Jahre
}
