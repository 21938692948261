import { CoreLocale } from '@arzt-direkt/wfa-definitions'
import { SurveyModel, ValidateQuestionEvent } from 'survey-core'

import { defaultLocale } from '../../../default-locale'
import { settings } from '../../../file-upload-question-customization/settings'

export function validateUploadLimit(
  surveyModel: SurveyModel,
  options: ValidateQuestionEvent,
): void {
  if (options.question.getType() !== 'file') return
  const files = options.question.value
  if (!Array.isArray(files)) return
  const { limit } = settings
  if (files.length > limit) {
    const locale = surveyModel.locale as CoreLocale
    options.error = getLocalizedErrorMessage(locale, limit, files.length)
  }
}

function getLocalizedErrorMessage(
  locale: CoreLocale,
  limit: number,
  numberOfFiles: number,
): string {
  const errorMessageDict: Record<CoreLocale, string> = {
    de: `Sie können höchstens ${limit} Dateien an das Formular anhängen. Bitte entfernen Sie ${
      numberOfFiles - limit
    } Datei(en), um fortfahren zu können.`,
    en: `You can attach a maximum of ${limit} files to the form. Please remove ${
      numberOfFiles - limit
    } file(s) to continue.`,
    es: `Puede adjuntar un máximo de ${limit} archivos al formulario. Por favor, elimine ${
      numberOfFiles - limit
    } archivo(s) para continuar.`,
    fr: `Vous pouvez joindre au maximum ${limit} fichiers au formulaire. Veuillez supprimer ${
      numberOfFiles - limit
    } fichier(s) pour pouvoir continuer.`,
    it: `È possibile allegare al modulo un massimo di ${limit} file. Rimuovere i file ${
      numberOfFiles - limit
    } per continuare.`,
  }
  return errorMessageDict[locale] ?? errorMessageDict[defaultLocale]
}
