import { notNullish } from '@arzt-direkt/wfa-generic-utils'
import { Serializer } from 'survey-core'

import { allGridProperties } from './all-grid-properties'
import { getSwitchedOffProperties } from './get-switched-off-properties'

type SurveyElementName = string
type SurveyElementPropertyName = string

const PropertiesToHide = getSwitchedOffProperties(allGridProperties)

export function hideSwitchedOffProperties(): void {
  Object.entries(PropertiesToHide).forEach(([elementName, propertyNames]) => {
    hideSurveyElementProperties(elementName, propertyNames)
  })
}

function hideSurveyElementProperties(
  elementName: SurveyElementName,
  propertyNames: SurveyElementPropertyName[],
): void {
  propertyNames.forEach((propertyName) => {
    const property = Serializer.getProperty(elementName, propertyName)
    if (notNullish(property)) property.visible = false
  })
}
