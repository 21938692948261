<div class="dialog-content-wrapper">
  <h1 mat-dialog-title>
    <span>{{ 'LIMIT-DIALOG.TITLE' | translate }}</span>
    <button mat-icon-button mat-dialog-close>
      <fa-icon [icon]="faXmark"></fa-icon>
    </button>
  </h1>

  <div mat-dialog-content>
    <p>
      {{ 'LIMIT-DIALOG.CONTENT' | translate }}
    </p>
  </div>

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close class="btn-primary">
      {{ 'LIMIT-DIALOG.BUTTON' | translate }}
    </button>
  </div>
</div>
