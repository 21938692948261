import Joi from 'joi'

import { LocStringJoi } from './loc-string.joi'
import { WfaQuestionJoi } from './wfa-question/wfa-question.joi'

export const WfaPanelJoi = Joi.object()
  .keys({
    name: Joi.string().optional(),
    type: Joi.string().valid('panel'),
    description: LocStringJoi.optional(),
    title: LocStringJoi.optional(),
    visibleIf: Joi.boolean().optional(),
    elements: Joi.array().items(WfaQuestionJoi),
  })
  .meta({
    className: 'WfaPanel',
  })
