import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { InstanceService } from '../../instance/instance.service';
import { LogoChangeMessageService } from '../../misc/logo-change-message.service';
import { ColorService } from '../service/color.service';
import { LogoService } from '../service/logo.service';

@Component({
  selector: 'zs-logo',
  template: `
    <img class="logo" [class.white-background]="showWhiteBackground" [src]="logoService.logoUrl + randomString" >
  `,
  styleUrls: ['./logo.component.scss'],
  standalone: true
})
export class LogoComponent implements OnInit, OnChanges, OnDestroy {

  @Input('hideHeader') hideHeader: boolean
  @Input('showWhiteBackground') showWhiteBackground: boolean = false
  private subscription: Subscription
  public randomString: string = ""

  constructor(
    private cd: ChangeDetectorRef,
    private instanceService: InstanceService,
    private logger: NGXLogger,
    private logoChangeMessageService: LogoChangeMessageService,
    public colorService: ColorService,
    public logoService: LogoService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.initLogos(window.innerWidth)
    this.subscription = this.logoChangeMessageService.currentMessage.subscribe((msg) => {
      if (msg === "logo") {
        this.logger.log("Reload Logo in Header")
        this.randomString = "?random=" + Math.random().toString()
        this.cd.detectChanges()
        this.logoService.currentRectangleLogoRandomString = this.randomString
      }
    })
  }

  ngOnChanges() {
    this.initLogos(window.innerWidth)

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initLogos(windowWidth: number) {
    const isDemoRoute = this.instanceService.activeInstance?._id === 'demo'
    const instanceId = (!isDemoRoute) ? this.instanceService.activeInstance?._id : null
    this.logoService.initLogos(instanceId, windowWidth);
    if (this.logoService.currentRectangleLogoRandomString) {
      this.randomString = this.logoService.currentRectangleLogoRandomString;
      this.cd.detectChanges()
    }
  }



}
