/**
 * Generates a valid MongoDB ObjectId.
 *
 * This function can be used in Angular Frontend for
 * ahead-of-type id generation, without the need to
 * importing anything from mongoose.
 *
 * @returns A string representation of a MongoDB ObjectId
 */
export function generateObjectId(date: Date = new Date()): string {
  const timestamp = Math.floor(date.valueOf() / 1000).toString(16)
  const machineId = Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')
  const processId = Math.floor(Math.random() * 65535)
    .toString(16)
    .padStart(4, '0')
  const counter = Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')

  return timestamp + machineId + processId + counter
}
