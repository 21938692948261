<div class="overlay-button">
  <button (click)="showOverlay = !showOverlay">Settings</button>
  <button (click)="testLogger()">Test Logger</button>
</div>

<div class="overlay" *ngIf="showOverlay">
  <div>
    <h1>Testing AD Form Viewer</h1>
  </div>

  <button style="margin: 0 auto 20px auto" (click)="magicFill()">
    Magic Fill
  </button>

  <div>
    <label> Locale: </label>
    <select [formControl]="localeControl">
      <option *ngFor="let locale of locales" [ngValue]="locale">
        {{ locale }}
      </option>
    </select>
  </div>

  <div style="margin-top: 0.5em">
    <label> JSON: </label>
    <select [formControl]="jsonControl">
      <option *ngFor="let json of jsons | keyvalue" [ngValue]="json.key">
        {{ json.key }}
      </option>
    </select>
  </div>

  <div style="margin-top: 0.5em">
    <label> Display option: </label>
    <select [formControl]="displayOptionControl">
      <option *ngFor="let displayOption of displayOptions">
        {{ displayOption }}
      </option>
    </select>
  </div>

  <div style="margin-top: 0.5em; margin-bottom: 2em">
    <div>
      <label> PraxisId: </label>
      <input
        [(ngModel)]="praxisId"
        (ngModelChange)="updateFormResponseSettings()"
      />
    </div>

    <div>
      <label> ResponseId: </label>
      <input
        [(ngModel)]="responseId"
        (ngModelChange)="updateFormResponseSettings()"
      />
    </div>

    <div>
      <label> WfaFormId: </label>
      <input
        [(ngModel)]="wfaFormId"
        (ngModelChange)="updateFormResponseSettings()"
      />
    </div>

    <div>
      <label> WfaFormVersionId: </label>
      <input
        [(ngModel)]="wfaFormVersionId"
        (ngModelChange)="updateFormResponseSettings()"
      />
    </div>
  </div>
</div>

<ng-container *ngIf="status === 'survey'">
  <wfa-viewer
    [form$]="form$"
    [envState$]="envState$"
    [formResponse$]="formResponse$"
    (formResponseDataEmitter)="emitFormResponseData$.next($event)"
  >
  </wfa-viewer>
</ng-container>

<ng-container *ngIf="status === 'submitting'">
  <wfa-user-message [surveyjsJson]="submittingJson"> </wfa-user-message>
</ng-container>

<ng-container *ngIf="status === 'submitted'">
  <wfa-user-message [surveyjsJson]="submittedJson"> </wfa-user-message>
</ng-container>
