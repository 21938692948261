/**
 * Default configuration object for the application.
 */
const coreEnv = {
  indesMongoUrl: 'mongodb://0.0.0.0:27017/indes',
  indesMongoTestUrl: 'mongodb://0.0.0.0:27017/wfa-test-ts',
  indesWfaUrl: 'http://localhost:4200',
  indesWfaUrlSubdirectory: '',
  indesWfbPort: 8082,
  indesWfbHost: 'localhost',
  indesWfbUrlSubdirectory: '',
  lotosWfaUrl: 'http://localhost:8080',
  lotosWfaUrlSubdirectory: '/tomedo_live/static/wfa-lotos/index.html#',
  lotosWfbHost: 'https://onlinetermine.test-arzt-direkt.zollsoft.net',
  lotosWfbUrlSubdirectory: '/api/wfb/app',
  testUploadsDir: 'shared/wfa-tests/temp-test-output/test-uploads',
  vitestUrl: 'http://localhost:3000',
}

export const env = {
  ...coreEnv,
  indesWfbUrl: `http://${coreEnv.indesWfbHost}:${coreEnv.indesWfbPort}`,
}

export type Env = typeof env
