// surveyjs's `name` attribute, used for expressions
const name = {
  de: [
    'Dieser Name ist für die Patienten unsichtbar.\n\n',
    'Sie können diesen Namen nutzen, um Ausdrücke dynamisch zu berechnen. ',
    'Zum Beispiel, falls Sie zwei Text-Fragen mit Namen "`firstName`" und "`lastName`" haben,',
    'können Sie in einem HTML-Element\n',
    '"""`<p>Hallo, {firstName} {lastName}!</p>`"""\n',
    'schreiben. Die Variablen werden automatisch mit den Patienteneingaben ersetzt.\n\n',
    'Der Name kann nur dann verändert werden, wenn tomedo® Rückschrieb Wert nicht gesetzt ist.',
  ].join(''),
  en: 'This name is invisible to patients. You can use this name to dynamically calculate expressions. For example, if you have two text questions named "firstName" and "lastName", you can write in an HTML element """`<p>Hello, {firstName} {lastName}!</p>`""". The variables will automatically be replaced with patient inputs. The name can only be changed if the tomedo® writeback value is not set.',
  es: 'Este nombre es invisible para los pacientes. Puede usar este nombre para calcular expresiones dinámicamente. Por ejemplo, si tiene dos preguntas de texto llamadas "firstName" y "lastName", puede escribir en un elemento HTML """`<p>Hola, {firstName} {lastName}!</p>`""". Las variables se reemplazarán automáticamente con las entradas del paciente. El nombre solo se puede cambiar si el valor de escritura de tomedo® no está configurado.',
  fr: 'Ce nom est invisible pour les patients. Vous pouvez utiliser ce nom pour calculer dynamiquement des expressions. Par exemple, si vous avez deux questions textuelles nommées "firstName" et "lastName", vous pouvez écrire dans un élément HTML """`<p>Bonjour, {firstName} {lastName}!</p>`""". Les variables seront automatiquement remplacées par les entrées du patient. Le nom ne peut être modifié que si la valeur de réécriture tomedo® n\'est pas définie.',
  it: 'Questo nome è invisibile ai pazienti. Puoi utilizzare questo nome per calcolare dinamicamente le espressioni. Ad esempio, se hai due domande di testo chiamate "firstName" e "lastName", puoi scrivere in un elemento HTML """`<p>Ciao, {firstName} {lastName}!</p>`""". Le variabili verranno automaticamente sostituite con gli input del paziente. Il nome può essere modificato solo se il valore di riscrittura di tomedo® non è impostato.',
}

export const infoTexts = {
  name,
  choices: {
    de: 'Die hier eingegebenen Werte werden den Befragten als auswählbare Optionen im Formular angezeigt. Diese Optionen können in bedingten Regeln verwendet werden, um die Formularlogik zu steuern.',
    en: 'The values entered here will be displayed to respondents as selectable options in the form. These options can be used in conditional rules to control form logic.',
    es: 'Los valores introducidos aquí se mostrarán a los encuestados como opciones seleccionables en el formulario. Estas opciones pueden utilizarse en reglas condicionales para controlar la lógica del formulario.',
    fr: `Les valeurs saisies ici seront affichées aux répondants sous forme d'options sélectionnables dans le formulaire.Ces options peuvent être utilisées dans des règles conditionnelles pour contrôler la logique du formulaire.`,
    it: 'I valori inseriti in questo punto saranno visualizzati dai rispondenti come opzioni selezionabili nel modulo. Queste opzioni possono essere utilizzate nelle regole condizionali per controllare la logica del modulo.',
  },
  imageFit: {
    de: '"/" - das Bild behält seine ursprüngliche Größe.\n "Anpassen" - die Größe des Bildes wird angepasst, wobei das Seitenverhältnis beibehalten wird.\n "Abdecken" - das Bild füllt den gesamten Rahmen aus, während das Seitenverhältnis beibehalten wird.\n "Ausfüllen" - Das Bild wird gestreckt, um den Rahmen auszufüllen, ohne das Seitenverhältnis beizubehalten.',
    en: '"/" - image maintains its original size.\n "Contain" - image is resized to fit while maintaining its aspect ratio.\n "Cover" - image fills the entire box while maintaining its aspect ratio.\n "Fill" - image is stretched to fill the box without maintaining its aspect ratio."',
    es: '"/": la imagen mantiene su tamaño original.\n "Contener": se cambia el tamaño de la imagen para que se ajuste manteniendo su relación de aspecto.\n "Cubrir": la imagen llena toda la caja manteniendo su relación de aspecto.\n "Llenar": la imagen se estira para llenar el cuadro sin mantener su relación de aspecto.',
    fr: '« / » - l’image conserve sa taille d’origine.\n « Contenir » - l’image est redimensionnée pour s’adapter tout en conservant son rapport hauteur/largeur.\n « Couvrir » - l’image remplit toute la boîte tout en conservant son rapport hauteur/largeur.\n « Remplir » - l’image est étirée pour remplir la boîte sans conserver son rapport hauteur/largeur.',
    it: `"/" - l'immagine mantiene le sue dimensioni originali.\n "Contenere": l'immagine viene ridimensionata per adattarla mantenendo le sue proporzioni.\n "Coprire": l'immagine riempie l'intera scatola mantenendo le sue proporzioni.\n "Riempire" - l'immagine viene allungata per riempire la casella senza mantenerne le proporzioni.`,
  },
  logoFit: {
    de: '"/" - das Bild behält seine ursprüngliche Größe.\n "Anpassen" - die Größe des Bildes wird angepasst, wobei das Seitenverhältnis beibehalten wird.\n "Abdecken" - das Bild füllt den gesamten Rahmen aus, während das Seitenverhältnis beibehalten wird.\n "Ausfüllen" - Das Bild wird gestreckt, um den Rahmen auszufüllen, ohne das Seitenverhältnis beizubehalten.',
    en: '"/" - image maintains its original size.\n "Contain" - image is resized to fit while maintaining its aspect ratio.\n "Cover" - image fills the entire box while maintaining its aspect ratio.\n "Fill" - image is stretched to fill the box without maintaining its aspect ratio."',
    es: '"/": la imagen mantiene su tamaño original.\n "Contener": se cambia el tamaño de la imagen para que se ajuste manteniendo su relación de aspecto.\n "Cubrir": la imagen llena toda la caja manteniendo su relación de aspecto.\n "Llenar": la imagen se estira para llenar el cuadro sin mantener su relación de aspecto.',
    fr: '« / » - l’image conserve sa taille d’origine.\n « Contenir » - l’image est redimensionnée pour s’adapter tout en conservant son rapport hauteur/largeur.\n « Couvrir » - l’image remplit toute la boîte tout en conservant son rapport hauteur/largeur.\n « Remplir » - l’image est étirée pour remplir la boîte sans conserver son rapport hauteur/largeur.',
    it: `"/" - l'immagine mantiene le sue dimensioni originali.\n "Contenere": l'immagine viene ridimensionata per adattarla mantenendo le sue proporzioni.\n "Coprire": l'immagine riempie l'intera scatola mantenendo le sue proporzioni.\n "Riempire" - l'immagine viene allungata per riempire la casella senza mantenerne le proporzioni.`,
  },
  shortTitle: {
    de: 'Für statistische Auswertung und Briefkommandos in tomedo®.',
    en: 'For statistical evaluation and letter commands in tomedo®.',
    es: 'Para análisis estadísticos y comandos de letras en tomedo®.',
    fr: `Pour l'analyse statistique et les commandes de lettres dans tomedo®.`,
    it: 'Per le analisi statistiche e i comandi delle lettere in tomedo®.',
  },
  wfaKey: {
    de: 'Rückschrieb des Feldes in die Patientenakte in tomedo®.',
    en: 'Write the field back into the patient file in tomedo®.',
    es: 'Introducción del campo en la ficha del paciente en tomedo®.',
    fr: 'Inscription du champ dans le dossier du patient dans tomedo®.',
    it: 'Inserimento del campo nella cartella clinica del paziente in tomedo®.',
  },
  question: {
    name,
  },
}
