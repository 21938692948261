/**
 * Creates an array with n repetitions of the given string.
 *
 * @param s The string to repeat.
 * @param n The number of times to repeat the string.
 * @returns An array containing n entries of the string s.
 *
 * @example
 * repeat("hello", 3) // returns ["hello", "hello", "hello"]
 * repeat("a", 5)     // returns ["a", "a", "a", "a", "a"]
 * repeat("", 2)      // returns ["", ""]
 * repeat("x", 0)     // returns []
 */
export function repeat(s: string, n: number): string[] {
  return Array(n).fill(s)
}

export function ensureTrailingSlash(str: string): string {
  if (str.endsWith('/')) {
    return str
  }
  return str + '/'
}

export function removeTrailingSlash(str: string): string {
  if (str.endsWith('/')) {
    return str.slice(0, -1)
  }
  return str
}

export function trimSlashes(str: string): string {
  // Remove leading slashes
  str = str.replace(/^\/+/, '')
  // Remove trailing slashes
  str = str.replace(/\/+$/, '')
  return str
}
