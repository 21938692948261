import { notNullish } from './../nullish'

export function hasStringKeys(x: unknown, keys: string[]): boolean {
  return (
    typeof x === 'object' &&
    notNullish(x) &&
    keys.every(
      (key) =>
        key in x && typeof (x as Record<string, unknown>)[key] === 'string',
    )
  )
}
