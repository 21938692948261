import { Route } from '@angular/router'

import { PermalinkViewerComponent } from '../adis/permalink-viewer/permalink-viewer.component'
import { PermalinkViewerResolver } from '../adis/permalink-viewer/permalink-viewer.resolver'
import { ViewerFromLinkComponent } from '../adis/viewer-from-link/viewer-from-link.component'
import { ViewerFromLinkResolver } from '../adis/viewer-from-link/viewer-from-link.resolver'
import { ViewerFromLinkErrorComponent } from '../adis/viewer-from-link-error/viewer-from-link-error.component'
import {
  aboutRoute,
  adisEditorRoute,
  fallbackRoute,
  supportEditorRoute,
  userMessageRoute,
} from './shared.routes'

const permalinkRoute: Route = {
  path: 'permalink/:instanceIdentifier/:wfaFormId',
  title: 'Patientenformulare | Online ausfüllen',
  component: PermalinkViewerComponent,
  resolve: {
    routeResolver: PermalinkViewerResolver,
  },
}

const viewerFromLinkRoute: Route = {
  path: 'viewer-from-link/:responseId',
  title: 'Patientenformulare | Online ausfüllen',
  component: ViewerFromLinkComponent,
  resolve: {
    routeResolver: ViewerFromLinkResolver,
  },
}

const viewerFromLinkErrorRoute = {
  path: 'viewer-from-link-error',
  title: 'Patientenformulare | Fehler',
  component: ViewerFromLinkErrorComponent,
}

export const adisRoutes: Route[] = [
  aboutRoute,
  viewerFromLinkRoute,
  viewerFromLinkErrorRoute,
  permalinkRoute,
  userMessageRoute,
  fallbackRoute,
]

// routes that are visible in adis for instances (require log in)
export const adisInstanceRoutes: Route[] = [
  adisEditorRoute,
  supportEditorRoute,
  fallbackRoute,
]

export const adisInstanceSettingsRoutes: Route[] = [adisEditorRoute]
