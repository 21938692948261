import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AsyncPipe, NgFor } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface ChipOption {
  readonly id: string,
  readonly name: string,
  selected: boolean,
}

@Component({
  selector: 'app-chip-list-autocomplete-field-component',
  host: { 'class': 'c-form__row' },
  template: `
    <mat-form-field [formGroup]="formGroup" class="c-form__chip">
      <mat-chip-grid #chipsList>
        <mat-chip-row *ngFor="let s of selectedChipsList" [removable]="true"
          (removed)="remove(s)">
          {{s}}
          <fa-icon matChipRemove [icon]="['fas', 'times-circle']"></fa-icon>
        </mat-chip-row>
        <input [placeholder]="placeholder" #chipSearchInput [formControl]="chipSearch"
          [matAutocomplete]="auto" [matChipInputFor]="chipsList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="processEvent($event)">
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event)">
        <mat-option *ngFor="let name of filteredChipsList | async" [value]="name">
            {{name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `,
  standalone: true,
  imports: [MatFormFieldModule, FormsModule, ReactiveFormsModule, MatChipsModule, NgFor, FontAwesomeModule, MatAutocompleteModule, MatOptionModule, AsyncPipe]
})
export class ChipListAutocompleteFieldComponent implements OnInit {

  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input() formGroup: UntypedFormGroup
  @Input() formAttributeName: string
  @Input() placeholder: string
  @Input() required: boolean
  @Input() defaultValueIds: ChipOption['id'][]
  @Input() rePopulateOnChange: boolean
  @Input() allChipsList: string[]

  @ViewChild('chipSearchInput') chipSearchInput: ElementRef<HTMLInputElement>
  @ViewChild('auto') matAutocomplete: MatAutocomplete

  public get formAttribute(): AbstractControl { return this.formGroup.get(this.formAttributeName) }
  public chipSearch = new UntypedFormControl()
  public filteredChipsList: Observable<string[]>
  public selectedChipsList: string[] = []

  constructor() {
    this.filteredChipsList = this.chipSearch.valueChanges.pipe(
      startWith(0),
      map((c: string | null) => (c && typeof c === 'string') ?
        this.allChipsList.filter(chip => chip.toLowerCase().indexOf(c.toLowerCase()) === 0) : this.allChipsList
      ))
  }

  ngOnInit(): void {
    // Set validators
    this.formAttribute.setValidators([
      ...(this.required ? [Validators.required] : []),
    ])
    this.selectedChipsList = (this.formAttribute.value) ? this.formAttribute.value : [];
  }

  public processEvent(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    this.chipSearch.setValue(null)
    event.chipInput.clear();
    if (value) {
      this.add(value)
    }
  }

  private add(chipName: string): void {
    if (this.isNotDuplicate(chipName)) {
      this.selectedChipsList.push(chipName)
      this.updateFormValue()
    }
  }

  public remove(chip: string): void {
    const index = this.selectedChipsList.indexOf(chip);
    if (index >= 0) {
      this.selectedChipsList.splice(index, 1)
      this.updateFormValue()
      this.formAttribute.markAsDirty()
    }
  }

  public select(event: MatAutocompleteSelectedEvent): void {
    const newValue = event.option.value
    if (this.isNotDuplicate(newValue)) {
      // add to selection
      this.selectedChipsList.push(newValue)
      this.updateFormValue()
      this.formAttribute.markAsDirty()
    } else {
      // remove from selection
      this.remove(newValue)
    }
    this.chipSearchInput.nativeElement.value = ''
    this.chipSearch.setValue(null)
  }

  private isNotDuplicate(entry: string): boolean {
    return !this.selectedChipsList.includes(entry);
  }

  private updateFormValue() {
    const newValue = []
    this.selectedChipsList.forEach(s => newValue.push(s))
    this.formAttribute.setValue(newValue)
  }

}
