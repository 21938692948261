import { WfaForm } from '../../interfaces-autogen-from-joi/wfa-form'
import { anamnese } from './anamnese/_anamnese.barrel'
import { augenzentrumHeppenheim } from './augenzentrum-heppenheim'
import { hautpraxisJena } from './hautpraxis-jena'
import { hnoMedicUnkelbach } from './hno-medic-unkelbach'
import { hnoMedicum } from './hno-medicum'
import { jenaPraxisklinik } from './jena-praxisklinik'
import { kinderarztpraxisHerzenssache } from './kinderarztpraxis-herzenssache'
import { mvzKaiser } from './mvz-kaiser'
import { testing } from './testing'
import { zollsoftForms } from './zollsoft/_zollsoft-forms.barrel'
import { zollsoftKutoForms } from './zollsoft-kuto'

export const json = {
  anamnese,
  augenzentrumHeppenheim,
  hautpraxisJena,
  hnoMedicum,
  hnoMedicUnkelbach,
  jenaPraxisklinik,
  kinderarztpraxisHerzenssache,
  mvzKaiser,
  testing,
  zollsoftForms,
  zollsoftKutoForms,
}

export const allStaticJsonForms: WfaForm[] = [
  ...Object.values(anamnese),
  ...Object.values(hautpraxisJena),
  ...Object.values(hnoMedicum),
  ...Object.values(hnoMedicUnkelbach),
  ...Object.values(jenaPraxisklinik),
  ...Object.values(kinderarztpraxisHerzenssache),
  ...Object.values(augenzentrumHeppenheim),
  ...Object.values(mvzKaiser),
  ...Object.values(testing),
  ...Object.values(zollsoftForms),
  ...Object.values(zollsoftKutoForms),
]

export const templateForms: WfaForm[] = [
  json.zollsoftForms.standardAnamnese02,
  json.zollsoftForms.standardDatenschutzformular02,
  json.zollsoftForms.standardKontaktdaten02,
]
