import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { LanguageService } from '@a-d/i18n/language.service';
import { JsonPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { State, STATES_DB } from '../state-field-i18n';

@UntilDestroy()
@Component({
  selector: 'app-state-field',
  standalone: true,
  imports: [
    JsonPipe,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatInputModule,
    NgFor,
    MatOptionModule,
    TranslateModule],
  templateUrl: './state-field.component.html',
  styleUrls: ['./state-field.component.scss']
})
export class StateFieldComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup
  @Input() name = 'stateOrProvince'
  @Input() allowedCountryCodes = ['DE', 'CH', 'AT']
  public translatedStates: State[] = []
  public filteredStates: State[] = []
  public currentCountry = ''
  public subDivisionName = 'Bundesland'

  get country(): AbstractControl { return this.formGroup.get('country'); }
  get state(): AbstractControl { return this.formGroup.get(this.name); }

  constructor(
    private adLoggerService: AdLoggerService,
    private translate: TranslateService,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.languageService.languageChangeSubject$.pipe(
      untilDestroyed(this)
    ).subscribe({
      next: () => this.updateStateTranslation(),
      error: (error) => this.adLoggerService.error(error)
    })

    if (this.country.value) this.filterStates(this.country.value);
    this.country.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((country: string) => {
      this.filterStates(country);
      this.state.reset()
    })
  }

  public updateStateTranslation() {
    this.translatedStates = STATES_DB.map((state) => {
      const translatedState = Object.assign({}, state);
      translatedState.name = this.translate.instant(state.name)
      return translatedState;
    });
    this.filterStates(this.currentCountry)
  }

  public filterStates(country: string) {
    if (!country) {
      this.filteredStates = this.translatedStates;
      return;
    }
    if (country !== this.currentCountry) {
      this.filteredStates = this.translatedStates.filter((state) => state.countryCode === country);
      this.currentCountry = country;
      if (this.allowedCountryCodes.includes(country)) {
        this.subDivisionName = this.translate.instant(`ADDRESS-SUBDIVISION-NAME.${country}`)
      }
    }
    return;
  }
}
