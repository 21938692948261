@if (zsSupportService.praxisDetails$.value !== undefined) {
  <mat-card *rxLet="zsSupportService.formInfos$; let formInfos">
    <mat-card-title data-selenium-id="text-@form-table/mat-card-title">{{
      "WFA.FORM-TABLE.TITLE" | translate
    }}</mat-card-title>
    <mat-card-content>
      <table
        mat-table
        [dataSource]="formInfos"
        [fixedLayout]="true"
        class="mat-elevation-z8"
        data-selenium-id="table-@form-table/table"
      >
        <!-- Identifier Column -->
        <ng-container matColumnDef="identifier" [style.flex]="'0 0 50%'">
          <th mat-header-cell *matHeaderCellDef>
            {{ "WFA.FORM-TABLE.COLUMNS.IDENTIFIER" | translate }}
          </th>
          <td mat-cell *matCellDef="let wfi">
            {{ wfi.wfaFormId }}
            <br />
            <span class="text-gray-500 text-sm">{{ wfi.title | i18n }}</span>
            <br />
            <a
              *ngIf="wfi.permalink"
              [href]="wfi.permalink"
              target="_blank"
              rel="noopener noreferrer"
              class="text-gray-500 text-sm"
            >
              {{ wfi.permalink }}
            </a>
          </td>
        </ng-container>

        <!-- Version Column -->
        <ng-container matColumnDef="version" [style.flex]="'0 0 25%'">
          <th mat-header-cell *matHeaderCellDef>
            {{ "WFA.FORM-TABLE.COLUMNS.VERSION" | translate }}
          </th>
          <td mat-cell *matCellDef="let wfi">
            {{ last(wfi.formVersion.split("-")) ?? "??" }} ({{
              mongoIdToLocaleString(wfi.wfaFormVersionId)
            }})
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions" [style.flex]="'0 0 25%'">
          <th mat-header-cell *matHeaderCellDef class="text-right"></th>
          <td mat-cell *matCellDef="let wfi" class="text-right">
            <app-action-buttons
              [showPublish]="false"
              [showPermalink]="true"
              [permalink]="wfi.permalink"
              (onCopy)="
                copyForm(wfi.wfaFormVersionId, wfi.wfaFormId, wfi.praxisId)
              "
              (onEdit)="openDraftEditor(wfi.wfaFormVersionId)"
              (onDelete)="changeStatus(wfi.praxisId, 'disabled', wfi.wfaFormId)"
              (onPermalink)="changePermalinkState(wfi.praxisId, wfi.wfaFormId)"
            ></app-action-buttons>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
          class="font-bold border-b"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </mat-card-content>
  </mat-card>
}
