<div class="multilink-viewer" *rxLet="store.fwr$; let selected">
  <ng-container *rxLet="store.fwrs$; let fwrs">
    @if (fwrs?.length > 1) {
      <wfa-scrollable-sidebar
        class="multilink-viewer__sidebar"
      ></wfa-scrollable-sidebar>
    }
  </ng-container>

  <div class="multilink-viewer__main-content">
    @if (selected) {
      <wfa-viewer-from-link></wfa-viewer-from-link>
    } @else {
      <div *ngIf="!selected" class="fade-in-delayed">
        {{ 'WFA.MULTILINK-VIEWER.NO-FORM-SELECTED' | translate }}
      </div>
    }
  </div>
</div>
