import Joi from 'joi'

export const hslaAttributes = [
  'primaryColor',

  'primaryBackColor',
  'secondaryBackColor',

  'primaryCardColor',
  'secondaryCardColor',

  'primaryTextColor',
  'secondaryTextColor',

  'primaryTextOnColor',
  'secondaryTextOnColor',

  'primaryLineColor',
  'secondaryLineColor',

  'shadowColor',

  'buttonColor',
  'buttonTextColor',
  'buttonTextOnColor',

  'errorColor',
  'successColor',
  'warningColor',
  'infoColor',

  'backgroundColorTertiary',
] as const

export const HslaAttributesJoi = Joi.string()
  .valid(...hslaAttributes)
  .meta({
    className: 'HslaAttributes',
  })
  .example('primaryColor')
