import { PraxisGeodata } from '@a-d/entities/Praxis.entity';
import { AfterViewInit, Component, ContentChild, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from '@env/environment';
import Leaflet from 'leaflet';
import { StreetmapOverlayComponent } from './streetmap-overlay/streetmap-overlay.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-streetmap',
  templateUrl: './streetmap.component.html',
  styleUrls: ['./streetmap.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule]
})
export class StreetmapComponent implements AfterViewInit, OnInit, OnDestroy {

  constructor(private dialog: MatDialog, private _elementRef: ElementRef<HTMLElement>) { }

  @Input() geodata: PraxisGeodata = { lon: 0, lat: 0 }; // geodata of central position
  @Input() data: any;  // data required for display of overlay-header (if so)
  @Input() overlayWidthPercent: number = 0.9;  // width of overlay in (0,1) as percentage of window-width (standard 0.9)
  @Input() overlayHeightPercent: number = 0.9; // height of overlay in (0,1) as percentage of window-height (standard 0.9)
  @Input() pxwidth: number = null
  @Input() pxheight: number = null
  @ViewChild('mapContainer') mapContainer: ElementRef

  map: Leaflet.Map;
  marker: Leaflet.Marker;
  _height: string;
  _width: string;
  @ContentChild('header') header: TemplateRef<any> | undefined; // templateRef of overlay-header (if so) - not being rendered here but passed to overlay

  openMapDialog() {
    const overlayWidth = this.overlayWidthPercent * window.innerWidth;
    const overlayHeight = this.overlayHeightPercent * window.innerHeight;
    const config: MatDialogConfig = {
      width: overlayWidth + "px",
      height: overlayHeight + "px",
      data: { geodata: this.geodata, header: this.header, data: this.data },
      disableClose: false,
      panelClass: "streetmap-overlay"
    };
    this.dialog.open(StreetmapOverlayComponent, config);
  }

  ngOnInit(): void {
    // totally fill parent element with map or preset in case parentelement is not drawn
    if (this.pxwidth && this.pxheight) {
      this._height = this.pxheight + "px";
      this._width = this.pxwidth + "px";
    } else {
      this._height = this._elementRef.nativeElement.parentElement.clientHeight + "px";
      this._width = this._elementRef.nativeElement.parentElement.clientWidth + "px";
    }
  }


  ngAfterViewInit(): void {
    setTimeout(() => {

      const options: Leaflet.MapOptions = {
        zoom: 17,
        center: Leaflet.latLng(this.geodata.lat, this.geodata.lon),
        zoomControl: false,
        attributionControl: false,
        scrollWheelZoom: false,
        dragging: false
      };

      this.map = Leaflet.map(this.mapContainer.nativeElement, options);
      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        maxZoom: 17,
        minZoom: 17,
      }).addTo(this.map);

      this.marker = Leaflet.marker([this.geodata.lat, this.geodata.lon], {
        icon: Leaflet.icon({
          iconSize: [25, 41],
          iconAnchor: [10, 30],
          iconUrl: `${environment.url}/assets/images/leaflet/marker-icon.png`,
          shadowUrl: `${environment.url}/assets/images/leaflet/marker-shadow.png`,
          popupAnchor: [12.5, 0],
        }),
      }).addTo(this.map);


      // add a pane that is between the markerPane (z-index 600) and the
      // tooltipPane (z-index 650) to grey out the markers as well
      // see: https://leafletjs.com/reference.html#map
      this.map.createPane('greyOutPane');
      this.map.getPane('greyOutPane').style.zIndex = '625';
      this.map.getPane('greyOutPane').style.pointerEvents = 'none';

      const mapBounds = this.map.getBounds();
      Leaflet.rectangle(mapBounds, {
        pane: 'greyOutPane',
        color: '#ededed',
        fillOpacity: 0.5,
        stroke: false,
        interactive: false
      }).addTo(this.map);

    }, 0)
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.off();
      this.map.remove();
    }
  }

  repaintMap(): void {
    this.map.invalidateSize()
  }

  resetCoordinates(): void {
    this.map.panTo(Leaflet.latLng(this.geodata.lat, this.geodata.lon))
    this.marker.setLatLng(Leaflet.latLng(this.geodata.lat, this.geodata.lon))
  }

  recalcsize() {
    this._height = this._elementRef.nativeElement.parentElement.clientHeight + "px";
    this._width = this._elementRef.nativeElement.parentElement.clientWidth + "px";
  }

}
