<form class="c-form c-form--fullHeight" [formGroup]="formGroup">

  <mat-form-field appearance="outline">
    <mat-label>Art des Hautproblems festlegen</mat-label>
    <mat-select formControlName="preSelectIsLesion">
      <mat-option><em>Nicht festlegen</em></mat-option>
      <mat-option value="Leberfleck">Leberfleck</mat-option>
      <mat-option value="Anderes Hautproblem">Anderes Hautproblem</mat-option>
    </mat-select>
  </mat-form-field>

  <app-chip-list-field-component *ngIf="preSelectIsLesion.value !== 'Leberfleck'" [formGroup]="formGroup"
    name="hautproblemOptions" placeholder="Auswahlmöglichkeiten Hautbeschwerden *" [required]="true">
  </app-chip-list-field-component>

</form>