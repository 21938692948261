import Joi from 'joi'

export const wfaInputTypeDict = {
  number: 'number',
} as const
export const wfaInputTypes = Object.values(wfaInputTypeDict)

export const WfaInputTypeJoi = Joi.string()
  .valid(...wfaInputTypes)
  .meta({
    className: 'WfaInputType',
  })
  .example('number')
