import 'survey-creator-core/survey-creator-core.i18n'

import { MatDialog } from '@angular/material/dialog'
import { SurveyCreatorModel } from 'survey-creator-core'

import { addCustomQuestionTypes } from '../shared-surveyjs/question-type-customization/add-custom-question-types'
import { combineCreatorOptions } from './customization/combine-creator-options'
import { customize } from './customization/customize'
import { addCustomIcons } from './customization/toolbox-customization/customize-icons/add-custom-icons'
import { EditorDataService } from './editor.data-service'
import { EditorStore } from './editor.store'
import { Routing } from './interfaces'
import { saveToStoreAndDb } from './save-to-store-and-db'
import { SetCreatorParams } from './set-creator-params'

export function createCreator(
  editorStore: EditorStore,
  scp: SetCreatorParams,
  dialog: MatDialog,
  editorDataService: EditorDataService,
  routing: Routing,
) {
  addCustomQuestionTypes()
  addCustomIcons()

  const licenseTier = scp?.editorEnv?.licenseTier ?? 'basic'
  const options = combineCreatorOptions(licenseTier)

  const creator = new SurveyCreatorModel(options)
  creator.JSON = scp?.json

  creator.saveSurveyFunc = saveToStoreAndDb(
    editorStore,
    creator,
    editorDataService,
    routing.wfaEnvService,
  )

  customize(creator, dialog, routing)
  creator.showSaveButton = true

  return creator
}
