/**
 * Represents an object with string keys and any value type.
 */
type AnyObject = Record<string, unknown>

/**
 * Removes specified keys from an object and returns a new object without those keys.
 *
 * @template T - The type of the input object, constrained to AnyObject.
 * @param {T} obj - The input object from which keys will be removed.
 * @param {(keyof T)[]} keysToRemove - An array of keys to remove from the object.
 * @returns {Partial<T>} A new object with the specified keys removed.
 *
 * @example
 * const obj = { a: 1, b: 'two', c: true };
 * const result = removeKeys(obj, ['b']);
 * // result is { a: 1, c: true }
 */
export function removeKeys<T extends AnyObject>(
  obj: T,
  keysToRemove: (keyof T)[],
): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key]) => !keysToRemove.includes(key as keyof T),
    ),
  ) as Partial<T>
}
