<lib-dialog [isLoading]="!camera" #dialog>

  <div mat-dialog-title>
    <h1>
      {{'ASSET-UPLOAD-FIELD.TAKE-PHOTO' | translate}}
    </h1>
    <ng-template [ngTemplateOutlet]="dialog.closeButtonRef"></ng-template>
  </div>

  <div mat-dialog-content style="display: flex; flex-direction: column;">
    <app-camera-snapshot (snapshotChange)="photoDataUrl = $event" [snapshot]=photoDataUrl #camera>
    </app-camera-snapshot>
  </div>

  <div mat-dialog-actions>
    <div *ngIf="photoDataUrl">
      <button attr.data-selenium-id="button-erneut-aufnehmen" mat-flat-button
        (click)="photoDataUrl = null; camera.deleteSnapshot()">
        <mat-icon>photo_camera</mat-icon>
        <span> {{'GLOBAL.TAKE-PHOTO-AGAIN' | translate}}</span>
      </button>
      <small class="hide--onSmall">{{'ASSET-UPLOAD-FIELD.PRESS-SPACE' | translate}}</small>
    </div>
    <div *ngIf="photoDataUrl">
      <button attr.data-selenium-id="button-fertig" mat-flat-button color="primary" [mat-dialog-close]="photoDataUrl">
        <span>{{'GLOBAL.DONE' | translate}}</span>
      </button>
      <small class="hide--onSmall">{{'AUTH-SELFIE-DIALOG.PRESS-ENTER' | translate}}</small>
    </div>
    <div *ngIf="!photoDataUrl">
      <button attr.data-selenium-id="button-aufnehmen" mat-flat-button color="primary" (click)="camera.takeSnapshot()"
        [disabled]="!camera || !camera.isPlaying">
        <mat-icon>photo_camera</mat-icon>
        <span> {{'GLOBAL.TAKE-PHOTO-BUTTON' | translate}}</span>
      </button>
      <small class="hide--onSmall">{{'ASSET-UPLOAD-FIELD.PRESS-SPACE' | translate}}</small>
    </div>
    <!--<button attr.data-selenium-id="button-abbrechen" *ngIf="!photoDataUrl" mat-flat-button [mat-dialog-close]="null">
      {{'GLOBAL.CANCEL' | translate}}
    </button>-->
  </div>

</lib-dialog>