import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'app-scroll-to-top',
    templateUrl: './scroll-to-top.component.html',
    styleUrls: ['./scroll-to-top.component.scss'],
    standalone: true,
    imports: [NgStyle, FontAwesomeModule]
})
export class ScrollToTopComponent implements OnInit {

  @Input() componentType: string // either "default" for documents.component or "dialog" for legal-dialog
  buttonOpacity: number

  dialogEl: HTMLElement // used for legal-dialog.component

  constructor(private elRef: ElementRef) { }

  ngOnInit() {
    if (this.componentType === 'default')
      this.buttonOpacity = 0
    else {
      this.buttonOpacity = 1
      this.dialogEl = (<HTMLElement>this.elRef.nativeElement).parentElement.parentElement
    }
  }

  @HostListener("window:scroll", [])

  // only works for documents.component, not for legal-dialog.component
  // (so scroll-to-top is position:fixed for components and relative
  // for dialogs)
  onWindowScroll() {
    if (this.componentType === 'default') {
      if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
        this.buttonOpacity = 0.9
      }
      else if (this.buttonOpacity === 0.9 && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
        this.buttonOpacity = 0
      }
    }
  }

  scrollToTop() {
    if (this.componentType === 'default')
      window.scrollTo({ left: 0, top: 0 }) // used for documents.component
    else
      this.dialogEl.scrollTop = 0 // used for legal-dialog.component
  }

  style() {
    if (this.componentType === 'default')
      return { opacity: this.buttonOpacity, position: 'fixed' }
    else
      return { opacity: this.buttonOpacity, position: 'relative' }
  }
}
