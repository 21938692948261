<app-wrapper headerType="header-has-2-rows" [hideAnnouncement]="bookingService.isEmbedded" [hideHeader]="isEmbedded"
  [ngClass]="bookingService.isEmbedded ? 'main-iframe-corrections' : ''" #wrapper>
  <ng-container leftNavSlot>
    <ng-content select="[leftNavSlot]"></ng-content>
  </ng-container>
  <ng-container rightNavSlot>
    <ng-content select="[rightNavSlot]"></ng-content>
    <app-legal-menu viewMode="patientOTK"></app-legal-menu>
    <app-language-toggle></app-language-toggle>
  </ng-container>
  <ng-container secondLine>
    <ng-content select="[secondLine]"></ng-content>
  </ng-container>
  <!-- stepper (header-only) -->
  <ng-container stepperSlot>
    <ng-content select="[stepperSlot]" #stepperHeaderComponent></ng-content>
  </ng-container>
  <!-- Main Content -->
  <mat-card appearance="outlined" class="c-card otk-card" [class.c-card--isFullViewport]="fixedHeight"
    [class.isLoading]="isLoading">
    <mat-card-title class="c-card-title otk-card-title">
      <!-- title -->
      <div class="title" *ngIf="title">
        <h2 [innerHTML]="sanitizer.bypassSecurityTrustHtml(title | translate)" [class.hide--onVerySmall]="shortTitle">
        </h2>
      </div>
      <!-- info line -->
    </mat-card-title>
    <ng-content select="[infoLine]"> </ng-content>
    <mat-progress-bar *ngIf="isLoading" mode="query" color="primary"></mat-progress-bar>
    <!-- stepper (content only) and errors -->
    <mat-card-content class="c-card-content stepper--hide-header otk-card-content test">
      <!-- loading errors -->
      <app-loading-error-fullsize-info *ngIf="isLoadingError"></app-loading-error-fullsize-info>
      <!-- Content Placeholder -->
      <ng-content #stepperContentComponent></ng-content>

    </mat-card-content>
    <!-- actions at the bottom -->
    <mat-card-actions #matCardActions class="footer-actions">
      <ng-content select="[buttonBack]"></ng-content>
      <ng-content select="[buttonSeriesBack]"></ng-content>
      <ng-content select="[buttonSeriesForward]"></ng-content>
      <ng-content select="[buttonForward]"></ng-content>
      <ng-content select="[buttonBook]"></ng-content>
      <!-- only show the legal and language buttons if embedded in iframe, not if embedded in the app -->
      <div *ngIf="isEmbedded && isInIframe" class="iframe-stepper">
        <app-legal-menu viewMode="patientOTK"></app-legal-menu>
        <app-language-toggle></app-language-toggle>
      </div>
      <ng-content *ngIf="bookingService.isEmbedded" select="[buttonsEmbeddedSlot]"></ng-content>
      <ng-content *ngIf="bookingEdit" select="[websiteButton]"></ng-content>
      <ng-content *ngIf="bookingEdit" select="[loadButton]"></ng-content>
      <ng-content *ngIf="bookingEdit" select="[acceptButton]"></ng-content>
      <ng-content *ngIf="bookingEdit" select="[cancelButton]"></ng-content>
      <ng-content *ngIf="bookingEdit" select="[moveButton]"></ng-content>
      <ng-content *ngIf="bookingEdit" select="[appButton]"></ng-content>
    </mat-card-actions>
  </mat-card>
</app-wrapper>