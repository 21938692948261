
export interface Person {
  readonly fname: string
  readonly lname: string
  readonly nameTitle?: string
  readonly nameAffix?: string
  readonly email: string
  readonly phone?: string
  readonly hausarzt?: string
  readonly beruf?: string
  readonly gender?: Gender
  readonly birthDate: Date
  readonly street?: string
  readonly streetNumber?: string
  readonly address_2?: string
  readonly zip?: string
  readonly city?: string
  readonly country?: string

  age?: number

  readonly photo?: string  // user-avatar (TODO move out of `Person`)
}

export enum Gender {
  M = 'M',
  W = 'W',
  D = 'D',
}

export enum GenderString {
  M = 'männlich',
  W = 'weiblich',
  D = 'divers'
}
