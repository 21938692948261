<h2 mat-dialog-title>Fehler beim Hochladen des jsons</h2>
<mat-dialog-content>
  Beim Hochladen des jsons ist der folgende Fehler aufgetreten:
  <br /><br />
  {{ data.wfaMessage.message }}. <br /><br />
  Bitte schicken sie diesen Fehler sowie den json an einen der folgenden
  Entwickler:
  <ul>
    <li>Arseniy Tsipenyuk (ArTs)</li>
    <li>Binh Ta (BiTa)</li>
  </ul>
  oder erstellen Sie einen Ticket mit dem Epic `Webforms`, wo sie den Fehler und
  den json anhängen, und weisen sie diesen Madita Poslovsky (MaPos) zu.
</mat-dialog-content>
