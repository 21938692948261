export const wfaMatrixQuestionExample = {
  type: 'matrix',
  name: 'Frage6',
  title: {
    de: 'Matrix (einfache Auswahl)',
  },
  columns: ['Column1', 'Column2', 'Column3'],
  rows: ['Row1', 'Row2'],
  shortTitle: 'matrix-einfache-auswahl',
}
