import {
  AfterRenderQuestionEvent,
  Model,
  SurveyModel,
  ValueChangedEvent,
} from 'survey-core'

/**
 * @param surveyModel its property `options` is mutated
 */
export function setQuestionConfiguration(surveyModel: Model): void {
  surveyModel.onAfterRenderQuestion.add(
    preventQuestionFocusOnTitleAndDescriptionClick,
  )
  surveyModel.onValueChanged.add(formatNumberInput)
}

/**
 * @param options comes from SurveyJs and is mutated
 */
function preventQuestionFocusOnTitleAndDescriptionClick(
  _: SurveyModel,
  options: AfterRenderQuestionEvent,
): void {
  const selectors = ['.sd-question__title', '.sd-question__description']
  selectors.forEach((selector) => {
    const element = options.htmlElement.querySelector(selector)
    if (element == undefined) return
    element.addEventListener('click', (event: Event) => {
      event.stopPropagation()
    })
  })
}

/**
 * @param options comes from SurveyJs and is mutated
 */
function formatNumberInput(_: SurveyModel, options: ValueChangedEvent): void {
  const question = options.question
  if (question.getType() === 'text' && question['wfaInputType'] === 'number') {
    const input = options.value
    const commaPattern = /^(-)?\d+,\d*$/
    question.value = commaPattern.test(input) ? input.replace(',', '.') : input
  }
}
