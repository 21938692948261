import { I18NString } from '@a-d/entities/I18N.entity'
import dayjs from 'dayjs'
import { Abbreviation, Duration, YMWD } from './duration'

export const deDuration: Duration = {
  year: { singular: 'Jahr', plural: 'Jahre', short: 'J' },
  month: { singular: 'Monat', plural: 'Monate', short: 'M' },
  week: { singular: 'Woche', plural: 'Wochen', short: 'W' },
  day: { singular: 'Tag', plural: 'Tage', short: 'T' },
}

export const enDuration: Duration = {
  year: { singular: 'year', plural: 'years', short: 'Y' },
  month: { singular: 'month', plural: 'months', short: 'M' },
  week: { singular: 'week', plural: 'weeks', short: 'W' },
  day: { singular: 'day', plural: 'days', short: 'D' },
}

// returns array of years, months, weeks, days between two dates
export function dateDiffTuple(d1: Date | null, d2: Date | null): YMWD | null {
  if ([d1, d2].includes(null)) return null
  if (d1.valueOf() > d2.valueOf()) return null

  let date1 = dayjs(d1)
  let date2 = dayjs(d2)

  const years = date2.diff(date1, 'year')
  date2 = date2.subtract(years, 'year')
  const months = date2.diff(date1, 'month')
  date2 = date2.subtract(months, 'month')
  const monthDays = date2.diff(date1, 'day')
  const weeks = Math.floor(monthDays / 7)
  const days = monthDays % 7

  return [years, months, weeks, days]
}

function zip<T1, T2>(x: T1[], y: T2[]): [T1, T2][] {
  return Array.from(Array(Math.max(x.length, y.length)), (_, i) => {
    return [x[i], y[i]]
  })
}

function selectAbbreviation(
  n: number,
  x: Abbreviation,
  useShort: boolean
): string {
  if (useShort) return x.short
  if (n === 0) return ''
  if (n === 1) return x.singular
  return x.plural
}

function readableDuration(input: YMWD, d: Duration, useShort: boolean): string {
  const dictionary = zip(input, Object.values(d))
  return dictionary
    .map((entry) => {
      const [n, x] = entry
      const abbreviation = selectAbbreviation(n, x, useShort)
      return [n, abbreviation]
    })
    .filter((entry) => entry[0] !== 0)
    .map((entry) => `${entry[0]} ${entry[1]}`)
    .join(', ')
}

export function dateDiff(d1: Date | null, d2: Date | null): I18NString {
  const times = dateDiffTuple(d1, d2)
  if (times === null) return ''
  const useShort = times.filter((v) => v !== 0).length > 2

  const de = readableDuration(times, deDuration, useShort)
  const en = readableDuration(times, enDuration, useShort)

  return { de, en }
}
