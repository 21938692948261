import { Injectable } from '@angular/core';
import { ArztType } from '@lib/entities/ArztType.entity';
import { InsuranceType, InsurantStatus } from '@lib/entities/Insurance.entity';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { User } from '../entities/User.entity';
@Injectable({
  providedIn: 'root'
})
export class InsuranceService {

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
  ) { }

  /**
   * Returns the text-version of the given insurance-type in different lengths (short, medium, long)
   */
  public abbreviation(insurance: InsuranceType) {
    switch (insurance) {
      case InsuranceType.GKV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.ABBREVIATION.GKV')
      case InsuranceType.PKV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.ABBREVIATION.PKV')
      case InsuranceType.HZV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.ABBREVIATION.HZV')
      case InsuranceType.SZ: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.ABBREVIATION.SZ')
      case InsuranceType.BES: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.ABBREVIATION.BES')
      case InsuranceType.None: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.ABBREVIATION.NONE')
      case InsuranceType.All: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.ABBREVIATION.ALL')
      default: return insurance
    }
  }
  public shortNameFormatted(insurance: InsuranceType) {
    switch (insurance) {
      case InsuranceType.GKV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.SHORT-NAME-FORMATTED.STATUTORY')
      case InsuranceType.PKV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.SHORT-NAME-FORMATTED.PRIVATE')
      case InsuranceType.HZV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.SHORT-NAME-FORMATTED.STATUTORY')
      case InsuranceType.SZ: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.SHORT-NAME-FORMATTED.DIRECT-PAYER')
      case InsuranceType.BES: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.SHORT-NAME-FORMATTED.SPECIAL')
      case InsuranceType.None: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.SHORT-NAME-FORMATTED.UNDOCUMENTED')
      default: return this.abbreviation(insurance)
    }
  }
  public mediumNameFormatted(insurance: InsuranceType) {
    switch (insurance) {
      case InsuranceType.GKV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.MEDIUM-NAME-FORMATTED.STATUTORY-INSURED')
      case InsuranceType.HZV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.MEDIUM-NAME-FORMATTED.STATUTORY-INSURED')
      case InsuranceType.PKV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.MEDIUM-NAME-FORMATTED.PRIVATELY-INSURED')
      case InsuranceType.BES: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.MEDIUM-NAME-FORMATTED.SPECIAL-PAYER')
      default: return this.shortNameFormatted(insurance)
    }
  }
  public longNameFormatted(insurance: InsuranceType) {
    switch (insurance) {
      case InsuranceType.GKV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.LONG-NAME-FORMATTED.STATUTORY-HEALTH-INSURANCE')
      case InsuranceType.HZV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.LONG-NAME-FORMATTED.STATUTORY-HEALTH-INSURANCE')
      case InsuranceType.PKV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.LONG-NAME-FORMATTED.PRIVATE-HEALTH-INSURANCE')
      case InsuranceType.BES: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.LONG-NAME-FORMATTED.SPECIAL-PAYER')
      default: return this.mediumNameFormatted(insurance)
    }
  }
  public longNameWithAbbreviationFormatted(insurance: InsuranceType) {
    switch (insurance) {
      case InsuranceType.GKV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.LONG-NAME-WITH-ABBREVIATION-FORMATTED.GKV')
      case InsuranceType.PKV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.LONG-NAME-WITH-ABBREVIATION-FORMATTED.PKV')
      case InsuranceType.HZV: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.LONG-NAME-WITH-ABBREVIATION-FORMATTED.HZV')
      case InsuranceType.SZ: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.LONG-NAME-WITH-ABBREVIATION-FORMATTED.SZ')
      case InsuranceType.BG: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.LONG-NAME-WITH-ABBREVIATION-FORMATTED.BG')
      default: return this.mediumNameFormatted(insurance)
    }
  }
  /**
   * Returns an array of all available insurance-types
   */
  public allInsurances(): InsuranceType[] {
    // FIXME would it be desirable to exclude 'None' and 'All' here? (ToMa 2023-12-21)
    return Object.keys(InsuranceType)
      .map(key => InsuranceType[key])
  }

  /**
   * @returns all available arzt types
   */
  public allArztTypes(): ArztType[] {
    return Object.keys(ArztType)
      .map(key => ArztType[key]);
  }

  /**
   * Returns an array of all available insurance-types which can be treated by the given user/arztType.
   */
  public allInsurancesTreatedByUser(user: User): InsuranceType[] {
    const userRoles = user?.roles || []
    if (!userRoles.includes('arzt')) return []

    return this.allInsurances()
      .filter((insurance) => this.isTreatedByUser(user, insurance))
  }
  public allInsurancesTreatedByArztType(arztType: ArztType): InsuranceType[] {
    return this.allInsurances()
      .filter((insurance) => this.isTreatedByArztType(arztType, insurance))
  }

  /**
   * returns all available arzt types who can treat the specified insurance type
   * @param insuranceType insurance type to check against
   * @returns all arzt types treating specified insurance type
   */
  public allArztTypesTreatingInsuranceType(insuranceType: InsuranceType): ArztType[] {
    return this.allArztTypes()
      .filter((arztType) => this.isTreatedByArztType(arztType, insuranceType));
  }

  /**
   * Returns true, if the given user is permitted to treat patients with the given insurance. (depending on his arzt-type)
   */
  public isTreatedByUser(user: User, insuranceType: InsuranceType) {
    if (!user || !user.arzt || !user.isArzt) {
      console.warn("No user given or user has no role 'arzt' and so isn't allowed to treat patients.", user)
      return false
    }
    return this.isTreatedByArztType(user.arzt.arztType, insuranceType)
  }

  /**
   * Returns true, if the given arzt-type is permitted to treat patients with the given insurance.
   */
  public isTreatedByArztType(arztType: ArztType, insuranceType: InsuranceType) {
    if ([InsuranceType.PKV, InsuranceType.SZ, InsuranceType.None].includes(insuranceType)) return true
    else return arztType === ArztType.Kassenarzt
  }

  /**
   * Returns an array of all available insurance-types which can be treated by the active user.
   */
  public allInsuranceTypesTreatedByActiveUser(): InsuranceType[] {
    const user = this.authService.user
    return this.allInsurancesTreatedByUser(user)
  }

  /**
   * Returns true, if the active user is permitted to treat patients with the given insurance.
   */
  public isTreatedByActiveUser(insurance: InsuranceType) {
    const user = this.authService.user
    return this.isTreatedByUser(user, insurance)
  }

  /**
   * Returns formatted GKV-InsurantStatus
   */
  public insurantStatusFormatted(insurantStatus: InsurantStatus) {
    switch (insurantStatus) {
      case 1: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANT-STATUS.1-MEMBER')
      case 3: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANT-STATUS.3-FAMILY-INSURED')
      case 5: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANT-STATUS.5-PENSIONER')
      default: return this.translate.instant('INSTANCE-FORM-INSURANCE.INSURANT-STATUS.DEFAULT')

    }
  }

  /**
   * Returns formatted BES-Type (Besonderer Kostenträger)
   */
  public besTypeFormatted(besType: string) {
    switch (besType) {
      case 'bw': return this.translate.instant('INSTANCE-FORM-INSURANCE.SPECIAL-PAYER-TYPES.ARMY')
      case 'po': return this.translate.instant('INSTANCE-FORM-INSURANCE.SPECIAL-PAYER-TYPES.POLICE')
      case 'as': return this.translate.instant('INSTANCE-FORM-INSURANCE.SPECIAL-PAYER-TYPES.REFUGEE')
      case 'eu': return this.translate.instant('INSTANCE-FORM-INSURANCE.SPECIAL-PAYER-TYPES.EUROPEAN-INSURANCE')
      case 'so': return this.translate.instant('INSTANCE-FORM-INSURANCE.SPECIAL-PAYER-TYPES.OTHER')
      default: return besType
    }
  }

}
