import { UntypedFormControl, ValidationErrors, ValidatorFn } from "@angular/forms"
import dayjs from "dayjs"

// FIXME: in most uses in the code this validator is used in conjunction with
//  Validators.required, which is more explicit 
// -> is the required argument actually needed?
/**
 * Date-Relation Validation ('after' or 'before')
 * 
 * @remark
 * Do NOT use this function directly for code outside of `validators/`,
 * use `ADValidators.datesRelation` instead.
 */
export function datesRelationValidator(
  relation: 'after'|'before', otherDate: dayjs.Dayjs, required: boolean = true
): ValidatorFn {
  return (control: UntypedFormControl): ValidationErrors => {
    const errorValue = { value: control.value }

    if (!control.value && required) return { 'required': errorValue }
    else if (!control.value && !required) return null

    const date = dayjs(control.value)
    if (!date.isValid() || !otherDate.isValid()) return { 'datesInvalid': errorValue }

    if (relation === 'after') {
      const isAfterOrSame = date.isAfter(otherDate, 'day') || date.isSame(otherDate, 'day')
      if (!isAfterOrSame) return { 'dateIsNotAfterOrSame': errorValue }

    } else if (relation === 'before') {
      const isBeforeOrSame = date.isBefore(otherDate, 'day') || date.isSame(otherDate, 'day')
      if (!isBeforeOrSame) return { 'dateIsNotBeforeOrSame': errorValue }
    }

    return null
  }
}
