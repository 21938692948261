import { AppointmentType, I18NString_MISSING_VALUE } from "@a-d/entities/Booking.entity";
import { LanguageService } from "@a-d/i18n/language.service";
import { CurrencyPipe, NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { BaseLanguage } from '../../../entities/I18N.entity';

@UntilDestroy()
@Component({
  selector: 'app-appointment-type',
  templateUrl: './appointment-type.component.html',
  styleUrls: ['./appointment-type.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconModule, MatTooltipModule, TranslateModule, CurrencyPipe],
})
export class AppointmentTypeComponent implements OnInit {
  @Input() appointmentType: AppointmentType
  descriptionSanitized: SafeHtml
  baseLanguage: BaseLanguage
  baseLanguageChangeSubscription: Subscription
  name: string
  description: string

  constructor(
    private domSanitizer: DomSanitizer,
    private languageService: LanguageService,
  ) { }

  ngOnInit(): void {
    this.baseLanguageChangeSubscribe()
    this.baseLanguage = this.languageService.activeBaseLang
    this.name = this.appointmentType.name[this.baseLanguage] === I18NString_MISSING_VALUE
      ? this.appointmentType.name[this.languageService.DEFAULT_BASE_LANG]
      : this.appointmentType.name[this.baseLanguage]
    this.description = this.appointmentType.description[this.baseLanguage] === I18NString_MISSING_VALUE
      ? "" //no description better than in wrong language
      : (this.appointmentType.description[this.baseLanguage] ?? '') // In case of null value
    //set empty string in case of showDescription = false
    this.description = this.appointmentType.showDescription ? this.description : ''
    this.descriptionSanitized = this.domSanitizer.bypassSecurityTrustHtml(this.description)
  }
  baseLanguageChangeSubscribe() {
    if (this.baseLanguageChangeSubscription)
      this.baseLanguageChangeSubscription.unsubscribe()
    this.baseLanguageChangeSubscription = this.languageService.baseLanguageChangeSubject$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.baseLanguage = this.languageService.activeBaseLang
        this.name = this.appointmentType.name[this.baseLanguage] === I18NString_MISSING_VALUE
          ? this.appointmentType.name[this.languageService.DEFAULT_BASE_LANG]
          : this.appointmentType.name[this.baseLanguage]
        this.description = this.appointmentType.description[this.baseLanguage] === I18NString_MISSING_VALUE
          ? ""
          : (this.appointmentType.description[this.baseLanguage] ?? '')
        this.description = this.appointmentType.showDescription ? this.description : ''
        this.descriptionSanitized = this.domSanitizer.bypassSecurityTrustHtml(this.description)
      })
  }
}
