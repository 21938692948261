<ng-container *ngIf="isVisible">
  <!-- Menu-Button -->
  <button attr.data-selenium-id="button-language-select" mat-icon-button [matMenuTriggerFor]="langMenu"
    matTooltip="{{ 'Language' | translate }}">
    <mat-icon>language</mat-icon>
    <span [class.o-vh]="true">{{ 'Language' | translate }}</span>
  </button>


  <!-- Menu-Content -->
  <mat-menu attr.data-selenium-id="menu-language-select" #langMenu="matMenu">

    <ng-container *ngFor="let baseLanguage of baseLanguagesActive; let i = index">
      <a mat-menu-item (click)="languageService.changeBaseLanguage(baseLanguage)"
        attr.data-selenium-id="anchor-language-menu-auswahl-{{i}}"
        [style.fontWeight]="baseLanguage === languageService.activeBaseLang ? '600' : 'normal'">
        {{ 'LANGUAGES.'+baseLanguage | translate }}
      </a>
    </ng-container>

  </mat-menu>
</ng-container>