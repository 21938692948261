import { AuthService } from '@a-d/auth/auth.service'
import { CookiesService, SupportCookieNames } from '@a-d/dsgvo/cookies.service'
import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import Bowser from 'bowser'
import { Observable } from 'rxjs'
import { BrowserSupportDialogComponent, BrowserSupportDialogData } from './browser-support-dialog.component'

@Injectable({
  providedIn: 'root'
})
export class BrowserSupportService {
  public browser: Bowser.Parser.Parser
  public browserFormatted: string

  // String with name of OS (also iPad OS 13+)
  public osName: string

  // True, if user is on iOS-device (also iPad OS 13+)
  public isIos: boolean

  // True, if browser is Safari
  public isSafari: boolean

  // True, if browser is Safari and user is on iOS-device (also iPad OS 13+)
  public isIosSafari: boolean

  // True, if browser is iOS- or other mobile-device (also iPad OS 13+)
  public isMobileOS: boolean

  /**
  * Necessary Browser-Requirements
  * (List of Browser-Names: https://github.com/lancedikson/bowser/blob/master/src/constants.js)
  */
  public readonly appRequirements = {
    desktop: {
      edge: '>=15',
      safari: '>=11',
      firefox: '>=55',
      opera: '>=46',
      chrome: '>=56',
      googlebot: '>=1',
      bingbot: '>=1',
      slurp: '>=1',
      duckduckbot: '>=1',
      yandexbot: '>=1'
    },
    Android: {
      firefox: '>=55',
      opera: '>=46',
      samsung_internet: '>=4',
      android: '>=67',
      chrome: '>=56',
      googlebot: '>=1',
      bingbot: '>=1',
      slurp: '>=1',
      duckduckbot: '>=1',
      yandexbot: '>=1'
    },
    iOS: {
      safari: '>=11',
      googlebot: '>=1',
      bingbot: '>=1',
      slurp: '>=1',
      duckduckbot: '>=1',
      yandexbot: '>=1',
      chrome: '>=56'
    },
  }

  /**
  * Exception-list of above Browser-Requirements:
  *   * Excluding macOS Safari 14 due to WebRTC-/Camera-bugs
  *   * Exluding iOS 14.0–14.2 Safari due to Audio-bugs
  */
  public readonly appRequirementExceptions = [
    // {
    //   desktop: { safari: "~14", },
    // },
    // {
    //   iOS: { safari: "~14.0", },
    // },
    // {
    //   iOS: { safari: "~14.1", },
    // },
    // {
    //   iOS: { safari: "~14.2", },
    // },
  ]

  public readonly appRequirementsByPlattform = {
    'Desktop': 'Chrome (≥ 100), Firefox (≥ 120), Opera (≥ 100), Microsoft Edge (≥ 70), Safari (≥ 14)',
    'Android': 'Chrome (≥ 100), Android-Browser (≥ 100), Firefox (≥ 120), Opera (≥ 100), Samsung Internet (≥ 20).',
    'iOS': 'Safari, Chrome, Firefox für iOS ≥ 14.3'
  }

  public get appRequirementsFormattedHtml(): string {
    let result = ''
    for (let plattform in this.appRequirementsByPlattform) {
      result += `<p><strong>${plattform}</strong><br>${this.appRequirementsByPlattform[plattform]}</p>`
    }
    return result
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthService
  ) {
    this.browser = isPlatformBrowser(this.platformId) ? Bowser.getParser(window.navigator.userAgent) : null
    this.browserFormatted = this.getBrowserFormatted()

    this.isSafari = this.browser.getBrowserName(true) === 'safari'
    this.isIos = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    this.isIosSafari = this.isSafari && this.isIos
    this.isMobileOS = this.isIos || (!!this.browser.getOSName() && this.browser.getPlatform().type === 'mobile')
    this.osName = this.isIos ? 'iOS' : this.browser.getOSName()
  }

  getAreAppRequirementsSatisfied() {
    return this.browser.satisfies(this.appRequirements) && !this.getAnyExceptionIsSatisfied()
  }

  /**
  * Returns String of Browser-Name & -Version
  */
  private getBrowserFormatted(): string {
    if (!this.browser) return null
    const browserName = this.browser.getBrowserName()
    const browserVersion = this.browser.getBrowserVersion()

    if (!browserName) return null
    else if (!browserVersion) return browserName
    else return `${browserName} (Version ${browserVersion})`
  }

  private isDesktop(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
      .test(navigator.userAgent)
      ? false
      : true
  }
  /**
  * Returns true if current browser satifies any exception (due to i.e. specific browser-bugs)
  * this used to check for different combinations of safari versions and desktop/mobile
  * however the current state is that as long as the doctor does not use safari on desktop
  * the vss works fine regardless of what the patient uses.
  * so the condition was simplified to not arzt&&desktop&&safari.
  * see the discussion in #videosprechstunde from the 17.12.2021 for, Dennis:
  * Ich denke das (Tom: die alte safari checks rauszukommentieren) hatten wir gemacht,
  * weil es für Patienten ja nicht mehr zutreffend ist,
  * wenn der Arzt Chrome / Firefox / Brave nutzt.
  * Dadurch wird letzteres aber eben auch nicht mehr enforced
  * → Wir sollten es Arzt - only wieder reinnehmen.
  * Die Regeln können gern auch vereinfacht werden,
  * ohne die Versionschecks und einfach generell bei Safari warnen.
  */
  getAnyExceptionIsSatisfied(): boolean {
    const isArzt = this.authService.user?.isArzt
    if (this.isSafari && isArzt && this.isDesktop())
      return true
    return false
  }
}

/**
* Guard that checks if Browser is supported
*/
@Injectable({
  providedIn: 'root'
})
export class BrowserSupportGuard implements CanActivate {

  public browserSupportDialogRef: MatDialogRef<BrowserSupportDialogComponent>

  constructor(
    private browserSupportService: BrowserSupportService,
    private dialog: MatDialog,
    private cookiesService: CookiesService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return true //temporary until we clean up requirements and upgrade to angular 15
    if (this.browserSupportService.getAreAppRequirementsSatisfied())
      return true
    else if (this.cookiesService.get(SupportCookieNames.hideBrowserSupportDialog) === 'true')
      return true
    const data: BrowserSupportDialogData = {
      appRequirementsFormattedHtml: this.browserSupportService.appRequirementsFormattedHtml,
      anyExceptionIsSatisfied: this.browserSupportService.getAnyExceptionIsSatisfied(),
      currentBrowser: this.browserSupportService.browserFormatted,
      currentPlattformType: this.browserSupportService.browser.getPlatform().type,
      currentOS: this.browserSupportService.osName,
      isMobileOS: this.browserSupportService.isMobileOS,
      isIos: this.browserSupportService.isIos,
      isIosSafari: this.browserSupportService.isIosSafari,
    }
    this.browserSupportDialogRef = this.dialog.open(BrowserSupportDialogComponent, {
      data,
      disableClose: true,
      width: '40rem',
    })
    return this.browserSupportDialogRef.afterClosed()
  }
}
