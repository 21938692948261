import { Observable } from "rxjs"

export const defaultRsaKeyParameters = {
  name: "RSASSA-PKCS1-v1_5",
  modulusLength: 4096,
  publicExponent: new Uint8Array([1, 0, 1]),
  hash: "SHA-256",
} as const

export function createRsaKeyPair(
  rsaKeyParameters = defaultRsaKeyParameters,
): Observable<CryptoKeyPair> {
  return new Observable((observer) => {
    crypto.subtle.generateKey(rsaKeyParameters, true, ["sign", "verify"]).then(
      (keyPair: CryptoKeyPair) => {
        observer.next(keyPair)
        observer.complete()
      },
      (error) => {
        observer.error(error)
      },
    )
  })
}
