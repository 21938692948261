export type CertificateIssuer = {
  country: string
  commonName: string
  organization: string
  address: string
  postalCode: string
  city: string
}

export type CertificateSubject = {
  commonName: string
  sat: string
  country: string
  organization: string
  city: string
  address: string
  postalCode: string
}

type Defaults = {
  hashAlgorithm: string
  signAlgorithm: string
  crypto: {
    encryptAlgorithm: any // `Algorithm `type complains about `length` but it is necessary
  }
  certificate: {
    issuer: CertificateIssuer
    subject: CertificateSubject
  }
}

export const defaults: Defaults = {
  hashAlgorithm: 'SHA-256',
  signAlgorithm: 'RSASSA-PKCS1-v1_5',
  crypto: {
    encryptAlgorithm: {
      name: 'AES-CBC',
      length: 256, // necessary on runtime but unwanted by ts transpiler
    },
  },
  certificate: {
    issuer: {
      country: 'DE',
      commonName: 'Arzt-Direkt',
      organization: 'zollsoft GmbH',
      address: 'Ernst-Haeckel-Platz 5/6',
      postalCode: '07745',
      city: 'Jena',
    },
    subject: {
      commonName: 'demo',
      sat: `https://www.app.arzt-direkt.de/demo`,
      country: 'DE',
      organization: '',
      city: '',
      address: '',
      postalCode: '',
    },
  },
} as const
