import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, startWith } from 'rxjs/operators';
import { NotificationService } from '../../../../../lib/src/lib/notifications/notification.service';
import { FacharztAusbildung, FacharztSchwerpunkt, WeiterbildungsOrdnung, ZusatzBezeichnung, ZusatzWeiterbildung } from '../../entities/Facharzt.entity';
import { FacharztService } from '../../facharzt/facharzt.service';
import { I18NStringPipe } from '../../i18n/i18n.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@UntilDestroy()
@Component({
    selector: 'app-facharzt-fields',
    templateUrl: './facharzt-fields.component.html',
    providers: [I18NStringPipe],
    standalone: true,
    imports: [
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatAutocompleteModule,
        NgIf,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        FontAwesomeModule,
        NgFor,
        MatOptionModule,
        MatSelectModule,
        MatChipsModule,
        TranslateModule,
        I18NStringPipe,
    ],
})
export class FacharztFieldsComponent implements OnInit {

  //public get isDemo(): boolean { return environment.demoMode }

  @Input() formGroup: UntypedFormGroup
  @Input() showSuggestionOption: boolean = false

  public get facharzt(): AbstractControl { return this.formGroup.get('facharzt') }
  public get facharzt_2(): AbstractControl { return this.formGroup.get('facharzt_2') }
  public get facharztSchwerpunkte(): AbstractControl { return this.formGroup.get('facharztSchwerpunkte') }
  public get facharztSchwerpunkte_2(): AbstractControl { return this.formGroup.get('facharztSchwerpunkte_2') }
  public get facharztZusatz(): AbstractControl { return this.formGroup.get('facharztZusatz') }
  public get facharztZusatzWeiterbildungen(): AbstractControl { return this.formGroup.get('facharztZusatzWeiterbildungen') }
  public get facharztZusatzVorschlag(): AbstractControl { return this.formGroup.get('facharztZusatzVorschlag') }

  addZusatzWeiterbildungCtrl = new UntypedFormControl();

  public filteredFacharztOptions: FacharztAusbildung[] = [];
  public filteredSecondFacharztOptions: FacharztAusbildung[] = [];
  public applicableFacharztSchwerpunkte: FacharztSchwerpunkt[] = [];
  public applicableSecondFacharztSchwerpunkte: FacharztSchwerpunkt[] = [];

  public applicableZusatzWeiterbildungen: ZusatzWeiterbildung[] = this.facharztService.facharztZusatzWeiterbildungen;
  public filteredZusatzWeiterbildungenOptions: ZusatzWeiterbildung[] = [];

  public filteredZusatzBezeichnungenOptions: ZusatzBezeichnung[] = [];

  public suggestionArray: (ZusatzWeiterbildung | FacharztAusbildung | FacharztSchwerpunkt)[]
  public suggestionMap: Map<ZusatzWeiterbildung | FacharztAusbildung | FacharztSchwerpunkt, string>
  public showSuggestionField: boolean = false
  public showSecondFacharzt: boolean = false

  /**
   * keys to separate chips in chip lists
   */
  public readonly chipListSeparatorKeyCodes: number[] = [ENTER, COMMA];

  @ViewChild('addZusatzWeiterbildungInput')
  addZusatzWeiterbildungInput: ElementRef<HTMLInputElement>;

  @ViewChild('zusatzBezeichnungenAutocomplete')
  zusatzBezeichnungenAutocomplete: MatAutocomplete

  constructor(
    private facharztService: FacharztService,
    private i18nStringPipe: I18NStringPipe,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    if (this.facharzt_2.value) this.showSecondFacharzt = true

    // Apply Facharzt-Validator
    this.facharzt.setValidators(this.facharztService.getFacharztIdValidator(false));
    this.facharzt.updateValueAndValidity();

    // Apply Facharzt2-Validator
    this.facharzt_2.setValidators(this.facharztService.getFacharztIdValidator(false));
    this.facharzt_2.updateValueAndValidity();

    // Filter Facharzt-Autocomplete based on input & clear Facharzt-Schwerpunkte on change
    this.facharzt.valueChanges.pipe(
      startWith(0),
      untilDestroyed(this),
    ).subscribe(() => {
      this.updateFacharzt(this.facharzt);
      this.filterFacharztOptions();
      this.updateFacharztSchwerpunkte();
      if (!this.facharzt.value) {
        this.facharzt_2.setValue('')
        this.showSecondFacharzt = false
      }
    })

    // Filter Facharzt2-Autocomplete based on input & clear Facharzt-Schwerpunkte2 on change
    this.facharzt_2.valueChanges.pipe(
      startWith(0),
      untilDestroyed(this),
    ).subscribe(() => {
      this.updateFacharzt(this.facharzt_2);
      this.filterSecondFacharztOptions();
      this.updateSecondFacharztSchwerpunkte();
    })

    // Apply ZusatzWeiterbildung-Validator
    this.addZusatzWeiterbildungCtrl.setValidators(this.facharztService.getZusatzWeiterbildungIdValidator(false));
    this.addZusatzWeiterbildungCtrl.updateValueAndValidity();

    // Filter ZusatzWeiterbildung-Autocomplete based on input
    this.addZusatzWeiterbildungCtrl.valueChanges.pipe(
      startWith(0),
      untilDestroyed(this),
    ).subscribe(() => {
      this.filterZusatzWeiterbildungenOptions();
    })

    this.facharztZusatz.valueChanges.pipe(
      startWith(0),
      untilDestroyed(this),
    ).subscribe(() => {
      this.filterZusatzBezeichnungenOptions();
    })

    this.facharztZusatz.setValidators(this.getZusatzBezeichnungenValidator(false))

    this.facharztZusatzVorschlag.valueChanges.pipe(
      startWith(0),
      debounceTime(1000),
      untilDestroyed(this),
    ).subscribe(() => {
      this.checkIfSuggestionIsKnown()
    })
  }


  /**
  * Creates `facharzt` FormGroup
  */
  public static createFacharztFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      facharzt: new UntypedFormControl(null, []),
      facharzt_2: new UntypedFormControl(null, []),
      facharztSchwerpunkte: new UntypedFormControl(null, []),
      facharztSchwerpunkte_2: new UntypedFormControl(null, []),
      facharztZusatz: new UntypedFormControl(null, []),
      facharztZusatzWeiterbildungen: new UntypedFormControl([], { initialValueIsDefault: true }),
      facharztZusatzVorschlag: new UntypedFormControl(null, { updateOn: 'change' }),
    })
  }


  /**
   * display label for the facharzt input
   */
  public getFacharztDisplayLabel(value: string): string {
    const facharzt = this.facharztService.getFacharztAusbildungById(value);
    if (!facharzt) {
      return value ?? "";
    }
    return this.i18nStringPipe.transform(facharzt?.name) ?? value;
  }

  /**
   * display label for a weiterbildungsordnung
   */
  public getWboDisplayLabel(wbo: WeiterbildungsOrdnung): string {
    const wboName = this.facharztService.getWboName(wbo);
    if (!wboName) {
      return undefined;
    }
    return this.i18nStringPipe.transform(wboName) ?? wbo;
  }

  /**
   * display label for the zusatz weiterbildung input
   */
  public getZusatzWeiterbildungDisplayLabel(value: string): string {
    const wb = this.facharztService.getZusatzWeiterbildungById(value);
    if (!wb) {
      return value ?? "";
    }
    return this.i18nStringPipe.transform(wb?.name) ?? value;
  }

  /**
   * display label for the zusatz bezeichnung input
   */
  public getZusatzBezeichnungDisplayLabel(value: string): string {
    const zb = this.facharztService.getZusatzBezeichnungById(value);
    if (!zb) {
      return value ?? "";
    }
    return this.i18nStringPipe.transform(zb?.name) ?? value;
  }


  /**
   * If the value of the facharzt-input exactly matches a valid facharzt-name, update the value to the according id.
   */
  private updateFacharzt(inputFacharzt) {
    const facharzt = this.facharztService.findFacharztAusbildungByName(inputFacharzt.value, this.i18nStringPipe);
    if (facharzt) {
      inputFacharzt.setValue(facharzt.id, { emitEvent: false });
    }
  }


  /**
   * Filters applicable facharzt-options depending on the facharzt-input value
   */
  private filterFacharztOptions() {
    // filter on displayed label
    const label = this.getFacharztDisplayLabel(this.facharzt.value ?? "");
    this.filteredFacharztOptions = this.facharztService.searchForFacharztAusbildungen(label, this.i18nStringPipe);
  }
  private filterSecondFacharztOptions() {
    // filter on displayed label
    const label = this.getFacharztDisplayLabel(this.facharzt_2.value ?? "");
    this.filteredSecondFacharztOptions = this.facharztService.searchForFacharztAusbildungen(label, this.i18nStringPipe);
  }


  /**
   * Filters applicable facharzt-schwerpunkt-options depending on the selected facharzt-id
   */
  private updateFacharztSchwerpunkte() {
    // applicable schwerpunkte for current facharzt
    this.applicableFacharztSchwerpunkte = this.facharztService.getSchwerpunkteForFacharztAusbildungId(this.facharzt.value);

    // check if currently selected schwerpunkte are still applicable
    if (!this.facharztSchwerpunkte.value?.length) return;

    const applicableFacharztSchwerpunkteIds = this.applicableFacharztSchwerpunkte.map((schwerpunkt) => schwerpunkt.id);

    // just compare to first, since other schwerpunkte also require the same selected facharzt
    if (applicableFacharztSchwerpunkteIds.includes(this.facharztSchwerpunkte.value[0])) return;

    // if no longer applicable, reset value
    this.facharztSchwerpunkte.setValue(null);
  }
  /**
   * Filters applicable facharzt-schwerpunkt2-options depending on the selected facharzt-id
   */
  private updateSecondFacharztSchwerpunkte() {
    // applicable schwerpunkte for current facharzt
    this.applicableSecondFacharztSchwerpunkte = this.facharztService.getSchwerpunkteForFacharztAusbildungId(this.facharzt_2.value);

    // check if currently selected schwerpunkte are still applicable
    if (!this.facharztSchwerpunkte_2.value?.length) return;

    const applicableFacharztSchwerpunkteIds = this.applicableSecondFacharztSchwerpunkte.map((schwerpunkt) => schwerpunkt.id);

    // just compare to first, since other schwerpunkte also require the same selected facharzt
    if (applicableFacharztSchwerpunkteIds.includes(this.facharztSchwerpunkte_2.value[0])) return;

    // if no longer applicable, reset value
    this.facharztSchwerpunkte_2.setValue(null);
  }

  /**
   * Filters applicable ZusatzWeiterbildung-options depending on the facharzt-input value
   */
  private filterZusatzWeiterbildungenOptions() {
    // filter on displayed label
    const label = this.addZusatzWeiterbildungCtrl.value ?? "";
    this.filteredZusatzWeiterbildungenOptions = this.facharztService.searchForZusatzWeiterbildungen(label, this.i18nStringPipe);
  }

  addZusatzWeiterbildung(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value ?? '').trim();

    // Add ZusatzWeiterbildung
    if (value?.length > 0) {
      const wb = this.facharztService.findZusatzWeiterbildungByName(value, this.i18nStringPipe);
      if (wb) {
        this.facharztZusatzWeiterbildungen.setValue([...this.facharztZusatzWeiterbildungen.value, wb.id]);
        this.facharztZusatzWeiterbildungen.updateValueAndValidity();
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.addZusatzWeiterbildungInput.nativeElement.value = '';
    this.addZusatzWeiterbildungCtrl?.setValue('');
  }

  removeZusatzWeiterbildung(wbId: string): void {
    const index = this.facharztZusatzWeiterbildungen.value.indexOf(wbId);

    if (index >= 0) {
      this.facharztZusatzWeiterbildungen.value.splice(index, 1);
      this.facharztZusatzWeiterbildungen.updateValueAndValidity();
    }
  }

  selectedZusatzWeiterbildung(event: MatAutocompleteSelectedEvent): void {
    const wbId = event.option.value;
    if (this.facharztService.existsZusatzWeiterbildungId(wbId)) {
      this.facharztZusatzWeiterbildungen.setValue([...(this.facharztZusatzWeiterbildungen.value ? this.facharztZusatzWeiterbildungen.value : []), wbId]);
    }

    this.addZusatzWeiterbildungInput.nativeElement.value = '';
    this.addZusatzWeiterbildungCtrl?.setValue('');
  }


  /**
   * Filters applicable ZusatzBezeichnung-options depending on the input value
   */
  private filterZusatzBezeichnungenOptions() {
    // filter on displayed label
    const zusatz = this.facharztZusatz.value ?? "";
    const lastIndexOfSeparator = Math.max(zusatz.lastIndexOf(','), zusatz.lastIndexOf(';'), zusatz.lastIndexOf('/'))
    const label = zusatz.substring(lastIndexOfSeparator + 1).trim();
    this.filteredZusatzBezeichnungenOptions = this.facharztService.searchForZusatzBezeichnungen(label, this.i18nStringPipe);
  }

  /**
   * Replaces latest input value with selected matching list entry or adds selected entry
   */
  autocompleteZusatzBezeichnungenValue(zbName) {
    const zusatz = this.facharztZusatz.value ?? "";
    const lastIndexOfSeparator = Math.max(zusatz.lastIndexOf(','), zusatz.lastIndexOf(';'), zusatz.lastIndexOf('/'))
    const label = zusatz.substring(lastIndexOfSeparator + 1).trim();
    if (label !== '') {
      return zusatz.replace(label, zbName)
    }
    return zusatz + ' ' + zbName
  }

  /**
   * Corrects to valid field on blur if value changed (only if autocomplete list is not open)
   */
  checkOnChange() {
    if (this.zusatzBezeichnungenAutocomplete.isOpen) return
    this.validateFacharztZusatz()
    this.facharztZusatz.setValue(this.facharztZusatz.value.replace(/,\s*$/, ""))
  }

  /**
   * Transforms value of facharztZusatz to string with only list elements separated by commas
   * (executed on blur after changes and if autocomplete list is closed)
   */
  validateFacharztZusatz() {
    let label = this.facharztZusatz.value ?? "";
    let labelArray: string[] = label.split(/(?: , |, | ,|,| ; |; | ;|;| \/ |\/ | \/|\/)+/)
    let validArray: string[] = []
    labelArray.map(element => {
      if (this.facharztService.findZusatzBezeichnungByName(element.trim(), this.i18nStringPipe) && !validArray.includes(element.trim())) {
        validArray.push(element.trim())
      }
    })
    this.facharztZusatz.setValue(validArray.join(', '))
    this.facharztZusatz.setValue(this.facharztZusatz.value.replace(/,\s*$/, ""))
  }

  /**
   * returns validator which checks if value of given control is valid ZusatzBezeichnungen-list.
   */
  public getZusatzBezeichnungenValidator(isRequired: boolean): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const errorValue = { value: control.value }

      // null value
      if (!control.value) {
        if (isRequired) {
          return { 'isEmpty': errorValue };
        } else {
          return null;
        }
      }

      let label = control.value ?? "";
      let labelArray: string[] = label.split(/(?: , |, | ,|,| ; |; | ;|;| \/ |\/ | \/|\/)+/)
      let validArray: string[] = []
      labelArray.map(element => {
        if (this.facharztService.findZusatzBezeichnungByName(element.trim(), this.i18nStringPipe) && !validArray.includes(element.trim())) {
          validArray.push(element.trim())
        }
      })

      // list is invalid
      if (!(validArray.join(', ') === control.value)) {
        return { 'listInvalid': errorValue };
      }

      return null;
    }
  }

  /**
   * Sets one comma at the end of the value if field is clicked
   */
  addCommaOnClick() {
    if (this.facharztZusatz.value) {
      this.facharztZusatz.setValue(this.facharztZusatz.value.replace(/,\s*$/, "") + ', ')
    }
  }

  /**
   * Checks if suggestions appear in exixting lists and creates suggestionMap (keys: suggestions, value: matched input value)/ suggestionArray (suggestions)
   */
  checkIfSuggestionIsKnown() {
    let label = this.facharztZusatzVorschlag.value ?? "";
    let labelArray: string[] = label.split(/(?: , |, | ,|,| ; |; | ;|;| \/ |\/ | \/|\/)+/)
    let suggestionMap = new Map
    if (labelArray.length === 1) {
      this.facharztService.searchForZusatzWeiterbildungen(labelArray[0], this.i18nStringPipe).map(item => suggestionMap.set(item, labelArray[0]))
      this.facharztService.searchForFacharztAusbildungen(labelArray[0], this.i18nStringPipe).map(item => suggestionMap.set(item, labelArray[0]))
      this.facharztService.searchForFacharztSchwerpunkte(labelArray[0], this.i18nStringPipe).map(item => suggestionMap.set(item, labelArray[0]))
      this.facharztService.searchForZusatzBezeichnungen(labelArray[0], this.i18nStringPipe).map(item => suggestionMap.set(item, labelArray[0]))
    } else if (labelArray.length > 1) {
      labelArray.forEach(element => {
        this.facharztService.searchForZusatzWeiterbildungen(element, this.i18nStringPipe).map(item => suggestionMap.set(item, element))
        this.facharztService.searchForFacharztAusbildungen(element, this.i18nStringPipe).map(item => suggestionMap.set(item, element))
        this.facharztService.searchForFacharztSchwerpunkte(element, this.i18nStringPipe).map(item => suggestionMap.set(item, element))
        this.facharztService.searchForZusatzBezeichnungen(element, this.i18nStringPipe).map(item => suggestionMap.set(item, element))
      })
    }
    this.suggestionMap = suggestionMap
    this.suggestionArray = [...suggestionMap.keys()]
  }

  /**
   * Fills in selection and removes corresponding value
   */
  public setValuesToSelection(suggestion) {
    if (suggestion.ausbildungsType === 'ZusatzWeiterbildung') {
      this.facharztZusatzWeiterbildungen.setValue([...this.facharztZusatzWeiterbildungen.value, suggestion.id]);
      this.facharztZusatzWeiterbildungen.updateValueAndValidity()
    }
    else if (suggestion.ausbildungsType === 'FacharztAusbildung') {
      if (this.facharzt.value && this.facharzt.valid && this.facharzt.value !== suggestion.id) {
        if (this.facharzt_2.value && this.facharzt_2.valid && this.facharzt_2.value !== suggestion.id) {
          this.notificationService.displayNotification('Weitere Facharztbezeichnung wurde ersetzt.')
        }
        this.facharzt_2.setValue(suggestion.id);
        this.facharzt_2.updateValueAndValidity()
        this.showSecondFacharzt = true
      } else {
        this.facharzt.setValue(suggestion.id);
        this.facharzt.updateValueAndValidity()
      }
    }
    else if (suggestion.ausbildungsType === 'FacharztSchwerpunkt') {
      if (this.facharzt.value && this.facharzt.valid && this.facharzt.value !== suggestion.requiredFacharzt) {
        if (this.facharzt_2.value && this.facharzt_2.valid && this.facharzt_2.value !== suggestion.requiredFacharzt) {
          this.notificationService.displayNotification('Weitere Facharztbezeichnung wurde ersetzt.')
        }
        this.facharzt_2.setValue(suggestion.requiredFacharzt);
        this.facharzt_2.updateValueAndValidity()
        this.showSecondFacharzt = true
        if (!this.facharztSchwerpunkte_2.value || this.facharztSchwerpunkte_2.value?.length === 0) {
          this.facharztSchwerpunkte_2.setValue([suggestion.id])
        } else if (!this.facharztSchwerpunkte_2.value.includes(suggestion.id)) {
          this.facharztSchwerpunkte_2.setValue([...this.facharztSchwerpunkte_2.value, suggestion.id])
        }
        this.facharztSchwerpunkte_2.updateValueAndValidity()
      } else {
        this.facharzt.setValue(suggestion.requiredFacharzt);
        this.facharzt.updateValueAndValidity()
        if (!this.facharztSchwerpunkte.value || this.facharztSchwerpunkte.value?.length === 0) {
          this.facharztSchwerpunkte.setValue([suggestion.id])
        } else if (!this.facharztSchwerpunkte.value.includes(suggestion.id)) {
          this.facharztSchwerpunkte.setValue([...this.facharztSchwerpunkte.value, suggestion.id])
        }
        this.facharztSchwerpunkte.updateValueAndValidity()
      }
    }
    else if (suggestion.ausbildungsType === 'ZusatzBezeichnung') {
      if (!this.facharztZusatz.value) {
        this.facharztZusatz.setValue(suggestion.name)
      }
      else if (!this.facharztZusatz.value.includes(suggestion.name)) {
        this.facharztZusatz.setValue(this.facharztZusatz.value.replace(/,\s*$/, "") + ', ' + suggestion.name)
      }
    }
    let assignedString = this.suggestionMap.get(suggestion)
    let removeRegex = new RegExp('(?<=\\W*?)\\b' + assignedString + '\\b(\\W*?)(?=\\w)|(\\W*?)\\b' + assignedString + '\\b(\\W*?)(?=\\w?)')
    this.facharztZusatzVorschlag.setValue(this.facharztZusatzVorschlag.value.replace(removeRegex, ''))
  }


}
