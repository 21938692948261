<!-- Chips Form Field with Language-Button -->
<div class="c-form__row__item-with-button">

  <mat-form-field [formGroup]="formGroup" appearance="outline">
    <!-- Chips -->
    <mat-label>{{placeholder}}</mat-label>
    <mat-chip-grid #chipList>
      <div *ngIf="isArray()">
        <mat-chip-row *ngFor="let item of i18nValues[activeLanguage]" (removed)="remove(item)">
          {{ item }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </div>
      <input [placeholder]="placeholder" [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)"
        [matAutocomplete]="auto" #inputField>
    </mat-chip-grid>
  </mat-form-field>

  <button mat-flat-button (click)="$event.stopPropagation(); toggleTranslationBox()"
    [matTooltip]="'MULTILINGUAL-FIELD.LANGUAGE-BUTTON-HINT' | translate">
    <span>{{ activeLanguage | uppercase }}</span>
    <fa-icon [icon]="['far', 'angle-down']" [fixedWidth]="true"></fa-icon>
  </button>

</div>

<!-- Multilingual Dropdown -->
<mat-autocomplete #auto="matAutocomplete">

  <mat-option *ngIf="isLoading && showTranslations" (click)="$event.stopPropagation()">
    <mat-spinner class="spinner" color="primary" [diameter]="20" [strokeWidth]="3"></mat-spinner>
  </mat-option>

  <ng-container *ngIf="!isLoading && showTranslations">

    <!-- <button mat-flat-button color="primary" class="auto-translate-button"
      (click)="$event.stopPropagation(); updateTranslation()"
      [matTooltip]="'MULTILINGUAL-FIELD.AUTO-TRANSLATE-BUTTON-HINT' | translate" [disabled]="isDisabled">
      <strong
        [innerHTML]="'MULTILINGUAL-FIELD.AUTO-TRANSLATE-BUTTON' | translate: {value: ('LANGUAGES.' + activeLanguage | translate)}">
      </strong>
      <fa-icon [icon]="['far', 'globe']" [fixedWidth]="true">
      </fa-icon>
    </button> -->

    <div *ngFor="let t of i18nValues | keyvalue">
      <mat-optgroup *ngIf="instanceLanguages.includes(t.key)" [label]="'LANGUAGES.' + (t.key) | translate"
        (click)="$event.stopPropagation(); setLanguage(t.key)">
        <mat-option (click)="$event.stopPropagation(); setLanguage(t.key)"
          [class]="t.key===activeLanguage ? 'item_selected':''">
          <em>{{ t.value === 'MULTILINGUAL-FIELD.NO-TRANSLATION' ? (t.value | translate):formatChipsArray(t.value)
            }}</em>
          <!-- failsafe -->
          <em *ngIf="!t.value?.length">
            {{'MULTILINGUAL-FIELD.NO-TRANSLATION' | translate}}
          </em>
          <button *ngIf="t.value?.length && t.value !== 'MULTILINGUAL-FIELD.NO-TRANSLATION'" mat-icon-button
            (click)="$event.stopPropagation(); clear(t.key)" style="color: #9a9a9a; transform: translateY(-1px);">
            <fa-icon [matTooltip]="'MULTILINGUAL-FIELD.REMOVE-TRANSLATION-BUTTON' | translate"
              [icon]="['fas', 'times-circle']"></fa-icon>
          </button>
        </mat-option>
      </mat-optgroup>
    </div>
  </ng-container>

</mat-autocomplete>