import { ActiveInstance } from '@a-d/entities/ActiveInstance.entity';
import { PatientInsuranceType } from '@a-d/entities/Booking.entity';
import { LanguageService } from '@a-d/i18n/language.service';
import { Injectable } from '@angular/core';
import { BaseLanguage } from 'vendor/arzt-direkt';

@Injectable({
  providedIn: 'root'
})
export class BookingStandaloneService {
  public appointmentTypes: string[]
  public betriebsstaetten: string[]                     // selectable betriebsstaetten
  public categories: string[]                           // visible categories
  public language: BaseLanguage                         // base language setting
  public known_patient: boolean                         // returning or new patient
  public insuranceTypes: PatientInsuranceType[] = []    // available insurance types
  public zuweiserCode: string                           // show hidden zuweiser appointment types only

  constructor(
    private languageService: LanguageService
  ) { }

  setLanguage(language: BaseLanguage, instance: ActiveInstance) {
    if (!language) { return; }
    const activeLanguages = instance.settings.general.internationalization.baseLanguagesActive
    if (activeLanguages.includes(language)) {
      this.languageService.changeBaseLanguage(language)
    }
  }
}
