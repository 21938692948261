import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { ActiveInstance } from '@a-d/entities/ActiveInstance.entity';
import { BaseLanguage } from '@a-d/entities/I18N.entity';
import { InstanceService } from '@a-d/instance/instance.service';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { debounce } from 'lib';
import { PageSizeService } from '../../theming/service/page-size.service';
import { BookingResultComponent } from '../booking-result/booking-result.component';
import { BookingStandaloneService } from '../booking-standalone.service';
import { BookingComponent } from '../booking.component';
import { BookingService } from '../booking.service';
@Component({
  selector: 'app-booking-standalone',
  standalone: true,
  imports: [CommonModule, BookingComponent, BookingResultComponent],
  templateUrl: './booking-standalone.component.html',
  styleUrls: ['./booking-standalone.component.scss', '../../../styles.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingStandaloneComponent implements OnInit, AfterViewInit {
  @Input() instance_identifier: string  // instance Id of calendar
  @Input() betriebsstaetten: string     // available betriebsstaetten
  @Input() categories: string           // selectable category ids
  @Input() appointment_types: string    // preselected OATs for booking page
  @Input() language: BaseLanguage       // base language
  @Input() zuweiser: string             // code for zuweiser appointments
  @Input() insurances: string           // allowed insurance types split by ,
  public isLoading = false
  public isLoadingError = false
  public hasInstance = false
  public bookingComplete = false

  private readonly FONT_FAMILY_NAME = 'Open Sans'

  private _known_patient: boolean       // known patient or first timer

  @Input()
  set known_patient(value: any) {
    if (value === 'true') {
      this._known_patient = true;
    } else if (value === 'false') {
      this._known_patient = false;
    }
  }
  get known_patient() { return this._known_patient; }

  constructor(
    private adLoggerService: AdLoggerService,
    private instanceService: InstanceService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private bookingService: BookingService,
    private pageSizeService: PageSizeService,
    private bookingStandaloneService: BookingStandaloneService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.bookingService.isStandalone = true;
    this.bookingService.zuweiserCode = this.zuweiser
    this.bookingService.isStandaloneComplete$.pipe()
      .subscribe({
        next: (isCompleted) => {
          this.bookingComplete = isCompleted;
        },
        error: (error) => this.adLoggerService.error(error)
      })
    this.setGlobalParameters();
    this.registerSvgIcons();
    console.log('check instance service', this.instance_identifier)
    this.instanceService.setInstanceByIdentifier(this.instance_identifier).subscribe({
      next: (instance) => {
        this.isLoading = false;
        this.isLoadingError = false;
        this.initializeParameters(instance);
        console.log('my response', instance)
        this.hasInstance = true
      },
      error: (error) => {
        this.adLoggerService.error('Error loading instance settings', error);
        this.isLoading = false;
        this.isLoadingError = true;
      }
    })
  }

  ngAfterViewInit(): void {
    this.viewportFix(window);
  }

  private setGlobalParameters() {
    if (this.appointment_types) {
      console.log('got app type params', this.appointment_types)
      this.bookingStandaloneService.appointmentTypes = this.appointment_types.split(',')
      console.log('app types after split', this.bookingStandaloneService.appointmentTypes)
    }
    if (this.betriebsstaetten) {
      console.log('betriebsstätten?', this.betriebsstaetten)
      this.bookingStandaloneService.betriebsstaetten = this.betriebsstaetten.split(',');
      console.log('bs split', this.bookingStandaloneService.betriebsstaetten)
    }
    if (this.categories) {
      this.bookingStandaloneService.categories = this.categories.split(',');
      console.log('my split categories', this.bookingStandaloneService.categories)
    }
    console.log('insurances?', this.insurances)
    if (this.insurances) {
      this.bookingStandaloneService.insuranceTypes = this.insurances.split(',') as any;
      console.log('my INSURANCES', this.bookingStandaloneService.insuranceTypes)
    }
    this.bookingStandaloneService.known_patient = this._known_patient;
    this.bookingStandaloneService.language = this.language;
    this.bookingStandaloneService.zuweiserCode = this.zuweiser;
  }

  /**
   * Set all OTK preselected booking parameters
   */
  public initializeParameters(instance: ActiveInstance) {
    if (!instance) { return; }
    if (this.language) {
      this.bookingStandaloneService.setLanguage(this.language, instance);
    }
    if (this.known_patient !== null && this.known_patient !== undefined) {

    }
  }

  @HostListener('window:orientationchange')
  @HostListener('window:resize', ['$event'])
  @debounce(100)
  public applyViewportUnitFix(event?) {
    if (!event || !event.target) { return; }
    this.viewportFix(event.target);
  }

  private viewportFix(element: any) {
    if (!element || !element.innerHeight) { return; }
    const vh = element.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.pageSizeService.checkIfMobile(element.innerWidth);
    this.pageSizeService.setSizes(element.innerWidth, element.innerHeight)
  }


  public registerSvgIcons() {
    [
      'insurance/pkv',
      'insurance/gkv',
      'insurance/hzv',
      'insurance/bg',
      'insurance/sz',
      'patient/reverse_camera',
      // 'icon_name', …
    ].forEach((icon) => {
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.url}/assets/icons/${icon}.svg`))
    });
  }

}
