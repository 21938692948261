import { I18NString } from '@a-d/entities/I18N.entity';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { merge, Subscription } from 'rxjs';
import { ReiseSettings } from '../reise-settings.entity';
import { ReiseService } from '../reise.service';
import { I18NStringPipe } from '../../../../../i18n/i18n.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectionFieldComponent } from '../../../../../forms/fields/selection-field.component';

@Component({
    selector: 'app-destination',
    templateUrl: './destination.component.html',
    styleUrls: ['./destination.component.scss'],
    standalone: true,
    imports: [
        SelectionFieldComponent,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        NgIf,
        MatInputModule,
        TextFieldModule,
        TranslateModule,
        I18NStringPipe,
    ],
})
export class DestinationComponent implements OnDestroy, OnInit {
  @Input() destinationControl: UntypedFormGroup | undefined;
  @Input() settings: ReiseSettings;
  arrivalDescription: AbstractControl | undefined;
  country: AbstractControl | undefined;
  duration$: Subscription;
  endDate: AbstractControl | undefined;
  formGroup: UntypedFormGroup | undefined;
  startDate: AbstractControl | undefined;
  tripDuration: I18NString = '';

  updateTripDuration() {
    const start = this.startDate.value;
    const end = this.endDate.value;
  }

  constructor(private reiseService: ReiseService) { }

  ngOnInit(): void {
    this.formGroup = this.reiseService.formGroup;
    this.arrivalDescription = this.destinationControl.get('arrivalDescription');
    this.country = this.destinationControl.get('country');
    this.endDate = this.destinationControl.get('endDate');
    this.startDate = this.destinationControl.get('startDate');

    this.duration$ = merge(
      this.startDate.valueChanges,
      this.endDate.valueChanges
    ).subscribe((duration) => {
      this.tripDuration = ReiseService.dateDiff(
        this.startDate.value,
        this.endDate.value
      );
    });
  }

  ngOnDestroy(): void {
    this.duration$.unsubscribe();
  }
}
