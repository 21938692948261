import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { Injectable } from '@angular/core';
import { Certificate } from 'pkijs';
import { fromBase64, stringToArrayBuffer } from 'pvutils';
import { Observable, Subject } from 'rxjs';
import { Asset } from '../entities/PatientSession.entity';
import { PatientSessionDataEncrypted, PatientSessionDataUnencrypted } from '../entities/PatientSessionCreation.entity';
import { WorkerEncryptionData, WorkerTypes } from '../entities/Worker.entity';
import { PatientSessionEncryptionService } from '../patient-session/patient-session-encryption.service';
import { WorkerDecryptionData } from './../entities/Worker.entity';
import { CryptoService } from './crypto.service';

@Injectable({
  providedIn: 'root'
})
export class CryptoWorkerService {
  private encryptSessionSubject$ = new Subject<PatientSessionDataEncrypted>();
  public encryptedSessionStatus$ = this.encryptSessionSubject$.asObservable();

  constructor(
    private adLoggerService: AdLoggerService,
    private cryptoService: CryptoService,
    private encryptService: PatientSessionEncryptionService,
  ) { }

  public decryptEnvelopedData(privateKey: ArrayBuffer, certificate: Certificate, encrypted: string, decode: boolean, show = false): Observable<any> {
    // console.log("decryptEnvelopedData: privateKey = ", privateKey)
    // console.log("decryptEnvelopedData: certificate = ", certificate)
    console.log("decryptEnvelopedData: encrypted = ", encrypted)
    return new Observable((observer) => {
      if (typeof Worker !== 'undefined') {
        const decryptWorker = new Worker(new URL('../crypto/crypto.worker.ts', import.meta.url), { type: 'module' });
        decryptWorker.onmessage = ({ data }) => {
          console.log('decryptEnvelopedData: decryptWorker.onmessage data', data)
          // console.log(data)
          observer.next(data);
          observer.complete();
          decryptWorker.terminate();
        }
        decryptWorker.onerror = (error) => {
          this.adLoggerService.error(error, 'CryptoWorkerService/decryptEnvelopedData');
        }
        const decryptData: WorkerDecryptionData = {
          type: WorkerTypes.DataDecrypt,
          privateKeyBuffer: privateKey,
          certificate: certificate,
          encrypted: encrypted,
          decode
        }
        decryptWorker.postMessage(decryptData);
      } else {
        this.cryptoService.decrypt(privateKey, certificate, encrypted, decode, show)
          .subscribe((decrypted) => {
            observer.next(decrypted);
            observer.complete();
          }, (error) => {
            observer.error(error);
            observer.complete();
          });
      }
    })
  }


  // tslint:disable-next-line:max-line-length
  public encryptSession(certBase64: string, sessionData: PatientSessionDataUnencrypted, encryptedAssets?: string): Observable<PatientSessionDataEncrypted> {
    return new Observable((observer) => {
      if (typeof Worker !== 'undefined') {
        const cryptoWorker = new Worker(new URL('../crypto/crypto.worker.ts', import.meta.url), { type: 'module' });
        cryptoWorker.onmessage = ({ data }) => {
          // console.log('crypto.worker.service encryptSession cryptoWorker.onmessage data');
          observer.next(data);
          observer.complete();
          cryptoWorker.terminate();
        }
        cryptoWorker.onerror = (error) => {
          this.adLoggerService.error(error, 'CryptoWorkerService/encryptSession: worker error');
        }
        const encryptionData: WorkerEncryptionData = {
          type: WorkerTypes.SessionEncrypt,
          cert: certBase64,
          data: sessionData,
          encryptedAssets
        }
        cryptoWorker.postMessage(encryptionData);
      } else {
        this.encryptService.encryptSession(certBase64, sessionData).subscribe((encryptedSession) => {
          console.log("encryptSession: encryptedSession = ", encryptedSession)
          observer.next(encryptedSession);
          observer.complete();
        }, (error) => {
          this.adLoggerService.error(error, 'CryptoWorkerService/encryptSession: main thread error');
          observer.error(error);
          observer.complete();
        })
      }
    });
  }

  public encryptAsset(
    certBase64: string,
    assetData: Asset,
    useCustomUmlautEncoding: boolean = false
  ): Observable<string> {
    return new Observable((observer) => {
      if (typeof Worker !== 'undefined') {
        const cryptoWorker = new Worker(new URL('../crypto/crypto.worker.ts', import.meta.url), { type: 'module' });
        cryptoWorker.onmessage = ({ data }) => {
          observer.next(data);
          observer.complete();
          cryptoWorker.terminate();
        }
        cryptoWorker.onerror = (error) => {
          this.adLoggerService.error(error, 'CryptoWorkerService/encryptAsset');
        }
        const encryptionData: WorkerEncryptionData = {
          type: useCustomUmlautEncoding
            ? WorkerTypes.AssetWithCustomUmlautEncoding
            : WorkerTypes.AssetEncrypt,
          cert: certBase64,
          data: null,
          assetData,
        }
        cryptoWorker.postMessage(encryptionData);
      } else {
        if (!certBase64) throw { name: 'NoCertificateForEncryption' }
        const certBuffer = stringToArrayBuffer(fromBase64(certBase64))
        // Encrypt assets separately
        this.cryptoService.encrypt(
          certBuffer,
          JSON.stringify(assetData),
          useCustomUmlautEncoding
        ).subscribe((encryptedAsset) => {
          observer.next(encryptedAsset);
          observer.complete();
        }, (error) => {
          this.adLoggerService.error('Main thread error', error);
          observer.error(error);
          observer.complete();
        })
      }
    });
  }
}
