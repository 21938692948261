<lib-dialog [isLoading]="isLoading" #dialog>

  <div mat-dialog-title>
    <h1>
      Passwort erforderlich
      <p>Eingabe aus Sicherheitsgründen erneut erforderlich</p>
    </h1>
  </div>

  <div mat-dialog-content>

    <form [formGroup]="formGroup" class="c-form c-form--noBottomMargin" (keydown.enter)="submitForm();"
      (keydown.shift.enter)="submitForm();" style="margin-top: 0.5rem">

      <mat-form-field appearance="outline">
        <mat-label>E-Mail Adresse</mat-label>
        <input matInput type="email" formControlName="email" spellcheck="false" autofocus>
      </mat-form-field>

      <app-password-field [formGroup]="formGroup" autocomplete="current-password"></app-password-field>

    </form>

  </div>

  <div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="submitForm()"
      [disabled]="isLoading || !formGroup.valid">Authentifizieren</button>
    <button mat-flat-button *ngIf="data.allowCancelAction" [mat-dialog-close] [disabled]="isLoading">Abbrechen</button>
    <button mat-flat-button *ngIf="!data.allowCancelAction" routerLink="/auth/logout" [mat-dialog-close]
      [disabled]="isLoading">Ausloggen</button>
  </div>

</lib-dialog>