import { AssetMeta, PatientSession } from './PatientSession.entity';
import { SocketUserRole } from './Socket.entity';
import { WebRTCConnectionType } from './WebRTC.entity';

/**
 * WebRTC message type
 */
export enum WebRTCMessage {
  Offer = 'offer',
  Answer = 'answer',
}

export enum WebRTCChannelDataType {
  Magic = "magic",              // causes each participant to share pre-defined data for testing (WebRTCMagicMessage)
  File = 'file',                // send a new file (WebRTCFileMessage)
  Message = 'message',          // send a chat message (WebRTCChannelMessage)
  Delete = 'delete',            // delete a shared file (WebRTCDeleteMessage)
  Coupled = 'coupled',          // doctor/tool were coupled successfully (WebRTCCoupledMessage)
  Session = 'session',          // the Arzt sends PatientSession to the Specialisst (WebRTCSessionMessage)
  Resync = 'resync',            // request resync of all shared files and chat with user (WebRTCResyncMessage)
  ChatSync = 'chatsync',        // send all shared chat messages to requesting user (WebRTCChatSyncMessage)
  Snapshot = 'snapshot',        // take high quality screenshot - !for tool only! (WebRTCSnapshotMessage)
  Username = 'username',        // peer usernames (WebRTCUsernameMessage)
  ForceClose = 'forceclose',    // force all peer connections to close (WebRTCCloseMessage)
  TrackStatus = 'trackStatus',  // user has enabled/disable audio or video channel (WebRTCTrackStatusMessage)
  OfferPairingRequest = 'offerPairingRequest', // sends request if pairing app <> tomedo is possible (doctor's side) (WebRTCOfferPairingRequestMessage)
  OfferPairing = 'offerPairing', // informs if pairing app <> tomedo is possible (patient app side) (WebRTCOfferPairingMessage)
  StartPairing = 'startPairing'  // triggers pairing app <> tomedo (WebRTCStartPairingMessage)
  // WebRTCFileProgress also exists but not in the enum
}

/*
 * base interface for messages.
 */

export interface WebRTCChannelMessage {
  readonly createdAt: string
  readonly type: WebRTCChannelDataType
}

export interface WebRTCMagicMessage extends WebRTCChannelMessage {

}
export interface WebRTCFileMessage extends WebRTCChannelMessage {
  meta: AssetMeta
}

export interface WebRTCChatMessage extends WebRTCChannelMessage {
  readonly message: string
  readonly role: SocketUserRole
  readonly username: string
  readonly createdAt: string
  readonly type: WebRTCChannelDataType
  readonly fileCreatedAt?: string
}

export interface WebRTCDeleteMessage extends WebRTCChannelMessage {
  readonly meta: AssetMeta
}

export enum CoupleType {
  Tool = 'tool',              // remove qr code on patient side when tool established connection
  Specialist = 'specialist'   // remove specialist invite dialogue wenn specialist joined
}

export interface WebRTCCoupledMessage extends WebRTCChannelMessage {
  readonly coupled: CoupleType
}

export interface WebRTCPatientSessionMessage extends WebRTCChannelMessage {
  readonly session: PatientSession
}

export enum WebRTCResync {
  Full = 'full',    // request full chat and all shared files
  Chat = 'chat',    // resync chat
  Files = 'files'   // resync shared files
}
export interface WebRTCResyncMessage extends WebRTCChannelMessage {
  readonly resync: WebRTCResync          // data to be synced with requester
  fileNames: string[]                    // names of all shared files
}

export interface WebRTCChatSyncMessage extends WebRTCChannelMessage {
  readonly chat: WebRTCChatMessage[]
}

export interface WebRTCSnapshotMessage extends WebRTCChannelMessage {
  readonly number?: number              // number of images to be taken
  readonly delay?: number               // delay between images
  readonly quality?: number             // quality level of requested screen capture
}

export interface WebRTCUsernameMessage extends WebRTCChannelMessage {
  readonly patient: string              // patient full name
  readonly doctor: string               // doctor full name
  readonly specialist: string           // specialist full name
}

export enum CloseType {
  All = 'all',    // force peer to close all running connections
  Tool = 'tool'   // close running tool connection
}

export interface WebRTCCloseMessage extends WebRTCChannelMessage {
  readonly createdAt: string
  readonly close: CloseType
}

export enum Track {
  Audio = 'audio',
  Video = 'video'
}

// sent when a user switches their audio or video on or off
export interface WebRTCTrackStatusMessage extends WebRTCChannelMessage {
  readonly track: Track // whats been turned on/off
  readonly status: boolean // is it now on or off
  webRTCConnectionType?: WebRTCConnectionType // which participants pair interact
  screenShare?: boolean
}

export interface WebRTCFileProgress {
  readonly webRTCConnectionType: WebRTCConnectionType
  readonly meta: AssetMeta
  progress: number
  readonly complete: boolean
}

// messages for pairing app <> tomedo

export interface WebRTCOfferPairingRequestMessage extends WebRTCChannelMessage {
  readonly qrContent: string
}

export interface WebRTCOfferPairingMessage extends WebRTCChannelMessage {
  readonly value: boolean
}

export interface WebRTCStartPairingMessage extends WebRTCChannelMessage {
  readonly qrContent?: string
}
