import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StreamService {
  public _localStream: MediaStream;
  public _remoteStream: MediaStream;
  public _toolStream: MediaStream;
  public _specialistStream: MediaStream;

  // stream overwriting local video track
  public _screenShareStream: MediaStream;

  private readonly BASE_MEDIA_CONSTRAINTS: MediaStreamConstraints = {
    audio: true,
    video: {
      facingMode: 'user',
      frameRate: { min: 10, max: 30 },
      aspectRatio: { ideal: 16 / 9 },
      width: { min: 320, ideal: 1280 },
      height: { min: 240, ideal: 720 }
    }
  };

  private readonly BASE_SCREEN_SHARE_CONSTRAINTS = {
    video: {
      cursor: 'always'
    },
    audio: false
  };

  constructor(
  ) { }

  public startScreenshareStream(): Promise<MediaStream> {
    return new Promise((resolve, reject) => {
      if (!('mediaDevices' in navigator && 'getDisplayMedia' in navigator.mediaDevices)) {
        return reject('Screen share is not supported by browser');
      }

      // workaround until typescript fixes entry for getDisplayMedia
      const mediaDevices = navigator.mediaDevices as any;
      mediaDevices.getDisplayMedia(this.BASE_SCREEN_SHARE_CONSTRAINTS)
        .then((stream: MediaStream) => {
          this._screenShareStream = stream;
          resolve(stream);
        })
        .catch((error) => reject(error));
    })
  }

  public startLocalStream(preferredVideoDeviceId?: string, preferredAudioDeviceId?: string): Promise<MediaStream> {
    return new Promise((resolve, reject) => {
      if (!('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices)) {
        return reject('Camera share is not supported by browser');
      }
      const currentConstraints = Object.assign({}, this.BASE_MEDIA_CONSTRAINTS);
      if (preferredVideoDeviceId && preferredVideoDeviceId !== 'default') {
        currentConstraints.video['deviceId'] = {
          exact: preferredVideoDeviceId
        }
      }
      if (preferredAudioDeviceId && preferredAudioDeviceId !== 'default') {
        currentConstraints.audio = {};
        currentConstraints.audio['deviceId'] = {
          exact: preferredAudioDeviceId
        }
      }

      navigator.mediaDevices.getUserMedia(currentConstraints)
        .then((stream) => {
          this._localStream = stream;
          return resolve(stream);
        })
        .catch((error) => reject(error));
    });
  }

  public stopStream(stream: MediaStream): Observable<any> {
    return new Observable((observer) => {
      if (!stream) {
        observer.next();
        observer.complete();
        return;
      }
      const tracks = stream.getTracks();
      for (let i = 0; i < tracks.length; i++) {
        tracks[i].stop();
        if (i === tracks.length - 1) {
          observer.next();
          observer.complete();
        }
      }
    })
  }

  get localstream(): MediaStream { return this._localStream; }
  set localstream(stream: MediaStream) { this._localStream = stream; }

  get remotestream(): MediaStream { return this._remoteStream; }
  set remotestream(stream: MediaStream) { this._remoteStream = stream; }

  get toolstream(): MediaStream { return this._toolStream; }
  set toolstream(stream: MediaStream) { this._toolStream = stream; }

  get specialistStream(): MediaStream { return this._specialistStream; }
  set specialistStream(stream: MediaStream) { this._specialistStream = stream; }

  get screenShareStream(): MediaStream { return this._screenShareStream; }
  set screenShareStream(stream: MediaStream) { this._screenShareStream = stream; }
}
