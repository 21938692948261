<lib-dialog [isLoading]="isLoading" #dialog>

  <div mat-dialog-title>
    <h1>
      Browser nicht unterstützt
      <p *ngIf="data.currentBrowser">{{ data.currentBrowser }}</p>
      <p *ngIf="!data.currentBrowser">Browser konnte nicht ermittelt werden</p>
    </h1>
  </div>

  <!-- Special Bug-Notes -->
  <div *ngIf="data.anyExceptionIsSatisfied && data.isIos" class="c-info-banner warning">
    <p>Leider enthält Safari unter iOS 14 einen Bug, der Audioprobleme verursacht.</p>
    <p><strong>Bitte updaten Sie Ihr iOS-Gerät mindestens auf Version 14.3, da der Fehler dort
        behoben wurde.</strong></p>
  </div>
  <div *ngIf="data.anyExceptionIsSatisfied && !data.isIos" class="c-info-banner warning">
    <p>
      Leider enthält Safari 14 unter macOS Big Sur einen Bug, der Videositzungen auf Arzt-Seite in einigen Fällen zum
      Absturz bringt.
      Zudem hat Apple deaktiviert, dass zwischen Safari unter Big Sur und Catalina unterschieden werden kann,
      deswegen erscheint diese Meldung ebenfalls für macOS Catalina-Nutzer.
    </p>
    <p><strong>Wir empfehlen auf Arzt-Seite einen anderen Browser, z.B. Google Chrome oder Firefox, zu nutzen.</strong>
    </p>
  </div>

  <div mat-dialog-content>
    <p>
      Benutzen Sie einen Browser der folgende Anforderungen erfüllt bzw. aktualisieren Sie Ihre Systemsoftware, um die
      Anwendung zu nutzen.
    </p>

    <span [innerHTML]="data.appRequirementsFormattedHtml"></span>

    <p> Hinweis: Ihre Patienten dürfen Safari (≥ 11) auf dem Desktop problemlos weiterhin verwenden. </p>
  </div>

  <mat-checkbox [(ngModel)]="notShowAgain" name="notShowAgain" color="primary" style="margin: .5rem 0 1rem;">
    Diese Meldung nicht erneut anzeigen
  </mat-checkbox>

  <div mat-dialog-actions>

    <button *ngIf="!data.isMobileOS" mat-flat-button color="primary"
      (click)="saveNotShowAgain(); openBrowserUpdatePage()">
      <span>Browser updaten</span>
      <mat-icon>open_in_new</mat-icon>
    </button>
    <button *ngIf="data.isMobileOS" mat-flat-button color="primary" (click)="saveNotShowAgain(); openOSUpdatePage()">
      <span>Software aktualisieren</span>
      <mat-icon>open_in_new</mat-icon>
    </button>

    <button mat-flat-button (click)="saveNotShowAgain(); dialogRef.close(true)" [disabled]="isLoading">Trotzdem
      fortfahren</button>

  </div>

</lib-dialog>
