import Joi from 'joi'

import { BaseQuestionWithoutShortTitleJoi } from './base-question.joi'
import { example } from './examples/wfa-file-question.joi.example'

export const WfaFileQuestionJoi = BaseQuestionWithoutShortTitleJoi.keys({
  type: Joi.string().valid('file').required(),
  sourceType: Joi.string().valid('file', 'camera', 'file-camera'),
  storeDataAsText: Joi.boolean().valid(false),
  allowMultiple: Joi.boolean().valid(true),
  waitForUpload: Joi.boolean().valid(true),
})
  .meta({
    className: 'WfaFileQuestion',
  })
  .example(example)
