import { InstanceService } from '@a-d/instance/instance.service';
import { LogoChangeMessageService } from '@a-d/misc/logo-change-message.service';
import { ColorService } from '@a-d/theming/service/color.service';
import { PageSizeService } from '@a-d/theming/service/page-size.service';
import { NgClass, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepper } from '@angular/material/stepper';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { BookingService } from '../booking/booking.service';
import { LanguageToggleComponent } from '../i18n/language-toggle.component';
import { LegalMenuComponent } from '../legal/legal-menu.component';
import { LoadingErrorFullsizeInfoComponent } from '../wrapper/loading-error-fullsize-info.component';
import { WrapperComponent } from '../wrapper/wrapper.component';

@UntilDestroy()
@Component({
  selector: 'app-wrapper-otk',
  templateUrl: './wrapper-otk.component.html',
  styleUrls: ['./wrapper-otk.component.scss'],
  standalone: true,
  imports: [
    WrapperComponent,
    NgClass,
    LegalMenuComponent,
    LanguageToggleComponent,
    MatCardModule,
    NgIf,
    MatProgressBarModule,
    LoadingErrorFullsizeInfoComponent,
    TranslateModule,
  ],
})
export class WrapperOTKComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() title: string
  @Input() shortTitle: string

  @Input() isLoading: boolean
  @Input() isLoadingError: boolean

  @Input() showNativeBackLink: boolean = true
  @Input() backLink: string
  @Input() backLinkFragment: string
  @Input() confirmBackLink: boolean
  @Input() isFreeContent: boolean = false
  @Input('logoLink') logoLink: string
  @Input('headerType') headerType: string
  @Input() headerOverflow: boolean = true
  @Input('stepper') stepper: MatStepper
  @Input('bookingEdit') bookingEdit: boolean = false

  @Input() fixedHeight: boolean = true

  @ViewChild('matCardActions', { read: ElementRef, static: false }) matCardActions: ElementRef
  @ViewChild('wrapper', { static: false }) wrapper: WrapperComponent

  public logoRectangleUrl: string
  public logoSquareUrl: string
  public primaryColor: string
  public headerClassName: string
  isEmbedded: boolean
  isInIframe: boolean
  constructor(
    public router: Router,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private elementRef: ElementRef,
    private instanceService: InstanceService,
    public pageSizeService: PageSizeService,
    private logoChangeMessageService: LogoChangeMessageService,
    private changeDetectorRef: ChangeDetectorRef,
    private colorService: ColorService,
    public bookingService: BookingService
  ) {
    // Remove native 'title'-attribute to hide value on hover
    this.elementRef?.nativeElement?.removeAttribute('title');
  }
  isMobile: boolean = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i
    .test(navigator.userAgent))

  ngOnInit() {
    this.headerClassName = this.headerOverflow
      ? 'main-header ' + this.headerType
      : 'main-header ' + this.headerType + ' c-header-no-overflow'
    this.initLogos()
    //copy-pasted from main-header.component.ts
    this.logoChangeMessageService.currentMessage
      .pipe(untilDestroyed(this))
      .subscribe((msg) => {
        if (msg === "color") {
          console.log("reset primary color in header")
          this.changeDetectorRef.detectChanges()
        }
      })
    this.primaryColor = this.colorService.primaryColor
    // embedded is true if in iframe or if set by get parameters (by the app) because I couldn't
    // get webview detection to work
    this.isEmbedded = this.bookingService.isEmbedded
    this.isInIframe = this.bookingService.isInIframe

    this.bookingService.recalcHeaderHeight$.pipe(untilDestroyed(this)).subscribe((completed) => {
      if (completed && this.wrapper.mainHeader?.nativeElement) {
        setTimeout(() => {
          this.pageSizeService.setStyleVar('--headerHeight', this.wrapper.mainHeader.nativeElement.offsetHeight);
        }, 50);
      }
    })

  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.pageSizeService.setStyleVar('--matCardActionsHeight', this.matCardActions.nativeElement.offsetHeight);
    }, 200);
  }

  ngOnChanges() {
    this.initLogos()
  }

  private initLogos() {
    const instanceId = this.instanceService.activeInstance?._id
    if (instanceId) {
      this.logoRectangleUrl = `${environment.url}/api/instance/asset/${instanceId}/logoImageRectangle`
      this.logoSquareUrl = `${environment.url}/api/instance/asset/${instanceId}/logoImageSquare`
    } else {
      this.logoRectangleUrl = `${environment.url}/assets/brand/arzt-direkt--white.svg`
      this.logoSquareUrl = `${environment.url}/assets/brand/arzt-direkt--white--small.svg`
    }
  }
}






