export type Size = [number, number]

/**
 * Calculates new size for the image to fit within the given dimension by keeping its aspect ratio.
 * Corresponds to the CSS property `object-fit` with the value `contain`.
 * @param originalSize Original dimensions of the image.
 * @param boxSize Dimensions of the bounding box to fit the image in.
 */
export function calculateNewImageSize(originalSize: Size, boxSize: Size): Size {
  const [currentWidth, currentHeight] = originalSize
  const [boxWidth, boxHeight] = boxSize
  const ratio = currentWidth / currentHeight

  const newWidth = Math.min(boxWidth, boxHeight * ratio)
  const newHeight = Math.min(boxHeight, boxWidth / ratio)

  if (newWidth > currentWidth || newHeight > currentHeight) return originalSize
  return [newWidth, newHeight]
}
