<div class="c-form__row" #target>
  <app-selection-field-component [descriptionRequiredIfSonstiges]="false" [formGroup]="formGroup" [multiple]="false"
    [options]="settings.wohinSelection.options" [selectionPlaceholder]="settings.wohinSelection.title | i18nString"
    [selectionRequired]="false" [selectionPretendRequired]="true" [sonstigesOption]="false" [withTypeahead]="true"
    [selectionControl]="country" selectionName="wohinSelection" class="c-form__row__item--small">
  </app-selection-field-component>

  <!-- <div class="c-form__row" #target> -->
  <mat-form-field attr.data-selenium-id="field-reisezeit" class="c-form__row__item--small" appearance="outline">
    <mat-label>
      {{ settings.travelDates.title | i18nString }}
      <span class="isError">*</span> &nbsp; - &nbsp;
      {{ settings.travelDates.title2 | i18nString }}
    </mat-label>
    <mat-date-range-input [formGroup]="formGroup" [rangePicker]="picker">
      <input matStartDate [formControl]="startDate"
        [placeholder]="settings.travelDates.startPlaceholder | i18nString" />
      <input matEndDate [formControl]="endDate" [placeholder]="settings.travelDates.endPlaceholder | i18nString" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="startDate.hasError('dynamicallyRequired')"
      [innerHTML]="settings.travelDates.error.required | i18nString">
    </mat-error>
    <mat-error *ngIf="startDate.hasError('matDatepickerParse')" [innerHTML]="'GLOBAL.DATE-ERROR-INVALID' | translate">
    </mat-error>
    <mat-error *ngIf="startDate.hasError('dateInvalid')" [innerHTML]="'GLOBAL.DATE-ERROR-INVALID' | translate">
    </mat-error>
    <mat-error *ngIf="startDate.hasError('dateIsInThePast')"
      [innerHTML]="settings.travelDates.error.future | i18nString"></mat-error>

    <mat-error *ngIf="endDate.hasError('matEndDateInvalid')" [innerHTML]="settings.travelDates.error.end | i18nString">
    </mat-error>
  </mat-form-field>

  <mat-form-field attr.data-selenium-id="field-reisedauer" class="c-form__row__item--verySmall" floatLabel="always"
    appearance="outline">
    <mat-label>{{ settings.duration.title | i18nString }}</mat-label>
    <span class="duration">{{ tripDuration | i18nString }}</span>
    <input matInput disabled hidden style="display:none" />
  </mat-form-field>
</div>

<div class="c-form__row" #target>
  <mat-form-field attr.data-selenium-id="field-anreise" appearance="outline">
    <mat-label>{{ settings.arrivalDescription.title | i18nString }}</mat-label>
    <textarea matInput cdkTextareaAutosize [formControl]="arrivalDescription"
      [placeholder]="settings.arrivalDescription.placeholder | i18nString" [required]="false" mat-autosize="true"
      cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" spellcheck="false"></textarea>
    <mat-error *ngIf="arrivalDescription.hasError('tooLong')"
      [innerHTML]="settings.arrivalDescription.regexFound | i18nString">
    </mat-error>
  </mat-form-field>
</div>