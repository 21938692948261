import Joi from 'joi'

const PartialLocStringJoi = Joi.object({
  de: Joi.string().optional(),
  en: Joi.string().optional(),
  es: Joi.string().optional(),
  fr: Joi.string().optional(),
  it: Joi.string().optional(),
})

export const WfaQuestionChoiceJoi = Joi.object({
  value: Joi.alternatives(Joi.string(), Joi.number()).required(),
  text: PartialLocStringJoi.required(),
}).meta({
  className: 'WfaQuestionChoice',
})
