import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchString',
    standalone: true,
})
export class SearchStringPipe implements PipeTransform {
  transform(input: string[], filterText: string | RegExp): string[] {
    const lowerCase =
      typeof filterText === 'string' ? filterText.toLowerCase() : filterText;
    return input.filter((s) => s.toLowerCase().search(lowerCase) !== -1);
  }
}
