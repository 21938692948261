import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { notNullish } from '@arzt-direkt/wfa-generic-utils'

import { WfaEnvService } from './../../environments/wfa-env.service'
import { AdisEditorEnv, StaticEditorEnv } from './editor.env'

export interface Routing<T extends StaticEditorEnv = StaticEditorEnv> {
  httpClient: HttpClient
  router: Router
  staticEditorEnv: T
  wfaEnvService: WfaEnvService
}

export function isAdisRouting(
  routing: Routing,
): routing is Routing<AdisEditorEnv> {
  return (
    routing.staticEditorEnv.isUsedIn === 'adisEditor' &&
    notNullish(routing.staticEditorEnv.deleteDraftModal)
  )
}
