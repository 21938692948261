<app-wrapper-otk [title]="title" [shortTitle]="" [isLoading]="isLoading" [stepper]="bookingService.stepper"
  [headerType]="'header-has-2-rows'" [headerOverflow]="false">
  <!-- error -->
  <app-booking-error *ngIf="bookingService.error"></app-booking-error>
  <!-- booking-stepper -->
  <!-- booking reservation -->
  <app-booking-reservation *ngIf="!bookingService.error" infoLine></app-booking-reservation>
  <!-- booking-stepper (content-only) -->
  <app-booking-stepper *ngIf="!bookingService.error" class="stepper-content"
    [isHeaderOnly]="false"></app-booking-stepper>
  <!-- booking-stepper (header-only) -->
  <app-booking-stepper *ngIf="!bookingService.error" stepperSlot [isHeaderOnly]="true"></app-booking-stepper>

  <!-- buttons for testing -->
  <div attr.data-selenium-id="div-buttons-for-testing" rightNavSlot *ngIf="isInDemoMode && !bookingService.error">
    <button attr.data-selenium-id="button-magic-fill-top" mat-icon-button (click)="magicFill()"
      [disabled]="this.bookingService.magicFill$.value">
      <fa-icon class="fa-lg__right" [icon]="['far', 'magic']"></fa-icon>
    </button>
    <button attr.data-selenium-id="button-shorten-reservation-timer-top" mat-icon-button
      (click)="shortenReservationTimer()" [disabled]="!bookingService.reservation">
      <fa-icon class="fa-lg__right" [icon]="['fas', 'stopwatch']"></fa-icon>
    </button>
  </div>
  <!-- same buttons for testing again, to fit in the bottom slot in case booking is embedded -->
  <div attr.data-selenium-id="div-buttons-for-testing-bottom" buttonsEmbeddedSlot
    *ngIf="isInDemoMode && !bookingService.error">
    <button attr.data-selenium-id="button-magic-fill-bottom" mat-icon-button (click)="magicFill()"
      [disabled]="this.bookingService.magicFill$.value">
      <fa-icon class="fa-lg__right" [icon]="['far', 'magic']"></fa-icon>
    </button>
    <button attr.data-selenium-id="button-shorten-reservation-timer-bottom" mat-icon-button
      (click)="shortenReservationTimer()" [disabled]="!bookingService.reservation">
      <fa-icon class="fa-lg__right" [icon]="['fas', 'stopwatch']"></fa-icon>
    </button>
  </div>
  <!-- buttons -->
  <!-- Start/Next Button -->
  <button attr.data-selenium-id="button-forward" buttonForward mat-flat-button color="primary"
    *ngIf="!bookingService.error && showForwardButton$ | async" (click)="onButtonForward()"
    [disabled]="isNextButtonDisabled$ | async">
    {{ ( ( (currentStep === Step.bookingSummary) && isPaidAppointment) ? 'OTK.NAVIGATION.PAID-NEXT' :
    (currentStep === Step.bookingInfo) ? 'GLOBAL.NEXT' : bookingService.arePrestepsCompleted$.value ?
    'GLOBAL.NEXT' : 'OTK.NAVIGATION.START'

    ) | translate }}
  </button>
  <!-- Back Button -->
  <button attr.data-selenium-id="button-back" buttonBack mat-flat-button
    *ngIf="!bookingService.error && showBackwardButton$ | async" (click)="onButtonBackward()">
    {{'GLOBAL.BACK' | translate }}</button>

  <!-- Book Button -->
  <button buttonBook attr.data-selenium-id="button-book" color="primary" mat-flat-button
    *ngIf="!bookingService.error && showBookButton$ | async"
    (click)="bookingService.seriesMode$.getValue() ? bookingSeriesService.book() : bookingService.book()"
    [disabled]="bookingService.currentlyLoading">{{'OTK.STEPPER.BOOK-NOW-BUTTON' |
    translate}}</button>
  <!-- Error button -->
  <button *ngIf="bookingService.error" attr.data-selenium-id="otk-error-button-try-again" buttonBack mat-flat-button
    (click)="refresh()">
    {{'OTK.BOOKING-ERROR.TRY-AGAIN' | translate }}</button>
</app-wrapper-otk>