import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { SocketUserRole } from '../entities/Socket.entity';
import { SocketFormattersService } from '../socket/socket-formatters.service';
import { Origin, WebRTCConnection } from './../entities/WebRTC.entity';
import { DataTransferService } from './dataTransfer.service';
import { MessagingService } from './messaging.service';

export interface Snapshot {
  readonly imageUri: string,
  readonly htmlImageElement: HTMLImageElement,
  readonly imageFile: File,
  readonly origin: Origin,
  readonly senderRole: SocketUserRole,
  readonly capturedRole: SocketUserRole,
}
@Injectable({
  providedIn: 'root'
})
export class SnapshotService {
  private renderer: Renderer2
  private virtualCanvas: HTMLCanvasElement
  public readonly snapshotType: string = 'image/jpeg'
  public readonly snapshotQuality: number = .7
  public snapshot$ = new BehaviorSubject<Snapshot>(null)

  constructor(
    private messagingService: MessagingService,
    private dataTransferService: DataTransferService,
    private socketFormattersService: SocketFormattersService,
    private rendererFactory: RendererFactory2
  ) {
    // this.snapshot$.pipe(skip(1)).subscribe((snapshot: Snapshot) => {
    //   const origin: Origin = Origin.Snapshot;
    //   this.dataTransferService.preloadFile(snapshot.imageFile, origin, snapshot.senderRole, snapshot.capturedRole)
    // })
  }

  public takeSnapshot(videoEl: HTMLVideoElement, origin: Origin, senderRole: SocketUserRole, capturedRole: SocketUserRole,): Observable<Snapshot> {
    return new Observable((observer) => {
      if (!this.virtualCanvas) {
        this.renderer = this.rendererFactory.createRenderer(null, null)
        this.virtualCanvas = this.renderer.createElement('canvas')
      }

      const width = videoEl.videoWidth
      const height = videoEl.videoHeight
      this.virtualCanvas.width = width
      this.virtualCanvas.height = height
      this.virtualCanvas.getContext('2d', { alpha: false }).drawImage(videoEl, 0, 0, width, height)
      const imageUri: string = this.virtualCanvas.toDataURL(this.snapshotType, this.snapshotQuality)
      const htmlImageElement = new Image()
      htmlImageElement.onload = () => {
        this.virtualCanvas.toBlob((imageBlob: Blob) => {
          const extension: string = this.snapshotType.split('/')[1];
          const time: dayjs.Dayjs = dayjs()
          const name: string = this.socketFormattersService.makeFileName(origin, extension, time, senderRole, capturedRole)
          const imageFile: File = new File([imageBlob], name, { type: this.snapshotType })
          const snapshot: Snapshot = {
            imageUri,
            htmlImageElement,
            imageFile,
            origin,
            senderRole,
            capturedRole,
          }
          // if recorded in the vss, add to the vss
          if (origin === Origin.Sprechstunde) {
            console.log("takeSnapshot: adding imageFile = ", imageFile)
            this.dataTransferService.addFile(imageFile, origin, senderRole, capturedRole)
          }
          this.snapshot$.next(snapshot)
          observer.next(snapshot)
          observer.complete()
        })
      }
      htmlImageElement.src = imageUri
    })
  }

  public requestToolSnapshot(connection: WebRTCConnection, quality: number) {
    this.messagingService.requestToolSnapshot(connection, quality);
  }
}
