import { FormGetter } from '@a-d/entities/FormGetter.entity';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { startWith, takeUntil } from 'rxjs/operators';
import { I18NArray } from '../../entities/I18N.entity';
import { UsefulComponent } from '../../misc/useful.component';
import { SearchStringPipe } from '../../misc/search-string.pipe';
import { I18NArrayPipe } from '../../i18n/i18n.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-selection-field-component',
    host: { 'class': 'c-form__row__item--stacked' },
    templateUrl: './selection-field.component.html',
    styles: [`
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
::ng-deep span.mat-select-arrow { position: absolute; right: 0; top: 15px; }`],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, MatFormFieldModule, MatSelectModule, MatOptionModule, NgFor, MatInputModule, TextFieldModule, MatAutocompleteModule, TranslateModule, I18NArrayPipe, SearchStringPipe]
})
export class SelectionFieldComponent extends UsefulComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup

  @Input() options: I18NArray = []
  @Input() multiple: boolean = true
  @Input() noOption = 'Keine Angabe'
  @Input() sonstigesOption = 'Sonstiges'
  @Input() sonstigesOptionUndertitle = 'Bitte unten beschreiben..'
  @Input() sonstigesShowHint = true
  @Input() withTypeahead: boolean = false

  @Input() selectionName: string
  @Input() selectionGetter: FormGetter
  @Input() selectionControl: AbstractControl
  @Input() selectionPretendRequired: boolean = false
  @Input() selectionRequired: boolean = true
  @Input() selectionPlaceholder: string

  @Input() descriptionName: string
  @Input() descriptionGetter: FormGetter
  @Input() descriptionHideUnlessSonstiges: boolean
  @Input() descriptionRequiredIfSonstiges: boolean = true
  @Input() descriptionPlaceholder = 'Sonstiges'
  @Input() descriptionHint: string

  typeaheadSearch: string = ''

  /**
   * Form-Controls
   */
  public get selection(): AbstractControl {
    if (this.selectionGetter !== undefined)
      return this.selectionGetter()
    if (this.selectionControl !== undefined) return this.selectionControl
    return this.formGroup.get(this.selectionName)
  }

  public get description(): AbstractControl {
    if (this.descriptionGetter !== undefined)
      return this.descriptionGetter()
    else
      return this.formGroup.get(this.descriptionName)
  }


  /**
   * Helper
   */
  public get sonstigesIsSelected(): boolean {
    if (!this.sonstigesOption || !this.selection.value || !this.selection.value.length) return false
    else if (Array.isArray(this.selection.value)) return this.selection.value.includes(this.sonstigesOption)
    else return this.selection.value.toString() === this.sonstigesOption
  }
  public get selectionJoinedValue(): string {
    if (!this.selection || !this.selection.value) return ''
    else if (!Array.isArray(this.selection.value)) return this.selection.value.toString()
    else return this.selection.value
      .map((value) => value ? value : this.noOption)
      .join(', ')
  }
  public get showDescription(): boolean {
    return this.description && (this.sonstigesIsSelected || !this.descriptionHideUnlessSonstiges)
  }
  public get descriptionIsRequired(): boolean {
    return this.showDescription && this.descriptionRequiredIfSonstiges && this.sonstigesIsSelected
  }

  onTypeahead(input: string) {
    this.typeaheadSearch = input
    this.cd.detectChanges()
  }

  constructor(
    private cd: ChangeDetectorRef,
  ) {
    super()
  }


  ngOnInit() {
    super.ngOnInit()

    // Set Selection-Validators
    const hasRequired = this.selection.hasValidator(Validators.required)
    if (hasRequired === true && this.selectionRequired === false)
      this.selection.removeValidators(Validators.required)
    if (hasRequired === false && this.selectionRequired === true)
      this.selection.addValidators(Validators.required)
    this.selection.updateValueAndValidity()

    // Set & Update Description-Validators
    if (this.description) {
      this.selection.valueChanges.pipe(
        startWith(0),
        takeUntil(this.unsubscribe$)
      ).subscribe(() => {
        this.updateDescriptionValidation()
      })
      return
    }

    this.cd.detectChanges()
  }


  /**
   * Updates the validation of the description based on given values and selected-state of sonstiges.
   */
  private updateDescriptionValidation() {
    if (this.descriptionIsRequired) {
      this.description.setValidators([Validators.required])
    } else if (!this.showDescription) {
      this.description.clearValidators()
      this.description.setValue(null)
    } else {
      this.description.clearValidators()
    }

    this.description.updateValueAndValidity()
    this.cd.detectChanges()
  }

}
