import { WfaEnv } from './wfa-env'

export function logWfaEnv(wfaEnv: WfaEnv): string {
  const {
    encryptString,
    frontendUrl,
    frontendUrlSubdirectory,
    production,
    serverUrl,
    serverUrlSubdirectory,
    targetBuild,
  } = wfaEnv
  return JSON.stringify({
    encryptString,
    frontendUrl,
    frontendUrlSubdirectory,
    production,
    serverUrl,
    serverUrlSubdirectory,
    targetBuild,
  })
}
