import { ElementRef, Injectable, Directive } from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { UsefulComponent } from '../../../../arzt-direkt/src/app/misc/useful.component';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class AnimationClassHelperService extends UsefulComponent {

  constructor() {
    super()
  }

  private readonly ANIMATION_END_EVENTS = [ 'animationend', 'transitionend', 'webkitAnimationEnd', 'oanimationend', 'MSAnimationEnd', 'webkitTransitionEnd', 'otransitionend', 'oTransitionEnd', 'msTransitionEnd' ]


  /**
   * Adds the given CSS-class to the given element and removes it
   * when the 'animationend' has fired.
   */
  public add(elementRef: ElementRef, cssClass: string, cb?: () => void ) {
    if (!elementRef || !elementRef.nativeElement) {
      console.warn(`Couldn't add class '${cssClass}' as the given element doesn't exist.`, elementRef)
      return
    }

    const nativeElement = elementRef.nativeElement
    nativeElement.classList.add(cssClass)
    this.onAnimationEnd(elementRef).subscribe(() => {
      nativeElement.classList.remove(cssClass)
      if (cb) cb()
    })
  }

  /**
   * Returns an observable which is fired on animation end of the given element.
   */
  public onAnimationEnd(elementRef: ElementRef): Observable<any> {
    const nativeElement = elementRef.nativeElement
    return merge(...this.ANIMATION_END_EVENTS.map((event) => fromEvent(nativeElement, event))).pipe(
      first(),
      takeUntil(this.unsubscribe$)
    )
  }

}
