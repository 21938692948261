<ng-container *ngIf="!hideAnnouncement">
  <!-- announcement -->
  <app-announcement></app-announcement>
</ng-container>
<div [ngClass]="'app-container'" [class.app-container--fixedHeight]="fixedHeight"
  [class.light-header]="colorService.lightHeader">
  <div #mainHeader>
    <zs-main-header *ngIf="!hideHeader" headerType="header-has-1-row" [headerOverflow]="headerOverflow">
      <div class="header-logo">
        <ng-content select="[leftNavSlot]"></ng-content>
        <a *ngIf="logoLink" (click)="navigateLogo()">
          <zs-logo></zs-logo>
        </a>
        <zs-logo *ngIf="!logoLink"></zs-logo>
      </div>

      <div class="second-line">
        <ng-content select="[secondLine]"></ng-content>
      </div>

      <div class="right-nav-slot">
        <ng-content select="[rightNavSlot]"></ng-content>
      </div>


      <div class="tab-wrapper">
        <ng-content select="[stepperSlot]"></ng-content>
      </div>

    </zs-main-header>
  </div>
  <main [class.c-flex-start]="!headerOverflow">
    <ng-content select="[mainMenu]"></ng-content>
    <ng-content></ng-content>
  </main>
</div>