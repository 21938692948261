import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '../../../../environments/environment';
import { ActiveInstance } from '../../../entities/ActiveInstance.entity';
import { InstanceForm } from '../../../entities/InstanceForm.entity';
import { UserFormattersService } from '../../../formatters/user-formatters';
import { FormHelpers } from '../../../forms/form-helpers.service';
import { InstanceService } from '../../../instance/instance.service';
import { UsefulComponent } from '../../../misc/useful.component';
import { InstanceFormInterface } from '../../instance-form-component.interface';
import { InstanceFormService } from '../../instance-form.service';
import { AnamneseForm } from '../anamnese-forms.service';
import { TranslateModule } from '@ngx-translate/core';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';



export interface BereitschaftsdienstAnamneseFormSettings {

}

@UntilDestroy()
@Component({
    selector: 'app-bereitschaftsdienst-anamnese-form',
    templateUrl: './bereitschaftsdienst-anamnese-form.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        NgIf,
        MatInputModule,
        TextFieldModule,
        TranslateModule,
    ],
})
export class BereitschaftsdienstAnamneseFormComponent extends UsefulComponent implements OnInit, InstanceFormInterface {

  @Input() anamneseForm: AnamneseForm
  @Input() settings: BereitschaftsdienstAnamneseFormSettings


  /**
   * Form-Group
   */
  public formGroup = new UntypedFormGroup({
    problemsQuestion: new UntypedFormControl('', [Validators.required]),
    caseNumber: new UntypedFormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(13), Validators.pattern("^[1-2]\\d{3}\/(0[1-9]|1[0-2])\/\\d+$")]),
  })
  public get problemsQuestion(): AbstractControl { return this.formGroup.get('problemsQuestion') }
  public get caseNumber(): AbstractControl { return this.formGroup.get('caseNumber') }



  /**
    * Current Instance & Instance-Form Helpers
   */
  public get activeInstance(): ActiveInstance { return this.instanceService.activeInstance }
  public get activeInstanceForm(): InstanceForm { return this.instanceFormService.activeInstanceForm }
  public readonly isDemoMode = environment.demoMode


  constructor(
    private instanceService: InstanceService,
    private instanceFormService: InstanceFormService,
    private formHelpers: FormHelpers,
    private cd: ChangeDetectorRef,
    private userFormatter: UserFormattersService
  ) {
    super()
    this.formHelpers.syncActiveAnamneseFormWithLocalStorage(this.activeInstanceForm, this.formGroup, this.unsubscribe$)
  }

  onInit() { }



  // ----------------------//
  // InstanceFormInterface //
  // ----------------------//

  public getData(): any {
    const data = this.formHelpers.trimmedRawValue(this.formGroup)

    return data
  }

  public magicFill() {
    if (!this.isDemoMode) return


    this.cd.detectChanges()
  }

}
