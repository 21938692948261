import { Route } from '@angular/router'

import { WebviewViewerComponent } from '../webview-viewer/webview-viewer.component'
import {
  aboutRoute,
  testViewerRoute,
  testWebviewViewerRoute,
} from './shared.routes'

const webviewViewerRoute = {
  path: 'webview-viewer',
  component: WebviewViewerComponent,
}

export const lotosRoutes: Route[] = [
  aboutRoute,
  testViewerRoute,
  testWebviewViewerRoute,
  webviewViewerRoute,
]
