import { AppointmentSeriesType, AppointmentType, BookingOpeningL, BookingResult, KdSetAlt, MessageToApp, OtkAppointment, OtkAttachment, OtkBookingStrategy, OtkEventType, TomKalenderDaten } from '@a-d/entities/Booking.entity';
import { InstanceService } from '@a-d/instance/instance.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { NotificationService } from 'lib';
import { BehaviorSubject, EMPTY, Subject, map, mergeMap, of, tap, timeout } from 'rxjs';
import { OtkReservation } from '../entities/Booking.entity';
import { BookingService } from './booking.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class BookingSeriesService {

  public appointmentSeriesType: AppointmentSeriesType = null
  public disableSeriesNext$ = new BehaviorSubject<boolean>(false)
  public disableSeriesPrevious$ = new BehaviorSubject<boolean>(false)
  public showSeriesNext$ = new BehaviorSubject<boolean>(false)
  public showSeriesPrevious$ = new BehaviorSubject<boolean>(false)
  public reservation: OtkReservation[] = []
  public activateMoveButton$ = new Subject<boolean>()

  public chainStepper: MatStepper = null
  public chainIndex$ = new BehaviorSubject<number>(0) //will be emitted on stepChange of chainstepper: onStepChange() booking-date-multi.component

  get multislots() { return this.bookingService.multiSlots }

  constructor(
    private bookingService: BookingService,
    private instanceService: InstanceService,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {
    this.bookingService.reset$.subscribe(() => this.reset())
  }





  // return false to allow standard handling of stepper
  next(): boolean {
    console.log("bookingSeries Service Next", !!this.chainStepper)
    if (this.chainIndex + 1 === this.chainLength) return !this.bookingService.bookingDateMultiFormGroup.valid
    console.log("we go next service")
    this.chainStepper.next()
    return true
  }

  previous() {
    console.log("bookingSeries Service Previous", !!this.chainStepper)
    if (this.chainIndex === 0) return false
    console.log("we go previous service")
    this.chainStepper.previous()
    return true
  }

  nextLast() {
    if (!this.bookingService.isBookingEdit && this.bookingService.stepper) {
      this.bookingService.stepper.selected.completed = true
      this.bookingService.stepper.next()
    }
  }

  get chainIndex() {
    return this.chainStepper.selectedIndex
  }

  get chainLength() {
    return this.bookingService.chainLength
  }

  // initAppointmentSeriesType() {
  //   this.appointmentSeriesType = this.bookingService.appointmentType.value?.appointmentSeriesType

  //   console.log("we use the duration")
  //   //durations
  //   //this.durationSincePrevious.length = 0
  //   //this.durationToSearch.length = 0
  //   this.appointmentSeriesType.items.forEach((item: AppointmentSeriesTypeItem) => {
  //     this.durationSincePrevious.push(dayjs.duration(item.durationSincePrevious))
  //     this.durationToSearch.push(dayjs.duration(item.durationToSearch))
  //   })
  // }

  setStepper(stepper: MatStepper) {
    console.log("wir setzen den stepper", !!stepper)
    this.chainStepper = stepper
  }



  getKdSetForOpening(opening: BookingOpeningL): TomKalenderDaten[] {
    const kdSetAlt: KdSetAlt = this.bookingService.multiSlots.kdSetMap.get(opening.kdSetKey)
    console.log("wir haben ein schönes kdset", kdSetAlt)
    return [...Array(kdSetAlt.kids.length).keys()].map(idx => ({ kid: kdSetAlt.kids[idx], lid: kdSetAlt.lids[idx] }))
  }


  book(payment = null) {
    this.bookingService.isLoading.next(true)
    const apps = this.createOtkAppointments()
    const query = { otkAppointments: apps, appointmentTypeId: this.bookingService.appointmentType.value?._id, otkAttachments: null, inApp: this.bookingService.isInApp }

    if (payment) {
      payment.paymentId ?
        (query['payment'] = payment) : (query['paymentDeferred'] = payment);
    }
    this.bookingService.encryptOtkAssets().pipe(
      mergeMap((otkAttachments: OtkAttachment[]) => {
        query.otkAttachments = otkAttachments
        return this.httpClient.post(environment.otkUrl + "/api/appointment/book-series", query).pipe(timeout(15000))
      })
    )
      .subscribe({
        next: (response: any) => {
          this.bookingService.bookingResult = response ? BookingResult.SUCCESS : BookingResult.FAILURE
          this.bookingService.isLoading.next(false)
          //response only has appointmentSeries property if in App to save time for in browser handling in this case send to app
          if (this.bookingService.bookingResult === BookingResult.SUCCESS && response.appointmentSeries)
            this.bookingService.sendMessageToApp(MessageToApp.BOOK, response.appointmentSeries);
          if (response)
            this.bookingService.navigateOnBookingSuccess()
          else
            this.bookingService.error = new Error('booking-series.service book failed')
        }
      })
  }



  setReservationSubscription() {
    console.log("we init reservation for series")


    // this.bookingService.bookingDateMultiFormGroup.valueChanges.subscribe({
    //   next: (value) => {
    //     console.log("bookingDateMultiFormGroup changes value", JSON.parse(JSON.stringify(value)))
    //   }
    // })

    let currentlyChosenOpening: BookingOpeningL
    let currentIndex: number
    this.bookingService.openingsMultiControl.valueChanges.pipe(
      untilDestroyed(this),
      mergeMap((value) => {
        if (!value || !value.length || this.bookingService.automaticSeriesSelection$.getValue()) return EMPTY
        currentIndex = this.chainIndex
        currentlyChosenOpening = value[currentIndex]
        return this.reservation.length > this.chainIndex ? this.cancelReservations(currentIndex) : of(null)
      }),
      mergeMap(() => this.reserve(currentlyChosenOpening, currentIndex))
    ).subscribe(() => { console.log("we reserve", currentlyChosenOpening, currentIndex) })
  }



  reserveAutomaticSelection(openings: BookingOpeningL[]) {
    if (!openings || !openings.length) return
    (this.reservation?.length ? this.cancelReservations(0) : of(null)).pipe(
      mergeMap(() => this.reserveAll(openings)))
      .subscribe(() => { console.log("we reserve", openings) })
  }



  createOtkAppointments(): Partial<OtkAppointment>[] {

    const openings: BookingOpeningL[] = this.bookingService.openingsMultiControl.value
    const appointmentType: AppointmentType = this.bookingService.appointmentType.value
    const appointmentSeriesType: AppointmentSeriesType = this.bookingService.appointmentType.value.appointmentSeriesType
    const patientData = this.bookingService.getPatientData()
    const appointments = []

    for (let i = 0; i < openings.length; i++) {
      const opening = openings[i]
      const appSeriesItem = appointmentSeriesType.items[i]
      const bs = this.bookingService.getBsForLocalities(this.multislots.kdSetMap.get(opening.kdSetKey).lids)

      let app = {
        instance: this.instanceService.activeInstance._id,
        eventType: OtkEventType.OTKAPPOINTMENT,
        start: opening.date.toISOString(), //opening.start could be offset by forerunTime
        end: opening.end,
        kdSet: this.getKdSetForOpening(opening),
        patientData,
        isPrimary: true,
        bookedOver: this.bookingService.getBookedOverType(),
        strategy: OtkBookingStrategy.AUTOCONFIRM,
        // starting from tomedo version 124, "betriebsstaette" is really the bs, not the id
        ...((this.bookingService.isBsActive && bs) ? { betriebsstaette: bs } : {}),
        name: (appointmentType as AppointmentType).name,
        description: (appointmentType as AppointmentType).description,
        dialect: (appointmentType as AppointmentType).dialect,
        terminSucheIdent: appSeriesItem?.terminSucheIdent || 'series',
        schemaVersion: '106',
        terminKetteSucheEintragIdent: appSeriesItem.terminKetteSucheEintragIdent,
        ...(appSeriesItem.forerunTime ? { forerunTime: appSeriesItem.forerunTime } : {}),
        isZuweiserTermin: !!this.bookingService.isZwCodeCorrect
      }

      appointments.push(app)
    }
    return appointments
  }


  setBs() {
    console.log("We get Bs!....")
    const openings: BookingOpeningL[] = this.bookingService.openingsMultiControl.value
    const firstOpening = openings[0]
    const bs = firstOpening ? this.bookingService.getBsForLocalities(this.multislots.kdSetMap.get(firstOpening.kdSetKey).lids) : null
    console.log("bs found", bs)
    if (bs) this.bookingService.bsDisplayed$.next(bs)
  }

  reset() {
    console.log("reset for booking series service")
  }


  loadAppointmentSeriesByCode(accessCode: string, birthDate: string) {
    return this.httpClient.post(`${environment.otkUrl}/api/appointment/load-series`, { accessCode, birthDate })
      .pipe(
        timeout(6000),
        tap((response) => console.log(response)),
        map((response: any) => ({ appointmentSeries: response.appointmentSeries, appointmentType: response.appointmentType, betriebsstaette: response.betriebsstaette }))
      )
  }


  /**
  * reservation
   */
  reserve(opening: BookingOpeningL, index: number) {

    const appointmentType: AppointmentType = this.bookingService.appointmentType.value
    const reservationTimeout = this.bookingService.globalReservationDurationS

    const query = {
      instance: this.instanceService.activeInstance._id,
      terminSucheIdent: appointmentType.appointmentSeriesType.items[index].terminSucheIdent,
      dateAppointment: opening.date.toDate(),
      duration: appointmentType.appointmentSeriesType.items[index].duration,
      dateExpiry: this.reservation.length ? this.reservation[0].dateExpiry : dayjs().add(reservationTimeout, "seconds").toDate(),
      doctorIds: this.multislots.kdSetMap.get(opening.kdSetKey).kids
    }
    return this.httpClient.post(environment.otkUrl + "/api/reservation/reserve", query).pipe(
      timeout(4000),
      mergeMap((res: any) => {
        console.log("Ergebnis der reservierung:", res)
        if (!res.reservation) {
          this.notificationService.displayNotification(this.translateService.instant('OTK.NOTIFICATIONS.APPOINTMENT-RESERVED'))
          return this.cancelReservationFrontend()
        }
        else {
          this.reservation[index] = res.reservation
          return of(null)
        }
      }))
  }

  reserveAll(openings: BookingOpeningL[]) {
    const chainLength = openings.length
    const appointmentType: AppointmentType = this.bookingService.appointmentType.value
    const reservationTimeout = this.bookingService.globalReservationDurationS

    const querys = Array.from({ length: chainLength }, (_, i) => (
      {
        instance: this.instanceService.activeInstance._id,
        terminSucheIdent: appointmentType.appointmentSeriesType.items[i].terminSucheIdent,
        dateAppointment: openings[i].date.toDate(),
        duration: appointmentType.appointmentSeriesType.items[i].duration,
        dateExpiry: this.reservation.length ? this.reservation[0].dateExpiry : dayjs().add(reservationTimeout, "seconds").toDate(),
        doctorIds: this.multislots.kdSetMap.get(openings[i].kdSetKey).kids
      }))

    return this.httpClient.post(environment.otkUrl + "/api/reservation/reserve-multi", querys).pipe(
      timeout(4000),
      mergeMap((res: any) => {
        console.log("Ergebnis der reservierung:", res)
        if (!res.reservations) {
          this.activateMoveButton$.next(false)
          this.notificationService.displayNotification(this.translateService.instant('OTK.NOTIFICATIONS.APPOINTMENT-RESERVED'))
          return this.cancelReservationFrontend()
        }
        else {
          this.reservation = res.reservations
          this.activateMoveButton$.next(true)
          return of(null)
        }
      }))
  }

  /**
   * cancel
   */
  cancelReservations(index: number) {
    const allReservationIds = this.reservation.slice(index).map(x => x._id)
    console.log("allReservationsIds", allReservationIds)
    if (!allReservationIds) return of(null)

    const query = {
      instance: this.instanceService.activeInstance._id,
      reservationIds: allReservationIds
    }

    return this.httpClient.post(environment.otkUrl + "/api/reservation/cancel-series", query)
      .pipe(timeout(4000),
        tap(() => {
          this.reservation = this.reservation.slice(0, index)
        }))
  }


  cancelReservationFrontend() {
    return this.cancelReservations(0).pipe(
      tap(() => {
        console.log("we reset because reservation frontend")
        this.chainStepper.reset()
        this.bookingService.stepperToBookingDateMulti()
      }),
      mergeMap(() => this.bookingService.initBookingMultiDate(false)))
  }


}
