<!-- Info-Banner Content Template -->
<ng-template #infoBannerContentTemplateRef>

  <!-- Link-Button -->
  <div *ngIf="hasLink" class="c-info-banner__close">
    <fa-icon [icon]="['fas', 'arrow-alt-circle-right']"></fa-icon>
  </div>

  <!-- Close-Button (Optional) -->
  <div *ngIf="!hasLink && closable" (click)="close()" class="c-info-banner__close">
    <fa-icon [icon]="['far', 'times']"></fa-icon>
  </div>

  <!-- Title (Optional) -->
  <h4 *ngIf="title || icon">
    <fa-icon *ngIf="icon" [icon]="icon" [fixedWidth]="true"></fa-icon>
    <ng-container *ngIf="title"><span>{{ title }}</span></ng-container>
  </h4>

  <!-- Content (Optional) -->
  <p>
    <ng-content></ng-content>
  </p>

</ng-template>


<!-- Info-Button with Router-Link -->
<div *ngIf="hasLink && !!routerLink && !externalLink"
  class="c-info-banner c-info-banner--isClickable {{state}} {{centeringClass}}"
  [class.c-info-banner--hasClose]="closable || showLink" [routerLink]="routerLink"
  (click)="linkClick.emit(); $event.stopPropagation();" [matTooltipDisabled]="!linkTooltip" [matTooltip]="linkTooltip">

  <ng-container *ngTemplateOutlet="infoBannerContentTemplateRef"></ng-container>

</div>

<!-- Info-Button with External-Link -->
<a *ngIf="hasLink && !routerLink && !!externalLink"
  class="c-info-banner c-info-banner--isClickable {{state}} {{centeringClass}}"
  [class.c-info-banner--hasClose]="closable || showLink" [href]="externalLink" target="_blank"
  (click)="linkClick.emit(); $event.stopPropagation();" [matTooltipDisabled]="!linkTooltip" [matTooltip]="linkTooltip">

  <ng-container *ngTemplateOutlet="infoBannerContentTemplateRef"></ng-container>

</a>

<!-- Info-Button w/o Link -->
<div *ngIf="!hasLink" class="c-info-banner {{state}} {{centeringClass}}"
  [class.c-info-banner--hasClose]="closable || showLink">

  <ng-container *ngTemplateOutlet="infoBannerContentTemplateRef"></ng-container>

</div>
