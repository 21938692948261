interface Rule {
  regex: RegExp
  replacement: string
  invRegex: RegExp
  invReplacement: string
}

const rules: Rule[] = [
  {
    regex: /\s/g,
    replacement: '.qsp.',
    invRegex: /.qsp./g,
    invReplacement: ' ',
  },
  {
    regex: /:/g,
    replacement: '.qsm.',
    invRegex: /.qsm./g,
    invReplacement: ':',
  },
  {
    regex: /;/g,
    replacement: '.qsc.',
    invRegex: /.qsc./g,
    invReplacement: ';',
  },
  {
    regex: /'/g,
    replacement: '.qsq.',
    invRegex: /.qsq./g,
    invReplacement: "'",
  },
  {
    regex: /,/g,
    replacement: '.qco.',
    invRegex: /.qco./g,
    invReplacement: ',',
  },
  {
    regex: /\[/g,
    replacement: '.qlb.',
    invRegex: /.qlb./g,
    invReplacement: '[',
  },
  {
    regex: /\]/g,
    replacement: '.qrb.',
    invRegex: /.qrb./g,
    invReplacement: ']',
  },
  {
    regex: /\//g,
    replacement: '.qfs.',
    invRegex: /.qfs./g,
    invReplacement: '/',
  },
  {
    regex: /\{/g,
    replacement: '.qlc.',
    invRegex: /.qlc./g,
    invReplacement: '{',
  },
  {
    regex: /\}/g,
    replacement: '.qrc.',
    invRegex: /.qrc./g,
    invReplacement: '}',
  },
  {
    regex: /\?/g,
    replacement: '.qqm.',
    invRegex: /.qqm./g,
    invReplacement: '?',
  },
]

export function encodeRouteParam(input: string): string {
  let res = input.replace(/[^a-zA-Z0-9\s:;',[\]/{}?]/g, '')
  rules.forEach(({ regex, replacement }) => {
    res = res.replace(regex, replacement)
  })
  return res
}

export function decodeRouteParam(input: string): string {
  let res = input
  rules.forEach(({ invRegex, invReplacement }) => {
    res = res.replace(invRegex, invReplacement)
  })
  return res
}
