<ng-container *ngIf="control != null && config != null">
  <div *ngIf="config?.display && control" class="c-form__row">
    <mat-checkbox dataSeleniumId="mat-checkbox-control" [formControl]="control" [required]="config?.required">
      <strong class="html-label">
        <span [innerHtml]="config.description | i18nString:''"></span>
        <span *ngIf="config?.required" class="isError">*</span>
      </strong>
      <mat-error *ngIf="control?.errors?.required && !control?.untouched">
        {{ "OTK.BOOKING-PERSONAL.ERRORS.CHECK-BOX" | translate }}
      </mat-error>
    </mat-checkbox>
  </div>
</ng-container>