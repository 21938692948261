import { OTKFieldConfig } from "@a-d/entities/Calendar.entity";
import { defaultBasicConfig } from "./default-settings";

/* ensure that all basic fields are present */
export function validateBasicConfig(possiblyCorrupt_: OTKFieldConfig[]): OTKFieldConfig[] {
  const possiblyCorrupt = possiblyCorrupt_ ?? [];
  const removeExtraEntries = possiblyCorrupt.filter((config) =>
    defaultBasicConfig
      .map((c) => c.identifier)
      .includes(config.identifier)
                                                   );
  const missingBasicConfigs = defaultBasicConfig.filter((c) => {
    const presentConfigIds = removeExtraEntries.map(
      (config) => config.identifier
    );
    return !presentConfigIds.includes(c.identifier);
  });
  return [...removeExtraEntries, ...missingBasicConfigs];
}
