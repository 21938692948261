import { MobileAppVersion } from "@a-d/entities/MobileApp.entity";
const major_ok = 2
const minor_ok = 3
const patch_ok = 6

/**
 * tests if Mobile AppVersion is greater than version to compare major_ok.minor_ok.patch_ok.
 * Used to date for filtering appointmentSeriesTypes for app versions which do not support it.
 */


export const terminKettenAppTest = function (v: MobileAppVersion) {
  if (!v) return false
  if (v.major < major_ok) return false
  else if (v.major > major_ok) return true
  if (v.minor < minor_ok) return false
  else if (v.minor > minor_ok) return true
  if (v.patch < patch_ok) return false
  return true
}

