import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class LogoChangeMessageService {

  private messageSource = new Subject<string>();
  public currentMessage = this.messageSource.asObservable();

  constructor() { }

  public logoChangeMessage() {
    this.messageSource.next("logo")
  }

  public resetColorMessage() {
    this.messageSource.next("color")
  }

}
