<div class="overlay-button">
  <input
    type="file"
    class="file-upload"
    (change)="importJson($event)"
    data-selenium-id="input-@support-editor/upload-file"
  />

  <span>
    Export with name
    <input type="text" [value]="jsonFilename" (input)="changeName($event)" />
  </span>

  <button
    (click)="requestWfaForm()"
    data-selenium-id="input-@support-editor/save-file"
  >
    Export as {{ jsonFilename }}.json
  </button>
</div>

<wfa-editor
  [editorEnv$]="editorEnv$"
  [requestWfaForm$]="requestWfaForm$"
  (emitWfaForm)="downloadWfaForm($event)"
></wfa-editor>
