import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { wfaVersion } from '@arzt-direkt/wfa-definitions'

@Component({
  selector: 'wfa-version',
  template: `
    <div
      class="version-text"
      (click)="showText()"
      [ngClass]="{ 'visible-text': isVisible, 'transparent-text': !isVisible }"
    >
      {{ version }}
    </div>
  `,
  styles: [
    `
      .transparent-text {
        color: rgba(0, 0, 0, 0); /* Fully transparent */
        transition: color 2s ease-in-out; /* Smooth transition */
      }

      .visible-text {
        color: rgba(0, 0, 0, 1); /* Fully opaque */
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export class VersionComponent {
  version = wfaVersion
  isVisible = false

  showText() {
    this.isVisible = true
    setTimeout(() => {
      this.isVisible = false
    }, 2000) // 2000 milliseconds = 2 seconds
  }
}
