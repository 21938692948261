import Joi from 'joi'

import { CertificateJoi } from './certificate.joi'
import { FormResponseOriginJoi } from './form-response-origin.joi'
import { IdJoi } from './id.joi'
import { PatientIdJoi } from './patient-id.joi'
import { PraxisIdJoi } from './praxis-id.joi'
import { SurveyjsResponseJoi } from './surveyjs-response.joi'
import { WfaFormIdJoi } from './wfa-form-id.joi'
import { wfaFormResponse } from './wfa-form-response.example'

export const WfaFormResponseJoi = Joi.object({
  _id: IdJoi.optional().description(
    `Autogenerated MongoId. If defined, it must be identical to 'responseId'.`,
  ),
  __v: Joi.number().optional().description(`Autogenerated Mongo Version.`),
  isDebugEntry: Joi.boolean().optional(),
  linkToCompleteSurveyInArztDirekt: Joi.string().optional(),
  origin: FormResponseOriginJoi.optional(),
  patientId: PatientIdJoi.optional(),
  praxisId: PraxisIdJoi.required(),
  responseId: IdJoi.required(),
  submittedAt: Joi.number().optional(),
  wfaFormId: WfaFormIdJoi.required(),
  formGroupId: WfaFormIdJoi.optional()
    .meta({
      _mongoose: { type: 'ObjectId' },
    })
    .description(
      [
        'Forms can be collected into groups for bulk submission.',
        'This id is set if a form is a part of a group.',
        'The id uniquely determines the group',
      ].join(' '),
    ),
  wfaFormVersionId: IdJoi.required().meta({
    _mongoose: { type: 'ObjectId', ref: 'WfaFormModel' },
  }),
  surveyjsResponse: SurveyjsResponseJoi.optional().default({}),
  certificate: CertificateJoi.optional(),
})
  .meta({
    className: 'WfaFormResponse',
  })
  .example(wfaFormResponse)
