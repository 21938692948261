import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
@UntilDestroy()
@Component({
  selector: 'app-search-field',
  styleUrls: ['./search-field.component.scss'],
  template: `
  <mat-form-field appearance="outline" class="c-search-field">
    <mat-label for="input">{{ label || 'GLOBAL.SEARCH' | translate }}</mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <input matInput id="input" type="text" [placeholder]="placeholder" [(ngModel)]="searchValue" (ngModelChange)="searchValueChange.emit(searchValue)" #searchInput>
    <button mat-icon-button matSuffix *ngIf="searchValue" (click)="$event.stopPropagation(); searchValue = ''; searchValueChange.emit(searchValue);">
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-form-field>
  `,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    NgIf,
    MatButtonModule,
    TranslateModule,
  ],
})
export class SearchFieldComponent implements OnInit {

  @Input() label: string
  @Input() placeholder: string
  @Input() searchValue: string
  @Output() searchValueChange = new EventEmitter<string>()

  constructor() { }

  ngOnInit(): void {
  }
}
