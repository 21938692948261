import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PageSizeService {

  public windowSize: number;
  public isMobile: boolean;
  public hideMainMenu: boolean;
  public globalHeight = 0
  public globalWidth = 0

  public hideMainMenuSubject = new Subject<boolean>();

  constructor() { }

  checkIfMobile(windowSize: number) {
    this.windowSize = windowSize;
    this.isMobile = windowSize <= 960 ? true : false;
    this.hideMainMenu = windowSize <= 1200 ? true : false;
    this.hideMainMenuSubject.next(this.hideMainMenu);
  }
  setSizes(windowWidth: number, windowHeight: number) {
    document.documentElement.style.setProperty('--windowHeight', windowHeight.toString() + 'px');
    document.documentElement.style.setProperty('--windowWidth', windowWidth.toString() + 'px');
    this.globalHeight = windowHeight;
    this.globalWidth = windowWidth;
  }

  setStyleVar(name: string, variable: number) {
    document.documentElement.style.setProperty(name, variable.toString() + 'px');
  }
  setStyleVarString(name: string, variable: string) {
    document.documentElement.style.setProperty(name, variable);
  }

}
