import { AngularComponentFactory } from 'survey-angular-ui'
import { PropertyGridEditorCollection } from 'survey-creator-core'

import { HtmlEditorComponent } from './html-editor-component/html-editor.component'
import { QUESTION_TYPE } from './question-type'

export function registerHtmlEditorComponent(): void {
  registerComponentInAngularComponentFactory()
  registerComponentInPropertyGrid()
}

/**
 * Register the component in `AngularComponentFactory`
 */
function registerComponentInAngularComponentFactory(): void {
  AngularComponentFactory.Instance.registerComponent(
    QUESTION_TYPE + '-question',
    HtmlEditorComponent,
  )
}

/**
 * Register component as an editor for property `html` in the Survey Creator's Property Grid
 */
function registerComponentInPropertyGrid(): void {
  PropertyGridEditorCollection.register({
    fit: function (prop) {
      return prop.type == 'html'
    },
    getJSON: function () {
      return { type: QUESTION_TYPE }
    },
  })
}
