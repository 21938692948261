import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'versionCheck',
    standalone: true
})
export class VersionCheckPipe implements PipeTransform {

  transform(tomedoVersion: string, minimumVersion: number): boolean {
    if (!tomedoVersion) { return false; }
    return parseInt(tomedoVersion, 10) >= minimumVersion;
  }

}
