import Joi from 'joi'

import { TomedoVersionJoi } from './tomedo-version.joi'

export const dummyGetInstance = () => ({
  tomedoVersion: process.env?.TOMEDO_VERSION ?? 'latest',
  // no default values
})

export const WfaInstanceSliceJoi = Joi.object()
  .keys({
    tomedoVersion: TomedoVersionJoi.optional(),
  })
  .description(
    'A partial version of the Arzt-Direkt Instance Object. Is set optionally by the Arzt-Direkt frontend.',
  )
  .meta({ className: 'WfaInstanceSlice' })
