import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { DayOfWeek } from "@a-d/entities/Calendar.entity"
import { BaseLanguage } from '@a-d/entities/I18N.entity'
import { Dialect } from "@a-d/entities/Instance.entity"
import { StepperSelectionEvent } from "@angular/cdk/stepper"
import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, SlicePipe } from "@angular/common"
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core'
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormGroup } from '@angular/forms'
import { MatButtonToggleModule } from "@angular/material/button-toggle"
import { DateAdapter, MatOptionModule } from '@angular/material/core'
import { MatCalendar, MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatSelectModule } from "@angular/material/select"
import { MatStepper, MatStepperModule } from "@angular/material/stepper"
import { environment } from "@env/environment"
import { NotificationService } from "@lib/notifications/notification.service"
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import dayjs from 'dayjs'
import { Subscription, of } from 'rxjs'
import { distinctUntilChanged, filter, mergeMap } from 'rxjs/operators'
import { AppointmentSeriesTypeItem, AppointmentType, Betriebsstaette, BookingOpening, BookingOpeningL, BookingOpeningLInject, BookingStep, I18NString, OtkDoctor } from '../../entities/Booking.entity'
import { I18NStringPipe } from "../../i18n/i18n.pipe"
import { LanguageService } from '../../i18n/language.service'
import { CustomDateAdapter } from '../booking-date/calendar-custom-date-adapter/calendar-custom-date-adapter.component'
import { CalendarHeaderComponent } from '../booking-date/calendar-header/calendar-header.component'
import { BookingSeriesService } from "../booking-series.service"
import { BookingService } from '../booking.service'

enum SlotStyle { onlyDoctor, onlyBs, both, nothing }

const LEFTALIGNSLOTS = 20

@UntilDestroy()
@Component({
  selector: 'app-booking-date-multi',
  templateUrl: './booking-date-multi.component.html',
  styleUrls: ['./booking-date-multi.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, MatStepperModule, NgFor, MatDatepickerModule, MatIconModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatInputModule, MatButtonToggleModule, NgSwitch, NgSwitchCase, NgSwitchDefault, AsyncPipe, SlicePipe, DatePipe, TranslateModule, I18NStringPipe]
})
export class BookingDateMultiComponent implements OnInit, AfterViewInit {
  @ViewChildren('calendar') calendar: QueryList<MatCalendar<Date>> // missing official documentation, see https://onthecode.co.uk/angular-material-calendar-component/
  @ViewChild('chainStepper') chainStepper: MatStepper
  openingsFilteredMulti: BookingOpeningL[][] = [[], [], []]
  @Input() currentOpenings: BookingOpeningLInject[]
  public failureAssetUrl = `${environment.url}/assets/otk/booking/failure.svg`


  // onlyOneVisibleDoctor: if after doctor filtering (after selecting the day) there is only one
  // visible doctor, don't show "beliebig" or the "x" button, disable doctor selection and select that one doctor.
  onlyOneVisibleDoctor: boolean
  initBookingDateSubscription: Subscription
  filterSubscription: Subscription
  filterSubscriptionDoctor: Subscription
  calendarSelectionSuscription: Subscription
  subscriptionMagicFill: Subscription
  calendarMinDate: Date
  calendarMaxDate: Date
  calendarSelectedDates: (Date | null)[] = [null, null, null]
  calendarMonthArrowButtons // contains references to the arrow buttons for switching the month in the desktop version of the calendar
  calendarHeaderComponent = CalendarHeaderComponent
  dayError: string
  showDoctors: boolean // should probably actually be called: showDoctorsInOpeningStrings
  allowDoctorChoice: boolean // should probably actually be called: showDoctorMenu
  doctorPhoto: string
  displayLimit: number
  urgentAppointment: boolean
  appointmentTypeName: I18NString
  slotStyle = SlotStyle
  usedSlotStyle = SlotStyle.nothing
  maxBsStringSize = 0
  leftAlignSlots = false
  leftAlignSlotsDoctors = false
  handlerName: string
  forerunTime: number[] = []
  firstCompleted: boolean[] = []
  localdayjs = dayjs
  doctorsArray = this.multiSlots.doctorArray
  bsArray = this.multiSlots.bsArray
  doctorSelectionArray = this.multiSlots.doctorsPerSeriesItems
  mobileCalFilters = []
  timezone: string

  bsFilterActive = false
  bsSelected: Betriebsstaette = null
  appointmentChain: AppointmentSeriesTypeItem[] = []
  appointmentChainDescription = ["Mo. 12.03.2023", "Noch nicht gewählt", "Noch nicht gewählt"]
  chainLength: number = this.bookingSeriesService.chainLength
  showNoSlotWarning = []

  openingBsStrings: string[] = [] //bs infos for the buttons

  public errorTitle = this.translateService.instant('OTK.BOOKING-DATE.TITLE-ERROR')

  get day(): AbstractControl { return this.days.at(this.chainStepper?.selectedIndex || 0) }
  get doctor(): AbstractControl { return this.doctors.at(this.chainStepper?.selectedIndex || 0) }

  /** doctorsVisible && doctorsInvisible:
  * doctors without a Nutzer get filtered out in backend.
  * the doctors received in frontend are divided into doctorsVisible and doctorsInvisible.
  * doctorsVisible are doctors with titel, vorname or nachname.
  * these doctors should be displayed in the doctors dropdown menu (if allowDoctorChoice),
  * be shown in the opening strings (if showDoctors) and have their openings displayed to the user.
  * doctorsInvisible are doctors with titel===vorname===nachname===''.
  * these doctors should not be displayed in the doctors dropdown menu or in the opening strings,
  * (because there is nothing to display) but their openings should still be displayed to the user.
  * There is no "invisible doctors" filter - The user can only filter according to visible doctors,
  * such that when filtering according to any doctor, all openings offered only by invisible doctors
  * will be filtered out
  */



  get bookingDateMultiFormGroup(): UntypedFormGroup { return this.bookingService.bookingDateMultiFormGroup }
  get days(): UntypedFormArray { return this.bookingDateMultiFormGroup.get('days') as UntypedFormArray }
  get doctors(): UntypedFormArray { return this.bookingDateMultiFormGroup.get('doctors') as UntypedFormArray }
  get openingsFormArray(): UntypedFormArray { return this.bookingDateMultiFormGroup.get('openings') as UntypedFormArray }



  get doctorsVisible(): OtkDoctor[] {
    const doctorsSortedByLastName = this.bookingService.doctorsVisible
      .sort((a, b) => a.fullName.split(" ").slice(-1)[0]
        .localeCompare(b.fullName.split(" ").slice(-1)[0]))
    return doctorsSortedByLastName
  }
  get doctorsInvisible(): OtkDoctor[] { return this.bookingService.doctorsInvisible }
  get openings(): BookingOpening[] { return this.bookingService.openings }
  get openingsMulti(): BookingOpeningL[][] { return this.bookingService.multiSlots.openingsMulti }
  get openingsMultiPruned(): BookingOpeningL[][] { return this.bookingService.multiSlots.openingsMultiPruned }
  get multiSlots() { return this.bookingService.multiSlots }


  // openingsExist:
  // it is possible to select appointment types with no openings, either if
  // 1. appointmentType.selectableIfNoOpenings === true
  // 2. the appointment type was reached via parametrization
  // in this case the appropriate message should be shown to the user
  openingsExist = true // start with true otherwise the error message shows for a moment in the beginning
  textNoOpenings: I18NString = { de: '', en: '', fr: '', it: '', es: '' }// message to show if !openingsExist
  openingsDisplayStringsSubscription: Subscription
  baseLanguageSubscription: Subscription
  baseLanguage: BaseLanguage

  constructor(
    private adLoggerService: AdLoggerService,
    public bookingService: BookingService,
    public bookingSeriesService: BookingSeriesService,
    private cd: ChangeDetectorRef,
    private renderer2: Renderer2,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private calendarCustomDateAdapter: DateAdapter<Date>,
    private changeDetectorRef: ChangeDetectorRef,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.initBookingDateSubscribe()
    this.filterSubscribe()
    this.setCalendarDayOfWeekAndLang()

    this.appointmentChain = this.bookingService.appointmentType.value?.appointmentSeriesType?.items || []

    this.openingsFormArray.valueChanges.subscribe(
      () => {
        //if (!value?.some(x => !!x)) return
        if (this.bookingService.automaticSeriesSelection$.getValue()) {
          const selectedOpenings = this.multiSlots.selectAllOpenings(this.openingsFormArray.at(0).value)
          if (!this.handleAllSelection(selectedOpenings)) return
          this.bookingSeriesService.nextLast()
          return
        }

        this.multiSlots.selectOpening(this.openingsFormArray.at(this.chainStepper.selectedIndex).value, this.chainStepper.selectedIndex)

        //console.log("also value changes", this.chainStepper.selectedIndex !== this.appointmentChain.length - 1, !value?.[this.chainStepper.selectedIndex])
        this.handleOpeningsSelection(this.chainStepper.selectedIndex)

        if (this.openingsFormArray.valid) this.bookingSeriesService.setBs()

        if (!(this.chainStepper.selectedIndex === this.appointmentChain.length - 1)) {
          this.calendar.get(this.chainStepper.selectedIndex + 1).updateTodaysDate()
        }

        if (this.chainStepper.selectedIndex !== this.appointmentChain.length - 1 && !this.firstCompleted[this.chainStepper.selectedIndex]) {
          //prune openings

          this.firstCompleted[this.chainStepper.selectedIndex] = true
          this.chainStepper.next()
        }
        else if (this.chainStepper.selectedIndex === this.appointmentChain.length - 1 && !this.firstCompleted[this.chainStepper.selectedIndex]) {
          this.firstCompleted[this.chainStepper.selectedIndex] = true
          this.bookingSeriesService.nextLast()
        }
        else {
          this.buttonCheck(this.chainStepper.selectedIndex)
        }
      })

    // this.days.valueChanges.subscribe((value) => {
    //   console.log("we get new value for day", JSON.parse(JSON.stringify(value)))
    // })

    this.bookingService.seriesMode$.pipe(
      untilDestroyed(this),
      filter((x) => !!x))
      .subscribe({
        next: () => {
          this.appointmentChain = this.bookingService.appointmentType.value?.appointmentSeriesType?.items || []
        }
      })

    this.magicFillSubscribe()

    this.timezone = this.bookingService.otkUser.timezone
  }





  // sets the language and first day of the week of the calendar.
  // I couldn't get this to work as an internal function of calendar-custom-date-adapter
  // because it requires usage of the language service. For that I need to inject it into the constructor
  // and I couldn't, not even after getting the "super" call right.
  setCalendarDayOfWeekAndLang() {
    (this.calendarCustomDateAdapter as CustomDateAdapter).firstDayOfWeek = this.bookingService.otkUser?.firstDayOfWeek
      ? this.bookingService.otkUser.firstDayOfWeek
      : DayOfWeek.MONDAY
    this.calendarCustomDateAdapter.setLocale(this.languageService.activeBaseLang)
  }

  ngAfterViewInit() {
    //this.setCalendar(true)
    //trigger recalc header height in case no booking start booking start is skipped
    this.bookingSeriesService.setStepper(this.chainStepper)
    this.bookingService.recalcHeaderHeight$.next(true)
  }


  setCalendar() {

    // Attention: setting cal min max date triggers value change attention !!!
    this.calendarMinDate = new Date()
    this.calendarMaxDate = dayjs(this.calendarMinDate).add(this.bookingService.MAXIMAL_ALLOWED_BOOKING_IN_ADVANCE_DAYS, 'days').toDate()
    for (let i = 0; i < this.appointmentChain.length; i++) {
      this.calendar.get(i).dateFilter = this.dateFilter.bind(this, i)
      this.mobileCalFilters[i] = this.dateFilter.bind(this, i)
    }
  }
  /**
    * dirty solution alert: "calendarMinDate" is set twice with a "delay of 0"
    * in order to force re - rendering of the calendar element, which also calls
    * this.getCalendarDateClass() again.
    */
  rerenderCalendar() {
    this.calendarMinDate = new Date()
    setTimeout(() => this.calendarMinDate = null, 0)
  }

  /**
   * called when a date is selected using the desktop version of the calendar.
   * Note that this is not called by default when the patient clicks on the arrows,
   * see https://onthecode.co.uk/angular-material-calendar-component/
   */
  onCalendarSelectDate(event) {
    this.days.at(this.chainStepper.selectedIndex).setValue(event)
    this.bookingService.updateMiniSummaryStrings()
    //this.rerenderCalendar()
  }



  /**
   * called when the month is changed using the desktop version of the calendar.
   * Reset the day, to prevent the case where the day from the previous month is still selected.
   */
  onCalendarSelectMonth() {
    this.day.reset()
    this.bookingService.updateMiniSummaryStrings()
    this.rerenderCalendar()
    this.cd.detectChanges() // otherwise the openings won't disappear
  }

  /**
   * initially as well as upon changes to booking type (the previous step),
   * get the doctors and openings for that booking type,
   * display the booking type name, select the first date,
   * select the doctor if theres only one, and perform magic fill
   * if requested.
   * Note that it is important to end subscriptions before setting them again
   * otherwise they will be set double here.
   */
  initBookingDateSubscribe() {
    if (this.initBookingDateSubscription)
      this.initBookingDateSubscription.unsubscribe()
    this.initBookingDateSubscription =
      this.bookingService.initBookingDateMulti$
        .pipe(
          untilDestroyed(this),
          mergeMap(() => {
            this.chainStepper.reset()
            this.onlyOneVisibleDoctor = Object.keys(this.bookingService.doctorsVisible).length === 1
            this.displayLimit = (this.bookingService.appointmentType.value as AppointmentType).displayLimit
            this.urgentAppointment = (this.bookingService.appointmentType.value as AppointmentType).urgentAppointment
            this.showDoctors = (this.bookingService.appointmentType.value as AppointmentType)?.showDoctors
            this.bsFilterActive = this.bookingService.isBsActive
            this.bsSelected = this.bookingService.bs.value !== this.bookingService.bsAll ? this.bookingService.bs.value : null
            this.forerunTime = this.appointmentChain.map(x => x.forerunTime || 0)
            this.usedSlotStyle = this.chooseSlotStyle(this.showDoctors, this.bsFilterActive && !this.bsSelected)
            this.allowDoctorChoice = (this.bookingService.appointmentType.value as AppointmentType).allowDoctorChoice
            const baseLanguage: BaseLanguage = this.languageService.activeBaseLang
            this.openingsExist = !!this.openingsMulti[0].length
            if (!this.openingsExist) {
              this.textNoOpenings = (this.bookingService.appointmentType.value as AppointmentType).textNoOpenings
                ? (this.bookingService.appointmentType.value as AppointmentType).textNoOpenings
                : null
              this.translateErrorMessage(baseLanguage);
            }
            const appointmentType: AppointmentType = this.bookingService.appointmentType.value as AppointmentType
            if (appointmentType) {
              this.appointmentTypeName = appointmentType.name
              const dialectAppointmentType: Dialect = appointmentType.dialect
                ? appointmentType.dialect
                : this.bookingService.dialectDefault
              this.languageService.setActiveDialect(dialectAppointmentType)
              this.handlerName = this.translateService.instant('OTK.HANDLER-SINGULAR')
            }
            return of(null)
          }),
        ).subscribe(() => {
          if (this.currentOpenings) this.multiSlots.injectOpenings(this.currentOpenings)
          this.selectFirstDay(0)
          this.setCalendar()
          this.cd.detectChanges()
          this.calendar.get(0).updateTodaysDate()
        })
  }

  public chooseSlotStyle(showDocs, showBs) {
    if (showDocs && showBs) return SlotStyle.both
    if (showDocs) return SlotStyle.onlyDoctor
    if (showBs) return SlotStyle.onlyBs
    return SlotStyle.nothing
  }

  private translateErrorMessage(baseLanguage: BaseLanguage) {
    const baseTranslation = this.textNoOpenings[this.languageService.DEFAULT_BASE_LANG];
    if (!this.textNoOpenings) {
      this.errorTitle = this.translateService.instant('OTK.BOOKING-DATE.TITLE-ERROR');
      return;
    }
    switch (this.textNoOpenings[baseLanguage]) {
      case '':
      case this.bookingService.I18NString_MISSING_VALUE:
        if (baseTranslation && baseTranslation !== this.bookingService.I18NString_MISSING_VALUE) {
          this.errorTitle = baseTranslation;
          return
        }
        this.errorTitle = this.translateService.instant('OTK.BOOKING-DATE.TITLE-ERROR');
        return
      default:
        this.errorTitle = this.textNoOpenings[baseLanguage];
    }
  }

  /**
   * filter doctor, day and opening as follows:
   * doctor filtering:
   */
  filterSubscribe() {
    if (this.filterSubscription)
      this.filterSubscription.unsubscribe()
    this.filterSubscription =
      // day selected => filter the openings
      this.days.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged((prev, current) => prev[this.chainStepper.selectedIndex] === current[this.chainStepper.selectedIndex])).
        subscribe(() => {
          this.filterOpenings(this.chainStepper.selectedIndex)
          this.buttonCheck(this.chainStepper.selectedIndex)
        })

    if (this.filterSubscriptionDoctor)
      this.filterSubscriptionDoctor.unsubscribe()
    this.filterSubscriptionDoctor =
      this.doctors.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.filterOpenings(this.chainStepper.selectedIndex)
          this.buttonCheck(this.chainStepper.selectedIndex)
        })
  }

  setDayError() {
    if (this.day.touched && this.day.errors?.dateInvalid)
      this.dayError = this.translateService.instant('OTK.STEPPER.DATE-FORMAT-HINT')
    else if (this.day.errors?.dateIsInThePast)
      this.dayError = this.translateService.instant('OTK.STEPPER.DATE-FUTURE-HINT')
    else if (this.day.errors?.matDatepickerFilter)
      this.dayError = this.translateService.instant('OTK.STEPPER.DATE-AVAILABILITY-HINT')
    else if (this.day.errors?.getDateMoreThanGivenDaysInTheFutureValidator)
      this.dayError = this.translateService.instant('OTK.BOOKING-DATE.ERROR.DAY.TOO-FAR-IN-THE-FUTURE')
    else
      this.dayError = ''
  }



  /**
   * selects the first day for which there are openings
   */
  selectFirstDay(idx: number) {
    const firstOpening: BookingOpeningL = this.openingsMultiPruned[idx]
      .find((opening) => this.dateFilter(idx, opening.date as unknown as Date))
    if (firstOpening === undefined) {
      this.adLoggerService.error("selectFirstDay: selected doctor has no openings")
      this.showNoSlotWarning[idx] = true
      this.days.at(idx).reset()
      return
    }
    const firstDay: Date = firstOpening.date.startOf("day").toDate() // convert to start of day in date format
    // this.calendarSelectedDates[0] = firstDay

    // this.calendar.get(0).activeDate = firstDay    //changes to month view


    this.calendarSelectedDates[idx] = firstDay
    this.calendar.get(idx).activeDate = firstDay
    this.cd.detectChanges()
    // This probably leads to triggering value change of this.days.value changes and filtering openings again. Maybe you can leave the filterOpenings next line or try to set this.days.setValue So maybe it will not trigger this.days.valueChanges
    this.days.at(idx).setValue(firstDay, { emitEvent: false })
    this.filterOpenings(idx) // maybe not necessary see above
  }


  //For automatic selection
  handleAllSelection(selectedOpenings: BookingOpeningL[]) {
    if (selectedOpenings?.length !== this.chainLength) {
      this.adLoggerService.error("could not select openings automatically")
      this.notificationService.displayNotification('Dieser Termin scheint reserviert zu sein. Bitte probieren Sie einen anderen Termin oder warten Sie 10 minuten und laden die Seite neu.')
      this.bookingService.resetMultiDateFormGroup()
      this.bookingSeriesService.activateMoveButton$.next(false)
      return false
    }
    this.openingsFormArray.setValue(selectedOpenings, { emitEvent: false })
    this.bookingSeriesService.reserveAutomaticSelection(selectedOpenings)
    if (this.openingsFormArray.valid) this.bookingSeriesService.setBs()
    return true
  }


  /**
   * Filters the openings according to what is selected by the user:
   */
  filterOpenings(chainIndex: number) {
    this.openingsFilteredMulti[chainIndex] = []

    const doc = this.doctors.at(chainIndex).value
    if (!doc) {
      this.openingsFilteredMulti[chainIndex] = this.openingsMultiPruned[chainIndex]
        .filter((bookingOpening: BookingOpeningL) =>
          bookingOpening.date.add(this.forerunTime[chainIndex], 'minute').isSame(dayjs(this.days.at(chainIndex).value), 'day'))
    }
    else {
      this.openingsFilteredMulti[chainIndex] = this.openingsMultiPruned[chainIndex]
        .filter((bookingOpening: BookingOpeningL) =>
          bookingOpening.date.add(this.forerunTime[chainIndex], 'minute').isSame(dayjs(this.days.at(chainIndex).value), 'day')
          && this.multiSlots.isDoctorForSlot(bookingOpening, doc))
    }

    // akut modus etc 
    if (this.urgentAppointment) {
      this.openingsFilteredMulti[chainIndex] = this.openingsFilteredMulti[chainIndex].slice(0, 1)
    } else if (!!this.displayLimit) {
      this.openingsFilteredMulti[chainIndex] = this.bookingService.filterEquidistantEntries(0, this.openingsFilteredMulti[chainIndex].length - 1, this.displayLimit, this.openingsFilteredMulti[chainIndex]);
    }


    //some ordering 
    this.openingsFilteredMulti[chainIndex] = this.openingsFilteredMulti[chainIndex].sort(
      (a, b) => a.date.unix() - b.date.unix())

    //offest
    if (this.forerunTime[chainIndex]) {
      this.openingsFilteredMulti[chainIndex] = this.openingsFilteredMulti[chainIndex].map((op: BookingOpeningL) => ({ ...op, start: op.date.add(this.forerunTime[chainIndex], 'minute').toISOString() }))
    }

    if (!this.openingsFilteredMulti[chainIndex].includes(this.openingsFormArray.at(chainIndex).value))
      this.openingsFormArray.at(chainIndex).setValue(null, { emitEvent: false })
  }


  // includeBsString
  public createBsStrings(openings: BookingOpening[]) {
    this.openingBsStrings = []
    this.maxBsStringSize = 0
    if (!openings || openings.length === 0) return
    for (let i = 0; i < openings.length; i++) {
      const locs = openings[i].kdSet.map(x => x.lid)
      const bs = this.bookingService?.betriebsstaetten ? this.bookingService.betriebsstaetten.find((x: Betriebsstaette) => locs.some(y => x.localityIdents.includes(y))) : null
      this.openingBsStrings.push(bs?.name ?? "")
      if (bs?.name.length > this.maxBsStringSize) this.maxBsStringSize = bs?.name.length
    }
    this.leftAlignSlots = this.maxBsStringSize > LEFTALIGNSLOTS
  }



  /**
   * filter the available calendar dates (days) to:
   * 1. Days not in the past
   * 2. if(doctor) days for which the selected doctor has at least one opening.
   */
  public dateFilter = (chainIndex: number, date: Date): boolean => {
    // filter out past dates
    if (dayjs(date).isBefore(dayjs().startOf('day')))
      return false
    // if booking openings not yet calculated, enable all dates
    if (!this.openingsMultiPruned?.[chainIndex])
      return true

    //try for performance 
    //if (this.openingsMultiPruned[chainIndex].at(-1)?.date?.isBefore(date) || this.openingsMultiPruned[chainIndex][0]?.date?.isAfter(date)) return false

    // if no doctor was selected don't filter according to a specific doctor.
    // Filter to days for which any doctor has at least one opening.
    // see https://zollsoft.atlassian.net/browse/ADI-1210
    // if (!this.doctor.value)
    return this.openingsMultiPruned[chainIndex]
      .some((bookingOpening: BookingOpeningL) =>
        bookingOpening.date.add(this.forerunTime[chainIndex], 'minute')
          .isSame(dayjs(date), "day"))
    // a doctor was selected. Filter to days for which the selected doctor
    // has at least one opening on that day.
    // return this.openingsMulti[chainIndex]
    //   .filter((bookingOpening: BookingOpening) =>
    //     // the opening is offered by the selected doctor
    //     bookingOpening.kdSet
    //       .map((tomKalendarDaten: TomKalenderDaten) =>
    //         tomKalendarDaten.kid)
    //       .some(x => (this.doctor.value as OtkDoctor).kids.includes(x))
    //   )
    //   // the booking is offered on the day in question
    //   .some((bookingOpening: BookingOpening) =>
    //     bookingOpening.date.add(this.forerunTime, 'minute')
    //       .isSame(dayjs(date), "day"))
  }

  public reFilter() {
    for (let i = 0; i < this.appointmentChain.length; i++) {
      this.calendar.get(i).dateFilter = this.dateFilter.bind(this, i)
    }
  }




  // called when clicking on the "x"
  resetDoctor($event: MouseEvent) {
    this.doctor.setValue(undefined)
    // prevent the doctor selection menu from opening up again automatically
    // right after clicking the x button
    $event.stopPropagation()
  }



  onStepChange(event: StepperSelectionEvent) {
    if (event.previouslySelectedIndex !== event.selectedIndex)
      this.handleDays(event.selectedIndex)
    this.buttonCheck(event.selectedIndex)
    this.bookingSeriesService.chainIndex$.next(event.selectedIndex)
  }


  //disable next button if not valid
  buttonCheck(currentIndex: number) {
    this.bookingService.isNextButtonDisabled$.next(!this.openingsFormArray.at(currentIndex).valid)
  }



  handleOpeningsSelection(idx: number) {
    if (this.days.value[idx + 1]) {
      this.resetForms(idx)
      this.resetCalendar(idx)
    }
    if (this.showNoSlotWarning[idx + 1]) this.showNoSlotWarning[idx + 1]
  }

  handleDays(idx: number) {
    if (!this.days.at(idx).value) this.selectFirstDay(idx)
  }

  resetForms(idx: number) {
    for (let i = idx + 1; i < this.chainLength; i++) {
      this.days.at(i).setValue(null, { emitEvent: false })
      this.openingsFormArray.at(i).setValue(null, { emitEvent: false })
    }
  }

  resetCalendar(idx: number) {
    for (let i = idx + 1; i < this.chainLength; i++) {
      this.calendar.get(i).updateTodaysDate()
    }
    this.cd.detectChanges()
  }

  // perform magic fill (fill the fields with preset values)
  magicFillSubscribe() {
    this.bookingService.magicFill$
      .pipe(untilDestroyed(this),
        filter(bookingStep => bookingStep === BookingStep.bookingDateMulti))
      .subscribe({
        next: () => this.magicFill()
      })
  }




  magicFill() {
    this.selectFirstDay(0)
    const selectedOpening = this.openingsFilteredMulti?.[0]?.[0]
    this.openingsFormArray.at(0).setValue(selectedOpening, { emitEvent: false })
    if (!selectedOpening) {
      this.adLoggerService.error("Problem with magicFill")
      return
    }
    const allSelectedOpenings = this.multiSlots.selectAllOpenings(selectedOpening)
    this.handleAllSelection(allSelectedOpenings)
    this.bookingSeriesService.nextLast()
    this.bookingService.magicFill$.next(BookingStep.bookingPersonal)
  }


}
