import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { TranslateModule } from '@ngx-translate/core'

import { LimitDialogComponent } from './../../editor/customization/toolbox-customization/handle-limited-question-types/limit-dialog/limit-dialog.component'

@Component({
  standalone: true,
  selector: 'app-test-limit-dialog-component',
  template: `<button (click)="openDialog()">Open LimitDialogComponent</button>`,
  imports: [CommonModule, MatDialogModule, MatButtonModule, TranslateModule],
})
export class TestLimitDialogComponent {
  constructor(private dialog: MatDialog) {}

  openDialog() {
    this.dialog.open(LimitDialogComponent, {
      autoFocus: false,
      data: {
        locale: 'de',
      },
    })
  }
}
