@if (zsSupportService.praxisDetails$.value !== undefined) {
  <mat-card>
    <mat-card-title data-selenium-id="mat-card-title-@unlock-form/title">{{
      'WFA.UNLOCK-FORM.TITLE' | translate
    }}</mat-card-title>
    <mat-card-content>
      <div class="radio-container">
        <mat-radio-group
          [formControl]="unlockModeControl"
          aria-label="Select an option"
        >
          <mat-radio-button [value]="unlockModeDict.fromMongoDb">
            <mat-label>
              {{ 'WFA.UNLOCK-FORM.UNLOCK-USING-ID' | translate }}
            </mat-label>

            @if (unlockModeControl.value === unlockModeDict.fromMongoDb) {
              <!-- praxis-select -->
              <mat-form-field appearance="outline">
                <mat-label>{{
                  'WFA.UNLOCK-FORM.SELECT-PRAXIS-LABEL' | translate
                }}</mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="praxisControl"
                  [matAutocomplete]="autoPraxisSelect"
                  data-selenium-id="input-@unlock-form/praxis-control"
                />
                @if (praxisControl.value) {
                  <button
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="clearInputValue(praxisControl)"
                  >
                    <fa-icon [icon]="faXmark"></fa-icon>
                  </button>
                }
                <mat-autocomplete
                  #autoPraxisSelect="matAutocomplete"
                  (optionSelected)="onKennungSelect($event)"
                >
                  <mat-option
                    *ngFor="let option of filteredPraxisDetails$ | async"
                    [value]="option.identifier"
                    data-selenium-id="mat-option-@unlock-form/praxis-select-option"
                  >
                    {{ option.identifier }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <!-- form-info-select -->
              <mat-form-field appearance="outline">
                <mat-label>{{
                  'WFA.UNLOCK-FORM.SELECT-FORM-LABEL' | translate
                }}</mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="formInfoControl"
                  [matAutocomplete]="autoFormInfoSelect"
                  data-selenium-id="input-@unlock-form/form-info-control"
                />
                @if (formInfoControl.value) {
                  <button
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="clearInputValue(formInfoControl)"
                  >
                    <fa-icon [icon]="faXmark"></fa-icon>
                  </button>
                }
                <mat-autocomplete
                  #autoFormInfoSelect="matAutocomplete"
                  (optionSelected)="onFormInfoSelect($event)"
                >
                  <mat-option
                    *ngFor="let option of filteredFormInfos$ | async"
                    [value]="option.wfaFormId"
                    data-selenium-id="mat-option-@unlock-form/form-info-option"
                  >
                    {{ option.title | i18n }} ({{ option.wfaFormId }})
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <!-- form-version-select -->
              <mat-form-field appearance="outline">
                <mat-label>{{
                  'WFA.UNLOCK-FORM.SELECT-FORM-VERSION-LABEL' | translate
                }}</mat-label>
                <mat-select
                  [formControl]="formVersionControl"
                  data-selenium-id="select-@unlock-form/form-version-control"
                >
                  <mat-option
                    *ngFor="let option of allFormVersionInfos$ | async"
                    [value]="option.wfaFormVersionId"
                    data-selenium-id="mat-option-@unlock-form/form-version-option"
                  >
                    {{ option.title | i18n }} ({{
                      mongoIdToLocaleString(id2String(option.wfaFormVersionId))
                    }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
            }
          </mat-radio-button>
          <mat-radio-button [value]="unlockModeDict.uploadedByUser">
            <mat-label>
              {{ 'WFA.UNLOCK-FORM.UNLOCK-USING-UPLOAD' | translate }}
            </mat-label>

            @if (unlockModeControl.value === unlockModeDict.uploadedByUser) {
              <input
                type="file"
                class="file-upload"
                (change)="ufImportJson($event)"
              />
            }
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- new-wfa-form-id-input -->
      <mat-form-field appearance="outline">
        <mat-label>{{
          'WFA.UNLOCK-FORM.SELECT-NEW-WFA-FORM-ID' | translate
        }}</mat-label>
        <input
          type="text"
          matInput
          [formControl]="newWfaFormIdControl"
          data-selenium-id="input-@unlock-form/new-wfa-form-id-control"
        />
        @if (newWfaFormIdControl.value) {
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearInputValue(newWfaFormIdControl)"
          >
            <fa-icon [icon]="faXmark"></fa-icon>
          </button>
        }
        @if (newWfaFormIdControl.hasError('required')) {
          <mat-error>{{
            'WFA.UNLOCK-FORM.SELECT-NEW-WFA-FORM-ID-REQUIRED' | translate
          }}</mat-error>
        }
        @if (newWfaFormIdControl.hasError('forbiddenValue')) {
          <mat-hint>{{
            'WFA.UNLOCK-FORM.SELECT-NEW-WFA-FORM-ID-EXISTS' | translate
          }}</mat-hint>
        }
      </mat-form-field>

      <button mat-button aria-label="Submit" (click)="ufSubmitUnlock()">
        {{ 'WFA.UNLOCK-FORM.SUBMIT' | translate }}
      </button>

      <button
        mat-button
        aria-label="Publish standard forms"
        (click)="publishStandardForms()"
        data-selenium-id="button-@unlock-form/publish-standard-forms"
        style="margin-bottom: 1em"
      >
        {{ 'WFA.UNLOCK-FORM.PUBLISH-STANDARD-FORMS' | translate }}
      </button>
    </mat-card-content>
  </mat-card>
}
