import Joi from 'joi'

import { IsEmbeddedIn } from './../interfaces-autogen-from-joi/is-embedded-in'
import { TargetBuild } from './../interfaces-autogen-from-joi/target-build'

export const isEmbeddedInDict: Record<IsEmbeddedIn, IsEmbeddedIn> = {
  arztDirekt: 'arztDirekt',
  iOsTomedo: 'iOsTomedo',
  macOsTomedo: 'macOsTomedo',
  adStepper: 'adStepper',
  adMultilinkViewer: 'adMultilinkViewer',
  adPermalinkViewer: 'adPermalinkViewer',
} as const

export const isEmbeddedIns = Object.values(isEmbeddedInDict)

export const IsEmbeddedInJoi = Joi.string()
  .valid(...isEmbeddedIns)
  .meta({
    className: 'IsEmbeddedIn',
  })

const conversionDict: Record<TargetBuild, IsEmbeddedIn> = {
  indes: 'arztDirekt',
  adis: 'arztDirekt',
  lotos: 'iOsTomedo',
}

export function getIsEmbeddedIn(targetBuild: TargetBuild): IsEmbeddedIn {
  return conversionDict[targetBuild]
}
