import Joi from 'joi'

import { zollsoftForms } from '../../assets/json/zollsoft/_zollsoft-forms.barrel'
import { uniqueNamesValidator } from '../../joi-custom-validation/unique-names-validator'
import version from '../../version.json'
import { CertificateJoi } from '../certificate.joi'
import { CoreLocaleJoi } from '../core-locale.joi'
import { IdJoi } from '../id.joi'
import { LocStringJoi } from '../loc-string.joi'
import { PraxisIdJoi } from '../praxis-id.joi'
import { WfaFormIdJoi } from '../wfa-form-id.joi'
import { wfaFormStatusDefault, WfaFormStatusJoi } from '../wfa-form-status.joi'
import { WfaPageJoi } from '../wfa-page.joi'
import { staticIdDict } from './../../assets/praxis-id.dict'
import { WfaForm_v2_10_0 } from './../../interfaces-autogen-from-joi/wfa-form-versions/wfa-form-v2-10-0'

export const wfaForm_v2_10_0Default = {
  praxisId: staticIdDict.staticZsLib,
  instance: staticIdDict.staticZsLib,
  status: wfaFormStatusDefault,
  wfaVersion: version.wfaVersion,
} as const

export const WfaForm_v2_10_0Joi: Joi.ObjectSchema<WfaForm_v2_10_0> =
  Joi.object()
    .keys({
      certificate: CertificateJoi.optional(),
      description: LocStringJoi.optional(),
      instance: PraxisIdJoi.required().valid(Joi.ref('praxisId')),
      locale: CoreLocaleJoi.optional().description(
        'Currently necessary for legacy arzt-direkt settings.',
      ),
      permalink: Joi.string().allow(null).optional(),
      praxisId: PraxisIdJoi.required(),
      pages: Joi.array().items(WfaPageJoi),
      status: WfaFormStatusJoi.required(),
      title: LocStringJoi.required(),
      wfaFormId: WfaFormIdJoi.required(),
      wfaFormVersionId: IdJoi.required(),
      wfaVersion: Joi.string().required(),
      _id: IdJoi.valid(Joi.ref('wfaFormVersionId')).optional(),
    })
    .custom(uniqueNamesValidator, 'uniqueNames')
    .unknown(true)
    .description(
      `
    Schema for validation of JSON created by the SurveyJS editor.
    SurveyJS does not provide a validation of its own, that is why this schema here was created.
    Accordingly, this Joi schema must be considered with every update in relation to the editor!
    `,
    )
    .meta({
      className: 'WfaForm_v2_10_0',
    })
    .example(zollsoftForms.personalData)
