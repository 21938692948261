import { Injectable, Type } from '@angular/core';
import { InsuranceType } from 'lib';
import { InstanceWhitelabelType } from '../entities/Instance.entity';
import { InstanceForm, InstanceFormStatus, InstanceFormTarget, InstanceFormType, PrivatePaymentHandler, PrivatePaymentItemType } from '../entities/InstanceForm.entity';
import { InstanceWhitelabel } from 'vendor/arzt-direkt/shared/schemas/interfaces/instance/instance-whitelabel'
import { HomeWhitelabelSectionInterface } from '../home/home-components/whitelabel-sections/home-whitelabel-section.interface';
import { WhitelabelSectionMedikonsilDirektComponent } from '../home/home-components/whitelabel-sections/medikonsil-direkt/whitelabel-section-medikonsil-direkt.component';
import { WhitelabelSectionMedipolisComponent } from '../home/home-components/whitelabel-sections/medipolis/whitelabel-section-medipolis.component';
import { WhitelabelSectionMeinRezeptOnlineComponent } from '../home/home-components/whitelabel-sections/mein-rezept-online/whitelabel-section-mein-rezept-online.component';
import { WhitelabelSectionRehaAktivComponent } from '../home/home-components/whitelabel-sections/reha-aktiv/whitelabel-section-reha-aktiv.component';


@Injectable({
  providedIn: 'root'
})
export class WhitelabelingService {

  constructor() { }

  public readonly allWhitelabelTypes = Object.keys(InstanceWhitelabelType).map(key => InstanceWhitelabelType[key])


  /**
   * Returns formatted name of given whitelabel-type
   */
  public nameFormatted(whitelabelType: InstanceWhitelabel): String {
    switch (whitelabelType) {
      case InstanceWhitelabelType.NONE: return 'keines'
      case InstanceWhitelabelType.BER: return 'Videosprechstunde Bereitschaftsdienst'
      case InstanceWhitelabelType.KLAW: return 'KanzLaw'
      case InstanceWhitelabelType.MD: return 'Medikonsil-direkt'
      case InstanceWhitelabelType.MP: return 'Medipolis Apotheken'
      case InstanceWhitelabelType.MRO: return 'meinRezept.online'
      case InstanceWhitelabelType.REHA: return 'REHA aktiv 2000​'
      default: return null
    }
  }

  /**
   * Returns internal description for mitarbeiter of given whitelabel-type
   */
  public internalDescription(whitelabelType: InstanceWhitelabel): String {
    switch (whitelabelType) {
      case InstanceWhitelabelType.BER: return 'Kooperation KVBB Videosprechstunde Bereitschaftsdienst'
      case InstanceWhitelabelType.KLAW: return 'Spezifische Anpassungen für KanzLaw'
      case InstanceWhitelabelType.MD: return 'Telemedizin in Pflegeheimen (Carenoble-Projekt)'
      case InstanceWhitelabelType.MP: return 'Kooperation VSS für Medipolis Mitarbeiter'
      case InstanceWhitelabelType.MRO: return 'MRO-Werbeaktion (1 Jahr kostenlos)'
      case InstanceWhitelabelType.REHA: return 'Kooperation VSS für REHA Mitarbeiter'
      default: return ''
    }
  }

  /**
   * Returns whitelabel-section type
   */
  public homeSectionType(whitelabelType: InstanceWhitelabel): Type<HomeWhitelabelSectionInterface> {
    switch (whitelabelType) {
      case InstanceWhitelabelType.MRO: return WhitelabelSectionMeinRezeptOnlineComponent
      case InstanceWhitelabelType.REHA: return WhitelabelSectionRehaAktivComponent
      case InstanceWhitelabelType.MD: return WhitelabelSectionMedikonsilDirektComponent
      case InstanceWhitelabelType.MP: return WhitelabelSectionMedipolisComponent
      default: return null
    }
  }

  /**
   * Returns square whitelabel-logo url
   * NOTE:
   *   Paths are relative to dist/arzt-direkt (?)
   */
  public logoUrls(whitelabelType: InstanceWhitelabel): { logoRectangleUrl: string, logoSquareUrl: string } {
    switch (whitelabelType) {
      case InstanceWhitelabelType.KLAW: return {
        logoRectangleUrl: '/assets/whitelabeling/kanzlaw/kanzlaw--logo-full.png',
        logoSquareUrl: '/assets/whitelabeling/kanzlaw/kanzlaw--logo-scales.png',
      }
      case InstanceWhitelabelType.MD: return {
        logoRectangleUrl: '/assets/whitelabeling/medikonsil-direkt/medikonsil-direkt--white.svg',
        logoSquareUrl: '/assets/whitelabeling/medikonsil-direkt/medikonsil-direkt--white--small.svg',
      }
      case InstanceWhitelabelType.MP: return {
        logoRectangleUrl: '/assets/whitelabeling/medipolis/medipolis--white.svg',
        logoSquareUrl: '/assets/whitelabeling/medipolis/medipolis--white--small.svg',
      }
      case InstanceWhitelabelType.MRO: return {
        logoRectangleUrl: '/assets/whitelabeling/mein-rezept-online/mein-rezept-online--white.svg',
        logoSquareUrl: '/assets/whitelabeling/mein-rezept-online/mein-rezept-online--white--small.svg',
      }
      case InstanceWhitelabelType.REHA: return {
        logoRectangleUrl: '/assets/whitelabeling/reha-aktiv/reha-aktiv--white.svg',
        logoSquareUrl: '/assets/whitelabeling/reha-aktiv/reha-aktiv--white.svg',
      }
      default: return null
    }
  }

  /** 
   * Returns Forms which are activated by default with the instance for the whitelabel 
   * if returns null videosprechstunde form is inserted in backend
   */
  public defaultForms(whitelabelType: InstanceWhitelabel): InstanceForm[] {
    switch (whitelabelType) {
      case InstanceWhitelabelType.BER: return [{
        "insuranceTypes": [
          InsuranceType.GKV,
          InsuranceType.PKV
        ],
        "type": InstanceFormType.Video,
        "target": InstanceFormTarget.Patient,
        "status": InstanceFormStatus.Public,
        "identifier": "bereitschaftsdienst",
        "title": "Bereitschaftsdienst",
        "description": "Videosprechstunde des Bereitschaftsdienstes",
        "medicalSpecializations": ['1'],
        "anamneseFormIdentifier": "bereitschaftsdienst",
        "personalFormIdentifier": "neu-oder-bestandspatient",
        "privatePayment": {
          "isRequired": true,
          "handler": PrivatePaymentHandler.External,
          "items": [
            {
              "type": PrivatePaymentItemType.Videosprechstunde,
              "price": 0,
              "factor": 1,
              "justification": null
            }
          ]
        },
        "customPaymentLabels": {
          "gkv": "",
          "pkv": "",
          "sz": null,
          "hideSZHint": false,
          "hideSZForms": false
        },
        "personalFormSettings": null,
        "anamneseFormSettings": null
      }]
      default: return null
    }
  }

}
