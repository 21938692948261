import { Injectable } from '@angular/core';
import { AsyncValidatorFn, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { DatauriHelpersService } from '../misc/datauri-helpers.service';
import { ADValidators } from "./validators/a-d-validators";

@Injectable({
  providedIn: 'root'
})
export default class FormValidators {

  constructor(
    private dataUriHelpers: DatauriHelpersService
  ) { }


  /**
   * Versichertennummer Validation
   * https://de.wikipedia.org/wiki/Krankenversichertennummer
   */
  public static vnrValidator(control: UntypedFormControl): ValidationErrors {
    const errorValue = { value: control.value }

    if (!control.value) return { 'vnrEmpty': errorValue }
    if (control.value.length < 10) return { 'vnrTooShort': errorValue }
    if (control.value.length > 10) return { 'vnrTooLong': errorValue }

    let letterValue: any = control.value.toLowerCase().charCodeAt(0) - 96
    if (isNaN(letterValue) || letterValue > 26 || letterValue <= 0) return { 'vnrFirstChar': errorValue }
    letterValue = letterValue < 10 ? `0${letterValue}` : String(letterValue)

    const numbers = letterValue + control.value.substr(1, 8)
    let sum = 0
    for (let i = 0; i < numbers.length; i++) {
      const factor = i % 2 === 0 ? 1 : 2
      const multiplication = parseInt(numbers[i]) * factor
      const digitSum = Math.abs(multiplication).toString().split('').reduce(function (a, b) { return +a + +b }, 0)
      sum += digitSum

    }

    // const lastDigit = sum.toString().split('').pop()
    const checkDigit = sum % 10
    const givenCheckDigit = parseInt(control.value.split('').pop())
    if (givenCheckDigit !== checkDigit) return { 'vnrInvalid': errorValue }

    return null
  }


  /**
   * LANR Validation
   * https://de.wikipedia.org/wiki/Lebenslange_Arztnummer
   */
  public static lanrValidator(control: UntypedFormControl): ValidationErrors {
    const errorValue = { value: control.value }

    if (!control.value) return { 'lanrEmpty': errorValue }
    if (control.value.length < 9) return { 'lanrTooShort': errorValue }
    if (control.value.length > 9) return { 'lanrTooLong': errorValue }
    if (!/^\d+$/.test(control.value)) return { 'lanrDigitsOnly': errorValue }

    let sum = 0
    const numbers = control.value.substr(0, 6)
    for (let i = 0; i < numbers.length; i++) {
      const factor = i % 2 == 0 ? 4 : 9
      const multiplication = parseInt(numbers[i]) * factor
      sum += multiplication
      // sum += Math.abs(multiplication).toString().split('').reduce(function(a,b){return +a + +b}, 0)
    }

    let difference = Math.abs(10 - sum % 10)
    if (difference == 10) difference = 0
    const checkDigit = control.value.substr(6, 1)
    if (difference != checkDigit) return { 'lanrInvalid': errorValue }

    return null
  }


  /**
   * Kassennummer (IK) Validation
   * https://de.wikipedia.org/wiki/Institutionskennzeichen
   */
  public static kassennummerValidator(control: UntypedFormControl): ValidationErrors {
    const errorValue = { value: control.value }

    if (!control.value) return { 'insuranceEmpty': errorValue }
    if (control.value.length < 9) return { 'insuranceTooShort': errorValue }
    if (control.value.length > 9) return { 'insuranceTooLong': errorValue }
    if (!/^\d+$/.test(control.value)) return { 'insuranceDigitsOnly': errorValue }

    let sum = 0
    const numbers = control.value.substr(2, 6)
    for (let i = 0; i < numbers.length; i++) {
      const factor = i % 2 == 0 ? 2 : 1
      const multiplication = parseInt(numbers[i]) * factor
      sum += Math.abs(multiplication).toString().split('').reduce(function (a, b) { return +a + +b }, 0)
    }

    const checkDigit = parseInt(control.value.split('').pop())
    if ((sum % 10) != checkDigit) return { 'insuranceInvalid': errorValue }

    return null
  }


  /**
   * Date Validation
   */
  public static dateValidator(control: UntypedFormControl): ValidationErrors {
    const errorValue = { value: control.value }
    if (control.value === null) return null // if date is required, use Validators.required

    if (!control.value || !dayjs(control.value).isValid()) {
      return { 'dateInvalid': errorValue }
    }

    return null
  }


  /**
   * Base64 Image-String Validation
   */
  public static base64ImageValidator(control: UntypedFormControl): ValidationErrors {
    const errorValue = { value: control.value }

    if (!control.value) return { 'imageEmpty': errorValue }
    else if (!/^data:image\/(jpeg|png);base64,([^\"]*)$/.test(control.value)) return { 'imageInvalid': errorValue }

    return null
  }


  /**
   * Base64 Image-String Validation
   */
  public getDataUriValidator(isRequired: boolean, mediaTypes: string[]): ValidatorFn {

    return (control: UntypedFormControl): ValidationErrors => {
      const errorValue = { value: control.value }

      // Speeding up computation by only taking first 50 chars
      let dataUri = control?.value?.dataUri ? control.value.dataUri : (control.value ? control.value : '')
      dataUri = dataUri.substring(0, 50)

      if (!dataUri && isRequired) return { 'empty': errorValue }
      else if (!dataUri) return null

      const regExp = new RegExp(this.dataUriHelpers.dataUriRegExp)
      if (!regExp.test(dataUri)) {
        return { 'invalid': errorValue }
      }

      if (mediaTypes && mediaTypes.length && !mediaTypes.includes('*')) {
        const mediaType = this.dataUriHelpers.getDataUriMediaType(dataUri)
        if (!mediaTypes.includes(mediaType)) return {
          'wrongType': { ...errorValue, mediaType }
        }
      }

      return null
    }
  }


  /**
   * Minimum-Age Validation
   */
  public static getMinimumAgeDateValidator(age: number): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const errorValue = { value: control.value }

      if (!control.value) return { 'birthDateEmpty': errorValue }
      if (!dayjs(control.value).isValid()) return { 'birthDateInvalid': errorValue }

      const minBirthDate = dayjs().subtract(age, 'year')
      const isTooYoung = dayjs(control.value).isAfter(minBirthDate)
      if (isTooYoung) return { 'birthDateTooYoung': errorValue }

      return null
    }
  }

  /**
   * Minimal Value Validation
   */
  public static getMinimalValueValidator(minimalValue: number): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const errorValue = { value: control.value }
      if (control.value < minimalValue) return { 'valueBelowMinimum': errorValue }
      return null
    }
  }

  /**
   * Maximal Value Validation
   */
  public static getMaximalValueValidator(maximalValue: number): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const errorValue = { value: control.value }
      if (control.value > maximalValue) return { 'valueAboveMaximum': errorValue }
      return null
    }
  }

  /**
   * Date-Filter for MatDatepicker
   */
  public static getMinimumAgeDateFilter(age: number): ((date: Date) => boolean) {
    if (!age && age !== 0) return (_) => true
    const minBirthDate = dayjs().subtract(age, 'year')

    return (date: Date) => {
      const isTooYoung = dayjs(date).isAfter(minBirthDate)
      return !isTooYoung
    }
  }

  public static getFutureDateFilter(): ((date: Date) => boolean) {
    return (date: Date) => {
      const isPast = dayjs().isAfter(date, 'day')
      return !isPast
    }
  }


  /**
   * Password-Strength Validation (analogous to VSS)
   */
  public static getPasswordStrengthValidator(isRequired: boolean): ValidatorFn {

    return (control: UntypedFormControl): ValidationErrors => {
      const errorValue = { value: control.value }

      if (!control.value && isRequired) return { 'passwordLengthEight': errorValue }
      if (control.value && control.value.length < 8) return { 'passwordLengthEight': errorValue }
      if (control.value && !/[a-z]/.test(control.value)) return { 'passwordNoLowerLetter': errorValue }
      if (control.value && !/[A-Z]/.test(control.value)) return { 'passwordNoUpperLetter': errorValue }
      if (control.value && !/[\d]/.test(control.value)) return { 'passwordNoDigit': errorValue }
      if (control.value && !/[^a-zA-Z0-9]/.test(control.value)) return { 'passwordNoSpecialChar': errorValue }
      if (control.value && /[\s]/.test(control.value)) return { 'passwordWhitespace': errorValue }

      return null
    }
  }

  /**
   * Insurance-Type Validation
   */
  public static getInsuranceTypeValidator(includeNoneType: boolean = false): ValidatorFn {
    if (includeNoneType) return Validators.pattern('^(gkv|pkv|sz|bes|none)$')
    else return Validators.pattern('^(gkv|pkv|sz|bes)$')
  }


  /**
   * Arzt-Type Validation
   */
  public static getArztTypeValidator(): ValidatorFn {
    return Validators.pattern('^(pa|ka)$')
  }


  /**
   * Fields-Match Validation (esp. for repeating passwords)
   */
  public static getFieldsMatchValidator(otherField: string, mustMatch: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const errorValue = { value: control.value }

      const otherControl = control.root.get(otherField)
      if (!otherControl) return { 'otherControlNotFound': errorValue }

      if (mustMatch && control.value !== otherControl.value) return { 'fieldsDontMatch': errorValue }
      else if (!mustMatch && control.value === otherControl.value) return { 'fieldsMatch': errorValue }

      return null
    }
  }


  /**/
  /* Date-Relation Validator (compare with other field)
   */
  public static dateOrder(
    relation: 'after'|'before',
    otherField: string,
    required: boolean = true
  ): ValidatorFn {
    return (control: UntypedFormControl) => {
      const otherControl = control.root.get(otherField);
      const otherDate = otherControl?.value;
      if (otherDate !== undefined)
        return ADValidators.datesRelation(
          relation,
          otherDate,
          required
        )(control);
      return null;
    };
  }

  /**
   * Is given day in the past?
   * Note: day, not date. So if now is 2021.12.22 19:56,
   *  and we compare now to 2021.12.22 00:00, we don't get
   *  an error, because the day is not in the past, the day
   *  is in the present, it is the current day.
   */
  public static pastDayValidator(): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      if (dayjs(control.value).startOf('day').isBefore(dayjs().startOf('day')))
        return { 'dateIsInThePast': true }
      return null
    }
  }

  /**
   * Is given date in the future?
   */
  public static getFutureDateValidator(required: boolean = false): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const errorValue = { value: control.value }
      if (!control.value && required) return { 'required': errorValue }
      else if (!control.value && !required) return null
      const date = dayjs(control.value)
      if (!date.isValid()) return { 'datesInvalid': errorValue }
      if (date.startOf("day").isAfter(dayjs().startOf("day"))) {
        return { 'dateIsInTheFuture': errorValue }
      }
      return null
    }
  }

  /**
   * Is given date more than "days" days in the future?
   */
  public static getDateMoreThanGivenDaysInTheFutureValidator(days: number): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      if (!control.value) return null
      const date = dayjs(control.value)
      const errorValue = { value: control.value }
      if (date.startOf("day").isAfter(dayjs().add(days, "day")))
        return { 'getDateMoreThanGivenDaysInTheFutureValidator': errorValue }
      return null
    }
  }

  /**
   * Field-Helper for Date-Relation Validation ('after' or 'before')
   */
  public static getDateFieldsRelationValidator(relation: 'after'|'before', otherField: string): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const errorValue = { value: control.value }

      const otherControl = control.root.get(otherField)
      if (!otherControl) return { 'otherControlNotFound': errorValue }

      const date = dayjs(otherControl.value)
      return ADValidators.datesRelation(relation, date)(control)
    }
  }


  /**
   * Asynchronous Image-Size Validator
   */
  public getAsyncImageSizeValidator(minSize: [number, number] | boolean, maxSize: [number, number] | boolean, quadratic: boolean): AsyncValidatorFn {
    return (control: UntypedFormControl): Observable<ValidationErrors | null> => {
      const result$ = new BehaviorSubject<ValidationErrors | null>(null)
      const dataUri = control?.value?.dataUri ? control.value.dataUri : (control.value ? control.value : '')
      const img = new Image()
      fromEvent(img, 'load').subscribe(() => {
        const isQuadratic = img.width === img.height
        const errorValue = { width: img.width, height: img.height, quadratic: isQuadratic }
        errorValue['sizeFormatted'] = `${img.width}×${img.height}`

        const mediaType = this.dataUriHelpers.getDataUriMediaType(dataUri.substring(0, 50))
        const isVector = ['image/svg+xml'].includes(mediaType)

        if (quadratic && !isQuadratic) result$.next({ 'imageNotQuadratic': errorValue })
        else if (minSize && !isVector && (img.width < minSize[0] || img.height < minSize[1])) result$.next({ 'imageTooSmall': errorValue })
        else if (maxSize && !isVector && (img.width > maxSize[0] || img.height > maxSize[1])) result$.next({ 'imageTooLarge': errorValue })
        else result$.next(null)

        result$.complete()
      })
      img.src = dataUri

      return result$
    }
  }


  /**
   * Returns a validator which required the controls value to be true when
   * the given condition evaluates to true, too.
   */
  public static requiredToBeTrueIfValidator(condition: () => boolean): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const result = !!condition()
      if (!result) return null
      if (result && !!control?.value) return null
      return { required: true }
    }
  }

  /**
   * Regex validator
   *
   * If switcher is 'ValidRegex', the form is valid if regex is found in control.search
   * If switcher is 'InvalidRegex', the form is valid if regex is NOT found in control.search
   */
  public static regexValidator(
    regex: RegExp,
    switcher: 'ValidRegex' | 'InvalidRegex',
    errorName?: string
  ): ValidatorFn {

    return (control: UntypedFormControl): ValidationErrors => {
      if (control.value === null) return null
      const errorValue = { value: control.value }

      if (typeof control.value !== 'string') return { 'typeError': errorValue }
      if (control.value === '') return null

      const value = control.value as string
      const regexLocation: number = value.search(regex)
      if (switcher === 'ValidRegex') {
        const errorName1 = errorName ?? 'regexNotFound'
        if (regexLocation === -1) return { [errorName1]: errorValue }
      }

      if (switcher === 'InvalidRegex') {
        const errorName1 = errorName ?? 'regexFound'
        if (regexLocation !== -1) return { [errorName1]: errorValue }
      }

      return null
    }
  }

  /*
  * validator for form array all not null
  */
  public static openingsFilledValidator(control: UntypedFormControl): ValidationErrors {
    const arr = control.value
    if (!arr.length || !arr.some) return { 'empty': arr }
    if (arr.some((x: any) => !x)) return { 'not full': arr }
    return null
  }


  public static getValueExistsInArrayValidator(instanceIdentifiers: string[], mustExist: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      if (mustExist) {
        return instanceIdentifiers.includes(control.value)
          ? null
          : { 'not found': control.value }
      } else {
        return instanceIdentifiers.includes(control.value)
          ? { 'found match': control.value }
          : null
      }
    }
  }
}
