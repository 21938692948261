import { Injectable, OnDestroy } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CertificateService } from '../crypto';
import { InstanceService } from '../instance/instance.service';
import { KeyService } from './../crypto/key.service';
import { UtilityService } from './../crypto/utility.service';

@Injectable({
  providedIn: 'root'
})
export class PemHelpersService implements OnDestroy {

  private unsubscribe$ = new Subject()
  private PRIVATE_KEY_FILE = `Private-Key_${this.instanceService.activeInstance ? this.instanceService.activeInstance.identifier : 'demo'}.pem`


  constructor(
    private utilityService: UtilityService,
    private certificateService: CertificateService,
    private keyService: KeyService,
    private instanceService: InstanceService,
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }


  public loadCertificateAndPrivateKeyBuffer(pem: string): Observable<any> {
    return new Observable((observer) => {
      console.log('MY PEM: ', pem)
      const certificatePem = this.extractKeyPem(pem, 'certificate');
      const certRegex = new RegExp(/(-----(BEGIN|END)( NEW)? CERTIFICATE-----|\n)/g);
      console.log('cert pem', certificatePem)
      const privateKeyPem = this.extractKeyPem(pem, 'private key');
      const keyRegex = new RegExp(/(-----(BEGIN|END)( NEW)? PRIVATE KEY-----|\n)/g);
      console.log('private key pem', privateKeyPem)
      forkJoin([
        this.utilityService.pemToBuffer(certificatePem, certRegex),
        this.utilityService.pemToBuffer(privateKeyPem, keyRegex)
      ])
        .pipe(
          takeUntil(this.unsubscribe$)
        )
        .subscribe(([certBuffer, keyBuffer]) => {
          const cryptoData = {
            certificateBuffer: certBuffer,
            privateKeyBuffer: keyBuffer
          };
          observer.next(cryptoData);
          observer.complete();
        }, (error) => {
          observer.error(error);
        })
    });
  }

  public extractKeyPem(pem: string, type: string): string {
    const header = `-----BEGIN ${type.toUpperCase()}-----\r\n`;
    const footer = `-----END ${type.toLocaleUpperCase()}-----\r\n`;
    console.log('TYPE: ', type)
    console.log('extract', pem)
    console.log('HEADER', header)
    const pemStart = pem.indexOf(header);
    console.log('pem start', pemStart)
    const pemEnd = pem.indexOf(footer) + footer.length;
    console.log('pem end', pemEnd)
    return pem.substring(pemStart, pemEnd);
  }

  public downloadPem(certBuffer: ArrayBuffer, privateKeyBuffer: ArrayBuffer) {
    return new Observable((observer) => {
      forkJoin([
        this.certificateService.writeCertPem(certBuffer),
        this.keyService.createKeyPem(privateKeyBuffer, 'private key')
      ])
        .pipe(
          takeUntil(this.unsubscribe$)
        )
        .subscribe(([certPem, keyPem]) => {
          const pem = `${certPem}\n${keyPem}`;
          const keyblob = new Blob([pem], { type: 'text/plain' })
          const url = window.URL.createObjectURL(keyblob);
          const downloadAnchor = document.createElement('a');
          document.body.appendChild(downloadAnchor);
          downloadAnchor.setAttribute('style', 'display: none');
          downloadAnchor.href = url;
          downloadAnchor.download = this.PRIVATE_KEY_FILE;
          downloadAnchor.click();
          window.URL.revokeObjectURL(url);
          downloadAnchor.remove();
          observer.next();
          observer.complete();
        }, (error) => {
          observer.error(error);
        })
    })
  }
}
