import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { I18NArray, I18NString, I18NString_MISSING_VALUE } from '../entities/I18N.entity';
import { LanguageService } from "./language.service";
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'i18nString',
    pure: false,
    standalone: true,
})
export class I18NStringPipe implements PipeTransform {

  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService
  ) { }

  transform(input: I18NString, fallback?: string): string {
    if (input && typeof input === 'object') {
      if (input[this.languageService.activeBaseLang] && input[this.languageService.activeBaseLang] !== I18NString_MISSING_VALUE) return input[this.languageService.activeBaseLang]
      if (input[this.languageService.DEFAULT_BASE_LANG] && input[this.languageService.DEFAULT_BASE_LANG] !== I18NString_MISSING_VALUE) return input[this.languageService.DEFAULT_BASE_LANG]
      return fallback ?? this.translateService.instant("MULTILINGUAL-FIELD.NO-TRANSLATION");
    }
    return input as string
  }

}

@Pipe({
    name: 'trustHtml',
    pure: true,
    standalone: true,
})
export class trustHtmlPipe implements PipeTransform {

  constructor(
    private languageService: LanguageService,
    private domSanitizer: DomSanitizer
  ) { }

  transform(input: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(input)
  }
}


@Pipe({
    name: 'i18nArray',
    pure: false,
    standalone: true,
})
export class I18NArrayPipe implements PipeTransform {

  constructor(
    private languageService: LanguageService,
  ) { }

  transform(input: I18NArray): string[] {
    if (input && typeof input === 'object' && !Array.isArray(input)) {
      if (input[this.languageService.activeBaseLang]) return input[this.languageService.activeBaseLang]
      if (input[this.languageService.DEFAULT_BASE_LANG]) return input[this.languageService.DEFAULT_BASE_LANG]
      return []
    }
    return input as string[]
  }

}
