import { CustomPropertyInfo } from '@arzt-direkt/wfa-definitions'
import { sharedProperties } from '@arzt-direkt/wfa-definitions'

import { Dictionary } from '../../../types/dictionary'
import { mergeObjects } from '../../../utility/merge-objects'
import { editorOnlyProperties } from './editor-only/_editor-only.barrel'

export const customEditorProperties = mergeObjects(
  ...sharedProperties,
  ...editorOnlyProperties,
) as Dictionary<CustomPropertyInfo[]>

export const customViewerProperties = mergeObjects(
  ...sharedProperties,
) as Dictionary<CustomPropertyInfo[]>
