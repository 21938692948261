import { Inject, Injectable } from '@angular/core';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';


/**
* Custom Date-Formats and Adapter (https://github.com/iamkun/dayjs)
*/

export const AppDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
}


@Injectable({
  providedIn: 'root'
})
export class AppDateAdapter extends NativeDateAdapter {

  constructor(@Inject(MAT_DATE_LOCALE) matDateLocale: string) {
    super(matDateLocale)

    // Initalize DayJS-Parser
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(localizedFormat)
    // dayjs.extend(relativeTime)
  }

  parse(value: any): Date | null {
    if (!value) return null

    let date = dayjs(value, 'DD.MM.YYYY')

    if (!date.isValid()) date = dayjs(value, 'DDMMYYYY')
    if (!date.isValid()) date = dayjs(value, 'DDMMYY')

    if (!date.isValid()) date = dayjs(value, 'D.MM.YYYY')
    if (!date.isValid()) date = dayjs(value, 'DD.M.YYYY')
    if (!date.isValid()) date = dayjs(value, 'D.M.YYYY')
    if (!date.isValid()) date = dayjs(value, 'DD.MM.YY')
    if (!date.isValid()) date = dayjs(value, 'D.MM.YY')
    if (!date.isValid()) date = dayjs(value, 'DD.M.YY')
    if (!date.isValid()) date = dayjs(value, 'D.M.YY')

    if (!date.isValid()) date = dayjs(value, 'YYYY-MM-DD')
    if (!date.isValid()) date = dayjs(value, 'YYYY-MM-D')
    if (!date.isValid()) date = dayjs(value, 'YYYY-M-DD')
    if (!date.isValid()) date = dayjs(value, 'YYYY-M-D')
    if (!date.isValid()) date = dayjs(value, 'YY-MM-DD')
    if (!date.isValid()) date = dayjs(value, 'YY-MM-D')
    if (!date.isValid()) date = dayjs(value, 'YY-M-DD')
    if (!date.isValid()) date = dayjs(value, 'YY-M-D')

    return date.toDate()
  }

  format(date: Date, displayFormat: any): string {
    return dayjs(date).format(displayFormat)
  }

}

