import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, Subject } from 'rxjs';
import adapter from 'webrtc-adapter';

import { ToolWebRTCConnectionTypes, WebRTCConnection, WebRTCConnectionType } from '../entities/WebRTC.entity';
import { CloseType, CoupleType, Track, WebRTCMessage, WebRTCResync } from '../entities/WebRTCMessage.entity';
import { WebRTCData, WebRTCICEData, WebRTCRequestRetry } from '../entities/WebRTCSocketMessage.entity';
import { BrowserSupportService } from '../misc/browser-support/browser-support.service';
import { SocketUserRole } from './../entities/Socket.entity';
import { SocketService } from './../socket/socket.service';
import { DataTransferService } from './dataTransfer.service';
import { MessagingService } from './messaging.service';
import { PeerConnectionStatsService } from './peer-connection-stats.service';
import { StreamService } from './stream.service';
import { VideoService } from './video.service';

@Injectable({
  providedIn: 'root'
})
export class SignalingService implements OnDestroy {

  private BASE_SDP_CONFIG = {
    offerToReceiveAudio: true,
    offerToReceiveVideo: true,
    voiceActivityDetection: false
  }

  private TOOL_SDP_CONFIG = {
    offerToReceiveAudio: true,
    offerToReceiveVideo: true,
    voiceActivityDetection: false
  }

  private DATACHANNEL_LABEL_AUDIO = 'audio';
  private DATACHANNEL_LABEL_DATA = 'data';

  public remoteStream = new MediaStream();
  public iceCandidates: RTCIceCandidate[] = [];

  private sessionId: string;
  private userRole: SocketUserRole;

  // test connections
  public patDoc: WebRTCConnection = this.createEmptyConnection(WebRTCConnectionType.DocPatient)
  public patSpecialist: WebRTCConnection = this.createEmptyConnection(WebRTCConnectionType.PatientSpecialist);
  public patTool: WebRTCConnection = this.createEmptyConnection(WebRTCConnectionType.PatientTool);
  public docTool: WebRTCConnection = this.createEmptyConnection(WebRTCConnectionType.DocTools);
  public docSpecialist: WebRTCConnection = this.createEmptyConnection(WebRTCConnectionType.DocSpecialist);
  public specialistTool: WebRTCConnection = this.createEmptyConnection(WebRTCConnectionType.SpecialistTools);

  private runningConnections: WebRTCConnection[] = [];
  private candidateFinished = new Subject<WebRTCConnectionType>();

  private sharingScreen = false;
  private syncData = true;

  public peerConnectionStateChange$ = new Subject<{ type: WebRTCConnectionType, state: RTCPeerConnectionState }>()
  public datachannelStateChange$ = new Subject<{ type: WebRTCConnectionType, state: RTCDataChannelState }>();
  public screenshareChange$ = new Subject<boolean>();
  private coupledSpecialist$ = new Subject<boolean>();

  constructor(
    private adLoggerService: AdLoggerService,
    private socketService: SocketService,
    private videoService: VideoService,
    private streamService: StreamService,
    private messagingService: MessagingService,
    private dataTransferService: DataTransferService,
    private browserSupportService: BrowserSupportService,
    private peerStatService: PeerConnectionStatsService,
    private http:HttpClient
  ) { }

  private createEmptyConnection(type:WebRTCConnectionType):WebRTCConnection {
    return {
      type: type,
      remoteElement: undefined,
      peerRole: undefined,
      peerId: undefined,
      makingOffer: false,
      isSettingRemoteAnswerPending: false,
      iceCandidates: [],
      dataChannels: [],
      remoteStream: new MediaStream(),
      peerConnection: undefined
    };
  }

  ngOnDestroy(): void {
    this.coupledSpecialist$.complete();
    this.peerConnectionStateChange$.complete();
    this.datachannelStateChange$.complete();
    this.screenshareChange$.complete();
  }

  public coupledSpecialist(): Observable<boolean> {
    return this.coupledSpecialist$.asObservable();
  }

  public screenShareChange(): Observable<boolean> {
    return this.screenshareChange$.asObservable();
  }

  public startConnection(localStream: MediaStream, remoteElement: ElementRef,
    rtcType: WebRTCConnectionType, peerRole: SocketUserRole,
    addDatachannel: boolean, peerId?: string): Promise<RTCPeerConnection|undefined> {
      return new Promise((resolve,reject)=>{
        this.http.get('/api/session/stunTurnConfig',{headers:{ 'x-app-id': environment.appId, 'x-api-key': environment.apiKey }}).subscribe({
        next:(result:{config:RTCConfiguration})=>{
          const pc = new RTCPeerConnection(result.config);
          const peerConnection = this.getConnectionByType(rtcType);
          this.initializeConnection(peerConnection,pc,remoteElement,peerRole,peerId);
          this.addRunningConnection(peerConnection);
          if (rtcType === WebRTCConnectionType.DocTools) {
            const patientConnection = this.runningConnections.find((connection) => connection.type === WebRTCConnectionType.DocPatient);
            this.messagingService.toolCoupled(patientConnection, CoupleType.Tool);
          }
          if (rtcType === WebRTCConnectionType.DocSpecialist) {
            this.coupledSpecialist$.next(true);
          }
          // add the local camera stream to the peer connection
          if (!(ToolWebRTCConnectionTypes.includes(rtcType) && peerRole === SocketUserRole.Tool)) {
            localStream.getTracks().forEach((track) => pc.addTrack(track, localStream));
            if (this.sharingScreen) {
              this.streamService.screenShareStream.getTracks().forEach((track) => {
                if (track && track.kind === 'video') {
                  const sender = pc.getSenders().find((pcSender) => pcSender.track.kind === 'video');
                  sender.replaceTrack(track);
                }
              })
            }
          }
          // add event handlers
          pc.onicecandidate = (event: RTCPeerConnectionIceEvent) => this.handleIceCandidate(event, peerConnection);
          pc.oniceconnectionstatechange = (event: any) => this.handleIceConnectionStateChange(event, pc, peerConnection);
          pc.ontrack = (event: RTCTrackEvent) => this.handleTracks(event, remoteElement, peerConnection.remoteStream, rtcType);
          pc.onconnectionstatechange = (event: any) => this.handleConnectionStateChange(event, pc, rtcType, peerId);
          pc.onnegotiationneeded = (event: any) => this.handleNegotiationChange(event, peerConnection);
          pc.ondatachannel = (event: RTCDataChannelEvent) => this.receiveChannelCallback(event, peerConnection);
          if (addDatachannel) {
            this.startDataChannels(peerConnection);
          }
          resolve(pc);
        },
        error:(error)=>{
          this.adLoggerService.error(error);
          resolve(undefined)
        }
      })
      })
  }

  private initializeConnection(connection:WebRTCConnection,pc: RTCPeerConnection, remoteElement:ElementRef<any>,peerRole:SocketUserRole, peerId:string) {
    connection.remoteElement = remoteElement;
    connection.peerRole = peerRole;
    connection.peerId = peerId;
    connection.peerConnection = pc;
  }

  private addRunningConnection(peerConnection: WebRTCConnection) {
    const connectionIndex = this.runningConnections.findIndex((connection) => connection.type === peerConnection.type);
    if (connectionIndex > -1) {
      this.runningConnections[connectionIndex] = peerConnection;
    } else {
      this.runningConnections.push(peerConnection);
    }
  }

  public handleNegotiationChange(event: any, connection: WebRTCConnection) {
    if (connection && event && event.type === 'negotiationneeded') {
      this.sendOffer(connection);
    } else {
      console.log('negotiation status change', event)
    }
  }

  public handleConnectionStateChange(event: any, pc: RTCPeerConnection, type: WebRTCConnectionType, peerId: string) {
    switch (pc.connectionState) {
      case 'new':
        console.log('New RTC peer connection');
        this.updateConnectionStatus(pc, type, peerId);
        break;
      case 'connecting':
        console.log('Connection process has started');
        this.updateConnectionStatus(pc, type, peerId);
        break;
      case 'connected':
        console.log('fully connected to peer');
        this.updateConnectionStatus(pc, type, peerId);
        this.peerStatService.updateActiveIceCandidateType(pc, type);
        this.peerStatService.createWebRTCStatistics(pc, this.userRole, this.sessionId);
        if (this.userRole === 'patient') this.peerStatService.saveWebRTCStatistics(this.sessionId, this.userRole, true);
        break;
      case 'closed':
        console.log('User closed the peer connection');
        this.updateConnectionStatus(pc, type, peerId, true);
        if (this.userRole === 'arzt') this.peerStatService.saveWebRTCStatistics(this.sessionId, this.userRole, true);
        break;
      case 'failed':
        console.log('Failed to establish peer connection');
        this.updateConnectionStatus(pc, type, peerId, true);
        break;
      case 'disconnected':
        console.log('Peer disconnected from RTC connection', type);
        this.updateConnectionStatus(pc, type, peerId, true);
        if (this.userRole === 'arzt') this.peerStatService.saveWebRTCStatistics(this.sessionId, this.userRole, true);
        break;
      default:
        console.log('unknown state')
    }
  }

  private updateConnectionStatus(pc: RTCPeerConnection, type: WebRTCConnectionType, peerId: string, reset = false) {
    const connection = this.getConnectionByType(type);
    if (connection && connection.peerId === peerId) {
      this.peerConnectionStateChange$.next({
        type: type,
        state: pc.connectionState
      });
      if (reset) {
        this.resetConnection(connection);
      }
    } else {
      console.log(`Unknown peerId: ${peerId}`, connection)
    }
  }

  public async sendOffer(connection: WebRTCConnection) {
    try {
      if (adapter.browserDetails.browser === 'safari') {
        const versionNumber = parseFloat(this.browserSupportService.browser.getBrowserVersion());
        if (versionNumber < 14.5) {
          console.log('DONT SEND OFFER AS OLD SAFARI')
          return;
        }
      }
      connection.makingOffer = true;
      const isTool = ToolWebRTCConnectionTypes.includes(connection.type);
      const sdpConfig = isTool ? this.TOOL_SDP_CONFIG : this.BASE_SDP_CONFIG;
      const offer = await connection.peerConnection.createOffer(sdpConfig);
      if (connection.peerConnection.signalingState !== 'stable') {
        return;
      }
      await connection.peerConnection.setLocalDescription(offer);
      // await this.setPreferredCodecs(connection.peerConnection);
      console.log('SAFARI OPPFER', offer)
      this.socketService.sendRTCMessage(this.makeMessageData(connection));
    } catch (error) {
      this.adLoggerService.error(error);
    } finally {
      connection.makingOffer = false;
    }
  }

  /**
   * send sdp information to peer after an offer was received
   * @param connection webrtc connection information
   */
  public sendAnswer(connection: WebRTCConnection) {
    console.log("sendAnswer: connection = ", connection)
    this.setIceCandidates(connection.type);
    const isTool = ToolWebRTCConnectionTypes.includes(connection.type);
    const sdpConfig = isTool ? this.TOOL_SDP_CONFIG : this.BASE_SDP_CONFIG;
    connection.peerConnection.createAnswer(sdpConfig).then((answer) => {
      return connection.peerConnection.setLocalDescription(answer);
    })
      // .then(() => this.setPreferredCodecs(connection.peerConnection))
      .then(() => {
        this.socketService.sendRTCMessage(this.makeMessageData(connection));
      })
      .catch((error) => {
        this.adLoggerService.error("sendAnswer: Failed to set the local description, error = ", error);
      });
  }

  public async receiveOfferAndAnswer(data: WebRTCData, videoElement: ElementRef, stream: MediaStream, type: WebRTCConnectionType) {
    const remoteDescription = data.session;
    if (!remoteDescription) {
      console.warn('Did not receive session description in offer');
      return;
    }

    let connection = this.getConnectionByType(type);
    let peerConnection: RTCPeerConnection;
    if (connection && connection.peerConnection) {
      peerConnection = connection.peerConnection;
    } else {
      peerConnection = await this.startConnection(stream, videoElement, type, data.role, false, data.peerId);
      connection = this.getConnectionByType(type);
    }
    // handle offer collisions
    let ignoreOffer = false;
    try {
      const offerCollision = connection.makingOffer || peerConnection.signalingState !== 'stable';
      const isMaster = this.isConnectionMaster(connection.peerRole);
      ignoreOffer = isMaster && offerCollision;

      if (ignoreOffer) {
        console.log('receiveOfferAndAnswer: Master ignored peer offer to avoid collision');
        return;
      }
      if (offerCollision) {
        const browser = adapter.browserDetails.browser
        const versionNumber = parseFloat(this.browserSupportService.browser.getBrowserVersion());
        if (browser === 'safari' && versionNumber < 14.5) {
          console.log('receiveOfferAndAnswer: Have to handle manual rollback')
          connection = await this.manualRollback(connection, remoteDescription, stream)
        } else {
          console.log('receiveOfferAndAnswer: Offer collision detected -> rolling back')
          await Promise.all([
            peerConnection.setLocalDescription({ type: 'rollback' }),
            peerConnection.setRemoteDescription(remoteDescription)
          ]);
          console.log('receiveOfferAndAnswer: done with rollback')
        }
      } else {
        console.log('receiveOfferAndAnswer: no rollback necessary')
        await peerConnection.setRemoteDescription(remoteDescription);
      }
      this.sendAnswer(connection);
    } catch (error) {
      this.adLoggerService.error('receiveOfferAndAnswer: Failed to set the remote description, error = ', error);
    }
  }

  private async manualRollback(connection: WebRTCConnection, remoteDescription: RTCSessionDescription,
    stream: MediaStream): Promise<WebRTCConnection> {
    this.stopConnection(connection);
    const peerConnection = await this.startConnection(stream, connection.remoteElement,
      connection.type, connection.peerRole, true, connection.peerId);
    await peerConnection.setRemoteDescription(remoteDescription);
    return this.getConnectionByType(connection.type);
  }

  public stopConnection(connection: WebRTCConnection) {
    connection.makingOffer = false;
    connection.isSettingRemoteAnswerPending = false;
    connection.iceCandidates = [];
    connection.dataChannels = [];
    connection.peerConnection.close();
    connection.peerConnection.removeEventListener('icecandidate', (event) => this.handleIceCandidate)
    connection.peerConnection.removeEventListener('negotiationneeded', (event) => this.handleNegotiationChange)
    connection.peerConnection.removeEventListener('datachannel', (event) => this.receiveChannelCallback)
    connection.peerConnection.removeEventListener('track', (event) => this.handleTracks)
    connection.peerConnection.removeEventListener('iceconnectionstatechange', (event) => this.handleIceConnectionStateChange)
    connection.peerConnection.removeEventListener('connectionstatechange', (event) => this.handleConnectionStateChange)
    connection.peerConnection = null;
    const connectionIndex = this.runningConnections.findIndex((rc) => rc.type === connection.type);
    if (connectionIndex > -1) {
      this.runningConnections.splice(connectionIndex, 1);
    }
    this.resetConnectionByType(connection.type);
  }

  public receiveAnswer(data: WebRTCData, type: WebRTCConnectionType) {
    const connection = this.getConnectionByType(type);
    if (!(connection.peerConnection.connectionState === 'connected')) {
      connection.isSettingRemoteAnswerPending = true;
      connection.peerConnection.setRemoteDescription(data.session)
        .then(() => {
          connection.isSettingRemoteAnswerPending = false;
          this.setIceCandidates(connection.type);
        })
        .catch((error) => {
          this.adLoggerService.error('Failed to set the remote description', error);
          connection.isSettingRemoteAnswerPending = false;
        });
    }
  }

  private startDataChannels(connection: WebRTCConnection) {
    // standard channel to transfer files between peers
    const dataChannel = connection.peerConnection.createDataChannel(this.DATACHANNEL_LABEL_DATA);
    console.log("startDataChannels: connection = ", connection, " dataChannel = ", dataChannel)
    this.initializeDataChannel(connection, dataChannel);
    // special channel to receive recorded audio
    // the second condition is there to transfer audio from the tool to the
    // specialist through the doctor, because the tool doesn't do it directly
    if (ToolWebRTCConnectionTypes.includes(connection.type)
      || connection.type === WebRTCConnectionType.DocSpecialist) {
      const audioChannel = connection.peerConnection.createDataChannel(this.DATACHANNEL_LABEL_AUDIO);
      console.log("startDataChannels: audio. connection = ", connection, " dataChannel = ", dataChannel)
      this.initializeDataChannel(connection, audioChannel);
    }
  }

  private initializeDataChannel(connection: WebRTCConnection, channel: RTCDataChannel) {
    console.log('initializeDataChannel: connection', connection, 'channel ', channel)
    if (!connection.dataChannels) {
      connection.dataChannels = [];
    }
    const channelIndex = connection.dataChannels.findIndex((dataChannel) => dataChannel && dataChannel.rtcDataChannel.label === channel.label);
    const channelData = {
      rtcDataChannelState: channel.readyState,
      rtcDataChannel: channel
    };
    if (channelIndex === -1) {
      connection.dataChannels.push(channelData);
    } else {
      connection.dataChannels[channelIndex] = channelData;
    }
    // event handlers
    channel.onopen = (event: any) => this.dataChannelStatusChange(event, connection, channel);
    channel.onclose = (event: any) => this.dataChannelStatusChange(event, connection, channel);
    if (channel.label === this.DATACHANNEL_LABEL_DATA) {
      channel.onmessage = (event: any) => this.dataTransferService.receiveChannelMessage(event, connection);
    } else {
      channel.onmessage = (event: MessageEvent<ArrayBuffer>) => {
        console.log('EVENT', event)
      }
    }
    channel.binaryType = 'arraybuffer';
  }

  private receiveChannelCallback(channelEvent: RTCDataChannelEvent, connection: WebRTCConnection) {
    const receiveChannel = channelEvent.channel;
    console.log("receiveChannelCallback: connection = ", connection, " receiveChannel = ", receiveChannel)
    this.initializeDataChannel(connection, receiveChannel);
  }

  /** called when channels open or close.
   * used to sync session data and to send PatientSession to the specialist.
   */
  private dataChannelStatusChange(event: any, connection: WebRTCConnection, channel: RTCDataChannel) {
    console.log('initializeDataChannel: EVENT CHANGE: ', event)
    if (!connection.dataChannels) { return; }
    const channelIndex = connection.dataChannels.findIndex((dc) => dc.rtcDataChannel.label === channel.label);
    console.log('initializeDataChannel: Channel status has changed', channelIndex)
    if (channelIndex > -1) {
      connection.dataChannels[channelIndex].rtcDataChannelState = channel.readyState;
      if (channel.readyState === 'open' && channel.label === this.DATACHANNEL_LABEL_AUDIO) {
        // if (!this.webrtcAudioService.hasPlayer) {
        // this.webrtcAudioService.initAudioPlayer();
        // }
      }
      if (channel.readyState === 'open' && channel.label === this.DATACHANNEL_LABEL_DATA) {
        if (this.syncData) {
          this.syncSessionData(connection);
        }
        if (connection.type === WebRTCConnectionType.DocSpecialist && this.userRole === SocketUserRole.Arzt) {
          this.messagingService.sendPatientSessionToSpecialist(connection);
        }
        this.datachannelStateChange$.next({
          type: connection.type,
          state: channel.readyState
        });
      }
    }
  }

  private syncSessionData(connection: WebRTCConnection) {
    switch (this.userRole) {
      case SocketUserRole.Arzt:
        const arztTypes = [WebRTCConnectionType.DocSpecialist, WebRTCConnectionType.DocPatient];
        if (arztTypes.includes(connection.type)) {
          this.messagingService.requestResync(connection, WebRTCResync.Full);
          this.syncData = false;
        }
        break;
      case SocketUserRole.Patient:
        if (connection.type === WebRTCConnectionType.DocPatient) {
          this.messagingService.requestResync(connection, WebRTCResync.Full);
          this.syncData = false;
        }
        break;
      case SocketUserRole.Spezialist:
        if (connection.type === WebRTCConnectionType.DocSpecialist) {
          this.messagingService.requestResync(connection, WebRTCResync.Full);
        }
        break;
      default:
        this.syncData = false;
        break;
    }
  }

  public connectionDataChannelOpen(type: WebRTCConnectionType) {
    const connection = this.getConnectionByType(type);
    if (!connection) { return false; }
    for (let i = 0; i < connection.dataChannels.length; i++) {
      const channelData = connection.dataChannels[i];
      const channel = channelData.rtcDataChannel;
      if (channel.readyState === 'open' && channel.label === this.DATACHANNEL_LABEL_DATA) {
        return connection;
      }
      if (i < connection.dataChannels.length - 1) {
        return;
      }
    }
  }

  /**
   * handle updated sdp information
   */
  public updatePeerConnection(data: WebRTCData, videoElement: ElementRef, stream: MediaStream, type: WebRTCConnectionType) {
    switch (data.sdpType) {
      case WebRTCMessage.Offer:
        this.receiveOfferAndAnswer(data, videoElement, stream, type)
        break;
      case WebRTCMessage.Answer:
        this.receiveAnswer(data, type);
        break;
      default:
        console.log('Unknown data type', data);
        break;
    }
  }

  public handleTracks(event: RTCTrackEvent, remoteElement: ElementRef, remoteStream: MediaStream, type: WebRTCConnectionType) {
    remoteStream.addTrack(event.track);
    const trackCondition = remoteStream.getVideoTracks().length > 0 && remoteStream.getAudioTracks().length > 0;
    // if (trackCondition || isToolVideo) {
    if (trackCondition) {
      this.videoService.startRemoteVideo(remoteElement, remoteStream, type, this.userRole).subscribe({
        next:(stream) => {
          console.log('Finished remote video initialization', stream);
        }, 
        error:(error) => {
          this.adLoggerService.error('Remote init error', error);
        }
      });
    }
  }

  /**
   * handle connection restart once the established connection fails (ex. user switches from WLAN to LTS)
   */
  public handleIceConnectionStateChange(event: any, pc: RTCPeerConnection, connection: WebRTCConnection) {
    console.log(`New ICE state: ${pc.iceConnectionState} !`, connection)
    console.log(event)
    if (pc.iceConnectionState === 'failed') {
      this.adLoggerService.error(JSON.stringify(connection), 'handleIceConnectionStateChange: failed to gather sufficient ICE candidates');
      // @ts-ignore
      pc.restartIce();
    }
  }

  /**
   * process ICE candidate received from the coturn server and send it to the peer
   * @param event new ICE candidate event
   * @param connection webrtc connection information
   */
  public handleIceCandidate(event: RTCPeerConnectionIceEvent, connection: WebRTCConnection) {
    // console.log('handleIceCandidate: event = ', event)
    if (event && event.candidate) {
      const iceData: WebRTCICEData = {
        candidate: event.candidate,
        role: this.userRole,
        receiverRole: connection.peerRole,
        peerId: connection.peerId,
        sessionId: this.sessionId
      };
      // console.log('handleIceCandidate: iceData = ', iceData)
      this.socketService.sendICECandidate(iceData);
    } else {
      // console.log('handleIceCandidate: END of ICE candidates');
      this.candidateFinished.next(connection.type);
    }
  }

  /**
   * set all ICE candidates that were received from the peer
   * @param type type of user connection
   */
  private setIceCandidates(type: WebRTCConnectionType) {
    const connection = this.getConnectionByType(type);
    connection.iceCandidates.forEach((candidate:RTCIceCandidate)=>{
      connection.peerConnection?.addIceCandidate(candidate)
    })
    connection.iceCandidates=[];
  }

  /**
   * create new RTCMessage data to inform the peer about the media capabilities
   * @param connection webrtc connection information
   */
  private makeMessageData(connection: WebRTCConnection): WebRTCData {
    return {
      peerId: connection.peerId,
      sdpType: connection.peerConnection.localDescription.type,
      sdp: connection.peerConnection.localDescription.sdp,
      session: connection.peerConnection.localDescription,
      role: this.userRole,
      receiverRole: connection.peerRole,
      sessionId: this.sessionId
    }
  }

  /**
   * add a single ICE candidate to the candidate pool
   * @param data ICE candidate information
   * @param type type of user connection
   */
  public async addIceCandidate(data: WebRTCICEData, type: WebRTCConnectionType) {
    const connection = this.getConnectionByType(type);
    if (connection && connection.peerConnection) {
      try {
        await connection.peerConnection.addIceCandidate(data.candidate);
      } catch (error) {
        this.adLoggerService.error('ICE CANDIDATE ERROR', error);
      }
    } else {
      connection.iceCandidates.push(data.candidate);
    }
  }

  /**
   * save updated connection information
   */
  private resetConnectionByType(type: WebRTCConnectionType) {
    switch (type) {
      case WebRTCConnectionType.DocPatient:
        this.patDoc = this.createEmptyConnection(WebRTCConnectionType.DocPatient);
        break;
      case WebRTCConnectionType.DocSpecialist:
        this.docSpecialist = this.createEmptyConnection(WebRTCConnectionType.DocSpecialist);
        break;
      case WebRTCConnectionType.DocTools:
        this.docTool = this.createEmptyConnection(WebRTCConnectionType.DocTools);
        break;
      case WebRTCConnectionType.PatientSpecialist:
        this.patSpecialist = this.createEmptyConnection(WebRTCConnectionType.PatientSpecialist);
        break;
      case WebRTCConnectionType.PatientTool:
        this.patTool = this.createEmptyConnection(WebRTCConnectionType.PatientTool);
        break;
      case WebRTCConnectionType.SpecialistTools:
        this.specialistTool = this.createEmptyConnection(WebRTCConnectionType.SpecialistTools);
        break;
      default:
        break;
    }
  }

  /**
   * load webrtc connection information by connection type
   * @param type type of user connection
   */
  public getConnectionByType(type: WebRTCConnectionType): WebRTCConnection {
    switch (type) {
      case WebRTCConnectionType.DocPatient:
        return this.patDoc;
      case WebRTCConnectionType.DocSpecialist:
        return this.docSpecialist;
      case WebRTCConnectionType.DocTools:
        return this.docTool;
      case WebRTCConnectionType.PatientSpecialist:
        return this.patSpecialist;
      case WebRTCConnectionType.PatientTool:
        return this.patTool;
      case WebRTCConnectionType.SpecialistTools:
        return this.specialistTool;
      default:
        this.adLoggerService.error('Unknown connection type', type);
        return null;
    }
  }

  public init(role: SocketUserRole, sessionId: string) {
    this.userRole = role;
    this.sessionId = sessionId;
  }

  /**
   * stop webrtc connection and media streams to the specified connections
   * @param closeType special connection scenario
   */
  public closeConnections(closeType: CloseType) {
    if (this.userRole === 'arzt') this.peerStatService.saveWebRTCStatistics(this.sessionId, this.userRole, true);
    if (closeType === CloseType.Tool) {
      this.closeConnection(WebRTCConnectionType.SpecialistTools);
    } else {
      this.videoService.stopVideoStream().subscribe(() => { });
      this.hangup();
    }
  }

  public closeConnection(type: WebRTCConnectionType) {
    const connection = this.getConnectionByType(type);
    if (!connection || !connection.peerConnection) { return; }
    connection.peerConnection.close();
    const connectionIndex = this.runningConnections.findIndex((rc) => rc.type === connection.type);
    if (connectionIndex > -1) {
      this.runningConnections.splice(connectionIndex, 1);
    }
    this.resetConnectionByType(type);
  }

  public resetConnection(connection: WebRTCConnection) {
    const receiverRole = connection.peerRole;
    connection.peerConnection.close();
    const connectionIndex = this.runningConnections.findIndex((rc) => rc.type === connection.type);
    if (connectionIndex > -1) {
      this.runningConnections.splice(connectionIndex, 1);
    }
    const requestData: WebRTCRequestRetry = {
      sessionId: this.sessionId,
      role: this.userRole,
      receiverRole
    };
    console.log('RESET DATA', requestData)
    this.socketService.retryRTCRequest(requestData);
    this.resetConnectionByType(connection.type);
  }

  public hangup() {
    this.runningConnections.forEach((connection:WebRTCConnection)=>{
      connection.peerConnection?.close();
    })
    this.runningConnections=[];
    this.remoteStream = new MediaStream();
    this.sharingScreen = false;
    this.patDoc = this.createEmptyConnection(WebRTCConnectionType.DocPatient);
    this.patSpecialist = this.createEmptyConnection(WebRTCConnectionType.PatientSpecialist);
    this.patTool = this.createEmptyConnection(WebRTCConnectionType.PatientTool);
    this.docTool = this.createEmptyConnection(WebRTCConnectionType.DocTools)
    this.docSpecialist = this.createEmptyConnection(WebRTCConnectionType.DocSpecialist);
    this.specialistTool = this.createEmptyConnection(WebRTCConnectionType.SpecialistTools);
  }

  public setRole(role: SocketUserRole) {
    this.userRole = role;
  }

  public getRunningDataConnections(): WebRTCConnection[] {
    return this.runningConnections.filter((connection) => connection.type && !ToolWebRTCConnectionTypes.includes(connection.type));
  }

  public switchLocalStreamSettings(switchVideo: boolean, switchAudio: boolean, forceState?: boolean) {
    const connections = this.getRunningDataConnections();
    if (switchVideo) {
      const videoTracks = this.streamService.localstream.getVideoTracks();
      videoTracks.forEach((mediaStreamTrack: MediaStreamTrack) => {
        mediaStreamTrack.enabled = forceState === undefined ? !mediaStreamTrack.enabled : forceState;
        console.log("switchLocalStreamSettings: mediaStreamTrack.enabled = ", mediaStreamTrack.enabled)
        this.messagingService.trackChange(connections, Track.Video, mediaStreamTrack.enabled, this.sharingScreen);
      });
    }
    if (switchAudio) {
      const audioTracks = this.streamService.localstream.getAudioTracks();
      audioTracks.forEach((track) => {
        track.enabled = forceState === undefined ? !track.enabled : forceState;
        this.messagingService.trackChange(connections, Track.Audio, track.enabled);
      });
    }
  }

  public toggleScreenShare(deviceId: any, sharingScreen: boolean): void {
    this.sharingScreen = sharingScreen
    this.videoService.toggleScreenShare(deviceId).subscribe({
      next:(track) => {
        this.replaceTrackForConnections(track);
        if (this.sharingScreen) {
          track.onended = () => {
            this.toggleScreenShare('standardCamera', false);
          }
        }
        this.screenshareChange$.next(this.sharingScreen);
      }, 
      error:(error) => {
        console.log("toggleScreenShare: error = ", error)
        this.sharingScreen = false
        this.screenshareChange$.next(false)
        //this.screenshareChange$.error(error)
      }
    })
  }

  public toggleCameraEffect(track: MediaStreamTrack) {
    console.log('Switch to a new track!')
    this.replaceTrackForConnections(track)
  }

  private replaceTrackForConnections(track: MediaStreamTrack) {
    this.runningConnections.forEach((connection) => {
      if (!ToolWebRTCConnectionTypes.includes(connection.type)) {
        const sender = connection.peerConnection.getSenders().find((pcSender) => pcSender.track.kind === 'video');
        sender.replaceTrack(track);
      }
    })
  }

  public switchMediaDevice(videoDeviceId: string, audioDeviceId: string): Observable<MediaStream> {
    return new Observable((observer) => {
      this.videoService.switchMediaDevice(videoDeviceId, audioDeviceId)
        .subscribe({
          next: (stream) => {
            this.runningConnections.forEach((connection) => {
              if (!ToolWebRTCConnectionTypes.includes(connection.type)) {
                this.replaceRTCStreamTracks(connection.peerConnection, stream);
              }
            })
            observer.next(stream);
            observer.complete();
          }, 
          error: (error) => {
            observer.error(error)
            observer.complete();
          }
        })
    })
  }

  private replaceRTCStreamTracks(connection: RTCPeerConnection, stream: MediaStream) {
    const videoSender = connection.getSenders().find((pcSender) => pcSender.track.kind === 'video');
    videoSender.replaceTrack(stream.getVideoTracks()[0]);
    const audioSender = connection.getSenders().find((pcSender) => pcSender.track.kind === 'audio');
    audioSender.replaceTrack(stream.getAudioTracks()[0]);
  }

  /**
   * check if user is impolite peer that ignores offer on collision
   */
  public isConnectionMaster(peerRole: SocketUserRole): boolean {
    const roleOrder = [SocketUserRole.Tool, SocketUserRole.Patient, SocketUserRole.Spezialist, SocketUserRole.Arzt];
    const userIndex = roleOrder.findIndex((role) => role === this.userRole);
    const peerIndex = roleOrder.findIndex((role) => role === peerRole);
    return (userIndex > peerIndex);
  }
}
