export const minBaseQuestion = {
  name: 'Vorname',
  type: 'text',
} as const

export const baseQuestionWithoutShortTitle = {
  ...minBaseQuestion,
}

export const baseQuestion = {
  ...baseQuestionWithoutShortTitle,
  shortTitle: 'vorname',
}
