import { Component } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { QuillEditorComponent, QuillModules } from 'ngx-quill'
import { QuestionAngular } from 'survey-angular-ui'

import { HtmlEditorModel } from '../html-editor-model'

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
  standalone: true,
  imports: [FormsModule, QuillEditorComponent],
})
export class HtmlEditorComponent extends QuestionAngular<HtmlEditorModel> {
  public placeholder = ''
  public quillModules: QuillModules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['link'],
      ],
    },
  }
  public get content() {
    return this.model.value
  }
  public set content(val: string) {
    this.model.value = val
  }
}
