import Joi from 'joi'

import { TitleLocation } from './../../../interfaces-autogen-from-joi/wfa-question/base-question-props/title-location'

export const titleLocationDict: Record<string, TitleLocation> = {
  default: 'default',
  top: 'top',
  bottom: 'bottom',
  left: 'left',
  hidden: 'hidden',
} as const

export const titleLocations = Object.values(titleLocationDict)

export const TitleLocationJoi = Joi.string()
  .valid(...titleLocations)
  .meta({
    className: 'TitleLocation',
  })
  .example('default')
