
import { AppAbility, AppRoleAbilities } from '@a-d/entities/Ability.entity';
import { Injectable } from '@angular/core';
import { createMongoAbility } from '@casl/ability';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AbilityService {
  public appAbilities: AppRoleAbilities = null
  public appRules: AppAbility
  public appRules$ = new BehaviorSubject<AppAbility>(null)

  constructor(private ability: AppAbility) { }

  public initializeUserAbilities(userAbilities: AppRoleAbilities) {
    console.log('my abilities?', userAbilities)
    if (!userAbilities?.abilities) { return; }
    this.appAbilities = userAbilities;
    this.appRules = this.appAbilities.abilities ? createMongoAbility(this.appAbilities.abilities) : createMongoAbility()
    console.log('loadedrules', this.appRules)
    this.appRules$.next(this.appRules);
    console.log('can delete', this.appRules.can('delete', 'Abilities'))
    this.ability.update(this.appRules.rules)
    console.log('CAN DO THIS: ', this.ability.can('create', 'Abilities'))
  }

  public resetAbilities() {
    this.appAbilities = null;
    this.appRules = createMongoAbility();
    this.appRules$.next(this.appRules);
  }
}
