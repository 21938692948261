import { GlobalSettingsService } from "@a-d/dashboard/global-settings/global-settings.service";
import { Announcement } from "@a-d/entities/Global-Setting.entity";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subscription } from "rxjs";
import { LanguageService } from '../../i18n/language.service';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgIf } from "@angular/common";

@UntilDestroy()
@Component({
    selector: 'app-announcement',
    templateUrl: './announcement.component.html',
    styleUrls: ['./announcement.component.scss'],
    standalone: true,
    imports: [NgIf, FontAwesomeModule]
})
export class AnnouncementComponent implements OnInit {
  showAnnouncement: boolean
  @Input() isPreviewMode: boolean // overrides showAnnouncement
  announcementText: string
  announcementUrl: string
  announcementChangeSubscription: Subscription
  constructor(
    public globalSettingsService: GlobalSettingsService,
    private languageService: LanguageService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }
  ngOnInit() {
    this.init()
    this.announcementChangeSubscribe()
  }
  init() {
    const announcement: Announcement = this.globalSettingsService.globalSettings?.announcement
      ? this.globalSettingsService.globalSettings.announcement
      : this.globalSettingsService.announcementDefault
    this.announcementText = this.languageService.activeLang === "DE"
      ? announcement.announcementTextDe
      : announcement.announcementTextEn
    this.showAnnouncement = announcement.showAnnouncement
    this.announcementUrl = announcement.announcementUrl
    this.changeDetectorRef.detectChanges()
  }
  announcementChangeSubscribe() {
    if (this.announcementChangeSubscription)
      this.announcementChangeSubscription.unsubscribe()
    this.announcementChangeSubscription = this.globalSettingsService.globalSettingsChanged
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.init()
      })
  }
}
