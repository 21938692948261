export function mapValues<T extends object, U>(
  obj: T,
  fn: (value: T[keyof T]) => U,
): { [K in keyof T]: U } {
  const result = {} as { [K in keyof T]: U }
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = fn(obj[key])
    }
  }
  return result
}
