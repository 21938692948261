import { FormGetter } from '@a-d/entities/FormGetter.entity';
import { I18NString } from '@a-d/entities/I18N.entity';
import FormValidators from '@a-d/forms/form-validators.service';
import WfaModule from '@a-d/wfr/wfa/wfa.module';
import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';
import { DestinationData } from '../entities/destination';
import { Duration, YMWD } from "./duration";
import { dateDiff, dateDiffTuple, deDuration, enDuration } from "./reise";

@Injectable({
  providedIn: null, // provided in reise.component
})
export class ReiseService {
  static readonly deDuration: Duration = deDuration;
  static readonly enDuration: Duration = enDuration;

  readonly capLength = (n: number) => new RegExp(`^.{1,${n}}$`);
  readonly arrivalDescriptionValidators = [
    FormValidators.regexValidator(this.capLength(240), 'ValidRegex', 'tooLong'),
  ];

  formGroup = new UntypedFormGroup({
    destinations: new UntypedFormArray([this.newDestination()]),
    tripStyle: new UntypedFormControl(
      '',
      WfaModule.WfaFormValidatorsService.dynamicallyRequired
    ),
    purpose: new UntypedFormControl('', WfaModule.WfaFormValidatorsService.dynamicallyRequired),
    customPurpose: new UntypedFormControl(''),
    activities: new UntypedFormControl(''),
    customActivities: new UntypedFormControl(''),
    other: new UntypedFormControl(''),
  });

  destinations$: BehaviorSubject<UntypedFormGroup[]> = new BehaviorSubject([]);

  static accessControl(formGroup: UntypedFormGroup, controlName: string): FormGetter {
    return () => formGroup.get(controlName);
  }

  static accessDestination(
    formGroup: UntypedFormGroup,
    destinationNumber: number,
    controlName: keyof DestinationData
  ): FormGetter {
    const destinations = formGroup.get('destinations') as UntypedFormArray;
    return () => destinations.at(destinationNumber).get(controlName);
  }

  static printableDestinations(
    destinations: DestinationData[],
    dateParser: (x: Date) => string
  ): string {
    const nullish = (v: any) => v === null || v === undefined || v === '';
    const stringified = destinations
      .map((d) => {
        const start = dateParser(d.startDate);
        const end = nullish(d.endDate) ? '---' : dateParser(d.endDate);
        return `${d.country}: ${start} -- ${end}`;
      })
      .join(', ');
    return stringified;
  }

  static dayDiff(d1: Date | null, d2: Date | null): string {
    const date1 = dayjs(d1);
    const date2 = dayjs(d2);
    const numDays = date2.diff(date1, 'day');
    return `${numDays}`;
  }

  // returns array of years, months, weeks, days between two dates
  static dateDiffTuple(d1: Date | null, d2: Date | null): YMWD | null {
    return dateDiffTuple(d1, d2)
  }

  static dateDiff(d1: Date | null, d2: Date | null): I18NString {
    return dateDiff(d1, d2)
  }

  addDestination() {
    const destinations = this.formGroup.get('destinations') as UntypedFormArray;
    destinations.push(this.newDestination());

    const destinationsArray = Object.values(
      (this.formGroup.get('destinations') as UntypedFormArray).controls
    ) as UntypedFormGroup[];
    this.destinations$.next(destinationsArray);

    const lastIndex = destinations.length - 1;
    const previousEndDate = destinations.at(lastIndex - 1).get('endDate').value;
    if (previousEndDate !== undefined) {
      destinations.at(lastIndex).get('startDate').setValue(previousEndDate);
    }
  }

  removeDestination(n: number) {
    const destinations = this.formGroup.get('destinations') as UntypedFormArray;
    destinations.removeAt(n);

    const destinationsArray = Object.values(
      (this.formGroup.get('destinations') as UntypedFormArray).controls
    ) as UntypedFormGroup[];
    this.destinations$.next(destinationsArray);
  }

  newDestination() {
    return new UntypedFormGroup({
      arrivalDescription: new UntypedFormControl(
        '',
        this.arrivalDescriptionValidators
      ),
      country: new UntypedFormControl(
        '',
        WfaModule.WfaFormValidatorsService.dynamicallyRequired
      ),
      startDate: new UntypedFormControl(null, [
        WfaModule.WfaFormValidatorsService.dynamicallyRequired,
        FormValidators.dateValidator,
        FormValidators.pastDayValidator(),
      ]),
      endDate: new UntypedFormControl(null, [
        FormValidators.dateOrder('after', 'startDate', false),
      ]),
    });
  }

  constructor() {
    const destinationsArray = Object.values(
      (this.formGroup.get('destinations') as UntypedFormArray).controls
    ) as UntypedFormGroup[];
    this.destinations$.next(destinationsArray);
  }


}
