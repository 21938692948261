import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MimetypeHelpersService {

  /**
   * Common Mime-Types
   */
  PDF_MIME_TYPES: string[] = [
    'application/pdf',
  ]
  ARCHIVE_MIME_TYPES: string[] = [
    'application/x-tar',
    'application/x-bzip2',
    'application/gzip',
    'application/x-7z-compressed',
    'application/x-rar-compressed',
    'application/zip'
  ]
  IMAGE_MIME_TYPES: string[] = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/bmp',
    'image/svg+xml',
    'image/webp',
  ]
  AUDIO_MIME_TYPES: string[] = [
    'audio/aac',
    'audio/mpeg',
    'audio/ogg',
    'audio/opus',
    'audio/wav',
    'audio/webm',
    'audio/3gpp',
    'audio/3gpp2',
  ]
  VIDEO_MIME_TYPES: string[] = [
    'video/x-msvideo',
    'video/mpeg',
    'video/ogg',
    'video/mp4',
    'video/mp2t',
    'video/webm',
    'video/3gpp',
    'video/3gpp2',
  ]
  DOCUMENT_MIME_TYPES: string[] = [
    'text/plain',
    'application/rtf',
    'application/vnd.oasis.opendocument.text',
  ]
  SPREADSHEET_MIME_TYPES: string[] = [
    'text/comma-separated-values',
    'application/vnd.oasis.opendocument.spreadsheet',
  ]
  PRESENTATION_MIME_TYPES: string[] = [
    'application/vnd.oasis.opendocument.presentation',
  ]
  WORD_MIME_TYPES: string[] = [
    'application/msword',
    'application/vnd.ms-word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.sheet',
    'application/vnd.ms-word.document.macroEnabled.12',
  ]
  EXCEL_MIME_TYPES: string[] = [
    'application/msexcel',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
  ]
  POWERPOINT_MIME_TYPES: string[] = [
    'application/mspowerpoint',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  ]

  ALLOWED_MIME_TYPES: string[] = [
    ...this.PDF_MIME_TYPES,
    ...this.ARCHIVE_MIME_TYPES,
    ...this.IMAGE_MIME_TYPES,
    ...this.AUDIO_MIME_TYPES,
    ...this.VIDEO_MIME_TYPES,
    ...this.DOCUMENT_MIME_TYPES,
    ...this.SPREADSHEET_MIME_TYPES,
    ...this.PRESENTATION_MIME_TYPES,
    ...this.WORD_MIME_TYPES,
    ...this.EXCEL_MIME_TYPES,
    ...this.POWERPOINT_MIME_TYPES,
  ]

  constructor() { }



  /**
   * Returns FontAwesome-Icon for given mime-type.
   */
  public getFileIcon(type: string): string {
    type = (type || '').trim()

    if (this.PDF_MIME_TYPES.includes(type)) return 'file-pdf'
    if (this.ARCHIVE_MIME_TYPES.includes(type)) return 'file-archive'
    if (this.IMAGE_MIME_TYPES.includes(type)) return 'file-image'
    if (this.AUDIO_MIME_TYPES.includes(type)) return 'file-audio'
    if (this.VIDEO_MIME_TYPES.includes(type)) return 'file-video'
    if (this.DOCUMENT_MIME_TYPES.includes(type)) return 'file-alt'
    if (this.SPREADSHEET_MIME_TYPES.includes(type)) return 'file-spreadsheet'
    if (this.PRESENTATION_MIME_TYPES.includes(type)) return 'file-powerpoint'
    if (this.WORD_MIME_TYPES.includes(type)) return 'file-word'
    if (this.EXCEL_MIME_TYPES.includes(type)) return 'file-excel'
    if (this.POWERPOINT_MIME_TYPES.includes(type)) return 'file-powerpoint'

    return 'file-alt'
  }

  /**
   * Returns Material-Icon for given mime-type.
   */
  public getFileMaterialIcon(type: string): string {
    type = (type || '').trim()

    if (this.PDF_MIME_TYPES.includes(type)) return 'picture_as_pdf'
    if (this.ARCHIVE_MIME_TYPES.includes(type)) return 'archive'
    if (this.IMAGE_MIME_TYPES.includes(type)) return 'image'
    if (this.AUDIO_MIME_TYPES.includes(type)) return 'audio_file'
    if (this.VIDEO_MIME_TYPES.includes(type)) return 'video_file'
    if (this.DOCUMENT_MIME_TYPES.includes(type)) return 'insert_drive_file'
    if (this.SPREADSHEET_MIME_TYPES.includes(type)) return 'grid_on'
    if (this.PRESENTATION_MIME_TYPES.includes(type)) return 'cast_for_education'
    if (this.WORD_MIME_TYPES.includes(type)) return 'description'
    if (this.EXCEL_MIME_TYPES.includes(type)) return 'grid_on'
    if (this.POWERPOINT_MIME_TYPES.includes(type)) return 'cast_for_education'

    return 'insert_drive_file'
  }

  public getFileMaterialIconDict(): Record<string, string> {
    return {
      ...[...this.PDF_MIME_TYPES,
      ...this.ARCHIVE_MIME_TYPES,
      ...this.IMAGE_MIME_TYPES,
      ...this.AUDIO_MIME_TYPES,
      ...this.VIDEO_MIME_TYPES,
      ...this.SPREADSHEET_MIME_TYPES,
      ...this.EXCEL_MIME_TYPES,
      ...this.PRESENTATION_MIME_TYPES,
      ...this.WORD_MIME_TYPES,
      ...this.EXCEL_MIME_TYPES,
      ...this.POWERPOINT_MIME_TYPES].reduce((prev, x) => ({ ...prev, [x]: this.getFileMaterialIcon(x) }), {}),
      'unknown': 'insert_drive_file'
    }

  }

}
