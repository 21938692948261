<ng-container *ngIf="status === 'survey'">
  <wfa-viewer
    [form$]="form$"
    [envState$]="envState$"
    [formResponse$]="formResponse$"
    (formResponseDataEmitter)="emitFormResponseData$.next($event)"
  >
  </wfa-viewer>
</ng-container>

<ng-container *ngIf="status === 'submitting'">
  <wfa-user-message [surveyjsJson]="submittingJson"> </wfa-user-message>
</ng-container>

<ng-container *ngIf="status === 'submitted'">
  <wfa-user-message [surveyjsJson]="submittedJson"> </wfa-user-message>
</ng-container>
