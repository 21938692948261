import Joi from 'joi'
import { nonHslaAttributes } from './non-hsla-attributes.joi'
import { hslaAttributes } from './hsla-attributes.joi'
import { HslaColorJoi } from './hsla-color.joi'
import { nonHslaAdColors, hslaAdColors, defaultAdColors } from './ad-colors.default'

export const NonHslaAdColorsJoi = Joi.object()
  .unknown(false)
  .required()
  .keys(
    nonHslaAttributes.reduce((acc, val) => {
      Object.assign(acc, { [val]: Joi.string().required() })
      return acc
    }, {})
  )
  .meta({
    className: 'NonHslaAdColors',
  })
  .example(nonHslaAdColors())

export const HslaAdColorsJoi = Joi.object()
  .unknown(false)
  .required()
  .keys(
    hslaAttributes.reduce((acc, val) => {
      Object.assign(acc, { [val]: HslaColorJoi.required() })
      return acc
    }, {})
  )
  .meta({
    className: 'HslaAdColors',
  })
  .example(hslaAdColors())

export const AdColorsJoi = Joi.object()
  .unknown(false)
  .required()
  .keys(
    [...nonHslaAttributes, ...hslaAttributes].reduce((acc, val) => {
      Object.assign(acc, { [val]: Joi.string().required() })
      return acc
    }, {})
  )
  .meta({
    className: 'AdColors',
  })
  .example(defaultAdColors())
