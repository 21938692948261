export type Nullable<T> = T | null | undefined

/**
 * Checks if a value is null or undefined
 */
export function isNil<T>(x: Nullable<T>): x is null | undefined {
  return x === null || x === undefined
}

/**
 * Checks if a value is neither null nor undefined
 */
export function nonNil<T>(x: Nullable<T>): x is T {
  return x !== null && x !== undefined
}
