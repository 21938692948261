import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { NotificationService } from '@lib/notifications/notification.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, fromEvent, map, mergeMap, Observable, of } from 'rxjs';
import { CertificateService } from '../../crypto/certificate.service';
import { CryptoService } from '../../crypto/crypto.service';
import { UtilityService } from '../../crypto/utility.service';
import { OtkAttachment, WaitinglistStatusType } from '../../entities/Booking.entity';
import { weekDaysShort } from '../../entities/Calendar.entity';
import { PemHelpersService } from '../../misc/pem-helpers.service';
import { BookingService } from '../booking.service';
import { MatButtonModule } from '@angular/material/button';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgTemplateOutlet } from '@angular/common';
import { DialogComponent } from '../../../../../lib/src/lib/dialog/dialog.component';

@UntilDestroy()
@Component({
    selector: 'demo-encryption-dialog',
    templateUrl: './demo-encryption-dialog.component.html',
    styleUrls: ['./demo-encryption-dialog.component.scss'],
    standalone: true,
    imports: [DialogComponent, MatDialogModule, NgTemplateOutlet, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, TextFieldModule, MatButtonModule]
})
export class DemoEncryptionDialogComponent implements OnInit {
  public isLoading = false


  public waitinglistStatus: WaitinglistStatusType

  private instance: string
  private accessCode: string
  private before: Date
  public weekDays = weekDaysShort
  private filename: string
  private mimeType: string

  public noSlots: boolean = false
  @ViewChild('attachmentInput') attachmentInput: ElementRef

  public formGroup = new UntypedFormGroup({
    certificate: new UntypedFormControl(null, [Validators.required])
  })
  get certificate(): AbstractControl { return this.formGroup.get('certificate'); }


  constructor(
    private adLoggerService: AdLoggerService,
    public dialogRef: MatDialogRef<DemoEncryptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public bookingService: BookingService,
    public notificationService: NotificationService,
    public pemHelpersService: PemHelpersService,
    public utilityService: UtilityService,
    public certificateService: CertificateService,
    public cryptoService: CryptoService
  ) { }

  ngOnInit(): void {

  }

  encrypt() {
    const file = this.attachmentInput.nativeElement.files.item(0)

    if (!file) {
      this.notificationService.displayNotification('Keine Datei vorhanden');
      return
    }
    // check allowed file size
    if (file.size > (1024 * 1024 * 12)) {
      alert(`Diese Datei ist zu groß ()>12 mb) `);
      return
    }

    this.isLoading = true;
    const cert = this.pemToBuffer()

    this.fileToDataUri(file).pipe(
      untilDestroyed(this),
      mergeMap(
        (dataUri) => {
          this.notificationService.displayNotification("Datei wird verschlüsselt")
          return this.cryptoService.encrypt(cert, dataUri as string)
        }))
      .subscribe((enc) => {
        this.isLoading = false
        this.notificationService.displayNotification("Datei verschlüsselt")
        const otkAttachment: OtkAttachment = {
          data: enc,
          mimeType: this.mimeType,
          filename: this.filename
        }
        this.dialogRef.close(otkAttachment)
      })

  }



  pemToBuffer() {
    //const cert = this.certificateService.decodeCert(this.certificate.value.trim())
    const pem = this.certificate.value.trim()
    // Load certificate in PEM encoding (base64 encoded DER)
    const b64 = pem.replace(/(-----(BEGIN|END) CERTIFICATE-----|[\n\r])/g, '')
    // Now that we have decoded the cert it's now in DER-encoding
    const der = Buffer.from(b64, 'base64');

    // And massage the cert into a BER encoded one :)
    const cert = new Uint8Array(der).buffer

    return cert
  }


  private fileToDataUri(file: File): Observable<string> {

    this.filename = file.name
    this.mimeType = file.type
    const reader = new FileReader()

    reader.readAsDataURL(file)
    return fromEvent(reader, 'load').pipe(
      map((_) => {
        if (reader.error) { throw reader.error } else { return reader.result as string }
      }),
      catchError((error) => {
        this.adLoggerService.error(error)
        this.notificationService.displayNotification('Fehler beim Upload')
        return of(null)
      }),
      map((dataUri: string) => {
        if (!dataUri) { return null }
        return dataUri
      }))
  }


}
