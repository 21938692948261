import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'secondsToDoubleDigitsMinutes',
    pure: false,
    standalone: true
})
export class SecondsToDoubleDigitsMinutesPipe implements PipeTransform {
  transform(timeInSeconds: number): any {
    const minutes: number = Math.floor(timeInSeconds / 60)
    const seconds: number = timeInSeconds - minutes * 60
    const minutesString: string = minutes > 9
      ? String(minutes)
      : "0" + String(minutes)
    const secondsString: string = seconds > 9
      ? String(seconds)
      : "0" + String(seconds)
    return minutesString + ":" + secondsString
  }
}
