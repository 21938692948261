const instanceWhitelabelDict = Object.freeze({
  NONE: 'none',
  BER: 'bereitschaftsdienst',
  KLAW: 'kanzlaw',
  MD: 'medikonsil-direkt',
  MP: 'medipolis',
  MRO: 'mein-rezept-online',
  REHA: 'reha-aktiv',
})

module.exports = {
  instanceWhitelabelDict
}
