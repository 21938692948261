import { OTKFieldConfig } from "@a-d/entities/Calendar.entity";
import { pipeOperator } from "@a-d/misc/pipe-operator";
import { TranslateService } from "@ngx-translate/core";

// default and user basic field settings
type DefaultAndUserBS = {
  defaultBS: OTKFieldConfig[];
  userBS: OTKFieldConfig[];
};

// todo: replace the hardcoded ".de" with custom language support
export function applyUserSettingsToFieldConfig(
  basicSettings: OTKFieldConfig[],
  defaultBS: OTKFieldConfig[],
  translateService: TranslateService
): OTKFieldConfig[] {
  const newBasicSettings = pipeOperator(
    { userBS: basicSettings, defaultBS },
    [
      removeUnusedUserSettings,
      addMissingDefaultSettings,
      alwaysOverwriteWithDefault(["editableName"]),
      overwriteConfigKeyWithDefault("info", "limit"),
      sortBasicByName(translateService),
    ].map(padOutput)
  );
  return newBasicSettings.userBS;
}

function removeUnusedUserSettings({
  userBS,
  defaultBS,
}: DefaultAndUserBS): OTKFieldConfig[] {
  const allowedKeys = defaultBS.map((c) => c.identifier);
  return userBS
    .filter((c) => allowedKeys.includes(c.identifier))
    .map((config) => Object.assign({}, config))
}

function addMissingDefaultSettings({
  userBS,
  defaultBS,
}: DefaultAndUserBS): OTKFieldConfig[] {
  const allowedConfigs = defaultBS;
  const presentKeys = userBS.map((c) => c.identifier);
  const missingConfigs = allowedConfigs.filter(
    (c) => !presentKeys.includes(c.identifier)
  );

  return [
    ...userBS.map((config) => Object.assign({}, config)),
    ...missingConfigs,
  ];
}

function sortBasicByName(translateService: TranslateService) {
  const translate = (c: OTKFieldConfig) =>
    translateService.instant("OTK.BOOKING-PERSONAL." + c.identifier);

  return function ({ userBS }: DefaultAndUserBS): OTKFieldConfig[] {
    let sorted = userBS.map((config) => Object.assign({}, config));
    sorted.sort((configA, configB) => {
      const a = translate(configA)
      const b = translate(configB)
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

    return sorted;
  }

}


/*
 * For all basic configs of userSettings, for certain keys, always use default values
 * from FieldSettingsService.
 */
var alwaysOverwriteWithDefault = (overrideKeys: (keyof OTKFieldConfig)[]) =>
  function ({ userBS, defaultBS }: DefaultAndUserBS): OTKFieldConfig[] {
    const defaultBSonfigs = defaultBS;
    const defaultIds = defaultBSonfigs.map((c) => c.identifier);

    return userBS
      .filter((uc) => defaultIds.includes(uc.identifier))
      .map((uc) => {
        const ucCopy = { ...uc };
        const defaultSetting = Object.assign(
          {},
          ...overrideKeys.map(
            extractField(getConfig(defaultBSonfigs, uc.identifier))
          )
        );
        return Object.assign(ucCopy, defaultSetting);
      });
  };

export function overwriteConfigKeyWithDefault(configId: string, overrideKey: (keyof OTKFieldConfig)) {
  return function ({ userBS, defaultBS }: DefaultAndUserBS): OTKFieldConfig[] {
    const defaultValue = defaultBS.find(c => c.identifier===configId)?.[overrideKey]
    if (defaultValue == null) return userBS
    return userBS
      .map((uc) => {
        if (uc.identifier !== configId) return uc
        return {...uc, [overrideKey]: defaultValue}
    })
  }
}

const ensureKeyExists = (
  fieldName: string,
  key: keyof OTKFieldConfig,
  valueIfKeyMissing: OTKFieldConfig[keyof OTKFieldConfig]
) =>
  function ({ userBS }: DefaultAndUserBS): OTKFieldConfig[] {
    const config = userBS.find((config) => config.identifier === fieldName);
    if (config == null) return userBS;
    if (config[key] != null) return userBS;

    return userBS.map((config) => {
      if (config.identifier !== fieldName) return config;
      return Object.assign({}, config, { [key]: valueIfKeyMissing });
    }) as unknown as OTKFieldConfig[];
  };

// helpers /////////////////////////////////////////////////////////////////////
var getConfig = (configs: OTKFieldConfig[], id: string) =>
  configs.find((config) => config.identifier === id);

function extractField(config: OTKFieldConfig): (x: string) => Object {
  if (config == null) return (_: string) => ({});
  return (fieldName: string) =>
    config[fieldName] != null ? { [fieldName]: config[fieldName] } : {};
}

/**
 * For a function `fn` that takes `userBS` and `defaultBS`, and returns only
 * `userBS`, create an function that returns both.
 **/
var padOutput =
  (fn: (x: DefaultAndUserBS) => OTKFieldConfig[]) => (x: DefaultAndUserBS) => ({
    userBS: fn(x),
    defaultBS: x.defaultBS,
  });
