import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { Injectable } from '@angular/core';
import PCMPlayer from 'pcm-player';
import { AudioPlayer } from './audio-player/audio-player';

@Injectable({
  providedIn: 'root'
})
export class WebRTCAudioService {
  public audioContext = new AudioContext({
    latencyHint: 'interactive',
    sampleRate: 44100
  });
  public audioWorkletNode: AudioWorkletNode;
  private startTime = 0;
  private player: PCMPlayer;
  private audioPlayer: AudioPlayer
  private _volume = .75;

  public get hasPlayer(): AudioPlayer { return this.audioPlayer; }

  constructor(
    private adLoggerService: AdLoggerService,
  ) { }

  public init(): void {
    this.audioContext = new AudioContext();
  }

  get volume(): number { return this._volume; }
  set volume(vol: number) {
    this._volume = vol;
    this.player.volume(this._volume);
    this.audioPlayer.volume(this._volume);
  }

  public initAudioPlayer() {
    console.log('INIT AUDIO PLAYER', this.audioPlayer)
    if (this.audioPlayer) {
      this.audioPlayer.destroy().subscribe(() => this.startAudioPlayer())
    } else {
      this.startAudioPlayer();
    }
  }

  private startAudioPlayer() {
    this.audioPlayer = new AudioPlayer();
    this.audioPlayer.volume(this._volume);
  }

  public deleteAudioPlayer() {
    this.audioPlayer.destroy();
  }

  public streamAudio(data: any) {
    this.audioPlayer.feedBuffer(data);
  }

  public startPlayer() {
    this.player = new PCMPlayer({
      inputCodec: 'Float32',
      channels: 2,
      sampleRate: 44100,
      flushTime: 1000,
      onstatechange: undefined,
      onended: undefined
    });
    this.player.volume(this._volume);
  }

  public deletePlayer() {
    if (this.audioPlayer) {
      this.audioPlayer.destroy();
    }
    if (this.player) {
      this.player.pause()
        .then(() => {
          this.player.destroy();
          this.player = null;
        })
        .catch((error) => {
          console.warn('player was already destroyed', error);
        });
    }
  }

  public setPCMPlayer(playerSettings: any) {
    this.player = new PCMPlayer(playerSettings);
  }

  public setVolume(volume: number) {
    this.player.volume(volume);
  }

  public streamPCM(eventData: any) {
    this.player.feed(eventData);
  }


  public playWhiteNoise() {
    const channelNumber = 2; // stereo
    const frameCount = this.audioContext.sampleRate * 2;
    const audioBuffer = this.audioContext.createBuffer(channelNumber, frameCount, this.audioContext.sampleRate);

    // fill buffer with white noise, values between -1.0 -> 1.0
    for (let channel = 0; channel < channelNumber; channel++) {
      // actual channel data
      const buffering = audioBuffer.getChannelData(channel);
      for (let i = 0; i < frameCount; i++) {
        buffering[i] = Math.random() * 2 - 1;
      }
    }

    // audio node to play the buffer sound
    const audioSource = this.audioContext.createBufferSource();
    audioSource.buffer = audioBuffer;
    // connect buffer source node to the destination to be able to hear the audio
    audioSource.connect(this.audioContext.destination);
    audioSource.start();

    audioSource.onended = () => {
      console.log('finished playing the audio')
    }
  }

  public createAudioProcessor(): void {
    try {
      this.audioContext.audioWorklet.addModule('./projects/arzt-direkt/src/app/web-rtc/audio.processor.ts'); // ???
    } catch (error) {
      this.adLoggerService.error('FAILURE TO INIT', error);
      return null;
    }
    this.audioWorkletNode = new AudioWorkletNode(this.audioContext, 'web-RTC-audio-processor');
  }
}
