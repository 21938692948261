import { NgModule } from "@angular/core";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SharedModule } from "../shared.module";
import { DsgvoPreferencesDialogComponent } from "./dsgvo-preferences-dialog.component";
import { DsgvoComponent } from "./dsgvo.component";

@NgModule({
    imports: [
        SharedModule,
        MatSlideToggleModule,
        MatBottomSheetModule,
        DsgvoComponent,
        DsgvoPreferencesDialogComponent,
    ],
    exports: [
        DsgvoComponent,
        DsgvoPreferencesDialogComponent
    ]
})
export class DsgvoModule { }
