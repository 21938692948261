import { Injectable, NgModule } from '@angular/core';
import { PreloadingStrategy, Route, Router, RouterModule, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../environments/environment';
import { BookingCancelComponent } from './booking/booking-cancel/booking-cancel.component';
import { GlobalSettingsGuard } from './dashboard/global-settings/global-settings.service';
import { DsgvoGuard } from './dsgvo/dsgvo.guard';
import { InstanceModule } from './entities/Instance.entity';
import { InstanceEnabledGuard, InstanceExistsGuard, RemoveInstanceGuard } from './instance/instance-guards.service';
import { BrowserSupportGuard, BrowserSupportService } from './misc/browser-support/browser-support.service';
import { AppDemoGuard } from './misc/demo-guard.service';
import { GuardsSynchronizierGuard } from './misc/guards-synchronizier-guard.service';
import { ModuleGuard } from './misc/module-guard.service';
import { OtkUserGuard } from './shared-services/otkuser-guard';


export const routes: Routes = [

  /* Landing-Page Reroute */
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/portal/patient?insurance=gkv'
  },

  /* Portal */
  {
    path: 'portal',
    loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule),
    canActivate: [RemoveInstanceGuard, DsgvoGuard],
  },

  /* Styled Documents */
  {
    path: 'dokumente',
    loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
    canActivate: [RemoveInstanceGuard, DsgvoGuard],
  },

  /* HW Test Standalone */
  {
    path: 'hw-test',
    loadChildren: () => import('./devices-tests/devices-tests.module').then(m => m.DevicesTestsModule),
    canActivate: [RemoveInstanceGuard, DsgvoGuard],
  },
  {
    path: 'log-download',
    loadChildren: () => import('./log-download/log-download.module').then(m => m.LogDownloadModule),
    canActivate: [RemoveInstanceGuard, DsgvoGuard],
  },

  {
    path: 'webforms',
    loadChildren: () => import('./wfr/wfa/adis-wfa-env.module').then(
      (m) => m.AdisWfaEnvModule
    ),
    canActivate: [],
  },


  /* Instance-Setup */
  {
    path: 'instance/setup/:link',
    pathMatch: 'full',
    canActivate: [RemoveInstanceGuard],
    loadChildren: () => import('./instance-setup/instance-setup.module').then(m => m.InstanceSetupModule),
    data: { title: 'Instanz setup' }
  },

  /* Admin Shortcut */
  {
    path: 'admin',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/admin/auth/login'
      },
      // special route to redirect paths that doesn't work well for admin (e.g. 'Instanz-Startseite', '/dokumente',...)
      // redirect to login page (via ':instanceIdentifier') except for '/dashboard' (and '/auth/login') 
      {
        matcher: (url) => {
          return url.length >= 1 && ['auth', 'dashboard'].includes(url[0].path) ? null : ({ consumed: url });
        },
        redirectTo: '/admin/auth/login'
      }
    ]
  },

  // special route for otk incognito mode to cancel
  {
    path: 'booking-cancel',
    component: BookingCancelComponent,
    pathMatch: 'full',
  },

  /* Instance-Routes (admin is also treated as an instance in this regard) */
  {
    path: ':instanceIdentifier',
    canActivate: [InstanceExistsGuard, DsgvoGuard],
    children: [
      /* Styled Documents */
      {
        path: 'dokumente',
        loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
      },
      /* Instanz-Startseite */
      {
        path: '',
        pathMatch: 'full',
        canActivate: [BrowserSupportGuard],
        loadChildren: () => import('./instance-home/instance-home.module').then(m => m.InstanceHomeModule),
        data: {
          preloadedBy: ['^/$']
        },
      },
      /* Arzt-/Verwalter-Login & -Dashboard */
      {
        path: 'auth',
        canActivate: [BrowserSupportGuard],
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
      }, {
        path: 'login',
        redirectTo: 'auth/login',
      }, {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [BrowserSupportGuard, GlobalSettingsGuard, OtkUserGuard],
        data: {
          title: "Dashboard",
          preloadedBy: ['^/[^/]+/auth.*$']
        },
      },

      /* Formulare */
      {
        path: 'patient/:formIdentifier',
        pathMatch: 'full',
        redirectTo: 'form/:formIdentifier',
      },
      {
        path: 'form',
        loadChildren: () => import('./instance-form/instance-form.module').then(m => m.InstanceFormModule),
        canActivate: [BrowserSupportGuard, InstanceEnabledGuard, GlobalSettingsGuard, ModuleGuard],
        data: {
          preloadedBy: ['^/[^/]+$'],
          module: InstanceModule.VSS
        },
      },
      {
        path: 'booking',
        loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule),
        canActivate: [GuardsSynchronizierGuard],
        data: {
          preloadedBy: ['^/[^/]+$'],
          guards: [BrowserSupportGuard, InstanceEnabledGuard, GlobalSettingsGuard, ModuleGuard],
          module: InstanceModule.OTK
        }
      },
      // originally we had two designs: booking and booking2.
      // since then booking has been deleted and booking2 was renamed booking.
      // the '/booking2' route was never meant to be used by the users directly anyways,
      // but just in case some are mistakenly using it, we keep the route here with automatic forwarding.
      {
        path: 'booking2',
        redirectTo: 'booking',
      },

      /* VSS-Warteraum & Spezialist-Beitritt */
      {
        path: 'warteraum/:id',
        loadChildren: () => import('./warteraum/warteraum.module').then(m => m.WarteraumModule),
        canActivate: [InstanceEnabledGuard, GlobalSettingsGuard, ModuleGuard],
        data: {
          title: "Warteraum",
          preloadedBy: ['^/[^/]+/form.*$'],
          module: InstanceModule.VSS
        },
      }, {
        path: 'spezialist',
        loadChildren: () => import('./specialist/specialist.module').then(m => m.SpecialistModule),
        canActivate: [InstanceEnabledGuard, ModuleGuard],
        data: {
          title: "Spezialist",
          module: InstanceModule.VSS
        },
      },
      {
        path: 'konsilWarteraum/:id',
        loadChildren: () => import('./council-arzt/council-arzt.module').then(m => m.CouncilArztModule),
        canActivate: [InstanceEnabledGuard, ModuleGuard],
        data: {
          module: InstanceModule.VSS
        },
      },
      {
        path: 'vssdone',
        loadChildren: () => import('./session-done/session-done.module').then(m => m.SessionDoneModule),
        canActivate: [InstanceEnabledGuard, ModuleGuard],
        data: {
          module: InstanceModule.VSS
        },
      },

      /* Fallback (Instance) */
      {
        path: '**',
        redirectTo: '',
      }
    ]
  },


  /* Fallback (Global) */
  {
    path: '**',
    redirectTo: '',
  },

]



/**
 * Demo-Routes are only enabled if `demoMode` is true.
 */
export const demoRoutes: Routes = [
  /* To Admin-Login */
  // {
  //   path: '',
  //   children: [],
  //   pathMatch: 'full',
  //   canActivate: [RemoveInstanceGuard, InternalRedirectGuard],
  //   data: {
  //     internalUrl: '/admin/auth/login'
  //   }
  // },

  /* Multiple Demo-Routes */
  {
    path: 'demo',
    canActivate: [AppDemoGuard],
    children: [
      {
        path: 'crypto',
        loadChildren: () => import('./crypto/crypto.module').then(m => m.CryptoModule),
        canActivate: [],
        data: { title: "Crypto-Tests" }
      }, {
        path: 'email',
        loadChildren: () => import('./email/email-demo.module').then(m => m.EmailDemoModule),
        canActivate: [],
        data: { title: "Email-Tests" }
      },
      /* {
        path: 'otk-booking',
        component: OtkBookingComponent,
      }, */
      // TODO import this as a proper lib
      {
        path: 'otk-booking',
        loadChildren: () => import('../../../otk-booking/src/routes').then(route => route.OTK_BOOKING_ROUTES)
      },
      {
        path: 'tests',
        loadChildren: () => import('./tests/tests-demo.module').then(m => m.TestsDemoModule),
        canActivate: [],
        data: { title: "Tests" }
      },
      {
        path: 'segmentation',
        loadChildren: () => import('./segmentation/segmentation.module').then(m => m.SegmentationModule),
        canActivate: [],
        data: { title: 'Segmentation-Tests' }
      },
      {
        path: 'webforms',
        loadChildren: () => import('./wfr/wfa/adis-wfa-env.module').then(
          (m) => m.DemoAdisWfaEnvModule
        ),
        canActivate: [],
      },
      {
        path: 'webrtc',
        loadChildren: () => import('./web-rtc/web-rtc.module').then(m => m.WebRTCModule),
        canActivate: [],
        data: { title: 'WebRTC-Tests' }
      },
      {
        path: '**',
        redirectTo: 'tests',
      }
    ],
  },
];



/**
* Custom Preloading-Strategy
*/
@Injectable({
  providedIn: 'root'
})
export class AppPreloadingStrategy implements PreloadingStrategy {

  private preloadedPaths = []

  constructor(
    private router: Router,
    private browserSupportService: BrowserSupportService
  ) { }

  preload(route: Route, load: Function): Observable<any> {
    if (!route || !route.data || !route.data.preloadedBy) {
      return of(null);
    }

    const url = this.router.url
    const doPreload = route.path && route.data.preloadedBy.filter((x) => new RegExp(x).test(url)).length
    const alreadyPreloaded = this.preloadedPaths.includes(route.path)
    const browserIsSupported = this.browserSupportService.getAreAppRequirementsSatisfied()

    if (doPreload && browserIsSupported && !alreadyPreloaded) {
      console.info(`Preloading '${route.path}'..`)
      this.preloadedPaths.push(route.path)
      return load()
    } else {
      return of(null)
    }
  }

}



@NgModule({
  imports: [
    RouterModule.forRoot(
      environment.demoMode ? [...demoRoutes, ...routes] : routes,
      {
        preloadingStrategy: AppPreloadingStrategy,
        anchorScrolling: 'enabled'
      }
    )
  ],
  exports: [RouterModule],
  providers: [],
  declarations: []
})

export class AppRoutingModule { }
