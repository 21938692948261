<mat-stepper orientation="vertical" [linear]="true">
  <mat-step [completed]="firstCompleted">
    <ng-template matStepLabel>{{
      settings.reiseStep | i18nString
      }}</ng-template>
    <app-reise [anamneseForm]="anamneseForm" [settings]="reiseSettings" #reise></app-reise>
  </mat-step>
  <mat-step [completed]="secondCompleted">
    <ng-template matStepLabel>{{ settings.goStep | i18nString }}</ng-template>
    <app-go [anamneseForm]="anamneseForm" [settings]="goSettings" #go></app-go>
  </mat-step>
</mat-stepper>