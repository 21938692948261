import { BookingOpeningL, KdSetAlt, MultiOpening, OtkAttachment } from '@a-d/entities/Booking.entity';
import { Asset } from '@a-d/entities/PatientSession.entity';
import { InstanceService } from '@a-d/instance/instance.service';
import { Injectable } from '@angular/core';
import { NotificationService } from '@lib/notifications/notification.service';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, forkJoin, from, of } from 'rxjs';
import { concatMap, map, toArray } from 'rxjs/operators';
import { CryptoWorkerService } from '../crypto/crypto.worker.service';
import { OTKUser } from '../entities/Calendar.entity';


@Injectable({
  providedIn: 'root'
})
export class BookingHelpersService {

  public otkUser: OTKUser

  constructor(
    private instanceService: InstanceService,
    private cryptoWorkerService: CryptoWorkerService,
    private notificationService: NotificationService,
  ) { }




  public encryptOtkAssets(assets: Asset[], cert: string, encrypting: BehaviorSubject<boolean>[], encryptionDone: BehaviorSubject<boolean>[]): Observable<OtkAttachment[]> {
    // const mockSessionData = { assets: assetsRepacked, person: null, anamnese: null, insurance: null }) 
    this.notificationService.displayNotification('Ihre Dateien werden verschlüsselt')

    return from(assets.entries()).pipe(
      concatMap(([idx, asset]) => {
        encrypting[idx].next(true)
        return forkJoin(
          {
            idx: of(idx),
            encryptedAsset: this.cryptoWorkerService.encryptAsset(cert, asset),
            mimeType: of(asset.meta.type),
            filename: of(asset.meta.name)
          })
      }),
      map(({ idx, encryptedAsset, mimeType, filename }): OtkAttachment => {
        encryptionDone[idx].next(true);
        return {
          data: encryptedAsset,
          mimeType,
          filename
        }
      }),
      toArray())
  }



  public prepareOpeningsMultiAndKdSetMap(kdSetMap: Map<number, KdSetAlt>, multiIn: MultiOpening[][]): BookingOpeningL[][] {
    //clear map 
    kdSetMap.clear()

    console.time("prepareOpeningsMulti")
    let openingsMulti: BookingOpeningL[][] = []
    let idx: number = 0
    for (let i = 0; i < multiIn.length; i++) {
      openingsMulti.push([])
      for (let j = 0; j < multiIn[i].length; j++) {
        const kids = multiIn[i][j].kids
        const lids = multiIn[i][j].lids
        kdSetMap.set(idx, { kids, lids })
        const newOps = multiIn[i][j].openings.map(op => ({
          ...op,
          kdSetKey: idx,
          date: dayjs(op.start)
        }))
        openingsMulti[i].push(...newOps)
      }
      openingsMulti[i] = openingsMulti[i].sort((opening1, opening2) =>
        opening1.date.diff(opening2.date))
    }
    console.timeEnd("prepareOpeningsMulti")

    return openingsMulti
  }

}
