import Joi from 'joi'

import { LocStringJoi } from '../loc-string.joi'

export const WfaQuestionValidatorJoi = Joi.object({
  type: Joi.string().required(),
  text: LocStringJoi.optional().description(
    `message displayed if validation fails`,
  ),
  expression: Joi.string()
    .optional()
    .description(
      `expression validation for built-in and custom functions see: {@link https://surveyjs.io/form-library/documentation/design-survey/conditional-logic#expressions}`,
    ),
  minCount: Joi.number().optional().description(`answercount validation`),
  maxCount: Joi.number().optional().description(`answercount validation`),
  minLength: Joi.number().optional().description(`text validation`),
  maxLength: Joi.number().optional().description(`text validation`),
  minValue: Joi.number().optional().description(`numeric validation`),
  maxValue: Joi.number().optional().description(`numeric validation`),
  regex: Joi.string().optional().description(`regex validation`),
})
  .description(
    `See: {@link https://surveyjs.io/form-library/documentation/data-validation#built-in-client-side-validators}`,
  )
  .meta({
    className: 'WfaQuestionValidator',
  })
