import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { ActiveInstance } from '../../../entities/ActiveInstance.entity';
import { InstanceForm } from '../../../entities/InstanceForm.entity';
import { FormHelpers } from '../../../forms/form-helpers.service';
import { InstanceService } from '../../../instance/instance.service';
import { UsefulComponent } from '../../../misc/useful.component';
import { InstanceFormInterface } from '../../instance-form-component.interface';
import { InstanceFormService } from '../../instance-form.service';
import { AnamneseForm } from '../anamnese-forms.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectionFieldComponent } from '../../../forms/fields/selection-field.component';


export interface AllgemeinAnamneseFormSettings {
  readonly diseaseOptions: string[]
  readonly showPreExistingDiseases: boolean
  readonly showPreExistingAllergies: boolean
  readonly showMedication: boolean
}


@Component({
    selector: 'app-allgemein-anamnese-form',
    templateUrl: './allgemein-anamnese-form.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        SelectionFieldComponent,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        NgIf,
        TranslateModule,
    ],
})
export class AllgemeinAnamneseFormComponent extends UsefulComponent implements OnInit, InstanceFormInterface {

  @Input() anamneseForm: AnamneseForm
  @Input() settings: AllgemeinAnamneseFormSettings

  /**
   * Form-Group
   */
  public formGroup = new UntypedFormGroup({
    diseaseSelection: new UntypedFormControl(''),
    diseaseDescription: new UntypedFormControl(''),
    diseaseHistory: new UntypedFormControl(''),
    preExistingDiseases: new UntypedFormControl(''),
    preExistingAllergies: new UntypedFormControl(''),
    medication: new UntypedFormControl(''),
  })

  public get diseaseSelection(): AbstractControl { return this.formGroup.get('diseaseSelection') }
  public get diseaseDescription(): AbstractControl { return this.formGroup.get('diseaseDescription') }
  public get diseaseHistory(): AbstractControl { return this.formGroup.get('diseaseHistory') }
  public get preExistingDiseases(): AbstractControl { return this.formGroup.get('preExistingDiseases') }
  public get preExistingAllergies(): AbstractControl { return this.formGroup.get('preExistingAllergies') }
  public get medication(): AbstractControl { return this.formGroup.get('medication') }


  /**
    * Current Instance & Instance-Form Helpers
   */
  public get activeInstance(): ActiveInstance { return this.instanceService.activeInstance }
  public get activeInstanceForm(): InstanceForm { return this.instanceFormService.activeInstanceForm }
  public readonly isDemoMode = environment.demoMode


  constructor(
    private instanceService: InstanceService,
    private instanceFormService: InstanceFormService,
    private formHelpers: FormHelpers,
    private cd: ChangeDetectorRef,
  ) {
    super()
    this.formHelpers.syncActiveAnamneseFormWithLocalStorage(this.activeInstanceForm, this.formGroup, this.unsubscribe$)
  }

  onInit() {
    if (!this.settings.showPreExistingDiseases) this.preExistingDiseases.setValue(null)
    if (!this.settings.showPreExistingAllergies) this.preExistingAllergies.setValue(null)
    if (!this.settings.showMedication) this.medication.setValue(null)
  }



  // ---------------------//
  // InstanceFormInterface //
  // ---------------------//

  public getData(): any {
    return this.formHelpers.trimmedRawValue(this.formGroup)
  }

  public magicFill() {
    if (!this.isDemoMode) return

    const randomSelection = this.settings.diseaseOptions[Math.floor(Math.random() * this.settings.diseaseOptions.length)]
    this.diseaseSelection.setValue([randomSelection])
    if (this.settings.showPreExistingAllergies) this.preExistingAllergies.setValue('Laktoseintoleranz')
    if (this.settings.showMedication) this.medication.setValue('Nach dem Aufstehen habe ich zwei ASS genommen.')

    this.cd.detectChanges()
  }

}
