<div
  class="dialog-content-wrapper"
  [ngClass]="{
    'is-embedded-in-arzt-direkt': data.isEmbeddedIn === 'arztDirekt',
    'is-embedded-in-ad-stepper': data.isEmbeddedIn === 'adStepper',
  }"
>
  <h1
    mat-dialog-title
    [ngClass]="{
      'is-embedded-in-arzt-direkt': data.isEmbeddedIn === 'arztDirekt',
      'is-embedded-in-ad-stepper': data.isEmbeddedIn === 'adStepper',
    }"
  >
    <span>{{ 'FILE-DELETION-DIALOG.TITLE' | translate }}</span>
    <button mat-icon-button mat-dialog-close>
      <fa-icon [icon]="faXmark"></fa-icon>
    </button>
  </h1>

  <div
    mat-dialog-content
    [ngClass]="{
      'is-embedded-in-ios-tomedo': data.isEmbeddedIn === 'iOsTomedo',
    }"
  >
    <p>
      <span
        *ngIf="data.fileName"
        [innerHTML]="
          'FILE-DELETION-DIALOG.CONTENT-SINGLE'
            | translate: { value: data.fileName }
        "
      >
      </span>
      <span
        *ngIf="!data.fileName"
        [innerHTML]="'FILE-DELETION-DIALOG.CONTENT-ALL' | translate"
      >
      </span>
    </p>
  </div>

  <div mat-dialog-actions>
    <button mat-button class="btn-primary" (click)="continue()">
      <span
        [ngClass]="{
          'is-embedded-in-arzt-direkt': data.isEmbeddedIn === 'arztDirekt',
        }"
      >
        {{ 'FILE-DELETION-DIALOG.CONTINUE' | translate }}
      </span>
    </button>
    <button mat-button mat-dialog-close class="btn-secondary">
      <span
        [ngClass]="{
          'is-embedded-in-arzt-direkt': data.isEmbeddedIn === 'arztDirekt',
        }"
      >
        {{ 'FILE-DELETION-DIALOG.CANCEL' | translate }}
      </span>
    </button>
  </div>
</div>
