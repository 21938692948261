import Joi from 'joi'

import { WfaAttachmentJoi } from './wfa-attachment.joi'
import { WfaForm_v1_5_0Joi } from './wfa-form-versions/wfa-form-v1-5-0.joi'
import { WfaForm_v2_7_6Joi } from './wfa-form-versions/wfa-form-v2-7-6.joi'
import { WfaForm_v2_9_0Joi } from './wfa-form-versions/wfa-form-v2-9-0.joi'
import {
  wfaForm_v2_10_0Default,
  WfaForm_v2_10_0Joi,
} from './wfa-form-versions/wfa-form-v2-10-0.joi'

export const MostRelaxedWfaFormJoi = WfaForm_v1_5_0Joi.meta({
  className: 'MostRelaxedWfaForm',
})

export const wfaFormDefault = wfaForm_v2_10_0Default
export const WfaFormDraftJoi = WfaForm_v2_10_0Joi.meta({
  className: 'WfaFormDraft',
})

export const WfaFormJoi = WfaForm_v2_10_0Joi.meta({
  className: 'WfaForm',
})

export const AnyWfaFormJoi = Joi.alternatives()
  .try(
    WfaForm_v1_5_0Joi,
    WfaForm_v2_7_6Joi,
    WfaForm_v2_9_0Joi,
    WfaForm_v2_10_0Joi,
  )
  .meta({
    className: 'AnyWfaForm',
  })

export const wfaFormJoiDict = {
  'v1.5.0': WfaForm_v1_5_0Joi,
  'v2.7.6': WfaForm_v2_7_6Joi,
  'v2.9.0': WfaForm_v2_9_0Joi,
  'v2.10.0': WfaForm_v2_10_0Joi,
}

export const LeanWfaFormJoi = WfaFormJoi.description(
  'Like WfaForm, but with all files & images (base64 strings) replaced by a url',
).meta({
  className: 'LeanWfaForm',
})

export const LeanWfaFormWithAttachmentsJoi = LeanWfaFormJoi.keys({
  attachments: Joi.array().items(WfaAttachmentJoi).optional(),
})
  .description(
    'Like LeanWfaForm, but with all files & images collected together in a separate attribute',
  )
  .meta({
    className: 'LeanWfaFormWithAttachments',
  })
