import { fromBER } from 'asn1js'
import {
  Certificate,
  CertificateSet,
  ContentInfo,
  EnvelopedData,
  OriginatorInfo,
} from 'pkijs'
import { Observable } from 'rxjs'
import { defaults } from '../defaults'

/**
 * container to hold encrypted data and recipient information
 * @param certificateBuffer the users personal certificate
 * @param msgBuffer array buffer of the message
 */
export function envelopData(
  certificateBuffer: ArrayBuffer,
  msgBuffer: ArrayBuffer,
  algorithm: Algorithm = defaults.crypto.encryptAlgorithm,
): Observable<ArrayBuffer> {
  return new Observable((observer) => {
    const asn1 = fromBER(certificateBuffer)
    const certificate = new Certificate({ schema: asn1.result })
    const cmsEnvelopedData = new EnvelopedData({
      originatorInfo: new OriginatorInfo({
        certs: new CertificateSet({
          certificates: [certificate],
        }),
      }),
    })

    cmsEnvelopedData.addRecipientByCertificate(
      certificate,
      { oaepHashAlgorithm: 'sha-256' },
      1,
    )
    cmsEnvelopedData.encrypt(algorithm, msgBuffer).then((buffer) => {
      const cmsContent = new ContentInfo()
      cmsContent.contentType = '1.2.840.113549.1.7.3'
      cmsContent.content = cmsEnvelopedData.toSchema()
      const encryptedBuffer = cmsContent.toSchema().toBER(false)
      if (encryptedBuffer.byteLength > 0) {
        observer.next(encryptedBuffer)
        observer.complete()
      } else {
        observer.error('Empty buffer')
      }
    })
  })
}
