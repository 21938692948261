import { I18NString } from "./I18N.entity";

export enum WeiterbildungsOrdnung {
  UNDEFINED = "undefined",
  WBO_NONE = "WBO-NONE",
  WBO_1992 = "WBO-1992",
  WBO_2003 = "WBO-2003",
  WBO_2018 = "WBO-2018",
}

export enum ArztAusbildungsType {
  UNDEFINED = "undefined",
  FACHARZT = "FacharztAusbildung",
  FACHARZT_SCHWERPUNKT = "FacharztSchwerpunkt",
  ZUSATZ_WEITERBILDUNG = "ZusatzWeiterbildung",
  ZUSATZ_BEZEICHNUNG = "ZusatzBezeichnung"
}

export interface ArztAusbildung {
  /**
   * type of ausbildung
   */
  readonly ausbildungsType: ArztAusbildungsType,

  /**
   * id for this ausbildung
   */
  readonly id: string,

  /**
   * name for this ausbildung
   */
  readonly name: I18NString,

  /**
   * verordnung regulating this ausbildung
   */
  readonly verordnung: WeiterbildungsOrdnung,

  /**
   * ids of medical fields this ausbildung belongs to
   */
  readonly fields: string[],
}

export interface FacharztAusbildung extends ArztAusbildung {
  readonly ausbildungsType: ArztAusbildungsType.FACHARZT,
}

export interface FacharztSchwerpunkt extends ArztAusbildung {
  readonly ausbildungsType: ArztAusbildungsType.FACHARZT_SCHWERPUNKT,

  /**
   * id of required FacharztAusbildung for this Schwerpunkt
   */
  readonly requiredFacharzt?: string,
}

export interface ZusatzWeiterbildung extends ArztAusbildung {
  readonly ausbildungsType: ArztAusbildungsType.ZUSATZ_WEITERBILDUNG,
}

/** interface for modeling a medical field */
export interface MedicalField {
  /**
   * id for this medical field
   */
  readonly id: string,

  /**
   * name of this medical field
   */
  readonly name: I18NString,

  /**
   * tags to search and find this medical field by
   */
  readonly tags: string[],
}

export interface ZusatzBezeichnung {

  readonly ausbildungsType: ArztAusbildungsType.ZUSATZ_BEZEICHNUNG

  readonly id: string,

  readonly name: I18NString
}
