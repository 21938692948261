import { ActiveInstance } from '@a-d/entities/ActiveInstance.entity';
import { InstanceForm } from '@a-d/entities/InstanceForm.entity';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { startWith, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { FormHelpers } from '../../../forms/form-helpers.service';
import { InstanceService } from '../../../instance/instance.service';
import { UsefulComponent } from '../../../misc/useful.component';
import { InstanceFormInterface } from '../../instance-form-component.interface';
import { InstanceFormService } from '../../instance-form.service';
import { AnamneseForm } from '../anamnese-forms.service';
import { TranslateModule } from '@ngx-translate/core';
import { TextFieldModule } from '@angular/cdk/text-field';
import { SelectionFieldComponent } from '../../../forms/fields/selection-field.component';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';


export interface HautproblemAnamneseFormSettings {
  readonly preSelectIsLesion: 'Leberfleck' | 'Anderes Hautproblem'
  readonly hautproblemOptions: string[]
}


@Component({
    selector: 'app-hautproblem-anamnese-form',
    templateUrl: './hautproblem-anamnese-form.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatInputModule,
        SelectionFieldComponent,
        TextFieldModule,
        TranslateModule,
    ],
})
export class HautproblemAnamneseFormComponent extends UsefulComponent implements OnInit, InstanceFormInterface {

  @Input() anamneseForm: AnamneseForm
  @Input() settings: HautproblemAnamneseFormSettings


  /**
   * Form-Group
   */
  public formGroup = new UntypedFormGroup({
    hautproblemIsLesion: new UntypedFormControl('', []),
    hautproblemLesionDiameter: new UntypedFormControl('', [Validators.min(1), Validators.max(100)]),
    hautproblemLesionMutations: new UntypedFormControl(''),
    hautproblemSelection: new UntypedFormControl(''),
    hautproblemDescription: new UntypedFormControl(''),
    hautproblemLocalization: new UntypedFormControl('', []),
    hautproblemHistory: new UntypedFormControl('', []),
    hautproblemPreTreated: new UntypedFormControl(''),
    hautproblemPreTreatmentDescription: new UntypedFormControl('', []),
  })

  public get hautproblemIsLesion(): AbstractControl { return this.formGroup.get('hautproblemIsLesion') }
  public get hautproblemLesionDiameter(): AbstractControl { return this.formGroup.get('hautproblemLesionDiameter') }
  public get hautproblemLesionMutations(): AbstractControl { return this.formGroup.get('hautproblemLesionMutations') }
  public get hautproblemSelection(): AbstractControl { return this.formGroup.get('hautproblemSelection') }
  public get hautproblemDescription(): AbstractControl { return this.formGroup.get('hautproblemDescription') }
  public get hautproblemLocalization(): AbstractControl { return this.formGroup.get('hautproblemLocalization') }
  public get hautproblemHistory(): AbstractControl { return this.formGroup.get('hautproblemHistory') }
  public get hautproblemPreTreated(): AbstractControl { return this.formGroup.get('hautproblemPreTreated') }
  public get hautproblemPreTreatmentDescription(): AbstractControl { return this.formGroup.get('hautproblemPreTreatmentDescription') }


  public readonly hautproblemIsLesionValue = "Leberfleck"
  public readonly hautproblemOtherHautproblemValue = "Anderes Hautproblem"
  public get isLesion(): boolean { return this.hautproblemIsLesion.value === this.hautproblemIsLesionValue }
  public get isOtherHautproblem(): boolean { return this.hautproblemIsLesion.value === this.hautproblemOtherHautproblemValue }

  public get lesionIsPreselected(): boolean {
    return [this.hautproblemIsLesionValue, this.hautproblemOtherHautproblemValue].includes(this.settings.preSelectIsLesion)
  }


  /**
    * Current Instance & Instance-Form Helpers
   */
  public get activeInstance(): ActiveInstance { return this.instanceService.activeInstance }
  public get activeInstanceForm(): InstanceForm { return this.instanceFormService.activeInstanceForm }
  public readonly isDemoMode = environment.demoMode


  constructor(
    private instanceService: InstanceService,
    private instanceFormService: InstanceFormService,
    private formHelpers: FormHelpers,
    private cd: ChangeDetectorRef,
  ) {
    super()
    this.formHelpers.syncActiveAnamneseFormWithLocalStorage(this.activeInstanceForm, this.formGroup, this.unsubscribe$)
  }

  ngOnInit() {
    super.ngOnInit()

    // Pre-Select `hautproblemIsLesion`
    if (this.lesionIsPreselected) {
      this.hautproblemIsLesion.setValue(this.settings.preSelectIsLesion)
    }

    // Update validation of `hautproblemSelection`
    this.hautproblemIsLesion.valueChanges.pipe(
      startWith(0),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.hautproblemSelection.setValidators(this.isLesion ? [] : [Validators.required])
      this.hautproblemSelection.updateValueAndValidity()
    })
  }



  // ---------------------//
  // InstanceFormInterface //
  // ---------------------//


  public getData(): any {
    const form = this.formHelpers.trimmedRawValue(this.formGroup)

    // Delete appropriate fields
    if (this.isLesion) {
      delete form.hautproblemSelection
      delete form.hautproblemDescription
    } else {
      delete form.hautproblemLesionDiameter
      delete form.hautproblemLesionMutations
    }

    return form
  }

  public magicFill() {
    if (!this.isDemoMode) return

    if (!this.lesionIsPreselected) this.hautproblemIsLesion.setValue(this.hautproblemOtherHautproblemValue)
    const randomSelection = this.settings.hautproblemOptions[Math.floor(Math.random() * this.settings.hautproblemOptions.length)]
    this.hautproblemSelection.setValue([randomSelection])
    this.hautproblemLocalization.setValue('Am linken unteren Oberschenkel')
    this.hautproblemPreTreated.setValue(false)
    this.hautproblemHistory.setValue('Seit weniger als 2 Wochen')

    this.cd.detectChanges()
  }

}
