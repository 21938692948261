import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import slugify from '@sindresorhus/slugify';
import { environment } from '../../../../environments/environment';
import { ActiveInstance } from '../../../entities/ActiveInstance.entity';
import { InstanceForm } from '../../../entities/InstanceForm.entity';
import { FormHelpers } from '../../../forms/form-helpers.service';
import { InstanceService } from '../../../instance/instance.service';
import { UsefulComponent } from '../../../misc/useful.component';
import { InstanceFormInterface } from '../../instance-form-component.interface';
import { InstanceFormService } from '../../instance-form.service';
import { AnamneseForm } from '../anamnese-forms.service';
import { TranslateModule } from '@ngx-translate/core';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InfoBannerComponent } from '../../../wrapper/info-banner.component';
import { NgIf, NgFor } from '@angular/common';


export interface CustomAnamneseFormField {
  readonly title: string
  readonly type: 'textarea'
  readonly isRequired: boolean
  readonly options: object
  // Added on the fly
  name: string
  control?: UntypedFormControl
}

export interface CustomAnamneseFormSettings {
  readonly customFields: CustomAnamneseFormField[]
}

@Component({
    selector: 'app-custom-anamnese-form',
    templateUrl: './custom-anamnese-form.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        InfoBannerComponent,
        NgFor,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        TranslateModule,
    ],
})
export class CustomAnamneseFormComponent extends UsefulComponent implements OnInit, InstanceFormInterface {

  @Input() anamneseForm: AnamneseForm
  @Input() settings: CustomAnamneseFormSettings


  /**
   * Form-Group
   */
  public formGroup = new UntypedFormGroup({})
  public formFields: CustomAnamneseFormField[] = []

  public get requiredFieldExists(): boolean { return this.formFields.some((field) => field.isRequired) }


  /**
    * Current Instance & Instance-Form Helpers
   */
  public get activeInstance(): ActiveInstance { return this.instanceService.activeInstance }
  public get activeInstanceForm(): InstanceForm { return this.instanceFormService.activeInstanceForm }
  public readonly isDemoMode = environment.demoMode


  constructor(
    private formHelpers: FormHelpers,
    private instanceService: InstanceService,
    private instanceFormService: InstanceFormService,
    private cd: ChangeDetectorRef,
  ) {
    super()
    const suffix = this.activeInstanceForm && this.activeInstanceForm.identifier
    this.formHelpers.syncActiveAnamneseFormWithLocalStorage(this.activeInstanceForm, this.formGroup, this.unsubscribe$, suffix)
  }

  public onInit() {
    this.initFormGroup()
  }


  /**
   * Create FormControls from given `customFields`
   */
  private initFormGroup() {
    if (!this.settings || !Array.isArray(this.settings.customFields)) {
      return
    }

    this.formFields = <CustomAnamneseFormField[]>JSON.parse(JSON.stringify(this.settings.customFields)).filter((field) => !!field && !!field.title)
    this.formFields.forEach((field, index) => {
      const validators = field.isRequired ? [Validators.required] : []
      field.name = `field-${index}-${slugify(field.title)}`
      field.control = new UntypedFormControl(null, validators)

      this.formGroup.addControl(field.name, field.control)

      // Add to `fieldTitles` of custom-form within `AnamneseFormsService`
      if (this.anamneseForm) this.anamneseForm.fieldTitles[field.name] = field.title
    })
  }



  // ---------------------//
  // InstanceFormInterface //
  // ---------------------//

  public getData(): any {
    return this.formHelpers.trimmedRawValue(this.formGroup)
  }

  public magicFill() {
    if (!this.isDemoMode) return

    for (const control of Object.values(this.formGroup.controls)) {
      // if (control.errors && control.errors['required'])
      control.setValue('test value 123')
    }

    this.cd.detectChanges()
  }

}
