import { WfaForm } from '../../../interfaces-autogen-from-joi/wfa-form'
import coronaVirus from './corona-virus.json'
import generalHealthIssue from './general-health-issue.json'
import postSurgeryControl from './post-surgery-control.json'
import skinIssues from './skin-issues.json'
import varices from './varices.json'
import videoConsultationStandby from './video-consultation-standby.json'

export const anamnese = {
  coronaVirus: coronaVirus as WfaForm,
  generalHealthIssue: generalHealthIssue as WfaForm,
  postSurgeryControl: postSurgeryControl as WfaForm,
  skinIssues: skinIssues as WfaForm,
  varices: varices as WfaForm,
  videoConsultationStandby: videoConsultationStandby as WfaForm,
}
