import { Certificate } from 'pkijs';
import { Asset } from './PatientSession.entity';
import { PatientSessionDataUnencrypted } from './PatientSessionCreation.entity';

export enum WorkerTypes {
  SessionEncrypt = 'sessionEncrypt',
  SessionDecrypt = 'sessionDecrypt',
  DataDecrypt = 'dataDecrypt',
  AssetEncrypt = 'assetEncrypt',
  AssetWithCustomUmlautEncoding = 'assetWithCustomUmlautEncoding',
}

export interface WorkerEncryptionData {
  readonly type: WorkerTypes
  readonly cert: string
  readonly data: PatientSessionDataUnencrypted
  readonly assetData?: Asset
  readonly encryptedAssets?: string
}

export interface WorkerDecryptionData {
  readonly type: WorkerTypes,
  readonly privateKeyBuffer: ArrayBuffer
  readonly certificate: Certificate
  readonly encrypted: string
  readonly decode: boolean
}
