import { OTKFieldConfig } from "@a-d/entities/Calendar.entity";
import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UntilDestroy } from "@ngneat/until-destroy";
import { I18NStringPipe } from "../../../i18n/i18n.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule, _MatCheckboxRequiredValidatorModule } from "@angular/material/checkbox";
import { NgIf } from "@angular/common";

@UntilDestroy()
@Component({
    selector: "app-checkbox",
    templateUrl: "./checkbox.component.html",
    styleUrls: ["./checkbox.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        MatCheckboxModule,
        _MatCheckboxRequiredValidatorModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        TranslateModule,
        I18NStringPipe,
    ],
})
export class CheckboxComponent implements OnInit {
  @Input() control: AbstractControl | undefined;
  @Input() config: OTKFieldConfig | undefined;

  constructor() { }

  ngOnInit(): void { }
}
