import { CoreLocale } from '@arzt-direkt/wfa-definitions'
import { nullish } from '@arzt-direkt/wfa-generic-utils'

interface ReplacementPatterns {
  searchStrings: string[]
  replaceStrings: string[]
}

const replacementDict: Record<CoreLocale, ReplacementPatterns> = {
  de: {
    searchStrings: ['Umfragen', 'die Umfrage', 'der Umfrage', 'Umfrage'],
    replaceStrings: ['Formulare', 'das Formular', 'des Formulars', 'Formular'],
  },
  en: {
    searchStrings: ['survey'],
    replaceStrings: ['form'],
  },
  es: {
    searchStrings: ['encuesta'],
    replaceStrings: ['forma'],
  },
  fr: {
    searchStrings: ['sondage'],
    replaceStrings: ['formulaire'],
  },
  it: {
    searchStrings: ['sondaggio'],
    replaceStrings: ['formulario'],
  },
}

/**
 * SurveyJS uses the term 'survey' (dt. 'Umfrage') throughout the complete project. Speaking of a 'survey' is not suitable for our use case.
 * Our goal is to provide a tool to our customers for creating 'forms' (dt. 'Formulare').
 * In order to reflect this in the user interface, a renaming must take place.
 * For example, replacing 'survey' with 'form' in menu options and labels provides users with a more intuitive experience when navigating and interacting with the platform.
 * By making this change, we enhance usability and ensure that the language used throughout the platform aligns with our users' expectations and intentions.
 * @param obj is being mutated
 */
export function renameTerms(
  obj: Record<string, unknown>,
  locale: CoreLocale,
): void {
  if (nullish(obj) || typeof obj !== 'object' || Array.isArray(obj)) return

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'string')
      replaceAllSearchStrings(obj, key, value, locale)
    else renameTerms(value as Record<string, unknown>, locale)
  })
}

function replaceAllSearchStrings(
  obj: Record<string, unknown>,
  key: string,
  value: string,
  locale: CoreLocale,
): void {
  const replacements = replacementDict[locale]
  if (nullish(replacements)) return

  const { searchStrings, replaceStrings } = replacements
  searchStrings.forEach((searchString, index) => {
    const regex = new RegExp(searchString, 'gi')
    value = value.replace(regex, (match) => {
      const str = /^[A-Z]/.test(match)
        ? replaceStrings[index].charAt(0).toUpperCase() +
          replaceStrings[index].slice(1)
        : replaceStrings[index]
      return str
    })
  })

  obj[key] = value
}
