import { SurveyCreatorModel } from 'survey-creator-core'

import { applyHtml } from './apply-html'
import { registerHtmlEditorComponent } from './register-html-editor-component'
import { registerHtmlEditorModel } from './register-html-editor-model'

export function addHtmlEditorToPropertyGrid(creator: SurveyCreatorModel): void {
  registerHtmlEditorModel()
  registerHtmlEditorComponent()
  creator.survey.onTextMarkdown.add(applyHtml)
}
