import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import { nullish } from '@arzt-direkt/wfa-generic-utils'
import dayjs from 'dayjs'

export function formatDate(
  date: Maybe<string | Date>,
  format: '.' | '-',
): Maybe<string> {
  if (nullish(date)) return undefined
  if (format === '-') return dayjs(date).format('DD-MM-YYYY')
  return dayjs(date).format('DD.MM.YYYY')
}
