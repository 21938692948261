import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class OverflowHelpersService {

  constructor() { }

  /**
   * Returns true if the given element overflows.
   */
  public elementIsOverflowing(el): boolean {
    // var curOverf = el.style.overflow;

    // if ( !curOverf || curOverf === "visible" )
    //     el.style.overflow = "hidden";
    console.log("elementIsOverflowing el.scrollWidth ", el.scrollWidth, " el.offsetWidth ", el.offsetWidth, " el.clientWidth ", el.clientWidth)
    console.log("elementIsOverflowing el.scrollHeight ", el.scrollHeight, " el.offsetHeight ", el.offsetHeight, " el.clientHeight ", el.clientHeight)

    const paEl = el.parentElement

    console.log("elementIsOverflowing paEl.scrollWidth ", paEl.scrollWidth, " paEl.offsetWidth ", paEl.offsetWidth, " paEl.clientWidth ", paEl.clientWidth)
    console.log("elementIsOverflowing paEl.scrollHeight ", paEl.scrollHeight, " paEl.offsetHeight ", paEl.offsetHeight, " paEl.clientHeight ", paEl.clientHeight)


    return paEl.scrollHeight > el.scrollHeight

    // el.style.overflow = curOverf;

  }

  /**
   * Returns true if the given element isn't scrolled to its end.
   */
  public elementIsScrolledToEnd(el): boolean {
    return el.scrollHeight - el.scrollTop === el.clientHeight
  }

}
