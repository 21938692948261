import { goSettings } from './go-settings';
import { GoComponent } from './go.component';

export const goForm = {
  name: 'ZRM Gesundheitsfragebogen Online (GO)',
  identifier: 'zrm-go',
  component: GoComponent,
  defaultMedicalSpecializationId: '1',
  defaultSettings: goSettings,
  fieldTitles: {
    sideEffects: 'Nebenwirkungen',
    customSideEffect: 'Sonstige Nebenwirkung',
    allergies: 'Allergien',
    allergySelect: 'Allergieauswahl',
    customAllergy: 'Sonstige Allergie',
    allergyDetails: 'Anfallbeschreibung',
    chronicDiseases: 'Chronische Erkrankungen',
    customChronicDisease: 'Sonstige Erkrankung',
    medications: 'Medikamente',
    customMedication: 'Sonstige Medikamente',
    goOther: 'Sonstiges',
  },
};
