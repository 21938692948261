import { CookiesService, SupportCookieNames } from '@a-d/dsgvo/cookies.service';
import { DsgvoService } from '@a-d/dsgvo/dsgvo.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';
import { DialogComponent } from '../../../../../lib/src/lib/dialog/dialog.component';

export interface BrowserSupportDialogData {
  appRequirementsFormattedHtml: string
  readonly anyExceptionIsSatisfied: boolean
  readonly currentBrowser: string
  readonly currentOS: string
  readonly currentPlattformType: string
  readonly isMobileOS: boolean
  readonly isIos: boolean
  readonly isIosSafari: boolean
}

@Component({
    selector: 'attest-browser-support-dialog',
    templateUrl: './browser-support-dialog.component.html',
    standalone: true,
    imports: [
        DialogComponent,
        MatDialogModule,
        NgIf,
        MatCheckboxModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
    ],
})
export class BrowserSupportDialogComponent implements OnInit {

  public isLoading = false
  public notShowAgain = false

  constructor(
    public dialogRef: MatDialogRef<BrowserSupportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BrowserSupportDialogData,
    private sanitizer: DomSanitizer,
    private dsgvoService: DsgvoService,
    private cookiesService: CookiesService
  ) {
    // Parse HTML
    this.data.appRequirementsFormattedHtml = <string>this.sanitizer.bypassSecurityTrustHtml(this.data.appRequirementsFormattedHtml)
  }

  ngOnInit() { }


  /**
   * Opens a page where the user can update/download a new browser.
   */
  public openBrowserUpdatePage() {
    window.open('http://outdatedbrowser.com/de', '_blank')
  }


  /**
   * Opens a page where the user can update/download a new OS.
   */
  public openOSUpdatePage() {
    const osEncoded = encodeURI(this.data.currentOS)
    const url = `https://duckduckgo.com/?q=${osEncoded}+aktualisieren`
    window.open(url, '_blank')
  }


  /**
   * Stores a flag to not show browser-support dialog again.
   */
  public saveNotShowAgain() {
    this.cookiesService.set(SupportCookieNames.hideBrowserSupportDialog, String(this.notShowAgain), { expires: 30 })
  }
}
