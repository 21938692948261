import { SurveyjsFileInputFormat } from '@arzt-direkt/wfa-definitions'

import { replaceUmlautsInString } from '../../../utility/replace-umlauts'
import { isFileImage } from './image-upload/is-file-image'

export function formatFile(
  name: string,
  type: string,
  base64String: string,
): SurveyjsFileInputFormat {
  const formattedName = formatFileName(name, type)
  return {
    file: {
      name: formattedName,
      type,
    },
    content: base64String,
  }
}

function formatFileName(name: string, type: string): string {
  const nameWithoutUmlauts = replaceUmlautsInString(name)
  const nameWithFormattedExtension = formatFileNameOnExtension(
    nameWithoutUmlauts,
    type,
  )
  return nameWithFormattedExtension
}

function formatFileNameOnExtension(name: string, type: string): string {
  if (isFileImage(type) === false) return name
  const baseName = name.replace(/.[^/.]+$/, '')
  const extension = type.split('/')[1]
  return `${baseName}.${extension}`
}
