import { formatCurrency } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { InsuranceType } from '@lib/entities/Insurance.entity';
import { TranslateService } from '@ngx-translate/core';
import { InstanceForm, PrivatePaymentHandler, PrivatePaymentItemType } from '../../entities/InstanceForm.entity';
import { NumberHelpersService } from '../../misc/number-helpers.service';


@Injectable({
  providedIn: 'root'
})
export class InstanceFormPaymentService {

  constructor(
    private numberHelpers: NumberHelpersService,
    @Inject(LOCALE_ID) private locale: string,
    private translate: TranslateService,
  ) { }


  /**
   * Returns if the given `insuranceType` must perform a payment on the given `instanceForm`
   */
  public paymentIsRequired(instanceForm: InstanceForm, insuranceType: InsuranceType) {
    return !!instanceForm && !!insuranceType
      && [InsuranceType.PKV, InsuranceType.SZ].includes(insuranceType)
      && [PrivatePaymentHandler.ArztDirekt, PrivatePaymentHandler.External].includes(instanceForm?.privatePayment?.handler)
      && instanceForm?.insuranceTypes?.includes(insuranceType)
      && (!!instanceForm?.privatePayment?.isRequired)
      && !!instanceForm?.privatePayment?.items?.filter((item) => item.isApplicable)?.length
      && !!instanceForm?.privatePayment?.price
  }


  /**
   * Calculate overall price depending on applicable items and sets it on the given `instanceForm`.
   * See "Dealing with float precision in JavaScript": https://stackoverflow.com/questions/11695618/dealing-with-float-precision-in-javascript
   */
  public calculateOverallPrice(instanceForm: InstanceForm, age?: number) {
    const privatePaymentItems = instanceForm.privatePayment.items || []
    instanceForm.privatePayment.price = privatePaymentItems.reduce((sum, item) => {
      // Determine if certain Zuschläge are applicable
      if (item.type === PrivatePaymentItemType.Zuschlag_K1) {
        item.isApplicable = age >= 0 && age < 4;
      }

      sum += item.isApplicable ? this.numberHelpers.toFixed(item.price, 2) : 0.00
      return this.numberHelpers.toFixed(sum, 2)
    }, 0.00)
  }


  /**
   * Formatted Price for given `instanceForm` and `insuranceType`
   */
  public formPriceFormatted(instanceForm: InstanceForm, insuranceType: InsuranceType, overwriteWithCustomLabel: boolean = false): string {
    const paymentIsRequired = this.paymentIsRequired(instanceForm, insuranceType)

    let formattedPrice: string
    if ([InsuranceType.GKV, InsuranceType.BES].includes(insuranceType) || !paymentIsRequired) {
      formattedPrice = this.translate.instant('VARIABLES-DEFAULT.FORM.WITHOUT-ADDITIONAL-CHARGES')
    } else {
      formattedPrice = formatCurrency(instanceForm.privatePayment.price, this.locale, '€')
    }

    return overwriteWithCustomLabel
      ? instanceForm?.customPaymentLabels?.[insuranceType] || formattedPrice
      : formattedPrice
  }


  /**
   * Formatted Title for each PrivatePaymentItemType
   */
  public paymentItemTypeFormatted(type: PrivatePaymentItemType): string {
    switch (type) {
      case PrivatePaymentItemType.Videosprechstunde: return 'Sprechstundengebühren'
      case PrivatePaymentItemType.Technikzuschlag: return 'Technikzuschlag'
      case PrivatePaymentItemType.Allgemein_1: return 'Beratung (GOÄ Nr. 1)'
      case PrivatePaymentItemType.Allgemein_3: return 'Eingehende Beratung (GOÄ Nr. 3)'
      case PrivatePaymentItemType.Allgemein_5: return 'Untersuchung (GOÄ Nr. 5)'
      case PrivatePaymentItemType.Zuschlag_B: return '6-8 Uhr oder 20-22 Uhr (GOÄ Zuschlag B)'
      case PrivatePaymentItemType.Zuschlag_C: return '22-6 Uhr (GOÄ Zuschlag C)'
      case PrivatePaymentItemType.Zuschlag_D: return 'Sams-, Sonn- oder Feiertag (GOÄ Zuschlag D)'
      case PrivatePaymentItemType.Zuschlag_K1: return 'Kind unter 4 Jahren (GOÄ Zuschlag K1)'
      default: return type
    }
  }

}
