@if (zsSupportService.praxisDetails$.value !== undefined) {
  <mat-card *rxLet="zsSupportService.draftInfos$; let draftInfos">
    <mat-card-title data-selenium-id="text-@form-draft-table/mat-card-title">{{
      'WFA.FORM-DRAFT-TABLE.TITLE' | translate
    }}</mat-card-title>
    <mat-card-content>
      <table
        mat-table
        [dataSource]="draftInfos"
        [fixedLayout]="true"
        class="mat-elevation-z8"
        data-selenium-id="table-@form-draft-table/table"
      >
        <!-- Identifier Column -->
        <ng-container matColumnDef="identifier" [style.flex]="'0 0 80%'">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'WFA.FORM-DRAFT-TABLE.COLUMNS.IDENTIFIER' | translate }}
          </th>
          <td mat-cell *matCellDef="let wfi">
            {{ wfi.wfaFormId }}
            <br />
            <span class="text-gray-500 text-sm">{{ wfi.title | i18n }}</span>
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions" [style.flex]="'0 0 20%'">
          <th mat-header-cell *matHeaderCellDef class="text-right"></th>
          <td mat-cell *matCellDef="let wfi" class="text-right">
            <app-action-buttons
              (onCopy)="
                copyDraft(wfi.wfaFormVersionId, wfi.wfaFormId, wfi.praxisId)
              "
              (onDelete)="deleteDraft(wfi.wfaFormVersionId)"
              (onEdit)="openEditor(wfi.wfaFormVersionId)"
              (onPublish)="publishDraft(wfi.wfaFormVersionId)"
              [showPublish]="true"
              [showPermalink]="false"
            ></app-action-buttons>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
          class="font-bold border-b"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </mat-card-content>
  </mat-card>
}
