import { CookiesService } from '@a-d/dsgvo/cookies.service';
import { InstanceStatusMessage } from '@a-d/entities/Instance.entity';
import { SessionMessageEncrypted } from '@a-d/entities/PatientSessionMessage.entity';
import { StressTestFinishedMessage } from '@a-d/entities/StressTest.entity';
import { AdLoggerService } from '@a-d/logging/ad-logger.service';
import { Injectable, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, interval, merge, Observable, of, Subject } from 'rxjs';
import { filter, first, skip, takeUntil } from 'rxjs/operators';
import { PatientSession, PatientSessionConnectionStatistics, PatientSessionStateElement } from '../entities/PatientSession.entity';
import { PatientSessionDataEncrypted } from '../entities/PatientSessionCreation.entity';
import { PatientSessionShallow } from '../entities/PatientSessionShallow.entity';
import { SocketArztPortalStatus, SocketArztStatus, SocketUserRole, SocketUserStatus } from '../entities/Socket.entity';
import { WaitRoomStatisticsForDoctors, WaitRoomStatisticsForPatients } from '../entities/Waitroom.entity';
import { WebRTCData, WebRTCHangup, WebRTCICEData, WebRTCRequest, WebRTCRequestRetry, WebRTCResponse } from '../entities/WebRTCSocketMessage.entity';
import { InstanceSocket } from '../instance/instance-socket.service';


@Injectable({
  providedIn: 'root'
})
export class SocketService implements OnDestroy {

  private unsubscribe$ = new Subject()
  userStatus$ = new BehaviorSubject<SocketUserStatus>(SocketUserStatus.NotJoined)
  isJoining$ = new BehaviorSubject<boolean>(false);
  private hasLeft$ = new Subject<void>();
  private currentRole: SocketUserRole | undefined;
  private currentData: any;

  // SocketUserArzt-Properties
  private activeSessionId$ = new BehaviorSubject<string | null>(null);

  // HACK: As changing state to 'documenting' is often not recognized this adds
  //       an manual way of notifying myself of a state-change.
  public sessionStateUpdate$ = new Subject<PatientSessionStateElement>()

  isConnected: boolean = false;

  private hasConnected$ = new Subject<void>();

  constructor(
    private adLoggerService: AdLoggerService,
    private logger: NGXLogger,
    private socket: InstanceSocket,
    private cookiesService: CookiesService
  ) {

    this.getUserStatusUpdates().subscribe((newStatus: SocketUserStatus) => {
      console.log('NEW USER STATUS', newStatus)
      if (newStatus !== this.userStatus$.value) this.userStatus$.next(newStatus);
      if (newStatus === SocketUserStatus.Joined && this.isJoining$.value) this.isJoining$.next(false)
    });

    this.socket.fromEvent('disconnect').pipe(takeUntil(this.unsubscribe$)).subscribe((reason) => {
      console.log(`Disconnected from Socket (${reason})`)
      this.isConnected = false;
      // Automatic Re-Connect
      // https://github.com/socketio/socket.io/issues/2476#issuecomment-316926924
      if (reason !== 'io client disconnect') {
        console.log("Automatically reconnecting after server disconnect")
        if (!this.isJoining$.value && this.currentRole && this.currentData) {
          this.isJoining$.next(true)
        }
        interval(1000 * 10).pipe(takeUntil(this.unsubscribe$), takeUntil(this.hasConnected$)).subscribe(() => {
          this.socket.connect()
        })
      }
    })

    this.socket.fromEvent('connect').pipe(takeUntil(this.unsubscribe$)).subscribe((_) => {
      this.isConnected = true;
      this.hasConnected$.next()
      if (this.isJoining$.value) {
        this.socket.emit(this.getJoinRouteFromRole(this.currentRole), this.currentData);
      }
    })
  }

  reconnect() {
    this.socket.reconnect()
  }

  initAndConnect(identifier: string) {
    if (!this.isConnected) {
      this.socket.initSocketUrlAndNamespace(identifier);
      this.socket.connect()
    }
  }

  // -------//
  // Events //
  // -------//

  private getUserStatusUpdates(): Observable<SocketUserStatus> {
    return this.socket
      .fromEvent<any>('userStatusUpdate').pipe(takeUntil(this.unsubscribe$));
  }

  public getArztStatusUpdates(): Observable<SocketArztStatus> {
    return this.socket
      .fromEvent<any>('arztStatusUpdate');
  }

  public getPatientPortalStatusUpdates(): Observable<SocketArztPortalStatus> {
    return this.socket
      .fromEvent<any>('arztFacharztPortalStatusUpdate');
  }

  public getSessionStateUpdate(): Observable<PatientSessionStateElement> {
    return merge(
      this.socket.fromEvent<any>('sessionStateUpdate'),
      this.sessionStateUpdate$.asObservable(),
    )
  }

  public getWaitRoomStatisticsForPatients(): Observable<WaitRoomStatisticsForPatients> {
    return this.socket
      .fromEvent<any>('waitRoomStatisticsForPatients');
  }

  public getWaitRoomStatisticsForDoctors(): Observable<WaitRoomStatisticsForDoctors> {
    return this.socket
      .fromEvent<any>('waitRoomStatisticsForDoctors');
  }

  public getPersonalDataRequest(): Observable<any> {
    return this.socket
      .fromEvent<any>('personalDataRequest');
  }

  public getPersonalDataResponse(): Observable<any> {
    return this.socket
      .fromEvent<any>('personalDataResponse');
  }

  public getCouncilTomedoDataArrival(): Observable<any> {
    return this.socket
      .fromEvent<any>('receivedCouncilTomedoPatientData')
  }

  public getStatusMessageUpdate(): Observable<InstanceStatusMessage> {
    return this.socket
      .fromEvent<any>('statusMessageUpdate')
  }

  public getWaitingPatientMessage(): Observable<any> {
    return this.socket
      .fromEvent<any>('waitingPatientMessage')
  }

  public getStressTestFinished(): Observable<StressTestFinishedMessage> {
    return this.socket.fromEvent<StressTestFinishedMessage>('stressTestFinished')
  }



  // ---------------//
  // Shared Actions //
  // ---------------//

  /**
   * Joins the socket with the given role.
   */
  public join(role: SocketUserRole, data: any) {
    this.logger.log("join: role = ", role, " data = ", data)
    if (this.userStatus$.value === SocketUserStatus.Joined) {
      console.warn("called join (socket.service.ts) while already joined a socket! Let's stop doing sth in this case!");
    }
    if (this.isJoining$.value) this.adLoggerService.error("called join (socket.service.ts) while already joining a socket! Let's stop doing sth in this case!");
    if (this.userStatus$.value !== SocketUserStatus.Joined && !this.isJoining$.value) {
      this.currentRole = role;
      this.currentData = role === SocketUserRole.Arzt ? { _id: data, token: this.cookiesService.temporaryAuthCookie } : data;
      this.isJoining$.next(true);
      if (this.isConnected) this.socket.emit(this.getJoinRouteFromRole(this.currentRole), this.currentData);
      // if not connected, continue in "this.socket.fromEvent('connect')" in constructor
    }


  }

  disjoin() {
    if (this.isJoining$.value) this.isJoining$.next(false);
    this.hasLeft$.next()
    if (this.userStatus$.value === SocketUserStatus.Joined) this.userStatus$.next(SocketUserStatus.NotJoined);
    this.socket.emit('disjoin')
  }

  private getJoinRouteFromRole(role: SocketUserRole) {
    switch (role) {
      case SocketUserRole.Arzt: return 'joinAsArzt';
      case SocketUserRole.Patient: return 'joinAsPatient';
      case SocketUserRole.Tool: return 'joinAsTool'
      case SocketUserRole.Spezialist: return 'joinAsSpecialist';
      case SocketUserRole.Admin: return 'joinAsAdmin';
      default:
        break;
    }
  }

  /**
   * this is done before new session in case that isJoining$.value is still true from last session
   */

  public resetIsJoining() {
    if (this.isJoining$.value) this.isJoining$.next(false)
    return of(true)
  }


  /**
   * Manually leaving the socket.
   */
  public leave() {
    this.logger.log(`Left Socket manually`)
    this.hasLeft$.next();
    if (this.isJoining$.value) this.isJoining$.next(false);
    if (this.userStatus$.value === SocketUserStatus.Joined) this.userStatus$.next(SocketUserStatus.NotJoined)
    this.socket.disconnect();
  }


  /**
   * Emits socket-message and ensure socket is joined (if not waits until joined)
   */
  public emitMessage(eventName: string, ...args: any[]) {
    this.userStatus$.pipe(
      filter((status) => status === SocketUserStatus.Joined),
      first(),
      takeUntil(this.hasLeft$),
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.socket.emit(eventName, ...args)
    })
  }


  /**
   * Sends the updated PatientSessionState for the given session.
   */
  public sendSessionStateUpdate(session: PatientSession | PatientSessionShallow, stateElement: PatientSessionStateElement) {
    this.logger.log("sendSessionStateUpdate: stateElement = ", stateElement)
    this.logger.log("sendSessionStateUpdate: session.state = ", session.state)
    this.emitMessage('sessionStateUpdate', {
      sessionId: session._id,
      stateElement: stateElement
    })
  }

  // I (Michael) find the logging in the above function confusing, esp. when I have already set the new state and stateElement to the session
  // actually, only the sessionId is needed to call this function, hence I replace it in all refactored code with the one below

  public sendSessionStateUpdateRefactored(sessionId: string, stateElement: PatientSessionStateElement) {
    this.emitMessage('sessionStateUpdate', { sessionId: sessionId, stateElement: stateElement })
  }


  /**
   * Manually request WaitRoomStatistics for arzt- and patient-sockets.
   */
  public requestWaitRoomStatistics() {
    //this.logger.log(`requestWaitRoomStatistics`)
    this.emitMessage('requestWaitRoomStatistics')
  }

  /**
   * Updates ConnectionStatistics for the given session.
   */
  public sendSessionConnectionStatisticsUpdate(sessionId: string, statisticsUpdate: PatientSessionConnectionStatistics) {
    this.emitMessage('sessionConnectionStatisticsUpdate', { sessionId: sessionId, statisticsUpdate: statisticsUpdate })
  }


  // -------------//
  // Arzt Actions //
  // -------------//

  /**
   * Assigns a new SocketArztStatus to the arzt-socket.
   */
  public sendArztStatusUpdate(newArztStatus: SocketArztStatus) {
    this.logger.log(`Updating SocketArztStatus to '${newArztStatus}'..`)
    this.emitMessage('arztStatusUpdate', newArztStatus)
  }

  /**
   * Assigns a new SocketArztStatus to the arztFacharztPortalStatus on the arzt-socket.
   */
  public sendArztFacharztPortalStatusUpdate(newArztPortalStatus: SocketArztPortalStatus) {
    this.logger.log(`Updating SocketArztFacharztPortalStatus to '${newArztPortalStatus}'..`)
    this.emitMessage('arztFacharztPortalStatusUpdate', newArztPortalStatus)
  }


  /**
   * Assigns the activeSessionId to the arzt-socket.
   */
  public sendArztActiveSessionUpdate(newActiveSessionId: string) {
    if (this.activeSessionId$.value === newActiveSessionId) return
    this.activeSessionId$.next(newActiveSessionId);

    if (newActiveSessionId) {
      this.logger.log(`Assigning new 'activeSessionId' (${newActiveSessionId}) to arzt-socket..`)
      this.emitMessage('arztActiveSessionUpdate', newActiveSessionId)

      // Re-Assigning Session after Re-Connect
      this.getUserStatusUpdates().pipe(
        filter((status) => status === SocketUserStatus.Joined),
        takeUntil(this.activeSessionId$.pipe(skip(1))),
        takeUntil(this.unsubscribe$),
      ).subscribe((_) => {
        this.logger.log(`Re-Assigning 'activeSessionId' (${newActiveSessionId}) to arzt-socket..`)
        this.emitMessage('arztActiveSessionUpdate', newActiveSessionId)
      })

    } else {
      this.logger.log(`Removing 'activeSessionId' from arzt-socket..`)
      this.emitMessage('arztActiveSessionUpdate', null)
    }
  }


  /**
    * Arzt requests personal-data encryptedly from Patient
   */
  public requestPersonalData(sessionId: string) {
    this.logger.log(`Requesting Personal-Data from PatientSession '${sessionId}'..`)
    this.emitMessage('requestPersonalData', sessionId)
  }

  /**
   * Doctor sends updated status message
   */
  public sendStatusMessageUpdate(instanceId: string, statusMessage: InstanceStatusMessage) {
    this.emitMessage('statusMessageUpdate', { instanceId: instanceId, statusMessage: statusMessage })
  }

  /**
   * Doctor sends updated status message
   */
  public sendWaitingPatientMessage(sessionId: string, instanceId: string, messagePatientEncrypted: SessionMessageEncrypted, messageArztEncrypted: string) {
    this.logger.log('sendWaitingPatientMessage')
    this.emitMessage('sendWaitingPaitientMessage', { sessionId, instanceId, messagePatientEncrypted, messageArztEncrypted })
  }


  // ----------------//
  // Patient Actions //
  // ----------------//

  /**
   * Patient sends personal-data encryptedly to Arzt
   */
  public sendPersonalData(userId: string, dataEncrypted: PatientSessionDataEncrypted) {
    this.logger.log(`Sending Personal-Data to Arzt-User '${userId}'..`)
    this.emitMessage('sendPersonalData', { userId, dataEncrypted })
  }


  // ----------- //
  // Web-RTC     //
  // ----------- //

  public getRTCRequest(): Observable<WebRTCRequest> {
    return this.socket
      .fromEvent<WebRTCRequest>('rtcRequest');
  }

  public getRTCResponse(): Observable<WebRTCResponse> {
    return this.socket
      .fromEvent<WebRTCResponse>('rtcResponse')
  }

  public getICECandidates(): Observable<WebRTCICEData> {
    return this.socket
      .fromEvent<WebRTCICEData>('iceCandidate');
  }

  public getRTCMessage(): Observable<WebRTCData> {
    return this.socket
      .fromEvent<WebRTCData>('rtcMessage');
  }

  public getRTCHangup(): Observable<any> {
    return this.socket
      .fromEvent<any>('rtcHangup')
  }

  public sendRTCRequest(request: WebRTCRequest) {
    this.emitMessage('sendRTCRequest', request);
  }

  public retryRTCRequest(request: WebRTCRequestRetry) {
    this.emitMessage('sendRTCRequestRetry', request);
  }

  public sendRTCResponse(response: WebRTCResponse) {
    this.emitMessage('sendRTCResponse', response);
  }

  public sendICECandidate(data: WebRTCICEData) {
    this.emitMessage('sendICECandidate', data)
  }

  public sendRTCMessage(data: WebRTCData) {
    this.logger.log('SOCKET MESSAGE: ', data)
    this.emitMessage('sendRTCMessage', data);
  }

  public sentRTCHangup(data: WebRTCHangup) {
    this.emitMessage('sendRTCHangup', data);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.userStatus$.complete();
    this.isJoining$.complete();
    this.hasLeft$.complete();
    this.activeSessionId$.complete();
    this.socket.removeAllListeners();
    this.socket.disconnect();
    this.socket.off();
  }
}
