import { Arzt } from '@a-d/entities/Arzt.entity';
import { Injectable } from '@angular/core';
import { ArztType } from '@lib/entities/ArztType.entity';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { ActiveInstanceUser } from '../entities/ActiveInstance.entity';
import { User, UserStatus } from '../entities/User.entity';


@Injectable({
  providedIn: 'root'
})
export class UserFormattersService {

  constructor(
    private translate: TranslateService,
  ) { }


  public fullName(user: User | ActiveInstanceUser | { arzt: Arzt }) {
    // console.log("fullName: user = ", user)
    if (!user || !user.arzt) return ''
    if (!user.arzt.fname && !user.arzt.lname) return ''

    const nameTitle = user.arzt.nameTitle
    const nameAffix = user.arzt.nameAffix
    // console.log("fullName: nameTitle = ", nameTitle)
    // console.log("fullName: nameAffix = ", nameAffix)

    let fullName = nameAffix
      ? `${nameAffix} ${user.arzt.lname}`.trim()
      : user.arzt.lname
    // console.log("fullName: fullName = ", fullName)
    fullName = nameTitle
      ? `${nameTitle} ${user.arzt.fname} ${fullName}`.trim()
      : user.arzt.fname + " " + fullName
    // console.log("fullName: fullName = ", fullName)

    return fullName
  }


  public initials(user: User | ActiveInstanceUser) {
    if (!user || !user.arzt) return ''
    if (!user.arzt.fname && !user.arzt.lname) return ''

    const fLetter: string = user?.arzt?.fname?.substring(0, 1) ? user.arzt.fname.substring(0, 1) : ''
    const lLetter: string = user?.arzt?.lname?.substring(0, 1) ? user.arzt.lname.substring(0, 1) : ''
    return `${fLetter} ${lLetter}`.trim()
  }


  public birthDate(user: User) {
    if (!user || !user.arzt || !user.arzt.birthDate) return ''
    if (!dayjs(user.arzt.birthDate).isValid()) return ''

    const date = dayjs(user.arzt.birthDate)
    const dateString = date.format('DD.MM.YYYY')
    const age = dayjs().diff(date, 'year')

    return `${dateString} (${age} ${this.translate.instant('USER-DATA-ARZT.years')})`
  }


  public gender(user: User) {
    if (!user || !user.arzt || !user.arzt.gender) return ''
    if (user.arzt.gender.toUpperCase() === 'M') return this.translate.instant("USER-DATA-ARZT.male")
    if (user.arzt.gender.toUpperCase() === 'W') return this.translate.instant("USER-DATA-ARZT.female")
    if (user.arzt.gender.toUpperCase() === 'D') return this.translate.instant("USER-DATA-ARZT.diverse")
    return ''
  }


  public arztType(type: ArztType, small?: boolean) {
    switch (type) {
      case ArztType.Privatarzt: return this.translate.instant("ARZT-TYPE." + (small ? "PA" : "PRIVATARZT"))
      case ArztType.Kassenarzt: return this.translate.instant("ARZT-TYPE." + (small ? "KA" : "KASSENARZT"))
      default: return type
    }
  }


  public status(status: UserStatus) {
    switch (status) {
      case UserStatus.Registriert: return "Registriert"
      case UserStatus.Verifiziert: return "Verifiziert"
      case UserStatus.Freigeschaltet: return "Aktiviert"
      case UserStatus.Abgelehnt: return "Nicht aktiviert"
      case UserStatus.Wiederhergestellt: return "Wiederhergestellt"
      case UserStatus.Gesperrt: return "Gesperrt"
      default: return status
    }
  }


  public arztNumberLocalizedName(country: string): string {
    switch ((country || '').toLowerCase()) {
      case "deutschland": return "LANR"
      case "schweiz": return "ZSR-Nr."
      case "österreich": return "ÖÄK-Nr."
      default: return 'LANR'
    }

  }

}
