import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { ElementRef, Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { SocketUserRole } from './../entities/Socket.entity';
import { WebRTCConnectionType } from './../entities/WebRTC.entity';
import { StreamService } from './stream.service';

export interface VideoData {
  readonly element: HTMLVideoElement,
  readonly stream: MediaStream
}

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  public screenShareVideo: HTMLVideoElement;
  public screenShareStream: MediaStream;

  public localVideo: HTMLVideoElement;
  public localStream: MediaStream;

  public remoteVideo: any = { volume: 1 };
  public remoteStream: MediaStream;

  public toolVideo: any = { volume: 1 };
  public toolStream: MediaStream;

  public specialistVideo: any = { volume: 1 };
  public specialistStream: MediaStream;

  private SCREEN_SHARE_ID = 'screenShare';
  private STANDARD_CAMERA_ID = 'standardCamera';

  private readonly BASE_MEDIA_CONSTRAINTS: MediaStreamConstraints = {
    audio: true,
    video: {
      facingMode: 'user',
      frameRate: { min: 10, max: 30 },
      aspectRatio: { ideal: 16 / 9 },
      width: { min: 320, ideal: 1280 },
      height: { min: 240, ideal: 720 }
    }
  };

  public videoStarted$ = new Subject<string>()

  constructor(
    private adLoggerService: AdLoggerService,
    private streamService: StreamService,
  ) { }

  /*public muteRemote() {
    this.remoteVideo.muted = !this.remoteVideo.muted;
  }*/

  /*public shareScreen(videoElement: ElementRef): Observable<MediaStream> {
    return new Observable((observer) => {
      if (!videoElement) {
        observer.error('Missing video element');
        observer.complete();
        return;
      }
      this.streamService.startScreenshareStream()
        .then((stream) => {
          this.startVideoElement(this.screenShareVideo, stream, true)
          this.screenShareStream = stream;
          observer.next(stream);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          observer.complete();
        })
    });
  }*/

  /*public stopSharingScreen(videoElement: ElementRef): Observable<MediaStream> {
    if (this.screenShareVideo) this.screenShareVideo.pause()
    this.screenShareStream = undefined
    this.setStreamForVideo(undefined, this.screenShareVideo);
    return of(undefined)
  }*/

  public initializeLocalVideo(videoElement: ElementRef): Observable<MediaStream> {
    return new Observable((observer) => {
      if (!videoElement) {
        observer.error('initializeLocalVideo: Missing video element')
        observer.complete()
        return
      }
      this.streamService.startLocalStream()
        .then((stream) => {
          this.localVideo = videoElement.nativeElement
          this.localVideo.onloadedmetadata = () => {
            this.localVideo.controls = false
            this.localVideo.muted = true
            this.localVideo.play().then(() => {
              console.log('initializeLocalVideo: Video stream started')
              window.dispatchEvent(new Event('resize'))
              this.videoStarted$.next('local')
            })
          }
          try {
            this.localVideo.srcObject = stream;
          } catch (error) {
            this.adLoggerService.error('Media stream error', error)
            this.localVideo.src = URL.createObjectURL(stream as any)
          }
          observer.next(stream)
          observer.complete()
        })
        .catch((error) => {
          observer.error(error)
          observer.complete()
        });
    });
  }

  private setElementsByConnectionType(stream: MediaStream, video: HTMLVideoElement,
    role: SocketUserRole, type: WebRTCConnectionType): void {
    switch (type) {
      case WebRTCConnectionType.DocPatient:
        this.remoteVideo = video;
        this.remoteStream = stream;
        break;
      case WebRTCConnectionType.DocSpecialist:
        this.specialistVideo = video;
        this.specialistStream = stream;
        break;
      case WebRTCConnectionType.DocTools:
        if ((role === SocketUserRole.Arzt)) {
          this.toolVideo = video;
          this.toolStream = stream;
        } else {
          this.remoteVideo = video;
          this.remoteStream = stream;
        }
        break;
      case WebRTCConnectionType.PatientSpecialist:
        if (role === SocketUserRole.Patient) {
          this.specialistVideo = video;
          this.specialistStream = stream;
        } else {
          this.remoteVideo = video;
          this.remoteStream = stream;
        }
        break;
      case WebRTCConnectionType.PatientTool:
        this.toolVideo = video;
        this.toolStream = stream;
        break;
      case WebRTCConnectionType.SpecialistTools:
        console.log('specialist tools in video', role)
        if (role === SocketUserRole.Spezialist) {
          this.toolVideo = video;
          this.toolStream = stream;
        } else {
          this.specialistVideo = video;
          this.specialistStream = stream;
        }
        break;
      default:
        break;
    }
  }

  public startRemoteVideo(videoElement: ElementRef, remoteStream: MediaStream,
    type: WebRTCConnectionType, role: SocketUserRole): Observable<MediaStream> {
    return new Observable((observer) => {
      if (!videoElement) {
        observer.error('Missing video element!');
        observer.complete();
        return;
      }
      console.log('REMOTE VIDEO')
      this.startVideoElement(videoElement.nativeElement, remoteStream, false, role, type);
      observer.next(remoteStream);
      observer.complete();
    })
  }

  /*private setStreamForVideo(stream: MediaStream, video: HTMLVideoElement, role?: SocketUserRole, type?: WebRTCConnectionType) {
    try {
      video.srcObject = stream;
      if (role && type) {
        this.setElementsByConnectionType(stream, video, role, type);
      }
    } catch (error) {
      this.adLoggerService.error('Media stream error ', error);
      video.src = URL.createObjectURL(stream as any);
    }
  }*/

  /*public startVideoStream(preferredDevice?: MediaDeviceInfo): Observable<MediaStream> {
    return new Observable((observer) => {
      const currentConstraints = Object.assign({}, this.BASE_MEDIA_CONSTRAINTS);
      if (preferredDevice && preferredDevice.deviceId) {
        currentConstraints.video['deviceId'] = {
          exact: preferredDevice.deviceId
        }
      }

      navigator.mediaDevices.getUserMedia(currentConstraints)
        .then((stream) => {
          this.startVideoElement(this.localVideo, stream, true);
          this.localStream = stream;
          observer.next(stream);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }*/

  private startVideoElement(videoElement: HTMLVideoElement, stream: MediaStream, mute = false,
    role?: SocketUserRole, type?: WebRTCConnectionType) {
    videoElement.onloadedmetadata = () => {
      videoElement.controls = false;
      videoElement.muted = mute;
      videoElement.play().then(() => {
        console.log('Video stream started');
        window.dispatchEvent(new Event('resize'));
        this.videoStarted$.next('remote')
      })
    }
    try {
      videoElement.srcObject = stream;
      if (role && type) {
        this.setElementsByConnectionType(stream, videoElement, role, type);
      }
    } catch (error) {
      this.adLoggerService.error('Media stream error', error);
      videoElement.src = URL.createObjectURL(stream as any);
    }
  }

  /**
   * Stop currently running streams and remove them from the local video element
   */
  public stopVideoStream(): Observable<any> {
    return new Observable((observer) => {
      console.log('STOPPING THE CAMERA STREAM!')
      if (!this.localVideo || !this.localVideo.srcObject) {
        observer.next();
        observer.complete();
        return;
      }

      this.localVideo.pause()
      forkJoin([
        this.streamService.stopStream(this.streamService.localstream),
        this.streamService.stopStream(this.streamService.screenShareStream)
      ])
        .subscribe(() => {
          this.localVideo.srcObject = null;
          this.localStream = null;
          observer.next();
          observer.complete();
        })
    });
  }

  /**
   * Switch between available camera hardware or begin screen sharing
   * @param deviceId getUserMedia camera deviceId's or screen sharing
   */
  public toggleScreenShare(deviceId: any): Observable<MediaStreamTrack> {
    //this.localVideo.pause();
    switch (deviceId) {
      case this.SCREEN_SHARE_ID:
        return this.switchToScreenShare();
      case this.STANDARD_CAMERA_ID:
        return this.switchToStandardCamera();
      default:
        return of(null);
    }
  }

  public switchMediaDevice(videoDeviceId: string, audioDeviceId: string): Observable<MediaStream> {
    return new Observable((observer) => {
      this.localVideo.pause();
      this.streamService.startLocalStream(videoDeviceId, audioDeviceId)
        .then((stream) => {
          this.localVideo.srcObject = this.streamService.localstream;
          this.localVideo.play();
          observer.next(stream);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          observer.complete();
        })
    })
  }

  /**
   * Activate standard browser behaviour to share browser or application windows
   */
  private switchToScreenShare(): Observable<MediaStreamTrack> {
    return new Observable((observer) => {
      this.streamService.startScreenshareStream()
        .then((stream) => {
          this.localVideo.srcObject = stream
          this.localVideo.play()
          const videoTrack = this.streamService.localstream.getVideoTracks()[0]
          videoTrack.enabled = false
          observer.next(stream.getVideoTracks()[0])
          observer.complete()
        }).catch(error => {
          console.log("switchToScreenShare: error = ", error)
          observer.error(error)
          observer.complete()
        })
    })
  }

  /**
   * Deactivate screen sharing and return to send the standard camera output
   */
  private switchToStandardCamera(): Observable<MediaStreamTrack> {
    return new Observable((observer) => {
      if (this.streamService.screenShareStream) {
        this.streamService.stopStream(this.streamService.screenShareStream).subscribe(() => {
          console.log('Stopped sharing the screen')
        });
      }
      const videoTrack = this.streamService.localstream.getVideoTracks()[0];
      videoTrack.enabled = true;
      this.localVideo.srcObject = this.streamService.localstream;
      this.localVideo.play().then(() => {
        this.videoStarted$.next('local')
      });


      observer.next(videoTrack)
      observer.complete();
    })
  }
}
