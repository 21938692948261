import { NgModule } from '@angular/core';
import { CameraSnapshotModule } from '../camera/camera-snapshot.module';
import { SharedModule } from '../shared.module';
import { DragNDropUploadDirective } from './directives/drag-n-drop-upload.directive';
import { AddressSearchFieldComponent } from './fields/address-search-field.component';
import { ArztTypeAndLanrFieldsComponent } from './fields/arzt-type-and-lanr-fields.component';
import { AssetUploadFieldWebcamDialogComponent } from './fields/asset-upload-field-webcam-dialog.component';
import { AssetUploadFieldComponent } from './fields/asset-upload-field.component';
import { CheckboxComponent } from './fields/checkbox/checkbox.component';
import { ChipListAutocompleteFieldComponent } from './fields/chip-list-autocomplete-field.component';
import { ChipListFieldComponent } from './fields/chip-list-field.component';
import { CountryFieldCustomComponent } from './fields/country-field-custom.component';
import { CountryFieldComponent } from './fields/country-field.component';
import { FacharztFieldsComponent } from './fields/facharzt-fields.component';
import { MultilingualChipListFieldComponent } from './fields/multilingual-chip-list-field.component';
import { NameTitleFieldComponent } from './fields/name-title-field.component';
import { NewPasswordFieldComponent } from './fields/new-password-field.component';
import { PasswordFieldComponent } from './fields/password-field.component';
import { RadioButtonSelectComponent } from './fields/radio-button-select.component';
import { SearchFieldComponent } from './fields/search-field.component';
import { SelectionFieldComponent } from './fields/selection-field.component';
import { SlugFieldComponent } from './fields/slug-field.component';


/**
 * Components of available Form-Fields
 */
const FORM_FIELD_COMPONENTS = [
  MultilingualChipListFieldComponent,
  NewPasswordFieldComponent,
  FacharztFieldsComponent,
  AssetUploadFieldComponent,
  AssetUploadFieldWebcamDialogComponent,
  SlugFieldComponent,
  SelectionFieldComponent,
  ChipListFieldComponent,
  ChipListAutocompleteFieldComponent,
  NameTitleFieldComponent,
  PasswordFieldComponent,
  DragNDropUploadDirective,
  CountryFieldComponent,
  CountryFieldCustomComponent,
  AddressSearchFieldComponent,
  SearchFieldComponent,
  ArztTypeAndLanrFieldsComponent,
  RadioButtonSelectComponent,
  CheckboxComponent,
]


@NgModule({
    imports: [
        SharedModule,
        CameraSnapshotModule,
        ...FORM_FIELD_COMPONENTS,
    ],
    exports: FORM_FIELD_COMPONENTS
})
export class FormFieldsModule { }
