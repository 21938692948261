import { WfaQuestionType } from '@arzt-direkt/wfa-definitions'
import { cloneDeep } from '@arzt-direkt/wfa-generic-utils'
import { ICreatorOptions } from 'survey-creator-core'

import { LicenseTier } from '../../../types/license-tier'
import { Operator } from '../../../types/operator'

const devEverything: WfaQuestionType[] = [
  'boolean',
  'buttongroup',
  'checkbox',
  'comment',
  'dropdown',
  'empty',
  'expression',
  'file',
  'html',
  'image',
  'imagepicker',
  'matrix',
  'matrixdropdown',
  'matrixdropdownbase',
  'matrixdynamic',
  'multipletext',
  'nonvalue',
  'panel',
  'paneldynamic',
  'radiogroup',
  'ranking',
  'rating',
  'text',
  'textbase',
  'tagbox',
  'signaturepad',
]

const basic: WfaQuestionType[] = [
  'checkbox',
  'comment',
  'dropdown',
  'html',
  'radiogroup',
  'text',
  'tagbox',
  'signaturepad',
]

// adi-3172 <delete note when ticket is completed>
const extended: WfaQuestionType[] = [
  'boolean',
  // 'buttongroup',
  'checkbox',
  'comment',
  'dropdown',
  // 'empty',
  'expression',
  // 'file',
  'html',
  // 'image',
  // 'imagepicker',
  'matrix',
  // 'matrixdropdown',
  // 'matrixdropdownbase',
  // 'matrixdynamic',
  // 'multipletext',
  // 'nonvalue',
  // 'panel',
  // 'paneldynamic',
  'radiogroup',
  // 'ranking',
  // 'rating',
  'text',
  // 'textbase',
  'tagbox',
  'signaturepad',
]

function getQuestionTypes(licenseTier: LicenseTier): WfaQuestionType[] {
  if (licenseTier === 'devEverything') return devEverything
  if (licenseTier === 'extended') return extended
  return basic
}

export function customizeQuestionTypes(
  licenseTier: LicenseTier,
): Operator<ICreatorOptions> {
  return (creatorOptions: ICreatorOptions) =>
    Object.assign(cloneDeep(creatorOptions), {
      questionTypes: getQuestionTypes(licenseTier),
    })
}
