import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AssetMeta, Assets, PatientSession } from '../entities/PatientSession.entity';
import { WebRTCConnection } from '../entities/WebRTC.entity';
import { CloseType, CoupleType, Track, WebRTCChannelDataType, WebRTCChatMessage, WebRTCCloseMessage, WebRTCCoupledMessage, WebRTCDeleteMessage, WebRTCPatientSessionMessage, WebRTCResync, WebRTCResyncMessage, WebRTCSnapshotMessage, WebRTCTrackStatusMessage, WebRTCUsernameMessage } from '../entities/WebRTCMessage.entity';
import { StorageService } from '../storage/storage.service';
import { DataTransferService } from './dataTransfer.service';
import { ChatService } from './videochat/chat/chat.service';
@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  constructor(
    private dataTransferService: DataTransferService,
    private storageService: StorageService,
    private chatService: ChatService
  ) { }

  /**
   * Send a text message to all other peers
   * @param connections currently running peer connections
   * @param role user role of sender
   * @param username name of message sender
   */
  public sendChatMessage(connections: WebRTCConnection[], webRTCChatMessage: WebRTCChatMessage) {
    if (!connections || connections.length < 1) { return; }
    this.dataTransferService.sendMessageToPeers(connections, webRTCChatMessage)
  }

  /**
   * Used by the Arzt to send PatientSession to the Specialist.
   * Actually an asset-less version of the PatientSession is sent
   * as the message would otherwise exceed the size limit.
   * The assets are then transferred separately using rtc streaming.
   * @param connection webrtc connection information
   */
  public sendPatientSessionToSpecialist(connection: WebRTCConnection) {
    if (!connection) { return; }
    this.storageService.getPatientSession().subscribe((patientSession: PatientSession) => {
      // if (!patientSession) { return; }
      const emptyAssets: Assets = {
      }
      const patientSessionWithoutAssets: PatientSession = {
        ...patientSession,
        assets: emptyAssets
      }
      console.log('sendPatientSessionToSpecialist: patientSessionWithoutAssets = ', patientSessionWithoutAssets)
      const messageData: WebRTCPatientSessionMessage = {
        createdAt: dayjs().toISOString(),
        type: WebRTCChannelDataType.Session,
        session: patientSessionWithoutAssets
      };
      this.dataTransferService.sendMessageToPeers([connection], messageData);
    })
  }

  /**
   * Inform peer that the tool or specialist was successfully coupled with the doctor
   * @param connection patient peer connection
   */
  public toolCoupled(connection: WebRTCConnection, coupled: CoupleType) {
    if (!connection) { return; }
    const messageData: WebRTCCoupledMessage = {
      createdAt: dayjs().toISOString(),
      type: WebRTCChannelDataType.Coupled,
      coupled: coupled
    };
    this.dataTransferService.sendMessageToPeers([connection], messageData);
  }

  /**
   * Remove local file and trigger deletion for connected peers
   * @param connections currently running peer connections
   */
  public deleteWebRTCFile(connections: WebRTCConnection[], assetMeta: AssetMeta): Observable<boolean> {
    return of(null).pipe(
      mergeMap(() => {
        console.log("deleteWebRTCFile: connections = ", connections)
        console.log("deleteWebRTCFile: assetMeta = ", assetMeta)
        if (connections && connections.length) {
          const webRTCDeleteMessage: WebRTCDeleteMessage = {
            meta: assetMeta,
            createdAt: dayjs().toISOString(),
            type: WebRTCChannelDataType.Delete
          }
          this.dataTransferService.sendMessageToPeers(connections, webRTCDeleteMessage)
        }
        return of(null)
      }),
      mergeMap(() =>
        // remove associated file
        this.dataTransferService.deleteWebRTCFile(assetMeta)
      )
    )
  }

  /**
   * Request a high quality snapshot of the iOS app
   * @param connection tool peer connection
   * @param quality quality level of requested snapshot
   * (it looks like WebRTCChannelDataType.Snapshot is not used in the app
   * but it is used by tool)
   */
  public requestToolSnapshot(connection: WebRTCConnection, quality = 1) {
    const messageData: WebRTCSnapshotMessage = {
      createdAt: dayjs().toISOString(),
      type: WebRTCChannelDataType.Snapshot,
      quality
    };
    console.log('SNAPSHOT DATA', messageData)
    this.dataTransferService.sendMessageToPeers([connection], messageData, true);
  }

  /**
   * Share connected users real names
   * @param connection webrtc peer connection data
   * @param patient full name of patient user
   * @param doctor full name of acting physician
   * @param specialist full name of added specialist doctor
   */
  public exchangeUsernames(connection: WebRTCConnection, patient: string, doctor: string, specialist: string) {
    if (!connection) { return; }
    const messageData: WebRTCUsernameMessage = {
      createdAt: dayjs().toISOString(),
      type: WebRTCChannelDataType.Username,
      patient,
      doctor,
      specialist
    };
    this.dataTransferService.sendMessageToPeers([connection], messageData);
  }

  /**
   * Resynchronize chat and file data with other session members
   * @param connection webrtc peer connection data
   * @param requestType data types to be resynced with the requester
   * @param fileIds currently saved file Ids
   */
  public requestResync(connection: WebRTCConnection, requestType: WebRTCResync) {
    const fileNames: string[] = []
    if (!connection) { return; }
    const messageData: WebRTCResyncMessage = {
      createdAt: dayjs().toISOString(),
      type: WebRTCChannelDataType.Resync,
      resync: requestType,
      fileNames
    }
    console.log('requesting session data resync', requestType);
    this.dataTransferService.initializeDataResync(connection, messageData);
  }

  public trackChange(connections: WebRTCConnection[], track: Track, status: boolean, screenShare?: boolean) {
    if (!connections || connections.length < 1) { return }
    const messageData: WebRTCTrackStatusMessage = {
      createdAt: dayjs().toISOString(),
      type: WebRTCChannelDataType.TrackStatus,
      track,
      status,
      screenShare
    };
    this.dataTransferService.sendMessageToPeers(connections, messageData);
  }

  /**
   * Manually force a peer to close all running peer connections
   * @param connection webrtc peer connection data
   */
  public forceCloseConnections(connection: WebRTCConnection, closeConnection: CloseType, toTool = false) {
    if (!connection) { return; }
    const messageData: WebRTCCloseMessage = {
      createdAt: dayjs().toISOString(),
      type: WebRTCChannelDataType.ForceClose,
      close: closeConnection
    };
    console.log('CLOSING CONNECTION', connection)
    this.dataTransferService.sendMessageToPeers([connection], messageData, toTool);
  }
}
