import { FlatConstructor, FlatRepository } from 'survey-pdf'

import { FlatFileCustom } from './flat-file-custom'

export function customizePdfClasses(): void {
  FlatRepository.getInstance().register(
    'file',
    FlatFileCustom as unknown as FlatConstructor,
  )
}
