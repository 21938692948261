import { AdPayCheckoutService } from '@a-d/dashboard/ad-pay/ad-pay-checkout.service';
import { AdPayGeneralService } from '@a-d/dashboard/ad-pay/ad-pay-general.service';
import { PaymentInfoDeferred, PaymentMethodTypes, PaymentMethodsResponse, TransactionChannel } from '@a-d/entities/AdPay.entity';
import { LanguageService } from '@a-d/i18n/language.service';
import { InstanceService } from '@a-d/instance/instance.service';
import { AdLoggerService } from '@a-d/logging/ad-logger.service';
import AdyenCheckout from '@adyen/adyen-web';
import { NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { environment } from '@env/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'lib';
import { BookingSeriesService } from '../booking-series.service';
import { BookingService } from '../booking.service';
import { BookingPaymentService } from './booking-payment.service';

@Component({
  selector: 'app-booking-payment',
  standalone: true,
  imports: [MatButtonModule, NgIf, MatListModule, MatExpansionModule, MatIconModule, TranslateModule],
  templateUrl: './booking-payment.component.html',
  styleUrl: './booking-payment.component.scss'
})
export class BookingPaymentComponent implements AfterViewInit {
  public isLoading = false
  public isDemo = false
  public storeId: string
  public hasConfig = false
  public dropin = null
  private sellerCountryCode: string
  private shopperLocale: string
  public applePayConfiguration = null
  public transactionCompleted = false
  public hasError = false
  public hasPayByBank = false
  public test = true
  public step: number
  private returnUrl: string

  private readonly PAY_BY_BANK_TYPE = 'paybybank'
  private readonly DROPIN_IDENTIFIER = '#dropin-container'

  constructor(
    private adLoggerService: AdLoggerService,
    private checkoutService: AdPayCheckoutService,
    private adPayGeneralService: AdPayGeneralService,
    private instanceService: InstanceService,
    private languageService: LanguageService,
    private notificationService: NotificationService,
    private bookingService: BookingService,
    private bookingSeriesService: BookingSeriesService,
    private translate: TranslateService,
    private bookingPaymentService: BookingPaymentService,
    private cdref: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this.isDemo = environment.demoMode
    if (!this.isDemo) {
      this.setStep(0)
    }
    this.returnUrl = window.location.href;
    this.checkoutService.countryUpdated$.subscribe((countryCode) => {
      this.sellerCountryCode = countryCode
      this.applePayConfiguration = {
        amount: this.checkoutService.amount,
        countryCode: countryCode
      }
      if (this.sellerCountryCode && this.shopperLocale) {
        this.initiateCheckout(countryCode);
      }
    }
    )
    this.languageService.shopperLocaleChangeSubject$.subscribe((shopperLocale: string) => {
      this.shopperLocale = shopperLocale
      if (this.sellerCountryCode && this.shopperLocale) {
        this.initiateCheckout(this.sellerCountryCode);
      }
    })
    this.checkoutService.storeUpdated$.subscribe((storeId) => {
      if (this.storeId === storeId) { return; }
      this.storeId = storeId;
      this.initiateCheckout(this.sellerCountryCode);
    })
  }

  /**
   * Load store for selected betriebsstätte and appointment type
   * TODO: switch to best matching store once it is implemented
   */
  private initiateCheckout(countryCode = 'DE') {
    // fallback
    if (!this.storeId) {
      this.storeId = this.checkoutService.storeToUse();
    }
    this.checkoutService.retrievePaymentMethods(countryCode, TransactionChannel.Web, this.checkoutService.amount, this.storeId).subscribe({
      next: (response) => this.adjustCheckout(response),
      error: (error) => {
        this.isLoading = false;
      }
    })
  }

  public setStep(index: number) {
    this.step = index;
    if (this.step === 0) {
      this.initiateCheckout(this.sellerCountryCode)
    }
  }

  private adjustCheckout(response: any) {
    this.isLoading = false;
    this.storeId = response.paymentMethodData?.storeId;
    const paymentMethods: any[] = response.paymentMethodData?.paymentMethods;
    const payByBankIndex = paymentMethods.findIndex((method) => method.type === this.PAY_BY_BANK_TYPE);
    if (payByBankIndex > -1) {
      this.hasPayByBank = true;
      paymentMethods.splice(payByBankIndex, 1)
    }
    this.createCheckoutConfig({ paymentMethods: paymentMethods }, this.sellerCountryCode)
  }

  public retryPayment() {
    // this.hasError = false;
    this.dropin.setStatus('ready'); // set back to the initial state
  }

  public payWithOpenBanking() {
    const paymentDeferred: PaymentInfoDeferred = {
      type: PaymentMethodTypes.OpenBanking,
      storeId: this.storeId
    }
    this.bookingService.seriesMode$.getValue() ? this.bookingSeriesService.book(paymentDeferred) : this.bookingService.book(paymentDeferred)
    // this.bookingService.book(paymentDeferred);
  }

  private handleFinalResult(response: any) {
    console.log('handling result', this.hasError)
    if (response?.authorise?.resultCode !== 'Authorised') {
      this.hasError = true
      this.test = false
      console.log('handling result not auth', this.hasError)

      this.bookingPaymentService.handlePaymentError(response, this.dropin);
      this.cdref.detectChanges()
      return;
    }
    console.log('transaction completed', response);
    const payment = {
      paymentId: response?._id,
      amount: response?.authorise?.amount,
      pspReference: response?.authorise.pspReference
    }
    // this.bookingService.book(payment);
    this.bookingService.seriesMode$.getValue() ? this.bookingSeriesService.book(payment) : this.bookingService.book(payment);
  }

  private async createCheckoutConfig(paymentMethods: PaymentMethodsResponse, countryCode: string) {
    if (this.dropin) {
      this.dropin.unmount();
    }
    if (!paymentMethods) { return; }
    const dropinConfig = {
      paymentMethodsResponse: paymentMethods,
      clientKey: environment.adyenClient,
      locale: this.shopperLocale,    // TODO dynamic translation
      environment: environment.adPayEnvironment,
      analytics: {
        enabled: false
      },
      onSubmit: (state: any, dropin: any) => {
        this.checkoutService.makePayment(state.data, this.storeId, this.checkoutService.amount, countryCode, this.returnUrl)
          .subscribe({
            next: (response) => {
              if (response.action) {
                console.log('additional action needed', response.action)
                return dropin.handleAction(response.action)
              }
              this.handleFinalResult(response);
            },
            error: (error) => {
              this.adLoggerService.error('Error while handling transaction', error)
              this.dropin.setStatus('error', { message: this.translate.instant('PAYMENT-ERROR.ERROR') });
              throw Error(error);
            }
          })
      },
      onAdditionalDetails: (state: any, dropin: any) => {
        this.checkoutService.requestPaymentDetails(state.data).subscribe({
          next: (response) => {
            if (response?.action) {
              return dropin.handleAction(response.action)
            }
            this.handleFinalResult(response);
          },
          error: (error) => {
            this.adLoggerService.error(error);
            this.dropin.setStatus('error', { message: this.translate.instant('PAYMENT-ERROR.ERROR') });
            this.adLoggerService.error(error);
            throw new Error(error);
          }
        })
      },
      paymentMethodsConfiguration: {
        applepay: this.applePayConfiguration,
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          enableStoreDetails: false,
          hideCVC: false,
          name: this.translate.instant('OTK.BOOKING-PAYMENT.CARD-PAYMENT')
        },
        threeDS2: {
          challengeWindowSize: '04'
        }
      },
      amount: this.checkoutService.amount
    }
    const checkout = await AdyenCheckout(dropinConfig);
    this.dropin = checkout.create('dropin', {
      openFirstPaymentMethod: true,
      instantPaymentTypes: ['applepay']
    });
    this.dropin.mount(this.DROPIN_IDENTIFIER);
  }

}
