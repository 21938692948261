import { SurveyjsPropertyInfo } from '@arzt-direkt/wfa-definitions'

export const backgroundColor: SurveyjsPropertyInfo = {
  name: 'backgroundColor',
  surveyElements: ['signaturepad'],
  customPropertySettings: [
    {
      settingName: 'defaultValue',
      settingValue: '#FFFFFF',
    },
  ],
}
