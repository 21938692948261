<ng-container [formGroup]="formGroup">

  <div class="c-form__row" *ngIf="withTypeahead===false">
    <mat-form-field attr.data-selenium-id="field-auswahl" appearance="outline">
      <mat-label>
        {{ selectionPlaceholder }}
        <span *ngIf="selectionPretendRequired" class="isError">*</span>
      </mat-label>
      <mat-select attr.data-selenium-id="mat-select-selection-name" [placeholder]="selectionPlaceholder"
        [formControlName]="selectionName" [multiple]="multiple" [required]="selectionRequired">
        <mat-select-trigger>
          {{ selectionJoinedValue }}
        </mat-select-trigger>

        <mat-option attr.data-selenium-id="mat-option-no-option" *ngIf="!selectionRequired && noOption">
          <em>{{ noOption }}</em>
        </mat-option>

        <mat-option attr.data-selenium-id="mat-option-{{item}}" *ngFor="let item of (options | i18nArray)"
          [value]="item">
          {{ item }}
        </mat-option>

        <mat-option attr.data-selenium-id="mat-option-sonstiges-option" *ngIf="sonstigesOption"
          [value]="sonstigesOption" class="mat-option--isMultiline">
          <ng-container *ngIf="!sonstigesOptionUndertitle">
            {{ sonstigesOption }}
          </ng-container>
          <ng-container *ngIf="sonstigesOptionUndertitle">
            <span>{{ sonstigesOption }}</span>
            <span>{{ sonstigesOptionUndertitle }}</span>
          </ng-container>
        </mat-option>
      </mat-select>

      <mat-hint *ngIf="sonstigesIsSelected && description && !description.value && sonstigesShowHint"
        style="font-weight: 600">
        {{'GLOBAL.OTHER' | translate}}
      </mat-hint>
    </mat-form-field>
  </div>

  <div class="c-form__row" *ngIf="showDescription">
    <mat-form-field attr.data-selenium-id="field-beschreibung" appearance="outline">
      <mat-label>{{ descriptionPlaceholder }}</mat-label>
      <textarea matInput cdkTextareaAutosize [formControlName]="descriptionName" [required]="descriptionIsRequired"
        mat-autosize=true cdkAutosizeMinRows=1 cdkAutosizeMaxRows=5></textarea>
      <mat-hint *ngIf="descriptionHint">{{ descriptionHint }}</mat-hint>
    </mat-form-field>
  </div>

  <div class="c-form__row" *ngIf="withTypeahead===true">
    <mat-form-field attr.data-selenium-id="field-auswahl-2" appearance="outline">
      <mat-label>
        {{ selectionPlaceholder }}
        <span *ngIf="selectionPretendRequired" class="isError">*</span>
      </mat-label>
      <input attr.data-selenium-id="input-selection" type="text" matInput [formControl]="selection"
        [matAutocomplete]="auto" (keyup)="onTypeahead($event.target.value)" [placeholder]="selectionPlaceholder"
        [required]="selectionRequired" />
      <span class="mat-select-arrow"></span>
    </mat-form-field>

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option attr.data-selenium-id="mat-option-{{i}}"
        *ngFor="let option of (options | i18nArray | searchString: typeaheadSearch); let i = index" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </div>
</ng-container>