import { Injectable } from "@angular/core"
import { CanActivate, Router } from "@angular/router"
import { of } from "rxjs"
import { catchError, map } from "rxjs/operators"
import { environment } from "../../environments/environment"
import { InstanceService } from "../instance/instance.service"


/**
 * Demo-Guard (Route only accessible when in demo-mode)
 */
@Injectable({
  providedIn: 'root'
})
export class AppDemoGuard implements CanActivate {
  constructor(
    public router: Router,
    public instanceService: InstanceService,
  ) { }

  canActivate() {
    if (!environment.demoMode) {
      this.router.parseUrl('/')
      return false
    }

    return this.instanceService.setInstanceByIdentifier('demo').pipe(
      map((_) => true),
      catchError(() => of(this.router.parseUrl(''))),
    )
  }
}
