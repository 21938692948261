import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { PatientSessionService } from '@a-d/patient-session/patient-session.service';
import { Injectable, OnDestroy } from '@angular/core';
import { NotificationService } from '@lib/notifications/notification.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import { BehaviorSubject, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Asset, AssetMeta, PatientSession } from '../entities/PatientSession.entity';
import { SocketUserRole } from '../entities/Socket.entity';
import { ChannelData, Origin, PartialData, RTCDataChannelLabels, WebRTCConnection, WebRTCConnectionType, WebRTCFile, WebRTCReceivedFile, WebRTCTransferData } from '../entities/WebRTC.entity';
import { CloseType, WebRTCChannelDataType, WebRTCChannelMessage, WebRTCChatMessage, WebRTCChatSyncMessage, WebRTCCloseMessage, WebRTCDeleteMessage, WebRTCFileMessage, WebRTCFileProgress, WebRTCMagicMessage, WebRTCPatientSessionMessage, WebRTCResync, WebRTCResyncMessage, WebRTCTrackStatusMessage, WebRTCUsernameMessage } from '../entities/WebRTCMessage.entity';
import { ConsoleService } from '../logging/console.service';
import { DatauriHelpersService } from '../misc/datauri-helpers.service';
import { ImageHelpersService, ImageScaleMode } from '../misc/image-helpers.service';
import { MimetypeHelpersService } from '../misc/mimetype-helpers.service';
import { StorageService } from './../storage/storage.service';
import { AssetService } from './asset.service';
import { ChatService } from './videochat/chat/chat.service';
import { WebRTCAudioService } from './webrtc-audio.service';
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DataTransferService implements OnDestroy {
  public webRTCFiles: WebRTCFile[] = []
  // when a new WebRTCFile is added to webRTCFiles, it is added as a File to
  // streamingFilesQueue, from which it is automatically streamed. Once finished
  // streaming, it is removed from streamingFilesQueue.
  private streamingFilesQueue: File[] = []
  public failedFiles: AssetMeta[] = []
  public webRTCFilesChanged$ = new Subject<WebRTCFile[]>()
  public deletedWebRTCFile$ = new Subject<AssetMeta>()
  public scaledWebRTCFile$ = new Subject<string>()
  public sendWebRTCFile$ = new Subject<WebRTCFile>()
  private allFilesToAssets$ = new Subject<WebRTCFile[]>()
  private receivedFile$ = new Subject<{ fileData: WebRTCReceivedFile, file: File }>()
  private coupledTool$ = new Subject<boolean>()
  private updateUsername$ = new Subject<object>()
  private transmittingFile$ = new Subject<boolean>()
  private transmitProgress$ = new Subject<WebRTCFileProgress>()
  private trackStatusChange$ = new Subject<WebRTCTrackStatusMessage>()
  private forceConnectionClose$ = new Subject<CloseType>()

  public isTransferingFiles$ = new BehaviorSubject<boolean>(false)
  public onSelectedChange$ = new Subject<boolean>()
  public isDemo = environment.demoMode
  public areWebRTCFilesOverlimit$ = new Subject<boolean>() // only calculated when closing the ArztVSSDialog and FileSelectionDialog
  public readonly CHUNK_SIZE = 30000; // 30kb. Amount of file to transfer in one transition.
  public readonly MAXIMUM_SINGLE_FILE_SAVE_SIZE = 1024 * 1024 * 1 // 1 Mb
  public readonly MAXIMUM_TOTAL_FILES_SAVE_SIZE = 1024 * 1024 * 2.4 // 2.4 Mb, siehe https://zollsoft.atlassian.net/browse/ADI-433
  private readonly ALLOWED_MIME_TYPES = this.mimeTypeHelpersService.ALLOWED_MIME_TYPES
  private webRTCTransferData: WebRTCTransferData = {}
  private readonly EOF_MESSAGE = 'EOF'
  private readonly toolWebRTCConnectionTypes = [
    WebRTCConnectionType.DocTools,
    WebRTCConnectionType.PatientTool,
    WebRTCConnectionType.SpecialistTools,
  ]

  private transmitting = false
  private _sendingFile = false
  private _sendQueue: Observable<any>[] = []
  public role: SocketUserRole // if patient, store webRTCFiles and Chat in local storage
  public magic$ = new Subject<boolean>() // causes the participants to send pre-made data
  public NUM_OF_MAGIC_ENTRIES_PER_ROLE = 2 // how many assets per role in assets/images/videochat/magic?

  constructor(
    private adLoggerService: AdLoggerService,
    private webrtcAudioService: WebRTCAudioService,
    private notificationService: NotificationService,
    private storageService: StorageService,
    private chatService: ChatService,
    private assetService: AssetService,
    private consoleService: ConsoleService,
    private imageHelpersService: ImageHelpersService,
    private dataUriHelpers: DatauriHelpersService,
    private patientSessionService: PatientSessionService,
    private mimeTypeHelpersService: MimetypeHelpersService
  ) { }

  public setRole(role: SocketUserRole) {
    this.role = role
  }

  ngOnDestroy() {
    this.webRTCFilesChanged$.complete();
    this.receivedFile$.complete();
    this.deletedWebRTCFile$.complete();
    this.scaledWebRTCFile$.complete();
    this.sendWebRTCFile$.complete();
    this.allFilesToAssets$.complete();
    this.coupledTool$.complete();
    this.updateUsername$.complete();
    this.forceConnectionClose$.complete();
    this.transmittingFile$.complete();
    this.transmitProgress$.complete();
    this.trackStatusChange$.complete();
  }

  public sendWebRTCFile(): Observable<WebRTCFile> {
    return this.sendWebRTCFile$.asObservable();
  }

  public receivedFile(): Observable<{ fileData: WebRTCReceivedFile, file: File }> {
    return this.receivedFile$.asObservable();
  }

  public coupledTool(): Observable<boolean> {
    return this.coupledTool$.asObservable();
  }

  public deletedWebRTCFile(): Observable<AssetMeta> {
    return this.deletedWebRTCFile$.asObservable();
  }

  public updateUsername(): Observable<object> {
    return this.updateUsername$.asObservable();
  }

  public trackStatusChange(): Observable<WebRTCTrackStatusMessage> {
    return this.trackStatusChange$.asObservable();
  }

  public closeConnections(): Observable<CloseType> {
    return this.forceConnectionClose$.asObservable();
  }

  public transmittingFile(): Observable<boolean> {
    return this.transmittingFile$.asObservable();
  }

  public transmitProgress(): Observable<WebRTCFileProgress> {
    return this.transmitProgress$.asObservable();
  }

  /**
   * adds a webRTCFile to "this", sends it to the other participants
   * and for the patient only also adds it to local storage
   */
  public addWebRTCFile(webRTCFile: WebRTCFile): Observable<boolean> {
    return of(null)
      .pipe(
        mergeMap(() =>
          this.assetService.doesAssetExist(webRTCFile, this.webRTCFiles)
        ),
        mergeMap((doesAssetExist: boolean) => {
          if (doesAssetExist) {
            return of(null)
          } else {
            this.webRTCFiles.push(webRTCFile)
            this.sendWebRTCFile$.next(webRTCFile)
            this.webRTCFilesChanged$.next(this.webRTCFiles)
            return (this.role && this.role === SocketUserRole.Patient)
              ? this.storageService.addWebRTCFiles([webRTCFile])
              : of(null)
          }
        })
      )
  }

  private addWebRTCFiles(webRTCFiles: WebRTCFile[], index: number = 0) {
    const webRTCFilesKeys: string[] = Object.keys(webRTCFiles)
    const numOfWebRTCFiles = webRTCFilesKeys.length
    this.consoleService.logDemo('addWebRTCFiles: webRTCFiles = ', webRTCFiles, " index = ", index, " numOfWebRTCFiles = ", numOfWebRTCFiles)
    if (index === numOfWebRTCFiles) {
    } else {
      const webRTCFile: WebRTCFile = webRTCFiles[webRTCFilesKeys[index]]
      this.addWebRTCFile(webRTCFile).subscribe(() => {
        this.addWebRTCFiles(webRTCFiles, index + 1)
      })
    }
  }

  // adds a File to the VSS. At the moment used only for snapshots
  // taken during the vss.
  // and for doctor-online
  public addFile(file: File, origin: Origin, senderRole?: SocketUserRole, capturedRole?: SocketUserRole) {
    this.assetService.fileToAsset(file, origin, senderRole, capturedRole)
      .pipe(
        mergeMap((asset: Asset) => {
          if (this.role && this.role === SocketUserRole.Patient) {
            this.storageService.addWebRTCFiles([asset]).subscribe()
          }
          return of(asset)
        })
      )
      .subscribe((asset: Asset) => {
        this.consoleService.logDemo('addFile: asset = ', asset)
        this.webRTCFiles.push(asset)
        this.webRTCFilesChanged$.next(this.webRTCFiles)
        this.sendWebRTCFile$.next(asset)
      })
  }

  // adds a list of files uploaded via the Dateien menu to the vss
  public addFileList(senderRole: SocketUserRole, fileList: FileList): void {
    if (this.role && this.role === SocketUserRole.Patient) {
      this.allFilesToAssets$.subscribe((webRTCFiles) => this.addFilesToLocalStorage(webRTCFiles))
    }
    this.consoleService.logDemo('addFilesList: fileList ', fileList)
    const webRTCFiles: WebRTCFile[] = []
    let indexSum = 0
    for (let i = 0; i < fileList.length; i++) {
      const file: File = fileList[i]
      // delay for creating assets from files so that files uploaded at the same time do not get the same createdAt
      // otherwise this causes problems e.g. for fileList in videochat-chat.component
      setTimeout(() => {
        this.assetService.fileToAsset(file, Origin.Sprechstunde, senderRole)
          .subscribe((asset: Asset) => {
            webRTCFiles.push(asset)
            this.webRTCFiles.push(asset)
            this.webRTCFiles = this.webRTCFiles
              .sort((fileA, fileB) => {
                const timeA = fileA.meta.createdAt
                const timeB = fileB.meta.createdAt
                return dayjs(timeA).isAfter(dayjs(timeB)) ? 1 : -1
              })
            this.sendWebRTCFile$.next(asset)
            indexSum += 1
            if (indexSum === fileList.length) this.allFilesToAssets$.next(webRTCFiles)
          })
      }, i * 10)

    }
    /*if (this.role && this.role === SocketUserRole.Patient) {
      this.storageService.addWebRTCFiles(webRTCFiles)
        .subscribe(() =>
          this.webRTCFilesChanged$.next(this.webRTCFiles)
        )
    }*/
  }

  private addFilesToLocalStorage(webRTCFiles: WebRTCFile[]) {
    this.storageService.addWebRTCFiles(webRTCFiles)
      .subscribe(() =>
        this.webRTCFilesChanged$.next(this.webRTCFiles)
      )
  }

  public shareWebRTCFile(name: string, file?: File) {
    const webRTCFile = this.webRTCFiles.find((webRTCFile) => webRTCFile?.meta.name === name);
    if (!webRTCFile) { return; }
    delete webRTCFile.sendError;
    this.sendWebRTCFile$.next(webRTCFile);
  }

  public sendWebRTCFileToPeers(
    webRTCConnections: WebRTCConnection[],
    assetMeta: AssetMeta
  ): Observable<any> {
    return new Observable((observer) => {
      this.transmitting = true;
      this.transmittingFile$.next(true);
      const webRTCFile: WebRTCFile = this.webRTCFiles.filter(
        (webRTCFile: WebRTCFile) =>
          this.assetService.areAssetsIdentical(webRTCFile.meta, assetMeta))[0]
      this.consoleService.logDemo('sendWebRTCFileToPeers: webRTCFile = ', webRTCFile)
      if (!webRTCConnections || !(webRTCConnections.length > 0) || !webRTCFile) {
        observer.next()
        observer.complete()
        this.transmitting = false
        this.transmittingFile$.next(false)
      }
      const file: File = this.assetService.fileFromAsset(webRTCFile)
      from(webRTCConnections)
        .pipe(
          mergeMap((webRTCConnection: WebRTCConnection) =>
            this.streamData(webRTCConnection.dataChannels, webRTCFile, file)))
        .subscribe({
          next: (_) => {
            observer.next()
            observer.complete()
            this.transmitting = false
            this.transmittingFile$.next(false)
          },
          error: (error) => {
            observer.error({ error, assetMeta })
            observer.complete()
            this.transmitting = false
            this.transmittingFile$.next(false)
            this.consoleService.logDemo('sendWebRTCFileToPeers: transmittingFile$.next(false), webRTCConnections error = ', error)
          }
        })
    })
  }
  /**
   * deletes the WebRTCFile from the service. For the patient also
   * deletes it from "WEB_RTC_FILES".
   * Note that files created before the VSS started are also stored
   * in "PATIENT_SESSION". These are not deleted from there.
   */
  public deleteWebRTCFile(assetMeta: AssetMeta): Observable<boolean> {
    return of(null)
      .pipe(
        mergeMap(() => {
          this.consoleService.logDemo('deleteWebRTCFile: assetMeta = ', assetMeta)
          const fileIndex = this.webRTCFiles.findIndex(
            (webRTCFile: WebRTCFile) =>
              this.assetService.areAssetsIdentical(webRTCFile.meta, assetMeta)
          )
          if (fileIndex >= 0) {
            this.webRTCFiles.splice(fileIndex, 1)
            this.webRTCFilesChanged$.next(this.webRTCFiles)
            this.deletedWebRTCFile$.next(assetMeta)
            console.log("trigger deletion----------------------------------------------")
            return of(true)
          }
          return of(false)
        }),
        mergeMap((deletedFile: boolean) =>
          (this.role && this.role === SocketUserRole.Patient)
            ? forkJoin([of(deletedFile), this.storageService.deleteWebRTCFile(assetMeta), this.storageService.deleteFileMessage(assetMeta)])
            : forkJoin([of(deletedFile), of(false)])
        ),
        mergeMap(([deletedFile, deletedFileFromStorage, deletedMessageFromStorage]) =>
          of(deletedFile))
      )
  }

  /**
   * todo: doesn't work when toTool=true because then both the if and the else if
   * return false?
   */
  public sendMessageToPeers(
    webRTCConnections: WebRTCConnection[],
    message: object,
    toTool = false
  ) {
    const toolIndex = this.toolWebRTCConnectionTypes.findIndex(
      (type) => type === webRTCConnections[0].type
    )
    this.consoleService.logDemo("sendMessageToPeers: toTool = ", toTool)
    this.consoleService.logDemo("sendMessageToPeers: toolIndex = ", toolIndex)
    this.consoleService.logDemo("sendMessageToPeers: message = ", message)
    webRTCConnections.forEach((webRTCConnection: WebRTCConnection) => {
      this.consoleService.logDemo("sendMessageToPeers: webRTCConnection = ", webRTCConnection)
      if (!toTool && webRTCConnection.type && toolIndex === -1) {
        this.sendChannelMessage(webRTCConnection, message)
      } else if (toTool && webRTCConnection.type && toolIndex !== -1) {
        this.consoleService.logDemo('SENDING TO TOOL')
        this.sendChannelMessage(webRTCConnection, message)
      }
    })
  }

  public addAsset(asset: Asset): Observable<boolean> {
    return new Observable((observer) => {
      of(null)
        .pipe(
          mergeMap(() =>
            (this.role && this.role === SocketUserRole.Patient)
              ? this.storageService.addWebRTCFiles([asset])
              : of(null)
          )
        ).subscribe(() => {
          this.webRTCFiles.push(asset)
          this.webRTCFilesChanged$.next(this.webRTCFiles)
          this.sendWebRTCFile$.next(asset)
          this.consoleService.logDemo("addAsset: asset = ", asset)
          observer.next(true)
          observer.complete()
        })
    })
  }

  /* Patient only: loads the WebRTCFiles inside the Assets field
  * of the stored session into the vss.
  */
  public addWebRTCFilesFromSession(): Observable<any> {
    return new Observable((observer) => {
      this.storageService.getPatientSession()
        .pipe(
          mergeMap((patientSession: PatientSession) => {
            const assetsArray: Asset[] = this.assetService.assetsToAssetsArray(patientSession.assets)
            let webRTCFiles: WebRTCFile[] = this.assetService.assetsArrayToWebRTCFiles(assetsArray)
            // mark named assets as selected for saving
            for (let i = 0; i < Object.keys(webRTCFiles).length; i++)
              webRTCFiles[i].selected = this.assetService.isAssetNamed(webRTCFiles[i]) === true
                ? true : false
            this.consoleService.logDemo("addWebRTCFilesFromSession: webRTCFiles = ", webRTCFiles)
            setTimeout(() => {
              this.addWebRTCFiles(webRTCFiles)
            }, 4000)
            return of(true)
          })
        ).subscribe(() => {
          observer.next(true)
          observer.complete()
        })
    })
  }

  /*
  * Patient only: loads the WebRTCFiles from local storage into the vss.
  * (important in case of refresh / reconnect)
  */
  public addWebRTCFilesFromLocalStorage(): Observable<any> {
    return new Observable((observer) => {
      this.storageService.getWebRTCFiles()
        .pipe(
          mergeMap((webRTCFiles: WebRTCFile[]) => {
            this.consoleService.logDemo("addWebRTCFilesFromLocalStorage: webRTCFiles = ", webRTCFiles)
            const addToLocalStorage: boolean = false
            return webRTCFiles
              ? of(this.addWebRTCFiles(webRTCFiles, undefined))
              : of(null)
          })
        ).subscribe(() => {
          observer.next(true)
          observer.complete()
        })
    })
  }



  /**
   * todo: this function says "requesting data resync" but I think its actually
   * the function offering it. In that case it should only be called
   * for the patient.
   * @param webRTCConnection
   * @param message
   */
  public initializeDataResync(
    webRTCConnection: WebRTCConnection,
    message: WebRTCResyncMessage
  ) {
    this.consoleService.logDemo('initializeDataResync')
    this.storageService.getWebRTCFiles().subscribe(
      (webRTCFilesFromStorage: WebRTCFile[]) => {
        const webRTCFiles = (this.role && this.role === SocketUserRole.Patient && webRTCFilesFromStorage) ? webRTCFilesFromStorage : this.webRTCFiles
        this.consoleService.logDemo('initializeDataResync: webRTCFiles = ', webRTCFiles)
        let fileNames: string[] = []
        if (Object.keys(webRTCFiles).length > 0) {
          this.webRTCFilesChanged$.next(webRTCFiles)
          fileNames = webRTCFiles.map((file) => file.meta.name)
        }
        message.fileNames = fileNames
        this.consoleService.logDemo('initializeDataResync: Requesting data resync', message)
        this.sendMessageToPeers([webRTCConnection], message)
      },
      (error) => this.adLoggerService.error(error)
    );
  }

  private sendChannelMessage(webRTCConnection: WebRTCConnection, message: object) {
    try {
      this.consoleService.logDemo('sendChannelMessage: webRTCConnection = ', webRTCConnection)
      this.consoleService.logDemo('sendChannelMessage: message = ', message)
      const channelsData = webRTCConnection.dataChannels
      const channelData = channelsData
        .find((channelData: ChannelData) =>
          channelData.rtcDataChannel.label === 'data')
      if (!channelData) {
        return this.consoleService.logDemo('sendChannelMessage: No data channel')
      }
      if (channelData.rtcDataChannel.readyState != 'open') return this.consoleService.logDemo('sendChannelMessage: Data channel not open')
      channelData.rtcDataChannel.send(JSON.stringify(message))
    } catch (error) {
      this.adLoggerService.error('Fehler beim senden der Nachricht', error)
      this.notificationService.displayNotification(
        'Fehler beim Senden der Nachricht'
      )
    }
  }

  /**
   * sends a WebRTCFile to another participant.
   * the first call to sliceFile with offset=0
   * sends the first 30kB. fileReader.onloadend then
   * keeps calling sliceFile and thus itself until
   * the complete file is sent.
   * @param channelsData
   * @param webRTCFile the file to send
   * @param fileToSend
   * @returns
   */
  public streamData(
    channelsData: ChannelData[],
    webRTCFile: WebRTCFile,
    fileToSend: File
  ): Observable<any> {
    return new Observable((observer) => {
      this.consoleService.logDemo('streamData: fileToSend ', fileToSend)
      const channelData: ChannelData = channelsData.find((channelData: ChannelData) => channelData.rtcDataChannel.label === RTCDataChannelLabels.Data)
      if (!channelData) {
        this.consoleService.logDemo('streamData: Missing channelData')
        observer.next()
        observer.complete()
        return
      }
      // send a message containing AssetMeta
      const webRTCFileMessage: WebRTCFileMessage = {
        meta: webRTCFile.meta,
        createdAt: dayjs().toISOString(),
        type: WebRTCChannelDataType.File,
      }
      const webRTCFileMessageStringifed = JSON.stringify(webRTCFileMessage);
      if (!(channelData.rtcDataChannel.readyState === 'open')) {
        console.warn('streamData: rtcDataChannel is not open!');
      }
      channelData.rtcDataChannel.send(webRTCFileMessageStringifed);
      // send either an "in progress" or an "EOF" message
      const fileReader: FileReader = new FileReader()
      let offset = 0;
      fileReader.onloadend = (event: any) => {
        channelData.rtcDataChannel.send(event.target.result)
        offset += event.target.result.byteLength

        if (offset < fileToSend.size) {
          this.sliceFile(offset, fileToSend, fileReader, offset)
        } else {
          channelData.rtcDataChannel.send(this.EOF_MESSAGE)
          this.consoleService.logDemo("streamData: Reached EOF")
          observer.next()
          observer.complete()
        }
      }
      this.sliceFile(0, fileToSend, fileReader, offset)
    })
  }

  private sliceFile(fileOffset: number, file: File, fileReader: FileReader, offset: number) {
    if (!this.transmitting) {
      this.transmitting = true
      this.transmittingFile$.next(this.transmitting)
    }
    const slice = file.slice(offset, fileOffset + this.CHUNK_SIZE)
    fileReader.readAsArrayBuffer(slice)
  }

  /**
   * receives a message from another participant
   * @param event
   * @param webRTCConnection
   * @returns
   */
  public receiveChannelMessage(event: any, webRTCConnection: WebRTCConnection) {
    this.consoleService.logDemo('receiveChannelMessage: event = ', event)
    if (typeof event.data === 'string') {
      if (event.data !== this.EOF_MESSAGE) {
        this.parseDataChannelMessage(event)
          .then((messageData: any) => {
            this.consoleService.logDemo('receiveChannelMessage: messageData = ', messageData)
            if (webRTCConnection.type === WebRTCConnectionType.DocTools) {
              switch (messageData.type) {
                case WebRTCChannelDataType.File:
                  const fileData: WebRTCFileMessage = {
                    type: WebRTCChannelDataType.File,
                    createdAt: messageData.time,
                    meta: {
                      name: messageData.filename,
                      createdAt: messageData.time,
                      type: messageData.mimeType,
                      size: messageData.filesize,
                      origin: Origin.Upload,
                      senderRole: messageData.senderRole,
                      capturedRole: messageData.capturedRole,
                      description: messageData.description
                    }
                  }
                  this.consoleService.logDemo('receiveChannelMessage: toolFileData = ', fileData)
                  this.handleMessageData(fileData, webRTCConnection)
                  break;
                case WebRTCChannelDataType.Message:
                  const chatData: WebRTCChatMessage = {
                    createdAt: messageData.time,
                    type: WebRTCChannelDataType.Message,
                    ...messageData
                  };
                  this.consoleService.logDemo('receiveChannelMessage: toolChatData = ', chatData)
                  this.handleMessageData(chatData, webRTCConnection)
                  break;
                default:
                  this.adLoggerService.error('Unknown tool data received', messageData)
              }
            } else {
              this.handleMessageData(<WebRTCChannelMessage>messageData, webRTCConnection)
            }
          })
          .catch((error) => {
            this.adLoggerService.error('receiveChannelMessage: Failed metadata parsing', error)
          })
      }
      if (event.data === this.EOF_MESSAGE) {
        this.consoleService.logDemo('receiveChannelMessage: EOF, this.webRTCTransferData = ', this.webRTCTransferData)
        const partialData: PartialData = this.webRTCTransferData[webRTCConnection.type]
        this.fileFromBuffer(partialData, webRTCConnection.type)
      }
      return
    } else {
      const partialData: PartialData = this.webRTCTransferData[webRTCConnection.type]
      if (partialData && partialData.buffer) {
        const dataBuffer: ArrayBuffer = event.data
        partialData.transferred += dataBuffer.byteLength
        partialData.buffer.push(dataBuffer)
        const webRTCFileProgress: WebRTCFileProgress = {
          webRTCConnectionType: webRTCConnection.type,
          meta: partialData.meta,
          progress: partialData.transferred,
          complete: false,
        }
        this.transmitProgress$.next(webRTCFileProgress)
      }
    }
  }

  public parseDataChannelMessage(event: any) {
    return new Promise((resolve, reject) => {
      try {
        resolve(JSON.parse(event.data));
      } catch (error) {
        reject(error);
      }
    });
  }

  public syncData(webRTCConnection: WebRTCConnection, message: WebRTCResyncMessage, index: number = 0) {
    if (index === 0 && message.resync === WebRTCResync.Chat || message.resync === WebRTCResync.Full) {
      this.chatService.syncChat(webRTCConnection)
    }
    const webRTCFilesKeys: string[] = Object.keys(this.webRTCFiles)
    const numOfWebRTCFiles: number = webRTCFilesKeys.length
    if (message.resync !== WebRTCResync.Chat && numOfWebRTCFiles > 0 && index < numOfWebRTCFiles) {
      const fileNames: string[] = message.fileNames ? message.fileNames : []
      const assetMeta: AssetMeta = this.webRTCFiles[webRTCFilesKeys[index]].meta
      const fileName: string = assetMeta.name
      if (!fileNames.includes(fileName)) {
        this.sendWebRTCFileToPeers([webRTCConnection], assetMeta).subscribe(() =>
          this.syncData(webRTCConnection, message, index + 1)
        )
      }
    }
  }

  private handleMessageData(messageData: WebRTCChannelMessage, webRTCConnection?: WebRTCConnection) {
    this.consoleService.logDemo("handleMessageData: messageData = ", messageData)
    switch (messageData.type) {
      case WebRTCChannelDataType.Magic:
        this.consoleService.logDemo("handleMessageData: magic")
        this.magic$.next(null) // make this participant (Patient, Specialist or Tool (not yet implemented?)) share pre-defined data
        break;
      case WebRTCChannelDataType.File:
        const tempBuffer = [];
        this.webRTCTransferData[webRTCConnection.type] = {
          meta: (<WebRTCFileMessage>messageData).meta,
          buffer: tempBuffer,
          transferred: 0,
        }
        this.consoleService.logDemo("handleMessageData : messageData.type is File. messageData = ", messageData)
        this.consoleService.logDemo("handleMessageData : this.webRTCTransferData[webRTCConnection.type] = ", this.webRTCTransferData[webRTCConnection.type])
        const webRTCFileProgress: WebRTCFileProgress = {
          webRTCConnectionType: webRTCConnection.type,
          progress: 0,
          meta: (<WebRTCFileMessage>messageData).meta,
          complete: false,
        }
        this.consoleService.logDemo("handleMessageData : webRTCFileProgress = ", webRTCFileProgress)

        this.transmitProgress$.next(webRTCFileProgress)
        this.transmitting = true
        this.transmittingFile$.next(true)
        break;
      case WebRTCChannelDataType.Message:
        this.consoleService.logDemo("handleMessageData : messageData.type is Message. messageData = ", messageData)
        this.chatService.receivedChatUpdate([<WebRTCChatMessage>messageData])
        break;
      case WebRTCChannelDataType.Delete:
        this.consoleService.logDemo("handleMessageData : messageData.type is Delete. messageData = ", messageData)
        const indexToDelete = this.webRTCFiles.findIndex(
          (file) => file.meta.name === (<WebRTCDeleteMessage>messageData).meta.name
        )
        if (indexToDelete > -1) {
          this.webRTCFiles.splice(indexToDelete, 1)
          this.webRTCFilesChanged$.next(this.webRTCFiles)
          this.deletedWebRTCFile$.next((<WebRTCDeleteMessage>messageData).meta)
          //call again already deleted
          //todo check next call
          this.deleteWebRTCFile((<WebRTCDeleteMessage>messageData).meta).subscribe()
        }
        break;
      case WebRTCChannelDataType.Coupled:
        this.consoleService.logDemo("handleMessageData : messageData.type is Coupled. messageData = ", messageData)
        this.closeCouplingWindow()
        break;
      case WebRTCChannelDataType.Session:
        this.consoleService.logDemo("handleMessageData : messageData.type is Session. messageData = ", <WebRTCPatientSessionMessage>messageData)
        this.storageService
          .setPatientSession((<WebRTCPatientSessionMessage>messageData).session)
          .subscribe(
            () => { },
            (error) => this.adLoggerService.error(error)
          );
        break;
      case WebRTCChannelDataType.Resync:
        this.consoleService.logDemo("handleMessageData : messageData.type is Resync. messageData = ", messageData)
        this.syncData(webRTCConnection, (<WebRTCResyncMessage>messageData));
        break;
      case WebRTCChannelDataType.ChatSync:
        this.consoleService.logDemo("handleMessageData : messageData.type is ChatResync. messageData = ", messageData)
        const syncedChat = (<WebRTCChatSyncMessage>messageData).chat;
        if (syncedChat && syncedChat.length > 0) {
          this.chatService.receivedChatUpdate(syncedChat)
        }
        break;
      // case WebRTCChannelDataType.Snapshot: // unused
      case WebRTCChannelDataType.Username:
        this.consoleService.logDemo("handleMessageData : messageData.type is Username. messageData = ", messageData)
        this.updateUsernames(<WebRTCUsernameMessage>messageData)
        break;
      case WebRTCChannelDataType.ForceClose:
        this.consoleService.logDemo("handleMessageData : messageData.type is ForceClose. messageData = ", messageData)
        this.forceConnectionClose$.next((<WebRTCCloseMessage>messageData).close)
        break;
      case WebRTCChannelDataType.TrackStatus:
        this.consoleService.logDemo("handleMessageData : messageData.type is TrackStatus. messageData = ", messageData)
        const trackStatusUpdate: WebRTCTrackStatusMessage = Object.assign(
          {},
          <WebRTCTrackStatusMessage>messageData
        )
        trackStatusUpdate.webRTCConnectionType = webRTCConnection.type;
        this.trackStatusChange$.next(trackStatusUpdate)
        this.consoleService.logDemo("handleMessageData: trackStatusUpdate = ", trackStatusUpdate)
        break;
      default:
        this.consoleService.logDemo("handleMessageData : messageData.type unrecognized. messageData = ", messageData)
        break;
    }
  }

  private updateUsernames(messageData: WebRTCUsernameMessage) {
    const names = {};
    names[SocketUserRole.Arzt] = messageData.doctor;
    names[SocketUserRole.Patient] = messageData.patient;
    names[SocketUserRole.Spezialist] = messageData.specialist;
    this.consoleService.logDemo('Updated names: ', names);
    this.updateUsername$.next(names);
  }

  private closeCouplingWindow() {
    this.coupledTool$.next(true);
  }

  private fileFromBuffer(partialData: PartialData, connectionType: WebRTCConnectionType) {
    this.consoleService.logDemo('fileFromBuffer: partialData', partialData)

    this.isTransferingFiles$.next(false)

    const fileBlob: Blob = new Blob(partialData.buffer, { type: partialData.meta.type })
    const newFile = new File([fileBlob], partialData.meta.name);

    /*if (partialData.transferred < partialData.meta.size) {*/ //incomplete/complete files were not recognised correctly
    if (newFile.size < partialData.transferred) {
      this.consoleService.logDemo('fileFromBuffer: File transfer was cancelled')
      this.failedFiles.push(partialData.meta)
      this.completeFileTransfer(connectionType, partialData.transferred)
      if (this.role && this.role === SocketUserRole.Arzt) { //adds asset from session if it exists (e.g. file upload by patient before vss started)
        const sessionAssets = Object.values(this.patientSessionService.activeSession$.value.assets)
        const fileIndex = sessionAssets.findIndex((file) => file.meta?.createdAt === partialData.meta?.createdAt && file.meta?.name === partialData.meta?.name)
        if (fileIndex !== -1) {
          const asset = sessionAssets[fileIndex]
          this.webRTCFiles.push(asset)
          this.webRTCFilesChanged$.next(this.webRTCFiles)
        }
      }
      return
    }

    const fileReader: FileReader = new FileReader()
    fileReader.readAsDataURL(fileBlob)
    fileReader.onload = () => {
      const webRTCFile: WebRTCFile = {
        dataUri: <string>fileReader.result,
        meta: partialData.meta,
      }
      if (!(webRTCFile.meta && webRTCFile.meta.size < this.MAXIMUM_SINGLE_FILE_SAVE_SIZE))
        webRTCFile.syncError = 'TOO_LARGE'

      const fileIndex = this.webRTCFiles.findIndex((file) => file.meta?.createdAt === webRTCFile.meta?.createdAt && file.meta?.name === webRTCFile.meta?.name)
      if (fileIndex != -1) {
        const oldUriSize = this.dataUriHelpers.getDataUriByteSize(this.webRTCFiles[fileIndex].dataUri)
        const newUriSize = this.dataUriHelpers.getDataUriByteSize(webRTCFile.dataUri)
        if (oldUriSize < newUriSize) {
          this.webRTCFiles[fileIndex] = webRTCFile
        }
      } else {
        this.webRTCFiles.push(webRTCFile)
      }

      this.consoleService.logDemo("fileFromBuffer: this.webRTCFiles = ", this.webRTCFiles)
      of(null)
        .pipe(
          mergeMap(() =>
            (this.role && this.role === SocketUserRole.Patient)
              ? this.storageService.addWebRTCFiles([webRTCFile])
              : of(null)
          )
        )
        .subscribe(() => {
          this.webRTCFilesChanged$.next(this.webRTCFiles)
          const receivedFile: WebRTCReceivedFile = {
            filename: partialData.meta.name,
            sender: partialData.meta.senderRole,
            createdAt: partialData.meta.createdAt,
            senderRole: partialData.meta.senderRole,
            connectionType: connectionType,
          }
          this.receivedFile$.next({ fileData: receivedFile, file: newFile })
          this.completeFileTransfer(connectionType, partialData.transferred)
        })
    }
  }

  private completeFileTransfer(
    webRTCConnectionType: WebRTCConnectionType,
    transferred: number
  ) {
    this.consoleService.logDemo('completeFileTransfer webRTCConnectionType = ', webRTCConnectionType)
    this.consoleService.logDemo('completeFileTransfer transferred = ', transferred)
    const webRTCFileProgress: WebRTCFileProgress = {
      webRTCConnectionType,
      meta: this.webRTCTransferData[webRTCConnectionType].meta,
      progress: transferred,
      complete: true,
    }
    this.transmitProgress$.next(webRTCFileProgress)
    this.webRTCTransferData[webRTCConnectionType].buffer = []
    this.transmitting = false
    this.transmittingFile$.next(false)
  }

  public audioChannelMessage(event: any) {
    this.webrtcAudioService.streamPCM(event.data);
  }

  public download(fileIndex: number) {
    // create download anchor element
    const downloadAnchor = document.createElement('a');
    const sharedFile = this.webRTCFiles[fileIndex];
    if (!sharedFile) {
      return this.adLoggerService.error('File does not exist');
    }
    downloadAnchor.href = sharedFile.dataUri
    downloadAnchor.download = sharedFile.meta.name;
    downloadAnchor.target = '_parent';
    (document.body || document.documentElement).appendChild(downloadAnchor);
    downloadAnchor.click();
    downloadAnchor.parentNode.removeChild(downloadAnchor);
  }

  public clear(): Observable<boolean> {
    return new Observable((observer) => {
      this.webRTCFiles = []
      this.failedFiles = []
      this.webRTCFilesChanged$.next(this.webRTCFiles)
      observer.next(true)
      observer.complete()
    })
  }

  public getFileByName(filename: string): WebRTCFile {
    return this.webRTCFiles.find(
      (file) => file.meta && file.meta.name === filename
    );
  }

  /**
   * calculates whether the total file size is greater than the allowed
   * maximum and signals it using a subject to arzt-behandlung-dialog,
   * which the Arzt uses to either close the session or first select
   * which files to keep in case the total file size is overlimit.
   */
  public areWebRTCFilesOverlimit() {
    const webRTCFilesSize: number =
      this.assetService.getSizeOfAssets(this.webRTCFiles)
    const areWebRTCFilesOverlimit: boolean = webRTCFilesSize > this.MAXIMUM_TOTAL_FILES_SAVE_SIZE
      ? true
      : false
    this.areWebRTCFilesOverlimit$.next(areWebRTCFilesOverlimit)
    if (this.isDemo) return areWebRTCFilesOverlimit
  }

  /**
   * Scales Image to lower file size
   */
  public scaleImage(name: string, file?: File): Observable<string> {
    const webRTCFileIndex = this.webRTCFiles.findIndex((webRTCFile) => webRTCFile?.meta.name === name);
    if (webRTCFileIndex === -1) { return; }
    const webRTCFile = this.webRTCFiles[webRTCFileIndex]

    return this.imageHelpersService.scaleBase64Image(webRTCFile.dataUri, [2000, 2000], ImageScaleMode.Contain).pipe(
      mergeMap((newDataUri: string) => {
        const finalSize = atob(newDataUri.split(',')[1]).length

        // const senderRole: SocketUserRole = this.settings.senderRole ? this.settings.senderRole : null
        // const capturedRole: SocketUserRole = this.settings.capturedRole ? this.settings.capturedRole : null
        const meta: AssetMeta = { ...webRTCFile.meta, size: finalSize }
        const newWebRTCFile: WebRTCFile = {
          dataUri: newDataUri,
          meta
        }
        return of({ newWebRTCFile, finalSize })
      }),
      mergeMap(({ newWebRTCFile, finalSize }) => {
        this.webRTCFiles[webRTCFileIndex] = newWebRTCFile
        this.webRTCFilesChanged$.next(this.webRTCFiles)
        const newFileSizeFormatted = this.dataUriHelpers.formatByteSize(finalSize)
        this.scaledWebRTCFile$.next(name)
        return of(newFileSizeFormatted)
      })
    )
  }


  /**
   * causes all participants to share pre-defined assets, chats and notes
   */
  public magic(webRTCConnections) {
    this.magic$.next(null) // causes this participant (the doctor) to share pre-defined data
    // message the other participants to make them share pre-defined data
    const message: WebRTCMagicMessage = {
      createdAt: dayjs().toISOString(),
      type: WebRTCChannelDataType.Magic
    }
    this.sendMessageToPeers(webRTCConnections, message)
  }

  get sendingFile(): boolean {
    return this._sendingFile;
  }
  set sendingFile(status: boolean) {
    this._sendingFile = status;
  }
  get sendQueue(): Observable<any>[] {
    return this._sendQueue;
  }
  set sendQueue(fileQueue: Observable<any>[]) {
    this._sendQueue = fileQueue;
  }
}
