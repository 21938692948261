import { OTKFieldConfig } from "@a-d/entities/Calendar.entity";
import { I18NString } from "@a-d/entities/I18N.entity";

export type ConfigPreset = Omit<Partial<OTKFieldConfig>, "identifier">;

const basicConfigPresets: Array<[string, ConfigPreset]> = [
  ["info", { limit: 200, editableName: true }],
  ["gender", { display: true }],
  ["phone", { display: true }],
  ["address", {}],
  ["customerId", { display: false, required: false }],
  ["emailCC", { editableName: true }],
  ["insuranceName", { limit: 40 }],
  ["insuranceNumber", { limit: 40 }],
  ["confirmTreated", {}],
  ["confirmCorrect", {}],
  ["checkbox3", {}],
  ["checkbox4", {}],
  ["checkbox5", {}],
];

const basicContactConfigPreset: Array<[string, ConfigPreset]> = [
  ["description", { display: true }],
  ["phone", { display: true }],
  ["address", {}],
  ["customerId", { display: true, required: true }],
  ["issue", { display: true, required: true }],
];

export function construct(identifier: string, config: ConfigPreset): OTKFieldConfig {
  const limit = config.limit ?? null;
  const display = config.display ?? false;
  const required = config.required ?? false;
  const editable = config.editable ?? false;
  const editableName = config.editableName ?? null;
  const description: I18NString = {
    en: "MULTILINGUAL-FIELD.NO-TRANSLATION",
    de: "MULTILINGUAL-FIELD.NO-TRANSLATION",
    fr: "MULTILINGUAL-FIELD.NO-TRANSLATION",
    it: "MULTILINGUAL-FIELD.NO-TRANSLATION",
    es: "MULTILINGUAL-FIELD.NO-TRANSLATION",
  };
  return Object.entries({
    identifier,
    limit,
    display,
    required,
    editable,
    editableName,
    description,
  })
    .filter(([_, value]) => value != null)
    .map(([key, value]) => ({ [key]: value }))
    .reduce(
      (acc, val) => Object.assign(acc, val),
      {}
    ) as unknown as OTKFieldConfig;
}

export const defaultBasicConfig: OTKFieldConfig[] = basicConfigPresets.map((args) =>
  construct(...args)
);
export const defaultBasicContactConfig: OTKFieldConfig[] = basicContactConfigPreset.map(
  (args) => construct(...args)
);
