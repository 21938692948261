import { OTKFieldConfig } from '@a-d/entities/Calendar.entity';
import { BaseLanguage } from '@a-d/entities/I18N.entity';
import { CheckboxService } from '@a-d/forms/fields/checkbox/checkbox.service';
import { UntypedFormGroup } from '@angular/forms';
import { ICustomFieldResponse } from '@models/otk-appointment/CustomFieldResponse.model';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { NGXLogger } from 'ngx-logger';
import {
  CheckboxResponse, ContactData, CustomFieldResponse,
  PatientData,
  PatientInsurance,
  PatientInsuranceType,
  PersonalContactData,
  PersonalData
} from '../../entities/Booking.entity';
import { FormAndFields, OtkFieldConfigs } from './booking-personal.d';

// goes to server and tomedo
function getPatientData(f: FormAndFields,
  baseLanguage: BaseLanguage,
  baseLanguageDefault: BaseLanguage,
  zollsoftMode = false): PatientData {
  const customFieldResponses = getCustomFieldsResponses(
    f.formGroup,
    f.customFields,
    baseLanguage,
    baseLanguageDefault
  );
  const birthdate = f.formGroup.get('birthDate')?.value;
  /**
   * birthdate represents the selected date at midnight in local time.
   * Convert this into midnight at UTC.
   * added checks to see whether the three get functions are already defined
   * because this function is sometimes called before they are.
   * todo: define them earlier?
   */
  let birthdateWithoutTime = dayjs().toDate();
  if (!zollsoftMode) {
    birthdateWithoutTime = birthdate != null && birthdate.getFullYear && birthdate.getMonth && birthdate.getDate
      ? new Date(
        Date.UTC(
          birthdate.getFullYear(),
          birthdate.getMonth(),
          birthdate.getDate()
        )
      )
      : null;
  }
  const personal: PersonalData = {
    fname: f.formGroup.get('fname')?.value,
    lname: f.formGroup.get('lname')?.value,
    email: f.formGroup.get('email')?.value,
    emailConfirm: f.formGroup.get('emailConfirm')?.value,
    emailCC: f.formGroup.get('emailCC')?.value,
    birthdate: birthdateWithoutTime,
    preferredLocale: f.languageService.activeBaseLang,
    gender: f.formGroup.get('gender')?.value,
    info: f.formGroup.get('info')?.value,
    phone: f.formGroup.get('phone')?.value,
    street: f.formGroup.get('street')?.value,
    streetNumber: f.formGroup.get('streetNumber')?.value,
    zip: f.formGroup.get('zip')?.value,
    city: f.formGroup.get('city')?.value,
    countryCode: f.formGroup.get('countryCode')?.value,
    customerId: f.formGroup.get('customerId')?.value
  };

  // TODO: ADI-709 Patient Insurance type will be loaded here
  const insuranceType = PatientInsuranceType.UNKNOWN;
  const insuranceName: string = f.formGroup.get('insuranceName')?.value;
  const insuranceNumber: string = f.formGroup.get('insuranceNumber')?.value;
  const insurance: PatientInsurance = {
    type: insuranceType,
    name: insuranceName,
    ...(insuranceNumber ? { number: insuranceNumber } : {})
  };

  const checkboxResponses = getCheckboxResponses(
    f.formGroup,
    f.basicFields,
    f.logger,
    baseLanguageDefault
  );
  const patientData: PatientData = {
    personal,
    insurance,
    customFieldResponses,
    checkboxResponses,
  };

  return patientData;
}

function getContactData(
  f: FormAndFields,
  baseLanguage: BaseLanguage,
  baseLanguageDefault: BaseLanguage): ContactData {
  const personal: PersonalContactData = {
    fname: f.formGroup.get('fname')?.value,
    lname: f.formGroup.get('lname')?.value,
    email: f.formGroup.get('email')?.value,
    gender: f.formGroup.get('gender')?.value,
    info: f.formGroup.get('info')?.value,
    phone: f.formGroup.get('phone')?.value,
    street: f.formGroup.get('street')?.value,
    streetNumber: f.formGroup.get('streetNumber')?.value,
    zip: f.formGroup.get('zip')?.value,
    city: f.formGroup.get('city')?.value,
    countryCode: f.formGroup.get('countryCode')?.value,
    issue: f.formGroup.get('issue')?.value,
    customerId: f.formGroup.get('customerId')?.value
  }
  const customFieldResponses = getCustomFieldsResponses(
    f.formGroup,
    f.customFields,
    baseLanguage,
    baseLanguageDefault
  );
  return {
    personal: personal,
    customFieldResponses
  }
}

function getCheckboxResponses(
  formGroup: UntypedFormGroup,
  basicFields: OtkFieldConfigs,
  logger: NGXLogger,
  baseLanguageDefault: BaseLanguage
): CheckboxResponse[] {
  return [
    "confirmTreated",
    "confirmCorrect",
    "checkbox3",
    "checkbox4",
    "checkbox5"
  ].map(identifier =>
    CheckboxService.getValue(
      formGroup.get(identifier),
      basicFields[identifier],
      logger,
      baseLanguageDefault
    ))
    .filter((v) => v != null);
}

function getCustomFieldsResponses(
  formGroup: UntypedFormGroup,
  customFields: OTKFieldConfig[],
  baseLanguage: BaseLanguage,
  baseLanguageDefault: BaseLanguage
): CustomFieldResponse[] {
  return customFields.map((config) => {
    const mandatory = {
      identifier: config.identifier,
      required: config.required,
    };
    const description: string = config?.description?.[baseLanguage]
      ? config.description[baseLanguage]
      : (config?.description?.[baseLanguageDefault]
        ? config.description[baseLanguageDefault]
        : '')
    const value = formGroup.get(config.identifier)?.value ?? '';
    let customFieldResponse: CustomFieldResponse = {
      identifier: config.identifier,
      required: config.required,
      ...(!!description && { description }),
      ...(!!value && { value })
    }
    return customFieldResponse
  });
}

// used to display the summary
function getData(f: FormAndFields, translate: TranslateService): Object {
  const { personal, insurance, customFieldResponses } = getPatientData(f, translate.currentLang as BaseLanguage, translate.defaultLang as BaseLanguage);
  const translatedType = translate.instant(
    'INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.SHORT-NAME-FORMATTED.UNDOCUMENTED'
  );

  const filteredPersonal = Object.entries(personal)
    .filter(([key, value]) => !['preferredLocale'].includes(key))
    .map(([key, value]) => ({ [key]: value }));

  const customResponses = customFieldResponses
    .filter((response) => ![undefined, null, '', 'info'].includes(response.value))
    // each custom field contains several different fields, but we just want to display
    // two of them, description and value, the former as the name and the latter as the value
    .map((iCustomFieldResponse: ICustomFieldResponse) => {
      const fieldName: string = iCustomFieldResponse.description
      const fieldValue: string = iCustomFieldResponse.value
      return { [fieldName]: fieldValue }
    })
  const infoValue = personal?.info;
  const infoKey = f.basicFields.info.identifier;

  return Object.assign(
    {},
    ...filteredPersonal,
    { [infoKey]: infoValue },
    { insuranceType: translatedType },
    { insuranceName: insurance.name },
    ...customResponses
  );
}

export default { getPatientData, getData, getContactData };
