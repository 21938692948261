import { I18NString } from './I18N.entity';
import { PrivatePaymentHandler } from './InstanceForm.entity';
import { Praxis } from './Praxis.entity';


export enum PatientEmailType {
  SessionJoined = 'joinedSession',
  SessionCompleted = 'completedSession',
  // SessionTerminated = 'terminatedSession',
  AttestSent = 'attestSent'
}


/**
 * Different Data-Types for Different Email-Types
 */

export type PatientEmailData = PatientJoinedEmailData | PatientCompletedEmailData | PatientAttestSentEmailData


export interface PatientJoinedEmailData {
  readonly instanceIdentifier: string
  readonly sessionId: string
}

export interface PatientCompletedEmailData {
  readonly instanceIdentifier: string
  readonly patientName: string
  readonly patientAddress: string
  readonly insuranceType: string
  readonly sessionDate: string
  readonly attest: boolean
  readonly attestPeriod: string
  readonly attestDurationDays: number
  readonly arztName: string
  readonly arztType: string
  readonly praxis: Praxis
  readonly instanceFormTitle: I18NString
  readonly paymentIsRequired: boolean
  readonly paymentPrice: number
  readonly paymentPriceFormatted: string
  readonly paymentHandler: PrivatePaymentHandler
}

export interface PatientAttestSentEmailData {
  readonly instanceIdentifier: string
  readonly attestPeriod: string
  readonly attestDurationDays: number
  readonly arztName: string
  readonly arztType: string
  readonly praxis: Praxis
}
