import { Component, Input, OnInit } from '@angular/core';
import { HomeWhitelabelSectionInterface } from '../home-whitelabel-section.interface';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-whitelabel-section-medipolis',
    templateUrl: './whitelabel-section-medipolis.component.html',
    styleUrls: ['./whitelabel-section-medipolis.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, TranslateModule]
})
export class WhitelabelSectionMedipolisComponent implements OnInit, HomeWhitelabelSectionInterface {

  @Input() mode: 'patient' | 'arzt'

  // public title = 'WHITELABEL-SECTION-MEDIPOLIS.TITLE'
  public subtitle = 'WHITELABEL-SECTION-MEDIPOLIS.SUBTITLE'
  public imageUrl = 'assets/whitelabeling/medipolis/medipolis--colored@2x.png'

  constructor() { }

  ngOnInit(): void {
  }

}
