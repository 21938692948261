<lib-dialog [isLoading]="isLoading" #dialog>
  <div mat-dialog-title>
    <h1>Demo Verschlüsselung</h1>
    <ng-template [ngTemplateOutlet]="dialog.closeButtonRef"></ng-template>
  </div>

  <div mat-dialog-content>
    <form [formGroup]="formGroup">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>X.509 Zertifikat</mat-label>
        <textarea attr.data-selenium-id="textarea-certificate" formControlName='certificate' matInput
          cdkTextareaAutosize mat-autosize=true cdkAutosizeMinRows=7 cdkAutosizeMaxRows=12></textarea>
      </mat-form-field>
      <input attr.data-selenium-id="input-file" #attachmentInput type="file" [multiple]="false">
    </form>
  </div>

  <div mat-dialog-actions>
    <!-- Actions Desktop-->
    <button attr.data-selenium-id="button-encrypt" mat-flat-button (click)="encrypt()" color="primary"
      style="margin-left: auto" [disabled]="!formGroup.valid">Verschlüsseln und
      Anhängen</button>

  </div>
</lib-dialog>