import { ITestData } from '@a-d/devices-tests/devices-tests-dialog.component';
import { Insurance } from '@lib/entities/Insurance.entity';
import { Documentation } from './Documentation.entity';
import { FutureSession } from './FutureSession.entity';
import { Instance } from './Instance.entity';
import { InstanceForm, InstanceFormPrivatePayment } from './InstanceForm.entity';
import { Person } from './Person.entity';
import { PortalCouncilArzt } from './Portal.entity';
import { SocketUserRole } from './Socket.entity';
import { User } from './User.entity';
import { Origin, WebRTCStatisticData } from './WebRTC.entity';


/**
 * Session of a patient
 */
export interface PatientSession {
  _id: string
  readonly createdAt: Date
  state: PatientSessionState // einfach immer = stateHistory[0].state, oder? todo: löschen?
  stateHistory: PatientSessionStateElement[]
  readonly sessionErrors: PatientSessionError[]
  doctor: User
  readonly person: Person
  readonly preferredLocale?: string
  readonly instance: Instance
  readonly rejections: PatientSessionRejectionElement[]
  readonly form: InstanceForm
  readonly preferredDoctor?: PatientSessionPreferredDoctor
  readonly insurance: Insurance
  readonly payment: PatientSessionPayment
  readonly relatedSpecialistSessions?: any
  readonly bill?: PatientSessionBill
  readonly anamnese: any
  documentation: Documentation
  readonly consultation?: Consultation // is this being used? isn't it contained in Documentation? Delete?
  assets: Assets
  encryptedData: string
  encryptedAssets: string
  readonly tomedoUpdateStatus?: PatientSessionTomedoUpdateStatus
  readonly tomedoPatientId?: number
  readonly futureSession?: FutureSession
  url?: string
  messages?: PatientSessionMessage[],
  patientPrivateKey?: string, //only on patient side
  patientPublicKey?: string,
  messagesPatientEncrypted?: string,
  messagesArztEncrypted?: string,
  readonly isCouncil?: boolean,
  readonly issuingDoctor?: PortalCouncilArzt,
  councilDataArrived?: boolean,
  connectionStatistics?: PatientSessionConnectionStatistics,
  version?: number
}

export interface PatientSessionMessage {
  sender: string
  message: string
  createdAt: string //Date as ISOString
  closed?: boolean
  //messageType: PatientSessionMessageType
}

/*export enum PatientSessionMessageType {
  Info = 'info',
  Warning = 'warning',
  Error = 'error'
}*/

export interface Consultation {
  readonly startTime?: string
  readonly endTime?: string
  readonly chat?: string[]
  readonly notes?: string
}


/**
 * PatientSessionError
 */
export interface PatientSessionError {
  readonly type: PatientSessionErrorType
  readonly date: Date
  readonly data?: Object
  readonly count?: Number
}

export enum PatientSessionErrorType {
  SocketDisconnect = 'socketDisconnect',
  DocumentationTimeout = 'documentationTimeout',
  ActiveTimeout = 'activeTimeout',
  UnsentAttest = 'unsentAttest',
  PaypalCapturePaymentError = 'paypalCapturePaymentError',
  PaypalVoidPaymentError = 'paypalVoidPaymentError',
  PappError = 'pappError',
  IssueSessionBillError = 'issueSessionBillError',
}


/**
 * Doctors (personal) rejection of a specific session
 */
export interface PatientSessionRejectionElement {
  readonly date: Date
  doctor: string
  readonly reason: string
}


/**
 * State of session
 */
export enum PatientSessionState {
  Waiting = 'waiting',
  Picked = 'picked',
  Pending = 'pending',
  Active = 'active',
  Documenting = 'documenting',
  Completed = 'completed',
  Halted = 'halted',
  Cancelled = 'cancelled'
}


/**
 * History-Element of each state which is stored within 'stateHistory' (sorted by 'date').
 */
export interface PatientSessionStateElement {
  readonly date: Date
  readonly state: PatientSessionState
  readonly data?: PatientSessionStateData
  readonly userId?: string
}


/**
 * Data send/saved with certain session-states (e.g. cancel-reason or VSS-room)
 */
export type PatientSessionStateData = WaitingStateData | PickedStateData | PendingStateVssData | HaltedStateData | CancelledStateData | CompletedStateData

export interface WaitingStateData {
  readonly reason?: WaitingStateReason
}

export interface PickedStateData {
  readonly doctorId: string
  readonly doctorName: string
  readonly doctorInitials: string
  readonly praxisName: string
}

export interface PendingStateVssData {
  readonly vssUrl: string
  readonly vssRoom: string
}

export interface HaltedStateData {
  readonly reason: HaltedStateReason
  readonly message?: string
}

export interface CancelledStateData {
  readonly reason: HaltedStateReason | CancelledStateReason
  readonly message?: string,
}

export interface CompletedStateData {
  readonly aborted?: boolean
}


/**
 * Reasons for some states which are stored in the belonging PatientSessionStateElement
 */
export enum WaitingStateReason {
  DoctorIdle = 'idle',
}

export enum HaltedStateReason {
  PendingTimeout = 'timeout',
  ConnectionError = 'connection',
  DoctorQuit = 'doctor-quit',
  Idle = 'idle',
}

export enum CancelledStateReason {
  PendingTimeout = 'timeout',           // Too often in state 'halted' with reason 'PendingTimeout'
  ConnectionError = 'connection',       // Too often in state 'halted' with reason 'ConnectionError'
  Idle = 'idle',                        // Too often in state 'halted' with reason 'Idle'
  DoctorQuit = 'doctor-quit',           // Too often in state 'halted' with reason 'DoctorQuit'

  Patient = 'patient',                  // Session cancelled by patient itself
  Authentication = 'authentication',    // Doctor couldn't authenticate patient (e.g. photo wrong)

  TomedoFacharzt = 'tomedoFacharzt',    // only for councils
  TomedoDeclined = 'tomedoDeclined'     // Doctor rejected patient in tomedo
}


/**
 * Assets of PatientSession
 */
export type Assets = {
  authPhoto?: Asset // NamedAsset.AuthPhoto
  KVKPhoto?: Asset // NamedAsset.KVKPhoto
  [_: string]: Asset
}

export enum NamedAsset {
  AuthPhoto = 'authPhoto',
  KVKPhoto = 'kvkPhoto',
}

export enum NamedAssetDescription {
  AuthPhoto = 'Authentifizierungs-Foto',
  KVKPhoto = 'Krankenversicherungskarte-Foto'
}

export interface Asset {
  readonly dataUri: string                    // data url in base64
  readonly meta: AssetMeta                    // various information
}

export interface AssetMeta {
  readonly name: string
  readonly createdAt: string                  // file added time
  readonly type: string                       // mime type
  readonly size: number                       // file size in B
  readonly origin: Origin                     // aufnahme/upload/snapshot
  readonly senderRole?: SocketUserRole        // wer hat die Datei geschickt
  readonly capturedRole?: SocketUserRole      // was wurde aufgenommen
  readonly description: string
}


/**
 * Payment
 */
export interface PatientSessionPayment extends InstanceFormPrivatePayment {
  readonly paypal?: PatientSessionPaymentPaypal,
}

export interface PatientSessionPaymentPaypal {
  readonly refunded: boolean
  readonly voided: boolean
  readonly captured: boolean
  readonly orderId: string
  readonly payerId: string
  readonly authorizationId: string
}

export interface PatientSessionPaymentBill {
  readonly issued: boolean
  readonly issuedAt: Date
  readonly sentToPatient: boolean
  readonly sentToTomedo: boolean
  readonly encryptedPdf: string
}

export enum PatientSessionPaymentStatus {
  NoPayment = 'Keine authorisierte Zahlung gefunden',
  Authorized = 'Autorisiert (Einzug ausstehend)',
  Refunded = 'Zurückerstattet',
  Voided = 'Storniert',
  Captured = 'Eingezogen',
}


/**
 * PatientSessionBill
 */

export interface PatientSessionBill {
  readonly type: BillType.SessionBill
  readonly number: number
  readonly date: Date

  readonly session: PatientSession
  readonly doctor: User
  readonly instance: Instance

  readonly sessionDate: Date
  readonly paypalOrderId: string

  readonly encryptedPdf: string
  readonly adminEncryptedPdf: string

  readonly sentToPatient: boolean
  readonly sentToBuchhaltung: boolean
  readonly sentToTomedo: boolean

  // decrypted pdf populated by client
  readonly pdf: string
}

export enum BillType {
  SessionBill = "SessionBill",
  PraxisBill = "PraxisBill",
  PraxisPayout = "PraxisPayout",
}


/**
 * PatientSessionPreferredDoctor
 */

export interface PatientSessionPreferredDoctor {
  readonly _id: string
  readonly fullName: string
}


/**
 * PatientSessionTomedoUpdateStatus
 */

export enum PatientSessionTomedoUpdateStatus {
  unsent = "unsent",
  sent = "sent",
  acknowledged = "acknowledged",
}

/**
 * On status updates, this seems to be the standard answer format
 */
export interface PatientSessionStatusUpdateResult {
  status: number,
  stateElement: PatientSessionStateElement | undefined
}

export type DatabaseQuery = {
  limit: number,
  select: string[],
  query: string
}

export interface SessionAndActiveSessionFromServerResult {
  session: PatientSession,
  activeSession: PatientSession
}

export interface PersonalDataResponse {
  sessionId: string,
  data: {
    encryptedData: string | undefined,
    encryptedAssets: string | undefined
  } | undefined
}


export interface PatientSessionConnectionStatistics {
  browserDoctor?: string,
  browserPatient?: string,
  candidateTypeDoctor?: string[],
  candidateTypePatient?: string[],
  jitterAudioDoctor?: WebRTCStatisticData,
  jitterVideoDoctor?: WebRTCStatisticData,
  jitterAudioPatient?: WebRTCStatisticData,
  jitterVideoPatient?: WebRTCStatisticData,
  rttAudio?: WebRTCStatisticData,
  rttVideo?: WebRTCStatisticData,
  averageRtt?: WebRTCStatisticData,
  bitrateIn?: WebRTCStatisticData,
  bitrateOut?: WebRTCStatisticData,
  packetLossAudio?: WebRTCStatisticData,
  packetLossVideo?: WebRTCStatisticData,
  patientTests?: ITestData
}
