<mat-stepper class="c-stepper otk-stepper" [ngClass]="isHeaderOnly ? 'mat-horizontal-stepper--header-only' : ''" linear
  #stepper>
  <!-- Custom Icons -->
  <ng-template matStepperIcon="edit" let-index="index">{{index+1}}</ng-template>
  <ng-template matStepperIcon="error" let-index="index">
    <fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
  </ng-template>
  <!-- "pre-steps" -->
  <!--  these steps are each shown separately and without the stepper header.
          "!isHeaderOnly" prevents components from loading for the stepper header
          "arePrestepsCompleted$" hides the step from the stepper content as well
            (otherwise the stepper header, which becomes visible after the pre steps are completed,
            will contain different steps than the stepper content, and thus navigation via
            the stepper header will not work as expected)
   -->
  <!-- pre-step 1: Info -->
  <mat-step [label]="tabTitles.BOOKING_INFO" completed="true" *ngIf="!isHeaderOnly && !arePrestepsCompleted">
    <app-booking-info id="booking-info" *ngIf="!isHeaderOnly"></app-booking-info>
  </mat-step>
  <!-- pre-step 2: Known Patient & Insurance -->
  <mat-step [label]="tabTitles.BOOKING_PRELIMINARY" completed="false" *ngIf="!isHeaderOnly && !arePrestepsCompleted">
    <app-booking-preliminary id="booking-preliminary" *ngIf="!isHeaderOnly"></app-booking-preliminary>
  </mat-step>
  <!-- stepper steps -->
  <!-- these steps are actually shown as part of a stepper including a stepper header -->
  <!-- step 1: Betriebsttaeten -->
  <!-- (completed set to "true" because "all" is selected by default.
    I would have expected this to not matter because of the subscription in booking.service,
    which sets completed automatically, but it does. Without this, the "Weiter" button in type doesn't work
    if bs is given as a parameter. Instead, one needs to click on back and then on forward. -->
  <mat-step [label]="tabTitles.BOOKING_BS" completed="true" *ngIf="arePrestepsCompleted && showBs">
    <app-booking-bs id="booking-bs" *ngIf="!isHeaderOnly"></app-booking-bs>
  </mat-step>
  <!-- step 2: Terminart -->
  <mat-step [label]="tabTitles.BOOKING_TYPE" completed="false" *ngIf="arePrestepsCompleted && showType">
    <app-booking-type id="booking-type" *ngIf="!isHeaderOnly"></app-booking-type>
  </mat-step>
  <!-- step 3: Datum Multi -->
  <mat-step [label]="tabTitles.BOOKING_DATE_MULTI" completed="false"
    *ngIf="arePrestepsCompleted && !isContactFormSelected && (seriesMode | async)" #bookingDateMultiStep>
    <ng-template matStepLabel>
      <ng-container *ngIf="!(automaticSeriesSelection | async)">{{ 'OTK.BOOKING-SUMMARY.SELECTED-APPOINTMENT-SERIES' |
        translate }} {{ (chainIndex | async) +
        1}}/{{bookingService.chainLength}}</ng-container>
      <ng-container *ngIf="(automaticSeriesSelection | async)">{{ 'OTK.BOOKING-SUMMARY.SELECTED-APPOINTMENT-SERIES' |
        translate }}</ng-container>
    </ng-template>
    <app-booking-date-multi id="booking-date" *ngIf="!isHeaderOnly"></app-booking-date-multi>
  </mat-step>
  <!-- step 3: Datum -->
  <mat-step [label]="tabTitles.BOOKING_DATE" completed="false"
    *ngIf="arePrestepsCompleted && !isContactFormSelected && !(seriesMode | async)">
    <app-booking-date id="booking-date" *ngIf="!isHeaderOnly"></app-booking-date>
  </mat-step>
  <!-- step 4 or hidden: Anamnese Formular -->
  <!-- note how the flag here is at the mat-step level to potentially hide the step -->
  <mat-step [label]="tabTitles.BOOKING_ANAMNESE" completed="false" *ngIf="arePrestepsCompleted && showBookingAnamnese">
    <app-booking-anamnese id="booking-anamnese" *ngIf="!isHeaderOnly" [isHeaderOnly]="isHeaderOnly">
    </app-booking-anamnese>
  </mat-step>

  <!-- step 4 or 5: Persönliche Daten -->
  <mat-step [label]="tabTitles.BOOKING_PERSONAL" completed="false" *ngIf="arePrestepsCompleted">
    <app-booking-personal id="booking-personal" *ngIf="!isHeaderOnly" #personalFormComponent></app-booking-personal>
  </mat-step>

  <!-- step 5 or 6: Zusammenfassung -->
  <mat-step [label]="tabTitles.BOOKING_SUMMARY" completed="false" *ngIf="arePrestepsCompleted">
    <app-booking-summary id="booking-summary" *ngIf="!isHeaderOnly"></app-booking-summary>
  </mat-step>

  <mat-step [label]="tabTitles.BOOKING_PAYMENT" completed="false" *ngIf="arePrestepsCompleted && showPayment">
    <app-booking-payment id="booking-payment" *ngIf="!isHeaderOnly"></app-booking-payment>
  </mat-step>
</mat-stepper>