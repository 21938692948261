import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { UsefulComponent } from '../../misc/useful.component';
import { PasswordFieldComponent } from './password-field.component';


@Component({
    selector: 'app-new-password-field',
    template: `
  <app-password-field name="password" [placeholder]="placeholder" [formGroup]="formGroup" [required]="required" [strengthValidation]="true">
  </app-password-field>

  <app-password-field name="repeatPassword" [placeholder]="placeholder + this.translate.instant('PASSWORD-FIELD.REPEAT')" [formGroup]="formGroup" [required]="required" matchField="password">
  </app-password-field>
  `,
    standalone: true,
    imports: [PasswordFieldComponent, FormsModule, ReactiveFormsModule]
})
export class NewPasswordFieldComponent extends UsefulComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup
  @Input() placeholder: string
  @Input() required: boolean

  public get password(): AbstractControl { return this.formGroup.get('password'); }
  public get repeatPassword(): AbstractControl { return this.formGroup.get('repeatPassword'); }

  constructor(
    public translate: TranslateService
  ) {
    super()
  }

  ngOnInit() {
    super.ngOnInit()

    // // Set validators for password-fields
    // const passwordValidators = [ this.formValidators.getPasswordStrengthValidator(this.required) ]
    // if (this.required) passwordValidators.push(Validators.required)
    // this.password.setValidators(passwordValidators)
    // this.password.updateValueAndValidity()

    // const repeatPasswordValidators = [ this.formValidators.getFieldsMatchValidator('password') ]
    // if (this.required) repeatPasswordValidators.push(Validators.required)
    // this.repeatPassword.setValidators(repeatPasswordValidators)
    // this.repeatPassword.updateValueAndValidity()

    // Update Error.Message when value of password-field updates
    this.password.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.repeatPassword.updateValueAndValidity()
    })
  }

}
