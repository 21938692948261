import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { Injectable } from '@angular/core';
import { FormHelpers } from '../../../forms/form-helpers.service';
import { InstanceFormSettingsInterface } from '../../../instance-form/instance-form-component.interface';


@Injectable({
  providedIn: 'root'
})
export class InstanceSettingsFormsService {

  constructor(
    private adLoggerService: AdLoggerService,
    private formHelpers: FormHelpers,
  ) { }


  /**
   * Gathers and returns merged settings-data for given `settingsComponent`.
   */
  public getMergedData(settingsComponent: InstanceFormSettingsInterface, returnCompleteSettings?: boolean): any {
    if (!settingsComponent?.formGroup) {
      this.adLoggerService.error(settingsComponent, "InstanceSettingsFormsService/getMergedData: Given component either doesn't exist or has no 'formGroup'.")
      return {}
    }

    const newSettings = {}
    const defaultSettings = settingsComponent.defaultSettings || {}
    const formData = this.formHelpers.trimmedRawValue(settingsComponent.formGroup) || {}
    const allKeys = [...Object.keys(defaultSettings), ...Object.keys(formData)]
    for (const key of allKeys) {
      const didChange = JSON.stringify(defaultSettings[key]) !== JSON.stringify(formData[key])
      const isSet = Object.keys(formData).includes(key)
      if ((didChange && isSet) || returnCompleteSettings) {
        const value = formData[key] ?? null
        newSettings[key] = JSON.parse(JSON.stringify(value))
      }
    }

    return newSettings
  }

}
