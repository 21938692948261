<ng-container *rxLet="languagePicker.locales$; let locales">
  @if (locales.length > 0) {
    <span class="language-picker">
      <button
        class="menu-icon__button"
        [matMenuTriggerFor]="langMenu"
        matTooltip="{{ 'Language' | translate }}"
        data-selenium-id="button-@language-picker/language"
        aria-label="Language selection"
      >
        <mat-icon [inline]="true">language</mat-icon>
      </button>
      <mat-menu
        #langMenu="matMenu"
        data-selenium-id="mat-menu-@language-picker/lang-menu"
      >
        <ng-container *ngFor="let lang of locales; let i = index">
          <button
            mat-menu-item
            (click)="changeLanguage(lang)"
            [style.fontWeight]="lang === localeControl.value ? '600' : 'normal'"
          >
            {{ lang | translateWithPrefix: 'WFA.LANGUAGES' }}
          </button>
        </ng-container>
      </mat-menu>
    </span>
  }
</ng-container>
