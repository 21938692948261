
import { LanguageService } from '@a-d/i18n/language.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import relativeTime from 'dayjs/plugin/relativeTime';
import { CancelledStateData, CancelledStateReason, PatientSession, PatientSessionErrorType, PatientSessionState, PatientSessionStateElement } from '../entities/PatientSession.entity';
import { Gender, Person } from '../entities/Person.entity';
import { InsuranceService } from '../insurance/insurance.service';
import { ColorUtilsService } from '../misc/color-utils.service';




@Injectable({
  providedIn: 'root'
})
export class PatientSessionFormattersService {

  constructor(
    private colorUtils: ColorUtilsService,
    private insuranceService: InsuranceService,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {
    dayjs.extend(relativeTime)
    dayjs.locale('de')
  }

  /* State */

  private sessionStateTerminator(stateHistory: PatientSessionStateElement[]): string {
    const cancelledState = stateHistory?.[0]
    if (!cancelledState || cancelledState.state !== PatientSessionState.Cancelled) return ''

    const cancelledReason = (stateHistory[0].data as CancelledStateData)?.reason
    switch (cancelledReason) {
      case CancelledStateReason.Patient: return this.translate.instant('PATIENT-SESSION.BY-PATIENT')
      case CancelledStateReason.Authentication: return this.translate.instant('PATIENT-SESSION.BY-DOCTOR')
      case CancelledStateReason.TomedoDeclined: return this.translate.instant('PATIENT-SESSION.BY-DOCTOR')
      case CancelledStateReason.TomedoFacharzt: return this.translate.instant('PATIENT-SESSION.BY-DOCTOR')
      default: return this.translate.instant("PATIENT-SESSION.BY-SYSTEM")
    }
  }

  public stateFormatted(session: PatientSession) {
    switch (session.state) {
      case PatientSessionState.Waiting: return this.translate.instant("PATIENT-SESSION.WAITING")
      case PatientSessionState.Picked: return this.translate.instant("PATIENT-SESSION.PICKED")
      case PatientSessionState.Pending: return this.translate.instant("PATIENT-SESSION.PENDING")
      case PatientSessionState.Active: return this.translate.instant("PATIENT-SESSION.ACTIVE")
      case PatientSessionState.Halted: return this.translate.instant("PATIENT-SESSION.HALTED")
      case PatientSessionState.Cancelled: return this.translate.instant("PATIENT-SESSION.CANCELLED") + `${this.sessionStateTerminator(session.stateHistory)}`.trim()
      case PatientSessionState.Documenting: return this.translate.instant("PATIENT-SESSION.DOCUMENTING")
      case PatientSessionState.Completed: return this.translate.instant("PATIENT-SESSION.COMPLETED")
      default: return session.state
    }
  }

  public stateTextStyle(session: PatientSession) {
    let color
    switch (session.state) {
      case PatientSessionState.Halted: color = [249, 168, 37]; break;     // orange
      case PatientSessionState.Cancelled: color = [183, 28, 28]; break;   // red
      // case PatientSessionState.Completed: color = [100, 221, 24]; break;  // green
      default: color = null
    }

    const colorValue = color ? `rgb(${color[0]}, ${color[1]}, ${color[2]})` : null
    return {
      color: colorValue,
      '-webkit-text-fill-color': colorValue,
      // 'font-weight': '600',
    }
  }


  /* Session-Errors */

  public sessionErrorDescription(type: PatientSessionErrorType) {
    switch (type) {
      case PatientSessionErrorType.SocketDisconnect: return this.translate.instant("PATIENT-SESSION.ERROR-SOCKET-DISCONNECT")
      case PatientSessionErrorType.DocumentationTimeout: return this.translate.instant("PATIENT-SESSION.ERROR-DOCUMENTATION-TIMEOUT")
      case PatientSessionErrorType.ActiveTimeout: return this.translate.instant("PATIENT-SESSION.ERROR-ACTIVE-TIMEOUT")
      case PatientSessionErrorType.UnsentAttest: return this.translate.instant("PATIENT-SESSION.ERROR-UNSENT-ATTEST")
      case PatientSessionErrorType.PaypalCapturePaymentError: return this.translate.instant("PATIENT-SESSION.ERROR-PAYPAL-CAPTURE")
      case PatientSessionErrorType.PaypalVoidPaymentError: return this.translate.instant("PATIENT-SESSION.ERROR-PAYPAL-VOID")
      case PatientSessionErrorType.PappError: return this.translate.instant("PATIENT-SESSION.ERROR-PAPP")
      case PatientSessionErrorType.IssueSessionBillError: return this.translate.instant("PATIENT-SESSION.ERROR-ISSUE-SESSION-BILL")
      default: return ''
    }
  }


  /* Waiting Time */

  public waitingTimeInWords(session: PatientSession) {
    if (!('createdAt' in session)) return ''
    if (!dayjs(session.createdAt).isValid()) return ''

    return this.languageService.activeLang.startsWith('de') ? (<any>dayjs(session.createdAt)).fromNow(true) : (<any>dayjs(session.createdAt).locale('en')).fromNow(true)
  }


  public waitingTimeMinutes(session: PatientSession) {
    if (!('createdAt' in session)) return -1
    if (!dayjs(session.createdAt).isValid()) return -1

    return dayjs().diff(dayjs(session.createdAt), 'minute')
  }


  public waitingTimeFormatted(session: PatientSession) {
    if (!('createdAt' in session)) return ''
    if (!dayjs(session.createdAt).isValid()) return ''

    let waitingTime = dayjs(dayjs().valueOf() - dayjs(session.createdAt).valueOf())
    waitingTime = waitingTime.subtract((<any>dayjs()).utcOffset(), 'minute')

    return waitingTime.format(waitingTime.hour() ? 'H:mm:ss' : 'm:ss')
  }


  public waitingTimeTextStyle(session: PatientSession) {
    const waitingMinutes = this.waitingTimeMinutes(session)
    if (waitingMinutes < 20) return {}

    const orange = [249, 168, 37]
    const red = [183, 28, 28]
    const interpolationFactor = (waitingMinutes - 40) / 40.0
    const color = this.colorUtils.interpolateHSL(orange, red, interpolationFactor)


    return {
      color: `rgb(${color[0]}, ${color[1]}, ${color[2]})`
    }
  }


  /* Session-Expiration (Archiving) */

  public expiresInTextStyle(expiresAt: Date) {
    const expiration = dayjs(expiresAt)
    const expiresInDays = expiration.diff(dayjs(), 'day')
    if (expiresInDays >= 13) return {}

    const orange = [249, 168, 37]
    const red = [183, 28, 28]
    const interpolationFactor = (expiresInDays - 7) / 7.0
    const color = this.colorUtils.interpolateHSL(red, orange, interpolationFactor)

    return {
      color: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
      fontWeight: '600'
    }
  }


  /* Personal */

  public patientFormatted(session: PatientSession) {
    const fullName = this.fullName(session.person)
    const age: number = +session?.person?.age

    if (fullName) {
      return `${fullName} | ${age} J`
    } else {
      return age + (age === 1 ? ' Jahr' : ' Jahre')
    }
  }


  public insuranceFormatted(session: PatientSession) {
    if (!session.insurance) return ''

    const insurance = this.insuranceService.mediumNameFormatted(session.insurance.insuranceType)
    const name = session.insurance.insuranceName
    return name ? `${insurance} (${name})` : insurance
  }

  public fullName(person: Person, shortFormat?: boolean, skipFName?: boolean) {
    if (!person) return ''
    if (!person.fname && !person.lname) return ''
    const nameTitle = person.nameTitle
    const nameAffix = person.nameAffix
    let result = nameAffix
      ? `${nameAffix} ${person.lname}`.trim()
      : `${person.lname}`.trim()
    result = nameTitle
      ? `${nameTitle} ${person.fname} ${result}`.trim()
      : `${person.fname} ${result}`.trim()
    if (shortFormat) {
      result = `${nameTitle} ${person.fname.charAt(0)}. ${person.lname}`.trim()
    }
    if (skipFName) {
      result = `${nameTitle} ${person.lname}`.trim()
    }
    return result
  }

  public initials(session: PatientSession) {
    if (!session || !session.person) return ''
    if (!session.person.fname && !session.person.lname) return ''

    const fLetter: string = session?.person?.fname?.substring(0, 1) ? session.person.fname.substring(0, 1) : ''
    const lLetter: string = session?.person?.lname?.substring(0, 1) ? session.person.lname.substring(0, 1) : ''
    return `${fLetter} ${lLetter}`.trim()
  }


  public age(session: PatientSession) {
    if (!session.person) return ''
    if (!('age' in session.person)) return ''

    const jahreString = session.person.age >= 2 ? ' ' + this.translate.instant('PATIENT-SESSION.YEARS') : this.translate.instant('PATIENT-SESSION.YEAR')
    return session.person.age + jahreString
  }


  public birthDate(session: PatientSession) {
    if (!session.person) return ''
    if (!('birthDate' in session.person)) return ''
    if (!dayjs(session.person.birthDate).isValid()) return ''

    const date = dayjs(session.person.birthDate)
    const dateString = date.format('DD.MM.YYYY')
    const age = session.person.age

    return `${dateString} (${age} ${this.translate.instant('PATIENT-SESSION.YEARS')})`
  }


  public address(session: PatientSession) {
    if (!session?.person) return ''
    const person = session.person

    const addressExists = !!(`${person.street || ''} ${person.streetNumber || ''} ${person.zip || ''} ${person.city || ''} ${person.country || ''}`.trim())
    if (!addressExists) return ''

    let address = `${person.street || ''} ${person.streetNumber || ''}`.trim()
    if (address && person.address_2) address += ', '
    if (person.address_2) address += person.address_2

    let cityAndZipAndCountry = `${person.zip || ''} ${person.city || ''}`.trim()
    if (cityAndZipAndCountry && person.country) cityAndZipAndCountry += ', '
    if (person.country) cityAndZipAndCountry += person.country

    if (address && cityAndZipAndCountry) return `${address}, ${cityAndZipAndCountry}`.trim()
    else if (address) return address.trim()
    else if (cityAndZipAndCountry) return cityAndZipAndCountry.trim()
  }


  public gender(session: PatientSession) {
    if (!session.person) return ''
    if (!('gender' in session.person)) return ''
    if (session.person.gender.toUpperCase() === 'M') return this.translate.instant('PATIENT-SESSION.MALE')
    if (session.person.gender.toUpperCase() === 'W') return this.translate.instant('PATIENT-SESSION.FEMALE')
    if (session.person.gender.toUpperCase() === 'D') return this.translate.instant('PATIENT-SESSION.DIVERSE')
    return ''
  }


  public genderIcon(session: PatientSession) {
    if (!session.person) return ['far', 'genderless']
    if (!('gender' in session.person)) return ['far', 'genderless']
    if (session.person.gender.toUpperCase() === 'M') return ['far', 'mars']
    if (session.person.gender.toUpperCase() === 'W') return ['far', 'venus']
    if (session.person.gender.toUpperCase() === 'D') return ['far', 'transgender']
    return ['far', 'genderless']
  }


  public expirationDate(session: PatientSession) {
    if (!session.insurance?.expirationDate) return ''
    if (!dayjs(session.insurance.expirationDate).isValid()) return ''

    const date = dayjs(session.insurance.expirationDate)
    const dateString = date.format('DD.MM.YYYY')

    return `${dateString}`
  }


  /* Avatar */

  public avatarFilename(session: PatientSession): string {
    const DELIMITER = [0, 6, 18, 26, 36, 46, 66]
    const age = session.person.age
    const gender: Gender = session?.person?.gender ? session.person.gender : Gender.D

    if (!['M', 'W', 'D'].includes(gender)) return null

    for (const [index, d] of DELIMITER.entries()) {
      if (age >= d && index !== DELIMITER.length - 1 && age < DELIMITER[index + 1]) {
        const dNext = DELIMITER[index + 1] - 1
        return `${gender}_${d}_${dNext}`
      } else if (age >= d && index === DELIMITER.length - 1) {
        return `${gender}_${d}+`
      }
    }

    return null
  }


  /* Documentation */

  // The translation is moved to EmailTranslateService in vss-server
  public attestDurationFormatted(attestDurationDays: number) {
    if (!attestDurationDays) attestDurationDays = 0

    const attestDurationDaysUnit = (attestDurationDays === 1) ? this.translate.instant('PATIENT-SESSION.DAY') : this.translate.instant('PATIENT-SESSION.DAYS')
    return `${attestDurationDays} ${attestDurationDaysUnit}`
  }

  public attestPeriodFormatted(attestStartDate: Date, attestEndDate: Date, includeYear?: boolean, forceLongFormat?: boolean) {
    if (!attestStartDate || !attestEndDate) return ''

    const startDate = dayjs(attestStartDate)
    const endDate = dayjs(attestEndDate)
    if (!startDate.isValid() || !endDate.isValid()) return ''

    let startYear = includeYear ? startDate.format('YY') : ''
    let endYear = includeYear ? endDate.format('YY') : ''

    let attestPeriodFormatted
    if (!startDate.diff(endDate, 'day') && !forceLongFormat) {
      attestPeriodFormatted = `${endDate.format('DD.MM.')}${startYear}`
    } else if (startDate.month() !== endDate.month() && !startDate.diff(endDate, 'year')) {
      attestPeriodFormatted = `${startDate.format('DD.MM.')}–${endDate.format('DD.MM.')}${startYear}`
    } else {
      attestPeriodFormatted = `${startDate.format('DD.MM.')}${startYear}–${endDate.format('DD.MM.')}${endYear}`
    }
    // } else if (!startDate.diff(endDate, 'year') && includeYear && !forceLongFormat) {
    //   attestPeriodFormatted = `${startDate.format('DD.MM.')}–${endDate.format('DD.MM.')}${startYear}`
    // } else {
    //   attestPeriodFormatted = `${startDate.format('DD.MM.')}${startYear}–${endDate.format('DD.MM.')}${endYear}`
    // }
    return attestPeriodFormatted
  }

  /* Table-Columns Icons */
  public stateIcon(state: string) {
    const icons = {
      waiting: 'user-clock', // patient in waiting room
      picked: 'user-check', // patient selected by doctor
      pending: 'user-cog', // timer running as doctor waits for patient to accept the call
      active: 'user-headset', // patient and doctor in session together
      documenting: 'user-edit', // session's been willfully terminated + doctor processes notes/documents
      completed: 'check-square', // session complete. Patient is gone. Documents have been processed
      halted: 'user-times', // patient did not respond to doctor's call/doctor cancelled session before start
      cancelled: 'user-slash', // no session has occured + patient no longer connected (left/lost connection)
    }
    return icons[state] ?? 'archive'
  }

}
