<div>
  <!-- instructions -->
  <div class="title" style="text-align: center">
    <span *ngIf="instructions">
      <b>{{ instructions }}</b>
    </span>
  </div>
  <!-- anamnese form -->
  <div [ngClass]="isMobile ? '' : 'desktop'">
    <ng-template #anamneseFormHost></ng-template>
  </div>
</div>
