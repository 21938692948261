import { UntypedFormControl, ValidationErrors } from "@angular/forms"
import slugify from "@sindresorhus/slugify"

/**
 * Slug validation
 * https://developer.mozilla.org/en-US/docs/Glossary/Slug
 * 
 * @remark
 * Do NOT use this function directly for code outside of `validators/`,
 * use `ADValidators.slug` instead.
 */
export function slugValidator(control: UntypedFormControl): ValidationErrors {
  const errorValue = { value: control.value }

  if (control.value && slugify(control.value) !== control.value) {
    return { slugInvalid: errorValue }
  }

  return null
}
