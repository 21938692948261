import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsefulComponent } from '../../../../../misc/useful.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


@Component({
    selector: 'app-custom-anamnese-form-settings-item',
    host: { 'class': 'c-form__row__item--stacked' },
    templateUrl: './custom-anamnese-form-settings-item.component.html',
    styleUrls: ['./custom-anamnese-form-settings-item.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        MatButtonModule,
        MatTooltipModule,
        FontAwesomeModule,
        MatCheckboxModule,
    ],
})
export class CustomAnamneseFormSettingsItemComponent extends UsefulComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup

  @Output() remove = new EventEmitter()

  public get title(): AbstractControl { return this.formGroup.get('title') }
  public get type(): AbstractControl { return this.formGroup.get('type') }
  public get isRequired(): AbstractControl { return this.formGroup.get('isRequired') }
  public get options(): AbstractControl { return this.formGroup.get('options') }


  constructor() {
    super()
  }

  ngOnInit() {
    super.ngOnInit()
  }

}
