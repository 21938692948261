import Joi from 'joi'

import { WfaQuestionValidatorJoi } from '../wfb/wfa-question-validator.joi'
import { BaseQuestionJoi } from './base-question.joi'
import { example } from './examples/wfa-comment-question.joi.example'

export const WfaCommentQuestionJoi = BaseQuestionJoi.keys({
  type: Joi.string().valid('comment').required(),
  validators: Joi.array().items(WfaQuestionValidatorJoi),
})
  .meta({
    className: 'WfaCommentQuestion',
  })
  .example(example)
