import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { AppointmentErrors } from '@a-d/entities/Booking.entity';
import { ColorService } from '@a-d/theming/service/color.service';
import { WrapperOTKComponent } from '@a-d/wrapper-otk/wrapper-otk.component';
import { NgClass, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'lib';
import { BookingService } from '../booking.service';

@Component({
  selector: 'app-booking-cancel',
  standalone: true,
  imports: [
    WrapperOTKComponent,
    NgIf,
    NgClass,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
  ],
  templateUrl: './booking-cancel.component.html',
  styleUrls: ['./booking-cancel.component.scss']
})
export class BookingCancelComponent {
  public isLoading = false
  public accessCode: string
  public canCancel = true
  public statusError: string
  public summaryText = 'OTK.BOOKING-EDIT.SUMMARY-TEXT'
  public inApp = false
  public appVersion: string

  constructor(
    private adLoggerService: AdLoggerService,
    private colorService: ColorService,
    private activatedRooute: ActivatedRoute,
    private translateService: TranslateService,
    private bookingService: BookingService,
    private notificationService: NotificationService
  ) {
    this.colorService.applyThemingVariables('');
    this.activatedRooute.queryParams.subscribe({
      next: (params) => {
        if (!params?.access) {
          this.statusError = this.translateError(AppointmentErrors.Parameter);
          return;
        }
        this.accessCode = params?.access
      },
      error: (error) => {
        this.adLoggerService.error(error);
        this.statusError = this.translateError(AppointmentErrors.Parameter);
      }
    })
  }

  public cancelAppointment() {
    console.log('begin cancel process')
    this.isLoading = true;

    this.bookingService.cancelAppointment(this.accessCode, true)
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.statusError = null;
          if (result.error) {
            this.statusError = this.translateError(result.error);
            this.notificationService.displayNotification(this.translateService.instant('OTK.NOTIFICATIONS.APPOINTMENT-CANCELED-ERROR'));
            return;
          }
          this.summaryText = 'OTK.BOOKING-EDIT.SUMMARY-TEXT-SUCCESS';
          this.canCancel = false;
          this.notificationService.displayNotification(this.translateService.instant('OTK.NOTIFICATIONS.APPOINTMENT-CANCELED-SUCCESS'))
        },
        error: (error) => {
          this.adLoggerService.error(error)
          this.isLoading = false;
          this.statusError = this.translateError(AppointmentErrors.Unknown);
          this.notificationService.displayNotification(this.translateService.instant('OTK.BOOKING-EDIT.MODAL.CANCEL-ERROR'));
        }
      })
  }

  public translateError(error: string) {
    switch (error) {
      case AppointmentErrors.Expired:
        this.canCancel = false;
        return 'OTK.BOOKING-EDIT.ERROR-EXPIRED'
      case AppointmentErrors.CancellationWindow:
        this.canCancel = false;
        return 'OTK.BOOKING-EDIT.ERROR-CANCELLATION-WINDOW'
      case AppointmentErrors.Parameter:
        this.canCancel = false;
        return 'OTK.BOOKING-EDIT.ERROR-PARAMETER'
      case AppointmentErrors.Unknown:
        this.canCancel = false;
        return 'OTK.BOOKING-EDIT.ERROR-UNKNOWN'
      case AppointmentErrors.Cancelled:
        this.canCancel = false;
        return 'OTK.BOOKING-EDIT.ERROR-IS-CANCELLED'
      default:
        return error;
    }
  }
}
