import { PaymentAmount, StoreTypes, TransactionChannel } from '@a-d/entities/AdPay.entity';
import { LanguageService } from '@a-d/i18n/language.service';
import { InstanceService } from '@a-d/instance/instance.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdPayCheckoutService {
  public activeStoreId: string
  public globalStoreId: string
  public bsStoreId: string
  public oatStoreId: string
  public sellerCountryCode = 'DE'
  public countryUpdated$ = new BehaviorSubject<string>(this.sellerCountryCode)
  public storeUpdated$ = new BehaviorSubject<string>('')
  public amount: PaymentAmount = {
    currency: 'EUR',
    value: 0
  }

  constructor(
    private instanceService: InstanceService,
    private languageService: LanguageService,
    private http: HttpClient
  ) { }

  public getSellerCountry(): string {
    const country = this.instanceService.activeInstance?.contact?.country;
    switch (country) {
      case 'Deutschland':
        return 'DE';
      case 'Schweiz':
        return 'CH';
      case 'Österreich':
        return 'AT';
      default:
        return 'DE'
    }
  }

  public updateActiveStore(type: StoreTypes, storeId: string) {
    switch (type) {
      case StoreTypes.global:
        this.globalStoreId = storeId
        break;
      case StoreTypes.betriebsstaette:
        this.bsStoreId = storeId;
        break;
      case StoreTypes.appointmentType:
        this.oatStoreId = storeId;
        break;
      default:
        return;
    }
    console.log('UPDATE STORE TYpE', type)
    console.log('UPDATE STORE ID', storeId)
    this.activeStoreId = this.storeToUse();
    this.storeUpdated$.next(this.activeStoreId);
  }

  public storeToUse() {
    return (this.oatStoreId ? this.oatStoreId : (this.bsStoreId ? this.bsStoreId : this.globalStoreId));
  }

  public updateCountryOfOrigin(countryCode: string) {
    this.sellerCountryCode = countryCode;
    this.countryUpdated$.next(this.sellerCountryCode);
  }

  public retrievePaymentMethods(countryCode: string, channel: TransactionChannel, amount: PaymentAmount, storeId: string): Observable<any> {
    const instance = this.instanceService.activeInstance._id
    const requestData = {
      checkoutData: {
        amount: amount,
        channel: channel,
        countryCode: countryCode,
        shopperLocale: this.languageService.activeBaseLang
      },
      instanceId: instance,
      environment: environment.adPayEnvironment
    }
    if (storeId) { requestData['storeId'] = storeId; }
    return this.http.post('/api/adyen/paymentMethodLoad', requestData);
  }

  public makePayment(data: any, storeId: string, amount: PaymentAmount, country: string, returnUrl: string): Observable<any> {
    const instance = this.instanceService.activeInstance._id
    console.log('payment data', data)
    return this.http.post('/api/adyen/authorizePayment', {
      instanceId: instance,
      storeId: storeId,
      stateData: data,
      amount: amount,
      countryCode: country,
      returnUrl: returnUrl
    }).pipe(
      map((response: any) => {
        console.log('my response')
        return response.payment
      })
    )
  }

  // test route -> payment should only be captured in backend
  public capturePayment(data: any): Observable<any> {
    const instance = this.instanceService.activeInstance._id
    return this.http.post('/api/adyen/capturePayment', {
      instance: instance,
      paymentData: data
    })
  }

  public requestPaymentDetails(data: any): Observable<any> {
    console.log('details request', data)
    return this.http.post('/api/adyen/paymentDetails', data)
      .pipe(
        map((response: any) => {
          console.log('my response', response)
          return response.payment;
        })
      );
  }
}
