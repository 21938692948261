import { Injectable, Type } from '@angular/core';
import { AllgemeinAnamneseFormSettingsComponent } from '../../dashboard/instance-settings/forms/anamnese-settings/allgemein/allgemein-anamnese-form-settings.component';
import { CustomAnamneseFormSettingsComponent } from '../../dashboard/instance-settings/forms/anamnese-settings/custom/custom-anamnese-form-settings.component';
import { HautproblemAnamneseFormSettingsComponent } from '../../dashboard/instance-settings/forms/anamnese-settings/hautproblem/hautproblem-anamnese-form-settings.component';
import { OpKontrolleAnamneseFormSettingsComponent } from '../../dashboard/instance-settings/forms/anamnese-settings/op-kontrolle/op-kontrolle-anamnese-form-settings.component';
import { VarizenAnamneseFormSettingsComponent } from '../../dashboard/instance-settings/forms/anamnese-settings/varizen/varizen-anamnese-form-settings.component';
import { I18NArray } from '../../entities/I18N.entity';
// import { DataItemDisplayType } from '../../../dashboard/patient-data/patient-data-anamnese.component';
// import { MedicalSpecialization } from '../../dashboard/instance-settings/forms/medical-specialization.service';
import { InstanceForm } from '../../entities/InstanceForm.entity';
import { InstanceFormInterface, InstanceFormSettingsInterface } from '../instance-form-component.interface';
import { AllgemeinAnamneseFormComponent } from './allgemein/allgemein-anamnese-form.component';
import { BereitschaftsdienstAnamneseFormComponent, BereitschaftsdienstAnamneseFormSettings } from './bereitschaftsdienst/bereitschaftsdienst-anamnese-form.component';
import { CoronaAnamneseFormComponent, CoronaAnamneseFormSettings } from './corona/corona-anamnese-form.component';
import { CustomAnamneseFormComponent } from './custom/custom-anamnese-form.component';
import { HautproblemAnamneseFormComponent } from './hautproblem/hautproblem-anamnese-form.component';
import { OpKontrolleAnamneseFormComponent } from './op-kontrolle/op-kontrolle-anamnese-form.component';
import { DataItemDisplayType } from './types/data-item-display-type';
import { VarizenAnamneseFormComponent } from './varizen/varizen-anamnese-form.component';
import { GoSettings } from './zrm/go/go-settings.entity';
import { ReiseSettings } from './zrm/reise/reise-settings.entity';
import { ZrmFormsService } from './zrm/zrm-forms.service';



export interface AnamneseFormSettings {
  readonly diseaseOptions?: string[] | I18NArray,
  readonly showPreExistingDiseases?: boolean,
  readonly showPreExistingAllergies?: boolean,
  readonly showMedication?: boolean,
  readonly hautproblemOptions?: string[],
  readonly opOptions?: string[],
  readonly symptomOptions?: string[]
  readonly personalHistoryOptions?: string[]
}

export interface AnamneseForm {
  readonly name: string
  readonly identifier: string
  readonly component: Type<InstanceFormInterface>
  readonly settingsComponent?: Type<InstanceFormSettingsInterface>
  readonly defaultSettings: AnamneseFormSettings
  | CoronaAnamneseFormSettings
  | GoSettings
  | ReiseSettings
  readonly defaultMedicalSpecializationId: string,
  // readonly defaultMedicalSpecializationId: MedicalSpecialization['id'],

  // IMPORTANT: Keep old values in these dictionaries after form-changes to ensure readability of already existing data.
  readonly fieldTitles: { [s: string]: string }
  readonly displayTypes?: { [s: string]: DataItemDisplayType }
}


@Injectable({
  providedIn: 'root'
})
export class AnamneseFormsService {

  /**
   * All available anamnese-forms.
   */
  // TODO (Dan) 🧑‍💻 - someone REALLY needs to check these default `medicalSpecialization`s XD
  public readonly allPatientAnamneseForms: AnamneseForm[] = [

    {
      name: 'Benutzerdefiniert',
      identifier: 'custom',
      component: CustomAnamneseFormComponent,
      settingsComponent: CustomAnamneseFormSettingsComponent,
      defaultMedicalSpecializationId: '1',
      defaultSettings: {
      },
      fieldTitles: {
        // Populated on the fly
      }
    },

    {
      name: 'Allgemeine Beschwerden',
      identifier: 'allgemein',
      component: AllgemeinAnamneseFormComponent,
      settingsComponent: AllgemeinAnamneseFormSettingsComponent,
      defaultMedicalSpecializationId: '1',
      defaultSettings: {
        diseaseOptions: {
          de: [
            'Schnupfen',
            'Halsschmerzen',
            'Heiserkeit',
            'Fieber',
            'Kopfschmerzen',
            'Durchfall',
            'Harninkontinenz',
          ], en: [
            'Cold',
            'Sore throat',
            'Hoarseness',
            'Fever',
            'Headache',
            'Diarrhoea',
            'Urinary incontinence',
          ], fr: [
            'Rhume',
            'Maux de gorge',
            'Enrouement de la gorge',
            'Fièvre',
            'Maux de tête',
            'Diarrhée',
            'Incontinence urinaire'
          ], it: [
            'Raffreddore',
            'Mal di gola',
            'Raucedine',
            'Febbre',
            'Mal di testa',
            'Diarrea',
            'Incontinenza urinaria'
          ]
        },
        showPreExistingDiseases: true,
        showPreExistingAllergies: true,
        showMedication: true,
      },
      fieldTitles: {
        'diseaseSelection': 'Angegebene Beschwerden',
        'diseaseDescription': 'Beschreibung der Beschwerden',
        'diseaseHistory': 'Verlauf der Beschwerden',
        'preExistingDiseases': 'Vorerkrankungen',
        'preExistingAllergies': 'Allergien',
        'medication': 'Medikation',
      },
      displayTypes: {
        'diseaseSelection': DataItemDisplayType.List,
        'diseaseDescription': DataItemDisplayType.TextArea,
        'diseaseHistory': DataItemDisplayType.TextArea,
        'mediacation': DataItemDisplayType.TextArea
      }
    },

    {
      name: 'Hautbeschwerden',
      identifier: 'haut',
      component: HautproblemAnamneseFormComponent,
      settingsComponent: HautproblemAnamneseFormSettingsComponent,
      defaultMedicalSpecializationId: '10',
      defaultSettings: {
        hautproblemOptions: [
          'Ausschlag',
          'Rötung',
          'Juckreiz',
          'Schmerzen',
          'Schwellung',
          'Schuppung',
        ],
      },
      fieldTitles: {
        'hautproblemIsLesion': 'Art des Hautproblems',
        'hautproblemLesionDiameter': 'Durchmesser des Leberflecks (mm)',
        'hautproblemLesionMutations': 'Kürzliche Veränderung des Leberflecks',
        'hautproblemSelection': 'Ausgewählte Hautbeschwerden',
        'hautproblemDescription': 'Beschreibung der Beschwerden',
        'hautproblemLocalization': 'Lokalisierung',
        'hautproblemHistory': 'Beschwerdeverlauf',
        'hautproblemPreTreated': 'Bisherige Behandlungen',
        'hautproblemPreTreatmentDescription': 'Beschreibung bisheriger Behandlungen',
      },
      displayTypes: {
        'hautproblemLesionDiameter': DataItemDisplayType.TextField,
        'hautproblemLesionMutations': DataItemDisplayType.List,
        'hautproblemHistory': DataItemDisplayType.TextArea,
        'hautproblemPreTreatmentDescription': DataItemDisplayType.TextArea

      }
    },

    {
      name: 'OP-Kontrolle',
      identifier: 'op-kontrolle',
      component: OpKontrolleAnamneseFormComponent,
      settingsComponent: OpKontrolleAnamneseFormSettingsComponent,
      defaultMedicalSpecializationId: '13.1',
      defaultSettings: {
        opOptions: [
          'Krampfadern (Varizenchirurgie)',
          'Hand (z.B. Karpaltunnel)',
          'Fuß (z.B. Hallux)',
          'Bauch (z.B. Hernie)',
          'Gelenke (z.B. Arthroskopie)',
          'Chronische Wunde',
          'Kosmetische Operation (z.B. Liposuktion)',
        ],
      },
      fieldTitles: {
        'opSelection': 'Art der OP',
        'opDescription': 'Beschreibung der OP',
        // 'opSide': 'Operierte Seite',
        'opDate': 'Datum der OP',
        'surgeonSelection': 'Operateur',
      },
      displayTypes: {
        'opDate': DataItemDisplayType.Date
      }
    },

    {
      name: 'Varizen',
      identifier: 'varizen',
      component: VarizenAnamneseFormComponent,
      settingsComponent: VarizenAnamneseFormSettingsComponent,
      defaultMedicalSpecializationId: '10',
      defaultSettings: {
        symptomOptions: [
          'Mein Bein schwellt im Tagesverlauf an',
          'Mein Bein wird schwer beim Sitzen und Stehen',
          'Mein Unterschenkel ist bräunlich verfärbt',
          'Ich habe sichtbare Krampfadern',
          'Ich habe ein offenes Bein',
        ],
        personalHistoryOptions: [
          'Eine oberflächliche Venenentzündung',
          'Eine tiefe Beinvenenthrombose',
          'Ein offenes Bein',
          'Zurückliegende Venen-Operation',
        ]
      },
      fieldTitles: {
        'familyHistory': 'Eltern hatten Krampfadern',
        'personalHistory': 'Hatte Krampfadern',
        'personalHistorySelection': 'Ausgewählte Krampfader-Vorgeschichte',
        'personalHistoryDescription': 'Sonstige Krampfader-Vorgeschichte',
        'symptomSelection': 'Ausgewählte Beschwerden',
        'symptomDescription': 'Sonstige Beschwerden',
        'symptomSide': 'Seite(n)',
      }
    },

    {
      name: 'Coronavirus Fragebogen',
      identifier: 'corona',
      component: CoronaAnamneseFormComponent,
      // settingsComponent: CoronaAnamneseFormSettingsComponent,
      defaultMedicalSpecializationId: '1',
      defaultSettings: {
        coronaSymptomOptions: [
          'Geruchsstörungen',
          'Geschmacksstörungen',
          'Fieber',
          'Husten',
          'Halsschmerzen',
          'Atemnot',
          'Laufende Nase',
          'Durchfall',
          'Allgemeine Schwäche',
          'Allgemeine Schmerzen',
        ],
      } as CoronaAnamneseFormSettings,
      fieldTitles: {
        'coronaSymptomsSelection': 'Auswahl akuter Beschwerden',
        'coronaSymptomsDescription': 'Sonstige Beschwerden',
        'coronaSymptomsHistory': 'Dauer der Beschwerden',
        'coronaLungDisease': 'Chronische Lungenerkrankung diagnostiziert',
        'coronaInfectedPersonContact': 'Enger Kontakt zu infizierter Person',
      }
    },
    {
      name: 'Videosprechstunde Bereitschaftsdienst',
      identifier: 'bereitschaftsdienst',
      component: BereitschaftsdienstAnamneseFormComponent,
      // settingsComponent: CoronaAnamneseFormSettingsComponent,
      defaultMedicalSpecializationId: '1',
      defaultSettings: {} as BereitschaftsdienstAnamneseFormSettings,
      fieldTitles: {
        'problemsQuestion': 'Auswahl akuter Beschwerden',
        'caseNumber': 'Fallnummer',
      },
      displayTypes: {
        'problemsQuestion': DataItemDisplayType.TextArea,
        'caseNumber': DataItemDisplayType.TextField,
      },
    },
    ZrmFormsService.go,
    ZrmFormsService.reise,
    ZrmFormsService.reiseNGo,
  ]
  constructor() { }
  /**
   * Returns the anamnese-form for the given `anamneseFormIdentifier`
   */
  public getAnamneseForm(identifier: string): AnamneseForm {
    if (!identifier) return null

    return this.allPatientAnamneseForms.find((form) => {
      return form.identifier === identifier
    })
  }


  /**
   * Returns the anamnese-form for the given `instanceForm`
   */
  public getAccordingAnamneseForm(instanceForm: InstanceForm): AnamneseForm {
    return instanceForm ? this.getAnamneseForm(instanceForm.anamneseFormIdentifier) : null
  }


  /**
   * Returns the applicable `fieldTitles` of the anamnese-form for the given `instanceForm`
   */
  public getAccordingAnamneseFormFieldTitles(instanceForm: InstanceForm, anamnese: object): object {
    const anamneseForm = this.getAccordingAnamneseForm(instanceForm)
    const anamneseFieldTitles = {}
    for (const key in anamnese) {
      anamneseFieldTitles[key] = anamneseForm?.fieldTitles?.[key] || key
    }
    return anamneseFieldTitles
  }


  /**
   * Merges anamnese- into given intance-form.
   *
   * returns 0 if successful, not zero otherwise
   */
  public populateInstanceForm(instanceForm: InstanceForm): number {
    const anamneseForm = this.getAccordingAnamneseForm(instanceForm)
    if (!anamneseForm) {
      return 1
    }

    instanceForm.anamneseFormSettings = Object.assign(JSON.parse(JSON.stringify(anamneseForm.defaultSettings)), instanceForm.anamneseFormSettings)
    instanceForm.anamneseFormComponent = anamneseForm.component
    instanceForm.anamneseFormSettingsComponent = anamneseForm.settingsComponent
    instanceForm.anamneseFormFieldTitles = anamneseForm.fieldTitles
    return 0
  }

}
