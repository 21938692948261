import { Ability, AbilityClass, MongoAbility, RawRuleOf } from "@casl/ability"

export type AbilityAction = 'manage' | 'create' | 'read' | 'update' | 'delete'
export type AbilitySubject = 'Abilities' | 'Instance' | 'User'
export type AppAbilities = [AbilityAction, AbilitySubject]

export type AppAbility = MongoAbility<AppAbilities>
export const AppAbility = Ability as AbilityClass<AppAbility>

// export interface RawAppAbility {
//   action: Action[]        // allowed CRUD operation 
//   subject: Subject[]      // allowed area of interaction
//   fields?: string[]       // database field names
//   conditions?: Object     // special mongo query interactions
//   inverted?: boolean      // switch between allow/forbid
//   reason?: string         // special error message if interaction is forbidden
// }

export enum RoleType {
  Admin = 'admin',
  Support = 'support',
  Marketing = 'marketing',
  Instance = 'instance',
  Doctor = 'doctor',
  MFA = 'mfa'
}

export interface AppRoleAbilities {
  roleType: RoleType          // basic role type
  roleName: string            // custom name for role ability set
  customRole: boolean         // custom defined ruleset within role type confines
  abilities: RawRuleOf<AppAbility>[]       // abilities of role
  instance: string            // owner of ruleset
}