export const themingDefault = {
  colorPrimary: 206,
  saturationPrimary: 90,
  lightPrimary: 40,
  colorButton: 206,
  saturationButton: 90,
  lightButton: 40,
  isHeaderWhite: false,
  isLogoLarge: false,
} as const
