import { PaymentAmount } from '@a-d/entities/AdPay.entity';
import { AttachmentType, BookingStep, I18NString, OtkAppointment, PatientInsuranceType } from '@a-d/entities/Booking.entity';
import { OTKUser } from '@a-d/entities/Calendar.entity';
import { BaseLanguage } from '@a-d/entities/I18N.entity';
import {
  PrintableArrayRow, PrintableStringRow
} from '@a-d/entities/Printable.entity';
import { LanguageService } from '@a-d/i18n/language.service';
import { InstanceService } from '@a-d/instance/instance.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AsyncPipe, CurrencyPipe, DatePipe, NgFor, NgIf, SlicePipe, UpperCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import timezone from "dayjs/plugin/timezone";
import utc from 'dayjs/plugin/utc';
import { environment } from 'projects/arzt-direkt/src/environments/environment';
import { Observable, Subscriber, Subscription, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AppointmentType, Betriebsstaette, BookingOpening, I18NString_MISSING_VALUE, OtkAttachment, OtkDoctor, TomKalenderDaten, TomTerminLokalitaet } from '../../entities/Booking.entity';
import { PraxisGeodata } from '../../entities/Praxis.entity';
import { I18NStringPipe } from '../../i18n/i18n.pipe';
import { MimetypeHelpersService } from '../../misc/mimetype-helpers.service';
import { PrepareForDisplayService } from '../../misc/prepare-for-display.service';
import { WeekdayPipe } from '../../misc/weekday.pipe';
import { StreetmapComponent } from '../../streetmap/streetmap.component';
import { LogoComponent } from '../../theming/logo/logo.component';
import { ColorService } from '../../theming/service/color.service';
import { BookingContactService } from '../booking-contact.service';
import { BookingService } from '../booking.service';
import { DemoEncryptionDialogComponent } from '../demo-encryption-dialog/demo-encryption-dialog.component';

export interface Section {
  name: string;
  updated: Date;
}

@UntilDestroy()
@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.scss'],
  standalone: true,
  imports: [NgIf, CurrencyPipe, LogoComponent, StreetmapComponent, NgFor, MatButtonModule, MatIconModule, FontAwesomeModule, MatTooltipModule, MatProgressSpinnerModule, MatCheckboxModule, FormsModule, ReactiveFormsModule, AsyncPipe, UpperCasePipe, SlicePipe, DatePipe, TranslateModule, I18NStringPipe, WeekdayPipe]
})
export class BookingSummaryComponent implements OnInit {
  @ViewChild('bookingSummary', { static: true }) bookingSummary: ElementRef
  appointmentTypeName: string
  dateParams: Object
  doctorsBooked: OtkDoctor[]
  isDoctorsBookedEmpty: boolean
  onlyOneDoctor: boolean
  localities: TomTerminLokalitaet[]
  isLocalitiesEmpty: boolean
  isBookingValid: boolean
  isContactValid = false
  contactFormName: I18NString
  contactNameFallback = 'OTK.BOOKING-TYPE.CONTACT-FORM-NAME'
  personal: PrintableArrayRow<string>[]
  insuranceType: PatientInsuranceType
  anamnese: PrintableArrayRow<I18NString>[]
  initTimeout: NodeJS.Timeout
  anamneseFormTitle: I18NString
  instanceEmail: string
  emailString: string
  instancePhone: string
  instanceName: string
  instanceGeodata: PraxisGeodata
  isMobile: boolean
  isDemo: boolean
  pediatricianMode: boolean = false
  showDoctors: boolean // should probably actually be called: showDoctorMenu
  info: I18NString
  showInfo: boolean = false
  baseLanguage: BaseLanguage
  baseLanguageSubscription: Subscription
  betriebsstaettenFilter: boolean = false
  bsDisplayed: Betriebsstaette = null
  isOpeningConnectedToMultipleBs: boolean
  isOpeningNotConnectedToAnyBs: boolean
  bsNone: boolean = false
  waitinglistActivated: boolean = false
  otkEmail: string = ""
  uploadDialogRef: MatDialogRef<DemoEncryptionDialogComponent>
  hasAttachment: boolean = false
  attachmentType = AttachmentType
  isOnlineConsultation: boolean = false
  showFileUpload: boolean = false
  doctorArray = this.bookingService.multiSlots.doctorArray
  openingsFormArray: AbstractControl = this.bookingService.openingsMultiControl
  timezone: string

  public isPaid = false
  public paymentAmount: PaymentAmount = null

  noInstanceMap: boolean = false
  instanceStreetNumber: string = ""
  instanceCity: string = ""
  instanceZip: string = ""

  bsEmail: string = ""
  bsEmailHref: string = ""

  @ViewChild('map') bsMap: StreetmapComponent
  @ViewChild('instanceMap') instanceMap: StreetmapComponent
  I18NString_MISSING_VALUE: string = I18NString_MISSING_VALUE // string stored for instance in I18NString.en in case it was not translated yet
  isZollsoftMode = false

  //subscribe waitinglist
  get subscribeWaitinglistControl(): AbstractControl { return this.bookingService.subscribeWaitinglistControl }

  get assets(): AbstractControl { return this.bookingService.assets }
  fileIconDict: Record<string, string>


  constructor(
    public bookingService: BookingService,
    public colorService: ColorService,
    private prepareForDisplayService: PrepareForDisplayService,
    public translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private instanceService: InstanceService,
    public languageService: LanguageService,
    private contactService: BookingContactService,
    private dialog: MatDialog,
    private mimetypeHelpersService: MimetypeHelpersService
  ) { }

  ngOnInit() {
    this.setScrollableNativeElement()
    this.isDemo = environment.demoMode
    this.isMobile = this.bookingService.isMobile()
    this.setInstanceContactParameters()
    dayjs.extend(utc)
    dayjs.extend(timezone)
    this.isZollsoftMode = this.bookingService.otkUser?.zollsoftMode;
    this.bookingChangesSubscription()
    this.baseLanguageChangeSubscribe()

    this.bookingService.stepper.selectionChange.pipe(untilDestroyed(this))
      .subscribe((e: StepperSelectionEvent) => {
        if (e.selectedStep.label === this.bookingService.tabTitles.BOOKING_SUMMARY) {
          this.isContactValid = this.bookingService.isContactFormSelected$.value && this.bookingService.personalFormComponent?.formGroup.valid
          if (this.isContactValid && this.bookingService.otkUser) {
            this.contactFormName = this.bookingService.otkUser?.contactSettings?.name
            this.setPersonal()
          }
          setTimeout(() => {
            if (this.bsMap) {
              this.bsMap.recalcsize()
              this.changeDetectorRef.detectChanges()
              this.bsMap.repaintMap()
            }
            if (this.instanceMap) {
              this.instanceMap.recalcsize()
              this.changeDetectorRef.detectChanges()
              this.instanceMap.repaintMap()
            }
          }, 0)
        }
      })

    this.fileIconDict = this.mimetypeHelpersService.getFileMaterialIconDict()

    this.bookingService.bsDisplayed$.pipe(untilDestroyed(this)).subscribe({
      next: (bs: Betriebsstaette) => this.setBsToDisplayMulti(bs)
    })
  }

  setScrollableNativeElement() {
    this.bookingService.scrollableNativeElement[BookingStep.bookingSummary] = this.bookingSummary.nativeElement
  }

  setInstanceContactParameters() {
    this.instanceEmail = this.instanceService.activeInstance.contact?.email
    this.otkEmail = this.bookingService.otkUser?.emailSettings?.replyToAddress || this.instanceEmail || ""
    this.emailString = "mailto:" + this.otkEmail + "?Subject=Online-Terminkalender"
    this.instancePhone = this.instanceService.activeInstance.contact?.phone
    this.instanceName = this.instanceService.activeInstance?.shortName || this.instanceService.activeInstance?.name || ""

    this.instanceZip = this.instanceService.activeInstance.contact?.zip
    this.instanceCity = this.instanceService.activeInstance.contact?.city
    this.instanceStreetNumber = this.instanceService.activeInstance.contact?.address_1
    this.instanceGeodata = this.instanceService.activeInstance.contact?.geodata
    this.noInstanceMap === !this.instanceGeodata || (this.instanceGeodata?.lat === 0 && this.instanceGeodata?.lon === 0)
  }

  /**
   * if any of the forms change their value such that
   * the forms are all valid, initialize booking summary.
   * the timeout prevents this from being called an excessive number of times,
   * for instance during magic fill, auto fill or fast typing
   */
  private bookingChangesSubscription() {
    let initSubscription: Subscription
    this.bookingService.bookingValueChanges$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        clearTimeout(this.initTimeout)
        this.initTimeout = setTimeout(() => {
          if (!this.bookingService.seriesMode$.getValue()) {
            this.isBookingValid = this.bookingService.isBookingValid
            // "!this.bookingService.reservation" is for when the entire form
            // is filled correctly and then an already-reserved opening is selected
            if (!this.isBookingValid || !this.bookingService.reservation)
              return
            if (initSubscription)
              initSubscription.unsubscribe()
            initSubscription = this.init()
              .subscribe()
          } else {
            if (this.bookingService.appointmentSeriesChain && this.bookingService.appointmentType) {
              this.isPaid = this.bookingService?.appointmentType?.value?.adPayActive
              this.paymentAmount = (this.isPaid) ? this.bookingService?.appointmentType?.value?.amount : null
            }
            this.isBookingValid = true
            if (this.bookingService.personalFormComponent.formGroup.valid) this.initForMulti()
          }
        }, 1000)
      })
  }

  // re-render everything upon language change (needed for instance for the appointment type and the pdf)
  baseLanguageChangeSubscribe() {
    if (this.baseLanguageSubscription)
      this.baseLanguageSubscription.unsubscribe()
    this.baseLanguageSubscription = this.languageService.baseLanguageChangeSubject$
      .pipe(untilDestroyed(this),
        mergeMap(() => {
          this.baseLanguage = this.languageService.activeBaseLang
          return of(null)
        }),
        mergeMap(() => {
          if (!this.bookingService.seriesMode$.getValue()) {
            return (this.isBookingValid && this.bookingService.reservation) ? this.init() : of(null)
          }
          else {
            this.initForMulti();
            return of(null)
          }
        }))
      .subscribe()
  }

  /**
   * creates the appointment and sets the strings
   * for displaying it.
   * Called whenever the booking information changes.
   */
  private init(): Observable<any> {
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => this.bookingService.createOtkAppointment()),
        mergeMap(() => {
          this.dateParams = this.getDateParams(
            this.bookingService.otkAppointment,
            this.bookingService.otkUser
          )
          this.waitinglistActivated = !!this.bookingService.appointmentType.value?.waitinglist && this.waitinglistTiming(this.bookingService.opening.value?.date)
          this.showFileUpload = !!this.bookingService.appointmentType.value?.fileUpload
          const useSpecificFieldSettings = !!this.bookingService.appointmentType.value?.useSpecificFieldSettings
          this.pediatricianMode = useSpecificFieldSettings ? !!this.bookingService.appointmentType.value?.specificFieldSettings?.pediatricianMode : !!this.bookingService.otkUser?.fieldSettings?.pediatricianMode
          this.isOnlineConsultation = !!this.bookingService.appointmentType.value?.onlineConsultation
          return this.setDoctorDisplayStrings()
        }),
        mergeMap(() => of(this.setPersonal())),
        mergeMap(() => this.getBsToDisplay()),
        mergeMap(() => {
          this.betriebsstaettenFilter = this.bookingService.otkUser.betriebsstaettenFilter
          this.anamneseFormTitle = this.bookingService.anamneseFormTitle
          this.insuranceType = this.bookingService.insuranceType.value
          this.showDoctors = this.bookingService.showDoctors
          this.info = (this.bookingService.appointmentType.value as AppointmentType)?.info
          this.showInfo = (this.bookingService.appointmentType.value as AppointmentType)?.showInfo
          this.isPaid = this.bookingService?.appointmentType?.value?.adPayActive
          this.paymentAmount = (this.isPaid) ? this.bookingService?.appointmentType?.value?.amount : null
          return of(null)
        }),
        mergeMap(() => {
          this.changeDetectorRef.detectChanges()
          return of(null)
        })
      )
  }


  private initForMulti() {
    this.waitinglistActivated = !!this.bookingService.appointmentType.value?.waitinglist && this.waitinglistTiming(this.bookingService.opening.value?.date)
    this.showFileUpload = !!this.bookingService.appointmentType.value?.fileUpload
    let useSpecificFieldSettings = !!this.bookingService.appointmentType.value?.useSpecificFieldSettings
    this.pediatricianMode = useSpecificFieldSettings ? !!this.bookingService.appointmentType.value?.specificFieldSettings?.pediatricianMode : !!this.bookingService.otkUser?.fieldSettings?.pediatricianMode
    this.isOnlineConsultation = !!this.bookingService.appointmentType.value?.onlineConsultation
    this.setPersonal()    //this.setDoctorDisplayStrings()

    this.betriebsstaettenFilter = this.bookingService.otkUser.betriebsstaettenFilter
    this.anamneseFormTitle = this.bookingService.anamneseFormTitle
    this.insuranceType = this.bookingService.insuranceType.value
    this.showDoctors = this.bookingService.showDoctors
    this.info = (this.bookingService.appointmentType.value as AppointmentType)?.info
    this.showInfo = (this.bookingService.appointmentType.value as AppointmentType)?.showInfo
    this.timezone = this.bookingService.otkUser.timezone
  }



  private waitinglistTiming(date: dayjs.Dayjs): boolean {
    if (!date) return false
    if (typeof date === 'string') {
      return dayjs().isBefore(dayjs(date).subtract(1, 'day'))
    }
    return dayjs().isBefore(date.subtract(1, 'day'))
  }

  private setDoctorDisplayStrings(): Observable<any> {
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          const baseLanguage: BaseLanguage = this.languageService.activeBaseLang
          const baseLanguageDefault: BaseLanguage = this.languageService.DEFAULT_BASE_LANG
          const appointmentTypeNameI18NString: I18NString = (this.bookingService.bookingTypeFormGroup.get("appointmentType").value as AppointmentType).name
          this.appointmentTypeName = appointmentTypeNameI18NString[baseLanguage] !== I18NString_MISSING_VALUE
            ? appointmentTypeNameI18NString[baseLanguage]
            : appointmentTypeNameI18NString[baseLanguageDefault]
          this.doctorsBooked = this.getDoctorsBooked()
          this.onlyOneDoctor = Object.keys(this.doctorsBooked).length === 1
          return this.bookingService.getLocalities()
        }),
        mergeMap((localities: TomTerminLokalitaet[]) => {
          this.localities = localities
          this.isLocalitiesEmpty = Object.keys(localities).length === 0
          return of(null)
        })
      )
  }

  private parseDate(date: Date): string {
    if (date === null) return ''
    return dayjs.utc(date).format('DD.MM.YYYY')
  }

  private printable(data: Object): PrintableArrayRow<string>[] {
    return this.prepareForDisplayService.prepareForPrint(data, (d) => this.parseDate(d))
  }

  private getDoctorsBooked(): OtkDoctor[] {
    const bookingOpening: BookingOpening =
      this.bookingService.bookingDateFormGroup.get("opening").value
    const openingKids: string[] =
      bookingOpening?.kdSet?.map((tomKalendarDaten: TomKalenderDaten) => tomKalendarDaten.kid)
    const doctorsBooked =
      this.bookingService.doctorsVisible.filter((doctor: OtkDoctor) => openingKids.some((x: string) => doctor?.kids?.includes(x)))
    this.isDoctorsBookedEmpty = Object.keys(doctorsBooked).length === 0
    return doctorsBooked
  }

  get printableDate(): PrintableStringRow<string> {
    const name = this.translate.instant('OTK.STEPPER.TITLES.APPOINTMENT-DATE');
    const dateParams = this.getDateParams(
      this.bookingService.otkAppointment,
      this.bookingService.otkUser
    )
    const value = this.translate.instant('OTK.STEPPER.SUMMARY-DATE', dateParams);
    return { name: name, value: value }
  }

  private setPersonal() {
    const personalData = this.bookingService.personalFormComponent.getData()
    if (this.pediatricianMode) { // ugly hack, maybe best solution dialect for pediatricians
      const pfname = (personalData as any).fname ?? "";
      const plname = (personalData as any).lname ?? "";
      const pbirthday = (personalData as any).birthdate ?? "";
      delete (personalData as any).fname
      delete (personalData as any).lname
      delete (personalData as any).birthdate

      this.personal = this.printable(personalData)
      this.personal.unshift({ name: this.translate.instant("OTK.BOOKING-PERSONAL.pbirthdate"), values: [this.parseDate(pbirthday)] })
      this.personal.unshift({ name: this.translate.instant("OTK.BOOKING-PERSONAL.plname"), values: [plname] })
      this.personal.unshift({ name: this.translate.instant("OTK.BOOKING-PERSONAL.pfname"), values: [pfname] })
    } else {
      this.personal = this.printable(personalData)
    }
    this.personal = this.personal.filter((printableArrayRow: PrintableArrayRow<string>) =>
      printableArrayRow.name !== 'emailConfirm')
  }

  private getBsToDisplay(): Observable<boolean> {
    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      this.isOpeningNotConnectedToAnyBs = this.bookingService.isOpeningNotConnectedToAnyBs
      this.isOpeningConnectedToMultipleBs = this.bookingService.isOpeningConnectedToMultipleBs
      this.bsDisplayed = this.bookingService.bsDisplayed
      this.bsEmail = this.bsDisplayed?.emailSettings?.replyToAddress || this.bookingService?.otkUser?.emailSettings?.replyToAddress || this.bsDisplayed?.contact?.email || this.instanceEmail || ""
      this.bsEmailHref = "mailto:" + this.bsEmail + "?Subject=Online-Terminkalender"
      subscriber.next()
      subscriber.complete()
    })
  }

  private setBsToDisplayMulti(bs: Betriebsstaette) {
    this.bsDisplayed = bs
    this.bsEmail = this.bsDisplayed?.emailSettings?.replyToAddress || this.bookingService?.otkUser?.emailSettings?.replyToAddress || this.bsDisplayed?.contact?.email || this.instanceEmail || ""
    this.bsEmailHref = "mailto:" + this.bsEmail + "?Subject=Online-Terminkalender"
  }



  private getDateParams(otkAppointment: OtkAppointment, otkUser: OTKUser) {
    // no idea why that started failing with toUTCString is not a function...
    //const dateStr = otkAppointment?.start.toUTCString().split('Z')[0]; 
    const dateStr = otkAppointment?.start;
    const dateStrs = dayjs(dateStr)
      .tz(otkUser.timezone)
      .format('DD.MM.YYYY[,]HH:mm')
      .split(',');
    const dateParams = { summaryDate: dateStrs[0], summaryTime: dateStrs[1] };
    return dateParams;
  }

  uploadFile() {
    this.uploadDialogRef = this.dialog.open(DemoEncryptionDialogComponent, {
      width: '50rem',
      backdropClass: 'c-dialog-dark-backdrop',
    })

    this.uploadDialogRef.afterClosed().pipe(
      untilDestroyed(this)
    ).subscribe((attachment: OtkAttachment) => {
      if (attachment) {
        this.hasAttachment = true
        this.changeDetectorRef.detectChanges()
      }
    })
  }


  public sendContactData() {
    this.bookingService.isLoading.next(true);
    const contactData = this.bookingService.personalFormComponent.getContactData(this.baseLanguage, this.instanceService.activeInstance?.settings?.general?.internationalization?.baseLanguageDefault ?? this.languageService.DEFAULT_BASE_LANG)
    this.contactService.book(this.instanceService.activeInstance._id, contactData)
      .subscribe({
        next: (response) => {
          this.bookingService.navigateContact()
          this.bookingService.isLoading.next(false)
        },
        error: (error) => {
          this.bookingService.isLoading.next(false)
          this.bookingService.setError(error)
        }
      })
  }
}
