import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { LegalDataFiles } from '../entities/Legal.entity';
import { LegalService } from './legal.service';


export interface LegalMenuItem {
  title: string
  icon: string
  showForParents?: MenuViewMode[],
  action: () => void
  hasDivider?: boolean
}

export enum MenuViewMode {
  instance = 'instance',
  arzt = 'arzt',
  admin = 'admin',
  patient = 'patient',
  patientOTK = 'patientOTK',
  auth = 'auth',
}

@Component({
  selector: 'app-legal-menu',
  templateUrl: './legal-menu.component.html',
  styleUrls: ['./legal-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    NgFor,
    MatDividerModule,
    TranslateModule,
  ],
})
export class LegalMenuComponent implements OnInit {

  @Input() menuItem: boolean
  @Input() title: string = 'Rechtliches'
  @Input() viewMode: MenuViewMode
  @Input() asBar: boolean = false

  public readonly DASHBOARD_PARENTS = [MenuViewMode.instance, MenuViewMode.arzt, MenuViewMode.admin]
  public readonly CUSTOMER_PARENTS = [...this.DASHBOARD_PARENTS, MenuViewMode.auth]

  public readonly ALL_MENU_ITEMS: LegalMenuItem[] = [
    {
      title: 'AGB',
      icon: 'policy',
      action: () => { this.legalService.openDialog(LegalDataFiles.AGB, 'global') },
    },
    {
      title: 'Datenschutz',
      icon: 'shield',
      action: () => { this.legalService.openDialog(LegalDataFiles.Datenschutz) }
    },
    // TODO
    // {
    //     title: 'Zahlungshinweise',
    //     icon: 'file-alt',
    //     action: () => { this.legalService.openDialog(LegalDataFiles.Zahlungshinweise, 'global') }
    // },
    {
      title: 'Impressum',
      icon: 'article',
      action: () => { this.legalService.openDialog(LegalDataFiles.Impressum) },
      hasDivider: true
    },
    {
      title: 'Support',
      icon: 'contact_support',
      action: () => { this.legalService.openDialog(LegalDataFiles.Support) },
      showForParents: [MenuViewMode.patient],
    },
    {
      title: 'Kundensupport',
      icon: 'contact_support',
      action: () => { this.legalService.openDialog(LegalDataFiles.Kundensupport, 'global') },
      showForParents: this.CUSTOMER_PARENTS,
    },
  ]

  public menuItems: LegalMenuItem[] = []


  constructor(
    public legalService: LegalService
  ) { }

  ngOnInit() {
    this.menuItems = this.ALL_MENU_ITEMS.filter((item: LegalMenuItem) => {
      return !item.showForParents?.length
        || item.showForParents.includes(this.viewMode)
    })
  }

}
