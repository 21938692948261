import {
  AnyWfaForm,
  SurveyjsResponse,
  WfaFormWithResponse,
  getQuestions,
} from '@arzt-direkt/wfa-definitions'
import {
  nullish,
  set,
  createArrayIntersection,
  Maybe,
} from '@arzt-direkt/wfa-generic-utils'

type MinResponse = {
  responseId?: WfaFormWithResponse['formResponse']['responseId']
  surveyjsResponse?: WfaFormWithResponse['formResponse']['surveyjsResponse']
}

type WithMinResponse = {
  formResponse: MinResponse
}

type WithFormAndMinResponse = {
  form: AnyWfaForm
  formResponse: MinResponse
}

export function getVorbefuellungResponse(
  selected: WithFormAndMinResponse,
  all: WithMinResponse[],
): SurveyjsResponse {
  const questions = getQuestions(selected.form)

  const allKeys = questions.filter((q) => q.name[0] === '$').map((q) => q.name)
  const allResponses = Object.assign(
    {},
    ...(all?.map((f) => f?.formResponse?.surveyjsResponse) ?? []),
  )

  const relevantKeys = createArrayIntersection(
    Object.keys(allResponses),
    allKeys,
  )

  const vorbefuellungResponse = Object.fromEntries(
    relevantKeys.map((key) => [key, allResponses[key]]),
  )

  return {
    ...vorbefuellungResponse,
    ...selected.formResponse.surveyjsResponse,
  }
}

/**
 * mutates `all`
 */
export function setVorbefuellungResponse<FWR extends WithFormAndMinResponse>(
  selected: FWR,
  all: FWR[],
): Maybe<FWR> {
  const surveyjsResponse = getVorbefuellungResponse(selected, all)
  const withLatestResponse: Maybe<FWR> = all?.find(
    (fwr) => fwr.formResponse.responseId === selected.formResponse.responseId,
  )

  if (nullish(withLatestResponse)) {
    return null
  }

  set(withLatestResponse, 'formResponse.surveyjsResponse', surveyjsResponse)

  return withLatestResponse
}
