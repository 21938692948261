
export enum InsuranceType {
  GKV = 'gkv',
  PKV = 'pkv',
  HZV = 'hzv',
  SZ = 'sz',
  BES = 'bes',
  BG = 'bg',
  None = 'none',
  All = 'alle'
}

export enum InsuranceBesType {
  bw = 'bw',
  po = 'po',
  as = 'as',
  eu = 'eu',
  so = 'so',
}

export type InsurantStatus = 0 | 1 | 3 | 5

export interface Insurance {
  readonly insuranceType?: InsuranceType
  readonly insuranceName?: string
  readonly insuranceNumber?: string
  readonly insurantNumber?: string
  readonly insurantStatus?: InsurantStatus
  readonly besType?: InsuranceBesType
  readonly expirationDate?: Date
  readonly noExpirationDate?: boolean
}
