import { convertToWfaFormId, WfaForm } from '@arzt-direkt/wfa-definitions'
import {
  getCurrentDateTime,
  Maybe,
  nullish,
} from '@arzt-direkt/wfa-generic-utils'

type MultilingualText = Record<string, string>;

function isMultilingualText(value: unknown): value is MultilingualText {
  return typeof value === 'object' && value !== null
}

export type UnsanitizedForm = Maybe<{
  title?: string | MultilingualText
  description?: string | MultilingualText
  wfaFormId?: string
}>

function getMultilingualValue(
  value: string | MultilingualText | undefined,
  defaultValue: string,
): string {
  if (isMultilingualText(value)) {
    return value['de'] ?? Object.values(value)[0] ?? defaultValue
  }
  return value ?? defaultValue
}

export function getWfaFormId(
  wfaForm: UnsanitizedForm,
  date: Date = new Date(),
): string {
  return (wfaForm as WfaForm)?.wfaFormId ?? getPristineWfaFormId(wfaForm, date)
}

function getPristineWfaFormId(
  wfaForm: UnsanitizedForm,
  date: Date = new Date(),
): string {
  const dateTime = getCurrentDateTime(date)
  if (nullish(wfaForm)) {
    return `form-${dateTime}`
  }

  const title = getMultilingualValue(wfaForm.title, 'form')
  const description = getMultilingualValue(wfaForm.description, '')

  const candidateWfaFormId = convertToWfaFormId(`${title}-${description}`)
  if (candidateWfaFormId === 'form') {
    return `form-${dateTime}`
  }

  return candidateWfaFormId
}
