export const responseIdDict = {
  alpha: '657028eadaa9020fad643151',
  beta: '657028eadaa9020fad643152',
  betaGroup: '671a48f7474bb924ce04706c',
  gamma: '657028eadaa9020fad643153',
  gammaGroup: '671a48f7474bb924ce04706c', // equal to betaGroup
  beta2: '6731ca28e1a00e8c5fb47835',
  beta3: '6731ca2f6d14ef8ea76d7a39',
  delta: '6731ca2f6d14ef8ea76d7a40',
}
