// DEPRECATED Use src/app/entities/Booking.entity.ts instead
//
// note:
//  there are 3 definitions:
//    src/app/entities/Booking.entity.ts
//    server_appointment/src/types/i18n.types.ts
//    src/app/entities/I18N.entity.ts
//  the first two should be kept synchronized with each other.
//  the third should be deleted and replaced by the first?

export enum BaseLanguage {
  English = 'en',
  German = 'de',
  French = 'fr',
  Italian = 'it',
  Spanish = 'es'
}
export type I18NDictionary<T> = {
  [key in BaseLanguage]?: T
}

export const I18NString_MISSING_VALUE: string = "MULTILINGUAL-FIELD.NO-TRANSLATION"

export type I18NString = string | I18NDictionary<string>
export type I18NArray = string[] | I18NDictionary<string[]>
