import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'abbreviateFileName',
    pure: false,
    standalone: true
})
export class AbbreviateFileNamePipe implements PipeTransform {
  transform(str: string, length?: number): any {
    if (!length) {
      length = 10
    }
    if (str.length <= length)
      return str
    const extension: string = str.indexOf(".") > 0
      ? str.split(".")[str.split(".").length - 1]
      : ''
    const result = str.substr(0, length) + '... ' + extension
    return result
  }
}
