import { LegalDataFiles } from '@a-d/entities/Legal.entity';
import { LegalService } from '@a-d/legal/legal.service';
import { AdLoggerService } from '@a-d/logging/ad-logger.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { DialogComponent } from '../../../../../lib/src/lib/dialog/dialog.component';
import { UserService } from '../../dashboard/user/user.service';
import { User } from '../../entities/User.entity';

@Component({
  selector: 'app-agb-privacy-dialog',
  templateUrl: './agb-privacy-dialog.component.html',
  styleUrls: ['./agb-privacy-dialog.component.scss'],
  standalone: true,
  imports: [
    DialogComponent,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    RouterLink,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule
  ],
})
export class AGBPrivacyDialogComponent implements OnInit, OnDestroy {

  public isLoading = false;
  public user: User;
  private unsubscribe$ = new Subject();

  constructor(
    private adLoggerService: AdLoggerService,
    private userService: UserService,
    private dialogRef: MatDialogRef<AGBPrivacyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public matData: any,
    public legalService: LegalService
  ) { }

  public agbCheckbox = new FormControl(false, [Validators.requiredTrue])

  ngOnInit() {
    this.user = this.matData.user;
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public saveAGBAcceptance() {
    const isInstance: boolean = !!this.user?.roles?.includes('instance')
    const isArzt: boolean = !!this.user?.roles?.includes('arzt')
    if (!isInstance && !isArzt) return
    this.isLoading = true;
    this.user.acceptedAGB = true;

    this.userService.updateAGBAcceptedStatus(this.user._id, true, isInstance)
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (updatedUser) => {
          console.log('finished user update');
          console.log(updatedUser);
          this.dialogRef.close(true);
        },
        error: (error) => {
          this.adLoggerService.error(error);
          this.user.acceptedAGB = false;
        }
      })
  }

  public openAGB() {
    this.legalService.openDialog(LegalDataFiles.AGB, 'global')
  }

  public openPrivacy() {
    this.legalService.openDialog(LegalDataFiles.Datenschutz, 'global')
  }

}
