import Joi from 'joi'

export const wfaKeys = [
  '$wfaPatientVorname$',
  '$wfaPatientNachname$',
  '$wfaPatientNamenszusatz$',
  '$wfaPatientTitel$',
  '$wfaPatientGeschlecht$',
  '$wfaPatientGeburtstag$',
  '$wfaPatientEmail$',
  '$wfaPatientTelefonPrivat$',
  '$wfaPatientTelefonMobil$',
  '$wfaPatientTelefonGeschaeftlich$',
  '$wfaPatientStrasseHausnummer$',
  '$wfaPatientPlz$',
  '$wfaPatientOrt$',
  '$wfaPatientLand$',
  '$wfaPatientKrankenkasse$',
  '$wfaPatientVersicherungsnummer$',
  '$wfaPatientVersichertennummer$',
  '$wfaPatientGewicht$',
  '$wfaPatientGroesse$',
  '$wfaPatientBeruf$',
  '$wfaPatientArbeitgeber$',
  '$wfaPatientKontaktaufnahme$',
  '$wfaPatientVersandVonInformationen$',
  '$wfaPatientHausarztName$',
  '$wfaPatientHausarztOrt$',
  '$wfaPatientFacharztName$',
  '$wfaPatientFacharztOrt$',
  '$wfaHauptversicherterVorname$',
  '$wfaHauptversicherterNachname$',
  '$wfaHauptversicherterNamenszusatz$',
  '$wfaHauptversicherterTitel$',
  '$wfaHauptversicherterGeschlecht$',
  '$wfaHauptversicherterGeburtstag$',
  '$wfaHauptversicherterStrasseHausnummer$',
  '$wfaHauptversicherterPlz$',
  '$wfaHauptversicherterOrt$',
  '$wfaHauptversicherterLand$',
] as const

export const WfaKeyJoi = Joi.string()
  .valid(...wfaKeys)
  .meta({
    className: 'WfaKey',
  })
